import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import * as _ from 'lodash'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'

import * as teamActions from '@reduxActions/teamActions'
import * as stripeActions from '@reduxActions/stripeActions'

import * as Constants from '@global/Constants'
import Spinner from '@global/Spinner'
import Checkbox from '@bit/coworks.base-components.checkbox'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import Badge from '@global/Base/Badge/Badge'
import RoundedImage from '@global/RoundedImage'
import { captureException } from '@global/ErrorFactory'

import ArchiveMemberSelect from './ArchiveMemberSelect'
import ArchiveMessage from './ArchiveMessage'
import PlanRow from '../../Plans/PlanRow'

function ArchiveModal({
  history,
  match,
  stripe_actions,
  team_actions,
  member,
  activeCampus,
  activeCommunity,
  plans,
  team,
  showArchiveModal,
  toggleShowArchiveModal,
  handleChangeStatus,
  newAdminId,
  handleInputChange,
  type = 'Member',
  // isInactive,
}) {
  let shouldSelectAll = false
  let theCheckedMembers = type === 'Team' ? [] : null
  if (theCheckedMembers) {
    if (team.status === 'inactive' && team.inactive_users) {
      // pre-check the whole inactive list
      theCheckedMembers = team.inactive_users.map(member => member.id)
      shouldSelectAll = true
    } else if (team.admins) {
      // pre-check admins
      theCheckedMembers = team.admins.map(member => member.id)
    }
  }
  const currencySymbol = Constants.getCurrencySymbolFromCommunity(
    activeCommunity
  )

  const isInactive =
    type === 'Member'
      ? Boolean(member.status === 'inactive') // todo change this to status
      : Boolean(team.status === 'inactive')
  const [loading, setLoading] = useState(false)
  const [stripeSubscriptions, setStripeSubscriptions] = useState(null)
  const [selectedAdminId, setSelectedAdminId] = useState(
    team && team.admins && team.admins.length > 0 ? team.admins[0].id : null
  )
  const [checkedMembers, setCheckedMembers] = useState(theCheckedMembers)
  const [selectAllValue, setSelectAllValue] = useState(shouldSelectAll)

  // we have this state so we can pull a team from the member and useEffect will
  // refetch the customer when this is resolved.
  const [teamCopy, setTeamCopy] = useState(team ? team : null)

  useEffect(() => {
    if (
      activeCommunity.stripe_account_id &&
      teamCopy &&
      teamCopy.stripe_customer_id
    ) {
      setLoading(true)
      stripe_actions
        .getCustomer({
          team_id: teamCopy.id,
          community_id: activeCommunity.id,
        })
        .then(response => {
          const customer = response.customer
          const stripeSubs = customer.subscriptions
            ? Constants.parseStripeSubscriptions(customer, plans)
            : null
          setStripeSubscriptions(stripeSubs)
        })
        .catch(error =>
          captureException({
            text: `Error getting customer in the archive modal`,
            error,
          })
        )
        .finally(() => setLoading(false))
    } else if (!activeCommunity.stripe_account_id && teamCopy) {
      setLoading(false)
      setStripeSubscriptions([])
    }
  }, [
    activeCommunity.id,
    activeCommunity.stripe_account_id,
    plans,
    stripe_actions,
    team,
    teamCopy,
  ])

  const memberTeams = member && member.teams ? member.teams : null

  useEffect(() => {
    if (type === 'Member') {
      setLoading(true)
      team_actions
        .getTeam(member.teams[0].id, { campus_id: activeCampus.id })
        .then(team => {
          setTeamCopy(team)
        })
        .catch(error => {
          toast.error(
            'Whoops, looks like there was an error getting the latest member details!'
          )
          captureException({
            text: `Error getting team in archive modal`,
            error,
          })
        })
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCampus.id, memberTeams, team_actions, type])

  const viewTeamClicked = team => {
    NavigationConstants.navigateToTeam({
      history: history,
      match: match,
      team,
      tab: 'profile',
    })
  }

  const handleCheckboxChange = (memberId, checked) => {
    const newCheckedMembers = _.clone(checkedMembers)
    if (checked) {
      //need to remove
      const indexToRemove = newCheckedMembers.indexOf(memberId)
      newCheckedMembers.splice(indexToRemove, 1)
      setCheckedMembers(newCheckedMembers)
      setSelectAllValue(false)
    } else {
      //need to add
      const newList =
        newCheckedMembers.indexOf(memberId) === -1
          ? [...newCheckedMembers, memberId]
          : newCheckedMembers
      setCheckedMembers(newList)
      setSelectAllValue(newList.length === team.inactive_users.length)
    }
  }
  const handleChangeInactiveAdmin = newAdminId => {
    setSelectedAdminId(newAdminId)
    const existsAsChecked =
      checkedMembers.filter(id => id === newAdminId.id).length > 0
    if (!existsAsChecked) {
      handleCheckboxChange(newAdminId, false)
    }
  }

  // eslint-disable-next-line react/no-multi-comp,  react/display-name
  const renderSubscriptions = () => {
    if (team.plans && team.plans[0] && team.plans[0].cost === 0) {
      return (
        <div>
          <h6>This team is on a free plan.</h6>
        </div>
      )
    }
    if (!stripeSubscriptions) {
      return <Spinner />
    }
    if (stripeSubscriptions.length === 0) {
      return null
    }
    return (
      <div className="col-md-12">
        <div className="row">
          <div className="col-12">
            You will also be <strong>cancelling</strong> all of these plans!
          </div>
        </div>
        {stripeSubscriptions.map((stripeSubscription, index) => {
          let interval = stripeSubscription.items.data[0].plan.interval
          const firstLetter = interval.charAt(0).toUpperCase()
          interval = firstLetter + interval.slice(1, interval.length)
          const discount = stripeSubscription.discount
            ? stripeSubscription.discount
            : null
          const coupon = discount && discount.coupon ? discount.coupon : null
          const discountCycleCount =
            discount && coupon && coupon.max_redemptions
              ? coupon.max_redemptions - coupon.times_redeemed
              : null
          return (
            <div key={index}>
              <div className="row">
                <br />
              </div>
              <div className="row">
                <div className="col">
                  <strong className="h3">{interval}ly Plans</strong>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="callout callout-primary">
                    <small className="text-muted">Billed</small>
                    <br />
                    <strong className="h5">{interval}ly</strong>
                  </div>
                </div>
                <div className="col">
                  <div className="callout callout-primary">
                    <small className="text-muted">Next Bill Date</small>
                    <br />
                    <strong className="h5">
                      {moment
                        .unix(stripeSubscription.current_period_end)
                        .format('MMM DD, YYYY')}
                    </strong>
                  </div>
                </div>
                <div className="col">
                  <div className="callout callout-primary">
                    <small className="text-muted">Start Date</small>
                    <br />
                    <strong className="h5">
                      {moment
                        .unix(stripeSubscription.start)
                        .format('MMM DD, YYYY')}
                    </strong>
                  </div>
                </div>
                <div className="col">
                  <div className="callout callout-primary">
                    <small className="text-muted">{interval}ly Total</small>
                    <br />
                    <strong className="h5">
                      {currencySymbol}
                      {Constants.calculateSubscriptionTotal(stripeSubscription)}
                    </strong>
                  </div>
                </div>
              </div>
              {renderPlans(
                stripeSubscription.items.data,
                stripeSubscription.id
              )}
            </div>
          )
        })}
      </div>
    )
  }
  function renderPlans(stripePlans, subscriptionId) {
    return stripePlans.map((plan, index) => {
      const stripeMappedPlan = Constants.mapCoworksPlanFromStripePlan(plan)
      return (
        <PlanRow
          defaultCurrency={activeCommunity.community_preference.currency}
          cost={stripeMappedPlan.cost}
          key={index}
          index={index}
          plan={stripeMappedPlan}
          subscriptionId={subscriptionId}
          quantity={stripeMappedPlan.quantity}
          totalCost={stripeMappedPlan.quantity * stripeMappedPlan.cost}
        />
      )
    })
  }
  function checkReactivateAdmin(id, members) {
    if (members && members.length > 0) {
      for (let index = 0; index < members.length; index++) {
        const element = members[index]
        if (element === id) return true
      }
      return false
    }
    return true
  }
  const selectAll = checked => {
    let newList = []
    if (checked) {
      newList = team.inactive_users.map(member => member.id)
    }
    setCheckedMembers(newList)
    setSelectAllValue(checked)
  }

  let replacementAdmins = []
  let isMemberAdmin = false
  const hasReactivateAdmin = checkReactivateAdmin(
    selectedAdminId,
    checkedMembers
  )
  if (type === 'Member') {
    isMemberAdmin = Constants.isMemberAdmin(member, team)
    if (isMemberAdmin) {
      // to save on some computing
      replacementAdmins = getReplacementAdmins(teamCopy, member)
    }
  }

  let shouldRenderSubscriptions = false

  if (type === 'Member') {
    if (isMemberAdmin && replacementAdmins.length === 0) {
      shouldRenderSubscriptions = !isInactive
    }
  } else if (type === 'Team') {
    shouldRenderSubscriptions = !isInactive
  }
  return (
    <Modal
      isOpen={showArchiveModal}
      toggle={toggleShowArchiveModal}
      className="modal-primary fade in bd-example-modal-lg modal-lg"
    >
      <ModalHeader toggle={toggleShowArchiveModal}>
        {isInactive ? 'Activate' : 'Deactivate'}{' '}
        {type === 'Member'
          ? `${member.first_name} ${member.last_name}`
          : `${team.name}`}
        ?
      </ModalHeader>
      {loading ? (
        <Spinner />
      ) : (
        <ModalBody className="py-0">
          <div className="row border-bottom py-2">
            <ArchiveMessage
              team={teamCopy}
              member={member}
              type={type}
              isInactive={isInactive}
              isMemberAdmin={isMemberAdmin}
              hasReplacementAdmins={replacementAdmins.length > 0}
              shouldRenderSubscriptions={shouldRenderSubscriptions}
              teamMemberComp={() => (
                <div className="my-2">
                  {teamCopy.inactive_users && teamCopy.users && (
                    <ArchiveMemberSelect
                      team={teamCopy}
                      selectedAdminId={selectedAdminId}
                      checkedMembers={checkedMembers}
                      members={
                        isInactive ? teamCopy.inactive_users : teamCopy.users
                      }
                      handleChangeInactiveAdmin={handleChangeInactiveAdmin}
                      handleCheckboxChange={handleCheckboxChange}
                      onSelectAll={selectAll}
                      selectValue={selectAllValue}
                    />
                  )}
                </div>
              )}
              dropdownComp={() => (
                <select
                  name="newAdminId"
                  className="form-control"
                  value={newAdminId}
                  onChange={handleInputChange}
                >
                  <option key={-1} value={-1}>
                    -
                  </option>
                  {renderReplacementAdmins(replacementAdmins)}
                </select>
              )}
              companyButton={() => (
                <TextButton
                  onClick={() => viewTeamClicked(team)}
                  className="m-0 p-0"
                >
                  team profile.{' '}
                </TextButton>
              )}
              renderSubscriptions={renderSubscriptions}
            />
            {isInactive && checkedMembers && checkedMembers.length === 0 && (
              <div className="text-danger px-3">
                Warning: You are about to reactivate this team with no members.
              </div>
            )}
            {isInactive && !hasReactivateAdmin && (
              <div className="text-danger px-3">
                Warning: You must select an admin before reactivating this team.
              </div>
            )}
          </div>
        </ModalBody>
      )}
      <ModalFooter className="d-flex justify-content-end align-items-center">
        <Button color="secondary" onClick={toggleShowArchiveModal}>
          Cancel
        </Button>
        <Button
          disabled={Boolean(
            (replacementAdmins &&
              isMemberAdmin &&
              replacementAdmins.length > 0 &&
              newAdminId < 0) ||
              loading ||
              !hasReactivateAdmin
          )}
          color={isInactive ? 'success' : 'danger'}
          onClick={() => {
            const params = {}
            if (selectedAdminId) {
              params.admin_id = selectedAdminId
            }

            if (checkedMembers && checkedMembers.length >= 0) {
              params.member_ids = JSON.stringify(checkedMembers)
            }
            handleChangeStatus({ isInactive, params })
          }}
        >
          {isInactive ? 'Activate' : 'Deactivate'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function getReplacementAdmins(team, member) {
  if (!team.users) {
    return []
  }
  const replacementAdmins = []
  for (let counter = 0; counter < team.users.length; counter++) {
    if (team.users[counter].id !== member.id) {
      // don't add the current admin to the list of replacements
      replacementAdmins.push(team.users[counter])
    }
  }
  return replacementAdmins
}

function renderReplacementAdmins(replacements) {
  return replacements.map((member, index) => (
    <option
      key={index}
      value={member.id}
    >{`${member.first_name} ${member.last_name} - ${member.email}`}</option>
  ))
}

ArchiveModal.displayName = 'ArchiveModal'
ArchiveModal.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  stripe_actions: PropTypes.object.isRequired,
  team_actions: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  activeCampus: PropTypes.object.isRequired,
  activeCommunity: PropTypes.object.isRequired,
  plans: PropTypes.array.isRequired,
  team: PropTypes.object.isRequired,
  showArchiveModal: PropTypes.bool.isRequired,
  toggleShowArchiveModal: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  newAdminId: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  isInactive: PropTypes.bool.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    team_actions: bindActionCreators(teamActions, dispatch),
    stripe_actions: bindActionCreators(stripeActions, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(ArchiveModal)
