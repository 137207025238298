import { combineReducers } from 'redux'
import { clearState } from '../store/localStorage'

import attachments from './attachmentReducer'
import announcements from './announcementReducer'
import bookings from './bookingReducer'
// import campuses from './campusReducer'
import checkEmail from './checkEmailReducer'
import community from './communityReducer'
import leads from './leadReducer'
import manager from './managerReducer'

import members from './memberReducer'
import network from './networkReducer'
import checkins from './checkinReducer'
import staff from './staffReducer'
import plans from './planReducer'
import resources from './resourceReducer'
import rooms from './roomReducer'
import teams from './teamsReducer'
import externalBookings from './externalBookingsReducer'
import ui from './uiReducer'
import user from './userReducer'
import gcal from './googleCalendarReducer'
import stripe from './stripeReducer'
import stats from './statsReducer'
import integrations from './integrationReducer'

import * as actionTypes from '../reduxActions/actionTypes'
import initialState from './initialState'

const appReducer = combineReducers({
  /* your app’s top-level reduxReducers alphabetically */
  attachments,
  announcements,
  bookings,
  // campuses,
  checkEmail,
  community,
  gcal,
  integrations,
  leads,
  manager,
  members,
  network,
  checkins,
  plans,
  resources,
  externalBookings,
  rooms,
  staff,
  stats,
  stripe,
  teams,
  ui,
  user,
})

const rootReducer = (state, action) => {
  let myState = state
  if (action.type === actionTypes.SIGN_OUT) {
    myState = initialState
    clearState()
  }
  return appReducer(myState, action)
}

export default rootReducer
