import {
  SET_EXBOOKING_ROOMS,
  UPDATE_EXBOOKING_ROOMS,
  CLEAR_EXBOOKING_ROOMS
} from '../reduxActions/actionTypes'
import { arrayMerge } from './reducerConstants'

const initialState = {
  rooms: {
    data: [],
    meta: null
  }
}

export default function (exBookings = initialState, action) {
  const exBookingsArray = exBookings.rooms.data

  switch (action.type) {
    case SET_EXBOOKING_ROOMS:
      return {
        ...exBookings,
        rooms: {
          data: action.rooms,
          meta: action.meta
        }
      }
    case UPDATE_EXBOOKING_ROOMS:
      if (exBookingsArray && exBookingsArray.length > 0) {
        const oldRooms = exBookingsArray
        const newRooms = action.rooms
        return {
          ...exBookings,
          rooms: {
            data: arrayMerge(oldRooms, newRooms),
            meta: action.meta,
          }
        }
      }
      return {
        ...exBookings,
        rooms: { data: action.rooms, meta: action.meta }
      }
    case CLEAR_EXBOOKING_ROOMS:
      return {
        ...exBookings,
        rooms: { data: [], meta: null }
      }


    default:
      return exBookings
  }
}
