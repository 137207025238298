import React from 'react';
import Otto from '../../img/placeholders/otto_color.png';
import PropTypes from 'prop-types';

export default class NothingFound extends React.Component {
  static propTypes = {
    renderInCard: PropTypes.bool.isRequired,
    displayText: PropTypes.string,
    showFeedbackButton: PropTypes.bool,
  }
  render() {
    let displayText = 'Looks like nothing\'s here.';
    if (this.props.displayText) {
      displayText = this.props.displayText;
    }
    if (this.props.renderInCard) {
      return (
        <div className='animated fadeIn'>
          <div className='row'>
            <div className='col-sm-12 col-md-12'>
              <div className='card'>
                <div className='card-block'>
                  <div className='row'>
                    <div className='col-6 d-inline-flex justify-content-center align-items-center'>
                      <h5>{displayText}</h5>
                    </div>
                    <div className='col-6 d-inline-flex justify-content-start align-items-center'>
                      <img src={Otto} className='img-responsive w-50 center-block'/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-sm-12 col-md-12'>
            <div className='row'>
              <div className='col-6 d-inline-flex justify-content-center align-items-center'>
                <h5>{displayText}</h5>
              </div>
              <div className='col-6 d-inline-flex justify-content-start align-items-center'>
                <img src={Otto} className='img-responsive w-50 center-block'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NothingFound.displayName = 'NothingFound';

