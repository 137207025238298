/* eslint-disable react/no-multi-comp */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'

import * as Constants from '@global/Constants'
import { convertStringDateToMomentDate } from '@global/Constants/DateConstants'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'

import { LabelStyled, SingleDatePicker } from '@global/Form/FormComponents'

const EXPIRES_indefinitely = 'indefinitely'
const EXPIRES_nextBillingCycle = 'nextSevenDays'
const EXPIRES_nextThirtyDays = 'nextThirtyDays'
const EXPIRES_nextMonth = 'nextMonth'
const EXPIRES_custom = 'custom'

export default function PauseMembershipModal({
  showModal,
  toggleModal,
  handleSaveClicked,
  subscription,
  nextBillingCycle,
  isPaused,
}) {
  const [dateRangeOption, setDateRangeOption] = useState(EXPIRES_indefinitely)
  const [resumeAtDate, setResumeAtDate] = useState(null)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [datePickerFocused, setDatePickerFocused] = useState(false)
  const [pauseMembershipBehavior, setPauseMembershipBehavior] = useState(
    'keep_as_draft'
  )

  useEffect(() => {
    if (dateRangeOption === EXPIRES_custom) {
      setResumeAtDate(null)
    }
  }, [dateRangeOption])

  function handleDateRangeCheckChanged(event) {
    const newDateString = event.target.name
    const { start, end } = convertStringDateToMomentDate(newDateString)
    switch (event.target.name) {
      case EXPIRES_indefinitely:
      case EXPIRES_custom:
        setResumeAtDate(null)
        break
      case EXPIRES_nextBillingCycle:
        setResumeAtDate(moment.unix(nextBillingCycle).add(1, 'days'))
        break
      case EXPIRES_nextThirtyDays:
        setResumeAtDate(end)
        break
      case EXPIRES_nextMonth:
        setResumeAtDate(start)
        break
      default:
        break
    }

    setDateRangeOption(newDateString)
  }

  function handlePrepareApiObject() {
    // Bundle up the params
    let params = null
    if (isPaused) {
      params = {
        pause_resume_now: true,
      }
    } else {
      params = {
        pause_behavior: pauseMembershipBehavior,
        pause_resumes_at: resumeAtDate ? resumeAtDate.unix() : null,
      }
    }
    // pause_resume_now = true
    handleSaveClicked(params)
  }
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      className="modal-primary fade in modal-lg"
    >
      <ModalHeader toggle={toggleModal}>Pause Membership?</ModalHeader>
      <ModalBody>
        {!isPaused ? (
          <div className="container-fluid">
            <Row className="my-3">
              <div className="col-12">
                <LabelStyled>Pause membership</LabelStyled>
                {/* <span className="text-muted">
                    {' '}
                    The date the membership will resume normal operation
                  </span> */}
              </div>
              <div className="col-12">
                <FormGroup tag="fieldset">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name={EXPIRES_indefinitely}
                        checked={dateRangeOption === EXPIRES_indefinitely}
                        onClick={handleDateRangeCheckChanged}
                      />{' '}
                      Indefinitely
                      <span className="ml-3 text-muted">
                        This will be paused until you resume it
                      </span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name={EXPIRES_nextBillingCycle}
                        checked={dateRangeOption === EXPIRES_nextBillingCycle}
                        onClick={handleDateRangeCheckChanged}
                      />{' '}
                      Until next billing cycle
                      <span className="ml-3 text-muted">
                        Skips the{' '}
                        {moment.unix(nextBillingCycle).format('MMM DD')} billing
                        cycle
                      </span>
                    </Label>
                  </FormGroup>
                  {/* <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name={EXPIRES_nextThirtyDays}
                      checked={dateRangeOption === EXPIRES_nextThirtyDays}
                      onClick={handleDateRangeCheckChanged}
                    />{' '}
                    For 1 month
                    <span className="ml-3 text-muted">
                      {convertStringDateToMomentDate(
                        EXPIRES_nextThirtyDays,
                        startDate,
                        endDate
                      ).end.format('MMM DD')}
                    </span>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name={EXPIRES_nextMonth}
                      onClick={handleDateRangeCheckChanged}
                      checked={dateRangeOption === EXPIRES_nextMonth}
                    />{' '}
                    Until the beginning of next month
                    <span className="ml-3 text-muted">
                      {`${convertStringDateToMomentDate(
                        EXPIRES_nextMonth,
                        startDate,
                        endDate
                      ).start.format('MMM DD')}`}
                    </span>
                  </Label>
                </FormGroup> */}
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name={EXPIRES_custom}
                      checked={dateRangeOption === EXPIRES_custom}
                      onClick={handleDateRangeCheckChanged}
                    />{' '}
                    Custom
                  </Label>
                </FormGroup>
              </div>
              {dateRangeOption === EXPIRES_custom && (
                <div className="col-12">
                  <SingleDatePicker
                    id="pauseMembershipDate"
                    date={resumeAtDate}
                    onDateChange={momentDate => {
                      // ensure that we don't fuck with time that was set previously
                      setResumeAtDate(momentDate)
                    }}
                    focused={datePickerFocused}
                    onFocusChange={({ focused }) =>
                      setDatePickerFocused(focused)
                    }
                    showClearDate
                    placeholder={'Pause Until Date'}
                  />
                </div>
              )}
            </Row>
            <Row className="my-3">
              <div className="col-12">
                <LabelStyled>Invoice Behavior</LabelStyled>
              </div>
              <div className="col-md-6 col-12">
                <select
                  className="form-control"
                  onChange={event =>
                    setPauseMembershipBehavior(event.target.value)
                  }
                >
                  <option value="keep_as_draft">Don't create</option>
                  <option value="void">
                    Create, but then automatically close
                  </option>
                </select>
              </div>
            </Row>
            <Row className="my-3">
              <div className="col-md-12">
                <p className="text-muted">
                  Note: Pausing a membership causes invoices that would be
                  raised during the pause time to be effected by your pause
                  behavior. A membership will not prorate when it is resumed and
                  it will not change the billing cycle.
                </p>
              </div>
            </Row>
          </div>
        ) : (
          <Row>
            <div className="col-12">
              <h6>Are you sure you want to resume the membership?</h6>
              <p className="text-muted">
                This membership will resume raising invoices on it's regularly
                scheduled billing cycle.
              </p>
            </div>
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end align-items-center">
        <Button color="white" onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={
            !dateRangeOption ||
            (dateRangeOption === EXPIRES_custom && !resumeAtDate)
          }
          onClick={handlePrepareApiObject}
        >
          {isPaused ? 'Resume' : 'Pause'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
PauseMembershipModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  subscription: PropTypes.object.isRequired,
  nextBillingCycle: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSaveClicked: PropTypes.func.isRequired,
  isPaused: PropTypes.bool.isRequired,
}

PauseMembershipModal.displayName = 'PauseMembershipModal'
