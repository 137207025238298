import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'

// ACTION CREATORS
export function setStaff(staff, meta = null) {
  return { type: actionTypes.SET_STAFF, staff, meta }
}

// ACTION DISPATCHERS
export function getStaffTeam(campusId, params) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .getStaff(campusId, params)
      .then(response => dispatch(setStaff(response.team, response.meta)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}
