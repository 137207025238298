import { ColorLuminance, getContrastingColor } from '@global/Colors/ColorUtils'
import { readableColor, lighten, darken } from 'polished'
import * as _ from 'lodash'
import colors from '../Colors/colors'

const theme = key => prop =>
  _.get(prop.theme, key) || console.warn('missing key', key)

export function resolveColor(props) {
  let color = props.theme.colorPrimary
  if (props.color && props.color.match(/^(#|rgb)/)) return props.color
  // background color will override build-in colors
  if (props.backgroundColor) {
    color = props.backgroundColor
  } else if (props.color) {
    color = resolveColorValue(props, props.color)
  }
  return color
}

export function resolveHoverColor(props) {
  const threshold = 0.2
  let color = props.theme.colorPrimaryHover
  if (props.color && props.color.match(/^(#|rgb)/))
    return lighten(threshold, props.color)
  // background color will override build-in colors
  if (props.backgroundColor) {
    color = ColorLuminance(props.backgroundColor, 0.4)
  } else {
    color = resolveColorValue(props, props.color)
  }
  return color
}
export function resolveBorderColor(props) {
  let color = props.theme.colorPrimary
  if (props.color && props.color.match(/^(#|rgb)/)) return props.color
  // background color will override build-in colors
  if (props.backgroundColor) {
    color = props.backgroundColor
  } else {
    switch (props.color) {
      case 'primary':
        color = props.theme.colorPrimary
        break
      case 'secondary':
        color = props.theme.colorSecondary
        break
      case 'danger':
        color = props.theme.colorDanger
        break
      case 'info':
        color = props.theme.colorInfo
        break
      case 'warning':
        color = props.theme.colorWarning
        break
      case 'white':
        color = '#cccccc'
        break
      case 'default':
        color = props.theme.colorDefault
        break
      default:
        break
    }
  }
  return color
}

export function resolveButtonTextColor(props) {
  const threshold = 0.08
  let color = props.theme.colorPrimaryText
  if (props.color && props.color.match(/^(#|rgb)/))
    return readableColor(darken(threshold, props.color))
  // background color will override build-in colors
  if (props.backgroundColor) {
    color = getContrastingColor(props.backgroundColor)
    if (props.textColor) {
      color = props.textColor
    }
  } else {
    switch (props.color) {
      case 'primary':
      case 'danger':
        color = props.theme.colorPrimaryText
        break
      case 'secondary':
        color = props.theme.colorSecondaryText
        break
      case 'white':
        color = colors.black
        break
      case 'default':
        color = props.theme.colorDefaultText
        break
      case 'info':
        color = props.theme.colorInfoText
        break
      case 'warning':
        color = props.theme.colorWarningText
        break
      default:
        break
    }
  }
  return color
}

export function resolveTextColorValue(props, colorKey) {
  let color = ''
  switch (colorKey) {
    case 'primary':
      color = props.theme.fonts.colors.primary
      break
    case 'secondary':
      color = props.theme.fonts.colors.secondary
      break
    case 'dark':
      color = props.theme.fonts.colors.dark
      break
    case 'light':
      color = props.theme.fonts.colors.light
      break
    case 'gray':
      color = props.theme.fonts.colors.gray
      break
    case 'success':
      color = props.theme.fonts.colors.success
      break
    case 'warning':
      color = props.theme.fonts.colors.warning
      break
    case 'danger':
      color = props.theme.fonts.colors.danger
      break
    case 'info':
      color = props.theme.fonts.colors.info
      break
    default:
      color = resolveColorValue(props, colorKey)
      break
  }
  return color
}

export function resolveColorValue(props, colorKey) {
  let color
  switch (colorKey) {
    case 'primary':
      color = props.theme.colors.primary
      break
    case 'primary-light':
      color = props.theme.colors.primaryLight
      break
    case 'secondary':
      color = props.theme.colors.secondary
      break
    case 'secondary-light':
      color = props.theme.colors.secondaryLight
      break
    case 'success':
      color = props.theme.colors.success
      break
    case 'success-light':
      color = props.theme.colors.successLight
      break
    case 'danger':
      color = props.theme.colors.danger
      break
    case 'danger-light':
      color = props.theme.colors.dangerLight
      break
    case 'info':
      color = props.theme.colors.info
      break
    case 'info-light':
      color = props.theme.colors.infoLight
      break
    case 'warning':
      color = props.theme.colors.warning
      break
    case 'warning-light':
      color = props.theme.colors.warningLight
      break
    case 'white':
      color = props.theme.colors.white
      break
    case 'neutral-1':
      color = props.theme.colors.neutral1
      break
    case 'neutral-2':
      color = props.theme.colors.neutral2
      break
    case 'neutral-3':
      color = props.theme.colors.neutral3
      break
    case 'neutral-4':
      color = props.theme.colors.neutral4
      break
    case 'neutral-5':
      color = props.theme.colors.neutral5
      break
    case 'neutral-6':
      color = props.theme.colors.neutral6
      break
    case 'accent-1':
      color = props.theme.colors.accent1
      break
    case 'accent-2':
      color = props.theme.colors.accent2
      break
    case 'accent-3':
      color = props.theme.colors.accent3
      break
    case 'accent-4':
      color = props.theme.colors.accent4
      break
    case 'accent-5':
      color = props.theme.colors.accent5
      break
    case 'accent-6':
      color = props.theme.colors.accent6
      break
    case 'accent-7':
      color = props.theme.colors.accent7
      break
    default:
      break
  }
  return color
}

export function resolveLightVariant(colorKey) {
  let color = 'primary-light' // default
  switch (colorKey) {
    // case 'primary':
    //   color =
    //   break
    case 'secondary':
      color = 'secondary-light'
      break
    case 'neutral-3':
      color = 'neutral-1'
      break
    case 'neutral-4':
      color = 'neutral-1'
      break
    case 'danger':
      color = 'danger-light'
      break
    case 'success':
      color = 'success-light'
      break
    case 'warning':
      color = 'warning-light'
      break
    case 'info':
      color = 'info-light'
      break
    default:
      console.warn(
        `Using the default ${color}. resolveLightVariant() wasn't provided a color`
      )
      break
  }
  return color
}

export { theme }
