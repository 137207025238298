/* eslint-disable react/prop-types */
import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { getCustomerDefaultSource } from '@global/Constants/PaymentConstants'
import PaymentSourceComponent from '@global/Stripe/PaymentSourceComponent'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import { TextButton } from '@global/Base/Button/ButtonStyled'

export default function PayNowModal({
  showPayNowModal,
  togglePayNowModal,
  renderNotesFields,
  isFetching,
  payInvoice,
  customer,
  team,
  history,
  match,
  hostedInvoiceUrl,
}) {
  const { defaultSource } = getCustomerDefaultSource(customer, true)
  return (
    <Modal
      isOpen={showPayNowModal}
      toggle={togglePayNowModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={togglePayNowModal}>
        Choose Payment Source
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            {defaultSource ? (
              <div className="col">
                <strong>
                  Are you sure you want to{' '}
                  <span className="text-primary">charge</span> this invoice?
                </strong>
                <p>
                  <small>This will immediately draft from:</small>
                </p>
                <div className="row d-flex align-items-center">
                  <PaymentSourceComponent
                    isLoadingSource={false}
                    defaultSourceObject={defaultSource}
                  />
                </div>
                <br />
                <Row>
                  <div className="col-12">{renderNotesFields()}</div>
                </Row>
              </div>
            ) : (
              <div className="col text-center">
                <strong>No Payment Method Detected</strong>
                <p>
                  <small>
                    Please add a payment method before attempting to pay this
                    invoice
                  </small>
                </p>
                <p>
                  This can be done by going to the team's{' '}
                  {team && (
                    <React.Fragment>
                      <TextButton
                        className="m-0 p-0"
                        onClick={() => {
                          NavigationConstants.navigateToTeam({
                            history: history,
                            match: match,
                            team,
                            tab: 'membership',
                          })
                        }}
                      >
                        membership tab.
                      </TextButton>
                    </React.Fragment>
                  )}
                  {hostedInvoiceUrl && (
                    <React.Fragment>
                      {team ? ',' : ''} the hosted{' '}
                      <TextButton
                        className="m-0 p-0"
                        onClick={() => {
                          window.open(hostedInvoiceUrl, '_blank')
                        }}
                      >
                        invoice link
                      </TextButton>
                    </React.Fragment>
                  )}
                  {team && ' or by the member in the mobile app.'}
                </p>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {defaultSource && (
          <Button color="secondary" onClick={togglePayNowModal}>
            Cancel
          </Button>
        )}
        {defaultSource && (
          <Button
            color="primary"
            disabled={isFetching}
            onClick={() => payInvoice(false)}
          >
            Confirm
          </Button>
        )}
        {!defaultSource && (
          <Button color="primary" onClick={togglePayNowModal}>
            OK
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}
PayNowModal.displayName = 'PayNowModal'
