/* eslint-disable react/no-multi-comp */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import * as Constants from '@global/Constants'

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
} from 'reactstrap'

import { LabelStyled } from '@global/Form/FormComponents'

import TogglesComponent from '@global/Base/Toggle/TogglesComponent'

const DISCOUNT_EXPIRES_NEVER = 'Never Expires'
const DISCOUNT_EXPIRES = 'Expires'
export default function DiscountModal({
  showAddDiscountModal,
  subForDiscountModal, // when existing subscription and we're adding a discount to it
  subscriptionCart, // when new company and we're adding a discount to it
  team,
  teamName, // when no team object has been created
  activeCommunity,
  toggleAddDiscountModal,
  handleSaveDiscountClicked,
  billingFrequency,
  listLength,
  currencySymbol,
  isProrated,
}) {
  const [discountName, setDiscountName] = useState(null)
  const [discountTotalDollars, setDiscountTotalDollars] = useState(0)
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(listLength) // selectedPlanToDiscountCartIndex in NewTeamForm
  const [discountExpiresOption, setDiscountExpiresOption] = useState(
    'Never Expires'
  )
  const [isPercentage, setIsPercentage] = useState(true)
  const [discountValue, setDiscountValue] = useState(0)
  const [plansSubtotal, setPlansSubtotal] = useState(0)
  const [discountExpirationDuration, setDiscountExpirationDuration] = useState(
    1
  )

  useEffect(() => {
    // calculate discount and plan total when options change
    let totalInDollars = 0
    let totalPlanVal = 0
    if (subForDiscountModal) {
      // existing subscription
      if (
        parseInt(selectedPlanIndex) === subForDiscountModal.items.data.length
      ) {
        // the all option
        // go through all plans in cart and calculate total
        totalPlanVal = Constants.calculateSubscriptionTotal(subForDiscountModal)

        if (isPercentage) {
          // %
          totalInDollars += totalPlanVal * (discountValue / 100)
        } else {
          // $
          totalInDollars = discountValue
        }
      } else {
        const subItem =
          subForDiscountModal.items.data[parseInt(selectedPlanIndex)]
        totalPlanVal = subItem.plan.amount * subItem.quantity
        if (isPercentage) {
          // %
          totalInDollars = totalPlanVal * (discountValue / 100)
        } else {
          // $
          totalInDollars = discountValue
        }
      }
    } else if (
      subscriptionCart &&
      parseInt(selectedPlanIndex) === subscriptionCart.length
    ) {
      // new team form
      // the all option
      // go through all plans in cart and caluclate total
      subscriptionCart.forEach(item => {
        const qty = Number(item.uiState.quantity)
        const cost = Number(item.plan.cost)
        if (!isNaN(qty) && !isNaN(cost)) totalPlanVal += qty * cost
      })

      if (isPercentage) {
        // %
        totalInDollars += totalPlanVal * (discountValue / 100)
      } else {
        // $
        totalInDollars = discountValue
      }
    } else if (
      subscriptionCart &&
      parseInt(selectedPlanIndex) !== subscriptionCart.length
    ) {
      const cartObj = subscriptionCart[parseInt(selectedPlanIndex)]
      const qty = Number(cartObj.uiState.quantity)
      const cost = Number(cartObj.plan.cost)
      if (!isNaN(qty) && !isNaN(cost)) {
        totalPlanVal = qty * cost
      }

      if (isPercentage) {
        // %
        totalInDollars = totalPlanVal * (discountValue / 100)
      } else {
        // $
        totalInDollars = discountValue
      }
    }
    setDiscountTotalDollars(totalInDollars)
    setPlansSubtotal(totalPlanVal)
  }, [
    discountValue,
    isPercentage,
    selectedPlanIndex,
    plansSubtotal,
    subForDiscountModal,
    subscriptionCart,
  ])

  function handleDiscountNameChange(event) {
    setDiscountName(event.target.value)
  }
  function handleDiscountExpirationDurationChange(event) {
    if (event.target.value > 0 || event.target.value === '') {
      setDiscountExpirationDuration(parseInt(event.target.value))
    }
  }
  function handleSelectedPlanChange(event) {
    const indexVal = parseInt(event.target.value)
    setSelectedPlanIndex(indexVal)
  }
  function handleDiscountExpiresOptionChange(option) {
    setDiscountExpiresOption(option)
  }

  // eslint-disable-next-line react/display-name
  function getDiscountMath() {
    const symbol = Constants.getCurrencySymbolFromCommunity(activeCommunity)

    if (isPercentage) {
      let ofValue = plansSubtotal
      if (subForDiscountModal) {
        if (
          subForDiscountModal.items.data.length > 0 &&
          selectedPlanIndex !== null &&
          selectedPlanIndex !== subForDiscountModal.items.data.length
        ) {
          ofValue = plansSubtotal
        }
      } else if (subscriptionCart) {
        if (
          subscriptionCart.length > 0 &&
          selectedPlanIndex !== null &&
          selectedPlanIndex !== subscriptionCart.length
        ) {
          ofValue = subscriptionCart[selectedPlanIndex].totalCost
        }
      }
      const string = `(${
        discountValue && discountValue >= 0 ? discountValue : '-'
      }% of ${symbol}${ofValue})`
      return <span className="text-muted">{string}</span>
    }

    return null
  }

  function getDiscountPlanTotalAfterDiscount() {
    if (
      subForDiscountModal &&
      subForDiscountModal.items.data.length > 0 &&
      selectedPlanIndex !== null
    ) {
      // existing subscription
      const planTotal = plansSubtotal
      return Number(planTotal - discountTotalDollars).toFixed(2)
    } else if (
      subscriptionCart &&
      subscriptionCart.length > 0 &&
      selectedPlanIndex !== null
    ) {
      // new team form
      return Number(
        subscriptionCart[selectedPlanIndex].totalCost - discountTotalDollars
      ).toFixed(2)
    }
    return null
  }
  function handleDiscountValueChange(event) {
    let value = parseInt(event.target.value)
    if (!isNaN(value)) {
      // ensure number before continuing
      if (value < 0) {
        value = 0
      } else if (isPercentage && value > calculateMaxDiscountAmount('%')) {
        value = calculateMaxDiscountAmount('%')
      } else if (value > calculateMaxDiscountAmount('$')) {
        value = calculateMaxDiscountAmount('$')
      }
      setDiscountValue(value)
    } else {
      setDiscountValue(null)
    }
  }

  function calculateMaxDiscountAmount(type) {
    // type = '$' or '%
    if (subForDiscountModal) {
      if (type === '$') {
        // if (selectedPlanIndex !== subForDiscountModal.items.data.length) {
        //   return
        // }
        return plansSubtotal
      }
      return 100
    } else if (subscriptionCart) {
      // type = '$' or '%'
      if (subscriptionCart.length > 0) {
        if (type === '$') {
          // if (selectedPlanIndex !== subscriptionCart.length) {
          //   return subscriptionCart[selectedPlanIndex].totalCost
          // }
          return plansSubtotal //planGrandTotal
        }
        return 100
      }
      return null
    }
    return null
  }

  function handleFlexUnitToggle(event) {
    setIsPercentage(!isPercentage)
  }

  // eslint-disable-next-line react/display-name
  function renderDiscountPlanOptions() {
    const symbol = Constants.getCurrencySymbolFromCommunity(activeCommunity)
    if (subForDiscountModal) {
      return subForDiscountModal.items.data.map((subObject, index) => {
        return (
          <option key={index} value={index}>
            {subObject.quantity && subObject.quantity > 1
              ? ` (${subObject.quantity}) `
              : ''}
            {subObject.plan.nickname} - {symbol}
            {subObject.plan.amount * subObject.quantity}
            {subObject.quantity && subObject.quantity > 1
              ? ' total'
              : ''} per {subObject.plan.interval}
          </option>
        )
      })
    } else if (subscriptionCart) {
      return subscriptionCart.map((subObject, index) => {
        return (
          <option key={index} value={index}>
            {subObject.uiState.quantity && subObject.uiState.quantity > 1
              ? ` (${subObject.uiState.quantity}) `
              : ''}
            {subObject.plan.name} - {symbol}
            {subObject.totalCost}
            {subObject.uiState.quantity && subObject.uiState.quantity > 1
              ? ' total'
              : ''}{' '}
            per {subObject.plan.frequency}
          </option>
        )
      })
    }
    return null // todo implement when no subscription
  }

  function handleSave() {
    // Bundle up the discount object and send to parent
    const couponObject = {
      stripe_account_id: activeCommunity.stripe_account_id,
      name: discountName, // api calls don't share same name :(
      coupon_name: discountName, // coupon_name -  api calls don't share same name :(
      amount_off: discountTotalDollars,
    }
    if (discountExpiresOption === DISCOUNT_EXPIRES) {
      // duration_in_months is better than expirations, but duration_in_months is required still
      couponObject.duration_in_months = discountExpirationDuration
      couponObject.duration = 'repeating'
    } else {
      couponObject.duration = 'forever'
    }

    if (team) {
      // may be in new team form
      couponObject.stripe_customer_id = team.stripe_customer_id
    }

    if (subForDiscountModal) {
      // adding a coupon to a subscription
      couponObject.subscription_id = subForDiscountModal.id

      let metadata
      if (selectedPlanIndex !== subForDiscountModal.items.data.length) {
        // taking a discount from an actual plan
        const cartObjectToDiscount =
          subForDiscountModal.items.data[selectedPlanIndex]

        metadata = {
          type: 'plan',
          planId: cartObjectToDiscount.plan.id,
          discountType: isPercentage ? '%' : '$',
          planTotalBeforeDiscount: plansSubtotal,
          planQuantity: cartObjectToDiscount.quantity,
        }
        if (isPercentage) {
          metadata.percentageOff = discountValue
        }
      } else {
        // taking a discount from the whole subscription
        couponObject.amount_off = discountTotalDollars
        metadata = {
          type: 'subscription',
          discountType: isPercentage ? '%' : '$',
          subscriptionTotalBeforeDiscount: plansSubtotal,
        }
        if (isPercentage) {
          metadata.percentageOff = discountValue
        }
      }
      couponObject.metadata = JSON.stringify(metadata)
    } else {
      // this is new subscription
      let metadata
      if (selectedPlanIndex !== subscriptionCart.length) {
        // taking a discount from an actual plan
        const cartObjectToDiscount = subscriptionCart[selectedPlanIndex]

        metadata = {
          type: 'plan',
          planId: cartObjectToDiscount.plan.id,
          discountType: isPercentage ? '%' : '$',
          planTotalBeforeDiscount: plansSubtotal,
          planQuantity: cartObjectToDiscount.uiState.quantity,
        }
        if (isPercentage) {
          metadata.percentageOff = discountValue
        }
      } else {
        // taking a discount from the whole subscription
        couponObject.amount_off = discountTotalDollars
        metadata = {
          type: 'subscription',
          discountType: isPercentage ? '%' : '$',
          subscriptionTotalBeforeDiscount: plansSubtotal,
        }
        if (isPercentage) {
          metadata.percentageOff = discountValue
        }
      }
      couponObject.metadata = JSON.stringify(metadata)
    }

    handleSaveDiscountClicked(couponObject)
  }
  return (
    <Modal
      isOpen={showAddDiscountModal}
      toggle={toggleAddDiscountModal}
      className="modal-primary fade in bd-example-modal-lg modal-lg"
    >
      <ModalHeader toggle={toggleAddDiscountModal}>
        Discount {team ? `for ${team.name}` : `${teamName}`}
      </ModalHeader>
      <ModalBody className="py-0">
        <div className="row border-bottom py-2">
          <div className="col-12">
            <div className="row">
              <div className="col-8">
                <div className="w-100 m-1">
                  <LabelStyled>
                    Discount Name
                    {discountName && (
                      <span
                        className={discountName.length > 40 ? '' : 'text-muted'}
                        style={discountName.length > 40 ? { color: 'red' } : {}}
                      >
                        {discountName.length > 0
                          ? ` (${discountName.length}/40)`
                          : ''}
                      </span>
                    )}
                  </LabelStyled>
                  <Input
                    type="text"
                    name="discountName"
                    autoFocus
                    value={discountName}
                    placeholder={'What is this discount for?'}
                    onChange={handleDiscountNameChange}
                    className="p-3 d-block form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-8 d-flex align-items-center">
                <div className="w-100">
                  <LabelStyled>Plan to Discount </LabelStyled>
                  <select
                    className="form-control h-100 w-100"
                    id="plan-form"
                    onChange={handleSelectedPlanChange}
                    defaultValue={listLength}
                  >
                    {renderDiscountPlanOptions()}
                    {listLength > 1 && (
                      <option value={listLength} name="all">
                        All {billingFrequency}ly plans - {currencySymbol}
                        {plansSubtotal} total per month
                      </option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-2 d-flex align-items-center">
                <div>
                  <LabelStyled className="my-1">
                    {isPercentage ? 'Percent ' : 'Amount '} Off
                  </LabelStyled>
                  <Input
                    type="number"
                    name="discountValue"
                    value={discountValue}
                    placeholder={'0'}
                    onChange={handleDiscountValueChange}
                    className="p-3 d-block form-control"
                  />
                </div>
              </div>
              <div className="col-2 d-flex align-items-center">
                <div>
                  <label>&nbsp;</label>
                  <div className="p-2 d-block form-control">
                    <Button
                      className={
                        isPercentage
                          ? 'btn btn-sm w-50 btn-primary'
                          : 'btn btn-sm w-50 btn-link text-muted'
                      }
                      onClick={handleFlexUnitToggle}
                    >
                      %
                    </Button>
                    <Button
                      className={
                        !isPercentage
                          ? 'btn btn-sm w-50 btn-primary'
                          : 'btn btn-sm w-50 btn-link text-muted'
                      }
                      onClick={handleFlexUnitToggle}
                    >
                      {currencySymbol}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-12">
            <div className="row d-flex justify-content-center">
              <div className="col-5 d-flex align-items-center pl-3">
                {TogglesComponent({
                  arrayOfTabs: [
                    {
                      label: 'Never Expires',
                    },
                    {
                      label: 'Expires',
                    },
                  ],
                  handleToggleTab: handleDiscountExpiresOptionChange,
                  activeTab: discountExpiresOption,
                })}
              </div>
              <div
                className={
                  discountExpiresOption === DISCOUNT_EXPIRES_NEVER
                    ? 'col-7 opacity-25 d-flex align-items-center'
                    : 'col-7 opacity-100 d-flex align-items-center'
                }
              >
                <div className="row">
                  <div className="col-4">
                    <Input
                      type="number"
                      name="discountExpirationDuration"
                      value={discountExpirationDuration}
                      disabled={
                        discountExpiresOption === DISCOUNT_EXPIRES_NEVER
                      }
                      onChange={handleDiscountExpirationDurationChange}
                      placeholder={'1'}
                      className="p-3 mr-2 d-block form-control"
                    />
                  </div>
                  <div className="col-7">
                    <label className="my-3">
                      {listLength > 0 ? billingFrequency : null}
                      {discountExpirationDuration > 1 ? 's' : ''}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {selectedPlanIndex !== listLength &&
            <div className='row border-bottom py-2'>
              <div className='col-12'>
                <div className='row d-flex justify-content-center'>
                  <div className='col-8 d-flex align-items-center'>
                    <h5>Plan</h5>
                  </div>
                  <div className='col-4 d-flex align-items-center'>
                    <h5 className='text-right'>${getDiscountPlanTotalAfterDiscount()}</h5>
                  </div>
                </div>
              </div>
            </div>} */}
        <div className="row border-bottom py-2">
          <div className="col-12">
            <div className="row d-flex justify-content-center">
              <div className="col-8 d-flex align-items-center">
                <h5>Discount</h5>
              </div>
              <div className="col-4 d-flex align-items-center">
                <h5 className="text-right">
                  -{currencySymbol}
                  {Number(discountTotalDollars).toFixed(2)} {getDiscountMath()}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-12">
            <div className="row d-flex justify-content-center">
              <div className="col-7 d-flex align-items-center">
                <h5>
                  {selectedPlanIndex !== listLength
                    ? 'Plan (after discount)'
                    : 'Subtotal (after discount)'}
                </h5>
              </div>
              <div className="col-5 d-flex align-items-center">
                <h3 className="text-right">
                  {selectedPlanIndex !== listLength ? (
                    <strong>
                      {currencySymbol}
                      {getDiscountPlanTotalAfterDiscount()} per{' '}
                      {listLength > 0 ? billingFrequency : null}
                    </strong>
                  ) : (
                    <div>
                      {currencySymbol}
                      {Number(plansSubtotal - discountTotalDollars).toFixed(2)}
                    </div>
                  )}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end align-items-center">
        <Button color="secondary" onClick={toggleAddDiscountModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={
            isNaN(discountValue) ||
            discountValue <= 0 ||
            (discountName && discountName.length > 40) ||
            isNaN(discountExpirationDuration)
          }
          onClick={handleSave}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}
DiscountModal.propTypes = {
  showAddDiscountModal: PropTypes.bool.isRequired,
  subForDiscountModal: PropTypes.object,
  subscriptionCart: PropTypes.array,
  isProrated: PropTypes.bool.isRequired,
  team: PropTypes.object,
  teamName: PropTypes.string,
  activeCommunity: PropTypes.object.isRequired,
  toggleAddDiscountModal: PropTypes.func.isRequired,
  billingFrequency: PropTypes.string.isRequired,
  handleSaveDiscountClicked: PropTypes.func.isRequired,
  listLength: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
}

DiscountModal.displayName = 'DiscountModal'
