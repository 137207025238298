import {
  CREATE_ATTACHMENT,
  UPDATE_ATTACHMENT,
} from '../reduxActions/actionTypes'

export default function(attachments = [], action) {
  switch (action.type) {
    case CREATE_ATTACHMENT:
      return [...attachments, Object.assign({}, action.attachment)]
    case UPDATE_ATTACHMENT:
      return [...attachments, Object.assign({}, action.attachment)]
    default:
      return attachments
  }
}
