/* eslint-disable react/prop-types */
import React from 'react'
import { Route, Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import breadcrumbRoutes from './breadcrumbRoutes'

const findRouteName = url => breadcrumbRoutes[url]

const getPaths = pathname => {
  const paths = ['/']

  // eslint-disable-next-line curly
  if (pathname === '/') return paths

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`
    paths.push(currPath)
    return currPath
  })
  return paths
}

// eslint-disable-next-line no-unused-vars, react/prop-types
const BreadcrumbsItem = ({ match, ...rest }) => {
  const routeName = findRouteName(match.url)
  if (routeName) {
    return match.isExact ? (
      <BreadcrumbItem active>{routeName}</BreadcrumbItem>
    ) : (
        <BreadcrumbItem>
          <Link to={match.url || ''}>{routeName}</Link>
        </BreadcrumbItem>
      )
  }
  return null
}

BreadcrumbsItem.displayName = 'BreadcrumbsItem'

/* eslint-disable max-len, no-unused-vars, react/prop-types, react/no-multi-comp */
const Breadcrumbs = ({ location: { pathname }, match, ...rest }) => {
  const paths = getPaths(pathname)
  // const i = 0;
  const items = paths.map((path, i) => (
    <Route key={i} path={path} component={BreadcrumbsItem} />
  ))
  return <Breadcrumb>{items}</Breadcrumb>
}
/* eslint-enable max-len, no-unused-vars, react/prop-types, react/no-multi-comp */

Breadcrumbs.displayName = 'Breadcrumbs'

// eslint-disable-next-line react/display-name, react/no-multi-comp
export default props => (
  <div>
    <Route path="/:path" component={Breadcrumbs} {...props} />
  </div>
)
