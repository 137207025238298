import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { FETCHING_RESOURCES } from '@global/Constants/FetchingConstants'

export function setResources(resources) {
  return { type: actionTypes.SET_RESOURCES, resources }
}

export function successCreatingResource(resource) {
  return { type: actionTypes.CREATE_RESOURCE, resource }
}

export function successUpdatingResource(resource) {
  return { type: actionTypes.UPDATE_RESOURCE, resource }
}

// ACTION DISPATCHERS
export function getResources(campusId) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_RESOURCES))
    return apiService
      .getResources(campusId)
      .then(response => dispatch(setResources(response.resources)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false, FETCHING_RESOURCES)))
      .catch(err =>
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_RESOURCES))
      )
  }
}

export function createResource(resource) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .createResource(resource)
      .then(resource => dispatch(successCreatingResource(resource)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}

export function createResourceSlides(resource) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .createResourceSlides(resource)
      .then(resource => dispatch(successCreatingResource(resource)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}

export function updateResource(resource) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .updateResource(resource)
      .then(response => dispatch(successUpdatingResource(response.resource)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}
