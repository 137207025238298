import * as actionTypes from './actionTypes'
import * as leadActions from './leadActions'
import * as apiService from '../services/apiService'

// ACTION CREATORS
export function setActiveTab(activeTab) {
  return { type: actionTypes.SET_ACTIVE_TAB, activeTab }
}
export function updateFetchingStatus(isFetching, objectType) {
  return { type: actionTypes.IS_FETCHING, isFetching, objectType }
}
export function updateFetchingReportStatus(isFetchingReport) {
  return { type: actionTypes.IS_FETCHING_REPORT, isFetchingReport }
}
export function updateFetchingLeadTasks(isFetchingLeadTasks) {
  return { type: actionTypes.IS_FETCHING_TASKS, isFetchingLeadTasks }
}
export function updateIsPosting(isPosting) {
  return { type: actionTypes.IS_POSTING, isPosting }
}

export function setActiveContext(activeCommunity, activeCampus) {
  return {
    type: actionTypes.SET_ACTIVE_COMMUNITY,
    activeCommunity,
    activeCampus,
  }
}
export function setActiveCommunity(activeCommunity) {
  return { type: actionTypes.SET_ACTIVE_COMMUNITY, activeCommunity }
}
export function setUserTimezone(timezoneIdentifier) {
  return { type: actionTypes.SET_TIMEZONE_IDENTIFIER, timezoneIdentifier }
}
export function clearCampusReduxData() {
  return { type: actionTypes.CLEAR_CAMPUS_DATA }
}
export function setGroups(groups) {
  return { type: actionTypes.SET_GROUPS, groups }
}
export function SuccessCreatingGroup(group) {
  return { type: actionTypes.ADD_DYNAMIC_GROUP, group }
}
export function SuccessUpdatingGroup(group) {
  return { type: actionTypes.UPDATE_DYNAMIC_GROUP, group }
}
export function SuccessCreatingDayPass(dayPass) {
  return { type: actionTypes.ADD_DAY_PASS, dayPass }
}
export function successUpdatingDayPass(dayPass) {
  return { type: actionTypes.UPDATE_DAY_PASS, dayPass }
}
export function setIsTabletUser(tabletUser) {
  return { type: actionTypes.SET_IS_TABLET_USER, tabletUser }
}
export function setFrontDesk(shouldSet) {
  return { type: actionTypes.SET_FRONT_DESK_LOADED, shouldSet }
}
export function updateCommunityPreference(communityPreference) {
  return { type: actionTypes.UPDATE_COMMUNITY_PREFERENCE, communityPreference }
}
export function setFiltersAction(filters) {
  return { type: actionTypes.SET_FILTERS, filters }
}
export function updateFiltersAction(filters) {
  return { type: actionTypes.UPDATE_FILTERS, filters }
}

export function setActiveCampus(activeCampus) {
  return dispatch => {
    dispatch({ type: actionTypes.SET_ACTIVE_CAMPUS, activeCampus })
    dispatch(updateFilters('leads', { _campusSelected: [activeCampus.id] }))
    dispatch(clearCampusReduxData())
  }
}
export function setIsFetching(isFetching) {
  return dispatch => {
    dispatch(updateFetchingStatus(isFetching))
  }
}
export function setIsFetchingReport(isFetching) {
  return dispatch => {
    dispatch(updateFetchingReportStatus(isFetching))
  }
}

export function setFilters(type, values = {}) {
  return dispatch => {
    dispatch(setFiltersAction({ type, values }))
  }
}
export function updateFilters(type, values = {}) {
  return dispatch => {
    dispatch(updateFiltersAction({ type, values }))
  }
}

export function clearCampusData() {
  return dispatch => {
    dispatch(clearCampusReduxData())
  }
}

export const getGroups = campusId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(updateFetchingStatus(true, 'groups'))
    return apiService
      .getGroups(campusId)
      .then(response => {
        dispatch(setGroups(response.groups))
        resolve(response.groups)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() => dispatch(updateFetchingStatus(false, 'groups')))
  })
}

export const getTagsForGroup = params => dispatch => {
  dispatch(updateFetchingStatus(true, 'groups_tags'))
  return new Promise((resolve, reject) => {
    apiService
      .getTagsForGroup(params)
      .then(response => resolve(response))
      .catch(err => {
        reject(err)
      })
      .finally(() => updateFetchingStatus(false, 'groups_tags'))
  })
}

export const createGroup = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .createGroup(params)
      .then(response => {
        dispatch(SuccessCreatingGroup(response.group))
        resolve(response.group)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const updateGroup = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .updateGroup(params)
      .then(response => {
        dispatch(SuccessUpdatingGroup(response.group))
        resolve(response.group)
      })
      .catch(err => {
        reject(err)
      })
  })
}
