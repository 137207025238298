import {
  CREATE_BOOKING,
  REGISTER_COMMUNITY,
  SET_BOOKINGS,
  UPDATE_BOOKING,
  CREATE_TABLET_KEY,
  CLEAR_CAMPUS_DATA,
  CLEAR_BOOKINGS,
} from '../reduxActions/actionTypes'
import { arrayMerge } from './reducerConstants'
import initialState from './initialState'

export default function (bookings = initialState.bookings, action) {
  const bookingsArray = bookings.bookings ? bookings.bookings : []

  switch (action.type) {
    case CREATE_BOOKING:
      return {
        ...bookings,
        bookings:
          bookingsArray && bookingsArray.length > 0
            ? [...bookingsArray, Object.assign({}, action.booking)]
            : [Object.assign({}, action.booking)],
        meta: action.meta,
      }
    case REGISTER_COMMUNITY:
      return {
        ...bookings,
        bookings: action.booking,
        meta: action.meta,
      }
    case SET_BOOKINGS: {
      return {
        ...bookings,
        bookings:
          bookingsArray && bookingsArray.length > 0
            ? arrayMerge(bookingsArray, action.bookings)
            : action.bookings,
        meta: action.meta,
      }
    }
    case UPDATE_BOOKING: {
      let booking = []
      if (action.booking.is_archived) {
        booking = bookingsArray.filter(
          booking => booking.id !== action.booking.id
        )
        return {
          ...bookings,
          bookings: booking,
          meta: action.meta,
        }
      }
      booking = bookingsArray.map(oldBooking => {
        if (oldBooking.id !== action.booking.id) {
          return oldBooking
        }
        return { ...oldBooking, ...action.booking }
      })
      return {
        ...bookings,
        bookings: booking,
        meta: action.meta,
      }
    }
    case CREATE_TABLET_KEY:
      return {
        ...bookings,
        tabletKey: action.tabletKey,
      }
    case CLEAR_CAMPUS_DATA:
    case CLEAR_BOOKINGS:
      return initialState.bookings
    default:
      return bookings
  }
}
