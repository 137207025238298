import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import styled from 'styled-components'
import { ThemeProvider, withTheme } from 'styled-components'
import colors from '@global/Colors/colors'
import defaultCompanyPic from '../../../../img/placeholders/otto_black_white.jpeg'
import Aside from './Aside'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: relative;
`
const ImageContainer = styled.div``
const Image = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
`
const TitleContainer = styled.div`
  padding: 20px 0 30px;
`
const Title = styled.h2``
const ContentContainer = styled.div`
  border-top: 1px solid gray;
  padding: 20px 0 30px;
`
const ContentHeader = styled.p`
  font-weight: bold;
`
const ContentBody = styled.p``
const ContentAction = styled.div``
const ButtonInLine = styled.button`
  padding: 0;
`
const AsideStyled = styled(Aside)`
  @media (min-width: 1024px) {
    display: none;
  }
`

const Main = ({ onBook, room, ...other }) => {
  const { type, name, description, capacity } = room
  return (
    <Container {...other}>
      <ImageContainer>
        <Image src={defaultCompanyPic} />
      </ImageContainer>
      <TitleContainer>
        <Title>{name}</Title>
      </TitleContainer>
      <AsideStyled onBook={onBook} />
      <ContentContainer>
        <ContentHeader>{type}</ContentHeader>
        <ContentBody>{description}</ContentBody>
        <ContentAction>
          <ButtonInLine className="btn btn-link btn-sm" onClick={() => null}>
            Contact Us
          </ButtonInLine>
        </ContentAction>
      </ContentContainer>
      <ContentContainer>
        <ContentHeader>welp</ContentHeader>
        <ContentBody>WELP: welp</ContentBody>
      </ContentContainer>
      <ContentContainer>
        <ContentHeader>welp</ContentHeader>
        <ContentBody>WELP: welp</ContentBody>
      </ContentContainer>
    </Container>
  )
}

Main.propTypes = {
  onBook: PropTypes.func,
  room: PropTypes.object,
}
Main.displayName = 'Main'

export default Main
