import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'

export function successUpdatingCampus(campus) {
  return { type: actionTypes.UPDATE_CAMPUS, campus }
}
export function successCreatingCampus(campus) {
  return { type: actionTypes.CREATE_CAMPUS, campus }
}

export const updateCampus = campus => dispatch => {
  return new Promise(async (resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateCampus(campus)
      .then(response => {
        dispatch(successUpdatingCampus(response.campus))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() => dispatch(uiActions.updateFetchingStatus(false)))
  })
}

export const createCampus = campus => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createCampus(campus)
      .then(response => {
        dispatch(successCreatingCampus(response.campus))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() => dispatch(uiActions.updateFetchingStatus(false)))
  })
}