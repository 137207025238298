/* eslint-disable no-invalid-this */
import React, { useState, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { format, isAfter } from 'date-fns' //todo what?
// import { isSameDay,  } from 'date-fns'
import * as _ from 'lodash'
import Spinner from '@global/Spinner'
import {
  UncontrolledDropdown,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from 'reactstrap'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'

import NotificationToggle from '@tippyjs/react/headless'

import * as Constants from '@global/Constants'
import { resolveDomainPrefix } from '@global/Constants'

import * as uiActions from '@app/reduxActions/uiActions'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import defaultUserPic from '@app/img/placeholders/otto_black_white.jpeg'
import RoundedImage from '@global/RoundedImage'
import { LabelStyled } from '@global/Form/FormComponents'
import CampusDropdown from '@global/Header/CampusDropdown'

import NotificationListItem from '../Notification/NotificationListItem'
import NotificationMenu from '../Notification/NotificationMenu'
import NotificationBell from '../Notification/NotificationBell'
import useInterval from './useInterval'
import HeaderIconButton from './HeaderIconButton'

const TextPrimaryStyled = styled.span`
  color: ${props => props.theme.colorPrimary}!important;
`

const DropdownElementStyled = styled.div`
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid #cfd8dc;
`

const NOTIFICATIONS_RESET_TIMER = 1000 * 60 * 5

const ALLOWED_NOTIFICATION_TYPES = [
  'NotificationMembershipRequest',
  'NotificationMembershipSignup',
  'NotificationTourRequest',
  'NotificationBookingOverage',
  'NotificationAnnouncement',
  'NotificationLeadFollowUp',
  'NotificationVisitor',
  'NotificationExternalBooking',
  'NotificationDayPass',
]

function Header({
  history,
  user,
  theme,
  activeCampus,
  activeCommunity,
  ui_actions,
  notificationCount,
  lastClearNotificationsDate,
  pagination,
  notifications,
  user_actions,
}) {
  if (!activeCampus) {
    history.push('/login')
  }
  const [loadingNotifications, setLoadingNotifications] = useState(false)
  const [clearingNotifications, setClearingNotifications] = useState(false)
  const [perPage, setPerPage] = useState(Constants.PER_PAGE_DEFAULT)
  const [instance, setInstance] = useState(null)
  const [notificationsVisible, setNotificationsVisibility] = useState(false)

  const handleNotificationsVisibleChange = visible => {
    if (
      !notificationsVisible &&
      notificationCount > 0 &&
      !clearingNotifications
    ) {
      refreshNotifications(() => clearNotificationCount())
    }
    setNotificationsVisibility(visible)
  }

  const getNotifications = useCallback(() => {
    setLoadingNotifications(true)
    user_actions
      .getNotifications(user.id, {
        campus_id: activeCampus.id,
        page: pagination ? pagination.page : 0,
        per_page: perPage,
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setLoadingNotifications(false)
      })
  }, [])

  useEffect(() => {
    document.body.classList.toggle('aside-menu-hidden')
    getNotifications()
  }, [activeCampus.id, getNotifications])

  useInterval(() => {
    getNotifications()
  }, NOTIFICATIONS_RESET_TIMER)

  function sidebarMinimize(event) {
    event.preventDefault()
    document.body.classList.toggle('sidebar-minimized')
  }

  function mobileSidebarToggle(event) {
    event.preventDefault()
    document.body.classList.toggle('sidebar-mobile-show')
  }

  const logout = () => {
    history.push('/login')
  }
  const changeCampus = campus => {
    ui_actions.setActiveCampus(campus)
  }

  const generateHomeLink = () => {
    if (user && user.type === 'Member') {
      return '/calendar'
    }
    return '/dashboard'
  }
  const nextPage = () => {
    if (
      pagination &&
      notifications &&
      pagination.page < pagination.total_pages &&
      notifications.length < pagination.total_objects
    ) {
      getNotifications()
    }
  }
  const clearNotificationCount = () => {
    setClearingNotifications(true)
    user_actions
      .clearNotificationCount(user.id, {
        campus_id: activeCampus.id,
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setClearingNotifications(false)
        setLoadingNotifications(false)
      })
  }
  const refreshNotifications = callback => {
    setLoadingNotifications(true)
    user_actions
      .getNotifications(
        user.id,
        {
          campus_id: activeCampus.id,
          page: Constants.START_PAGE_DEFAULT,
          per_page: Constants.PER_PAGE_DEFAULT,
        },
        true
      )
      .then(res => {
        if (callback) callback()
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setLoadingNotifications(false))
  }

  const markNotificationsAsSeen = () => {
    setLoadingNotifications(true)
    user_actions
      .markNotificationsAsSeen(
        user.id,
        {
          campus_id: activeCampus.id,
        },
        true
      )
      .catch(err => {
        console.error(err)
      })
      .finally(() => setLoadingNotifications(false))
  }

  const notificationClicked = (notificationId, callback) => {
    instance.hide()
    callback()

    user_actions
      .updateNotification(notificationId, { read: true })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setLoadingNotifications(false)
      })
  }

  // eslint-disable-next-line react/no-multi-comp, react/display-name
  function renderNotification(notification, index, length) {
    if (
      !notification ||
      !ALLOWED_NOTIFICATION_TYPES.includes(notification.type)
    )
      return null
    let route = ''
    let routeState = {}
    let icon = ''
    if (
      notification.type === 'NotificationMembershipRequest' ||
      notification.type === 'NotificationTourRequest' ||
      notification.type === 'NotificationLeadFollowUp'
    ) {
      route = `/crm/leads/${notification.contact.id}`
      routeState = {
        lead: notification.contact,
      }
      icon = 'user-plus'
      if (notification.type === 'NotificationLeadFollowUp') {
        // lead followup reminders have a different notification, but same action
        icon = 'clock'
      }
    } else if (notification.type === 'NotificationMembershipSignup') {
      if (notification.team) {
        route = `/directory/teams/${notification.team.id}/profile`
        routeState = { team: notification.team }
      } else {
        route = `/directory/teams`
      }
      icon = 'user-plus'
    } else if (notification.type === 'NotificationBookingOverage') {
      if (notification.team) {
        route = `/directory/teams/${notification.team.id}/profile`
        routeState = { team: notification.team }
      } else {
        route = `/directory/teams`
      }
      icon = 'calendar-alt'
    } else if (notification.type === 'NotificationAnnouncement') {
      route = `/announcements`
      // routeState = { announcementId: notification.id } //todo api needs to be added here I think
      icon = 'envelope'
    } else if (notification.type === 'NotificationExternalBooking') {
      route = `/calendar`
      if (notification.booking && notification.booking.start_time) {
        //this is probably not necessary
        routeState = {
          returnToDate: notification.booking.start_time,
          event: notification.booking,
        }
      }
      icon = 'calendar-alt'
    } else if (notification.type === 'NotificationDayPass') {
      //TODO fallback for if no invoice? check for user or lead?
      route = `/billing/invoices/${notification.invoice_id}`
      icon = 'user-plus'
    }

    return (
      <NotificationListItem
        notification={notification}
        route={route}
        routeState={routeState}
        icon={icon}
        onClick={notificationClicked}
        lastClearDate={lastClearNotificationsDate}
        history={history}
        badgeComponent={
          notification.contact
            ? Constants.getGeneratedFromBadge(notification.contact.created_by)
            : null
        }
        showBottomBorder={index === length - 1}
      />
    )
  }
  // eslint-disable-next-line react/no-multi-comp, react/display-name
  function renderNotifications(notifications) {
    if (!notifications) return null
    return (
      <PaginatedTable
        objects={notifications}
        type={'notifications'}
        icon={'bell'}
        placeholderSubtext1={'They will show up here.'}
        nextPage={nextPage}
        isLoading={loadingNotifications}
        containerClassName={null}
        wrapperClassName={null}
        ancestor={null}
        renderItem={(contact, index, length) =>
          renderNotification(contact, index, length)
        }
      />
    )
  }
  // eslint-disable-next-line react/no-multi-comp, react/display-name
  const renderCommunityChanger = () => {
    if (user.type === 'SuperAdmin') {
      return (
        <DropdownItem onClick={() => history.push('/change-campus')}>
          (Admin) Change Communities
        </DropdownItem>
      )
    }
    return null
  }
  // eslint-disable-next-line react/no-multi-comp, react/display-name
  const renderClearData = () => {
    if (user.type === 'SuperAdmin') {
      return (
        <DropdownItem onClick={ui_actions.clearCampusData}>
          (Admin) Clear Campus Data
        </DropdownItem>
      )
    }
    return null
  }
  const handleClickNotificationsSettings = () => {
    instance.hide()
    history.push('/preferences/notifications')
  }
  const handleClickCampusSettings = () => {
    history.push(`/community/campuses/${activeCampus.id}/settings`, {
      campus: activeCampus,
    })
  }
  const handleClickArticles = () => {
    window.open('https://help.coworksapp.com', '_blank')
  }

  return (
    <header className="app-header navbar">
      <button
        className="navbar-toggler mobile-sidebar-toggler d-lg-none"
        type="button"
        onClick={mobileSidebarToggle}
      >
        &#9776;
      </button>
      <NavLink className="navbar-brand" to={generateHomeLink()} />
      <ul className="nav navbar-nav d-md-down-none mr-auto">
        <li className="nav-item">
          <button
            className="nav-link navbar-toggler sidebar-toggler"
            type="button"
            onClick={sidebarMinimize}
          >
            &#9776;
          </button>
        </li>
      </ul>

      <ul className="nav navbar-nav  mr-auto">
        <li className="nav-item">
          <CampusDropdown
            campus={activeCampus}
            community={activeCommunity}
            onChange={changeCampus}
            dropdownHeader={'Campuses'}
          />
        </li>
      </ul>
      <HeaderIconButton
        size="medium"
        color="neutral-4"
        icon={['fal', 'cog']}
        onClick={handleClickCampusSettings}
      />
      <HeaderIconButton
        size="medium"
        color="neutral-4"
        icon={['fal', 'question-circle']}
        onClick={handleClickArticles}
      />
      <NotificationToggle
        render={attrs => (
          <NotificationMenu
            handleClickRefresh={refreshNotifications}
            handleClickMarkAllRead={markNotificationsAsSeen}
            handleClickSettings={handleClickNotificationsSettings}
            {...attrs}
          >
            {notificationsVisible && renderNotifications(notifications)}
          </NotificationMenu>
        )}
        trigger="click"
        interactive
        placement="bottom-end"
        offset={[0, 10]}
        onCreate={setInstance}
        onShow={() => handleNotificationsVisibleChange(true)}
        onHide={() => handleNotificationsVisibleChange(false)}
      >
        <span>
          <NotificationBell
            dropdownVisible={notificationsVisible}
            unread={notificationCount}
          />
        </span>
      </NotificationToggle>

      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <RoundedImage
            className="mr-2"
            photo={user.user_photo ? user.user_photo.file_url : defaultUserPic}
            alt="Picture"
          />
          {`${user.first_name} ${user.last_name}`}
        </DropdownToggle>
        <DropdownMenu right style={{ whiteSpace: 'normal' }}>
          <DropdownElementStyled>
            <LabelStyled>Signed in as:</LabelStyled>
            <div>{user.email}</div>
          </DropdownElementStyled>
          <DropdownItem onClick={() => history.push('/my-profile')}>
            My Profile
          </DropdownItem>
          <DropdownItem onClick={() => history.push('/preferences')}>
            Preferences
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              const baseUrl = resolveDomainPrefix(
                activeCommunity.community_preference
              )
              window.open(`${baseUrl}/tablet-login`)
            }}
          >
            <FontAwesomeIcon icon={'external-link-alt'} /> Go to Tablet Login
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              window.open('https://help.coworksapp.com', '_blank')
            }}
          >
            <FontAwesomeIcon icon={'external-link-alt'} /> Support Articles
          </DropdownItem>
          {renderCommunityChanger()}
          {renderClearData()}
          <DropdownItem onClick={logout}>
            <FontAwesomeIcon icon="lock" /> Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </header>
  )
}

Header.displayName = 'Header'

Header.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  theme: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  ui_actions: PropTypes.object,
  notificationCount: PropTypes.number,
  notifications: PropTypes.array,
  lastClearNotificationsDate: PropTypes.string,
  pagination: PropTypes.object,
  user_actions: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    user: state.user,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    notificationCount:
      state.ui.notifications && state.ui.notifications.notificationCount
        ? state.ui.notifications.notificationCount
        : null,
    notifications:
      state.ui.notifications && state.ui.notifications.notifications
        ? state.ui.notifications.notifications
        : null,
    lastClearNotificationsDate: state.ui.notifications
      ? state.ui.notifications.lastClearNotificationsDate
      : null,
    pagination:
      state.ui.notifications && state.ui.notifications.meta
        ? state.ui.notifications.meta.pagination
        : null,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
