import {
  SET_NETWORK_MEMBERS,
  CLEAR_NETWORK_MEMBERS,
} from '../reduxActions/actionTypes'
import { arrayMerge, sortByMemberName } from './reducerConstants'

import initialState from './initialState'

export default function (network = initialState.network, action) {
  switch (action.type) {
    case SET_NETWORK_MEMBERS:
      if (network.members.length > 0) {
        const oldMembers = network.members
        const newMembers = action.members
        return {
          ...network,
          members: arrayMerge(oldMembers, newMembers),
          meta: action.meta,
        }
      }
      return {
        ...network,
        members: sortByMemberName(action.members),
        meta: action.meta,
      }
    case CLEAR_NETWORK_MEMBERS:
      return initialState
    default:
      return network
  }
}
