/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Button, Row, TabPane } from 'reactstrap'
import { MenuButton, TextButton } from '@global/Base/Button/ButtonStyled'
import { Switch, Route, Redirect } from 'react-router-dom'
import classnames from 'classnames'
import CardComponent from '@global/CardComponent'
import styled from 'styled-components'
import * as _ from 'lodash'
import { useField, Form, FormikProps, Formik } from 'formik'
import * as Yup from 'yup'
import {
  CustomInputComponentWithHooks,
  LabelStyled,
} from '@global/Form/FormComponents'
import ButtonStyled from '@global/Base/Button/ButtonStyled'
import * as apiService from '@app/services/apiService'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import {
  activateIntegration,
  deactivateIntegration,
  setPendingIntegration,
  setInitialIntegrationState,
  handleActiveIntegrations,
} from '@reduxActions/integrationActions'
import CardRowComponent from './CardRowComponent'

const Section = styled(Row)`
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 18px;
`
const MenuButtonStyled = styled(MenuButton)`
  margin-bottom: 0.5rem;
`
const TextRed = styled.div`
  color: red;
`
const TextButtonStyled = styled(TextButton)`
  margin: 0px;
  padding: 0px;
`
export default function Integrations({
  history,
  match,
  activeCommunity,
  theme,
}) {
  const [error, setError] = useState(null)
  const [tabs, setTabs] = useState([
    {
      name: 'Available',
      path: `/community/integrations/all`,
      icon: 'search',
    },
    {
      name: 'Active',
      path: `/community/integrations/active`,
      icon: 'check-circle',
    },
  ])

  const pendingIntegrations = useSelector(state =>
    state.integrations && state.integrations.pending
      ? state.integrations.pending
      : []
  )
  const activeIntegrations = useSelector(state =>
    state.integrations && state.integrations.active
      ? state.integrations.active
      : []
  )
  const inactiveIntegrations = useSelector(state =>
    state.integrations && state.integrations.inactive
      ? state.integrations.inactive
      : []
  )
  const integrations = useSelector(state => state.integrations)
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !integrations ||
      (integrations &&
        (!integrations.pending ||
          !integrations.active ||
          !integrations.inactive))
    ) {
      dispatch(setInitialIntegrationState())
      dispatch(handleActiveIntegrations(activeCommunity.active_integrations))
    }
    if (
      activeIntegrations &&
      activeIntegrations.length === 0 &&
      pendingIntegrations.length === 0 &&
      history.location.pathname === `${match.url}/integrations`
    ) {
      if (`${match.url}/integrations/all` !== history.location.pathname)
        history.push(`/community/integrations/all`)
    }
  }, [])

  return (
    <div id="integrations" className="animated fadeIn">
      <div className="col-md-12">
        <div className="row border-bottom-1 my-3">
          <div className="col-10">
            <h2>Integrations</h2>
          </div>
        </div>
      </div>
      <Row>
        <div className="col-md-2 col-12">
          {tabs.map(tab => (
            <Row key={tab.name}>
              <div className="col-12">
                <MenuButtonStyled
                  showBackground
                  className={classnames({
                    active: history.location.pathname.includes(tab.path),
                  })}
                  onClick={() => {
                    toggleTab(tab.path, history)
                  }}
                >
                  {tab.name}
                </MenuButtonStyled>
              </div>
            </Row>
          ))}
        </div>
        <div className="col-md-10 col-12">
          <Switch>
            <Route exact path={`/community/integrations`}>
              <Redirect to={`/community/integrations/active`} />
            </Route>
            <Route path={`/community/integrations/active`}>
              <TabPane>
                {/* actions=[
                      {
                        className: 'btn btn-outline-primary',
                        text: 'Deactivate',
                        size: 'sm',
                        onClick: () => console.log('sleep'),
                      },
                    ]} */}
                {activeIntegrations.length === 0 &&
                pendingIntegrations.length === 0 ? (
                  <div>
                    <p>
                      Looks like you have no active integrations!{'  '}
                      <TextButton
                        inline
                        onClick={() =>
                          history.push('/community/integrations/all')
                        }
                      >
                        Discover Available
                      </TextButton>
                    </p>
                  </div>
                ) : null}
                {activeIntegrations.map((integration, index) => (
                  <CardRowComponent
                    key={integration.type}
                    header={integration.type}
                    sub={`Api Key ${integration.api_key}`}
                    actions={[]}
                    bottomRow={
                      <p>
                        Active{' '}
                        <FontAwesomeIcon
                          icon={'check-circle'}
                          color={theme ? theme.colorSuccess : '#4dbd74'}
                        />
                      </p>
                    }
                  />
                ))}
                {pendingIntegrations.map((integration, index) => {
                  return (
                    <Row key={integration.name}>
                      <div className="col">
                        <Row>
                          <div className="col-12">
                            <h4>{integration.name}</h4>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-12">
                            <Formik
                              initialValues={{
                                apiKey: '',
                                type: integration.type,
                                section: integration.section_internal_name,
                              }}
                              validationSchema={Yup.object().shape({
                                apiKey: Yup.string().required(
                                  'Api Key is required'
                                ),
                              })}
                              onSubmit={(values, actions) => {
                                dispatch(
                                  activateIntegration({
                                    type: values.type,
                                    section: values.section,
                                    api_key: values.apiKey,
                                    community_id: activeCommunity.id,
                                  })
                                )
                                // actions.setSubmitting(false)
                              }}
                            >
                              {({ isSubmitting }) => (
                                <Form className="w-50">
                                  <CustomInputComponentWithHooks
                                    name="apiKey"
                                    label="Api Key"
                                    description="You can find this api key in the Kisi Dashboard"
                                  />
                                  {integration.type === 'kisi' ? ( // sorry for doing this :)
                                    <TextButtonStyled
                                      onClick={() => {
                                        window.open(
                                          integration.activation_help_article,
                                          '_blank'
                                        )
                                      }}
                                    >
                                      Where do I find this?
                                    </TextButtonStyled>
                                  ) : null}

                                  {error && (
                                    <TextRed className="my-2 p-2">
                                      {error}
                                    </TextRed>
                                  )}

                                  <div className="d-flex align-items-center justify-content-end">
                                    <ButtonStyled
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting && (
                                        <React.Fragment>
                                          <FontAwesomeIcon
                                            icon={'circle-notch'}
                                            spin
                                          />{' '}
                                        </React.Fragment>
                                      )}
                                      Activate
                                    </ButtonStyled>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </Row>
                      </div>
                    </Row>
                  )
                })}
              </TabPane>
            </Route>
            <Route path={`/community/integrations/all`}>
              <TabPane>
                {inactiveIntegrations.map((section, sectionIndex) => {
                  if (
                    section.integrations.filter(
                      integration => !integration.active
                    ).length === 0
                  ) {
                    {
                      /* dont show empty sections */
                    }
                    return null
                  }
                  return (
                    <React.Fragment key={`${section.name}${sectionIndex}`}>
                      <Section>{section.name}</Section>
                      <Row>
                        {section.integrations
                          .filter(integration => !integration.active)
                          .map((integration, index) =>
                            CardComponent({
                              key: integration.id,
                              name: integration.name
                                ? integration.name
                                : 'No name',
                              alt: integration.name
                                ? integration.name
                                : 'Image',
                              editClickHandler: () => {
                                if (integration.externalUrl) {
                                  window.open(integration.externalUrl, '_blank')
                                } else {
                                  dispatch(
                                    setPendingIntegration(
                                      inactiveIntegrations[sectionIndex]
                                        .integrations[index]
                                    )
                                  )
                                  history.push('/community/integrations/active')
                                }
                              },
                              item: integration,
                              imageUrl: integration.photo,
                              actionButtonText: integration.actionButtonText,
                              cardSize: 4,
                              detail: integration.description
                                ? integration.description
                                : 'No description',
                            })
                          )}
                      </Row>
                    </React.Fragment>
                  )
                })}
              </TabPane>
            </Route>
          </Switch>
        </div>
      </Row>
    </div>
  )
}

function toggleTab(newTab, history) {
  history.replace(newTab)
}

Integrations.displayName = 'Integrations'
