/* eslint-disable max-statements */
/* eslint-disable react/prop-types */
/* eslint-disable max-depth,react/display-name,react/no-multi-comp */

// CONSTANTS INDEX FILE

import React from 'react'
import CustomTagDropdown from '@global/CustomTagDropdown'
import Tooltip from '@bit/coworks.base-components.tooltip'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as PaymentConstants from '@global/Constants/PaymentConstants'

import bookingColors from './lists/bookingColors'
import currencies from './lists/currencies'
import timezones from './lists/timezones'
import countries from './lists/countries'
import billDates from './lists/billingDates'
import templates from './lists/announcementTemplates'

import { Button } from 'reactstrap'
import BadgeV2 from '@bit/coworks.base-components.badge'

import moment from 'moment'
import * as _ from 'lodash'

export const PER_PAGE_DEFAULT = 15
export const START_PAGE_DEFAULT = 0

export const EXTERNAL_BOOKING_TIME_INTERVAL = 30

// lists
export { bookingColors, currencies, timezones, countries, billDates, templates }

export const isEmpty = obj => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}
export const stripNullString = object => {
  for (const property in object) {
    if (object.hasOwnProperty(property)) {
      // eslint-disable-next-line no-param-reassign
      if (object[property] === 'null' || object[property] === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        delete object[property]
      }
    }
  }
  return object
}
export const stripPhotoObjects = object => {
  // strip the photos
  const values = _.cloneDeep(object)
  Object.keys(object).forEach(key => {
    if (
      values[key] &&
      values[key] instanceof Object &&
      (key.includes('_placeholder') || key.includes('whitelabel_background_'))
    ) {
      delete values[key]
    }
  })
  return values
}
// get the difference between two objects
export function difference(object, base) {
  function changes(object, base) {
    // eslint-disable-next-line func-names
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        // eslint-disable-next-line no-param-reassign
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value
      }
    })
  }
  return changes(object, base)
}

export function logM(message) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    if (typeof message === 'string') {
      console.log(`[DEV] ${message}`)
    } else if (typeof message === 'object') {
      console.log('[DEV]', message)
    }
  }
}
export function logO(message, object) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(`[DEV] ${message}`, object)
  }
}

/* CURRENCIES */
export function getCurrencyNameFromSymbol(currencySymbol) {
  // $ -> 'USD'
  for (const arrayItem of currencies) {
    if (arrayItem.symbol === currencySymbol) {
      return arrayItem.name
    }
  }
  return null
}
export function getCurrencyLabelFromName(currencyName) {
  // 'USD' -> 'USD - United States Dollar'
  for (const arrayItem of currencies) {
    if (arrayItem.name.toLowerCase() === currencyName.toLowerCase()) {
      return arrayItem.label
    }
  }
  return null
}
export function getCurrencySymbolFromName(currencyName) {
  // 'USD' -> '$'
  if (!currencyName) {
    console.warn('Not provided a currency name in [getCurrencySymbolFromName]')
    return null
  }
  for (const arrayItem of currencies) {
    if (arrayItem.name.toLowerCase() === currencyName.toLowerCase()) {
      return arrayItem.symbol
    }
  }
  return null
}
export function getCurrencySymbolFromCommunity(community) {
  if (
    community &&
    community.community_preference &&
    community.community_preference.currency
  ) {
    return getCurrencySymbolFromName(community.community_preference.currency)
  }
  return null
}

/* BILLING DATES */
export function getBillDateValueFromName(billDates, billDateName) {
  for (const arrayItem of billDates) {
    if (arrayItem.name === billDateName) {
      return arrayItem.value
    }
  }
  return null
}
export function getBillDateNameFromValue(billDates, billDateValue) {
  for (const arrayItem of billDates) {
    if (arrayItem.value === billDateValue) {
      return arrayItem.name
    }
  }
  return null
}
export function getBillDateName(billDateValue) {
  for (const arrayItem of this.billDates) {
    if (arrayItem.value === billDateValue) {
      return arrayItem.name
    }
  }
  return null
}

/* COUNTRIES */
export function convertCountryCodeToName(code, countries) {
  for (const item in countries) {
    if (countries.hasOwnProperty(item) && countries[item] !== '') {
      if (countries[item].code === code) {
        return countries[item].name
      }
    }
  }
  return null
}

export function validateEmail(email) {
  /* eslint-disable max-len */
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

/* BILLING / STRIPE CONSTANTS / HELPERS */
export function parseStripeSubscriptions(
  stripeCustomer,
  plans,
  convertToDollars = true
) {
  // A helper function to map through the stripe subscriptions and match up coworks plans
  // convert to dollars will mutate the subscription's values to dollars....
  //    everywhere is using this so I don't want to "remove" the conversion, but added
  //    flag to make it backwards compatible
  if (
    !stripeCustomer ||
    !stripeCustomer.subscriptions ||
    !stripeCustomer.subscriptions.data
  ) {
    return null
  }
  // deep copy first
  const stripeSubscriptions = JSON.parse(
    JSON.stringify(stripeCustomer.subscriptions.data)
  )
  const subscriptions = []
  let indexKey = 0
  // Iterate through the stripe subscriptions
  for (
    let subCounter = 0;
    subCounter < stripeSubscriptions.length;
    subCounter++
  ) {
    // push subscriptions to this.state.stripeSubscriptions
    subscriptions.push(stripeSubscriptions[subCounter])
    // Iterate through the stripe plans of each stripe subscription
    for (
      let planCounter = 0;
      planCounter < stripeSubscriptions[subCounter].items.data.length;
      planCounter++
    ) {
      // try to match up coworks plans with stripe plans

      //todo consider amountInDollars instead of manipulating Stripe! causes a lot of pain further down the chain....
      subscriptions[subCounter].items.data[planCounter].plan.amount /= 100 // we do this so that we're dealing with the same currencies with coworks plans.

      for (let mapCounter = 0; mapCounter < plans.length; mapCounter++) {
        // eslint-disable-next-line radix
        if (
          plans[mapCounter].id ===
          parseInt(
            subscriptions[subCounter].items.data[planCounter].plan.id,
            10
          )
        ) {
          // to avoid state mutations with the division below
          subscriptions[subCounter].items.data[
            planCounter
          ].plan = Object.assign(
            {},
            subscriptions[subCounter].items.data[planCounter].plan
          )
          // assign this.state.stripeSubscriptions.plan[x].coworksPlan to the corresponding coworks plan.
          subscriptions[subCounter].items.data[planCounter].plan.coworksPlan =
            plans[mapCounter]
          subscriptions[subCounter].items.data[planCounter].plan.uiState = {
            isEditing: false,
            calculatedValue: 0, // the value of the discount / flex
            hideOnInvoice: false,
            shouldShowRemovePlan: true,
          }
          subscriptions[subCounter].items.data[
            planCounter
          ].plan.uiState.indexKey = indexKey
          if (subscriptions[subCounter].items.total_count > 1) {
            subscriptions[subCounter].items.data[
              planCounter
            ].plan.uiState.shouldShowRemovePlan = true
          } else {
            subscriptions[subCounter].items.data[
              planCounter
            ].plan.uiState.shouldShowRemovePlan = false
          }
          indexKey++
          break
        }
      }
    }
  }
  return subscriptions
}

export function mapCoworksPlanFromStripePlan(subscriptionItem) {
  // this function will map through stripe subscription items (usually plans) and
  // append any necessary coworks data this function expects the coworks plan to be
  // appended to the actual stripe subscription item
  // at subscription.plan.coworksPlan

  // If it is missing a coworks plan, the all the data is taken from stripe instead.
  const mappedPlan = {}
  const coworksPlan = subscriptionItem.plan.coworksPlan
  mappedPlan.cost = subscriptionItem.plan.amount
  mappedPlan.frequency = subscriptionItem.plan.interval
  mappedPlan.quantity = subscriptionItem.quantity
  mappedPlan.indexKey = subscriptionItem.indexKey // for contextual rendering
  mappedPlan.subscriptionItemId = subscriptionItem.id
  if (coworksPlan) {
    mappedPlan.name = coworksPlan.name
    mappedPlan.id = coworksPlan.id
    mappedPlan.created_at = coworksPlan.created_at
    mappedPlan.currency_units = coworksPlan.currency_units
    mappedPlan.description = coworksPlan.description
    mappedPlan.is_free = coworksPlan.is_free
    mappedPlan.plan_photo = coworksPlan.plan_photo
    mappedPlan.plan_photo_url = coworksPlan.plan_photo_url
    mappedPlan.quantity_available = coworksPlan.quantity_available
    mappedPlan.frequency = coworksPlan.frequency
    //mappedPlan.recurring = coworksPlan.recurring;
    //mappedPlan.is_perk = coworksPlan.is_perk;
    mappedPlan.updated_at = coworksPlan.updated_at
  } else {
    mappedPlan.name = subscriptionItem.plan.nickname
    mappedPlan.id = subscriptionItem.plan.id
    mappedPlan.created_at = subscriptionItem.created
    mappedPlan.currency_units = subscriptionItem.plan.currency
    mappedPlan.description = ''
    mappedPlan.is_free = Boolean(subscriptionItem.plan.amount === 0)
    mappedPlan.plan_photo = null
    mappedPlan.plan_photo_url = null
    mappedPlan.quantity_available = null
    //mappedPlan.recurring = null
    //mappedPlan.is_perk = false;
    mappedPlan.updated_at = null
  }
  return mappedPlan
}

export function calculateSubscriptionTotal(stripeSubscription) {
  // Iterates through the stripe plans of each stripe subscription and calculate the subscription cost
  let total = 0
  for (
    let planCounter = 0;
    planCounter < stripeSubscription.items.data.length;
    planCounter++
  ) {
    // todo the subscriptiont total might have been /100 by the time it gets here
    total +=
      stripeSubscription.items.data[planCounter].plan.amount *
      stripeSubscription.items.data[planCounter].quantity
  }
  if (stripeSubscription.discount && stripeSubscription.discount.coupon) {
    // todo the subscriptiont total might have been /100 by the time it gets here
    const coupon = stripeSubscription.discount.coupon
    if (coupon.amount_off) {
      total -= coupon.amount_off
    } else if (coupon.percent_off) {
      total -= total * (coupon.percent_off / 100)
    }
  }
  return total
}

export function calculateCoworksPlansTotal(plans) {
  if (!plans || plans.length === 0) {
    return 0
  }
  let total = 0
  for (let planCounter = 0; planCounter < plans.length; planCounter++) {
    const plan = plans[planCounter]
    // eslint-disable-next-line radix
    total += parseInt(plan.quantity) * parseInt(plan.cost)
  }
  return total
}

export function initializeEmptyCartObject(isPerk) {
  // This function initializes an empty cart object for the subscription Cart
  return {
    plan: null,
    uiState: {
      isPerk: isPerk,
      value: 0,
      quantity: 1,
    },
    totalCost: 0,
    discount: {},
  }
}

export function planExistsInCart(selectedPlan, subscriptionCart) {
  // This function checks to see if a coworks plan already exists in an array of subsctions objects
  // to create into a subscription.
  for (let counter = 0; counter < subscriptionCart.length; counter++) {
    if (subscriptionCart[counter].plan.id === selectedPlan.id) {
      return true
    }
  }
  return false
}

export function calculatePlanTotalsWithQuantities(cartCopy) {
  // this function will map through a cart and calculate the totals of the plans
  // taking into account the quantities for those plans.
  // expects the format presented in the function initializeEmptyCartObject
  for (const cartObj of cartCopy) {
    cartObj.totalCost = 0
    if (
      cartObj &&
      cartObj.plan &&
      cartObj.plan.cost &&
      cartObj.plan.cost !== 0
    ) {
      // make sure there a plan first and its not equal to 0
      cartObj.totalCost += cartObj.plan.cost * cartObj.uiState.quantity
    }
  }
  return cartCopy
}

export function calculateTeamPlanTotal({
  cart,
  discountTotalDollars = null,
  prefs = null,
  customer = null,
  intendedPaymentSource = null,
}) {
  // this will map through a card and add up the totalCost of all the plans
  // and remove discounts if provided.
  let total = 0
  for (const cartObj of cart) {
    if (cartObj) {
      total += cartObj.totalCost
    }
  }
  if (discountTotalDollars) {
    total -= discountTotalDollars
  }
  // add the invoice fees and stripe fees
  if (prefs) {
    const arrayFees = PaymentConstants.createInvoiceFeesArray({
      amountInDollars: total,
      prefs,
      returnEmpty: false,
      customer,
      intendedPaymentSource,
    })
    if (arrayFees) {
      for (const feeObj of arrayFees) {
        if (feeObj) {
          total += Number(feeObj.amountInDollars)
        }
      }
    }
  }
  return total
}
export function calculateTeamPlanSubTotal(cart) {
  let total = 0
  for (const cartObj of cart) {
    if (cartObj) {
      total += cartObj.totalCost
    }
  }
  return total
}

export function getConferenceRoomHours(cart) {
  let total = 0.0
  for (const cartObj of cart) {
    if (cartObj) {
      if (
        cartObj.plan &&
        cartObj.plan.conference_hours_default &&
        cartObj.uiState.quantity
      ) {
        total +=
          parseFloat(cartObj.plan.conference_hours_default) *
          parseFloat(cartObj.uiState.quantity)
      }
    }
  }
  return total
}

export function getMonthlyBillDate(theStartDate, monthlyBillDay) {
  // this function takes a start date and then calculates the monthly bill date based on the start date
  let billDate = monthlyBillDay

  //lets make sure to set the date to the end of the month if date is over (eg feb 31st will set to feb 28th)
  const daysInMonth = moment(theStartDate).daysInMonth()
  if (monthlyBillDay > daysInMonth) {
    billDate = daysInMonth
  }

  const startDate = moment(theStartDate).startOf('day')
  const month = startDate.month()
  const year = startDate.year()
  if (startDate.date() <= billDate) {
    return moment().date(billDate).month(month).year(year).startOf('day')
  }
  return moment()
    .date(billDate)
    .month(month)
    .year(year)
    .add(1, 'months')
    .startOf('day')
}

export function getCardAbbreviation(string) {
  if (string) {
    if (/\s/g.test(string)) {
      // If the string has whitespace/spaces
      if (string.length < 150) {
        return string
      }
      return `${string.substring(0, 147)}...`
    }
    // no space in string
    return `${string.substring(0, 20)}...`
  }
  return ''
}

export function pluckObjectByID(array, objectId) {
  // this function will map through a list of objects and return
  // the team where the ids match.
  try {
    if (array && array.length > 0 && objectId) {
      for (let counter = 0; counter < array.length; counter++) {
        if (`${array[counter].id}` === `${objectId}`) {
          return array[counter]
        }
      }
    }
    return null
  } catch (err) {
    console.error(err)
    return null
  }
}

export function parseCustomTagState(type, groups, tags) {
  // the method is used to set the initial state of the dropdowns so that they can
  // render changes appropriately.
  const customTagState = []
  const filteredGroups = groups.filter(group => group.category === type)
  for (let i = 0; i < filteredGroups.length; i++) {
    const group = filteredGroups[i]
    const obj = {
      groupName: group.name,
      uiId: group.name.replace(' ', ''),
      groupId: group.id,
      allow_multiselect: group.allow_multiselect,
      tags: group.tags,
    }
    if (tags) {
      const filteredTags = tags.filter(tag => tag.group_id === group.id)
      if (filteredTags.length > 0) {
        // if there's any existing state of the object tags
        if (group.allow_multiselect) {
          // if the group allows more than one tag selected from a group.
          const tagIds = []
          for (let count = 0; count < filteredTags.length; count++) {
            const tag = filteredTags[count]
            tagIds.push(tag.id)
          }
          obj.selectedTagIds = tagIds
        } else {
          // should only store one entry.
          obj.selectedTagId = filteredTags[0].id
        }
      } else {
        obj.selectedTagId = 'undefined'
        obj.selectedTagIds = 'undefined'
      }
    } else {
      obj.selectedTagId = 'undefined'
      obj.selectedTagIds = 'undefined'
    }
    customTagState.push(obj)
  }
  return customTagState
}
export function parseCustomFieldsToCustomTagState(
  event,
  customTagState,
  groupId
) {
  if (event.target.name.includes('tag-')) {
    return {
      customTagState: this.assignSingleCustomDropdownValue(
        customTagState,
        event.target.name.replace('tag-', ''),
        event.target.value,
        groupId
      ),
    }
  }
  return null
}
export function parseCustomFieldsToCustomTag(event, customTagState, groupId) {
  if (event.target.name.includes('tag-')) {
    return this.assignSingleCustomDropdownValue(
      customTagState,
      event.target.name.replace('tag-', ''),
      event.target.value,
      groupId
    )
  }
  return null
}

export function assignSingleCustomDropdownValue(
  customTagState,
  name,
  value,
  groupId
) {
  // This method is a helper to select a single value from a group
  // and assign a state object to store that custom value.
  // By nature, this will replace an existing value selected for that group.
  //{groupName: '', groupValue: ''}
  const newState = JSON.parse(JSON.stringify(customTagState))
  let found = false
  for (let i = 0; i < newState.length; i++) {
    if (newState[i].hasOwnProperty('uiId') && newState[i].uiId === name) {
      found = true
      if (value !== 'null') {
        // eslint-disable-next-line radix
        newState[i].selectedTagId = parseInt(value)
      } else {
        newState[i].selectedTagId = value
      }
      newState[i].groupId = groupId
      break
    }
  }
  if (!found) {
    newState.push({ uiId: name, selectedTagId: value, groupId })
  }
  return newState
}

export function renderCustomDropdowns(
  groups,
  customTagState,
  handleCustomDropdownChange,
  setFieldValue
) {
  if (!groups) {
    return null
  }
  return customTagState.map(obj => (
    <CustomTagDropdown
      key={obj.groupId}
      groupName={obj.groupName}
      uiId={obj.uiId}
      groupId={obj.groupId}
      tags={obj.tags}
      initialValue={obj.selectedTagId ? obj.selectedTagId : null}
      initialValues={obj.selectedTagIds ? obj.selectedTagIds : null}
      handleInputChange={(event, groupId) =>
        handleCustomDropdownChange(event, groupId, setFieldValue)
      }
    />
  ))
}

export function renderInvoiceStatusBadge(
  stripeObject,
  size = 'small',
  currencySymbol = '$'
) {
  if (!stripeObject) {
    return null
  }
  // expects a charge or an invoice
  let color = 'warning'
  let badgeText = 'Unpaid'
  let icon = null
  let tooltipContent = 'This is unpaid and needs to be actioned.'
  let invoice, charge
  if (stripeObject.object === 'charge') {
    charge = stripeObject
  }
  if (stripeObject.object === 'invoice') {
    invoice = stripeObject
    if (invoice.charge && typeof invoice.charge === 'object') {
      charge = invoice.charge
    }
  }
  if (charge) {
    if (charge.status === 'failed') {
      color = 'danger'
      badgeText = 'Failed'
      tooltipContent = "This invoice's payment has failed."
    }
    if (charge.paid) {
      color = 'success'
      badgeText = 'Paid'
      tooltipContent = "This invoice's payment was successful."
    }
    if (charge.refunded) {
      color = 'info'
      badgeText = 'Refunded'
      tooltipContent = 'This invoice was refunded in full.'
    }
    if (
      charge.amount_refunded &&
      charge.amount_refunded > 0 &&
      !charge.refunded
    ) {
      color = 'info'
      icon = <FontAwesomeIcon icon="adjust" />
      badgeText = 'Refunded'
      tooltipContent = `This invoice has been partially refunded ${currencySymbol}${(
        charge.amount_refunded / 100
      ).toFixed(2)}`
    }

    if (charge.status === 'pending') {
      color = 'info'
      badgeText = 'Pending'
      tooltipContent =
        "This invoice's payment is pending with the customer's bank."
    }
  } else if (invoice) {
    const invoice = stripeObject
    if (invoice.paid && invoice.closed && invoice.charge) {
      color = 'success'
      badgeText = 'Paid'
      tooltipContent = 'This invoice was paid successfully.'
    }
    if (
      (!invoice.paid && invoice.closed && !invoice.charge) ||
      invoice.status === 'void'
    ) {
      color = 'neutral-5'
      badgeText = 'Closed'
      tooltipContent = 'This invoice was marked as closed.'
    }
    if (invoice.status === 'paid' && invoice.closed && !invoice.charge) {
      color = 'primary'
      badgeText = 'Marked Paid'
      tooltipContent = 'This invoice was marked as paid.'
    }
    if (
      (invoice.charge && invoice.charge.status === 'pending') ||
      invoice.status === 'draft'
    ) {
      color = 'info'
      badgeText = 'Scheduled'
      if (invoice.auto_advance) {
        tooltipContent = `This invoice is scheduled to send via email will be sent automatically on ${moment
          .unix(Number(invoice.created))
          .add(1, 'hour')
          .format('MMM D, YYYY hh:mm a')}`
      }
    }
    if (invoice && invoice.billing_reason === 'upcoming') {
      color = 'primary'
      badgeText = 'Upcoming'
      tooltipContent = 'This is a preview of an upcoming invoice.'
    }

    if (invoice.status === 'open' && invoice.closed) {
      color = 'info'
      badgeText = 'Paused'
      tooltipContent = 'This invoice was paused.'
    }
  }
  return (
    <Tooltip
      header={badgeText}
      tooltipContent={tooltipContent}
      buttonText={'More on statuses'}
      handleClick={() => {
        window.open(
          'https://help.coworksapp.com/en/articles/3764710-invoices',
          '_blank'
        )
      }}
    >
      <BadgeV2
        id={
          stripeObject.id
            ? stripeObject.id
            : `badge${Math.floor(Math.random() * 100)}`
        }
        size={size}
        color={color}
        outline={false}
      >
        {icon ? <span className="mr-1">{icon}</span> : null}
        {badgeText}
      </BadgeV2>
    </Tooltip>
  )
}

export function getTransactionDetailText(stripeObject) {
  if (stripeObject && typeof stripeObject === 'object') {
    let invoice
    if (stripeObject.object === 'charge' && stripeObject.invoice) {
      invoice = stripeObject.invoice
    } else if (stripeObject.object === 'invoice') {
      invoice = stripeObject
    }
    if (invoice && invoice.lines) {
      const lineItems = [...invoice.lines.data].reverse()
      const lineItemNames = []
      for (let lineCounter = 0; lineCounter < lineItems.length; lineCounter++) {
        const lineItem = lineItems[lineCounter]
        if (lineItem.plan && lineItem.plan.nickname) {
          lineItemNames.push(lineItem.plan.nickname)
        } else if (lineItem.description) {
          lineItemNames.push(lineItem.description)
        } else {
          lineItemNames.push(`Item ${lineCounter}`)
        }
      }
      return lineItemNames.join(', ')
    }
    return stripeObject.description
  }
  return null
}

export function getTransactionType(stripeObject) {
  let type = 'Once'
  if (stripeObject && typeof stripeObject === 'object') {
    if (stripeObject.object === 'charge' && stripeObject.invoice) {
      if (
        typeof stripeObject.invoice === 'object' &&
        stripeObject.invoice.subscription
      ) {
        type = 'Recurring'
      }
    } else if (stripeObject.object === 'invoice' && stripeObject.subscription) {
      type = 'Recurring'
    }
  }
  return type
}

/*  This section is for the react-select when a component is selected, it will render this. */

export function renderMemberSelected(member) {
  return (
    <div className="text-primary">
      {member && `${member.first_name} ${member.last_name}`}
    </div>
  )
}
export function renderRoomSelected(room) {
  return <div className="text-primary">{room && room.name}</div>
}
export function renderCompanySelected(company) {
  return <div className="text-primary">{company && company.name}</div>
}
export function renderPlanSelected(plan) {
  return (
    <div className="text-primary">
      {plan.name} -{' '}
      {plan.currency_units
        ? getCurrencySymbolFromName(plan.currency_units)
        : '$'}
      {Number(plan.cost).toFixed(2)} per {plan.frequency}
    </div>
  )
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function isMemberAdmin(member, team) {
  if (team && team.admins) {
    for (let tcounter = 0; tcounter < team.admins.length; tcounter++) {
      if (team.admins[tcounter].id === member.id) {
        return true
      }
    }
  }
  return false
}

export function resolveUIFilters(state, type) {
  return state && state.ui && state.ui.filters && state.ui.filters[type]
    ? state.ui.filters[type]
    : {}
}

// This function takes in the history object and will resolve which
// of the tabs to be rendered on page load.
export function resolveActiveTab(history, defaultTab) {
  //todo replace logic in tabbed components with this!
  let activeTab = '1'
  if (defaultTab) {
    activeTab = defaultTab
  }
  const numericalPatternToMatch = /(#[0-9])/g // #1 or #9
  const alphanumericalPatternToMatch = /#\w*/g // #a to z
  if (history.location) {
    const hash = history.location.hash
    if (hash) {
      if (numericalPatternToMatch.test(hash)) {
        activeTab = hash.substr(1)
      } else if (alphanumericalPatternToMatch.test(hash)) {
        activeTab = hash.substr(1, hash.length)
      }
    }
    const state = history.location.state
    if (state && state.activeTab) {
      activeTab = state.activeTab
    }
  }
  return activeTab
}

export function getSubscriptionDateParams(
  startDate,
  calculatedMonthlyBillDate,
  isProrated
) {
  //1 today: (April 1st), startDate: [April 7th], monthlyBillDate: [v]1st, calculatedMonthlyBillDate = May 1st
  // billing_cycle_anchor_date = may 1st, trial_end = isProrated ? april 7th : NULL
  // if today
  //2 today: (April 1st), startDate: [May 1st], monthlyBillDate: [v]1st, calculatedMonthlyBillDate = May 1st
  // billing_cycle_anchor_date = may 1st, trial_end = NULL
  //3 today: (April 1st), startDate: [April 30th], monthlyBillDate: [v]1st, calculatedMonthlyBillDate = May 1st
  // billing_cycle_anchor_date = may 1st, trial_end = isProrated ? april 30th : NULL
  //4 today: (April 1st), startDate: [April 1st], monthlyBillDate: [v]1st, calculatedMonthlyBillDate = April 1st
  // billing_cycle_anchor_date = NULL, trial_end = NULL
  const params = {}
  const today = moment()
  if (moment(startDate).isSame(calculatedMonthlyBillDate, 'day')) {
    if (today.isSame(calculatedMonthlyBillDate, 'day')) {
      // today's date and monthlyBillDate match, start that shit now!
    } else {
      // today's date is not the same as the calculated monthly bill date (Aka we're starting a month from now)
      params.trial_end = moment(calculatedMonthlyBillDate).unix()
    }
  } else {
    // if start date & the date selected in the dropdown don't fall on the same day
    // need to check prorate status
    // eslint-disable-next-line no-lonely-if
    if (isProrated) {
      // if prorate, we need to set anchor
      params.billing_cycle_anchor_date = moment(
        calculatedMonthlyBillDate
      ).unix()
      if (moment(startDate).isSame(today, 'day')) {
        // start Date and today match so no free trial.. only billing cycle.
      } else {
        // need to throw in a free tiral because start date and today don't match.
        params.trial_end = moment(startDate).unix()
      }
    } else {
      // not prorated
      params.trial_end = moment(calculatedMonthlyBillDate).unix()
    }
  }
  return params
}

export function getGeneratedFromBadge(created_by) {
  // this uses the new label
  let label = 'Dashboard'
  let color = 'accent-2'

  switch (created_by) {
    case 'Invoice':
      color = 'accent-1'
      label = 'Invoice'
      break
    case 'DayPass':
      color = 'accent-2'
      label = 'Day Pass'
      break
    case 'WebsiteForm':
      color = 'accent-3'
      label = 'Membership Form'
      break
    case 'Zapier':
      color = 'accent-4'
      label = 'Zapier'
      break
    case 'FrontDesk':
      color = 'accent-5'
      label = 'Frontdesk Tour Form'
      break
    case 'WebsiteTourForm':
      color = 'accent-5'
      label = 'Tour Form'
      break
    case 'VisitorCheckin':
      color = 'accent-6'
      label = 'Visitor Checkin'
      break
    case 'ExternalBooking':
      color = 'accent-6'
      label = 'External Booking'
      break
    default:
      break
  }
  return (
    <BadgeV2 color={color} badgeShape="pill" size="small">
      {label}
    </BadgeV2>
  )
}

export function resolveHistoryState(history) {
  if (!history || !history.location || !history.location.state) {
    return null
  }
  return history.location.state
}
function isObject(obj) {
  const type = typeof obj
  return type === 'function' || (type === 'object' && Boolean(obj))
}

export function iterationCopy(src) {
  const target = {}
  for (const prop in src) {
    if (src.hasOwnProperty(prop)) {
      // if the value is a nested object, recursively copy all it's properties
      if (isObject(src[prop])) {
        target[prop] = iterationCopy(src[prop])
      } else {
        target[prop] = src[prop]
      }
    }
  }
  return target
}
export function resolveDomainPrefix(preference) {
  const host = window.location.host.toLowerCase()
  const protocol = window.location.protocol.toLowerCase()
  const subdomain = preference.subdomain.toLowerCase()
  return `${protocol}//${!host.includes(subdomain) ? `${subdomain}.` : ''}${
    host === 'dashboard.coworksapp.com' ? 'coworksapp.com' : `${host}`
  }`
}

export function getActiveCheckIn(checkins) {
  const _iterator = checkins ? checkins : []
  let obj = null
  for (const checkin of _iterator) {
    if (!checkin.time_out) {
      obj = checkin
      break
    }
  }
  return obj
}
export function checkForNetwork(user) {
  let networkId = null
  if (user && user.networks && user.networks.length > 0) {
    networkId = user.networks[0].id
  }
  return networkId
}
export const concatenateDateTime = (date, time, fFormat) => {
  const dateTime = moment(`${date} ${time}`)
  return dateTime.format(fFormat)
}

export const mockRequest = (success, timeout) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve({})
      } else {
        reject({ message: 'Error' })
      }
    }, timeout)
  })
}

export const convertMinutesToTime = mins => {
  return moment().startOf('day').add(mins, 'minutes')
}

export const convertTimeToMinutes = (time, format) => {
  const dateObj = moment(time, format)
  const mins = dateObj.hour() * 60 + dateObj.minute()
  return mins
}
export const getMinifiedMetric = (metric, lower = false) => {
  let print = ''
  switch (metric) {
    case 'feet':
      print = 'Ft'
      break

    case 'meters':
      print = 'M'
      break

    default:
      break
  }
  if (lower) {
    print = print.toLowerCase()
  }
  return print
}
