/* eslint-disable no-invalid-this */
import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '@reduxActions/userActions'
import * as uiActions from '@reduxActions/uiActions'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import Login from './Login'

const Container = styled.div`
  overflow-x: hidden;
  background-size: cover;
  background-attachment: fixed;
  width: 100% !important;
  height: 100vh;
  background: rgb(41, 64, 79);
  background: linear-gradient(
    343deg,
    rgba(41, 64, 79, 1) 0%,
    rgba(38, 151, 150, 1) 50%,
    rgba(205, 217, 229, 1) 100%
  );
`
function TabletLoginWrapper({
  user_actions,
  ui_actions,
  history,
  user,
  roomToken,
  frontDeskLoaded,
}) {
  useEffect(() => {
    if (user && user.authentication_token) {
      if (roomToken) {
        history.replace(`/booking-tablet/${roomToken}`)
      } else if (frontDeskLoaded) {
        history.replace('/front-desk')
      }
    } else {
      // make sure the user is cleared if we're noto active on the
      // booking tablet or the front desk tablet
      user_actions.signOutUser()
    }
    // indicats that we're logging into the tablet
    ui_actions.setIsTabletUser(true)
  }, [frontDeskLoaded, history, roomToken, ui_actions, user, user_actions])

  return (
    <Container>
      <Login
        history={history}
        titleText={'Front-desk and Booking Tablets'}
        isTablet
      />
    </Container>
  )
}

TabletLoginWrapper.displayName = 'TabletLoginWrapper'

TabletLoginWrapper.propTypes = {
  user_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  roomToken: PropTypes.string,
  frontDeskLoaded: PropTypes.bool,
  showTabletPicker: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    user: state.user,
    roomToken: state.ui.roomToken,
    frontDeskLoaded: state.ui.frontDeskLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabletLoginWrapper)
