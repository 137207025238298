import axios from 'axios'
import store from '../store/store'

const STRIPE_API_URL = 'https://js.stripe.com/v3/'
const MIXPANEL_APP_TOKEN = 'd5520e4e23d79638306d6d38284edc33'

const baseURL = process.env.REACT_APP_API_URL
const socketURL = process.env.REACT_APP_SOCKET_URL
const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY
const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID
const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID
const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME
const STRIPE_OAUTH_BASE_URL = 'https://connect.stripe.com/oauth/authorize'
const STRIPE_OAUTH_SCOPE = 'read_write'
const STRIPE_RESPONSE_TYPE = 'code'

const Axios = axios.create({
  baseURL: baseURL,
  timeout: 20000,
})

function formatHeaders({
  userToken,
  client,
  uid,
  activeCommunityId,
  activeCampusId,
  timezoneIdentifier,
}) {
  // eslint-disable-next-line no-undef
  const newHeaders = { 'Client-Version': BUILD_VERSION, 'Client-Type': 'web' }

  if (userToken && client && uid) {
    newHeaders['Access-Token'] = userToken
    newHeaders.Client = client
    newHeaders.Uid = uid
  }

  if (activeCommunityId) {
    newHeaders['Active-Community-Id'] = activeCommunityId
  }

  if (activeCampusId) {
    newHeaders['Active-Campus-Id'] = activeCampusId
  }
  if (timezoneIdentifier) {
    newHeaders['Timezone-Identifier'] = timezoneIdentifier
  }
  return newHeaders
}

Axios.interceptors.request.use(axiosConfig => {
  const state = store.getState()

  const newObject = {}

  if (typeof state.user !== 'undefined') {
    const user = state.user
    if (user) {
      newObject.userToken = user.authentication_token
      newObject.client = user.client
      newObject.uid = user.uid
    }
  }

  if (typeof state.ui !== 'undefined') {
    const uIState = state.ui
    if (uIState) {
      if (uIState.activeCommunity && uIState.activeCampus) {
        newObject.activeCommunityId = uIState.activeCommunity.id
        newObject.activeCampusId = uIState.activeCampus.id
      }
      if (uIState.timezoneIdentifier) {
        newObject.timezoneIdentifier = uIState.timezoneIdentifier
      }
    }
  }

  // eslint-disable-next-line no-undef
  const newHeaders = formatHeaders(newObject)

  // eslint-disable-next-line no-param-reassign
  axiosConfig.headers = { ...axiosConfig.headers, ...newHeaders }

  return axiosConfig
})

Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error && error.response && error.response.status === 401) {
      if (
        !error.config.url.includes('/sign_in') &&
        !window.location.pathname.includes('login')
      ) {
        // If we're not trying to sign or we're not already on a login page
        //    ... redirect to login

        window.location = '/login'
      }
    }
    return Promise.reject(error)
  }
)

export {
  Axios,
  baseURL,
  socketURL,
  STRIPE_API_KEY,
  STRIPE_API_URL,
  STRIPE_CLIENT_ID,
  STRIPE_OAUTH_BASE_URL,
  STRIPE_OAUTH_SCOPE,
  STRIPE_RESPONSE_TYPE,
  ENVIRONMENT_NAME,
  MIXPANEL_APP_TOKEN,
  INTERCOM_ID,
  formatHeaders,
}
