/* eslint-disable react/no-multi-comp,react/display-name */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as memberActions from '@app/reduxActions/memberActions'
import * as leadActions from '@app/reduxActions/leadActions'
import * as networkActions from '@app/reduxActions/networkActions'
import * as teamActions from '@app/reduxActions/teamActions'
import * as uiActions from '@app/reduxActions/uiActions'
import * as checkinActions from '@app/reduxActions/checkinActions'
import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import * as memberSelectors from '@reduxSelectors/memberSelectors'
import {
  CHECKIN_ENUM,
  CHECKIN_PASSPORT,
  CHECKIN_MEMBER,
  CHECKIN_VISITOR,
} from '@global/Constants/CheckinsConstants'
import { Button, TextButton } from '@global/Base/Button/ButtonStyled'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import CoworksSearch from '@global/Search/CoworksSearch'
import classnames from 'classnames'
import { Modal } from 'reactstrap'
import { toast } from 'react-toastify'
import MemberSelector from '../Members/MemberSelector'
import Spinner from '@global/Spinner'
import MemberRowSearch from '../Members/MemberRowSearch'
import moment from 'moment'
import Log from './Log'
import VisitorForm from './VisitorForm'
import { captureException } from '@global/ErrorFactory'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

const Container = styled.div``
const Header = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const LogStyled = styled(Log)`
  display: block;
  padding-left: 0;
  padding-right: 0;
`
const HeaderText = styled.h4`
  flex: 1;
`
const HeaderSearch = styled.div`
  flex: 2;
`
const HeaderActions = styled.div`
  flex: 1;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: flex-end;
`
const TextButtonStyled = styled(TextButton)`
  margin: 0;
`
const ButtonStyled = styled(Button)`
  margin: 0 0 0 8px;
`
const SearchWrapper = styled.div`
  flex: 4 0 auto;
`
const TabWrapper = styled.div`
  padding: 16px;
`
const TabContentStyled = styled(TabContent)`
  margin-bottom: 15px;
`
const TabPaneStyled = styled(TabPane)``

function CheckIns({
  history,
  match,
  user,
  checkin_actions,
  activeCampus,
  activeCommunity,
  checkedInMembers,
  members,
  leads,
  lead_actions,
  member_actions,
  network_actions,
}) {
  const [gridOpen, setGrid] = useState(false)
  const [formOpen, setForm] = useState(false)
  const [networkId, setNetowrkId] = useState(Constants.checkForNetwork(user))
  const [selectedMember, setMember] = useState(null)
  // const [pendingVisitor, setVisitor] = useState(null)
  const [activeTab, setActiveTab] = useState(
    match.params.tabId ? match.params.tabId : CHECKIN_ENUM[CHECKIN_MEMBER]
  )
  const [showCampusPicker, setShowCampusPicker] = useState(false)
  const [selectedCampus, setSelectedCampus] = useState(activeCampus)

  const toggleForm = () => {
    setForm(!formOpen)
  }
  const toggleGrid = () => {
    setGrid(!gridOpen)
  }
  const setTab = tab => {
    if (activeTab !== tab) {
      history.push(`/checkins/${tab}`)
    }
  }

  const grabType = tab => {
    const keys = Object.keys(CHECKIN_ENUM)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (CHECKIN_ENUM[key] === tab) {
        return key
      }
    }
    return null
  }
  const getMemberSelectorHeader = type => {
    let headerText = null
    switch (type) {
      case CHECKIN_ENUM[CHECKIN_MEMBER]:
        headerText = 'Which member are you checking in?'
        break
      case CHECKIN_ENUM[CHECKIN_VISITOR]:
        headerText = ''
        break
      case CHECKIN_ENUM[CHECKIN_PASSPORT]:
        headerText = 'Which passport member are you checking in?'
        break
      default:
        headerText = 'Who are you checking in?'
        break
    }
    return headerText
  }

  const createCheckIn = ({
    email,
    firstName,
    lastName,
    contactId,
    memberId,
    campusId,
    fromCampusId,
    message,
  }) => {
    const type = grabType(activeTab)
    const campus_id = campusId ? campusId : activeCampus.id

    let from_campus = null
    let network_id = null

    if (type === CHECKIN_PASSPORT) {
      network_id = networkId
      from_campus = fromCampusId
    }

    const params = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      contact_id: contactId,
      user_id: memberId,
      network_id,
      campus_id,
      from_campus,
      community_id: activeCommunity.id,
      creator_id: user.id,
      message,
      time_in: moment().toISOString(),
      type: type,
      created_by: 'Dashboard',
    }

    return checkin_actions.createCheckIn(params, type).then(() => {
      toast.success(`Created Check-in`)
      // todo close the form and grid modal
      // todo render lottie?
    })
  }

  const getMemberSelectorContext = type => {
    let actions = member_actions
    if (type === CHECKIN_ENUM[CHECKIN_PASSPORT]) {
      actions = network_actions
    }

    return (
      <MemberSelector
        toggleModal={toggleGrid}
        headerText={getMemberSelectorHeader(type)}
        action={(member, campusId) =>
          createCheckIn({ memberId: member.id, fromCampusId: campusId })
        }
        actions={actions}
        campusId={activeCampus.id}
        showCloseButton
        campuses={activeCommunity.campuses}
        isDashboard
        showCampusPicker={showCampusPicker}
        buttonText="Check-in"
        excluded={Object.keys(checkedInMembers)}
        otherParams={{ community_id: activeCommunity.id }}
        showLottie
        showActiveStatus
        type="Ping [MemberName]"
      />
    )
  }

  const renderSearchRow = object =>
    MemberRowSearch({
      key: object.id,
      member: object,
      team: object.teams && object.teams[0],
      index: object.id,
      // viewMemberClicked: () => null,
      // teamLinkClicked: () => null,
      includeMarginsAndPadding: true,
      includePhoto: false,
      showEdit: false,
      showActions: false,
    })
  const manageModals = tab => {
    if (tab === CHECKIN_ENUM[CHECKIN_VISITOR]) {
      toggleForm()
    } else {
      toggleGrid()
    }
  }

  const redirectReport = () => {
    history.push(`/reports/${activeTab} check-ins`)
  }
  const onMemberClick = member => {
    const mem = JSON.parse(JSON.stringify(member))
    NavigationConstants.navigateToMember({
      history,
      match,
      member: mem,
    })
  }

  const searchField = type => {
    let placeholder = 'Search Members'
    let actions = member_actions
    let defaultData = members
    if (type === CHECKIN_PASSPORT) {
      actions = network_actions
      placeholder = 'Search Passport Members'
    } else if (type === CHECKIN_VISITOR) {
      actions = lead_actions
      placeholder = 'Search Visitors'
      defaultData = leads
    }
    return (
      <SearchWrapper>
        <CoworksSearch
          placeholder={placeholder}
          defaultData={defaultData}
          actions={actions}
          value={selectedMember}
          activeCampus={activeCampus}
          handleItemSelected={setMember}
          handleSearchCleared={setMember}
          dataResolver={response => {
            if (type === CHECKIN_VISITOR) return response
            return response.members
          }}
          renderSearchRow={renderSearchRow}
          otherParams={{
            community_id: activeCommunity.id,
            campus_id: type === CHECKIN_VISITOR ? activeCampus.id : null,
          }}
          isClearable
          renderSingleValue={Constants.renderMemberSelected}
        />
      </SearchWrapper>
    )
  }
  const actionButtons = () => (
    <ButtonsWrapper>
      <TextButtonStyled color="primary" onClick={redirectReport}>
        <FontAwesomeIcon icon={'book'} /> Check-in Report
      </TextButtonStyled>
      <ButtonStyled
        id="new-invoice"
        color="primary"
        onClick={() => manageModals(activeTab)}
      >
        <FontAwesomeIcon icon={'map-marker-check'} /> New Check-in
      </ButtonStyled>
    </ButtonsWrapper>
  )
  const getTabContent = (header, type) => {
    return (
      <TabWrapper key={`tab-${type}`}>
        <Header>
          <HeaderText>{header}</HeaderText>
          <HeaderSearch>{searchField(type)}</HeaderSearch>
          <HeaderActions>{actionButtons()}</HeaderActions>
        </Header>
        <LogStyled
          className="card-block"
          member={selectedMember}
          onMemberClick={onMemberClick}
          type={type}
          showCampusPicker={showCampusPicker}
        />
      </TabWrapper>
    )
  }

  return (
    <Container>
      <Nav tabs>
        {Object.keys(CHECKIN_ENUM).map(key => {
          if (key === CHECKIN_PASSPORT && !networkId) return null
          return (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === CHECKIN_ENUM[key],
                })}
                onClick={() => {
                  setTab(CHECKIN_ENUM[key])
                }}
              >
                {`${CHECKIN_ENUM[key].substr(0, 1).toUpperCase()}${CHECKIN_ENUM[
                  key
                ].substr(1)}`}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContentStyled activeTab={activeTab}>
        <TabPaneStyled tabId={CHECKIN_ENUM[CHECKIN_MEMBER]}>
          {activeTab === CHECKIN_ENUM[CHECKIN_MEMBER] && // Prevents multi-tab rendering
            getTabContent('Member Check-ins', CHECKIN_MEMBER)}
        </TabPaneStyled>
        {networkId && (
          <TabPaneStyled tabId={CHECKIN_ENUM[CHECKIN_PASSPORT]}>
            {activeTab === CHECKIN_ENUM[CHECKIN_PASSPORT] &&
              getTabContent('Passport Check-ins', CHECKIN_PASSPORT)}
          </TabPaneStyled>
        )}
        <TabPaneStyled tabId={CHECKIN_ENUM[CHECKIN_VISITOR]}>
          {activeTab === CHECKIN_ENUM[CHECKIN_VISITOR] &&
            getTabContent('Visitor Check-ins', CHECKIN_VISITOR)}
        </TabPaneStyled>
      </TabContentStyled>
      <Modal isOpen={gridOpen} size="lg">
        {getMemberSelectorContext(activeTab)}
      </Modal>
      <Modal isOpen={formOpen} size="lg">
        <VisitorForm
          toggleModal={toggleForm}
          submitVisitorForm={createCheckIn}
          showCampusPicker={showCampusPicker}
          campuses={activeCommunity.campuses}
          selectedCampus={selectedCampus}
        />
      </Modal>
    </Container>
  )
}

CheckIns.propTypes = {
  history: PropTypes.object,
  checkin_actions: PropTypes.object,
  lead_actions: PropTypes.object,
  activeCommunity: PropTypes.object,
  members: PropTypes.array,
  leads: PropTypes.array,
  activeCampus: PropTypes.object,
  member_actions: PropTypes.object,
  network_actions: PropTypes.object,
  checkedInMembers: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
}

CheckIns.defaultProps = {}

CheckIns.displayName = 'CheckIns'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    checkedInMembers: state.checkins.active ? state.checkins.active : {},
    teams: state.teams.teams,
    user: state.user,
    leads: state.leads.prospects ? state.leads.prospects : [],
    members: memberSelectors.getMemberList(state),
    networkMembers: state.network.members,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkin_actions: bindActionCreators(checkinActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
    network_actions: bindActionCreators(networkActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    lead_actions: bindActionCreators(leadActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckIns)
