/* eslint-disable no-invalid-this */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as campusActions from '../../reduxActions/campusActions'
import * as userActions from '../../reduxActions/userActions'
import * as uiActions from '../../reduxActions/uiActions'
import PropTypes from 'prop-types'

import {
  MessageTypeButton,
  MessageTypeTitle,
  MessageTypeDescription,
} from '@components/Messages/MessageTypeButton'

import { Button } from 'reactstrap'

import conferencePlaceholder from '../../img/placeholders/conference_room.jpg'

import ReactGA from 'react-ga'
import Login from '../Login/Login'

const TABLET_FUNCTIONS = [
  {
    id: 0,
    name: 'Conference Tablet',
    description: 'The tablet that will sit in front of a conference room.',
    route: '/conference-rooms',
    photo:
      'https://coworksprod.s3.amazonaws.com/assets/admin-dashboard-assets/coworks_coworks-booking.png',
  },
  {
    id: 1,
    name: 'Front-Desk Tablet',
    description: 'Tour request and Guest checkin on the tablet',
    route: '/front-desk',
    photo:
      'https://coworksprod.s3.amazonaws.com/assets/admin-dashboard-assets/coworks_coworks-front-desk.png',
  },
]

class SelectTabletType extends React.Component {
  static propTypes = {
    activeCampus: PropTypes.object,
    campus_actions: PropTypes.object,
    user_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    history: PropTypes.object,
    campuses: PropTypes.array,
    user: PropTypes.object,
    isFetching: PropTypes.bool,
    roomToken: PropTypes.string,
    frontDeskLoaded: PropTypes.bool,
  }
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.props.ui_actions.setIsTabletUser(true)
  }
  returnToLastRoom = shouldReplaceRoute => {
    if (
      this.props.user &&
      this.props.user.authentication_token &&
      this.props.roomToken
    ) {
      if (shouldReplaceRoute) {
        this.props.ui_actions.setIsTabletUser(true)
        this.props.history.replace(`/booking-tablet/${this.props.roomToken}`)
      }
      return true
    }
    return false
  }
  returnToFrontDesk = shouldReplaceRoute => {
    if (
      this.props.user &&
      this.props.user.authentication_token &&
      this.props.frontDeskLoaded
    ) {
      if (shouldReplaceRoute) {
        this.props.ui_actions.setIsTabletUser(true)
        this.props.history.replace('/front-desk')
      }
      return true
    }
    return false
  }
  handleFormTypeChange = route => {
    this.props.history.replace(route)
  }
  handleLogout = () => {
    this.props.user_actions.signOutUser()
    this.props.history.replace('/tablet-login')
  }
  handleTabletFunctionSelected = tabletFunction => {
    switch (tabletFunction.id) {
      case 0:
        this.props.history.replace('/conference-rooms')
        break
      case 1:
        this.props.history.replace('/front-desk')
        break
      default:
        this.props.history.replace('/conference-rooms')
        break
    }
  }
  render() {
    if (!this.returnToLastRoom(true) && !this.returnToFrontDesk(true)) {
      return (
        <div>
          <div className="w-100" id="BookingAdminForm">
            <nav className="navbar sticky-top navbar-light bg-faded">
              <h2>
                {this.props.activeCampus
                  ? `${this.props.activeCampus.name} Tablet`
                  : 'Coworks Tablet'}
              </h2>
              <Button
                className="btn btn-primary float-right"
                onClick={this.handleLogout}
              >
                Logout
              </Button>
            </nav>
            <div className="col-12">
              <div className="row px-md-5">
                {TABLET_FUNCTIONS.map((tabletFunction, index) => {
                  return (
                    <div className="col-md-6" key={index}>
                      <MessageTypeButton
                        id="company-charge"
                        className={`card d-flex align-items-center justify-content-center p-3 m-5`}
                        onClick={() =>
                          this.handleFormTypeChange(tabletFunction.route)
                        }
                      >
                        {/* <FontAwesomeIcon icon={'users'} size="3x" /> */}
                        <img
                          className="img-responsive center-block mb-3"
                          style={{ width: '15rem' }}
                          src={tabletFunction.photo}
                        />
                        <MessageTypeTitle>
                          {tabletFunction.name}
                        </MessageTypeTitle>
                        <MessageTypeDescription>
                          {tabletFunction.description}
                        </MessageTypeDescription>
                      </MessageTypeButton>
                    </div>
                  )
                })}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-4 offset-md-4 mt-2 card p-4">
                <h4>Your Tablet Features:</h4>
                <h6 className="text-muted">Please choose one</h6>
                {this.renderTabletFunctions()}
              </div>
            </div> */}
          </div>
        </div>
      )
    }
    return null
  }
}

SelectTabletType.displayName = 'SelectTabletType'

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    campuses:
      state.ui.activeCommunity && state.ui.activeCommunity.campuses
        ? state.ui.activeCommunity.campuses
        : [],
    user: state.user,
    isFetching: state.ui.isFetching,
    roomToken: state.ui.roomToken,
    frontDeskLoaded: state.ui.frontDeskLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    campus_actions: bindActionCreators(campusActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTabletType)
