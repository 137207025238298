import {
  // Rooms
  CREATE_ROOM,
  REGISTER_COMMUNITY,
  SET_ROOMS,
  UPDATE_ROOM,
  CLEAR_CAMPUS_DATA,
  ARCHIVE_ROOM,
  CLEAR_ROOMS,

  // Suites
  CREATE_SUITE,
  SET_SUITES,
  UPDATE_SUITE,
  ARCHIVE_SUITE,
  CLEAR_SUITES,
} from '../reduxActions/actionTypes'
import { arrayMerge } from './reducerConstants'
import initialState from './initialState'

export default function (state = initialState.rooms, action) {
  let roomsArray = []
  if (Array.isArray(state)) {
    roomsArray = state
  } else {
    roomsArray =
      state && state.rooms && state.rooms.data ? state.rooms.data : []
  }
  const roomsMeta =
    state && state.rooms && state.rooms.meta ? state.rooms.meta : null
  const suitesArray =
    state && state.suites && state.suites.data ? state.suites.data : []
  const suitesMeta =
    state && state.suites && state.suites.meta ? state.suites.meta : null
  switch (action.type) {
    case CREATE_ROOM:
      return {
        ...state,
        rooms: {
          data: [...roomsArray, Object.assign({}, action.room)],
          meta: roomsMeta,
        },
      }
    case CREATE_SUITE:
      return {
        ...state,
        suites: {
          data: [...suitesArray, Object.assign({}, action.room)],
          meta: suitesMeta,
        },
      }
    case REGISTER_COMMUNITY:
      return action.rooms
    case SET_ROOMS: {
      return {
        ...state,
        rooms: {
          data:
            roomsArray && roomsArray.length > 0
              ? arrayMerge(roomsArray, action.rooms)
              : action.rooms,
          meta: action.meta,
        },
      }
    }
    case SET_SUITES: {
      return {
        ...state,
        suites: {
          data:
            suitesArray && suitesArray.length > 0
              ? arrayMerge(suitesArray, action.rooms)
              : action.rooms,
          meta: action.meta,
          stats: action.stats,
        },
      }
    }
    case UPDATE_ROOM: {
      return {
        ...state,
        rooms: {
          data:
            roomsArray && roomsArray.length > 0
              ? arrayMerge(roomsArray, [action.room])
              : [action.room],
          meta: action.meta,
        },
      }
    }
    case UPDATE_SUITE: {
      return {
        ...state,
        suites: {
          data:
            suitesArray && suitesArray.length > 0
              ? arrayMerge(suitesArray, [action.room])
              : [action.room],
          meta: action.meta,
        },
      }
    }
    case ARCHIVE_ROOM:
      return {
        ...state,
        rooms: {
          data: roomsArray.filter(listedRoom => {
            if (listedRoom.id === action.room.id) {
              return false
            }
            return true
          }),
        },
      }
    case ARCHIVE_SUITE:
      return {
        ...state,
        rooms: {
          data: roomsArray.filter(listedRoom => {
            if (listedRoom.id === action.room.id) {
              return false
            }
            return true
          }),
        },
      }
    case CLEAR_ROOMS:
      return {
        ...state,
        rooms: initialState.rooms.rooms,
      }
    case CLEAR_SUITES:
      return {
        ...state,
        suites: initialState.rooms.suites,
      }
    case CLEAR_CAMPUS_DATA:
      return initialState.rooms
    default:
      return state
  }
}
