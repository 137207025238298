import * as Sentry from '@sentry/browser'
import Intercom from 'react-intercom'
import { mixpanel } from './initializers'

function trackUser(user) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
    if (user) {
      mixpanel.track('init_coworks_web', {
        distinct_id: user.id,
      }) // initialize and track mixpanel stats
      Sentry.configureScope(scope => {
        scope.setUser({
          email: user.email,
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          // eslint-disable-next-line no-undef
          build_version: BUILD_VERSION,
        })
      })
      try {
        // eslint-disable-next-line no-undef
        FS.identify(`${user.id}`, {
          displayName: `${user.first_name} ${user.last_name}`,
          email: `${user.email}`,
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      mixpanel.track('init_coworks_web') // initialize and track mixpanel stats
    }
  }
}
export { trackUser }
