import React, { useState } from 'react'
import { Formik, FormikProps, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  CustomInputComponent,
  LabelStyled,
  CustomSelectComponent,
} from '@global/Form/FormComponents'
import CoworksSearch from '@global/Search/CoworksSearch'
import Checkbox from '@bit/coworks.base-components.checkbox'
import styled from 'styled-components'
import MemberRowSearch from '../Members/MemberRowSearch'
import * as Constants from '@global/Constants'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as memberActions from '@app/reduxActions/memberActions'
import * as memberSelectors from '@reduxSelectors/memberSelectors'

// DEPENDENCIES
import PropTypes from 'prop-types'
import {
  Button,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap'

const CheckboxStyled = styled(Checkbox)`
  margin-right: 12px;
  line-height: 0;
`
const SearchWrapper = styled.div`
  flex: 4 0 auto;
`

const VisitorForm = ({
  toggleModal,
  submitVisitorForm,
  showCampusPicker,
  campuses,
  selectedCampus,
  members,
  member_actions,
  activeCampus,
}) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    notifyMember: false,
    member: null,
    selectedCampusId: selectedCampus.id,
    message: '',
  }
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    email: Yup.string().email().required('Email is required.'),
    member: Yup.object()
      .nullable()
      .when('notifyMember', {
        is: true,
        then: Yup.object().nullable().required('member is required'),
      }),
  })
  function onFormSubmit(values, { setSubmitting }) {
    const memberId = values.member ? parseInt(values.member.id, 10) : null
    const campusId = parseInt(values.selectedCampusId, 10)
    const message = values.message.trim() ? values.message.trim() : null
    submitVisitorForm({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      message,
      memberId,
      campusId,
    })
    toggleModal()
    setSubmitting(false)
  }
  const renderSearchRow = object =>
    MemberRowSearch({
      key: object.id,
      member: object,
      team: object.teams && object.teams[0],
      index: object.id,
      // viewMemberClicked: () => null,
      // teamLinkClicked: () => null,
      includeMarginsAndPadding: true,
      includePhoto: false,
      showEdit: false,
      showActions: false,
    })

  return (
    <div>
      <ModalHeader>
        <div
          id="close-modal"
          className="fixed-top text-black mr-2 mt-2 "
          aria-hidden="true"
          onClick={toggleModal}
        >
          <FontAwesomeIcon icon="times" color="black" />
        </div>
        <h1 className="mb-2 member-title mt-3">Check-In Visitor</h1>
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {formProps => {
          return (
            <Form>
              <ModalBody className="py-0">
                <Row>
                  <Col xs="6" className="form-group align-items-center">
                    <Field
                      type="text"
                      name="firstName"
                      label={'First Name'}
                      placeholder="John"
                      className="form-control visitor-message form-control-lg"
                      component={CustomInputComponent}
                    />
                  </Col>
                  <Col xs="6" className="form-group align-items-center">
                    <Field
                      type="text"
                      name="lastName"
                      label={'Last Name'}
                      placeholder="Smith"
                      className="form-control visitor-message form-control-lg"
                      component={CustomInputComponent}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="form-group align-items-center">
                    <Field
                      type="text"
                      name="email"
                      label={'Email'}
                      placeholder="visitor@email.com"
                      className="form-control visitor-message form-control-lg"
                      component={CustomInputComponent}
                    />
                  </Col>
                  {showCampusPicker && (
                    <Col xs="12" className="form-group align-items-center">
                      <Field
                        name="selectedCampusId"
                        label="Which campus should we check this visitor into?"
                        className="form-control-lg form-control w-100"
                        component={CustomSelectComponent}
                      >
                        {campuses.map((campus, index) => (
                          <option value={campus.id}>{campus.name}</option>
                        ))}
                      </Field>
                    </Col>
                  )}
                  <Col
                    xs="12"
                    className="d-flex flex-row form-group align-items-center"
                  >
                    <CheckboxStyled
                      name="notifyMember"
                      label="Are you visiting a member?"
                      selected={formProps.values.notifyMember}
                      handleChange={() => {
                        formProps.setFieldValue(
                          'notifyMember',
                          !formProps.values.notifyMember
                        )
                      }}
                      error={formProps.errors.notifyMember}
                    />
                  </Col>
                </Row>
                {formProps.values.notifyMember && (
                  <Row className="py-2">
                    <Col xs="12" className="form-group align-items-center">
                      <SearchWrapper>
                        <CoworksSearch
                          placeholder={`Search Members`}
                          defaultData={members}
                          actions={member_actions}
                          value={formProps.values.member}
                          activeCampus={activeCampus}
                          handleItemSelected={member =>
                            formProps.setFieldValue('member', member)
                          }
                          handleSearchCleared={() =>
                            formProps.setFieldValue('member', null)
                          }
                          dataResolver={response => response.members}
                          renderSearchRow={renderSearchRow}
                          isClearable
                          renderSingleValue={Constants.renderMemberSelected}
                        />
                      </SearchWrapper>
                      <ErrorMessage name="member">
                        {msg => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </Col>
                    <Col
                      xs="12"
                      className="d-flex flex-column form-group align-items-flex-start"
                    >
                      <textarea
                        className="form-control visitor-message form-control-lg"
                        id="message"
                        name="message"
                        maxLength="75"
                        rows="3"
                        placeholder="Send a message to the member (optional)"
                        value={formProps.values.message}
                        onChange={formProps.handleChange}
                      />
                      <ErrorMessage name="message" />
                    </Col>
                  </Row>
                )}
              </ModalBody>
              <ModalFooter className="d-flex justify-content-end align-items-center">
                <Button className="cancel-btn btn-lg" onClick={toggleModal}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="send-btn btn-lg"
                  disabled={formProps.isSubmitting}
                >
                  Send
                </Button>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

VisitorForm.propTypes = {
  member: PropTypes.object,
  toggleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  showCampusPicker: PropTypes.bool,
  campuses: PropTypes.array,
  toggleSuccessModal: PropTypes.func,
  submitVisitorForm: PropTypes.func,
  handleInputChange: PropTypes.func,
  selectedCampus: PropTypes.object,
  members: PropTypes.array,
  activeCampus: PropTypes.object,
  member_actions: PropTypes.object,
}

VisitorForm.displayName = 'VisitorForm'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    checkedInMembers: state.checkins.active ? state.checkins.active : {},
    teams: state.teams.teams,
    user: state.user,
    members: memberSelectors.getMemberList(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    member_actions: bindActionCreators(memberActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorForm)
