import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik, Form, Field } from 'formik'
import * as uiActions from '@reduxActions/uiActions'
import validationSchema from './DynamicGroupsValidationSchema'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import Spinner from '@global/Spinner'

import {
  LabelStyled,
  CustomInputComponent,
  CustomToggleComponent,
  CustomSelectComponent,
} from '@global/Form/FormComponents'

import ErrorFocus from '@global/Form/ErrorFocus'

function DynamicGroupFormModal({
  showCreateGroupModal,
  toggleCreateGroupModal,
  ui_actions,
  activeCampus,
  activeCommunity,
  dynamicGroup,
}) {
  const [isLoading, setisLoading] = useState(false)
  const [focusedInput, setfocusedInput] = useState(null)

  const createGroup = (values, setSubmitting, resetForm) => {
    setisLoading(true)
    ui_actions
      .createGroup({
        tags: JSON.stringify(values.tags),
        label: values.label,
        type: values.type,
        campus_id: activeCampus.id,
        community_id: activeCommunity.id,
        is_multiselect: values.isMultiselect,
        is_creatable: values.isCreatable,
      })
      .then(response => {
        toast.success('Created custom dropdown')
        resetForm()
      })
      .catch(err => {
        setisLoading(false)
        toast.error('Problem creating dropdown')
        console.error(err)
      })
  }
  const updateGroup = (values, setSubmitting, resetForm) => {
    setisLoading(true)
    ui_actions
      .updateGroup({
        id: dynamicGroup.id,
        tags: JSON.stringify(values.tags),
        label: values.label,
        type: values.type,
        campus_id: activeCampus.id,
        community_id: activeCommunity.id,
        is_multiselect: values.isMultiselect,
        is_creatable: values.isCreatable,
      })
      .then(response => {
        toast.success('Updated custom dropdown')
        resetForm()
      })
      .catch(err => {
        toast.error('Problem creating dropdown')
        console.error(err)
      })
      .finally(() => {
        setSubmitting(false)
        setisLoading(false)
      })
  }

  const onFormSubmit = (values, { setSubmitting, resetForm }) => {
    if (dynamicGroup) {
      // updated a group!
      updateGroup(values, setSubmitting, resetForm)
    } else {
      createGroup(values, setSubmitting, resetForm)
    }
  }

  const handleAddTag = (tags, setFieldValue) => {
    const tagsCopy = JSON.parse(JSON.stringify(tags))
    if (tagsCopy.length <= 9) {
      tagsCopy.push('')
      setfocusedInput(`tagName-${tagsCopy.length - 1}`)
      setFieldValue('tags', tagsCopy)
    }
  }
  function handleTagChange(event, tags, index, setFieldValue) {
    const tagsCopy = JSON.parse(JSON.stringify(tags))
    tagsCopy[index] = event.target.value

    setFieldValue('tags', tagsCopy)
  }
  function handleRemoveTag(tags, index, setFieldValue) {
    const tagsCopy = JSON.parse(JSON.stringify(tags))
    tagsCopy.splice(index, 1)
    setFieldValue('tags', tagsCopy)
  }
  const handleKeyPress = (event, tags, setFieldValue) => {
    if (event.key === 'Enter') {
      handleAddTag(tags, setFieldValue)
    }
  }
  // eslint-disable-next-line react/no-multi-comp, react/display-name
  function renderTagRows(tags, setFieldValue) {
    return (
      <Fragment>
        {tags.map((tag, index) => (
          <div className="row mt-2 mb-2">
            <div className="col-md-10">
              <Field
                name={`tagName-${index}`}
                className={'form-control w-100'}
                placeholder={'Type an option name'}
                onChange={event =>
                  handleTagChange(event, tags, index, setFieldValue)
                }
                onKeyPress={event => handleKeyPress(event, tags, setFieldValue)}
                autoFocus={focusedInput === `tagName-${index}`}
                value={tag}
              />
            </div>
            <div className="col=md-2">
              <Button
                className="btn-sm btn-link btn-link-secondary-primary"
                onClick={() => handleRemoveTag(tags, index, setFieldValue)}
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </div>
          </div>
        ))}
        <Button
          className="btn-link"
          disabled={tags.length === 10}
          onClick={() => handleAddTag(tags, setFieldValue)}
        >
          Add option
        </Button>
      </Fragment>
    )
  }

  return (
    <Modal
      isOpen={showCreateGroupModal}
      toggle={toggleCreateGroupModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleCreateGroupModal}>
        {dynamicGroup ? `Edit Dropdown` : 'Create a Dropdown'}
      </ModalHeader>

      <Formik
        initialValues={{
          type: dynamicGroup ? dynamicGroup.type : 'Contact,Profile',
          label: dynamicGroup ? dynamicGroup.label : '',
          tags: dynamicGroup ? dynamicGroup.tags : [''],
          isMultiselect: false,
          isCreatable: false,
        }}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {formProps => {
          return isLoading ? (
            <Spinner />
          ) : (
            <Form>
              <ModalBody>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        name="label"
                        type="text"
                        label="Dropdown Name"
                        className={'form-control w-100'}
                        component={CustomInputComponent}
                        placeholder={'Status, Priority, Type, etc.'}
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="type"
                        label="Available on"
                        className={'form-control w-100'}
                        component={CustomSelectComponent}
                      >
                        <option
                          key={'Contact,Profile'}
                          value={'Contact,Profile'}
                        >
                          Lead/Member
                        </option>
                        <option key={'Team'} value={'Team'}>
                          Team
                        </option>
                        {/* <option key={'Plan'} value={'Plan'}>
                            Plan
                          </option> */}
                        {/* <option key={'Resource'} value={'Resource'}>
                            Resource
                          </option> */}
                      </Field>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        {renderTagRows(
                          formProps.values.tags,
                          formProps.setFieldValue
                        )}
                        <span className="text-muted">
                          {formProps.values.tags.length} / 10
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <ErrorFocus formik={formProps} />
              </ModalBody>
              <ModalFooter className="pt-0">
                <Button
                  color="secondary"
                  type="button"
                  onClick={toggleCreateGroupModal}
                >
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
DynamicGroupFormModal.displayName = 'DynamicGroupFormModal'

DynamicGroupFormModal.propTypes = {
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  showCreateGroupModal: PropTypes.bool,
  toggleCreateGroupModal: PropTypes.func,
  ui_actions: PropTypes.object,
  dynamicGroup: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicGroupFormModal)
