const addMinutes = require('date-fns/add_minutes')
import {
  CLEAR_CAMPUS_DATA,
  CLEAR_COMMUNITY_DATA,
  SET_ACTIVE_INTEGRATION,
  SET_DEACTIVE_INTEGRATION,
  SET_ACTIVE_INTEGRATIONS,
  SET_PENDING_INTEGRATION,
  SET_DOOR_GROUPS,
  SET_DOOR_PLACES,
  SET_USER,
  SET_INITIAL_INTEGRATION_STATE,
} from '../reduxActions/actionTypes'

import { arrayMerge } from './reducerConstants'
import { bindActionCreators } from 'redux'
import * as _ from 'lodash'

import initialState from './initialState'

export default function (integrations = {}, action) {
  let inactives
  switch (action.type) {
    case SET_PENDING_INTEGRATION:
      inactives = _.cloneDeep(integrations.inactive)
      inactives.forEach(inactiveSection => {
        // eslint-disable-next-line no-param-reassign
        inactiveSection.integrations = inactiveSection.integrations.filter(
          pendingIntegration => {
            if (pendingIntegration.type === action.payload.type) return false
            return true
          }
        )
      })
      return {
        ...integrations,
        inactive: inactives,
        pending: [...integrations.pending, action.payload],
      }
    case SET_ACTIVE_INTEGRATION:
      let pending = _.cloneDeep(integrations.pending)
      pending = pending.filter(pendingIntegration => {
        // eslint-disable-next-line no-param-reassign
        if (pendingIntegration.type === action.payload.type) return false
        return true
      })
      return {
        ...integrations,
        pending,
        active: [...integrations.active, action.payload],
      }
    case SET_DEACTIVE_INTEGRATION:
      let actives = _.cloneDeep(integrations.active)
      actives = actives.filter(activeIntegration => {
        // eslint-disable-next-line no-param-reassign
        if (activeIntegration.type === action.payload.type) return false
        return true
      })
      return {
        ...integrations,
        inactive: [...integrations.inactive, action.payload],
        active: actives,
      }
    case SET_ACTIVE_INTEGRATIONS:
      inactives = _.cloneDeep(integrations.inactive)
      const pendings = _.cloneDeep(integrations.pending)
      action.payload.forEach(activeIntegration => {
        inactives.forEach(inactiveSection => {
          // eslint-disable-next-line no-param-reassign
          inactiveSection.integrations = inactiveSection.integrations.filter(
            pendingIntegration =>
              pendingIntegration.type !== activeIntegration.type
          )
        })
        pendings.forEach(pendingSection => {
          // eslint-disable-next-line no-param-reassign
          pendingSection.integrations = pendingSection.integrations.filter(
            pendingIntegration =>
              pendingIntegration.type !== activeIntegration.type
          )
        })
      })
      return {
        ...integrations,
        inactive: inactives,
        pending: pendings,
        active: [...integrations.active, ...action.payload],
      }
    case SET_DOOR_GROUPS:
      return {
        ...integrations,
        doorAccess: { ...integrations.doorAccess, groups: action.payload },
      }
    case SET_DOOR_PLACES:
      return {
        ...integrations,
        doorAccess: { ...integrations.doorAccess, places: action.payload },
      }
    // case CLEAR_CAMPUS_DATA:
    case SET_USER:
    case SET_INITIAL_INTEGRATION_STATE:
    case CLEAR_COMMUNITY_DATA:
      return initialState.integrations
    default:
      return integrations
  }
}
