import {
  CREATE_PLAN,
  REGISTER_COMMUNITY,
  SET_PLANS,
  UPDATE_PLAN,
  ARCHIVE_PLAN,
  CREATE_PLAN_ON_FLY,
  CLEAR_CAMPUS_DATA,
} from '../reduxActions/actionTypes'
import { arrayMerge, sortByName } from './reducerConstants'

export default function (plans = [], action) {
  switch (action.type) {
    case REGISTER_COMMUNITY:
      return action.plans
    case CREATE_PLAN:
      return [...plans, Object.assign({}, action.plan)]
    case CREATE_PLAN_ON_FLY:
      return [...plans, Object.assign({}, action.plan)]
    case SET_PLANS:
      if (plans && plans.length > 0) {
        const oldPlans = plans
        const newPlans = action.plans
        return arrayMerge(oldPlans, newPlans)
      }
      // list is empty so initialize so just set it.
      return sortByName(action.plans)
    case UPDATE_PLAN:
      return plans.map(oldPlan => {
        if (oldPlan.id !== action.plan.id) {
          return oldPlan
        }
        return { ...oldPlan, ...action.plan }
      })
    case ARCHIVE_PLAN:
      return plans.filter(plan => {
        if (plan.id === action.plan.id) {
          return false
        }
        return true
      })
    case CLEAR_CAMPUS_DATA:
      return []
    default:
      return plans
  }
}
