/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import classnames from 'classnames'
import styled, { css } from 'styled-components'

const Radio = ({ selected, onChange, label, value, disabled }) => {
  return (
    <Item>
      <RadioButton
        type="radio"
        value={value}
        // disabled={disabled} // this doesn't wrk yet!
        checked={selected === value}
        onChange={() => onChange(value)}
      />
      <RadioButtonCircleLabel />
      <div>{label}</div>
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  > div {
    color: #334556;
  }
`

const RadioButtonCircleLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonCircleLabel} {
    background: #bebebe;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 6px;
      background: #eeeeee;
    }
  }
  ${props =>
    props.checked &&
    css`
      &:checked + ${RadioButtonCircleLabel} {
        background: #334556;
        border: 1px solid #334556;
        &::after {
          content: '';
          display: block;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          margin: 6px;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
          background: white;
        }
      }
    `}
`
Radio.displayName = 'Radio'
export default Radio
