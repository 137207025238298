import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import Badge from '@global/Base/Badge/Badge'
import conferencePlaceholder from '@app/img/placeholders/rooms_placeholder.jpeg'
import Anchor from '@global/Base/Anchor/Anchor'
import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import { Button } from 'reactstrap'

const Container = styled.div`
  display: flex;
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  padding: 10px;
  flex-flow: row nowrap;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
`
const ImageContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`
const Image = styled.div`
  width: 190px;
  height: 130px;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-position: center;
`
const Body = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1;
  margin: 0 15px;
`
const Detail = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
  margin-right: 10px;
`

const Title = styled.h4`
  display: flex;
  flex-flow: row nowrap;
`
const TeamWrapper = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: auto; */
  /* margin-bottom: 5px; */

  img {
    margin-right: 10px;
  }
`

const Accomodates = styled.p`
  margin-bottom: 3px;
`
const Row = styled.div`
  display: flex;
  flex-flow: row;
  width: 60%;
`
const PillsContainer = styled.div``
const ButtonStyled = styled(Button)``

const PriceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
`
const PriceWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`
const Amount = styled.h5`
  font-weight: bold;
  margin: 0;
`
const Available = styled.p`
  display: ${props => (props.available ? 'flex' : 'none')};
  align-items: flex-end;
  font-size: 14px;
  margin: 0;
  margin-bottom: 3px;
  margin-left: 10px;
  color: ${props => props.theme.colorWarning};
`
const TextButtonStyled = styled(TextButton)`
  margin: 0;
  padding: 0;
`

const ListCard = ({
  room,
  img,
  onClick,
  color,
  history,
  metric,
  match,
  className,
}) => {
  const {
    name,
    type,
    capacity,
    team,
    is_occupied,
    room_photo,
    plan,
    size,
  } = room

  let roomType = type
  if (roomType === 'Conference') roomType = 'Conference Room'
  const imageSrc = room_photo ? room_photo.file_url : conferencePlaceholder

  let planText = null
  if (plan) {
    const currencySymbol = Constants.getCurrencySymbolFromName(
      plan.currency_units
    )
    planText = plan
      ? `${currencySymbol}${Number.parseFloat(plan.cost).toFixed(2)} / ${
          plan.frequency
        } - ${plan.name}`
      : null
  }
  const handleAddTeamClick = () => {
    history.push(`/occupancy/${room.id}/edit#team`, { room })
  }
  const handleAddPlanClick = () => {
    history.push(`/occupancy/${room.id}/edit#plan`, { room })
  }

  const handleTeamClick = team => {
    if (team) {
      NavigationConstants.navigateToTeam({
        history,
        match,
        team,
      })
    }
  }
  const handlePlanClick = plan => {
    if (plan) {
      NavigationConstants.navigateToPlan({
        history,
        match,
        plan,
      })
    }
  }

  return (
    <Container className={className}>
      <Wrapper>
        <ImageContainer onClick={onClick}>
          <Image src={imageSrc} />
        </ImageContainer>
        <Body>
          <Title>
            <Anchor color="secondary" onClick={onClick}>
              {name}
            </Anchor>
            <PriceContainer>
              <PriceWrapper>
                <Badge
                  badgeShape="pill"
                  color={is_occupied ? 'danger' : 'success'}
                >
                  {is_occupied ? 'Occupied' : 'Vacant'}
                </Badge>
              </PriceWrapper>
            </PriceContainer>
          </Title>
          <Accomodates>
            <FontAwesomeIcon icon="users" /> {capacity}
          </Accomodates>
          <Accomodates>
            <FontAwesomeIcon icon="ruler-triangle" />{' '}
            {size ? (
              <React.Fragment>
                {`${size} ${metric}`}
                <sup>2</sup>
              </React.Fragment>
            ) : (
              <text className="text-muted">none</text>
            )}
          </Accomodates>
          <Accomodates>
            <React.Fragment>
              <FontAwesomeIcon icon="credit-card" />{' '}
              {planText ? (
                planText
              ) : (
                <TextButtonStyled color="primary" onClick={handleAddPlanClick}>
                  Add Suite Plan
                </TextButtonStyled>
              )}
            </React.Fragment>
          </Accomodates>
          <Accomodates>
            <FontAwesomeIcon icon="briefcase" />{' '}
            <TextButtonStyled
              color="primary"
              onClick={team ? () => handleTeamClick(team) : handleAddTeamClick}
            >
              {team ? `${team.name}` : 'Add Team'}{' '}
              {team && team.status === 'inactive' && (
                <span className="text-muted">(inactive)</span>
              )}
            </TextButtonStyled>
          </Accomodates>
        </Body>
        <Detail>
          <ButtonStyled
            className="btn btn-sm btn-outline-primary mx-1"
            onClick={onClick}
          >
            View
          </ButtonStyled>
        </Detail>
      </Wrapper>
    </Container>
  )
}

ListCard.propTypes = {
  room: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  img: PropTypes.string,
  available: PropTypes.bool,
  color: PropTypes.string,
  metric: PropTypes.string,
  className: PropTypes.string,
}

ListCard.displayName = 'ListCard'

export default ListCard
