/* eslint-disable react/prop-types,no-trailing-spaces,camelcase */
import React from 'react'
import defaultCompanyPic from '../../../img/placeholders/otto_black_white.jpeg'
import { Button } from 'reactstrap'
import styled, { css } from 'styled-components'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as Constants from '../../Global/Constants'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import moment from 'moment'
import RoundedImage from '@global/RoundedImage'
import Anchor from '@global/Base/Anchor/Anchor'

const Container = styled.div`
  ${props => {
    if (props.inactive) {
      return css`
        .text-muted {
          color: #778992 !important;
        }
      `
    }
    return null
  }}
`
const InfoContainer = styled.div`
  opacity: ${props => (props.inactive ? 0.6 : 1)};
`
const ImageContianer = styled.div`
  position: relative;
  margin: auto;
  opacity: ${props => (props.inactive ? 0.6 : 1)};
`

const TeamRow = ({
  company,
  index,
  editCompanyClicked,
  showActions,
  includeMarginsAndPadding,
  includePhoto = true,
  canEdit,
  showJoinDate,
  showViewButton,
  isInactive,
}) => {
  const defaultPicture = defaultCompanyPic
  const photo = company.team_photo
  const { name, size, joined_at, created_at } = company

  let shouldShowJoinDate = true
  let showActionButtons = true

  let showMarginsAndPadding = true

  if (showActions !== null && showActions !== undefined) {
    showActionButtons = showActions
  }
  if (
    includeMarginsAndPadding !== null &&
    includeMarginsAndPadding !== undefined
  ) {
    showMarginsAndPadding = includeMarginsAndPadding
  }
  if (showJoinDate !== null && showJoinDate !== undefined) {
    shouldShowJoinDate = showJoinDate
  }

  return (
    <Container
      key={index}
      className={`${showMarginsAndPadding && 'border-bottom-1 mt-2'}`}
      inactive={isInactive}
    >
      <div className={`row ${showMarginsAndPadding && 'p-2'}`}>
        {includePhoto && (
          <div className="col-1 d-flex align-items-center">
            <Anchor onClick={() => editCompanyClicked(company, event)}>
              <ImageContianer inactive={isInactive}>
                <RoundedImage
                  photo={photo}
                  alt={`${company.first_name} ${company.last_name}`}
                  size="medium"
                  className="img-responsive mr-3"
                />
              </ImageContianer>
            </Anchor>
          </div>
        )}

        <InfoContainer
          className={!includePhoto && !showActionButtons ? 'col-12' : 'col-8'}
          inactive={isInactive}
        >
          <h4>
            <Anchor
              color="secondary"
              className="my-0 p-0"
              onClick={() => editCompanyClicked(company, event)}
            >
              {name}
            </Anchor>
          </h4>
          {shouldShowJoinDate && (
            <h6>
              <p className="text-primary my-0 p-0">
                <FontAwesomeIcon icon="calendar-alt" /> Joined{' '}
                {moment(joined_at ? joined_at : created_at)
                  .utc()
                  .local()
                  .format('MMM DD, YYYY')}
              </p>
            </h6>
          )}
          <h6>
            {size ? size : 0} {size > 1 || !size ? 'members' : 'member'}
          </h6>
          {showViewButton && (
            <TextButton
              color="primary"
              onClick={event => editCompanyClicked(company, event)}
            >
              View
            </TextButton>
          )}
        </InfoContainer>
        {showActionButtons && (
          <div className="col d-flex justify-content-end align-items-center">
            <Button
              className="btn btn-outline-primary"
              onClick={event => editCompanyClicked(company, event)}
            >
              {canEdit ? 'Manage' : 'View'}
            </Button>
          </div>
        )}
      </div>
    </Container>
  )
}

TeamRow.displayName = 'TeamRow'

export default TeamRow
