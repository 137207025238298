/* eslint-disable no-invalid-this,no-unused-vars,max-depth,react/jsx-boolean-value,react/sort-comp,radix,no-param-reassign,max-statements,max-params */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as _ from 'lodash'
import styled from 'styled-components'

import * as planActions from '@reduxActions/planActions'
import * as stripeActions from '@reduxActions/stripeActions'
import * as teamActions from '@reduxActions/teamActions'
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import CreditCardForm from '@global/Stripe/CreditCardForm'
import * as Roles from '@app/config/roles'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap'

import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import { toast } from 'react-toastify'
import moment from 'moment'
import { captureException } from '@global/ErrorFactory'
import QuickSearch from '@global/Search/QuickSearch'
import Spinner from '@global/Spinner'

import * as Constants from '@global/Constants'

import { convertStripeErrorToMessage } from '@global/ErrorFactory'

import BillingMethodModal from '@global/Modal/BillingMethodModal'
import * as TeamBillingConstants from '@global/Constants/TeamBillingConstants'
import DiscountModal from '@global/Modal/DiscountModal'
import VerifyBankModal from '@global/Modal/VerifyBankModal'
import PaymentSourceComponent from '@global/Stripe/PaymentSourceComponent'
import { LabelStyled } from '@global/Form/FormComponents'

import {
  getCustomerDefaultSource,
  shouldDisableChargeNow,
  getIntendedPaymentSource,
  doPlanFrequenciesMatch,
  convertIntervalToFrequency,
  frequencies,
} from '@global/Constants/PaymentConstants'

import PlanRow from '../../../../Plans/PlanRow'
import Subscription from '../../../../Plans/Subscription'
import InvoiceDetail from '../../../../Payments/InvoiceDetail/InvoiceDetail'

import RemovePlanModal from '../../../../Plans/RemovePlanModal'
import ChangeBillingTypeModal from './ChangeBillingTypeModal'
import CancelSubscriptionModal from './CancelSubscriptionModal'
import AddPlanModal from './AddPlanModal'
import Badge from '@global/Base/Badge/Badge'
import Callout from '@global/Base/Callout/Callout'
import PauseMembershipModal from './PauseMembershipModal'
import {
  resolveFetchingStatus,
  FETCHING_PLANS,
} from '@global/Constants/FetchingConstants'

const AlertStyled = styled(Alert)`
  margin-bottom: 0px;
`
class TeamMembership extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    plan_actions: PropTypes.object,
    stripe_actions: PropTypes.object,
    team_actions: PropTypes.object,
    team: PropTypes.object,
    teams: PropTypes.array,
    user: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    plans: PropTypes.array,
    isFetchingPlans: PropTypes.bool,
    stripeSubscriptions: PropTypes.array,
    isInactive: PropTypes.bool,
    toggleShouldRefreshPayments: PropTypes.func,
  }

  constructor(props) {
    super(props)
    const isStripeConnected = Boolean(
      this.props.activeCommunity.stripe_account_id
    )
    const commPrefs = this.props.activeCommunity.community_preference

    const { chargeNowDisabled, billingType } = shouldDisableChargeNow(commPrefs)

    this.state = {
      errors: [],
      isStripeConnected,
      conferenceHoursAllowance: 0, // this is the amount to ADD, not what's on the company right now.
      isLoadingSource: false,
      teamBillingState: {},
      showSubscriptionDeletionModal: false,
      showRemovePlanModal: false,
      showPlanAddModal: false,
      showBillingMethodModal: false,
      showAddPlanDropdown: true,
      showVerifyBankModal: false,
      shouldShowPlanPickerInModal: true,
      newPlanProrateStatus: 'no',
      showProrationOptions: true,
      showUpcomingInvoiceModal: false,
      showAddDiscountModal: false,
      startDateFocused: false,
      /*
       * Following state is used for when they are on no plans or only free plans
       *  It's used when creating a new subscription
       *  */
      needToCreateNewSubscription: false,
      subscriptionCart: [],
      billingType: billingType,
      chargeNowDisabled: chargeNowDisabled,
      monthlyBillDate: commPrefs.default_billing_cycle_day,
      startDate: moment(),
      isProrated: true,
    }
  }

  componentDidMount() {
    const { plans, activeCampus, isFetchingPlans, plan_actions } = this.props
    this.refreshStripeData(this.props.team)
    if (!isFetchingPlans && (!plans || (plans && plans.length === 0)))
      plan_actions.getPlans(activeCampus.id)
  }

  refreshStripeData = team => {
    if (
      team &&
      !this.state.isLoading &&
      team.stripe_customer_id &&
      this.state.isStripeConnected
    ) {
      if (!this.state.isLoading) {
        this.setState({ isLoading: true })

        this.props.stripe_actions
          .getCustomer({
            team_id: team.id,
            community_id: this.props.activeCommunity.id,
          })
          .then(response => {
            this.handleStripeCustomerResponse(response)
          })
          .catch(err => {
            toast.error(convertStripeErrorToMessage(err))
          })
          .finally(() => this.setState({ isLoading: false }))
      }
    } else if (this.state.isStripeConnected) {
      toast.error(
        "Whoops. It looks like there's a problem with this team. Support has been notified!"
      )

      captureException({
        text: `Team doesn't have stripe_customer_id, but billing is disabled! team_id: ${
          this.props.team ? this.props.team.id : null
        }`,
      })
    } else {
      this.setState({
        stripeSubscriptions: [],
        teamCustomer: null,
      })
    }
  }
  cancelNewBilling = () => {
    this.setState({
      needToCreateNewSubscription: false,
      subscriptionCart: [],
      shouldShowPlanPickerInModal: true,
    })
  }
  addCard = cardToken => {
    const self = this
    const params = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      stripe_customer_id: this.props.team.stripe_customer_id,
      card_token: cardToken.id,
    }
    this.setState({
      isLoadingSource: true,
    })
    this.props.stripe_actions
      .addTokenToCustomer(params)
      .then(response => {
        toast.success('Added card!')
        self.setState({
          showBillingMethodModal: false,
          teamCustomer: response.customer,
          isLoadingSource: false,
        })
      })
      .catch(err => {
        toast.error(convertStripeErrorToMessage(err))
        this.setState({
          isLoadingSource: false,
        })
      })
  }
  addBank = bankToken => {
    const self = this
    const params = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      stripe_customer_id: this.props.team.stripe_customer_id,
      bank_token: bankToken.id,
    }
    this.props.stripe_actions
      .addTokenToCustomer(params)
      .then(response => {
        toast.success('Added bank.')
        self.setState({
          showBillingMethodModal: false,
          teamCustomer: response.customer,
        })
      })
      .catch(err => {
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  deleteSource = sourceID => {
    const self = this
    const params = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      stripe_customer_id: this.props.team.stripe_customer_id,
      source_id: sourceID,
    }
    this.setState({
      isLoadingSource: true,
    })
    this.props.stripe_actions
      .removeCustomerBillingSource(params)
      .then(response => {
        toast.success('Deleted payment source.')
        self.setState({
          showBillingMethodModal: false,
          teamCustomer: response.customer,
          isLoadingSource: false,
        })
      })
      .catch(err => {
        this.setState({
          isLoadingSource: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  handleInputChange = event => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'discountExpirationDuration') {
      // don't allow negative values
      if (value < 1) {
        value = 1
      }
    } else if (name === 'newPlanProrateStatus') {
      if (this.state.newPlanProrateStatus === 'yes') {
        value = 'no'
      } else {
        value = 'yes'
      }
    }
    this.setState({
      [name]: value,
    })
  }

  handleImageUpload = obj => {
    this.setState({
      teamPhoto: obj.file,
      teamPhotoPreview: obj.imagePreviewUrl,
    })
  }

  toggleChangePlanModal = () => {
    this.setState({
      selectedPlan: null,
      shouldShowPlanPickerInModal: true,
    })
  }
  togglePauseMembershipModal = index => {
    this.setState({
      showPauseMembershipModal: !this.state.showPauseMembershipModal,
      selectedSubscription: this.state.showPauseMembershipModal
        ? null
        : this.state.stripeSubscriptions[index],
    })
  }

  viewUpcomingInvoiceClicked = index => {
    if (this.props.team.stripe_customer_id) {
      const params = {
        subscription_id: this.state.stripeSubscriptions[index].id,
        team_id: this.props.team.id,
        community_id: this.props.activeCommunity.id,
        expand: JSON.stringify(['customer', 'charge']),
      }
      this.props.stripe_actions
        .getUpcomingInvoice(params)
        .then(response => {
          this.setState({
            upcomingInvoice: response && response.invoice,
            subscriptionForNextInvoice: this.state.stripeSubscriptions[index],
          })
          this.toggleUpcomingInvoiceModal()
        })
        .catch(err => {
          toast.error(convertStripeErrorToMessage(err))
        })
    }
  }
  previewSubscriptionChangesClicked = subscription => {
    const subscriptionCart = this.state.subscriptionCart

    const isFree = Boolean(parseInt(subscriptionCart[0].plan.cost) === 0)
    if (!isFree) {
      const subscriptionItems = this.convertCartToSubscriptionItems(
        subscriptionCart
      )

      for (let counter = 0; counter < subscriptionItems.length; counter++) {
        const newPlan = subscriptionItems[counter]
        const newPlanQuantity = subscriptionItems[counter].quantity
        for (
          let subItemCount = 0;
          subItemCount < subscription.items.data.length;
          subItemCount++
        ) {
          const subscriptionItem = subscription.items.data[subItemCount]
          if (`${subscriptionItem.plan.id}` === `${newPlan.plan}`) {
            newPlan.quantity =
              Number(newPlanQuantity) + Number(subscriptionItem.quantity)
            newPlan.id = subscriptionItem.id
          }
        }
      }
      const params = {
        subscription_id: subscription.id,
        community_id: this.props.activeCommunity.id,
        team_id: this.props.team.id,
        subscription_items: JSON.stringify(subscriptionItems),
        subscription_prorate: Boolean(
          this.state.newPlanProrateStatus === 'yes'
        ),
        expand: JSON.stringify(['customer', 'charge']),
      }
      this.props.stripe_actions
        .getUpcomingInvoice(params)
        .then(response => {
          this.setState({
            upcomingInvoice: response && response.invoice,
          })
          this.toggleUpcomingInvoiceModal()
        })
        .catch(err => {
          toast.error(convertStripeErrorToMessage(err))
        })
    }
  }
  cancelSubscriptionClicked = index => {
    this.setState({
      subscriptionInQuestion: this.state.stripeSubscriptions[index],
      showSubscriptionDeletionModal: true,
    })
  }
  confirmCancelSubscriptionClicked = () => {
    this.setState({
      showSubscriptionDeletionModal: false,
      isLoading: true,
    })
    const plansToRemove = []
    for (
      let counter = 0;
      counter < this.state.subscriptionInQuestion.items.data.length;
      counter++
    ) {
      const stripePlan = this.state.subscriptionInQuestion.items.data[counter]
      plansToRemove.push({
        id: stripePlan.plan.id,
        quantity: stripePlan.quantity,
      })
    }
    const params = {
      subscription_id: this.state.subscriptionInQuestion.id,
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      stripe_customer_id: this.props.team.stripe_customer_id,
      team_id: this.props.team.id,
      plans_to_remove: JSON.stringify(plansToRemove),
    }
    this.props.stripe_actions
      .cancelSubscription(params)
      .then(response => {
        this.handleStripeCustomerResponse(response)
        this.setState({
          startDate: moment(),
        })
        toast.success('Cancelled the membership.')
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  handleAddPlanClicked = subscription => {
    if (subscription) {
      // adding to existing subscription
      // should show the modal popup
      this.setState({
        existingSubscription: subscription,
        showPlanAddModal: true,
      })
    } else {
      // adding to a brand new subscription
      // should show all teh subscription compoennts and billing info
      this.setState({
        showPlanAddModal: false,
        showAddPlanDropdown: true,
      })
    }
  }
  handleChangeFromFreePlanClicked = () => {
    this.setState({
      showPlanAddModal: false,
      showAddPlanDropdown: true,
      needToCreateNewSubscription: true,
    })
  }
  convertCartToSubscriptionItems(subscriptionCart) {
    const plans = [Object.assign({}, subscriptionCart[0].plan)]
    const subItems = []
    for (let counter = 0; counter < plans.length; counter++) {
      const subItem = {
        id: plans[counter].subscriptionItemId,
        plan: plans[counter].id,
        quantity: subscriptionCart[counter].uiState.quantity,
      }
      subItems.push(subItem)
    }
    return subItems
  }
  confirmAddPlanSaveClicked = () => {
    const subscriptionCart = this.state.subscriptionCart
    const plans = [Object.assign({}, subscriptionCart[0].plan)]
    const isFree = Boolean(parseInt(subscriptionCart[0].plan.cost) === 0)
    if (!isFree) {
      const subscriptionItems = this.convertCartToSubscriptionItems(
        subscriptionCart
      )

      if (this.state.useExistingSubscription) {
        // adding a plan of the same frequency
        //todo add conference_hours_allowance?
        this.addPlanToSubscriptionApiCall({
          subscription_id: this.state.existingSubscription.id,
          stripe_customer_id: this.props.team.stripe_customer_id,
          stripe_account_id: this.props.activeCommunity.stripe_account_id,
          plans: JSON.stringify(subscriptionItems),
          prorate: Boolean(this.state.newPlanProrateStatus === 'yes'),
          conference_hours_allowance: this.state.conferenceHoursAllowance,
          team_id: this.props.team.id,
        })
      } else {
        // adding a plan of a different frequency
        let billingType = this.state.billingType // this starts as bill_automatically;
        for (
          let counter = 0;
          counter < this.state.stripeSubscriptions.length;
          counter++
        ) {
          const subscription = this.state.stripeSubscriptions[counter]
          if (subscription) {
            // need to match other subscriptions billing type as we don't
            // currently have access to change the billing type without the full add plan flow.
            billingType = subscription.billing
          }
        }
        this.setState(
          {
            isProrated: false,
            billingType,
            monthlyBillDate: this.state.startDate.date(),
          },
          () => {
            this.createSubscriptionForExistingTeam(null)
          }
        )
      }
    } else {
      // the plan is free
      const newPlans = []
      for (let counter = 0; counter < plans.length; counter++) {
        const planItem = {
          id: plans[counter].id,
          quantity: subscriptionCart[counter].uiState.quantity,
        }
        newPlans.push(planItem)
      }
      //todo add conference_hours_allowance?
      this.addCoworksPlanToTeam({
        id: this.props.team.id,
        plan_ids: JSON.stringify(newPlans),
        campus_id: this.props.activeCampus.id,
      })
    }
  }
  addPlanToSubscriptionApiCall = params => {
    this.setState(
      {
        showPlanAddModal: false,
        showAddPlanDropdown: true,
        shouldShowPlanPickerInModal: true,
        isLoading: true,
        subscriptionCart: [],
      },
      () => {
        this.props.stripe_actions
          .updateSubscription(params)
          .then(response => {
            this.handleStripeCustomerResponse(response)
            toast.success('Updated the membership.')
            this.props.toggleShouldRefreshPayments()
          })
          .catch(err => {
            this.setState({ isLoading: false })
            toast.error(convertStripeErrorToMessage(err))
          })
      }
    )
  }
  addCoworksPlanToTeam = params => {
    this.setState({
      showPlanAddModal: false,
      subscriptionCart: [],
      isLoading: true,
    })
    this.props.team_actions
      .addCoworksPlan(params)
      .then(() => {
        // non stripe!
        this.setState({ isLoading: false })
        toast.success('Added the plan.')
      })
      .catch(err => {
        this.setState({ isLoading: false })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  handleStripeCustomerResponse(response) {
    const customer = response.customer
    const stripeSubs = customer.subscriptions
      ? Constants.parseStripeSubscriptions(customer, this.props.plans)
      : null
    const newSubs = []
    stripeSubs.forEach(sub => {
      const newSubscription = _.clone(sub)
      if (newSubscription.discount && newSubscription.discount.coupon) {
        newSubscription.discount.coupon.amount_off /= 100
      }
      newSubs.push(newSubscription)
    })
    this.setState({
      teamCustomer: customer,
      stripeSubscriptions: newSubs,
      isLoading: false,
    })
  }
  removePlanFromSubscriptionClicked = (subscriptionItem, subscriptionId) => {
    const stripeSubscriptions = this.state.stripeSubscriptions
    for (let counter = 0; counter < stripeSubscriptions.length; counter++) {
      if (
        stripeSubscriptions[counter].id === subscriptionId &&
        stripeSubscriptions[counter].items.data.length === 1 &&
        subscriptionItem.quantity === 1
      ) {
        // do the cancel all plan instead
        this.setState({
          subscriptionInQuestion: stripeSubscriptions[counter],
          showSubscriptionDeletionModal: true,
        })
        break
      } else {
        // do a regular remove plan from subscription
        const obj = Constants.initializeEmptyCartObject(false)
        obj.plan = subscriptionItem.plan
        obj.subscriptionId = subscriptionId
        obj.subscriptionItemId = subscriptionItem.id
        obj.uiState.quantity = subscriptionItem.quantity
        obj.uiState.quantityToRemove = 1
        this.setState({
          planObjectToRemove: obj,
          showRemovePlanModal: true,
        })
      }
    }
  }
  toggleUpcomingInvoiceModal = () =>
    this.setState({
      showUpcomingInvoiceModal: !this.state.showUpcomingInvoiceModal,
      subscriptionForNextInvoice: this.state.showUpcomingInvoiceModal
        ? null
        : this.state.subscriptionForNextInvoice,
    })
  toggleRemovePlanModal = () =>
    this.setState({
      showRemovePlanModal: !this.state.showRemovePlanModal,
      planObjectToRemove: null,
      coworksPlanToRemove: null,
    })

  confirmRemovePlanClickedModal = () => {
    if (this.state.planObjectToRemove) {
      // removing a plan from a subscription!
      // currently only sending up one id, but api supports many plans!
      const planObjs = [Object.assign({}, this.state.planObjectToRemove)]
      const subscriptionItems = []
      for (let counter = 0; counter < planObjs.length; counter++) {
        const subItem = {
          id: planObjs[counter].subscriptionItemId,
          quantity_to_remove: this.state.planObjectToRemove.uiState
            .quantityToRemove,
          plan: planObjs[counter].plan.id,
        }
        subscriptionItems.push(subItem)
      }
      this.removePlanFromSubscriptionApiCall({
        subscription_id: this.state.planObjectToRemove.subscriptionId,
        plans: JSON.stringify(subscriptionItems),
        stripe_account_id: this.props.activeCommunity.stripe_account_id,
        stripe_customer_id: this.props.team.stripe_customer_id,
        prorate: false,
      })
    } else if (this.state.coworksPlanToRemove) {
      // removing a coworks plan from a team!
      // currently only sending up one plan id, but api supports many plans!
      const plans = []
      const planToRemove = {
        id: this.state.coworksPlanToRemove.plan.id,
        quantity_to_remove: this.state.coworksPlanToRemove.uiState
          .quantityToRemove,
      }
      plans.push(planToRemove)
      this.removePlanFromCoworksApiCall({
        plans: JSON.stringify(plans),
        id: this.props.team.id,
        campus_id: this.props.activeCampus.id,
      })
    } else {
      console.error("Shouldn't meet this state!")
    }
  }
  verifyBank = (params, customerId) => {
    const stripeParams = {
      amount1: params.amount1,
      amount2: params.amount2,
      bank_token: params.bankToken,
      customer_id: customerId,
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
    }
    this.setState({
      isLoadingSource: true,
    })
    this.props.stripe_actions
      .verifyBank(stripeParams)
      .then(response => {
        toast.success('Verified the bank account.')
        this.setState({
          teamCustomer: response.customer,
          showBillingMethodModal: false,
          showVerifyBankModal: false,
          isLoadingSource: false,
        })
      })
      .catch(err => {
        this.setState({
          showVerifyBankModal: false,
          isLoadingSource: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  removePlanFromSubscriptionApiCall = params => {
    this.setState(
      {
        newPlanProrateStatus: 'yes',
        showRemovePlanModal: false,
        planObjectToRemove: null,
        isLoading: true,
        /*didParseSubscriptions: false,  // so we get updates in props*/
      },
      () => {
        this.props.stripe_actions
          .updateSubscription(params)
          .then(response => {
            this.handleStripeCustomerResponse(response)
            toast.success('Removed the plan!')
          })
          .catch(err => {
            this.setState({ isLoading: false })
            toast.error(convertStripeErrorToMessage(err))
          })
      }
    )
  }
  removePlanFromCoworksApiCall = params => {
    this.setState({
      showRemovePlanModal: false,
      coworksPlanToRemove: null,
      isLoading: true,
    })
    this.props.team_actions
      .removeCoworksPlan(params)
      .then(() => {
        this.setState({
          isLoading: false,
        })
        toast.success('Removed the plan.')
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  handleRemoveCoworksPlan = plan => {
    // do a regular remove plan from subscription
    const obj = Constants.initializeEmptyCartObject(false)
    obj.plan = plan
    obj.uiState.quantityToRemove = 1 // set the initial amount to remove.
    obj.uiState.quantity = plan.quantity // set the initial amount to remove.
    this.setState({
      coworksPlanToRemove: obj,
      showRemovePlanModal: true,
    })
  }
  deleteDiscount = stripeSubscription => {
    this.setState({ isLoading: true })
    this.props.stripe_actions
      .deleteDiscount({
        stripe_account_id: this.props.activeCommunity.stripe_account_id,
        subscription_id: stripeSubscription.id,
        stripe_customer_id: this.props.team.stripe_customer_id,
      })
      .then(response => {
        this.handleStripeCustomerResponse(response)

        toast.success('Removed the discount.')
        this.props.toggleShouldRefreshPayments()
      })
      .catch(err => {
        this.setState({ isLoading: false })
        toast.error(convertStripeErrorToMessage(err))
      })
  }

  calculatePlanGrandTotal = stripeSubscription => {
    let total = 0
    // Iterate through the stripe plans of each stripe subscription and calculate the subscription cost
    for (
      let planCounter = 0;
      planCounter < stripeSubscription.items.data.length;
      planCounter++
    ) {
      total +=
        stripeSubscription.items.data[planCounter].plan.amount *
        stripeSubscription.items.data[planCounter].quantity
    }
    return total
  }

  toggleAddDiscountModal = () => {
    this.setState({
      showAddDiscountModal: !this.state.showAddDiscountModal,
    })
  }
  toggleBillingMethodModal = () => {
    this.setState({
      showBillingMethodModal: !this.state.showBillingMethodModal,
    })
  }
  toggleAddPlanModal = () => {
    this.setState({
      showPlanAddModal: !this.state.showPlanAddModal,
      subscriptionCart: [],
      shouldShowPlanPickerInModal: true,
      showAddPlanDropdown: true,
    })
  }
  showAddDiscountModalForSub = stripeSubscription => {
    this.setState({
      subForDiscountModal: stripeSubscription,
      planGrandTotal: this.calculatePlanGrandTotal(stripeSubscription),
      showAddDiscountModal: !this.state.showAddDiscountModal,
    })
  }
  /* end of discount function  */
  /* START of functions used for when creating a new stripe subscription */
  handleTypeChange = event => this.setState({ billingType: event.target.value })

  handleQuantityChange = (index, event) => {
    const amount = event.target.value
    if (amount > 0 || amount === '' || amount <= 50) {
      let cartCopy = Object.assign([], this.state.subscriptionCart)
      cartCopy[index].uiState.quantity = amount
      cartCopy = Constants.calculatePlanTotalsWithQuantities(cartCopy)
      const total = Constants.calculateTeamPlanTotal({
        cart: cartCopy,
        discountTotalDollars:
          this.state.discountObject && this.state.discountObject.amount_off
            ? this.state.discountObject
            : 0,
        prefs: this.props.activeCommunity.community_preference,
        customer: null,
      })
      const subTotal = Constants.calculateTeamPlanTotal({
        cart: cartCopy,
        discountTotalDollars: null,
        customer: null,
        prefs: null,
      }) // don't factor discounts
      const conferenceHoursToAdd = Constants.getConferenceRoomHours(cartCopy)
      this.setState({
        planGrandTotal: total,
        planSubtotal: subTotal,
        subscriptionCart: cartCopy,
        conferenceHoursAllowance: conferenceHoursToAdd,
      })
    }
  }
  handleExistingPlanQuantityChange = (index, event) => {
    this.handleQuantityChange(index, event)
  }
  handleQuantityToRemoveChange = event => {
    const amount = event.target.value
    if (this.state.planObjectToRemove) {
      const planObject = JSON.parse(
        JSON.stringify(this.state.planObjectToRemove)
      )
      if (amount > 0 || amount === '' || amount <= 50) {
        planObject.uiState.quantityToRemove = amount
      }
      this.setState({
        planObjectToRemove: planObject,
      })
    } else if (this.state.coworksPlanToRemove) {
      const planObject = JSON.parse(
        JSON.stringify(this.state.coworksPlanToRemove)
      )
      if (amount > 0 || amount === '' || amount <= 50) {
        planObject.uiState.quantityToRemove = amount
      }
      this.setState({
        coworksPlanToRemove: planObject,
      })
    }
  }
  handleProrateToggle = isProrated => {
    this.setState({
      isProrated: isProrated,
    })
  }
  handleProrateLength = value => {
    this.setState({
      prorateLength: value,
    })
  }
  handleMonthlyBillDateChanged = monthlyBillDate => {
    this.setState({
      monthlyBillDate: monthlyBillDate,
    })
  }
  handleChangeBillingType = stripeSubscription => {
    if (!this.state.showChangeBillingTypeModal) {
      this.setState(
        {
          subscriptionToChangeBillingType: stripeSubscription,
        },
        () => {
          this.toggleChangeBillingTypeModal()
        }
      )
    } else {
      const subscriptionToChangeBillingType = this.state
        .subscriptionToChangeBillingType
      const proposed =
        subscriptionToChangeBillingType.billing === 'charge_automatically'
          ? 'send_invoice'
          : 'charge_automatically'
      const params = {
        subscription_id: stripeSubscription.id,
        stripe_account_id: this.props.activeCommunity.stripe_account_id,
        stripe_customer_id: this.props.team.stripe_customer_id,
        billing_type: proposed,
      }
      if (proposed === 'send_invoice') {
        params.days_until_due = 3
      }
      this.setState({
        showChangeBillingTypeModal: false,
        subscriptionToChangeBillingType: null,
      })
      this.props.stripe_actions
        .updateSubscription(params)
        .then(response => {
          toast.success('Updated the billing type.')
        })
        .catch(err => {
          toast.error(convertStripeErrorToMessage(err))
        })
    }
  }
  toggleChangeBillingTypeModal = () => {
    const newState = {
      showChangeBillingTypeModal: !this.state.showChangeBillingTypeModal,
    }
    if (newState === false) {
      newState.subscriptionToChangeBillingType = null
    }
    this.setState(newState)
  }
  toggleCancelSubscriptionModal = () => {
    this.setState({
      showSubscriptionDeletionModal: !this.state.showSubscriptionDeletionModal,
    })
  }
  toggleVerifyBankModal = () => {
    this.setState({
      showVerifyBankModal: !this.state.showVerifyBankModal,
    })
  }
  getProrateLength = () => {
    return Constants.getMonthlyBillDate(
      this.state.startDate,
      this.state.monthlyBillDate
    ).diff(this.state.startDate.startOf('day'), 'days')
  }
  removePlanFromCart = index => {
    let needToCreateNewSubscription = this.state.needToCreateNewSubscription
    const {
      shouldShowPlanPickerInModal,
      subscriptionCart,
      discountObject,
    } = this.state
    if (subscriptionCart.length >= 1) {
      const cartCopy = JSON.parse(JSON.stringify(subscriptionCart))
      cartCopy.splice(index, 1)
      if (cartCopy.length === 0) {
        needToCreateNewSubscription = false
      }
      const total = Constants.calculateTeamPlanTotal({
        cart: cartCopy,
        discountTotalDollars:
          discountObject && discountObject.amount_off ? discountObject : 0,
        prefs: this.props.activeCommunity.community_preference,
        customer: null,
      })
      const subTotal = Constants.calculateTeamPlanTotal({
        cart: cartCopy,
        discountTotalDollars: null,
        prefs: null,
        customer: null,
      })
      this.setState(
        {
          planGrandTotal: total,
          planSubtotal: subTotal,
          subscriptionCart: [],
          needToCreateNewSubscription,
          shouldShowPlanPickerInModal: shouldShowPlanPickerInModal,
        },
        () => {
          //TODO add this back
          //this.validateField();
        }
      ) // for form validation of the subscription cart
    }
  }
  isOnlyFreePlanSelected = subscriptionCart => {
    // helper method to detemine if only a free plan is selected.
    if (
      subscriptionCart.length === 1 &&
      subscriptionCart[0].plan &&
      parseInt(subscriptionCart[0].plan.cost) === 0
    ) {
      return true
    }
    return false
  }
  /* END of functions used for when creating a new stripe subscription */
  /* Autosuggestion stuff */
  handleSearchItemSelected = selectedPlan => {
    const {
      existingSubscription,
      stripeSubscriptions,
      subscriptionCart,
      discountObject,
    } = this.state
    if (selectedPlan) {
      // we're not clearing the box
      if (existingSubscription) {
        // adding a plan to an existing subscription
        let { cart } = this.pushEmptyPlanObjectToCart()
        cart[cart.length - 1].plan = selectedPlan
        cart[cart.length - 1].uiState.quantity = 1
        cart = Constants.calculatePlanTotalsWithQuantities(cart)
        const total = Constants.calculateTeamPlanTotal({
          cart: cart,
          discountTotalDollars:
            discountObject && discountObject.amount_off ? discountObject : 0,
          prefs: this.props.activeCommunity.community_preference,
          customer: null,
        })
        const subTotal = Constants.calculateTeamPlanTotal({
          cart,
          discountTotalDollars: null,
          prefs: null,
          customer: null,
        }) // don't factor discounts
        const conferenceHoursToAdd = Constants.getConferenceRoomHours(cart)
        const state = {
          planGrandTotal: total,
          planSubtotal: subTotal,
          subscriptionCart: cart,
          showAddPlanDropdown: false,
          shouldShowPlanPickerInModal: false,
          selectedPlan,
          conferenceHoursAllowance: conferenceHoursToAdd,
        }
        if (
          !doPlanFrequenciesMatch(
            selectedPlan,
            existingSubscription.items.data[0].plan
          )
        ) {
          // This will swap the 'existing subscription' with a subscription that shares the same billing
          // frequency (month, year) so that the plan gets added to the right subscription
          let found = false
          for (
            let counter = 0;
            counter < stripeSubscriptions.length;
            counter++
          ) {
            const subscription = stripeSubscriptions[counter]
            for (
              let counter2 = 0;
              counter2 < subscription.items.data.length;
              counter2++
            ) {
              const subdetails = subscription.items.data[counter2]
              if (doPlanFrequenciesMatch(selectedPlan, subdetails.plan)) {
                found = true
                state.useExistingSubscription = true
                state.existingSubscription = subscription
              }
            }
          }
          if (!found) {
            state.useExistingSubscription = false
          }
        } else {
          state.useExistingSubscription = true
        }
        this.setState(state)
      } else if (
        // adding a entire new subscription
        subscriptionCart.length <= 0 ||
        doPlanFrequenciesMatch(selectedPlan, subscriptionCart[0].plan)
      ) {
        if (!Constants.planExistsInCart(selectedPlan, subscriptionCart)) {
          let { cart } = this.pushEmptyPlanObjectToCart()
          const showAdd = cart.length >= 1 // shows the add another plan button when the dropdown of the first plan is selected.
          cart[cart.length - 1].plan = selectedPlan
          cart = Constants.calculatePlanTotalsWithQuantities(cart)
          const total = Constants.calculateTeamPlanTotal({
            cart,
            discountTotalDollars:
              this.state.discountObject && this.state.discountObject.amount_off
                ? this.state.discountObject
                : 0,
            prefs: this.props.activeCommunity.community_preference,
            customer: null,
          })

          const subTotal = Constants.calculateTeamPlanTotal({
            cart,
            discountTotalDollars: null,
            prefs: null,
            customer: null,
          }) // don't factor discounts
          const conferenceHoursToAdd = Constants.getConferenceRoomHours(cart)

          this.setState({
            planGrandTotal: total,
            planSubtotal: subTotal,
            subscriptionCart: cart,
            showAddPlanButton: showAdd,
            showAddPlanDropdown: false,
            shouldShowPlanPickerInModal: false,
            showPlanAddModal: false,
            needToCreateNewSubscription: true,
            conferenceHoursAllowance: conferenceHoursToAdd,
            isProrated: selectedPlan.frequency === 'month', // we only prorate if equal to month
          })
        } else {
          toast.error(
            'Sorry! You should increment the quantity instead of adding duplicate plans.'
          )
        }
      } else {
        toast.error(
          'Sorry! You can only stack plans that have the same bill frequency.'
        )
      }
    }
  }
  handleChangePlanClicked = () => {
    this.setState({
      subscriptionCart: [],
      shouldShowPlanPickerInModal: true,
      showAddPlanDropdown: true,
    })
  }
  handleRemoveDiscount = () => {
    this.setState({ discountObject: null })
  }
  pushEmptyPlanObjectToCart = () => {
    // this func handles calling a funciton that returns an empty cart object as well as adding it to cart.
    const obj = Constants.initializeEmptyCartObject(false)
    const cart = _.clone(this.state.subscriptionCart)
    cart.push(obj)
    let showAdd = true
    for (const cartObj of cart) {
      if (!cartObj.plan) {
        // if any of the dropdowns are not selected (when size > 1), the add plans button should not show.
        showAdd = false
      }
    }
    return { cart, showAdd }
  }
  handleStartDateChanged = date => {
    this.setState({
      startDate: date,
    })
  }
  handleStartDateFocused = focused => {
    this.setState({
      startDateFocused: focused,
    })
  }
  handleSaveDiscountClicked = couponObject => {
    if (this.state.subForDiscountModal) {
      this.setState({ isLoading: true })
      this.props.stripe_actions
        .createAndAddCouponToSubscription(couponObject)
        .then(response => {
          this.handleStripeCustomerResponse(response)
          toast.success('Created the discount.')
          this.toggleAddDiscountModal()
        })
        .catch(err => {
          this.setState({ isLoading: false })
          toast.error(convertStripeErrorToMessage(err))
        })
    } else {
      //todo this is where we save the discount object to state instead of saving it to api
      this.setState({
        discountObject: couponObject,
      })
      this.toggleAddDiscountModal()
    }
  }

  handlePauseMembership = params => {
    this.setState({ isLoading: true })
    const newParams = _.cloneDeep(params)

    newParams.subscription_id = this.state.selectedSubscription.id
    newParams.stripe_customer_id = this.props.team.stripe_customer_id
    newParams.community_id = this.props.activeCommunity.id

    this.props.stripe_actions
      .updateSubscription(newParams)
      .then(response => {
        this.handleStripeCustomerResponse(response)
        toast.success('Paused the membership.')
        this.togglePauseMembershipModal()
        this.props.toggleShouldRefreshPayments()
      })
      .catch(err => {
        toast.error(convertStripeErrorToMessage(err))
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  handleCreateNewSubscription = (defaultSource, stripe, elements) => {
    const { subscriptionCart, billingType, isStripeConnected } = this.state
    this.setState({ isLoading: true, isLoadingSource: true })
    if (subscriptionCart.length > 0) {
      // Only when Stripe is connected
      // and we're not just checkin out free plans
      if (
        isStripeConnected &&
        billingType === 'charge_automatically' &&
        !this.isOnlyFreePlanSelected(subscriptionCart)
      ) {
        // need to send up credit card information
        if (!defaultSource) {
          const cardElement = elements.getElement(CardElement)
          stripe
            .createToken(cardElement)
            .then(response => {
              this.createSubscriptionForExistingTeam(response.token.id)
            })
            .catch(err => {
              console.error(err)
              toast.error('Looks like there was a problem')
            })
        } else {
          this.createSubscriptionForExistingTeam(null)
        }
      } else {
        // Stripe is not connected or we might be just dealing with free plans
        this.createSubscriptionForExistingTeam(null)
      }
    }
  }
  createSubscriptionForExistingTeam = cardToken => {
    this.setState({ isLoading: true })
    const {
      subscriptionCart,
      billingType,
      isStripeConnected,
      startDate,
      monthlyBillDate,
      isProrated,
      daysUntilDue,
      discountObject,
      conferenceHoursAllowance,
    } = this.state
    let frequency = 'month'
    if (subscriptionCart.length > 0) {
      frequency = subscriptionCart[0].plan.frequency
    }
    const plans = TeamBillingConstants.formatPlansForApi(subscriptionCart)
    let teamObj = {
      campus_id: this.props.activeCampus.id,
      id: this.props.team.id,
      plans: JSON.stringify(plans),
      conference_hours_allowance: conferenceHoursAllowance,
    }
    // Actually dealing with Stripe
    if (!this.isOnlyFreePlanSelected(subscriptionCart) && isStripeConnected) {
      teamObj.stripe_account_id = this.props.activeCommunity.stripe_account_id
      // set stripe subscription related settings
      let calculatedBillDate = startDate
      if (frequency === 'month') {
        // set stripe subscription related settings
        calculatedBillDate = Constants.getMonthlyBillDate(
          startDate,
          monthlyBillDate
        )
      } else {
        // todo not monthly aka we don't want to fuck w/proration
        calculatedBillDate = startDate
      }
      teamObj = {
        ...teamObj,
        ...Constants.getSubscriptionDateParams(
          startDate,
          calculatedBillDate,
          isProrated
        ),
      }
      teamObj.billing_type = billingType
      if (billingType === 'send_invoice') {
        teamObj.days_until_due = daysUntilDue
      }
      if (cardToken) {
        teamObj.card_token = cardToken
      }
      if (discountObject) {
        teamObj.coupon_present = true
        teamObj.coupon_name = discountObject.name
        teamObj.coupon_duration = discountObject.duration
        teamObj.coupon_amount_off = discountObject.amount_off
        if (discountObject.duration_in_months) {
          teamObj.coupon_duration_in_months = parseInt(
            discountObject.duration_in_months
          )
        }
        teamObj.coupon_metadata = discountObject.metadata
      }
      this.setState({
        needToCreateNewSubscription: false,
      })
      this.props.team_actions
        .createTeamSubscription(teamObj)
        .then(response => {
          this.handleStripeCustomerResponse(response)
          this.setState({
            isLoadingSource: false,
            needToCreateNewSubscription: false,
            subscriptionCart: [],
            isLoading: false,
          })
          toast.success('Created the membership.')
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            isLoadingSource: false,
          })
          toast.error(convertStripeErrorToMessage(error))
        })
    } else {
      // We are NOT touching stripe.. whether its not actually connected, or we're dealing with
      // free plans and their assignment.
      const bandaidTeamObj = {
        // server is expecting plan_ids, not plans
        campus_id: teamObj.campus_id,
        plan_ids: teamObj.plans,
        id: teamObj.id,
      }
      this.props.team_actions
        .addCoworksPlan(bandaidTeamObj)
        .then(() => {
          toast.success('Added the plans.')
          this.setState({
            needToCreateNewSubscription: false,
            subscriptionCart: [],
            isLoading: false,
          })
        })
        .catch(err => {
          this.setState({
            isLoading: false,
          })
          toast.error(convertStripeErrorToMessage(err))
        })
    }
    this.setState({
      isProrated: true,
      showPlanAddModal: false,
      showAddPlanDropdown: true,
      subscriptionCart: [],
    })
  }

  makeSourceDefault = secondarySource => {
    if (secondarySource) {
      const params = {
        stripe_account_id: this.props.activeCommunity.stripe_account_id,
        stripe_customer_id: this.state.teamCustomer.id,
        default_source: secondarySource.id,
      }
      this.props.stripe_actions
        .updateCustomer(params)
        .then(() => {
          toast.success('Changed the default payment source.')
        })
        .catch(err => {
          toast.error(convertStripeErrorToMessage(err))
        })
    }
  }
  /* Autosuggestion functions */
  onSuggestionChanged = (event, { newValue, method }) => {
    this.setState(
      {
        shouldShowPlanPickerInModal: false,
      },
      this.handleSearchItemSelected(newValue)
    )
  }
  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        {/* <Button className='p-2 my-0 btn btn-primary btn-block'
            onClick={this.props.toggleNewPlanModal}>Create New Plan
            <i className='fa fa-plus fa-sm pull-right' aria-hidden='true'/></Button>*/}
      </div>
    )
  }
  /* end of autosuggestion functions */

  renderMonthlyTotal(stripeSubscription, coworksPlans) {
    let total
    if (stripeSubscription) {
      // manulate discount amount so currencies are on the same base 10 scale,,,,,
      total = Constants.calculateSubscriptionTotal(stripeSubscription)
    } else {
      total = Constants.calculateCoworksPlansTotal(coworksPlans)
    }
    return total <= 0 ? 0 : total
  }

  renderCoworksPlans = coworksPlans => {
    // this function will render only free plans if billing is enabled, otherwise it will show all the plans.
    if (!coworksPlans || (coworksPlans && coworksPlans.length === 0)) {
      return null
    }

    return (
      <h4>Existing Plans</h4> &&
      coworksPlans.map((plan, index) => (
        <PlanRow
          defaultCurrency={
            this.props.activeCommunity.community_preference.currency
          }
          key={index}
          index={index}
          quantity={plan.quantity ? plan.quantity : 1}
          cost={plan.cost}
          handleQuantityChange={null}
          plan={plan}
          handleRemoveCoworksPlan={coworksPlan =>
            this.handleRemoveCoworksPlan(coworksPlan)
          }
          totalCost={plan.cost}
        />
      ))
    )
  }
  renderMembershipSummary = ({
    statusBadge,
    stripeSubscription,
    coworksPlans,
    interval,
  }) => {
    // This method will render a membership summary based on a Stripe subscription
    // or with Coworks plans.
    if (!stripeSubscription && (!coworksPlans || coworksPlans.length === 0)) {
      return null
    }

    const symbol = Constants.getCurrencySymbolFromCommunity(
      this.props.activeCommunity
    )

    return (
      <div className="row">
        <div className="col-md col-12">
          <Callout color="primary">
            <small className="text-primary">Status</small>
            <br />
            <strong className="h6">
              {statusBadge ? (
                statusBadge
              ) : (
                <Badge color="success" badgeShape="pill" className="h6">
                  Active
                </Badge>
              )}
            </strong>
          </Callout>
        </div>
        {stripeSubscription && (
          <div className="col-md col-12">
            <Callout color="primary">
              {/* <small className='text-primary'>Billed</small>
                    <br /><strong className='h6'>{interval}ly on {Constants.getBillDateName(moment.unix(stripeSubscription.current_period_end).get('date'))}</strong> */}
              <small className="text-primary">Current Period</small>
              <br />
              <strong className="h6">
                {moment
                  .unix(stripeSubscription.current_period_start)
                  .format('MMM DD')}
                {' - '}
                {moment
                  .unix(stripeSubscription.current_period_end)
                  .format('MMM DD')}
              </strong>
            </Callout>
          </div>
        )}
        {stripeSubscription && (
          <div className="col-md col-12">
            <Callout color="primary">
              <small className="text-primary">
                {stripeSubscription.status === 'trialing' ? 'First' : 'Next'}{' '}
                Bill Date
              </small>
              <br />
              <strong className="h6">
                {moment
                  .unix(stripeSubscription.current_period_end)
                  .format('MMM DD, YYYY')}
              </strong>
            </Callout>
          </div>
        )}
        <div className="col-md col-12">
          <Callout color="primary">
            <small className="text-primary">
              {interval && `${interval}ly `}Total
            </small>
            <br />
            <strong className="h6">
              {symbol}
              {this.renderMonthlyTotal(stripeSubscription, coworksPlans)}
            </strong>
          </Callout>
        </div>
        {stripeSubscription && (
          <div className="col-md col-12">
            <Callout color="primary">
              <small className="text-primary">Billing Type</small>
              <br />
              <TextButton
                onClick={() => this.handleChangeBillingType(stripeSubscription)}
                className="m-0 p-0"
              >
                <strong className="h6 mr-1">
                  <small>
                    <FontAwesomeIcon icon="pencil-alt" />
                  </small>{' '}
                  {stripeSubscription.billing === 'charge_automatically'
                    ? 'Autopay '
                    : 'Emailed '}
                </strong>
              </TextButton>
            </Callout>
          </div>
        )}
      </div>
    )
  }
  renderSubscriptions = () => {
    if (!this.state.stripeSubscriptions) {
      return null
    }
    const coworksPlans = TeamBillingConstants.formatCoworksPlansForUI({
      teamsPlans: this.props.team.plans,
      isStripeConnected: this.state.isStripeConnected,
    })
    if (
      !this.state.isStripeConnected ||
      this.state.stripeSubscriptions.length === 0
    ) {
      return (
        <div className="row">
          <div className="col">
            {this.renderMembershipSummary({
              coworksPlans,
              interval: 'Month',
            })}
            {this.renderCoworksPlans(coworksPlans)}
            {!this.state.needToCreateNewSubscription ? (
              <ButtonStyled
                className="d-flex align-items-left my-3"
                disabled={Boolean(this.props.isInactive)}
                onClick={this.handleChangeFromFreePlanClicked}
              >
                Add Plan
              </ButtonStyled>
            ) : null}
          </div>
        </div>
      )
    }
    const symbol = Constants.getCurrencySymbolFromCommunity(
      this.props.activeCommunity
    )

    return this.state.stripeSubscriptions.map((stripeSubscription, index) => {
      const stripePlan = stripeSubscription.items.data[0].plan
      const frequencyObj = convertIntervalToFrequency(
        stripePlan.interval,
        stripePlan.interval_count
      )
      let interval = frequencyObj.adverb
      const firstLetter = interval.charAt(0).toUpperCase()
      interval = firstLetter + interval.slice(1, interval.length)
      const discount = stripeSubscription.discount
        ? stripeSubscription.discount
        : null

      let couponExpiration = 'never'
      const coupon = discount ? discount.coupon : null

      if (discount) {
        // figure out discount expiration date.
        // let couponExpiration = 'never'
        // if (coupon && coupon.duration === 'once') {
        //   couponExpiration = `after ${moment
        //     .unix(stripeSubscription.current_period_end)
        //     .format('MMM DD, YYYY')}`
        // } else if (
        //   coupon &&
        //   coupon.duration === 'repeating' &&
        //   coupon.duration_in_months
        // ) {
        //   couponExpiration = `after ${moment
        //     .unix(stripeSubscription.current_period_end)
        //     .add(coupon.duration_in_months - 1, 'month')
        //     .format('MMM DD, YYYY')}`
        // }

        if (discount && discount.end) {
          const currentPeriodEnd = moment.unix(
            stripeSubscription.current_period_end
          )
          const discountEnd = moment.unix(discount.end)
          couponExpiration = discountEnd.diff(currentPeriodEnd, 'months')
          if (stripeSubscription.current_period_end !== discount.end)
            couponExpiration += 1
          if (couponExpiration === 0) {
            couponExpiration = `Expired`
          } else {
            couponExpiration = `Expires: after ${couponExpiration} more use${
              couponExpiration > 1 ? 's' : ''
            }`
          }
        }
      }

      let statusBadge = (
        <Badge color="success" badgeShape="pill" className="h6">
          Active
        </Badge>
      )
      if (stripeSubscription.status === 'trialing') {
        statusBadge = (
          <Badge badgeShape="pill" className="h6">
            Upcoming
          </Badge>
        )
      }
      if (stripeSubscription.pause_collection) {
        statusBadge = (
          <Badge badgeShape="pill" className="h6">
            Paused
            {stripeSubscription.pause_collection.resumes_at
              ? ` - Resumes ${moment
                  .unix(stripeSubscription.pause_collection.resumes_at)
                  .format('MM/DD/YYYY')}`
              : ' indefinitely'}
          </Badge>
        )
      }
      return (
        <div key={index} className="my-2 mx-4">
          <div
            className={
              index < this.state.stripeSubscriptions.length - 1
                ? 'border-bottom-1'
                : undefined
            }
          >
            <br />
            <div className="row">
              <div className="col-12 col-lg-6">
                <strong className="h3">{interval} Plans</strong>
              </div>
              <div className="col-12">
                <div className="row d-flex justify-content-between">
                  <div className="col-md-6 col-12 d-flex align-items-center justify-content-end">
                    <TextButton
                      className="mb-0"
                      disabled={!this.props.team.stripe_customer_id}
                      onClick={() => this.viewUpcomingInvoiceClicked(index)}
                    >
                      View Next Invoice
                    </TextButton>
                  </div>
                  <div className="col-md-3 col-12 d-flex align-items-center justify-content-end">
                    <TextButton
                      className="mb-0"
                      disabled={!this.props.team.stripe_customer_id}
                      onClick={() => this.togglePauseMembershipModal(index)}
                    >
                      {!stripeSubscription.pause_collection
                        ? 'Pause Membership'
                        : 'Resume Membership'}
                    </TextButton>
                  </div>
                  <div className="col-md-3 col-12 d-flex align-items-center justify-content-end">
                    <TextButton
                      color="danger"
                      className="mb-0"
                      onClick={() => this.cancelSubscriptionClicked(index)}
                    >
                      Cancel Membership
                    </TextButton>
                  </div>
                </div>
              </div>
            </div>
            {this.renderMembershipSummary({
              stripeSubscription,
              coworksPlans,
              interval,
              statusBadge,
            })}
            {this.renderStripePlans(
              stripeSubscription.items.data,
              stripeSubscription.id
            )}
            {this.renderCoworksPlans(coworksPlans)}
            <div className="row">
              <div className="col-md-6 d-flex align-items-center justify-content-start">
                <ButtonStyled
                  className="my-2"
                  onClick={() => this.handleAddPlanClicked(stripeSubscription)}
                >
                  Add Plan
                </ButtonStyled>
              </div>
              {/* todo re-enable discounts for non-monthly!!! */}
              {frequencyObj.name === 'month'
                ? TeamBillingConstants.renderDiscountBadge(
                    discount,
                    coupon,
                    couponExpiration,
                    stripeSubscription,
                    this.showAddDiscountModalForSub,
                    this.deleteDiscount,
                    symbol
                  )
                : null}
            </div>
          </div>
        </div>
      )
    })
  }
  renderNewSubscriptionCart = subscriptionCart => {
    return subscriptionCart.map((cartObject, i) => {
      const symbol =
        cartObject.plan && cartObject.plan.currency_units
          ? Constants.getCurrencySymbolFromName(cartObject.plan.currency_units)
          : '$'
      const plan = cartObject.plan
        ? cartObject.plan
        : {
            name: '',
            status: '',
            cost: 0,
            description: '',
            frequency: '',
            currency: '',
          }
      return (
        <PlanRow
          defaultCurrency={
            this.props.activeCommunity.community_preference.currency
          }
          key={i}
          index={i}
          plan={plan}
          totalCost={cartObject.totalCost}
          symbol={symbol}
          quantity={cartObject.uiState.quantity}
          handleQuantityChange={this.handleQuantityChange}
          toggleNewPlanModal={this.toggleNewPlanModal}
          removePlanFromCart={index => this.removePlanFromCart(index)}
        />
      )
    })
  }
  renderNewSubscriptionsForm(defaultSource) {
    const {
      billingType,
      chargeNowDisabled,
      isOnlyFreePlanSelected,
      subscriptionCart,
      isStripeConnected,
      monthlyBillDate,
      showAddPlanDropdown,
      isProrated,
      discountObject,
      startDate,
      conferenceHoursAllowance,
      teamCustomer,
    } = this.state
    const { activeCommunity, plans } = this.props
    const commPrefs = activeCommunity.community_preference
    const planGrandTotal = Constants.calculateTeamPlanTotal({
      cart: subscriptionCart,
      discountTotalDollars:
        discountObject && discountObject ? discountObject.amount_off : 0,
      prefs: commPrefs,
      customer: teamCustomer,
    })
    const planSubTotal = Constants.calculateTeamPlanTotal({
      cart: subscriptionCart,
      discountTotalDollars: null,
      prefs: null,
      customer: null,
    })
    const frequency =
      subscriptionCart && subscriptionCart.length > 0
        ? subscriptionCart[0].plan.frequency
        : 'month'
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <div className="col-12">
            <hr />
            <h4>New Plans</h4>
            <div className=" p-3">
              <div className="">
                {this.renderNewSubscriptionCart(subscriptionCart)}
                <div className="row">
                  <div className="col-6">
                    {!showAddPlanDropdown && (
                      <ButtonStyled
                        className="my-3"
                        onClick={() => this.handleAddPlanClicked(null)}
                      >
                        {subscriptionCart.length === 0
                          ? 'Add a plan'
                          : 'Add more plans'}
                      </ButtonStyled>
                    )}
                    {showAddPlanDropdown ? (
                      <QuickSearch
                        type="Plan"
                        showTitle={false}
                        handleSearchItemCleared={() =>
                          this.handleSearchItemSelected(null)
                        }
                        handleSearchItemSelected={plan =>
                          this.handleSearchItemSelected(plan)
                        }
                      />
                    ) : null}
                  </div>
                  {subscriptionCart.length > 0 &&
                    isStripeConnected &&
                    !this.isOnlyFreePlanSelected(subscriptionCart) &&
                    frequency === 'month' && (
                      <div className="col d-flex align-items-center justify-content-end">
                        {!this.state.discountObject ? (
                          <TextButton
                            className="d-block my-3"
                            onClick={this.toggleAddDiscountModal}
                          >
                            Add Discount
                          </TextButton>
                        ) : (
                          <React.Fragment>
                            {this.state.discountObject
                              ? `Discount applied for ${Constants.getCurrencySymbolFromName(
                                  commPrefs.currency
                                )}${Number(
                                  this.state.discountObject.amount_off
                                ).toFixed(2)}`
                              : null}
                            <TextButton
                              className="m-0 p-0 ml-2"
                              onClick={this.handleRemoveDiscount}
                            >
                              Remove
                            </TextButton>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
            {!this.isOnlyFreePlanSelected(subscriptionCart) &&
            isStripeConnected ? (
              <Subscription
                history={this.props.history}
                conferenceHours={conferenceHoursAllowance}
                handleInputChange={this.handleInputChange} // only used for conferenceHoursAllowance
                activeCommunity={activeCommunity}
                subscriptionCart={subscriptionCart}
                discountObject={discountObject}
                startDate={startDate}
                monthlyBillDate={monthlyBillDate}
                planGrandTotal={planGrandTotal}
                planSubTotal={planSubTotal}
                handleStartDateChanged={this.handleStartDateChanged}
                handleMonthlyBillDateChanged={this.handleMonthlyBillDateChanged}
                handleProrateToggle={this.handleProrateToggle}
                isProrated={isProrated}
                handleProrateLength={this.handleProrateLength}
                getProrateLength={this.getProrateLength}
                getMonthlyBillDate={Constants.getMonthlyBillDate}
                frequency={
                  subscriptionCart.length > 0
                    ? subscriptionCart[0].plan.frequency
                    : ''
                }
                stripeCustomer={teamCustomer}
              />
            ) : null}
            <hr />
            {!chargeNowDisabled &&
            isStripeConnected &&
            subscriptionCart.length > 0 &&
            !this.isOnlyFreePlanSelected(subscriptionCart) ? (
              <div className="row my-3">
                <div className="col-md-8 mx-auto">
                  <div className="col-12 my-3">
                    <h3>Payment</h3>
                    <div className="form-group">
                      <p>
                        Would you like to charge for these plans automatically?
                      </p>
                      <label>
                        <input
                          className="mr-1"
                          name="billingType"
                          type="radio"
                          value="charge_automatically"
                          checked={billingType === 'charge_automatically'}
                          onChange={this.handleTypeChange}
                        />
                        Yes, charge automatically.
                      </label>
                      <br />
                      <label>
                        <input
                          className="mr-1"
                          name="billingType"
                          type="radio"
                          value="send_invoice"
                          checked={billingType === 'send_invoice'}
                          onChange={this.handleTypeChange}
                        />
                        No, send an invoice to be paid manually by email.
                      </label>
                    </div>
                  </div>
                  {billingType === 'charge_automatically' &&
                    (!defaultSource ||
                      (defaultSource.object !== 'card' &&
                        defaultSource.object !== 'bank_account')) && (
                      <div className="my-3 col-12">
                        <CreditCardForm stripe={stripe} elements={elements} />
                      </div>
                    )}
                  {billingType === 'charge_automatically' && (
                    <div className="row w-75">
                      <PaymentSourceComponent
                        toggleVerifyBankModal={this.toggleVerifyBankModal}
                        isLoadingSource={this.state.isLoadingSource}
                        defaultSourceObject={defaultSource}
                      />
                    </div>
                  )}
                  {billingType === 'charge_automatically' &&
                    defaultSource &&
                    defaultSource.object === 'bank_account' &&
                    defaultSource.status !== 'verified' && (
                      <div className="row">
                        <br />
                        <div className="mx-1 col-12 text-danger">
                          This source is not chargeable until it is verified.
                        </div>
                        <br />
                      </div>
                    )}
                  {billingType !== 'charge_automatically' && (
                    <span>
                      Note: Invoices will be sent, but if a member doesn't pay
                      within{' '}
                      {commPrefs && commPrefs.days_until_due
                        ? commPrefs.days_until_due
                        : 3}{' '}
                      days, subscription will be automatically cancelled.
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                {isStripeConnected &&
                subscriptionCart.length > 0 &&
                !this.isOnlyFreePlanSelected(subscriptionCart) ? (
                  <div className="col-12">
                    <h3>Payment</h3>
                    <div className="col-12 my-3">
                      Invoices will be sent via email, but if a member doesn't
                      pay within 30 days, subscription will be automatically
                      cancelled.
                    </div>
                  </div>
                ) : null}
              </div>
            )}

            <div className="row my-1">
              <div className="offset-3 col-9 d-flex justify-content-end">
                <ButtonStyled
                  color="white"
                  className="btn btn-secondary mr-3"
                  onClick={this.cancelNewBilling}
                >
                  Cancel
                </ButtonStyled>
                <ButtonStyled
                  disabled={
                    (billingType === 'charge_automatically' &&
                      defaultSource &&
                      defaultSource.object === 'bank_account' &&
                      defaultSource.status !== 'verified') ||
                    this.state.isLoading
                  }
                  onClick={() =>
                    this.handleCreateNewSubscription(
                      defaultSource,
                      stripe,
                      elements
                    )
                  }
                >
                  {this.state.isLoading && (
                    <FontAwesomeIcon icon={'circle-notch'} spin />
                  )}{' '}
                  Save Team Membership
                </ButtonStyled>
              </div>
            </div>
          </div>
        )}
      </ElementsConsumer>
    )
  }
  renderStripePlans(stripePlans, subscriptionId) {
    return stripePlans.map((plan, index) => {
      const stripeMappedPlan = Constants.mapCoworksPlanFromStripePlan(plan)
      return (
        <PlanRow
          defaultCurrency={
            this.props.activeCommunity.community_preference.currency
          }
          key={plan.id}
          index={parseInt(plan.id)}
          plan={stripeMappedPlan}
          subscriptionItemId={plan.id}
          subscriptionId={subscriptionId}
          quantity={stripeMappedPlan.quantity}
          cost={stripeMappedPlan.cost}
          totalCost={stripeMappedPlan.cost * stripeMappedPlan.quantity}
          removePlanFromSubscriptionClicked={() =>
            this.removePlanFromSubscriptionClicked(plan, subscriptionId)
          }
        />
      )
    })
  }
  renderUpcomingInvoice = () => {
    const {
      upcomingInvoice,
      showUpcomingInvoiceModal,
      subscriptionForNextInvoice,
    } = this.state
    if (upcomingInvoice && showUpcomingInvoiceModal) {
      return (
        <Modal
          isOpen={showUpcomingInvoiceModal}
          toggle={this.toggleUpcomingInvoiceModal}
          className="modal-primary modal-lg fade in"
        >
          <ModalHeader>Upcoming Invoice</ModalHeader>
          <ModalBody>
            <InvoiceDetail
              preview
              activeCommunity={this.props.activeCommunity}
              team={this.props.team}
              invoice={upcomingInvoice}
              membershipPaused={
                subscriptionForNextInvoice &&
                subscriptionForNextInvoice.pause_collection
                  ? subscriptionForNextInvoice.pause_collection
                  : false
              }
              membershipResumeAt={
                subscriptionForNextInvoice &&
                subscriptionForNextInvoice.pause_collection
                  ? subscriptionForNextInvoice.pause_collection.resumes_at
                  : null
              }
            />
          </ModalBody>
          <ModalFooter>
            <ButtonStyled
              color="white"
              onClick={this.toggleUpcomingInvoiceModal}
            >
              Close
            </ButtonStyled>
          </ModalFooter>
        </Modal>
      )
    }
    return null
  }

  renderSecondarySource = secondarySource => {
    const secondaryCard = secondarySource.card
      ? secondarySource.card
      : secondarySource
    return (
      secondaryCard && (
        <AlertStyled color="info">
          <div className="row">
            <div className="col-md-9">
              We found a different credit card ({secondaryCard.last4}) that was
              added by a one-off invoice on{' '}
              {moment.unix(secondarySource.created).format('MM/DD/YYYY')}. Make
              this credit card the default payment source?
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <TextButton
                className="m-0 p-0"
                onClick={() => this.makeSourceDefault(secondarySource)}
              >
                <h6>
                  <FontAwesomeIcon icon="credit-card" /> Set as Default
                </h6>
              </TextButton>
            </div>
          </div>
        </AlertStyled>
      )
    )
  }

  renderVerifyBankAlert = () => (
    <AlertStyled color="info">
      <div className="row">
        <div className="col-md-9">
          This member's bank is unverified and can't be charged. Would you like
          to verify this bank?
        </div>
        <div className="col-md-3 d-flex justify-content-end">
          <TextButton className="m-0 p-0" onClick={this.toggleVerifyBankModal}>
            <h6>Verify Bank Account</h6>
          </TextButton>
        </div>
      </div>
    </AlertStyled>
  )

  /* eslint-disable max-len */
  render() {
    const { team, activeCommunity } = this.props
    const { isStripeConnected, teamCustomer, isLoading } = this.state
    if (isLoading) {
      return <Spinner />
    }

    const {
      defaultSource,
      secondaryChargeableSource,
    } = getCustomerDefaultSource(teamCustomer, true)

    const prefs = activeCommunity.community_preference
    const country = prefs.country ? prefs.country : 'US'
    const currency = prefs.currency ? prefs.currency : 'USD'
    return (
      <div className="animated fadeIn">
        {secondaryChargeableSource &&
          this.renderSecondarySource(secondaryChargeableSource)}
        {defaultSource &&
          defaultSource.object === 'bank_account' &&
          (defaultSource.status === 'new' ||
            defaultSource.status === 'validated') &&
          this.renderVerifyBankAlert(defaultSource)}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            {team && <h2 className="text-primary">{team.name}</h2>}
          </div>
          {isStripeConnected && (
            <div className="col-md-6 col-12">
              {defaultSource && defaultSource.type !== 'ach_credit_transfer' ? (
                <div className={'row d-flex justify-content-end'}>
                  <PaymentSourceComponent
                    isLoadingSource={this.state.isLoadingSource}
                    toggleVerifyBankModal={this.toggleVerifyBankModal}
                    defaultSourceObject={defaultSource}
                  />
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <TextButton
                      className="p-0 m-0"
                      onClick={this.toggleBillingMethodModal}
                    >
                      <small>
                        <FontAwesomeIcon icon="pencil-alt" />
                      </small>{' '}
                      Edit
                    </TextButton>
                  </div>
                </div>
              ) : (
                <div className={'row'}>
                  <div className="col-12 d-flex justify-content-end">
                    <TextButton onClick={this.toggleBillingMethodModal}>
                      Add Payment Method
                    </TextButton>
                  </div>
                </div>
              )}
            </div>
          )}
          {!isStripeConnected && (
            <div className="col-md-6 text-muted d-flex jusitfy-content-end">
              Note: Billing is not enabled. This is just for records. Adding
              plans will not actually charge members.
            </div>
          )}
        </div>
        {this.renderSubscriptions()}
        {this.state.needToCreateNewSubscription &&
          this.renderNewSubscriptionsForm(defaultSource)}
        {/* MODAL SECTION */}
        {this.state.showSubscriptionDeletionModal &&
          CancelSubscriptionModal({
            showSubscriptionDeletionModal: this.state
              .showSubscriptionDeletionModal,
            toggleCancelSubscriptionModal: this.toggleCancelSubscriptionModal,
            team: team,
            subscriptionInQuestion: this.state.subscriptionInQuestion,
            confirmCancelSubscriptionClicked: this
              .confirmCancelSubscriptionClicked,
          })}
        {this.state.showRemovePlanModal && (
          <RemovePlanModal
            showRemovePlanModal={this.state.showRemovePlanModal}
            planObjectToRemove={
              this.state.planObjectToRemove
                ? this.state.planObjectToRemove
                : this.state.coworksPlanToRemove
            }
            handleQuantityToRemoveChange={this.handleQuantityToRemoveChange}
            quantityToRemove={
              this.state.planObjectToRemove
                ? this.state.planObjectToRemove.uiState.quantityToRemove
                : this.state.coworksPlanToRemove.uiState.quantityToRemove
            }
            toggleRemovePlanModal={this.toggleRemovePlanModal}
            confirmRemovePlanClickedModal={() =>
              this.confirmRemovePlanClickedModal()
            }
          />
        )}
        {AddPlanModal({
          subscriptionCart: this.state.subscriptionCart,
          stripeSubscriptions: this.state.stripeSubscriptions,
          showAddPlanDropdown: this.state.showAddPlanDropdown,
          team: this.props.team,
          showPlanAddModal: this.state.showPlanAddModal,
          toggleAddPlanModal: this.toggleAddPlanModal,
          plans: this.props.plans,
          renderSuggestionsContainer: this.renderSuggestionsContainer,
          handleAutoSuggestChange: this.handleSearchItemSelected,
          toggleChangePlanModal: this.toggleChangePlanModal,
          handleChangePlanClicked: this.handleChangePlanClicked,
          handleExistingPlanQuantityChange: this
            .handleExistingPlanQuantityChange,
          shouldProrate: this.state.isProrated,
          existingSubscription: this.state.existingSubscription,
          newPlanProrateStatus: this.state.newPlanProrateStatus,
          handleInputChange: this.handleInputChange,
          confirmSaveClickedModal: this.confirmAddPlanSaveClicked,
          defaultCurrency: this.props.activeCommunity.community_preference
            .currency,
          previewSubscriptionChangesClicked: subscription =>
            this.previewSubscriptionChangesClicked(subscription),
          conferenceHoursAllowance: this.state.conferenceHoursAllowance,
          handleStartDateChanged: this.handleStartDateChanged,
          startDate: this.state.startDate,
          startDateFocused: this.state.startDateFocused,
          handleStartDateFocused: this.handleStartDateFocused,
        })}
        {this.renderUpcomingInvoice()}
        <BillingMethodModal
          isLoadingSource={this.state.isLoadingSource}
          showBillingMethodModal={this.state.showBillingMethodModal}
          toggleBillingMethodModal={this.toggleBillingMethodModal}
          toggleVerifyBankModal={this.toggleVerifyBankModal}
          defaultSource={defaultSource}
          addBank={this.addBank}
          addCard={this.addCard}
          deleteSource={this.deleteSource}
          country={country}
          currency={currency}
        />
        {defaultSource && defaultSource.object === 'bank_account' && (
          <VerifyBankModal
            isLoading={this.state.isLoadingSource}
            bankToken={defaultSource.id}
            stripeCustomerId={teamCustomer && teamCustomer.id}
            showVerifyBankModal={this.state.showVerifyBankModal}
            toggleVerifyBankModal={this.toggleVerifyBankModal}
            verifyBank={this.verifyBank}
          />
        )}
        {this.state.subscriptionToChangeBillingType &&
          this.state.showChangeBillingTypeModal &&
          ChangeBillingTypeModal({
            subscriptionToChangeBillingType: this.state
              .subscriptionToChangeBillingType,
            showChangeBillingTypeModal: this.state.showChangeBillingTypeModal,
            customer: this.state.teamCustomer,
            toggleChangeBillingTypeModal: this.toggleChangeBillingTypeModal,
            handleChangeBillingType: this.handleChangeBillingType,
          })}
        {this.state.showAddDiscountModal ? (
          <DiscountModal
            activeCommunity={this.props.activeCommunity}
            subForDiscountModal={this.state.subForDiscountModal}
            subscriptionCart={this.state.subscriptionCart}
            billingFrequency={
              this.state.subForDiscountModal
                ? this.state.subForDiscountModal.items.data[0].plan.interval
                : this.state.subscriptionCart[0].plan.frequency
            }
            currencySymbol={Constants.getCurrencySymbolFromCommunity(
              this.props.activeCommunity
            )}
            discountObject={this.state.discountObject} // in case we want to edit a discount (only on new subscriptions..., otherwise, we post it to api immediately)
            handleSaveDiscountClicked={couponObject =>
              this.handleSaveDiscountClicked(couponObject)
            }
            listLength={
              this.state.subForDiscountModal
                ? this.state.subForDiscountModal.items.data.length
                : this.state.subscriptionCart.length
            }
            showAddDiscountModal={this.state.showAddDiscountModal}
            team={this.props.team}
            toggleAddDiscountModal={() => this.toggleAddDiscountModal()}
            isProrated={this.state.isProrated}
          />
        ) : null}
        {this.state.selectedSubscription && (
          <PauseMembershipModal
            isPaused={this.state.selectedSubscription.pause_collection}
            showModal={this.state.showPauseMembershipModal}
            toggleModal={this.togglePauseMembershipModal}
            handleSaveClicked={params => this.handlePauseMembership(params)}
            subscription={this.state.selectedSubscription}
            nextBillingCycle={
              this.state.selectedSubscription.current_period_end
            }
          />
        )}
      </div>
    )
  }
}

TeamMembership.displayName = 'TeamMembership'

const mapStateToProps = state => {
  return {
    plans: state.plans,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    user: state.user,
    teams: state.teams.teams,
    isFetchingPlans: resolveFetchingStatus(state, FETCHING_PLANS),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    plan_actions: bindActionCreators(planActions, dispatch),
    stripe_actions: bindActionCreators(stripeActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembership)
