import React, { useState } from 'react'
import { toast } from 'react-toastify'

import * as campusActions from '@reduxActions/campusActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as uiActions from '@reduxActions/uiActions'
import PropTypes from 'prop-types'
import Spinner from '@global/Spinner'
import { checkDoorProvider } from '@components/Community/Integrations/IntegrationConstants'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import * as Yup from 'yup'
import { logO, difference } from '@global/Constants'

import {
  CustomInputComponent,
  CustomTextAreaComponent,
  CustomPresetSelectComponent,
  CustomSelectComponent,
  HoursOfOperation,
  CustomImageUploader,
} from '@global/Form/FormComponents'

import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import { Button, Input } from 'reactstrap'
import RoundedImage from '@global/RoundedImage'
import defaultAvatar from '@app/img/placeholders/otto_black_white.jpeg'

// eslint-disable-next-line react/no-multi-comp, react/display-name
function renderCampusPhoto(photo) {
  return (
    <RoundedImage
      photo={photo ? photo.medium_file_url : defaultAvatar}
      alt="Campus Photo"
      size="large"
      className="img-responsive"
    />
  )
}

export const CampusProfileFields = ({ isEditing, errors, photo }) => {
  return (
    <div className="row">
      <div className="col-md-3">
        {isEditing ? (
          <Field
            isEditing={isEditing}
            renderViewState={!isEditing}
            name="photo"
            id="photo"
            component={CustomImageUploader}
          />
        ) : (
          renderCampusPhoto(photo)
        )}
      </div>
      <div className="col-md-8 col-offset-1">
        <div className="row">
          <div className="col-12">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Campus Name"
              name="name"
              id="name"
              placeholder="Campus name"
              component={CustomInputComponent}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Description"
              name="description"
              id="description"
              rows="3"
              placeholder="Description"
              component={CustomTextAreaComponent}
            />
          </div>
        </div>
        <br />
        <h3>Address</h3>
        <hr />
        <div className="row">
          <div className="col-12">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Address Line 1"
              name="address1"
              id="address1"
              placeholder="Street address, P.O. box, team name, c/o"
              component={CustomInputComponent}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Address Line 2"
              name="address2"
              id="address-line2"
              placeholder="Apartment, suite, unit, building, floor, etc."
              component={CustomInputComponent}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-7">
                <Field
                  isEditing={isEditing}
                  renderViewState={!isEditing}
                  label="City"
                  name="city"
                  id="city"
                  placeholder="city"
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-5">
                <Field
                  isEditing={isEditing}
                  renderViewState={!isEditing}
                  label="State/Province/Region"
                  name="state"
                  id="region"
                  placeholder="state"
                  component={CustomInputComponent}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Zip/Postal Code"
              name="zip"
              id="zip"
              placeholder="zip or postal code"
              component={CustomInputComponent}
            />
          </div>
          <div className="col-6">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Country"
              name="country"
              id="country"
              dropdownType="country"
              component={CustomPresetSelectComponent}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Phone"
              name="phone"
              id="phone"
              placeholder="Phone number"
              component={CustomInputComponent}
            />
          </div>
        </div>
        <br />
        <h3>Stats</h3>
        <hr />
        <div className="row">
          <div className="col-6">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Campus Size"
              name="campusSize"
              type="number"
              id="campusSize"
              placeholder="Campus size"
              component={CustomInputComponent}
            />
          </div>
          <div className="col-6">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Campus Size Units"
              name="areaUnits"
              id="#areaUnits"
              placeholder="Feet/Meters"
              component={CustomSelectComponent}
            >
              <option value="feet">Feet</option>
              <option value="meters">Meters</option>
            </Field>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Number of Dedicated Desks"
              name="dedicatedDesks"
              id="dedicatedDesks"
              type="number"
              placeholder="dedicatedDesks"
              component={CustomInputComponent}
            />
          </div>
          <div className="col-6">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Number of Suites"
              name="suites"
              id="suites"
              type="number"
              placeholder="suites"
              component={CustomInputComponent}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              isEditing={isEditing}
              renderViewState={!isEditing}
              label="Rent Cost / month"
              name="rentCost"
              id="rentCost"
              placeholder="Rent Cost"
              component={CustomInputComponent}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

CampusProfileFields.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  photo: PropTypes.object.isRequired,
  showDoorAccessFields: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
}
CampusProfileFields.displayName = 'CampusProfileFields'

export default CampusProfileFields
