//attachment actions
export const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT'
export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT'

// booking actions
export const CREATE_BOOKING = 'CREATE_BOOKING'
export const GET_BOOKINGS = 'GET_BOOKINGS'
export const SET_BOOKINGS = 'SET_BOOKINGS'
export const UPDATE_BOOKINGS = 'UPDATE_BOOKINGS'
export const CLEAR_BOOKINGS = 'CLEAR_BOOKINGS'
export const UPDATE_BOOKING = 'UPDATE_BOOKING'
export const DELETE_BOOKING = 'DELETE_BOOKING'
export const GET_ROOM_ID = 'GET_ROOM_ID'
export const CREATE_TABLET_KEY = 'CREATE_TABLET_KEY'
export const ARCHIVE_BOOKING = 'ARCHIVE_BOOKING'

//campus actions
export const SET_CAMPUSES = 'SET_CAMPUSES'
export const UPDATE_CAMPUS = 'UPDATE_CAMPUS'
export const CREATE_CAMPUS = 'CREATE_CAMPUS'

// community actions
export const REGISTER_COMMUNITY = 'REGISTER_COMMUNITY'
export const SET_CURRENT_MANAGER = 'SET_CURRENT_MANAGER'
export const UPDATE_COMMUNITY_COWORKS_GROUPS = 'UPDATE_COMMUNITY_COWORKS_GROUPS'

// network members actions
export const SET_NETWORK_MEMBERS = 'SET_NETWORK_MEMBERS'
export const CLEAR_NETWORK_MEMBERS = 'CLEAR_NETWORK_MEMBERS'

// member actions
export const CREATE_MEMBER = 'CREATE_MEMBER'
export const SET_MEMBERS = 'SET_MEMBERS'
export const SET_INACTIVE_USERS = 'SET_INACTIVE_USERS'
export const UPDATE_MEMBER = 'UPDATE_MEMBER'
export const CREATE_MEMBER_CHECKIN = 'CREATE_MEMBER_CHECKIN'
export const UPDATE_MEMBER_CHECKIN = 'UPDATE_MEMBER_CHECKIN'
export const DELETE_MEMBER_CHECKIN = 'DELETE_MEMBER_CHECKIN'
export const DEACTIVATE_MEMBER = 'DEACTIVATE_MEMBER'
export const ACTIVATE_MEMBER = 'ACTIVATE_MEMBER'
export const CLEAR_MEMBERS = 'CLEAR_MEMBERS'

// occupancy actions
export const CREATE_OFFICE = 'CREATE_OFFICE'
export const SET_OFFICES = 'SET_OFFICES'
export const SET_INACTIVE_OFFICES = 'SET_INACTIVE_OFFICES'
export const UPDATE_OFFICE = 'UPDATE_OFFICE'
export const CREATE_OFFICE_CHECKIN = 'CREATE_OFFICE_CHECKIN'
export const UPDATE_OFFICE_CHECKIN = 'UPDATE_OFFICE_CHECKIN'
export const DELETE_OFFICE_CHECKIN = 'DELETE_OFFICE_CHECKIN'
export const DEACTIVATE_OFFICE = 'DEACTIVATE_OFFICE'
export const ACTIVATE_OFFICE = 'ACTIVATE_OFFICE'

// staff actions
export const SET_STAFF = 'SET_STAFF'

// lead actions
export const CREATE_LEAD = 'CREATE_LEAD'
export const SET_LEADS = 'SET_LEADS'
export const UPDATE_LEADS = 'UPDATE_LEADS'
export const CLEAR_LEADS = 'CLEAR_LEADS'
export const UPDATE_LEAD = 'UPDATE_LEAD'
export const ARCHIVE_LEAD = 'ARCHIVE_LEAD'
export const SET_PROSPECTS = 'SET_PROSPECTS'
export const UPDATE_PROSPECTS = 'UPDATE_PROSPECTS'
export const CLEAR_PROSPECTS = 'CLEAR_PROSPECTS'
export const CREATE_PROSPECT = 'CREATE_PROSPECT'
export const ARCHIVE_PROSPECT = 'ARCHIVE_PROSPECT'
export const UPDATE_PROSPECT = 'UPDATE_PROSPECT'
export const SUCCESS_SCHEDULING_TOUR = 'SUCCESS_SCHEDULING_TOUR'

// pending members actions
export const CREATE_PENDING_MEMBER = 'CREATE_PENDING_MEMBER'
export const SET_PENDING_MEMBERS = 'SET_PENDING_MEMBERS'
export const UPDATE_PENDING_MEMBER = 'UPDATE_PENDING_MEMBER'

// plan actions
export const CREATE_PLAN = 'CREATE_PLAN'
export const CREATE_PLAN_ON_FLY = 'CREATE_PLAN_ON_FLY'
export const GET_PLANS = 'GET_PLANS'
export const SET_PLANS = 'SET_PLANS'
export const UPDATE_PLAN = 'UPDATE_PLAN'
export const ARCHIVE_PLAN = 'ARCHIVE_PLAN'

// resource actions
export const CREATE_RESOURCE = 'CREATE_RESOURCE'
export const GET_RESOURCES = 'GET_RESOURCES'
export const SET_RESOURCES = 'SET_RESOURCES'
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE'
export const ARCHIVE_RESOURCE = 'ARCHIVE_RESOURCE'

// room actions
export const CREATE_ROOM = 'CREATE_ROOM'
export const GET_ROOMS = 'GET_ROOMS'
export const SET_ROOMS = 'SET_ROOMS'
export const CLEAR_ROOMS = 'CLEAR_ROOMS'
export const GET_ROOM = 'GET_ROOM'
export const UPDATE_ROOM = 'UPDATE_ROOM'
export const ARCHIVE_ROOM = 'ARCHIVE_ROOM'
export const STORE_ROOM_TOKEN = 'STORE_ROOM_TOKEN'

// suite actions
export const CREATE_SUITE = 'CREATE_SUITE'
export const GET_SUITES = 'GET_SUITES'
export const SET_SUITES = 'SET_SUITES'
export const CLEAR_SUITES = 'CLEAR_SUITES'
export const GET_SUITE = 'GET_SUITE'
export const UPDATE_SUITE = 'UPDATE_SUITE'
export const ARCHIVE_SUITE = 'ARCHIVE_SUITE'
export const STORE_SUITE_TOKEN = 'STORE_SUITE_TOKEN'

// team actions
export const CREATE_TEAM = 'CREATE_TEAM'
export const GET_TEAMS = 'GET_TEAMS'
export const GET_TEAM = 'GET_TEAM'
export const SET_TEAMS = 'SET_TEAMS'
export const SET_TEAM = 'SET_TEAM'
export const UPDATE_TEAM = 'UPDATE_TEAM'
export const CHANGE_ADMIN = 'CHANGE_ADMIN'
export const SET_INACTIVE_TEAMS = 'SET_INACTIVE_TEAMS'
export const DEACTIVATE_TEAM = 'DEACTIVATE_TEAM'
export const ACTIVATE_TEAM = 'ACTIVATE_TEAM'
export const DELETE_TEAM = 'DELETE_TEAM'
export const CLEAR_TEAMS = 'CLEAR_TEAMS'

// ui actions
export const IS_FETCHING = 'IS_FETCHING'
export const IS_FETCHING_REPORT = 'IS_FETCHING_REPORT'
export const IS_FETCHING_TASKS = 'IS_FETCHING_TASKS'
export const IS_POSTING = 'IS_POSTING'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const SET_ACTIVE_CAMPUS = 'SET_ACTIVE_CAMPUS'
export const SET_ACTIVE_COMMUNITY = 'SET_ACTIVE_COMMUNITY'
export const SET_ACTIVE_CONTEXT = 'SET_ACTIVE_CONTEXT'
export const SET_GROUPS = 'SET_GROUPS'
export const ADD_GROUP = 'ADD_GROUP'
export const ADD_DYNAMIC_GROUP = 'ADD_DYNAMIC_GROUP'
export const UPDATE_DYNAMIC_GROUP = 'UPDATE_DYNAMIC_GROUP'
export const ADD_DAY_PASS = 'ADD_DAY_PASS'
export const UPDATE_DAY_PASS = 'UPDATE_DAY_PASS'

export const CLEAR_CAMPUS_DATA = 'CLEAR_CAMPUS_DATA'
export const CLEAR_COMMUNITY_DATA = 'CLEAR_COMMUNITY_DATA'

export const SET_IS_TABLET_USER = 'SET_IS_TABLET_USER'
export const SET_FRONT_DESK_LOADED = 'SET_FRONT_DESK_LOADED'
export const UPDATE_COMMUNITY_PREFS_EXPIRATION =
  'UPDATE_COMMUNITY_PREFS_EXPIRATION'
export const UPDATE_COMMUNITY_PREFERENCE = 'UPDATE_COMMUNITY_PREFERENCE'
export const SET_TIMEZONE_IDENTIFIER = 'SET_TIMEZONE_IDENTIFIER'

export const SET_FILTERS = 'SET_FILTERS'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'

// user actions
export const SET_USER = 'SET_USER'
export const SIGN_OUT = 'SIGN_OUT'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_USER = 'UPDATE_USER'
export const SET_CHECK_EMAIL_EXISTS = 'SET_CHECK_EMAIL_EXISTS'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const SEND_ONBOARDING_EMAIL = 'SEND_ONBOARDING_EMAIL'
export const CLEAR_SEND_ONBOARDING_EMAIL_STATUS =
  'CLEAR_SEND_ONBOARDING_EMAIL_STATUS'

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const CLEAR_NOTIFICATION_COUNT = 'CLEAR_NOTIFICATION_COUNT'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const REFRESH_NOTIFICATIONS = 'REFRESH_NOTIFICATIONS'

//Google calendar actions
export const INJECT_GAPI_SCRIPT = 'INJECT_GAPI_SCRIPT'
export const INIT_GAPI = 'INIT_GAPI'
export const GOOGLE_AUTH = 'GOOGLE_AUTH'

export const SET_GAPI = 'SET_GAPI'

export const CREATE_GOOGLE_EVENT = 'CREATE_GOOGLE_EVENT'
export const UPDATE_GOOGLE_EVENT = 'UPDATE_GOOGLE_EVENT'
export const MOVE_GOOGLE_EVENT = 'MOVE_GOOGLE_EVENT'
export const DELETE_GOOGLE_EVENT = 'DELETE_GOOGLE_EVENT'

export const SET_GCAL_EVENTS = 'SET_GCAL_EVENTS'

export const SET_GCAL_CALENDARS = 'SET_GCAL_CALENDARS'

// Stripe actions
export const SET_STRIPE_CUSTOMER = 'SET_STRIPE_CUSTOMER'
export const SET_COWORKS_CUSTOMER = 'SET_COWORKS_CUSTOMER'
export const UPDATE_STRIPE_INVOICE = 'UPDATE_STRIPE_INVOICE'
export const SET_STRIPE_INVOICE = 'SET_STRIPE_INVOICE'
export const SET_STRIPE_TRANSACTION = 'SET_STRIPE_TRANSACTION'
export const CREATE_STRIPE_SUBSCRIPTION = 'CREATE_STRIPE_SUBSCRIPTION'
export const CREATE_STRIPE_REFUND = 'CREATE_STRIPE_REFUND'
export const DELETE_STRIPE_SUBSCRIPTION = 'DELETE_STRIPE_SUBSCRIPTION'
export const UPDATE_STRIPE_SUBSCRIPTION = 'UPDATE_STRIPE_SUBSCRIPTION'
export const REMOVE_STRIPE_PLANS_FROM_SUB = 'REMOVE_STRIPE_PLANS_FROM_SUB'
export const ACCEPT_STRIPE_TOS = 'ACCEPT_STRIPE_TOS'

export const CREATE_STRIPE_CHARGE = 'CREATE_STRIPE_CHARGE'
export const UPDATE_STRIPE_CHARGE = 'UPDATE_STRIPE_CHARGE'

export const CREATE_STRIPE_INVOICE = 'CREATE_STRIPE_INVOICE'
export const SET_STRIPE_ACCOUNT = 'SET_STRIPE_ACCOUNT'
export const CLEAR_STRIPE_PAYMENT_DATA = 'CLEAR_STRIPE_PAYMENT_DATA'

export const SET_STRIPE_TRANSACTIONS = 'SET_STRIPE_TRANSACTIONS'
export const SET_STRIPE_FAILED_TRANSACTIONS = 'SET_STRIPE_FAILED_TRANSACTIONS'
export const SET_STRIPE_PENDING_TRANSACTIONS = 'SET_STRIPE_PENDING_TRANSACTIONS'
export const SET_STRIPE_UNPAID_INVOICES = 'SET_STRIPE_UNPAID_INVOICES'
export const SET_STRIPE_MARKED_AS_PAID_INVOICES =
  'SET_STRIPE_MARKED_AS_PAID_INVOICES'
export const SET_STRIPE_CLOSED_INVOICES = 'SET_STRIPE_CLOSED_INVOICES'
export const CONNECT_TO_STRIPE = 'CONNECT_TO_STRIPE'
export const VERIFY_BANK = 'VERIFY_BANK'

// Stats
export const SET_DASHBOARD_STATS = 'SET_DASHBOARD_STATS'

// guest actions
export const CREATE_GUEST = 'CREATE_GUEST'
export const GET_GUESTS = 'GET_GUESTS'
export const GET_GUEST = 'GET_GUEST'
export const SET_GUESTS = 'SET_GUESTS'
export const SET_GUEST = 'SET_GUEST'
export const UPDATE_GUEST = 'UPDATE_GUEST'
export const SET_INACTIVE_GUESTS = 'SET_INACTIVE_GUESTS'

// Announcement Actions
export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT'
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT'
export const SET_ANNOUNCEMENTS = 'SET_ANNOUNCEMENTS'

// COWORKS COMMUNITY
export const SET_PUBLIC_COMMUNITY = 'SET_PUBLIC_COMMUNITY'
export const SET_PUBLIC_CAMPUS = 'SET_PUBLIC_CAMPUS'
export const SET_PUBLIC_EVENT = 'SET_PUBLIC_EVENT'

export const CREATE_TASK = 'CREATE_TASK'

// NTEGRATIONS
export const SET_ACTIVE_INTEGRATION = 'SET_ACTIVE_INTEGRATION'
export const SET_DEACTIVE_INTEGRATION = 'SET_DEACTIVE_INTEGRATION'

export const SET_ACTIVE_INTEGRATIONS = 'SET_ACTIVE_INTEGRATIONS'
export const SET_PENDING_INTEGRATION = 'SET_PENDING_INTEGRATION'

// door access
export const SET_DOOR_GROUPS = 'SET_DOOR_GROUPS'
export const SET_DOOR_PLACES = 'SET_DOOR_PLACES'
export const SET_INITIAL_INTEGRATION_STATE = 'SET_INITIAL_INTEGRATION_STATE'

// Member Checkin Actions
export const CREATE_CHECKIN = 'CREATE_CHECKIN'
export const SET_CHECKINS = 'SET_CHECKINS'
export const UPDATE_CHECKINS = 'UPDATE_CHECKINS'
export const CLEAR_CHECKINS = 'CLEAR_CHECKINS'
export const UPDATE_CHECKIN = 'UPDATE_CHECKIN'
export const DELETE_CHECKIN = 'DELETE_CHECKIN'
export const SET_ACTIVE = 'SET_ACTIVE'

// Passport Checkin Actions
export const CREATE_PASSPORT_CHECKIN = 'CREATE_PASSPORT_CHECKIN'
export const SET_PASSPORT_CHECKINS = 'SET_PASSPORT_CHECKINS'
export const UPDATE_PASSPORT_CHECKINS = 'UPDATE_PASSPORT_CHECKINS'
export const CLEAR_PASSPORT_CHECKINS = 'CLEAR_PASSPORT_CHECKINS'
export const UPDATE_PASSPORT_CHECKIN = 'UPDATE_PASSPORT_CHECKIN'
export const DELETE_PASSPORT_CHECKIN = 'DELETE_PASSPORT_CHECKIN'

// Visitor Checkin Actions
export const CREATE_VISITOR_CHECKIN = 'CREATE_VISITOR_CHECKIN'
export const SET_VISITOR_CHECKINS = 'SET_VISITOR_CHECKINS'
export const UPDATE_VISITOR_CHECKINS = 'UPDATE_VISITOR_CHECKINS'
export const CLEAR_VISITOR_CHECKINS = 'CLEAR_VISITOR_CHECKINS'
export const UPDATE_VISITOR_CHECKIN = 'UPDATE_VISITOR_CHECKIN'
export const DELETE_VISITOR_CHECKIN = 'DELETE_VISITOR_CHECKIN'

// External Bookings Actions
export const GET_EXBOOKING_ROOMS = 'GET_EXBOOKING_ROOMS'
export const SET_EXBOOKING_ROOMS = 'SET_EXBOOKING_ROOMS'
export const UPDATE_EXBOOKING_ROOMS = 'UPDATE_EXBOOKING_ROOMS'
export const CLEAR_EXBOOKING_ROOMS = 'CLEAR_EXBOOKING_ROOMS'
