import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { darken } from '@global/Colors/ColorUtils'
import { IconButton } from '@bit/coworks.base-components.button'

const HeaderIconButton = styled(IconButton)`
  outline: none !important;
  color: ${props =>
    props.dropdownVisible
      ? darken(props.theme.colors.neutral4, 0.1)
      : undefined};
  background-color: ${props =>
    props.dropdownVisible ? props.theme.colors.neutral2 : undefined};
`
export default HeaderIconButton
