/* eslint-disable no-invalid-this */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Constants from '@global/Constants'

import ResourceTextForm from './ResourceTextForm'
import ResourceImageForm from './ResourceImageForm'
import ResourceWebsiteForm from './ResourceWebsiteForm'
import ResourceFormTypeForm from './ResourceFormTypeForm'

import * as resourceActions from '@reduxActions/resourceActions'
import * as uiActions from '@reduxActions/uiActions'

import { toast } from 'react-toastify'
function NewResource({
  history,
  resource_actions,
  ui_actions,
  groups,
  activeCampus,
}) {
  let type = null
  let hResource = null
  if (history.location && history.location.state) {
    hResource = history.location.state.resource
    type = history.location.state.type
    if (hResource) {
      type = hResource.type
    }
  }
  const [resource, setResource] = useState(hResource)
  const [customTagState, setCustomTagState] = useState([])
  const [customTagsDirty, setCustomTagsDirty] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    // CDM
    if (!groups) {
      ui_actions.getGroups(activeCampus.id)
    } else {
      setCustomTagState(
        Constants.parseCustomTagState(
          'resource',
          groups,
          resource && resource.tags ? resource.tags : null
        )
      )
    }
  }, [])

  const goBack = () => history.goBack()

  const handleCustomDropdownChange = (event, groupId) => {
    setCustomTagState(
      Constants.parseCustomFieldsToCustomTag(event, customTagState, groupId)
    )
    setCustomTagsDirty(true)
  }

  const archiveResource = resource => {
    const obj = {
      id: resource.id,
      is_archived: true,
    }
    resource_actions
      .updateResource(obj)
      .then(() => {
        toast.success('Archived the resource')
        history.push('/resources')
      })
      .catch(err => {
        if (err) {
          setError(err)
          toast.error(err.message)
        }
      })
  }
  const renderType = type => {
    let formType = ''
    if (type === 'Slides') {
      formType = 'Image Slides'
    }
    if (type === 'TextBased') {
      formType = 'Text'
    }
    if (type === 'Website') {
      formType = 'Website'
    }
    if (type === 'Form') {
      formType = 'Form'
    }
    if (type === 'Survey') {
      formType = 'Survey'
    }
    if (type === 'Survey') {
      formType = 'Survey'
    }
    if (formType === '') {
      formType = ''
    }
    return formType
  }

  /* eslint-disable max-len */

  return (
    <div className="animated fadeIn">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-12">
                  <h2>
                    {resource ? 'Edit' : 'New'} {renderType(type)} Resource
                  </h2>
                </div>
              </div>
            </div>
            <div className="card-block">
              {type === 'Slides' && (
                <ResourceImageForm
                  archiveResource={archiveResource}
                  resource={resource}
                  history={history}
                  goBack={goBack}
                  customTagState={customTagState}
                  customTagsDirty={customTagsDirty}
                  renderCustomDropdowns={() =>
                    Constants.renderCustomDropdowns(
                      groups,
                      customTagState,
                      (event, groupId) =>
                        handleCustomDropdownChange(event, groupId)
                    )
                  }
                />
              )}
              {type === 'TextBased' && (
                <ResourceTextForm
                  archiveResource={archiveResource}
                  resource={resource}
                  history={history}
                  goBack={goBack}
                  customTagState={customTagState}
                  customTagsDirty={customTagsDirty}
                  renderCustomDropdowns={() =>
                    Constants.renderCustomDropdowns(
                      groups,
                      customTagState,
                      (event, groupId) =>
                        handleCustomDropdownChange(event, groupId)
                    )
                  }
                />
              )}
              {type === 'Website' && (
                <ResourceWebsiteForm
                  archiveResource={archiveResource}
                  resource={resource}
                  history={history}
                  goBack={goBack}
                  customTagState={customTagState}
                  customTagsDirty={customTagsDirty}
                  renderCustomDropdowns={() =>
                    Constants.renderCustomDropdowns(
                      groups,
                      customTagState,
                      (event, groupId) =>
                        handleCustomDropdownChange(event, groupId)
                    )
                  }
                />
              )}
              {type === 'Form' && (
                <ResourceFormTypeForm
                  archiveResource={archiveResource}
                  resource={resource}
                  history={history}
                  goBack={goBack}
                  customTagState={customTagState}
                  customTagsDirty={customTagsDirty}
                  renderCustomDropdowns={() =>
                    Constants.renderCustomDropdowns(
                      groups,
                      customTagState,
                      (event, groupId) =>
                        handleCustomDropdownChange(event, groupId)
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
/* eslint-enable max-len */

NewResource.displayName = 'NewResource'
NewResource.propTypes = {
  history: PropTypes.object,
  resource_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  groups: PropTypes.array,
  activeCampus: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCampusId: state.ui.activeCampus.id,
    groups: state.ui.groups,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resource_actions: bindActionCreators(resourceActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewResource)
