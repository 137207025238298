/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button } from 'reactstrap'
import defaultUserPic from '../../img/placeholders/otto_black_white.jpeg'
import * as Constants from '@global/Constants'
import { LabelStyled } from '@global/Form/FormComponents'

export default class PlanRow extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    plan: PropTypes.object,
    subscriptionId: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    cost: PropTypes.number.isRequired,
    totalCost: PropTypes.number.isRequired,
    removePlanFromCart: PropTypes.func, // new subscription
    removePlanFromSubscriptionClicked: PropTypes.func, // edit subscription
    handleRemoveCoworksPlan: PropTypes.func, // removing free plans in coworks
    handleQuantityChange: PropTypes.func, // only provide if quantity is required
    handleChangePlanClicked: PropTypes.func, // only provide if this is in the add plan modal
    size: PropTypes.string, // default is large
    defaultCurrency: PropTypes.string.isRequired, // this comes from the community preference
  }
  static defaultProps = {
    size: 'large',
  }

  constructor(props, context) {
    super(props, context)
    const currency =
      props.plan && props.plan.currency_units
        ? props.plan.currency_units
        : props.defaultCurrency
    this.state = {
      planCurrency: currency,
    }
  }
  render() {
    const {
      index,
      plan,
      subscriptionId,
      totalCost,
      cost,
      removePlanFromCart,
      removePlanFromSubscriptionClicked,
      handleRemoveCoworksPlan,
      quantity,
      handleQuantityChange,
      handleChangePlanClicked,
      size,
    } = this.props
    const currSymbol = Constants.getCurrencySymbolFromName(
      this.state.planCurrency
    )
    return (
      <div className="border px-3 mb-3">
        <div className="row bg-light ">
          <div className="col p-6">
            <div className="bg-light p-2">
              <div className="row d-flex align-items-center">
                <div className={size === 'large' ? 'col-md-1' : 'col-md-3'}>
                  <img
                    className="img-responsive center-block img-avatar"
                    style={{ width: '50px', height: '50px' }}
                    src={
                      plan && plan.plan_photo
                        ? plan.plan_photo.small_file_url
                        : defaultUserPic
                    }
                  />
                </div>
                <div className={size === 'large' ? 'col-md-5' : 'col-md-9'}>
                  <p className="my-0 h6">{plan ? plan.name : null}</p>
                </div>

                {handleQuantityChange ? (
                  <div className={size === 'large' ? 'col-md-2' : 'col-md-5'}>
                    <div className="row">
                      <div className="col-3 d-flex align-items-center">
                        <LabelStyled>Qty:</LabelStyled>
                      </div>
                      <div className="col-9">
                        <Input
                          id="name"
                          type="number"
                          name="quantity"
                          className="w-100"
                          placeholder="qty"
                          onChange={event => handleQuantityChange(index, event)}
                          value={quantity}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {!handleQuantityChange ? (
                  <div className={size === 'large' ? 'col-md-2' : 'col-md-3'}>
                    <span className="my-0 text-muted">
                      {}
                      {currSymbol}
                      {cost} x{' '}
                    </span>
                    <span className="text-muted">Qty:</span> {quantity}
                    {/* <Button className='btn btn-sm btn-link btn-link-secondary-primary'>
                      <small><i className='fa fa-pencil fa-sm fa-fw' /></small>
                    </Button>*/}
                  </div>
                ) : null}
                <div className={size === 'large' ? 'col-md-3' : 'col-md-4'}>
                  <p className="my-0">
                    {' '}
                    {plan
                      ? `${currSymbol}${totalCost} per ${plan.frequency}`
                      : null}
                  </p>
                  {plan.conference_hours_default ? (
                    <small>
                      <p className="my-0">
                        {' '}
                        {plan.conference_hours_default} Conference Room Hours{' '}
                      </p>
                    </small>
                  ) : null}
                </div>
                <div
                  className={`d-flex justify-content-end ${
                    size === 'large' ? 'col-md-1' : 'col-md-3'
                  }`}
                >
                  {removePlanFromCart ? (
                    <Button
                      className="btn-sm mx-2 btn-link text-danger"
                      onClick={() => removePlanFromCart(index)}
                    >
                      Remove
                    </Button>
                  ) : null}
                  {removePlanFromSubscriptionClicked ? (
                    <Button
                      className="btn-sm pull-right mx-2 btn-link text-danger"
                      onClick={() =>
                        removePlanFromSubscriptionClicked(plan, subscriptionId)
                      }
                    >
                      Remove
                    </Button>
                  ) : null}
                  {handleRemoveCoworksPlan ? (
                    <Button
                      className="btn-sm pull-right mx-2 btn-link text-danger"
                      onClick={() => handleRemoveCoworksPlan(plan)}
                    >
                      Remove
                    </Button>
                  ) : null}
                  {handleChangePlanClicked ? (
                    <Button
                      className="btn-sm pull-right mx-2 btn-link text-primary"
                      onClick={() => handleChangePlanClicked()}
                    >
                      Change
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PlanRow.displayName = 'PlanRow'
