import React from 'react'
import PropTypes from 'prop-types'
import Anchor from '@global/Base/Anchor/Anchor'
import TagRow from '@global/TagRow'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const CampusCol = ({ campusId, campuses, ...other }) => {
  for (let counter = 0; counter < campuses.length; counter++) {
    const campus = campuses[counter]
    if (campus.id === campusId) {
      return (
        <span className="text-muted" {...other}>
          <FontAwesomeIcon icon="building" /> {campus.name}
        </span>
      )
    }
  }
  return null
}

CampusCol.propTypes = {
  campusId: PropTypes.string,
  campuses: PropTypes.array,
}

CampusCol.displayName = 'Campus Column'

export default CampusCol
