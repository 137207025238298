/* eslint-disable no-invalid-this,max-depth,camelcase */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Route, withRouter } from 'react-router-dom'
import moment from 'moment'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Row, TabContent } from 'reactstrap'
import { captureException } from '@global/ErrorFactory'
import {
  resolveFetchingStatus,
  FETCHING_MEMBER,
} from '@global/Constants/FetchingConstants'

import { Alert } from 'reactstrap'

import * as memberActions from '@reduxActions/memberActions'
import * as teamActions from '@reduxActions/teamActions'
import * as taskActions from '@reduxActions/taskActions'
import * as checkinActions from '@app/reduxActions/checkinActions'

import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import { checkDoorProvider } from '@app/components/Community/Integrations/IntegrationConstants'

import QuickSearch from '@global/Search/QuickSearch'
import { Button } from '@global/Base/Button/ButtonStyled'
import TogglesComponent from '@global/Base/Toggle/TogglesComponent'

import ArchiveModal from '../../../ArchiveModal'
import ChangeTeamModal from '../../../ChangeTeamModal'
import MemberForm from './MemberForm'
import * as _ from 'lodash'
import Spinner from '@global/Spinner'

class MemberProfile extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    member_actions: PropTypes.object,
    checkin_actions: PropTypes.object,
    team_actions: PropTypes.object,
    task_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    pagination: PropTypes.object,
    plans: PropTypes.array,
    integrations: PropTypes.object,
    customers: PropTypes.array,
    member: PropTypes.object,
    team: PropTypes.object,
    role: PropTypes.string,
    isPosting: PropTypes.bool,
    isFetchingMember: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    const { member, team, user, history, activeCommunity } = props
    const locState = history.location.state

    let isEditing = false
    let backRoute = '/directory'
    if (locState) {
      if (locState.isEditing) {
        isEditing = true
      }
      if (locState.backRoute) {
        backRoute = locState && locState.backRoute
      }
    }
    let viewOnly = false
    if (user && user.type === 'Member') {
      viewOnly = true
    }
    const coworksGroups = activeCommunity.coworks_groups

    this.state = {
      activePill: 'Archive Team',
      loading: false,
      viewOnly,
      backRoute,
      showAccess: false,
      doorAccess: [],
      accessDate: moment(),
      photoChanged: false,
      errors: [],
      showArchiveModal: false,
      showChangeModal: false,
      newAdminId: -1,
      isEditing,
      comment: '',
      dateRange: {
        startDate: moment(),
        endDate: moment().add('weeks', 1),
      },
      tasks: [],
      archiveTeamAfterChange: false, // super Admin function to archive current team after user change
      isAdminPanelVisible: false, // super admin show/hide panel,
      disableWelcomeEmailButton: false,
      userRoomPermissionsOptions:
        coworksGroups &&
        coworksGroups.room_permissions &&
        coworksGroups.room_permissions.tags
          ? coworksGroups.room_permissions.tags.map((permission, index) => ({
              label: permission,
              value: permission,
            }))
          : [],
      userSkillOptions:
        coworksGroups &&
        coworksGroups.user_skills &&
        coworksGroups.user_skills.tags
          ? coworksGroups.user_skills.tags.map((permission, index) => ({
              label: permission,
              value: permission,
            }))
          : [],
      bio_html: member ? member.bio_html : '',
      email: member ? member.email : '',
      phone: member ? member.phone : '',
      title: member ? member.title : '',
      firstName: member ? member.first_name : '',
      lastName: member ? member.last_name : '',
      dynamicTags: member ? member.dynamic_tags : [],
      stripeCustomerId:
        member && member.stripe_customer_id ? member.stripe_customer_id : null,
      type: member ? member.type : null,
      userPhoto: member && member.user_photo ? member.user_photo.file_url : '',
      isMemberAdmin:
        member && team ? Constants.isMemberAdmin(member, team) : false,
      isCheckedIn: member ? Constants.getActiveCheckIn(member.checkins) : false,
      userJoinedAt: this.getCreatedDate(member),
      hasOnboarded: member ? member.has_onboarded : false,
      customFieldsObject:
        member && member.custom_field ? member.custom_field : { fields: [] },
      userRoomPermissions:
        member && member.user_room_permission_list
          ? member.user_room_permission_list.map((val, index) => ({
              label: val,
              value: val,
            }))
          : [],
      userSkills:
        member && member.user_skill_list
          ? member.user_skill_list.map((val, index) => ({
              label: val,
              value: val,
            }))
          : [],
    }
  }
  getCreatedDate = member => {
    if (member) {
      return member.joined_at
        ? moment(member.joined_at)
        : moment(member.created_at)
    }
    return null
  }
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleInputValuesChange = (name, value) => {
    this.setState({
      [name]: value,
      allowFormSubmit: true,
    })
  }
  handleUserJoinedAtChanged = mom => {
    this.setState({
      userJoinedAt: mom,
    })
  }
  handleDoorsGranted = doorAccess => {
    this.setState({
      doorAccess,
    })
  }
  handleKisiAccessDateChange = accessDate => {
    this.setState({
      accessDate,
    })
  }
  handleAccessToggle = () => {
    this.setState({
      doorAccess: !this.state.showAccess ? [] : this.state.doorAccess,
      showAccess: !this.state.showAccess,
    })
  }
  handleImageUpload = image => {
    this.setState({
      photoChanged: true,
      userPhoto: image,
    })
  }
  toggleHasOnboarded = () => {
    this.setState({
      hasOnboarded: !this.state.hasOnboarded,
    })
  }
  goBack = () => {
    this.props.history.push(this.state.backRoute)
  }

  renderInactiveAlert = () => {
    const { member } = this.props
    if (member && member.is_archived) {
      return (
        <Alert color="warning" className={'m-0'} isOpen>
          This member is inactive!
        </Alert>
      )
    }
    return null
  }
  handleChangeStatus = ({ stripeSubscriptions }) => {
    this.setState({
      showArchiveModal: false,
      loading: true,
    })
    const isMemberAdmin = Constants.isMemberAdmin(
      this.props.member,
      this.props.team
    )
    const subscriptions = []
    if (stripeSubscriptions) {
      stripeSubscriptions.map((subscription, index) => {
        subscriptions.push({ id: subscription.id })
      })
    }

    const params = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      team_id: this.props.team.id,
      id: this.props.member.id,
      campus_id: this.props.activeCampus.id,
      community_id: this.props.activeCommunity.id,
      subscription_ids: JSON.stringify(subscriptions),
    }
    if (
      this.state.newAdminId &&
      this.state.newAdminId !== -1 &&
      isMemberAdmin
    ) {
      params.new_admin_id = this.state.newAdminId
    } else if (isMemberAdmin && !this.state.newAdminId) {
      console.log('problem!!!!')
    }

    if (this.props.member && this.props.member.is_archived) {
      this.props.member_actions
        .activateMember(params)
        .then(response => {
          toast.success('Success activating member')
          this.setState({
            showArchiveModal: false,
            isMemberAdmin: Constants.isMemberAdmin(
              response.user,
              response.team
            ),
            loading: false,
          })
        })
        .catch(error => {
          this.setState({
            showArchiveModal: false,
            loading: false,
          })
          captureException({
            text: `Problem activating member`,
            error,
          })

          toast.error('There was a problem activating member')
        })
    } else {
      this.props.member_actions
        .deactivateMember(params)
        .then(response => {
          toast.success('Success deactivating member')
          this.checkOutMember()
          this.setState({
            showArchiveModal: false,
            isMemberAdmin: Constants.isMemberAdmin(
              response.user,
              response.team
            ),
            loading: false,
          })
        })
        .catch(error => {
          this.setState({
            showArchiveModal: false,
            loading: false,
          })
          captureException({
            text: `Problem deactivating member`,
            error,
          })
          toast.error('There was a problem deactivating member')
        })
    }
  }

  checkOutMember = () => {
    const { member } = this.props
    const activeCheckin = Constants.getActiveCheckIn(member.checkins)
    if (activeCheckin)
      this.props.checkin_actions
        .checkOutMember(activeCheckin.id)
        .then(() => {
          this.handleMemberCheckedOut()
        })
        .catch(error =>
          captureException({
            text: `Problem checking out member`,
            error,
          })
        )
  }
  updateMember = () => {
    // const profilePrefs = this.state.profile_preferences
    // const prefs = this.state.preferences
    // prefs.member.membership_agreement_signed_at = moment().format('YYYY')
    const {
      bio_html,
      email,
      phone,
      title,
      firstName,
      lastName,
      type,
      userJoinedAt,
      hasOnboarded,
      customFieldsObject,
      userRoomPermissions,
      dynamicTags,
      userSkills,
      photoChanged,
      userPhoto,
      showAccess,
      doorAccess,
      accessDate,
    } = this.state
    const { member, activeCampus, member_actions } = this.props
    const obj = {
      id: member.id,
      campus_id: activeCampus.id,
      bio_html: bio_html,
      email: email,
      phone: phone,
      title: title,
      first_name: firstName,
      last_name: lastName,
      type: type,
      joined_at: userJoinedAt,
      has_onboarded: hasOnboarded,
      custom_fields: JSON.stringify(customFieldsObject),
      user_room_permissions: userRoomPermissions
        ? userRoomPermissions.map(obj => obj.value).join(', ')
        : '',
      dynamic_tags: JSON.stringify(dynamicTags),
      user_skills: userSkills
        ? userSkills.map(obj => obj.value).join(', ')
        : '',
    }
    // if the photo has changed.
    if (photoChanged) {
      obj.user_photo = userPhoto
    }

    if (showAccess) {
      obj.add_to_door_access_system = true

      const doorIDs = []
      doorAccess.forEach(element => {
        if (element.value === null) {
          obj.all_doors = true
        } else {
          doorIDs.push(element.id)
        }
      })
      obj.door_access_valid_from = accessDate.toISOString()

      if (doorIDs.length > 0) {
        obj.door_access_group_ids = JSON.stringify(doorIDs)
      }
    }

    member_actions
      .updateMember(obj)
      .then(response => {
        toast.success('Updated member.')
        this.setState({ isEditing: false })
      })
      .catch(response => {
        if (response && response.errors && response.errors.message) {
          this.setState({ errors: response.errors, isEditing: false })
          toast.error(response.errors.message)
        } else {
          toast.error('Error saving member')
        }
        captureException({
          text: `Problem updating member`,
          response,
        })
      })
  }
  changeTeam = () => {
    const obj = {
      id: this.props.member.id,
      campus_id: this.props.activeCampus.id,
    }
    // SuperAdmin should we move the member to another company
    if (this.state.selectedTeamToChange && this.props.team) {
      obj.new_team_id = this.state.selectedTeamToChange.id
      obj.current_team_id = this.props.team.id
      // Should the company be deactivated after the move.
      obj.archive_team = this.state.archiveTeamAfterChange
    }
    this.props.member_actions
      .changeTeam(obj)
      .then(user => {
        toast.success("Changed member's team.")
        //this.props.history.push('/directory');
      })
      .catch(response => {
        if (response && response.errors) {
          this.setState({ errors: response.errors, isEditing: false })
          toast.error(response.errors.message)
        } else {
          toast.error('Error saving member')
        }
        captureException({
          text: `Problem changing a members team`,
          response,
        })
      })
  }
  resendOnboardingEmail = () => {
    const params = {
      id: this.props.member.id,
      campus_id: this.props.activeCampus.id,
    }
    this.setState({
      disableWelcomeEmailButton: true,
    })
    if (!this.state.disableWelcomeEmailButton) {
      this.props.member_actions
        .resendOnboardingEmail(params)
        .then(user => {
          toast.success('Resent the welcome email!')
        })
        .catch(error => {
          toast.error('Failed to resend the welcome email.')
          this.setState({
            disableWelcomeEmailButton: false,
          })
          captureException({
            text: `Problem reseding the welcome email`,
            error,
          })
        })
    }
  }
  toggleShowArchiveModal = () => {
    if (this.props.member && this.props.team) {
      this.setState({
        showArchiveModal: !this.state.showArchiveModal,
      })
    }
  }
  toggleShowChangeModal = () => {
    if (this.props.member && this.props.team) {
      this.setState({
        showChangeModal: !this.state.showChangeModal,
      })
    }
  }
  toggleIsEditing = () => {
    this.setState({
      isEditing: !this.state.isEditing,
    })
  }
  editCompanyClicked = (team, event, canEdit) => {
    event.preventDefault()
    NavigationConstants.navigateToTeam({
      history: this.props.history,
      match: this.props.match,
      team,
      tab: 'profile',
      state: { canEdit },
    })
  }
  handleBioChange = bio => {
    this.setState({ bio_html: bio })
  }
  handleSearchItemSelected = team => {
    if (team) {
      this.setState({
        selectedTeamToChange: team,
      })
    }
  }
  handleSearchItemCleared = () => {
    this.setState({
      selectedTeamToChange: null,
    })
  }
  toggleTab = tab => {
    const { member } = this.props
    if (member) {
      NavigationConstants.navigateToMember({
        history: this.props.history,
        match: this.props.match,
        member,
        tab,
      })
    }
  }
  handleMemberCheckedIn = () => {
    this.setState({ isCheckedIn: true })
  }
  handleMemberCheckedOut = () => {
    this.setState({ isCheckedIn: false })
  }
  toggleAdminPanelVisibility = () => {
    this.setState({
      isAdminPanelVisible: !this.state.isAdminPanelVisible,
    })
  }
  render() {
    const {
      match,
      history,
      user,
      integrations,
      member,
      team,
      role,
    } = this.props
    const {
      isEditing,
      bio_html,
      email,
      phone,
      firstName,
      lastName,
      type,
      title,
      errors,
      userPhoto,
      userJoinedAt,
      isMemberAdmin,
      stripeSubscriptions,
      newAdminId,
      showArchiveModal,
      showChangeModal,
      hasOnboarded,
      selectedTeamToChange,
      customFieldsObject,
      disableWelcomeEmailButton,
      userRoomPermissions,
      userRoomPermissionsOptions,
      userSkillOptions,
      userSkills,
      dynamicTags,
    } = this.state

    const canEdit = role !== 'Member'
    const shouldShowArchiveButton = member && member.type !== 'Admin'

    return (
      <React.Fragment>
        {this.state.loading || !member ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <MemberForm
              history={history}
              match={match}
              user={user}
              activeCommunity={this.props.activeCommunity}
              activeCampus={this.props.activeCampus}
              member={member}
              role={this.props.role}
              isEditing={isEditing}
              canEdit={canEdit}
              bio_html={bio_html}
              email={email}
              firstName={firstName}
              lastName={lastName}
              type={type}
              memberCheckInClicked={this.props.checkin_actions.createCheckIn}
              memberCheckOutClicked={this.props.checkin_actions.checkOutMember}
              phone={phone}
              title={title}
              userPhoto={userPhoto}
              team={team}
              userJoinedAt={userJoinedAt}
              hasOnboarded={hasOnboarded}
              shouldShowArchiveButton={shouldShowArchiveButton}
              hasDoorProvider={checkDoorProvider(
                this.props.integrations,
                this.props.activeCampus
              )}
              errors={errors}
              showAccess={this.state.showAccess}
              doorAccess={this.state.doorAccess}
              handleAccessToggle={this.handleAccessToggle}
              handleDoorsGrantedChanged={this.handleDoorsGranted}
              accessDate={this.state.accessDate}
              handleUserAccessDateChanged={this.handleKisiAccessDateChange}
              doorAccessOptions={integrations.doorAccess}
              handleImageUpload={this.handleImageUpload}
              handleInputChange={this.handleInputChange}
              handleInputValuesChange={this.handleInputValuesChange} // for customfieldsobj
              handleBioChange={this.handleBioChange}
              updateMember={this.updateMember}
              toggleShowArchiveModal={this.toggleShowArchiveModal}
              toggleShowChangeModal={this.toggleShowChangeModal}
              resendOnboardingEmail={this.resendOnboardingEmail}
              disableWelcomeEmailButton={disableWelcomeEmailButton}
              handleUserJoinedAtChanged={this.handleUserJoinedAtChanged}
              editCompanyClicked={(company, event) =>
                this.editCompanyClicked(company, event, canEdit)
              }
              toggleIsEditing={this.toggleIsEditing}
              toggleHasOnboarded={this.toggleHasOnboarded}
              currentUser={this.props.user}
              goBack={this.goBack}
              isCheckedIn={this.state.isCheckedIn}
              isInactive={member && member.is_archived}
              userRoomPermissions={userRoomPermissions}
              userRoomPermissionsOptions={userRoomPermissionsOptions}
              userSkills={userSkills}
              userSkillOptions={userSkillOptions}
              dynamicTags={dynamicTags}
              customFieldsObject={customFieldsObject}
            />
          </React.Fragment>
        )}

        {showArchiveModal && (
          <ArchiveModal
            history={this.props.history}
            match={this.props.match}
            type="Member"
            member={member}
            plans={this.props.plans}
            team={team}
            isMemberAdmin={isMemberAdmin}
            stripeSubscriptions={stripeSubscriptions}
            handleInputChange={this.handleInputChange}
            newAdminId={newAdminId}
            showArchiveModal={showArchiveModal}
            toggleShowArchiveModal={this.toggleShowArchiveModal}
            activeCampus={this.props.activeCampus}
            activeCommunity={this.props.activeCommunity}
            handleChangeStatus={this.handleChangeStatus}
          />
        )}
        {showChangeModal && (
          <ChangeTeamModal
            history={this.props.history}
            match={this.props.match}
            member={member}
            team={team}
            isMemberAdmin={isMemberAdmin}
            newAdminId={newAdminId}
            showModal={showChangeModal}
            toggleShowModal={this.toggleShowChangeModal}
            activeCampus={this.props.activeCampus}
          />
        )}
      </React.Fragment>
    )
  }
}

MemberProfile.displayName = 'MemberProfile'

function mapStateToProps(state, ownProps) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    plans: state.plans,
    integrations: state.integrations,
    customers: state.stripe.customers,
    user: state.user,
    isFetchingMember: resolveFetchingStatus(state, FETCHING_MEMBER),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkin_actions: bindActionCreators(checkinActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    task_actions: bindActionCreators(taskActions, dispatch),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberProfile)
)
