/* eslint-disable no-invalid-this,quote-props */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import * as googleCalendarActions from '@reduxActions/googleCalendarActions'
import styled, { css } from 'styled-components'
import { readableColor, lighten, desaturate, darken } from 'polished'
import { getContrastingColor } from '@global/Colors/ColorUtils'

import moment from 'moment'

import * as _ from 'lodash'

import BookingCard from './BookingCard'
import {
  resolveFetchingStatus,
  FETCHING_ROOMS,
} from '@global/Constants/FetchingConstants'

const Container = styled.div``

function getStyledHeader(props) {
  if (props.color) {
    const fixCol = desaturate(0.2, lighten(0.1, props.color))
    return css`
      background-color: ${fixCol};
      color: ${getContrastingColor(fixCol, '#585858')};
    `
  }
  return css`
    background-color: #6dbbba;
    h5 {
      color: ${props.theme.colorWhite};
    }
  `
}

const Header = styled.div`
  ${props => {
    return props.today
      ? getStyledHeader(props)
      : css`
          background-color: #bdbdbd;
          h5 {
            color: #6d6d6d;
          }
        `
  }}
`

const BookingCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const BookingCardStyled = styled(BookingCard)`
  display: flex;
`
const HeaderText = styled.h5`
  margin: 0;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

class BookingsBundle extends React.Component {
  static propTypes = {
    segment: PropTypes.object,
    multiRoom: PropTypes.bool,
    onClick: PropTypes.function,
    brandColor: PropTypes.string,
  }

  renderBooking = () => {
    const { segment, multiRoom, brandColor } = this.props
    const bookings = segment.bookings
    const sortedBookings = bookings.sort(
      (pre, post) =>
        moment(pre.start_time).valueOf() - moment(post.start_time).valueOf()
    )
    return sortedBookings.map(booking => (
      <BookingCardWrapper>
        <BookingCardStyled
          booking={booking}
          unique={multiRoom}
          onClick={this.props.onClick}
          brandColor={brandColor}
        />
      </BookingCardWrapper>
    ))
  }

  renderHeader = () => {
    const { segment, brandColor } = this.props
    const timeFormat = 'dddd, MMMM Do, YYYY'
    const isToday = moment().isSame(segment.day, 'day')

    const timeFormatted = moment(segment.day).format(timeFormat)
    return (
      <Header
        color={brandColor}
        today={isToday}
        className="px-4 py-2 container-fluid"
      >
        <HeaderText>
          {isToday ? 'Today, ' : ''}
          {timeFormatted}
        </HeaderText>
      </Header>
    )
  }
  /* eslint-disable max-len */
  render() {
    return (
      <Container>
        {this.renderHeader()}
        {this.renderBooking()}
      </Container>
    )
  }
}

BookingsBundle.displayName = 'BookingsBundle'

function mapStateToProps(state) {
  return {
    isFetching: state.ui.isFetching,
    isFetchingRooms: resolveFetchingStatus(state, FETCHING_ROOMS),

    googleCalendars: state.gcal ? state.gcal.calendars : null,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    google_actions: bindActionCreators(googleCalendarActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingsBundle)
