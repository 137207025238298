import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { format, parse, isSameDay } from 'date-fns'
import moment from 'moment'
// import Modal from 'react-modal'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  Button,
  ButtonDropdown,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Collapse,
  CardBody,
  Card,
  Row,
} from 'reactstrap'

import * as leadActions from '@reduxActions/leadActions'
import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'
import styled, { css } from 'styled-components'

import Spinner from '@global/Spinner'
import ScheduleTourForm from '../../Tablet/FrontDesk/ScheduleTourForm'
import withPublicCommunityContext from '../withPublicCommunityContext'

import './_ExternalEventPage.scss'
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc'

const linkStyles = css`
  text-decoration: none;
  display: block;
  color: #e42d2d;
  font-size: 18px;
  text-align: center;
  padding: 6px;
`
const componentStyles = css`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 0 30px;

  @media (min-width: 768px) {
    width: 50%;
  }
`
function ExternalEventPage({
  history,
  match,
  coworks_community_actions,
  ui_actions,
  publicCommunity,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [publicEvent, setPublicEvent] = useState(null)
  const [hasSubmitted, setHasSubmitted] = useState(true)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const eventId = match.params.eventId
    if (eventId && publicCommunity) {
      coworks_community_actions
        .getPublicEvent({
          subdomain: publicCommunity.preferences.subdomain,
          event_id: eventId,
        })
        .then(response => {
          setPublicEvent(response.event)
          setIsLoading(false)
        })
        .catch(err => {
          setIsLoading(false)
        })
    }
  }, [coworks_community_actions, match.params.eventId, publicCommunity])

  const sendRSVP = formState => {
    const params = {
      // created_by: 'WebsiteForm',
      // first_name: formState.firstName,
      // last_name: formState.lastName,
      // email: formState.email,
      // interested_in: formState.interestedIn,
      // phone: formState.phone,
      community_id: formState.publicCommunity.id,
      // stripe_account_id: formState.publicCommunity.stripe_account_id,
    }

    // coworks_community_actions.sendRequest(params)
    //     .then(response => {
    //       console.log('Success', response);
    //     })
    //     .catch(err => {
    //       console.log('Error', err);
    //    });
  }
  function toggleModal() {
    setShowModal(!showModal)
  }
  // eslint-disable-next-line react/no-multi-comp, react/display-name, react/prop-types
  function MyDropdown({ children }) {
    return <div className="mr-4">{children}</div>
  }
  // eslint-disable-next-line react/no-multi-comp, react/display-name, react/prop-types
  function MyButton({ children, onClick }) {
    return <button onClick={onClick}>{children}</button>
  }

  // eslint-disable-next-line react/no-multi-comp, react/display-name
  function renderAddToCalendarButton() {
    const startDatetime = moment(publicEvent.start)
    const endDatetime = moment(publicEvent.end)
    const duration = moment.duration(endDatetime.diff(startDatetime)).asHours()
    const event = {
      description: publicEvent.description,
      duration,
      endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
      location: publicEvent.room
        ? `${publicEvent.room.name} at ${publicCommunity.name}`
        : publicEvent.location,
      startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
      title: publicEvent.name,
    }
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

    const AddToCalendarDropdown = AddToCalendarHOC(MyButton, MyDropdown)
    return (
      <AddToCalendarDropdown
        className={{}}
        linkProps={{
          className: linkStyles,
        }}
        event={event}
        items={
          isiOS
            ? [SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.YAHOO]
            : undefined
        }
      />
    )
  }
  if (isLoading || !publicEvent) {
    return <Spinner />
  }
  const imageUrl =
    publicEvent &&
    publicEvent.event_photo &&
    publicEvent.event_photo.small_file_url
      ? publicEvent.event_photo.small_file_url
      : 'https://s3.amazonaws.com/coworksprod/assets/admin-dashboard-assets/frontdesk-tablet-background.jpg'
  const eventImageBackground = {
    backgroundImage: `url(${imageUrl})`,
  }
  return (
    <div id="ExternalEventPage">
      <img
        src={imageUrl}
        alt={publicEvent.name}
        className="backgroundBlurImage"
      />
      <div className="container">
        <div className="row">
          <div
            className="col-12 my-5 py-5 bg-primary event-image-background"
            style={eventImageBackground}
          />
        </div>
      </div>
      <div className="container bg-white card">
        <div className="row">
          <div className="col-12 p-md-5 p-3">
            <h1 className="display-1 mb-3">{publicEvent.name}</h1>
            <div className="row">
              <div className="col-md-6">
                <h4>
                  {/* If SAME DAY */}
                  {format(parse(publicEvent.start_time), 'dddd, MMMM d, YYYY')}
                  <br />
                  {format(parse(publicEvent.start_time), 'h:mm A')} -
                  {format(parse(publicEvent.end_time), ' h:mm A')}
                </h4>
                {renderAddToCalendarButton()}
                <p className="lead">{publicEvent.description}</p>
              </div>
              <div className="col-md-6 d-none d-md-block register-sidebar">
                <h2>Register</h2>
                <input
                  className="form-control my-3"
                  type="text"
                  placeholder="Name"
                />
                <input
                  className="form-control my-3"
                  type="text"
                  placeholder="Email"
                />
                <div className="form-check pl-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="interestedInMembership"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="interestedInMembership"
                  >
                    Interested in becoming a member?
                  </label>
                </div>
                <button className="btn btn-primary btn-block">Register</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Only */}
      <button
        className={
          showModal
            ? 'd-none'
            : 'btn d-block d-md-none fixed-bottom btn-primary btn-block'
        }
        onClick={toggleModal}
      >
        Register
      </button>

      {/* Move this to its own Component */}
      {/* <Modal
        isOpen={showModal}
        className={'EventRegisterModal'}
        contentLabel="Register"
      >
        <div className="row">
          <div className="col-10">
            <h2>Register</h2>
          </div>
          <div className="col-2">
            <FontAwesomeIcon
              icon={'times'}
              className="fa-2x float-right"
              onClick={toggleModal}
            />
          </div>
        </div>
        <input className="form-control my-3" type="text" placeholder="Name" />
        <input className="form-control my-3" type="text" placeholder="Email" />
        <div className="form-check pl-4">
          <input
            className="form-check-input"
            type="checkbox"
            id="interestedInMembership"
          />
          <label className="form-check-label" htmlFor="interestedInMembership">
            Interested in becoming a member?
          </label>
        </div>
        <button className="btn btn-primary btn-block">Submit</button>
      </Modal> */}
    </div>
  )
}

ExternalEventPage.displayName = 'ExternalEventPage'

ExternalEventPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  coworks_community_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  publicCommunity: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return {
    coworks_community_actions: bindActionCreators(
      coworksCommunityActions,
      dispatch
    ),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withPublicCommunityContext()(ExternalEventPage))
