import React, { Component } from 'react'
import PropTypes from 'prop-types'

import defaultAvatar from '../../../img/placeholders/otto_black_white.jpeg'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

class MemberRecipientRow extends Component {
  static propTypes = {
    recipient: PropTypes.object,
    admins: PropTypes.object,
    deleteRecipient: PropTypes.func,
  }
  constructor(props) {
    super(props)
    const member = this.props.recipient
    const admins = this.props.admins
    this.state = {}
  }

  render() {
    const { recipient } = this.props
    return (
      <div className="border-bottom p-1 w-100 my-1" key={recipient.id}>
        <div className="row">
          <div className="col-1 d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={'check'}
              className="member-plus-icon primary-color"
            />
          </div>
          <div className="col-10 pl-0 pr-0">
            <h5 className="my-0">{`${recipient.first_name} ${recipient.last_name}`}</h5>
            {recipient.teams && recipient.teams.length > 0 && (
              <h6 className="my-0">{`${recipient.teams[0].name}`}</h6>
            )}
            <p className="my-0">{`${
              recipient.email ? recipient.email : recipient.username
            }`}</p>
            {recipient.isAdmin ? (
              <p className="my-0 text-muted">Team Admin</p>
            ) : null}
          </div>
          <div
            className="col-1 d-flex align-items-center"
            onClick={this.props.deleteRecipient}
          >
            <FontAwesomeIcon
              icon={'trash-alt'}
              className="recipient-delete-icon danger-color"
              onClick={this.props.deleteRecipient}
            />
          </div>
        </div>
      </div>
    )
  }
}

MemberRecipientRow.displayName = 'Member Recipient Row'

export default MemberRecipientRow
