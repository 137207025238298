import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import * as teamActions from '@reduxActions/teamActions'
import * as checkinActions from '@app/reduxActions/checkinActions'
import { captureException } from '@global/ErrorFactory'

import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import { Button } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import NothingFound from '@global/NothingFound'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import * as Constants from '@global/Constants'
import { sortByMemberName } from '@reduxReducers/reducerConstants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import MemberRow from '@components/Directory/Members/MemberRow'
import styled from 'styled-components'

const TextButtonStyled = styled(TextButton)`
  margin: 0;
`
const HeaderActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`

function TeamMembers({
  history,
  match,
  team_actions,
  checkin_actions,
  team,
  isStaff,
  isInactive,
  activeCampus,
  activeCommunity,
  canEdit = true,
}) {
  //   const integrations = useSelector(state => state.integrations)
  const dispatch = useDispatch()

  const [pagination, setPagination] = useState(null)
  const [members, setMembers] = useState(null)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(Constants.PER_PAGE_DEFAULT)
  const [loading, setLoading] = useState(false)
  const [dropdownsOpen, setDropdownsOpen] = useState(false)

  useEffect(() => {
    loadNextMembers(page, perPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const nextMembersPage = () => {
    if (
      pagination &&
      page < pagination.total_pages &&
      members.length < pagination.total_objects
    ) {
      const newPage = page + 1
      setLoading(true)
      setPage(newPage)
      loadNextMembers(newPage, perPage)
    }
  }

  const loadNextMembers = (page, perPage) => {
    if (!loading && team) {
      setLoading(true)
      team_actions
        .getTeamMembers(team.id, {
          page: page,
          per_page: perPage,
          campus_id: activeCampus.id,
          status: team.status,
        })
        .then(response => {
          setMembers(
            members ? [...members, ...response.members] : response.members
          )
          setPagination(response.meta.pagination)
        })
        .catch(error => {
          captureException({
            text: `Error loading next members`,
            error,
          })
          toast.error('There was a problem getting the team members!')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  const editMemberClicked = (member, team) => {
    const theMember = JSON.parse(JSON.stringify(member))
    theMember.isInactive = isInactive
    NavigationConstants.navigateToMember({
      history: history,
      match: match,
      member: theMember,
      state: { team, isEditing: true },
    })
  }
  const viewMemberClicked = (member, team) => {
    const mem = JSON.parse(JSON.stringify(member))
    mem.isInactive = isInactive
    NavigationConstants.navigateToMember({
      history: history,
      match: match,
      member: mem,
      state: { team },
    })
  }
  const teamLinkClicked = team => {
    NavigationConstants.navigateToTeam({
      history: history,
      match: match,
      team,
      tab: 'profile',
    })
  }
  const handlePromoteToAdmin = (newAdmin, team) => {
    if (team.admins && team.admins.length > 0) {
      const currentAdmin = team.admins[0]
      const params = {
        id: team.id,
        current_admin_id: currentAdmin.id,
        new_admin_id: newAdmin.id,
        campus_id: activeCampus.id,
        stripe_account_id: activeCommunity.stripe_account_id,
      }
      setLoading(true)

      team_actions
        .changeAdmin(params)
        .then(response => {
          toast.success('Updated the admin.')
        })
        .catch(err => {
          Constants.logO('problem updated admin', err)
          toast.error('There was a problem changing the admin.')
        })
        .finally(() => setLoading(false))
    }
  }
  const toggleDropdown = index => {
    const cloneDrops = [...dropdownsOpen]
    if (cloneDrops[index]) {
      cloneDrops[index] = !cloneDrops[index]
    } else {
      cloneDrops[index] = true
    }
    // update state
    setDropdownsOpen(cloneDrops)
  }
  // eslint-disable-next-line react/no-multi-comp,react/display-name
  const renderTeamMembers = (team, members) => {
    return (
      <PaginatedTable
        objects={members}
        canEdit //??
        type={'members'}
        nextPage={nextMembersPage}
        isLoading={loading}
        totalPages={pagination && pagination.total_pages}
        renderItem={(member, index) => {
          return (
            <MemberRow
              key={member.id}
              member={member}
              team={team}
              index={index}
              showEdit
              handlePromoteToAdmin={
                members.length > 1 ? handlePromoteToAdmin : null
              }
              toggleDropdown={toggleDropdown}
              dropdownsOpen={dropdownsOpen}
              memberCheckInClicked={checkin_actions.createCheckIn}
              memberCheckOutClicked={checkin_actions.checkOutMember}
              canCheckInMember
              editMemberClicked={editMemberClicked}
              viewMemberClicked={viewMemberClicked}
              teamLinkClicked={teamLinkClicked}
              loading={loading}
              isInactive={member.is_archived}
            />
          )
        }}
      />
    )
  }
  const redirectReport = () => {
    history.push(`/reports/members`, {
      team: team,
    })
  }
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <div className="row">
          <div className="col-10">
            {team && team.name ? (
              <h2 className="text-primary">{team.name}</h2>
            ) : null}
          </div>
          {canEdit && (
            <HeaderActions className="col-2 action-right">
              <TextButtonStyled color="primary" onClick={redirectReport}>
                <FontAwesomeIcon icon={'book'} /> Members Report
              </TextButtonStyled>
              <Button
                className="btn btn-sm btn-primary"
                disabled={isInactive}
                onClick={() =>
                  history.push('/directory/members/add-member', {
                    team: team,
                    toRoute: 'team-detail',
                    activeTab: 'profile',
                    isStaff,
                  })
                }
              >
                Add Member
              </Button>
            </HeaderActions>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card-header-null border-bottom-1 p-1" />
          </div>
        </div>
        {renderTeamMembers(team, members)}
      </div>
    </div>
  )
}

TeamMembers.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  team_actions: PropTypes.object.isRequired,
  checkin_actions: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  isStaff: PropTypes.bool.isRequired,
  isInactive: PropTypes.bool.isRequired,
  activeCampus: PropTypes.object.isRequired,
  activeCommunity: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
}
TeamMembers.displayName = 'TeamMembers'

export default TeamMembers
