import * as _ from 'lodash'

const navigateToTeam = ({ history, match, team, state, tab, url }) => {
  const newState = state ? _.cloneDeep(state) : {}
  newState.team = team
  // this allows you to navigate to a specific tab or to the teams from another page.
  const baseRoute = url ? url : `/directory/teams/${team.id}`
  history.push(`${baseRoute}/${tab ? tab : 'profile'}`, newState)
}

const navigateToMember = ({ history, match, member, state, tab }) => {
  const newState = state ? _.cloneDeep(state) : {}
  newState.member = member
  history.push(
    `/directory/members/${member.id}/${tab ? tab : 'profile'}`,
    newState
  )
}
const navigateToPlan = ({ history, match, plan, state, tab }) => {
  const newState = state ? _.cloneDeep(state) : {}
  newState.plan = plan
  history.push(`/plans/${plan.id}`, newState)
}

const navigateToContact = ({ history, match, lead, state, tab }) => {
  const newState = state ? _.cloneDeep(state) : {}
  let isLead = false
  if (lead && lead.is_lead !== undefined) {
    isLead = lead.is_lead
  }
  newState.lead = lead
  newState.contactId = lead.id
  history.push(`/crm/${isLead ? 'leads' : 'prospects'}/${lead.id}`, newState)
}

export { navigateToTeam, navigateToMember, navigateToContact, navigateToPlan }
