/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react'
import { LabelStyled } from '@global/Form/FormComponents'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import styled, { css } from 'styled-components'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as Constants from '@global/Constants'
import Button, {
  OutlineButton,
  IconButton,
} from '@bit/coworks.base-components.button'
import { breakpoints } from '@global/Constants/BreakpointConstants'

import TimeDropdownWithBusinessHours from '../../Filter/TimeDropdownWithBusinessHours'
import GenericModal from '@global/GenericModal'
import TotalTextRows from '../TotalTextRows'
import BookingInput from './BookingInput'

const Container = styled.div``
const ContentContainer = styled.div`
  flex: 1;
`
const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
const IconButtonStyled = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
`
const BookingInputStyled = styled(BookingInput)``
const GenericModalStyled = styled(GenericModal)`
  height: 100vh;
  max-width: none;

  .modal-content {
    border: none !important;
    width: 100%;
  }
  @media (min-width: ${breakpoints.tablet + 1}px) {
    display: block;
    height: auto;
    width: 95%;
    max-width: 900px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    margin: 0 !important;
    .web-ft {
      display: none;
    }
  }
`
const ButtonContainer = styled.div`
  display: flex;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
`
const TitleContainer = styled.div`
  padding: 12px 24px;
  position: relative;
`
const Title = styled.h2`
  display: flex;
`
// const CheckboxStyled = styled(Checkbox)`
//   margin-right: 12px;
//   line-height: 0;
// `
const TimeDropdownStyled = styled(TimeDropdownWithBusinessHours)`
  width: 100%;
`
const TotalTextRowsStyled = styled(TotalTextRows)``
const FormWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1;
`
const DetailWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
`
const Subtitle = styled.h6``
const RoomTitle = styled.span``
const PhotoContainer = styled.div``
const InputContainer = styled.div`
  background: #eee;
  padding: 12px 24px;
`
const InfoContainer = styled.div`
  flex: 1;
  padding: 12px 24px;
`
const RowDetails = styled.div`
  display: flex;
  flex-flow: ${props => (props.mobile ? 'column' : 'row')} nowrap;
  juistify-content: space-between;

  .flexible {
    width: ${props => (props.mobile ? '100%' : '50%')};
  }
`
const CostContainer = styled.div`
  background: #eee;
  padding: 12px 24px;
`
const CloseIcon = styled.div`
  font-size: 24px;
`
const HelperText = styled.span``
const DayToggle = styled.div`
  display: flex;
`
const TimeInputs = styled.div`
  display: flex;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
`
const SubHeader = styled.h6`
  display: flex;
`
const RText = styled.div`
  display: flex;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 24px;
`
const HeaderPhotoWrapper = styled.div`
  display: flex;
  position: relative;
`
const RoomCarousel = styled.img`
  display: flex;
  position: relative;
  ${props => css`
    max-width: ${props.scale * 100}%;
    max-height: ${props.scale * 100}%;
    transform: scale(${props.scale * 100});
  `}
`
const ButtonStyled = styled(Button)``
const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  position: relative;
  height: ${props => (props.mobile ? '250px' : '400px')};
  width: 100%;
`
const TextWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-flow: column nowrap;
  width: 100%;
  background: black;
  top: 0;
  left: 0;
  z-index: 1;
  color: white;
`
const OverlayHeader = styled.h5`
  // margin: 0;
  font-weight: bold;
`

const ConfirmBookingModal = ({
  isOpen,
  toggle,
  onSubmit,
  room = {},
  campus,
  activeCommunity,
  resetForm,
  bookingDetails,
  onCancel,
  fullscreen,
  ...other
}) => {
  const [localDetails, setDetails] = useState(bookingDetails)
  const brandColor = activeCommunity.community_preference.branding_primary_color

  const currencySymbol = Constants.getCurrencySymbolFromCommunity(
    activeCommunity
  )

  const updateLocalDetails = details => {
    setDetails(details)
  }
  const calculateTotal = () => {
    let startTime,
      endTime,
      duration = null
    if (localDetails) {
      const { start, end } = localDetails.timeRange
      const date = localDetails.date
      startTime = moment(Constants.concatenateDateTime(date.format('l'), start))
      endTime = moment(Constants.concatenateDateTime(date.format('l'), end))
      duration = endTime.diff(startTime, 'minutes')
    }

    const dur = duration ? duration / 60 : 0
    const total = dur * room.external_hourly_rate
    const costSum = [
      {
        label: `${dur} Hour(s) x ${currencySymbol}${parseFloat(
          room.external_hourly_rate
        ).toFixed(2)}`,
        value: `${currencySymbol}${total.toFixed(2)}`,
        style: { fontSize: 15, fontWeight: 'bold' },
      },
    ]
    return costSum
  }

  const photoCarousel = () =>
    room && room.room_photo ? (
      <PhotoWrapper mobile={fullscreen}>
        <RoomCarousel scale={0.1} src={room.room_photo.file_url} />
      </PhotoWrapper>
    ) : null

  const form = () => (
    <BookingInputStyled
      room={room}
      campus={campus}
      bookingDetails={bookingDetails}
      onUpdateDetails={updateLocalDetails}
    />
  )
  // const form = () => (
  //   <FormContainer>
  //     {/* <DayToggle>
  //       <CheckboxStyled
  //         name="allday"
  //         label="All Day"
  //         selected={allDay}
  //         handleChange={() => setTimeLength(prev => !prev)}
  //         error={null}
  //       />
  //     </DayToggle> */}
  //     <TimeInputs>
  //       <FormWrapper>
  //         <LabelStyled htmlFor="firstName">Date</LabelStyled>
  //         <DateDropdown value={moment()} onChange={() => null} />
  //       </FormWrapper>
  //       <FormWrapper
  //         style={{
  //           margin: '0 12px',
  //         }}
  //       >
  //         <LabelStyled htmlFor="firstName">Check In</LabelStyled>
  //         <TimeDropdownStyled
  //           value={'7:00 am'}
  //           timeFormat="h:mm A"
  //           onChange={() => null}
  //         />
  //       </FormWrapper>
  //       <FormWrapper>
  //         <LabelStyled htmlFor="firstName">Check Out</LabelStyled>
  //         <TimeDropdownStyled
  //           value={'7:00 am'}
  //           timeFormat="h:mm A"
  //           onChange={() => null}
  //         />
  //       </FormWrapper>
  //     </TimeInputs>
  //   </FormContainer>
  // )

  const details = () => (
    <FormContainer className="flex-column">
      <RowDetails mobile={fullscreen}>
        <DetailWrapper className="flexible">
          <LabelStyled htmlFor="firstName">Accommodates</LabelStyled>
          <p>
            <FontAwesomeIcon icon={'users'} />
            {` `} {room.capacity}
          </p>
        </DetailWrapper>
        {/* <DetailWrapper className="flexible">
          <LabelStyled htmlFor="firstName">Included Amenities</LabelStyled>
          <p>Wifi, Wifi, Wifi, Wifi</p>
        </DetailWrapper> */}
      </RowDetails>
      <DetailWrapper>
        <LabelStyled htmlFor="details">Description</LabelStyled>
        <p>{room.description}</p>
      </DetailWrapper>
      {/* <DetailWrapper>
        <LabelStyled htmlFor="details">Photos</LabelStyled>
        <PhotoGrid
          photos={Array(5).fill(
            'https://images.unsplash.com/photo-1494548162494-384bba4ab999?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80'
          )}
        />
      </DetailWrapper> */}
    </FormContainer>
  )

  const cancel = () => onCancel()

  const content = () => (
    <ContentWrapper>
      <PhotoContainer>{photoCarousel()}</PhotoContainer>
      <InputContainer>{form()}</InputContainer>
      <InfoContainer>{details()}</InfoContainer>
      <CostContainer>
        <OverlayHeader
          style={{
            borderBottom: '1px solid #ddd',
            marginBottom: '12px',
            paddingBottom: '4px',
          }}
        >
          Booking Details
        </OverlayHeader>
        {localDetails && (
          <p
            style={{
              fontSize: 15,
              fontWeight: 'bold',
              margin: '6px 0',
            }}
          >
            {`${localDetails.date.format('dddd MMM Do YYYY')} from ${
              localDetails.timeRange.start
            } to ${localDetails.timeRange.end}`}
          </p>
        )}
        <TotalTextRowsStyled rows={calculateTotal()} />
      </CostContainer>
    </ContentWrapper>
  )

  const getHeader = () => {
    let address,
      name,
      type = ''
    if (campus && room) {
      const { address1, city, state, country } = campus
      if (address1 && city && state && country)
        address = `${address1}, ${city} ${state}, ${country}`
      type = room.type === 'Conference' ? 'Conference Room' : room.type
      name = room.name
    }

    return (
      <React.Fragment>
        <OverlayHeader>{name}</OverlayHeader>
        {address && <Subtitle>{address}</Subtitle>}
        <Subtitle>{type}</Subtitle>
      </React.Fragment>
    )
  }

  const getHeaderPhoto = () => {
    let address,
      name,
      type = ''
    if (campus && room) {
      address = `${campus.address1}, ${campus.city} ${campus.state}, ${campus.country}`
      type =
        room.type.toLowerCase() === 'conference'
          ? `${room.type} Room`
          : room.type
      name = room.name
    }

    return (
      <HeaderPhotoWrapper>
        <TextWrapper>
          <OverlayHeader>{name}</OverlayHeader>
          <Subtitle>{address}</Subtitle>
          <Subtitle>{type}</Subtitle>
        </TextWrapper>
      </HeaderPhotoWrapper>
    )
  }

  const continuteButton = mobile => (
    <ButtonStyled
      color={brandColor}
      className={mobile ? 'w-100 ' : 'ml-3'}
      onClick={() => onSubmit(localDetails)}
      disabled={!localDetails}
    >
      Continue
    </ButtonStyled>
  )

  const body = () => (
    <ContentContainer>
      <TitleContainer className="web-ft">
        {getHeader()}
        <IconButtonStyled
          color="#000"
          size="small"
          icon={['fal', 'times']}
          onClick={cancel}
        />
        {/* <Title>
            Confirm Booking
            <CloseIcon>
              <FontAwesomeIcon icon="times" />
            </CloseIcon>
          </Title> */}
        {/* <Subtitle>
            <b>Room:</b>
            <RoomTitle>The Room</RoomTitle>
          </Subtitle> */}
      </TitleContainer>
      {content()}
      <ButtonContainer className="web-ft">
        <HelperText>You will not be charged in this step.</HelperText>
        <div>
          <OutlineButton color="neutral-4" onClick={cancel}>
            Cancel
          </OutlineButton>
          {continuteButton(fullscreen)}
        </div>
      </ButtonContainer>
    </ContentContainer>
  )

  return room ? (
    <Container {...other}>
      <GenericModalStyled
        mobile
        hideHeaderToggle
        header={<HeaderWrapper>{getHeader()}</HeaderWrapper>}
        body={body()}
        footer={continuteButton(fullscreen)}
        isOpen={isOpen}
        toggle={toggle}
        onClose={cancel}
        noPadding
        noHeaderPadding
        backdrop="static"
      />
    </Container>
  ) : null
}

ConfirmBookingModal.propTypes = {
  isOpen: PropTypes.bool,
  fullscreen: PropTypes.bool,
  toggle: PropTypes.func,
  room: PropTypes.object,
  campus: PropTypes.object.isRequired,
  activeCommunity: PropTypes.object.isRequired,
  bookingDetails: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  onCancel: PropTypes.func,
}

ConfirmBookingModal.displayName = 'Confirm Booking Modal'

export default ConfirmBookingModal
