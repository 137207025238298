import {
  //MEMBER
  CREATE_CHECKIN,
  DELETE_CHECKIN,
  UPDATE_CHECKIN,
  SET_CHECKINS,
  UPDATE_CHECKINS,
  CLEAR_CHECKINS,
  //PASSPORT
  CREATE_PASSPORT_CHECKIN,
  DELETE_PASSPORT_CHECKIN,
  UPDATE_PASSPORT_CHECKIN,
  SET_PASSPORT_CHECKINS,
  UPDATE_PASSPORT_CHECKINS,
  CLEAR_PASSPORT_CHECKINS,
  //VISITOR
  CREATE_VISITOR_CHECKIN,
  DELETE_VISITOR_CHECKIN,
  UPDATE_VISITOR_CHECKIN,
  SET_VISITOR_CHECKINS,
  UPDATE_VISITOR_CHECKINS,
  CLEAR_VISITOR_CHECKINS,
} from '../reduxActions/actionTypes'
import { arrayMerge } from './reducerConstants'

import initialState from './initialState'

export default function (checkins = initialState.checkins, action) {
  const memberCheckinsArray = checkins.member.data
  const memberCheckinsMeta = checkins.member.meta
  const passportCheckinsArray = checkins.passport.data
  const passportCheckinsMeta = checkins.passport.meta
  const visitorCheckinsArray = checkins.visitor.data
  const visitorCheckinsMeta = checkins.visitor.meta

  switch (action.type) {
    case CLEAR_CHECKINS:
      return {
        ...checkins,
        member: {
          data: [],
          meta: null,
        },
      }
    case CLEAR_PASSPORT_CHECKINS:
      return {
        ...checkins,
        passport: {
          data: [],
          meta: null,
        },
      }
    case CLEAR_VISITOR_CHECKINS:
      return {
        ...checkins,
        visitor: {
          data: [],
          meta: null,
        },
      }
    case CREATE_CHECKIN:
      return {
        ...checkins,
        member: {
          data: [Object.assign({}, action.checkin), ...memberCheckinsArray],
          meta: memberCheckinsMeta,
        },
      }
    case CREATE_PASSPORT_CHECKIN:
      return {
        ...checkins,
        passport: {
          data: [Object.assign({}, action.checkin), ...passportCheckinsArray],
          meta: passportCheckinsMeta,
        },
      }
    case CREATE_VISITOR_CHECKIN:
      return {
        ...checkins,
        visitor: {
          data: [Object.assign({}, action.checkin), ...visitorCheckinsArray],
          meta: visitorCheckinsMeta,
        },
      }
    case UPDATE_CHECKINS:
      if (memberCheckinsArray && memberCheckinsArray.length > 0) {
        const oldCheckins = memberCheckinsArray
        const newCheckins = action.checkins
        return {
          ...checkins,
          member: {
            data: arrayMerge(oldCheckins, newCheckins),
            meta: action.meta,
          },
        }
      }
      // list is empty so initialize so just set it.
      return {
        ...checkins,
        member: { data: action.checkins, meta: action.meta },
      }
    case UPDATE_PASSPORT_CHECKINS:
      if (passportCheckinsArray && passportCheckinsArray.length > 0) {
        const oldCheckins = passportCheckinsArray
        const newCheckins = action.checkins
        return {
          ...checkins,
          passport: {
            data: arrayMerge(oldCheckins, newCheckins),
            meta: action.meta,
          },
        }
      }
      // list is empty so initialize so just set it.
      return {
        ...checkins,
        passport: { data: action.checkins, meta: action.meta },
      }
    case UPDATE_VISITOR_CHECKINS:
      if (visitorCheckinsArray && visitorCheckinsArray.length > 0) {
        const oldCheckins = visitorCheckinsArray
        const newCheckins = action.checkins
        return {
          ...checkins,
          visitor: {
            data: arrayMerge(oldCheckins, newCheckins),
            meta: action.meta,
          },
        }
      }
      // list is empty so initialize so just set it.
      return {
        ...checkins,
        visitor: { data: action.checkins, meta: action.meta },
      }
    case SET_CHECKINS:
      return {
        ...checkins,
        member: {
          data: action.checkins,
          meta: action.meta,
        },
      }
    case SET_PASSPORT_CHECKINS:
      return {
        ...checkins,
        passport: {
          data: action.checkins,
          meta: action.meta,
        },
      }
    case SET_VISITOR_CHECKINS:
      return {
        ...checkins,
        visitor: {
          data: action.checkins,
          meta: action.meta,
        },
      }
    case DELETE_CHECKIN:
      return {
        ...checkins,
        member: {
          data: memberCheckinsArray.filter(
            checkin => checkin.id !== action.checkinId
          ),
          meta: memberCheckinsMeta,
        },
      }
    case DELETE_PASSPORT_CHECKIN:
      return {
        ...checkins,
        passport: {
          data: passportCheckinsArray.filter(
            checkin => checkin.id !== action.checkinId
          ),
          meta: passportCheckinsMeta,
        },
      }
    case DELETE_VISITOR_CHECKIN:
      return {
        ...checkins,
        visitor: {
          data: visitorCheckinsArray.filter(
            checkin => checkin.id !== action.checkinId
          ),
          meta: visitorCheckinsMeta,
        },
      }
    case UPDATE_CHECKIN:
      if (memberCheckinsArray.length === 0) {
        return {
          ...checkins,
          member: {
            data: [action.checkin],
            meta: memberCheckinsMeta,
          },
        }
      }
      let found = false
      let newCheckins = memberCheckinsArray.map(checkin => {
        if (checkin.id === action.checkin.id) {
          found = true
          return { ...checkin, ...action.checkin }
        }
        return checkin
      })
      if (!found)
        newCheckins = [...newCheckins, Object.assign({}, action.checkin)]
      return {
        ...checkins,
        member: {
          data: newCheckins,
          meta: memberCheckinsMeta,
        },
      }
    case UPDATE_PASSPORT_CHECKIN:
      if (passportCheckinsArray.length === 0) {
        return {
          ...checkins,
          passport: {
            data: [action.checkin],
            meta: passportCheckinsMeta,
          },
        }
      }
      found = false
      newCheckins = passportCheckinsArray.map(checkin => {
        if (checkin.id === action.checkin.id) {
          found = true
          return { ...checkin, ...action.checkin }
        }
        return checkin
      })
      if (!found)
        newCheckins = [...newCheckins, Object.assign({}, action.checkin)]
      return {
        ...checkins,
        passport: {
          data: newCheckins,
          meta: passportCheckinsMeta,
        },
      }
    case UPDATE_VISITOR_CHECKIN:
      if (visitorCheckinsArray.length === 0) {
        return {
          ...checkins,
          visitor: {
            data: [action.checkin],
            meta: visitorCheckinsMeta,
          },
        }
      }
      found = false
      newCheckins = visitorCheckinsArray.map(checkin => {
        if (checkin.id === action.checkin.id) {
          found = true
          return { ...checkin, ...action.checkin }
        }
        return checkin
      })
      if (!found)
        newCheckins = [...newCheckins, Object.assign({}, action.checkin)]
      return {
        ...checkins,
        visitor: {
          data: newCheckins,
          meta: visitorCheckinsMeta,
        },
      }
    default:
      return checkins
  }
}
