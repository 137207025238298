import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, Button, Col, Row } from 'reactstrap'
import otto from '../../../../img/global/otto_final-16_1024.png'

import * as Constants from '@global/Constants'
import styled from 'styled-components'

import { TextButton } from '@global/Base/Button/ButtonStyled'

import PlanRow from '../../../Plans/PlanRow'
import QuickSearch from '@global/Search/QuickSearch'

const CollapseStyled = styled(Collapse)`
  width: 100%;
  max-width: 500px;
`
const DiscountWrapper = styled.div.attrs(() => ({
  className: 'col',
}))`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`

export default class AddPlansFlow extends React.Component {
  static propTypes = {
    plans: PropTypes.array.isRequired,
    subscriptionCart: PropTypes.array.isRequired,
    toggleNewPlanModal: PropTypes.func.isRequired,
    handleRemoveDiscount: PropTypes.func.isRequired,
    handleAddPlanClicked: PropTypes.func.isRequired,
    handleAutoSuggestChange: PropTypes.func.isRequired,
    updateSubscriptionCart: PropTypes.func.isRequired,
    removePlanFromCart: PropTypes.func.isRequired,
    showAddPlanDropdown: PropTypes.bool.isRequired,
    isBillingEnabled: PropTypes.bool.isRequired,
    isOnlyFreePlanSelected: PropTypes.bool.isRequired,
    isDiscountApplied: PropTypes.bool.isRequired,
    toggleAddDiscountModal: PropTypes.func.isRequired,
    discountObject: PropTypes.object.isRequired,
    defaultCurrency: PropTypes.string.isRequired,
  }

  handleQuantityChange = (index, event) => {
    const amount = event.target.value
    let cartCopy = Object.assign([], this.props.subscriptionCart)
    cartCopy[index].uiState.quantity = amount
    cartCopy = Constants.calculatePlanTotalsWithQuantities(cartCopy)
    const total = Constants.calculateTeamPlanTotal({
      cart: cartCopy,
      discountTotalDollars: this.props.discountObject
        ? this.props.discountObject.amount_off
        : 0,
      prefs: null,
      customer: null,
    })
    const subTotal = Constants.calculateTeamPlanTotal({
      cart: cartCopy,
      discountTotalDollars: null,
      prefs: null,
      customer: null,
    }) // don't factor discounts
    this.props.updateSubscriptionCart(cartCopy)
    this.setState({
      planGrandTotal: total,
      planSubtotal: subTotal,
      subscriptionCart: cartCopy,
    })
  }
  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        <Button
          className="p-2 my-0 btn btn-primary btn-block"
          onClick={this.props.toggleNewPlanModal}
        >
          Create New Plan
          <i className="fa fa-plus fa-sm pull-right" aria-hidden="true" />
        </Button>
      </div>
    )
  }
  renderSubscriptionCart = () => {
    return this.props.subscriptionCart.map((cartObject, i) => {
      const symbol =
        cartObject.plan && cartObject.plan.currency_units
          ? Constants.getCurrencySymbolFromName(cartObject.plan.currency_units)
          : '$'
      const plan = cartObject.plan
        ? cartObject.plan
        : {
            name: '',
            status: '',
            cost: 0,
            description: '',
            frequency: '',
            currency: '',
          }
      return (
        <PlanRow
          defaultCurrency={this.props.defaultCurrency}
          key={i}
          index={i}
          plan={plan}
          totalCost={cartObject.totalCost}
          symbol={symbol}
          quantity={cartObject.uiState.quantity}
          handleQuantityChange={this.handleQuantityChange}
          removePlanFromCart={index => this.props.removePlanFromCart(index)}
        />
      )
    })
  }
  render() {
    let ignorePlanIds = []
    if (this.props.subscriptionCart.length > 0) {
      ignorePlanIds = this.props.subscriptionCart.map(
        cartObject => cartObject.plan.id
      )
    }
    return (
      <React.Fragment>
        <div className="col-12">
          <h5>Team Plans</h5>
        </div>
        <div className="col-12">
          {this.renderSubscriptionCart()}
          <div className="row">
            <Col>
              <Button
                className="btn btn-primary"
                onClick={this.props.handleAddPlanClicked}
              >
                Add Plans
              </Button>
              <CollapseStyled isOpen={this.props.showAddPlanDropdown}>
                {this.props.showAddPlanDropdown && (
                  <QuickSearch
                    id="add-plan-search"
                    type="Plan"
                    showTitle={false}
                    clearOnSelect
                    // handleSearchItemCleared={() =>
                    //   this.props.handleAutoSuggestChange(null)
                    // }
                    handleSearchItemSelected={plan => {
                      // hideAddPlanInputDropdown()
                      // const hideAddPlanInputDropdown = () => setShowAddPlanDropdown(false)
                      this.props.handleAutoSuggestChange(plan)
                    }}
                    ignoreIds={ignorePlanIds}
                  />
                )}
              </CollapseStyled>
            </Col>
            <DiscountWrapper>
              {this.props.subscriptionCart.length > 0 &&
                this.props.isBillingEnabled &&
                !this.props.isOnlyFreePlanSelected &&
                this.props.subscriptionCart[0].plan.frequency === 'month' && (
                  <div>
                    {!this.props.isDiscountApplied ? (
                      // <button />'s REQUIRE the type='button'
                      <TextButton onClick={this.props.toggleAddDiscountModal}>
                        Add Discount
                      </TextButton>
                    ) : (
                      <React.Fragment>
                        {this.props.discountObject
                          ? `Discount applied for ${Constants.getCurrencySymbolFromName(
                              this.props.defaultCurrency
                            )}${Number(
                              this.props.discountObject.amount_off
                            ).toFixed(2)} `
                          : null}
                        <TextButton
                          className="m-0 ml-2 p-0"
                          onClick={this.props.handleRemoveDiscount}
                        >
                          Remove
                        </TextButton>
                      </React.Fragment>
                    )}
                  </div>
                )}
            </DiscountWrapper>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

AddPlansFlow.displayName = 'AddPlansFlow'
