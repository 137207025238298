import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { format, isAfter } from 'date-fns'
import * as _ from 'lodash'
import { BaseButton } from '@bit/coworks.base-components.base-buttons'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { theme } from '@global/Base/baseComponentColorUtils'
import { darken } from '@global/Colors/ColorUtils'

const moment = require('moment')

const NotificationItem = styled(BaseButton)`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 400px;
  padding: 15px 10px 15px 15px;
  margin: 0;
  cursor: pointer;
  position: relative;
  background-color: ${props =>
    props.theme.colors[props.read ? 'white' : 'neutral2']};
  border-top: ${theme('values.outlines.outline')} transparent;
  border-bottom: ${theme('values.outlines.outline')};
  ${props =>
    props.showBottomBorder ? 'transparent' : props.theme.colors.neutral4};
  outline: none !important;
  text-transform: none;
  border-left: ${props =>
    props.showCalloutColor
      ? `6px solid ${props.theme.colors.primary}`
      : 'none'};
  &:hover {
    background-color: ${props =>
      darken(props.theme.colors[props.read ? 'white' : 'neutral2'], 0.05)};
  }
`

const Container = styled.div`
  display: flex;
  text-overflow: ellipsis;
  font-size: ${theme('fonts.sizes.p.fontSize')};
  width: 370px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Label = styled.div`
  text-align: left;
`

const PillArea = styled.div`
  margin-top: 10px;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: flex-start;
`

const IconContainer = styled.div`
  height: 100%;
  margin-right: 5px;
`

const TimestampContainer = styled.div`
  display: flex;
  font-size: ${theme('fonts.sizes.pSmall.fontSize')};
  color: ${theme('fonts.colors.gray')};
  height: 100%;
  justify-content: flex-end;
  width: 80px;
  margin-left: 15px;
`

const NotificationListItem = ({
  notification,
  route,
  routeState,
  icon,
  onClick,
  history,
  lastClearDate,
  badgeComponent,
  ...other
}) => {
  return (
    <NotificationItem
      key={`notification-${notification.id}`}
      onClick={() =>
        onClick(notification.id, () => navigate(route, routeState, history))
      }
      {...other}
      showCalloutColor={showUnseenBorderColor(notification, lastClearDate)}
      read={notification.read}
    >
      <Container>
        <IconContainer>
          <FontAwesomeIcon icon={icon} />
        </IconContainer>
        <Content>
          {getNotificationTitle(notification)}
          <PillArea show={badgeComponent}>{badgeComponent}</PillArea>
        </Content>
        <TimestampContainer>
          {formatTimestamp(notification.created_at)}
        </TimestampContainer>
      </Container>
    </NotificationItem>
  )
}

// eslint-disable-next-line react/no-multi-comp, react/display-name
function getNotificationTitle(notification) {
  // Note: if you're adding more types, enable the type in ALLOWED_NOTIFICATION_TYPES in header.jsx
  //    You also need to make sure to determine the route
  // This eventually needs to move into the backend...
  switch (notification.type) {
    case 'NotificationExternalBooking':
      return (
        <Label>
          New External Booking from{' '}
          <strong>
            {notification.contact
              ? getContactName(notification.contact)
              : 'Guest '}
          </strong>
          {' on '}{' '}
          <strong>
            {' '}
            {moment(notification.booking.start_time).format('ddd, MMM DD')}{' '}
            {' at '}
            {moment(notification.booking.start_time).format('h:mm A')}
          </strong>
        </Label>
      )
    case 'NotificationMembershipRequest':
      return (
        <Label>
          New Membership Request from{' '}
          <strong>{getContactName(notification.contact)}</strong>
        </Label>
      )
    case 'NotificationMembershipSignup':
      return (
        <Label>
          New Membership Signup from{' '}
          <strong>{getTeamContact(notification.team)}</strong>
          for{' '}
          <strong>
            {' '}
            {notification.plan
              ? notification.plan.name
              : 'a new membership!'}{' '}
          </strong>
        </Label>
      )
    case 'NotificationDayPass':
      return (
        <Label>
          New Day Pass Purchase from{' '}
          <strong>{getMemberOrContact(notification)}</strong>
          {' '} for{' '}
          <strong>
            {' '}
            {notification.plan
              ? notification.plan.name
              : ' a Day Pass'}{' '}
          </strong>
        </Label>
      )
    case 'NotificationTourRequest':
      return (
        <Label>
          New Tour Request from{' '}
          <strong>{getContactName(notification.contact)}</strong>
        </Label>
      )
    case 'NotificationBookingOverage':
      return (
        <Label>
          <strong>{notification.team.name}</strong> has exceeded their
          conference room hour allowance.
        </Label>
      )
    case 'NotificationAnnouncement':
      return <span>{notification.message}</span>
    case 'NotificationLeadFollowUp':
      return (
        <Label>
          Lead follow-up reminder for {getContactName(notification.contact)}
        </Label>
      )
    case 'NotificationVisitor':
      return (
        <Label>You have a new visitor checked in and waiting for you!</Label>
      )
    default:
      return null
  }
}

function formatTimestamp(timestamp) {
  const current = Date.now()
  const previous = new Date(Date.parse(timestamp))

  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = current - previous

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)}s ago`
  } else if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} min ago`
  } else if (elapsed < msPerDay / 2) {
    return format(Date.parse(timestamp), 'h:mm A')
  }

  return format(Date.parse(timestamp), 'M/D/YY')
}

function showUnseenBorderColor(notification, lastClearDate) {
  //if notification was made before the last clear date then we've "seen it"
  // if (notification.read) return false This is "clicked", not "seen"
  if (!notification) return false
  if (
    !notification.read &&
    lastClearDate &&
    isAfter(notification.created_at, lastClearDate)
  ) {
    return true
  }
  return false
}

function getContactName(contact) {
  if (!contact) return 'Contact with no name'
  if (contact.first_name || contact.last_name) {
    return `${contact.first_name} ${contact.last_name}`
  } else if (contact.email) {
    return `${contact.email}`
  }
  return 'Contact with no name'
}

function getMemberOrContact(notification) {
  let contact = null
  if (notification.member) {
    contact = notification.member
  } 
  else if (notification.contact) {
    contact = notification.contact
  }

  if (contact) {
    if (contact.first_name || contact.last_name) {
      return `${contact.first_name} ${contact.last_name}`
    } else if (contact.email) {
      return `${contact.email}`
    }
  }
  
  return 'Guest'

}

function getTeamContact(team) {
  if (team && team.admins && team.admins.length > 0) {
    const admin = team.admins[0]
    return `${admin.first_name} ${admin.last_name} `
  }
  return 'New Member '
}

function navigate(route, state, history) {
  history.push(route, state)
}

NotificationListItem.propTypes = {
  notification: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  routeState: PropTypes.string,
  lastClearDate: PropTypes.object,
  route: PropTypes.object,
  badgeComponent: PropTypes.object,
}
NotificationListItem.defaultProps = {
  notification: null,
}

NotificationListItem.displayName = 'Notification List Item'

export default NotificationListItem
