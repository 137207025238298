/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, Input, Collapse } from 'reactstrap'
import CreatableSelect from 'react-select/creatable'
import { LabelStyled } from '@global/Form/FormComponents'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import { default as SortByFilter } from '@global/Filters/Filters'
import Badge from '@global/Base/Badge/Badge'
import { LeadsTabInfo } from '@global/Constants/CRMConstants'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import SelectDropdown from './SelectDropdown'

const Container = styled.div`
  margin: 12px 0;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 0;
`
const InputContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 12px;
`
const InputWrapper = styled.div`
  flex: 1;
  margin: 0px 12px 8px;
  min-width: 40%;
  .form-control {
    width: auto;
  }
`
const CollapseStyled = styled(Collapse)`
  padding: 14px 8px;
`
const ActionButtons = styled.div`
  display: flex;
  flex-flow nowrap row;
  justify-content: space-between;
`
const FilterText = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px;
  flex-wrap: wrap;
`
const FilterActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const BadgeStyle = styled(Badge)`
  margin: 2px 8px 2px 0;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 85%;
`
const SelectDropdownStyled = styled(SelectDropdown)`
  margin: 0 6px;
`
const Label = styled.span``
const CreatableStyled = styled(CreatableSelect)``
const DropdownWrapper = styled.div`
  margin-right: 12px;
`
const ExtraActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
`

function FilterCollection({
  campusSelected,
  activeStatus,
  createdBy,
  selects,
  inputs,
  theme,
  type,
  ...other
}) {
  const [filterOpen, setFilterOpen] = useState(false)
  const applyThemeColors = existingTheme => {
    return {
      ...existingTheme,
      borderRadius: 0,
      colors: {
        ...existingTheme.colors,
        primary25: theme.colorPrimaryHoverSearchRowHighlight,
        primary: theme.colorPrimaryHover,
      },
    }
  }

  const clearFilters = () => {
    for (let index = 0; index < inputs.length; index++) {
      inputs[index].props.onChange(null)
    }
  }

  const renderFilterActions = () => {
    const filters = []
    if (createdBy && createdBy.length > 0)
      filters.push(`Created By: ${createdBy.map(elm => elm.label).join(', ')}`)
    if (activeStatus && activeStatus.length > 0 && type === 'leads')
      filters.push(
        `Temperature: ${activeStatus.map(elm => elm.label).join(', ')}`
      )
    if (campusSelected && campusSelected.length > 0)
      filters.push(`Campus: ${campusSelected.map(elm => elm.label).join(', ')}`)

    return (
      <FilterActionWrapper>
        <Button onClick={() => setFilterOpen(!filterOpen)}>
          <FontAwesomeIcon icon={'sliders-h'} /> Filter
        </Button>
        <FilterText>
          {filters.map(filter => (
            <BadgeStyle>{filter}</BadgeStyle>
          ))}
        </FilterText>
        {filters.length > 0 && (
          <TextButton style={{ marginBottom: 0 }} onClick={clearFilters}>
            clear
          </TextButton>
        )}
      </FilterActionWrapper>
    )
  }
  const inputFactory = input =>
    input.map((input, index) => {
      if (input.hide) return null
      return (
        <InputWrapper>
          <Label className="text-muted">{input.label}</Label>
          <CreatableStyled {...input.props} theme={applyThemeColors} />
        </InputWrapper>
      )
    })
  const selectFactory = selects =>
    selects.map((select, index) => {
      if (select.hide) return null
      return <SelectDropdownStyled {...select.props} />
    })

  return (
    <Container {...other}>
      <ActionButtons>
        {renderFilterActions()}
        <ExtraActions>
          {selectFactory(selects)}
          {/* {archiveDropdown()}
          {sortByDropdown()} */}
        </ExtraActions>
      </ActionButtons>
      <Wrapper className="card">
        <CollapseStyled isOpen={filterOpen}>
          <InputContainer>
            {inputFactory(inputs)}
            {/* {campusFilter()}
            {type === LeadsTabInfo[0].slug && statusFilter()}
            {createdByFilter()} */}
          </InputContainer>
        </CollapseStyled>
      </Wrapper>
    </Container>
  )
}

FilterCollection.propTypes = {
  campusSelected: PropTypes.object,
  createdBy: PropTypes.object,
  activeStatus: PropTypes.string,
  selects: PropTypes.array,
  inputs: PropTypes.array,
  theme: PropTypes.object,
  type: PropTypes.string,
}

FilterCollection.defaultProps = {
  selects: [],
  inputs: [],
}
FilterCollection.displayName = 'Leads Top Bar'

export default FilterCollection
