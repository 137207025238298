import * as Yup from 'yup'
import { isValidHex } from '@global/Colors/ColorUtils'

const validationSchema = Yup.object().shape({
  billing_enabled: Yup.boolean(),
  directory_enabled: Yup.boolean(),
  events_enabled: Yup.boolean(),
  bookings_enabled: Yup.boolean(),
  resources_enabled: Yup.boolean(),
  equipment_enabled: Yup.boolean(),
  is_whitelabeled: Yup.boolean(),
  pass_cc_fee_to_member: Yup.boolean(),
  pass_ach_fee_to_member: Yup.boolean(),
  automatic_invoice_fees: Yup.string().nullable(),
  send_onboarding_email: Yup.boolean(),
  show_booking_migration: Yup.boolean(),
  show_member_migration: Yup.boolean(),
  event_visibility_default: Yup.boolean(),
  days_until_invoice_due: Yup.number()
    .min(1, 'Must be between 1 and 31')
    .max(31, 'Must be between 1 and 31')
    .positive('Must be a positive number')
    .typeError('Must be a number'),
  campus_max: Yup.number(),
  custom_field_max: Yup.number(),
  status: Yup.string(),
  ios_store_url: Yup.string().nullable(),
  android_store_url: Yup.string().nullable(),
  branding_primary_color: Yup.string().test(
    'valid-primary-color',
    'This is not a valid color.',
    // eslint-disable-next-line func-names
    function (value) {
      return isValidHex(value)
    }
  ),
  branding_secondary_color: Yup.string().test(
    'valid-secondary-color',
    'This is not a valid color.',
    // eslint-disable-next-line func-names
    function (value) {
      return isValidHex(value)
    }
  ),
  currency: Yup.string().required(),
  sales_email: Yup.string()
    .email('This is not a valid email address')
    .nullable(),
  membership_agreement_url: Yup.string('This must be a url.')
    .url('This is not a valid url')
    .nullable(),
})

export { validationSchema }
