/* eslint-disable react/no-multi-comp */
import { LabelStyled } from '@global/Form/FormComponents'
import { Form, withFormik } from 'formik'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useAvailabilityCheck } from '../../../../../hooks'
import DateDropdown from '../../Filter/DateDropdown'
import TimeDropdownWithBusinessHours from '../../Filter/TimeDropdownWithBusinessHours'
import * as Constants from '@global/Constants'
import { isInclusivelyBeforeDay } from 'react-dates'
import { readableColor, lighten } from 'polished'
import Spinner from '@global/Spinner'
import moment from 'moment'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const Container = styled(Form)`
  display: flex;
  flex-flow: column nowrap;

  .DateInput_input__focused {
    border-bottom: 2px solid
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
  }

  .CalendarDay__selected {
    background: ${props =>
      props.color ? props.color : props.theme.colorPrimary};
    color: ${props => props.theme.colorWhite};
    border: 1px double
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
    &:hover {
      background: ${props =>
        lighten(0.1, props.color ? props.color : props.theme.colorPrimary)};
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
    color: ${props => props.theme.colorWhite};
  }
`
const TimeDropdownStyled = styled(TimeDropdownWithBusinessHours)`
  /* width: 100%; */
`
const FormWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1;
`
const TimeInputs = styled.div`
  display: flex;
`

const BookingInput = ({
  bookingDetails,
  values,
  campus,
  room,
  validateForm,
  setFieldValue,
  setFieldError,
  setValues,
  isValid,
  onUpdateDetails,
  errors,
  ...other
}) => {
  const [{ response, loading }, query] = useAvailabilityCheck(room.id)
  const [validated, setValidated] = useState({
    passed: false,
    increment: 0,
  })
  const { start, end, date } = values

  useEffect(() => {
    if (!loading && end && isValid) {
      availabilityNode()
      responseAction()
    }
    if (!start || !end) {
      onUpdateDetails(null)
    }
  }, [validated, isValid, loading])

  const availabilityNode = () => {
    if (!validated.passed) {
      setValidated({ passed: true, increment: validated.increment + 1 })
      query({
        campus_id: campus.id,
        start_time: Constants.concatenateDateTime(date.format('l'), start),
        end_time: Constants.concatenateDateTime(date.format('l'), end),
      })
    }
  }

  const responseAction = () => {
    if (response && validated.passed) {
      if (response.available) {
        const data = {
          date,
          timeRange: { start, end },
          available: true,
        }
        onUpdateDetails(data)
      } else {
        setFieldError(
          'end',
          'This date and time is not available. Please choose another time and try again.'
        )
      }
    } else {
      onUpdateDetails(null)
    }
  }

  const updateDate = date => {
    updateValues({
      date,
      start: null,
      end: null,
    })
  }
  const updateStart = start => {
    updateValues({
      start,
      end: null,
    })
  }
  const updateEnd = end => {
    setFieldValue('end', end)
    setValidated({ passed: false, increment: validated.increment + 1 })
  }

  const updateValues = newValues => {
    setValues({
      ...values,
      ...newValues,
    })
  }

  const constructInlineMessage = () => {
    let errMsg = null
    const errorKeys = Object.keys(errors)
    for (const key of errorKeys) {
      if (errors[key]) {
        errMsg = errors[key]
        break
      }
    }
    if (errMsg) return <div className="text-danger">{errMsg}</div>
    if (values.start && values.end) {
      return (
        <React.Fragment>
          <FontAwesomeIcon icon={'check'} /> This time is available
        </React.Fragment>
      )
    }
    return null
  }

  return (
    <Container {...other}>
      <TimeInputs>
        <FormWrapper>
          <LabelStyled htmlFor="firstName">Date</LabelStyled>
          <DateDropdown
            withBusinessHours
            value={date}
            onChange={updateDate}
            calendarProps={{
              isOutsideRange: day =>
                isInclusivelyBeforeDay(day, moment().subtract(1, 'day')),
            }}
            disabled={loading}
          />
        </FormWrapper>
        <FormWrapper
          style={{
            margin: '0 12px',
          }}
        >
          <LabelStyled htmlFor="firstName">Start</LabelStyled>
          <TimeDropdownStyled
            date={date}
            value={start}
            timeFormat="h:mm A"
            minInterval={Constants.EXTERNAL_BOOKING_TIME_INTERVAL}
            onChange={updateStart}
            disabled={loading}
          />
        </FormWrapper>
        <FormWrapper>
          <LabelStyled htmlFor="firstName">End</LabelStyled>
          <TimeDropdownStyled
            date={date}
            value={end}
            timeFormat="h:mm A"
            minInterval={Constants.EXTERNAL_BOOKING_TIME_INTERVAL}
            onChange={updateEnd}
            earliestTime={start}
            disabled={!start || loading}
          />
        </FormWrapper>
      </TimeInputs>

      <div>
        {loading ? (
          <React.Fragment>
            <FontAwesomeIcon icon="circle-notch" spin /> Checking for
            conflicts...
          </React.Fragment>
        ) : (
          constructInlineMessage()
        )}
      </div>
      {/* {errors.start && <div className="text-danger">{errors.start}</div>}
      {errors.end && <div className="text-danger">{errors.end}</div>} */}
    </Container>
  )
}

BookingInput.displayName = 'BookingInput'

BookingInput.propTypes = {
  room: PropTypes.object,
  campus: PropTypes.object,
  bookingDetails: PropTypes.object,
  onUpdateDetails: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  isValid: PropTypes.bool,
  validateForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setValues: PropTypes.func,
}

export default withFormik({
  mapPropsToValues: props => {
    return {
      date: props.bookingDetails.date,
      start: props.bookingDetails.timeRange.start,
      end: props.bookingDetails.timeRange.end,
    }
  },

  validationSchema: Yup.object().shape({
    date: Yup.object(),
    start: Yup.string()
      .nullable()
      .test('is-true', 'Booking time must be in the future.', function (value) {
        const inputTime = moment(
          Constants.concatenateDateTime(this.parent.date.format('l'), value)
        )
        const nowTime = moment()
        return value ? inputTime.isAfter(nowTime) : true
      }),
    end: Yup.string()
      .nullable()
      .test(
        'is-true',
        'The end time must be after the start time.',
        // eslint-disable-next-line func-names
        function (value) {
          const startTime = moment(this.parent.start, 'h:mm a')
          const endTime = moment(value, 'h:mm a')
          return value ? endTime.isAfter(startTime) : true
        }
      ),
  }),
})(BookingInput)
