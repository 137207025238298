import React from 'react'
import Proptypes from 'prop-types'

const COWORKS_TERMS = 'https://coworksapp.com/privacy-policy/'

const TermsOfServiceDescription = ({
  campusName = 'the campus',
  communityTerms,
  cost,
  frequency,
  billingEnabled,
}) => {
  let isValid = false
  if (communityTerms) isValid = true

  return (
    <div>
      {`By purchasing, you're agreeing to Coworks’ `}
      <a href={COWORKS_TERMS} target="_blank">
        Terms of Service
      </a>
      {` and you agrees to `}
      {campusName[campusName.length - 1] === 's'
        ? `${campusName}'`
        : `${campusName}\'s`}{' '}
      {communityTerms ? (
        <a href={communityTerms} target="_blank">
          Terms of Service
        </a>
      ) : (
        ` Terms of service`
      )}
      {` and you consent to allowing ${campusName} to contact you.`}
      {Number(cost) > 0 && billingEnabled
        ? ` and you are authorizing us to charge your card ${cost} per ${frequency}.`
        : null}
    </div>
  )
}

TermsOfServiceDescription.propTypes = {
  campusName: Proptypes.string,
  communityTerms: Proptypes.string,
  cost: Proptypes.string,
  billingEnabled: Proptypes.bool,
  frequency: Proptypes.string,
}

TermsOfServiceDescription.displayName = 'Terms of Service Description'

export default TermsOfServiceDescription
