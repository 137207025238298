/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import * as defaultResource1 from '../../img/resources/resources_blue.jpg'
import Spinner from '@global/Spinner'
import { Button, Row } from 'reactstrap'
import { LabelStyled } from '@global/Form/FormComponents'
import styled from 'styled-components'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Badge from '@global/Base/Badge/Badge'

class Handle extends Component {
  render() {
    return (
      <div {...this.props}>
        <FontAwesomeIcon icon={'bars'} />
      </div>
    )
  }
}

Handle.displayName = 'Drag me'

Handle.propTypes = {}

const DragableHandle = styled(sortableHandle(Handle, { withRef: true }))`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #b3b3b3;
  cursor: grab;

  :hover {
    color: #a1a6ce;
  }
`

const OrderNumber = styled.h6`
  margin-left: 20px;
  margin-bottom: 0;
`

class Form extends Component {
  render() {
    const {
      slide,
      formIndex,
      removeSlideCard,
      handleSlideDescriptionChange,
      handleImageUpload,
      imageUrl,
      loading,
      ...other
    } = this.props
    const description = slide.description ? slide.description : ''
    return (
      <li className="d-flex align-items-stretch" {...other}>
        <div className="card w-100 d-flex mb-0">
          <div className="card-header">
            <div className={'d-flex justify-content-between'}>
              <div className="d-flex flex-row align-items-center ">
                <DragableHandle />
                <OrderNumber className="text-muted">{`Slide #${
                  formIndex + 1
                }`}</OrderNumber>
              </div>
              <div>
                <Button
                  className="btn btn-link pull-right"
                  style={{
                    color: '#f86c6b',
                    marginTop: 0,
                  }}
                  disabled={loading}
                  onClick={removeSlideCard}
                >
                  <FontAwesomeIcon icon="trash-alt" />
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body d-flex flex-column">
            <div className="m-2">
              <textarea
                rows="2"
                name={'description'}
                className="w-100 form-control"
                placeholder="Slide description"
                onChange={handleSlideDescriptionChange}
                value={description}
                maxLength="140"
              />
            </div>
            <div className="upload-photo-global">
              <ImageUploadComponent
                style={{ padding: '0px', margin: '0.5rem', marginTop: '0' }}
                index={formIndex}
                aspectRatio={1}
                id={`imageUploadComponentstring${formIndex}`}
                onUpload={handleImageUpload}
                imageUrl={imageUrl}
                ref={`imageUploadComponentstring${formIndex}`}
              />
            </div>
          </div>
        </div>
      </li>
    )
  }
}

Form.displayName = 'SortableContainer'

Form.propTypes = {
  slide: PropTypes.object,
  formIndex: PropTypes.number,
  removeSlideCard: PropTypes.func,
  handleSlideDescriptionChange: PropTypes.func,
  handleImageUpload: PropTypes.func,
  imageUrl: PropTypes.string,
  loading: PropTypes.bool,
}

const SortableElement = sortableElement(Form, { withRef: true })

class Container extends Component {
  render() {
    return <ul {...this.props}>{this.props.children}</ul>
  }
}

Container.displayName = 'SortableContainer'

Container.propTypes = {
  children: PropTypes.object,
}

const SortableContainer = sortableContainer(
  styled(Container)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: stretch;
    align-items: stretch;
    list-style-type: none;
    grid-gap: 24px 24px;
    z-index: 1000 !important;

    li {
      width: 100%;
      height: 100%;
      z-index: 1000 !important;

      .form-control {
        height: 100px;
      }

      .pull-right {
        font-size: 18px;
        color: ${props => props.theme.colorDanger};
        background-color: transparent;
        border-radius: 5px;
        border-color: transparent;

        &:hover {
          border-color: ${props => props.theme.colorDanger};
          background-color: #e8e8e8;
        }
      }

      .upload-photo-global,
      .card-body {
        flex: 1;
      }
    }
  `,
  { withRef: true }
)

const SortableResourceImageForms = ({
  slides,
  loading,
  removeSlideCard,
  handleSlideDescriptionChange,
  handleImageUpload,
  children,
  ...other
}) => {
  const getSlideImage = slide => {
    if (slide.slide_photo && slide.slide_photo.file_url) {
      return slide.slide_photo.file_url
    } else if (slide.id) {
      return defaultResource1
    }
    return null
  }
  return (
    <SortableContainer
      helperClass="sortableHelper"
      lockToContainerEdges
      axis="xy"
      useDragHandle
      disableAutoscroll
      {...other}
    >
      {slides.map((slide, index) => (
        <SortableElement
          index={index}
          key={`sortable-item-${slide.id ? slide.id : slide.new_id}-${index}`}
          formIndex={index}
          slide={slide}
          loading={loading}
          imageUrl={getSlideImage(slide)}
          removeSlideCard={() => removeSlideCard(index)}
          handleImageUpload={val => handleImageUpload(index, val)}
          handleSlideDescriptionChange={event =>
            handleSlideDescriptionChange(index, event)
          }
        />
      ))}
      {children}
    </SortableContainer>
  )
}

SortableResourceImageForms.propTypes = {
  slides: PropTypes.array,
  handleImageUpload: PropTypes.func,
  handleSlideDescriptionChange: PropTypes.func,
  loading: PropTypes.bool,
  removeSlideCard: PropTypes.bool,
  children: PropTypes.object,
}

SortableResourceImageForms.displayName = 'Sortable'

export default SortableResourceImageForms
