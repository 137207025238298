/* eslint-disable no-unused-vars,max-depth,no-lonely-if,react/jsx-boolean-value,radix,no-param-reassign */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import faker from 'faker'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import InviteNewMember from '../../Members/NewMember/InviteNewMember'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import '../../../../styles/coworks/components/NewTeamForm.scss'
import {
  Button,
  Input,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import PlanFields from '../../../Plans/Plan/PlanFields'
import Subscription from '../../../Plans/Subscription'
import { Row, Col } from 'reactstrap'
import _PlanFormModal from '../../../Templates/Forms/_PlanFormModal'
import * as Yup from 'yup'

import { toast } from 'react-toastify'

import * as Constants from '@global/Constants'
import { LabelStyled, SingleDatePicker } from '@global/Form/FormComponents'
import otto from '../../../../img/placeholders/otto_black_white.jpeg'

import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import CreditCardForm from '@global/Stripe/CreditCardForm'

import DiscountModal from '@global/Modal/DiscountModal'

import * as userActions from '@reduxActions/userActions'
import * as planActions from '@reduxActions/planActions'
import * as uiActions from '@reduxActions/planActions'
import AddPlansFlow from './AddPlansFlow'
import {
  shouldDisableChargeNow,
  getIntendedPaymentSource,
  CHARGE_AUTOMATICALLY,
  SEND_INVOICE,
} from '@global/Constants/PaymentConstants'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import moment from 'moment'
import { checkDoorProvider } from '@app/components/Community/Integrations/IntegrationConstants'
import GenericModal from '@global/GenericModal'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import AvatarNamePlate from '@global/Avatar/AvatarNamePlate'
import { withTheme } from 'styled-components'

class NewTeamForm extends React.PureComponent {
  static propTypes = {
    user_actions: PropTypes.object,
    user: PropTypes.object,
    plan_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    isFetching: PropTypes.bool,
    history: PropTypes.object,
    match: PropTypes.object,
    handleIsCreatingNewPlan: PropTypes.func,
    createTeam: PropTypes.func.isRequired,
    cost: PropTypes.number,
    description: PropTypes.string,
    errors: PropTypes.array,
    goBack: PropTypes.func.isRequired,
    handleFrequencyDropdownChange: PropTypes.func.isRequired,
    handleImageUpload: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleStartDateChanged: PropTypes.func.isRequired,
    teamName: PropTypes.string,
    planCost: PropTypes.number,
    website: PropTypes.string,
    planName: PropTypes.string,
    planDescription: PropTypes.string,
    planFrequency: PropTypes.string,
    planCurrency: PropTypes.string,
    isExtraTeamDetailsCollapsed: PropTypes.bool.isRequired,
    toggleExtraTeamDetails: PropTypes.func.isRequired,
    toggleNewPlanModal: PropTypes.func.isRequired,
    showNewPlanModal: PropTypes.bool.isRequired,
    showAddDiscountModal: PropTypes.bool.isRequired,
    toggleAddDiscountModal: PropTypes.func.isRequired,
    stripe: PropTypes.object,
    handleProrateToggle: PropTypes.func,
    isProrated: PropTypes.bool,
    monthlyBillDate: PropTypes.number,
    conferenceHoursAllowance: PropTypes.number,
    handleMonthlyBillDateChanged: PropTypes.func,
    handleProrateLength: PropTypes.func,
    getProrateLength: PropTypes.func,
    getMonthlyBillDate: PropTypes.func,
    startDate: PropTypes.object,
    saveDiscount: PropTypes.func,
    planOnFly: PropTypes.object,
    isBillingEnabled: PropTypes.bool.isRequired,
    navFromMember: PropTypes.bool.isRequired,
    lead: PropTypes.object,
    joinedAt: PropTypes.object,
    userJoinedAt: PropTypes.object,
    handleJoinedAtChanged: PropTypes.func,
    handleUserJoinedAtChanged: PropTypes.func,
    handleConferenceHoursChange: PropTypes.func,
    integrations: PropTypes.object,
    discountObject: PropTypes.object,
    captureCard: PropTypes.string,
    theme: PropTypes.object,
  }
  constructor(props) {
    super(props)
    const lead = this.props.lead ? this.props.lead : null
    console.log(lead)
    const commPrefs = this.props.activeCommunity.community_preference
    const { chargeNowDisabled, billingType } = shouldDisableChargeNow(commPrefs)
    this.state = {
      subscriptionCart: [],
      showAddPlanButton: false,
      showAddPlanDropdown: false,
      chargeNowDisabled: chargeNowDisabled,
      captureCard: chargeNowDisabled ? 'true' : 'false',
      showAccess: false,
      doorAccess: [],
      firstName: lead ? lead.first_name : '',
      lastName: lead ? lead.last_name : '',
      email: lead ? lead.email : '',
      phone: lead ? lead.phone : '',
      title: lead ? lead.title : '',
      website: lead ? lead.team_website : '',
      lead,
      teamName:
        this.props.lead && this.props.lead.company_name
          ? this.props.lead.company_name
          : '',
      sendWelcomeEmail:
        this.props.activeCommunity.community_preference
          .send_onboarding_email !== null
          ? this.props.activeCommunity.community_preference
              .send_onboarding_email
          : true,
      isOnlyFreePlanSelected: false,
      cardToken: null,
      billingType: billingType,
      isProrated: true,
      daysUntilDue: 30,

      // selectedPlanAfterDiscountTotal: 0, // used for the discount modal
      inlineErrors: {
        // form validation
        teamName: false,
        email: false,
        firstName: false,
        lastName: false,
        plansError: false,
      },
      touched: {
        // form validation
        teamName: false,
        email: false,
        firstName: false,
        lastName: false,
      },
      isEnabled: false, // form validation
      emailValid: false,
      formErrors: { email: '' }, // form validation errors
      emailIsUnique: false,
      profileExistsInCommunity: false,
      emailExistsObj: null,
      dateFocused: false,
      userDateFocused: false,
      accessDate: moment(),
      showExistingMemberModal: false,
    }
  }
  componentDidMount() {
    if (this.props.lead) {
      this.checkEmailExists(this.state.email)
      this.validateField('teamName', this.state.teamName)
    }
  }
  fakeIt = () => {
    // this method will fake some if the data
    // dev only mode
    this.setState({
      email: faker.internet.email(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      title: faker.name.jobTitle(),
      phone: faker.phone.phoneNumber(),
      teamName: faker.company.companyName(),
    })
  }
  handleCloseExistingMemberModal = () => {
    this.setState({
      showExistingMemberModal: false,
    })
  }
  getExistingMemberText = () => {
    let text = ''
    if (this.state.emailExistsObj) {
      if (this.state.emailExistsObj.profile_exists_in_campus) {
        text = 'A member with this email already exists in this campus. '
      } else if (this.state.emailExistsObj.profile_exists_in_community) {
        text = `A member with this email has already been added to ${
          this.state.emailExistsObj.campus_name
            ? this.state.emailExistsObj.campus_name
            : 'another campus'
        } in your community. `
      } else {
        text = 'A member with this email already exists in your community. '
      }

      if (this.state.emailExistsObj.active_user === false) {
        text +=
          'This member is currently inactive. Go to their profile to re-activate.'
      }
    }
    return text
  }
  handleViewExistingMember = () => {
    const { history, match } = this.props
    NavigationConstants.navigateToMember({
      history,
      match,
      member: this.state.emailExistsObj.user,
    })
  }
  checkEmailExists = email => {
    this.props.user_actions
      .checkEmailExists(email, this.props.activeCampus.id)
      .then(response => {
        const emailIsUnique = !response.emailExists.email_exists
        const profileExistsInCommunity =
          response.emailExists.profile_exists_in_community
        const emailExistsObj = response.emailExists
        let showExistingMemberModal = this.state.showExistingMemberModal
        if (profileExistsInCommunity) {
          showExistingMemberModal = true
        }
        this.setState({
          emailIsUnique,
          profileExistsInCommunity,
          emailExistsObj,
          showExistingMemberModal,
        })
        this.validateField('email', email)
      })
      .catch(err => {
        toast.error('There was a error checking this email.')
      })
  }
  handleRemovePlanFromCart = index => {
    const cart = this.state.subscriptionCart
    let isOnlyFreePlanSelected = this.state.isOnlyFreePlanSelected
    let isProrated = this.state.isProrated
    if (cart.length >= 1) {
      const cartCopy = JSON.parse(JSON.stringify(cart))
      const plan = cartCopy[index].plan
      if (plan.conference_hours_default) {
        let hours =
          parseFloat(this.props.conferenceHoursAllowance) -
          parseFloat(plan.conference_hours_default)
        if (hours < 0) {
          hours = 0
        }
        this.props.handleConferenceHoursChange(hours)
      }
      cartCopy.splice(index, 1)
      if (cartCopy.length === 1 && cartCopy[0].plan.cost === 0) {
        isOnlyFreePlanSelected = true
      } else {
        isOnlyFreePlanSelected = false
      }
      if (cartCopy.length === 1) {
        isProrated = true
      }
      const cartWithUpdatedPrices = Constants.calculatePlanTotalsWithQuantities(
        cartCopy
      )
      this.setState(
        {
          subscriptionCart: cartWithUpdatedPrices,
          isProrated,
          isOnlyFreePlanSelected,
        },
        () => {
          this.validateField()
        }
      ) // for form validation of the subscription cart
    }
  }
  createNewPlan = info => {
    this.props.handleIsCreatingNewPlan(true)
    const planObj = {
      campus_id: this.props.activeCampus.id,
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      name: info.name,
      cost: info.price,
      description: info.description,
      frequency: info.frequency,
    }
    if (planObj.cost === 'null' || !planObj.cost || planObj.cost === ' ') {
      planObj.cost = 0
    }
    this.props.plan_actions
      .createPlanOnFly(planObj)
      .then(response => {
        this.handleAutoSuggestChange(response.plan)
        this.props.handleIsCreatingNewPlan(false)
      })
      .catch(err => {
        toast.error('Error creating plan!')
        console.log(err)
      })
  }

  handleTypeChange = event =>
    this.setState({
      billingType: event.target.value,
    })
  handleTeamAdminInfoChange = event => {
    event.preventDefault()
    const stateToUpdate = event.target.name
    this.setState({
      [stateToUpdate]: event.target.value,
    })
  }

  /* autosuggestion functions */
  handleAutoSuggestChange = selectedPlan => {
    if (
      this.state.subscriptionCart.length <= 0 ||
      selectedPlan.frequency === this.state.subscriptionCart[0].plan.frequency
    ) {
      if (
        !Constants.planExistsInCart(selectedPlan, this.state.subscriptionCart)
      ) {
        const showAdd = this.state.subscriptionCart.length >= 1 // shows the add another plan button when the dropdown of the first plan is selected.
        let cartCopy = JSON.parse(JSON.stringify(this.state.subscriptionCart))
        const obj = Constants.initializeEmptyCartObject(false)
        cartCopy.push(obj)
        cartCopy[cartCopy.length - 1].plan = selectedPlan
        cartCopy[cartCopy.length - 1].uiState.isPlanSelected = true
        cartCopy = Constants.calculatePlanTotalsWithQuantities(cartCopy)

        const state = {
          subscriptionCart: cartCopy,
          showAddPlanButton: showAdd,
          showAddPlanDropdown: false,
          showNewPlanModal: false,
          isOnlyFreePlanSelected: false,
        }
        if (selectedPlan.conference_hours_default) {
          const hours =
            parseFloat(this.props.conferenceHoursAllowance) +
            parseFloat(selectedPlan.conference_hours_default)
          this.props.handleConferenceHoursChange(hours)
        }
        if (
          cartCopy.length === 1 &&
          cartCopy[0].plan &&
          cartCopy[0].plan.cost === 0
        ) {
          // hide all the subscription content and whatnot until there's paid plans added.
          state.isOnlyFreePlanSelected = true
        }
        if (selectedPlan.frequency === 'year') {
          state.isProrated = false
        }
        this.setState(state, () => {
          this.props.toggleNewPlanModal(false)
          this.validateField() // for form validation of the subscription cart
        })
      } else {
        toast.error(
          'Sorry! You should increment the quantity instead of adding duplicate plans.'
        )
      }
    } else {
      toast.error(
        'Sorry! You can only stack plans that have the same bill frequency.'
      )
    }
  }

  updateSubscriptionCart = cartCopy => {
    const newConferenceAllowsTotal = Constants.getConferenceRoomHours(cartCopy)
    if (newConferenceAllowsTotal >= 0) {
      this.props.handleConferenceHoursChange(newConferenceAllowsTotal)
    }
    this.setState({
      subscriptionCart: cartCopy,
    })
  }

  shouldRenderSuggestions = value => {
    return value.length >= 1
  }

  handleAddPlanClicked = event => {
    event.preventDefault()
    this.setState({
      showAddPlanDropdown: !this.state.showAddPlanDropdown,
    })
    return false
  }

  handleFrequencyDropdownChange = event => {
    const frequency = event.target.value
    this.setState({
      frequency: frequency,
    })
    this.props.handleFrequencyDropdownChange(frequency)
  }

  handleInputChange = event => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'cost') {
      if (value < 0) {
        value = 0
        event.target.value = 0
      }
    } else if (name === 'email' && Constants.validateEmail(value)) {
      this.checkEmailExists(value)
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value)
      }
    )
    this.props.handleInputChange(event)
  }
  handleDoorsGranted = doorAccess => {
    this.setState({
      doorAccess,
    })
  }
  handleAccessToggle = () => {
    this.setState({
      doorAccess: !this.state.showAccess ? [] : this.state.doorAccess,
      showAccess: !this.state.showAccess,
    })
  }
  handleSendWelcomeEmailToggle = value => {
    this.setState({
      sendWelcomeEmail: !this.state.sendWelcomeEmail,
    })
  }
  handleBlur = field => evt => {
    this.setState({
      inlineErrors: this.validateInline(),
      touched: { ...this.state.touched, [field]: true },
    })
  }

  validateSchema = (val, name) => {
    let message = null
    const schema = Yup.object().shape({
      website: Yup.string().url(
        'Invalid website format! It must be http://www.yourlink.com'
      ),
    })
    try {
      schema.validateSync({ [name]: val })
    } catch (error) {
      message = error.message
    }
    return message
  }

  validateField = (fieldName, value) => {
    const inlineErrors = this.validateInline()
    const isEnabled = !Object.keys(inlineErrors).some(
      index => inlineErrors[index]
    )

    const fieldValidationErrors = this.state.formErrors
    let emailValid = this.state.emailValid

    switch (fieldName) {
      case 'website':
        fieldValidationErrors.website = this.validateSchema(value, 'website')
        break
      case 'email':
        const validRegexEmail = Constants.validateEmail(value)
        if (this.state.profileExistsInCommunity) {
          emailValid = false
          const emailExistsObj = this.state.emailExistsObj
          if (emailExistsObj) {
            if (emailExistsObj.profile_exists_in_campus) {
              fieldValidationErrors.email =
                'A member with this email already exists in this campus. '
            } else if (emailExistsObj.profile_exists_in_community) {
              fieldValidationErrors.email = `A member with this email has already been added to ${
                emailExistsObj.campus_name
                  ? emailExistsObj.campus_name
                  : 'another campus'
              } in your community. `
            } else {
              fieldValidationErrors.email =
                'A member with this email already exists in your community. '
            }

            if (emailExistsObj.active_user === false) {
              fieldValidationErrors.email +=
                'This member is currently inactive. Please find them in the inactive list in the directory and reactivate them to add to this campus. '
            }
          }
        } else if (
          !this.state.emailIsUnique &&
          !this.state.profileExistsInCommunity
        ) {
          // User is in coworks but not this community... proceed
          emailValid = validRegexEmail
        } else if (this.state.emailIsUnique) {
          fieldValidationErrors.email = ''
          emailValid = validRegexEmail
        }
        if (!validRegexEmail) {
          fieldValidationErrors.email = 'Email is in an invalid format.'
        }
        break
      default:
        break
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid,
      inlineErrors,
      isEnabled,
    })
  }
  validateInline = () => {
    const { teamName, email, firstName, website, lastName } = this.state
    // true indicates error
    return {
      teamName: !teamName || (teamName && teamName.length === 0),
      firstName: !firstName || (firstName && firstName.length === 0),
      lastName: !lastName || (lastName && lastName.length === 0),
      email: !(email && email.length > 0) || !Constants.validateEmail(email),
      website:
        website &&
        website.length > 0 &&
        Boolean(this.validateSchema(website, 'website')),
      plansError: this.state.subscriptionCart.length === 0,
    }
  }
  /* Subscription functions */

  handleStartDateTimeChanged = mom => {
    this.setState({
      startDate: mom,
    })
  }

  /*End Subscription Functions*/
  handleSubmit = (stripe, elements) => {
    if (
      this.state.subscriptionCart.length > 0 &&
      !this.state.isOnlyFreePlanSelected &&
      this.props.isBillingEnabled &&
      (this.state.billingType === CHARGE_AUTOMATICALLY ||
        (this.state.billingType === SEND_INVOICE &&
          this.props.captureCard === 'true'))
    ) {
      const cardElement = elements.getElement(CardElement)
      stripe
        .createToken(cardElement)
        .then(({ token }) => {
          this.setState(
            {
              cardToken: token.id,
            },
            () => {
              this.props.createTeam(this.state)
            }
          )
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      this.props.createTeam(this.state)
    }
  }
  handleRemoveDiscount = () => {
    this.props.saveDiscount(null)
    const cartCopy = JSON.parse(JSON.stringify(this.state.subscriptionCart))
    const discountInDollars = 0

    this.setState({
      selectedPlanAfterDiscountTotal: 0,
      subscriptionCart: cartCopy,
      memberDateFocused: false,
    })
  }
  /*
    Bundle up the discount object
  */
  handleSaveDiscountClicked = couponObject => {
    // todo ensure the format of the discount object is the same as what's returned.
    this.props.saveDiscount(couponObject)
    this.props.toggleAddDiscountModal()
    const cartCopy = JSON.parse(JSON.stringify(this.state.subscriptionCart))
    this.setState({
      duration_in_months: 0,
      amount_off: 0,
      cartObjectToDiscount: null,
    })
  }

  handleUserDateFocused = ({ focused }) => {
    this.setState({ memberDateFocused: focused })
  }
  handleDateFocused = ({ focused }) => {
    this.setState({ dateFocused: focused })
  }
  handleUserAccessDateChanged = accessDate => {
    this.setState({ accessDate })
  }
  onSuggestionChanged = (event, { newValue, method }) => {
    this.setState({
      selectedPlan: newValue,
    })
  }
  shouldShowConferenceHoursInput = () => this.state.subscriptionCart.length > 0

  renderInputComponent = inputProps => (
    <div>
      <div className="input-group">
        <span className="input-group-addon">
          <i className="fa fa-search" />
        </span>
        <input type="text" className="form-control" {...inputProps} />
      </div>
    </div>
  )
  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        <Button
          className="p-2 my-0 btn btn-primary btn-block"
          onClick={this.props.toggleNewPlanModal}
        >
          Create New Plan
          <i className="fa fa-plus fa-sm pull-right" aria-hidden="true" />
        </Button>
      </div>
    )
  }
  renderSuggestion = plan => {
    const symbol = Constants.getCurrencySymbolFromCommunity(
      this.props.activeCommunity
    )
    return (
      <div>
        <img
          className="img-responsive center-block img-avatar"
          style={{ width: '50px', height: '50px' }}
          src={plan.plan_photo ? plan.plan_photo.small_file_url : otto}
        />
        <span>
          {' '}
          {plan.name} - {symbol}
          {plan.cost} per {plan.frequency}
        </span>
      </div>
    )
  }
  /* end of autosuggestion functions */

  render() {
    const {
      description,
      errors,
      goBack,
      handleImageUpload,
      isExtraTeamDetailsCollapsed,
      toggleExtraTeamDetails,
      joinedAt,
      userJoinedAt,
      handleJoinedAtChanged,
      handleUserJoinedAtChanged,
      conferenceHoursAllowance,
      handleInputChange,
      discountObject,
      integrations,
      captureCard,
      theme,
    } = this.props
    const { billingType, teamName, chargeNowDisabled, website } = this.state

    const shouldMarkError = field => {
      const hasError = this.state.inlineErrors[field]
      const shouldShow = this.state.touched[field]
      return hasError ? shouldShow : false
    }
    const intendedPaymentSource = getIntendedPaymentSource(
      billingType,
      captureCard
    )

    const planSubTotal = Constants.calculateTeamPlanTotal({
      cart: this.state.subscriptionCart,
      discountTotalDollars: null,
      prefs: null,
      customer: null,
      intendedPaymentSource: intendedPaymentSource,
    })
    const planGrandTotal = Constants.calculateTeamPlanTotal({
      cart: this.state.subscriptionCart,
      discountTotalDollars:
        discountObject && discountObject ? discountObject.amount_off : 0,
      prefs: this.props.activeCommunity.community_preference,
      customer: null, // do we have customer?
      intendedPaymentSource: intendedPaymentSource,
    })

    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <div className="animated fadeIn">
            <div id="new-team-form" className="card">
              <div className="card-header">
                <Row>
                  <div className="col-6">
                    <h2>
                      {this.props.navFromMember ? 'New Member' : 'New Team'}
                    </h2>
                  </div>
                  {(!process.env.NODE_ENV ||
                    process.env.NODE_ENV === 'development' ||
                    this.props.user.type === 'SuperAdmin') && (
                    <div className="col-6">
                      <TextButton onClick={this.fakeIt}>Populate</TextButton>
                    </div>
                  )}
                </Row>
              </div>
              <div className="card-block ml-2">
                <Row>
                  <div
                    className={
                      this.props.navFromMember
                        ? 'col-12 d-flex flex-column'
                        : 'col-12 d-flex flex-column-reverse'
                    }
                  >
                    <div
                      className={
                        this.props.navFromMember
                          ? 'col-12 flex-first'
                          : 'col-12 flex-last'
                      }
                    >
                      <div className="row">
                        <div className="col-12">
                          {!this.props.navFromMember && (
                            <React.Fragment>
                              <hr />
                              <h5>Team Admin</h5>
                              <p className="text-muted">
                                This new member will have full admin access to
                                their team profile{' '}
                              </p>
                            </React.Fragment>
                          )}
                          <InviteNewMember
                            history={this.props.history}
                            email={this.state.email}
                            phone={this.state.phone}
                            firstName={this.state.firstName}
                            title={this.state.title}
                            lastName={this.state.lastName}
                            handleBlur={this.handleBlur}
                            shouldMarkError={shouldMarkError}
                            formErrors={this.state.formErrors}
                            emailValid={this.state.emailValid}
                            showAccess={this.state.showAccess}
                            doorAccess={this.state.doorAccess}
                            accessDate={this.state.accessDate}
                            handleUserAccessDateChanged={
                              this.handleUserAccessDateChanged
                            }
                            doorAccessOptions={integrations.doorAccess}
                            handleDoorsGrantedChanged={this.handleDoorsGranted}
                            hasDoorProvider={checkDoorProvider(
                              integrations,
                              this.props.activeCampus
                            )}
                            handleAccessToggle={this.handleAccessToggle}
                            handleWelcomeEmailToggle={
                              this.handleSendWelcomeEmailToggle
                            }
                            sendWelcomeEmail={this.state.sendWelcomeEmail}
                            joinedAt={userJoinedAt}
                            handleJoinedAtChanged={handleUserJoinedAtChanged}
                            handleDateFocused={this.handleUserDateFocused}
                            dateFocused={this.state.memberDateFocused}
                            handleInputChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.props.navFromMember
                          ? 'col-12 flex-last'
                          : 'col-12 flex-first'
                      }
                    >
                      <div className="row">
                        <div className="col-12">
                          {this.props.navFromMember && (
                            <React.Fragment>
                              <h3>Team</h3>
                              <p className="text-muted">
                                All members are required to be on a team.{' '}
                              </p>
                            </React.Fragment>
                          )}

                          <div className="row">
                            <div className="col-md-3 col-12">
                              <LabelStyled>Team Logo</LabelStyled>
                              <div>
                                <ImageUploadComponent
                                  onUpload={handleImageUpload}
                                  uploadText="Upload Logo"
                                  // ref="imageUploadComponent"
                                />
                              </div>
                              <p className="text-center badge badge-default d-block">
                                <small>
                                  For best results, upload a square image
                                </small>
                              </p>
                            </div>
                            <div className="col-md-9 col-12">
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <LabelStyled>Team Name</LabelStyled>
                                  <Input
                                    type="text"
                                    name="teamName"
                                    onBlur={this.handleBlur('teamName')}
                                    className={
                                      shouldMarkError('teamName')
                                        ? ' w-100 error'
                                        : 'w-100'
                                    }
                                    placeholder="Team Name"
                                    onChange={this.handleInputChange}
                                    value={teamName}
                                  />
                                </div>
                              </div>
                              <div className="row my-3 ">
                                <div className="col-12">
                                  <Button
                                    className="btn btn-link text-primary"
                                    onClick={toggleExtraTeamDetails}
                                    style={{ marginBottom: '1rem' }}
                                  >
                                    {!isExtraTeamDetailsCollapsed
                                      ? '+ Show more'
                                      : ' - Hide Details'}
                                  </Button>
                                  <Collapse
                                    isOpen={isExtraTeamDetailsCollapsed}
                                  >
                                    <div className="row">
                                      <div className="col-md-6 col-12">
                                        <LabelStyled htmlFor="teamURL">
                                          Team Website
                                        </LabelStyled>
                                        <Input
                                          id="website"
                                          type="text"
                                          name="website"
                                          onBlur={() =>
                                            this.handleBlur('website')
                                          }
                                          className={
                                            shouldMarkError('website')
                                              ? ' w-100 error'
                                              : 'w-100'
                                          }
                                          onChange={this.handleInputChange}
                                          value={website}
                                        />
                                        {this.state.formErrors.website ? (
                                          <p className="help-block text-danger">
                                            {this.state.formErrors.website}
                                          </p>
                                        ) : null}
                                      </div>
                                      <div className="col-md-6">
                                        <LabelStyled>
                                          Team Join Date
                                        </LabelStyled>{' '}
                                        <br />
                                        <SingleDatePicker
                                          id="join_date_new_company" // PropTypes.string.isRequired,
                                          name="joinedAt"
                                          date={joinedAt} // momentPropTypes.momentObj or null
                                          onDateChange={handleJoinedAtChanged} // PropTypes.func.isRequired
                                          focused={this.state.dateFocused} // PropTypes.bool
                                          onFocusChange={this.handleDateFocused} // PropTypes.func.isRequired
                                          handleDateFocused={
                                            this.handleDateFocused
                                          }
                                          showClearDate
                                          isOutsideRange={() => false}
                                          placeholder={'Join Date'}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6 col-12">
                                        <LabelStyled>Description</LabelStyled>
                                        <textarea
                                          id="description"
                                          name="description"
                                          className="w-100 form-control "
                                          placeholder={`Tell your space about ${
                                            name ? name : 'your new team'
                                          }`}
                                          onChange={this.handleInputChange}
                                          value={description}
                                        />
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <AddPlansFlow
                          defaultCurrency={
                            this.props.activeCommunity.community_preference
                              .currency
                          }
                          subscriptionCart={this.state.subscriptionCart}
                          handleRemoveDiscount={this.handleRemoveDiscount}
                          handleAddPlanClicked={this.handleAddPlanClicked}
                          handleAutoSuggestChange={this.handleAutoSuggestChange}
                          updateSubscriptionCart={this.updateSubscriptionCart}
                          removePlanFromCart={this.handleRemovePlanFromCart}
                          toggleNewPlanModal={this.props.toggleNewPlanModal}
                          showAddPlanDropdown={this.state.showAddPlanDropdown}
                          isBillingEnabled={this.props.isBillingEnabled}
                          isOnlyFreePlanSelected={
                            this.state.isOnlyFreePlanSelected
                          }
                          isDiscountApplied={Boolean(this.props.discountObject)}
                          toggleAddDiscountModal={
                            this.props.toggleAddDiscountModal
                          }
                          discountObject={this.props.discountObject}
                        />
                      </Row>
                      <Row>
                        <Col>
                          {this.shouldShowConferenceHoursInput() && (
                            <React.Fragment>
                              <Row className="mt-3">
                                <Col>
                                  <LabelStyled>
                                    Include conference room booking hours?
                                  </LabelStyled>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="2">
                                  <Input
                                    type="number"
                                    name="conferenceHoursAllowance"
                                    className="w-100"
                                    placeholder="hours"
                                    onChange={handleInputChange}
                                    value={conferenceHoursAllowance}
                                  />
                                </Col>
                                <Col className="d-flex align-items-center">
                                  hours
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="12" className="text-muted my-1">
                                  {conferenceHoursAllowance} hours will be added
                                  to{' '}
                                  {teamName ? `${teamName}'s` : "this team's"}{' '}
                                  conference hours allowance.
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {!this.state.isOnlyFreePlanSelected &&
                            this.props.isBillingEnabled && (
                              <Subscription
                                history={this.props.history}
                                activeCommunity={this.props.activeCommunity}
                                subscriptionCart={this.state.subscriptionCart}
                                discountObject={this.props.discountObject}
                                startDate={this.props.startDate}
                                monthlyBillDate={this.props.monthlyBillDate}
                                planGrandTotal={planGrandTotal}
                                planSubTotal={planSubTotal}
                                handleMonthlyBillDateChanged={
                                  this.props.handleMonthlyBillDateChanged
                                }
                                handleProrateToggle={
                                  this.props.handleProrateToggle
                                }
                                isProrated={this.props.isProrated}
                                handleProrateLength={
                                  this.props.handleProrateLength
                                }
                                getProrateLength={this.props.getProrateLength}
                                getMonthlyBillDate={
                                  this.props.getMonthlyBillDate
                                }
                                handleInputChange={handleInputChange}
                                frequency={
                                  this.state.subscriptionCart.length > 0
                                    ? this.state.subscriptionCart[0].plan
                                        .frequency
                                    : ''
                                }
                                handleStartDateChanged={
                                  this.props.handleStartDateChanged
                                }
                                intendedPaymentSource={
                                  billingType === CHARGE_AUTOMATICALLY ||
                                  (billingType === SEND_INVOICE &&
                                    captureCard === 'true')
                                    ? 'card'
                                    : null
                                }
                              />
                            )}
                        </Col>
                      </Row>
                      <hr />
                      {this.props.isBillingEnabled &&
                      this.state.subscriptionCart.length > 0 &&
                      !this.state.isOnlyFreePlanSelected ? (
                        <React.Fragment>
                          {chargeNowDisabled ? (
                            <div className="row my-3">
                              {/* Charge now enabled */}
                              <div className={'col-md-6 col-12'}>
                                <div className="col-12 my-3">
                                  <h3>Payment</h3>
                                  <div className="form-group">
                                    <p>
                                      Would you like to capture a credit card?
                                    </p>
                                    <label>
                                      <input
                                        className="mr-1"
                                        name="captureCard"
                                        type="radio"
                                        value="true"
                                        checked={captureCard === 'true'}
                                        onChange={this.handleInputChange}
                                      />
                                      Yes.
                                    </label>
                                    <br />
                                    <label>
                                      <input
                                        className="mr-1"
                                        name="captureCard"
                                        type="radio"
                                        value="false"
                                        checked={captureCard === 'false'}
                                        onChange={this.handleInputChange}
                                      />
                                      No, I'll add it later.
                                    </label>
                                  </div>
                                </div>
                                {captureCard === 'true' && (
                                  <div className="col-12 my-3">
                                    <CreditCardForm
                                      stripe={stripe}
                                      elements={elements}
                                    />
                                  </div>
                                )}
                                <span className="text-muted">
                                  Note: Invoices will be sent via email, but
                                  this credit card will be added as the default
                                  payment source.
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="row my-3">
                              {/* Charge now enabled */}
                              <div className={'col-md-6 col-12'}>
                                <div className="col-12 my-3">
                                  <h3>Payment</h3>
                                  <div className="form-group">
                                    <p>
                                      Would you like to charge for these plans
                                      automatically?
                                    </p>
                                    <label>
                                      <input
                                        className="mr-1"
                                        name="billingType"
                                        type="radio"
                                        value="charge_automatically"
                                        checked={
                                          billingType === CHARGE_AUTOMATICALLY
                                        }
                                        onChange={this.handleTypeChange}
                                      />
                                      Yes, charge automatically.
                                    </label>
                                    <br />
                                    <label>
                                      <input
                                        className="mr-1"
                                        name="billingType"
                                        type="radio"
                                        value="send_invoice"
                                        checked={billingType === SEND_INVOICE}
                                        onChange={this.handleTypeChange}
                                      />
                                      No, send an invoice to be paid manually by
                                      email.
                                    </label>
                                  </div>
                                </div>
                                {billingType === CHARGE_AUTOMATICALLY ? (
                                  <React.Fragment>
                                    <div className="col-12 my-3">
                                      <CreditCardForm
                                        stripe={stripe}
                                        elements={elements}
                                      />
                                    </div>
                                    <div className="col-12 my-3">
                                      <span>
                                        Note: Bank accounts are not immediately
                                        chargeable. To use a bank account,{' '}
                                        <TextButton
                                          className="m-0 p-0"
                                          onClick={() => {
                                            this.setState({
                                              billingType: SEND_INVOICE,
                                            })
                                          }}
                                        >
                                          send an invoice by email
                                        </TextButton>{' '}
                                        and add the bank account in the team
                                        profile after creating membership.
                                      </span>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <span className="text-muted">
                                    Note: Invoices will be sent via email.
                                  </span>
                                )}
                                {!this.props.isBillingEnabled && (
                                  <span>
                                    Note: Billing is not enabled so customer
                                    will not be charged this amount. This is
                                    just for records.
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ) : null}
                      <Row className="my-1">{errors}</Row>
                    </div>
                  </div>
                  <div className="my-1 col-12 col-12 d-flex justify-content-between">
                    <ButtonStyled
                      color="white"
                      className="bml-2"
                      onClick={goBack}
                    >
                      Cancel
                    </ButtonStyled>
                    <ButtonStyled
                      className="mr-2"
                      disabled={!this.state.isEnabled || this.props.isFetching}
                      onClick={() => this.handleSubmit(stripe, elements)}
                    >
                      {this.props.isFetching && (
                        <FontAwesomeIcon icon={'circle-notch'} spin />
                      )}{' '}
                      Save Team
                    </ButtonStyled>
                  </div>
                </Row>
              </div>
            </div>
            <Row>
              <Modal
                isOpen={this.props.showNewPlanModal}
                toggle={this.props.toggleNewPlanModal}
                className="modal-primary fade in"
              >
                <ModalHeader toggle={this.props.toggleNewPlanModal}>
                  New Plan
                </ModalHeader>
                <ModalBody>
                  <_PlanFormModal
                    initialValues={{
                      name: this.props.planName,
                      price: 0,
                      frequency: this.props.planFrequency,
                      description: '',
                    }}
                    schema={Yup.object().shape({
                      name: Yup.string().required('name is required'),
                      price: Yup.string().required('price is required'),
                      description: Yup.string().required(
                        'description is required'
                      ),
                      frequency: Yup.string(),
                    })}
                    onSubmit={this.createNewPlan}
                    toggle={this.props.toggleNewPlanModal}
                  />
                </ModalBody>
              </Modal>
              {this.props.showAddDiscountModal && (
                <DiscountModal
                  activeCommunity={this.props.activeCommunity}
                  // subForDiscountModal={this.state.subForDiscountModal}
                  subscriptionCart={this.state.subscriptionCart}
                  billingFrequency={
                    this.state.subscriptionCart.length > 0
                      ? this.state.subscriptionCart[0].plan.frequency
                      : ''
                  }
                  currencySymbol={Constants.getCurrencySymbolFromCommunity(
                    this.props.activeCommunity
                  )}
                  discountObject={this.props.discountObject} // ??? does the parent have this?
                  handleSaveDiscountClicked={couponObject =>
                    this.handleSaveDiscountClicked(couponObject)
                  }
                  listLength={this.state.subscriptionCart.length}
                  showAddDiscountModal={this.props.showAddDiscountModal}
                  team={null}
                  teamName={this.state.teamName}
                  toggleAddDiscountModal={this.props.toggleAddDiscountModal}
                  isProrated={this.state.isProrated}
                />
              )}
              {this.state.emailExistsObj &&
              this.state.emailExistsObj.profile_exists_in_community ? (
                <React.Fragment>
                  <GenericModal
                    onClose={this.handleCloseExistingMemberModal}
                    isOpen={this.state.showExistingMemberModal}
                    header={'Member Exists'}
                    toggle={this.handleCloseExistingMemberModal}
                    isLoading={false}
                    onConfirm={this.handleViewExistingMember}
                    confirmLabel={'View Profile'}
                    color={theme.colors.primary}
                    textColor={theme.fonts.textColors.primary}
                    body={
                      <Row>
                        <Col className="mb-2" xs={'12'}>
                          {this.getExistingMemberText()}
                        </Col>
                        <Col xs={'12'}>
                          <AvatarNamePlate
                            object={this.state.emailExistsObj.user}
                            onClick={this.handleViewExistingMember}
                            size={1}
                            objectType="user"
                          />
                        </Col>
                      </Row>
                    }
                  />
                </React.Fragment>
              ) : null}
            </Row>
          </div>
        )}
      </ElementsConsumer>
    )
  }
}
/* eslint-enable max-len */

NewTeamForm.displayName = 'NewTeamForm'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    user: state.user,
    integrations: state.integrations,
    isFetching: state.ui.isFetching,
    planOnFly: state.ui.planOnFly,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    user_actions: bindActionCreators(userActions, dispatch),
    plan_actions: bindActionCreators(planActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(NewTeamForm))
