/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import Radio from '@global/Base/Radio/Radio'
import classnames from 'classnames'
import styled from 'styled-components'

// const arrayOfRadios = [
//   {label: 'Tab 1'},
//   {label: 'Tab 2'},
//   {label: 'Tab 3'},
// ]
export default function RadioGroup({
  arrayOfRadios,
  handleSelectRadio,
  activeRadioName,
  className,
}) {
  // const [checked, setChecked] = useState('unlimited')
  // const handleSelectChange = value => {
  //   setChecked(value)
  // }
  if (!arrayOfRadios || !(arrayOfRadios instanceof Array)) {
    return null
  }

  return (
    <Wrapper className={className}>
      {arrayOfRadios.map((radio, index) => (
        <React.Fragment>
          <Radio
            key={index}
            label={radio.label}
            value={radio.label}
            selected={activeRadioName}
            className={classnames({
              active: activeRadioName === radio.label,
            })}
            onChange={() => {
              handleSelectRadio(radio.label)
            }}
          />
          {radio.children && activeRadioName === radio.label
            ? radio.children
            : null}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0px 16px 24px 16px;
  box-sizing: border-box;
`

RadioGroup.displayName = 'RadioGroup'
