import React from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import Lottie from 'react-lottie'
import check from './json/9553-check-animation.json' // https://lottiefiles.com/21052-checking
import error from './json/14331-error.json' // https://lottiefiles.com/21052-checking

// Event listener options =>
// https://github.com/airbnb/lottie-web#events
const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: check,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export default function BaseLottie({ type, eventListeners, width, height }) {
  let animationData = defaultOptions.animationData
  let autoplay = defaultOptions.autoplay
  let loop = defaultOptions.loop

  switch (type) {
    case 'checkin':
      animationData = check
      loop = false
      autoplay = true
      break
    case 'error':
      animationData = error
      loop = false
      autoplay = true
      break
    default:
      break
  }
  defaultOptions.animationData = animationData
  defaultOptions.autoplay = autoplay
  defaultOptions.loop = loop

  return (
    <Lottie
      options={defaultOptions}
      width={width}
      height={height}
      eventListeners={eventListeners}
    />
  )
}
BaseLottie.propTypes = {
  type: PropTypes.string,
  eventListeners: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
}
BaseLottie.defaultProps = {
  type: 'checkin',
  eventListeners: [],
}

BaseLottie.displayName = 'BaseLottie'
