import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import Spinner from '../Spinner'
import { LabelStyled } from '@global/Form/FormComponents'
import {
  handleCustomFieldsChange,
  handleAddCustomField,
  renderCustomField,
  handleRemoveCustomField,
} from './CustomFieldFunctions'

class CustomFieldSection extends Component {
  static propTypes = {
    customFieldsObject: PropTypes.array.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleInputValuesChange: PropTypes.func.isRequired,
    customFieldMax: PropTypes.number.isRequired,
    customFieldsObjectKey: PropTypes.string,
    isEditing: PropTypes.bool, // optional
  }
  static defaultProps = {
    customFieldMax: 20,
    customFieldsObjectKey: 'customFieldsObject',
    isEditing: true,
  }
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  handleKeyPress = event => {
    const {
      setFieldValue,
      handleInputValuesChange,
      customFieldsObject,
      customFieldsObjectKey,
    } = this.props
    if (event.key === 'Enter') {
      const newCustomFields = handleAddCustomField(customFieldsObject)
      if (setFieldValue) {
        setFieldValue(customFieldsObjectKey, newCustomFields)
      } else if (handleInputValuesChange) {
        handleInputValuesChange(customFieldsObjectKey, newCustomFields)
      }
    }
  }
  handleAddCustomField = () => {
    const {
      setFieldValue,
      handleInputValuesChange,
      customFieldsObject,
      customFieldsObjectKey,
    } = this.props
    const newCustomFields = handleAddCustomField(customFieldsObject)
    if (setFieldValue) {
      setFieldValue(customFieldsObjectKey, newCustomFields)
    } else if (handleInputValuesChange) {
      handleInputValuesChange(customFieldsObjectKey, newCustomFields)
    }
  }

  render() {
    const {
      setFieldValue,
      customFieldsObject,
      handleInputValuesChange,
      customFieldMax,
      customFieldsObjectKey,
      isEditing,
    } = this.props
    if (!customFieldsObject || !customFieldsObject.fields) {
      return null
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <React.Fragment>
              <div className="row my-3">
                <div className="col-6">
                  <LabelStyled htmlFor="customFields" isEditing={isEditing}>
                    Custom Fields{' '}
                    {isEditing && (
                      <span className="text-muted">
                        {customFieldsObject.fields.length}/{customFieldMax}
                      </span>
                    )}
                  </LabelStyled>
                </div>
              </div>
              {renderCustomField({
                customFieldsObject,
                handleCustomFieldsChange,
                setFieldValue: setFieldValue
                  ? setFieldValue
                  : handleInputValuesChange,
                handleAddCustomField: this.handleAddCustomField,
                isEditing: isEditing,
                handleKeyPress: this.handleKeyPress,
              })}
              {isEditing && (
                <TextButton
                  color="primary"
                  disabled={customFieldsObject.fields.length === customFieldMax}
                  onClick={() => {
                    const newCustomFields = handleAddCustomField(
                      customFieldsObject
                    )
                    if (setFieldValue) {
                      setFieldValue(customFieldsObjectKey, newCustomFields)
                    } else if (handleInputValuesChange) {
                      handleInputValuesChange(
                        customFieldsObjectKey,
                        newCustomFields
                      )
                    }
                  }}
                >
                  Add custom field
                </TextButton>
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
    )
  }
}
CustomFieldSection.displayName = 'CustomFieldSection'

export default CustomFieldSection
