import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as uiActions from '../../reduxActions/uiActions'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import * as reportActions from '../../reduxActions/reportActions'
import moment from 'moment'
const momentTZ = require('moment-timezone')
import { toast } from 'react-toastify'
import { DateRangePicker } from 'react-dates'
import Spinner from '@global/Spinner'
import { memberRole, canViewEditBilling } from '../../config/roles'
import { LabelStyled } from '@global/Form/FormComponents'
import { IntercomAPI } from 'react-intercom'
import { captureException } from '@global/ErrorFactory'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  convertStringDateToMomentDate,
  DRP_today,
  DRP_currentMonth,
  DRP_previousSevenDays,
  DRP_lastMonth,
  DRP_custom,
} from '@global/Constants/DateConstants'
import * as Constants from '@global/Constants'
import Checkbox from '@bit/coworks.base-components.checkbox'
import styled from 'styled-components'
import QuickSearch from '@global/Search/QuickSearch'
import RadioGroup from '@global/LocalRadioGroup'
import {
  resolveFetchingStatus,
  FETCHING_REPORTS,
} from '@global/Constants/FetchingConstants'

const REPORT_Members = 'Members'
const REPORT_Teams = 'Teams'
const REPORT_Leads = 'Leads'
const REPORT_Prospects = 'Prospects'
const REPORT_VisitorCheckIns = 'Visitor Check-ins'
const REPORT_MemberCheckIns = 'Member Check-ins'
const REPORT_PassportCheckIns = 'Passport Check-ins'
const REPORT_Rooms = 'Rooms'
const REPORT_Bookings = 'Bookings'
const REPORT_BookingsUsage = 'Bookings Usage'
const REPORT_Plans = 'Plans'
const REPORT_ActiveMemberships = 'Active Memberships'
const REPORT_Billing = 'Billing'

// team filter options
const FILTER_all = 'All'
const FILTER_by_team = 'By Team'
function Reports({
  history,
  location,
  match,
  report_actions,
  activeCampus,
  activeCommunity,
  user,
  isFetching,
  isFetchingReport,
}) {
  const now = moment()
  const role = user.type

  const equipmentName =
    activeCommunity.community_preference.equipment_resource_name
  const equipmentEnabled =
    activeCommunity.community_preference.equipment_enabled
  const networkId = Constants.checkForNetwork(user)
  const availableReports = [
    {
      name: REPORT_Members,
      icon: 'user',
      description:
        'All of the members in your community, their teams, and their contact info.',
      useModal: true,
      showModalDatePickers: false,
      showModalIncludeInactiveCheckbox: true,
      showTeamPicker: true,
    },
    {
      name: REPORT_Teams,
      icon: 'users',
      description: 'All of the teams in your community and their admins.',
    },
    {
      name: REPORT_Leads,
      icon: 'user-plus',
      description: 'All of the leads in your community.',
    },
    {
      name: REPORT_Prospects,
      icon: 'user-plus',
      description: 'All of the prospects in your community.',
    },
    {
      name: REPORT_VisitorCheckIns,
      icon: 'map-marker-check',
      description:
        'All of the guest check-ins from the front-desk tablet for your community.',
      useModal: true,
      showModalDatePickers: true,
    },
    {
      name: REPORT_MemberCheckIns,
      icon: 'map-marker-check',
      description: 'All of the member check-ins from your community.',
      useModal: true,
      showModalDatePickers: true,
    },
    {
      name: REPORT_PassportCheckIns,
      icon: 'map-marker-check',
      description: 'All of the member check-ins from your passport.',
      useModal: true,
      showModalDatePickers: true,
      visible: Boolean(networkId),
    },
    {
      name: REPORT_Rooms,
      icon: 'door-open',
      description: equipmentEnabled
        ? `All of the rooms, spaces, and ${equipmentName.toLowerCase()} in your community.`
        : 'All of the rooms and spaces in your community.',
    },
    {
      name: REPORT_Bookings,
      icon: 'calendar-alt',
      description: 'All of the bookings in your current campus by member.',
      useModal: true,
      showModalDatePickers: true,
      showTeamPicker: true,
    },
    {
      name: REPORT_BookingsUsage,
      icon: 'calendar-alt',
      description:
        'This report will give you all of the bookings totals for each team in your community.',
      useModal: true,
      showModalDatePickers: true,
      showTeamPicker: true,
    },
    {
      name: REPORT_Plans,
      icon: 'credit-card',
      description:
        'All of the plans in your community and the number of teams assigned to them. ',
    },
    {
      name: REPORT_ActiveMemberships,
      icon: 'dollar-sign',
      description:
        'All of the active membership subscriptions by team in your community (MRR) with team payment sources (if billing enabled).',
      visible: Boolean(
        canViewEditBilling.includes(role) && activeCommunity.stripe_account_id
      ),
    },
    {
      name: REPORT_Billing,
      icon: 'dollar-sign',
      description:
        'This report will give you all of the payments in your community.',
      useModal: true,
      showModalDatePickers: true,
      visible: Boolean(
        canViewEditBilling.includes(role) && activeCommunity.stripe_account_id
      ),
    },
  ]
  let routedReport = null
  if (match.params.reportName) {
    // go through reports and find which type
    // not actually rendering these is done in the #renderReports method (for whatever reason)
    const filteredReports = availableReports.filter(
      reportObj =>
        reportObj.name.replace('%20', '').toLowerCase() ===
          match.params.reportName.toLowerCase() && reportObj.useModal
    )
    if (filteredReports.length === 1) {
      routedReport = filteredReports[0]
    }
  }
  const passedTeam =
    location.state && location.state.team ? location.state.team : null
  const defaultOption = passedTeam ? FILTER_by_team : FILTER_all

  const [interestedReport, setInterestedReport] = useState(routedReport)
  const [showRequestReportModal, setShowRequestReportModal] = useState(
    Boolean(routedReport)
  )
  const [reportDate, setReportDate] = useState(now)
  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(now)
  const [focusedInput, setFocusedInput] = useState(null)
  const [dateRangeCheck, setDateRangeCheck] = useState(DRP_currentMonth)
  const [includeInactive, setIncludeInactive] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(passedTeam)
  const [filterOption, setFilterOption] = useState(defaultOption)

  useEffect(() => {
    if (isFetchingReport) {
      report_actions.updateFetchingReportStatus(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // only want to run this once when mounted

  const handleResponse = (report, response, startEnd) => {
    toast.success(
      'Your report is generating and will be emailed to you as soon as it is ready. Please allow 5 minutes before requesting again.'
    )
  }
  const handleError = (report, error) => {
    if (report.downloadResponse) {
      toast.error(
        `Whoops, Looks like there was a problem generating the ${report.name} report.`
      )
    }
    captureException({ error: new Error(error) })
  }
  const generateReport = report => {
    const startEnd = convertStringDateToMomentDate(
      dateRangeCheck,
      startDate,
      endDate
    )
    const params = {
      community_id: activeCommunity.id,
      stripe_account_id: activeCommunity.stripe_account_id,
      timezone_identifier: momentTZ.tz.guess(),
      month: reportDate.month() + 1, // DEPRECATED in favor of start_time, end_time
      year: reportDate.year(), // DEPRECATED in favor of start_time, end_time
      start_time: startEnd.start.toISOString(),
      end_time: startEnd.end.toISOString(),
      team_id:
        filterOption === FILTER_by_team && selectedTeam
          ? selectedTeam.id
          : undefined,
      user_id: user.id,
      email: user.email,
    }
    if (report.showModalIncludeInactiveCheckbox && includeInactive) {
      params.include_archived = true
    }
    switch (report.name) {
      case REPORT_Members:
        report_actions
          .getCommunityMembersReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_Plans:
        report_actions
          .getCommunityPlansReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_Leads:
        report_actions
          .getCommunityLeadsReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_Prospects:
        report_actions
          .getCommunityProspectsReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_Bookings:
        params.campus_id = activeCampus.id
        report_actions
          .getCampusBookingsReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_Teams:
        report_actions
          .getCommunityTeamsReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_Rooms:
        report_actions
          .getCommunityRoomsReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_BookingsUsage:
        report_actions
          .getCommunityBookingTotalsReport(params)
          .then(response => handleResponse(report, response, startEnd))
          .catch(err => handleError(report, err))
        break
      case REPORT_Billing:
        report_actions
          .getCommunityPaymentsReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_ActiveMemberships:
        report_actions
          .getCommunityActiveMembershipsReport(params)
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_VisitorCheckIns:
        report_actions
          .getCheckinsReport(params, 'Visitor')
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_MemberCheckIns:
        report_actions
          .getCheckinsReport(params, 'Member')
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      case REPORT_PassportCheckIns:
        params.network_id = networkId
        report_actions
          .getCheckinsReport(params, 'Network')
          .then(response => handleResponse(report, response))
          .catch(err => handleError(report, err))
        break
      default:
        break
    }

    setInterestedReport(null)
    setShowRequestReportModal(false)
  }

  const toggleSuggestReportModal = () =>
    IntercomAPI('showNewMessage', 'I would like to request a new report!')

  const handleFilterOptionChange = val => {
    setSelectedTeam(null)
    setFilterOption(val)
  }

  const toggleRequestReportModal = interestedReport => {
    const route =
      interestedReport && interestedReport.name
        ? `/${interestedReport.name}`
        : ''
    history.push(`/reports${route}`)
    // setShowRequestReportModal(!showRequestReportModal)
    // setInterestedReport(interestedReport)
  }
  function getTimestamp() {
    // DEPRECATED in favor of getRangeTimestamp
    // methods that use this function aren't hooked up with a to - from date yet
    return moment().format('MMM-DD-YY-HH.mma')
  }

  function getRangeTimestamp(startTime = null, endTime = null) {
    let string = ''
    if (startTime) {
      string += startTime.format('MMM-DD-YY')
    }
    if (endTime) {
      string += ' to '
      string += endTime.format('MMM-DD-YY ')
    }
    return string
  }

  const handleDateRangeCheck = dateRadioOption => {
    setDateRangeCheck(dateRadioOption)
  }
  // eslint-disable-next-line react/no-multi-comp, react/display-name
  function renderReportRow(index, report, useModal) {
    return (
      <div
        key={index}
        className={`${
          availableReports.length - 1 !== index ? 'border-bottom-1' : ''
        } mt-2`}
      >
        <div className="row p-2">
          <div className="col-md-3 col-12">
            <h6>
              <FontAwesomeIcon icon={report.icon} /> {report.name}
            </h6>
          </div>
          <div className="col-12 col-md-6 text-muted">{report.description}</div>
          <div className="col-12 col-md-3 d-flex align-items-center justify-content-end">
            <Button
              disabled={isFetchingReport}
              className="btn btn-sm btn-outline-primary mx-1"
              onClick={() =>
                useModal
                  ? toggleRequestReportModal(report)
                  : generateReport(report)
              }
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    )
  }
  // eslint-disable-next-line react/no-multi-comp, react/display-name
  const renderRequestReportModal = () => {
    // const {
    //   showRequestReportModal,
    //   interestedReport,
    //   dateRangeCheck,
    // } = state
    return (
      <Modal
        isOpen={showRequestReportModal}
        toggle={toggleRequestReportModal}
        className="modal-primary modal-lg fade in"
      >
        <ModalHeader toggle={toggleRequestReportModal}>
          {interestedReport.name} Report
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            {interestedReport.showTeamPicker && (
              <Row className="my-3">
                <div className="col-8">
                  <LabelStyled>Filter</LabelStyled>
                  <RadioGroup
                    classname="bootstrap-boxed"
                    arrayOfRadios={[
                      {
                        label: FILTER_all,
                        value: FILTER_all,
                      },
                      {
                        label: FILTER_by_team,
                        value: FILTER_by_team,
                        children: (
                          <QuickSearch
                            type="Team"
                            team={selectedTeam}
                            handleSearchItemCleared={() =>
                              setSelectedTeam(null)
                            }
                            handleSearchItemSelected={val =>
                              setSelectedTeam(val)
                            }
                          />
                        ),
                      },
                    ]}
                    activeRadioValue={filterOption}
                    handleChange={handleFilterOptionChange}
                  />
                </div>
              </Row>
            )}
            {interestedReport.showModalIncludeInactiveCheckbox && (
              <Row className="my-3">
                <div className="col-12">
                  <LabelStyled>Status</LabelStyled>
                </div>
                <div className="col-6">
                  <Checkbox
                    name="includeInactive"
                    style={{ width: '100%' }}
                    label={`Include ${
                      interestedReport.name === REPORT_Members ||
                      interestedReport.name === REPORT_Teams
                        ? 'inactive'
                        : 'archived'
                    } ${interestedReport.name}`}
                    selected={includeInactive}
                    handleChange={() => setIncludeInactive(!includeInactive)}
                  />
                </div>
              </Row>
            )}
            {interestedReport.showModalDatePickers && (
              <Row className="my-3">
                <div className="col-8">
                  <LabelStyled>Date Range</LabelStyled>
                  <RadioGroup
                    classname="bootstrap-boxed"
                    arrayOfRadios={[
                      {
                        value: DRP_today,
                        label: DRP_today,
                        description: (
                          <span>
                            {convertStringDateToMomentDate(
                              DRP_today,
                              startDate,
                              endDate
                            ).start.format('MMM DD')}
                          </span>
                        ),
                      },
                      {
                        value: DRP_currentMonth,
                        label: DRP_currentMonth,
                        description: (
                          <span>
                            {`${convertStringDateToMomentDate(
                              DRP_currentMonth,
                              startDate,
                              endDate
                            ).start.format(
                              'MMM DD'
                            )} - ${convertStringDateToMomentDate(
                              DRP_currentMonth,
                              startDate,
                              endDate
                            ).end.format('MMM DD')}`}
                          </span>
                        ),
                      },
                      {
                        value: DRP_previousSevenDays,
                        label: DRP_previousSevenDays,
                        description: (
                          <span>
                            {`${convertStringDateToMomentDate(
                              DRP_previousSevenDays,
                              startDate,
                              endDate
                            ).start.format(
                              'MMM DD'
                            )} - ${convertStringDateToMomentDate(
                              DRP_previousSevenDays,
                              startDate,
                              endDate
                            ).end.format('MMM DD')}`}
                          </span>
                        ),
                      },
                      {
                        value: DRP_lastMonth,
                        label: DRP_lastMonth,
                        description: (
                          <span>{`${convertStringDateToMomentDate(
                            DRP_lastMonth,
                            startDate,
                            endDate
                          ).start.format(
                            'MMM DD'
                          )} - ${convertStringDateToMomentDate(
                            DRP_lastMonth,
                            startDate,
                            endDate
                          ).end.format('MMM DD')}`}</span>
                        ),
                      },
                      {
                        value: DRP_custom,
                        label: DRP_custom,
                        description: 'Select your own date range',
                        children: (
                          <DateRangePicker
                            startDate={startDate} // momentPropTypes.momentObj or null,
                            isOutsideRange={() => false}
                            startDateId="your_unique_start_date_id_999999" // PropTypes.string.isRequired,
                            endDate={endDate} // momentPropTypes.momentObj or null,
                            endDateId="your_unique_end_date_id_999999" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => {
                              setStartDate(startDate)
                              setEndDate(endDate)
                            }} // PropTypes.func.isRequired,
                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput =>
                              setFocusedInput(focusedInput)
                            } // PropTypes.func.isRequired,
                          />
                        ),
                      },
                    ]}
                    activeRadioValue={dateRangeCheck}
                    handleChange={setDateRangeCheck}
                  />
                </div>
              </Row>
            )}
            <Row className="my-3">
              <div className="col-md-12">
                <p className="text-muted">
                  Note: This report will be emailed to
                  <span className="text-primary"> {user && user.email}</span>.
                  Please allow 5 minutes before requesting again.
                </p>
              </div>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mr-3"
            color="secondary"
            onClick={() => toggleRequestReportModal(null)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => generateReport(interestedReport)}
          >
            Request
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <div id="leads" className="animated fadeIn">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6 d-flex align-items-center">
                  <h2>Reports</h2>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-end">
                  <Button
                    onClick={() => toggleSuggestReportModal()}
                    className="btn btn-primary btn-link pull-right"
                  >
                    Missing a report?
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-block">
              {isFetchingReport ? (
                <Spinner />
              ) : (
                <div className="col-12">
                  {availableReports.map((report, i) => {
                    if (report.visible !== undefined && !report.visible)
                      return null
                    return renderReportRow(i, report, report.useModal)
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showRequestReportModal && renderRequestReportModal()}
    </div>
  )
}

Reports.displayName = 'Reports'
Reports.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  report_actions: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  user: PropTypes.object,
  isFetching: PropTypes.bool,
  isFetchingReport: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    user: state.user,
    activeCommunity: state.ui.activeCommunity,
    isFetching: state.ui.isFetching,
    isFetchingReport: resolveFetchingStatus(state, FETCHING_REPORTS),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ui_actions: bindActionCreators(uiActions, dispatch),
    report_actions: bindActionCreators(reportActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
