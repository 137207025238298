import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as _ from 'lodash'

const Container = styled.div`
`
const TopWrapper = styled.h6`
`
const BottomWrapper = styled.span`
`

function CampusCol({
  campus,
}) {

  return (
    <Container>
      <TopWrapper>
        {campus}
      </TopWrapper>
      {/* <BottomWrapper className='text-muted'>
        {email}&nbsp;
      </BottomWrapper> */}
    </Container>
  )
}

CampusCol.propTypes = {
  campus: PropTypes.string,
  // last: PropTypes.string,
  // email: PropTypes.string,
  // onClick: PropTypes.func,
}

CampusCol.displayName = 'Campus Column'

export default CampusCol