import React, { useState } from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line no-unused-vars
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CampusCard from './CampusCard'
import * as campusActions from '../../../../reduxActions/campusActions'
import * as uiActions from '../../../../reduxActions/uiActions'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import Tooltip from '@bit/coworks.base-components.tooltip'
import { IntercomAPI } from 'react-intercom'
import * as Roles from '@app/config/roles'

import * as Constants from '@global/Constants'

function handleEditCampusClicked({ campus, history }) {
  history.push(`/community/campuses/${campus.id}/profile`, { campus })
}
function handleAddCampusClicked({ history }) {
  history.push(`/community/campuses/add`)
}

function renderCampusCards(campuses, history) {
  if (!campuses) {
    return null
  }
  return campuses.map((campus, i) => {
    const countryCode = Constants.convertCountryCodeToName(campus.country)

    return (
      <React.Fragment>
        {CampusCard({
          key: i,
          campus: campus,
          picture: campus.campus_photo,
          handleEditCampusClicked: campus =>
            handleEditCampusClicked({ campus, history }),
          name: campus.name,
          address1: campus.address1 ? campus.address1 : '',
          address2: campus.address2 ? campus.address2 : '',
          city: campus.city ? campus.city : '',
          state: campus.state ? campus.state : '',
          zip: campus.zip ? campus.zip : null,
          phone: campus.phone ? campus.phone : '',
          country: countryCode ? countryCode : '',
        })}

        {i < campuses.length - 1 && <hr />}
      </React.Fragment>
    )
  })
}

function Campuses({ activeCommunity, history, user }) {
  const maxCampuses = activeCommunity.community_preference.campus_max
  const isCampusMaxHit = activeCommunity.campuses.length >= maxCampuses
  let shouldDisableCampuses = isCampusMaxHit
  if (user.type === Roles.superAdminRole) shouldDisableCampuses = false
  const [shouldDisableNewCampusButton] = useState(shouldDisableCampuses)
  return (
    <React.Fragment>
      <div className={`row border-bottom-1 my-3`}>
        <div className="col-9">
          <h2>{activeCommunity.name}</h2>
        </div>
        <div className="col-3 d-flex align-items-center justify-content-end">
          {shouldDisableNewCampusButton ? (
            <Tooltip
              tooltipContent={
                'You are at the max number of campuses allowed. Additional campuses are available at $99/mo!'
              }
              buttonText={'Contact Support'}
              header={`You're maxed out!`}
              handleClick={() => {
                IntercomAPI('showNewMessage', `I want to add another campus!`)
              }}
            >
              <Button
                style={{ pointerEvents: 'none' }}
                disabled={shouldDisableNewCampusButton}
                className="btn btn-primary"
              >
                New Campus
              </Button>
            </Tooltip>
          ) : (
            <Button
              className="btn btn-primary"
              onClick={() => handleAddCampusClicked({ history })}
            >
              New Campus
            </Button>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="row">
            <div className="col">
              {renderCampusCards(activeCommunity.campuses, history)}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Campuses.propTypes = {
  campuses: PropTypes.array,
  community: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  campus_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
}
Campuses.displayName = 'Campuses'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetching: state.ui.isFetching,
    campuses: state.ui.activeCommunity.campuses,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    campus_actions: bindActionCreators(campusActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Campuses)
