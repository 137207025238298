/* eslint-disable no-loop-func */
import React from 'react'
import LabelStyled from '@global/Form/LabelStyled'
import { Button } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import InputWithoutBorders from '@global/Form/InputWithoutBorders'
import { ErrorMessage } from 'formik'

export function renderRow({
  jsonString,
  setFieldValue,
  isEditing,
  handleKeyPress,
  resolveFieldName,
  resolveFieldValue,
  objectKey,
  setFieldTouched,
}) {
  if (!jsonString) {
    return isEditing ? null : <span className="text-muted">None</span>
  }
  const jsonArray = JSON.parse(jsonString)

  const inputs = []
  for (let index = 0; index < jsonArray.length; index++) {
    const obj = jsonArray[index]
    const shouldFocus = index === jsonArray.length - 1
    const keys = Object.keys(obj)
    inputs.push(
      <React.Fragment>
        <div className="row my-3">
          <div className="col-3">
            {!isEditing && (
              <LabelStyled htmlFor={resolveFieldName(obj)}>
                {resolveFieldName(obj)}
              </LabelStyled>
            )}
            {isEditing && (
              <React.Fragment>
                <InputWithoutBorders
                  id={`${keys[0]}-${index}`}
                  key={`${keys[0]}-${index}`}
                  name={`${keys[0]}`}
                  value={resolveFieldName(obj)}
                  type="text"
                  autoFocus={shouldFocus}
                  className={'form-control w-100'}
                  placeholder={'Name'}
                  onKeyPress={handleKeyPress}
                  onChange={event =>
                    handleChange({
                      event,
                      index,
                      setFieldValue,
                      jsonArray,
                      objectKey,
                    })
                  }
                  onBlur={event => {
                    setFieldTouched(`${objectKey}.${keys[0]}-${index}`, true)
                  }}
                />
                <ErrorMessage name={`${objectKey}.${keys[0]}-${index}`}>
                  {msg => <div className="text-danger">{msg}</div>}
                </ErrorMessage>
              </React.Fragment>
            )}
          </div>
          <div className="col-8">
            {!isEditing && (
              <div>
                {obj.field_value ? (
                  obj.field_value
                ) : (
                  <div className="text-muted">none</div>
                )}
              </div>
            )}
            {isEditing && (
              <React.Fragment>
                <input
                  id={`${keys[1]}-${index}`}
                  key={`${keys[1]}-${index}`}
                  name={`${keys[1]}`}
                  value={resolveFieldValue(obj)}
                  type="text"
                  className={'form-control w-100'}
                  placeholder={'Value'}
                  onKeyPress={handleKeyPress}
                  onChange={event =>
                    handleChange({
                      index,
                      event,
                      setFieldValue,
                      jsonArray,
                      objectKey,
                    })
                  }
                  onBlur={event => {
                    setFieldTouched(`${objectKey}.${keys[1]}-${index}`, true)
                  }}
                />
                <ErrorMessage name={`${objectKey}.${keys[1]}-${index}`}>
                  {msg => <div className="text-danger">{msg}</div>}
                </ErrorMessage>
              </React.Fragment>
            )}
          </div>
          <div className="col-1">
            {isEditing && (
              <Button
                id={`trash-${index}`}
                key={`trash-${index}`}
                name={`trash-${index}`}
                className="btn-link"
                onClick={event =>
                  handleRemove({
                    indexToRemove: index,
                    setFieldValue,
                    jsonArray,
                    objectKey,
                  })
                }
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return inputs
  // return customFields.``
}

export function handleChange({
  event,
  index,
  setFieldValue,
  jsonArray,
  objectKey,
}) {
  if (!event || !setFieldValue) {
    return null
  }
  const newJsonArray = updateObject({
    index,
    inputValue: event.target.value,
    keyToUpdate: event.target.name,
    jsonArray,
  })
  const newJsonString = JSON.stringify(newJsonArray)
  setFieldValue(objectKey, newJsonString)
  return newJsonString
}

export function handleAddRow({
  setFieldValue,
  jsonArray,
  objectKey,
  createNewObject,
}) {
  if (!jsonArray) {
    return null
  }
  jsonArray.push(createNewObject())
  setFieldValue(objectKey, JSON.stringify(jsonArray))
  return jsonArray
}

export function handleRemove({
  jsonArray,
  indexToRemove,
  setFieldValue,
  objectKey,
}) {
  const jsonArrayCopy = JSON.parse(JSON.stringify(jsonArray))
  jsonArrayCopy.splice(indexToRemove, 1)
  if (setFieldValue) {
    if (jsonArrayCopy.length > 0) {
      setFieldValue(objectKey, JSON.stringify(jsonArrayCopy))
    } else {
      setFieldValue(objectKey, '')
    }
  }
  return jsonArrayCopy
}

export function updateObject({ inputValue, keyToUpdate, jsonArray, index }) {
  if (index !== null && index !== undefined && jsonArray.length > index) {
    // eslint-disable-next-line no-param-reassign
    jsonArray[index][keyToUpdate] = inputValue
  }
  return jsonArray
}
