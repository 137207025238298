import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ColorPicker from '@global/Colors/ColorPicker'
import BrandingPhotos from './BrandingPhotos'
import { Row } from 'reactstrap'
import { Alert } from 'reactstrap'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import { IntercomAPI } from 'react-intercom'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

function BrandingSettings({ formProps, ...rest }) {
  return (
    <React.Fragment>
      {rest.disabled && (
        <Alert color={'info'}>
          <div className="row d-flex align-items-center justify-space-between">
            <div className="col-8">
              <FontAwesomeIcon icon="paint-brush" /> This is available with the{' '}
              <TextButton
                className="p-0 m-0"
                onClick={() => {
                  window.open(
                    'https://help.coworksapp.com/en/articles/3591547-what-is-the-coworks-white-label-upgrade'
                  )
                }}
              >
                white-label add-on
              </TextButton>{' '}
              for $99/mo.{' '}
            </div>
            <div className="col-4  d-flex justify-content-end">
              <TextButton
                className="p-0 m-0"
                onClick={() => {
                  IntercomAPI(
                    'showNewMessage',
                    'I would like to add the white-labeled branding upgrade to my account.'
                  )
                }}
              >
                Upgrade
              </TextButton>
            </div>
          </div>
        </Alert>
      )}
      <h3>Colors</h3>
      <br />
      <Row>
        <div className="col-md-6 col-12">
          <ColorPicker
            disabled={rest.disabled}
            name="branding_primary_color"
            color={formProps.values.branding_primary_color}
            label="Primary Color"
            description="The primary color for your community and will control mobile app and email colors."
            onChange={color => {
              formProps.setFieldValue('branding_primary_color', color)
            }}
          />
          {formProps.touched.branding_primary_color &&
            formProps.errors.branding_primary_color && (
              <div className="text-danger">
                {formProps.errors.branding_primary_color}
              </div>
            )}
        </div>
      </Row>
      {/* <Row>
        <div className="col-md-6 col-12">
          <ColorPicker
            name="branding_secondary_color"
            description="The secondary color for your community. This is not yet used in mobile or emails."
            label="Secondary Color"
            color={formProps.values.branding_secondary_color}
            onChange={color => {
              formProps.setFieldValue('branding_secondary_color', color)
            }}
          />
          {formProps.touched.branding_secondary_color &&
            formProps.errors.branding_secondary_color && (
              <div className="text-danger">
                {formProps.errors.branding_secondary_color}
              </div>
            )}
        </div>
      </Row> */}
      <br />
      <br />
      <Row>
        <div className="col-md-6 col-12">
          <BrandingPhotos disabled={rest.disabled} />
        </div>
      </Row>
    </React.Fragment>
  )
}

BrandingSettings.propTypes = {
  formProps: PropTypes.object,
  role: PropTypes.string,
}
BrandingSettings.displayName = 'BrandingSettings'
export default BrandingSettings
