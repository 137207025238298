/* eslint-disable react/no-multi-comp,react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import * as memberActions from '@reduxActions/memberActions'
import * as teamActions from '@reduxActions/teamActions'
import * as uiActions from '@reduxActions/uiActions'
import * as userActions from '@reduxActions/userActions'
import * as checkinActions from '@app/reduxActions/checkinActions'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { toast } from 'react-toastify'
// import { DropdownToggle } from '@global/Base/Button/ButtonStyled'
import { captureException } from '@global/ErrorFactory'
import { QueryStringToJSON } from '@global/Utilities/routeUtilities'

import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import * as memberSelectors from '@reduxSelectors/memberSelectors'
import {
  resolveFetchingStatus,
  FETCHING_MEMBERS,
  FETCHING_MEMBERS_INACTIVE,
} from '@global/Constants/FetchingConstants'

import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import MemberRow from './MemberRow'
import MemberRowSearch from './MemberRowSearch'
import Spinner from '@global/Spinner'
import CoworksSearch from '@global/Search/CoworksSearch'
import Filters from '@global/Filters/Filters'
import * as Roles from '@app/config/roles'
import moment from 'moment'

const HeaderText = styled.h2`
  font-size: ${props => (props.small ? '1.5rem' : '2rem')};
`

class Members extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    member_actions: PropTypes.object,
    user_actions: PropTypes.object,
    team_actions: PropTypes.object,
    plan_actions: PropTypes.object,
    checkin_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    members: PropTypes.array,
    inactiveMembers: PropTypes.array,
    teams: PropTypes.array,
    plans: PropTypes.array,
    ui_actions: PropTypes.object,
    role: PropTypes.string,
    pagination: PropTypes.object,
    inactivePagination: PropTypes.object,
    isFetching: PropTypes.bool,
    isFetchingInactive: PropTypes.bool,
  }

  constructor(props, context) {
    super(props, context)
    let canEdit,
      canEditBilling = false
    if (this.props.role !== 'Member') {
      canEdit = true // don't want to be able to edit directly from members components.
    }
    if (Roles.canViewEditBilling.includes(this.props.role)) {
      canEditBilling = true
    }
    const query = QueryStringToJSON(this.props.location)

    this.state = {
      member: null,
      dropdownsOpen: [],
      showInactive: Boolean(
        query && query.status && query.status === 'inactive'
      ),
      searchBar: '',
      newMemberDropdownOpen: false,
      searchValue: '',
      page: Constants.START_PAGE_DEFAULT,
      pageInactive: Constants.START_PAGE_DEFAULT,
      perPage: Constants.PER_PAGE_DEFAULT,
      sinceTS: moment().unix(),
      sortBy: 'first_name',
      canEdit,
      canEditBilling,
      canCheckInMember: true,
    }
  }

  componentDidMount() {
    if (this.state.page === Constants.START_PAGE_DEFAULT) {
      this.loadNextObjects(
        this.state.showInactive ? this.state.pageInactive : this.state.page
      )
    }
  }
  changeSortBy = sortBy => {
    this.setState(
      {
        sortBy,
        [this.state.showInactive ? 'pageInactive' : 'page']: 0,
      },
      () => {
        this.props.user_actions.clearMembers()
        this.loadNextObjects(
          this.state.showInactive ? this.state.pageInactive : this.state.page
        )
      }
    )
  }

  dataResolver = response => {
    return response.members
  }

  toggleDropdown = index => {
    const dropdownsOpen = this.state.dropdownsOpen
    if (this.state.dropdownsOpen[index]) {
      dropdownsOpen[index] = !this.state.dropdownsOpen[index]
    } else {
      dropdownsOpen[index] = true
    }
    // update state
    this.setState({
      dropdownsOpen,
    })
  }

  toggleShowArchivedMembers = () => {
    const self = this
    this.props.history.push(
      `/directory/members?status=${
        this.state.showInactive ? 'active' : 'inactive'
      }`
    )
    this.setState(
      {
        showInactive: !this.state.showInactive,
        isLoading: true,
      },
      () => {
        self.loadNextObjects(
          this.state.showInactive ? this.state.pageInactive : this.state.page
        )
      }
    )
  }

  editMemberClicked = (member, team) => {
    const mem = JSON.parse(JSON.stringify(member))
    mem.isInactive = this.state.showInactive
    NavigationConstants.navigateToMember({
      history: this.props.history,
      match: this.props.match,
      member: mem,
      state: { team, isEditing: true },
    })
  }
  viewMemberClicked = (member, team) => {
    const mem = JSON.parse(JSON.stringify(member))
    mem.isInactive = this.state.showInactive
    NavigationConstants.navigateToMember({
      history: this.props.history,
      match: this.props.match,
      member: mem,
      state: { team },
    })
  }
  viewMemberBilling = member => {
    if (member && member.teams && member.teams.length > 0) {
      const team = member.teams[0]
      NavigationConstants.navigateToTeam({
        history: this.props.history,
        match: this.props.match,
        team,
        state: {
          team,
        },
        tab: 'membership',
      })
    } else {
      toast.error("Looks like this member doesn't have a team.")
    }
  }
  toggleNewMemberDropdown = () => {
    // update state
    this.setState({
      newMemberDropdownOpen: !this.state.newMemberDropdownOpen,
    })
  }
  teamLinkClicked = (team, member) => {
    if (team) {
      const teem = JSON.parse(JSON.stringify(team))
      teem.isInactive = this.state.showInactive
      NavigationConstants.navigateToTeam({
        history: this.props.history,
        match: this.props.match,
        team: teem,
      })
    } else {
      toast.error(
        `Whoops! This member is missing a team! Support has been notified!`
      )
      captureException({
        text: `Member is missing a team. ${member ? member.id : null}`,
      })
    }
  }
  handleNewMember = createNewTeam => {
    if (createNewTeam) {
      this.props.history.push('/directory/teams/add-team', {
        navFromMember: true,
        backRoute: '/directory/teams',
      })
    } else {
      this.props.history.push('/directory/members/add-member', {
        backRoute: '/directory/members',
      })
    }
  }

  handleSearchItemSelected = member => {
    //this.editMemberClicked(member);
  }

  loadNextObjects = page => {
    if (
      (this.state.showInactive && !this.props.isFetchingInactive) ||
      (!this.state.showInactive && !this.props.isFetching)
    ) {
      this.props.member_actions.getMembers(
        this.props.activeCampus.id,
        {
          page,
          per_page: this.state.perPage,
          status: this.state.showInactive ? 'inactive' : 'active',
          order_by: this.state.sortBy,
        },
        this.state.showInactive ? FETCHING_MEMBERS_INACTIVE : FETCHING_MEMBERS
      )
    }
  }

  nextPage = () => {
    const stateCopy = Object.assign({}, this.state)
    const showInactive = this.state.showInactive
    const pagination = showInactive
      ? this.props.inactivePagination
      : this.props.pagination
    const members = showInactive
      ? this.props.inactiveMembers
      : this.props.members
    const page = showInactive ? stateCopy.pageInactive : stateCopy.page
    if (
      pagination &&
      members &&
      page < pagination.total_pages &&
      members.length < pagination.total_objects
    ) {
      this.setState(
        {
          [showInactive ? 'pageInactive' : 'page']: page + 1,
          isLoading: true,
        },
        () => this.loadNextObjects(page)
      )
    }
  }

  renderSearchRow = object =>
    MemberRowSearch({
      key: object.id,
      member: object,
      team: object.teams && object.teams.length > 0 ? object.teams[0] : null,
      index: object.id,
      toggleDropdown: this.toggleDropdown,
      dropdownsOpen: this.state.dropdownsOpen,
      editMemberClicked: this.editMemberClicked,
      viewMemberClicked: this.viewMemberClicked,
      teamLinkClicked: this.teamLinkClicked,
      viewMemberBilling: this.viewMemberBilling,
      includeMarginsAndPadding: true,
      includePhoto: false,
      showEdit: false,
      showActions: false,
    })

  renderMember = (member, index) => {
    if (!member) return null
    return (
      <MemberRow
        key={member.id}
        member={member}
        team={member.teams && member.teams.length > 0 && member.teams[0]}
        index={index}
        memberCheckInClicked={this.props.checkin_actions.createCheckIn}
        memberCheckOutClicked={this.props.checkin_actions.checkOutMember}
        showEdit={this.state.canEdit}
        toggleDropdown={this.toggleDropdown}
        dropdownsOpen={this.state.dropdownsOpen}
        viewMemberClicked={this.viewMemberClicked}
        editMemberClicked={this.editMemberClicked}
        teamLinkClicked={this.teamLinkClicked}
        viewMemberBilling={this.viewMemberBilling}
        canViewEditBilling={this.state.canEditBilling}
        canCheckInMember={this.state.canCheckInMember}
        isInactive={member.is_archived}
      />
    )
  }

  renderPaginatedMembers = canEdit => {
    const {
      inactiveMembers,
      members: propsMembers,
      inactivePagination,
      pagination: propsPagination,
      isFetching,
      isFetchingInactive,
      activeCampus,
    } = this.props

    const showInactive = this.state.showInactive
    const members = showInactive ? inactiveMembers : propsMembers
    const pagination = showInactive ? inactivePagination : propsPagination
    const fetchingStatus = showInactive ? isFetchingInactive : isFetching
    return (
      <PaginatedTable
        objects={members}
        canEdit={canEdit}
        type={showInactive ? 'inactive members' : 'members'}
        nextPage={this.nextPage}
        isLoading={fetchingStatus}
        totalPages={pagination && pagination.total_pages}
        renderItem={(member, index) => this.renderMember(member, index)}
        placeholderTitle={`No ${
          showInactive ? 'inactive members' : 'members'
        } at ${activeCampus.name}`}
        placeholderSubtext1={`You currently have no ${
          showInactive ? 'inactive members' : 'members'
        } in this campus`}
        placeholderSubtext2={
          showInactive ? '' : `Add one by clicking New Member`
        }
        icon={'user'}
      />
    )
  }

  render() {
    const { canEdit, showInactive } = this.state
    const members = showInactive
      ? this.props.inactiveMembers
      : this.props.members
    return (
      <div className="animated fadeIn">
        <div className="col-md-12">
          <div className="row border-bottom-1 my-3 d-flex justify-content-between align-items-center">
            <div className="col-12 col-md-3 d-flex align-items-center">
              <HeaderText small={showInactive}>
                {`${showInactive ? 'Inactive' : ''} Members`}
              </HeaderText>
            </div>
            <div className="col-12 col-md-5 col-sm-12 d-block align-items-center">
              <CoworksSearch
                placeholder={`Search ${showInactive ? 'Inactive' : ''} Members`}
                defaultData={members}
                actions={this.props.member_actions}
                activeCampus={this.props.activeCampus}
                handleItemSelected={this.handleSearchItemSelected}
                dataResolver={this.dataResolver}
                renderSingleValue={() => {}}
                renderSearchRow={this.renderSearchRow}
                otherParams={{
                  status: showInactive ? 'inactive' : 'active',
                }}
              />
            </div>
            {canEdit && (
              <div className="col-6 col-md-2 d-flex align-items-center d-md-down-none">
                <select
                  onChange={this.toggleShowArchivedMembers}
                  value={this.state.showInactive ? 'inactive' : 'active'}
                  className={'w-100 form-control'}
                >
                  <option value={'active'}>Active</option>
                  <option value={'inactive'}>Inactive</option>
                </select>
              </div>
            )}
            {canEdit && (
              <div className="col-6 col-md-2 d-flex justify-content-end align-items-center">
                <ButtonDropdown
                  isOpen={this.state.newMemberDropdownOpen}
                  toggle={() => this.toggleNewMemberDropdown()}
                >
                  {/*<Button className='btn btn-primary' >New</ButtonDropdown>*/}
                  <DropdownToggle className="btn btn-primary" caret>
                    New Member
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleNewMember(true)}>
                      <FontAwesomeIcon icon="user" /> New Member
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleNewMember(false)}>
                      <FontAwesomeIcon icon="users" /> Add to Existing Team
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            )}
          </div>
          {/* <Filters
            sortBy={this.state.sortBy}
            onChangeSortBy={this.changeSortBy}
            sortByOptions={[
              { value: 'first_name', name: 'First Name' },
              { value: 'last_name', name: 'Last Name' },
              { value: 'email', name: 'Email' },
              // { value: 'team_name', name: 'Company Name' },
              { value: 'joined_at', name: 'Joined' },
            ]}
          /> */}
          {this.renderPaginatedMembers(canEdit)}
        </div>
      </div>
    )
  }
}

Members.displayName = 'Members'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    members: memberSelectors.getMemberList(state),
    pagination: memberSelectors.getMemberPagination(state),
    inactiveMembers: memberSelectors.getInactiveMemberList(state),
    inactivePagination: memberSelectors.getInactiveMemberPagination(state),
    teams: state.teams.teams,
    isFetching: resolveFetchingStatus(state, FETCHING_MEMBERS),
    isFetchingInactive: resolveFetchingStatus(state, FETCHING_MEMBERS_INACTIVE),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkin_actions: bindActionCreators(checkinActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Members)
