import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { setBookings } from './bookingActions'
import { setPendingMembers } from './leadActions'

// ACTION CREATORS
export function successfulCommunityRegistration(newCommunity) {
  const {
    manager,
    community,
    campus,
    teams,
    plans,
    rooms,
    resources,
    bookings,
  } = newCommunity

  return {
    type: actionTypes.REGISTER_COMMUNITY,
    manager,
    community,
    campus,
    teams,
    plans,
    rooms,
    resources,
    bookings,
  }
}

export function successUpdatingCommunityCoworksGroups(coworksGroups) {
  return { type: actionTypes.UPDATE_COMMUNITY_COWORKS_GROUPS, coworksGroups }
}

// ACTION DISPATCHERS
export function register(registration) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .registerCommunity(registration)
      .then(response => dispatch(successfulCommunityRegistration(response)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}
export const getCommunities = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .getCommunities(params)
      .then(response => {
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const getCommunityPreference = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityPreference(params)
      .then(response => {
        if (response.community_preference) {
          dispatch(
            uiActions.updateCommunityPreference(response.community_preference)
          )
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const updateCommunityPreference = (
  params,
  communityId,
  fetchingName
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingName))
    apiService
      .updateCommunityPreference(communityId, params)
      .then(response => {
        if (response.community && response.community.preferences) {
          dispatch(
            uiActions.updateCommunityPreference(response.community.preferences)
          )
        } else if (response.community_preference) {
          dispatch(
            uiActions.updateCommunityPreference(response.community_preference)
          )
        }
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        reject(err)
      })
  })
}
export const sendCommunityOnboardingEmails = (
  params,
  communityId,
  fetchingName
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingName))
    apiService
      .sendCommunityOnboardingEmails(params, communityId)
      .then(response => {
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        reject(err)
      })
  })
}
export const convertAllPendingMembers = (
  params,
  communityId,
  fetchingName
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingName))
    apiService
      .convertAllPendingMembers(params, communityId)
      .then(response => {
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        reject(err)
      })
  })
}

export const importMembers = (params, fetchingName) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingName))
    apiService
      .importMembers(params)
      .then(response => {
        dispatch(setPendingMembers(response.pending_members))
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        reject(err)
      })
  })
}

export const importBookings = (params, fetchingName) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingName))
    apiService
      .importBookings(params)
      .then(response => {
        dispatch(setBookings(response.bookings))
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        reject(err)
      })
  })
}
