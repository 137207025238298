/* eslint-disable react/prop-types */
import React from 'react'

import PillToggle from '@global/Base/Toggle/PillToggle'
import classnames from 'classnames'

// const arrayOfTabs = [
//   {label: 'Tab 1'},
//   {label: 'Tab 2'},
//   {label: 'Tab 3'},
// ]
export default function TogglesComponent({
  arrayOfTabs,
  handleToggleTab,
  activeTab,
  className,
}) {
  if (!arrayOfTabs || !(arrayOfTabs instanceof Array)) {
    return null
  }
  return arrayOfTabs.map((tab, index) => (
    <PillToggle
      key={index}
      className={classnames({
        active:
          activeTab === (tab.internalValue ? tab.internalValue : tab.label),
        ...className,
      })}
      onClick={() => {
        handleToggleTab(tab.internalValue ? tab.internalValue : tab.label)
      }}
    >
      {tab.label}
    </PillToggle>
  ))
}

TogglesComponent.displayName = 'TogglesComponent'
