import {
  CREATE_RESOURCE,
  SET_RESOURCES,
  UPDATE_RESOURCE,
  REGISTER_COMMUNITY,
  CLEAR_CAMPUS_DATA,
} from '../reduxActions/actionTypes'

export default function(resources = [], action) {
  switch (action.type) {
    case CREATE_RESOURCE:
      return [...resources, Object.assign({}, action.resource)]
    case SET_RESOURCES:
      return action.resources
    case UPDATE_RESOURCE:
      return resources.map(oldResource => {
        if (oldResource.id !== action.resource.id) {
          return oldResource
        }
        return { ...oldResource, ...action.resource }
      })
    case REGISTER_COMMUNITY:
      return action.resources
    case CLEAR_CAMPUS_DATA:
      return []
    default:
      return resources
  }
}
