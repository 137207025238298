import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withFormik, Field, Form, ErrorMessage } from 'formik'
import { CustomSelectComponent } from '@global/Form/FormComponents'

const PickerContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`
const Label = styled.h6`
  display: flex;
  align-items: center;
  margin-right: 45px;
`

const CampusPicker = ({ campuses, setFieldValue, campus, ...other }) => {
  if (campuses.length > 1) {
    return (
      <PickerContainer {...other}>
        <Label>Campus</Label>
        <select
          name="selectedCampus"
          label="Which campus should we check this visitor into?"
          className="form-control"
          value={campus}
          onChange={event =>
            setFieldValue('selectedCampus', event.target.value)
          }
          component={CustomSelectComponent}
        >
          {campuses.map((campus, index) => (
            <option value={campus.id}>{campus.name}</option>
          ))}
        </select>
      </PickerContainer>
    )
  }
  return null
}

CampusPicker.displayName = 'Check-in CampusPicker'

CampusPicker.propTypes = {
  campus: PropTypes.object,
  campuses: PropTypes.array,
  setFieldValue: PropTypes.func,
}

export default CampusPicker
