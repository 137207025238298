/* eslint-disable no-invalid-this */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MyStatefulEditor from '@global/MyStatefulEditor'

import * as resourceActions from '../../reduxActions/resourceActions'
import { Button } from 'reactstrap'
import PinnedFields from './PinnedFields'
import { FormErrors } from '@global/FormErrorsComponent'
import { toast } from 'react-toastify'
import { LabelStyled } from '@global/Form/FormComponents'

class ResourceTextForm extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    resource: PropTypes.object,
    reset: PropTypes.func,
    resource_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCampusId: PropTypes.number,
    archiveResource: PropTypes.func,
    renderCustomDropdowns: PropTypes.func,
    customTagState: PropTypes.array,
    goBack: PropTypes.function,
  }
  constructor(props, context) {
    super(props, context)
    const history = this.props.history
    let name = ''
    let content = ''
    let description = ''
    let isPinned = false
    let buttonText = 'Create'
    let resource = {}
    let isEditing = false
    let nameValid = false
    let contentValid = false
    let formValid = false

    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.resource
    ) {
      resource = history.location.state.resource
      name = resource.name
      content = resource.content
      description = resource.description
      if (resource.is_pinned) {
        isPinned = true
      }
      buttonText = 'Update'
      isEditing = true
      nameValid = true
      contentValid = true
      formValid = true
    }
    this.state = {
      resource,
      name,
      content,
      isPinned,
      description,
      buttonText,
      formErrors: { Name: '', Content: '' },
      nameValid,
      contentValid,
      formValid,
      isEditing,
    }
  }
  handleStatefulEditorChange = htmlString => {
    this.setState(
      {
        content: htmlString,
      },
      () => {
        this.validateField('content', htmlString)
      }
    )
  }
  handlePinnedStatusChange = () => {
    this.setState({
      isPinned: !this.state.isPinned,
    })
  }
  /*
    Start of form validation -- don't forget state variables and component in render()
   */
  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }
  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors
    let nameValid = this.state.nameValid
    let contentValid = this.state.contentValid

    switch (fieldName) {
      case 'name':
        nameValid = value.length >= 1
        fieldValidationErrors.Name = nameValid ? '' : ' is a required field.'
        break
      case 'content':
        contentValid = value.length >= 1
        fieldValidationErrors.Content = contentValid
          ? ''
          : ' is a required field.'
        break
      default:
        break
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        nameValid: nameValid,
        contentValid: contentValid,
      },
      this.validateForm
    )
  }
  validateForm() {
    this.setState({
      formValid: this.state.nameValid && this.state.contentValid,
    })
  }
  /*
    End of form validation
  */
  createResource = () => {
    let customTagState = this.props.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    customTagState = JSON.stringify(customTagState)
    this.props.resource_actions
      .createResource({
        name: this.state.name,
        content: this.state.content,
        type: 'TextBased',
        is_slide: false,
        is_pinned: this.state.isPinned,
        description: this.state.description,
        campus_id: this.props.activeCampusId,
        custom_tag_state: customTagState,
      })
      .then(() => {
        this.props.history.push('/resources')
      })
      .catch(err => {
        if (err) {
          this.setState({ errors: err })
          toast.error(this.state.errors.message)
        }
      })
  }

  updateResource = () => {
    let customTagState = this.props.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    customTagState = JSON.stringify(customTagState)
    this.props.resource_actions
      .updateResource({
        id: this.props.resource.id,
        name: this.state.name,
        content: this.state.content,
        is_pinned: this.state.isPinned,
        description: this.state.description,
        campus_id: this.props.activeCampusId,
        custom_tag_state: customTagState,
      })
      .then(() => {
        this.props.history.push('/resources')
      })
      .catch(err => {
        if (err) {
          this.setState({ errors: err })
          toast.error(this.state.errors.message)
        }
      })
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-12">
            <form>
              <div className="row mb-3">
                <div className="col-md-4 col-12">
                  <LabelStyled>Resource Name</LabelStyled>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    className="w-100 form-control"
                    placeholder="Name"
                    onChange={this.handleInputChange}
                    value={this.state.name}
                  />
                </div>
                <div className="col-md-6 col-12">
                  {PinnedFields({
                    isPinned: this.state.isPinned,
                    handlePinnedStatusChange: () =>
                      this.handlePinnedStatusChange(),
                  })}
                </div>
                <div className="col-12 col-md-4">
                  {this.props.renderCustomDropdowns()}
                </div>
              </div>
              <div className="row my-3">
                <div className="col-md-8 col-12">
                  <LabelStyled>Content</LabelStyled>
                  <MyStatefulEditor
                    id="content"
                    name="content"
                    content={this.state.content}
                    onChange={this.handleStatefulEditorChange}
                  />
                </div>
              </div>
              <div className="row my-1">
                {this.state.isEditing ? (
                  <div className="col-7 d-md-down-none">
                    <Button
                      id="archive"
                      className="btn btn-danger"
                      onClick={() =>
                        this.props.archiveResource(this.state.resource)
                      }
                    >
                      Archive
                    </Button>
                  </div>
                ) : null}
                <div className="d-flex col justify-content-end">
                  <Button
                    className="btn btn-secondary mr-2"
                    onClick={this.props.goBack}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    onClick={
                      this.props.resource
                        ? this.updateResource
                        : this.createResource
                    }
                    disabled={!this.state.formValid}
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              </div>
            </form>
            <FormErrors formErrors={this.state.formErrors} />
          </div>
        </div>
      </div>
    )
  }
}

ResourceTextForm.displayName = 'ResourceTextForm'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCampusId: state.ui.activeCampus.id,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resource_actions: bindActionCreators(resourceActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceTextForm)
