import { createSelector } from 'reselect'

const NAMESPACE = 'members'
const rootSelector = state => state[NAMESPACE]

// get initial state object
export const getRoot = createSelector([rootSelector], root => root)

// active section
export const getMemberList = createSelector([getRoot], root => {
  if (Array.isArray(root)) return root
  return root && root.members ? root.members : []
})
export const getMemberPagination = createSelector([getRoot], root => root && root.meta ? root.meta.pagination : null)

// inactive section
export const getInactiveMemberList = createSelector([getRoot], root => {
  if (Array.isArray(root)) return root
  return root && root.inactive ? root.inactive : []
})
export const getInactiveMemberPagination = createSelector([getRoot], root => root && root.inactiveMeta ? root.inactiveMeta.pagination : null)