/* eslint-disable react/no-multi-comp,react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as staffActions from '@reduxActions/staffActions'
import * as uiActions from '@reduxActions/uiActions'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import { captureException } from '@global/ErrorFactory'

import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import * as checkinActions from '@app/reduxActions/checkinActions'

import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import MemberRow from '../Members/MemberRow'
import Spinner from '@global/Spinner'
import CoworksSearch from '@global/Search/CoworksSearch'

import moment from 'moment'

class Staff extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    member_actions: PropTypes.object,
    team_actions: PropTypes.object,
    plan_actions: PropTypes.object,
    staff_actions: PropTypes.object,
    checkin_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    staff: PropTypes.object,
    members: PropTypes.array,
    inactiveMembers: PropTypes.array,
    teams: PropTypes.array,
    plans: PropTypes.array,
    ui_actions: PropTypes.object,
    role: PropTypes.string,
    pagination: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context)
    let canEdit = false
    if (this.props.role !== 'Member') {
      canEdit = true
    }
    this.state = {
      isFetching: true,
      member: null,
      dropdownsOpen: [],
      showInactive: false,
      filteredMembers: null,
      filteredInactiveMembers: null,
      hasReceivedInactiveMembers: false,
      searchBar: '',
      newMemberDropdownOpen: false,
      searchValue: '',
      page: Constants.START_PAGE_DEFAULT,
      perPage: Constants.PER_PAGE_DEFAULT,
      totalRows: 0,
      sinceTS: moment().unix(),
      canEdit,
      isStaff: true,
    }
  }

  componentDidMount() {
    if (this.state.page === Constants.START_PAGE_DEFAULT) {
      this.props.staff_actions
        .getStaffTeam(this.props.activeCommunity.id, {
          campus_id: this.props.activeCampus.id,
        })
        .then(() => {
          this.setState({ isFetching: false })
        })
        .catch(err => {
          this.setState({ isFetching: false })
        })
    }
  }

  dataResolver = response => {
    return response.members
  }

  toggleDropdown = index => {
    const dropdownsOpen = this.state.dropdownsOpen
    if (this.state.dropdownsOpen[index]) {
      dropdownsOpen[index] = !this.state.dropdownsOpen[index]
    } else {
      dropdownsOpen[index] = true
    }
    // update state
    this.setState({
      dropdownsOpen,
    })
  }

  editMemberClicked = (member, team) => {
    const mem = JSON.parse(JSON.stringify(member))
    mem.isInactive = this.state.showInactive

    NavigationConstants.navigateToMember({
      history: this.props.history,
      match: this.props.match,
      member: mem,
      state: { team, isEditing: true },
    })
  }
  viewMemberClicked = (member, team) => {
    const mem = JSON.parse(JSON.stringify(member))
    mem.isInactive = this.state.showInactive

    NavigationConstants.navigateToMember({
      history: this.props.history,
      match: this.props.match,
      member: mem,
      state: { team },
    })
  }
  teamLinkClicked = (team, member) => {
    const { canEdit, isStaff } = this.state
    if (team) {
      const teem = JSON.parse(JSON.stringify(team))
      teem.isInactive = this.state.showInactive
      NavigationConstants.navigateToTeam({
        history: this.props.history,
        match: this.props.match,
        team: teem,
        tab: 'profile',
        state: { isStaff: true },
      })
    } else {
      toast.error(
        `Whoops! This member is missing a team! Support has been notified!`
      )
      captureException({
        text: `Failed to navigate to team (missing team param): member_id:${
          member ? member.id : null
        }`,
      })
    }
  }
  editTeamClicked = () => {
    const { canEdit, isStaff } = this.state
    const team = JSON.parse(JSON.stringify(this.props.staff))
    team.isInactive = this.state.showInactive
    NavigationConstants.navigateToTeam({
      history: this.props.history,
      match: this.props.match,
      team,
      tab: 'members',
      state: { canEdit, isStaff },
    })
  }

  nextPage = () => {
    // const stateCopy = Object.assign({}, this.state);
    // if (this.props.pagination && this.props.members &&
    //     (stateCopy.page < this.props.pagination.total_pages) &&
    //     (this.props.members.length < this.props.pagination.total_objects) &&
    //     this.props.activeTab === '1') {
    //   const page = stateCopy.page + 1;
    //   const perPage = stateCopy.perPage;
    //   this.setState({
    //     page: page,
    //     isFetching: true,
    //   }, () => this.loadNextObjects(page, perPage));
    // }
  }

  renderMember = (member, index) => {
    return (
      <MemberRow
        key={member.id}
        member={member}
        team={this.props.staff}
        index={index}
        showEdit={this.state.canEdit}
        toggleDropdown={this.toggleDropdown}
        dropdownsOpen={this.state.dropdownsOpen}
        memberCheckInClicked={this.props.checkin_actions.createCheckIn}
        memberCheckOutClicked={this.props.checkin_actions.checkOutMember}
        editMemberClicked={this.editMemberClicked}
        viewMemberClicked={this.viewMemberClicked}
        role={member.type}
      />
    )
  }

  renderPaginatedMembers = canEdit => {
    const showInactive = this.state.showInactive
    if (!this.props.staff) {
      return null
    }
    const members = !showInactive
      ? this.props.staff.users
      : this.props.inactiveMembers

    return (
      <PaginatedTable
        objects={members}
        canEdit={canEdit}
        type={showInactive ? 'inactive staff' : 'staff'}
        placeholderSubtext2={'Add one by clicking'}
        placeholderButtonText={'manage staff'}
        placeholderHandleClick={this.editTeamClicked}
        nextPage={this.nextPage}
        isLoading={this.state.isFetching}
        totalPages={this.props.pagination && this.props.pagination.total_pages}
        renderItem={(member, index) => this.renderMember(member, index)}
      />
    )
  }

  render() {
    const { canEdit, isFetching } = this.state
    return (
      <div className="animated fadeIn">
        <div className="col-12">
          <div className="row border-bottom-1 my-3 d-flex justify-content-between">
            <div className="col-12 col-md-6">
              <h2>{!this.props.staff ? null : this.props.staff.name}</h2>
            </div>
            {/* <div className='col-md-5'>
                <CoworksSearch
                  placeholder={'Search Members'}
                  defaultData={this.props.members}
                  actions={this.props.member_actions}
                  activeCampus={this.props.activeCampus}
                  handleItemSelected={this.handleSearchItemSelected}
                  dataResolver={this.dataResolver}
                  renderSingleValue={() => {}}
                  renderSearchRow={this.renderSearchRow} />
              </div> */}
            {canEdit && (
              <div className="col-md-2 col-12">
                <Button onClick={this.editTeamClicked} color="primary">
                  Manage Staff
                </Button>
              </div>
            )}
          </div>
          {/* {isFetching && !this.props.staff ? (
            <Spinner />
          ) : (
          )} */}
          {this.renderPaginatedMembers(canEdit)}
        </div>
      </div>
    )
  }
}

Staff.displayName = 'Staff'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    staff: state.staff.team ? state.staff.team : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkin_actions: bindActionCreators(checkinActions, dispatch),
    staff_actions: bindActionCreators(staffActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Staff)
