import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '@global/Spinner'
import { NavLink } from 'react-router-dom'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const HeaderRowStyled = styled.div`
  background-color: ${props => props.theme.colorSecondary};
  margin-bottom: 1rem;
  justify-content: space-between;
`
function DashboardMiniScrollview({
  loading,
  renderItems,
  title,
  linkDestination,
}) {
  return (
    <div className="card">
      <div className="clearfix p-0 card-body">
        <HeaderRowStyled className="d-flex flex-row align-items-center">
          <h5 className="text-muted text-uppercase font-weight-bold font-xs p-2 m-0 listview-title">
            {title}
          </h5>
          <NavLink to={linkDestination} className="listview-add-icon">
            <FontAwesomeIcon color="white" icon="plus-circle" />
          </NavLink>
        </HeaderRowStyled>
        <div className="container listview-scroll-list">
          {loading ? <Spinner /> : renderItems()}
        </div>
      </div>
    </div>
  )
}

DashboardMiniScrollview.propTypes = {
  loading: PropTypes.bool,
  renderItems: PropTypes.func,
  linkDestination: PropTypes.string,
  title: PropTypes.string,
}

DashboardMiniScrollview.displayName = 'DashboardMiniScrollview'
export default DashboardMiniScrollview
