const colors = {
  primary: '#269796',
  secondary: '#29404f',
  // react-toastify-overrides.css has these as well
  success: '#4dbd74',
  warning: '#ffc107',
  error: '#dc3545',
  danger: '#f86c6b',
  info: '#63c2de',
  black: '#212121', // md_grey_900,
  default: '#b0bec5',
  true_black: '#000000', //md_black_1000,
  white: '#FFFFFF',

  // <!-- reds -->
  md_red_50: '#FFEBEE',
  md_red_100: '#FFCDD2',
  md_red_200: '#EF9A9A',
  md_red_300: '#E57373',
  md_red_400: '#EF5350',
  md_red_500: '#F44336',
  md_red_600: '#E53935',
  md_red_700: '#D32F2F',
  md_red_800: '#C62828',
  md_red_900: '#B71C1C',
  md_red_A100: '#FF8A80',
  md_red_A200: '#FF5252',
  md_red_A400: '#FF1744',
  md_red_A700: '#D50000',

  //<!-- pinks -->
  md_pink_50: '#FCE4EC',
  md_pink_100: '#F8BBD0',
  md_pink_200: '#F48FB1',
  md_pink_300: '#F06292',
  md_pink_400: '#EC407A',
  md_pink_500: '#E91E63',
  md_pink_600: '#D81B60',
  md_pink_700: '#C2185B',
  md_pink_800: '#AD1457',
  md_pink_900: '#880E4F',
  md_pink_A100: '#FF80AB',
  md_pink_A200: '#FF4081',
  md_pink_A400: '#F50057',
  md_pink_A700: '#C51162',

  //<!-- purples -->
  md_purple_50: '#F3E5F5',
  md_purple_100: '#E1BEE7',
  md_purple_200: '#CE93D8',
  md_purple_300: '#BA68C8',
  md_purple_400: '#AB47BC',
  md_purple_500: '#9C27B0',
  md_purple_600: '#8E24AA',
  md_purple_700: '#7B1FA2',
  md_purple_800: '#6A1B9A',
  md_purple_900: '#4A148C',
  md_purple_A100: '#EA80FC',
  md_purple_A200: '#E040FB',
  md_purple_A400: '#D500F9',
  md_purple_A700: '#AA00FF',

  //<!-- deep purples -->
  md_deep_purple_50: '#EDE7F6',
  md_deep_purple_100: '#D1C4E9',
  md_deep_purple_200: '#B39DDB',
  md_deep_purple_300: '#9575CD',
  md_deep_purple_400: '#7E57C2',
  md_deep_purple_500: '#673AB7',
  md_deep_purple_600: '#5E35B1',
  md_deep_purple_700: '#512DA8',
  md_deep_purple_800: '#4527A0',
  md_deep_purple_900: '#311B92',
  md_deep_purple_A100: '#B388FF',
  md_deep_purple_A200: '#7C4DFF',
  md_deep_purple_A400: '#651FFF',
  md_deep_purple_A700: '#6200EA',

  //<!-- indigo -->
  md_indigo_50: '#E8EAF6',
  md_indigo_100: '#C5CAE9',
  md_indigo_200: '#9FA8DA',
  md_indigo_300: '#7986CB',
  md_indigo_400: '#5C6BC0',
  md_indigo_500: '#3F51B5',
  md_indigo_600: '#3949AB',
  md_indigo_700: '#303F9F',
  md_indigo_800: '#283593',
  md_indigo_900: '#1A237E',
  md_indigo_A100: '#8C9EFF',
  md_indigo_A200: '#536DFE',
  md_indigo_A400: '#3D5AFE',
  md_indigo_A700: '#304FFE',

  //<!--blue-->
  md_blue_50: '#E3F2FD',
  md_blue_100: '#BBDEFB',
  md_blue_200: '#90CAF9',
  md_blue_300: '#64B5F6',
  md_blue_400: '#42A5F5',
  md_blue_500: '#2196F3',
  md_blue_600: '#1E88E5',
  md_blue_700: '#1976D2',
  md_blue_800: '#1565C0',
  md_blue_900: '#0D47A1',
  md_blue_A100: '#82B1FF',
  md_blue_A200: '#448AFF',
  md_blue_A400: '#2979FF',
  md_blue_A700: '#2962FF',

  //<!-- light blue-->
  md_light_blue_50: '#E1F5FE',
  md_light_blue_100: '#B3E5FC',
  md_light_blue_200: '#81D4fA',
  md_light_blue_300: '#4fC3F7',
  md_light_blue_400: '#29B6FC',
  md_light_blue_500: '#03A9F4',
  md_light_blue_600: '#039BE5',
  md_light_blue_700: '#0288D1',
  md_light_blue_800: '#0277BD',
  md_light_blue_900: '#01579B',
  md_light_blue_A100: '#80D8FF',
  md_light_blue_A200: '#40C4FF',
  md_light_blue_A400: '#00B0FF',
  md_light_blue_A700: '#0091EA',

  // <!-- cyan -->
  md_cyan_50: '#E0F7FA',
  md_cyan_100: '#B2EBF2',
  md_cyan_200: '#80DEEA',
  md_cyan_300: '#4DD0E1',
  md_cyan_400: '#26C6DA',
  md_cyan_500: '#00BCD4',
  md_cyan_600: '#00ACC1',
  md_cyan_700: '#0097A7',
  md_cyan_800: '#00838F',
  md_cyan_900: '#006064',
  md_cyan_A100: '#84FFFF',
  md_cyan_A200: '#18FFFF',
  md_cyan_A400: '#00E5FF',
  md_cyan_A700: '#00B8D4',

  // <!-- teal -->
  md_teal_50: '#E0F2F1',
  md_teal_100: '#B2DFDB',
  md_teal_200: '#80CBC4',
  md_teal_300: '#4DB6AC',
  md_teal_400: '#26A69A',
  md_teal_500: '#009688',
  md_teal_600: '#00897B',
  md_teal_700: '#00796B',
  md_teal_800: '#00695C',
  md_teal_900: '#004D40',
  md_teal_A100: '#A7FFEB',
  md_teal_A200: '#64FFDA',
  md_teal_A400: '#1DE9B6',
  md_teal_A700: '#00BFA5',

  // <!-- green -->
  md_green_50: '#E8F5E9',
  md_green_100: '#C8E6C9',
  md_green_200: '#A5D6A7',
  md_green_300: '#81C784',
  md_green_400: '#66BB6A',
  md_green_500: '#4CAF50',
  md_green_600: '#43A047',
  md_green_700: '#388E3C',
  md_green_800: '#2E7D32',
  md_green_900: '#1B5E20',
  md_green_A100: '#B9F6CA',
  md_green_A200: '#69F0AE',
  md_green_A400: '#00E676',
  md_green_A700: '#00C853',

  // <!--light green-->
  md_light_green_50: '#F1F8E9',
  md_light_green_100: '#DCEDC8',
  md_light_green_200: '#C5E1A5',
  md_light_green_300: '#AED581',
  md_light_green_400: '#9CCC65',
  md_light_green_500: '#8BC34A',
  md_light_green_600: '#7CB342',
  md_light_green_700: '#689F38',
  md_light_green_800: '#558B2F',
  md_light_green_900: '#33691E',
  md_light_green_A100: '#CCFF90',
  md_light_green_A200: '#B2FF59',
  md_light_green_A400: '#76FF03',
  md_light_green_A700: '#64DD17',

  // <!-- lime-->
  md_lime_50: '#F9FBE7',
  md_lime_100: '#F0F4C3',
  md_lime_200: '#E6EE9C',
  md_lime_300: '#DCE775',
  md_lime_400: '#D4E157',
  md_lime_500: '#CDDC39',
  md_lime_600: '#C0CA33',
  md_lime_700: '#A4B42B',
  md_lime_800: '#9E9D24',
  md_lime_900: '#827717',
  md_lime_A100: '#F4FF81',
  md_lime_A200: '#EEFF41',
  md_lime_A400: '#C6FF00',
  md_lime_A700: '#AEEA00',

  // <!--yellow -->
  md_yellow_50: '#FFFDE7',
  md_yellow_100: '#FFF9C4',
  md_yellow_200: '#FFF590',
  md_yellow_300: '#FFF176',
  md_yellow_400: '#FFEE58',
  md_yellow_500: '#FFEB3B',
  md_yellow_600: '#FDD835',
  md_yellow_700: '#FBC02D',
  md_yellow_800: '#F9A825',
  md_yellow_900: '#F57F17',
  md_yellow_A100: '#FFFF82',
  md_yellow_A200: '#FFFF00',
  md_yellow_A400: '#FFEA00',
  md_yellow_A700: '#FFD600',

  // <!--amber-->
  md_amber_50: '#FFF8E1',
  md_amber_100: '#FFECB3',
  md_amber_200: '#FFE082',
  md_amber_300: '#FFD54F',
  md_amber_400: '#FFCA28',
  md_amber_500: '#FFC107',
  md_amber_600: '#FFB300',
  md_amber_700: '#FFA000',
  md_amber_800: '#FF8F00',
  md_amber_900: '#FF6F00',
  md_amber_A100: '#FFE57F',
  md_amber_A200: '#FFD740',
  md_amber_A400: '#FFC400',
  md_amber_A700: '#FFAB00',

  // <!--orange-->
  md_orange_50: '#FFF3E0',
  md_orange_100: '#FFE0B2',
  md_orange_200: '#FFCC80',
  md_orange_300: '#FFB74D',
  md_orange_400: '#FFA726',
  md_orange_500: '#FF9800',
  md_orange_600: '#FB8C00',
  md_orange_700: '#F57C00',
  md_orange_800: '#EF6C00',
  md_orange_900: '#E65100',
  md_orange_A100: '#FFD180',
  md_orange_A200: '#FFAB40',
  md_orange_A400: '#FF9100',
  md_orange_A700: '#FF6D00',

  // <!--deep orange-->
  md_deep_orange_50: '#FBE9A7',
  md_deep_orange_100: '#FFCCBC',
  md_deep_orange_200: '#FFAB91',
  md_deep_orange_300: '#FF8A65',
  md_deep_orange_400: '#FF7043',
  md_deep_orange_500: '#FF5722',
  md_deep_orange_600: '#F4511E',
  md_deep_orange_700: '#E64A19',
  md_deep_orange_800: '#D84315',
  md_deep_orange_900: '#BF360C',
  md_deep_orange_A100: '#FF9E80',
  md_deep_orange_A200: '#FF6E40',
  md_deep_orange_A400: '#FF3D00',
  md_deep_orange_A700: '#DD2600',

  // <!--brown -->
  md_brown_50: '#EFEBE9',
  md_brown_100: '#D7CCC8',
  md_brown_200: '#BCAAA4',
  md_brown_300: '#A1887F',
  md_brown_400: '#8D6E63',
  md_brown_500: '#795548',
  md_brown_600: '#6D4C41',
  md_brown_700: '#5D4037',
  md_brown_800: '#4E342E',
  md_brown_900: '#3E2723',

  // <!--grey-->
  md_grey_50: '#FAFAFA',
  md_grey_100: '#F5F5F5',
  md_grey_200: '#eeeeee',
  md_grey_300: '#e0e0e0',
  md_grey_400: '#BDBDBD',
  md_grey_500: '#9E9E9E',
  md_grey_600: '#757575',
  md_grey_700: '#616161',
  md_grey_800: '#424242',
  md_grey_900: '#212121',
  md_black_1000: '#000000',
  md_white_1000: '#ffffff',

  // <!--blue grey-->
  md_blue_grey_50: '#ECEFF1',
  md_blue_grey_100: '#CFD8DC',
  md_blue_grey_200: '#B0BBC5',
  md_blue_grey_300: '#90A4AE',
  md_blue_grey_400: '#78909C',
  md_blue_grey_500: '#607D8B',
  md_blue_grey_600: '#546E7A',
  md_blue_grey_700: '#455A64',
  md_blue_grey_800: '#37474F',
  md_blue_grey_900: '#263238',
}
export default colors
