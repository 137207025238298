import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { withRouter, Route, Switch } from 'react-router'

import { toast } from 'react-toastify'
import * as _ from 'lodash'
import classnames from 'classnames'
import styled from 'styled-components'
import { Button, Row } from 'reactstrap'

import { extractCoworksErrorObject } from '@global/ErrorFactory'

import {
  stripNullString,
  stripPhotoObjects,
  logO,
  resolveDomainPrefix,
  difference,
} from '@global/Constants'

import { MenuButton } from '@global/Base/Button/ButtonStyled'
import ErrorFocus from '@global/Form/ErrorFocus'
import * as Roles from '@app/config/roles'
import Spinner from '@global/Spinner'
import { FETCHING_SETTINGS } from '@global/Constants/FetchingConstants'

import { validationSchema } from './SettingsFormConstants'
import SuperAdminSettings from './Sections/SuperAdminSettings'
import OnboardingSettings from './Sections/OnboardingSettings'
import ExternalSettings from './Sections/ExternalSettings'
import BillingSettings from './Sections/BillingSettings'
import BrandingSettings from './Sections/BrandingSettings'
import CalendarSettings from './Sections/CalendarSettings'
import MobileSettings from './Sections/MobileSettings'
import LegalSettings from './Sections/LegalSettings'

const MenuButtonStyled = styled(MenuButton)`
  margin-bottom: 0.5rem;
`

class CommunitySettings extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    activeCommunity: PropTypes.object,
    activeCampus: PropTypes.object,
    preference: PropTypes.object,
    community_actions: PropTypes.object,
    user: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
  }
  constructor(props) {
    super(props)
    const tabs = []
    const showBrandingSection =
      props.activeCommunity.community_preference.is_whitelabeled
    tabs.push({ name: 'Branding', path: `/community/settings/branding` })
    tabs.push({ name: 'Calendar', path: `/community/settings/calendar` })
    tabs.push({ name: 'Website Forms', path: `/community/settings/external` })
    tabs.push({ name: 'Mobile', path: `/community/settings/mobile` })
    tabs.push({ name: 'Onboarding', path: `/community/settings/onboarding` })
    tabs.push({ name: 'Legal', path: `/community/settings/legal` })
    if (props.activeCommunity.stripe_account_id) {
      tabs.push({ name: 'Billing', path: `/community/settings/billing` })
    }
    const showSuperAdmin =
      props.user.type === Roles.superAdminRole ||
      process.env.NODE_ENV === 'development' ||
      !process.env.NODE_ENV
    if (showSuperAdmin) {
      tabs.push({ name: 'SuperAdmin', path: `/community/settings/super` })
    }
    const activeCommunity = this.props.activeCommunity
    this.state = {
      disableOnboardingEmailButton: false,
      tabs,
      showBrandingSection,
      initialValues: activeCommunity.community_preference,
      showSuperAdmin,
    }
  }

  updatePreferences = formState => {
    let values = stripNullString(formState)
    values = stripPhotoObjects(values)
    const communityPreferenceObjectValues = difference(
      values,
      this.state.initialValues
    )
    const newCommunityPreferenceValues = {}
    // we're only interested in the differences
    Object.keys(communityPreferenceObjectValues).forEach(key => {
      if (communityPreferenceObjectValues[key] instanceof Object) {
        newCommunityPreferenceValues[key] = communityPreferenceObjectValues[key]
        delete communityPreferenceObjectValues[key]
      }
    })
    // This section works by assuming all nested objects are that of the new jsonb
    // preferences. This is because we couldn't nest.
    // Photos are stripped out by stripPhotoObjects so we don't need to worry about
    // not getting updates for those.. they send in a string instead of the whole photo
    // object.

    // preferences: param is the new jsonb
    // ...communityPreferenceObjectValues is the old community_preferences attributes
    this.props.community_actions
      .updateCommunityPreference(
        {
          preferences: JSON.stringify({
            ...newCommunityPreferenceValues,
          }),
          ...communityPreferenceObjectValues,
        },
        this.props.activeCommunity.id,
        FETCHING_SETTINGS
      )
      .then(response => {
        toast.success("Updated the community's preferences.")
      })
      .catch(error => {
        toast.error(extractCoworksErrorObject(error))
      })
  }
  sendCommunityOnboardingEmails = event => {
    event.preventDefault()
    this.setState({
      disableOnboardingEmailButton: true,
    })
    this.props.community_actions
      .sendCommunityOnboardingEmails(
        {},
        this.props.activeCommunity.id,
        FETCHING_SETTINGS
      )
      .then(response => {
        if (response && response.sent) {
          toast.success('Sent emails')
          this.setState({
            disableOnboardingEmailButton: false,
          })
        } else {
          toast.error('Failed to send emails.')
        }
      })
      .catch(err => {
        toast.error('Failed to send emails.')
      })
  }
  convertAllPendingMembers = event => {
    event.preventDefault()
    this.props.community_actions
      .convertAllPendingMembers(
        { campus_id: this.props.activeCampus.id },
        this.props.activeCommunity.id,
        FETCHING_SETTINGS
      )
      .then(response => {
        if (response && response.success) {
          toast.success(
            `Converted ${
              response.members && response.members.length
                ? response.members.length
                : ''
            } members`
          )
        } else {
          toast.error('Failed to convert')
        }
      })
      .catch(err => {
        toast.error('Failed to convert')
      })
  }
  toggleTab = (newTab, history) => {
    history.push(newTab)
  }
  onFormSubmit = (values, { setSubmitting }) => {
    this.updatePreferences(values)
    setSubmitting(false)
  }

  render() {
    const {
      activeCommunity,
      activeCampus,
      history,
      match,
      user,
      isFetching,
    } = this.props
    const role = user.type

    if (isFetching) return <Spinner />

    const { tabs, showBrandingSection } = this.state
    const preference = activeCommunity.community_preference

    let stripeLink = `https://dashboard.stripe.com/${
      activeCommunity.stripe_account_id
    }${
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? '/test'
        : ''
    }/dashboard`

    const platformCustomerLink = `https://dashboard.stripe.com${
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? '/test'
        : ''
    }/customers/${activeCommunity.coworks_customer_id}`

    if (!activeCommunity.stripe_account_id) {
      stripeLink = 'STRIPE_NOT_CONNECTED!'
    }

    const consoleObject = {
      platformCustomerLink,
      stripeLink,
    }
    const baseUrl = resolveDomainPrefix(preference)
    logO('object', consoleObject)
    return (
      <div className="animated fadeIn">
        <div className="col-md-12">
          <div className="row border-bottom-1 my-3">
            <div className="col-10">
              <h2>Community Settings</h2>
            </div>
          </div>
        </div>
        <Formik
          initialValues={this.state.initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onFormSubmit}
        >
          {formProps => {
            return (
              <Form>
                <Row>
                  <div className="col-md-2 col-12">
                    {tabs.map(tab => (
                      <Row>
                        <div className="col-12">
                          <MenuButtonStyled
                            showBackground
                            className={classnames({
                              active: location.pathname.includes(tab.path),
                            })}
                            onClick={() => {
                              this.toggleTab(tab.path, history)
                            }}
                          >
                            {tab.name}
                          </MenuButtonStyled>
                        </div>
                      </Row>
                    ))}
                    {/* </Nav> */}
                  </div>
                  <div className="col-md-10 col-12">
                    <Switch>
                      <Route exact path={`/community/settings/branding`}>
                        <BrandingSettings
                          {...this.props}
                          disabled={!showBrandingSection}
                          formProps={formProps}
                        />
                      </Route>
                      <Route exact path={`/community/settings/calendar`}>
                        <CalendarSettings formProps={formProps} />
                      </Route>

                      <Route exact path={`/community/settings/external`}>
                        <ExternalSettings
                          formProps={formProps}
                          role={role}
                          history={history}
                        />
                      </Route>
                      <Route exact path={`/community/settings/mobile`}>
                        <MobileSettings
                          {...formProps}
                          activeCommunity={activeCommunity}
                        />
                      </Route>
                      <Route exact path={`/community/settings/onboarding`}>
                        <OnboardingSettings
                          {...formProps}
                          activeCommunity={activeCommunity}
                        />
                      </Route>
                      <Route exact path={`/community/settings/legal`}>
                        <LegalSettings
                          {...formProps}
                          activeCommunity={activeCommunity}
                        />
                      </Route>

                      <Route exact path={`/community/settings/billing`}>
                        <BillingSettings
                          {...formProps}
                          role={role}
                          jsonString={formProps.values.automatic_invoice_fees}
                        />
                      </Route>

                      {this.state.showSuperAdmin && (
                        <Route exact path={`/community/settings/super`}>
                          <SuperAdminSettings
                            disableOnboardingEmailButton={Boolean(
                              this.state.disableOnboardingEmailButton
                            )}
                            sendCommunityOnboardingEmails={
                              this.sendCommunityOnboardingEmails
                            }
                            convertAllPendingMembers={
                              this.convertAllPendingMembers
                            }
                            stripeLink={stripeLink}
                            testPublicUserPreferencesLink={`${baseUrl}/preferences/${user.email}/${activeCommunity.id}/${user.client}/${user.authentication_token}`}
                            activeCommunity={activeCommunity}
                            setFieldValue={formProps.setFieldValue}
                            user={user}
                          />
                        </Route>
                      )}
                    </Switch>
                  </div>
                  <br />
                  <div className="col-12 d-flex align-items-center justify-content-end">
                    <Button
                      type="submit"
                      disabled={
                        !formProps.dirty ||
                        formProps.isSubmitting ||
                        this.state.isFetching
                      }
                      className="my-2 btn btn-primary btn-block"
                    >
                      Save
                    </Button>
                  </div>
                </Row>

                <ErrorFocus formik={formProps} />
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

CommunitySettings.displayName = 'CommunitySettings'
export default withRouter(CommunitySettings)
