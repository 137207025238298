import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

// import '../../styles/coworks/global/_placeholder.scss'

const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0px;

  .placeholder-button {
    margin-top: 20px;
  }

  .placeholder-title {
    color: lightgrey;
    text-align: center;
  }

  .placeholder-icon-wrapper {
    margin: 30px 0px;
    .placeholder-icon {
      font-size: 140px;
      color: lightgrey;
    }
  }

  .placeholder-subtext-wrapper {
    .placeholder-subtext {
      color: lightgrey;
      text-align: center;
      font-size: 13px;
      margin-bottom: 0px;
    }
  }
`
class Placeholder extends Component {
  static propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    subtext1: PropTypes.string,
    subtext2: PropTypes.string,
    subtext3: PropTypes.string,
    handleClick: PropTypes.func,
    buttonIcon: PropTypes.string,
    buttonText: PropTypes.string,
    color: PropTypes.string,
  }
  render() {
    const {
      color,
      title,
      icon,
      subtext1,
      subtext2,
      subtext3,
      buttonText,
      handleClick,
      buttonIcon,
    } = this.props
    return (
      <PlaceholderContainer>
        <h4 className="placeholder-title" style={{ color: color }}>
          {title}
        </h4>
        <div className="placeholder-icon-wrapper">
          <FontAwesomeIcon
            icon={icon}
            className="placeholder-icon"
            style={{ color: color }}
          />
        </div>
        <div className="placeholder-subtext-wrapper">
          {subtext1 && (
            <p className="placeholder-subtext" style={{ color: color }}>
              {subtext1}
            </p>
          )}
          {subtext2 && (
            <p className="placeholder-subtext" style={{ color: color }}>
              {subtext2}{' '}
              {buttonText && handleClick ? (
                <TextButton
                  className="text-white m-0 p-0 placeholder-button"
                  onClick={handleClick}
                >
                  <FontAwesomeIcon icon={buttonIcon} /> {buttonText}
                </TextButton>
              ) : null}
            </p>
          )}
          {subtext3 && (
            <p className="placeholder-subtext" style={{ color: color }}>
              {subtext3}
            </p>
          )}
        </div>
      </PlaceholderContainer>
    )
  }
}

Placeholder.defaultProps = {
  color: 'lightgrey',
}

Placeholder.displayName = 'Placeholder'

export default Placeholder
