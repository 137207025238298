import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'

import { useDispatch, useSelector } from 'react-redux'

import { createMember } from '@app/services/apiService'
import styled from 'styled-components'
import { captureException } from '@global/ErrorFactory'

import * as Yup from 'yup'
import * as Constants from '@global/Constants'
import Spinner from '@global/Spinner'
import { logO } from '@global/Constants'
import { connect } from 'react-redux'
import { getPrimaryColorFromCommunity } from '@global/Constants/BrandingConstants'
import { bindActionCreators, compose } from 'redux'

import { extractCoworksErrorObject } from '@global/ErrorFactory'
import PublicSignupContainer from '../PublicSignupContainer'
import moment from 'moment'
import * as _ from 'lodash'
import TermsOfServiceDescription from '../TermsOfServiceDescription'

const PublicFormContainer = styled.div`
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
`
function PlansSignup({
  history,
  match,
  theme,
  publicCommunity,
  publicCampus,
  coworks_community_actions,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const [isSuccessful, setIsSuccessful] = useState(null)
  const [plan, setPlan] = useState(null)
  const [email, setEmail] = useState(null)
  const [billingEnabled, setBillingEnabled] = useState(
    publicCommunity.stripe_account_id
  )
  const [error, setError] = useState(null)

  // const publicCommunity = useSelector(state => state.ui.publicCommunity)
  const isFetching = useSelector(state => state.ui.isFetching)
  const dispatch = useDispatch()

  const shouldRedirectSuccess = Boolean(
    publicCommunity.preferences.public_membership_signup_options &&
      publicCommunity.preferences.public_membership_signup_options
        .default_success_redirect_url
  )

  useEffect(() => {
    // change teh document title for the visible plan.
    if (plan) {
      document.title = `Signup for ${plan.name}`
    }
  }, [plan])

  useEffect(() => {
    // check if form is submitted and redirect if needed
    if (
      isSuccessful &&
      shouldRedirectSuccess &&
      publicCommunity &&
      publicCommunity.community_preference
    ) {
      const prefs = publicCommunity.community_preference
      window.location.assign(
        prefs.public_membership_signup_options.default_success_redirect_url
      )
    }
  }, [publicCommunity, isSuccessful, shouldRedirectSuccess])

  useEffect(() => {
    // fetch the public plan info on mount
    if (match && match.params && match.params.planId) {
      let subdomain = window.location.hostname
      subdomain = subdomain.substring(0, subdomain.indexOf('.'))
      const params = {
        subdomain,
      }
      params.plan_id = match.params.planId

      coworksCommunityActions
        .getPublicPlanHOOK(params, dispatch)
        .then(response => {
          setPlan(response.plan)
          const campusId = Number(match.params.planId)
          const campus = _.find(publicCommunity.campuses, ['id', campusId])
          coworks_community_actions.setPublicCampus(campus)
          if (Number(response.plan.cost) === 0) setBillingEnabled(false)
          setIsLoading(false)
        })
        .catch(err => {
          setIsLoading(false)
          logO('successful', err)
        })
    }
  }, [match, dispatch])

  function onSubmit(values) {
    const apiParams = {
      plans: JSON.stringify([{ id: plan.id, quantity: 1 }]),
      community_id: publicCommunity.id,
      team_name: values.teamName,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      send_onboarding_email: false,
      public_signup: true,
      type: 'Member',
    }

    if (publicCommunity.stripe_account_id) {
      apiParams.card_token = values.card_token
      apiParams.billing_type = 'charge_automatically'
      apiParams.days_until_due =
        publicCommunity.community_preference.days_until_invoice_due
    }

    createMember(apiParams)
      .then(response => {
        setHasSubmitted(true)
        setIsSuccessful(true)
        setError(null)
        setEmail(values.email)
      })
      .catch(err => {
        setHasSubmitted(true)
        setIsSuccessful(false)
        setError(extractCoworksErrorObject(err))
        captureException({ error: err })
        setEmail(values.email)
      })
  }
  const renderTermsOfServiceDescription = cost => {
    return (
      <TermsOfServiceDescription
        campusName={publicCampus && publicCampus.name}
        communityTerms={
          publicCommunity.preferences &&
          publicCommunity.preferences.legal &&
          publicCommunity.preferences.legal.terms_of_service_url
        }
        cost={cost}
        frequency={plan && plan.frequency}
        billingEnabled={billingEnabled}
      />
    )
  }

  function renderPurchaseCondition() {
    let purchaseCondition = ''
    if (billingEnabled) {
      purchaseCondition = `Your first invoice will be sent today for prorated amount and then `
      if (plan.frequency === 'month') {
        purchaseCondition += `monthly on the ${Constants.getBillDateNameFromValue(
          Constants.billDates,
          publicCommunity.community_preference.default_billing_cycle_day
        )}.`
      }
      if (plan.frequency === 'year') {
        purchaseCondition += `yearly on ${moment().format('MMMM D')}.`
      }
    }
    return purchaseCondition
  }

  if (isLoading || !publicCommunity) {
    return <Spinner />
  }
  if (!isLoading && !plan) {
    captureException({
      text: `Looks like someone had trouble accessing the public member signup at url ${window.location}`,
    })
    return (
      <div>
        Unable to load the form. Please contact Coworks support at
        support@coworksapp.com! We'll get you fixed up.
      </div>
    )
  }
  const primaryColor = getPrimaryColorFromCommunity(publicCommunity)

  const selectedCampuses = plan
    ? publicCommunity.campuses.filter(campus => campus.id === plan.campus_id)
    : []
  let campusPhoto = null
  if (selectedCampuses.length > 0) {
    const campus = selectedCampuses[0]
    if (campus && campus.campus_photo) {
      campusPhoto = campus.campus_photo.small_file_url
    }
  }

  const details = () => [
    {
      title: 'Item',
      text: plan && plan.name,
    },
  ]

  return (
    <PublicSignupContainer
      featureType={'memberBilling'}
      content={{
        photo: campusPhoto,
        name: plan.name,
        cost: plan.cost,
        description: plan.description,
        frequency: plan.frequency,
      }}
      onSubmit={onSubmit}
      goBack={() => history.goBack()}
      isSuccessful={isSuccessful}
      hasSubmitted={hasSubmitted}
      primaryColor={primaryColor}
      history={history}
      campus={selectedCampuses[0]}
      shouldRedirectSuccess={shouldRedirectSuccess}
      error={error}
      theme={theme}
      detailData={details()}
      confirmationMessage={`
        We've sent a confirmation to ${email} and someone from
        ${publicCommunity.name} will reach out to you soon.
      `}
      termsDescription={cost => renderTermsOfServiceDescription(cost)}
      purchaseCondition={renderPurchaseCondition()}
      publicCommunity={publicCommunity}
      formProps={{
        extraValidation: {
          teamName: Yup.string().required('Team name is required'),
        },
        extraInitialValues: {
          teamName: '',
        },
      }}
    />
  )
}
PlansSignup.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  isFetching: PropTypes.bool,
  coworks_community_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  theme: PropTypes.object,
  publicCommunity: PropTypes.object.isRequired,
  publicCampus: PropTypes.object.isRequired,
}

PlansSignup.displayName = 'PlansSignup'

const mapDispatchToProps = dispatch => ({
  coworks_community_actions: bindActionCreators(
    coworksCommunityActions,
    dispatch
  ),
})

export default compose(connect(null, mapDispatchToProps))(PlansSignup)
