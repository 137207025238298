import { createSelector } from 'reselect'

const NAMESPACE = 'plans'
const rootSelector = state => state[NAMESPACE]

// get initial state object
export const getRoot = createSelector([rootSelector], root => root)

export const getPlansList = createSelector([getRoot], root => {
  return Array.isArray(root) ? root : []
})

export const getStandardPlansList = createSelector([getRoot], root => {
  return Array.isArray(root) ? root.filter(plan => plan.type !== 'DayPass') : []
})

export const getDayPass = createSelector([getPlansList], root => {
  let dayPass = null
  if (Array.isArray(root)) {
    for (let i = 0; i < root.length; i++) {
      const element = root[i];
      if (element.type === 'DayPass') {
        dayPass = element
        break
      }
    }
  }
  return dayPass
})