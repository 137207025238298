/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Table from '@global/Table'
import * as Constants from '@global/Constants'
import { LeadsTabInfo, LEAD, PROSPECT } from '@global/Constants/CRMConstants'
import { LeadCol, CampusCol, TouredCol, PromoteCol } from './Columns'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import moment from 'moment'
import { default as TopBar } from './FiltersCollection'
import * as leadActions from '../../../reduxActions/leadActions'
import * as uiActions from '../../../reduxActions/uiActions'
import {
  resolveFetchingStatus,
  FETCHING_LEADS,
} from '@global/Constants/FetchingConstants'
import * as _ from 'lodash'

const Wrapper = styled.div``

const SLUG_ENUM = {
  NAME: 'first_name',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  MARK_LEAD: 'marked_lead_on',
  CAMPUS: 'campus',
  TOURED: 'toured',
  TYPE: 'type',
}

const rowStyle = {
  borderBottom: '1px #f2f2f2 solid',
  height: '75px',
}

const headerStyle = {
  borderBottom: '2px solid #cfd8dc',
  borderTop: '1px solid #cfd8dc',
  height: '48px',
}

const contactTypes = [
  {
    label: 'Lead',
    value: 'Lead',
  },
  {
    label: 'Visitor',
    value: 'Visitor',
  },
  {
    label: 'PendingMember',
    value: 'PendingMember',
  },
]

const tempTypes = [
  {
    label: 'Hot',
    value: 'Hot',
  },
  {
    label: 'Warm',
    value: 'Warm',
  },
  {
    label: 'Cold',
    value: 'Cold',
  },
]

const createdByTypes = [
  {
    label: 'Dashboard',
    value: 'Dashboard',
  },
  {
    label: 'Frontdesk Tour Form',
    value: 'FrontDesk',
  },
  {
    label: 'Invoice',
    value: 'Invoice',
  },
  {
    label: 'Day Pass',
    value: 'DayPass',
  },
  {
    label: 'Membership Form',
    value: 'WebsiteForm',
  },
  {
    label: 'Tour Form',
    value: 'WebsiteTourForm',
  },
  {
    label: 'External Booking',
    value: 'ExternalBooking',
  },
  {
    label: 'Visitor Checkin',
    value: 'VisitorCheckin',
  },
  {
    label: 'Zapier',
    value: 'Zapier',
  },
]

const sortByOptions = [
  { label: 'Creation Date', value: { slug: SLUG_ENUM.CREATED_AT, asc: false } },
  { label: 'Created By', value: { slug: SLUG_ENUM.CREATED_BY, asc: true } },
  { label: 'Name', value: { slug: SLUG_ENUM.NAME, asc: true } },
  { label: 'Email', value: { slug: 'email', asc: true } },
]

const archiveOptions = [
  { label: 'active', value: 'active' },
  { label: 'inactive', value: 'inactive' },
]

const createCampusOptions = arr => {
  return arr.map(campus => ({
    label: campus.name,
    value: campus.id,
  }))
}

const LeadsTable = ({
  onLeadClick,
  onEditClicked,
  data,
  dataPagination,
  onPromoteToExistingTeamClicked,
  onPromoteToNewTeamClicked,
  onPromoteToLead,
  isFetching,
  activeCampus,
  activeCommunity,
  ui_actions,
  onQueryChange,
  type,
  lead_actions,
  uiFilters,
  theme,
  archivedDefault,
  ...other
}) => {
  const {
    _createdBy,
    _campusSelected,
    _activeStatus,
    _sortBy,
    _archived,
  } = uiFilters

  const compileOptions = () => {
    if (type === LeadsTabInfo[0].slug) {
      const newArray = sortByOptions.slice(1)
      return [
        {
          label: 'Marked Lead',
          value: { slug: SLUG_ENUM.MARK_LEAD, asc: false },
        },
        ...newArray,
      ]
    }
    return sortByOptions
  }
  const getOptionsFromValues = (values, options) => {
    if (!values) return null
    const filtered = values.filter(val => Boolean(val))
    return filtered && filtered.length > 0
      ? filtered.map(value => _.find(options, ['value', value]))
      : null
  }
  const campusOptions = createCampusOptions(activeCommunity.campuses)

  const [openIndex, setOpen] = useState(null)
  const [page, setPage] = useState(Constants.START_PAGE_DEFAULT)
  const [perPage, setPerPage] = useState(Constants.PER_PAGE_DEFAULT)
  const [nextTriggered, setTrigger] = useState(false)
  const [contactType, setContactTypeFilter] = useState(null)
  const [activeStatus, setFilteredStatus] = useState(_activeStatus)
  const [sortBy, setSortBy] = useState(compileOptions()[0].value)
  const [archived, setArchived] = useState(_archived ? _archived : 'active')
  const [createdBy, setCreatedByFilter] = useState(_createdBy)
  const [campusSelected, setCampusFilter] = useState(
    getOptionsFromValues(_campusSelected, campusOptions)
  )
  const prevPageRef = useRef()

  useEffect(() => {
    ui_actions.getGroups(activeCampus.id)
  }, [activeCampus.id, ui_actions])

  useEffect(() => {
    if (!isFetching) {
      const prevPage = prevPageRef.current ? prevPageRef.current : 0
      if (page > prevPage) {
        setTrigger(false)
        loadNextObjects(page, perPage, true)
      } else {
        loadNextObjects(Constants.START_PAGE_DEFAULT, perPage)
      }
      prevPageRef.current = page
    }
    ui_actions.getGroups(activeCampus.id)
  }, [
    createdBy,
    campusSelected,
    contactType,
    page,
    sortBy,
    archived,
    activeStatus,
  ])

  const refresh = () => {
    setPage(Constants.START_PAGE_DEFAULT)
  }
  const getOrderString = () => {
    const str = `${sortBy.slug}.${sortBy.asc ? 'ASC' : 'DESC'}`
    return JSON.stringify([str])
  }

  const getEndParams = () => {
    const isLead = type === LeadsTabInfo[0].slug
    // const contactType = contactType ? contactType.value : null
    const tempFilter =
      isLead && activeStatus && activeStatus.length > 0
        ? JSON.stringify(activeStatus.map(item => item.value))
        : null
    const createdByIds =
      createdBy && createdBy.length > 0
        ? JSON.stringify(createdBy.map(item => item.value))
        : null
    const campusIds =
      campusSelected && campusSelected.length > 0
        ? JSON.stringify(campusSelected.map(elm => elm.value))
        : JSON.stringify(campusOptions.map(elm => elm.value))
    const isType = type === LeadsTabInfo[0].slug ? LEAD : null
    return {
      campus_ids: campusIds,
      is_lead: isLead,
      created_by_types: createdByIds,
      status: archived,
      temperature: tempFilter,
      type: isType,
      order_by: sortBy ? getOrderString() : null,
    }
  }

  const loadNextObjects = (page, perPage, nextPage) => {
    const isLead = type === LeadsTabInfo[0].slug
    const paramRange = getEndParams()
    lead_actions.getCommunityContacts(
      {
        community_id: activeCommunity.id,
        page: page,
        per_page: perPage,
        ...paramRange,
      },
      nextPage,
      isLead ? LEAD : PROSPECT
    )
  }

  const nextPage = () => {
    if (isFetching || nextTriggered) return
    if (
      (dataPagination &&
        data &&
        data.length < dataPagination.total_objects &&
        page < dataPagination.total_pages) ||
      !data
    ) {
      const newPage = page + 1
      setTrigger(true)
      setPage(newPage)
    }
  }

  const setFilters = () => {
    const filters = {
      _archived: archived,
      _sortBy: sortBy,
      _campusSelected: campusSelected.value,
      _createdBy: createdBy,
      _activeStatus: activeStatus,
    }
    ui_actions.setFilters('leads', filters)
  }

  const toggleDropdown = index => {
    let newIndex = index
    if (openIndex === index) {
      newIndex = null
    }
    setOpen(newIndex)
  }

  const updateCampus = val => {
    setCampusFilter(val)
    const IdArrs = val ? val.map(campus => campus.value) : null
    ui_actions.updateFilters('leads', {
      _campusSelected: IdArrs,
    })
    refresh()
  }
  const updateContactType = val => {
    setContactTypeFilter(val)
    refresh()
  }
  const updateArchived = val => {
    setArchived(val)
    ui_actions.updateFilters('leads', {
      _archived: val,
    })
    refresh()
  }
  const updateSortBy = (val, asc) => {
    let sort = val
    if (asc !== undefined) sort = { slug: val, asc }
    setSortBy(sort)
    refresh()
  }
  const updateCreatedBy = val => {
    setCreatedByFilter(val)
    const createdBy = val ? val : null
    ui_actions.updateFilters('leads', {
      _createdBy: createdBy,
    })
    refresh()
  }
  const updateFilteredStatus = val => {
    setFilteredStatus(val)
    ui_actions.updateFilters('leads', {
      _activeStatus: val,
    })
    refresh()
  }
  const getColumnSort = slug => {
    if (sortBy.slug === slug) {
      return sortBy.asc ? 'asc' : 'desc'
    }
    return null
  }
  const promoteToLeadAction = lead => {
    const data = {
      id: lead.id,
      campus_id: lead.campus_id,
      is_lead: true,
    }
    onPromoteToLead(data)
  }
  const columns = sortBy => [
    {
      name: 'Name',
      slug: SLUG_ENUM.NAME,
      style: {
        padding: '15px 10px',
        width: '350px',
      },
      options: {
        sort: getColumnSort(SLUG_ENUM.NAME),
        customRender: data => (
          <LeadCol
            lead={data}
            onClick={() => (data ? onLeadClick(data) : null)}
          />
        ),
      },
    },
    {
      name: 'Marked lead on',
      slug: SLUG_ENUM.MARK_LEAD,
      options: {
        display: type === LeadsTabInfo[0].slug,
        sort: getColumnSort(SLUG_ENUM.MARK_LEAD),
        customRender: data =>
          data.marked_lead_on
            ? moment(data.marked_lead_on).format('MMM DD, YY hh:mm a')
            : '',
      },
    },
    {
      name: 'Created date',
      slug: SLUG_ENUM.CREATED_AT,
      options: {
        display: type !== LeadsTabInfo[0].slug,
        sort: getColumnSort(SLUG_ENUM.CREATED_AT),
        customRender: data =>
          data.created_at
            ? moment(data.created_at).format('MMM DD, YY hh:mm a')
            : '',
      },
    },
    {
      name: 'Created by',
      slug: SLUG_ENUM.CREATED_BY,
      options: {
        sort: getColumnSort(SLUG_ENUM.CREATED_BY),
        customRender: data => {
          const createdBy = data.created_by
          const elm = _.find(createdByTypes, ['value', createdBy])
          if (elm || !createdBy)
            return Constants.getGeneratedFromBadge(createdBy)
          return createdBy
        },
      },
    },
    {
      name: 'Type',
      slug: SLUG_ENUM.TYPE,
      accessor: 'type',
      options: {
        display: false,
      },
    },
    {
      name: 'Campus',
      slug: SLUG_ENUM.CAMPUS,
      options: {
        sortable: false,
        customRender: data => (
          <CampusCol
            // eslint-disable-next-line react/prop-types
            campusId={data.campus_id}
            // eslint-disable-next-line react/prop-types
            campuses={activeCommunity.campuses}
          />
        ),
      },
    },
    {
      name: 'Toured',
      slug: SLUG_ENUM.TOURED,
      style: {
        width: '120px',
      },
      options: {
        display: false,
        sortable: false,
        // eslint-disable-next-line react/prop-types
        customRender: data => <TouredCol toured={data.toured} />,
      },
    },
    {
      name: '',
      style: {
        width: '150px',
      },
      options: {
        sortable: false,
        customRender: (data, index) => {
          return (
            <PromoteCol
              isOpen={openIndex === index}
              toggleDropdown={() => toggleDropdown(index)}
              onEditClicked={() => onEditClicked(data)}
              onPromoteToExistingTeamClicked={() =>
                onPromoteToExistingTeamClicked(data)
              }
              onPromoteToNewTeamClicked={() => onPromoteToNewTeamClicked(data)}
              onPromoteToLead={() => promoteToLeadAction(data)}
              isLead={type === LeadsTabInfo[0].slug}
            />
          )
        },
      },
    },
  ]

  const getSelects = () => [
    {
      props: {
        onChange: updateArchived,
        value: archived,
        label: 'State',
        options: archiveOptions,
      },
    },
    {
      props: {
        onChange: updateSortBy,
        value: sortBy,
        label: 'Sort By',
        options: compileOptions(),
      },
    },
  ]
  const getInputs = () => [
    {
      label: 'Campus',
      props: {
        isMulti: true,
        isClearable: true,
        placeholder: 'All Campuses',
        onChange: updateCampus,
        value: campusSelected,
        options: campusOptions,
        isValidNewOption: () => false,
      },
    },
    {
      hide: true,
      label: 'Type',
      props: {
        isClearable: true,
        placeholder: 'select',
        onChange: updateContactType,
        value: contactType,
        options: contactTypes,
        isValidNewOption: () => false,
      },
    },
    {
      hide: type !== LeadsTabInfo[0].slug,
      label: 'Temperature',
      props: {
        isMulti: true,
        isClearable: true,
        placeholder: 'Select',
        onChange: updateFilteredStatus,
        value: activeStatus,
        options: tempTypes,
        isValidNewOption: () => false,
      },
    },
    {
      label: 'Created By',
      props: {
        isMulti: true,
        isClearable: true,
        placeholder: 'Select',
        onChange: updateCreatedBy,
        value: createdBy,
        options: createdByTypes,
        isValidNewOption: () => false,
      },
    },
  ]
  const getPlaceholder = () => {
    const hasSearched = createdBy || activeStatus || archived === 'inactive'
    const isLead = type === LeadsTabInfo[0].slug
    return {
      title: `No ${type}`,
      sub1: !hasSearched
        ? null
        : `No ${type} could be found for this search criteria`,
      sub2: !hasSearched ? `Add a new one` : null,
      button: isLead ? 'new lead' : 'new prospect',
    }
  }
  const placeholderCopy = getPlaceholder()
  return (
    <Wrapper>
      <TopBar
        selects={getSelects()}
        inputs={getInputs()}
        createdBy={createdBy}
        campusSelected={campusSelected}
        activeStatus={activeStatus}
        theme={theme}
        type={type}
      />
      <Table
        columns={columns(sortBy)}
        theme={theme}
        nextPage={nextPage}
        onSort={updateSortBy}
        headerStyle={headerStyle}
        rowStyle={rowStyle}
        isLoading={isFetching}
        totalPages={dataPagination && dataPagination.total_pages}
        objects={data}
        type={type}
        placeholderTitle={placeholderCopy.title}
        placeholderSubtext1={placeholderCopy.sub1}
        placeholderSubtext2={placeholderCopy.sub2}
        placeholderButtonText={placeholderCopy.button}
        {...other}
      />
    </Wrapper>
  )
}

LeadsTable.propTypes = {
  campuses: PropTypes.array,
  onLeadClick: PropTypes.func,
  onEditClicked: PropTypes.func,
  data: PropTypes.array,
  onPromoteToLead: PropTypes.func,
  onPromoteToExistingTeamClicked: PropTypes.func,
  onPromoteToNewTeamClicked: PropTypes.func,
  theme: PropTypes.object,
  history: PropTypes.object,
  lead_actions: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  type: PropTypes.string,
  isFetching: PropTypes.bool,
  onQueryChange: PropTypes.func,
  dataPagination: PropTypes.object,
  ui_actions: PropTypes.object,
  uiFilters: PropTypes.object,
  archivedDefault: PropTypes.string,
}

LeadsTable.displayName = 'Leads Table'

function mapStateToProps(state, props) {
  const type = props.type === LeadsTabInfo[0].slug ? LEAD : PROSPECT
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetching: resolveFetchingStatus(state, leadActions.getFetchByType(type)),
    uiFilters: Constants.resolveUIFilters(state, 'leads'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    lead_actions: bindActionCreators(leadActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsTable)
