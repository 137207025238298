/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { breakpoints } from '@global/Constants/BreakpointConstants'
import {
  FETCHING_ROOMS,
  resolveFetchingStatus,
} from '@global/Constants/FetchingConstants'
import {
  createInvoiceFeesArray,
  getCustomerDefaultSource,
  shouldDisableChargeNow,
  getIntendedPaymentSource,
  SEND_INVOICE,
} from '@global/Constants/PaymentConstants'
import { resolvePreferenceFromCommunity } from '@global/Utilities/ResolveConstants'
import { extractCoworksErrorObject } from '@global/ErrorFactory'
import Spinner from '@global/Spinner'

import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import * as externalBookingActions from '@reduxActions/externalBookingsActions'
import styled, { css } from 'styled-components'
import { ThemeProvider, withTheme } from 'styled-components'
import colors from '@global/Colors/colors'
import { Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { LabelStyled } from '@global/Form/FormComponents'
import TotalTextRows from '../TotalTextRows'
import GenericModal from '@global/GenericModal'

import {
  resolveColor,
  resolveHoverColor,
  resolveButtonTextColor,
  resolveBorderColor,
} from '@global/Base/baseComponentColorUtils'

import { withFormik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import CreditCardForm from '@global/Stripe/CreditCardFormV2'

import {
  CustomInputComponent,
  CustomTextAreaComponent,
} from '@global/Form/FormComponentsV2'

import {
  ElementsConsumer,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js'
import * as Constants from '@global/Constants'
import Checkbox from '@bit/coworks.base-components.checkbox'
import * as _ from 'lodash'
import { bindActionCreators, compose } from 'redux'

import Button, {
  OutlineButton,
  IconButton,
} from '@bit/coworks.base-components.button'

import moment from 'moment'
import { SET_ACTIVE_CAMPUS } from '../../../../../reduxActions/actionTypes'
import CheckoutForm from './CheckoutForm'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-flow: ${props => (props.mobile ? 'column' : 'row')} nowrap;
  position: relative;
`
const GenericModalStyled = styled(GenericModal)`
  height: 100vh;
  max-width: none;

  .modal-content {
    border: none !important;
    width: 100%;
  }
  @media (min-width: ${breakpoints.tablet + 1}px) {
    display: block;
    height: auto;
    width: 95%;
    max-width: 1100px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    margin: 0 !important;
    .web-ft {
      display: none;
    }
  }
`
const CheckoutFormStyled = styled(CheckoutForm)``
const FormContainer = styled.form`
  display: flex;
  flex-flow: column wrap;
  padding: 12px 24px;
`
const IconButtonStyled = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
`
const OutlineButtonStyled = styled(OutlineButton)``
const ButtonStyled = styled(Button)`
  position: relative;

  .loading-spinner-contextual {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    opacity: 0.5;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  padding: 12px 24px;
  flex-flow: row nowrap;
  justify-content: flex-end;
`
const FormWrapper = styled.div`
  margin-bottom: 16px;
`
const Title = styled.h5`
  display: flex;
  padding: 12px 24px 0;
  margin: 0;
  font-weight: bold;
`
const Subtitle = styled.h5`
  display: flex;
  font-size: 18px;
`
const InputContainer = styled.div`
  flex: 1;
`
const CostContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  background: #eee;
  min-width: 40%;
`
const BookingDetails = styled.div`
  flex: 2;
  padding: 12px 24px;
`
const Summary = styled.div`
  flex: 1;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 12px 24px;
`
const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`
const CloseIcon = styled.div`
  font-size: 24px;
`
const TotalTextRowsStyled = styled(TotalTextRows)``
const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
`
const OverlayHeader = styled.h5`
  font-weight: bold;
`
const TextAreaStyled = styled.textarea`
  height: 150px;
`

const BookingPaymentModal = ({
  isOpen,
  toggle,
  onSuccess,
  room,
  fullscreen,
  onCancel,
  isValid,
  bookingDetails,
  billingEnabled,
  validateForm,
  onGoBackAction,
  external_booking_actions,
  currentRoom,
  currentCampus,
  activeCommunity,
}) => {
  const checkoutRef = useRef(null)
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const brandColor = activeCommunity.community_preference.branding_primary_color

  const currencySymbol = Constants.getCurrencySymbolFromCommunity(
    activeCommunity
  )

  const calculateTotal = () => {
    let startTime,
      endTime,
      duration = null
    if (bookingDetails) {
      const { start, end } = bookingDetails.timeRange
      const date = bookingDetails.date
      startTime = moment(Constants.concatenateDateTime(date.format('l'), start))
      endTime = moment(Constants.concatenateDateTime(date.format('l'), end))
      duration = endTime.diff(startTime, 'minutes')
    }

    let subtotal = duration ? (duration / 60) * room.external_hourly_rate : 0

    const feesArray = createInvoiceFeesArray({
      amountInDollars: subtotal,
      prefs: resolvePreferenceFromCommunity(activeCommunity),
      returnEmpty: false,
      customer: null,
      intendedPaymentSource: 'card',
      feature: 'externalBookings',
    })

    const costSum = []
    costSum.push({
      label: 'Subtotal',
      value: `${currencySymbol}${subtotal.toFixed(2)}`,
      style: { fontSize: 14, fontWeight: 'bold' },
    })
    if (feesArray && feesArray.length > 0) {
      feesArray.forEach(fee => {
        subtotal += fee.amount / 100
        costSum.push({
          label: fee.description,
          value: `${currencySymbol}${(fee.amount / 100).toFixed(2)}`,
          style: { fontSize: 12 },
        })
      })
    }
    costSum.push({
      label: 'Total',
      value: `${currencySymbol}${subtotal.toFixed(2)}`,
      style: { fontSize: 18, fontWeight: 'bold' },
    })
    return costSum
  }

  const createBooking = (values, formActions) => {
    const { date, timeRange } = bookingDetails
    const info = { ...values }
    const start_time = Constants.concatenateDateTime(
      date.format('l'),
      timeRange.start
    )
    const end_time = Constants.concatenateDateTime(
      date.format('l'),
      timeRange.end
    )
    delete info.policy
    delete info.creditCard
    setSubmitError(null)

    return external_booking_actions
      .createBooking({
        end_time,
        start_time,
        room_id: currentRoom.id,
        campus_id: currentCampus.id,
        community_id: activeCommunity.id,
        notes: info.description,
        ...info,
      })
      .then(() => {
        onSuccess()
        // todo remove this?
        formActions.setSubmitting(false)
        // checkoutRef.current.submitForm()
      })
      .catch(err => {
        setSubmitError(extractCoworksErrorObject(err))
        formActions.setSubmitting(false)
        // todo remove this and render erorrs itself
        // checkoutRef.current.setFieldError(
        //   'form',
        //   extractCoworksErrorObject(err)
        // )
      })
  }

  const details = () => {
    let startTime,
      endTime,
      duration = null
    if (bookingDetails) {
      const { date, timeRange } = bookingDetails
      const { start, end } = timeRange
      startTime = moment(Constants.concatenateDateTime(date.format('l'), start))
      endTime = moment(Constants.concatenateDateTime(date.format('l'), end))
      duration = endTime.diff(startTime, 'minutes') / 60
    }

    const arr = [
      {
        title: 'Room',
        text: room && `${room.name}`,
      },
      {
        title: 'Start Date',
        text: startTime && `${startTime.format('LLLL')}`,
      },
      {
        title: 'End Date',
        text: endTime && `${endTime.format('LLLL')}`,
      },
      {
        title: 'Duration',
        text: duration && `${duration} Hour(s)`,
      },
    ]
    return arr
  }

  // const submit = () => checkoutRef.current.submit()
  // const cancel = () => checkoutRef.current.cancel()

  const body2 = () => {
    return (
      <CheckoutFormStyled
        mobile={fullscreen}
        brandColor={brandColor}
        community={activeCommunity}
        campus={currentCampus}
        onGoBackAction={onGoBackAction}
        submitError={submitError}
        // loading={loading}
        showCheckbox={currentCampus && room}
        totalData={calculateTotal()}
        detailData={details()}
        formRef={checkoutRef}
        sendFormToApi={createBooking}
        // setLoading={setLoading}
        setValid={setValid}
        billingEnabled={billingEnabled}
        onCancel={onCancel}
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          company_name: '',
          description: '',
          policy: false,
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required('First Name is required.'),
          last_name: Yup.string().required('Last Name is required.'),
          email: Yup.string().required('Email is required.'),
          phone: Yup.string().required('Phone Number is required.'),
          company_name: Yup.string().required('Company is required.'),
          description: Yup.string(),
          policy: Yup.bool().oneOf(
            [true],
            'You must accept the terms before continuing.'
          ),
        })}
      />
    )
  }

  return (
    <Container>
      <GenericModalStyled
        mobile
        header={
          <HeaderWrapper>
            <OverlayHeader>Guest Information</OverlayHeader>
          </HeaderWrapper>
        }
        body={body2()}
        isOpen={isOpen}
        toggle={toggle}
        onClose={onCancel}
        noPadding
        hideHeaderToggle
        hideFooter
        noHeaderPadding
        backdrop="static"
      />
    </Container>
  )
}

BookingPaymentModal.propTypes = {
  isOpen: PropTypes.bool,
  fullscreen: PropTypes.bool,
  isValid: PropTypes.bool,
  toggle: PropTypes.func,
  onSuccess: PropTypes.func,
  billingEnabled: PropTypes.string,
  submitForm: PropTypes.func,
  validateForm: PropTypes.func,
  onGoBackAction: PropTypes.func,
  external_booking_actions: PropTypes.func,
  resetForm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  bookingDetails: PropTypes.object,
  currentRoom: PropTypes.object,
  currentCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  room: PropTypes.object,
}

BookingPaymentModal.displayName = 'Confirm Booking Modal'

function mapDispatchToProps(dispatch) {
  return {
    external_booking_actions: bindActionCreators(
      externalBookingActions,
      dispatch
    ),
  }
}

export default compose(connect(null, mapDispatchToProps))(BookingPaymentModal)
