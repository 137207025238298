import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Intercom, { IntercomAPI } from 'react-intercom'
import { withTheme } from 'styled-components'

import ReactGA from 'react-ga'
import { mixpanel } from '../config/initializers'
import { Detector, Offline, Online } from 'react-detect-offline'
import { Alert } from 'reactstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
const momentTZ = require('moment-timezone')
import { isAfter } from 'date-fns'
import styled from 'styled-components'
import '../styles/react-sortable-overrides.css'

import * as uiActions from '@reduxActions/uiActions'
import * as communityActions from '@reduxActions/communityActions'
import * as userActions from '@reduxActions/userActions'
import * as roomActions from '@reduxActions/roomActions'
import * as stripeActions from '@reduxActions/stripeActions'
import * as planActions from '@reduxActions/planActions'
import * as integrationsActions from '@reduxActions/integrationActions'

import { QueryStringToJSON } from '@global/Utilities/routeUtilities'

import Header from '@global/Header/Header'
import Sidebar from '@global/Sidebar/Sidebar'
import Breadcrumb from '@global/Breadcrumb/Breadcrumb'
import Routes from './MainContainerRoutes'
import Spinner from '@global/Spinner'
import ErrorBoundary from '@global/ErrorBoundry'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import {
  resolveFetchingStatus,
  FETCHING_COWORKS_CUSTOMER_NAME,
  FETCHING_DOOR_ACCESS_GROUPS,
} from '@global/Constants/FetchingConstants'
import '../config/fontawesome'
import { captureException } from '@global/ErrorFactory'
import * as Roles from '../config/roles'
import { checkDoorProvider } from '@app/components/Community/Integrations/IntegrationConstants'

import { INTERCOM_ID } from '../config/axios'
import { trackUser } from '../config/analytics'
import { toTitleCase } from './Global/Constants'
import AlertsContainer from './AlertsContainer'

class MainContainer extends React.PureComponent {
  static propTypes = {
    ui_actions: PropTypes.object.isRequired,
    community_actions: PropTypes.object.isRequired,
    stripe_actions: PropTypes.object.isRequired,
    room_actions: PropTypes.object.isRequired,
    user_actions: PropTypes.object.isRequired,
    plan_actions: PropTypes.object.isRequired,
    integration_actions: PropTypes.object.isRequired,
    user: PropTypes.object,
    activeCommunity: PropTypes.object,
    communityPreference: PropTypes.object,
    stripeAccount: PropTypes.object,
    activeCampus: PropTypes.object,
    history: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isTabletUser: PropTypes.bool,
    doorAccessGroups: PropTypes.array,
    isFetchingDoorAccessGroups: PropTypes.bool,
    integrations: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.state = {
      validatingUser: false,
      fetchingStripeAccount: false,
      fetchingStripeCustomer: false,
    }
  }
  componentDidMount() {
    const { user, history, isTabletUser } = this.props
    if (
      this.checkUser({
        shouldReplaceRoute: true,
        user,
        history,
        isTabletUser,
      })
    ) {
      this.getCommunityPreferences()
      // poll for community preferences.
      this.preferencesPollingTimer = setInterval(
        () => this.getCommunityPreferences(),
        1000 * 60 * 60
      )
    }
    this.validateUser()
  }

  componentDidUpdate(prevProps) {
    const { history, location, user } = this.props
    const DEBUG_LOGGING = false
    if (
      (this.props.location !== prevProps.location && DEBUG_LOGGING) ||
      (process.env.NODE_ENV && process.env.NODE_ENV !== 'development')
    ) {
      if (location && location.pathname) {
        const path = location.pathname
        if (mixpanel) {
          const params = { pathname: path }
          if (user) {
            params.distinct_id = user.id
            params.email = user.email
            params.first_name = user.first_name
            params.last_name = user.last_name
          }
          mixpanel.track('navigation', params)
        }
        ReactGA.set({ page: path })
        ReactGA.pageview(path)
      }
    }
  }

  componentWillUnmount() {
    this.preferencesPollingTimer = null
  }

  checkUser = ({ shouldReplaceRoute, user, history, isTabletUser }) => {
    trackUser(user)
    if (!user || !user.authentication_token || isTabletUser) {
      if (shouldReplaceRoute) {
        if (isTabletUser) {
          history.replace('/tablet-login')
        } else {
          history.replace('/login')
        }
      }
      return false
    }
    return true
  }
  handleUserValidate({
    validatedUser,
    ui_actions,
    integration_actions,
    stripe_actions,
    activeCommunity,
    activeCampus,
    isFetchingCoworksCustomer,
  }) {
    let currentCampusFound = false
    let communityFound = false
    // this.props.ui_actions.clearCampusData()
    if (this.props.user.type !== Roles.superAdminRole) {
      // validate for super admins causes an error becacuse they don't have profiles
      // at the given campus

      validatedUser.communities.forEach(community => {
        if (activeCommunity.id === community.id) {
          communityFound = true
          // eslint-disable-next-line no-param-reassign
          activeCommunity = community
          // update redux preferences
          ui_actions.setActiveCommunity(community)

          // eslint-disable-next-line no-loop-func
          community.campuses.forEach(campus => {
            if (activeCampus.id === campus.id) {
              currentCampusFound = true
              // eslint-disable-next-line no-param-reassign
              activeCampus = campus
              // update redux preferences
              ui_actions.setActiveCampus(campus)
            }
          })

          integration_actions.handleActiveIntegrations(
            community.active_integrations
          )
        }
      })
      // Check if we found the active community and campuses
      if (!communityFound && validatedUser.communities.length > 0) {
        ui_actions.setActiveCommunity(validatedUser.communities[0])
        // eslint-disable-next-line no-param-reassign
        activeCommunity = validatedUser.communities[0]
      }
      if (!currentCampusFound && activeCommunity.campuses.length > 0) {
        ui_actions.setActiveCampus(activeCommunity.campuses[0])
      }
      // check prefs on the account
      if (activeCommunity.community_preference.status === 'terminated') {
        toast.info(
          'Your Coworks account has been paused. Please contact support@coworksapp.com to re-enable your account.'
        )
        captureException({
          text: `Capturing an attempt at a manager trying to logic with a terminated account with community_id: [${this.props.activeCommunity.id}]`,
        })
        this.props.history.push('/login')
      }
    }

    // open intercom chat?
    if (this.props.location) {
      const query = QueryStringToJSON(this.props.location)
      if (query && query.supportChat) {
        IntercomAPI('showNewMessage', '')
      }
    }

    // set the timezone for the user
    ui_actions.setUserTimezone(momentTZ.tz.guess())

    // get stripe account and check status!
    if (
      Roles.justSpaceStaffRoles.includes(validatedUser.type) &&
      activeCommunity.stripe_account_id
    ) {
      this.props.stripe_actions.getAccount({
        stripe_account_id: activeCommunity.stripe_account_id,
      })
    }

    if (
      !isFetchingCoworksCustomer &&
      activeCommunity.hasOwnProperty('coworks_customer_id') &&
      activeCommunity.coworks_customer_id
    ) {
      stripe_actions.getCoworksCustomer({
        coworks_customer_id: activeCommunity.coworks_customer_id,
      })
    }

    // this will populate redux with the
    this.loadCampusDataDefaults()
  }
  loadCampusDataDefaults = () => {
    // fetch rooms
    this.props.room_actions.getRoomsAtCampus(this.props.activeCampus.id, {
      types: JSON.stringify(['Conference', 'Space', 'Equipment']),
      serializer: 'RoomSerializer',
    })

    // fetch plans
    this.props.plan_actions.getPlans(this.props.activeCampus.id)
    // fetch teams

    this.props.ui_actions.getGroups(this.props.activeCampus.id)

    if (
      checkDoorProvider(this.props.integrations, this.props.activeCampus) &&
      !this.props.isFetchingDoorAccessGroups &&
      ((this.props.doorAccessGroups &&
        this.props.doorAccessGroups.length === 0) ||
        !this.props.doorAccessGroups)
    ) {
      this.props.integration_actions.getDoorAccessGroups(
        {
          campus_id: this.props.activeCampus.id,
          community_id: this.props.activeCommunity.id,
        },
        FETCHING_DOOR_ACCESS_GROUPS
      )
    }
  }
  validateUser = () => {
    // validate a token
    this.setState({
      validatingUser: true,
    })
    this.props.user_actions
      .validateToken()
      .then(response => {
        if (response && response.user) {
          this.handleUserValidate({
            validatedUser: response.user,
            ...this.props,
          })
          this.setState({
            validatingUser: false,
          })
        } else {
          console.error('Error validating user')
          this.props.history.push('/login')
        }
      })
      .catch(err => {
        this.setState({
          validatingUser: false,
        })
        console.error(err)
      })
  }

  getCommunityPreferences() {
    const { activeCommunity } = this.props

    this.props.community_actions.getCommunityPreference(activeCommunity.id, {})
  }

  render() {
    const { user, history, isTabletUser, activeCommunity } = this.props
    const { validatingUser } = this.state
    if (
      !this.checkUser({
        shouldReplaceRoute: false,
        user,
        history,
        isTabletUser,
      })
    ) {
      // This is required because the render will happen before ComponentWillMount
      return null
    }
    const commPrefs = activeCommunity.community_preference
    // eslint-disable-next-line no-undef
    const version = BUILD_VERSION
    // eslint-disable-next-line no-undef
    const internalVersion = INTERNAL_BUILD_VERSION
    const intercomContext = user
      ? {
          email: user.email,
          user_id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          version,
          internalVersion,
          created_at: moment(user.created_at).unix(),
          space_name: user.email.includes('@coworksapp.com')
            ? 'Coworks'
            : activeCommunity.name,
          user_role: user.type,
          community_trial_end_at: commPrefs.account_trial_end_at
            ? moment(commPrefs.account_trial_end_at).unix()
            : null,
          community_live_at: commPrefs.account_live_at
            ? moment(commPrefs.account_live_at).unix()
            : null,
          community_terminated_at: commPrefs.account_terminated_at
            ? moment(commPrefs.account_terminated_at).unix()
            : null,
          community_onboarding_started_at: commPrefs.account_onboarding_started_at
            ? moment(commPrefs.account_onboarding_started_at).unix()
            : null,
          community_onboarding_ending_at: commPrefs.account_onboarding_ended_at
            ? moment(commPrefs.account_onboarding_ended_at).unix()
            : null,
          community_stripe_account_id: activeCommunity.stripe_account_id
            ? activeCommunity.stripe_account_id
            : null,
          community_stripe_connected: Boolean(
            activeCommunity.stripe_account_id
          ),
          community_nudge_for_stripe_setup: Boolean(commPrefs.billing_enabled),
          community_status: commPrefs.status,
          community_is_whitelabeled: commPrefs.is_whitelabeled,
          community_branding_primary_color: commPrefs.branding_primary_color,
          community_subdomain: commPrefs.subdomain,
          community_send_onboarding_email: commPrefs.send_onboarding_email,
          community_pass_ach_fee_to_member: commPrefs.pass_ach_fee_to_member,
          community_pass_cc_fee_to_member: commPrefs.pass_cc_fee_to_member,
          community_campus_max: commPrefs.campus_max,
          community_campus_count: activeCommunity.campuses.length,
        }
      : null
    const stripeAccount = this.props.stripeAccount

    const stripeContext = {}
    if (stripeAccount) {
      stripeContext.community_stripe_charges_enabled =
        stripeAccount.charges_enabled
      stripeContext.community_stripe_payouts_enabled =
        stripeAccount.payouts_enabled
    }

    return validatingUser ? (
      <Spinner />
    ) : (
      <div className="app">
        <Intercom appID={INTERCOM_ID} {...intercomContext} {...stripeContext} />
        <ErrorBoundary renderFallbackComponent={false}>
          <AlertsContainer {...this.state} {...this.props} />
        </ErrorBoundary>
        <Offline>
          <div className="text-white bg-danger text-center p-2">
            <h5>No internet connection detected.</h5>
          </div>
        </Offline>
        <ErrorBoundary renderFallbackComponent={false}>
          <Header {...this.props} />
        </ErrorBoundary>
        <div className="app-body">
          <ErrorBoundary renderFallbackComponent={false}>
            <Sidebar {...this.props} />
          </ErrorBoundary>
          <main className="main">
            <Breadcrumb />
            <div className="container">
              <ErrorBoundary>
                {Routes({
                  user: this.props.user,
                  theme: this.props.theme,
                })}
              </ErrorBoundary>
            </div>
          </main>
        </div>
        {/* <Footer /> */}
      </div>
    )
  }
}

MainContainer.displayName = 'MainContainer'

function mapStateToProps(state) {
  return {
    user: state.user,
    isTabletUser: state.ui.isTabletUser,
    stripeAccount: state.stripe.account,
    activeCommunity: state.ui.activeCommunity,
    activeCampus: state.ui.activeCampus,
    isFetchingCoworksCustomer: resolveFetchingStatus(
      state,
      FETCHING_COWORKS_CUSTOMER_NAME
    ),

    isFetchingDoorAccessGroups: resolveFetchingStatus(
      state,
      FETCHING_DOOR_ACCESS_GROUPS
    ),
    integrations: state.integrations,
    doorAccessGroups:
      state.integrations &&
      state.integrations.doorAccess &&
      state.integrations.doorAccess.groups,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ui_actions: bindActionCreators(uiActions, dispatch),
    community_actions: bindActionCreators(communityActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    room_actions: bindActionCreators(roomActions, dispatch),
    plan_actions: bindActionCreators(planActions, dispatch),
    stripe_actions: bindActionCreators(stripeActions, dispatch),
    integration_actions: bindActionCreators(integrationsActions, dispatch),
  }
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(MainContainer)
)
