/* eslint-disable max-statements */
import {
  REMOVE_STRIPE_PLANS_FROM_SUB,
  SET_STRIPE_CUSTOMER,
  SET_STRIPE_UNPAID_INVOICES,
  SET_STRIPE_INVOICE,
  UPDATE_STRIPE_SUBSCRIPTION,
  DELETE_STRIPE_SUBSCRIPTION,
  SET_STRIPE_ACCOUNT,
  CREATE_STRIPE_CHARGE,
  CLEAR_STRIPE_PAYMENT_DATA,
  SET_COWORKS_CUSTOMER,
  SET_STRIPE_TRANSACTIONS,
  SET_STRIPE_FAILED_TRANSACTIONS,
  SET_STRIPE_PENDING_TRANSACTIONS,
  SET_STRIPE_MARKED_AS_PAID_INVOICES,
  SET_STRIPE_CLOSED_INVOICES,
} from '../reduxActions/actionTypes'

let stripeTeams
export default function(stripe = {}, action) {
  if (stripe.teams) {
    stripeTeams = action.teams
      ? [...stripe.teams, ...action.teams]
      : stripe.teams
    //append new stripe teams to existing teams
    //stripeTeams = stripe.teams ? [...stripe.teams, action.teams] : action.teams;
  } else {
    stripeTeams = action.teams ? action.teams : []
    //return stripe team
    //stripeTeams = stripe.teams ? stripe.teams : [];
  }
  switch (action.type) {
    case SET_COWORKS_CUSTOMER: {
      return { ...stripe, coworksCustomer: Object.assign({}, action.customer) }
    }
    case SET_STRIPE_CUSTOMER: {
      // the customer could be the manager's coworks customer so check that first
      if (
        stripe.coworksCustomer &&
        stripe.coworksCustomer.id === action.customer.id
      ) {
        return { ...stripe, coworksCustomer: action.customer }
      }

      // otherwise, update member customers
      if (stripe.customers) {
        const filteredList = stripe.customers.filter(
          customer => customer.id === action.customer.id
        )
        if (filteredList.length > 0) {
          // list has customer...so replace
          return {
            ...stripe,
            customers: stripe.customers.map(oldCustomer => {
              if (oldCustomer.id !== action.customer.id) {
                return oldCustomer
              }
              return { ...oldCustomer, ...action.customer }
            }),
          }
        }
        // list doesn't have customer so append
        return {
          ...stripe,
          customers: [...stripe.customers, Object.assign({}, action.customer)],
        }
      }
      // list is empty so initialize new array and append.
      return {
        ...stripe,
        customers: [Object.assign({}, action.customer)],
      }
    }
    case SET_STRIPE_ACCOUNT: {
      return Object.assign({}, stripe, { account: action.response.account })
    }
    case CLEAR_STRIPE_PAYMENT_DATA: {
      return Object.assign({}, stripe, {
        transactions: {},
        failedTransactions: {},
        pendingTransactions: {},
        unpaidInvoices: {},
        markedAsPaid: {},
      })
    }
    case SET_STRIPE_TRANSACTIONS: {
      // list is not empty to append
      if (stripe.transactions && stripe.transactions.data) {
        return {
          ...stripe,
          transactions: {
            ...stripe.transactions,
            data: [...stripe.transactions.data, ...action.transactions.data],
            ...action.transactions,
          },
          teams: stripeTeams,
        }
      }
      // list is empty so initialize new array and append.
      return {
        ...stripe,
        transactions: action.transactions,
        teams: stripeTeams,
      }
    }
    case SET_STRIPE_FAILED_TRANSACTIONS: {
      // list is not empty to append
      if (stripe.failedTransactions && stripe.failedTransactions.data) {
        return {
          ...stripe,
          failedTransactions: {
            ...stripe.failedTransactions,
            data: [
              ...stripe.failedTransactions.data,
              ...action.failedTransactions.data,
            ],
            ...action.failedTransactions,
          },
          teams: stripeTeams,
        }
      }
      // list is empty so initialize new array and append.
      return {
        ...stripe,
        failedTransactions: action.failedTransactions,
        teams: stripeTeams,
      }
    }
    case SET_STRIPE_PENDING_TRANSACTIONS: {
      // list is not empty to append
      if (stripe.pendingTransactions && stripe.pendingTransactions.data) {
        return {
          ...stripe,
          pendingTransactions: {
            ...stripe.pendingTransactions,
            data: [
              ...stripe.pendingTransactions.data,
              ...action.pendingTransactions.data,
            ],
            ...action.pendingTransactions,
          },
          teams: stripeTeams,
        }
      }
      // list is empty so initialize new array and append.
      return {
        ...stripe,
        pendingTransactions: action.pendingTransactions,
        teams: stripeTeams,
      }
    }
    case SET_STRIPE_UNPAID_INVOICES: {
      // list is not empty to append
      if (stripe.unpaidInvoices && stripe.unpaidInvoices.data) {
        return {
          ...stripe,
          unpaidInvoices: {
            ...stripe.unpaidInvoices,
            data: [
              ...stripe.unpaidInvoices.data,
              ...action.unpaidInvoices.data,
            ],
            ...action.unpaidInvoices,
          },
          teams: stripeTeams,
        }
      }
      // list is empty so initialize new array and append.
      return {
        ...stripe,
        unpaidInvoices: action.unpaidInvoices,
        teams: stripeTeams,
      }
    }
    case SET_STRIPE_CLOSED_INVOICES: {
      // list is not empty to append
      if (stripe.closedInvoices && stripe.closedInvoices.data) {
        return {
          ...stripe,
          closedInvoices: {
            ...stripe.closedInvoices,
            data: [
              ...stripe.closedInvoices.data,
              ...action.closedInvoices.data,
            ],
            ...action.closedInvoices,
          },
          teams: stripeTeams,
        }
      }
      // list is empty so initialize new array and append.
      return {
        ...stripe,
        closedInvoices: action.closedInvoices,
        teams: stripeTeams,
      }
    }
    case SET_STRIPE_MARKED_AS_PAID_INVOICES: {
      // list is not empty to append
      if (stripe.markedAsPaid && stripe.markedAsPaid.data) {
        return {
          ...stripe,
          markedAsPaid: {
            ...stripe.markedAsPaid,
            data: [...stripe.markedAsPaid.data, ...action.markedAsPaid.data],
            ...action.markedAsPaid,
          },
          teams: stripeTeams,
        }
      }
      // list is empty so initialize new array and append.
      return {
        ...stripe,
        markedAsPaid: action.markedAsPaid,
        teams: stripeTeams,
      }
    }
    case CREATE_STRIPE_CHARGE: {
      if (stripe.charges) {
        return {
          ...stripe,
          charges: {
            //todo this should be put into transactions!!
            ...stripe.charges,
            data: [
              ...stripe.charges.data,
              Object.assign({}, action.response.charge[0]),
            ],
          },
        }
      }
      // list is empty so initialize new array and append.
      return {
        ...stripe,
        charges: {
          data: [Object.assign({}, action.response.charge[0])],
        },
      }
    }
    case SET_STRIPE_INVOICE: {
      if (stripe.invoices && stripe.invoices.data) {
        const filteredList = stripe.invoices.data.filter(
          invoice => invoice.id === action.invoice.id
        )
        if (filteredList.length > 0) {
          return {
            ...stripe,
            invoices: stripe.invoices.data.map(oldInvoice => {
              if (oldInvoice.id !== action.invoice.id) {
                return oldInvoice
              }
              return { ...oldInvoice, ...action.invoice }
            }),
          }
        }
        return {
          ...stripe,
          invoices: [...stripe.invoices, Object.assign({}, action.invoice)],
        }
      }
      return {
        ...stripe,
        invoices: [Object.assign({}, action.invoice)],
      }
    }
    case REMOVE_STRIPE_PLANS_FROM_SUB: {
      return {
        ...stripe,
        customers: stripe.customers.map(oldCustomer => {
          if (oldCustomer.id !== action.customer.id) {
            return oldCustomer
          }
          return {
            ...oldCustomer,
            customer: {
              ...stripe.customer,
              subscriptions: {
                ...stripe.customer.subscriptions,
                data: [
                  ...stripe.customer.subscriptions.data.map(subscription => ({
                    ...subscription,
                    items: {
                      ...subscription.items,
                      data: [
                        ...subscription.items.data
                          .filter(
                            subscriptionItem =>
                              Object.assign({}, subscriptionItem).id !==
                              action.subscriptionItems[0].id
                          )
                          .map(subscriptionItem =>
                            Object.assign({}, subscriptionItem)
                          ),
                      ],
                    },
                  })),
                ],
              },
            },
          }
        }),
      }
    }
    case UPDATE_STRIPE_SUBSCRIPTION:
      return {
        ...stripe,
        customers: stripe.customers.map(oldCustomer => {
          if (oldCustomer.id !== action.customer.id) {
            return oldCustomer
          }
          return {
            ...action.customer,
            subscriptions: {
              ...action.customer.subscriptions,
              data: [
                ...action.customer.subscriptions.data.map(subscription => {
                  if (subscription.id === action.subscription.id) {
                    return action.subscription
                  }
                  return subscription
                }),
              ],
            },
          }
        }),
      }

    case DELETE_STRIPE_SUBSCRIPTION:
      return {
        ...stripe,
        customers: stripe.customers.map(oldCustomer => {
          if (oldCustomer.id !== action.customer.id) {
            return oldCustomer
          }
          return {
            ...oldCustomer,
            customer: {
              ...stripe.customer,
              subscriptions: {
                ...stripe.customer.subscriptions,
                data: [],
              },
            },
          }
        }),
      }
    default:
      return stripe
  }
}
