import {
  CREATE_ANNOUNCEMENT,
  SET_ANNOUNCEMENTS,
  DELETE_ANNOUNCEMENT,
} from '../reduxActions/actionTypes'
import { arrayMerge } from './reducerConstants'

export default function (announcements = {}, action) {
  const announcementsList = announcements.announcements
  switch (action.type) {
    case CREATE_ANNOUNCEMENT:
      if (announcementsList && announcementsList.length > 0) {
        return {
          ...announcements,
          announcements: [
            Object.assign({}, action.announcement),
            ...announcementsList,
          ],
        }
      }
      return {
        ...announcements,
        announcements: [Object.assign({}, action.announcement)],
      }
    case SET_ANNOUNCEMENTS:
      if (announcementsList && announcementsList.length > 0) {
        const oldAnnouncements = announcementsList
        const newAnnouncements = action.announcements
        return {
          ...announcements,
          announcements: arrayMerge(oldAnnouncements, newAnnouncements),
          meta: action.meta,
        }
      }
      // list is empty so initialize so just set it.
      return {
        ...announcements,
        announcements: action.announcements,
        meta: action.meta,
      }
    case DELETE_ANNOUNCEMENT:
      if (announcementsList && announcementsList.length > 0) {
        let newAnnouncements = JSON.parse(JSON.stringify(announcementsList))
        newAnnouncements = newAnnouncements.filter(
          object => object.id !== action.announcement.id
        )
        return {
          ...announcements,
          announcements: newAnnouncements,
        }
      }
      // list is empty so dont attempt to remove it
      return announcements
    default:
      return announcements
  }
}
