/* eslint-disable no-invalid-this,radix */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Switch, Route, Redirect } from 'react-router-dom'
import { Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import { Button } from '@global/Base/Button/ButtonStyled'
import Tooltip from '@bit/coworks.base-components.tooltip'

import { resolveDomainPrefix } from '@global/Constants'

import * as roomActions from '@reduxActions/roomActions'
import * as uiActions from '@reduxActions/uiActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'

import CardComponent from '@global/CardComponent'
import NothingFound from '@global/NothingFound'
import Spinner from '@global/Spinner'
import Badge from '@global/Base/Badge/Badge'
import Placeholder from '@global/Placeholder'
import {
  resolveFetchingStatus,
  FETCHING_ROOMS,
} from '@global/Constants/FetchingConstants'

import conferencePlaceholder from '../../img/placeholders/rooms_placeholder.jpeg'

const SEARCH_BAR_EQUIPMENT = 'searchBarEquipment'
const SEARCH_BAR_ROOMS = 'searchBarRooms'
const SEARCH_BAR_SPACES = 'searchBarSpaces'

class Rooms extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    ui_actions: PropTypes.object,
    room_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    isFetchingRooms: PropTypes.bool,
    rooms: PropTypes.array,
    spaces: PropTypes.array,
    equipment: PropTypes.array,
  }
  constructor(props, context) {
    super(props, context)

    // const activeTab = resolveActiveTab(this.props.history, 'rooms')
    this.state = {
      activeTab: this.props.match.params.tabId
        ? this.props.match.params.tabId
        : 'rooms',
      filteredRooms: null,
      filteredSpaces: null,
      filteredEquipment: null,
      hasReceivedRooms: false,
      hasReceivedSpaces: false,
      hasReceivedEquipment: false,
      searchBarSpaces: '',
      searchBarRooms: '',
      searchBarEquipment: '',
    }
  }
  componentDidMount() {
    const { rooms, isFetchingRooms } = this.props
    if (!isFetchingRooms && (!rooms || (rooms && rooms.length === 0))) {
      this.props.room_actions
        .getRoomsAtCampus(this.props.activeCampus.id, {
          types: JSON.stringify(['Conference', 'Space', 'Equipment']),
          serializer: 'RoomSerializer',
        })
        .catch(err => {
          toast.error('Whoops! Looks like there was an error loading the rooms')
          console.error(err)
        })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //todo replace with static getDerivedStateFromProps(props, state) {
    // set the initial state of the filteredLists
    if (!this.state.hasReceivedRooms && nextProps.rooms) {
      this.setState({
        hasReceivedRooms: true,
        filteredRooms: nextProps.rooms,
      })
    }
    if (!this.state.hasReceivedSpaces && nextProps.spaces) {
      this.setState({
        hasReceivedSpaces: true,
        filteredSpaces: nextProps.spaces,
      })
    }
    if (!this.state.hasReceivedEquipment && nextProps.equipment) {
      this.setState({
        hasReceivedEquipment: true,
        filteredEquipment: nextProps.equipment,
      })
    }
  }
  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === SEARCH_BAR_ROOMS) {
          this.filter('rooms', value)
        } else if (name === SEARCH_BAR_SPACES) {
          this.filter('spaces', value)
        } else if (name === SEARCH_BAR_EQUIPMENT) {
          this.filter('equipment', value)
        }
      }
    )
  }
  filter = (type, filter) => {
    let filteredList
    if (type === 'rooms') {
      filteredList = this.props.rooms
    } else if (type === 'spaces') {
      filteredList = this.props.spaces
    } else if (type === 'equipment') {
      filteredList = this.props.equipment
    }
    const num = parseInt(filter)
    filteredList = filteredList.filter(
      room =>
        (room.name
          ? room.name.toLowerCase().includes(filter.toLowerCase())
          : false) ||
        (room.description
          ? room.description.toLowerCase().includes(filter.toLowerCase())
          : false) ||
        room.capacity === (num !== 'NaN' ? num : filter)
    )
    const state = {}
    if (type === 'rooms') {
      state.filteredRooms = filteredList
    } else if (type === 'spaces') {
      state.filteredSpaces = filteredList
    } else if (type === 'equipment') {
      state.filteredEquipment = filteredList
    }
    this.setState(state)
  }
  editRoomClicked = room =>
    this.props.history.push(`/rooms/room-detail?${room.id}`, { room })

  handleNewRoom = type =>
    this.props.history.push('/rooms/add-room', {
      type,
    })

  toggleTab(tab) {
    if (this.props.match.url !== `/rooms/${tab}`) {
      this.props.history.push(`/rooms/${tab}`)
    }
  }
  /* End of autosuggestion component */
  renderSubHeaderComponent = room => {
    const { activeCommunity, activeCampus } = this.props
    const baseUrl = resolveDomainPrefix(activeCommunity.preferences)

    return (
      <div className="col-12">
        {room.is_public && (
          <div>
            <Tooltip
              tooltipContent={
                'This room is visible in the external bookings page.'
              }
              header={`Publicly Visible`}
              buttonText={'View'}
              handleClick={() => {
                window.open(`${baseUrl}/book/${activeCampus.id}`, '_blank')
              }}
            >
              <FontAwesomeIcon icon="eye" /> Public
            </Tooltip>
          </div>
        )}
        {room.requires_access && (
          <Tooltip
            tooltipContent={
              'This room requires a permission to book for members.'
            }
            header={`Requires Permission`}
            buttonText={'More on Permissions'}
            handleClick={() => {
              window.open(
                `https://help.coworksapp.com/en/articles/3982972-how-do-i-use-room-permissions`,
                '_blank'
              )
            }}
          >
            <Badge key={room.id} size="small" className="mb-2">
              <FontAwesomeIcon icon="lock" /> Requires Access
            </Badge>
          </Tooltip>
        )}
      </div>
    )
  }
  renderCards(rooms, equipmentName) {
    const activeTab = this.state.activeTab
    if (rooms && rooms.length === 0 && !this.props.isFetchingRooms) {
      let label = 'room'
      let type = 'Conference'
      if (activeTab === 'spaces') {
        label = 'space'
        type = 'Space'
      } else if (activeTab === 'equipment') {
        label = equipmentName
        type = equipmentName
      }
      return (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <Placeholder
            title={`No ${
              activeTab !== 'equipment' ? `${label}s` : label
            } at this campus`}
            icon={'door-open'}
            subtext1={`You currently have no plans in this campus`}
            subtext2={'Add one by clicking'}
            buttonText={`new ${label}`}
            handleClick={() => this.handleNewRoom(type)}
          />
        </div>
      )
    }

    return (
      rooms &&
      rooms.map((room, i) =>
        CardComponent({
          key: room.id,
          name: `${room.name}`,
          alt: room.name ? room.name : 'Image',
          editClickHandler: this.editRoomClicked,
          item: room,
          path: '/rooms/room-detail',
          isRectangular: true,
          renderSubHeaderComponent: () => this.renderSubHeaderComponent(room),
          imageUrl: room.room_photo
            ? room.room_photo.medium_file_url
            : conferencePlaceholder,
          detail: `${room.description ? room.description : 'No description'}`,
          actionButtonText: 'View',
          value:
            room.type !== 'Equipment' ? `Capacity: ${room.capacity}` : null,
        })
      )
    )
  }

  render() {
    const { isFetchingRooms, match, history } = this.props
    let rooms, spaces, equipment
    if (this.state.searchBarRooms.length > 0) {
      rooms = this.state.filteredRooms
    } else {
      rooms = this.props.rooms
    }
    if (this.state.searchBarSpaces.length > 0) {
      spaces = this.state.filteredSpaces
    } else {
      spaces = this.props.spaces
    }
    if (this.state.searchBarEquipment.length > 0) {
      equipment = this.state.filteredEquipment
    } else {
      equipment = this.props.equipment
    }
    const equipmentEnabled = this.props.activeCommunity.community_preference
      .equipment_enabled
    const equipmentName = this.props.activeCommunity.community_preference
      .equipment_resource_name
    return (
      <div id="rooms" className="animated fadeIn">
        <div className="row">
          <div className="col-md-12 mb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: history.location.pathname === `/rooms`,
                  })}
                  onClick={() => this.toggleTab('rooms')}
                >
                  Rooms
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: history.location.pathname.includes('spaces'),
                  })}
                  onClick={() => this.toggleTab('spaces')}
                >
                  Spaces
                </NavLink>
              </NavItem>
              {equipmentEnabled && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: history.location.pathname.includes('equipment'),
                    })}
                    onClick={() => this.toggleTab('equipment')}
                  >
                    {equipmentName}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent>
              <TabPane>
                <Switch>
                  <Route exact path={`/rooms/rooms`}>
                    <Redirect to={`/rooms`} />
                  </Route>
                  <Route exact path={'/rooms'}>
                    <div className="animated fadeIn">
                      <div className="col-12">
                        <div className="row border-bottom-1 my-3">
                          <div className="col-md-4 col-12">
                            <h2>Conference Rooms</h2>
                          </div>
                          <div className="col-md-5 col-12 d-flex align-items-center">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <FontAwesomeIcon icon="search" />
                              </span>
                              <input
                                type="text"
                                name={SEARCH_BAR_ROOMS}
                                value={this.state.searchBarRooms}
                                disabled={Boolean(isFetchingRooms)}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="Search for a room..."
                              />
                            </div>
                          </div>
                          <div className="col-md-3 col-12 d-flex justify-content-end align-items-center">
                            <Button
                              onClick={() => this.handleNewRoom('Conference')}
                              className="btn btn-primary pull-right"
                            >
                              <FontAwesomeIcon icon="door-open" /> New Room
                            </Button>
                          </div>
                        </div>
                        {isFetchingRooms ? (
                          <Spinner />
                        ) : (
                          <div className="row">{this.renderCards(rooms)}</div>
                        )}
                      </div>
                    </div>
                  </Route>
                  <Route path={'/rooms/spaces'}>
                    <div className="animated fadeIn">
                      <div className="col-md-12">
                        <div className="row border-bottom-1 my-3">
                          <div className="col-3">
                            <h2>Spaces</h2>
                          </div>
                          <div className="col-6 d-flex align-items-center">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <FontAwesomeIcon icon={'search'} />
                              </span>
                              <input
                                type="text"
                                name={SEARCH_BAR_SPACES}
                                value={this.state.searchBarSpaces}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="Search for a space..."
                              />
                            </div>
                          </div>
                          <div className="col-3 d-flex justify-content-end align-items-center">
                            <Button
                              onClick={() => this.handleNewRoom('Space')}
                              className="btn btn-primary pull-right"
                            >
                              <FontAwesomeIcon icon="door-open" /> New Space
                            </Button>
                          </div>
                        </div>
                        {isFetchingRooms ? (
                          <Spinner />
                        ) : (
                          <div className="row">{this.renderCards(spaces)}</div>
                        )}
                      </div>
                    </div>
                  </Route>
                  <Route path={'/rooms/equipment'}>
                    <div className="animated fadeIn">
                      <div className="col-md-12">
                        <div className="row border-bottom-1 my-3">
                          <div className="col-4">
                            <h2>{equipmentName}</h2>
                          </div>
                          <div className="col-5 d-flex align-items-center">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <FontAwesomeIcon icon={'search'} />
                              </span>
                              <input
                                type="text"
                                name="searchBarEquipment"
                                value={this.state.searchBarEquipment}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder={`Search for ${equipmentName}...`}
                              />
                            </div>
                          </div>
                          <div className="col-3 d-flex justify-content-end align-items-center">
                            <Button
                              onClick={() => this.handleNewRoom('Equipment')}
                              className="btn btn-primary pull-right"
                            >
                              New {equipmentName}
                            </Button>
                          </div>
                        </div>
                        {isFetchingRooms ? (
                          <Spinner />
                        ) : (
                          <div className="row">
                            {this.renderCards(equipment, equipmentName)}
                          </div>
                        )}
                      </div>
                    </div>
                  </Route>
                </Switch>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    )
  }
}

Rooms.displayName = 'Rooms'

const mapStateToProps = state => {
  return {
    isFetchingRooms: resolveFetchingStatus(state, FETCHING_ROOMS),
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    rooms: roomSelectors.getConferenceRoomsList(state),
    spaces: roomSelectors.getSpaceList(state),
    equipment: roomSelectors.getEquipmentList(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rooms)
