import { createSelector } from 'reselect'

const NAMESPACE = 'rooms'
const rootSelector = state => state[NAMESPACE]

// get initial state object
export const getRoot = createSelector([rootSelector], root => root)

// room section
export const getRooms = createSelector([getRoot], root => Array.isArray(root) ? root : root.rooms)
export const getRoomsList = createSelector([getRooms], root => {
  if (Array.isArray(root)) return root // backwards compatibility
  const list = root && root.data ? root.data : []
  return list.filter(room => room.type !== 'Suite')
})
export const getConferenceRoomsList = createSelector([getRoomsList], root => root ? root.filter(room => room.type === 'Conference') : [])
export const getSpaceList = createSelector([getRoomsList], root => root ? root.filter(room => room.type === 'Space') : [])
export const getEquipmentList = createSelector([getRoomsList], root => root ? root.filter(room => room.type === 'Equipment') : [])
export const getRoomsPagination = createSelector([getRooms], root => root && root.meta ? root.meta.pagination : null)

// suite section
export const getSuites = createSelector([getRoot], root => root.suites)
export const getSuiteList = createSelector([getSuites], root => root && root.data ? root.data : [])
export const getSuitePagination = createSelector([getSuites], root => root && root.meta && root.meta.pagination ? root.meta.pagination : null)
export const getSuiteStats = createSelector([getSuites], root => root && root.stats ? root.stats : null)

// combined retrive
export const getAll = createSelector([getRoomsList, getSuiteList], (rooms, suites) => [...rooms, ...suites])