/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'
import * as Reactstrap from 'reactstrap'
import without from '../../WithoutPropsStyledComponents'
import colors from '@global/Colors/colors'
import {
  resolveColor,
  resolveHoverColor,
  resolveButtonTextColor,
  resolveBorderColor,
} from '../baseComponentColorUtils'

export const Button = styled.button.attrs(props => ({
  color: props.color || 'primary',
  type: props.type || 'button',
}))`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: inherit;
  touch-action: manipulation;
  margin: 0;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  transition: all 0.2s ease-in-out;
  border-color: ${props => resolveBorderColor(props)};
  -webkit-appearance: button;
  color: ${props => resolveButtonTextColor(props)};
  background-color: ${props => resolveColor(props)};
  :active {
    background-image: none;
    background-color: ${props => resolveHoverColor(props)};
    border-color: ${props => resolveHoverColor(props)};
  }
  :hover {
    background-color: ${props => resolveHoverColor(props)};
    border-color: ${props => resolveHoverColor(props)};
    text-decoration: none;
  }
  :focus {
    background-color: ${props => resolveHoverColor(props)};
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 2px ${props => resolveHoverColor(props)};
  }
  :disabled {
    background-color: ${props => resolveHoverColor(props)};
    cursor: not-allowed;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      opacity: ${props => props.theme.disabledOpacity} !important;
    `};
`
// not yet ready!
// export const ButtonOutline = styled(Button)`
//   color: ${props => resolveColor(props)};
//   background-color: transparent;
//   border-color: ${props => resolveColor(props)};
//   &:hover {
//     color: ${props => resolveButtonTextColor(props)};
//     border-color: ${props => resolveButtonTextColor(props)};
//     background-color: ${props => resolveColor(props)};
//   }
// `

export const TextButton = styled(Button).attrs(props => ({
  color: props.color || 'primary',
  type: props.type || 'button',
}))`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-font-smoothing: antialiased !important;
  box-sizing: inherit !important;
  touch-action: manipulation !important;
  margin: 0;
  margin-bottom: ${props => (props.inline ? '0 !important' : '1rem')};
  overflow: visible !important;
  text-transform: none !important;
  cursor: pointer !important;
  display: inline-block !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  user-select: none !important;
  border: 0px solid transparent !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  padding: ${props => (props.inline ? '0 !important' : '0.5rem 1rem')};
  font-size: ${props => (props.inline ? 'inherit' : '0.875rem !important')};
  font-weight: ${props => (props.inline ? 'inherit' : 'normal !important')};
  line-height: ${props => (props.inline ? 'inherit' : '1.25 !important')};
  transition: all 0.2s ease-in-out !important;
  background-color: transparent !important;
  color: ${props => resolveColor(props)} !important;
  -webkit-appearance: button;
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      opacity: ${props => props.theme.disabledOpacity} !important;
    `};

  :active {
    -webkit-appearance: button;
    border-color: transparent;
    background-image: none;
  }
  :focus {
    outline: 0;
    box-shadow: ${props =>
    props.inline ? 'none' : '0 0 0 2px rgba(204, 204, 204, 0.5)'};
    border-color: transparent;
    /* text-decoration: underline; */
    background-color: transparent;
    /* background-color: none;
    border-color: none; */
  }
  :hover {
    border-color: transparent;
    /* text-decoration: underline; */
    color: ${props => resolveHoverColor(props)} !important;
    background-color: transparent;
  }
`

export const MenuButton = styled(Button)`
  color: #263238;
  background-color: transparent;
  border-color: transparent;
  /* font-weight: 400;*/
  /* border-radius: 0.25rem;*/

  &.active {
    background-color: #e6e6e6;
    background-image: none;
    border-color: transparent;
    /* border-color: #adadad; */
    /* background-color: ${props => resolveHoverColor(props)}; */
    /* border-color: ${props => resolveHoverColor(props)}; */
  }
  :hover {
    background-color: rgb(243, 243, 243);
    border-color: transparent;
    text-decoration: none;
  }
  :focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 2px #adadad;
    background-color: #e6e6e6;
    background-image: none;
    /* border-color: #adadad; */
    border-color: transparent;
  }
`

export const DropdownToggle = styled(Reactstrap.DropdownToggle)`
  background-color: ${props => resolveColor(props)};
  color: ${props => resolveButtonTextColor(props)};
  border-color: ${props => resolveBorderColor(props)};
  &:hover {
    color: ${props => resolveButtonTextColor(props)};
    background-color: ${props => resolveHoverColor(props)};
  }
`

export default Button
