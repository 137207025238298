/* eslint-disable react/prop-types,no-trailing-spaces,camelcase */
import React from 'react'
import defaultCompanyPic from '../../../img/placeholders/otto_black_white.jpeg'
import { Button } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as Constants from '../../Global/Constants'
import moment from 'moment'
import RoundedImage from '@global/RoundedImage'
import Anchor from '@global/Base/Anchor/Anchor'

const CompanyRow = ({ company, index, includeMarginsAndPadding }) => {
  const { name, size, joined_at, created_at } = company

  let showMarginsAndPadding = true
  if (
    includeMarginsAndPadding !== null &&
    includeMarginsAndPadding !== undefined
  ) {
    showMarginsAndPadding = includeMarginsAndPadding
  }
  return (
    <div key={index} style={{ cursor: 'pointer' }}>
      <div className={`row ${showMarginsAndPadding && 'p-2'}`}>
        <div className={'col-12'}>
          <h6>{name}</h6>

          <div className="text-truncate">
            <FontAwesomeIcon icon="user" className="text-primary" />
            {' Admin: '}
            <span className="text-primary">
              {company.admins && company.admins.length > 0
                ? company.admins[0].email
                : ''}
            </span>
          </div>
          <div>
            <span className="text-primary my-0 p-0">
              <FontAwesomeIcon icon="calendar-alt" /> Joined{' '}
              {moment(joined_at ? joined_at : created_at)
                .utc()
                .local()
                .format('MMM DD, YYYY')}
            </span>
          </div>
          <div>
            {size ? size : 0} {size > 1 || !size ? 'members' : 'member'}
          </div>
        </div>
      </div>
    </div>
  )
}

CompanyRow.displayName = 'CompanyRow'

export default CompanyRow
