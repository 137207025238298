/* eslint-disable react/prop-types,no-trailing-spaces */
import React from 'react'
import defaultUserPic from '../../../img/placeholders/otto_black_white.jpeg'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as Constants from '../../Global/Constants'
import styled from 'styled-components'
import { DropdownToggleOutlineStyled } from '../../Global/BaseComponents'

import Anchor from '@global/Base/Anchor/Anchor'

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colorPrimary};
`

const MemberRow = ({
  member,
  team,
  index,
  toggleDropdown,
  editMemberClicked,
  viewMemberClicked,
  viewMemberBilling,
  teamLinkClicked,
  dropdownsOpen,
  handlePromoteToAdmin,
  showEdit,
  showActions,
  includeMarginsAndPadding,
  includePhoto,
  canViewEditBilling,
  role,
}) => {
  const canEditBilling =
    canViewEditBilling !== undefined ? canViewEditBilling : false
  const defaultPicture = defaultUserPic
  const name = Constants.toTitleCase(`${member.first_name} ${member.last_name}`)
  const photo = member.user_photo
  const { title, email } = member
  const isAdmin =
    team &&
    team.admins &&
    team.admins.length > 0 &&
    team.admins[0].id === member.id

  let showActionButtons = true
  if (showActions !== null && showActions !== undefined) {
    showActionButtons = showActions
  }
  let showMarginsAndPadding = true
  if (
    includeMarginsAndPadding !== null &&
    includeMarginsAndPadding !== undefined
  ) {
    showMarginsAndPadding = includeMarginsAndPadding
  }
  let showPhoto = true
  if (includePhoto !== null && includePhoto !== undefined) {
    showPhoto = includePhoto
  }

  function interceptMemberClicked(event, shouldEdit) {
    if (editMemberClicked) {
      event.stopPropagation()
      if (shouldEdit) {
        editMemberClicked(member, team)
      } else if (viewMemberClicked) {
        viewMemberClicked(member, team)
      } else {
        editMemberClicked(member, team)
      }
    }
  }

  function teamClicked(event) {
    event.stopPropagation()
    teamLinkClicked(team, member)
  }
  function interceptToggleDropdown(event) {
    event.stopPropagation()
    toggleDropdown(index)
  }

  return (
    <div key={index} style={{ cursor: 'pointer' }}>
      <div
        className={`row ${showMarginsAndPadding && 'p-2'}`}
        onClick={interceptMemberClicked}
      >
        <div className={!showPhoto && !showActionButtons ? 'col-12' : 'col-8'}>
          <h6>
            <a
              className="my-0 p-0"
              onClick={interceptMemberClicked}
              style={{ cursor: 'pointer' }}
            >
              {name}
            </a>
          </h6>
          {teamLinkClicked && (
            <div>
              <Anchor className="my-0 p-0" onClick={teamClicked}>
                <FontAwesomeIconStyled icon="briefcase" />{' '}
                {team ? `${team.name}` : ''}
              </Anchor>
            </div>
          )}
          {role && (
            <div>
              <Anchor className="my-0 p-0">
                <FontAwesomeIconStyled icon="user" /> {role}
              </Anchor>
            </div>
          )}
          <div>
            {email && (
              <Anchor title="send email">
                <FontAwesomeIconStyled icon="envelope" /> {email}
              </Anchor>
            )}
          </div>
        </div>
        {showActionButtons && (
          <div className="col-3 d-flex justify-content-end align-items-center">
            {showEdit ? (
              <ButtonDropdown
                isOpen={dropdownsOpen[index]}
                toggle={interceptToggleDropdown}
              >
                <DropdownToggleOutlineStyled caret>
                  Member Actions
                </DropdownToggleOutlineStyled>
                <DropdownMenu>
                  <DropdownItem
                    className="btn btn-outline-primary"
                    onClick={interceptMemberClicked}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    onClick={event => interceptMemberClicked(event, true)}
                  >
                    Edit
                  </DropdownItem>
                  {canEditBilling && viewMemberBilling && (
                    <DropdownItem onClick={() => viewMemberBilling(member)}>
                      View Team Billing
                    </DropdownItem>
                  )}
                  {handlePromoteToAdmin && !isAdmin ? (
                    <DropdownItem
                      onClick={() => handlePromoteToAdmin(member, team)}
                    >
                      Promote to Team Admin
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </ButtonDropdown>
            ) : (
              <Button
                className="btn btn-outline-primary"
                onClick={interceptMemberClicked}
              >
                View
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

MemberRow.displayName = 'MemberRow'

export default MemberRow
