// This is a list on email templates which users are able to choose from
// to pre-populate their announcements.
// package delivery, Visitor request, Payment Reminder, General Annoucement, Add Payment Source Reminder
const templates = [
  {
    name: 'None',
    subject: '',
    greeting: '',
    message: '',
  },
  {
    name: 'Package Delivery',
    subject: 'Package Arrived',
    header: 'Package Arrived',
    greeting: 'Hey',
    message: `
        You have received a package. Please pick it up at your earliest convenience.
      `,
  },
  {
    name: 'Visitor Request',
    subject: 'New Visitor',
    header: 'You Have a Visitor',
    greeting: 'Hey',
    message: `
        Someone is requesting to visit you. Please respond at your earliest convenience.
      `,
  },
  {
    name: 'Payment Overdue',
    subject: 'Overdue Payment Reminder',
    header: 'Overdue Payment Reminder',
    greeting: 'Hey',
    message: `
        You have an overdue payment. Please pay this at your earliest convenience.
      `,
  },
  {
    name: 'General Announcement',
    subject: 'Announcement',
    header: 'Announcement',
    greeting: 'Hey',
    message: '',
  },
  {
    name: 'Add Payment Source Reminder',
    subject: 'Missing Payment Info',
    header: 'Missing Payment Info',
    greeting: 'Hey',
    message: `
        We are missing a payment source for your membership. Please update your payment source in the app.
      `,
  },
  {
    name: 'Onboarding',
    subject: 'Welcome',
    header: 'Welcome',
    greeting: 'Hey',
    message: `
        Welcome to our Community. See app for resources and other information and reach out if you need anything.
      `,
  },
]
export default templates
