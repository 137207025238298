/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

export default function GenericModal({
  open,
  toggle,
  isFetching,
  onConfirm,
  onCancel,
  header,
  body,
  cancelLabel = 'Cancel',
  confirmLabel = 'Submit',
}) {
  return (
    <Modal isOpen={open} toggle={toggle} className="modal-primary fade in">
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h6>{body}</h6>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button color="primary" disabled={isFetching} onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

GenericModal.displayName = 'GenericModal'

GenericModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  isFetching: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  header: PropTypes.string,
  body: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
}
