import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import styled, { css } from 'styled-components'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import { Button } from 'reactstrap'
import colors from '@global/Colors/colors'
import { IconButton } from '@bit/coworks.base-components.button'
import defaultAvatar from '@app/img/placeholders/otto_black_white.jpeg'
import CampusDropdown from '@global/Header/CampusDropdown'

const Container = styled.header`
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  height: 75px;
  padding: 0;
  margin: 10px 0;

  @media (max-width: 768px) {
    padding: 0 10px;
    height: auto;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Space = styled.div`
  flex: 1;
  order: 4;

  @media (max-width: 768px) {
    display: none;
  }
`
const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex: 1;
  order: 3;
  margin: 0;

  @media (max-width: 768px) {
    // display: none;
    margin: 10px 0;
    font-size: 22px;
    justify-content: flex-start;
    padding: 0;
    order: 1;
  }
`
const LogoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 24px;
  flex: 1;
  order: 2;

  a {
    color: #263238;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`
const LogoImage = styled.img`
  height: 3rem;
  width: 3rem;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
`
const CampusTitle = styled.h4`
  margin: 0;
  font-weight: bold;
`
const TopWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
`
const BottomWrapper = styled.div`
  width: 100%;
`
const ButtonLinkStyled = styled(TextButton)`
  background: #0000004d;
  margin: 0;

  @media (max-width: 768px) {
    display: none !important;
  }
`
const MenuListButton = styled(Button)`
  margin: 0;
  text-align: right !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding: 0.8rem 1rem !important;
`
const MenuButton = styled(IconButton)`
  height: 42px;
  width: 42px;

  @media (min-width: 769px) {
    display: none !important;
  }
`
const ButtonText = styled.h6`
  margin: 0;
`
const MenuOverlay = styled.div`
  position: absolute;

  background: #fff;
  height 0;
  width: 100%;
  overflow: hidden;
  transition: 0.5s;

  ${props => {
    if (props.open) {
      return css`
        height 100vh;
      `
    }
    return null
  }}
`
const MenuOverlayContent = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  border-top: 1px solid #ccc;
`

const Header = ({
  history,
  toggleScheduleTourModal,
  campus,
  community,
  onCampusClick,
  title,
  url,
  ...other
}) => {
  const [modalOpen, setModal] = useState(false)
  const toggle = () => setModal(prev => !prev)

  let campusPhoto = defaultAvatar
  if (campus && campus.campus_photo) {
    campusPhoto = campus.campus_photo.file_url
  }

  const changeCampus = campus => {
    if (onCampusClick) {
      onCampusClick(campus)
    } else {
      history.push(`/${url}/${campus.id}`)
    }
  }

  const menuButtons = [
    {
      label: 'Spaces',
      onClick: () => history.push(`/book/${campus.id}`),
    },
    {
      label: 'Schedule a Tour',
      onClick: toggleScheduleTourModal,
    },
    {
      label: 'Contact Us',
      onClick: () => null,
    },
  ]

  return (
    <Container {...other}>
      <TopWrapper>
        <LogoContainer>
          <CampusDropdown
            campus={campus}
            community={community}
            onChange={changeCampus}
          />
        </LogoContainer>
        {title && <Title>{title}</Title>}
        <Space />
        {/* <ButtonContainer>
          <MenuButton
            size="medium"
            color="neutral-4"
            icon={['fal', modalOpen ? 'times' : 'bars']}
            onClick={toggle}
          />
          {menuButtons.map(btn => (
            <ButtonLinkStyled onClick={btn.onClick}>
              {btn.label}
            </ButtonLinkStyled>
          ))}
        </ButtonContainer> */}
      </TopWrapper>
      {/* <BottomWrapper>
        <MenuOverlay open={modalOpen}>
          <MenuOverlayContent>
            {menuButtons.map(btn => (
              <MenuListButton onClick={btn.onClick}>
                <ButtonText>{btn.label}</ButtonText>
              </MenuListButton>
            ))}
          </MenuOverlayContent>
        </MenuOverlay>
      </BottomWrapper> */}
    </Container>
  )
}

Header.propTypes = {
  history: PropTypes.object,
  campus: PropTypes.object,
  community: PropTypes.object,
  toggleScheduleTourModal: PropTypes.func,
  onCampusClick: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

Header.defaultProps = {
  name: '',
}

Header.displayName = 'Header'

export default Header
