/* eslint-disable react/prop-types */
import React from 'react'
import moment from 'moment'
function TaskRow(props) {
  const { index, task } = props
  return (
    <div key={index} className="row border-bottom-1 mb-3">
      <div className="col-md-12">
        <p className="m-0">
          <span className="text-primary h6">
            {' '}
            {task.created_by_user &&
              `${task.created_by_user.first_name} ${task.created_by_user.last_name}`}
          </span>
          <span className="text-muted">
            {' '}
            {moment(task.created_at).format('MMM DD, YYYY hh:mm a')}
          </span>
        </p>
      </div>
      <div className="col-md-12 my-2">{task.description}</div>
    </div>
  )
}
TaskRow.displayName = 'TaskRow'
export default TaskRow
