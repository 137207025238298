/* eslint-disable prefer-spread */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'

// ASSETS
import Office from '../../../img/placeholders/conference_edit.jpg'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Offline, Online } from 'react-detect-offline'
import { toast } from 'react-toastify'
import Login from '@components/Login/Login'
import Spinner from '@global/Spinner'
import { getContrastingColor } from '@global/Colors/ColorUtils'
import moment from 'moment'
import * as socketMethods from './SocketFunctionConstants'
import * as roomSelectors from '@reduxSelectors/roomSelectors'

// DEPENDENCIES
import { Modal } from 'reactstrap'
import {
  format,
  addSeconds,
  setSeconds,
  addMinutes,
  getMinutes,
  getHours,
  setMinutes,
  setHours,
  isPast,
  isFuture,
  isEqual,
  isToday,
  isSameMinute,
  isWithinRange,
  compareAsc,
  parse,
  differenceInMinutes,
} from 'date-fns'

import { Prompt } from 'react-router-dom'

// REDUX BITS
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as uiActions from '@reduxActions/uiActions'
import * as bookingActions from '@reduxActions/bookingActions'
import * as communityActions from '@reduxActions/communityActions'
import * as userActions from '@reduxActions/userActions'

// CHILDREN
import MemberSelector from '../../Directory/Members/MemberSelector'
import Booking from './BookingCard'

import { updateCampusTablet } from '../../../services/apiService'

import * as axios from '../../../config/axios'
import { isEmpty } from '@global/Constants'
import { saturate, transparentize, lighten, linearGradient } from 'polished'

const brandSecondary = '#253847'

const primaryPulse = color => keyframes`
  from {
    background-color: ${saturate('75%', transparentize(0.05, color))};
  }
  to {
    background-color: ${saturate('0%', transparentize(0.1, color))};
  }
`
const DateText = styled.h3`
  color: ${props =>
    saturate('50%', transparentize(0.5, props.theme.colorWhite))};
`
const Container = styled.div`
  .text-colorized,
  .btn-colorized {
    color: ${props =>
      getContrastingColor(
        props.brandColor ? props.brandColor : props.theme.colorPrimary
      )};
  }

  .border-colorized,
  .btn-colorized {
    border-color: ${props =>
      getContrastingColor(
        props.brandColor ? props.brandColor : props.theme.colorPrimary
      )} !important;
  }
`

const ModalStyled = styled(Modal)`
  margin: 0 auto;
  .modal-content {
    height: 100vh;
    background: none;
    border: none;
  }
`
const FloatingButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 16px;
`
const RoomInfoPanel = styled.div`
  ${props => {
    if (props.booked) {
      return css`
        background: ${transparentize(0.05, brandSecondary)};
      `
    }
    return null
  }}
`
const BookingInfoPanel = styled.div`
  ${props => {
    if (props.booked) {
      return css`
        background: ${brandSecondary};
      `
    }
    return css`
      animation: ${primaryPulse(
          props.color ? props.color : props.theme.colorPrimary
        )}
        2s infinite alternate;
    `
  }}
`

const UpcomingContainer = styled.div`
  overflow-x: hidden;
`
const CLOCK_INTERVAL = 10000 // interval in miliseconds to time and update state
const WAIT_INTERVAL = 1000 * 30 // interval for the timeout of the login panel

class BookingTablet extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    room_actions: PropTypes.object,
    booking_actions: PropTypes.object,
    user_actions: PropTypes.object,
    community_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    room: PropTypes.object,
    bookings: PropTypes.array,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    user: PropTypes.object,
    rooms: PropTypes.array,
    isFetching: PropTypes.bool,
    roomToken: PropTypes.string,
    tabletKey: PropTypes.string,
    isTabletUser: PropTypes.bool,
  }

  static defaultProps = {
    tabletKey: null,
  }

  constructor(props) {
    super(props)
    console.log('[BookingTablet] constructor runs')
    let showJoinButton = true
    if (props.activeCommunity && props.activeCommunity.community_preference) {
      showJoinButton = Boolean(
        props.activeCommunity.community_preference
          .should_show_tablet_join_button
      )
    }
    this.state = {
      // UI State
      promptNavigation: false,
      upcomingChecked: false,
      adminAuth: false,
      errors: [],
      isAdminModalOpen: false,
      isBookNowModalOpen: false,
      cannotClick: false,
      // Booking State
      socket: null,
      roomId: null,
      assignedRoom: null,
      currentTime: new Date(),
      currentBooking: null,
      // vv This should eventually be the time of night that the room becomes unavailable if there is one-- or something...
      nextBookingStart: '10:00pm',
      bookings: [],
      upcomingBookings: [],
      showJoinButton,
      isLoading: false,
    }
  }

  componentDidMount() {
    this.pubSub()
    this.interval = window.setInterval(
      () => this.setTimeAndCheckBookings(),
      CLOCK_INTERVAL
    ) // CHANGE BACK to 30s or 60s
    if (!this.props.isTabletUser) {
      this.props.ui_actions.setIsTabletUser(true)
    }
    if (!this.props.roomToken) {
      const tabletKey = this.getTabletKey()
      this.props.room_actions.storeRoomToken(tabletKey)
    }
  }
  UNSAFE_componentWillMount() {
    const { user, history, isTabletUser } = this.props
    if (
      this.checkUser({
        shouldReplaceRoute: false, // do we want to redirect back to login
        user,
        history,
        isTabletUser,
      })
    ) {
      this.getCommunityPreferences()
      // poll for community preferences.
      this.preferencesPollingTimer = setInterval(
        () => this.getCommunityPreferences(),
        1000 * 60 * 30
      )
    }
  }
  componentWillUnmount() {
    this.preferencesPollingTimer = null
    clearInterval(this.interval)
    console.log('[BookingTablet] Will Unmount')
    socketMethods.closeSocket()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //todo replace with static getDerivedStateFromProps(props, state) {
    if (nextProps.rooms && this.state.roomId) {
      // this should only be entered if we didn't have this.props.rooms on load
      // or the room wasn't found in the list of this.props.rooms.
      // This will grab and set the interested room based on what was received through the socket (this.state.roomId)
      const roomId = this.state.roomId
      const nextRooms = nextProps.rooms

      // Should this loop through nextProps instead, in order to set the assignedRoom to reflect the incoming changes?
      for (let counter = 0; counter < nextRooms.length; counter++) {
        if (nextRooms[counter].id === roomId) {
          const tabletKey = this.getTabletKey()
          this.setState({
            assignedRoom: nextRooms[counter],
          })
          // this.setState({
          //   assignedRoom: this.props.rooms[counter],
          //   hasReceivedRooms: true,
          // });
          break
        }
      }
    }
    if (
      this.props.activeCommunity.community_preference !==
      nextProps.activeCommunity.community_preference
    ) {
      const showJoinButton = Boolean(
        nextProps.activeCommunity.community_preference
          .should_show_tablet_join_button
      )
      this.setState({
        showJoinButton,
      })
    }
  }

  pubSub() {
    // eslint-disable-line
    let socket
    let roomId
    socketMethods.connectToSocket()
    // socketMethods.sendTabletTypeOnRequest(() => {
    //   socketMethods.publishTabletType(TABLET_TYPE);
    // });

    // Publish tabletKey
    const tabletKey = this.getTabletKey()
    socketMethods.publishTabletKey(tabletKey)
    socketMethods.sendTabletKeyOnRequest(() => {
      socketMethods.publishTabletKey(tabletKey)
    })
    // Get roomId from socket based on tabletKey
    socketMethods.subscribeRoomId(roomId => {
      this.setRoom(roomId)
      this.registerConnect(roomId)
    })
    // Subscribe to Version Updates
    socketMethods.subscribeShouldTabletRefresh(modifiedVersion => {
      console.log(
        '[BookingTablet.js] gets that sweet sweet modifiedVersion, which is: ',
        modifiedVersion
      )
      /*if (modifiedVersion.should_update_tablet) {
        // don't know if this is the right model attribute reference.
        this.checkForUpdates(true);
      }*/
      this.checkForUpdates(true, modifiedVersion)
    })

    // Subscribe to live room changes
    socketMethods.subscribeRoomModifiedChannel(newRoomData => {
      this.handleModifiedRoom(newRoomData)
    })
    socketMethods.subscribeRoomDeleteChannel(deletedRoom => {
      this.handleDeletedRoom(deletedRoom)
    })
    // Get intial bookings from socket server using roomId
    socketMethods.subscribeInitialBookings(bookings => {
      this.setInitialBookings(bookings)
    })
    // Subscribe to the modifiedBooking channel
    socketMethods.subscribeBookingChannel(modifiedBooking => {
      this.handleIncomingBooking(modifiedBooking)
    })
    // Subscribe to the deletedBooking channel
    socketMethods.subscribeBookingDeleteChannel(deletedBooking => {
      this.handleRemovedBooking(deletedBooking)
    })
    socketMethods.subscribeToFailure(failure => {
      this.handleFailure(failure)
    })
    socketMethods.onDisconnect(cause => {
      this.registerDisconnect()
    })
  }
  checkUser = ({ shouldReplaceRoute, user, history }) => {
    if (!user || !user.authentication_token) {
      if (shouldReplaceRoute) {
        history.replace('/tablet-login')
      }
      return false
    }
    return true
  }

  handleActivityInLoginForm = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.setState({ isAdminModalOpen: false })
    }, WAIT_INTERVAL)
  }

  getTabletKey = () => {
    if (this.props.tabletKey) {
      return this.props.tabletKey
    }
    const pathname = this.props.location.pathname
    const pathSplit = pathname.split('/')
    return pathSplit[2]
  }

  registerConnect = (roomId, socket) => {
    console.log('[BookingTablet] register connect fires')
    const tabletKey = this.getTabletKey()
    const campusTablet = {
      tablet_key: tabletKey,
      is_active: true,
    }
    updateCampusTablet(campusTablet)
  }

  registerDisconnect = () => {
    console.log('[BookingTablet] register DISconnect fires')
    const tabletKey = this.getTabletKey()
    const campusTablet = {
      tablet_key: tabletKey,
      is_active: false,
    }
    updateCampusTablet(campusTablet)
  }

  checkForUpdates = (forceUpdate, modifiedVersion) => {
    // this will refresh the window grabbing the new code!
    if (forceUpdate) {
      window.swUpdate = true
    }
    if (window.swUpdate) {
      window.location = this.props.location.pathname
    }
  }

  /**
  |--------------------------------------------------
  | HANDLE ROOM CHANGES
  |--------------------------------------------------
  */
  handleModifiedRoom = newRoomData => {
    const isArchived = newRoomData.is_archived
    if (isArchived) {
      this.setState({ promptNavigation: false }, () => {
        this.props.history.replace('/tablet-login')
      })
    } else if (!isArchived) {
      this.setState(
        {
          hasReceivedRooms: false,
          roomId: newRoomData.id,
        },
        () => this.props.room_actions.getRoom(newRoomData.id)
      )
    }
  }

  handleDeletedRoom = deletedRoom => {
    // check deletedRoom.id against assignedRoom.id just to be thorough...

    if (deletedRoom.id === this.state.assignedRoom.id) {
      this.setState({ promptNavigation: false }, () => {
        this.props.history.replace('/tablet-login')
      })
    }
  }

  /**
  |--------------------------------------------------
  | MANAGE ROOM
  |--------------------------------------------------
  */

  setRoom = roomId => {
    this.setState({ roomId }, () => this.getRoomDetails(roomId))
  }

  getRoomDetails = roomId => {
    let interestedRoom = null
    if (this.props.rooms && this.props.rooms.length > 0) {
      // map through rooms and get the interested one
      for (let counter = 0; counter < this.props.rooms.length; counter++) {
        if (this.props.rooms[counter].id === roomId) {
          interestedRoom = this.props.rooms[counter]
          break
        }
      }
      this.setState({
        assignedRoom: interestedRoom,
        hasReceivedRooms: true,
      })
    }
    if (!interestedRoom) {
      // if it doesn't exist in this.props.rooms, then do a one-off call to get that specific one
      // setting the state will now be handled in ComponentWillReceiveProps
      this.props.room_actions.getRoom(roomId)
    }
  }

  /**
  |--------------------------------------------------
  | HANDLE BOOKINGS
  |--------------------------------------------------
  */

  setTimeAndCheckBookings = () => {
    this.setState({ currentTime: new Date() }, () => {
      this.cleanBookingState()
      this.getCurrentBooking()
    })
  }

  cleanBookingState = () => {
    const { bookings } = this.state
    const newBookings = bookings.filter(booking => !isPast(booking.end_time))
    this.setState({ bookings: newBookings })
  }

  setInitialBookings = initialBookings => {
    const bookings = initialBookings.filter(booking =>
      isFuture(booking.end_time)
    )
    this.setState({ bookings }, () => this.setTimeAndCheckBookings())
  }

  // archiveDoneBookings() {
  //   const bookings = this.state.bookings;
  //   bookings.forEach(booking => {
  //     const timeData = this.adjustForTimezone(booking.start_time, booking.end_time);
  //     if (timeData.currentTime.isAfter(timeData.bookingEnd, 'minute')) {
  //       this.archiveBooking(null, booking);
  //     }
  //   });
  // }
  handleFailure = failure => {
    toast.warn(`<h5>${failure.message}.</h5>`)
    this.setState({ cannotClick: false })
  }
  getCommunityPreferences() {
    this.props.community_actions.getCommunityPreference(
      this.props.activeCommunity.id,
      {}
    )
  }

  getCurrentBooking() {
    const { bookings } = this.state
    const currentBooking = bookings.filter(booking =>
      isWithinRange(
        new Date().toISOString(),
        booking.start_time,
        booking.end_time
      )
    )[0]
    this.setState({ currentBooking }, () =>
      this.setUpcomingBookings(currentBooking)
    )
  }

  handleIncomingBooking = modifiedBooking => {
    let bookings = [...this.state.bookings]
    modifiedBooking.start_time = this.addZ(modifiedBooking.start_time) // eslint-disable-line
    modifiedBooking.end_time = this.addZ(modifiedBooking.end_time) // eslint-disable-line

    if (modifiedBooking.is_archived) {
      bookings = bookings.filter(booking => booking.id !== modifiedBooking.id)
    } else {
      if (
        isSameMinute(
          parse(modifiedBooking.end_time),
          new Date().toISOString()
        ) ||
        isPast(modifiedBooking.end_time)
      ) {
        bookings = bookings.filter(booking => booking.id !== modifiedBooking.id)
      }
      if (
        isFuture(modifiedBooking.end_time) &&
        !isSameMinute(parse(modifiedBooking.end_time), new Date().toISOString())
      ) {
        const isUpdate = bookings.find(
          booking => booking.id === modifiedBooking.id
        )
        if (isUpdate) {
          // modifiedBooking is already in local state
          bookings = bookings.filter(
            booking => booking.id !== modifiedBooking.id
          ) // remove previous copy of that booking
        }
        bookings.push(modifiedBooking) // if it is an update, it's copy has been removed. If not, it's just added on.
      } else {
        console.log('modified booking ends in the past') //eslint-disable-line
      }
    }
    console.log('Bookings before setting as state: ', bookings)
    this.props.ui_actions.setIsFetching(false)
    this.setState({ bookings, cannotClick: false }, () =>
      this.getCurrentBooking()
    )
  }

  handleRemovedBooking(removedBooking) {
    let bookings = this.state.bookings.slice()
    bookings = bookings.filter(booking => {
      return booking.id !== removedBooking.id
    })
    this.setState({ bookings }, () => this.getCurrentBooking())
  }

  setUpcomingBookings(currentBooking) {
    const { bookings } = this.state
    const books = [...bookings]
    const now = new Date().toISOString()
    //NEVER SHOW PAST EVENTS IN UPCOMING BOOKINGS
    const upcomingBooks = books.filter(booking => isFuture(booking.end_time))
    const sortedBookings = upcomingBooks.sort((bookingA, bookingB) =>
      compareAsc(bookingA.start_time, bookingB.start_time)
    )

    if (!currentBooking) {
      this.setState({ upcomingBookings: sortedBookings })
    } else {
      const upcomingBookings = sortedBookings.filter(
        booking => booking.id !== currentBooking.id
      )
      this.setState({ upcomingBookings })
    }
  }

  getOpenUntil() {
    const { upcomingBookings } = this.state
    if (this.state.upcomingBookings.length > 0) {
      const sortedBookings = upcomingBookings.sort((bookingA, bookingB) =>
        compareAsc(bookingA.start_time, bookingB.start_time)
      )
      const nextBooking = sortedBookings[0]
      const timeOfNext = nextBooking.start_time
      return timeOfNext
    }
    return null
  }

  /**
|--------------------------------------------------
| BOOKING TABLET ACTIONS
|--------------------------------------------------
*/
  bookNow = bookedBy => {
    const {
      activeCampus: { id: campusId },
      location: { pathname },
      user: bookedByUser,
    } = this.props
    const {
      assignedRoom: { id: roomId },
    } = this.state
    const tabletKey = this.getTabletKey()

    //fix this for member picker
    //const bookedByUser = bookedBy || this.props.user;

    /* -- Use this if booking window is 15 minutes and start time is arbitrary
    const newbookingStart = new Date().toISOString();
    const newBookingEnd = addMinutes(now, 15);
  */
    const now = new Date().toISOString()
    const currentMinutes = getMinutes(now)

    let newBookingStart
    let newBookingEnd
    if (currentMinutes < 30) {
      newBookingStart = new Date()
      newBookingStart.setMinutes(0)
      newBookingStart.setSeconds(1, 0)
      newBookingEnd = addMinutes(newBookingStart, 30)
      newBookingEnd.setSeconds(0, 0)
    } else if (currentMinutes >= 30) {
      newBookingStart = new Date()
      newBookingStart.setMinutes(30)
      newBookingStart.setSeconds(1, 0)
      newBookingEnd = addMinutes(newBookingStart, 30)
      newBookingEnd.setSeconds(0, 0)
    }

    console.log('new booking start: ', format(newBookingStart))
    console.log('new booking end: ', newBookingEnd)

    const obj = {
      start_time: now, // TODO: @DeShawn @Tanner, if we want booking start to be rounded down to the last 30 minute interval, change 'now' here to 'newBookingStart'
      end_time: newBookingEnd,
      name: 'Walk Up Booking',
      description: 'WALK-UP',
      type: 'Booking',
      booked_by_tablet_key: tabletKey,
      booked_by_client_type: 1,
      created_by_source: 3,
      room_id: roomId,
      user_id: bookedByUser.id,
      original_user_id: bookedByUser.id,
      campus_id: campusId,
    }

    this.sendBooking(obj)
  }

  endBooking = event => {
    const booking = this.state.currentBooking
    const bookingId = booking.id
    const now = new Date().toISOString()
    const originalEndTime = booking.end_time
    console.log('booking to end: ', booking)
    const obj = {
      id: bookingId,
      end_time: now,
      original_end_time: originalEndTime,
    }

    console.log('Ending booking by changing end time...insterting obj: ', obj)
    this.sendBooking(obj)
  }

  handleLoginSuccess = () => {
    // logged in successfully
    this.setState({ isLoading: true })
    this.props.room_actions
      .getRoomsAtCampus(this.props.activeCampus.id, {
        types: JSON.stringify(['Conference', 'Space', 'Equipment']),
      })
      .then(() => {
        this.props.room_actions.storeRoomToken(null)
        this.props.history.push('/tablet-type')
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleAddTime = () => {
    const { currentBooking } = this.state
    // const timeFormated = this.adjustForTimezone(this.state.currentBooking.end_time);
    const currentEndTime = currentBooking.end_time
    const nextBookingStart = this.getOpenUntil()
    const nextAndCurrentDiff = nextBookingStart
      ? differenceInMinutes(nextBookingStart, currentEndTime)
      : null
    const isOverFifteenAway = nextAndCurrentDiff >= 15
    const isUnderFifteen = nextAndCurrentDiff < 15 && nextAndCurrentDiff > 1

    console.log('nextAndCurrentDiff is: ', nextAndCurrentDiff)

    let newEndTime
    let shouldUpdate = false

    if (!nextBookingStart || isOverFifteenAway) {
      // Next booking either doesn't exist, or is over fifteen minutes away
      newEndTime = addMinutes(currentEndTime, 15) //currentEndTime.add(15, 'minutes').utc().local().format('YYYY-MM-DDTHH:mm:ss.sssZ');
      shouldUpdate = true
    } else if (isUnderFifteen) {
      // next booking is between 1 and 15 minutes away, so just add that amount of time to the booking
      const timeToNext = nextAndCurrentDiff
      newEndTime = addMinutes(currentEndTime, timeToNext)
      shouldUpdate = true
    } else {
      // next booking is 1 or fewer minutes away-- don't UPDATE and don't add, warn user
      shouldUpdate = false
      toast.warn(
        `<h5>Sorry! This room is booked at ${format(
          parse(nextBookingStart),
          'h:mm a'
        )}.</h5>`
      )
      return
    }

    const obj = {
      id: this.state.currentBooking.id,
      end_time: newEndTime,
    }

    if (shouldUpdate) {
      this.sendBooking(obj)
    }
  }

  sendBooking(booking) {
    this.props.ui_actions.setIsFetching(true)
    this.setState({ cannotClick: true })
    socketMethods.publishBooking(booking)
  }

  // bookingAuth() {
  //   this.setState({
  //     primary: !this.state.primary,
  //     adminAuth: false,
  //   });
  // }

  /**
  |--------------------------------------------------
  | HANDLE UI
  |--------------------------------------------------
  */
  toggleAdminModal = () => {
    if (!this.state.isAdminModalOpen) {
      this.handleActivityInLoginForm()
    }
    this.setState({ isAdminModalOpen: !this.state.isAdminModalOpen })
  }

  toggleBookNowModal = () => {
    this.setState({ isBookNowModalOpen: !this.state.isBookNowModalOpen })
  }

  seeUpcoming = () => {
    this.setState({ upcomingChecked: !this.state.upcomingChecked })
  }

  /**
  |--------------------------------------------------
  | AUXILIARY FUNCS
  |--------------------------------------------------
  */
  addZ = time => {
    const hasUTC = /z/i.test(time)
    if (hasUTC) {
      return time
    }
    return `${time}Z`
  }

  // bookNow

  /**
  |--------------------------------------------------
  | COMPONENTS -- TODO: modularize this at some point.
  |--------------------------------------------------
  */
  getTitle() {
    const { currentBooking } = this.state
    if (!currentBooking) return null

    return (
      <React.Fragment>
        <h3 className="text-center text-colorized">
          {/* {currentBooking.hosted_by ? `${currentBooking.hosted_by}'s ` : null}Meeting in Progress */}
          {currentBooking.name}
        </h3>
        {currentBooking.user ? (
          <h6 className="text-colorized">
            {`${currentBooking.user.first_name} ${currentBooking.user.last_name}`}
          </h6>
        ) : null}
      </React.Fragment>
    )
  }

  roomButton() {
    const { currentBooking, cannotClick } = this.state
    if (currentBooking) {
      // const booking = this.state.currentBooking;
      // const adjustedTimes = this.adjustForTimezone(booking.start_time, booking.end_time);
      const startTime = format(currentBooking.start_time, 'h:mm a')
      const endTime = format(currentBooking.end_time, 'h:mm a')

      return (
        <div className="booked-buttons">
          <div className="row d-flex justify-content-center">
            <div className="col d-flex justify-content-center align-items-center">
              <h4 className="my-0 text-center text-colorized">
                {`${startTime} - ${endTime}`}
              </h4>
            </div>
            {/* Removed this while collision detection is not being accounted for. */}
            {/*<div className='col d-flex justify-content-center align-items-center'>
              <button disabled={cannotClick} className='my-0 btn btn-rounded w-100 btn-outline-white  mx-2' onClick={this.handleAddTime} >
                Add Time
              </button>
            </div>*/}
          </div>
          {/* Removed this while the function is currently broken. */}
          {/*<div className='row mt-5'>
            <button disabled={cannotClick} className='btn btn-rounded w-50 btn-outline-white  mx-2 w-100 mt-4 ' onClick={this.endBooking}>
              End{currentBooking.hosted_by ? ` ${currentBooking.hosted_by}'s` : ''} Meeting
            </button>
          </div>*/}
        </div>
      )
    }
    return (
      <div>
        <h4 className="text-center text-colorized">
          {isToday(this.getOpenUntil())
            ? `Until ${format(this.getOpenUntil(), 'h:mma')}`
            : null}
        </h4>
        {this.state.showJoinButton && (
          <button
            disabled={cannotClick || !this.state.assignedRoom}
            className="btn btn-rounded btn-outline-white btn-block btn-lg btn-colorized"
            onClick={this.bookNow}
          >
            Book Now
          </button>
        )}
      </div>
    )
  }

  renderUpcoming() {
    const upcoming = this.state.upcomingBookings
    if (upcoming.length > 0) {
      let key = 1
      return upcoming.map(booking => {
        return <Booking booking={booking} key={key++} />
      })
    }
    return null
  }

  render() {
    const { isFetching, activeCommunity } = this.props
    const { community_preference } = activeCommunity
    const {
      assignedRoom,
      currentTime,
      currentBooking,
      upcomingChecked,
      isAdminModalOpen,
      isBookNowModalOpen,
      promptNavigation,
    } = this.state
    const room = assignedRoom
    const roomName = room ? room.name : null
    const blockingMessage =
      'Leaving this page will reset this device. Administrative access will be required to reinstate.'
    const defaultColor =
      community_preference && community_preference.branding_primary_color
        ? community_preference.branding_primary_color
        : null
    const url = room && room.room_photo ? room.room_photo.file_url : Office

    return (
      <Container
        id="BookingTablet"
        className={`container-fluid room-${
          currentBooking ? 'booked ' : 'open'
        }`}
        style={{ backgroundImage: `url(${url})`, backgroundSize: 'cover' }}
        // brandColor={defaultColor}
      >
        {' '}
        {/*change this to room.imageURL*/}
        {promptNavigation ? <Prompt message={blockingMessage} /> : null}
        <Offline>
          <div className="col-md-12">
            <div className="text-white bg-danger text-center card">
              <div className="card-body">No internet detected.</div>
            </div>
          </div>
        </Offline>
        <div className="row d-flex">
          <RoomInfoPanel
            className="col-6 booking-room-description p-3 d-flex flex-column justify-content-between"
            booked={currentBooking}
          >
            <div>
              <h2 className="booking-room-time">
                {format(parse(currentTime), 'h:mm a')}
              </h2>
              <DateText
              // color={defaultColor}
              >
                {format(parse(currentTime), 'MMMM D YYYY')}
              </DateText>
              <h1 className="mt-5 booking-room-name">{roomName}</h1>
            </div>
          </RoomInfoPanel>
          <BookingInfoPanel
            // color={defaultColor}
            booked={currentBooking}
            className="col-6 booking-room-sidebar d-flex justify-content-between flex-column align-items-center"
          >
            <div />
            <div className="w-100">
              {this.getTitle()}
              <h1 className="text-center text-colorized booking-room-status">
                {currentBooking ? 'Booked ' : 'Open '}
              </h1>
              {this.roomButton()}
            </div>
            <div className="w-100">
              <p className="text-center">
                <button
                  className="btn btn-rounded btn-outline-white mx-auto text-center btn-colorized"
                  onClick={() => this.seeUpcoming()}
                >
                  {!upcomingChecked ? 'See ' : 'Hide '}
                  Upcoming
                </button>
              </p>
              <UpcomingContainer
                className={`booking-room-cards w-100 border-colorized ${
                  upcomingChecked ? 'active' : 'inactive'
                }`}
              >
                {this.renderUpcoming()}
              </UpcomingContainer>
            </div>
          </BookingInfoPanel>
        </div>
        <ModalStyled
          isOpen={isAdminModalOpen}
          toggle={this.toggleAdminModal}
          className="w-100"
          id="AdminAuth"
        >
          {this.state.isLoading ? (
            <Spinner />
          ) : (
            <Login
              history={history}
              titleText={'Booking Tablet'}
              handleLoginSuccess={this.handleLoginSuccess}
              hideFooterLinks
              notifyOfEvent={this.handleActivityInLoginForm}
              onClose={this.toggleAdminModal}
            />
          )}
        </ModalStyled>
        <Modal
          isOpen={isBookNowModalOpen}
          toggle={this.toggleBookNowModal}
          id="BookNowAuth"
        >
          <MemberSelector
            toggleModal={this.toggleBookNowModal}
            action={this.bookNow}
            campusId={this.props.activeCampus.id}
            type="Book"
          />
        </Modal>
        <FloatingButton
          className="btn btn-outline-white btn-sm"
          onClick={this.toggleAdminModal}
        >
          <FontAwesomeIcon icon="lock" /> Admin
        </FloatingButton>
      </Container>
    )
  }
}
BookingTablet.displayName = 'BookingTablet'

const mapStateToProps = state => {
  return {
    rooms: roomSelectors.getRoomsList(state),
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetching: state.ui.isFetching,
    user: state.user,
    roomToken: state.ui.roomToken,
    isTabletUser: state.ui.isTabletUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
    community_actions: bindActionCreators(communityActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingTablet)
