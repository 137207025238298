import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TotalTextRows from '../TotalTextRows'

const BookingDetails = styled.div`
  flex: 2;
  padding: 12px 24px;
  border-bottom: 1px solid #ddd;
`
const Summary = styled.div`
  flex: 1;
  border-bottom: 1px solid #ddd;
  padding: 12px 24px;
`
const Subtitle = styled.h5`
  display: flex;
  font-size: 18px;
`
const TotalTextRowsStyled = styled(TotalTextRows)``

const CheckoutDetails = ({ detailData = [], subtitle, totalData }) => {
  const detailRows = list =>
    list.map(item => (
      <div>
        <b>{`${item.title}:`}</b>
        {` ${item.text}`}
      </div>
    ))

  return (
    <React.Fragment>
      {detailData.length > 0 && (
        <BookingDetails>{detailRows(detailData)}</BookingDetails>
      )}
      <Summary>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <TotalTextRowsStyled rows={totalData} />
      </Summary>
    </React.Fragment>
  )
}

CheckoutDetails.displayName = 'CheckoutDetails'

CheckoutDetails.propTypes = {
  detailData: PropTypes.array,
  subtitle: PropTypes.string,
  totalData: PropTypes.array,
}

export default CheckoutDetails
