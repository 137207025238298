import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import moment from 'moment'
import Badge from '@global/Base/Badge/Badge'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import Toggle from '@bit/coworks.base-components.toggle'
import Spinner from '@global/Spinner'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const Bottom = styled.div``
const Title = styled.h5``
const Description = styled.p`
  margin-bottom: 8px;
`
const Buttons = styled.div``
const ToggleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  min-width: 300px;
  .loading-spinner-contextual {
    position: absolute;
    left: -40px;
    top: -13px;
    display: ${props => (props.loading ? 'block' : 'none')};
  }
`
const BadgeStyled = styled(Badge)`
  margin-left: 4px;
`
const TextButtonStyled = styled(TextButton)`
  padding: 0px;
  margin-right: 24px;
`
const SpinnerStyled = styled(Spinner)``

const FormOption = ({
  option,
  active = false,
  onSwitch,
  onEditClick,
  className,
  disableSwitch,
}) => {
  const [loading, setLoading] = useState(false)
  const triggerSwitch = async () => {
    setLoading(true)
    await onSwitch(option)
    setLoading(false)
  }
  return (
    <Container className={className}>
      <Top>
        <Title>
          {option.name}
          {option.newUntilBadgeDate &&
            moment(option.newUntilBadgeDate).isAfter(moment()) && (
              <BadgeStyled>New</BadgeStyled>
            )}
        </Title>
        {typeof active === 'boolean' && (
          <ToggleWrapper loading={loading}>
            <Toggle
              onChange={triggerSwitch}
              checked={active}
              disabled={disableSwitch}
              label={option.toggleLabel}
              description={option.toggleDescription}
            />
            <SpinnerStyled size="xs" />
          </ToggleWrapper>
        )}
      </Top>
      <Bottom>
        <Description className="text-muted">{option.description}</Description>
        <Buttons>
          {onEditClick && (
            <TextButtonStyled onClick={() => onEditClick()}>
              <FontAwesomeIcon icon="cog" />{' '}
              {option.editAction && option.editAction.name
                ? option.editAction.name
                : 'Edit Settings'}
            </TextButtonStyled>
          )}
          {option.showCopyUrlButton !== false ? (
            <TextButtonStyled
              onClick={() => {
                const elem = document.createElement('textarea')
                document.body.appendChild(elem)
                elem.value = option.url
                elem.select()
                document.execCommand('copy')
                document.body.removeChild(elem)
                toast.success(
                  `Copied the ${
                    typeof option.name === 'string'
                      ? `${option.name} form`
                      : 'feature'
                  } link!`
                )
              }}
            >
              <FontAwesomeIcon icon="copy" /> Copy link
            </TextButtonStyled>
          ) : null}
          <TextButtonStyled
            onClick={() => {
              window.open(option.url, '_blank')
            }}
          >
            <FontAwesomeIcon icon="external-link-alt" /> View
          </TextButtonStyled>
        </Buttons>
      </Bottom>
    </Container>
  )
}

FormOption.displayName = 'FormOption'

FormOption.propTypes = {
  toggle: PropTypes.func,
  onEditClick: PropTypes.func,
  onSwitch: PropTypes.func,
  active: PropTypes.bool,
  disableSwitch: PropTypes.bool,
  history: PropTypes.object,
  option: PropTypes.object,
  className: PropTypes.string,
  toggleLabel: PropTypes.string,
  toggleDescription: PropTypes.string,
}

export default FormOption
