/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import Header from './Header'
import Body from './Body'
import Row from './Row'
import Placeholder from '@global/Placeholder'
import Spinner from '@global/Spinner'

const Container = styled.div`
  flex: 1 0 auto;
`
const SpinnerContainer = styled.div``
const TableStyled = styled.table`
  width: 100%;
  table-layout: fixed;
`

function List({
  columns,
  className,
  headerStyle,
  rowStyle,
  onSort,
  objects,
  isLoading,
  type,
  placeholderSubtext1,
  placeholderSubtext2,
  hideHeader,
  icon,
  placeholderTitle,
  placeholderButtonText,
  placeholderHandleClick,
  renderItem,
  ...other
}) {
  const createState = cols => {
    const state = {}
    cols.forEach(col => {
      if (col.options && col.options.sort) {
        state[col.slug] = col.options.sort === 'asc'
      } else if (col.slug) {
        state[col.slug] = null
      }
    })
    return state
  }

  const initialState = createState(columns)
  const [sort, setColumnSort] = useState(initialState)

  useEffect(() => {
    setColumnSort(initialState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortColumns = (state, action) => {
    const newState = {}
    Object.keys(state).map(key => {
      newState[key] = null
      if (action.col === key) {
        newState[key] = action.type
      }
    })
    setColumnSort(newState)
  }

  const renderListItem = (info, index) => {
    return (
      <Row
        key={`list-item-${index}`}
        data={info}
        index={index}
        style={rowStyle}
        columns={columns}
      />
    )
  }
  const renderContent = bodyOnly => {
    if (isLoading && !bodyOnly) {
      return objects && objects.length === 0 ? (
        <SpinnerContainer className="col-md-12 d-flex justify-content-center">
          <Spinner />
        </SpinnerContainer>
      ) : (
        <div className="col-md-12 d-flex justify-content-center">
          <div className="h6 text-primary">Loading...</div>
        </div>
      )
    } else if (objects && objects.length === 0 && !isLoading && !bodyOnly) {
      return (
        <Placeholder
          title={placeholderTitle ? placeholderTitle : `No ${type}`}
          icon={icon}
          subtext1={
            placeholderSubtext1
              ? placeholderSubtext1
              : `You currently have no ${type} in this campus.`
          }
          subtext2={placeholderSubtext2}
          buttonText={placeholderButtonText}
          handleClick={placeholderHandleClick}
        />
      )
    } else if (bodyOnly) {
      return <Body renderItem={renderListItem} objects={objects} {...other} />
    }
    return null
  }
  const clickedHeader = (col, type) => {
    const isAscending = !type
    if (!isLoading) {
      sortColumns(initialState, { col, type: isAscending })
      onSort(col, isAscending)
    }
  }

  return (
    <Container className={className}>
      <TableStyled>
        {!hideHeader && (
          <Header
            columns={columns}
            sortInfo={sort}
            style={headerStyle}
            onClick={clickedHeader}
          />
        )}
        {renderContent(true)}
      </TableStyled>
      {renderContent()}
    </Container>
  )
}

List.propTypes = {
  data: PropTypes.array,
  isFetching: PropTypes.bool,
  pagination: PropTypes.object,
  headerStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  columns: PropTypes.array,
  nextPage: PropTypes.func,
  onSort: PropTypes.func,
  objects: PropTypes.array,
  canEdit: PropTypes.bool,
  hideHeader: PropTypes.bool,
  totalPages: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  renderItem: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  ancestor: PropTypes.any,
  placeholderSubtext1: PropTypes.string,
  placeholderSubtext2: PropTypes.string,
  icon: PropTypes.string,
  removeWrapper: PropTypes.bool,
  placeholderTitle: PropTypes.string,
  placeholderButtonText: PropTypes.string,
  placeholderHandleClick: PropTypes.string,
}

List.defaultProps = {
  data: [],
}

List.displayName = 'List'

export default List
