/* eslint-disable react/prop-types,no-trailing-spaces */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import defaultUserPic from '../../../img/placeholders/otto_black_white.jpeg'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as Constants from '../../Global/Constants'
import styled, { css } from 'styled-components'
import { DropdownToggleOutlineStyled } from '../../Global/BaseComponents'
import Anchor from '@global/Base/Anchor/Anchor'
import RoundedImage from '@global/RoundedImage'
import { IconDot } from '@global/Icons'
import { toast } from 'react-toastify'

const Container = styled.div`
  ${props => {
    if (props.inactive) {
      return css`
        .text-muted {
          color: #778992 !important;
        }
      `
    }
    return null
  }}
`
const InfoContainer = styled.div`
  opacity: ${props => (props.inactive ? 0.6 : 1)};
`
const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colorPrimary};
`
const StatusIcon = styled(IconDot)`
  position: absolute;
  bottom: 0;
  left: -4px;
  font-size: 10px;
  border: solid #ffff 1px;
  background: ${props => props.theme.colorSuccess};
`
const ImageContianer = styled.div`
  position: relative;
  margin: auto;
  opacity: ${props => (props.inactive ? 0.6 : 1)};
`

const MemberRow = ({
  member,
  team,
  index,
  toggleDropdown,
  editMemberClicked,
  viewMemberClicked,
  viewMemberBilling,
  teamLinkClicked,
  dropdownsOpen,
  handlePromoteToAdmin,
  showEdit,
  showActions,
  includeMarginsAndPadding,
  includePhoto,
  canViewEditBilling,
  role,
  loading,
  memberCheckInClicked,
  memberCheckOutClicked,
  canCheckInMember,
  isInactive,
}) => {
  const [activeCheckIn, setActiveCheckIn] = useState(
    Constants.getActiveCheckIn(member.checkins)
  )
  const canEditBilling =
    canViewEditBilling !== undefined ? canViewEditBilling : false
  const canCheckIn = canCheckInMember !== undefined ? canCheckInMember : false
  const name = Constants.toTitleCase(`${member.first_name} ${member.last_name}`)
  const photo = member.user_photo
  const { title, email } = member
  let isAdmin = null
  if (
    team &&
    team.admins &&
    team.admins.length > 0 &&
    team.admins[0].id === member.id
  ) {
    isAdmin = true
  }
  let showActionButtons = true
  if (showActions !== null && showActions !== undefined) {
    showActionButtons = showActions
  }
  let showMarginsAndPadding = true
  if (
    includeMarginsAndPadding !== null &&
    includeMarginsAndPadding !== undefined
  ) {
    showMarginsAndPadding = includeMarginsAndPadding
  }
  let showPhoto = true
  if (includePhoto !== null && includePhoto !== undefined) {
    showPhoto = includePhoto
  }

  function interceptMemberClicked(event, shouldEdit) {
    if (viewMemberClicked || editMemberClicked) {
      event.stopPropagation()
      if (shouldEdit) {
        if (editMemberClicked) {
          editMemberClicked(member, team)
        }
      } else if (viewMemberClicked) {
        viewMemberClicked(member, team)
      } else if (editMemberClicked) {
        editMemberClicked(member, team)
      }
    }
  }

  function teamClicked(event) {
    event.stopPropagation()
    teamLinkClicked(team, member)
  }
  function interceptToggleDropdown(event) {
    event.stopPropagation()
    toggleDropdown(index)
  }
  function checkInUser() {
    memberCheckInClicked({ user_id: member.id }).then(response => {
      setActiveCheckIn(response.checkin)
      toast.success(`Successfully Checked In`)
    })
  }
  function checkOutUser() {
    memberCheckOutClicked(activeCheckIn.id).then(() => {
      setActiveCheckIn(null)
      toast.success(`Successfully Checked Out`)
    })
  }
  function getCheckinButton() {
    const isLoading =
      loading !== undefined && loading !== null ? loading : false

    if (canCheckIn && memberCheckInClicked && memberCheckOutClicked) {
      if (!activeCheckIn) {
        return (
          <DropdownItem disabled={isLoading} onClick={checkInUser}>
            Check In
          </DropdownItem>
        )
      }
      return (
        <DropdownItem disabled={isLoading} onClick={checkOutUser}>
          Check Out
        </DropdownItem>
      )
    }
    return null
  }
  const isLoading = loading !== undefined && loading !== null ? loading : false
  return (
    <Container
      key={index}
      className={`${showMarginsAndPadding && 'border-bottom-1 mt-2'}`}
      inactive={isInactive}
    >
      <div className={`row ${showMarginsAndPadding && 'p-2'}`}>
        {showPhoto && (
          <div className="col-12 col-md-1 mr-2 d-flex align-items-center">
            <Anchor onClick={interceptMemberClicked}>
              <ImageContianer inactive={isInactive}>
                <RoundedImage
                  className="img-responsive"
                  photo={photo}
                  alt="Picture"
                  size="medium"
                />
                {activeCheckIn && (
                  <StatusIcon size={18} icon={'map-marker-check'} />
                )}
              </ImageContianer>
            </Anchor>
          </div>
        )}
        <InfoContainer
          inactive={isInactive}
          className={!showPhoto && !showActionButtons ? 'col-12' : 'col-8'}
        >
          <h4>
            <Anchor
              color="secondary"
              className="my-0 p-0"
              onClick={interceptMemberClicked}
            >
              {name}
            </Anchor>
            <span className="text-muted h6"> {title ? title : ''}</span>
          </h4>
          {teamLinkClicked && (
            <h6>
              <Anchor className="my-0 p-0" onClick={teamClicked}>
                <FontAwesomeIconStyled icon="briefcase" />{' '}
                {team ? `${team.name}` : ''}{' '}
                {team && team.status === 'active' && isInactive && (
                  <span className="text-muted">(active)</span>
                )}
              </Anchor>
            </h6>
          )}
          {role && (
            <h6>
              <span className="my-0 p-0">
                <FontAwesomeIconStyled icon="user" /> {role}
              </span>
            </h6>
          )}
          <h6>
            {email && (
              <span>
                <FontAwesomeIconStyled icon="envelope" /> {email}
              </span>
            )}
          </h6>
          {isAdmin && <h6 className="text-muted"> Team Admin</h6>}
        </InfoContainer>
        {showActionButtons && (
          <div className="col-12 col-md-2 d-flex justify-content-end align-items-center">
            {showEdit ? (
              <ButtonDropdown
                isOpen={dropdownsOpen[index]}
                toggle={interceptToggleDropdown}
                disabled={isLoading}
              >
                <DropdownToggleOutlineStyled caret disabled={isLoading}>
                  Member Actions
                </DropdownToggleOutlineStyled>
                <DropdownMenu>
                  <DropdownItem
                    disabled={isLoading}
                    onClick={interceptMemberClicked}
                  >
                    View
                  </DropdownItem>
                  {editMemberClicked && !isInactive ? (
                    <DropdownItem
                      disabled={isLoading}
                      onClick={event => interceptMemberClicked(event, true)}
                    >
                      Edit
                    </DropdownItem>
                  ) : null}
                  {!isInactive && getCheckinButton()}
                  {canEditBilling && viewMemberBilling && (
                    <DropdownItem
                      disabled={isLoading}
                      onClick={() => viewMemberBilling(member)}
                    >
                      View Team Billing
                    </DropdownItem>
                  )}
                  {handlePromoteToAdmin && !isAdmin ? (
                    <DropdownItem
                      disabled={isLoading}
                      onClick={() => handlePromoteToAdmin(member, team)}
                    >
                      Promote to Team Admin
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </ButtonDropdown>
            ) : (
              <Button
                className="btn btn-outline-primary"
                onClick={interceptMemberClicked}
              >
                View
              </Button>
            )}
          </div>
        )}
      </div>
    </Container>
  )
}

MemberRow.displayName = 'MemberRow'

export default MemberRow
