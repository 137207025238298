import * as apiService from '../services/apiService'
import * as uiActions from './uiActions'
import * as actionTypes from './actionTypes'

export function setAnnouncements(announcements, meta = null) {
  return { type: actionTypes.SET_ANNOUNCEMENTS, announcements, meta }
}

export function successCreatingAnnouncement(announcement) {
  return { type: actionTypes.CREATE_ANNOUNCEMENT, announcement }
}
export function successArchivingAnnouncement(announcement) {
  return { type: actionTypes.DELETE_ANNOUNCEMENT, announcement }
}

// ACTION DISPATCHERS
export function getAnnouncements(campusId, params) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .getAnnouncements(campusId, params)
      .then(response =>
        dispatch(setAnnouncements(response.announcements, response.meta))
      )
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}

export function createAnnouncement(announcement) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .createAnnouncement(announcement)
      .then(response =>
        dispatch(successCreatingAnnouncement(response.announcement))
      )
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}
export function archiveAnnouncement(announcementId) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .updateAnnouncement(announcementId, { status: 'archived' })
      .then(response =>
        dispatch(successArchivingAnnouncement(response.announcement))
      )
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}
