// This file is used to tell fontawesome only which icons to load.
import { library } from '@fortawesome/fontawesome'

import {
  faEnvelope as farEnvelope,
  faCalendarAlt as farCalendarAlt,
  faBuilding as farBuilding,
  faTachometerAlt as farTachometerAlt,
  faUsers as farUsers,
  faMoneyCheckAlt as farMoneyCheckAlt,
  faUserPlus as farUserPlus,
  faCreditCard as farCreditCard,
  faBook as farBook,
  faCoffee as farCoffee,
  faDoorOpen as farDoorOpen,
  faCog as farCog,
  faAngleUp as farAngleUp,
  faAngleDown as farAngleDown,
  faAngleLeft as farAngleLeft,
  faAngleRight as farAngleRight,
  faSearch as farSearch,
  faFilter as farFilter,
  faSort as farSort,
  faBars as farBars,
  faTimes as farTimes,
  faPlus as farPlus,
  faBell as farBell,
  faCommentAlt as farCommentAlt,
  faQuestionCircle as farQuestionCircle,
  faExclamationCircle as farExclamationCircle,
  faExclamationTriangle as farExclamationTriangle,
  faInfoCircle as farInfoCircle,
  faCheckCircle as farCheckCircle,
  faMapMarkerCheck as farMapMarkerCheck,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faEnvelope as falEnvelope,
  faCalendarAlt as falCalendarAlt,
  faBuilding as falBuilding,
  faTachometerAlt as falTachometerAlt,
  faUsers as falUsers,
  faMoneyCheckAlt as falMoneyCheckAlt,
  faUserPlus as falUserPlus,
  faCreditCard as falCreditCard,
  faBook as falBook,
  faCoffee as falCoffee,
  faDoorOpen as falDoorOpen,
  faCog as falCog,
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faAngleLeft as falAngleLeft,
  faAngleRight as falAngleRight,
  faSearch as falSearch,
  faFilter as falFilter,
  faSort as falSort,
  faBars as falBars,
  faTimes as falTimes,
  faPlus as falPlus,
  faBell as falBell,
  faCommentAlt as falCommentAlt,
  faQuestionCircle as falQuestionCircle,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faInfoCircle as falInfoCircle,
  faCheckCircle as falCheckCircle,
  faSync as falSync,
  faEnvelopeOpenText as falEnvelopeOpenText,
} from '@fortawesome/pro-light-svg-icons'
import {
  faMapMarkerCheck,
  faChairOffice,
  faRulerTriangle,
  faPageBreak,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCoffee,
  faBeer,
  faDoorClosed,
  faTachometerAlt,
  faCalendarAlt,
  faUsers,
  faUsersCog,
  faSlidersH,
  faUser,
  faUserPlus,
  faCreditCard,
  faDollarSign,
  faMoneyCheckAlt,
  faBook,
  faCog,
  faCheck,
  faBuilding,
  faHandshake,
  faSearch,
  faPaperPlane,
  faTimes,
  faClock,
  faLock,
  faLockOpen,
  faPhone,
  faTabletAlt,
  faPlusCircle,
  faPlus,
  faImage,
  faFileAlt,
  faCamera,
  faTrashAlt,
  faCircleNotch,
  faPencilAlt,
  faArrowLeft,
  faEnvelope,
  faGlasses,
  faBriefcase,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faUndo,
  faUniversity,
  faQuestionCircle,
  faGlobe,
  faBell,
  faSync,
  faCheckSquare,
  faExternalLinkAlt,
  faThumbtack,
  faBullhorn,
  faSms,
  faPaintBrush,
  faMapSigns,
  faCaretDown,
  faDoorOpen,
  faCopy,
  faChevronUp,
  faChevronDown,
  faBars,
  faHeartBroken,
  faEye,
  faPlay, // invoice resume button
  faPause, // invoice pause button
  faAdjust,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  falEnvelope,
  falCalendarAlt,
  falBuilding,
  falTachometerAlt,
  falUsers,
  falMoneyCheckAlt,
  falUserPlus,
  falCreditCard,
  falBook,
  falCoffee,
  falDoorOpen,
  falCog,
  falAngleUp,
  falAngleDown,
  falAngleLeft,
  falAngleRight,
  falSearch,
  falFilter,
  falSort,
  falBars,
  falPlus,
  falTimes,
  falBell,
  falCommentAlt,
  falQuestionCircle,
  falCheckCircle,
  falExclamationCircle,
  falExclamationTriangle,
  falInfoCircle,
  falSync,
  falEnvelopeOpenText,
  farEnvelope,
  farCalendarAlt,
  farBuilding,
  farTachometerAlt,
  farUsers,
  farMoneyCheckAlt,
  farUserPlus,
  farCreditCard,
  farBook,
  farCoffee,
  farDoorOpen,
  farCog,
  farAngleUp,
  farAngleDown,
  farAngleLeft,
  farAngleRight,
  farSearch,
  farFilter,
  farSort,
  farBars,
  farPlus,
  farTimes,
  farBell,
  farCommentAlt,
  farQuestionCircle,
  farCheckCircle,
  faPageBreak,
  farExclamationCircle,
  farExclamationTriangle,
  farInfoCircle,
  farMapMarkerCheck,
  faCoffee,
  faBeer,
  faDoorClosed,
  faTachometerAlt,
  faCalendarAlt,
  faUsers,
  faUsersCog,
  faSlidersH,
  faUser,
  faRulerTriangle,
  faUserPlus,
  faCreditCard,
  faDollarSign,
  faMoneyCheckAlt,
  faDoorOpen,
  faBook,
  faCog,
  faCheck,
  faBuilding,
  faHandshake,
  faSearch,
  faPaperPlane,
  faTimes,
  faClock, // add to upcoming invoice icons
  faLock,
  faLockOpen,
  faPhone,
  faTabletAlt,
  faPlusCircle,
  faPlus,
  faImage,
  faFileAlt,
  faCamera,
  faTrashAlt,
  faCircleNotch,
  faPencilAlt,
  faArrowLeft,
  faEnvelope,
  faGlasses,
  faBriefcase,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faUndo,
  faUniversity,
  faQuestionCircle,
  faGlobe,
  faBell, // notification icon
  faSync, // notifications refresh buttons
  faExternalLinkAlt, // resources link icon
  faCheckSquare, // resources form icon
  faThumbtack, // resoureces pinned icon
  faBullhorn, // announcements push type
  faSms, // sms notification,
  faPaintBrush, // white label feature icon
  faMapSigns, // multi-campus
  faCaretDown, // navigation down arrow
  faCopy, // copy link
  faChevronUp,
  faChevronDown,
  faBars,
  faHeartBroken, // for the error page
  faMapMarkerCheck,
  faEye, // eye for the public room toggle
  faChairOffice, // occupancy icon
  faAdjust, // partial refund
  faPlay, // invoice resume
  faPause // invoice pause
)
