/* eslint-disable prefer-spread */
import React from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import PicturePlaceholder from '../../../img/placeholders/otto_black_white.jpeg'
import styled, { css } from 'styled-components'
import * as Constants from '@global/Constants'

const Card = styled.div`
  display: flex;
  background: #fff;
  flex-flow: column nowrap;
  margin-bottom: 0;
  overflow: hidden;
  cursor: pointer;
  ${props => {
    if (props.active) {
      return css`
        opacity: 0.8;
        cursor: default;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background: #000;
          opacity: 0.6;
          width: 100%;
          height: 100%;
        }
      `
    }
    return null
  }}
`
const Info = styled.div`
  padding: 12px;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
`
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`
const Text = styled.h5`
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  line-height: 1.2em;
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 5px;
`
const Subtext = styled.h6`
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  line-height: 1.2em;
  white-space: nowrap;
  opacity: 0.7;
  margin-bottom: 4px;
`
const UserImage = styled.img`
  width: 100%;
`
const IconStyled = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colorWhite};
  z-index: 1;
  opacity: 0.65;
  margin-bottom: 30px;
`

export function MemberCard({
  member,
  handleSelectMember,
  showActive = false,
  defaultUserPic,
}) {
  const handleSelection = event => {
    handleSelectMember(member)
  }
  const active = showActive
    ? Constants.getActiveCheckIn(member.checkins)
    : false

  const memberPic =
    member && member.user_photo && member.user_photo.file_url
      ? member.user_photo.file_url
      : defaultUserPic
  return (
    <Card
      active={active}
      className="card"
      onClick={!active ? handleSelection : null}
    >
      {active && (
        <IconWrapper>
          <IconStyled icon={'map-marker-check'} size="6x" />
        </IconWrapper>
      )}
      <UserImage src={memberPic} />
      <Info>
        <Text>{`${member.first_name} ${member.last_name}`}</Text>
        <Subtext>
          {member.teams.length > 0 ? member.teams[0].name : 'Member'}
        </Subtext>
      </Info>
    </Card>
  )
}

MemberCard.displayName = 'MemberCard'

MemberCard.propTypes = {
  member: PropTypes.object,
  handleSelectMember: PropTypes.func,
  showActive: PropTypes.bool,
  defaultUserPic: PropTypes.string,
}
MemberCard.defaultProps = {
  defaultUserPic: PicturePlaceholder
}
export default MemberCard
