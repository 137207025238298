/* eslint-disable no-undef */
/* eslint-disable no-invalid-this */
import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import { extractCoworksErrorObject } from '@global/ErrorFactory'

import * as campusActions from '@reduxActions/campusActions'
import * as userActions from '@reduxActions/userActions'
import * as uiActions from '@reduxActions/uiActions'
import * as communityActions from '@reduxActions/communityActions'

import {
  CustomInputComponent,
  CustomSelectComponent,
} from '@global/Form/FormComponents'

import { Axios } from '@config/axios'
import { LabelStyled } from '@global/Form/FormComponents'
import { listOfRoles, memberRole, superAdminRole } from '@config/roles'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import Spinner from '@global/Spinner'

import coworksLogo from '@app/img/global/coworksLogo.png'
import conferencePlaceholder from '@app/img/placeholders/conference_room.jpg'
import { Link } from 'react-router-dom'

const ENTER_KEY = 13

const TextWhiteStyled = styled.div`
  color: white;
  font-weight: 500;
`
const CloseModalContainer = styled.div`
  margin-left: auto;
  width: fit-content;
  padding: 12px 20px;
  margin-top: -24px;
  margin-right: -24px;
`
const CloseIcon = styled.div`
  font-size: 24px;
`
const ButtonGradient = styled.button`
  background: none;
  text-decoration: inherit;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.25;
  :hover {
    border-width: 2px;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #50d3d2, #29404f);
    font-weight: 600 !important;
  }
  /* background: radial-gradient(circle at center, red 0, blue 100%); */
`
const LoginContainer = styled.form`
  display: flex;
  height: 100%;
`
const LoginBox = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 3rem !important;
  padding: 1.5rem !important;
  font-weight: 400;
  background-color: #ffffff;
  width: 70%;
  max-width: 500px;
  min-width: 310px;
  border-top: ${props =>
    props.isDev ? `5px solid ${props.theme.colorPrimary}` : ''} !important;
`
const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 12vh;
`
const SpinnerContainer = styled.div``
// eslint-disable-next-line react/no-multi-comp
export function Login({
  campus_actions,
  community_actions,
  user_actions,
  ui_actions,
  history,
  campuses,
  user,
  isFetching,
  isTablet,
  routeToSignup,
  background,
  titleText,
  activeCommunity,
  activeCampus,
  hideFooterLinks = false,
  handleLoginSuccess,
  onClose,
  notifyOfEvent,
}) {
  useEffect(() => {
    console.log(`v${BUILD_VERSION}`)
  }, [])

  const isDev = () => {
    let isDev = false
    const baseURL = Axios.defaults.baseURL
    if (
      baseURL === 'http://coworks-test.herokuapp.com' ||
      baseURL === 'https://coworks-dev.herokuapp.com' ||
      baseURL === 'http://localhost:4010' ||
      baseURL === 'http://localhost:3000'
    ) {
      isDev = true
      const warning =
        baseURL === 'http://localhost:4010'
          ? ' and make sure stoplight prism is running!'
          : ''
      console.info(`Connected to development database ${baseURL}${warning}`)
    }
    return isDev
  }

  const handleKeyDown = event => {
    switch (event.keyCode) {
      case ENTER_KEY:
        handleLogin(event)
        break
      default:
        break
    }
  }

  function canUserContinue(user) {
    //todo this looks like it will let anything BUT member in the dashboard .. dangerouos for new roles!
    const index = listOfRoles.indexOf(memberRole)
    if (index !== -1) {
      listOfRoles.splice(listOfRoles.indexOf(memberRole), 1)
    }
    if (user) {
      if (user.type === superAdminRole) {
        proceedWithLogin(user)
      } else if (!user.communities || user.communities.length === 0) {
        console.log('There may be no communities associated with this user.')
        throwAlert('You have no active communities. Please contact support.')
      } else if (user.communities[0].community_preference.is_terminated) {
        console.log('This community has been locked.')
        throwAlert()
      } else {
        proceedWithLogin(user)
      }
    } else {
      throwAlert()
    }
  }
  const checkCommunityIntegrity = user => {
    let areWeGoodToLogin = true
    for (let i = 0; i < user.communities.length; i++) {
      const community = user.communities[i]
      if (!community.campuses || community.campuses.length === 0) {
        throwAlert(
          'You have no active campuses or communities. Please contact support.'
        )
        console.log("There are no campuses in one of this user's communities")
        areWeGoodToLogin = false
        break
      }
    }
    return areWeGoodToLogin
  }
  const proceedWithLogin = user => {
    // make sure there are campuses
    let areWeGoodToLogin = true
    areWeGoodToLogin = checkCommunityIntegrity(user)

    if (!areWeGoodToLogin) {
      throwAlert()
    } else {
      window.IS_UNAUTHORIZED = false
      user_actions.setUser(user)
      if (activeCampus && activeCommunity && handleLoginSuccess) {
        // the login form is nested in another page that wants to handle routing.
        handleLoginSuccess()
      } else {
        const community = user.communities[0]
        ui_actions.setActiveContext(community, community.campuses[0])
        // ui_actions.setActiveCampus(community.campuses[0])
        history.push('/change-campus')
      }
    }
  }

  const throwAlert = text => {
    let alertText =
      'Whoops it looks like there is a problem with your account. Please contact support!'
    if (text) {
      alertText = text
    }
    toast.error(alertText)
  }
  const handleLogin = values => {
    ui_actions.setIsFetching(true)
    user_actions
      .signIn({
        email: values.email,
        password: values.password,
      })
      .then(response => {
        ui_actions.setIsFetching(false)
        canUserContinue(response.user)
      })
      .catch(err => {
        ui_actions.setIsFetching(false)
        toast.error(extractCoworksErrorObject(err))
      })
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required!'),
        password: Yup.string().required('Password is required!'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleLogin(values)
        setSubmitting(false)
      }}
    >
      {({ dirty, isSubmitting, handleSubmit, setFieldValue }) => {
        return (
          <LoginContainer onSubmit={handleSubmit} handleKeyDown={handleKeyDown}>
            <LoginWrapper>
              <Fragment>
                {isFetching ? (
                  <SpinnerContainer>
                    <Spinner color="#fff" size="2x" />
                  </SpinnerContainer>
                ) : (
                  <LoginBox className="card" isDev={isDev}>
                    {onClose && (
                      <CloseModalContainer
                        id="close-modal"
                        aria-hidden="true"
                        onClick={onClose}
                      >
                        <CloseIcon className="cancel-btn">
                          <FontAwesomeIcon icon="times" />
                        </CloseIcon>
                      </CloseModalContainer>
                    )}
                    <img
                      className="img-fluid mr-2 w-50 d-flex m-auto"
                      src={coworksLogo}
                    />
                    <h4 className="text-center">
                      {titleText ? titleText : 'Sign Into Coworks'}
                    </h4>
                    <div className="form-group">
                      <Field
                        label="Email"
                        name="email"
                        placeholder="name@email.com"
                        type="email"
                        onChange={event => {
                          setFieldValue('email', event.target.value)
                          if (notifyOfEvent) notifyOfEvent(event)
                        }}
                        component={CustomInputComponent}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        label="Password"
                        name="password"
                        placeholder=""
                        type="password"
                        onChange={event => {
                          setFieldValue('password', event.target.value)
                          if (notifyOfEvent) notifyOfEvent(event)
                        }}
                        component={CustomInputComponent}
                      />
                      {!hideFooterLinks && (
                        <div className="row">
                          <div className="col-8 col-md-6">
                            <Link to="/forgot_password">Forgot Password?</Link>
                          </div>
                        </div>
                      )}
                      <button
                        id="login-button"
                        type="submit"
                        disabled={!dirty || isSubmitting || isFetching}
                        className="mt-3 my-2 btn btn-primary btn-block"
                      >
                        Login
                      </button>
                    </div>
                  </LoginBox>
                )}
                {!hideFooterLinks && !isFetching && (
                  <Fragment>
                    <TextWhiteStyled>
                      <span className="mr-4">Don't have an account?</span>
                      <ButtonGradient onClick={() => history.push('/signup')}>
                        Sign Up
                      </ButtonGradient>
                    </TextWhiteStyled>

                    <div className="row m-2">
                      <div className="col">
                        <Button
                          color="link"
                          onClick={() => window.open('https://coworksapp.com')}
                        >
                          <TextWhiteStyled>Home</TextWhiteStyled>
                        </Button>
                        <Button
                          color="link"
                          onClick={() =>
                            window.open('mailto:support@coworksapp.com')
                          }
                        >
                          <TextWhiteStyled>Support</TextWhiteStyled>
                        </Button>
                        <Button
                          color="link"
                          onClick={() =>
                            window.open('https://help.coworksapp.com')
                          }
                        >
                          <TextWhiteStyled>Articles</TextWhiteStyled>
                        </Button>
                        <Button
                          color="link"
                          onClick={() =>
                            window.open('https://coworksapp.com/blog/')
                          }
                        >
                          <TextWhiteStyled>Blog</TextWhiteStyled>
                        </Button>
                      </div>
                    </div>
                    <div className="row m-1">
                      <div className="col">
                        <Button
                          color="link"
                          className="m-0 ml-1 p-0"
                          onClick={() =>
                            history.push(`/${isTablet ? '' : 'tablet-'}login`)
                          }
                        >
                          <TextWhiteStyled>
                            {isTablet
                              ? 'Looking for the admin dashboard login?'
                              : 'Looking for the tablet login?'}
                          </TextWhiteStyled>
                        </Button>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            </LoginWrapper>
          </LoginContainer>
        )
      }}
    </Formik>
  )
}

Login.displayName = 'Login'
Login.propTypes = {
  campus_actions: PropTypes.object,
  community_actions: PropTypes.object,
  user_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  history: PropTypes.object,
  campuses: PropTypes.array,
  user: PropTypes.object,
  isFetching: PropTypes.bool,
  isTablet: PropTypes.bool,
  routeToSignup: PropTypes.func,
  onClose: PropTypes.func,
  background: PropTypes.string,
  titleText: PropTypes.string,
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  hideFooterLinks: PropTypes.bool,
  handleLoginSuccess: PropTypes.func,
  notifyOfEvent: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    campuses:
      state.ui.activeCommunity && state.ui.activeCommunity.campuses
        ? state.ui.activeCommunity.campuses
        : [],
    user: state.user,
    isFetching: state.ui.isFetching,
    activeCommunity: state.ui.activeCommunity,
    activeCampus: state.ui.activeCampus,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    campus_actions: bindActionCreators(campusActions, dispatch),
    community_actions: bindActionCreators(communityActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
