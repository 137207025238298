import * as _ from 'lodash'

export function QueryStringToJSON(propsLocation) {
  // use this.props.location as parameter
  if (!propsLocation) {
    console.warn('location passed to QueryStringToJSON was null or undefined')
    return {}
  }
  const pairs = propsLocation.search.slice(1).split('&')

  const result = {}
  pairs.forEach(pair => {
    const newPair = pair.split('=')
    if (newPair[0] && newPair[0] !== "")
      result[newPair[0]] = decodeURIComponent(newPair[1] || '')
  })

  return JSON.parse(JSON.stringify(result))
}
