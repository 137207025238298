/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import Button from '@bit/coworks.base-components.button'
import Input from '@bit/coworks.base-components.input'

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const TextInput = styled(Input)``
const ButtonStyled = styled(Button)`
  width: 35px;
  height: 35px;
  padding: 2px;
  font-size: 22px;
  margin-left: 8px;
  margin-right: 8px;
`

const IncrementButton = ({
  min,
  max,
  value,
  step,
  onChange,
  color,
  ...other
}) => {
  const numberBounds = val => {
    let newVal = val

    if (min !== null) newVal = Math.max(min, newVal)
    if (max !== null) newVal = Math.min(max, newVal)

    return newVal
  }

  const [incValue, setValue] = useState(numberBounds(value))

  const updateVal = val => {
    const newVal = numberBounds(val)
    setValue(newVal)
    onChange(newVal)
  }

  const handleInput = event => {
    const value = event.target.value
    if (value) updateVal(parseInt(value, 10))
  }

  return (
    <Container {...other}>
      <ButtonStyled color={color} onClick={() => updateVal(incValue - step)}>
        -
      </ButtonStyled>
      <TextInput
        type="number"
        value={String(incValue)}
        onChange={handleInput}
      />
      <ButtonStyled color={color} onClick={() => updateVal(incValue + step)}>
        +
      </ButtonStyled>
    </Container>
  )
}

IncrementButton.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  step: PropTypes.number,
  color: PropTypes.string,
  onChange: PropTypes.func,
}

IncrementButton.defaultProps = {
  min: null,
  max: null,
  value: 0,
  step: 1,
}

export default IncrementButton
