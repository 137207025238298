/* eslint-disable react/prop-types,react/display-name */
import React, { useState } from 'react'

import * as _ from 'lodash'
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import DateTime from 'react-datetime'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  Row,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
} from 'reactstrap'

import Spinner from '@global/Spinner'
import {
  LabelStyled,
  CustomInputComponent,
  CustomTextAreaComponent,
  CustomDatePickerComponent,
  PhoneInputField,
} from '@global/Form/FormComponents'
import CustomFieldSection from '@global/CustomFields/CustomFieldSection'
import Switch from '@global/Base/Switch/Switch'

import { validateEmail } from '@global/Constants'

import { renderDynamicGroups } from '@global/DynamicGroups/DynamicGroupFunctions'

// todo refactor this to a proper formik form that doesn't
//    use state in parent, but maintains it in the formik component.

const LeadForm = ({
  activeCommunity,
  toggleIsLead,
  toggleHasToured,
  goBack,
  handleFollowUpDateChanged,
  isFetching,
  archiveLead,
  renderCustomDropdowns,
  getGeneratedFromTag,
  followUpQuickButtonSelected,
  handleInputValuesChange,
  toggleDropdown,
  promoteToMemberNewTeamClicked,
  promoteToMemberExistingTeamClicked,
  allowFormSubmit,
  customFieldMax,
  customFieldsObject,
  dropdownOpen,
  followUpDate,
  isConverted,
  isEditing,
  isInactive,
  leadId,
  isLead,
  dynamicTags,
  hasToured,
  description,
  email,
  firstName,
  lastName,
  notes,
  phone,
  title,
  teamName,
  tourDate,
  teamWebsite,
  checkEmailExists,
  handleFormSubmit,
}) => {
  const formType = isLead ? 'Lead' : 'Prospect'
  const pageTitle = isEditing ? `Edit ${formType}` : `New ${formType}`

  const ASYNC_VALIDATION_TIMEOUT_IN_MS = 1000

  function shouldRunApiEmailValidation(newEmail) {
    // first check if existing lead
    if (leadId) {
      // if it's existing, we want to check the emails and see if they match
      // if they match, then we're just editing itself and shouldn't check server
      if (newEmail === email) {
        return false
      }
    }
    return true
  }

  const validationFunction = async (value, resolve) => {
    if (!validateEmail(value)) {
      // check presence of an actual email before hitting server
      resolve(false)
    } else {
      if (!shouldRunApiEmailValidation(value)) {
        resolve(true)
      }
      // if we're not editing, or the email has changed, then run api validate
      checkEmailExists(value, activeCommunity.id)
        .then(response => {
          if (response.emailExists.email_exists) {
            // email exists
            resolve(false)
          } else {
            // none exists - don't alert for error
            resolve(true)
          }
        })
        .catch(() => {
          // other problem exists - alert for error
          resolve(false)
        })
    }
  }

  const validationDebounced = _.debounce(
    validationFunction,
    ASYNC_VALIDATION_TIMEOUT_IN_MS
  )

  return (
    <Formik
      initialValues={{
        description,
        email,
        firstName,
        lastName,
        notes,
        phone,
        teamName,
        title,
        tourDate,
        teamWebsite,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .trim('Email cannot have whitespace at the beginning or end.')
          .required('Email is required')
          .test(
            'checkEmailExists',
            `This email already exists for a lead or prospect in your community`,
            value => new Promise(resolve => validationDebounced(value, resolve))
          ),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        title: Yup.string(),
        teamWebsite: Yup.string().url(
          'Invalid website format! It must be http://www.yourlink.com'
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleFormSubmit(values, setSubmitting)
      }}
    >
      {({ dirty, isSubmitting, touched, values, errors }) => (
        <div id="new-member-form" className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-8">
                <h2>{pageTitle}</h2>
              </div>
              {/* <div className="col-4 d-flex align-items-center">
            <LabelStyled>Mark as Lead</LabelStyled>
            <div>
              <Switch
                value={isLead}
                onChange={toggleIsLead}
                labelRight={isLead ? ' Is Lead' : ' Is Not Lead'}
              />
            </div>
          </div> */}
            </div>
          </div>
          <div className="card-block">
            <div className="row">
              <div className="col-12">
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="offset-md-2 col-md-8">
                          {isConverted && (
                            <Alert color={'info'}>
                              {`This ${
                                isLead ? 'lead' : 'prospect'
                              } has already been converted!`}
                            </Alert>
                          )}
                          {isInactive && (
                            <Alert color={'warning'}>
                              {`This ${
                                isLead ? 'lead' : 'prospect'
                              } is inactive!`}
                            </Alert>
                          )}
                          <div className="row">
                            <div className="col-md-9">
                              <h3>Profile</h3>
                            </div>
                            <div className="col-md-3">
                              <div className="row">
                                <div className="col-md-12">
                                  {getGeneratedFromTag()}
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-6">
                              <Field
                                label="First Name"
                                name="firstName"
                                id="firstName"
                                placeholder="Johnny"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-6">
                              <Field
                                label="Last Name"
                                name="lastName"
                                id="lastName"
                                placeholder="Appleseed"
                                component={CustomInputComponent}
                              />
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-6">
                              <Field
                                label="Email"
                                name="email"
                                id="email"
                                type="email"
                                placeholder="johnny@me.com"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-6">
                              <Field
                                label="Phone"
                                name="phone"
                                id="phone"
                                placeholder="555-555-5555"
                                component={PhoneInputField}
                              />
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-6">
                              <Field
                                label="Team Name"
                                name="teamName"
                                id="teamName"
                                placeholder="Johnny's Team"
                                component={CustomInputComponent}
                              />
                            </div>
                            <div className="col-6">
                              <Field
                                label="Title"
                                name="title"
                                id="title"
                                placeholder="Founder, CTO, Sales, etc"
                                component={CustomInputComponent}
                              />
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-12">
                              <Field
                                label="Team Website"
                                name="teamWebsite"
                                id="teamWebsite"
                                placeholder="Website"
                                component={CustomInputComponent}
                              />
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-12">
                              <Field
                                name="description"
                                label="Interested In"
                                className="form-control"
                                placeholder={`What this ${
                                  isLead ? 'lead' : 'prospect'
                                } is interested in: (auto-populated from external forms)`}
                                component={CustomInputComponent}
                              />
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-12">
                              <Field
                                label="Private Notes"
                                name="notes"
                                id="notes"
                                placeholder={`Write any private notes or additional info about ${
                                  values.firstName
                                    ? values.firstName
                                    : `your new ${isLead ? 'lead' : 'prospect'}`
                                }`}
                                rows={7}
                                component={CustomTextAreaComponent}
                              />
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-6">
                              <LabelStyled>Mark as Lead</LabelStyled>
                              <div className="form-group">
                                <Switch
                                  value={isLead}
                                  onChange={toggleIsLead}
                                  labelRight={isLead ? ' Lead' : ' Not a Lead'}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <Row className="ml-1">
                                <LabelStyled>
                                  {!isConverted
                                    ? `Promote this ${
                                        isLead ? 'lead' : 'prospect'
                                      }`
                                    : `This ${
                                        isLead ? 'lead' : 'prospect'
                                      } has already been converted!`}{' '}
                                </LabelStyled>
                                <div className="col-12 d-flex align-items-center m-0 p-0">
                                  <ButtonDropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleDropdown}
                                    disabled={
                                      !leadId || (leadId && isConverted)
                                    }
                                  >
                                    <DropdownToggle
                                      caret
                                      color="outline-primary"
                                      disabled={
                                        !leadId || (leadId && isConverted)
                                      }
                                    >
                                      Promote to
                                    </DropdownToggle>
                                    <DropdownMenu
                                      disabled={
                                        !leadId || (leadId && isConverted)
                                      }
                                    >
                                      <DropdownItem
                                        disabled={
                                          !leadId || (leadId && isConverted)
                                        }
                                        onClick={promoteToMemberNewTeamClicked}
                                      >
                                        New Team
                                      </DropdownItem>
                                      <DropdownItem
                                        disabled={
                                          !leadId || (leadId && isConverted)
                                        }
                                        onClick={
                                          promoteToMemberExistingTeamClicked
                                        }
                                      >
                                        Existing Team
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                </div>
                              </Row>
                            </div>
                          </div>
                          {isLead && (
                            <React.Fragment>
                              <div className="row my-3">
                                <div className="col-6">
                                  <LabelStyled>Tour Status</LabelStyled>
                                  <div className="form-group">
                                    <Switch
                                      value={hasToured}
                                      onChange={toggleHasToured}
                                      labelRight={
                                        hasToured ? ' Toured' : ' Not toured'
                                      }
                                    />
                                  </div>
                                </div>
                                {hasToured && (
                                  <div className="col-6">
                                    <Field
                                      label="Tour Date"
                                      name="tourDate"
                                      id="tourDate"
                                      placeholder={`Tour Date`}
                                      format={'MMM DD, YYYY'}
                                      value={tourDate}
                                      component={CustomDatePickerComponent}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="row my-3">
                                <div className="col-md-6">
                                  <LabelStyled>Follow-up Date</LabelStyled>
                                  <DateTime
                                    name="followUpDate"
                                    dateFormat="MMMM DD, YYYY"
                                    timeFormat="hh:mm a"
                                    value={followUpDate}
                                    inputProps={{
                                      readOnly: true,
                                      style: { backgroundColor: 'white' },
                                    }}
                                    timeConstraints={{
                                      hours: { max: 23, step: 1 },
                                      minutes: { max: 59, step: 5 },
                                    }}
                                    onChange={handleFollowUpDateChanged}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <LabelStyled>Follow-up after:</LabelStyled>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic example"
                                  >
                                    <button
                                      onClick={() =>
                                        followUpQuickButtonSelected(0)
                                      }
                                      type="button"
                                      className="btn btn-secondary"
                                    >
                                      1 week
                                    </button>
                                    <button
                                      onClick={() =>
                                        followUpQuickButtonSelected(1)
                                      }
                                      type="button"
                                      className="btn btn-secondary"
                                    >
                                      2 weeks
                                    </button>
                                    <button
                                      onClick={() =>
                                        followUpQuickButtonSelected(2)
                                      }
                                      type="button"
                                      className="btn btn-secondary"
                                    >
                                      1 month
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <Row className="my-3">
                                <div className="col-6">
                                  {renderCustomDropdowns()}
                                </div>
                                {/* <div className='col-6 my-3 d-flex align-items-end'>
                            {isEditing ? <Button className='btn btn-primary ml-3' onClick={convertLead}>Mark as converted</Button> : null }
                          </div>*/}
                              </Row>
                            </React.Fragment>
                          )}

                          {renderDynamicGroups({
                            allGroups: activeCommunity.dynamic_groups,
                            handleChange: newValues => {
                              handleInputValuesChange('dynamicTags', newValues)
                            },
                            existingTagValues: dynamicTags,
                            groupType: 'Contact',
                            isEditing: true, //todo
                          })}

                          <CustomFieldSection
                            customFieldMax={customFieldMax}
                            customFieldsObject={customFieldsObject}
                            handleInputValuesChange={handleInputValuesChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row className="my-1">
                    <div className="offset-md-2 col-md-6 pl-0">
                      {isEditing ? (
                        <button
                          className={
                            isInactive
                              ? 'btn btn-success ml-3'
                              : 'btn btn-danger ml-3'
                          }
                          onClick={() => archiveLead(!isInactive)}
                        >
                          {isInactive ? 'Restore' : 'Archive'}
                        </button>
                      ) : null}
                    </div>
                    <div className="col-md-2 d-flex justify-content-end">
                      <button
                        className="btn btn-secondary mr-3"
                        onClick={goBack}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                          (!dirty || isSubmitting || isFetching) &&
                          !allowFormSubmit
                        }
                      >
                        {isSubmitting && (
                          <FontAwesomeIcon icon={'circle-notch'} spin />
                        )}{' '}
                        Save
                      </button>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

LeadForm.displayName = 'LeadForm'

export default LeadForm
