/* eslint-disable no-invalid-this,quote-props */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import * as googleCalendarActions from '@reduxActions/googleCalendarActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'
import styled from 'styled-components'

import moment from 'moment'
import { toast } from 'react-toastify'
import * as _ from 'lodash'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Collapse,
  CardBody,
  Card,
} from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import Spinner from '@global/Spinner'

import BookingBundle from './BookingsBundle'
import Placeholder from '../../Global/Placeholder'
import {
  resolveFetchingStatus,
  FETCHING_ROOMS,
} from '@global/Constants/FetchingConstants'

const Container = styled.div``

const FilterHeader = styled.div`
  background-color: red;
`
const BookingCardWrapper = styled.div``
const HeaderText = styled.h5`
  margin: 0;
  color: #50646f;
  font-weight: bold;
`
const PaginatedTableStyled = styled(PaginatedTable)`
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 576px) {
    padding: 0;
  }
`

class BookingsResults extends React.Component {
  static propTypes = {
    bookings: PropTypes.array,
    multiRoom: PropTypes.bool,
    loading: PropTypes.bool,
    fetching: PropTypes.bool,
    room: PropTypes.object,
    pagination: PropTypes.object,
    startDate: PropTypes.object,
    onClick: PropTypes.function,
    nextPage: PropTypes.function,
    showEvents: PropTypes.bool,
    showBookings: PropTypes.bool,
    publicCommunity: PropTypes.object,
  }

  segmentBookingsByDay = bookings => {
    const daySegemnts = []
    const days = []
    const timeFormat = 'dddd, MMMM Do, YYYY'

    bookings.forEach(booking => {
      const day = moment(booking.start_time).format('ll')
      const segIndex = days.indexOf(day)
      if (segIndex > -1) {
        daySegemnts[segIndex].bookings.push(booking)
      } else {
        days.push(day)
        daySegemnts.push({ day, bookings: [booking] })
      }
    })
    return daySegemnts
  }

  bundleBookings = bookings => {
    const { publicCommunity } = this.props
    const brandColor =
      publicCommunity.community_preference.branding_primary_color
    const daySegments = this.segmentBookingsByDay(bookings)
    const sortedSegments = daySegments.sort(
      (pre, post) => moment(pre.day).valueOf() - moment(post.day).valueOf()
    )
    return sortedSegments.map(segment => (
      <BookingBundle
        onClick={this.props.onClick}
        segment={segment}
        multiRoom={this.props.multiRoom}
        brandColor={brandColor}
      />
    ))
  }

  filterBookings = bookings => {
    const { room, startDate, showBookings, showEvents } = this.props
    if (!bookings) return []

    return bookings.filter(booking => {
      let valid = true
      if (room) {
        if (!booking.room || booking.room.id !== room.id) {
          valid = false
        } else if (
          !moment(booking.start_time).isSameOrAfter(startDate, 'day')
        ) {
          valid = false
        } else if (!showEvents && booking.type === 'Event') {
          valid = false
        } else if (!showBookings && booking.type === 'Booking') {
          valid = false
        }
      }
      return valid
    })
  }

  renderPaginatedBookings = bookings => {
    const { pagination, nextPage, loading } = this.props
    return (
      <PaginatedTableStyled
        objects={[bookings]}
        type={'bookings'}
        nextPage={nextPage}
        wrapperClassName=""
        isLoading={loading}
        totalPages={pagination && pagination.total_pages}
        renderItem={bookings => this.bundleBookings(bookings)}
      />
    )
  }

  renderContent = () => {
    const { bookings, room, loading, publicCommunity } = this.props
    const brandColor =
      publicCommunity.community_preference.branding_primary_color

    if (loading) {
      return <Spinner color={brandColor} />
    } else if (!room) {
      return (
        <Placeholder
          title={'Please Select a Room'}
          icon={'door-open'}
          subtext1={'Select a room from the drop down'}
          color={brandColor}
        />
      )
    }

    const filteredBookings = this.filterBookings(bookings)
    if (filteredBookings && filteredBookings.length === 0) {
      return (
        <Placeholder
          title={'No Results Found'}
          icon={'search'}
          subtext1={'No bookings could be found for this search criteria'}
          color={brandColor}
        />
      )
    }

    return this.renderPaginatedBookings(filteredBookings)
  }

  /* eslint-disable max-len */
  render() {
    const { publicCommunity } = this.props
    const brandColor =
      publicCommunity.community_preference.branding_primary_color

    return (
      <Container>
        {this.renderContent()}
        {this.props.fetching && <Spinner color={brandColor} />}
      </Container>
    )
  }
}

BookingsResults.displayName = 'BookingsResults'

function mapStateToProps(state) {
  return {
    rooms: roomSelectors.getRoomsList(state),
    bookings: state.bookings.bookings,
    isFetching: state.ui.isFetching,
    isFetchingRooms: resolveFetchingStatus(state, FETCHING_ROOMS),
    googleCalendars: state.gcal ? state.gcal.calendars : null,
    user: state.user,
    pagination: state.bookings.meta ? state.bookings.meta.pagination : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
    booking_actions: bindActionCreators(bookingActions, dispatch),
    google_actions: bindActionCreators(googleCalendarActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingsResults)
