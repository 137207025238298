import {
  REGISTER_COMMUNITY,
  SET_CURRENT_MANAGER,
} from '../reduxActions/actionTypes'

export default function(manager = {}, action) {
  switch (action.type) {
    case REGISTER_COMMUNITY:
      return action.manager
    case SET_CURRENT_MANAGER:
      return action.manager
    default:
      return manager
  }
}
