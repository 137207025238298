import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'

export function createTaskSuccessful(task) {
  return { type: actionTypes.CREATE_TASK, task }
}

// ACTION DISPATCHERS
export const createTask = task => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateIsPosting(true))
    apiService
      .createTask(task)
      .then(response => {
        dispatch(createTaskSuccessful(response))
        dispatch(uiActions.updateIsPosting(false))
        resolve(response)
      })
      .catch(err => {
        uiActions.updateIsPosting(false)
        reject(err)
      })
  })
}
