/* eslint-disable react/prop-types,react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* eslint-disable no-invalid-this */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as Roles from '../../../../../config/roles' //todo implement this where roles are used
import { Button, TextButton } from '@global/Base/Button/ButtonStyled'
import RoundedImage from '@global/RoundedImage'
import { withFormik, Form, ErrorMessage } from 'formik'
import DateTime from 'react-datetime'
import moment from 'moment'
import { LabelStyled } from '@global/Form/FormComponents'

const FormStyled = styled(Form)`
  display: flex;
  flex-flow: column wrap;
`
const Label = styled(LabelStyled)`
  display: flex;
  align-items: center;
`
const TimeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`
const ButtonStyled = styled(Button)``
const DateTimeStyled = styled(DateTime)`
  width: 65%;
`

function CheckInTimes({ values, errors, setFieldValue, ...other }) {
  const updateTime = (name, date) => {
    setFieldValue(name, date.unix(), false)
  }

  const getLabel = title => {
    switch (title) {
      case 'timeIn':
        return 'Time in'
      case 'timeOut':
        return 'Time out'
      default:
        return 'Time'
    }
  }

  const renderTimeInput = name => {
    const date = moment.unix(values[name])

    return (
      <TimeContainer>
        <Label>{getLabel(name)}</Label>
        {values[name] ? (
          <DateTimeStyled
            name={name}
            dateFormat="MMMM DD, YYYY"
            timeFormat="hh:mm a"
            value={date}
            inputProps={{
              readOnly: true,
              style: { backgroundColor: 'white' },
            }}
            timeConstraints={{
              hours: { max: 23, step: 1 },
              minutes: { max: 59, step: 5 },
            }}
            onChange={date => updateTime(name, date)}
          />
        ) : (
          <TextButton onClick={() => updateTime(name, moment())}>
            Check Out
          </TextButton>
        )}
      </TimeContainer>
    )
  }
  return (
    <FormStyled {...other}>
      {renderTimeInput('timeIn')}
      <div className="text-danger">{errors.timeIn}</div>
      {renderTimeInput('timeOut')}
      <div className="text-danger">{errors.timeOut}</div>
    </FormStyled>
  )
}

CheckInTimes.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
}

CheckInTimes.defaultProps = {}

CheckInTimes.displayName = 'Check-in Times'

export default CheckInTimes
