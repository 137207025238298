import React from 'react';
import PropTypes from 'prop-types';
import GlassesMascot from '../../img/placeholders/otto_color.png'; // eslint-disable-line max-len

export default function OnboardingSidePanel({ title, caption, isLastStep = false }) { // eslint-disable-line max-len
  return (
    <div className='col-md-5 d-flex align-items-center'>
      <img
        className='img-responsive center-block'
        alt='Coworks Mascot'
        src={GlassesMascot} />
      <div className='alert alert-info text-center' role='alert'>
        <strong>{title}</strong><br/>
        {caption}
      </div>
    </div>
  );
}

OnboardingSidePanel.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  isLastStep: PropTypes.bool,
};

OnboardingSidePanel.displayName = 'OnboardingSidePanel';
