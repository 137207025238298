/* eslint-disable react/display-name,react/prop-types */
import React from 'react'
import ReactGA from 'react-ga'

const SmartLogger = (mixpanel, user) => WrappedComponent => {
  const smartLogger = props => {
    const DEBUG_LOGGING = false
    if (
      DEBUG_LOGGING ||
      (process.env.NODE_ENV && process.env.NODE_ENV !== 'development')
    ) {
      if (
        props &&
        props.history &&
        props.history.location &&
        props.history.location.pathname
      ) {
        //todo change to props.match
        const path = props.history.location.pathname
        if (mixpanel) {
          const params = { pathname: path }
          if (user) {
            params.distinct_id = user.id
            params.email = user.email
            params.first_name = user.first_name
            params.last_name = user.last_name
          }
          mixpanel.track('navigation', params)
        }
        ReactGA.set({ page: path })
        ReactGA.pageview(path)
      }
    }
    return <WrappedComponent {...props} />
  }
  return smartLogger
}
export default SmartLogger
