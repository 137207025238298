/* eslint-disable no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-invalid-this */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import * as Sentry from '@sentry/browser'
import styled from 'styled-components'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { Link } from 'react-router-dom'

import { extractCoworksErrorObject } from '@global/ErrorFactory'
import { QueryStringToJSON } from '@global/Utilities/routeUtilities'

import * as campusActions from '@reduxActions/campusActions'
import * as userActions from '@reduxActions/userActions'
import * as uiActions from '@reduxActions/uiActions'
import * as communityActions from '@reduxActions/communityActions'

import {
  CustomInputComponent,
  CustomSelectComponent,
} from '@global/Form/FormComponents'

import { Axios } from '@config/axios'
import { LabelStyled } from '@global/Form/FormComponents'
import { listOfRoles, memberRole } from '@config/roles'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Spinner from '@global/Spinner'

import HoorayMascot from '@app/img/placeholders/otto_color.png'
import coworksLogoWhite from '@app/img/global/coworksLogoWhite.png'
import conferencePlaceholder from '@app/img/placeholders/conference_room.jpg'

const ENTER_KEY = 13

const LoginContainer = styled.div`
  overflow-x: hidden;
  /* background-image: ${props =>
    props.background ? `url(${props.background})` : null}; */
  background-size: cover;
  background-attachment: fixed;
  width: 100% !important;
  height: 100vh;
  background-image: linear-gradient(
    to right,
    rgba(08, 90, 110),
    rgba(38, 110, 110),
    rgb(90, 179, 177)
  );

  form {
    display: flex;
    height: 100%;
  }
`
const RightLoginStyled = styled.div`
  font-weight: 400;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  /* background-color: #e3e3e3ee; */
  // background: ${props =>
    props.background ? `url(${props.background})` : ''};

  .ReactTypeformEmbed {
    position: relative !important;
  }
`
const LogoPrompt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  padding: 25px 50px 25px;
  color: ${props => props.theme.colorWhite};
  flex: 0 0 33%;

  @media (max-width: 1200px) {
    flex: 0 0 44%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
const LogoWrapper = styled.div`
  text-align: center;
`
const CoworksLogoWhite = styled.img`
  width: 40%;
`
const PromptContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 22px;
  text-align: center;
`
const FormContainer = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    margin-bottom: 25px;
    display: none;
  }
`
const RedirectContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
  padding: 8px 0;
  text-align: center;
`
const SignUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colorWhite};
  flex: 1;
`

// eslint-disable-next-line react/no-multi-comp
export class Login extends React.Component {
  static propTypes = {
    campus_actions: PropTypes.object,
    community_actions: PropTypes.object,
    user_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    campuses: PropTypes.array,
    user: PropTypes.object,
    isFetching: PropTypes.bool,
    routeToSignup: PropTypes.func,
    background: PropTypes.string,
    isDashboardLogin: PropTypes.bool,
    titleText: PropTypes.string,
    wrapperClassId: PropTypes.string,
    loginRedirectRoute: PropTypes.string,
    handleLoginSuccess: PropTypes.func,
    activeCommunity: PropTypes.object,
    activeCampus: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      showTypeform: false,
      showBetaModal: false,
      loginRedirectRoute: '',
    }
    this.handleKeyDown = this.handleKeyDown.bind(this)
    console.log(`v${BUILD_VERSION}`)
  }

  componentDidMount = () => {
    if (
      Axios.defaults.baseURL === 'http://coworks-test.herokuapp.com' ||
      Axios.defaults.baseURL === 'https://coworks-dev.herokuapp.com' ||
      Axios.defaults.baseURL === 'http://localhost:4010' ||
      Axios.defaults.baseURL === 'http://localhost:3000'
    ) {
      const warning =
        Axios.defaults.baseURL === 'http://localhost:4010'
          ? ' and make sure stoplight prism is running!'
          : ''
      toast.info(
        `Connected to development database ${Axios.defaults.baseURL}${warning}`,
        {
          timeout: 3000,
        }
      )
    }
    this.props.ui_actions.setIsTabletUser(false)
    this.props.user_actions.signOutUser()
  }
  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value })
  }

  handleKeyDown(event) {
    switch (event.keyCode) {
      case ENTER_KEY:
        this.handleLogin(event)
        break
      default:
        break
    }
  }

  userLoggedIn(response) {
    const index = listOfRoles.indexOf('Member')
    if (index !== -1) {
      listOfRoles.splice(listOfRoles.indexOf('Member'), 1)
    }
    const user = response.user
    if (user) {
      if (user.type === 'SuperAdmin') {
        this.proceedWithLogin(user)
      } else if (
        !listOfRoles.includes(user.type) ||
        (listOfRoles.includes(user.type) && user.type === memberRole)
      ) {
        this.throwAlert(
          'Coworks Dashboard is currently restricted to Coworking Managers!'
        )
      } else if (!user.communities || user.communities.length === 0) {
        console.log('There may be no communities associated with this user.')
        this.throwAlert()
      } else if (user.communities[0].community_preference.is_terminated) {
        console.log('This community has been locked.')
        this.throwAlert()
      } else {
        this.proceedWithLogin(user)
      }
    } else {
      this.throwAlert()
    }
  }
  proceedWithLogin = user => {
    // make sure there are campuses
    let areWeGoodToLogin = true
    for (let i = 0; i < user.communities.length; i++) {
      const obj = user.communities[i]
      if (!obj.campuses || obj.campuses.length === 0) {
        this.throwAlert()
        console.log("There are no campuses in one of this user's communities")
        areWeGoodToLogin = false
        break
      }
    }
    if (areWeGoodToLogin) {
      this.setSentryContext(user)
      window.IS_UNAUTHORIZED = false
      this.props.user_actions.setUser(user)
      // Todo: This will need to change when UISettings are created vvvvvv
      const community = user.communities[0]
      this.props.ui_actions.setActiveCommunity(community)
      this.props.ui_actions.setActiveCampus(community.campuses[0])
      if (user.type === 'SuperAdmin') {
        this.setState({
          showCommunityPicker: true,
        })
      } else if (community.campuses.length > 1) {
        this.setState({
          showCampusPicker: true,
        })
      } else {
        this.props.handleLoginSuccess(user.type)
      }
    } else {
      this.throwAlert()
    }
  }
  setSentryContext = user => {
    if (
      user &&
      process.env.NODE_ENV &&
      process.env.NODE_ENV !== 'development'
    ) {
      Sentry.configureScope(scope => {
        scope.setUser({
          email: user.email,
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          build_version: BUILD_VERSION,
        })
      })
    }
  }
  throwAlert = text => {
    let alertText =
      'Whoops it looks like there is a problem with your account. Please contact support!'
    if (text) {
      alertText = text
    }
    toast.error(alertText)
  }
  handleLogin = values => {
    this.props.ui_actions.setIsFetching(true)
    this.props.user_actions
      .signIn({
        email: values.email,
        password: values.password,
      })
      .then(response => {
        this.props.ui_actions.setIsFetching(false)
        this.userLoggedIn(response)
      })
      .catch(err => {
        //this.setState({ errors: err.data.errors });
        this.props.ui_actions.setIsFetching(false)
        toast.error(extractCoworksErrorObject(err))
      })
  }
  handleCampusSelection = () => {
    this.props.handleLoginSuccess(this.props.user.type)
  }
  handleCommunitySelection = community => {
    this.props.ui_actions.setActiveCommunity(community)
    if (community && community.campuses.length > 1) {
      this.setState({
        showCampusPicker: true,
        campuses: community.campuses,
      })
    } else if (community && community.campuses.length === 1) {
      this.props.ui_actions.setActiveCampus(community.campuses[0])
      this.props.handleLoginSuccess()
    } else {
      this.throwAlert()
    }
  }
  toggleBetaModal = () => {
    this.setState({ showBetaModal: !this.state.showBetaModal })
  }
  toggleTypeform = () => {
    this.setState({ showTypeform: !this.state.showTypeform })
  }
  renderCampuses = campuses =>
    campuses.map((campus, index) => (
      <div
        role={'button'}
        key={index}
        className="border-bottom-1 mt-2"
        onClick={() => this.handleCampusSelection(campus)}
      >
        <a onClick={() => this.handleCampusSelection(campus)}>
          <img
            className="img-responsive center-block img-avatar"
            style={{ width: '50px', height: '50px' }}
            src={
              campus.campus_photo
                ? campus.campus_photo.small_file_url
                : conferencePlaceholder
            }
          />
          <span className="ml-3">{campus.name}</span>
        </a>
      </div>
    ))
  renderCommunities = communities =>
    communities.map((community, index) => (
      <div
        role={'button'}
        key={index}
        className="border-bottom-1 mt-2"
        onClick={() => this.handleCommunitySelection(community)}
      >
        <a onClick={() => this.handleCommunitySelection(community)}>
          <img
            className="img-responsive center-block img-avatar"
            style={{ width: '50px', height: '50px' }}
            src={
              community.community_photo
                ? community.community_photo.small_file_url
                : conferencePlaceholder
            }
          />
          <span className="ml-3">{community.name}</span>
        </a>
      </div>
    ))

  render() {
    const { showCampusPicker, showCommunityPicker, showBetaModal } = this.state
    const {
      titleText,
      isFetching,
      wrapperClassId,
      background,
      isDashboardLogin,
      routeToSignup,
      history,
      location,
    } = this.props

    const query = QueryStringToJSON(location)
    const source = query && query.source ? query.source : ''
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Invalid email address')
            .required('Email is required!'),
          password: Yup.string().required('Password is required!'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          this.handleLogin(values)
          setSubmitting(false)
        }}
      >
        {props => {
          const { dirty, isSubmitting, handleSubmit } = props
          return (
            <LoginContainer
              id={wrapperClassId ? wrapperClassId : undefined}
              background={background}
            >
              <form onSubmit={handleSubmit}>
                <LogoPrompt>
                  <LogoWrapper>
                    <CoworksLogoWhite src={coworksLogoWhite} />
                  </LogoWrapper>
                  <PromptContainer>
                    Takes the chaos out of managing your coworking space.
                  </PromptContainer>
                  <div className="row mt-5">
                    <div className="col">
                      Have an account? <Link to="/login">Sign In</Link>
                    </div>
                  </div>
                </LogoPrompt>
                <SignUpWrapper>
                  {isFetching ? (
                    <Spinner />
                  ) : (
                    <RightLoginStyled>
                      <RedirectContainer className="mt-auto">
                        <div className="col">
                          Have an account? <Link to="/login">Sign In</Link>
                        </div>
                      </RedirectContainer>
                      <FormContainer className="d-flex align-items-center justify-content-center">
                        <ReactTypeformEmbed
                          url={`https://coworks.typeform.com/to/VUlOQN${
                            source ? `#source=${source}` : ''
                          }`}
                        />
                      </FormContainer>
                    </RightLoginStyled>
                  )}
                </SignUpWrapper>
              </form>
            </LoginContainer>
          )
        }}
      </Formik>
    )
  }
}

Login.displayName = 'Login'

const mapStateToProps = state => {
  return {
    campuses:
      state.ui.activeCommunity && state.ui.activeCommunity.campuses
        ? state.ui.activeCommunity.campuses
        : [],
    user: state.user,
    isFetching: state.ui.isFetching,
    activeCommunity: state.ui.activeCommunity,
    activeCampus: state.ui.activeCampus,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    campus_actions: bindActionCreators(campusActions, dispatch),
    community_actions: bindActionCreators(communityActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
