import {
  SET_GCAL_CALENDARS,
  SET_GCAL_EVENTS,
  CREATE_GOOGLE_EVENT,
  UPDATE_BOOKING,
  CREATE_BOOKING,
} from '../reduxActions/actionTypes'

export default function(gcal = [], action) {
  switch (action.type) {
    case SET_GCAL_EVENTS:
      return Object.assign({}, gcal, { ...gcal, events: action.events })
    case SET_GCAL_CALENDARS:
      return Object.assign({}, gcal, { ...gcal, calendars: action.calendars })
    case CREATE_GOOGLE_EVENT:
      return Object.assign({}, gcal, { ...gcal, createdEvent: action.result })
    case UPDATE_BOOKING:
      // only updates to bookings include google calendar event details
      if (action.booking && action.booking.gcal_calendar_id) {
        return Object.assign({}, gcal, {
          ...gcal,
          lastUsedCalendarId: action.booking.gcal_calendar_id,
        })
      }
      return gcal
    // case CREATE_BOOKING:
    //   if (action.booking && action.booking.gcal_calendar_id) {
    //     return Object.assign({}, gcal, {
    //       lastUsedCalendarId: action.booking.gcal_calendar_id,
    //     })
    //   }
    //   return gcal
    default:
      return gcal
  }
}
