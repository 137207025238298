import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  background: #000;
  border-radius: 50%;
  color: white;
  ${props => {
    if(props.size) {
      return css`
        width: ${props.size}px;
        height: ${props.size}px;
      `
    }
    return css``
  }}
`
const Icon = styled(FontAwesomeIcon)`
  width: 100%;
  height: 100%;
`

const IconDot = ({
  icon,
  ...other
}) => {
  return (
    <Container {...other}>
      <Icon icon={icon}/>
    </Container>
  )
}

IconDot.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number,
}
IconDot.defaultProps = {
  size: 14
}

IconDot.displayName = 'IconDot'

export default IconDot