/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Input from '@bit/coworks.base-components.input'
import TextArea from '@bit/coworks.base-components.text-area'
import Toggle from '@bit/coworks.base-components.toggle'

import { ErrorMessage, useField } from 'formik'
import LabelStyled from './LabelStyled'
export { LabelStyled }
import SingleDatePicker from './SingleDatePicker'

// NOTE: V2 indicates v2 STYLING (coworks-web-components)

// ! Note on usage of These
// just use the components directly in dom. DON'T WRAP IN <Field />

export const CustomInputComponent = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { error, touched } = meta
  return (
    <div>
      {props.inputPrefix ? props.inputPrefix : undefined}
      <Input
        type={props.type ? props.type : 'text'}
        title={label ? label : props.name}
        placeholder={props.placeholder ? props.placeholder : ''}
        error={touched && error ? error : undefined}
        {...field}
        {...props}
      />
    </div>
  )
}
CustomInputComponent.displayName = 'CustomInputComponent'

export const CustomTextAreaComponent = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { error, touched } = meta

  return (
    <TextArea
      title={label ? label : props.name}
      placeholder={props.placeholder ? props.placeholder : ''}
      error={touched && error ? error : undefined}
      {...field}
      {...props}
    />
  )
}
CustomTextAreaComponent.displayName = 'CustomTextAreaComponent'

export const CustomDatePickerComponent = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { error, touched } = meta

  const [isFocused, setIsFocused] = useState(false)

  const val = field.value === null ? undefined : field.value
  return (
    <div>
      <SingleDatePicker
        // disabled={Boolean(isLoading || isInactive)}
        numberOfMonths={1}
        className={`form-control w-100 ${touched && error ? error : undefined}`}
        date={val}
        onDateChange={newDate => helpers.setValue(newDate)}
        focused={isFocused}
        onFocusChange={({ focused }) => setIsFocused(focused)}
        isOutsideRange={
          props.isOutsideRange ? props.isOutsideRange : () => false
        }
        showClearDate
        reopenPickerOnClearDate
        showDefaultInputIcon
        small
        placeholder={props.placeholder ? props.placeholder : ''}
      />
    </div>
  )
}
CustomDatePickerComponent.displayName = 'CustomDatePickerComponent'

export const CustomToggleComponent = props => {
  const [field, meta, helpers] = useField(props)
  const { error, touched } = meta
  return (
    <div>
      {props.inputPrefix ? props.inputPrefix : undefined}
      <Toggle
        error={touched && error ? error : undefined}
        checked={field.value}
        value={props.value}
        {...field}
        {...props}
      />
    </div>
  )
}
CustomToggleComponent.displayName = 'CustomToggleComponent'
