import * as actionTypes from './actionTypes'

// how to keep persistent app sign-in status
// so that admin user doesn't need to sign in but ONCE

/**
 * CONSIDER:
 * vv Incremental Synchronization vv
 * https://developers.google.com/google-apps/calendar/v3/sync
 */
const CLIENT_ID =
  '621733347369-b2v82jjsk0ai6fc4d01ng9s5jvsaapqn.apps.googleusercontent.com'
const SCOPES = 'https://www.googleapis.com/auth/calendar'
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
]

/**
 * See google API documentation for details on the ^^ above data
 * SCOPES const can have various scope delimiters added after 'calendar'
 * For scope docs see https://developers.google.com/api-client-library/javascript/features/authentication
 * Injecting the script tag (see loadScript method) makes available the global variable window.gapi
 * See componentDidMount hook for more details on window.gapi method
 */

export const injectGApiScript = gApiTimeout => dispatch => {
  // waitForGAPI takes timeout in SECONDS
  if (window.gapi === undefined) {
    //This will inject the script it its null
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/client.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.INJECT_GAPI_SCRIPT,
        injected: false,
      })
      /**
       * SET INTERVAL at which to recheck resolution of injected script tag
       */
      let waited = 0

      function wait(interval) {
        // eslint-disable-next-line consistent-return
        setTimeout(() => {
          waited += interval
          if (window.gapi !== undefined) {
            dispatch({
              type: actionTypes.INJECT_GAPI_SCRIPT,
              injected: true,
            })
            return resolve(window.gapi)
          }
          if (waited >= gApiTimeout * 1000) {
            return reject(
              new Error('Unable to resolve call to GAPI within timeout')
            )
          }
          wait(interval * 2)
        }, interval)
      }

      wait(30)
    })
  }
  return new Promise((resolve, reject) => {
    // this will return the gapi if it already exists
    if (window.gapi !== null) {
      return resolve(window.gapi)
    }
    return reject(new Error('Something went wrong with the Google Api object.'))
  })
}

export const initializeGApi = gApi => dispatch => {
  dispatch({
    type: actionTypes.INIT_GAPI,
    initialized: false,
  })
  return new Promise((resolve, reject) => {
    if (gApi) {
      gApi.load('client:auth2', () => {
        gApi.client
          .init({
            discoveryDocs: DISCOVERY_DOCS,
            clientId: CLIENT_ID,
            scope: SCOPES,
          })
          .then(() => {
            //console.log('gapi loads');
            dispatch({
              type: actionTypes.INIT_GAPI,
              initialized: true,
            })
            resolve()
          })
          .catch(err => {
            dispatch({
              type: actionTypes.INIT_GAPI,
              initialized: false,
            })
            reject(err)
          })
      })
    } else {
      reject(new Error('The google api instance is undefined.'))
    }
  })
}

export const checkGoogleLoginStatus = gApi => dispatch => {
  return new Promise((resolve, reject) => {
    if (gApi.auth2.getAuthInstance().isSignedIn.get()) {
      dispatch({
        type: actionTypes.GOOGLE_AUTH,
        authorized: true,
      })
      resolve()
    } else {
      dispatch({
        type: actionTypes.GOOGLE_AUTH,
        authorized: false,
      })
      reject(new Error('Not logged in to Google.'))
    }
  })
}

export const loginGoogleWithOAuth = gApi => dispatch => {
  return new Promise((resolve, reject) => {
    gApi.auth2
      .getAuthInstance()
      .signIn() // eslint-disable-line
      .then(response => {
        console.log('Response from google OAuth', response)
        dispatch({
          type: actionTypes.GOOGLE_AUTH,
          loggedIn: true,
        })
        resolve()
      })
      .catch(err => {
        dispatch({
          type: actionTypes.GOOGLE_AUTH,
          loggedIn: false,
        })
        reject(err)
      })
  })
}

export const logoutGoogleWithOAuth = gApi => dispatch => {
  return new Promise((resolve, reject) => {
    gApi.auth2
      .getAuthInstance()
      .signOut() // eslint-disable-line
      .then(response => {
        console.log('Response from google OAuth', response)
        dispatch({
          type: actionTypes.GOOGLE_AUTH,
          loggedIn: false,
        })
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function setCalendars(calendars) {
  return { type: actionTypes.SET_GCAL_CALENDARS, calendars }
}
// Other reduxActions
export function getCalendars(gapi, params) {
  return dispatch => {
    const calendars = gapi.client.calendar.calendarList
    calendars
      .list({
        ...params,
      })
      .then(response => {
        dispatch(setCalendars(response.result.items))
      })
      .catch(err => {
        console.log('Error in getCalendars', err)
      })
  }
}

/**
 * Load Calendar Events
 */
export const loadGoogleEvents = (gapi, calID, params, CB) => {
  console.log('List calendar events')
  const events = gapi.client.calendar.events
  events
    .list({
      calendarId: calID,
      ...params,
    })
    .then(response => {
      console.log(response)
      const events = response.result.items
      CB(events)
    })
}

export function successCreatingEvent(response) {
  return { type: actionTypes.CREATE_GOOGLE_EVENT, response }
}
export function successUpdatingEvent(response) {
  return { type: actionTypes.UPDATE_GOOGLE_EVENT, response }
}
export function successMovingEvent(response) {
  return { type: actionTypes.MOVE_GOOGLE_EVENT, response }
}
export function successDeletingEvent(response) {
  return { type: actionTypes.DELETE_GOOGLE_EVENT, response }
}
export function setLastUsedCalendar(calendarId) {
  return { type: actionTypes.DELETE_GOOGLE_EVENT, response: calendarId }
}

/**
 * Create New Event
 */
export const createGoogleEvent = (gapi, calID, params) => dispatch => {
  return new Promise((resolve, reject) => {
    const events = gapi.client.calendar.events
    events
      .insert({
        calendarId: calID,
        ...params,
      })
      .then(response => {
        const googleEvent = JSON.parse(JSON.stringify(response.result))
        googleEvent.calendarId = calID
        dispatch(successCreatingEvent(googleEvent))
        resolve(googleEvent)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * Edit event
 */
export const updateGoogleEvent = (gapi, calID, eventID, params) => dispatch => {
  const events = gapi.client.calendar.events
  return new Promise((resolve, reject) => {
    events
      .update({
        calendarId: calID,
        eventId: eventID,
        ...params,
      })
      .then(response => {
        dispatch(successUpdatingEvent(response.result))
        resolve(response.result)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * Move Google event to a new calendar
 */
export const moveGoogleEvent = (
  gapi,
  calID,
  eventID,
  destinationCalId
) => dispatch => {
  const events = gapi.client.calendar.events
  return new Promise((resolve, reject) => {
    events
      .move({
        calendarId: calID,
        eventId: eventID,
        destination: destinationCalId,
      })
      .then(response => {
        const googleEvent = JSON.parse(JSON.stringify(response.result))
        googleEvent.calendarId = destinationCalId
        dispatch(successMovingEvent(googleEvent))
        resolve(googleEvent)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * Move Google event to a new calendar
 */
export const deleteGoogleEvent = (gapi, calID, eventID) => dispatch => {
  const events = gapi.client.calendar.events
  return new Promise((resolve, reject) => {
    events
      .delete({
        calendarId: calID,
        eventId: eventID,
      })
      .then(response => {
        dispatch(successDeletingEvent(response.result))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * Delete event
 */
export const deleteEvent = (gapi, calID, eventID, CB) => {
  const events = gapi.client.calendar.events

  events
    .delete({
      calendarId: calID,
    })
    .then(response => {
      CB(response)
    })
}
