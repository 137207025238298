import React, { useState, useEffect } from 'react'

// ASSETS
import '../../../styles/coworks/components/frontDesk/FrontDesk.scss'

// DEPENDENCIES
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import * as socketMethods from '../Conference/SocketFunctionConstants'
import { Offline, Online } from 'react-detect-offline'
import { toast } from 'react-toastify'
import moment from 'moment'
import ButtonStyled from '@global/Base/Button/ButtonStyled'
import styled from 'styled-components'
import Login from '@components/Login/Login'

// REDUX BITS
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import * as leadActions from '@reduxActions/leadActions'
import * as checkinActions from '@reduxActions/checkinActions'
import * as uiActions from '@reduxActions/uiActions'

// CHILDREN
import MemberSelector from '../../Directory/Members/MemberSelector'
import ScheduleTourForm from './ScheduleTourForm'
import ConfirmMessage from './ConfirmMessage'
import Success from './SuccessModal'

// Replace this with a redux-doo, maybe?
import { sendNotification } from '../../../services/apiService'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { CHECKIN_VISITOR } from '@global/Constants/CheckinsConstants'

const ROOM = 'frontdesk'

const FormContainer = styled.div`
  /* min-height: 100vh; */
`
const ModalStyled = styled(Modal)`
  margin: 0 auto;
  .modal-content {
    height: 100vh;
    background: none;
    border: none;
  }
`
const FloatingButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 16px;
`
const ButtonOption = styled(ButtonStyled)`
  height: 100px;
  border-radius: 50vh;
  border: none;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WAIT_INTERVAL = 1000 * 30
let timer = null

function FrontDesk({
  activeCampus,
  activeCommunity,
  history,
  location,
  lead_actions,
  checkin_actions,
  user,
  ui_actions,
  room_actions,
  isTabletUser,
  isFetching,
}) {
  const [isMemberSelectorModalOpen, setisMemberSelectorModalOpen] = useState(
    false
  )
  const [isScheduleTourModalOpen, setisScheduleTourModalOpen] = useState(false)
  const [isConfirmModalOpen, setisConfirmModalOpen] = useState(false)
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false)
  const [isAdminModalOpen, setisAdminModalOpen] = useState(false)
  const [memberBeingVisited, setmemberBeingVisited] = useState(null)
  const [successMessage, setsuccessMessage] = useState('')
  const [modalTimeout, setModalTimeout] = useState(80000)
  const [shortModalTimeout, setShortModalTimeout] = useState(20000)

  useEffect(() => {
    pubSub()

    if (!isTabletUser) {
      ui_actions.setIsTabletUser(true)
    }
    ui_actions.setFrontDesk(true)
    room_actions.storeRoomToken(null)

    return () => {
      console.log('[BookingTablet] Will Unmount')
      socketMethods.closeSocket()
    }
  }, [isTabletUser])

  const toggleMemberSelectorModal = () => {
    if (!isMemberSelectorModalOpen) {
      setisMemberSelectorModalOpen(true)
      setTimeout(() => {
        setisMemberSelectorModalOpen(false)
      }, modalTimeout)
    } else {
      setisMemberSelectorModalOpen(false)
    }
  }

  const toggleScheduleTourModal = () => {
    if (!isScheduleTourModalOpen) {
      setisScheduleTourModalOpen(true)
      setTimeout(() => {
        setisScheduleTourModalOpen(false)
      }, modalTimeout)
    } else {
      setisScheduleTourModalOpen(false)
    }
  }
  const toggleConfirmModal = () => {
    if (!isConfirmModalOpen) {
      setisConfirmModalOpen(true)
      setTimeout(() => {
        setisConfirmModalOpen(false)
      }, modalTimeout)
    } else {
      setisConfirmModalOpen(false)
    }
  }

  const toggleSuccessModal = message => {
    if (!isSuccessModalOpen) {
      setisSuccessModalOpen(true)
      setsuccessMessage(message)
      setTimeout(() => {
        setisSuccessModalOpen(false)
        setsuccessMessage(null)
      }, shortModalTimeout)
    } else {
      setisSuccessModalOpen(false)
      setsuccessMessage(null)
    }
  }

  const toggleAdminModal = () => {
    if (!isAdminModalOpen) handleActivityInLoginForm()
    setisAdminModalOpen(!isAdminModalOpen)
  }

  const checkInVisitor = member => {
    console.log('member is: ', member)
    setmemberBeingVisited(member)
    toggleConfirmModal()
  }
  function pubSub() {
    socketMethods.connectToSocket(ROOM)
    socketMethods.subscribeShouldFrontDeskTabletRefresh(modifiedVersion => {
      console.log(
        '[FrontDesk.js] gets that sweet sweet modifiedVersion, which is: ',
        modifiedVersion
      )
      checkForUpdates(true)
    })
  }

  const checkForUpdates = forceUpdate => {
    // this will refresh the window grabbing the new code!
    if (forceUpdate) {
      window.swUpdate = true
    }
    if (window.swUpdate) {
      window.location = location.pathname
    }
  }

  const handleLoginSuccess = () => {
    // logged in successfully
    ui_actions.setFrontDesk(false)
    history.push('/tablet-type')
  }
  const handleActivityInLoginForm = () => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setisAdminModalOpen(false)
    }, WAIT_INTERVAL)
  }

  const sendMessage = ({ visitorMessage, firstName, lastName, email }) => {
    const memberId = memberBeingVisited.id
    createCheckIn({
      message: visitorMessage,
      memberId: memberId,
      campusId: activeCampus.id,
      firstName: firstName,
      lastName: lastName,
      email: email,
    })
    // sendNotification(notificationParams)
  }

  const createCheckIn = ({
    email,
    firstName,
    lastName,
    contactId,
    memberId,
    campusId,
    message,
  }) => {
    const type = CHECKIN_VISITOR
    const from_campus = null

    const params = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      contact_id: contactId,
      user_id: memberId,
      campus_id: campusId,
      from_campus,
      community_id: activeCommunity.id,
      creator_id: user.id,
      message,
      time_in: moment().toISOString(),
      type,
      created_by: 'FrontDesk',
    }

    return checkin_actions.createCheckIn(params, type)
  }

  const scheduleTour = values => {
    const object = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
      email: values.email,
      interested_in: values.interestedIn,
      campus_id: activeCampus.id,
      created_by: 'FrontDesk',
      community_id: activeCommunity.id,
      company_name: values.teamName,
      stripe_account_id: activeCommunity.stripe_account_id,
    }
    console.log('the obj from ScheduleTour is: ', object)
    lead_actions
      .scheduleTour(object)
      .then(response => {
        console.log(response)
        toggleScheduleTourModal()
        if (response && response.lead && response.lead.first_name) {
          toggleSuccessModal(
            `Thanks ${response.lead.first_name}. You have successfully notified a manager. We will reach out soon!`
          )
        } else {
          toggleSuccessModal(
            'You have successfully scheduled a tour of our campus'
          )
        }
      })
      .catch(err => {
        console.log(err)
        toggleScheduleTourModal()
        toast.error('There was a problem connecting.')
      })
  }

  const campus = activeCampus
  const campusPhoto =
    campus && campus.campus_photo ? campus.campus_photo.file_url : null
  const community_preference = activeCommunity.community_preference
  const brandColor = community_preference.branding_primary_color

  return (
    <div
      id="FrontDesk"
      className="front-desk-background flex-column justify-content-between"
      style={{
        backgroundImage:
          'url(https://s3.amazonaws.com/coworksprod/assets/admin-dashboard-assets/frontdesk-tablet-background.jpg)',
        backgroundSize: 'cover',
      }}
    >
      <div className=" h-100 overlay">
        <Offline>
          <div className="col-md-12">
            <div className="text-white bg-danger text-center card">
              <div className="card-body">No internet detected.</div>
            </div>
          </div>
        </Offline>
        <div className="row ml-4 full-height white-overlay">
          <FormContainer className="col-md-12 w-100 d-flex flex-column align-items-center justify-content-center ">
            <div className="mb-5">
              <img
                src={campusPhoto}
                className="mx-auto d-block img-avatar campus-avatar"
                style={{ width: '22vh' }}
              />
              <h1 className="text-center mb-2 campus-name mt-3">
                {campus.name}
              </h1>
            </div>
            <div className="row w-75 mt-2">
              <div className="col-6">
                <div className="card-null check-in-card">
                  <ButtonOption
                    className="btn btn-block btn-lg"
                    color={brandColor}
                    onClick={toggleMemberSelectorModal}
                  >
                    <ContentWrapper>
                      <span className="h4 m-0 btn-text d-flex align-items-center">
                        <FontAwesomeIcon
                          className="mr-2"
                          icon="users"
                          size="2x"
                        />{' '}
                        Visitor Check-in
                      </span>
                    </ContentWrapper>
                  </ButtonOption>
                </div>
              </div>
              <div className="col-6">
                <div className="card-null check-in-card">
                  <ButtonOption
                    className="btn btn-block btn-lg"
                    color={brandColor}
                    onClick={toggleScheduleTourModal}
                  >
                    <ContentWrapper>
                      <span className="h4 m-0 btn-text d-flex align-items-center">
                        <FontAwesomeIcon
                          className="mr-2"
                          icon="calendar-alt"
                          size="2x"
                        />{' '}
                        Schedule a Tour
                      </span>
                    </ContentWrapper>
                  </ButtonOption>
                </div>
              </div>
            </div>
          </FormContainer>
          <FloatingButton
            className="btn btn-outline-white btn-sm"
            onClick={toggleAdminModal}
          >
            <FontAwesomeIcon icon="lock" /> Admin
          </FloatingButton>
        </div>
      </div>

      {/* Schedule Tour Modal and Confirm */}
      <Modal
        isOpen={isScheduleTourModalOpen}
        toggle={toggleScheduleTourModal}
        className="fade in bd-example-modal-lg modal-lg member-modal"
      >
        <div id="BookingAuth" className="request-tour">
          <div
            id="close-modal"
            className="fixed-top"
            aria-hidden="true"
            onClick={toggleScheduleTourModal}
          >
            <span className="mr-2 mt-2 cancel-btn">
              <FontAwesomeIcon icon="times" />
            </span>
          </div>
          <div className="p-4" id="ScheduleTourForm">
            <div className="row">
              <ScheduleTourForm
                toggleScheduleTourModal={toggleScheduleTourModal}
                activeCampus={activeCampus}
                publicCommunity={activeCommunity}
                scheduleTour={scheduleTour}
                type={'TourRequest'}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* Visitor Checkin Modal and Confirm */}
      <Modal
        isOpen={isMemberSelectorModalOpen}
        toggle={toggleMemberSelectorModal}
        className="fade in bd-example-modal-lg modal-lg member-modal"
      >
        <MemberSelector
          toggleModal={toggleMemberSelectorModal}
          action={checkInVisitor}
          isFetching={isFetching}
          campusId={activeCampus.id}
          type="Check In"
        />
      </Modal>

      {memberBeingVisited && (
        <ConfirmMessage
          isOpen={isConfirmModalOpen}
          toggleModal={toggleConfirmModal}
          toggleSuccessModal={toggleSuccessModal}
          whitelabeled={community_preference.is_whitelabeled}
          sendMessage={sendMessage}
          member={memberBeingVisited}
        />
      )}

      <Modal
        isOpen={isSuccessModalOpen}
        toggle={toggleSuccessModal}
        className="fade in bd-example-modal-lg modal-lg member-modal"
      >
        {Success({
          whitelabeled: community_preference.is_whitelabeled,
          toggleSuccessModal: () => toggleSuccessModal(null),
          successMessage: successMessage,
        })}
      </Modal>
      <ModalStyled
        isOpen={isAdminModalOpen}
        toggle={toggleAdminModal}
        className="w-100"
        id="AdminAuth"
      >
        <Login
          history={history}
          titleText={'Frontdesk Tablet'}
          handleLoginSuccess={handleLoginSuccess}
          hideFooterLinks
          notifyOfEvent={handleActivityInLoginForm}
          onClose={toggleAdminModal}
        />
      </ModalStyled>
    </div>
  )
}

FrontDesk.displayName = 'FrontDesk'
FrontDesk.propTypes = {
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  lead_actions: PropTypes.object,
  checkin_actions: PropTypes.object,
  user: PropTypes.object,
  ui_actions: PropTypes.object,
  room_actions: PropTypes.object,
  isTabletUser: PropTypes.bool,
  isFetching: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isTabletUser: state.ui.isTabletUser,
    isFetching: state.ui.isFetching,
    user: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
    lead_actions: bindActionCreators(leadActions, dispatch),
    booking_actions: bindActionCreators(bookingActions, dispatch),
    checkin_actions: bindActionCreators(checkinActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontDesk)
