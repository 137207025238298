/* eslint-disable react/display-name,react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'

const withBlockDays = WrappedComp => ({ publicCampus, ...other }) => {
  const checkBlockedDay = day => {
    if (
      publicCampus &&
      publicCampus.preferences &&
      publicCampus.preferences.hours_of_operation
    ) {
      const hoursCopy = publicCampus.preferences.hours_of_operation
      const blockedDays = Object.keys(hoursCopy).map(dayOfWeek => {
        if (
          hoursCopy[dayOfWeek][0].open === 0 &&
          hoursCopy[dayOfWeek][0].close === 0
        )
          return dayOfWeek
        return false
      })
      const weekday = moment.weekdays(day.weekday())
      return blockedDays.includes(weekday.toLowerCase())
    }
    console.warn("Wasn't able to find a preferences object on the publicCampus")
    return false
  }

  return <WrappedComp isDayBlocked={checkBlockedDay} {...other} />
}

withBlockDays.displayName = 'withBlockDays'

withBlockDays.propTypes = {
  publicCampus: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    publicCampus: state.ui.publicCampus,
  }
}

const composedHoc = compose(connect(mapStateToProps), withBlockDays)

export default composedHoc
