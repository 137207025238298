import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Table, { Column } from '@global/Table'
import moment from 'moment'
import * as Constants from '@global/Constants'

const Container = styled.div`
  display: flex;
  hr {
    width: 100%;
  }
`
const StyledTable = styled(Table)`
  width: 100%;
`
const Header = styled.h3`
  margin-bottom: 0;
`
const Wrapper = styled.div``

const RefundsCard = ({ charge, currencySymbol, className }) => {
  const getRefunds = () => {
    let list = []
    if (charge && charge.refunds && Array.isArray(charge.refunds.data)) {
      list = charge.refunds.data
    }
    return list
  }

  const columns = () => {
    return [
      {
        name: 'Amount',
        options: {
          sortable: false,
          customRender: data => {
            const realAmount = data.amount / 100
            const symbol = Constants.getCurrencySymbolFromName(data.currency)
            return `${symbol}${realAmount.toFixed(2)}`
          },
        },
      },
      {
        name: 'Date',
        options: {
          sortable: false,
          customRender: data => moment.unix(data.created).format('LLL'),
        },
      },
    ]
  }

  return (
    <Container className={className}>
      <Header>Refunds</Header>
      <hr />
      <Wrapper>
        <StyledTable
          columns={columns()}
          objects={getRefunds()}
          type="refunds"
          placeholderSubtext1="There have been no refunds recorded for this invoice."
        />
      </Wrapper>
    </Container>
  )
}

RefundsCard.displayName = 'Refunds Card'

RefundsCard.propTypes = {
  charge: PropTypes.object,
  currencySymbol: PropTypes.object,
}

export default RefundsCard
