/* eslint-disable no-invalid-this */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

import styled from 'styled-components'
import { captureException } from '@global/ErrorFactory'

const UploadContainer = styled.div`
  cursor: pointer;
`

export default class ImageUploadComponent extends Component {
  static defaultProps = {
    aspectRatio: 1,
    uploadText: 'Upload Photo',
    index: 0,
  }
  // imagePreviewUrl can be a url or a local base 64 encoded string!
  static propTypes = {
    imageUrl: PropTypes.string,
    onUpload: PropTypes.func,
    index: PropTypes.number,
    uploadText: PropTypes.string,
    aspectRatio: PropTypes.number,
    disabled: PropTypes.bool,
    style: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      imageUrl: props.imageUrl,
      src: null,
      cropperOpen: false,
      crop: {
        aspect: props.aspectRatio,
        height: 85,
        unit: '%',
      },
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //todo replace with static getDerivedStateFromProps(props, state) {
    // todo is this necessary
    if (nextProps.imageUrl) {
      this.setState({ imageUrl: nextProps.imageUrl })
    }
  }

  onSelectFile = eventStuff => {
    // eventStuff.preventStuffDefault()
    if (eventStuff.target.files && eventStuff.target.files.length > 0) {
      const file = eventStuff.target.files[0]
      if (file.size > 6000000) {
        toast.info(
          'Whoops! This photo is too big. Try something smaller than 6mb.'
        )
        captureException({
          text: "Failed to upload image because it's bigger than 6MB",
        })
        // } else if (file.type === 'image/png') {
        //   toast.info('Whoops! We do not support PNG files just yet!')
      } else {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          this.setState({
            fileType: file.type,
            fileName: file.type,
            cropperOpen: true,
            src: reader.result,
          })
        })
        try {
          reader.readAsDataURL(file)
        } catch (event) {
          toast.info('Whoops! Could not process this photo.')
          captureException({
            text: 'Could not process this photo',
            error: event,
          })
        }
      }
    }
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropComplete = crop => {
    // needs to remain in this shell function because of async natures.
    this.makeClientCrop(crop)
  }

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop })
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newImage.jpeg'
      )
      // eslint-disable-next-line react/prop-types
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const newFileType = 'image/jpeg'
    return canvas.toDataURL(newFileType)
  }

  handleCancelCropper = () => {
    // cancelling the cropping modal
    this.setState({
      croppedImageUrl: null,
      src: null,
      cropperOpen: false,
    })
  }
  handleSaveCropper = () => {
    // save the cropper
    this.setState({
      cropperOpen: false,
    })
    this.props.onUpload(this.state.croppedImageUrl)
  }

  _handleDivClick = () => {
    const idd = `photoUpload${this.props.index}`
    document.getElementById(idd).click()
  }

  /* eslint-disable max-len */
  render() {
    const { crop, croppedImageUrl, src, cropperOpen, imageUrl } = this.state
    const { disabled } = this.props
    const image = croppedImageUrl ? croppedImageUrl : imageUrl
    // const image = croppedImageUrl
    const sectionStyle = {
      width: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      minHeight: image ? 'auto' : '200px',
    }

    if (disabled) {
      sectionStyle.opacity = 0.4
      sectionStyle.backgroundColor = '#000'
    }

    const actions = [
      <Button key="cancel" color="secondary" onClick={this.handleCancelCropper}>
        Cancel
      </Button>,
      <Button key="save" color="primary" onClick={this.handleSaveCropper}>
        Save
      </Button>,
    ]
    return (
      <UploadContainer
        className="d-flex justify-content-space-between"
        style={this.props.style}
      >
        <div
          id="imageFrame"
          className="border d-flex justify-content-center align-items-center"
          onClick={this._handleDivClick}
          style={sectionStyle}
        >
          {image && (
            <img
              alt="preview-thumbnail"
              className="img-responsive w-100 center-block"
              src={image}
              // style={{ display: image ? 'block' : 'none' }}
              style={{ maxWidth: '100%' }}
            />
          )}
          <p hidden={Boolean(image)} className={'text-fade text-center'}>
            <FontAwesomeIcon icon="camera" className="text-center" size="2x" />
            <br />
            <span className="mt-3">{this.props.uploadText}</span>
          </p>
          <input
            id={`photoUpload${this.props.index}`}
            type="file"
            hidden
            onChange={this.onSelectFile}
          />
        </div>
        {/* {croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
        )} */}
        <Modal
          isOpen={cropperOpen}
          onRequestClose={this.handleCancelCropper}
          className="modal-primary modal-lg fade in"
        >
          <ModalHeader toggle={this.handleClose}>Crop Photo</ModalHeader>
          <ModalBody>
            <Row className="col-xs-12 d-flex justify-content-center">
              {src && (
                <ReactCrop
                  src={src}
                  crop={crop}
                  keepSelection
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
            </Row>
          </ModalBody>
          <ModalFooter>{actions}</ModalFooter>
        </Modal>
      </UploadContainer>
    )
  }
}

ImageUploadComponent.displayName = 'ImageUploadComponent'
