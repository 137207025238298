import {
  //LEADS
  SET_LEADS,
  UPDATE_LEADS,
  CLEAR_LEADS,
  CREATE_LEAD,
  ARCHIVE_LEAD,
  UPDATE_LEAD,
  //PROSPECTS
  SET_PROSPECTS,
  UPDATE_PROSPECTS,
  CLEAR_PROSPECTS,
  CREATE_PROSPECT,
  ARCHIVE_PROSPECT,
  UPDATE_PROSPECT,
  //PENDING MEMBER
  SET_PENDING_MEMBERS,
  CLEAR_CAMPUS_DATA,
  UPDATE_PENDING_MEMBER,
} from '../reduxActions/actionTypes'
import { arrayMerge, sortByCreatedDate } from './reducerConstants'
import initialState from './initialState'

export default function (leads = initialState.leads, action) {
  const leadsArray = leads.leads
  const leadsMeta = leads.leadsMeta
  const prospectsArray = leads.prospects
  const prospectsMeta = leads.prospectsMeta
  const pendingMembersArray = []

  switch (action.type) {
    case CREATE_LEAD:
      return {
        ...leads,
        leads: sortByCreatedDate([
          ...leadsArray,
          Object.assign({}, action.lead),
        ]),
      }
    case CREATE_PROSPECT:
      return {
        ...leads,
        prospects: sortByCreatedDate([
          ...prospectsArray,
          Object.assign({}, action.lead),
        ]),
      }
    case SET_LEADS:
      return {
        ...leads,
        leads: action.leads,
        leadsMeta: action.meta,
      }
    case SET_PROSPECTS:
      return {
        ...leads,
        prospects: action.leads,
        prospectsMeta: action.meta,
      }
    case CLEAR_LEADS:
      return {
        ...leads,
        leads: [],
        leadsMeta: null,
      }
    case CLEAR_PROSPECTS:
      return {
        ...leads,
        prospects: [],
        prospectsMeta: null,
      }
    case UPDATE_LEAD:
      if (leadsArray && leadsArray.length > 0) {
        const newArr = []
        leadsArray.forEach(oldLead => {
          if (oldLead.id !== action.lead.id) {
            newArr.push(oldLead)
          } else {
            const elm = action.lead.is_lead
              ? { ...oldLead, ...action.lead }
              : null
            if (elm) newArr.push(elm)
          }
        })
        return {
          ...leads,
          leads: newArr,
        }
      }
      return { ...leads, leads: action.leads }
    case ARCHIVE_LEAD:
      if (leadsArray && leadsArray.length > 0) {
        return {
          ...leads,
          leads: leadsArray.filter(listedLead => {
            if (listedLead.id === action.lead.id) {
              return false
            }
            return true
          }),
        }
      }
      return { ...leads, leads: action.leads }
    case UPDATE_LEADS:
      if (leadsArray && leadsArray.length > 0) {
        const oldLeads = leadsArray
        const newLeads = action.leads
        return {
          ...leads,
          leads: arrayMerge(oldLeads, newLeads),
          leadsMeta: action.meta,
        }
      }
      // list is empty so initialize so just set it.
      return {
        ...leads,
        leads: action.leads,
        leadsMeta: action.meta,
      }
    case UPDATE_PROSPECT:
      if (prospectsArray && prospectsArray.length > 0) {
        const newArr = []
        prospectsArray.forEach(oldLead => {
          if (oldLead.id !== action.lead.id) {
            newArr.push(oldLead)
          } else {
            const elm = !action.lead.is_lead
              ? { ...oldLead, ...action.lead }
              : null
            if (elm) newArr.push(elm)
          }
        })
        return {
          ...leads,
          prospects: newArr,
        }
      }
      return { ...leads, prospects: action.leads }
    case ARCHIVE_PROSPECT:
      if (prospectsArray && prospectsArray.length > 0) {
        return {
          ...leads,
          prospects: prospectsArray.filter(listedLead => {
            if (listedLead.id === action.lead.id) {
              return false
            }
            return true
          }),
        }
      }
      return { ...leads, prospects: action.leads }
    case UPDATE_PROSPECTS:
      if (prospectsArray && prospectsArray.length > 0) {
        const oldLeads = prospectsArray
        const newLeads = action.leads
        return {
          ...leads,
          prospects: arrayMerge(oldLeads, newLeads),
          prospectsMeta: action.meta,
        }
      }
      // list is empty so initialize so just set it.
      return {
        ...leads,
        prospects: action.leads,
        prospectsMeta: action.meta,
      }

    case SET_PENDING_MEMBERS:
      if (pendingMembersArray && pendingMembersArray.length > 0) {
        return {
          ...leads,
          pendingMembers: arrayMerge(
            pendingMembersArray,
            action.pendingMembers
          ),
        }
      }
      // list is empty so initialize so just set it.
      return { ...leads, pendingMembers: action.pendingMembers }
    case UPDATE_PENDING_MEMBER:
      if (pendingMembersArray && pendingMembersArray.length > 0) {
        return {
          ...leads,
          pendingMembers: pendingMembersArray.map(oldMember => {
            if (oldMember.id !== action.pendingMember.id) {
              return oldMember
            }
            return { ...oldMember, ...action.pendingMember }
          }),
        }
      }
      return { ...leads, pendingMembers: [action.pendingMember] }
    case CLEAR_CAMPUS_DATA:
      return initialState.leads
    default:
      return leads
  }
}
