import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as teamActions from '@app/reduxActions/teamActions'
import * as memberActions from '@app/reduxActions/memberActions'
import * as leadActions from '@app/reduxActions/leadActions'
import * as planActions from '@app/reduxActions/planActions'

import { toast } from 'react-toastify'

import * as Constants from '@global/Constants'
import * as TeamBillingConstants from '@global/Constants/TeamBillingConstants'

import {
  resolveFetchingStatus,
  FETCHING_PLANS,
} from '@global/Constants/FetchingConstants'

import { LabelStyled } from '@global/Form/FormComponents'
import CoworksSearch from '@global/Search/CoworksSearch'
import TeamRowSearch from '@app/components/Directory/Teams/TeamRowSearch'
import MemberRowSearch from '@app/components/Directory/Members/MemberRowSearch'
import LeadSearchRow from '../../Leads/LeadSearchRow'

const TYPE_TEAM = 'team'
const TYPE_MEMBER = 'member'
const TYPE_LEAD = 'lead'
const TYPE_PLAN = 'plan'
// HEY DEVELOPER:
// This is a wrapper around the CoworksSearch
// The point is to make it easy to setup so you don't
// have to pass in all the redux, actions, data resolvers etc

// It currently supports the following:
// User, Team, Plan, Lead

// It is already hooked up to redux to make it easy
// so the parent components don't necessarily have to be hooked as well.

// This should be used in replace of Coworks Search where possible for vast
// simplification.
export class QuickSearch extends PureComponent {
  static propTypes = {
    // Redux Store
    team_actions: PropTypes.object.isRequired,
    member_actions: PropTypes.object.isRequired,
    lead_actions: PropTypes.object.isRequired,
    plan_actions: PropTypes.object.isRequired,
    activeCampus: PropTypes.object.isRequired,
    activeCommunity: PropTypes.object.isRequired,
    isFetchingPlans: PropTypes.bool.isRequired,
    teams: PropTypes.array,
    inactiveTeams: PropTypes.array,
    members: PropTypes.array,
    plans: PropTypes.array,
    leads: PropTypes.array,
    // Manual Props
    id: PropTypes.string,
    otherParams: PropTypes.object,
    team: PropTypes.object,
    plan: PropTypes.object,
    member: PropTypes.object,
    handleSearchItemCleared: PropTypes.func,
    handleSearchItemSelected: PropTypes.func.isRequired,
    onCreateOption: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    dataResolver: PropTypes.func,
    placeholder: PropTypes.string,
    title: PropTypes.string,
    runSearchAndAttemptMatchValue: PropTypes.string,
    ignoreIds: PropTypes.array,
    type: PropTypes.string.isRequired,
    showTitle: PropTypes.bool.isRequired,
    isCreatable: PropTypes.bool,
    dataFilter: PropTypes.func,
    clearOnSelect: PropTypes.bool,
  }
  static defaultProps = {
    // this is so that member detail change member
    // function can remove the company the member is already on.
    ignoreIds: [],
    type: TYPE_TEAM,
    showTitle: true,
    isCreatable: false,
    clearOnSelect: false, // will clear the input state when selecting https://react-select.com/advanced#controlled-props
  }

  componentDidMount() {
    // initial data fetch
    const { type, isFetchingPlans, ignoreIds, activeCampus } = this.props
    const actions = this.actionsResolver()
    const defaultData = this.defaultDataResolver()
    switch (type) {
      case TYPE_PLAN:
        if (
          !isFetchingPlans &&
          (!defaultData || (defaultData && defaultData.length === 0))
        ) {
          actions.getPlans(activeCampus.id)
        }
        break
      default:
        break
    }
  }

  actionsResolver() {
    const {
      type,
      team_actions,
      member_actions,
      lead_actions,
      plan_actions,
    } = this.props
    switch (type) {
      case TYPE_TEAM:
        return team_actions
      case TYPE_MEMBER:
        return member_actions
      case TYPE_LEAD:
        return lead_actions
      case TYPE_PLAN:
        return plan_actions
      default:
        return null
    }
  }
  defaultDataResolver() {
    const {
      type,
      teams,
      members,
      leads,
      plans,
      otherParams,
      dataFilter,
    } = this.props
    switch (type) {
      case TYPE_TEAM:
        return otherParams &&
          otherParams.status !== undefined &&
          otherParams.status
          ? this.getTeamsByStatus(otherParams.status)
          : teams
      case TYPE_MEMBER:
        return members
      case TYPE_LEAD:
        return dataFilter ? dataFilter(leads) : leads
      case TYPE_PLAN:
        // we do this so we trigger an inital fetch
        return plans && plans.length > 0 ? plans : true
      default:
        return null
    }
  }

  dataResolver = response => {
    let list = response.teams
    switch (this.props.type) {
      case TYPE_TEAM:
        list = response.teams
        break
      case TYPE_MEMBER:
        list = response.members
        break
      case TYPE_LEAD:
        return this.props.dataFilter
          ? this.props.dataFilter(response)
          : response
      case TYPE_PLAN:
        list = response.plans
        break
      default:
        break
    }

    return this.filterDataIfRequired(list)
  }
  filterDataIfRequired = list => {
    let objectIds = []
    let filteredList = []

    // default is teams
    if (this.props.ignoreIds) {
      objectIds = this.props.ignoreIds
    }

    if (objectIds) {
      filteredList = this.filterByIds(objectIds, list)
    }
    return filteredList
  }

  filterByIds(ids, list) {
    if (!list || list === true) return undefined
    return list.filter(item => {
      return ids.indexOf(item.id) === -1
    })
  }

  renderSearchRow = object => {
    switch (this.props.type) {
      case TYPE_MEMBER:
        return MemberRowSearch({
          key: object.id,
          member: object,
          team: object.teams && object.teams[0],
          index: object.id,
          includeMarginsAndPadding: true,
          includePhoto: false,
          showEdit: false,
          showActions: false,
          showActionButtons: false,
        })
      case TYPE_LEAD:
        if (object.label && object.value) {
          // It's a "new" option so we shouldn't render the lead row.
          return (
            <div>
              Send an invoice to{' '}
              <span className="text-primary">{object.value}</span>
            </div>
          )
        }
        return LeadSearchRow({
          lead: object,
          editLeadClicked: lead => {},
        })
      case TYPE_PLAN:
        return TeamBillingConstants.renderPlanSuggestion(object)
      default:
        return TeamRowSearch({
          key: object.id,
          company: object,
          index: object.id,
          showEdit: false,
          showActions: false,
          editCompanyClicked: () => {},
          includeMarginsAndPadding: true,
          includePhoto: true,
          canEdit: false,
        })
    }

    // default is teams
  }
  handleSearchItemCleared = () => {
    this.setState({
      [this.props.type]: null,
    })
    if (this.props.handleSearchItemCleared) {
      this.props.handleSearchItemCleared(null)
    }
  }
  onInputChange = (query, { action }) => {
    // Prevents resetting our input after option has been selected
    if (action !== 'set-value') this.setState({ inputValue: query })
  }
  getTeamsByStatus = status => {
    const { inactiveTeams, teams } = this.props
    return status === 'inactive' ? inactiveTeams : teams
  }

  render() {
    const {
      id,
      type,
      team,
      member,
      plan,
      activeCampus,
      handleSearchItemSelected,
      onCreateOption,
      handleSearchItemCleared,
      placeholder,
      title,
      runSearchAndAttemptMatchValue,
      showTitle,
      isCreatable,
      otherParams,
      noOptionsMessage,
      clearOnSelect,
    } = this.props
    let value, renderSingleValue, inputTitle, inputPlaceholder

    const actions = this.actionsResolver()
    const defaultData = this.defaultDataResolver()

    switch (type) {
      case TYPE_TEAM:
        value = team
        renderSingleValue = Constants.renderCompanySelected
        inputTitle = title ? title : 'Select Team'
        inputPlaceholder = placeholder
          ? placeholder
          : "Start typing team's name..."
        break
      case TYPE_MEMBER:
        value = member
        renderSingleValue = Constants.renderMemberSelected
        inputTitle = title ? title : 'Select Member'
        inputPlaceholder = placeholder
          ? placeholder
          : "Start typing member's name or email..."
        break
      case TYPE_LEAD:
        value = member
        renderSingleValue = Constants.renderMemberSelected
        inputTitle = title ? title : 'Select Lead'
        inputPlaceholder = placeholder
          ? placeholder
          : "Start typing lead's name or email..."
        break
      case TYPE_PLAN:
        // actions = plan_actions
        // defaultData = dataFilter ? dataFilter(plans) : plans
        value = plan
        renderSingleValue = Constants.renderPlanSelected
        inputTitle = title ? title : 'Select Plan'
        inputPlaceholder = placeholder
          ? placeholder
          : "Start typing a plan's name..."
        break
      default:
        break
    }
    const dataWithIgnore = this.filterDataIfRequired(defaultData)
    return (
      <React.Fragment>
        {showTitle && <LabelStyled>{inputTitle}</LabelStyled>}
        <CoworksSearch
          id={id}
          isCreatable={isCreatable}
          placeholder={inputPlaceholder}
          defaultData={dataWithIgnore} // this fixes the double highlighting problem
          actions={actions}
          activeCampus={activeCampus}
          value={value}
          handleItemSelected={object => handleSearchItemSelected(object)}
          onCreateOption={onCreateOption}
          handleSearchCleared={handleSearchItemCleared}
          dataResolver={this.dataResolver}
          renderSingleValue={renderSingleValue}
          noOptionsMessage={noOptionsMessage}
          isClearable
          clearOnSelect={clearOnSelect}
          otherParams={otherParams}
          renderSearchRow={this.renderSearchRow}
          runSearchAndAttemptMatchValue={runSearchAndAttemptMatchValue}
        />
      </React.Fragment>
    )
  }
}
QuickSearch.displayName = 'QuickSearch'

function mapStateToProps(state, props) {
  const type = props.type
  return {
    type: type ? type.toLowerCase() : 'team',
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    teams: state.teams.teams ? state.teams.teams : [],
    inactiveTeams: state.teams.inactive ? state.teams.inactive : [],
    leads: state.leads.leads ? state.leads.leads : [],
    plans: state.plans ? state.plans : null,
    fetchingPlans: resolveFetchingStatus(state, FETCHING_PLANS),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    member_actions: bindActionCreators(memberActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    lead_actions: bindActionCreators(leadActions, dispatch),
    plan_actions: bindActionCreators(planActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearch)
