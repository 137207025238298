import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import styled from 'styled-components'

import { Modal } from 'reactstrap'
import Success from '@app/components/Tablet/FrontDesk/SuccessModal'
import * as leadActions from '@reduxActions/leadActions'
import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'
import * as _ from 'lodash'
import Spinner from '@global/Spinner'
import { toast } from 'react-toastify'
import { captureException } from '@global/ErrorFactory'
import withPublicCommunityContext from '../withPublicCommunityContext'
import Header from '../ExternalBooking/Header'
import urlParse from '@app/hocs/urlParse'

import ScheduleTourForm from '@components/Tablet/FrontDesk/ScheduleTourForm'

const HeaderStyled = styled(Header)`
  position: absolute;
  z-index: 1;
  width: 100%;
`
class TourRequest extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    isFetching: PropTypes.bool,
    params: PropTypes.object,
    queryParams: PropTypes.object,
    coworks_community_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    publicCommunity: PropTypes.object,
    publicCampus: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      hasSubmitted: true,
      modalTimeout: 80000,
      shortModalTimeout: 20000,
      isSuccessModalOpen: false,
    }
  }

  UNSAFE_componentWillMount() {
    const {
      queryParams,
      publicCommunity,
      coworks_community_actions,
    } = this.props
    let campus = null
    if (publicCommunity) {
      const id = queryParams.campus
      if (id) {
        const campusId = Number(id)
        campus = _.find(publicCommunity.campuses, ['id', campusId])
        coworks_community_actions.setPublicCampus(campus)
      } else {
        campus = publicCommunity.campuses[0]
        coworks_community_actions.setPublicCampus(campus)
      }
    }
  }

  toggleSuccessModal = message => {
    if (!this.state.isSuccessModalOpen) {
      this.setState({
        isSuccessModalOpen: true,
        successMessage: message,
      })
      setTimeout(() => {
        this.setState({
          isSuccessModalOpen: false,
          successMessage: null,
        })
      }, this.state.shortModalTimeout)
    } else {
      this.setState({
        isSuccessModalOpen: false,
        successMessage: null,
      })
    }
  }

  sendRequest = formState => {
    const {
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
    } = this.props.queryParams
    const params = {
      created_by: 'WebsiteTourForm',
      first_name: formState.firstName,
      last_name: formState.lastName,
      email: formState.email,
      interested_in: formState.interestedIn,
      phone: formState.phone,
      community_id: this.props.publicCommunity.id,
      campus_id: formState.campusId,
      company_name: formState.teamName,
      team_website: formState.website,
      title: formState.title,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
    }

    this.props.coworks_community_actions
      .sendRequest(params)
      .then(response => {
        if (response && response.lead && response.lead.first_name) {
          this.toggleSuccessModal(
            `Thanks ${response.lead.first_name}. You have successfully sent your request! We will reach out soon!`
          )
        }
        console.log('Success', response)
      })
      .catch(err => {
        toast.error('Whoops, looks like there was an error!')
        captureException({
          text: `Failed to send tour request: ${JSON.stringify(
            params
          )}, error: ${JSON.stringify(err)}`,
        })
      })
  }

  changeCampus = campus =>
    this.props.coworks_community_actions.setPublicCampus(campus)

  render() {
    const {
      publicCommunity,
      queryParams,
      match,
      publicCampus,
      history,
    } = this.props
    const { isLoading } = this.state
    if (isLoading) {
      return <Spinner />
    }
    if (!isLoading && !publicCommunity) {
      return (
        <div>
          Unable to load the form. Please contact Coworks support at
          support@coworksapp.com! We'll get you fixed up.
        </div>
      )
    }
    const headerText = `Tour Request at ${
      publicCommunity && publicCommunity.name
    }`
    return (
      <div id="MembershipRequest">
        <HeaderStyled
          history={history}
          campus={publicCampus}
          community={publicCommunity}
          onCampusClick={this.changeCampus}
          url={match.path}
        />
        <div>
          <div className="container card p-4" id="ScheduleTourForm">
            <div className="row">
              <ScheduleTourForm
                customHeaderText={headerText}
                customButtonText={'Send Request'}
                type={'TourRequest'}
                customBodyPlaceholder={'I want to schedule a tour!'}
                publicCommunity={publicCommunity}
                activeCampus={publicCampus}
                scheduleTour={state => this.sendRequest(state)}
                {...queryParams}
              />
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.isSuccessModalOpen}
          toggle={this.toggleSuccessModal}
          className="fade in bd-example-modal-lg modal-lg member-modal"
        >
          {Success({
            toggleSuccessModal: () => this.toggleSuccessModal(null),
            successMessage: this.state.successMessage,
            whitelabeled: publicCommunity.community_preference.is_whitelabeled,
          })}
        </Modal>
      </div>
    )
  }
}

TourRequest.displayName = 'TourRequest'

function mapStateToProps(state) {
  return {
    publicCampus: state.ui.publicCampus,
    publicCommunity: state.ui.publicCommunity,
    isFetching: state.ui.isFetching,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    coworks_community_actions: bindActionCreators(
      coworksCommunityActions,
      dispatch
    ),
  }
}

export default compose(
  urlParse(),
  connect(mapStateToProps, mapDispatchToProps),
  withPublicCommunityContext()
)(TourRequest)
