/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import DateTime from 'react-datetime'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import moment from 'moment'
import {
  Input,
  UncontrolledTooltip,
  Row,
  Col,
  Label,
  Collapse,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import * as Roles from '@app/config/roles'
import * as Constants from '@global/Constants'
import renderHTML from 'react-render-html'
import {} from 'reactstrap'

import { LabelStyled, FontAwesomeIconLabel } from '@global/Form/FormComponents'
import MyStatefulEditor from '@global/MyStatefulEditor'
import RoundedImage from '@global/RoundedImage'
import CustomFieldSection from '@global/CustomFields/CustomFieldSection'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import Badge from '@global/Base/Badge/Badge'
import styled from 'styled-components'
import Switch from '@global/Base/Switch/Switch'
import UserPreferences from '@components/External/Preferences/UserPreferences'
import Toggle from '@components/Directory/Members/NewMember/Toggle'
import { IconDot } from '@global/Icons'
import {
  renderDynamicGroups,
  renderValues,
  renderCreatableDropdownGroup,
} from '@global/DynamicGroups/DynamicGroupFunctions'

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colorPrimary};
`
const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

const LinkStyled = styled.a`
  font-size: 16px;
`
const StatusIcon = styled(IconDot)`
  position: absolute;
  bottom: 0;
  left: -4px;
  font-size: 22px;
  border: solid #ffff 3px;
  background: ${props => props.theme.colorSuccess};
`
const ImageContianer = styled.div`
  position: relative;
  margin: auto;
`

export default function MemberForm({
  history,
  match,
  user,
  activeCommunity,
  activeCampus,
  member,
  role,
  bio_html,
  title,
  email,
  phone,
  errors,
  firstName,
  hasDoorProvider,
  showAccess,
  doorAccess,
  goBack,
  handleAccessToggle,
  handleDoorsGrantedChanged,
  doorAccessOptions,
  handleImageUpload,
  handleInputChange,
  handleInputValuesChange,
  handleBioChange,
  lastName,
  updateMember,
  userPhoto,
  type,
  team,
  editCompanyClicked,
  toggleShowArchiveModal,
  toggleShowChangeModal,
  shouldShowArchiveButton,
  resendOnboardingEmail,
  disableWelcomeEmailButton,
  accessDate,
  userJoinedAt,
  currentUser,
  handleUserJoinedAtChanged,
  handleUserAccessDateChanged,
  toggleIsEditing,
  isEditing,
  canEdit,
  hasOnboarded,
  toggleHasOnboarded,
  customFieldMax,
  customFieldsObject,
  userRoomPermissions,
  userRoomPermissionsOptions,
  userSkills,
  userSkillOptions,
  profilePreferences,
  preferences,
  isCheckedIn,
  handleProfilePreferenceChange,
  handleUserPreferenceChange,
  dynamicTags,
}) {
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [dropdownOpen, setDropdown] = useState(false)
  // const [showAccess, setShowAccess] = useState(false)

  function handleCollapseToggle() {
    setCollapseOpen(!collapseOpen)
  }

  function renderKisiToggle() {
    if (hasDoorProvider) {
      if (
        member &&
        member.integration_ids &&
        activeCampus &&
        activeCampus.integration_ids
      ) {
        const campusID = activeCampus.integration_ids.door_access.kisi_place_id
        const userID = member.integration_ids.door_access.kisi_user_id

        if (campusID && userID) {
          return (
            <div className="form-group my-3">
              <LabelStyled isEditing={isEditing}>Kisi Integration</LabelStyled>
              <div className="row col">
                <LinkStyled
                  target="_blank"
                  href={`https://web.kisi.io/places/${campusID}/members/${userID}`}
                >
                  <span>Edit in Kisi</span>
                </LinkStyled>
              </div>
            </div>
          )
        }
      }

      return (
        <Toggle
          className="row my-3"
          isMulti
          isEditing={isEditing}
          show={showAccess}
          name="doorAccess"
          fieldLabel={isEditing ? 'Doors' : 'Kisi Integration'}
          value={doorAccess}
          toggleLabel="Add to Kisi"
          options={
            doorAccessOptions && doorAccessOptions.groups
              ? [
                  { label: 'All Doors', value: null },
                  ...doorAccessOptions.groups.map(gr => ({
                    label: gr.name,
                    value: gr.id,
                    ...gr,
                  })),
                ]
              : [{ label: 'All Doors', value: null }]
          }
          onToggle={handleAccessToggle}
          onChange={handleDoorsGrantedChanged}
          userKisiAccessDate={accessDate}
          handleUserAccessDateChanged={handleUserAccessDateChanged}
          placeholder={'Choose which doors this member can access'}
        >
          <span className="text-muted"> Not Synced </span>
        </Toggle>
      )
    }
    return null
  }
  function renderDoorAccess(doors) {
    if (!doors || doors.length === 0)
      return <span className="text-muted">None</span>
    return doors.map((permission, index) => {
      return <Badge className="mr-2">{doors.label}</Badge>
    })
  }
  let shouldDisableTypePicker = false

  if (
    type === 'Admin' ||
    type === 'SuperAdmin' || // dont want to be able to edit admin role
    !team ||
    !team.campus_teams ||
    team.campus_teams.lengh === 0 ||
    !team.campus_teams[0].is_staff || // dont want to be able to edit roles when not on staff team?
    !Roles.canEditRoles.includes(role) ||
    currentUser.id === member.id
  ) {
    shouldDisableTypePicker = true
  }

  const isInactive = member.is_archived

  return (
    <div className="animated fadeIn">
      <div id="new-member-form">
        <div className="card-block">
          <div className="row">
            <div className="col-12">
              <form>
                <fieldset disabled={isInactive ? 'disabled' : ''}>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <Row>
                            <div className="col-12">
                              {!isEditing ? (
                                renderProfilePicture(member, isCheckedIn)
                              ) : (
                                <ImageUploadComponent
                                  file={userPhoto}
                                  imageUrl={userPhoto}
                                  onUpload={handleImageUpload}
                                />
                              )}
                            </div>
                            {team && (
                              <div className="col-12 mt-5">
                                <Row>
                                  <div className="col-12">
                                    <LabelStyled
                                      isEditing={isEditing}
                                      htmlFor="team"
                                    >
                                      Team
                                    </LabelStyled>
                                  </div>
                                  <div className="col-12 text-truncate">
                                    <TextButton
                                      color="primary"
                                      className="m-0 p-0"
                                      onClick={event =>
                                        editCompanyClicked(team, event)
                                      }
                                    >
                                      <FontAwesomeIconStyled icon="briefcase" />{' '}
                                      {team.name}{' '}
                                      {team &&
                                        team.status === 'active' &&
                                        isInactive && (
                                          <span className="text-muted">
                                            (active)
                                          </span>
                                        )}
                                    </TextButton>
                                  </div>
                                </Row>
                              </div>
                            )}
                          </Row>
                        </div>

                        <div className="col-12 col-md-9">
                          <div className="row">
                            <div className="col">
                              <h3>Member Profile</h3>
                            </div>
                            <React.Fragment>
                              {!isEditing && (
                                <div className="col d-flex justify-content-end align-items-center">
                                  {isInactive ? (
                                    <span className="text-muted mr-2">
                                      Member Inactive
                                    </span>
                                  ) : null}
                                  <ButtonContainer>
                                    <Button
                                      className="mr-2"
                                      color="primary"
                                      onClick={toggleIsEditing}
                                    >
                                      Edit
                                    </Button>
                                    <ButtonDropdown
                                      isOpen={dropdownOpen}
                                      toggle={() => setDropdown(prev => !prev)}
                                    >
                                      <DropdownToggle
                                        color="outline-primary"
                                        caret
                                      >
                                        Actions
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          className="btn"
                                          color="primary"
                                          onClick={event =>
                                            editCompanyClicked(team, event)
                                          }
                                        >
                                          <span>
                                            <FontAwesomeIcon icon="users" />{' '}
                                            View Team
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem
                                          className="btn"
                                          onClick={toggleShowChangeModal}
                                        >
                                          <span>
                                            <FontAwesomeIcon icon="users-cog" />{' '}
                                            Change Team
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem
                                          className="btn"
                                          disabled={Boolean(
                                            isInactive ||
                                              isEditing ||
                                              disableWelcomeEmailButton
                                          )}
                                          color="link"
                                          onClick={resendOnboardingEmail}
                                        >
                                          <span>
                                            <FontAwesomeIcon icon="paper-plane" />{' '}
                                            Resend Onboarding Email
                                          </span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                  </ButtonContainer>
                                </div>
                              )}
                            </React.Fragment>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <LabelStyled
                                isEditing={isEditing}
                                htmlFor="firstName"
                              >
                                First Name
                              </LabelStyled>
                              {isEditing ? (
                                <Input
                                  id="firstName"
                                  type="text"
                                  name="firstName"
                                  className="w-100"
                                  placeholder="First Name"
                                  onChange={handleInputChange}
                                  value={firstName}
                                />
                              ) : (
                                <h5>{member.first_name}</h5>
                              )}
                            </div>
                            <div className="col-12 col-md-6">
                              <LabelStyled
                                isEditing={isEditing}
                                htmlFor="lastName"
                              >
                                Last Name
                              </LabelStyled>
                              {isEditing ? (
                                <Input
                                  id="lastName"
                                  type="text"
                                  name="lastName"
                                  className="w-100"
                                  placeholder="Last Name"
                                  onChange={handleInputChange}
                                  value={lastName}
                                />
                              ) : (
                                <h5>{member.last_name}</h5>
                              )}
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-12 col-md-6">
                              <LabelStyled
                                isEditing={isEditing}
                                htmlFor="email"
                              >
                                Email
                              </LabelStyled>
                              {isEditing ? (
                                <Input
                                  id="email"
                                  type="email"
                                  name="email"
                                  className="w-100"
                                  placeholder="name@email.com"
                                  onChange={handleInputChange}
                                  value={email}
                                />
                              ) : (
                                <h5>
                                  <a href={`mailto:${email}`}>{member.email}</a>
                                </h5>
                              )}
                            </div>
                            <div className="col-12 col-md-6">
                              <LabelStyled
                                isEditing={isEditing}
                                htmlFor="email"
                              >
                                Title
                              </LabelStyled>
                              {isEditing ? (
                                <Input
                                  id="title"
                                  type="text"
                                  name="title"
                                  className="w-100"
                                  placeholder="Title"
                                  onChange={handleInputChange}
                                  value={title}
                                />
                              ) : (
                                <h5>{title}</h5>
                              )}
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-12 col-md-6">
                              <LabelStyled
                                isEditing={isEditing}
                                htmlFor="phone"
                              >
                                Phone
                              </LabelStyled>
                              {isEditing ? (
                                <Input
                                  id="phone"
                                  name="phone"
                                  className="w-100"
                                  placeholder="555-555-5555"
                                  onChange={handleInputChange}
                                  value={phone}
                                />
                              ) : (
                                <h5>{phone}</h5>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            {team &&
                              team.campus_teams &&
                              team.campus_teams.length > 0 && (
                                <div className="col-12 col-md-6">
                                  <LabelStyled
                                    id="type-field"
                                    isEditing={isEditing}
                                    htmlFor="type"
                                  >
                                    Account Type{' '}
                                    <FontAwesomeIconLabel icon="question-circle" />
                                  </LabelStyled>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="type-field"
                                  >
                                    <div className="row">
                                      <div className="col-md-12">
                                        <h6 className="mt-2">Type</h6>
                                        <p>This is the access of the user.</p>
                                        <p>
                                          <b>
                                            To change this, a user must be on
                                            the staff team.
                                          </b>
                                        </p>
                                        <p>
                                          Members do not have access to the
                                          Coworks Admin Dashboard.{' '}
                                        </p>
                                        <p>
                                          Managers, Sales Admins, and Events
                                          Coordinators have access to payment
                                          information.
                                        </p>
                                        <p>
                                          All other types limit other views.
                                        </p>
                                      </div>
                                    </div>
                                  </UncontrolledTooltip>
                                  {isEditing ? (
                                    <React.Fragment>
                                      {type === 'Admin' ||
                                      type === 'SuperAdmin' ? (
                                        <h5>Administrator</h5>
                                      ) : (
                                        <select
                                          id="type"
                                          name="type"
                                          disabled={shouldDisableTypePicker}
                                          className="form-control"
                                          value={type}
                                          onChange={handleInputChange}
                                        >
                                          {Roles.listOfRolesForFrontEnd.map(
                                            item => (
                                              <option value={item.role}>
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <h5>
                                        {type === 'SuperAdmin'
                                          ? 'Coworks Administrator'
                                          : getFrontEndRole(member.type)}
                                      </h5>
                                    </React.Fragment>
                                  )}
                                </div>
                              )}
                            <div className="col-6 col-md-3">
                              <LabelStyled isEditing={isEditing}>
                                Join Date
                              </LabelStyled>
                              {userJoinedAt && isEditing ? (
                                <DateTime
                                  name="userJoinedAt"
                                  value={userJoinedAt}
                                  timeFormat={false}
                                  inputProps={{
                                    readOnly: true,
                                    style: { backgroundColor: 'white' },
                                  }}
                                  timeConstraints={{
                                    hours: { max: 23, step: 1 },
                                    minutes: { max: 59, step: 5 },
                                  }}
                                  onChange={handleUserJoinedAtChanged}
                                />
                              ) : (
                                <h5>
                                  {userJoinedAt &&
                                    userJoinedAt.format('MMM DD, YYYY')}
                                </h5>
                              )}
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-12">
                              <div className="form-group">
                                <LabelStyled
                                  isEditing={isEditing}
                                  htmlFor="bio"
                                >
                                  Bio
                                </LabelStyled>
                                {isEditing ? (
                                  <MyStatefulEditor
                                    onChange={handleBioChange}
                                    content={bio_html}
                                    placeholder={`Use the bio to show skills, something interesting, or other aspects about this members!`}
                                    className={'form-control w-100 '}
                                  />
                                ) : (
                                  renderBio(bio_html)
                                )}
                              </div>
                            </div>
                            {renderCreatableDropdownGroup({
                              name: 'Skills',
                              groupName: 'userSkills',
                              placeholder:
                                'Select or type to create new skills...',
                              values: userSkills,
                              options: userSkillOptions,
                              handleChange: handleInputValuesChange,
                              useBadgesForViewState: true,
                              isEditing,
                            })}
                          </div>
                          <div className="row my-3">
                            <div className="col-8">
                              <LabelStyled
                                id="onboarding-label"
                                isEditing={isEditing}
                                htmlFor="bio"
                              >
                                Onboarding Status{' '}
                                <FontAwesomeIconLabel icon="question-circle" />
                              </LabelStyled>
                              <UncontrolledTooltip
                                placement="top"
                                target="onboarding-label"
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    <h6 className="mt-2">Onboarding Status</h6>
                                    <p>
                                      This allows you to manually indicate when
                                      a member has been onboarded to your
                                      community.
                                    </p>
                                  </div>
                                </div>
                              </UncontrolledTooltip>
                              <div className="row">
                                <div className={`col-md-${isEditing ? 2 : 6}`}>
                                  {isEditing ? (
                                    <div className="form-group">
                                      {/* inactiveLabel={<FontAwesomeIcon icon='times' />}
                                            activeLabel={<FontAwesomeIcon icon='check' />} */}
                                      <Switch
                                        value={hasOnboarded}
                                        onChange={toggleHasOnboarded}
                                      />
                                    </div>
                                  ) : (
                                    <h5>
                                      {member.has_onboarded ? (
                                        <FontAwesomeIcon icon="check" />
                                      ) : (
                                        <FontAwesomeIcon icon="times" />
                                      )}
                                      {member.has_onboarded
                                        ? ' Onboarded'
                                        : ' Not Onboarded'}
                                    </h5>
                                  )}
                                </div>
                                {isEditing && (
                                  <div className="col-12 col-md-6">
                                    <label className="ml-1">
                                      {hasOnboarded
                                        ? 'Onboarded. '
                                        : 'This member has not onboarded.'}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {renderKisiToggle()}
                          <Row>
                            {renderCreatableDropdownGroup({
                              name: 'Room Permissions',
                              groupName: 'userRoomPermissions',
                              placeholder: 'Select a room permission group...',
                              values: userRoomPermissions,
                              options: userRoomPermissionsOptions,
                              handleChange: handleInputValuesChange,
                              useBadgesForViewState: true,
                              isEditing,
                            })}
                          </Row>

                          {renderDynamicGroups({
                            allGroups: activeCommunity.dynamic_groups,
                            handleChange: newValues => {
                              handleInputValuesChange('dynamicTags', newValues)
                            },
                            existingTagValues: dynamicTags,
                            groupType: 'Profile',
                            isEditing: isEditing,
                          })}

                          <hr />

                          {role === 'SuperAdmin' && (
                            <React.Fragment>
                              <Button onClick={handleCollapseToggle}>
                                Toggle User Preferences Panel{' '}
                                <Badge>Super Admin</Badge>
                              </Button>

                              <Collapse isOpen={collapseOpen}>
                                <UserPreferences
                                  history={history}
                                  match={match}
                                  activeCommunity={activeCommunity}
                                  user={member}
                                  updatingMember
                                  client={user.client}
                                  userToken={user.authentication_token}
                                  uid={user.uid}
                                />
                              </Collapse>
                            </React.Fragment>
                          )}
                          <CustomFieldSection
                            customFieldMax={customFieldMax}
                            customFieldsObject={customFieldsObject}
                            handleInputValuesChange={handleInputValuesChange}
                            isEditing={isEditing}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>

              <div className="row my-1">
                <div className="col-md-9">
                  {shouldShowArchiveButton && team && (
                    <Button
                      className={
                        isInactive ? 'btn btn-success' : 'btn btn-danger'
                      }
                      onClick={toggleShowArchiveModal}
                    >
                      {isInactive ? 'Activate' : 'Deactivate'}
                    </Button>
                  )}
                </div>
                <div className="col-md-3">
                  {isEditing && (
                    <React.Fragment>
                      <Button
                        className="btn btn-secondary mr-5"
                        onClick={toggleIsEditing}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-primary"
                        disabled={Boolean(isInactive)}
                        onClick={updateMember}
                      >
                        Save
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
function getFrontEndRole(role) {
  for (let step = 0; step < Roles.listOfRolesForFrontEnd.length; step++) {
    // Runs 5 times, with values of step 0 through 4.
    if (Roles.listOfRolesForFrontEnd[step].role === role) {
      return Roles.listOfRolesForFrontEnd[step].name
    }
  }
  return ''
}

// eslint-disable-next-line react/display-name
function renderProfilePicture(member, isCheckedIn) {
  return (
    <ImageContianer>
      <RoundedImage
        photo={member.user_photo}
        className="img-responsive"
        alt="Picture"
        size="large"
      />
      {isCheckedIn && <StatusIcon size={37} icon={'map-marker-check'} />}
    </ImageContianer>
  )
}

function renderBio(bio_html) {
  let text = ''
  if (bio_html) {
    text = renderHTML(bio_html)
  }
  return text
}

MemberForm.displayName = 'MemberForm'
MemberForm.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  member: PropTypes.object.isRequired,
  role: PropTypes.string,
  bio_html: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  errors: PropTypes.array,
  firstName: PropTypes.string,
  hasDoorProvider: PropTypes.bool,
  showAccess: PropTypes.bool,
  doorAccess: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  handleAccessToggle: PropTypes.func.isRequired,
  handleDoorsGrantedChanged: PropTypes.func.isRequired,
  doorAccessOptions: PropTypes.func.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleInputValuesChange: PropTypes.func.isRequired, // for customFieldsObj
  handleBioChange: PropTypes.func.isRequired,
  lastName: PropTypes.string,
  updateMember: PropTypes.func.isRequired,
  userPhoto: PropTypes.string,
  type: PropTypes.string,
  team: PropTypes.object,
  editCompanyClicked: PropTypes.func,
  toggleShowArchiveModal: PropTypes.func.isRequired,
  toggleShowChangeModal: PropTypes.func.isRequired,
  shouldShowArchiveButton: PropTypes.bool.isRequired,
  resendOnboardingEmail: PropTypes.func.isRequired,
  disableWelcomeEmailButton: PropTypes.bool.isRequired,
  accessDate: PropTypes.object,
  userJoinedAt: PropTypes.object,
  currentUser: PropTypes.object,
  handleUserJoinedAtChanged: PropTypes.func,
  handleUserAccessDateChanged: PropTypes.func,
  toggleIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  hasOnboarded: PropTypes.bool.isRequired,
  toggleHasOnboarded: PropTypes.func.isRequired,
  customFieldMax: PropTypes.number.isRequired,
  customFieldsObject: PropTypes.object.isRequired,
  userRoomPermissions: PropTypes.object,
  userRoomPermissionsOptions: PropTypes.object,
  userSkills: PropTypes.object,
  userSkillOptions: PropTypes.object,
  profilePreferences: PropTypes.object,
  preferences: PropTypes.object,
  handleProfilePreferenceChange: PropTypes.func.isRequired,
  handleUserPreferenceChange: PropTypes.func.isRequired,
  isCheckedIn: PropTypes.bool.isRequired,
  dynamicTags: PropTypes.bool.isRequired,
}
