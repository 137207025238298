/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

const TopWrapper = styled.h6`
  margin: 0;
`
const BottomWrapper = styled.span`
`

function Column({
  text,
  subtext,
}) {

  return (
    <React.Fragment>
      <TopWrapper>
        {text}
      </TopWrapper>
      {subtext &&
        <BottomWrapper className='text-muted'>
          {subtext}
        </BottomWrapper>
      }
    </React.Fragment>
  )
}

Column.propTypes = {
  text: PropTypes.string,
  subtext: PropTypes.string,
}

Column.defaultProps = {
  data: [],
}

Column.displayName = 'Column'

export default Column
