/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classnames from 'classnames'

import * as _ from 'lodash'
import styled from 'styled-components'
import { Row } from 'reactstrap'
import { toast } from 'react-toastify'
import {
  withRouter,
  Route,
  Switch as RouterSwitch,
  Redirect,
} from 'react-router'

import * as userActions from '@reduxActions/userActions'
import * as memberActions from '@reduxActions/memberActions'
import * as Roles from '@config/roles'
import { formatHeaders } from '@config/axios'
import { MenuButton } from '@global/Base/Button/ButtonStyled'

import Spinner from '@global/Spinner'
import { convertStripeErrorToMessage } from '@global/ErrorFactory'
import RoundedImage from '@global/RoundedImage'
import { Button } from '@global/Base/Button/ButtonStyled'
import Toggle from '@bit/coworks.base-components.toggle'

import { LabelStyled } from '@global/Form/FormComponents'
import defaultUserPic from '@app/img/placeholders/otto_black_white.jpeg'
import { captureException } from '@global/ErrorFactory'

const Header = styled.div`
  background-color: ${props => props.theme.colorPrimary};
`
const MenuButtonStyled = styled(MenuButton)`
  margin-bottom: 0.5rem;
`
const ToggleStyled = styled(Toggle)`
  margin-bottom: 0 !important;
  label > label {
    margin-bottom: 0 !important;
  }
`
function renderToggle({
  preferences,
  label,
  key,
  handleChange,
  disabled = false,
}) {
  const value = _.get(preferences, key)
  function handleSwitchChange(event) {
    handleChange(key, !value)
  }
  return (
    <ToggleStyled
      disabled={disabled}
      checked={value}
      label={label}
      onChange={handleSwitchChange}
    />
  )
}

function getHeaders({ userToken, client, uid, currentCommunityId }) {
  const newHeaders = {}
  // eslint-disable-next-line no-undef
  if (userToken && client && uid) {
    newHeaders.userToken = userToken
    newHeaders.client = client
    newHeaders.uid = uid
  }

  if (currentCommunityId) {
    newHeaders.activeCommunityId = currentCommunityId
  }

  return { headers: formatHeaders(newHeaders) }
}
function UserPreferences(props) {
  //todo formik actually accepts .dot notation for field names so need to roll our own.
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [member, setMember] = useState(null)
  const [preferences, setPreferences] = useState(null)
  const [profilePreferences, setProfilePreferences] = useState(null)

  const tabRoutes = []
  tabRoutes.push({
    name: 'Notifications',
    path: `/preferences/notifications`,
  })
  tabRoutes.push({
    name: 'Privacy',
    path: `/preferences/privacy`,
  })

  const [tabs, setTabs] = useState(tabRoutes)
  const [activeTab, setActiveTab] = useState('/preferences/general')

  useEffect(() => {
    // Similar to componentDidMount and componentDidUpdate

    if (props.user) {
      // don't need to fetch context, we already has it!
      setUser(props.user)
      setPreferences(props.user.preferences)
      setProfilePreferences(props.user.profile_preferences)
      setLoading(false)
    } else if (
      props.match.params &&
      props.match.params.email &&
      props.match.params.token &&
      props.match.params.client
    ) {
      props.user_actions
        .getUserPreferencesByToken({
          uid: props.match.params.email,
          client: props.match.params.client,
          authentication_token: props.match.params.token,
          community_id: props.match.params.communityId,
        })
        .then(response => {
          const newUser = _.clone(response.user)
          newUser.client = props.match.params.client
          newUser.authentication_token = props.match.params.token

          setUser(newUser)
          setPreferences(response.user.preferences)
          setProfilePreferences(response.user.profile_preferences)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)

          toast.error(convertStripeErrorToMessage(err))
        })
    } else {
      toast.error('Whoops looks like there was an error.')
    }
  }, [props.user, props.match.params, props.user_actions]) // don't want to listen to updates!

  function handleSave() {
    setLoading(true)
    const obj = {
      id: user.id,
      community_id: props.activeCommunity
        ? props.activeCommunity.id
        : props.match.params.communityId,
      profile_preferences: profilePreferences
        ? JSON.stringify(profilePreferences)
        : null,
      preferences: preferences ? JSON.stringify(preferences) : null,
    }
    let headers = {}
    if (props.updatingMember && props.user) {
      // member detail!
      headers = getHeaders({
        client: props.client,
        userToken: props.userToken,
        uid: props.uid,
        currentCommunityId: props.activeCommunity.id,
      })
    } else if (!props.user) {
      // public form and pulling headers from the fetched user
      headers = getHeaders({
        client: user.client,
        userToken: user.authentication_token,
        uid: user.uid,
        currentCommunityId: props.match.params.communityId,
      })
    }

    if (props.updatingMember) {
      props.member_actions
        .updateMember(obj, headers)
        .then(response => {
          toast.success("Updated the member's preferences.")
          const newUser = _.clone(response.user)
          setUser(newUser)
          setLoading(false)
        })
        .catch(response => {
          toast.error('Error saving preferences.')
          setLoading(false)
        })
    } else {
      props.user_actions
        .updateUser({ user: obj, headers })
        .then(response => {
          toast.success('Updated your preferences.')
          const newUser = _.clone(response.user)
          setUser(newUser)
          setLoading(false)
        })
        .catch(response => {
          toast.error('Error saving preferences.')
          setLoading(false)
        })
    }
  }

  function handleUserPreferenceChange(key, newVal) {
    setPreferences(_.set(_.cloneDeep(preferences), key, newVal))
    // preferences: {
    //    "sms_notifications"=>{"manager_announcements"=>true, "front_desk_guest_notify"=>true},
    //    "push_notifications"=>{"manager_announcements"=>true, "front_desk_guest_notify"=>true,
    //        "member_booking_creation"=>true},
    //    "email_notifications"=>{"manager_announcements"=>true,
    //        "front_desk_guest_notify"=>true, "member_booking_creation"=>true}
    //  }
  }

  function handleProfilePreferenceChange(key, newVal) {
    setProfilePreferences(_.set(_.cloneDeep(profilePreferences), key, newVal))
    //   preferences: {
    //       "member"=>{"visible_on_directory"=>true, "directory_contact_info_visible" => nil, "membership_agreement_signed_at"=>nil, },
    //       "manager"=>{"tour_request_notifications"=>true, "membership_request_notifications"=>true}
    //   },
  }

  if (loading) {
    return <Spinner />
  }

  if (!loading && !user) {
    captureException({ text: 'Failed to load User Preferences page.' })
  }
  return (
    <RouterSwitch>
      <Route path={`/preferences`}>
        <div className="container-fluid">
          {user.type === 'SuperAdmin' && (
            <h5>Hey you! You're a Super Admin -- this page might not work</h5>
          )}
          {!loading && user ? (
            <React.Fragment>
              {!props.user && ( // if we aren't in the context of the dashboard...
                <Row>
                  <Header className="col-12 p-2 d-flex align-items-center justify-content-start">
                    <RoundedImage
                      photo={defaultUserPic}
                      alt="Picture"
                      size="medium"
                      className="img-responsive m-4"
                    />
                    <p className=" text-white h4">
                      {user ? `${user.first_name} ${user.last_name}` : null}
                    </p>
                  </Header>
                </Row>
              )}

              <Row>
                <div className="col-md-12">
                  <div className="row border-bottom-1 my-3">
                    <h2>
                      <b>My Preferences</b>
                    </h2>
                  </div>
                </div>
                <div className="col-md-2 col-12">
                  {tabs.map(tab => (
                    <Row>
                      <div className="col-12">
                        <MenuButtonStyled
                          showBackground
                          className={classnames({
                            active: location.pathname.includes(tab.path),
                          })}
                          onClick={() => {
                            props.history.push(tab.path)
                            // setActiveTab(tab.path)
                          }}
                        >
                          {tab.name}
                        </MenuButtonStyled>
                      </div>
                    </Row>
                  ))}
                  {/* </Nav> */}
                </div>
                <RouterSwitch>
                  <Route exact path={`/preferences`}>
                    <Redirect to={'/preferences/notifications'} />
                  </Route>
                  <Route path={'/preferences/notifications'}>
                    {preferences ? (
                      <div className="col-12 col-md-8 my-3">
                        <h4>
                          <b>General</b>
                          <hr />
                        </h4>
                        <LabelStyled>Announcements</LabelStyled>
                        <p>
                          Get notified when a manager sends an announcement and
                          you're a recipient
                        </p>
                        {renderToggle({
                          preferences,
                          label: 'Email',
                          key: 'email_notifications.manager_announcements',
                          handleChange: handleUserPreferenceChange,
                        })}
                        <br />
                        {renderToggle({
                          preferences,
                          label: 'Push',
                          key: 'push_notifications.manager_announcements',
                          handleChange: handleUserPreferenceChange,
                        })}
                        <br />
                        {renderToggle({
                          preferences,
                          label: 'Sms',
                          key: 'sms_notifications.manager_announcements',
                          handleChange: handleUserPreferenceChange,
                        })}
                        <br />
                        <br />
                        <LabelStyled>Front Desk Guests</LabelStyled>
                        <p>
                          Get notified when a guest finds you in the front-desk
                          tablet directory
                        </p>
                        {renderToggle({
                          preferences,
                          label: 'Email',
                          key: 'email_notifications.front_desk_guest_notify',
                          handleChange: handleUserPreferenceChange,
                        })}
                        <br />
                        {renderToggle({
                          preferences,
                          label: 'Push',
                          key: 'push_notifications.front_desk_guest_notify',
                          handleChange: handleUserPreferenceChange,
                        })}
                        <br />
                        {renderToggle({
                          preferences,
                          label: 'Sms',
                          key: 'sms_notifications.front_desk_guest_notify',
                          handleChange: handleUserPreferenceChange,
                        })}
                        <br />
                        <br />
                        <h4>
                          <b>Bookings</b>
                          <hr />
                        </h4>
                        <LabelStyled>Booking Receipts</LabelStyled>
                        <p>Get notified when I make a booking</p>
                        {renderToggle({
                          preferences,
                          label: 'Email',
                          key: 'email_notifications.member_booking_creation',
                          handleChange: handleUserPreferenceChange,
                        })}
                        <br />
                        {renderToggle({
                          preferences,
                          label: 'Push',
                          key: 'push_notifications.member_booking_creation',
                          handleChange: handleUserPreferenceChange,
                        })}
                        {/* todo: we don't have anything other than in-app notifications */}
                        {/* {Roles.justSpaceStaffRoles.includes(user.type) ? (
                          <React.Fragment>
                            <br />
                            <br />
                            <LabelStyled>Booking Overages</LabelStyled>
                            <p>
                              Get notified when a team reaches their conference
                              room allowance
                            </p>
                            {renderToggle({
                              preferences,
                              label: 'Email',
                              key: 'email_notifications.member_booking_overage',
                              handleChange: handleUserPreferenceChange,
                            })}
                            <br />
                            {renderToggle({
                              preferences,
                              label: 'Push',
                              key: 'push_notifications.member_booking_overage',
                              handleChange: handleUserPreferenceChange,
                            })}
                            <br />
                          </React.Fragment>
                        ) : null} */}
                        <br />
                        <LabelStyled>New External Bookings</LabelStyled>
                        <p>Get notified when external bookings are created</p>
                        {renderToggle({
                          preferences,
                          label: 'Email',
                          key: 'email_notifications.external_booking_creation',
                          handleChange: handleUserPreferenceChange,
                        })}
                        {/* <br />
                        {renderToggle({
                          preferences,
                          label: 'Push',
                          key: 'push_notifications.external_booking_creation',
                          handleChange: handleUserPreferenceChange,
                        })} */}
                        <br />
                        <br />
                        {profilePreferences &&
                        Roles.justSpaceStaffRoles.includes(user.type) ? (
                          <React.Fragment>
                            <h4>
                              <b>Leads</b>
                              <hr />
                            </h4>
                            <LabelStyled>Tour Requests</LabelStyled>
                            <p>
                              Get notified when a lead submits the tour request
                              form.
                            </p>
                            {renderToggle({
                              preferences: profilePreferences,
                              label: 'Email',
                              key: 'manager.tour_request_notifications',
                              handleChange: handleProfilePreferenceChange,
                            })}
                            <br />
                            <br />
                            <LabelStyled>Membership Requests</LabelStyled>
                            <p>
                              Get notified when a lead submits the membership
                              request Get notified when a lead submits the
                              membership request form.
                            </p>
                            {renderToggle({
                              preferences: profilePreferences,
                              label: 'Email',
                              key: 'manager.membership_request_notifications',
                              handleChange: handleProfilePreferenceChange,
                            })}
                            <br />
                            <br />
                            <h4>
                              <b>Day Passes</b>
                              <hr />
                            </h4>
                            <LabelStyled>Purchase Receipts</LabelStyled>
                            <p>
                              Get notified when a lead or member buys a day
                              pass.
                            </p>
                            {renderToggle({
                              preferences: profilePreferences,
                              label: 'Email',
                              key: 'manager.day_pass_purchase',
                              handleChange: handleProfilePreferenceChange,
                            })}
                          </React.Fragment>
                        ) : null}
                      </div>
                    ) : null}
                  </Route>

                  <Route path={'/preferences/privacy'}>
                    {profilePreferences ? (
                      <Row className="my-3 mb-3">
                        <div className="col-12">
                          <h4>
                            <b>Privacy</b>
                            <hr />
                          </h4>
                          <LabelStyled>Mobile App Directory</LabelStyled>
                          <p>
                            Configure the visibility of you and your personal
                            information on the directory.
                          </p>
                          {renderToggle({
                            preferences: profilePreferences,
                            label: 'Show Me',
                            key: 'member.visible_on_directory',
                            handleChange: handleProfilePreferenceChange,
                          })}
                          <br />
                          {renderToggle({
                            preferences: profilePreferences,
                            label: 'Email on Mobile Directory',
                            key: 'member.visible_on_directory_email',
                            handleChange: handleProfilePreferenceChange,
                            disabled: !_.get(
                              profilePreferences,
                              'member.visible_on_directory'
                            ),
                          })}
                          <br />
                          {renderToggle({
                            preferences: profilePreferences,
                            label: 'Phone visible on Mobile Directory',
                            key: 'member.visible_on_directory_phone',
                            handleChange: handleProfilePreferenceChange,
                            disabled: !_.get(
                              profilePreferences,
                              'member.visible_on_directory'
                            ),
                          })}
                          <br />
                        </div>
                      </Row>
                    ) : null}
                  </Route>
                </RouterSwitch>
              </Row>
              <Row className="my-3">
                <div className="col-12 d-flex align-items-center justify-content-end">
                  <Button onClick={handleSave}>Save</Button>
                </div>
              </Row>
            </React.Fragment>
          ) : (
            <h3>Whoops, looks like something went wrong!</h3>
          )}
        </div>
      </Route>
    </RouterSwitch>
  )
}

UserPreferences.propTypes = {
  user_actions: PropTypes.object.isRequired,
  member_actions: PropTypes.object.isRequired,
  activeCommunity: PropTypes.object, // this may be null if accessing from public
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  updatingMember: PropTypes.bool,
  client: PropTypes.string, // these will only be passed when in member detail as they don't exist on passed i
  userToken: PropTypes.string, // these will only be passed when in member detail as they don't exist on passed i
  uid: PropTypes.string, // these will only be passed when in member detail as they don't exist on passed i
}
UserPreferences.displayName = 'UserPreferences'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    user_actions: bindActionCreators(userActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferences)
