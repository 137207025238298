import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'

export function createdAttachment(attachment) {
  return { type: actionTypes.CREATE_ATTACHMENT, attachment }
}

export function updatedAttachment(attachment) {
  return { type: actionTypes.UPDATE_ATTACHMENT, attachment }
}

// ACTION DISPATCHERS
export const createAttachment = attachment => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createAttachment(attachment)
      .then(response => {
        dispatch(createdAttachment(response.attachment))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.attachment)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const updateAttachment = attachment => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateAttachment(attachment)
      .then(response => {
        dispatch(updatedAttachment(response.attachment))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.attachment)
      })
      .catch(err => {
        reject(err)
      })
  })
}
