import React, { PropTypes, useState } from 'react'
// import ReactMapGL from 'react-map-gl'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin: auto;
`

const token =
  'pk.eyJ1Ijoieml3YW0iLCJhIjoiY2tlYzBoODBtMDM2NzMxczVrd3Zwa3Q5cyJ9.YusxWHFBp9COeV23sD68uA'

const viewportParams = {
  width: '100%',
  height: '100%',
  latitude: 42.430472,
  longitude: -123.334102,
  zoom: 14,
}
const DetailMap = ({ ...other }) => {
  const [viewport, setViewPort] = useState(viewportParams)

  return (
    <Container {...other}>
      {/* <ReactMapGL
        {...viewport}
        onViewportChange={setViewPort}
        mapboxApiAccessToken={token}
      /> */}
      map
    </Container>
  )
}

DetailMap.propTypes = {}

DetailMap.displayName = 'DetailMap'

export default DetailMap
