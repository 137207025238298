const designTokens = {
  colors: {
    accent1: '#25af7f',
    accent2: '#3283c4',
    accent3: '#7635e2',
    accent4: '#9a3dc5',
    accent5: '#c24378',
    accent6: '#e18736',
    // accent7: '#ECBA82', // added for extra but not official
    boxShadow: '#ffffff',
    danger: '#c33f39',
    dangerLight: '#feeeee',
    info: '#4354ba',
    infoLight: '#f6f7fe',
    neutral1: '#f6f8fa',
    neutral2: '#eff3f8',
    neutral3: '#cdd9e5',
    neutral4: '#a0b2c4',
    neutral5: '#748493',
    neutral6: '#54616e',
    primary: '#259595',
    primaryLight: '#ecfafa',
    secondary: '#2a3842',
    secondaryLight: '#edf3f7',
    success: '#6eae42',
    successLight: '#f0fdea',
    warning: '#e5bd38',
    warningLight: '#fffcf6',
  },
  fonts: {
    bold: {
      fontSize: '13px',
      color: '#000000',
      letterSpacing: '0.46px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    button: {
      fontSize: '14px',
      color: '#000000',
      letterSpacing: '0.82px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 800,
      textTransform: 'uppercase',
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    extrabold: {
      fontSize: '13px',
      color: '#000000',
      letterSpacing: '0.46px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 800,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    h1: {
      fontSize: '46px',
      color: '#000000',
      lineHeight: '48px',
      fontFamily: 'Montserrat',
      fontWeight: 800,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    h2: {
      fontSize: '32px',
      color: '#000000',
      lineHeight: '36px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    h3: {
      fontSize: '24px',
      color: '#000000',
      lineHeight: '30px',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    h4: {
      fontSize: '20px',
      color: '#000000',
      lineHeight: '26px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    h5: {
      fontSize: '16px',
      color: '#000000',
      letterSpacing: '0.7px',
      lineHeight: '20px',
      fontFamily: 'Montserrat',
      fontWeight: 800,
      textTransform: 'uppercase',
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    h6: {
      fontSize: '12px',
      color: '#000000',
      letterSpacing: '0.7px',
      lineHeight: '16px',
      fontFamily: 'Montserrat',
      fontWeight: 800,
      textTransform: 'uppercase',
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    iconFont: {
      fontSize: '50px',
      color: '#000000',
      fontFamily: 'Font Awesome 5 Pro',
      fontWeight: 300,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    iconLight: {
      fontSize: '18px',
      color: '#000000',
      fontFamily: 'Font Awesome 5 Pro',
      fontWeight: 300,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    iconSolid: {
      fontSize: '18px',
      color: '#000000',
      fontFamily: 'Font Awesome 5 Pro',
      fontWeight: 900,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    large: {
      fontSize: '30px',
      color: '#000000',
      fontFamily: 'Font Awesome 5 Pro',
      fontWeight: 300,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    medium: {
      fontSize: '22px',
      color: '#000000',
      fontFamily: 'Font Awesome 5 Pro',
      fontWeight: 300,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    // eslint-disable-next-line id-length
    p: {
      fontSize: '14px',
      color: '#000000',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    pSmall: {
      fontSize: '11px',
      color: '#000000',
      letterSpacing: '0.64px',
      lineHeight: '14px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    primaryFont: {
      fontSize: '50px',
      color: '#000000',
      letterSpacing: '1.79px',
      lineHeight: '49px',
      fontFamily: 'Montserrat',
      fontWeight: 800,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    regular: {
      fontSize: '13px',
      color: '#000000',
      letterSpacing: '0.46px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    semibold: {
      fontSize: '13px',
      color: '#000000',
      letterSpacing: '0.46px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    small: {
      fontSize: '16px',
      color: '#000000',
      fontFamily: 'Font Awesome 5 Pro',
      fontWeight: 300,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textDanger: {
      fontSize: '14px',
      color: '#C33F39',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textDark: {
      fontSize: '14px',
      color: '#000000',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textGray: {
      fontSize: '14px',
      color: '#A0B2C4',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textInfo: {
      fontSize: '14px',
      color: '#4354BA',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textLight: {
      fontSize: '14px',
      color: '#FFFFFF',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textPrimary: {
      fontSize: '14px',
      color: '#259595',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textSecondary: {
      fontSize: '14px',
      color: '#2A3842',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textSuccess: {
      fontSize: '14px',
      color: '#6EAE42',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    textWarning: {
      fontSize: '14px',
      color: '#E5BD38',
      letterSpacing: '0.5px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
  },
}

export default designTokens
