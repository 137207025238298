import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const SMSWrapper = styled.div`
  font-family: 'Helvetica Neue';
  font-size: 20px;
  font-weight: normal;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  p {
    max-width: 255px;
    word-wrap: break-word;
    margin-bottom: 12px;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: -2px;
      height: 20px;
    }
  }
  .from-me {
    color: white;
    background: #0b93f6;
    align-self: flex-end;

    &:before {
      right: -7px;
      border-right: 20px solid #0b93f6;
      border-bottom-left-radius: 16px 14px;
      transform: translate(0, -2px);
    }

    &:after {
      right: -56px;
      width: 26px;
      background: #fff;/* ${props => props.theme.colorBodyBackground}; */
      border-bottom-left-radius: 10px;
      transform: translate(-30px, -2px);
    }
  }
  .from-them {
    background: #e5e5ea;
    color: black;

    &:before {
      left: -7px;
      border-left: 20px solid #e5e5ea;
      border-bottom-right-radius: 16px 14px;
      transform: translate(0, -2px);
    }

    &:after {
      left: 4px;
      width: 26px;
      background: white;
      border-bottom-right-radius: 10px;
      transform: translate(-30px, -2px);
    }
  }
`
function IOSSMSPreview({ fromMe, message, title }) {
  return (
    <SMSWrapper className="mr-3">
      <p className={`${fromMe ? 'from-me' : 'from-them'}`}>
        {title && (
          <React.Fragment>
            {title}
            <br />
          </React.Fragment>
        )}
        {message}
      </p>
    </SMSWrapper>
  )
}
IOSSMSPreview.defaultProps = {
  fromMe: true,
  message: 'No message.',
  title: null,
}
IOSSMSPreview.propTypes = {
  fromMe: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
}
IOSSMSPreview.displayName = 'IOSSMSPreview'
export default IOSSMSPreview
