/* eslint-disable no-invalid-this */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MyStatefulEditor from '@global/MyStatefulEditor'

import * as resourceActions from '../../reduxActions/resourceActions'
import { Button, Row } from 'reactstrap'
import PinnedFields from './PinnedFields'
import { FormErrors } from '@global/FormErrorsComponent'
import { toast } from 'react-toastify'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { CustomInputComponent } from '@global/Form/FormComponents'

class ResourceWebsiteForm extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    resource: PropTypes.object,
    reset: PropTypes.func,
    resource_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    archiveResource: PropTypes.func,
    renderCustomDropdowns: PropTypes.func,
    customTagState: PropTypes.array,
    customTagsDirty: PropTypes.bool,
    goBack: PropTypes.function,
  }
  constructor(props, context) {
    super(props, context)
    let name = ''
    let isPinned = false
    let buttonText = 'Create'
    let resource = {}
    let isEditing = false
    let url = ''
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.resource
    ) {
      resource = this.props.history.location.state.resource
      name = resource.name
      if (resource.is_pinned) {
        isPinned = true
      }
      buttonText = 'Update'
      isEditing = true
      if (resource.website_url) {
        url = resource.website_url
      }
    }
    this.state = {
      resource,
      name,
      url,
      isPinned,
      buttonText,
      isEditing,
    }
  }

  createResource = values => {
    let customTagState = this.props.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    customTagState = JSON.stringify(customTagState)
    this.props.resource_actions
      .createResource({
        name: values.name,
        type: 'Website',
        website_url: values.url,
        is_pinned: values.isPinned,
        campus_id: this.props.activeCampus.id,
        custom_tag_state: customTagState,
      })
      .then(() => {
        this.props.history.push('/resources')
        toast.success('Created the resource.')
      })
      .catch(err => {
        if (err) {
          this.setState({ errors: err })
          toast.error(this.state.errors.message)
        }
      })
  }

  updateResource = values => {
    let customTagState = this.props.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    customTagState = JSON.stringify(customTagState)
    this.props.resource_actions
      .updateResource({
        id: this.props.resource.id,
        name: values.name,
        website_url: values.url,
        type: 'Website',
        is_pinned: values.isPinned,
        campus_id: this.props.activeCampus.id,
        custom_tag_state: customTagState,
      })
      .then(() => {
        this.props.history.push('/resources')
      })
      .catch(err => {
        if (err) {
          this.setState({ errors: err })
          toast.error(this.state.errors.message)
        }
      })
  }

  render() {
    return (
      <div className="col-10">
        <div className="animated fadeIn">
          <Formik
            initialValues={{
              name: this.state.name,
              url: this.state.url,
              isPinned: this.state.isPinned,
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (this.props.resource) {
                this.updateResource(values)
              } else {
                this.createResource(values)
              }
              setTimeout(() => {
                setSubmitting(false)
              }, 500)
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required.'),
              url: Yup.string()
                .url('This link is not a valid URL. It must include "https://"')
                .required('Website link is required.'),
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldTouched,
                setFieldValue,
              } = props
              return (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-md-4 col-12">
                      <Field
                        label="Resource Name"
                        name="name"
                        placeholder="Enter a name here"
                        component={CustomInputComponent}
                      />
                    </div>
                    <div className="col-md-4 col-12">
                      {PinnedFields({
                        isPinned: values.isPinned,
                        handlePinnedStatusChange: value => {
                          if (!touched.isPinned) {
                            // Fix : this.props.form.setFieldTouched(this.props.field.name, true, false);
                            setFieldTouched('isPinned', true, false)
                          }
                          setFieldValue('isPinned', !values.isPinned)
                        },
                      })}
                    </div>
                    <div className="col-md-8 col-12">
                      <Field
                        label="Website URL"
                        name="url"
                        placeholder="Enter a website url here like https://www.coworksapp.com"
                        component={CustomInputComponent}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-12 col-md-4">
                      {this.props.renderCustomDropdowns()}
                    </div>
                  </Row>
                  <Row>
                    {this.state.isEditing ? (
                      <div className="col-7 d-md-down-none">
                        <Button
                          className="btn btn-danger"
                          onClick={() =>
                            this.props.archiveResource(this.state.resource)
                          }
                        >
                          Archive
                        </Button>
                      </div>
                    ) : null}
                    <div className="col d-flex justify-content-end">
                      <Button
                        className="btn btn-secondary mr-2"
                        onClick={this.props.goBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                          isSubmitting ||
                          (!dirty && !this.props.customTagsDirty)
                        }
                      >
                        {this.state.buttonText}
                      </Button>
                    </div>
                  </Row>
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

ResourceWebsiteForm.displayName = 'ResourceWebsiteForm'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resource_actions: bindActionCreators(resourceActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceWebsiteForm)
