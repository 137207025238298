import moment from 'moment'

// date range picker options
const DRP_today = 'Today'
const DRP_currentMonth = 'This Month'
const DRP_previousSevenDays = 'Previous 7 Days'
const DRP_lastMonth = 'Last Month'
const DRP_custom = 'Custom'
export {
  DRP_today,
  DRP_currentMonth,
  DRP_previousSevenDays,
  DRP_lastMonth,
  DRP_custom,
}

export function convertStringDateToMomentDate(
  dateRangeString,
  defaultStartDate,
  defaultEndDate
) {
  // this function takes
  //  'today' / 'currentMonth' / 'previousSevenDays' / 'lastMonth'
  //    and converts it to a moment object
  let start, end
  switch (dateRangeString) {
    case DRP_today:
      start = moment().startOf('day')
      end = moment().endOf('day')
      break
    case DRP_currentMonth:
      start = moment().startOf('month')
      end = moment().endOf('day')
      break
    case DRP_previousSevenDays:
      start = moment().subtract(7, 'days')
      end = moment().endOf('day')
      break
    case DRP_lastMonth:
      start = moment().subtract(1, 'month').startOf('month')
      end = moment().subtract(1, 'month').endOf('month')
      break
    case 'nextSevenDays':
      start = moment().startOf('day')
      end = moment().add(7, 'days').endOf('day')
      break
    case 'nextThirtyDays':
      start = moment().startOf('day')
      end = moment().add(1, 'month').endOf('day')
      break
    case 'nextMonth':
      start = moment().add(1, 'month').startOf('month')
      end = moment().add(1, 'month').endOf('month')
      break
    default:
      if (defaultStartDate) start = defaultStartDate.startOf('day')
      if (defaultEndDate) end = defaultEndDate.endOf('day')
      break
  }
  return { start, end }
}

export const presets = [
  {
    text: 'Yesterday',
    startDate: moment().add(-1, 'day').startOf('day'),
    endDate: moment().add(-1, 'day').endOf('day'),
  },
  {
    text: 'Last Week',
    startDate: moment().add(-1, 'week').startOf('week'),
    endDate: moment().add(-1, 'week').endOf('week'),
  },
  {
    text: 'Last Month',
    startDate: moment().add(-1, 'month').startOf('month'),
    endDate: moment().add(-1, 'month').endOf('month'),
  },
]

export const renderDateTimeStamp = (date, includeTime = true) =>
  moment.unix(date).format(`MMM D, YYYY${includeTime ? ' hh:mm a' : ''}`)
