/* eslint-disable react/no-multi-comp */
import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import designTokens from '../../../theme/designTokens'
import { theme } from '@global/Base/baseComponentColorUtils'
import { ErrorMessage, useField } from 'formik'

// Note, this element is to be used when you are trying to achieve V2 style
//  AND when the form utilizes hooks.

// https://github.com/stripe/react-stripe-js
// to use with hooks, use:
// (useStipe, useElements) from '@stripe/react-stripe-js'

const CardContainer = styled.div``

// This styling was taken from the Input.js in the coworks-web-components library
const CreditCardContainer = styled.div`
  border: ${theme('values.outlines.outline')};
  border-color: ${props =>
    props.error ? props.theme.colors.danger : props.theme.colors.neutral4};
  padding: 10px;

  background-color: #ffffff;
  border-radius: 4px;
  color: ${props => props.theme.colorInputText};

  .StripeElement--focus {
    /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;

    color: #607d8b;
    background-color: #ffffff;
    border-color: #64d8d7 !important;
    outline: none;
  }
`
// This styling was taken from the Input.js in the coworks-web-components library
const Heading = styled.div`
  display: flex;
`
// This styling was taken from the Input.js in the coworks-web-components library
const Helper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 3px;
  margin-right: 0px;
  font-size: ${theme('fonts.sizes.pSmall.fontSize')};
  letter-spacing: ${theme('fonts.sizes.pSmall.letterSpacing')};
  line-height: ${theme('fonts.sizes.pSmall.lineHeight')};
  color: ${theme('fonts.colors.gray')};
`
// This styling was taken from the Input.js in the coworks-web-components library
const Header = styled.span`
  display: inline-flex;
  font-family: inherit;
  font-weight: ${theme('fonts.weights.extrabold')};
  font-size: ${theme('fonts.sizes.h6.fontSize')};
  letter-spacing: ${theme('fonts.sizes.h6.letterSpacing')};
  line-height: ${theme('fonts.sizes.h6.lineHeight')};
  color: ${theme('fonts.colors.gray')};
  text-transform: uppercase;
  margin: 3px;
  margin-bottom: 1px;
`

// This styling was taken from the Input.js in the coworks-web-components library
const Description = styled.div`
  margin-top: 5px;
  margin-left: 3px;
  font-size: ${theme('fonts.sizes.pSmall.fontSize')};
  letter-spacing: ${theme('fonts.sizes.pSmall.letterSpacing')};
  line-height: ${theme('fonts.sizes.pSmall.lineHeight')};
  color: ${props =>
    props.error
      ? props.theme.fonts.colors.danger
      : props.theme.fonts.colors.gray};
`
const CreditCardForm = ({
  stripe,
  elements,
  primaryColor,
  label,
  helper,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const { setTouched, setValue, setError } = helpers
  const { error, touched } = meta

  const handleChange = stripeState => {
    const { complete, error, empty } = stripeState
    setTouched(!empty)
    setValue(stripeState)
    if (error) setError(error.message)
  }

  return (
    <CardContainer>
      <Heading>
        <Header>{label}</Header>
        <Helper>{helper}</Helper>
      </Heading>
      <CreditCardContainer>
        <CardElement
          stripe={stripe}
          elements={elements}
          onChange={handleChange}
          options={{
            style: {
              base: {
                fontSize: designTokens.fonts.p.fontSize,
                iconColor: primaryColor,
                fontFamily: 'Montserrat, Open Sans, Segoe UI, sans-serif',
                letterSpacing: designTokens.fonts.p.letterSpacing,
              },
            },
          }}
        />
      </CreditCardContainer>
      {props.description || error ? (
        <Description error={error}>
          {error ? error : props.description}
        </Description>
      ) : null}
    </CardContainer>
  )
}

CreditCardForm.displayName = 'CreditCardForm'

CreditCardForm.propTypes = {
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  primaryColor: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  description: PropTypes.string,
  helper: PropTypes.string,
}

export default withTheme(CreditCardForm)
