/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { Button, Row, Col } from 'reactstrap'
import * as Constants from '@global/Constants'
import Tooltip from '@bit/coworks.base-components.tooltip'

import {
  createInvoiceFeesArray,
  frequencies,
} from '@global/Constants/PaymentConstants'

import { getDaysInMonth } from '@global/Constants/TeamBillingConstants'

import {
  LabelStyled,
  FontAwesomeIconLabel,
  SingleDatePicker,
} from '@global/Form/FormComponents'

const PresetButtonStyled = styled.button`
  position: relative;
  height: 100%;
  text-align: center;
  background: none;
  border: 2px solid
    ${props =>
      props.color === 'primary'
        ? props.theme.colorPrimary
        : props.theme.colorSecondary};
  color: ${props =>
    props.color === 'primary'
      ? props.theme.colorPrimary
      : props.theme.colorSecondary};
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer;

  &.active {
    outline: 0;
  }
`
const PresetsPanelStyled = styled.div`
  padding: 0 22px 11px 22px;
`

export default class Subscription extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    activeCommunity: PropTypes.object.isRequired,
    monthlyBillDate: PropTypes.number,
    frequency: PropTypes.string,
    handleProrateToggle: PropTypes.func,
    handleMonthlyBillDateChanged: PropTypes.func,
    handleStartDateChanged: PropTypes.func,
    planGrandTotal: PropTypes.number,
    planSubTotal: PropTypes.number,
    subscriptionCart: PropTypes.array,
    startDate: PropTypes.object,
    firstBillDate: PropTypes.number,
    isProrated: PropTypes.bool,
    handleProrateLength: PropTypes.func,
    getProrateLength: PropTypes.func,
    getMonthlyBillDate: PropTypes.func,
    discountObject: PropTypes.object,
    handleInputChange: PropTypes.func,
    intendedPaymentSource: PropTypes.string,
    stripeCustomer: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      firstBillDate: this.props.startDate
        ? moment(this.props.startDate)
        : moment().valueOf(),
      presets: [
        { text: 'Today', start: moment() },
        { text: 'Next Week', start: moment().add(7, 'days') },
        { text: 'Next Month', start: moment().add(1, 'month') },
        {
          text: 'Start of Next Month',
          start: moment().add(1, 'month').startOf('month'),
        },
      ],
    }
  }

  handleMonthlyBillDateChanged = event => {
    const monthlyBillDate = Number(event.target.value)
    this.props.handleMonthlyBillDateChanged(monthlyBillDate)
  }

  isValidDate = (currentDate, selectedDate) => {
    if (currentDate.isSameOrBefore(moment())) {
      return false
    }
    return true
  }

  isOnMonthlyBillDate(startDate) {
    if (!startDate) {
      return false
    }
    const startDay = moment(startDate).startOf('day')
    const monthlyBillDate = moment()
      .date(this.props.monthlyBillDate)
      .month(startDay.month())
      .year(startDay.year())
    return Boolean(monthlyBillDate.startOf('day').diff(startDay) === 0)
  }
  getProrateValue = () => {
    const daysUntilBill = this.props.getProrateLength()
    const dailyRate = this.props.planGrandTotal / moment().daysInMonth()
    // if (this.props.monthlyBillDate === moment().get('date')) {
    //   return this.props.planGrandTotal.toFixed(2);
    //   //return (this.props.planGrandTotal - (dailyRate * (moment().get('hour') / 24))).toFixed(2);
    // }
    return (dailyRate * daysUntilBill).toFixed(2)
  }

  getBillCycle(frequency) {
    return frequencies.filter(freq => freq.name === frequency)[0].adverb
  }
  renderDatePresets = () => {
    const { presets } = this.state

    return (
      <PresetsPanelStyled>
        {presets.map(({ text, start }) => {
          return (
            <PresetButtonStyled
              key={text}
              color="primary"
              type="button"
              onClick={() => this.props.handleStartDateChanged(start)}
            >
              {text}
            </PresetButtonStyled>
          )
        })}
      </PresetsPanelStyled>
    )
  }

  renderProrateFields() {
    const symbol = Constants.getCurrencySymbolFromCommunity(
      this.props.activeCommunity
    )
    const frequency = this.props.subscriptionCart[0].plan.frequency
    return (
      <React.Fragment>
        <div className="mb-5">
          <div className="mb-2">
            <LabelStyled>When do you want the membership to start?</LabelStyled>
          </div>
          <SingleDatePicker
            id="subscription_start_date" // PropTypes.string.isRequired,
            onDateChange={this.props.handleStartDateChanged}
            renderCalendarInfo={this.renderDatePresets}
            date={this.props.startDate} // momentPropTypes.momentObj or null
            focused={this.state.focused} // PropTypes.bool
            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
            placeholder={'Start Date'}
          />
        </div>
        {frequency === 'month' && (
          <div className="mb-5 row">
            <div className="col-md-12">
              <div className="mb-2 d-flex align-items-center">
                <LabelStyled>When is the recurring bill date?</LabelStyled>
                <Tooltip
                  header={`Bill Date`}
                  tooltipContent={
                    'The day of the month that this this membership will be billed.'
                  }
                  handleClick={() =>
                    this.props.history.push('/community/settings/billing')
                  }
                  buttonText={'Change default'}
                >
                  {' '}
                  <FontAwesomeIconLabel icon="question-circle" />
                </Tooltip>
              </div>
            </div>
            <div className="col-md-12">
              <p>{`Billed ${this.getBillCycle(this.props.frequency)} on:`}</p>
            </div>
            <div className="col-3">
              <select
                id="monthlyBillDate"
                className="d-inline form-control"
                onChange={this.handleMonthlyBillDateChanged}
                value={this.props.monthlyBillDate}
              >
                {getDaysInMonth().map((monthlyBillDate, i) => (
                  <option key={i} value={monthlyBillDate.value}>
                    {monthlyBillDate.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              day of the month.
            </div>
          </div>
        )}

        {!this.isOnMonthlyBillDate(this.props.startDate) &&
          frequency === 'month' &&
          this.props.startDate && (
            <div className="mb-5">
              <div className="mb-3">
                <LabelStyled>
                  Will you prorate for{' '}
                  {`${this.props.startDate.format(
                    'MM/DD/YY'
                  )} to ${this.props
                    .getMonthlyBillDate(
                      this.props.startDate,
                      this.props.monthlyBillDate
                    )
                    .format(
                      'MM/DD/YY'
                    )} (${this.props.getProrateLength()} days)`}
                  ?
                </LabelStyled>
              </div>
              <div className="border p-3 mt-3 d-inline">
                <Button
                  className={`btn btn-sm d-inline ${
                    this.props.isProrated
                      ? 'btn-primary'
                      : 'btn-link text-muted'
                  }`}
                  onClick={() => this.props.handleProrateToggle(true)}
                >
                  {`Prorate ${symbol}${this.getProrateValue()}`}
                </Button>
                <Button
                  className={`btn btn-sm d-inline ${
                    !this.props.isProrated
                      ? 'btn-primary'
                      : 'btn-link text-muted'
                  }`}
                  onClick={() => this.props.handleProrateToggle(false)}
                >
                  Don't prorate
                </Button>
              </div>
            </div>
          )}
      </React.Fragment>
    )
  }
  renderInvoiceFees(amount, prefs, customer, intendedPaymentSource) {
    const symbol = Constants.getCurrencySymbolFromCommunity(
      this.props.activeCommunity
    )
    const array = createInvoiceFeesArray({
      amountInDollars: amount,
      prefs,
      returnEmpty: false,
      customer,
      intendedPaymentSource,
    })
    if (!array) {
      return null
    }
    return array.map(feeItem => (
      <Row>
        <div className="col">{feeItem.description}</div>
        <div className="col">
          <p className="text-right">
            {symbol}
            {Number(feeItem.amountInDollars).toFixed(2)}
          </p>
        </div>
      </Row>
    ))
  }

  render() {
    const {
      discountObject,
      handleInputChange,
      planGrandTotal,
      planSubTotal,
      activeCommunity,
      subscriptionCart,
      isProrated,
      intendedPaymentSource,
      stripeCustomer,
    } = this.props
    const symbol = Constants.getCurrencySymbolFromCommunity(activeCommunity)
    const planGrandTotalFormatted = planGrandTotal
      ? planGrandTotal.toFixed(2)
      : Number(0).toFixed(2)
    const planSubTotalFormatted = planSubTotal
      ? planSubTotal.toFixed(2)
      : Number(0).toFixed(2)
    return (
      activeCommunity.stripe_account_id &&
      subscriptionCart.length > 0 && (
        <div className="row mt-5">
          {' '}
          <div className="col">{this.renderProrateFields()}</div>
          {this.props.startDate && (
            <div className="col">
              <div className="bg-light p-4 mb-4">
                <Row>
                  <div className="col">
                    <p>First Bill Date</p>
                  </div>
                  <div className="col">
                    <h5 className="d-flex justify-content-end">
                      <p>
                        {this.props.isProrated ||
                        subscriptionCart[0].plan.frequency !== 'month'
                          ? this.props.startDate.format('MM/DD/YYYY')
                          : this.props
                              .getMonthlyBillDate(
                                this.props.startDate,
                                this.props.monthlyBillDate
                              )
                              .format('MM/DD/YYYY')}
                      </p>
                    </h5>
                  </div>
                </Row>
                {subscriptionCart[0].plan.frequency === 'month' && (
                  <Row>
                    <div className="col">
                      <p>First Bill Amount</p>
                    </div>
                    <div className="col d-flex align-items-center justify-content-end">
                      <p>
                        {this.props.isProrated &&
                        this.props.monthlyBillDate !== moment().get('date')
                          ? `Prorated value of ${symbol}${this.getProrateValue()} for ${this.props.getProrateLength()} days`
                          : `${symbol}${planGrandTotalFormatted}`}
                      </p>
                    </div>
                  </Row>
                )}
                <Row>
                  <div className="col">
                    <p>Bill Frequency</p>
                  </div>
                  <div className="col">
                    <p className="text-right">
                      {subscriptionCart[0].plan.frequency}ly
                    </p>
                  </div>
                </Row>
              </div>
              <div className="bg-light p-4 mb-4">
                <Row>
                  <div className="col">
                    <p className="h5">
                      <strong>Subtotal</strong>
                    </p>
                  </div>
                  <div className="col">
                    <p className="h5 text-right">
                      <strong>
                        {symbol}
                        {planSubTotalFormatted}
                      </strong>
                    </p>
                  </div>
                </Row>
                {discountObject && (
                  <Row>
                    <div className="col">
                      <p>Discount</p>
                    </div>
                    <div className="col">
                      <h5 className="d-flex justify-content-end">
                        -{symbol}
                        {Number(discountObject.amount_off).toFixed(2)}
                      </h5>
                    </div>
                  </Row>
                )}
                {this.renderInvoiceFees(
                  planSubTotal,
                  activeCommunity.community_preference,
                  stripeCustomer,
                  intendedPaymentSource
                )}
                <Row>
                  <div className="col">
                    <h4>
                      <strong>Total</strong>
                    </h4>
                  </div>
                  <div className="col">
                    <p className="h2 text-right">
                      <strong>
                        {symbol}
                        {planGrandTotalFormatted}
                      </strong>
                    </p>
                  </div>
                </Row>
              </div>

              {subscriptionCart[0].plan.frequency === 'month' ? (
                <span>
                  This customer will be regularly billed
                  {` ${symbol}`}
                  {planGrandTotalFormatted} on the{' '}
                  {Constants.getBillDateNameFromValue(
                    Constants.billDates,
                    this.props.monthlyBillDate
                  )}{' '}
                  of the month
                </span>
              ) : null}
            </div>
          )}
        </div>
      )
    )
  }
}

Subscription.displayName = 'Subscription'
