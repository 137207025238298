import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { successUpdatingCommunityCoworksGroups } from './communityActions'
import {
  successCreatingTeam,
  successActivatingTeam,
  successDeactivatingTeam,
} from './teamActions'
import {
  successArchivingLead,
  successUpdatingPendingMember,
} from './leadActions'
import {
  FETCHING_MEMBERS,
  FETCHING_MEMBER,
} from '@global/Constants/FetchingConstants'

// ACTION CREATORS
export function setMembers(members, meta = null) {
  return { type: actionTypes.SET_MEMBERS, members, meta }
}
export function setInactiveUsers(members, meta = null) {
  return { type: actionTypes.SET_INACTIVE_USERS, members, meta }
}
export function successCreatingMember(member) {
  return { type: actionTypes.CREATE_MEMBER, member }
}
export function successUpdatingMemberCheckin(memberId, checkin) {
  return { type: actionTypes.UPDATE_MEMBER_CHECKIN, memberId, checkin }
}
export function successCreatedMemberCheckin(memberId, checkin) {
  return { type: actionTypes.CREATE_MEMBER_CHECKIN, memberId, checkin }
}
export function successDeletingMemberCheckin(memberId, checkin) {
  return { type: actionTypes.DELETE_MEMBER_CHECKIN, memberId, checkin }
}
export function successUpdatingMember(member) {
  return { type: actionTypes.UPDATE_MEMBER, member }
}
export function successDeactivatingMember(member) {
  return { type: actionTypes.DEACTIVATE_MEMBER, member }
}
export function successActivatingMember(member) {
  return { type: actionTypes.ACTIVATE_MEMBER, member }
}
export function successSendingOnboardingEmail(member) {
  return { type: actionTypes.SEND_ONBOARDING_EMAIL, member }
}

// ACTION DISPATCHERS
export const createMember = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createMember(params)
      .then(response => {
        if (response) {
          dispatch(successCreatingMember(response.user))
          if (!params.team_id && response.team) {
            dispatch(successCreatingTeam(response.team))
          }
          if (response.lead) {
            switch (response.lead.type) {
              case 'Lead':
                dispatch(
                  successArchivingLead(response.lead.type, response.lead)
                )
                break
              case 'PendingMember':
                dispatch(successUpdatingPendingMember(response.lead))
                break
              default:
                dispatch(
                  successArchivingLead(response.lead.type, response.lead)
                )
                break
            }
          }
        }
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const getMember = (
  userId,
  params,
  fetchingKey = FETCHING_MEMBER
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingKey))
    apiService
      .getMember(userId, params)
      .then(response => {
        dispatch(successUpdatingMember(response.user))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, fetchingKey))
      )
  })
}
export const search = (campusId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getUsers(campusId, params)
      .then(response => {
        if ((params.status && params.status === 'active') || !params.status) {
          dispatch(setMembers(response.members, response.meta))
        } else if (params.status && params.status === 'inactive') {
          dispatch(setInactiveUsers(response.members, response.meta))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export function getMembers(campusId, params, fetchingKey = FETCHING_MEMBERS) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingKey))
    return apiService
      .getUsers(campusId, params)
      .then(response => {
        if ((params.status && params.status === 'active') || !params.status) {
          dispatch(setMembers(response.members, response.meta))
        } else if (params.status && params.status === 'inactive') {
          dispatch(setInactiveUsers(response.members, response.meta))
        }
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, fetchingKey))
      )
  }
}

export const updateMemberCheckin = checkin => dispatch => {
  const memberId = checkin.user_id
  dispatch(successUpdatingMemberCheckin(memberId, checkin))
}
export const deleteMemberCheckin = checkin => dispatch => {
  const memberId = checkin.user_id
  dispatch(successDeletingMemberCheckin(memberId, checkin))
}
export const createMemberCheckin = checkin => dispatch => {
  const memberId = checkin.user_id
  dispatch(successCreatedMemberCheckin(memberId, checkin))
}

export const updateMember = (member, headers) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateMember(member, headers)
      .then(response => {
        dispatch(successUpdatingMember(response.user))
        if (response.community_coworks_groups) {
          dispatch(
            successUpdatingCommunityCoworksGroups(
              response.community_coworks_groups
            )
          )
        }
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const deactivateMember = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .deactivateMember(params)
      .then(response => {
        dispatch(successDeactivatingMember(response.user))
        dispatch(successDeactivatingTeam(response.team))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const activateMember = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .activateMember(params)
      .then(response => {
        dispatch(successActivatingMember(response.user))
        dispatch(successActivatingTeam(response.team))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const changeTeam = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .changeMemberTeam(params)
      .then(response => {
        dispatch(successUpdatingMember(response.user))
        if (response.inactive_team) {
          dispatch(successDeactivatingTeam(response.inactive_team))
        }
        if (response.team) {
          dispatch(successCreatingTeam(response.team))
        }
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const resendOnboardingEmail = member => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .resendOnboardingEmail(member)
      .then(response => {
        dispatch(successSendingOnboardingEmail(response.user))
        resolve(response.user)
      })
      .catch(err => {
        reject(err)
      })
  })
}
