import {
  CREATE_TEAM,
  REGISTER_COMMUNITY,
  SET_TEAMS,
  SET_INACTIVE_TEAMS,
  UPDATE_TEAM,
  DELETE_TEAM,
  CLEAR_CAMPUS_DATA,
  ACTIVATE_TEAM,
  DEACTIVATE_TEAM,
  CLEAR_TEAMS,
} from '../reduxActions/actionTypes'
import { arrayMerge, sortByName } from './reducerConstants'
import initialState from './initialState'

export default function (teams = initialState.teams, action) {
  // initialization of the nested teams array
  let teamsArray = teams.teams
  let inactiveTeamsArray = teams.inactive
  if (!teamsArray) teamsArray = []
  if (!inactiveTeamsArray) inactiveTeamsArray = []
  // handle the action
  switch (action.type) {
    case CREATE_TEAM:
      return {
        ...teams,
        teams: sortByName([...teamsArray, Object.assign({}, action.team)]),
      }
    case UPDATE_TEAM:
      let activeTeams = teamsArray
      let inactiveTeams = inactiveTeamsArray
      if (action.team.status === 'active') {
        if (!teamsArray) return { ...teams, teams: [action.team] }
        activeTeams = arrayMerge(teamsArray, [action.team])
      } else if (action.team.status === 'inactive') {
        if (!inactiveTeamsArray) return { ...teams, inactive: [action.team] }
        inactiveTeams = arrayMerge(inactiveTeamsArray, [action.team])
      }

      return {
        ...teams,
        teams: activeTeams,
        inactive: inactiveTeams,
      }
    case DELETE_TEAM:
      return {
        ...teams,
        teams: teamsArray.filter(oldTeam => {
          if (oldTeam.id !== action.team.id) {
            return true
          }
          return false
        }),
      }
    case REGISTER_COMMUNITY:
      return { ...teams, teams: action.teams }
    case SET_TEAMS:
      if (teamsArray && teamsArray.length > 0) {
        const oldTeams = teamsArray
        const newTeams = action.teams
        return {
          ...teams,
          teams: sortByName(arrayMerge(oldTeams, newTeams)),
          meta: action.meta,
        }
      }
      // list is empty so initialize so just set it.
      const sortedTeams = sortByName(action.teams)
      return {
        ...teams,
        teams: sortedTeams,
        meta: action.meta,
      }
    case SET_INACTIVE_TEAMS:
      if (inactiveTeamsArray && inactiveTeamsArray.length > 0) {
        const oldTeams = inactiveTeamsArray
        const newTeams = action.teams
        return {
          ...teams,
          inactive: sortByName(arrayMerge(oldTeams, newTeams)),
          inactiveMeta: action.meta,
        }
      }
      return {
        ...teams,
        inactive: sortByName(action.teams),
        inactiveMeta: action.meta,
      }
    case ACTIVATE_TEAM:
      let deactiveTeams = JSON.parse(JSON.stringify(inactiveTeamsArray))
      deactiveTeams = deactiveTeams.filter(team => {
        if (action.team.status !== 'inactive' && team.id === action.team.id) {
          return false
        }
        return true
      })
      let newTeams = []
      if (action.team.status === 'active') newTeams.push(action.team)
      return {
        ...teams,
        teams: sortByName(arrayMerge(teamsArray, newTeams)),
        inactive: sortByName(deactiveTeams),
      }
    case DEACTIVATE_TEAM:
      // actually want to remove the team
      activeTeams = JSON.parse(JSON.stringify(teamsArray))
      activeTeams = activeTeams.filter(team => {
        if (action.team.status !== 'active' && team.id === action.team.id) {
          return false
        }
        return true
      })
      newTeams = []
      if (action.team.status === 'inactive') newTeams.push(action.team)
      return {
        ...teams,
        teams: sortByName(activeTeams),
        inactive: sortByName(arrayMerge(inactiveTeamsArray, newTeams)),
      }

    // // just remove the member from the team.
    // return {
    //   ...teams,
    //   teams: teamsArray.map(oldTeam => {
    //     if (oldTeam.id !== action.team.id) {
    //       return oldTeam
    //     }
    //     return { ...oldTeam, ...action.team }
    //   }),
    // }
    case CLEAR_TEAMS:
    case CLEAR_CAMPUS_DATA:
      return initialState.teams
    default:
      return teams
  }
}
