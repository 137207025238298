import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { FETCHING_INTEGRATIONS, FETCHING_DOOR_ACCESS_PLACES } from '@global/Constants/FetchingConstants'

// ACTION CREATORS

export function setActiveIntegrations(integrations) {
  return { type: actionTypes.SET_ACTIVE_INTEGRATIONS, payload: integrations }
}
export function setPendingIntegration(integration) {
  return { type: actionTypes.SET_PENDING_INTEGRATION, payload: integration }
}
export function successActivatingIntegration(integration) {
  return { type: actionTypes.SET_ACTIVE_INTEGRATION, payload: integration }
}
export function successDeactivatingIntegration(integration) {
  return { type: actionTypes.SET_DEACTIVE_INTEGRATION, payload: integration }
}
// door access
export function successGettingDoorAccessGroups(groups) {
  return { type: actionTypes.SET_DOOR_GROUPS, payload: groups }
}
export function successGettingDoorAccessPlaces(places) {
  return { type: actionTypes.SET_DOOR_PLACES, payload: places }
}
export function setInitialIntegrationState() {
  return { type: actionTypes.SET_INITIAL_INTEGRATION_STATE }
}

export function handleActiveIntegrations(activeIntegrations) {
  return dispatch => {
    const keys = Object.keys(activeIntegrations)
    const formatted = []

    keys.forEach(key => {
      const innerKeys = Object.keys(activeIntegrations[key])
      innerKeys.forEach(innerKey => {
        const element = activeIntegrations[key][innerKey]
        if (element) {
          formatted.push({
            type: innerKey,
            section_name: key,
            api_key: element,
          })
        }
      })
    })
    dispatch(setActiveIntegrations(formatted))
  }
}
// ACTION DISPATCHERS
export function activateIntegration(params) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_INTEGRATIONS))
    return apiService
      .activateIntegration(params)
      .then(response => {
        let truncatedApiKey = ''
        if (params.api_key.length > 3) {
          truncatedApiKey = `••••••••${params.api_key.substring(
            params.api_key.length - 4,
            params.api_key.length
          )}`
        }

        dispatch(
          successActivatingIntegration({
            type: response.type,
            section: response.section,
            api_key: truncatedApiKey,
          })
        )
        if (response.type === 'kisi') {
          dispatch()
        }
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_INTEGRATIONS))
      })
  }
}
export function deactivateIntegration(params) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_INTEGRATIONS))
    return apiService
      .deactivateIntegration(params)
      .then(response => {
        dispatch(
          successDeactivatingIntegration({
            type: response.type,
            section: response.section,
          })
        )
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_INTEGRATIONS))
      })
  }
}

export function getDoorAccessPlaces(params) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_DOOR_ACCESS_PLACES))
    return apiService
      .getDoorAccessPlaces(params)
      .then(response => {
        dispatch(successGettingDoorAccessPlaces(response.places))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_DOOR_ACCESS_PLACES))
      )
  }
}
export function getDoorAccessGroups(params, loader) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, loader))
    return apiService
      .getDoorAccessGroups(params)
      .then(response => {
        dispatch(successGettingDoorAccessGroups(response.groups))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => dispatch(uiActions.updateFetchingStatus(false, loader)))
  }
}
