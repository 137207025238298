import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as announcementActions from '../../reduxActions/announcementActions'

import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import Placeholder from '@global/Placeholder'
import Spinner from '@global/Spinner'
import CoworksSearch from '@global/Search/CoworksSearch'
import { Button } from '@global/Base/Button/ButtonStyled'
import PillToggle from '@global/Base/Toggle/PillToggle'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import * as Constants from '@global/Constants'

// CHILDREN
import AnnouncementsTable from './AnnouncementsTable'
import MessageRow from './TableRow/MessageRow'
import ReviewModal from './ReviewModal'
import GenericModal from '@global/GenericModal'
import { toast } from 'react-toastify'
class Announcements extends React.Component {
  static propTypes = {
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    announcements: PropTypes.array,
    isFetching: PropTypes.bool,
    announcement_actions: PropTypes.object,
    pagination: PropTypes.object,
    history: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.state = {
      searchBar: '',
      isFetching: false,
      announcementPage: Constants.START_PAGE_DEFAULT,
      perPage: Constants.PER_PAGE_DEFAULT,
      showViewModal: false,
      selectedMessage: null,
    }
  }

  componentDidMount() {
    this.fetchInitialProps()
  }

  fetchInitialProps = () => {
    this.setState({ messageLoader: true })
    this.loadNextObjects(this.state.announcementPage, this.state.perPage)
  }

  deleteAnnouncement = () => {
    this.props.announcement_actions
      .archiveAnnouncement(this.state.messageToDelete.id)
      .then(() => {
        toast.success('Archived the announcement.')
        this.setState({
          messageToDelete: null,
        })
      })
      .catch(() => {})
  }
  loadNextObjects = (page, perPage) => {
    this.props.announcement_actions
      .getAnnouncements(this.props.activeCampus.id, {
        page: page,
        per_page: perPage,
      })
      .then(() => {
        this.setState({
          messageLoader: false,
          isFetching: false,
        })
      })
      .catch(() => {
        this.setState({
          messageLoader: false,
          isFetching: false,
        })
      })
  }

  toggleReviewModal = () => {
    this.setState({
      showViewModal: !this.state.showViewModal,
    })
  }

  nextAnnouncementPage = () => {
    const stateCopy = Object.assign({}, this.state)
    if (
      this.props.pagination &&
      this.props.announcements &&
      stateCopy.announcementPage < this.props.pagination.total_pages &&
      this.props.announcements.length < this.props.pagination.total_objects
    ) {
      const page = stateCopy.announcementPage + 1
      const perPage = stateCopy.perPage
      this.setState(
        {
          announcementPage: page,
          isFetching: true,
        },
        () => this.loadNextObjects(page, perPage)
      )
    }
  }

  dataAnnouncementResolver = response => {
    return response.announcements
  }

  displayMessages = () => {
    if (this.state.messageLoader) {
      return <Spinner />
    }
    const announcements = this.props.announcements
      ? this.props.announcements
      : []

    return (
      <AnnouncementsTable
        messages={announcements}
        onNext={this.nextAnnouncementPage}
        isFetching={this.state.isFetching}
        pagination={this.props.pagination}
        renderRow={this.renderRow}
        handlePlaceholderButtonClick={this.handleNewAnnouncemenetClicked}
      />
    )
  }

  viewMessage = (selectedMessage, index) => {
    this.setState({
      selectedMessage,
      showViewModal: true,
    })
  }
  showDeleteModal = (messageToDelete, index) => {
    this.setState({
      messageToDelete,
    })
  }

  duplicateMessage = id => {
    this.props.history.push(`/announcements/send-announcement/${id}`)
  }

  handleNewAnnouncemenetClicked = () => {
    this.props.history.push('/announcements/send-announcement')
  }
  renderRow = (message, index) => {
    return (
      <MessageRow
        key={message.id}
        message={message}
        onView={() => this.viewMessage(message, index)}
        onDelete={() => this.showDeleteModal(message, index)}
        onDuplicate={() => this.duplicateMessage(message.id)}
      />
    )
  }

  render() {
    const { selectedMessage, messageToDelete } = this.state
    return (
      <div id="plans" className="animated fadeIn">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="row ">
                  <div className="col-7">
                    <h2>Announcements</h2>
                  </div>
                  {/* <div className='col-5 d-flex align-items-center'>
                    <div className='d-block input-group'>
                      <CoworksSearch
                        placeholder={'Search Messages'}
                        defaultData={this.props.announcements}
                        actions={this.props.announcement_actions}
                        activeCampus={this.props.activeCampus}
                        dataResolver={this.dataAnnouncementResolver}
                        renderSearchRow={this.renderRow}
                        renderSingleValue={() => {
                          return null;
                        }} />
                    </div>
                  </div> */}
                  <div className="col-5 d-flex justify-content-end align-items-center">
                    <Button
                      color="primary"
                      onClick={this.handleNewAnnouncemenetClicked}
                    >
                      <FontAwesomeIcon icon="envelope" /> New Announcement
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-block">{this.displayMessages()}</div>
            </div>
          </div>
        </div>
        {selectedMessage && (
          <ReviewModal
            activeCommunity={this.props.activeCommunity}
            activeCampus={this.props.activeCampus}
            showReviewModal={this.state.showViewModal}
            toggleReviewModal={this.toggleReviewModal}
            announcementId={selectedMessage.id}
            recipients={selectedMessage.recipients}
            recipientCount={selectedMessage.recipient_count}
            message={selectedMessage.message}
            from={selectedMessage.from_email}
            sendToAll={selectedMessage.send_to_campus}
            subject={selectedMessage.subject}
            header={selectedMessage.header}
            sentDate={selectedMessage.created_at}
            sentByUser={selectedMessage.user}
            sendAsSMS={selectedMessage.send_as_sms}
            sendAsEmail={selectedMessage.send_as_email}
            type={selectedMessage.type}
            selectedMessage={selectedMessage}
            greeting={null}
            editMode={false}
          />
        )}
        {messageToDelete && (
          <GenericModal
            className="modal-primary fade in"
            header={'Delete Announcement'}
            body={
              'Are you sure you want to delete this announcement? It will not un-send. It will just remove from your records.'
            }
            onConfirm={this.deleteAnnouncement}
            isOpen={Boolean(messageToDelete)}
            cancelLabel={'Cancel'}
            isLoading={this.props.isFetching}
            confirmLabel={'Delete'}
            onClose={() => this.setState({ messageToDelete: null })}
          />
        )}
      </div>
    )
  }
}

Announcements.displayName = 'Announcements'

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    announcements: state.announcements.announcements,
    pagination: state.announcements.meta
      ? state.announcements.meta.pagination
      : null,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    announcement_actions: bindActionCreators(announcementActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Announcements)
