/* eslint-disable max-depth,max-statements */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as stripeActions from '@reduxActions/stripeActions'
import * as teamActions from '@reduxActions/teamActions'
import moment from 'moment'
import { toast } from 'react-toastify'
import * as Constants from '@global/Constants'
import { addFeesToInvoice } from '@global/Constants/PaymentConstants'
import { renderDateTimeStamp } from '@global/Constants/DateConstants'
import RoundedImage from '@global/RoundedImage'
import EventModalComponent from '../../Calendar/EventModalComponent'

import { convertStripeErrorToMessage } from '@global/ErrorFactory'
import Spinner from '@global/Spinner'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import PaymentSourceComponent from '@global/Stripe/PaymentSourceComponent'
import * as _ from 'lodash'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import { LabelStyled } from '@global/Form/FormComponents'
import AvatarNamePlate from '@global/Avatar/AvatarNamePlate'
import Tooltip from '@bit/coworks.base-components.tooltip'
import {
  resolveFetchingStatus,
  FETCHING_TEAMS,
} from '@global/Constants/FetchingConstants'

import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import EditNotesModal from '../Modals/EditNotesModal'
import RefundModal from '../Modals/RefundModal'
import MarkPaidModal from '../Modals/MarkPaidModal'
import VoidInvoiceModal from '../Modals/VoidInvoiceModal'
import PayNowModal from '../Modals/PayNowModal'
import DeleteInvoiceModal from '../Modals/DeleteInvoiceModal'
import GenericModal from '../Modals/GenericModal'
import FinalizeInvoiceModal from '../Modals/FinalizeInvoiceModal'
import ResendInvoiceModal from '../Modals/ResendInvoiceModal'
import SplitInvoiceModal from '../Modals/SplitInvoiceModal'
import RefundsCard from './RefundsCard'
import InvoiceLink from './InvoiceLink'

const DropdownMenuStyled = styled(DropdownMenu)`
  .danger {
    color: ${props => props.theme.colorDanger};
  }
`

const InvoiceHeaderRowStyled = styled.div`
  background-color: #eeeeee;
`

const AltsContainer = styled(Col)`
  display: flex;
  flex-flow: column nowrap;
`
const HeaderStyled = styled.h3`
  font-weight: bold;
`
const TextButtonStyled = styled(TextButton)`
  margin: 0px;
  padding: 0px;
`
const ButtonDropdownStyled = styled(ButtonDropdown)`
  margin-left: 8px;
`

class InvoiceDetail extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    role: PropTypes.string,
    stripe_actions: PropTypes.object,
    team_actions: PropTypes.object,
    activeCommunity: PropTypes.object,
    charges: PropTypes.object,
    teams: PropTypes.array,
    team: PropTypes.object,
    invoice: PropTypes.object,
    objectId: PropTypes.string,
    charge: PropTypes.object,
    isFetching: PropTypes.bool,
    isFetchingTeams: PropTypes.bool,
    customers: PropTypes.array,
    preview: PropTypes.bool,
    membershipResumeAt: PropTypes.object,
    membershipPaused: PropTypes.object,
  }
  static defaultProps = {
    membershipResumeAt: moment(), // todo this needs to be props
  }

  constructor(props) {
    super(props)
    let disableActions = false
    let invoice = null
    let team = null
    let charge = null
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state
    ) {
      const locationState = this.props.history.location.state
      if (locationState.disableActions) {
        disableActions = locationState.disableActions
      }
    }
    if (this.props.team) {
      team = this.props.team
    }
    if (this.props.invoice) {
      invoice = this.props.invoice
    }
    if (this.props.charge) {
      charge = this.props.charge
    }
    this.state = {
      invoice, // for invoice
      charge, // for charge
      team,
      showRefundModal: false,
      showMarkPaidModal: false,
      showVoidInvoiceModal: false,
      showResumeModal: false,
      disableActions,
      notes: null,
      eventModal: false,
      actionsDropdown: false,
      isFetchingStripeCustomer: false,
    }
  }

  componentDidMount() {
    const { charge, invoice } = this.state

    const objectId = invoice ? invoice.id : this.props.objectId
    const isInvoice = objectId && objectId.includes('in_')
    if (isInvoice && objectId) {
      this.props.stripe_actions
        .getInvoice({
          invoice_id: objectId,
          community_id: this.props.activeCommunity.id,
          expand: JSON.stringify(['customer', 'charge']),
        })
        .then(response => {
          const invoice = response.invoice
          const team = response.team
          let customer = this.state.customer
          let charge =
            response.invoice &&
            response.invoice.charge &&
            typeof response.invoice.charge === 'object'
              ? response.invoice.charge
              : this.state.charge
          if (charge) {
            charge = JSON.parse(JSON.stringify(charge))
            charge.amount = Number(charge.amount / 100).toFixed(2)
          }
          if (invoice && typeof invoice.customer === 'object') {
            customer = invoice.customer
          }
          if (invoice) {
            this.setState({
              invoice,
              charge,
              team: team ? team : this.state.team,
              customer,
            })
          }
        })
        .catch(error => {
          toast.error(convertStripeErrorToMessage(error))
        })
    } else if (!isInvoice && objectId) {
      this.props.stripe_actions
        .getTransaction({
          charge_id: objectId,
          community_id: this.props.activeCommunity.id,
          expand: JSON.stringify(['customer', 'invoice']),
        })
        .then(response => {
          let invoice = null
          const team = response.team
          let customer = this.state.customer
          let charge = response.transaction
          if (charge) {
            charge = JSON.parse(JSON.stringify(charge))
            charge.amount = Number(charge.amount / 100).toFixed(2)
          }
          if (charge && typeof charge.customer === 'object') {
            customer = charge.customer
          }
          if (charge && typeof charge.invoice === 'object') {
            invoice = charge.invoice
          }
          if (charge) {
            this.setState({
              invoice,
              charge,
              team: team ? team : this.state.team,
              customer,
            })
          }
        })
        .catch(error => {
          toast.error(convertStripeErrorToMessage(error))
        })
    }
  }

  toggleRefundModal = () => {
    this.setState({
      showRefundModal: !this.state.showRefundModal,
      notes: this.getNotesFromStripeObjects(),
    })
  }
  toggleResumeModal = () => {
    this.setState({
      showResumeModal: !this.state.showResumeModal,
      notes: this.getNotesFromStripeObjects(),
    })
  }
  toggleMarkPaidModal = () => {
    this.setState({
      showMarkPaidModal: !this.state.showMarkPaidModal,
      notes: this.getNotesFromStripeObjects(),
    })
  }
  toggleVoidInvoiceModal = () => {
    this.setState({
      showVoidInvoiceModal: !this.state.showVoidInvoiceModal,
      notes: this.getNotesFromStripeObjects(),
    })
  }
  togglePayNowModal = () => {
    this.setState({
      showPayNowModal: !this.state.showPayNowModal,
      notes: this.getNotesFromStripeObjects(),
    })
  }
  toggleDeleteInvoiceModal = () => {
    this.setState({
      showDeleteInvoiceModal: !this.state.showDeleteInvoiceModal,
    })
  }
  toggleSplitInvoiceModal = () => {
    this.setState({
      showSplitInvoiceModal: !this.state.showSplitInvoiceModal,
    })
  }
  toggleFinalizeInvoiceModal = () => {
    this.setState({
      showFinalizeInvoiceModal: !this.state.showFinalizeInvoiceModal,
      notes: this.getNotesFromStripeObjects(),
    })
  }
  toggleEditNotesModal = () => {
    this.setState({
      showEditNotesModal: !this.state.showEditNotesModal,
      notes: this.getNotesFromStripeObjects(),
    })
  }
  toggleResendInvoiceModal = () => {
    this.setState({
      showResendInvoiceModal: !this.state.showResendInvoiceModal,
    })
  }
  getNotesFromStripeObjects = () => {
    const { invoice, charge } = this.state
    let notes = null
    if (invoice && invoice.metadata && invoice.metadata.notes) {
      notes = invoice.metadata.notes
    } else if (charge && charge.metadata && charge.metadata.notes) {
      notes = charge.metadata.notes
    }
    return notes
  }
  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  renderSplitInvoiceLinks = metaData => {
    const { invoice } = this.state
    const links = []
    if (metaData.is_original_invoice === 'true' && !metaData.split_invoice_id) {
      // you are viewing the original, and not the parent to a double-y split invoice
      links.push(<InvoiceLink label={'Original Invoice'} isCurrent />)
    }
    if (metaData.original_invoice_id) {
      links.push(
        <InvoiceLink
          label={'Original Invoice'}
          isCurrent={metaData.original_invoice_id === invoice.id}
          invoiceId={metaData.original_invoice_id}
        />
      )
    }

    if (metaData.split_invoice_id) {
      // you are viewing one of the two siblings
      // this weird string comparison is done so that the items stay in place when navigating to the other child
      // invoice
      // other invoice (current) <- when clicking this it will stay as 2 on the other invoice links page
      if (invoice.id > metaData.split_invoice_id) {
        links.push(
          <InvoiceLink
            label={'Split Invoice'}
            isCurrent={metaData.split_invoice_id === invoice.id}
            invoiceId={metaData.split_invoice_id}
            indentSize={'2rem'}
          />
        )
      }
      // push the current one with it's indented children
      links.push(
        <InvoiceLink label={'Invoice'} isCurrent indentSize={'2rem'} />
      )

      if (metaData.split_invoice_id_1) {
        links.push(
          <InvoiceLink
            label={'Split Invoice 1'}
            isCurrent={metaData.split_invoice_id_1 === invoice.id}
            invoiceId={metaData.split_invoice_id_1}
            indentSize={'4rem'}
          />
        )
      }
      if (metaData.split_invoice_id_2) {
        links.push(
          <InvoiceLink
            label={'Split Invoice 2'}
            isCurrent={metaData.split_invoice_id_2 === invoice.id}
            invoiceId={metaData.split_invoice_id_2}
            indentSize={'4rem'}
          />
        )
      }

      if (invoice.id < metaData.split_invoice_id) {
        links.push(
          <InvoiceLink
            label={'Other Split Invoice'}
            isCurrent={metaData.split_invoice_id === invoice.id}
            invoiceId={metaData.split_invoice_id}
            indentSize={'2rem'}
          />
        )
      }
    } else {
      if (metaData.split_invoice_id_1) {
        links.push(
          <InvoiceLink
            label={'Split Invoice 1'}
            isCurrent={metaData.split_invoice_id_1 === invoice.id}
            invoiceId={metaData.split_invoice_id_1}
            indentSize={'2rem'}
          />
        )
      }
      if (metaData.split_invoice_id_2) {
        links.push(
          <InvoiceLink
            label={'Split Invoice 2'}
            isCurrent={metaData.split_invoice_id_2 === invoice.id}
            invoiceId={metaData.split_invoice_id_2}
            indentSize={'2rem'}
          />
        )
      }
    }

    return (
      <div className="row card">
        <div className="col-12 py-3">
          <HeaderStyled>Split Invoice Links</HeaderStyled>
          <hr />
          <Row>
            <AltsContainer>{links}</AltsContainer>
          </Row>
        </div>
      </div>
    )
  }

  // start of API methods
  issueRefund = (refundEntity, amount) => {
    const refundParams = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      community_id: this.props.activeCommunity.id,
      amount,
    }
    if (this.state.notes) {
      refundParams.notes = this.state.notes
    }
    const invoice = this.state.invoice
    if (invoice) {
      refundParams.charge_id = this.state.invoice.charge.id
      if (refundEntity) refundParams.void_booking = refundEntity
    } else {
      refundParams.charge_id = this.state.charge.id
    }
    refundParams.expand = JSON.stringify(['invoice'])
    const self = this
    this.props.stripe_actions
      .createRefund(refundParams)
      .then(response => {
        const refund = response.refund
        const charge = response.charge

        const newState = {
          refund,
          showRefundModal: false,
        }
        charge.amount = Number(charge.amount / 100).toFixed(2)
        newState.charge = charge
        self.setState(newState)
        const currencySymbol = Constants.getCurrencySymbolFromName(
          refund.currency
        )
        toast.success(
          `Refunded ${currencySymbol}${Number(refund.amount / 100).toFixed(2)}.`
        )
      })
      .catch(err => {
        self.setState({
          showRefundModal: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }

  voidInvoice = voidEntity => {
    // used to void already finalized invoices
    const self = this
    const params = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      community_id: this.props.activeCommunity.id,
      notes: this.state.notes,
    }
    const invoice = this.state.invoice
    if (invoice) {
      params.invoice_id = invoice.id
      if (voidEntity) params.cancel_booking = invoice.id
    }

    this.props.stripe_actions
      .voidInvoice(params)
      .then(response => {
        const invoice = response.invoice
        self.setState({
          invoice,
          showVoidInvoiceModal: false,
        })
        toast.success('Closed the invoice.')
      })
      .catch(err => {
        console.log(err)
        self.setState({
          showVoidInvoiceModal: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  resumeInvoice = () => {
    const { invoice } = this.state
    const params = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      community_id: this.props.activeCommunity.id,
    }

    if (invoice) {
      params.invoice_id = invoice.id
      params.auto_advance = !invoice.auto_advance
      this.props.stripe_actions
        .updateInvoice(params)
        .then(response => {
          const inv = response.invoice
          toast.success(
            inv.auto_advance ? 'Resumed the invoice.' : 'Paused the Invoice.'
          )
          this.setState({
            invoice: inv,
            showResumeModal: false,
          })
        })
        .catch(error => {
          toast.error(convertStripeErrorToMessage(error))
        })
    }
  }
  payInvoice = markedAsPaid => {
    if (this.state.invoice) {
      const params = {
        invoice_id: this.state.invoice.id,
        stripe_account_id: this.props.activeCommunity.stripe_account_id,
        community_id: this.props.activeCommunity.id,
      }
      if (this.state.notes) {
        params.notes = this.state.notes
      }
      if (markedAsPaid) {
        params.paid_out_of_band = true
      }
      this.props.stripe_actions
        .payInvoice(params)
        .then(response => {
          const invoice = response.invoice
          let charge = response.charge
          if (charge) {
            charge = JSON.parse(JSON.stringify(charge))
            charge.amount = Number(charge.amount / 100).toFixed(2)
            toast.success('Charged the member.')
          } else {
            toast.success('Marked the invoice as paid.')
          }
          this.setState({
            invoice,
            charge,
            showPayNowModal: false,
            showMarkPaidModal: false,
          })
        })
        .catch(err => {
          this.setState(
            {
              showPayNowModal: false,
              showMarkPaidModal: false,
            },
            () => {
              toast.error(convertStripeErrorToMessage(err))
              console.error('Error', err)
            }
          )
        })
    }
  }
  // draft invoice methods
  finalizeInvoice = () => {
    // used to finalize draft invoices
    const self = this
    this.props.stripe_actions
      .finalizeInvoice({
        invoice_id: this.state.invoice.id,
        stripe_account_id: this.props.activeCommunity.stripe_account_id,
        community_id: this.props.activeCommunity.id,
        notes: this.state.notes,
      })
      .then(response => {
        const invoice = response.invoice
        self.setState({
          invoice,
          showFinalizeInvoiceModal: false,
        })
        toast.success('Finalized the invoice. It will be sent immediately.')
      })
      .catch(err => {
        console.log(err)
        self.setState({
          showFinalizeInvoiceModal: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  deleteInvoice = deleteEntity => {
    // used to delete draft invoices
    const self = this
    const params = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      community_id: this.props.activeCommunity.id,
    }
    const invoice = this.state.invoice
    if (invoice) {
      params.invoice_id = invoice.id
      if (deleteEntity) params.void_booking = deleteEntity
    }

    this.props.stripe_actions
      .deleteInvoice(params)
      .then(response => {
        toast.success('Cancelled the invoice. It will not be sent anymore.')
        this.props.history.goBack()
      })
      .catch(err => {
        self.setState({
          showDeleteInvoiceModal: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  resendInvoice = () => {
    // used to delete draft invoices
    this.props.stripe_actions
      .resendInvoice({
        invoice_id: this.state.invoice.id,
        stripe_account_id: this.props.activeCommunity.stripe_account_id,
        community_id: this.props.activeCommunity.id,
      })
      .then(response => {
        this.setState({
          showResendInvoiceModal: false,
        })
        toast.success('Resent the invoice.')
      })
      .catch(err => {
        console.log(err)
        this.setState({
          showResendInvoiceModal: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  splitInvoice = data => {
    //this.setState({ showSplitInvoiceModal: false })
    //toast.success(`Split the invoice: ${data.amount} ${data.finalize}`)
    this.props.stripe_actions
      .splitInvoice({
        invoice_id: this.state.invoice.id,
        amount: data.amount,
        finalize: data.finalize,
        stripe_account_id: this.props.activeCommunity.stripe_account_id,
        community_id: this.props.activeCommunity.id,
      })
      .then(response => {
        this.setState({
          showSplitInvoiceModal: false,
        })
        toast.success('Split the invoice.')
        this.props.history.push('/billing/invoices')
      })
      .catch(err => {
        console.log(err)
        this.setState({
          showSplitInvoiceModal: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  toggleEventModal = () => this.setState({ eventModal: !this.state.eventModal })
  redirectToProfile = meta => {
    if (meta.contact_id) {
      this.props.history.push(`/crm/leads/${meta.contact_id}`, {
        contactId: meta.contact_id,
      })
    } else if (meta.user_id) {
      this.props.history.push(`/directory/members/${meta.user_id}/profile`, {
        member: { id: meta.user_id },
      })
    }
  }
  renderModal = event => {
    if (this.state.eventModal) {
      return (
        <EventModalComponent
          user={this.props.user}
          role={this.props.role}
          history={this.props.history}
          isPrimaryOpen={this.state.eventModal}
          togglePrimary={this.toggleEventModal}
          calendarRedirect
          event={event}
        />
      )
    }
    return null
  }
  saveNotes = () => {
    const params = {
      stripe_account_id: this.props.activeCommunity.stripe_account_id,
      community_id: this.props.activeCommunity.id,
      notes: this.state.notes,
    }

    if (this.state.invoice) {
      params.invoice_id = this.state.invoice.id
      this.props.stripe_actions
        .updateInvoice(params)
        .then(response => {
          const invoice = response.invoice
          if (invoice) {
            this.setState({
              showEditNotesModal: false,
              invoice: invoice,
            })
          }
          toast.success('Updated the notes.')
        })
        .catch(error => {
          this.setState({
            showEditNotesModal: false,
          })
          toast.error(convertStripeErrorToMessage(error))
        })
    } else if (this.state.charge) {
      params.charge_id = this.state.charge.id
      this.props.stripe_actions
        .updateCharge(params)
        .then(response => {
          if (response.charge) {
            this.setState({
              charge: response.charge,
              showEditNotesModal: false,
            })
          }
        })
        .catch(error => {
          this.setState({
            showEditNotesModal: false,
          })
          toast.error(convertStripeErrorToMessage(error))
        })
    }
  }
  // end of API methods

  openHostedUrl = link => {
    window.open(link, '_blank')
  }

  getTeamFromList(teams, teamId) {
    for (let counter = 0; counter < teams.length; counter++) {
      if (teams[counter].id === teamId) {
        return this.props.teams[counter]
      }
    }
    return null
  }
  getSourceFromCharge = stripeObj => {
    let obj = null
    if (stripeObj.object === 'charge') {
      const charge = stripeObj
      if (charge.payment_method_details && charge.payment_method_details.card) {
        obj = _.cloneDeep(charge.payment_method_details.card)
        // for some reason, Stripe removed this from the charge payment_details
        // and the components down the tree are looking for this
        obj.object = 'card'
      } else if (
        charge.payment_method_details &&
        charge.payment_method_details.ach_debit
      ) {
        obj = _.cloneDeep(charge.payment_method_details.ach_debit)
        // for some reason, Stripe removed this from the charge payment_details
        // and the components down the tree are looking for this
        obj.object = 'bank_account'
      }
    }
    return obj
  }
  renderNotesFields = () =>
    this.props.isFetching ? (
      <Spinner />
    ) : (
      <React.Fragment>
        <LabelStyled>Internal Notes</LabelStyled>
        <textarea
          className="form-control"
          rows={3}
          value={this.state.notes}
          name="notes"
          placeholder="Write any internal notes here."
          onChange={this.handleInputChange}
        />
      </React.Fragment>
    )
  renderInvoiceDates = (charge, invoice, team, preview) => {
    return (
      <React.Fragment>
        <Row className="mb-1">
          <div className="col-6 col-md-3">
            <LabelStyled>
              {preview ? 'Creating on' : 'Created at'}
              {invoice ? '' : '& Paid'}{' '}
            </LabelStyled>
          </div>
          <div className="col-6 col-md-9">
            {invoice ? (
              <React.Fragment>
                {invoice.status_transitions &&
                invoice.status_transitions.finalized_at ? (
                  <span>
                    {renderDateTimeStamp(
                      invoice.status_transitions.finalized_at
                    )}
                  </span>
                ) : (
                  <span>{renderDateTimeStamp(invoice.created)}</span>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span>{renderDateTimeStamp(charge.created)}</span>
              </React.Fragment>
            )}
          </div>
        </Row>
        {invoice &&
          invoice.status_transitions &&
          invoice.status_transitions.voided_at && (
            <Row className="mb-1">
              <div className="col-6 col-md-3">
                {!preview && <div>Closed on: </div>}
              </div>
              <div className="col-6 col-md-9">
                <span>
                  {renderDateTimeStamp(invoice.status_transitions.voided_at)}
                </span>
              </div>
            </Row>
          )}
        {invoice &&
          invoice.status_transitions &&
          invoice.status_transitions.paid_at && (
            <Row className="mb-1">
              <div className="col-6 col-md-3">
                {!preview && <LabelStyled>Paid at </LabelStyled>}
              </div>
              <div className="col-6 col-md-9">
                <span>
                  {renderDateTimeStamp(invoice.status_transitions.paid_at)}
                </span>
              </div>
            </Row>
          )}
      </React.Fragment>
    )
  }
  renderLineItems = myInvoice => {
    if (
      !myInvoice &&
      !this.state.charge &&
      !this.state.charge.invoice &&
      typeof !this.state.charge.invoice === 'object'
    ) {
      return null
    }
    const invoice = myInvoice ? myInvoice : this.state.charge.invoice
    const lineItems = [...invoice.lines.data].reverse()
    return lineItems.map((lineItem, index) => {
      let currencySymbol = '$'
      if (lineItem.currency) {
        currencySymbol = Constants.getCurrencySymbolFromName(lineItem.currency)
      } else {
        currencySymbol = Constants.getCurrencySymbolFromCommunity(
          this.props.activeCommunity
        )
      }
      return (
        <div key={index} className="row">
          {lineItem.plan ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  {lineItem.proration
                    ? lineItem.description
                    : lineItem.plan.nickname}
                </div>
                <div className="col-md-2">
                  {Number(lineItem.quantity).toFixed(0)}
                </div>
                <div className="col-md-2">
                  {currencySymbol}
                  {Number(lineItem.plan.amount / 100).toFixed(2)}
                </div>
                <div id="amount" className="col-2 d-flex justify-content-end">
                  <Tooltip
                    header={`Proration`}
                    tooltipContent={
                      lineItem.proration
                        ? `This item is prorated for ${lineItem.description}.`
                        : ''
                    }
                    visible={lineItem.proration ? undefined : false}
                  >
                    {currencySymbol}
                    {Number(lineItem.amount / 100).toFixed(2)}
                  </Tooltip>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className="col-6">{lineItem.description}</div>
              <div className="col-2">{lineItem.quantity}</div>
              <div className="col-2">
                {currencySymbol}
                {Number(lineItem.amount / lineItem.quantity / 100).toFixed(2)}
              </div>
              <div className="col-2 d-flex justify-content-end">
                {currencySymbol}
                {Number(lineItem.amount / 100).toFixed(2)}
              </div>
            </React.Fragment>
          )}
        </div>
      )
    })
  }

  renderInvoiceSummary() {
    const { invoice, charge } = this.state
    const { preview } = this.props
    return invoice ? (
      <React.Fragment>
        {preview && (
          <h6 className="text-muted mr-1">
            This is an upcoming invoice preview for {'   '}
          </h6>
        )}
        <HeaderStyled>
          {Constants.getCurrencySymbolFromName(invoice.currency)}
          {Number(invoice.amount_due / 100).toFixed(2)}
          {!preview && invoice.due_date ? (
            <React.Fragment>
              {' '}
              due on {renderDateTimeStamp(invoice.due_date, false)}
            </React.Fragment>
          ) : null}
          {!preview &&
          !invoice.due_date &&
          invoice.status_transitions &&
          invoice.status_transitions.paid_at ? (
            <React.Fragment>
              {' '}
              paid on{' '}
              {renderDateTimeStamp(invoice.status_transitions.paid_at, false)}
            </React.Fragment>
          ) : null}
        </HeaderStyled>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <HeaderStyled>{renderDateTimeStamp(charge.created)}</HeaderStyled>
      </React.Fragment>
    )
  }
  renderNotes(object) {
    if (object.metadata && object.metadata.notes) {
      return (
        <React.Fragment>
          <p>
            {object.metadata.notes}
            <button
              className="btn btn-link m-0 p-0 ml-2"
              onClick={this.toggleEditNotesModal}
            >
              Edit
            </button>
          </p>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <p className="text-muted">
          None{' '}
          <button
            className="btn btn-link m-0 p-0 ml-2"
            onClick={this.toggleEditNotesModal}
          >
            Edit
          </button>
        </p>
      </React.Fragment>
    )
  }
  renderCustomerEmail = (charge, invoice) => {
    if (invoice && invoice.customer_email) {
      return <h5>{invoice.customer_email}</h5>
    } else if (
      charge &&
      charge.billing_details &&
      charge.billing_details.email
    ) {
      return <h5>{charge.billing_details.email}</h5>
    }
    return <h5>Guest</h5>
  }

  getCustomerEmail = () => {
    const stripeObject = this.state.invoice
      ? this.state.invoice
      : this.state.charge
    return stripeObject && stripeObject.customer && stripeObject.customer.email
      ? stripeObject.customer.email
      : ''
  }
  teamLinkClicked = () => {
    NavigationConstants.navigateToTeam({
      history: this.props.history,
      match: this.props.match,
      team: this.state.team,
    })
  }

  getCreatedBy = invoice => {
    const { metadata } = invoice
    if (metadata) {
      const createdBy = metadata.created_by
      switch (createdBy) {
        case 'DayPass':
          return (
            <Row className="mb-1">
              <div className="col-6 col-md-3">
                <LabelStyled>Created by:</LabelStyled>
              </div>
              <div className="col-6 col-md-9 text-truncate">
                Day Pass
                {(metadata.contact_id || metadata.user_id) && (
                  <button
                    className="btn btn-link m-0 p-0 ml-2"
                    onClick={() => this.redirectToProfile(metadata)}
                  >
                    View Customer
                  </button>
                )}
              </div>
              {this.renderModal({
                id: parseInt(invoice.metadata.booking_id, 10),
              })}
            </Row>
          )
        case 'ExternalBooking':
          return (
            <Row className="mb-1">
              <div className="col-6 col-md-3">
                <LabelStyled>Created by:</LabelStyled>
              </div>
              <div className="col-6 col-md-9 text-truncate">
                External Booking
                <button
                  className="btn btn-link m-0 p-0 ml-2"
                  onClick={this.toggleEventModal}
                >
                  View
                </button>
              </div>
              {this.renderModal({
                id: parseInt(invoice.metadata.booking_id, 10),
              })}
            </Row>
          )
        default:
          break
      }
    }
    return null
  }
  getResumeModalText = invoice => {
    if (!invoice) return ''
    if (invoice.auto_advance === true) {
      // you have the option to pause it
      if (invoice.status === 'draft') {
        return 'This will stop the invoice from being finalized and will not email the invoice to the customer.'
      }
      return 'This will stop reminding the customer to pay this invoice. It will still be considered unpaid. This invoice is still chargeable and payable by the customer.'
    }
    // you have the option to resume it
    let text = ''
    if (invoice.status === 'draft') {
      text = 'The invoice will automatically be finalized. '
      if (invoice.billing === 'send_invoice') {
        text = `${text} This customer will continued to be sent payment reminder emails.`
      } else {
        text = `${text} We will continue to attempt to charge the default card on file until the invoice is successfully paid or paused again.`
      }
    } else if (invoice.billing === 'send_invoice') {
      text = 'This customer will continued to be sent payment reminder emails.'
    } else {
      text = `${text} We will continue to attempt to charge the default card on file until the invoice is successfully paid or paused again.`
    }
    return text
  }

  toggleActionsDropdown = () => {
    this.setState({ actionsDropdown: !this.state.actionsDropdown })
  }

  renderToRow = () => {
    const team = this.state.team

    return (
      <Col xs={12} className="mt-2">
        <Row>
          {team && (
            <Col xs="4" className="mt-2">
              <LabelStyled>{'Team'}</LabelStyled>
            </Col>
          )}
          <Col xs="8" className="mt-2">
            <LabelStyled>{team ? 'Admin Email' : 'Guest Email'}</LabelStyled>
          </Col>
        </Row>
        <Row>
          {team && (
            <Col xs="4" className="text-truncate mb-2">
              <AvatarNamePlate
                object={team}
                size={1}
                onClick={this.teamLinkClicked}
                objectType="team"
              />
            </Col>
          )}
          <Col xs="8" className="text-truncate mb-2">
            {this.getCustomerEmail()}
          </Col>
        </Row>
      </Col>
    )
  }

  render() {
    let total, type, closed, dashboardLinkToInvoice
    let showCancel = false
    let showFinalize = false
    let showSplit = false
    let showClose = false
    let showPayNow = false
    let showMarkPaid = false
    let showRefundButton = false
    let showResumeButton = false
    let showResend = false
    const { membershipResumeAt, membershipPaused, preview } = this.props
    const { charge, team } = this.state
    let invoice = this.state.invoice

    const ID = 'in_1I5yb8DCzvBOwIZDehltCdkO'

    if (!invoice && !charge) return <Spinner />

    type = Constants.getTransactionType(charge ? charge : invoice)
    if (invoice) {
      showResend =
        invoice.status !== 'draft' &&
        invoice.collection_method &&
        invoice.collection_method === 'send_invoice' &&
        invoice.status !== 'void'
      showClose = invoice.status === 'open'
      showCancel = invoice.status === 'draft'
      showFinalize = invoice.status === 'draft'
      showPayNow = invoice.status === 'open'
      showMarkPaid = invoice.status === 'open'
      showSplit = showMarkPaid && showPayNow
      // manually add percentage based line items if its a preview

      invoice = addFeesToInvoice(
        invoice,
        this.props.activeCommunity.community_preference,
        invoice.customer
      )
      let orderType = 'Once'
      if (invoice.subscription) {
        orderType = 'Recurring'
      }
      if (invoice.billing === 'charge_automatically') {
        type = `${orderType} - Autopay`
      } else if (invoice.billing === 'send_invoice') {
        type = `${orderType} - Emailed`
      }
    }
    if (charge) {
      // total = charge.amount
      closed = Boolean(charge.paid && !charge.refunded)
      if (!invoice && !charge.invoice) {
        type = 'One-off Charge'
      } else if (charge.invoice && !invoice) {
        type = 'Transaction for Invoice'
        dashboardLinkToInvoice = `/billing/invoices/${
          typeof charge.invoice === 'string'
            ? charge.invoice
            : charge.invoice.id
        }`
      }
    }
    showRefundButton = Boolean(
      closed && charge && !charge.refunded && charge.paid
    )
    showResumeButton = Boolean(invoice && invoice.status !== 'draft')
    const invoiceUrl =
      (charge && charge.invoice && charge.invoice.hosted_invoice_url) ||
      (invoice && invoice.hosted_invoice_url)
    const invoicePDFUrl =
      (charge && charge.invoice && charge.invoice.invoice_pdf) ||
      (invoice && invoice.invoice_pdf)
    const currencySymbol = Constants.getCurrencySymbolFromName(
      charge ? charge.currency : invoice.currency
    )
    // for testing!
    // showCancel = true
    // showPayNow = true
    // showMarkPaid = true
    // showRefundButton = true
    const hasButton =
      (showResend && showPayNow) ||
      (showResend && showRefundButton) ||
      showSplit ||
      showMarkPaid ||
      showClose ||
      showResumeButton ||
      showCancel
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-12">
            <div className="card-block">
              {this.props.isFetching ? (
                <Spinner />
              ) : (
                <div className="row">
                  {membershipPaused ? (
                    <div className="col-12 col-md-8 offset-md-2 my-3">
                      <h6>
                        {`This membership is paused. ${
                          membershipResumeAt ? 'Resumes on ' : ''
                        }${
                          membershipResumeAt
                            ? moment
                                .unix(membershipResumeAt)
                                .format('MM/DD/YYYY')
                            : ''
                        }`}
                      </h6>
                    </div>
                  ) : null}
                  <div className="col-sm-12 col-md-8 offset-md-2">
                    <div className="row">
                      <div className="col-12 card pt-3 pb-3">
                        <Row>
                          <div className="col-12 col-lg-8 d-flex flex-column justify-content-start">
                            <div className="d-flex align-items-center">
                              {this.renderInvoiceSummary()}
                            </div>
                            {Constants.renderInvoiceStatusBadge(
                              charge ? charge : invoice,
                              'medium',
                              currencySymbol
                            )}
                          </div>
                          <div className="col-12 col-lg-4 d-flex justify-content-end align-items-start">
                            {showFinalize && (
                              <Button
                                color="primary"
                                onClick={this.toggleFinalizeInvoiceModal}
                              >
                                <FontAwesomeIcon icon="paper-plane" /> Send
                              </Button>
                            )}
                            {showPayNow && (
                              <Button
                                color="primary"
                                onClick={this.togglePayNowModal}
                              >
                                <FontAwesomeIcon icon="dollar-sign" /> Charge
                                Now
                              </Button>
                            )}
                            {showRefundButton && (
                              <Button
                                color="primary"
                                onClick={this.toggleRefundModal}
                              >
                                <FontAwesomeIcon icon="arrow-left" /> Refund
                              </Button>
                            )}
                            {!showPayNow && !showRefundButton && showResend && (
                              <Button
                                color="primary"
                                onClick={this.toggleResendInvoiceModal}
                              >
                                <FontAwesomeIcon icon={'paper-plane'} /> Resend
                              </Button>
                            )}
                            {!preview && hasButton && (
                              <ButtonDropdownStyled
                                isOpen={this.state.actionsDropdown}
                                toggle={() => this.toggleActionsDropdown()}
                              >
                                {/*<Button className='btn btn-primary' >New</ButtonDropdown>*/}
                                <DropdownToggle color="outline-primary" caret>
                                  Actions
                                </DropdownToggle>
                                <DropdownMenuStyled>
                                  {showMarkPaid && (
                                    <DropdownItem
                                      onClick={this.toggleMarkPaidModal}
                                    >
                                      <FontAwesomeIcon icon="check" /> Mark as
                                      Paid
                                    </DropdownItem>
                                  )}
                                  {showResend && (
                                    <DropdownItem
                                      onClick={this.toggleResendInvoiceModal}
                                    >
                                      <FontAwesomeIcon icon={'paper-plane'} />{' '}
                                      Resend
                                    </DropdownItem>
                                  )}
                                  {showSplit && (
                                    <DropdownItem
                                      onClick={this.toggleSplitInvoiceModal}
                                    >
                                      <FontAwesomeIcon icon="page-break" />{' '}
                                      Split
                                    </DropdownItem>
                                  )}
                                  {showResumeButton && (
                                    <DropdownItem
                                      onClick={this.toggleResumeModal}
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          invoice.auto_advance
                                            ? 'pause'
                                            : 'play'
                                        }
                                      />{' '}
                                      {invoice.auto_advance
                                        ? 'Pause'
                                        : 'Resume'}
                                    </DropdownItem>
                                  )}
                                  {showClose && (
                                    <DropdownItem
                                      className="danger"
                                      onClick={this.toggleVoidInvoiceModal}
                                    >
                                      <FontAwesomeIcon icon="times" /> Close
                                    </DropdownItem>
                                  )}
                                  {showCancel && (
                                    <Tooltip
                                      header={'Cancel Invoice'}
                                      tooltipContent={
                                        invoice.subscription
                                          ? 'You cannot cancel invoices that were created automatically by memberships!'
                                          : 'Cancel this invoice and stop it from being collected. This is irreversible.'
                                      }
                                    >
                                      <DropdownItem
                                        id={'cancelButton'}
                                        className="danger"
                                        onClick={this.toggleDeleteInvoiceModal}
                                        disabled={invoice.subscription}
                                      >
                                        <FontAwesomeIcon icon="times" /> Cancel
                                      </DropdownItem>
                                    </Tooltip>
                                  )}
                                </DropdownMenuStyled>
                              </ButtonDropdownStyled>
                            )}
                          </div>

                          {this.renderToRow()}
                          {/* {team ?  : this.renderGuestRow()}
                              ? this.renderTeamName()
                              : this.renderCustomerEmail(charge, invoice)} */}
                          <div className="col-12 mt-2 d-flex justify-content-end align-items-center">
                            {/* {!preview && (
                              <Row>
                                <hr />
                              </Row>
                            )} */}
                          </div>
                          {charge &&
                            charge.status === 'failed' &&
                            charge.failure_message && (
                              <div className="col-md-12">
                                <h4>
                                  Reason{' '}
                                  <small>
                                    <i>{charge.failure_message}</i>
                                  </small>
                                </h4>
                              </div>
                            )}
                        </Row>
                      </div>
                    </div>
                    <div className="row card">
                      <div className="col-12">
                        <Row className="d-flex align-items-center justify-content-end mt-2">
                          {charge && charge.receipt_url && (
                            <div className="col-12 col-md-3 d-flex align-items-center justify-content-end">
                              <TextButton
                                className="m-0"
                                onClick={() =>
                                  this.openHostedUrl(charge.receipt_url)
                                }
                              >
                                <FontAwesomeIcon icon={'cloud-download-alt'} />{' '}
                                Receipt
                              </TextButton>
                            </div>
                          )}
                          <div className="col-12 col-md-3 d-flex align-items-center justify-content-end">
                            {invoiceUrl && (
                              <TextButton
                                className="m-0"
                                onClick={() => this.openHostedUrl(invoiceUrl)}
                              >
                                <FontAwesomeIcon icon={'external-link-alt'} />{' '}
                                Invoice Link
                              </TextButton>
                            )}
                          </div>
                          <div className="col-12 col-md-2 d-flex align-items-center justify-content-end">
                            {invoicePDFUrl && (
                              <TextButton
                                className="m-0"
                                onClick={() =>
                                  this.openHostedUrl(invoicePDFUrl)
                                }
                              >
                                <FontAwesomeIcon icon={'cloud-download-alt'} />{' '}
                                PDF
                              </TextButton>
                            )}
                          </div>
                        </Row>
                      </div>
                      <div className="col-12">
                        <hr />
                        <Row className="mb-1">
                          <div className="col-6 col-md-3">
                            <LabelStyled>Type</LabelStyled>
                          </div>
                          {dashboardLinkToInvoice ? (
                            <div className="col-6 col-md-9">
                              <TextButton
                                className="m-0 p-0"
                                onClick={() =>
                                  this.props.history.push(
                                    dashboardLinkToInvoice
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={'external-link-alt'} />{' '}
                                {type}
                              </TextButton>
                            </div>
                          ) : (
                            <div className="col-6 col-md-9">{type}</div>
                          )}
                        </Row>
                        {!preview && (
                          <Row className="mb-1">
                            <div className="col-6 col-md-3  ">
                              <LabelStyled>Id</LabelStyled>
                            </div>
                            <div className="col-6 col-md-9 text-truncate">
                              {invoice ? invoice.id : charge.id}
                            </div>
                          </Row>
                        )}
                        {!preview && invoice && this.getCreatedBy(invoice)}
                        {this.renderInvoiceDates(
                          charge,
                          invoice,
                          this.state.team,
                          preview
                        )}
                        {!preview && (
                          <Row className="mb-1">
                            <div className="col-6 col-md-3">
                              <LabelStyled>Internal Notes </LabelStyled>
                            </div>
                            <div className="col-6 col-md-9">
                              {this.renderNotes(invoice ? invoice : charge)}
                            </div>
                          </Row>
                        )}
                        {!preview && charge && (
                          <Row>
                            <Col xs={12}>
                              <LabelStyled>Payment Source</LabelStyled>
                            </Col>
                            <div className="col-md-6 col-12">
                              <Row>
                                <PaymentSourceComponent
                                  isLoadingSource={false}
                                  toggleVerifyBankModal={null}
                                  defaultSourceObject={this.getSourceFromCharge(
                                    charge ? charge : invoice
                                  )}
                                />
                              </Row>
                            </div>
                            <br />
                          </Row>
                        )}
                      </div>
                      <div className="col-12">
                        <hr />
                      </div>
                      <div className="col-12 my-2">
                        Invoice Items{' '}
                        {invoice && invoice.period_start ? (
                          <span className="text-muted">
                            for{' '}
                            {renderDateTimeStamp(invoice.period_start, false)}{' '}
                            to {renderDateTimeStamp(invoice.period_end, false)}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-12">
                        <InvoiceHeaderRowStyled className="row">
                          <div className="col-6 my-2">Description</div>
                          <div className="col-2 my-2">Quantity</div>
                          <div className="col-2 my-2">Unit Price</div>
                          <div className="col-2 d-flex justify-content-end align-items-center">
                            Amount
                          </div>
                        </InvoiceHeaderRowStyled>
                      </div>
                      <div className="col-12 my-2">
                        {invoice ||
                        (charge &&
                          charge.invoice &&
                          typeof charge.invoice === 'object') ? (
                          this.renderLineItems(invoice)
                        ) : (
                          <div className="row">
                            <div className="col-7">
                              {(charge && charge.description) ||
                                (invoice && invoice.description)}
                            </div>
                            <div className="col-1">
                              {(charge &&
                                Number(
                                  charge.quantity ? charge.quantity : 1
                                )) ||
                                (invoice && Number(invoice.quantity))}
                            </div>
                            <div className="col-2 d-flex justify-content-end">
                              {currencySymbol}
                              {(charge && Number(charge.amount).toFixed(2)) ||
                                (invoice && Number(invoice.amount).toFixed(2))}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        {invoice &&
                          invoice.discount &&
                          invoice.discount.coupon &&
                          invoice.discount.coupon.valid && (
                            <div className="row">
                              <div className="col-12 pr-2">
                                <hr />
                                <div className="row">
                                  <div className="col-1 text-muted">
                                    discount{' '}
                                  </div>
                                  <div className="col-7 d-flex align-items-center justify-content-end">
                                    <p className="h6">
                                      <p
                                        className={
                                          invoice.lines.data.filter(
                                            item => item.proration
                                          ).length === 0
                                            ? ''
                                            : 'text-muted'
                                        }
                                      >
                                        {invoice.discount.coupon.name
                                          ? invoice.discount.coupon.name
                                          : null}
                                      </p>
                                    </p>
                                  </div>
                                  <div className="col-4  d-flex align-items-center justify-content-end">
                                    <p className="h6">
                                      {invoice.lines.data.filter(
                                        item => item.proration
                                      ).length === 0 ? (
                                        <p>
                                          -{currencySymbol}
                                          {Number(
                                            invoice.discount.coupon.amount_off /
                                              100
                                          ).toFixed(2)}
                                        </p>
                                      ) : (
                                        <p className="text-muted">
                                          -{currencySymbol}
                                          {Number(
                                            invoice.discount.coupon.amount_off /
                                              100
                                          ).toFixed(2)}
                                        </p>
                                      )}{' '}
                                    </p>
                                  </div>
                                </div>
                                {invoice.lines.data.filter(
                                  item => item.proration
                                ).length > 0 ? (
                                  <div className="col-12 d-flex align-items-center justify-content-end">
                                    <div className="text-muted">
                                      Discounts are not applied to prorated
                                      invoices
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <hr />
                        <div className="row">
                          <div className="col">
                            <p className="h2 d-flex justify-content-end align-items-center">
                              <strong>Total</strong>
                            </p>
                          </div>
                          <div className="col  d-flex justify-content-end align-items-center">
                            <p className="h2">
                              <strong>
                                {currencySymbol}
                                {Number(
                                  invoice
                                    ? invoice.amount_due / 100
                                    : charge.amount
                                ).toFixed(2)}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {invoice.metadata && invoice.metadata.has_split_invoices
                      ? this.renderSplitInvoiceLinks(invoice.metadata)
                      : null}
                    {charge &&
                      charge.refunds &&
                      charge.refunds.data &&
                      charge.refunds.data.length > 0 && (
                        <RefundsCard
                          className="row card card-block"
                          charge={charge}
                        />
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {PayNowModal({
          showPayNowModal: this.state.showPayNowModal,
          togglePayNowModal: this.togglePayNowModal,
          renderNotesFields: this.renderNotesFields,
          isFetching: this.props.isFetching,
          customer: this.state.customer,
          team: this.state.team,
          match: this.props.match,
          history: this.props.history,
          hostedInvoiceUrl: invoiceUrl,
          payInvoice: val => this.payInvoice(val),
        })}
        {MarkPaidModal({
          showMarkPaidModal: this.state.showMarkPaidModal,
          toggleMarkPaidModal: this.toggleMarkPaidModal,
          renderNotesFields: this.renderNotesFields,
          isFetching: this.props.isFetching,
          payInvoice: val => this.payInvoice(val),
        })}
        {FinalizeInvoiceModal({
          showFinalizeInvoiceModal: this.state.showFinalizeInvoiceModal,
          toggleFinalizeInvoiceModal: this.toggleFinalizeInvoiceModal,
          isFetching: this.props.isFetching,
          renderNotesFields: this.renderNotesFields,
          finalizeInvoice: this.finalizeInvoice,
        })}
        {this.state.showRefundModal && (
          <RefundModal
            showRefundModal={this.state.showRefundModal}
            toggleRefundModal={this.toggleRefundModal}
            renderNotesFields={this.renderNotesFields}
            isFetching={this.props.isFetching}
            issueRefund={this.issueRefund}
            currencySymbol={Constants.getCurrencySymbolFromName(
              charge.currency
            )}
            charge={charge}
            invoice={invoice}
          />
        )}
        <VoidInvoiceModal
          showVoidInvoiceModal={this.state.showVoidInvoiceModal}
          toggleVoidInvoiceModal={this.toggleVoidInvoiceModal}
          renderNotesFields={this.renderNotesFields}
          isFetching={this.props.isFetching}
          voidInvoice={this.voidInvoice}
          invoice={invoice}
        />
        <GenericModal
          open={this.state.showResumeModal}
          toggle={this.toggleResumeModal}
          isFetching={this.props.isFetching}
          onCancel={this.toggleResumeModal}
          onConfirm={this.resumeInvoice}
          header={`${invoice.auto_advance ? 'Pause' : 'Resume'} Invoice`}
          body={this.getResumeModalText(invoice)}
          confirmLabel={invoice.auto_advance ? 'Pause' : 'Resume'}
        />
        <DeleteInvoiceModal
          showDeleteInvoiceModal={this.state.showDeleteInvoiceModal}
          toggleDeleteInvoiceModal={this.toggleDeleteInvoiceModal}
          isFetching={this.props.isFetching}
          deleteInvoice={this.deleteInvoice}
          invoice={invoice}
        />
        <SplitInvoiceModal
          showSplitInvoiceModal={this.state.showSplitInvoiceModal}
          toggleSplitInvoiceModal={this.toggleSplitInvoiceModal}
          isFetching={this.props.isFetching}
          splitInvoice={this.splitInvoice}
          invoice={invoice}
          currencySymbol={currencySymbol}
        />
        {ResendInvoiceModal({
          showResendInvoiceModal: this.state.showResendInvoiceModal,
          toggleResendInvoiceModal: this.toggleResendInvoiceModal,
          isFetching: this.props.isFetching,
          resendInvoice: this.resendInvoice,
        })}
        {EditNotesModal({
          showEditNotesModal: this.state.showEditNotesModal,
          toggleEditNotesModal: this.toggleEditNotesModal,
          renderNotesFields: this.renderNotesFields,
          isFetching: this.props.isFetching,
          saveNotes: this.saveNotes,
        })}
      </div>
    )
  }
}

InvoiceDetail.displayName = 'InvoiceDetail'

function mapStateToProps(state, ownProps) {
  let invoice,
    objectId,
    charge,
    team = null

  // prefer invoice,charge,team from props of component
  if (ownProps.invoice) {
    invoice = ownProps.invoice
    if (invoice && typeof invoice.charge === 'object') {
      charge = invoice.charge
    }
  }
  if (ownProps.charge) charge = ownProps.charge
  if (ownProps.team) team = ownProps.team

  if (charge && charge.metadata && charge.metadata.team_id) {
    const teamLookup = Constants.pluckObjectByID(
      state.teams.teams,
      charge.metadata.team_id
    )
    if (teamLookup) team = teamLookup
  }

  // Pluck invoice,charge,team from history state if not passed as prop
  if (
    ownProps.history &&
    ownProps.history.location &&
    ownProps.history.location.state
  ) {
    const locationState = ownProps.history.location.state
    if (locationState.team && !team) {
      // this should always have a team.
      team = locationState.team
    }
    if (locationState.invoice && !invoice) {
      invoice = locationState.invoice
      if (invoice.charge && typeof invoice.charge === 'object') {
        charge = invoice.charge
        charge = JSON.parse(JSON.stringify(charge))
        charge.amount = Number(charge.amount / 100).toFixed(2)
      }
    }
    if (locationState.charge && !charge) {
      charge = locationState.charge
      charge = JSON.parse(JSON.stringify(charge))
      charge.amount = Number(charge.amount / 100).toFixed(2)
    }
  }

  // pull the invoice from the URL
  if (
    ownProps.match &&
    ownProps.match.params &&
    ownProps.match.params.objectId
  ) {
    objectId = ownProps.match.params.objectId
  }
  return {
    user: state.user,
    activeCommunity: state.ui.activeCommunity,
    teams: state.teams.teams,
    isFetching: state.ui.isFetching,
    isFetchingTeams: resolveFetchingStatus(state, FETCHING_TEAMS),
    invoice,
    objectId,
    team,
    charge,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    stripe_actions: bindActionCreators(stripeActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail)
