import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { extractCoworksErrorObject } from '@global/ErrorFactory'
import * as planActions from '@reduxActions/planActions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as Yup from 'yup'
import _PlanFormModal from '../../components/Templates/Forms/_PlanFormModal'
import * as uiSelectors from '@reduxSelectors/uiSelectors'

const ModalStyled = styled(Modal)`
  .custom-styles-modal {
    width: 700px;
  }
`

const DayPassSettings = ({
  open,
  toggle,
  plan_actions,
  isFetching,
  dayPass,
  activeCampus,
  activeCommunity,
}) => {
  const submit = info => {
    const planObj = {
      campus_id: activeCampus.id,
      community_id: activeCommunity.id,
      currency_units: activeCommunity.community_preference.currency,
      frequency: 'once',
      name: info.name,
      type: 'DayPass',
      is_public: true,
      date_required: info.date_required,
      cost: info.price,
      description: info.description,
    }
    if (!(info.photo instanceof Object)) {
      planObj.plan_photo = info.photo
    }
    try {
      if (dayPass && dayPass.id) {
        plan_actions
          .updateDayPass(Object.assign({ id: dayPass.id }, planObj))
          .then(() => success('Updated day pass'))
      } else {
        plan_actions
          .createDayPass(planObj)
          .then(() => success('Created day pass'))
      }
    } catch (err) {
      toast.error(extractCoworksErrorObject(err))
    }
  }

  const success = msg => {
    toast.success(msg)
    toggle()
  }

  const getInitialValues = ({
    name,
    cost,
    description = '',
    plan_photo,
    date_required = false,
  }) => ({
    photo: plan_photo,
    price: cost,
    name,
    description,
    date_required,
  })

  return (
    <ModalStyled
      isOpen={open}
      toggle={toggle}
      className="modal-primary fade in"
      contentClassName="custom-styles-modal"
    >
      <ModalHeader toggle={toggle}>Day Pass Settings</ModalHeader>
      <ModalBody>
        <_PlanFormModal
          initialValues={getInitialValues({ ...dayPass })}
          schema={Yup.object().shape({
            name: Yup.string().required('name is required'),
            price: Yup.string().required('price is required'),
            description: Yup.string().required('description is required'),
            date_required: Yup.boolean(),
            photo: Yup.object().nullable(),
          })}
          loading={isFetching}
          onSubmit={submit}
          toggle={toggle}
        />
      </ModalBody>
    </ModalStyled>
  )
}

DayPassSettings.displayName = 'DayPassSettings'

DayPassSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  dayPass: PropTypes.object,
  isFetching: PropTypes.bool,
  plan_actions: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    dayPass: uiSelectors.getDayPass(state),
    isFetching: state.ui.isFetching,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    plan_actions: bindActionCreators(planActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DayPassSettings)
