import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from 'reactstrap'
import { LabelStyled } from '@global/Form/FormComponents'

export default function RemovePlanModal({
  confirmRemovePlanClickedModal,
  toggleRemovePlanModal,
  showRemovePlanModal,
  planObjectToRemove,
  quantityToRemove,
  handleQuantityToRemoveChange,
}) {
  return (
    <Modal
      isOpen={showRemovePlanModal}
      toggle={toggleRemovePlanModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleRemovePlanModal}>
        Remove Plan Confirmation
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <p>How many would you like to remove?</p>
            </div>
          </div>
          {handleQuantityToRemoveChange ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col-2 d-flex align-items-center">
                  <LabelStyled>Qty:</LabelStyled>
                </div>
                <div className="col-4">
                  <Input
                    type="text"
                    name="quantity"
                    className="w-100"
                    placeholder="quantity"
                    onChange={event => {
                      const newQuantity = Number(event.target.value)
                      if (
                        (!isNaN(newQuantity) &&
                          newQuantity >= 0 &&
                          newQuantity <= planObjectToRemove.uiState.quantity) ||
                        !event.target.value
                      ) {
                        handleQuantityToRemoveChange(event)
                      }
                    }}
                    value={quantityToRemove}
                  />
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center text-muted">
                  {planObjectToRemove.uiState.quantity} available
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleRemovePlanModal}>
          Nevermind
        </Button>
        <Button
          color="danger"
          disabled={
            !quantityToRemove ||
            quantityToRemove === '' ||
            quantityToRemove <= 0 ||
            quantityToRemove > planObjectToRemove.plan.quantity
          }
          onClick={confirmRemovePlanClickedModal}
        >
          Remove {`${quantityToRemove > 0 ? quantityToRemove : ''}`} Plan
          {`${quantityToRemove > 1 ? 's' : ''}`}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

RemovePlanModal.propTypes = {
  planObjectToRemove: PropTypes.object.isRequired,
  quantityToRemove: PropTypes.number.isRequired,
  showRemovePlanModal: PropTypes.bool.isRequired,
  toggleRemovePlanModal: PropTypes.func.isRequired,
  confirmRemovePlanClickedModal: PropTypes.func.isRequired,
  handleQuantityToRemoveChange: PropTypes.func.isRequired,
}

RemovePlanModal.displayName = 'RemovePlanModal'
