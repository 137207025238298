import styled from 'styled-components'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap'

function getButtonColor(props) {
  return props.buttonColor === 'secondary'
    ? props.theme.colorSecondary
    : props.theme.colorPrimary
}
function getButtonTextColor(props) {
  return props.buttonColor === 'secondary'
    ? props.theme.colorSecondaryText
    : props.theme.colorPrimaryText
}
function getButtonHoverColor(props) {
  return props.buttonColor === 'secondary'
    ? props.theme.colorSecondaryHover
    : props.theme.colorPrimaryHover
}

// export const ButtonStyled = styled(Button)`
//   color: ${props => getButtonTextColor(props)};
//   border-color: ${props => getButtonColor(props)};
//   &:hover {
//     color: ${props => getButtonTextColor(props)};
//     background-color: ${props => getButtonColor(props)};
//   }
// `

/* Buttons */
export const DropdownToggleOutlineStyled = styled(DropdownToggle)`
  background-color: transparent;
  color: ${props => getButtonColor(props)};
  border-color: ${props => getButtonColor(props)};
  &:hover {
    color: ${props => getButtonTextColor(props)};
    border-color: ${props => getButtonTextColor(props)};
    background-color: ${props => getButtonColor(props)};
  }
`
