/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import React, { useRef } from 'react'
import * as Constants from '../Constants'
import MyStatefulEditor from '../MyStatefulEditor'
import styled from 'styled-components'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import { ErrorMessage, useField } from 'formik'
import Switch from '@global/Base/Switch/Switch'
import { ColorLuminance } from '@global/Colors/ColorUtils'
import Anchor from '@global/Base/Anchor/Anchor'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import moment from 'moment'
import TimeDropdown from '@components/External/ExternalBooking/Filter/TimeDropdown'
import Checkbox from '@bit/coworks.base-components.checkbox'
import Toggle from '@bit/coworks.base-components.toggle'
import QuickSearch from '@global/Search/QuickSearch'
import Badge from '@global/Base/Badge/Badge'
import AvatarNamePlate from '@global/Avatar/AvatarNamePlate'
import Tooltip from '@bit/coworks.base-components.tooltip'

import SingleDatePicker from './SingleDatePicker'

import LabelStyled from './LabelStyled'
import FontAwesomeIconLabel from './FontAwesomeIconLabel'
import PhoneInputField from './PhoneInputField'

export { FontAwesomeIconLabel, LabelStyled, SingleDatePicker, PhoneInputField }

const TooltipIconStyled = styled(FontAwesomeIconLabel)`
  margin-left: 3px;
`
const LabelWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

export const InputStyled = styled.input`
  width: 100% !important;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: ${props => props.theme.colorInputText};
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  overflow: visible;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: auto;

  :focus {
    outline: 0;
    border-color: ${props =>
      props.primaryColor
        ? ColorLuminance(props.primaryColor, 0.4)
        : props.theme.colorInputFocusedBorder};
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
`

const Description = styled.div`
  color: #0000004d !important;
  font-size: 0.75rem;
  margin-bottom: 1rem;
`
export function formatDescription(description) {
  return description ? (
    <React.Fragment>
      <Description>{description}</Description>
    </React.Fragment>
  ) : null
}
export function formatValue(viewType, value) {
  switch (viewType) {
    case 'URL':
    case 'externalURL':
      const target = viewType === 'externalURL' ? '_blank' : undefined
      return (
        <React.Fragment>
          {viewType === 'externalURL' && value ? (
            <React.Fragment>
              <FontAwesomeIcon icon="external-link-alt" />{' '}
            </React.Fragment>
          ) : null}
          <Anchor href={value} target={target}>
            {value}
          </Anchor>
        </React.Fragment>
      )
    // case 'text':
    default:
      return value
  }
}

export const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  tooltipProps,
  ...props
}) => {
  function interceptChange(event) {
    // this will convert "", undefined, or null to null
    const value = event.target.value
    if (
      props.coerceEmptyStringToNull &&
      (!value || value === undefined || value === '')
    ) {
      setFieldValue(field.name, null)
    } else {
      setFieldValue(field.name, value)
    }
  }
  return (
    <div className="form-group">
      {!props.hideLabel && (
        <LabelWrapper>
          <LabelStyled isEditing={props.isEditing}>
            {props.label ? props.label : props.name}
          </LabelStyled>
          {tooltipProps && (
            <Tooltip {...tooltipProps}>
              <TooltipIconStyled
                isEditing={props.isEditing}
                icon="question-circle"
              />
            </Tooltip>
          )}
        </LabelWrapper>
      )}
      {formatDescription(props.description)}
      {!props.renderViewState && (
        <InputStyled
          placeholder={props.placeholder ? props.placeholder : ''}
          className={`form-control w-100 ${
            errors[field.name] && touched[field.name] && 'error'
          }`}
          type={props.type ? props.type : 'text'}
          {...field}
          {...props}
          onChange={interceptChange}
        />
      )}
      {props.renderViewState && (
        <h5>
          {formatValue(
            props.viewType ? props.viewType : 'text',
            field.value,
            props.handleURLClick
          )}
        </h5>
      )}
      <ErrorMessage name={field.name}>
        {msg => <div className="text-danger">{msg}</div>}
      </ErrorMessage>
    </div>
  )
}
export const CustomDatePickerComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { values, touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    {!props.hideLabel && (
      <React.Fragment>
        <LabelStyled isEditing={props.isEditing}>
          {props.label ? props.label : props.name}
        </LabelStyled>
        <br />
      </React.Fragment>
    )}
    {formatDescription(props.description)}

    {!props.renderViewState ? (
      <SingleDatePicker
        // disabled={Boolean(isLoading || isInactive)}
        numberOfMonths={1}
        className={`form-control w-100 ${
          errors[field.name] && touched[field.name] && 'error'
        }`}
        date={field.value}
        onDateChange={newDate => setFieldValue(field.name, newDate)}
        focused={values[`${field.name}focused`]}
        onFocusChange={({ focused }) =>
          setFieldValue(`${field.name}focused`, focused)
        }
        isOutsideRange={
          props.isOutsideRange ? props.isOutsideRange : () => false
        }
        showClearDate
        reopenPickerOnClearDate
        showDefaultInputIcon
        small
        placeholder={props.placeholder ? props.placeholder : ''}
      />
    ) : (
      <h5>
        {field.value
          ? field.value.format(
              props.format ? props.format : 'MMM DD, YY hh:mm a'
            )
          : 'none'}
      </h5>
    )}

    <ErrorMessage name={field.name}>
      {msg => <div className="text-danger">{msg}</div>}
    </ErrorMessage>
  </div>
)

export const CustomInputComponentWithHooks = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { error, touched } = meta
  return (
    <div className="form-group">
      {!props.hideLabel && (
        <LabelStyled isEditing={props.isEditing}>
          {label ? label : props.name}
        </LabelStyled>
      )}
      {formatDescription(props.description)}
      {props.inputPrefix}
      <InputStyled
        placeholder={props.placeholder ? props.placeholder : ''}
        className={` ${touched && error ? 'error' : ''}`}
        type={props.type ? props.type : 'text'}
        {...field}
        {...props}
      />
      {touched && error ? <div className="text-danger">{error}</div> : null}
    </div>
  )
}
export const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    {!props.hideLabel && (
      <LabelStyled isEditing={props.isEditing}>
        {props.label && props.label !== '' ? props.label : props.name}
      </LabelStyled>
    )}
    {formatDescription(props.description)}
    {!props.renderViewState && (
      <select
        id={props.id}
        className={`form-control w-100 ${
          errors[field.name] && touched[field.name] && 'error'
        }`}
        type="text"
        {...field}
        {...props}
      >
        {props.children}
      </select>
    )}
    {props.renderViewState && (
      <h5>
        {formatValue(props.viewType ? props.viewType : 'text', field.value)}
      </h5>
    )}
    <ErrorMessage name={field.name}>
      {msg => <div className="text-danger">{msg}</div>}
    </ErrorMessage>
  </div>
)
export const CustomPresetSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    {!props.hideLabel && (
      <LabelStyled isEditing={props.isEditing}>
        {props.label && props.label !== '' ? props.label : props.name}
      </LabelStyled>
    )}
    {formatDescription(props.description)}
    {!props.renderViewState && (
      <select
        type="text"
        {...field}
        {...props}
        className={`form-control w-100 ${
          errors[field.name] && touched[field.name] && 'error'
        }`}
      >
        {props.options ? props.options : null}
        {props.dropdownType &&
          props.dropdownType === 'currency' &&
          Constants.currencies.map(currency => (
            <option value={currency.name}>{currency.label}</option>
          ))}
        {props.dropdownType &&
          props.dropdownType === 'timezone' &&
          Constants.timezones.map(currency => (
            <option value={currency}>{currency}</option>
          ))}

        {props.dropdownType && props.dropdownType === 'country' && (
          <React.Fragment>
            <option value="">Select a country</option>
            {Constants.countries.map((country, index) => (
              <option key={index} value={country.code}>
                {country.name}
              </option>
            ))}
          </React.Fragment>
        )}
      </select>
    )}
    {props.renderViewState && (
      <h5>
        {formatValue(props.viewType ? props.viewType : 'text', field.value)}
      </h5>
    )}
    <ErrorMessage name={field.name}>
      {msg => <div className="text-danger">{msg}</div>}
    </ErrorMessage>
  </div>
)
export const CustomToggleComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <React.Fragment>
      {!props.hideLabel && (
        <LabelStyled isEditing={props.isEditing}>
          {props.label && props.label !== '' ? props.label : props.name}
        </LabelStyled>
      )}
      {formatDescription(props.description)}

      <div className="form-group">
        <Switch
          // name={field.name}
          {...field}
          {...props}
          checked={field.value}
          onChange={event => {
            if (props.interceptChange) {
              event.stopPropagation()
              props.interceptChange(field.name, field.value, setFieldValue)
            } else {
              setFieldValue(field.name, !field.value)
            }
          }}
          disabled={props.disabled || props.renderViewState}
          className={`form-control w-100 ${
            errors[field.name] && touched[field.name] && 'error'
          }`}
        />
      </div>
      <ErrorMessage name={field.name}>
        {msg => <div className="text-danger">{msg}</div>}
      </ErrorMessage>
    </React.Fragment>
  )
}

export const CustomRichTextComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    {!props.hideLabel && (
      <LabelStyled>{props.label ? props.label : props.name}</LabelStyled>
    )}
    {formatDescription(props.description)}
    <MyStatefulEditor
      onChange={content => setFieldValue(field.name, content)}
      content={values[field.name]}
      placeholder={props.placeholder ? props.placeholder : ''}
      className={`form-control w-100 ${
        errors[field.name] && touched[field.name] && 'error'
      }`}
    />
    <ErrorMessage name={field.name}>
      {msg => <div className="text-danger">{msg}</div>}
    </ErrorMessage>
  </div>
)
export const CustomImageUploader = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  let url = field.value
  if (field.value instanceof Object) {
    if (field.value && field.value.medium_file_url) {
      url = field.value.medium_file_url
    }
  }
  return (
    <div className="form-group">
      <fieldset disabled={props.disabled}>
        {!props.hideLabel && (
          <LabelStyled>{props.label ? props.label : props.name}</LabelStyled>
        )}
        {formatDescription(props.description)}
        <ImageUploadComponent
          ref={props.ref}
          file={values[field.name]}
          imageUrl={url}
          index={field.name}
          disabled={props.disabled}
          onUpload={image => {
            setFieldValue(field.name, image)
          }}
        />
        <ErrorMessage name={field.name}>
          {msg => <div className="text-danger">{msg}</div>}
        </ErrorMessage>
      </fieldset>
    </div>
  )
}

export const CustomTextAreaComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    {!props.hideLabel && (
      <LabelStyled isEditing={props.isEditing}>
        {props.label ? props.label : props.name}
      </LabelStyled>
    )}
    {formatDescription(props.description)}
    {!props.renderViewState && (
      <textarea
        placeholder={props.placeholder ? props.placeholder : ''}
        className={`form-control w-100 ${
          errors[field.name] && touched[field.name] && 'error'
        }`}
        {...field}
        {...props}
      />
    )}
    {props.renderViewState && <h5>{field.value}</h5>}
    <ErrorMessage name={field.name}>
      {msg => <div className="text-danger">{msg}</div>}
    </ErrorMessage>
  </div>
)

const HoursOfOperationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`
const HoursOfOperationRow = styled.div`
  display: flex !important;
  justify-content: space-between;
`
// for some reason, the api doesn't like to store the values
// in the order that I created them so we have to organize here
const HoursOfOperationItem = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`
// for some reason, the api doesn't like to store the values
// in the order that I created them so we have to organize here

export const HoursOfOperation = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props.name)
  const { value, error, touched } = meta
  const { setValue, setError, setTouched } = helpers
  const isEditing = props.isEditing

  function isOpen(dayOfWeek) {
    // if open is greater than 0 or if open = 0 and close is set
    return Boolean(
      value[dayOfWeek].length > 0 &&
        (value[dayOfWeek][0].open > 0 ||
          (value[dayOfWeek][0].open >= 0 && value[dayOfWeek][0].close > 0))
    )
  }
  function formatDay(dayOfWeek) {
    return (
      <HoursOfOperationRow>
        <HoursOfOperationItem>
          {`${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}`}
        </HoursOfOperationItem>
        <HoursOfOperationItem>
          {isOpen(dayOfWeek) ? (
            <React.Fragment>
              {isAllDay(dayOfWeek)
                ? 'open all day'
                : `${formattedTime(value[dayOfWeek][0].open)} - ${formattedTime(
                    value[dayOfWeek][0].close
                  )}`}
            </React.Fragment>
          ) : (
            'closed'
          )}
        </HoursOfOperationItem>
      </HoursOfOperationRow>
    )
  }
  function handleSwitchChange(dayOfWeek) {
    if (isOpen(dayOfWeek)) {
      // the day is currently open and needs to be set as CLOSED
      handleHoursChange({
        dayOfWeek,
        periods: [
          { name: 'open', valueInSeconds: 0 },
          { name: 'close', valueInSeconds: 0 },
        ],
        businessHoursObject: value,
      })
    } else {
      // if the day needs is closed and needs to be set as OPEN
      // we need to reset both days to 0
      handleHoursChange({
        dayOfWeek,
        periods: [
          { name: 'open', valueInSeconds: 9 * 60 * 60 },
          { name: 'close', valueInSeconds: 17 * 60 * 60 },
        ],
        businessHoursObject: value,
      })
    }
  }
  function handleAllDayToggle(dayOfWeek) {
    if (
      !(
        value[dayOfWeek][0].open === 0 &&
        value[dayOfWeek][0].close === 24 * 60 * 60
      )
    ) {
      handleHoursChange({
        dayOfWeek,
        periods: [
          { name: 'open', valueInSeconds: 0 },
          { name: 'close', valueInSeconds: 24 * 60 * 60 },
        ],
        businessHoursObject: value,
      })
    } else {
      handleHoursChange({
        dayOfWeek,
        periods: [
          { name: 'open', valueInSeconds: 9 * 60 * 60 },
          { name: 'close', valueInSeconds: 17 * 60 * 60 },
        ],
        businessHoursObject: value,
      })
    }
  }
  function isAllDay(dayOfWeek) {
    return Boolean(
      value[dayOfWeek][0].open === 0 &&
        value[dayOfWeek][0].close === 24 * 60 * 60
    )
  }

  function timePickers(dayOfWeek) {
    return (
      <HoursOfOperationRow>
        <HoursOfOperationItem className="col-3">
          <Toggle
            onChange={() => handleSwitchChange(dayOfWeek)}
            checked={isOpen(dayOfWeek)}
            disabled={false}
            label={dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}
          />
        </HoursOfOperationItem>
        <HoursOfOperationItem>
          <HoursOfOperationRow>
            {!isOpen(dayOfWeek) ? (
              <HoursOfOperationItem>closed</HoursOfOperationItem>
            ) : (
              <React.Fragment>
                <HoursOfOperationItem className="mr-1 d-flex align-items-center">
                  <Checkbox
                    disabled={false}
                    selected={isAllDay(dayOfWeek)}
                    label={'Open All Day'}
                    handleChange={() => handleAllDayToggle(dayOfWeek)}
                  />
                </HoursOfOperationItem>
                {!isAllDay(dayOfWeek) && (
                  <HoursOfOperationItem>
                    <TimeDropdown
                      minInterval={Constants.EXTERNAL_BOOKING_TIME_INTERVAL}
                      timeFormat="h:mm A"
                      value={convertSecondsToMoment(value[dayOfWeek][0].open)}
                      returnAsDate
                      onChange={time =>
                        handleHoursChange({
                          dayOfWeek,
                          periods: [
                            {
                              name: 'open',
                              valueInSeconds: time.diff(
                                time.clone().startOf('day'),
                                'seconds'
                              ),
                            },
                          ],
                          businessHoursObject: value,
                        })
                      }
                    />
                    {'     '}-{'     '}
                    <TimeDropdown
                      minInterval={Constants.EXTERNAL_BOOKING_TIME_INTERVAL}
                      timeFormat="h:mm A"
                      value={convertSecondsToMoment(value[dayOfWeek][0].close)}
                      returnAsDate
                      onChange={time =>
                        handleHoursChange({
                          dayOfWeek,
                          periods: [
                            {
                              name: 'close',
                              valueInSeconds: time.diff(
                                time.clone().startOf('day'),
                                'seconds'
                              ),
                            },
                          ],
                          businessHoursObject: value,
                        })
                      }
                    />
                  </HoursOfOperationItem>
                )}
              </React.Fragment>
            )}
          </HoursOfOperationRow>
        </HoursOfOperationItem>
      </HoursOfOperationRow>
    )
  }

  function handleHoursChange({
    dayOfWeek = 'monday', // monday, tuesday...
    periods = [{ name: 'open', valueInSeconds: 0 }], // [{period: 'open', valueInSeconds: 0}] // 46800 = 1pm
    businessHoursObject, // [monday: {open, close}] ...
  }) {
    const businessHoursObjectCopy = JSON.parse(
      JSON.stringify(businessHoursObject)
    )
    // set multiple at a time
    // useful for resetting both to 0
    for (let index = 0; index < periods.length; index++) {
      const periodObject = periods[index]
      businessHoursObjectCopy[dayOfWeek][0][periodObject.name] =
        periodObject.valueInSeconds
    }
    setValue(businessHoursObjectCopy)
  }

  return (
    <HoursOfOperationContainer id={props.id}>
      {!props.hideLabel && (
        <LabelStyled isEditing={props.isEditing}>
          {label ? label : props.name}
        </LabelStyled>
      )}
      {isEditing ? (
        <React.Fragment>
          {timePickers('monday')}
          {timePickers('tuesday')}
          {timePickers('wednesday')}
          {timePickers('thursday')}
          {timePickers('friday')}
          {timePickers('saturday')}
          {timePickers('sunday')}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {formatDay('monday')}
          {formatDay('tuesday')}
          {formatDay('wednesday')}
          {formatDay('thursday')}
          {formatDay('friday')}
          {formatDay('saturday')}
          {formatDay('sunday')}
        </React.Fragment>
      )}
    </HoursOfOperationContainer>
  )
}

function convertSecondsToMoment(seconds = null) {
  if (seconds === null || seconds < 0) return null
  const helperDate = moment().startOf('day')
  helperDate.add(seconds, 'seconds')
  return helperDate
}
function formattedTime(seconds = null) {
  if (seconds === null || seconds < 0) return null
  return convertSecondsToMoment(seconds).format('h:mm a')
}
HoursOfOperation.displayName = 'HoursOfOperation'

export const CustomQuickSearchComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const type = props.type ? props.type.toLowerCase() : ''
  function handleSearchItemSelected(value) {
    setFieldValue(field.name, value)
  }

  function renderValues() {
    const value = field.value
    if (!props.isMulti) {
      return value ? (
        <div>
          <AvatarNamePlate
            object={value}
            onClick={props.onClick}
            objectType={type}
          />
        </div>
      ) : (
        <h5>none</h5>
      )
    }
    if (!value || value.length === 0) {
      return <div className="text-muted">None</div>
    }
    return value.map((object, index) =>
      props.renderWithBadge ? (
        <Badge className="mr-2">{object.label}</Badge>
      ) : (
        <div className="mr-2">{object.label}</div>
      )
    )
  }
  return (
    <div className="form-group">
      {!props.hideLabel && (
        <LabelStyled isEditing={props.isEditing}>
          {props.label ? props.label : props.name}
        </LabelStyled>
      )}
      {formatDescription(props.description)}
      {props.renderViewState ? (
        renderValues()
      ) : (
        <QuickSearch
          id={props.id}
          name={field.name}
          placeholder={props.placeholder ? props.placeholder : ''}
          showTitle={false}
          type={type}
          team={type === 'team' && field.value ? field.value : undefined}
          plan={type === 'plan' && field.value ? field.value : undefined}
          member={type === 'member' && field.value ? field.value : undefined}
          teams={props.teams}
          plans={props.plans}
          members={props.members}
          dataFilter={props.dataFilter}
          noOptionsMessage={props.noOptionsMessage}
          handleSearchItemCleared={handleSearchItemSelected}
          handleSearchItemSelected={handleSearchItemSelected}
        />
      )}
      <ErrorMessage name={field.name}>
        {msg => <div className="text-danger">{msg}</div>}
      </ErrorMessage>
    </div>
  )
}
