import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, Link } from 'react-router-dom'

import * as communityActions from '@reduxActions/communityActions'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'

import { logO, resolveDomainPrefix } from '@global/Constants'
import {
  resolveFetchingStatus,
  FETCHING_MIGRATION,
  FETCHING_SETTINGS,
} from '@global/Constants/FetchingConstants'

import * as Roles from '@app/config/roles'

import CampusContainer from './Campuses/CampusContainer'
import StripeStandardSetup from './Stripe/StripeStandardSetup'
import Migration from './Migration/Migration'
import CommunitySettings from './Settings/Settings'
import Integrations from './Integrations/Integrations'
import CoworksSubscription from './Billing/CoworksSubscription'
import WebsiteForms from './WebsiteForms/WebsiteForms'
import DynamicGroups from './DynamicGroups/DynamicGroups'

class Community extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    community_actions: PropTypes.object,
    activeCommunity: PropTypes.object,
    activeCampus: PropTypes.object,
    stripeAccount: PropTypes.object,
    coworksCustomer: PropTypes.object,
    isFetchingStripeOauthConnection: PropTypes.bool,
    isFetchingCoworksCustomer: PropTypes.bool,
    isFetchingMigration: PropTypes.bool,
    isFetchingSettings: PropTypes.bool,
    role: PropTypes.string,
    theme: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const {
      role,
      activeCommunity,
      history,
      isFetchingStripeOauthConnection,
    } = this.props

    // log these in localhost
    logO('Community Preference:', activeCommunity.community_preference)
    logO('Community:', activeCommunity)
  }

  toggleTab = tab => {
    const { match, activeCommunity, role, history } = this.props
    if (match.url !== `/community/${tab}`) {
      const newTab = `/community/${tab}`
      history.push(newTab)
    }
  }

  renderTab = ({ tabId, tabName }) => {
    const { history } = this.props
    return (
      <NavItem>
        <NavLink
          className={classnames({
            active: history.location.pathname.includes(tabId),
          })}
          onClick={() => {
            this.toggleTab(tabId)
          }}
        >
          {tabName ? tabName : tabId.charAt(0).toUpperCase() + tabId.slice(1)}
        </NavLink>
      </NavItem>
    )
  }

  isShowingDetail() {
    const { history } = this.props
    return history.location.pathname.includes('/community/campuses/')
  }
  wrapWithTabContent(component) {
    if (!this.isShowingDetail()) {
      return <TabContent>{component}</TabContent>
    }
    return component
  }

  render() {
    const { activeCommunity, role, match, location, history, user } = this.props
    const preference = activeCommunity.community_preference
    const billingAuthorised =
      role === 'Admin' || role === 'Manager' || role === 'SuperAdmin'
    const showMigrationsTab =
      preference.show_member_migration ||
      preference.show_booking_migration ||
      preference.show_plans_migration ||
      role === 'SuperAdmin'
    const showSettingsForm = true //role === 'SuperAdmin'
    const showCustomDropdownsTab =
      role === 'SuperAdmin' ||
      !process.env.NODE_ENV ||
      process.env.NODE_ENV === 'development' //role === 'SuperAdmin'

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-md-12 mb-4">
            {!this.isShowingDetail() && (
              <Nav tabs>
                <React.Fragment>
                  {showSettingsForm &&
                    this.renderTab({
                      tabId: 'settings',
                      tabName: 'Settings',
                    })}
                  {this.renderTab({
                    tabId: 'website',
                    tabName: 'Website Forms',
                  })}

                  {billingAuthorised
                    ? this.renderTab({
                        tabId: 'subscription',
                        tabName: 'Coworks Subscription',
                      })
                    : null}

                  {this.renderTab({
                    tabId: 'integrations',
                    tabName: 'Integrations',
                  })}
                  {this.renderTab({
                    tabId: 'stripe',
                  })}

                  {this.renderTab({
                    tabId: 'campuses',
                    tabName: 'Campuses',
                  })}

                  {showMigrationsTab &&
                    this.renderTab({
                      tabId: 'migrations',
                    })}
                  {showCustomDropdownsTab &&
                    this.renderTab({
                      tabId: 'groups',
                      tabName: 'Custom Dropdowns',
                    })}
                </React.Fragment>
              </Nav>
            )}

            {this.wrapWithTabContent(
              <Switch>
                <Route exact path={`/community`}>
                  <Redirect
                    to={{
                      pathname: `${match.path}/settings/branding`,
                      search: location.search,
                    }}
                  />
                </Route>
                <Route exact path={`${match.url}/settings`}>
                  <Redirect
                    to={{
                      pathname: `${match.path}/settings/branding`,
                      search: location.search,
                    }}
                  />
                </Route>
                <Route exact path={`${match.url}/website`}>
                  <TabPane>
                    <WebsiteForms {...this.props} />
                  </TabPane>
                </Route>

                <Route path={`${match.url}/campuses`}>
                  <CampusContainer user={user} history={history} />
                </Route>
                <Route path={`${match.url}/integrations`}>
                  <TabPane>
                    <Integrations
                      history={history}
                      match={match}
                      user={user}
                      theme={this.props.theme}
                      activeCommunity={this.props.activeCommunity}
                    />{' '}
                  </TabPane>
                </Route>
                {billingAuthorised && (
                  <Route path={`${match.url}/subscription`}>
                    <TabPane>
                      <CoworksSubscription
                        isFetching={this.props.isFetchingCoworksCustomer}
                        activeCommunity={this.props.activeCommunity}
                        coworksCustomer={this.props.coworksCustomer}
                      />{' '}
                    </TabPane>
                  </Route>
                )}
                <Route path={`${match.url}/stripe`}>
                  <TabPane>
                    <StripeStandardSetup
                      history={history}
                      activeCommunity={this.props.activeCommunity}
                      toggleTab={this.toggleTab}
                    />{' '}
                  </TabPane>
                </Route>

                {showMigrationsTab && (
                  <Route path={`${match.url}/migrations`}>
                    <TabPane>
                      <Migration
                        history={history}
                        activeCommunity={this.props.activeCommunity}
                        preference={
                          this.props.activeCommunity.community_preference
                        }
                        activeCampus={this.props.activeCampus}
                        community_actions={this.props.community_actions}
                        role={user.type}
                        isFetching={this.props.isFetchingMigration}
                        toggleTab={this.toggleTab}
                      />{' '}
                    </TabPane>
                  </Route>
                )}
                <Route path={`${match.url}/settings`}>
                  <TabPane>
                    <CommunitySettings
                      history={history}
                      match={match}
                      activeCommunity={this.props.activeCommunity}
                      user={user}
                      activeCampus={this.props.activeCampus}
                      community_actions={this.props.community_actions}
                      isFetching={this.props.isFetchingSettings}
                      toggleTab={this.toggleTab}
                    />
                  </TabPane>
                </Route>
                <Route path={`${match.url}/groups`}>
                  <TabPane>
                    <DynamicGroups
                      history={history}
                      match={match}
                      activeCommunity={this.props.activeCommunity}
                      user={user}
                      activeCampus={this.props.activeCampus}
                      community_actions={this.props.community_actions}
                      role={user.type}
                      isFetching={this.props.isFetchingSettings}
                      toggleTab={this.toggleTab}
                    />
                  </TabPane>
                </Route>
              </Switch>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Community.displayName = 'Community'

function mapStateToProps(state) {
  return {
    user: state.user,
    coworksCustomer: state.stripe.coworksCustomer,
    activeCommunity: state.ui.activeCommunity,
    activeCampus: state.ui.activeCampus,
    isFetchingMigration: resolveFetchingStatus(state, FETCHING_MIGRATION),
    isFetchingSettings: resolveFetchingStatus(state, FETCHING_SETTINGS),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    community_actions: bindActionCreators(communityActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Community)
