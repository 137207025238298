import React from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import classnames from 'classnames'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import Badge from '@global/Base/Badge/Badge'
import { convertStripeErrorToMessage } from '@global/ErrorFactory'
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import CreditCardForm from '@global/Stripe/CreditCardForm'
import BankAccountForm from '../Stripe/BankAccountForm'
import PaymentSourceComponent from '../Stripe/PaymentSourceComponent'
import Spinner from '../Spinner'

export default class BillingMethodModal extends React.Component {
  static propTypes = {
    showBillingMethodModal: PropTypes.bool,
    toggleBillingMethodModal: PropTypes.func,
    toggleVerifyBankModal: PropTypes.func,
    defaultSource: PropTypes.object,
    addCard: PropTypes.func,
    addBank: PropTypes.func,
    deleteSource: PropTypes.func,
    isLoadingSource: PropTypes.func.isRequired,
    currency: PropTypes.func.isRequired,
    country: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'card',
    }
  }
  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  removeToken = () => {
    const sourceID = this.props.defaultSource.id
    this.props.deleteSource(sourceID)
  }
  createBankToken = (stripe, bankData) => {
    return new Promise((resolve, reject) => {
      if (!bankData.account_holder_type) {
        // MUST STAY company!!
        // eslint-disable-next-line no-param-reassign
        bankData.account_holder_type = 'company'
      }
      stripe
        .createToken('bank_account', bankData)
        .then(response => {
          const token = response.token
          const error = response.error
          if (error) {
            toast.error(convertStripeErrorToMessage(error))
            reject(error)
          } else if (token) {
            this.props.addBank(token)
            resolve(token)
          } else {
            toast.error('Something went wrong!')
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  }
  getCardToken = (stripe, elements) => {
    const cardElement = elements.getElement(CardElement)
    stripe
      .createToken(cardElement)
      .then(response => {
        this.props.addCard(response.token)
      })
      .catch(err => {
        console.error(err)
        toast.error('Looks like there was a problem')
      })
  }

  render() {
    const {
      showBillingMethodModal,
      toggleBillingMethodModal,
      toggleVerifyBankModal,
      defaultSource,
      isLoadingSource,
      currency,
      country,
    } = this.props

    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Modal
            isOpen={showBillingMethodModal}
            toggle={toggleBillingMethodModal}
            className="modal-primary fade in"
          >
            <ModalHeader toggle={toggleBillingMethodModal}>
              Edit Default Billing Method
            </ModalHeader>
            {isLoadingSource ? (
              <Spinner />
            ) : (
              <ModalBody className="px-0">
                <div className="container-fluid">
                  {defaultSource && (
                    <div className="row border-bottom-1 mb-3">
                      <PaymentSourceComponent
                        isLoading={isLoadingSource}
                        toggleVerifyBankModal={toggleVerifyBankModal}
                        defaultSourceObject={defaultSource}
                      />
                      <div className="col d-flex align-items-center">
                        <TextButton color="danger" onClick={this.removeToken}>
                          Delete
                        </TextButton>
                      </div>
                    </div>
                  )}
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === 'card',
                        })}
                        onClick={() => {
                          this.toggleTab('card')
                        }}
                      >
                        Credit Card
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === 'bank',
                        })}
                        disabled={country.toLowerCase() !== 'us'}
                        onClick={() => {
                          this.toggleTab('bank')
                        }}
                      >
                        Bank {'  '}
                        <Badge
                          badgeShape="pill"
                          color={
                            country.toLowerCase() !== 'us'
                              ? 'default'
                              : 'primary'
                          }
                        >
                          US Only
                        </Badge>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={'card'}>
                      <div className="col">
                        <CreditCardForm stripe={stripe} elements={elements} />
                      </div>
                    </TabPane>
                    <TabPane tabId={'bank'}>
                      <div className="col">
                        <ElementsConsumer>
                          {({ stripe, elements }) => (
                            <BankAccountForm
                              currency={currency}
                              country={country}
                              toggleAddBankModal={() => {
                                toggleBillingMethodModal()
                              }}
                              createBankToken={(stripe, bankData) =>
                                this.createBankToken(stripe, bankData)
                              }
                              defaultSource={defaultSource}
                              onRef={ref => (this.bank = ref)}
                              stripe={stripe}
                              elements={elements}
                            />
                          )}
                        </ElementsConsumer>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </ModalBody>
            )}
            {this.state.activeTab === 'card' && (
              <ModalFooter className="pt-0">
                <Button
                  disabled={isLoadingSource}
                  color="secondary"
                  onClick={toggleBillingMethodModal}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoadingSource}
                  color="primary"
                  onClick={() => this.getCardToken(stripe, elements)}
                >
                  {defaultSource ? 'Replace' : 'Submit'}
                </Button>
              </ModalFooter>
            )}
          </Modal>
        )}
      </ElementsConsumer>
    )
  }
}

BillingMethodModal.displayName = 'BillingMethodModal'
