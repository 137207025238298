import * as apiService from '../services/apiService'
import * as uiActions from './uiActions'
import * as actionTypes from './actionTypes'

export function setPublicCommunity(community, campus) {
  return { type: actionTypes.SET_PUBLIC_COMMUNITY, community }
}
export function setPublicCampus(campus) {
  // don't need to set campus, it's setup to not need
  return { type: actionTypes.SET_PUBLIC_CAMPUS, campus }
}
export function setPublicEvent(event) {
  return { type: actionTypes.SET_PUBLIC_EVENT, event }
}
export const getPublicCommunityProfile = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .getPublicCommunityProfile(params)
      .then(response => {
        dispatch(setPublicCommunity(response.community))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const getPublicEvent = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .getPublicEvent(params)
      .then(response => {
        dispatch(uiActions.updateFetchingStatus(false))
        dispatch(setPublicEvent(response.community))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const sendRequest = object => dispatch => {
  return new Promise((resolve, reject) => {
    return apiService
      .scheduleTour(object)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getPublicPlans = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getPublicPlans(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const getPublicPlan = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getPublicPlan(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export function getPublicPlanHOOK(params, dispatch) {
  return new Promise((resolve, reject) => {
    apiService
      .getPublicPlan(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
