import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as stripeActions from '../../../reduxActions/stripeActions'
import defaultCompanyPic from '../../../img/placeholders/otto_black_white.jpeg'
import coworksLogo from '../../../img/global/coworksLogoSquared.png'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { convertStripeErrorToMessage } from '@global/ErrorFactory'

import { Button, Row, Collapse } from 'reactstrap'
import { TextButton } from '@global/Base/Button/ButtonStyled'

import { getCustomerDefaultSource } from '@global/Constants/PaymentConstants'
import { calculateSubscriptionTotal } from '@global/Constants'
import { LabelStyled } from '@global/Form/FormComponents'
import Spinner from '@global/Spinner'
import PaymentSourceComponent from '@global/Stripe/PaymentSourceComponent'
import BillingMethodModal from '@global/Modal/BillingMethodModal'
import VerifyBankModal from '@global/Modal/VerifyBankModal'
import { IntercomAPI } from 'react-intercom'
import RoundedImage from '@global/RoundedImage'

class CoworksSubscription extends React.Component {
  static propTypes = {
    coworksCustomer: PropTypes.object,
    isFetching: PropTypes.bool,
    activeCommunity: PropTypes.object,
    stripe_actions: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      showVerifyBankModal: false,
      showBillingMethodModal: false,
      isLoadingSource: false,
      isLoadingCustomer: true,
      isCollapseOpen: false,
    }
  }
  addBank = bankToken => {
    const self = this
    this.setState({
      isLoadingSource: true,
    })
    const params = {
      coworks_customer_id: this.props.coworksCustomer.id,
      bank_token: bankToken.id,
    }
    this.props.stripe_actions
      .addTokenToCustomer(params)
      .then(response => {
        self.setState({
          showBillingMethodModal: false,
          isLoadingSource: false,
        })
      })
      .catch(err => {
        self.setState({
          showBillingMethodModal: false,
          isLoadingSource: false,
        })
        toast.error(convertStripeErrorToMessage(err))
        console.error('Error', err)
      })
  }
  addCard = cardToken => {
    const self = this
    const params = {
      coworks_customer_id: this.props.coworksCustomer.id,
      card_token: cardToken.id,
    }
    this.setState({
      isLoadingSource: true,
    })
    this.props.stripe_actions
      .addTokenToCustomer(params)
      .then(response => {
        self.setState({
          showBillingMethodModal: false,
          isLoadingSource: false,
        })
      })
      .catch(err => {
        toast.error(convertStripeErrorToMessage(err))
        this.setState({
          isLoadingSource: false,
        })
        //todo toast?
        console.log(err)
      })
  }

  verifyBank = (params, customerId) => {
    const stripeParams = {
      amount1: params.amount1,
      amount2: params.amount2,
      bank_token: params.bankToken,
      customer_id: customerId,
    }
    this.setState({
      isLoadingSource: true,
    })
    this.props.stripe_actions
      .verifyBank(stripeParams)
      .then(response => {
        toast.success('Updated the bank account')
        this.setState({
          showBillingMethodModal: false,
          showVerifyBankModal: false,
          isLoadingSource: false,
        })
      })
      .catch(err => {
        this.setState({
          showVerifyBankModal: false,
          isLoadingSource: false,
        })
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  deleteSource = sourceID => {
    this.setState({
      isLoadingSource: true,
    })
    const params = {
      coworks_customer_id: this.props.coworksCustomer.id,
      source_id: sourceID,
    }
    const self = this
    this.props.stripe_actions
      .removeCustomerBillingSource(params)
      .then(response => {
        self.setState({
          showBillingMethodModal: false,
          isLoadingSource: false,
        })
      })
      .catch(err => {
        this.setState({
          isLoadingSource: false,
        })
        toast.error(convertStripeErrorToMessage(err))
        console.log(err)
      })
  }
  calculateMonthlyTotal(subscriptions) {
    let amount = 0
    subscriptions.forEach(subscription => {
      amount += calculateSubscriptionTotal(subscription)
    })

    return (amount / 100).toFixed(2)
  }
  toggleBillingMethodModal = () => {
    this.setState({
      showBillingMethodModal: !this.state.showBillingMethodModal,
    })
  }
  toggleVerifyBankModal = () => {
    this.setState({
      showVerifyBankModal: !this.state.showVerifyBankModal,
    })
  }
  filterSubscriptionTotal(stripeSubscription) {
    let amt = 0
    let qty = 0
    let discountString = ''
    for (
      let planCounter = 0;
      planCounter < stripeSubscription.items.data.length;
      planCounter++
    ) {
      const plan = stripeSubscription.items.data[planCounter].plan
      if (
        plan.nickname === 'Premium' ||
        plan.nickname === 'Essentials' ||
        plan.nickname === 'Enterprise'
      ) {
        amt +=
          stripeSubscription.items.data[planCounter].plan.amount *
          stripeSubscription.items.data[planCounter].quantity
        qty += stripeSubscription.items.data[planCounter].quantity
      }
    }
    if (stripeSubscription.discount && stripeSubscription.discount.coupon) {
      const coupon = stripeSubscription.discount.coupon
      discountString = `${coupon.name} `
      if (coupon.amount_off) {
        discountString += `$${coupon.amount_off / 100}`
        amt -= coupon.amount_off
      } else if (coupon.percent_off) {
        discountString += `${coupon.percent_off}%`
        amt -= amt * (coupon.percent_off / 100)
      }
    }
    return { amt, qty, discountString }
  }
  calculateBaseCoworksSubscriptionTotal(subscriptions) {
    let amount = 0
    let quantity = 0
    subscriptions.forEach(stripeSubscription => {
      const { qty, amt } = this.filterSubscriptionTotal(stripeSubscription)
      amount += amt
      quantity += qty
    })
    return `${amount / 100}${quantity > 1 ? ` • ${quantity}` : ''} `
  }
  getInterval(customer) {
    if (!customer) return null

    let stringInterval = ''
    customer.subscriptions.data.forEach(subscription => {
      subscription.items.data.forEach(subItem => {
        const interval = subItem.plan.interval
        if (subItem.plan) {
          stringInterval = `${interval}ly`
          if (subItem.plan.interval_count === 3) {
            if (interval === 'month') {
              stringInterval = `quarterly`
            }
          } else if (subItem.plan.interval_count > 1) {
            if (interval === 'month') {
              stringInterval = `per ${subItem.plan.interval_count} months`
            }
          }
        }
      })
    })
    return stringInterval
  }
  renderBillingRows(rows) {
    return rows.map((row, index) => {
      return (
        <div className="col-md-12 text-center m-1" key={index}>
          <span>
            <FontAwesomeIcon icon={row.icon} /> {row.title}
          </span>
        </div>
      )
    })
  }
  render() {
    const { isCollapseOpen } = this.state
    const tabs = [
      { title: 'Event Management', icon: 'calendar-alt' },
      { title: 'Bookings System', icon: 'calendar-alt' },
      { title: 'Automated Billing', icon: 'dollar-sign' }, //todo remove if no stripe customer id
      { title: 'Leads', icon: 'user-plus' },
      { title: 'Member Management', icon: 'users' },
      { title: 'Resource Management', icon: 'coffee' },
      { title: 'Front-desk Tablet', icon: 'tablet-alt' },
      { title: 'Dedicated Support', icon: 'phone' },
    ]
    const prefs = this.props.activeCommunity.community_preference

    if (prefs && prefs.is_whitelabeled) {
      tabs.push({ title: 'White-labeled Mobile Apps', icon: 'phone' })
    }
    // tabs.push({ title: 'Custom Whitelabel Mobile Apps', icon: 'phone' })
    const country = prefs.country ? prefs.country : 'US'
    const currency = prefs.currency ? prefs.currency : 'USD'
    const { defaultSource } = getCustomerDefaultSource(
      this.props.coworksCustomer,
      true
    )
    const customer = this.props.coworksCustomer
    const interval = this.getInterval(customer)
    return (
      <div id="billing" className="animated fadeIn">
        {this.props.isFetching || !customer ? (
          <Spinner />
        ) : (
          <div className="col-md-8 offset-md-2">
            <React.Fragment>
              {customer &&
              customer.subscriptions &&
              customer.subscriptions.data &&
              customer.subscriptions.data.length > 0 ? (
                <React.Fragment>
                  <Row>
                    <div className="col-12 mb-2">
                      <h5>Your Plan</h5>
                    </div>
                  </Row>
                  {customer.subscriptions.data.map(subscription => {
                    const {
                      amt,
                      qty,
                      discountString,
                    } = this.filterSubscriptionTotal(subscription)
                    return subscription.items.data
                      .filter(
                        subItem =>
                          subItem.plan.nickname === 'Premium' ||
                          subItem.plan.nickname === 'Essentials' ||
                          subItem.plan.nickname === 'Enterprise'
                      )
                      .map(subItem => (
                        <div className="my-2 p-3">
                          <Row className="">
                            <div className="col-md-4 col-12 d-flex align-items-center">
                              <h4>
                                <RoundedImage
                                  photo={coworksLogo}
                                  alt="Picture"
                                  size="small"
                                  className="img-responsive"
                                />{' '}
                                {subItem.plan.nickname}
                              </h4>
                            </div>
                            <div className="col-12 col-md-8">
                              <Row>
                                <div className="col-6">
                                  <Row>
                                    <div className="col-12  d-flex align-items-center">
                                      <p>
                                        Orig: $
                                        {(subItem.plan.amount / 100).toFixed(2)}{' '}
                                      </p>
                                    </div>
                                  </Row>
                                  {discountString && (
                                    <Row>
                                      <div className="col-12 text-muted">{`${discountString}`}</div>{' '}
                                    </Row>
                                  )}
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                  <h4>${(amt / 100).toFixed(2)} </h4>
                                </div>
                              </Row>
                            </div>
                          </Row>
                        </div>
                      ))
                  })}

                  <br />
                  <Row>
                    <div className="col-12 mb-2">
                      <h5>Add Ons</h5>
                    </div>
                    <div className="col-12">
                      <Row className="mt-2">
                        <div className="col-4">
                          <LabelStyled>Features</LabelStyled>
                        </div>
                        <div className="col-4">
                          <LabelStyled>Standard Price</LabelStyled>
                        </div>
                        <div className="col-4">
                          <LabelStyled>Your Price</LabelStyled>
                        </div>
                      </Row>
                    </div>
                    <div className="col-4">
                      <FontAwesomeIcon icon="paint-brush" /> White-labeled
                    </div>
                    <div className="col-4">$99</div>
                    <div className="col-4">
                      {this.props.activeCommunity.community_preference
                        .is_whitelabeled ? (
                        'Included'
                      ) : (
                        <span>
                          Not Included{' '}
                          <TextButton
                            className="p-0 m-0"
                            onClick={() => {
                              IntercomAPI(
                                'showNewMessage',
                                'I would like to add the white-labeled branding upgrade to my account.'
                              )
                            }}
                          >
                            Upgrade
                          </TextButton>
                        </span>
                      )}
                    </div>
                  </Row>
                  {customer.subscriptions.data.map(sub =>
                    sub.items.data
                      .filter(
                        subItem => subItem.plan.nickname === 'Additional Campus'
                      )
                      .map(subItem => (
                        <Row>
                          <div className="col-4">
                            <FontAwesomeIcon icon="map-signs" /> Additional
                            Campuses
                          </div>
                          <div className="col-4">{`$99 / additional campus`}</div>
                          <div className="col-4">
                            <p>{`$${
                              subItem.quantity * (subItem.plan.amount / 100)
                            } • ${subItem.quantity} campus${
                              subItem.quantity > 1 ? 'es' : ''
                            }`}</p>
                          </div>
                        </Row>
                      ))
                  )}

                  <br />
                  <TextButton
                    className="m-0 p-0"
                    onClick={() => {
                      this.setState({
                        isCollapseOpen: !isCollapseOpen,
                      })
                    }}
                    style={{ marginBottom: '1rem' }}
                  >
                    {!isCollapseOpen
                      ? '+ Show Included features'
                      : ' - Hide features'}
                  </TextButton>
                  <Collapse isOpen={isCollapseOpen}>
                    {tabs.map(tab => (
                      <Row>
                        <div className="col-8">
                          <FontAwesomeIcon icon={tab.icon} /> {tab.title}
                        </div>
                        <div className="col-4">Included</div>
                      </Row>
                    ))}
                  </Collapse>
                  <hr />
                  <Row>
                    <div className="col-md-8 col-12">
                      <h2>Total {interval} price</h2>
                    </div>
                    <div className="col-md-4 col-12">
                      <h2>
                        $
                        {this.calculateMonthlyTotal(
                          customer.subscriptions.data
                        )}
                      </h2>
                    </div>
                  </Row>
                  <br />
                  <br />
                </React.Fragment>
              ) : (
                'No subscription setup.'
              )}
            </React.Fragment>

            <div className="row">
              <div className="col">
                <h5>Payment Method</h5>
              </div>
            </div>
            <div className="row">
              {defaultSource ? (
                <React.Fragment>
                  <PaymentSourceComponent
                    isLoadingSource={this.state.isLoadingSource}
                    toggleVerifyBankModal={this.toggleVerifyBankModal}
                    defaultSourceObject={defaultSource}
                  />
                  {!this.state.isLoadingSource && (
                    <div className="col-3 d-flex align-items-start">
                      <TextButton onClick={this.toggleBillingMethodModal}>
                        <small>
                          <FontAwesomeIcon icon="pencil-alt" />
                        </small>{' '}
                        Edit
                      </TextButton>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={this.toggleBillingMethodModal}
                >
                  Add Payment Method
                </button>
              )}
            </div>
          </div>
        )}
        <BillingMethodModal
          isLoadingSource={this.state.isLoadingSource}
          showBillingMethodModal={this.state.showBillingMethodModal}
          toggleBillingMethodModal={this.toggleBillingMethodModal}
          toggleVerifyBankModal={this.toggleVerifyBankModal}
          defaultSource={defaultSource}
          addBank={token => this.addBank(token)}
          addCard={token => this.addCard(token)}
          deleteSource={this.deleteSource}
          country={country}
          currency={currency}
        />
        {defaultSource && (
          <VerifyBankModal
            bankToken={defaultSource.id}
            stripeCustomerId={customer.id}
            showVerifyBankModal={this.state.showVerifyBankModal}
            toggleVerifyBankModal={this.toggleVerifyBankModal}
            verifyBank={this.verifyBank}
          />
        )}
        {/*   <div className='row'>
              <div className='col'>
                <h3>History</h3>
              </div>
            </div>
            <div className='row border-bottom-1'>
              <div className='col'>
                <div className='card my-0 border-bottom-0 w-100 p-3'>
                  <div className='row'>
                    <div className='col'>
                      <h5 className='m-0'>$149</h5>
                    </div>
                    <div className='col'>
                      <p className='pull-right m-0'>1/1/2017</p>
                    </div>
                  </div>
                </div>
                <div className='card my-0 border-bottom-0 w-100 p-3'>
                  <div className='row'>
                    <div className='col'>
                      <h5 className='m-0'>$149</h5>
                    </div>
                    <div className='col'>
                      <p className='pull-right m-0'>1/1/2017</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
      </div>
    )
  }
}

CoworksSubscription.displayName = 'CoworksSubscription'

function mapStateToProps(state) {
  return {
    user: state.user,
    stripeAccount: state.stripe.account,
    activeCommunity: state.ui.activeCommunity,
    activeCampus: state.ui.activeCampus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    stripe_actions: bindActionCreators(stripeActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoworksSubscription)
