import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { format, parse, isSameDay } from 'date-fns';

class Booking extends Component {
  static propTypes = {
    booking: PropTypes.object,
    adjustZone: PropTypes.func,
  }

  render() {
    const { booking } = this.props;
    const startTime = format(parse(booking.start_time), 'h:mm a');
    const endTime = format(parse(booking.end_time), 'h:mm a');
    let day;
    if (!isSameDay(booking.start_time, new Date())) {
      day = format(parse(booking.start_time), 'dddd MM/DD');
    } else {
      day = 'Today';
    }
    // if (moment(adjustedTimes.bookingStart).isAfter(moment(), 'day')) {
    //   day = moment(adjustedTimes.bookingStart).format('dddd MM/DD');
    // }
    return (
      <div>
        <div className='card-bubble mb-3 d-block w-100'>
          <h4>{booking.name}</h4>
          <h6>{booking.hosted_by}</h6>
          <h6>{day}</h6>
          <h6>{`${startTime} - ${endTime}`}</h6>
        </div>
      </div>
    );
  }
}

Booking.displayName = 'Booking';
export default Booking;
