import { ColorLuminance } from '@global/Colors/ColorUtils'
import { desaturate, saturate, lighten } from 'polished'
import colors from '@global/Colors/colors'
import designTokens from './designTokens'

export const lightTemplate = template => ({
  name: 'light',
  backgroundColor: '#fff', //storybook background color

  colors: {
    ...designTokens.colors,
    white: '#FFFFFF',
  },
  fonts: {
    sizes: {
      h1: {
        fontSize: designTokens.fonts.h1.fontSize,
        lineHeight: designTokens.fonts.h1.lineHeight,
        letterSpacing: '1px',
      },
      h2: {
        fontSize: designTokens.fonts.h2.fontSize,
        lineHeight: designTokens.fonts.h2.lineHeight,
        letterSpacing: '1px',
      },
      h3: {
        fontSize: designTokens.fonts.h3.fontSize,
        lineHeight: designTokens.fonts.h3.lineHeight,
        letterSpacing: '1px',
      },
      h4: {
        fontSize: designTokens.fonts.h4.fontSize,
        lineHeight: designTokens.fonts.h4.lineHeight,
        letterSpacing: '1px',
      },
      h5: {
        fontSize: designTokens.fonts.h5.fontSize,
        lineHeight: designTokens.fonts.h5.lineHeight,
        letterSpacing: '1.5px',
      },
      h6: {
        fontSize: designTokens.fonts.h6.fontSize,
        lineHeight: designTokens.fonts.h6.lineHeight,
        letterSpacing: '1.5px',
      },
      // eslint-disable-next-line id-length
      p: {
        fontSize: designTokens.fonts.p.fontSize,
        lineHeight: designTokens.fonts.p.lineHeight,
        letterSpacing: '0px', // todo DEVIATION this was changed to 0 from 1 (which is also deviation)
      },
      pSmall: {
        fontSize: designTokens.fonts.pSmall.fontSize,
        lineHeight: designTokens.fonts.pSmall.lineHeight,
        letterSpacing: '1px', // DEVIATION
      },
      button: {
        fontSize: designTokens.fonts.button.fontSize,
        lineHeight: designTokens.fonts.button.lineHeight,
        letterSpacing: '2px',
      },
      sidebarSection: '8px',
    },
    weights: {
      regular: designTokens.fonts.regular.fontWeight,
      semibold: designTokens.fonts.semibold.fontWeight,
      bold: designTokens.fonts.bold.fontWeight,
      extrabold: designTokens.fonts.extrabold.fontWeight,
    },
    colors: {
      light: designTokens.fonts.textLight.color,
      dark: designTokens.fonts.textDark.color,
      gray: designTokens.fonts.textGray.color,
      success: designTokens.fonts.textSuccess.color,
      warning: designTokens.fonts.textWarning.color,
      danger: designTokens.fonts.textDanger.color,
      info: designTokens.fonts.textInfo.color,
      primary: designTokens.fonts.textPrimary.color,
      secondary: designTokens.fonts.textSecondary.color,
    },
    textColors: {
      light: designTokens.fonts.textLight.color,
      dark: designTokens.fonts.textLight.color,
      gray: designTokens.fonts.textLight.color,
      success: designTokens.fonts.textLight.color,
      warning: designTokens.fonts.textLight.color,
      danger: designTokens.fonts.textLight.color,
      info: designTokens.fonts.textLight.color,
      primary: designTokens.fonts.textLight.color,
      secondary: designTokens.fonts.textLight.color,
      neutral1: designTokens.fonts.textDark.color,
      neutral2: designTokens.fonts.textDark.color,
      neutral3: designTokens.fonts.textDark.color,
      neutral4: designTokens.fonts.textDark.color,
      neutral5: designTokens.fonts.textDark.color,
      neutral6: designTokens.fonts.textDark.color,
      accent1: designTokens.fonts.textLight.color,
      accent2: designTokens.fonts.textLight.color,
      accent3: designTokens.fonts.textLight.color,
      accent4: designTokens.fonts.textLight.color,
      accent5: designTokens.fonts.textLight.color,
      accent6: designTokens.fonts.textLight.color,
    },
  },
  icons: {
    iconFont: designTokens.fonts.iconFont.fontFamily,
    sizes: {
      small: '16px',
      medium: '22px',
      large: '30px',
    },
    weights: {
      light: designTokens.fonts.iconLight.fontWeight,
      solid: designTokens.fonts.iconSolid.fontWeight,
    },
  },
  values: {
    form: {
      borderRadius: '4px',
    },
    outlines: {
      outline: '1px solid',
      outlineThick: '3px solid',
    },
  },
  boxShadow: '0 0 8px 0 rgba(0,0,0,0.14)',

  colorPrimary: template.primary, //269796
  colorPrimaryHover: ColorLuminance(template.primary, 0.4),
  colorPrimaryHoverSearchRowHighlight: desaturate(
    0.6,
    lighten(0.6, template.primary)
  ),
  colorPrimaryText: template.white,
  colorSecondary: template.secondary, //29404f
  colorSecondaryText: template.white,
  colorSecondaryHover: ColorLuminance(template.secondary, 0.4),
  colorBodyBackground: '#e4e5e6',
  colorDanger: template.danger, //'#f86c6b',
  colorDangerHover: ColorLuminance(template.danger, 0.4),
  colorDefault: template.default,
  colorDefaultHover: ColorLuminance(template.default, 0.4),
  colorSuccess: template.success, // cant change green until sass is gone! #4fbe79
  colorSuccessHover: ColorLuminance(template.success, 0.4),
  colorInfo: template.info,
  colorInfoText: template.white,
  colorInfoHover: ColorLuminance(template.info, 0.4),
  colorWarning: template.warning,
  colorWarningText: template.white,
  colorWarningHover: ColorLuminance(template.warning, 0.4),
  colorWhite: template.white, // didn't want to name colorWhite as convention because might be confused with actually white
  colorWhiteHover: ColorLuminance(template.white, 0.4),
  colorInputFocusedBorder: '#64d8d7', // the color of the border for inputs when you focus them
  colorInputText: '#607d8b', // the color of the text in inputs
  disabledOpacity: 0.4,
})

export const darkTemplate = template => ({
  ...lightTemplate(template),
  name: 'dark',
  backgroundColor: '#000', //storybook background color
  colorPrimary: template.danger, //269796
})

export const lightTheme = lightTemplate(colors)
export const darkTheme = darkTemplate(colors)
export default lightTheme
// $blue:    #007bff !default; #2193F3
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #e83e8c !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
// $green:   #28a745 !default;
// $teal:    #20c997 !default;
// $cyan:    #17a2b8 !default;

// $colors: (
//   blue: $blue,
//   indigo: $indigo,
//   purple: $purple,
//   pink: $pink,
//   red: $red,
//   orange: $orange,
//   yellow: $yellow,
//   green: $green,
//   teal: $teal,
//   cyan: $cyan,
//   white: $white,
//   gray: $gray-600,
//   gray-dark: $gray-800
// ) !default;

// $theme-colors: (
//   primary: $blue,
//   secondary: $gray-600,
//   success: $green,
//   info: $cyan,
//   warning: $yellow,
//   danger: $red,
//   light: $gray-100,
//   dark: $gray-800
// ) !default;
