import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled, { css } from 'styled-components'

const TableHeader = styled.thead`
`
const IconStyled = styled(FontAwesomeIcon)`
  margin-left: 4px; 
`
const Wrapper = styled.div`
`
const HeaderRow = styled.tr`
  width: 100%;
`
const Col = styled.th`
  background: ${props => props.active ? '#00000009' : 'none'};
  ${props => props.sortable ?
    css`
      :hover {
        background: #00000012;
        cursor: pointer;
      }
    `: ''
  }}
`

function Header({
  columns,
  sortInfo,
  onClick,
  ...other
}) {

  const getColFunction = (col, sortable) => {
    const { slug } = col
    return sortable ? () => onClick(slug, sortInfo[slug]) : () => null 
  }

  const renderHeaders = () => {
    return columns.map(col => {
      const { name, slug, options, style } = col
      const colSort = sortInfo[slug]
      const active = typeof colSort === "boolean"
      let icon = null
      let sortableCol = true

      if (options) {
        const { display, sortable } = options;
        if (display !== undefined && !display) return null
        if (sortable !== undefined) sortableCol = sortable;
      }
      if (active) icon = colSort ? 'chevron-up' : 'chevron-down'

      return (
        <Col
          active={active}
          style={style}
          sortable={sortableCol}
          onClick={getColFunction(col, sortableCol)}
        >
          {name}
          {icon && <IconStyled icon={icon} />}
        </Col>
      )
    })
  }

  return (
    <TableHeader>
      {/* <Wrapper className="col-md-12"> */}
      <HeaderRow {...other}>
        {renderHeaders()}
      </HeaderRow>
      {/* </Wrapper> */}
    </TableHeader>
  )
}

Header.propTypes = {
  columns: PropTypes.array.isRequired,
  sortInfo: PropTypes.object,
  onClick: PropTypes.func,
}
Header.defaultProps = {
}

Header.displayName = 'Header'

export default Header
