const breadcrumbRoutes = {
  /*Coworks Routes*/
  '/dashboard': 'Dashboard',
  '/calendar': 'Calendar',
  '/checkins': 'Check-ins',
  '/checkins/member': 'Member',
  '/checkins/visitor': 'Visitor',
  '/checkins/passport': 'Passport',
  '/calendar/add-event': 'New Event',
  '/calendar/event-detail': 'Event Detail',
  '/announcements': 'Announcements',
  '/announcements/send-announcement': 'Send Announcement',
  '/announcements/send-announcement/:id': 'Send Announcement',
  '/connect-to-stripe': 'Connect to Stripe',
  '/crm': 'CRM',
  '/crm/leads': 'Leads',
  '/crm/prospects': 'Prospects',
  '/crm/leads/add': 'New Lead',
  '/crm/prospects/add': 'New Prospect',
  '/billing': 'Billing',
  '/billing/invoices': 'Invoices',
  '/billing/transactions': 'Transactions',
  '/billing/create-charge': 'Create Invoice',
  '/passport': 'Passport',
  '/plans': 'Plans',
  '/plans/add-plan': 'New Plan',
  '/directory/teams': 'Teams',
  '/directory/teams/add-team': 'New',
  '/directory/teams/:teamId/:tabId': 'Team Detail',
  '/directory/members': 'Members',
  '/directory/members/add-member': 'New',
  '/directory/members/:memberId/:tabId': 'Member Detail',
  '/directory/staff': 'Staff',
  '/directory/add-team': 'New Team',
  '/directory': 'Directory',
  '/book/:campusId': 'Spaces',
  '/book/:campusId/:roomId': 'Room Detail',
  '/resources': 'Resources',
  '/resources/add-resource': 'New Resource',
  '/resources/resource-detail': 'Resource Detail',
  '/community': 'Community',
  '/community/personal': 'My Profile',
  '/community/preferences': 'User Preferences',
  '/community/subscription': 'Coworks Subscription',
  '/community/settings': 'Settings',
  '/community/settings/branding': 'Branding',
  '/community/settings/calendar': 'Calendar',
  '/community/website': 'Your Website Forms',
  '/community/settings/onboarding': 'Onboarding',
  '/community/settings/billing': 'Billing',
  '/community/settings/super': 'Super Admin',
  '/community/integrations/all': 'Available',
  '/community/integrations/active': 'All',
  '/community/integrations': 'Integrations',
  '/community/migrations': 'Migrations',
  '/community/campuses': 'Campuses',
  '/campuses': 'Campus Form',
  '/occupancy': 'Occupancy',
  '/preferences': 'Preferences',
  '/my-profile': 'My Profile',
  '/community/groups': 'Custom Drop-downs',
  '/my-team': 'My Team',
  '/account/groups': 'Custom Dropdowns',
  '/rooms': 'Rooms',
  '/rooms/spaces': 'Spaces',
  '/rooms/equipment': 'Equipment',
  '/rooms/add-room': 'New Room',
  '/rooms/room-detail': 'Room Detail',
  '/reports': 'Reports',
  '/mobile': 'Mobile Apps',
}
export default breadcrumbRoutes
