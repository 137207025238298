import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { FETCHING_BOOKING_THICC } from '@global/Constants/FetchingConstants'

// ACTION CREATORS
export function successCreatingBooking(booking) {
  return { type: actionTypes.CREATE_BOOKING, booking }
}

export function setBookings(bookings, meta = null) {
  return { type: actionTypes.SET_BOOKINGS, bookings, meta }
}

export function clearBookings() {
  return { type: actionTypes.CLEAR_BOOKINGS }
}

export function successUpdatingBooking(booking) {
  return { type: actionTypes.UPDATE_BOOKING, booking }
}

export function setTabletKey(tabletKey) {
  return { type: actionTypes.CREATE_TABLET_KEY, tabletKey }
}

// ACTION DISPATCHERS
export const getBookings = (
  campusId,
  otherParams,
  clear = false
) => dispatch => {
  dispatch(uiActions.updateFetchingStatus(true))
  if (clear) {
    dispatch(clearBookings())
  }

  return new Promise((resolve, reject) => {
    apiService
      .getBookings(campusId, otherParams)
      .then(response => {
        const bookings = response.bookings ? response.bookings : []
        const events = response.events ? response.events : []
        const results = [...bookings, ...events]
        dispatch(setBookings(results, response.meta))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export function getBookingsThin(campusId, fromDate, isPrivate) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .getBookingsThin(campusId, fromDate, isPrivate)
      .then(response => {
        const bookings = response.bookings ? response.bookings : []
        const events = response.events ? response.events : []
        const results = [...bookings, ...events]
        dispatch(setBookings(results))
      })
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}

export const getBookingThick = (eventId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_BOOKING_THICC))
    return apiService
      .getBookingThick(eventId, params)
      .then(response => {
        dispatch(successUpdatingBooking(response.booking))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_BOOKING_THICC))
      )
  })
}

export const createBooking = booking => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createBooking(booking)
      .then(response => {
        dispatch(successCreatingBooking(response.booking))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}
export const updateBooking = booking => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateBooking(booking)
      .then(response => {
        dispatch(successUpdatingBooking(response.booking))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}

export const getTabletKey = (roomId, campusId) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .getTabletKey(roomId, campusId)
      .then(response => {
        dispatch(setTabletKey(response))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  })
}
