/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Otto from '@app/img/placeholders/otto_color.png'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import { withTheme } from 'styled-components'
import PermissionsPlaceholder from '@global/PermissionPlaceholder'

// Authorization HOC
const Authorization = allowedRoles => WrappedComponent => {
  class WithAuthorization extends React.Component {
    static propTypes = {
      user: PropTypes.object,
    }
    render() {
      const user = this.props.user
      if (user && user.type && allowedRoles.includes(user.type)) {
        return <WrappedComponent {...this.props} role={user.type} />
      }
      return (
        <PermissionsPlaceholder
          header="Hey! Where do you think you're going?"
          subheader="Sorry, you don't have access to view this page."
          text="The page you're looking for has been removed or you no longer have access to it. If you think this is a mistake, please contact support@coworksapp.com."
          onClick={this.props.history.goBack}
          colorPrimary={this.props.theme.colorPrimary}
        />
      )
    }
  }
  return connect(mapStateToProps, null)(withTheme(WithAuthorization))
}
const mapStateToProps = state => {
  return {
    user: state.user,
  }
}
export default Authorization
