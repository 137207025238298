import React from 'react'
import PropTypes from 'prop-types'

// REDUX BITS
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as memberActions from '../../../reduxActions/memberActions'

// DEPENDENCIES
import fallbackUserAvatar from '../../../img/placeholders/otto_black_white.jpeg'
import MemberCard from './MemberCard'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import '../../../styles/coworks/components/frontDesk/FrontDesk.scss'
import styled from 'styled-components'

import Placeholder from '@global/Placeholder'
import Spinner from '@global/Spinner'
import NothingFound from '@global/NothingFound'

import Lottie from '@global/Lottie/Lottie'

const WAIT_INTERVAL = 1400

const CloseModal = styled.div`
  text-align: right;
  font-size: 34px;
  padding-bottom: 10px;

  :hover {
    cursor: pointer;
  }
`
const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`
const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
const SearchWrapper = styled.div``
const ButtonStyled = styled.button`
  position: relative;
`
const MemberCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CardWrapper = styled.div`
  width: 33.33%;
  padding: 10px;
`
const MemberCardStyled = styled(MemberCard)`
  height: 100%;
  width: 100%;
  margin: 10px;
`
const Scroller = styled.div`
  flex: 1;
  overflow: scroll;
`

const CloseIcon = styled.div`
  font-size: 24px;
`
const CloseModalContainer = styled.div`
  margin-left: auto;
  width: fit-content;
  padding: 12px 30px;
`
const SelectedContainer = styled.div`
  margin: auto;
  width: 55%;
  padding-top: 35px;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export class MemberSelector extends React.Component {
  static propTypes = {
    toggleModal: PropTypes.func,
    action: PropTypes.func,
    campusId: PropTypes.number,
    actions: PropTypes.object, // if you want to use a different api call
    otherParams: PropTypes.object, // if you want to pass through other params
    member_actions: PropTypes.object,
    type: PropTypes.string,
    headerText: PropTypes.string,
    buttonText: PropTypes.string,
    isFetching: PropTypes.bool,
    excluded: PropTypes.array,
    showCloseButton: PropTypes.bool,
    showLottie: PropTypes.bool,
    public: PropTypes.bool,
    showActiveStatus: PropTypes.bool,
  }
  static defaultProps = {
    headerText: 'Who are you visiting?',
    otherParams: {},
    showCloseButton: true,
    buttonText: 'Notify',
    excluded: [],
    showLottie: false,
    showActiveStatus: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      memberConfirmed: false,
      membersToDisplay: null,
      selectedMember: null,
      loading: false,
      lottieVisible: false,
    }
    this.timer = null
  }

  componentDidMount() {
    // get members by Campus
    if (this.props.public) this.handleMemberSearch()
  }
  getMembersToDisplay = members => {
    const filteredMemberIds = this.props.excluded
    return members.filter(
      member => !filteredMemberIds.includes(member.id.toString())
    )
  }

  handleMemberSearch = (searchString = '') => {
    const actions = this.props.actions
      ? this.props.actions
      : this.props.member_actions

    const privateSearch = searchString.length <= 0
    if (searchString.length >= 3 || (privateSearch && this.props.public)) {
      this.setState(
        {
          isFetching: true,
          searchString,
        },
        () => {
          actions
            .search(this.props.campusId, {
              search_string: searchString,
              ...this.props.otherParams,
            })
            .then(response => {
              this.setState({
                membersToDisplay: this.getMembersToDisplay(response.members),
                isFetching: false,
              })
            })
            .catch(err => {
              this.setState({ isFetching: false })
              console.error(err)
            })
        }
      )
    }
  }

  sortMembers = event => {
    const searchString = event.target.value.toLowerCase()
    clearTimeout(this.timer)
    // this.props.member_actions.getMembers(this.props.campusId, { search_string: searchString });
    this.timer = setTimeout(() => {
      this.handleMemberSearch(searchString)
    }, WAIT_INTERVAL)
  }

  renderMembers() {
    //eslint-disable-line
    const { membersToDisplay } = this.state

    if (membersToDisplay && membersToDisplay.length > 0) {
      return (
        <MemberCardWrapper id="member-cards">
          {this.state.membersToDisplay.map(member => {
            return (
              <CardWrapper key={member.id}>
                <MemberCardStyled
                  member={member}
                  showCampus
                  showActive={this.props.showActiveStatus}
                  handleSelectMember={this.handleSelectMember}
                />
              </CardWrapper>
            )
          })}
        </MemberCardWrapper>
      )
    }
    if (!this.state.isFetching) {
      // if (!membersToDisplay) {
      //   return (
      //     <Placeholder
      //       title={'Search a Member'}
      //       icon={'search'}
      //       subtext1={'No members were found by this search'}
      //       color="#269796"
      //     />
      //   )
      // }
      if (membersToDisplay) {
        return (
          <Placeholder
            title={'No Results Found'}
            icon={'search'}
            subtext1={'No members were found by this search'}
            color="#269796"
          />
        )
      }
    }
    return null
  }

  handleSelectMember = member => {
    this.setState({ selectedMember: member, memberChecked: true })
  }

  handleAction = () => {
    this.setState({ loading: true })
    Promise.resolve(this.props.action(this.state.selectedMember)).then(() => {
      this.setState({ loading: false })
      if (this.props.showLottie) {
        this.setState({ lottieVisible: true })
        // lottie animtation will close modal after animation finished.
      } else {
        this.props.toggleModal()
      }
    })
  }

  handleCancel = () => {
    this.setState({ selectedMember: null, memberChecked: false })
  }

  bookConfirmation() {
    return (
      <div className="p-3 fixed-bottom booking-auth-header">
        <div className="row">
          <div className="d-flex justify-content-center col-3">
            <button
              className="btn w-100 btn-link h1"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
          <div className="d-flex justify-content-center col-9">
            <button
              className="btn w-100 btn-primary btn-huge"
              onClick={this.handleAction}
            >{`${this.props.type}`}</button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { buttonText } = this.props
    const { loading } = this.state
    const member = this.state.selectedMember
    const memberImage =
      member && member.user_photo && member.user_photo.file_url
        ? member.user_photo.file_url
        : fallbackUserAvatar

    const headerText =
      this.props.type === 'Book' ? 'Member Login' : this.props.headerText
    return (
      <Container id="BookingAuth">
        {this.props.showCloseButton && (
          <CloseModalContainer
            id="close-modal"
            className="fixed-top"
            aria-hidden="true"
            onClick={this.props.toggleModal}
          >
            <CloseIcon className="cancel-btn">
              <FontAwesomeIcon icon="times" />
            </CloseIcon>
          </CloseModalContainer>
        )}
        {this.props.showLottie && this.state.lottieVisible ? (
          <Lottie
            type={'checkin'}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => {
                  this.setState({ lottieVisible: false })
                  this.props.toggleModal()
                },
              },
            ]}
          />
        ) : (
          <React.Fragment>
            {!this.state.memberChecked ? (
              <React.Fragment>
                <SearchWrapper className="p-3 booking-auth-header">
                  <h4 className="modal-title text-center d-block w-100 modal-styled-title">
                    {headerText}
                  </h4>
                  <input
                    id="name"
                    type="text"
                    name="MemberName"
                    className="w-100 form-bubble form-control"
                    placeholder={
                      this.props.type === 'Book' ? 'Your name' : 'Member name'
                    }
                    onChange={this.sortMembers}
                  />
                </SearchWrapper>
                <Scroller>
                  {this.state.isFetching ? <Spinner /> : this.renderMembers()}
                </Scroller>
              </React.Fragment>
            ) : (
              <SelectedContainer>
                <img
                  src={memberImage}
                  className="mx-auto d-block img-avatar campus-avatar"
                  style={{ width: '22vh' }}
                />
                <h4
                  className="text-center d-block w-100 member-title"
                  style={{ marginBottom: '80px', marginTop: '15px' }}
                >{`${member.first_name} ${member.last_name}`}</h4>
                <div className="row cancel-or-book">
                  <div className="d-flex justify-content-center align-items-center col-12">
                    <ButtonStyled
                      style={{
                        color: 'white',
                        borderRadius: '50vh',
                        border: 'none',
                        backgroundColor: '#283845',
                        height: '75px',
                        fontWeight: '520',
                        textTransform: 'uppercase',
                        fontSize: '23px',
                        marginBottom: '10px',
                        opacity: loading ? 0.7 : 1,
                      }}
                      disabled={loading}
                      className="btn w-100 btn-primary btn-huge "
                      onClick={this.handleAction}
                    >
                      <ContentWrapper>
                        {loading && (
                          <LoadingWrapper>
                            <Spinner size="sm" color="#fff" />
                          </LoadingWrapper>
                        )}
                        {`${buttonText} ${member.first_name}`}
                      </ContentWrapper>
                    </ButtonStyled>
                  </div>
                  <div className="d-flex justify-content-center col-12">
                    <button
                      style={{
                        color: '#283845',
                        fontWeight: '520',
                        textTransform: 'uppercase',
                        fontSize: '23px',
                        padding: '25px',
                      }}
                      className="btn w-100 btn-link"
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </SelectedContainer>
            )}
          </React.Fragment>
        )}
      </Container>
    )
  }
}
MemberSelector.displayName = 'MemberSelector'

const mapStateToProps = state => {
  return {
    isFetching: state.ui.isFetching,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    member_actions: bindActionCreators(memberActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberSelector)
