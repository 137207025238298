import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const NavLinkStyled = styled(NavLink)`
  display: block;
  padding: 0.75rem 1rem;
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff !important;
    background: ${props => props.theme.colorSecondaryHover}!important;
    text-decoration: none !important;
  }
  &.active {
    color: #fff;
    background: ${props => props.theme.colorPrimary}!important;
    text-decoration: none !important;
  }
`
export default class SidebarNavLink extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    activeClassName: PropTypes.string.isRequired,
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
    text: PropTypes.object,
    id: PropTypes.string.isRequired,
  }

  // onClick = event => {
  //   event.preventDefault()
  //   if (window.swUpdate) {
  //     return (window.location = this.props.to)
  //   }
  //   if (this.props.to === '/support') {
  //     window.location = 'https://help.coworksapp.com'
  //   }
  //   if (this.props.to !== this.props.location.pathname) {
  //     return this.props.history.push(this.props.to)
  //   }
  //   return null
  // }
  render() {
    const { className, to, activeClassName, text, id } = this.props
    return (
      <NavLinkStyled
        id={`${id}-sidebar`}
        to={to}
        className={className}
        // onClick={this.onClick}
        activeClassName={activeClassName}
      >
        {text}
      </NavLinkStyled>
    )
  }
}

SidebarNavLink.displayName = 'SidebarNavLink'
