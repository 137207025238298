import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import {
  FETCHING_PLANS,
  FETCHING_DAY_PASS,
} from '@global/Constants/FetchingConstants'
import { resolve } from 'promise'

export function setPlans(plans) {
  return { type: actionTypes.SET_PLANS, plans }
}

export function successCreatingPlan(plan) {
  return { type: actionTypes.CREATE_PLAN, plan }
}

export function successCreatingPlanOnFly(plan) {
  return { type: actionTypes.CREATE_PLAN_ON_FLY, plan }
}

export function successUpdatingPlan(plan) {
  return { type: actionTypes.UPDATE_PLAN, plan }
}
export function successArchivingPlan(plan) {
  return { type: actionTypes.ARCHIVE_PLAN, plan }
}

// ACTION DISPATCHERS
export const createPlan = plan => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createPlan(plan)
      .then(response => {
        dispatch(successCreatingPlan(response.plan))
        resolve(response)
      })
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const createDayPass = plan => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_DAY_PASS))
    apiService
      .createPlan(plan)
      .then(response => {
        dispatch(uiActions.successUpdatingDayPass(response.plan))
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_DAY_PASS))
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_DAY_PASS))
      })
  })
}

export const createPlanOnFly = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createPlan(params)
      .then(response => {
        dispatch(successCreatingPlanOnFly(response.plan))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}

export const getPlans = (params, type) => dispatch => {
  dispatch(uiActions.updateFetchingStatus(true, type ? type : FETCHING_PLANS))
  return new Promise((resolve, reject) => {
    apiService
      .getPlans(params)
      .then(response => {
        dispatch(setPlans(response.plans))
        dispatch(
          uiActions.updateFetchingStatus(false, type ? type : FETCHING_PLANS)
        )
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(
          uiActions.updateFetchingStatus(false, type ? type : FETCHING_PLANS)
        )
      })
  })
}

export const updatePlan = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updatePlan(params)
      .then(response => {
        dispatch(successUpdatingPlan(response.plan))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const updateDayPass = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_DAY_PASS))
    apiService
      .updatePlan(params)
      .then(response => {
        dispatch(uiActions.successUpdatingDayPass(response.plan))
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_DAY_PASS))
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_DAY_PASS))
      })
  })
}

export const archivePlan = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updatePlan(params)
      .then(response => {
        dispatch(successArchivingPlan(response.plan))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.plan)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const checkoutPlan = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updatePlan(params)
      .then(response => {
        dispatch(successArchivingPlan(response.plan))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.plan)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const search = (campusId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getPlans(campusId, params)
      .then(response => {
        // dispatch(setPlans(response.plans))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
