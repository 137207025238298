/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes, { func } from 'prop-types'
import { Row } from 'reactstrap'
import { Field } from 'formik'
import {
  CustomInputComponent,
  CustomToggleComponent,
  LabelStyled,
  CustomPresetSelectComponent,
} from '@global/Form/FormComponents'
import GenericModal from '@global/GenericModal'

// eslint-disable-next-line react/display-name
function renderModal({ setFieldValue, key, label, values }) {
  const showModal = `show_modal_${key}`
  return (
    <GenericModal
      className="modal-primary fade in"
      isOpen={values[showModal]}
      toggle={() => {
        setFieldValue(showModal, !values[showModal])
      }}
      header={'Are you sure?'}
      body={
        <p>
          This will {`${values[key] ? 'disable' : 'enable'}`} the{' '}
          <b>{`${label}`}</b> feature ({`${values[key] ? 'hiding' : 'showing'}`}{' '}
          the tab) in the mobile app for members! <br />
          <br />
          <span className="text-muted">
            Note: Changes will not be reflected in mobile app until quitting and
            re-opening the mobile app.
          </span>
        </p>
      }
      onConfirm={() => {
        setFieldValue(showModal, false)
        setFieldValue(key, !values[key])
      }}
      confirmLabel={
        values[key] ? 'Yes, disable feature' : 'Yes, enable feature'
      }
    />
  )
}
function interceptSwitchChange(fieldName, fieldValue, setFieldValue) {
  setFieldValue(`show_modal_${fieldName}`, true)
}
// eslint-disable-next-line react/no-multi-comp
function MobileSettings({ values, setFieldValue }) {
  return (
    <React.Fragment>
      <h3>Mobile App Features</h3>
      <br />
      <Field
        label="Show Community Tab"
        name="events_enabled"
        description="Will disable the mobile app tab for events"
        interceptChange={interceptSwitchChange}
        component={CustomToggleComponent}
      />
      <Field
        label="Show Directory Tab"
        name="directory_enabled"
        description="Will disable the mobile app tab for directory"
        interceptChange={interceptSwitchChange}
        component={CustomToggleComponent}
      />
      <Field
        label="Show Resources Tab"
        name="resources_enabled"
        description="Will disable the mobile app tab for resources"
        interceptChange={interceptSwitchChange}
        component={CustomToggleComponent}
      />
      <Field
        label="Show Bookings Tab"
        name="bookings_enabled"
        description="Will disable the mobile app tab for bookings"
        interceptChange={interceptSwitchChange}
        component={CustomToggleComponent}
      />
      <Field
        label="Show Equipment Tab"
        name="equipment_enabled"
        disabled={!values.bookings_enabled}
        description="Will disable the mobile app tab for equipment"
        interceptChange={interceptSwitchChange}
        component={CustomToggleComponent}
      />
      <Field
        label="Equipment Tab Name"
        name="equipment_resource_name"
        disabled={!values.equipment_enabled || !values.bookings_enabled}
        description="Will rename Equipment to a name of your choosing."
        component={CustomInputComponent}
      />
      {renderModal({
        values,
        setFieldValue,
        label: 'Community',
        key: 'events_enabled',
      })}
      {renderModal({
        values,
        setFieldValue,
        label: 'Directory',
        key: 'directory_enabled',
      })}
      {renderModal({
        values,
        setFieldValue,
        label: 'Bookings',
        key: 'bookings_enabled',
      })}
      {renderModal({
        values,
        setFieldValue,
        label: 'Resources',
        key: 'resources_enabled',
      })}
      {renderModal({
        values,
        setFieldValue,
        label: 'Equipment',
        key: 'equipment_enabled',
      })}
    </React.Fragment>
  )
}

MobileSettings.propTypes = {
  activeCommunity: PropTypes.object,
  role: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
}

MobileSettings.displayName = 'MobileSettings'

export default MobileSettings
