/* eslint-disable no-invalid-this,no-unused-vars,radix,camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IntercomAPI } from 'react-intercom'

import Spinner from '@global/Spinner'
import NewMemberForm from './NewMemberForm'
import * as memberActions from '../../../../reduxActions/memberActions'
import * as userActions from '../../../../reduxActions/userActions'

import { toast } from 'react-toastify'
import moment from 'moment'
import { resolveHistoryState } from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import { checkDoorProvider } from '@app/components/Community/Integrations/IntegrationConstants'
class NewMember extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    member_actions: PropTypes.object,
    user_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    isFetching: PropTypes.bool,
    plans: PropTypes.array,
    integrations: PropTypes.object,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    // Todo: Cascade this method to the rest of the app for cleanup in constructor.
    const historyState = resolveHistoryState(this.props.history)
    const stt = historyState
    const lead = stt ? stt.lead : null
    const team = stt ? stt.team : null
    const activeTab = stt ? stt.activeTab : 'members'
    const backRoute = stt ? stt.backRoute : '/directory'
    const toRoute = stt && stt.toRoute ? stt.toRoute : '/directory'
    // use the new function in the constnats

    let isStaff = false // helps determine if you're adding a member to the staff team or not.
    if (historyState) {
      if (historyState.isStaff !== null && historyState.isStaff !== undefined) {
        isStaff = historyState.isStaff
      }
    }

    this.state = {
      bio: '',
      bio_html: '',
      errors: [],
      email: lead ? lead.email : '',
      firstName: lead ? lead.first_name : '',
      lastName: lead ? lead.last_name : '',
      lead: lead,
      phone: lead ? lead.phone : '',
      title: '',
      type: isStaff ? 'Staff' : 'Member',
      status: 'Active',
      showAccess: false,
      doorAccess: [],
      photo: null,
      disableMemberTypeDropdown: true,
      sendWelcomeEmail:
        this.props.activeCommunity.community_preference
          .send_onboarding_email !== null
          ? this.props.activeCommunity.community_preference
              .send_onboarding_email
          : true,
      emailIsUnique: false,
      isEnabled: false,
      userJoinedAt: moment(),
      accessDate: moment(),
      activeTab,
      backRoute,
      toRoute,
      team,
      submittingRequest: false,
      runSearchAndAttemptMatchValue: null,
    }
  }
  UNSAFE_componentWillMount() {
    if (this.state.lead && this.state.lead.email) {
      this.checkEmailExists(this.state.lead.email, this.props.activeCampus.id)
    }
    if (this.state.lead && !this.state.team && this.state.lead.company_name) {
      this.setState({
        runSearchAndAttemptMatchValue: this.state.lead.company_name,
      })
    }
    if (this.state.team) {
      this.handleSearchItemSelected(this.state.team)
    }
  }

  handleImageUpload = image => {
    this.setState({
      photo: image,
      photoChanged: true,
    })
  }

  checkEmailExists(email, activeCampusId) {
    return this.props.user_actions
      .checkEmailExists(email, activeCampusId)
      .then(response => {
        if (response.emailExists.email_exists) {
          return true
        }
        return false
      })
      .catch(err => {
        return err
      })
  }
  handleUserJoinedAtChanged = mom => {
    this.setState({
      userJoinedAt: mom,
    })
  }
  handleSendWelcomeEmailToggle = () => {
    this.setState({
      sendWelcomeEmail: !this.state.sendWelcomeEmail,
    })
  }
  handleUserAccessDateChanged = accessDate => {
    this.setState({
      accessDate,
    })
  }
  handleDoorsGranted = doorAccess => {
    this.setState({
      doorAccess,
    })
  }
  handleAccessToggle = () => {
    this.setState({
      doorAccess: !this.state.showAccess ? [] : this.state.doorAccess,
      showAccess: !this.state.showAccess,
    })
  }
  createMember = formValues => {
    const {
      sendWelcomeEmail,
      team,
      userJoinedAt,
      photo,
      photoChanged,
      type,
      lead,
      toRoute,
      activeTab,
      backRoute,
      bio_html,
      showAccess,
      doorAccess,
      accessDate,
    } = this.state
    const {
      activeCampus,
      activeCommunity,
      history,
      member_actions,
      user_actions,
    } = this.props
    this.setState({
      submittingRequest: true,
    })
    const self = this
    user_actions
      .checkEmailExists(formValues.email, activeCampus.id)
      .then(responseObj => {
        // true is bad
        // false is good
        // object is error?
        // null is error?
        //response.emailExists.email_exists
        const response = responseObj.emailExists
        let shouldCreateUser = true
        if (response.email_exists === true) {
          if (response.profile_exists_in_community === true) {
            shouldCreateUser = false
            toast.info(response.message)
            self.setState({
              submittingRequest: false,
            })
          }
        }
        if (shouldCreateUser) {
          const memberObj = {
            bio: formValues.bio,
            bio_html: formValues.bio_html,
            campus_id: activeCampus.id,
            title: formValues.title,
            email: formValues.email,
            phone: formValues.phone,
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            team_id: team.id,
            type,
            send_onboarding_email: sendWelcomeEmail,
            stripe_account_id: activeCommunity.stripe_account_id,
            user_joined_at: userJoinedAt,
          }
          if (photoChanged) {
            memberObj.profile_pic = photo
          }
          if (lead) {
            memberObj.lead_id = lead.id
          }

          if (showAccess) {
            memberObj.add_to_door_access_system = true

            const doorIDs = []
            this.state.doorAccess.forEach(element => {
              if (element.value === null) {
                memberObj.all_doors = true
              } else {
                doorIDs.push(element.id)
              }
            })
            memberObj.door_access_valid_from = accessDate.toISOString()

            if (doorIDs.length > 0) {
              memberObj.door_access_group_ids = JSON.stringify(doorIDs)
            }
          }

          const self = this
          member_actions
            .createMember(memberObj)
            .then(response => {
              if (response && response.user) {
                toast.success(
                  `${response.user.first_name} was created successfully.`
                )
                IntercomAPI('trackEvent', 'add-member-existing-team')
                NavigationConstants.navigateToMember({
                  history: self.props.history,
                  match: self.props.match,
                  member: response.user,
                  state: { backRoute: '/directory' },
                })
              } else {
                toast.success('Member was created successfully.')
                self.props.history.push(toRoute, {
                  activeTab,
                  backRoute: '/directory',
                  team,
                })
              }
            })
            .catch(err => {
              if (err && err.message) {
                toast.error(err.message)
                self.setState({
                  submittingRequest: false,
                })
                //this.setState({ errors: err });
              }
            })
        }
      })
      .catch(err => {
        console.log(err)
        toast.error('There was a problem submitting this form!')
        this.setState({
          submittingRequest: false,
        })
      })
  }

  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value
    // if (name === 'type' &&
    //   this.state.team &&
    //   !this.state.team.is_staff &&
    //   this.state.type === 'Manager') {
    //     value = 'Member'
    //     toast. warn('Managers can only be added to staff teams.');
    // }
    this.setState({
      [name]: value,
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  // for the existing company search input
  handleSearchItemSelected = team => {
    if (team) {
      let type = this.state.type
      let disableMemberTypeDropdown = true
      if (
        team &&
        team.campus_teams &&
        team.campus_teams[0] &&
        team.campus_teams[0].is_staff
      ) {
        // staff team selected.
        disableMemberTypeDropdown = false
      } else {
        // regular team selected
        if (type === 'Manager') {
          toast.info('Only managers can be added to your staff team!')
        }
        disableMemberTypeDropdown = true
        type = 'Member'
      }
      this.setState({
        team,
        disableMemberTypeDropdown,
        type,
      })
    }
    // let shouldDisableTypePicker = false;
    // if (type === 'Admin' || // dont want to be able to edit admin role
    //   !team || !team.campus_teams || team.campus_teams.lengh === 0 || !team.campus_teams[0].is_staff || // dont want to be able to edit roles when not on staff team?
    //   (type === 'Manager' && !canEditRoles.includes(this.props.role)) || // don't want to be able to change
    //   !canEditRoles.includes(this.props.role) ||
    //   type === 'SuperAdmin') { //dont want managers to be able to change super admins
    //   shouldDisableTypePicker = true;
    // }
    //this.validateField('team', team);
  }
  handleSearchItemCleared = () => {
    this.setState({
      team: null,
      formValid: false,
      disableMemberTypeDropdown: true,
    })
    // this.validateField('team', null);
  }
  render() {
    return (
      <NewMemberForm
        history={this.props.history}
        activeCampus={this.props.activeCampus}
        createMember={values => this.createMember(values)}
        type={this.state.type}
        status={this.state.status}
        goBack={this.goBack}
        hasDoorProvider={checkDoorProvider(
          this.props.integrations,
          this.props.activeCampus
        )}
        showAccess={this.state.showAccess}
        doorAccess={this.state.doorAccess}
        accessDate={this.state.accessDate}
        handleAccessToggle={this.handleAccessToggle}
        handleDoorsGrantedChanged={this.handleDoorsGranted}
        doorAccessOptions={this.props.integrations.doorAccess}
        handleImageUpload={obj => this.handleImageUpload(obj)}
        handleInputChange={event => this.handleInputChange(event)}
        handleSearchItemSelected={team => this.handleSearchItemSelected(team)}
        handleSearchItemCleared={() => this.handleSearchItemCleared()}
        handleUserAccessDateChanged={this.handleUserAccessDateChanged}
        dataResolver={this.dataResolver}
        team={this.state.team}
        email={this.state.email} // for if from lead
        phone={this.state.phone}
        firstName={this.state.firstName} // for if from lead
        lastName={this.state.lastName} // for if from lead
        handleSendWelcomeEmailToggle={value =>
          this.handleSendWelcomeEmailToggle(value)
        }
        runSearchAndAttemptMatchValue={this.state.runSearchAndAttemptMatchValue}
        sendWelcomeEmail={this.state.sendWelcomeEmail}
        isEnabled={this.state.isEnabled}
        disableMemberTypeDropdown={this.state.disableMemberTypeDropdown}
        userJoinedAt={this.state.userJoinedAt}
        submittingRequest={this.state.submittingRequest}
        handleUserJoinedAtChanged={this.handleUserJoinedAtChanged}
      />
    )
  }
}

NewMember.displayName = 'NewMember'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    plans: state.plans,
    integrations: state.integrations,
    checkEmailExists: state.checkEmail.check_email,
    checkedEmail: state.checkEmail.email,
    isFetching: state.ui.isFetching,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    member_actions: bindActionCreators(memberActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMember)
