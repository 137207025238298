/* eslint-disable no-invalid-this */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as userActions from '../../reduxActions/userActions'
import * as uiActions from '../../reduxActions/uiActions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { Formik, Field } from 'formik'
import { CustomInputComponent } from '@global/Form/FormComponents'
import { Button, Row } from 'reactstrap'
import * as Yup from 'yup'
import { convertStripeErrorToMessage } from '@global/ErrorFactory'
import { TextButton } from '@global/Base/Button/ButtonStyled'

const Container = styled.div`
  overflow-x: hidden;
  background-size: cover;
  background-attachment: fixed;
  width: 100% !important;
  height: 100vh;
  background-image: linear-gradient(
    to right,
    rgba(08, 90, 110),
    rgba(38, 110, 110),
    rgb(90, 179, 177)
  );

  form {
    display: flex;
  }
`

const Card = styled.div`
  width: 100%;
  min-width: 310px;
  max-width: 500px;
`

export class ForgotPassword extends React.Component {
  static propTypes = {
    campus_actions: PropTypes.object,
    user_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    history: PropTypes.object,
    campuses: PropTypes.array,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  UNSAFE_componentWillMount = () => {
    this.props.user_actions.signOutUser()
  }

  handleResetPass = values => {
    this.props.user_actions
      .sendResetPassword(values.email)
      .then(response => {
        this.setState({
          submitted: true,
        })
        toast.success(
          'Reset password email instructions sent. Please check your email inbox.'
        )
      })
      .catch(err => {
        // For security reasons, we always want to indicate that we're good.
        this.setState({
          submitted: true,
        })
        toast.success(
          'Reset password email instructions sent. Please check your email inbox.'
        )
        // toast.error(convertStripeErrorToMessage(err))
      })
  }

  renderErrorMessages() {
    if (this.state.errors) {
      return this.state.errors.map(error => {
        return <div key={error}>{error}</div>
      })
    }
    return null
  }

  render() {
    return (
      <Container id="Login">
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Email is not properly formatted.')
              .required('Email is required.'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            this.handleResetPass(values)
            setSubmitting(true)
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = props
            // Dev method for understanding errors
            //if (errors && errors !== {}) { console.log(errors); }
            return (
              <form
                onSubmit={handleSubmit}
                disabled={this.state.submitted}
                className="p-5"
              >
                <Row className="w-100 d-flex justify-content-center">
                  <Card className="mt-2 card p-4">
                    <h1 className="text-center my-3 h2">
                      {this.state.submitted
                        ? 'Password Reset Requested'
                        : 'Forgot Password'}
                    </h1>
                    {this.state.submitted ? (
                      <p className="ml-3">
                        Please check your email inbox at {`${values.email}`} and
                        follow the link to reset your password.
                      </p>
                    ) : (
                      <React.Fragment>
                        <div className="col-12">
                          <Field
                            label="Email"
                            name="email"
                            placeholder="Please enter your email"
                            component={CustomInputComponent}
                          />
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={!dirty || isSubmitting}
                          >
                            Send Password Reset
                          </Button>
                        </div>
                        <div className="row mt-3">
                          <div className="col text-center">
                            Go back to <Link to="/login">admin dashboard</Link>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {this.state.submitted && (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-6">
                            <TextButton
                              buttonStyle="primary"
                              onClick={() => {
                                this.props.history.push('/login')
                              }}
                            >
                              Back to Login
                            </TextButton>
                          </div>
                          <div className="col-6">
                            <TextButton
                              buttonStyle="primary"
                              onClick={() => {
                                handleReset()
                                this.setState({
                                  submitted: false,
                                })
                              }}
                            >
                              Back to Reset Password
                            </TextButton>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </Card>
                </Row>
              </form>
            )
          }}
        </Formik>
      </Container>
    )
  }
}

ForgotPassword.displayName = 'Forgot Password'

const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
