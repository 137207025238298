/* eslint-disable react/no-multi-comp,react/display-name */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { checkoutDayPass } from '@app/services/apiService'
import * as Yup from 'yup'
import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import FeatureToggle from '@app/hocs/FeatureToggle'
import withPublicCommunityContext from '../../withPublicCommunityContext'
import PublicSignupContainer from '../PublicSignupContainer'
import {
  captureException,
  extractCoworksErrorObject,
} from '@global/ErrorFactory'
import * as Constants from '@global/Constants'
import { getPrimaryColorFromCommunity } from '@global/Constants/BrandingConstants'
import Placeholder from '@global/Placeholder'
import PageMessage from '@global/PageMessage'
import TermsOfServiceDescription from '../TermsOfServiceDescription'
import Spinner from '@global/Spinner'

function PublicDayPass({
  history,
  theme,
  publicCommunity,
  publicCampus,
  isFetching,
}) {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [error, setError] = useState(null)
  const [email, setEmail] = useState(null)
  const plan = publicCampus ? publicCampus.day_pass : null
  const shouldRedirectSuccess = Boolean(
    publicCommunity.preferences.day_passes &&
      publicCommunity.preferences.day_passes.default_success_redirect_url
  )

  useEffect(() => {
    // change teh document title for the visible plan.
    if (plan) document.title = `Signup for ${plan.name}`
  }, [plan])

  useEffect(() => {
    // check if form is submitted and redirect if needed
    if (
      isSuccessful &&
      shouldRedirectSuccess &&
      publicCommunity &&
      publicCommunity.community_preference
    ) {
      const prefs = publicCommunity.community_preference
      window.location.assign(prefs.day_passes.default_success_redirect_url)
    }
  }, [publicCommunity, isSuccessful, shouldRedirectSuccess])

  // eslint-disable-next-line react/no-multi-comp, react/display-name

  function onSubmit(values, setFormSubmitting) {
    const apiParams = {
      plan_id: plan.id,
      campus_id: publicCampus.id,
      community_id: publicCommunity.id,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      redeem_date: values.date && values.date.toISOString(),
    }

    if (publicCommunity.stripe_account_id) {
      apiParams.card_token = values.card_token
      apiParams.billing_type = 'charge_automatically'
      apiParams.days_until_due =
        publicCommunity.community_preference.days_until_invoice_due
    }

    checkoutDayPass(apiParams)
      .then(response => {
        setIsSuccessful(true)
        setInvoice(response.invoice)
        setError(null)
        setEmail(values.email)
      })
      .catch(err => {
        setIsSuccessful(false)
        const error = extractCoworksErrorObject(err)
        setError(error)
        captureException({ error: err })
        setEmail(values.email)
        setFormSubmitting(false)
      })
      .finally(() => {
        setHasSubmitted(true)
      })
  }

  if (isFetching || !publicCommunity) {
    return <Spinner />
  }
  if (!isFetching && !plan) {
    captureException({
      text: `Looks like someone had trouble accessing the public day pass at url ${window.location}`,
    })
    return (
      <PageMessage
        icon="times"
        header="Whoops..."
        subheader="Looks like this feature has not been setup yet"
        text={`Try contacting the campus.`}
        onClick={history.goBack}
      />
    )
  }

  const primaryColor = getPrimaryColorFromCommunity(publicCommunity)

  const selectedCampuses = plan
    ? publicCommunity.campuses.filter(campus => campus.id === plan.campus_id)
    : []
  let campusPhoto = null
  if (selectedCampuses.length > 0) {
    const campus = selectedCampuses[0]
    if (campus && campus.campus_photo) {
      campusPhoto = campus.campus_photo.small_file_url
    }
  }
  const getInvoiceText = invoice => {
    if (!invoice || !plan || !publicCampus) return null
    const realAmount = invoice.total / 100
    const symbol = Constants.getCurrencySymbolFromName(invoice.currency)
    return `Successfully charged ${symbol}${realAmount.toFixed(2)} for 
    ${plan.name} in ${publicCampus.name}. 
    We've sent a confirmation email to ${email} with more details. Thank you for your purchase!`
  }

  const details = () => [
    {
      title: 'Item',
      text: plan && plan.name,
    },
  ]
  const renderTermsOfServiceDescription = () => {
    return (
      <TermsOfServiceDescription
        campusName={publicCampus && publicCampus.name}
        communityTerms={
          publicCommunity.preferences &&
          publicCommunity.preferences.legal &&
          publicCommunity.preferences.legal.terms_of_service_url
        }
      />
    )
  }

  return (
    <PublicSignupContainer
      featureType={'dayPasses'}
      content={
        plan
          ? {
              photo: plan.plan_photo,
              name: plan.name,
              cost: plan.cost,
              description: plan.description,
              frequency: plan.frequency,
            }
          : {}
      }
      onSubmit={onSubmit}
      goBack={() => history.goBack()}
      isSuccessful={isSuccessful}
      hasSubmitted={hasSubmitted}
      primaryColor={primaryColor}
      history={history}
      invoice={invoice}
      publicCommunity={publicCommunity}
      shouldRedirectSuccess={shouldRedirectSuccess}
      error={error}
      theme={theme}
      detailData={details()}
      purchaseCondition={`
        Your card will be charged and you will be emailed a
        confirmation.
      `}
      confirmationMessage={getInvoiceText(invoice)}
      termsDescription={renderTermsOfServiceDescription}
      formProps={{
        extraValidation: {
          date:
            plan && plan.date_required
              ? Yup.object().nullable().required('Date is required')
              : undefined,
        },
        extraInitialValues: {
          date: plan && plan.date_required ? null : undefined,
        },
      }}
    />
  )
}

PublicDayPass.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  isFetching: PropTypes.bool,
  coworks_community_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  theme: PropTypes.object,
  publicCommunity: PropTypes.object.isRequired,
  publicCampus: PropTypes.object,
}

PublicDayPass.displayName = 'PublicDayPass'

function mapStateToProps(state) {
  return {
    isFetching: state.ui && state.ui.isFetching ? state.ui.isFetching : false,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    coworks_community_actions: bindActionCreators(
      coworksCommunityActions,
      dispatch
    ),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PublicDayPass
)
