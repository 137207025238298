import { SET_DASHBOARD_STATS } from '../reduxActions/actionTypes'

export default function(stats = [], action) {
  switch (action.type) {
    case SET_DASHBOARD_STATS:
      return { ...stats, dashboard: action.stats }
    default:
      return stats
  }
}
