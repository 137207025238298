/* eslint-disable no-invalid-this,max-statements,quote-props */
/* eslint-disable no-invalid-this,max-statements,quote-props */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Nav, NavItem, Input, Button, Row, Alert } from 'reactstrap'

import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import { ButtonStyled } from '@global/Base/Button/ButtonStyled'
import PillToggle from '@global/Base/Toggle/PillToggle'
import {
  LabelStyled,
  FontAwesomeIconLabel,
  SingleDatePicker,
} from '@global/Form/FormComponents'
import DateTime from 'react-datetime'
import * as bookingActions from '../../reduxActions/bookingActions'
import * as memberActions from '../../reduxActions/memberActions'
import * as roomActions from '../../reduxActions/roomActions'
import * as checkinActions from '@app/reduxActions/checkinActions'
import * as googleCalendarActions from '../../reduxActions/googleCalendarActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'
import GenericModal from '@global/GenericModal'
import Checkbox from '@bit/coworks.base-components.checkbox'
import { captureException } from '@global/ErrorFactory'

import Switch from '@global/Base/Switch/Switch'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import MyStatefulEditor from '@global/MyStatefulEditor'

import * as Constants from '@global/Constants'
import Tooltip from '@bit/coworks.base-components.tooltip'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import conferencePlaceholder from '../../img/placeholders/conference_room.jpg'
import * as _ from 'lodash'

import moment from 'moment'
const momentTZ = require('moment-timezone')

require('react-datetime')
import { toast } from 'react-toastify'
import { FormErrors } from '@global/FormErrorsComponent'
import CoworksSearch from '@global/Search/CoworksSearch'
import RoomSearchRow from '@global/Search/SearchRowComponents/RoomSearchRow'

import MemberRow from '../Directory/Members/MemberRow'
import Spinner from '@global/Spinner'
// import ConfirmAlert from '@components/Global/ConfirmAlert'
import { extractCoworksErrorObject } from '@global/ErrorFactory'

//TODO: Graceful error handling of the google api.
//TODO: refactor these api calls.. annoying as fuck.
const populateEventInfo = event => {
  const val = event ? event : {}
  const hasEvent = Boolean(event)
  const {
    type = 'Booking',
    description_html = '',
    notes = '',
    location = '',
    name = '',
    now = moment(),
    remainder = 30 - (now.clone().minute() % 30),
    start = now.clone().add(remainder, 'm'),
    end = start.clone().add(1, 'h'),
    event_photo = null,
    user = null,
    room = null,
    gcal_event_id = '',
    gcal_calendar_id = '',
    external_information_url = null,
    external_registration_url = null,
    attendee_max = null,
    contact = null,
    attendee_join_deadline = null,
    room_id = null,
    invoice_id = null,
    invoice_amount = null,
  } = _.omitBy(val, _.isNil)

  let selectedCalendarId,
    showOnGoogle = null
  if (gcal_calendar_id) {
    selectedCalendarId = gcal_calendar_id
    showOnGoogle = true
  }

  return {
    event: hasEvent ? event : null,
    name,
    notes,
    contact,
    location,
    room,
    user,
    type,
    invoiceId: invoice_id,
    invoiceAmount: invoice_amount,
    description_html,
    gCalEventId: gcal_event_id,
    gCalCalendarId: gcal_calendar_id,
    selectedCalendarId,
    showOnGoogle,
    externalInformationLink: external_information_url,
    externalRegistrationLink: external_registration_url,
    attendeeMax: attendee_max,
    isPrivate: val.private,
    pageTitle: `Edit ${type}`,
    startDate: moment(start),
    endDate: moment(end),
    onCampus: room_id || type === 'Booking' ? 'true' : 'false',
    photo: event_photo && event_photo.file_url ? event_photo.file_url : null,
    showDelete: hasEvent,
    attendeeJoinDeadline: attendee_join_deadline
      ? moment(attendee_join_deadline)
      : null,
  }
}

class EventForm extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    booking_actions: PropTypes.object,
    room_actions: PropTypes.object,
    member_actions: PropTypes.object,
    google_actions: PropTypes.object,
    checkin_actions: PropTypes.object,
    bookings: PropTypes.array,
    event: PropTypes.object,
    rooms: PropTypes.array,
    user: PropTypes.object,
    isAuthorizedWithGoogle: PropTypes.bool,
    googleCalendars: PropTypes.array,
    lastUsedCalendarId: PropTypes.string,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    isFetching: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    const { history } = props

    const linkedIn = ''
    const twitter = ''
    const cancelInvoice = false
    const showImageUploadComponent = true
    const space = {}
    const now = moment()
    const yesterday = now.clone().subtract(1, 'day')
    const photoChanged = false
    const creator = {}
    const showPageTitle = true
    const attendees = []
    const errors = []
    let event = this.props.event

    if (history.location.state && history.location.state.event) {
      event = history.location.state.event
    }

    const eventState = this.extractEventState(event)

    const currencySymbol = Constants.getCurrencySymbolFromCommunity(
      props.activeCommunity
    )

    this.state = {
      event,
      space,
      location,
      linkedIn,
      twitter,
      cancelInvoice,
      photoChanged,
      creator,
      attendees,
      showImageUploadComponent,
      showPageTitle,
      errors,
      formErrors: { Name: '' },
      nameValid: false,
      formValid: false,
      alertModal: false,
      isGoogleApiReady: false,
      yesterday,
      currencySymbol,
      equipmentName:
        props.activeCommunity.community_preference.equipment_resource_name,
      ...eventState,
    }
  }

  // UNSAFE_componentWillMount() {
  //   if (
  //     this.state.event &&
  //     !this.state.event.created_at &&
  //     !this.props.isFetching
  //   ) {
  //     this.props.booking_actions
  //       .getBookingThick(this.state.event.id)
  //       .then(res => {
  //         console.log('fetched event', res.booking)
  //         const eventState = this.extractEventState(res.booking)
  //         this.setState({ ...eventState })
  //       })
  //       .catch(err => {
  //         toast.error('There was a problem loading this booking')
  //       })
  //   }
  // }

  componentDidMount() {
    const { rooms } = this.props
    this.props.google_actions
      .injectGApiScript(10)
      .then(gApi => {
        this.setState({ googleApi: gApi })
        this.props.google_actions
          .initializeGApi(gApi)
          .then(() => {
            this.setState({ isGoogleApiReady: true })
            this.props.google_actions
              .checkGoogleLoginStatus(gApi)
              .then(() => {
                this.setState(
                  { isAuthorizedWithGoogle: true },
                  this.props.google_actions.getCalendars(
                    this.state.googleApi,
                    {}
                  )
                )
              })
              .catch(() => {
                // err
                // Not logged into google. That's fine!
                this.setState({ showOngoogle: false })
              })
          })
          .catch(err => {
            // err
            console.log('Error initializing the Gapi scripts', err)
          })
      })
      .catch(err => {
        console.log('Error injecting the Gapi scripts', err)
        this.setState({ isGoogleApiReady: false })
      })
    if (!rooms || (Array.isArray(rooms) && rooms.length === 0)) {
      this.props.room_actions.getRoomsAtCampus(this.props.activeCampus.id, {
        types: JSON.stringify(['Conference', 'Space', 'Equipment']),
        serializer: 'RoomSerializer',
      })
    }
  }

  extractEventState = event => {
    const {
      history,
      googleCalendars,
      lastUsedCalendarId,
      activeCommunity,
      user,
    } = this.props
    let slotInfo = {}
    const eventState = populateEventInfo(event)

    // Handle getting the time from a dragged state event
    if (history.location.state && history.location.state.intendedType) {
      // console.log(
      //   eventState.type,
      //   eventState,
      //   history.location.state.intendedType
      // )
      eventState.type = history.location.state.intendedType
      eventState.pageTitle = `New ${eventState.type}`
    }

    if (!eventState.event) {
      eventState.user = user
      eventState.isPrivate = !activeCommunity.community_preference
        .event_visibility_default

      if (history.location.state && history.location.state.slotInfo) {
        slotInfo = history.location.state.slotInfo

        eventState.startDate = moment(slotInfo.start.toISOString())
        eventState.endDate = moment(slotInfo.end.toISOString())
        eventState.pageTitle = `New ${eventState.type}`
      }
    }

    if (!eventState.selectedCalendarId) {
      eventState.selectedCalendarId = lastUsedCalendarId
      if (
        !eventState.selectedCalendarId &&
        googleCalendars &&
        googleCalendars.length > 0
      ) {
        eventState.selectedCalendarId = googleCalendars[0].id
      }
    }

    return eventState
  }

  toggle = () => this.setState({ alertModal: !this.state.alertModal })

  handleStartDateTimeChanged = mom => {
    const newState = {
      startDate: mom,
    }
    const endDate = this.state.endDate

    if (
      mom &&
      endDate &&
      moment(endDate).startOf('day').isSameOrBefore(moment(mom).startOf('day'))
    ) {
      // adjust the DATE if end date is before start date
      newState.endDate = moment(mom).set({
        year: mom.get('year'),
        month: mom.get('month'),
        date: mom.get('date'),
        hour: endDate.get('hour'),
        minute: endDate.get('minute'),
        second: endDate.get('second'),
        millisecond: endDate.get('millisecond'),
      })
      // adjust by 1 hour if end time is now earlier than start time
      if (moment(newState.endDate).isSameOrBefore(moment(mom))) {
        newState.endDate = moment(newState.endDate).set({
          hour: mom.get('hour') + 1,
        })
      }
    }
    this.setState(newState)
  }
  handleEndDateTimeChanged = mom => {
    this.setState({
      endDate: mom,
    })
  }
  handleAttendeeJoinDeadlineChanged = mom => {
    this.setState({
      attendeeJoinDeadline: mom ? mom.endOf('day') : mom,
    })
  }

  handleCalendarDropdownChange = event => {
    const stateObj = {
      selectedCalendarId: event.target.value,
    }
    if (this.state.event) {
      if (this.state.event.gcal_calendar_id !== event.target.value) {
        stateObj.hasCalendarChanged = true
      } else {
        stateObj.hasCalendarChanged = false
      }
    } else {
      stateObj.hasCalendarChanged = true
    }
    this.setState(stateObj)
  }

  isValidStartDate = current => {
    return current.isAfter(this.state.yesterday)
  }
  isValidEndDate = current => {
    return current.isSameOrAfter(this.state.startDate)
  }

  goBack = () => {
    this.props.history.goBack()
  }

  resolveGoogleCalendarError(error) {
    if (
      error &&
      error.result &&
      error.result.error &&
      error.result.error.message
    ) {
      toast.error(`Google Calendar error: ${error.result.error.message}`)
    } else {
      toast.error('There was a problem with Google Calendar')
    }
  }
  isValid = () => {
    if (
      this.state.type === 'Booking' &&
      !this.state.room &&
      this.state.room &&
      !this.state.room.id
    ) {
      this.setState({
        formValid: false,
        formErrors: { Error: 'Cannot create a booking without a room' },
      })
      return false
    }
    if (!this.state.startDate) {
      this.setState({
        formValid: false,
        formErrors: { Error: 'Must have a start time' },
      })
      return false
    }
    if (!this.state.endDate) {
      this.setState({
        formValid: false,
        formErrors: { Error: 'Must have an end time' },
      })
      return false
    }
    if (this.state.startDate.isAfter(this.state.endDate)) {
      this.setState({
        formValid: false,
        formErrors: { Error: 'Start time must come before end time' },
      })
      return false
    }
    return true
  }
  // This is the create event that points to the correct calls.
  initiateCreatingEvent = () => {
    const obj = {
      start_time: this.state.startDate
        ? this.state.startDate.toISOString()
        : null,
      end_time: this.state.endDate ? this.state.endDate.toISOString() : null,
      name: this.state.name,
      description_html: this.state.description_html,
      type: this.state.type,
      room_id: this.state.room && this.state.room.id ? this.state.room.id : '',
      location: this.state.location ? this.state.location : '',
      user_id:
        this.state.user && this.state.user.id
          ? this.state.user.id
          : this.props.user.id,
      original_user_id: this.props.user.id,
      campus_id: this.props.activeCampus.id,
      notes: this.state.notes ? this.state.notes : '',
      private: this.state.isPrivate,
      booked_by_client_type: 'web',
      allow_collision: false,
      timezone_identifier: momentTZ.tz.guess(),
      external_information_url: this.state.externalInformationLink,
      external_registration_url: this.state.externalRegistrationLink,
      attendee_max: this.state.attendeeMax,
      attendee_join_deadline: this.state.attendeeJoinDeadline,
      /*event_url: ,
      show_attendees: ,*/
    }
    if (this.state.photoChanged) {
      obj.event_photo = this.state.photo ? this.state.photo : ''
    }
    if (!this.isValid()) return

    // This will create the event in google and pass the id to the final coworks create booking call
    if (
      this.state.showOnGoogle &&
      this.state.googleApi &&
      this.state.isAuthorizedWithGoogle
    ) {
      this.createCoworksEvent(obj).then(coworksBooking => {
        this.createGoogleEvent(coworksBooking)
          .then(coworksBookingAfterCalendars => {
            obj.gcal_event_id = coworksBookingAfterCalendars.gcal_event_id
            obj.gcal_calendar_id = coworksBookingAfterCalendars.gcal_calendar_id
            obj.id = coworksBooking.id
            delete obj.timezone_identifier
            this.updateCoworksEvent(obj)
              .then(() => {
                console.log('Updated coworks event with google calendar stuff.')
              })
              .catch(error => {
                console.log(error)
                //todo toast?
              })
          })
          .catch(error => {
            this.resolveGoogleCalendarError(error)
            //todo toast?
          })
      })
    } else {
      this.createCoworksEvent(obj)
    }
  }

  // This is the wrapper update method that directs to the appropriate calls.
  initiateUpdatingEvent = () => {
    if (!this.isValid()) return

    const {
      startDate,
      endDate,
      name,
      description_html,
      type,
      user,
      event,
      notes,
      isPrivate,
      gCalEventId,
      gCalCalendarId,
      externalInformationLink,
      externalRegistrationLink,
      attendeeMax,
      attendeeJoinDeadline,
      onCampus,
      location,
      room,
      photo,
    } = this.state

    const obj = {
      start_time: startDate.toISOString(),
      end_time: endDate.toISOString(),
      name: name,
      description_html: description_html ? description_html : '',
      type: type,
      user_id: user.id,
      original_user_id: this.props.user.id,
      campus_id: this.props.activeCampus.id,
      id: event.id,
      notes: notes ? notes : '',
      private: isPrivate,
      gcal_event_id: gCalEventId,
      gcal_calendar_id: gCalCalendarId,
      allow_collision: false,
      external_information_url: externalInformationLink,
      external_registration_url: externalRegistrationLink,
      attendee_max: attendeeMax,
      attendee_join_deadline: attendeeJoinDeadline,
      /*event_url: ,
      show_attendees: ,*/
    }
    // location / room_id logic
    if (onCampus === 'false') {
      //
      obj.location = location
    } else {
      obj.room_id = room && room.id ? room.id : ''
    }
    if (this.state.photoChanged) {
      // Need to update the photo
      obj.event_photo = photo ? photo : ''
    }

    if (
      !this.state.showOnGoogle &&
      obj.gcal_event_id &&
      obj.gcal_calendar_id &&
      this.state.isAuthorizedWithGoogle
    ) {
      // Delete the event from google
      this.deleteGoogleEvent(obj)
        .then(response => {
          obj.gcal_event_id = ''
          obj.gcal_calendar_id = ''
          this.updateCoworksEvent(obj)
        })
        .catch(err => {
          console.error('Error deleting google event', err)
          if (err.status === 410) {
            // This means that the resource has already been deleted.
            // remove it from coworks.
            obj.gcal_event_id = ''
            obj.gcal_calendar_id = ''
            this.updateCoworksEvent(obj)
          } else {
            toast.error('Error removing event from google.')
            this.updateCoworksEvent(obj)
          }
        })
    } else if (
      this.state.showOnGoogle &&
      obj.gcal_event_id &&
      obj.gcal_calendar_id &&
      this.state.isAuthorizedWithGoogle
    ) {
      // User intention is to Edit the google calendar event with new information
      if (this.state.hasCalendarChanged) {
        // This catches a change in calendars before making the update
        this.props.google_actions
          .moveGoogleEvent(
            this.state.googleApi,
            obj.gcal_calendar_id,
            obj.gcal_event_id,
            this.state.selectedCalendarId
          )
          .then(gcalEvent => {
            this.updateGoogleEvent(obj).then(() => {
              this.updateCoworksEvent(obj)
            })
          })
          .catch(err => {
            console.log('Error moving the event to a new calendar', err)
            this.updateCoworksEvent(obj)
          })
      } else {
        // Calendar did not change so update the google event and then update coworks event.
        this.updateGoogleEvent(obj)
          .then(gCalResponse => {
            this.updateCoworksEvent(obj)
          })
          .catch(() => {
            // if this event doesn't exist, google brings it back from the grave
            this.updateCoworksEvent(obj)
          })
      }
    } else if (
      this.state.showOnGoogle &&
      !obj.gcal_event_id &&
      !obj.gcal_calendar_id &&
      this.state.isAuthorizedWithGoogle
    ) {
      // This catches if there is already a coworks event created, but it wasn't ever pushed to google before
      this.createGoogleEvent(obj)
        .then(coworksObj => {
          this.updateCoworksEvent(coworksObj)
        })
        .catch(err => {
          console.log('Error creating google event', err)
          // todo toast?
        })
    } else {
      // Not authenticated with google or missing the gcal_event_id or gcal_calendar_id
      this.updateCoworksEvent(obj)
    }
  }

  createCoworksEvent = obj => {
    return new Promise((resolve, reject) => {
      this.props.booking_actions
        .createBooking(obj)
        .then(response => {
          this.navigate()
          let type = 'booking'
          if (response.booking.type === 'Event') {
            type = 'event'
          }
          toast.success(`Created ${type}.`)
          resolve(response.booking)
        })
        .catch(err => {
          const [message, context] = extractCoworksErrorObject(err, {
            newFormat: true,
          })
          toast.error(message)
          reject(err)
        })
    })
  }
  updateCoworksEvent = obj => {
    return new Promise((resolve, reject) => {
      this.props.booking_actions
        .updateBooking(obj)
        .then(response => {
          toast.success(
            `Updated ${
              response && response.booking && response.booking.type === 'Event'
                ? 'Event'
                : 'Booking'
            }.`
          )
          this.navigate()
          resolve(response.booking)
        })
        .catch(err => {
          const [message, context] = extractCoworksErrorObject(err, {
            newFormat: true,
          })
          console.log(message, context)
          toast.error(message)
          reject(err)
        })
    })
  }
  onArchiveClicked = () => {
    if (this.state.contact) {
      this.toggle()
    } else {
      this.archiveCoworksEvent()
    }
  }
  archiveCoworksEvent = async () => {
    const { cancelInvoice, event } = this.state
    const obj = event
    const newObj = {
      id: obj.id,
      is_archived: true,
      refund_invoice: cancelInvoice,
    }

    // delete google event first if possible.
    try {
      if (
        obj.gcal_event_id &&
        obj.gcal_calendar_id &&
        this.state.isAuthorizedWithGoogle
      ) {
        const deleteGoogleEventResponse = await this.deleteGoogleEvent(obj)
      }
    } catch (exception) {
      toast.error('There was a problem deleting the google event.')
      captureException({ error: exception })
    }
    // update the booking object after!
    this.props.booking_actions
      .updateBooking(newObj)
      .then(response => {
        let type = 'Booking'
        if (response && response.booking && response.booking.type === 'Event') {
          type = 'Event'
        }
        // todo: add context for if a refund was done.
        let toastMessage = `Cancelled ${type}`
        if (response.refund && response.refund.status === 'succeeded') {
          //todo check status of refund and confirm
          toastMessage += ` and refunded ${this.state.currencySymbol}${Number(
            response.refund.amount / 100
          ).toFixed(2)}`
        }
        toast.success(toastMessage)
        this.navigate()
      })
      .catch(err => {
        this.setState({ errors: err })
        toast.error(this.state.errors.message)
      })
  }

  createGoogleEvent = obj => {
    return new Promise((resolve, reject) => {
      let location = this.state.room ? this.state.room.name : ''
      if (!location) {
        location = this.state.location ? this.state.location : ''
      }
      const googleEvent = {
        summary: obj.name,
        location: location,
        description: obj.description_html,
        start: {
          dateTime: obj.start_time,
        },
        end: {
          dateTime: obj.end_time,
        },
        //recurrence: [],
        //attendees: [],
        reminders: {
          useDefault: true,
        },
      }
      let calId = this.state.selectedCalendarId
      if (!calId) {
        calId =
          this.props.googleCalendars && this.props.googleCalendars.length > 0
            ? this.props.googleCalendars[0].id
            : ''
      }
      this.props.google_actions
        .createGoogleEvent(this.state.googleApi, calId, googleEvent)
        .then(gEvent => {
          const objCopy = JSON.parse(JSON.stringify(obj))
          objCopy.gcal_event_id = gEvent.id
          objCopy.gcal_calendar_id = this.state.selectedCalendarId
          console.log('Created gcal event', gEvent)
          resolve(objCopy)
        })
        .catch(err => {
          console.log('Error creating gcal event', err)
          //todo toast?
          reject(err)
        })
    })
  }
  updateGoogleEvent = obj => {
    return new Promise((resolve, reject) => {
      let location = this.state.room ? this.state.room.name : ''
      if (!location) {
        location = this.state.location ? this.state.location : ''
      }
      const googleEvent = {
        summary: obj.name,
        location: location,
        description: obj.description_html,
        start: {
          dateTime: obj.start_time,
        },
        end: {
          dateTime: obj.end_time,
        },
        //recurrence: [],
        //attendees: [],
        reminders: {
          useDefault: true,
        },
      }
      this.props.google_actions
        .updateGoogleEvent(
          this.state.googleApi,
          obj.gcal_calendar_id,
          obj.gcal_event_id,
          googleEvent
        )
        .then(gCalResponse => {
          console.log('Sucessfully udpated google cal event')
          resolve(gCalResponse)
        })
        .catch(err => {
          console.log('Error updating gcal event', err)
          reject(err)
        })
    })
  }
  deleteGoogleEvent = async obj => {
    return new Promise((resolve, reject) => {
      this.props.google_actions
        .deleteGoogleEvent(
          this.state.googleApi,
          obj.gcal_calendar_id,
          obj.gcal_event_id
        )
        .then(response => {
          console.log('deleted', response)
          const objCopy = JSON.parse(JSON.stringify(obj))
          objCopy.gcal_event_id = ''
          objCopy.gcal_calendar_id = ''
          resolve(objCopy)
        })
        .catch(err => {
          console.log('Error deleting google event', err)
          //todo toast?
          reject(err)
        })
    })
  }
  handleImageUpload = image => {
    this.setState({
      photoChanged: true,
      photo: image,
    })
  }

  navigate = () => {
    this.props.history.push('/calendar', {
      returnToDate: this.state.startDate.toISOString(),
    })
  }
  /*
      Start of form validation -- don't forget state variables and component in render()
  */
  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value
    const values = { [name]: value }
    if (name === 'type') {
      values.pageTitle = `Edit ${value}`
      if (value === 'Booking') {
        // bookings should always be "public" because it doesn't mean anything right now.
        values.isPrivate = false
      } else {
        // when changing from a booking type to event, we want to make sure to set default
        values.isPrivate = !this.props.activeCommunity.community_preference
          .event_visibility_default
      }
    }
    this.setState(values, () => {
      this.validateField(name, value)
    })
  }
  handleDescriptionChange = content => {
    this.setState({ description_html: content }, () => {
      this.validateField('description_html', content)
    })
  }
  handleLocationToggle = onCampus => {
    this.setState({ onCampus, room: null, location: null }, () => {
      this.validateField('onCampus', onCampus)
    })
  }
  validateField = (fieldName, value) => {
    const fieldValidationErrors = this.state.formErrors
    let nameValid = this.state.nameValid

    switch (fieldName) {
      case 'name':
        nameValid = value.length > 0
        fieldValidationErrors.Name = nameValid ? '' : ' is required'
        break
      default:
        break
    }
    this.setState(
      { formErrors: fieldValidationErrors, nameValid },
      this.validateForm
    )
  }
  validateForm = () => {
    this.setState({ formValid: this.state.nameValid })
  }
  /*
    End of form validation
  */
  /* For rendering the rooms material in the react-search */
  handleRoomSearchItemSelected = room => {
    this.setState({ room, formValid: true, formErrors: {} })
  }
  handleRoomSearchItemCleared = () => {
    this.setState({ room: null, formValid: false, formErrors: {} })
  }
  roomDataResolver = response => {
    return response.rooms
  }

  renderRoomSearchRow = room => (
    <RoomSearchRow room={room} equipmentName={this.state.equipmentName} />
  )

  /* For rendering the members material in the react-search */
  handleMemberSearchItemSelected = user => {
    this.setState({ user, formValid: true, formErrors: {} })
  }
  handleMemberSearchItemCleared = () => {
    this.setState({ user: null, formValid: false, formErrors: {} })
  }
  memberDataResolver = response => {
    return response.members
  }
  renderMemberSearchRow = object => {
    return (
      <MemberRow
        key={object.id}
        member={object}
        team={object.teams ? object.teams[0] : null}
        index={object.id}
        showEdit={false}
        showActions={false}
        teamLinkClicked={this.teamLinkClicked}
        //editMemberClicked={this.editMemberClicked}
        // memberCheckInClicked={this.props.checkin_actions.createCheckIn}
        // memberCheckOutClicked={this.props.checkin_actions.checkOutMember}
        includeMarginsAndPadding
        includePhoto={false}
      />
    )
  }

  renderImageUploadComponent() {
    if (this.state.showImageUploadComponent) {
      return (
        <div className="upload-photo-global">
          <ImageUploadComponent
            aspectRatio={2 / 1}
            imageUrl={this.state.photo ? this.state.photo : ''}
            onUpload={this.handleImageUpload}
            ref="imageUploadComponent"
          />
        </div>
      )
    }
    return (
      <img
        src={this.state.photo}
        alt={this.state.event.title}
        className="z-depth-1"
      />
    )
  }
  renderGoogleFormComponents = () => (
    <Row>
      <div className="col-6 col-lg-4">
        <LabelStyled>Push to Google Calendar?</LabelStyled>
        <div className="form-group">
          {/* activeLabel={<FontAwesomeIcon icon={['fab', 'google']} />}
              inactiveLabel={<FontAwesomeIcon icon="times" />} */}
          <Switch
            value={this.state.showOnGoogle}
            onChange={value => {
              this.setState({
                showOnGoogle: !this.state.showOnGoogle,
              })
            }}
          />
        </div>
      </div>

      {this.state.showOnGoogle && (
        <div className="col-6">
          <LabelStyled>Google Calendar</LabelStyled>
          <select
            id="calendar"
            name="calendar"
            className="form-control"
            onChange={this.handleCalendarDropdownChange}
            value={this.state.selectedCalendarId}
          >
            {this.props.googleCalendars &&
              this.props.googleCalendars.map((calendar, i) => {
                return (
                  <option key={i} value={calendar.id}>
                    {calendar.summary}
                  </option>
                )
              })}
          </select>
        </div>
      )}
    </Row>
  )
  /* eslint-disable max-len */
  render() {
    const { type, contact, invoiceAmount, invoiceId } = this.state
    const isGuestBooking = Boolean(contact)
    if (this.props.isFetching) {
      return <Spinner />
    }
    return (
      <div id="event-form" className="animated fadeIn card">
        <div className="card-header">
          <div className="row">
            <div className="col-12">
              <h2>{this.state.pageTitle}</h2>
            </div>
          </div>
        </div>
        <div className="card-body p-3">
          <div className="row py-2 border-bottom">
            <div className="col-md-8 offset-md-2">
              {type === 'Event' && this.renderImageUploadComponent()}
              <form>
                {isGuestBooking && (
                  <Alert color="warning" className={'mb-3'} isOpen>
                    This was a booking made by a guest externally and the time
                    cannot be modified.
                  </Alert>
                )}
                <div className="row my-1">
                  <div className="col-md-8">
                    <LabelStyled>{type} Name</LabelStyled>
                    <div className="form-group">
                      <Input
                        name={'name'}
                        type={'text'}
                        value={this.state.name}
                        label={'name'}
                        className={'w-100'}
                        onChange={this.handleInputChange}
                        placeholder={
                          type === 'Event' ? 'New Event' : 'New Booking'
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <LabelStyled>Type</LabelStyled>
                    <div className="form-group">
                      <select
                        name="type"
                        type="text"
                        value={type}
                        label="type"
                        className={'w-100 form-control'}
                        onChange={this.handleInputChange}
                      >
                        <option value="Event">Event</option>
                        <option value="Booking">Booking</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    opacity: isGuestBooking ? 0.5 : 1,
                  }}
                >
                  <div className="row my-1">
                    <div className="col-12">
                      <div className="form-group row">
                        <div className="col-4">
                          <LabelStyled>Start Date</LabelStyled>
                          <br />
                          <SingleDatePicker
                            id="start_date_calendar"
                            date={this.state.startDate}
                            disabled={isGuestBooking}
                            onDateChange={momentDate => {
                              // ensure that we don't fuck with time that was set previously
                              const time = this.state.startDate
                              if (momentDate && time) {
                                momentDate.set({
                                  hour: time.get('hour'),
                                  minute: time.get('minute'),
                                  second: time.get('second'),
                                })
                              }
                              this.handleStartDateTimeChanged(momentDate)
                            }}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) =>
                              this.setState({ focused })
                            }
                            showClearDate={false}
                            placeholder={'Start Date'}
                          />
                        </div>
                        <div className="col-6">
                          <LabelStyled>Start Time</LabelStyled>
                          <DateTime
                            name="startDate"
                            mode="time"
                            dateFormat={false}
                            value={this.state.startDate}
                            timeFormat="hh:mm a"
                            isValidDate={this.isValidStartDate}
                            inputProps={{
                              readOnly: true,
                              disabled: isGuestBooking,
                              style: { backgroundColor: 'white' },
                            }}
                            timeConstraints={{
                              hours: { max: 23, step: 1 },
                              minutes: { max: 59, step: 5 },
                            }}
                            onChange={this.handleStartDateTimeChanged}
                          />
                        </div>
                        {/*<div className='col-4'>
                        <div className='control-group'>
                          <label className='control-label'>Duration</label>
                          <div className='controls'>
                            <select name='duration' className='form-control' value={this.state.duration} onChange={this.handleDurationChanged}>
                              <option value=''>Select a duration</option>
                              {this.renderDurations()}
                            </select>
                          </div>
                        </div>
                      </div>*/}
                      </div>
                    </div>
                  </div>
                  <div className="row my-1">
                    <div className="col-12">
                      <div className="form-group row">
                        <div className="col-4">
                          <LabelStyled>End Date</LabelStyled>
                          <br />
                          <SingleDatePicker
                            id="end_date_calendar" // PropTypes.string.isRequired,
                            date={this.state.endDate} // momentPropTypes.momentObj or null
                            disabled={isGuestBooking}
                            onDateChange={momentDate => {
                              // ensure that we don't fuck with time that was set previously
                              const time = this.state.endDate
                              if (momentDate && time) {
                                momentDate.set({
                                  hour: time.get('hour'),
                                  minute: time.get('minute'),
                                  second: time.get('second'),
                                })
                              }
                              this.handleEndDateTimeChanged(momentDate)
                            }} // PropTypes.func.isRequired
                            focused={this.state.endDateFocused} // PropTypes.bool
                            onFocusChange={({ focused }) =>
                              this.setState({ endDateFocused: focused })
                            } // PropTypes.func.isRequired
                            showClearDate={false}
                            // isOutsideRange={() => {
                            //   return !this.isValidEndDate(this.state.endDate)
                            // }}
                            placeholder={'End Date'}
                          />
                        </div>
                        <div className="col-6">
                          <LabelStyled>End Time</LabelStyled>
                          <DateTime
                            name="endDate"
                            mode="time"
                            value={this.state.endDate}
                            dateFormat={false}
                            timeFormat="hh:mm a"
                            isValidDate={this.isValidEndDate}
                            inputProps={{
                              readOnly: true,
                              disabled: isGuestBooking,
                              style: { backgroundColor: 'white' },
                            }}
                            timeConstraints={{
                              hours: { max: 23, step: 1 },
                              minutes: { max: 59, step: 5 },
                            }}
                            onChange={this.handleEndDateTimeChanged}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-12">
                    <div className="form-group">
                      <LabelStyled>Description</LabelStyled>
                      <MyStatefulEditor
                        onChange={content =>
                          this.handleDescriptionChange(content)
                        }
                        content={this.state.description_html}
                        placeholder={`Write ${
                          type === 'Event' ? 'an event' : 'a booking'
                        } description here.`}
                        style={{ zIndex: 3 }}
                        className={'form-control w-100 '}
                      />
                    </div>
                  </div>
                </div>
                {type === 'Event' && (
                  <LabelStyled>
                    Will this {type} take place in{' '}
                    {this.props.activeCampus.name}?
                  </LabelStyled>
                )}
                <div className="row">
                  {type === 'Event' && (
                    <React.Fragment>
                      <Nav pills className="mb-3 ml-3">
                        <NavItem>
                          <PillToggle
                            className={classnames({
                              active: this.state.onCampus === 'true',
                            })}
                            onClick={() => this.handleLocationToggle('true')}
                          >
                            On my campus
                          </PillToggle>
                        </NavItem>
                        <NavItem>
                          <PillToggle
                            className={classnames({
                              active: this.state.onCampus === 'false',
                            })}
                            onClick={() => this.handleLocationToggle('false')}
                          >
                            Outside of my campus
                          </PillToggle>
                        </NavItem>
                      </Nav>
                    </React.Fragment>
                  )}
                  <div
                    className="col-md-12"
                    style={{
                      opacity: isGuestBooking ? 0.5 : 1,
                    }}
                  >
                    <div className="row">
                      <div className="col-md-8">
                        {this.state.onCampus === 'true' ? (
                          <div className="form-group">
                            <LabelStyled>Room name</LabelStyled>
                            <CoworksSearch
                              isSearch={false}
                              placeholder={'Search Rooms'}
                              renderSingleValue={Constants.renderRoomSelected}
                              defaultData={this.props.rooms}
                              actions={this.props.room_actions}
                              otherParams={{
                                types: JSON.stringify([
                                  'Conference',
                                  'Space',
                                  'Equipment',
                                ]),
                              }}
                              activeCampus={this.props.activeCampus}
                              disabled={isGuestBooking}
                              handleItemSelected={
                                this.handleRoomSearchItemSelected
                              }
                              handleSearchCleared={
                                this.handleRoomSearchItemCleared
                              }
                              dataResolver={this.roomDataResolver}
                              value={this.state.room}
                              isClearable
                              renderSearchRow={this.renderRoomSearchRow}
                            />
                          </div>
                        ) : (
                          <div className="form-group">
                            <LabelStyled>Location Name</LabelStyled>
                            <Input
                              placeholder="Location Name"
                              className="form-control"
                              name="location"
                              disabled={isGuestBooking}
                              value={this.state.location}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {type === 'Event' && (
                  <div className="row my-1">
                    <div className="col-6" id="publicToggle">
                      <LabelStyled>Show this to members?</LabelStyled>
                      <Tooltip
                        tooltipContent={
                          'This will determine if this event is visible in the mobile apps.'
                        }
                        buttonText={'Change default'}
                        header={`Event Visibility`}
                        handleClick={() => {
                          this.props.history.push(
                            '/community/settings/calendar'
                          )
                        }}
                      >
                        {' '}
                        <FontAwesomeIconLabel icon="question-circle" />
                      </Tooltip>
                    </div>
                    <div className="col-12">
                      <Nav pills className="mb-3">
                        <NavItem>
                          <PillToggle
                            className={classnames({
                              active: !this.state.isPrivate,
                            })}
                            onClick={() => {
                              this.setState({
                                isPrivate: false,
                              })
                            }}
                          >
                            {' '}
                            Show
                          </PillToggle>
                        </NavItem>
                        <NavItem>
                          <PillToggle
                            className={classnames({
                              active: this.state.isPrivate,
                            })}
                            onClick={() => {
                              this.setState({
                                isPrivate: true,
                              })
                            }}
                          >
                            {' '}
                            <FontAwesomeIcon icon="lock" /> Hide
                          </PillToggle>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                )}
                {this.state.isAuthorizedWithGoogle &&
                  this.renderGoogleFormComponents()}
                {type === 'Event' && (
                  <div className="row my-1">
                    <div className="col-8">
                      <LabelStyled>Event Information Link</LabelStyled>
                      <div className="form-group">
                        <Input
                          type="url"
                          placeholder="www.eventinformation.com"
                          className="form-control mb-2"
                          name="externalInformationLink"
                          value={this.state.externalInformationLink}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <LabelStyled>Event Registration Link</LabelStyled>
                      <div className="form-group">
                        <Input
                          type="url"
                          placeholder="www.registration.com"
                          className="form-control"
                          name="externalRegistrationLink"
                          value={this.state.externalRegistrationLink}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <LabelStyled>Attendee Join Deadline Date</LabelStyled>
                      <br />
                      <SingleDatePicker
                        id="attendee_join_date_calendar" // PropTypes.string.isRequired,
                        date={this.state.attendeeJoinDeadline}
                        onDateChange={this.handleAttendeeJoinDeadlineChanged} // PropTypes.func.isRequired
                        focused={this.state.attendeeJoinDateFocused}
                        onFocusChange={({ focused }) =>
                          this.setState({ attendeeJoinDateFocused: focused })
                        }
                        showClearDate
                        placeholder={'Deadline Date'}
                      />
                    </div>
                    <div className="col-4">
                      <LabelStyled>Attendee Max</LabelStyled>
                      <div className="form-group">
                        <Input
                          name="attendeeMax"
                          type="number"
                          value={this.state.attendeeMax}
                          label={'attendeeMax'}
                          className={'w-100'}
                          onChange={this.handleInputChange}
                          placeholder={'Max number of attendees'}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row my-1">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-1">
                        <LabelStyled>Notes</LabelStyled>
                      </div>
                      <div className="col-11">
                        <p className="text-muted">
                          Notes are private and will not show on the mobile app.
                        </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        name="notes"
                        value={this.state.notes}
                        className="form-control"
                        rows="3"
                        placeholder="Write any notes here."
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    opacity: isGuestBooking ? 0.5 : 1,
                  }}
                >
                  <LabelStyled>Owner</LabelStyled>
                  <div className="row my-2">
                    <div className="col-md-8">
                      <CoworksSearch
                        isSearch={false}
                        placeholder={'Search Members'}
                        renderSingleValue={Constants.renderMemberSelected}
                        value={this.state.user}
                        actions={this.props.member_actions}
                        disabled={isGuestBooking}
                        activeCampus={this.props.activeCampus}
                        handleItemSelected={this.handleMemberSearchItemSelected}
                        handleSearchCleared={this.handleMemberSearchItemCleared}
                        dataResolver={this.memberDataResolver}
                        isClearable
                        renderSearchRow={this.renderMemberSearchRow}
                      />
                    </div>
                  </div>
                </div>
                {this.state.showDelete ? (
                  <div className="row my-3">
                    <div className="col-md-8">
                      <Button
                        className="btn btn-small btn-danger"
                        onClick={this.onArchiveClicked}
                      >
                        Cancel Booking
                      </Button>
                    </div>
                    <div className="col-md-2">
                      <Button
                        className="btn btn-block btn-secondary"
                        onClick={this.goBack}
                      >
                        Go Back
                      </Button>
                    </div>
                    <div className="col-md-2">
                      <Button
                        disabled={this.props.isFetching}
                        className="btn btn-block btn-primary"
                        onClick={() => this.initiateUpdatingEvent()}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="row py-2">
                    <div className="col-md-12 d-flex justify-content-end">
                      <Button
                        className="btn btn-small btn-secondary mr-2"
                        onClick={this.goBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-primary"
                        disabled={
                          !this.state.formValid ||
                          (this.state.onCampus === 'true' && !this.state.room)
                        }
                        onClick={this.initiateCreatingEvent}
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                )}
                <div className="row my-1">
                  <div className="col-md-12">
                    <FormErrors formErrors={this.state.formErrors} />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {this.state.alertModal && (
            <GenericModal
              className="modal-primary fade in"
              isOpen={this.state.alertModal}
              toggle={this.toggle}
              header={'Are you sure?'}
              body={
                <p>
                  This booking will be cancelled and{' '}
                  {this.state.contact.first_name} will be notified.{' '}
                  {!moment().isAfter(this.state.endDate) &&
                    `
                  By cancelling this booking you will be notifying to
                  the guest that this booking is canceled.`}
                  <br />
                  {this.state.invoiceId && (
                    <div style={{ marginTop: '20px' }}>
                      <Checkbox
                        name="archiveBooking"
                        style={{ width: '100%' }}
                        label="Refund Invoice"
                        description={`This is a paid booking. Would you like to also refund the amount of 
                        ${this.state.currencySymbol}${Number.parseFloat(
                          this.state.invoiceAmount
                        ).toFixed(2)} to ${this.state.contact.first_name} ${
                          this.state.contact.last_name
                        }?`}
                        selected={this.state.cancelInvoice}
                        handleChange={() =>
                          this.setState({
                            cancelInvoice: !this.state.cancelInvoice,
                          })
                        }
                      />
                    </div>
                  )}
                </p>
              }
              onConfirm={this.archiveCoworksEvent}
              onClose={() => {
                this.toggle()
                this.setState({
                  cancelInvoice: false,
                })
              }}
              confirmLabel="Cancel Booking"
              confirmColor="danger"
            />
          )}
        </div>
      </div>
    )
  }
  /* eslint-enable max-len */
}

EventForm.displayName = 'EventForm'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    rooms: roomSelectors.getRoomsList(state),
    googleCalendars: state.gcal.calendars,
    lastUsedCalendarId: state.gcal.lastUsedCalendarId,
    isFetching: state.ui.isFetching,
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    checkin_actions: bindActionCreators(checkinActions, dispatch),
    booking_actions: bindActionCreators(bookingActions, dispatch),
    room_actions: bindActionCreators(roomActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
    google_actions: bindActionCreators(googleCalendarActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventForm)
