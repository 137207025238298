import DashboardMiniCard from '@app/components/Dashboard/DashboardMiniCard'
import ButtonStyled from '@global/Base/Button/ButtonStyled'
import * as Constants from '@global/Constants'
import * as roomSelectors from '@reduxSelectors/roomSelectors'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SuiteList from './SuiteList'

const Container = styled.div``
const StatsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
`

const ListWrapper = styled.div``
const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const DashboardMiniCardStyled = styled(DashboardMiniCard)`
  width: 30%;
`

const Occupancy = ({ history, stats, activeCommunity }) => {
  const handleNewSuiteClicked = () => {
    history.push(`/occupancy/add`, { type: 'Suite' })
  }

  const renderSuiteList = () => {
    return <SuiteList history={history} />
  }

  const getOccupancyStatsByKey = key => {
    const currencySymbol = Constants.getCurrencySymbolFromCommunity(
      activeCommunity
    )
    const value = stats[key] ? stats[key] : 0
    switch (key) {
      case 'occupancy_percent':
        return {
          loading: false,
          count: `${value}%`,
          text: 'Occupancy',
          tooltipProps: {
            header: 'Total Occupancy Percentage',
            tooltipContent: 'The percentage of suites filled with teams',
          },
        }
      case 'occupancy_mrr':
        return {
          loading: false,
          count: `${currencySymbol}${Number.parseFloat(value).toFixed(2)}`,
          text: 'Est. MRR',
          tooltipProps: {
            header: 'Estimated MRR',
            tooltipContent:
              'The estimated (excluding discounts) that you should be receiving from your suites',
          },
        }
      case 'vacancy_mrr':
        return {
          loading: false,
          count: `${currencySymbol}${Number.parseFloat(value).toFixed(2)}`,
          text: 'Est. Potential MRR',
          tooltipProps: {
            header: 'Estimated Potential MRR',
            tooltipContent:
              'The total estimated (excluding discounts) that you could be receiving from the currently open suites at market price',
          },
        }
      default:
        return {
          loading: true,
        }
    }
  }
  return (
    <Container>
      <StatsContainer>
        {stats &&
          Object.keys(stats).map(key => (
            <DashboardMiniCardStyled {...getOccupancyStatsByKey(key)} />
          ))}
      </StatsContainer>
      <ListWrapper className="card">
        <Header className="card-header">
          <h2>Occupancy</h2>
          <ButtonStyled
            style={{
              margin: 'auto 0 auto auto',
            }}
            onClick={handleNewSuiteClicked}
          >
            New Suite
          </ButtonStyled>
        </Header>
        <div className="card-block">{renderSuiteList()}</div>
      </ListWrapper>
    </Container>
  )
}

Occupancy.propTypes = {
  history: PropTypes.object,
  stats: PropTypes.object,
  activeCommunity: PropTypes.object,
}

Occupancy.displayName = 'Occupancy'

function mapStateToProps(state) {
  return {
    stats: roomSelectors.getSuiteStats(state),
    activeCommunity: state.ui.activeCommunity,
  }
}

export default connect(mapStateToProps, null)(Occupancy)
