/* eslint-disable react/prop-types */
import React from 'react'
import { LabelStyled } from '@global/Form/FormComponents'

const CustomTagDropdown = ({
  groupName,
  uiId,
  groupId,
  handleInputChange,
  tags,
  initialValue,
}) => (
  <div className="my-2">
    <LabelStyled>{groupName}</LabelStyled>
    <select
      name={`tag-${uiId}`}
      className="form-control"
      value={initialValue}
      onChange={event => handleInputChange(event, groupId)}
    >
      <option value={'null' || 'undefined'}>None</option>
      {tags &&
        tags.map(tag => {
          return (
            <option key={tag.id} value={tag.id}>
              {tag.name}
            </option>
          )
        })}
    </select>
  </div>
)
CustomTagDropdown.displayName = 'CustomTagDropdown'

export default CustomTagDropdown
