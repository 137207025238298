import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CoworksSelect from '@global/Search/CoworksSelect'
import styled from 'styled-components'
import { LabelStyled, SingleDatePicker } from '@global/Form/FormComponents'
import Switch from '@global/Base/Switch/Switch'

const ToggleWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
`
const SelectWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
`
const SwitchWrapper = styled.div`
  display: flex;
`
const SwitchStyled = styled(Switch)`
  margin: auto;
`

const Toggle = ({
  isEditing,
  show,
  toggleLabel,
  fieldLabel,
  onToggle,
  children,
  className,
  userKisiAccessDate,
  handleUserAccessDateChanged,
  ...other
}) => {
  const [focused, setFocus] = useState(null)

  return isEditing ? (
    <div className={className}>
      <ToggleWrapper className="col-3">
        <LabelStyled>{toggleLabel}</LabelStyled>
        <SwitchWrapper className="form-group">
          <SwitchStyled value={show} onChange={onToggle} />
        </SwitchWrapper>
      </ToggleWrapper>
      {show && (
        <React.Fragment>
          <SelectWrapper className="col-3">
            <LabelStyled isEditing={isEditing}>Grant Access On</LabelStyled>
            <SingleDatePicker
              id="subscription_start_date" // PropTypes.string.isRequired,
              onDateChange={date => handleUserAccessDateChanged(date)}
              date={userKisiAccessDate} // momentPropTypes.momentObj or null
              focused={focused} // PropTypes.bool
              onFocusChange={({ focused }) => setFocus(focused)} // PropTypes.func.isRequired
              placeholder={'Start Date'}
            />
          </SelectWrapper>
          <SelectWrapper className="col">
            <LabelStyled isEditing={isEditing}>{fieldLabel}</LabelStyled>
            <CoworksSelect {...other} />
          </SelectWrapper>
        </React.Fragment>
      )}
    </div>
  ) : (
    <div className={className}>
      <LabelStyled className="col" isEditing={isEditing}>
        {fieldLabel}
      </LabelStyled>
      <div className="col-12">{children}</div>
    </div>
  )
}

Toggle.propTypes = {
  isEditing: PropTypes.bool,
  children: PropTypes.object,
  userKisiAccessDate: PropTypes.object,
  show: PropTypes.bool,
  toggleLabel: PropTypes.string,
  fieldLabel: PropTypes.string,
  onToggle: PropTypes.func,
  handleUserAccessDateChanged: PropTypes.func,
}

Toggle.displayName = 'Toggle'

export default Toggle
