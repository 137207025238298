/* eslint-disable react/no-multi-comp, react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import RoundedImage from '@global/RoundedImage'
import defaultUserPic from '@app/img/placeholders/otto_black_white.jpeg'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  UncontrolledDropdown,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from 'reactstrap'
import styled, { css } from 'styled-components'

const CampusTitle = styled.h4`
  margin: 0;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const DropdownItemStyled = styled(DropdownItem)`
  display: flex;
  align-items: center;
`

const CampusDropdown = ({
  campus,
  community,
  onChange,
  dropdownHeader = 'Locations',
}) => {
  if (!community || !campus) return null

  // const otherCampuses = community.campuses.filter(cc => cc.id !== campus.id)
  const campuses = community.campuses

  const renderCampusDropdownItems = campuses =>
    campuses.map((cc, index) => (
      <DropdownItemStyled
        key={index}
        className="d-flex align-items-center justify-content-start"
        onClick={() => {
          if (cc.id !== campus.id) {
            onChange(cc)
          }
        }}
      >
        <RoundedImage
          photo={
            cc.campus_photo ? cc.campus_photo.small_file_url : defaultUserPic
          }
          alt="Picture"
          className="img-responsive mr-2"
        />
        <span>{cc.name}</span>
        {cc.id === campus.id ? (
          <FontAwesomeIcon className="ml-auto text-muted" icon="check" />
        ) : null}
      </DropdownItemStyled>
    ))

  function renderSelectedCampus(campus) {
    return (
      <React.Fragment>
        <RoundedImage
          className="mr-2"
          size="small"
          photo={
            campus.campus_photo ? campus.campus_photo.file_url : defaultUserPic
          }
          alt="Picture"
        />
        <CampusTitle>{campus ? campus.name : ''}</CampusTitle>
      </React.Fragment>
    )
  }
  // if (otherCampuses.length === 0) {
  //   return renderSelectedCampus(campus)
  // }
  return (
    <UncontrolledDropdown className="mr-4">
      <DropdownToggle
        nav
        caret
        className="d-flex flex-row align-items-center p-0"
      >
        {renderSelectedCampus(campus)}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu">
        <DropdownItem header className="text-center">
          <strong>{dropdownHeader}</strong>
        </DropdownItem>
        {renderCampusDropdownItems(campuses)}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

CampusDropdown.displayName = 'Campus Dropdown'

CampusDropdown.propTypes = {
  onChange: PropTypes.func,
  campus: PropTypes.object,
  community: PropTypes.object,
  dropdownHeader: PropTypes.string,
}

export default CampusDropdown
