/* eslint-disable no-invalid-this,quote-props */
import React, { useEffect, useRef, useState } from 'react'

import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import moment from 'moment'

import * as Constants from '@global/Constants'
import * as _ from 'lodash'

import {
  FETCHING_EXBOOKING_ROOMS,
  resolveFetchingStatus,
} from '@global/Constants/FetchingConstants'

import * as externalBookingsActions from '@reduxActions/externalBookingsActions'

import Filter from '../Filter'
import CardRenderer from './CardRenderer'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
const ScrollerContainer = styled.div`
  position: relative;
  flex: 1;
  height: 400px;
  overflow-y: scroll;
`
const FilterStyled = styled(Filter)`
  padding: 0 24px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`
const CardRenderStyled = styled(CardRenderer)`
  position: absolute;
  width: 100%;
  padding: 0 24px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`
const radios = currencySymbol => [
  {
    label: `Less than ${currencySymbol}25`,
    value: JSON.stringify({ min: null, max: 24 }),
  },
  {
    label: `${currencySymbol}25 to ${currencySymbol}50`,
    value: JSON.stringify({ min: 25, max: 50 }),
  },
  {
    label: `${currencySymbol}50 to ${currencySymbol}100`,
    value: JSON.stringify({ min: 50, max: 100 }),
  },
  {
    label: `${currencySymbol}100 to ${currencySymbol}300`,
    value: JSON.stringify({ min: 100, max: 300 }),
  },
  {
    label: `Greater than ${currencySymbol}300`,
    value: JSON.stringify({ min: 301, max: null }),
  },
]

const sortByOptions = [
  {
    label: 'Name: A - Z',
    value: 'name.ASC',
  },
  {
    label: 'Name: Z - A',
    value: 'name.DESC',
  },
  {
    label: 'Capacity: low - high',
    value: 'capacity.ASC',
  },
  {
    label: 'Capacity: high - low',
    value: 'capacity.DESC',
  },
  {
    label: 'Price: low - high',
    value: 'external_hourly_rate.ASC',
  },
  {
    label: 'Price: high - low',
    value: 'external_hourly_rate.DESC',
  },
]

const start = moment()
const remainder = 30 - (start.minute() % 30)
const dateTime = moment(start).add(remainder, 'minutes')

const initialFilter = {
  time: {
    date: moment(),
    timeRange: {
      start: dateTime.format('LT'),
      end: dateTime.add(1, 'hours').format('LT'),
    },
    duration: 1,
    isTime: true,
  },
  attributes: {
    priceRange: null,
    capacity: 1,
    amenities: [],
  },
  sort: sortByOptions[0].value,
}

const RoomList = ({
  match,
  theme,
  rooms,
  campus,
  history,
  onSelect,
  ui_actions,
  breakpoint,
  timeParams,
  pagination,
  external_bookings_actions,
  isFetchingRooms,
  publicCommunity,
  onUpdateBookingCriteria,
  ...other
}) => {
  const defaultFilterParams = {
    sort: initialFilter.sort,
    ...initialFilter.time,
    ...initialFilter.attributes,
    ...timeParams,
  }

  const [page, setPage] = useState(Constants.START_PAGE_DEFAULT)
  const [nextTriggered, setTrigger] = useState(false)
  const [filterParams, setFilterParams] = useState(defaultFilterParams)
  const perPage = Constants.PER_PAGE_DEFAULT
  const prevPageRef = useRef()
  const brandColor = publicCommunity.community_preference.branding_primary_color
  const currencySymbol = Constants.getCurrencySymbolFromCommunity(
    publicCommunity
  )

  useEffect(() => {
    if (campus && publicCommunity) {
      const prevPage = prevPageRef.current
        ? prevPageRef.current
        : Constants.START_PAGE_DEFAULT
      if (page > prevPage) {
        setTrigger(false)
        loadNextObjects(page, perPage, true)
      } else {
        loadNextObjects(Constants.START_PAGE_DEFAULT, perPage)
      }
      prevPageRef.current = page
    }
    // eslint-disable-next-line
  }, [page, campus, filterParams])

  useEffect(() => {
    setPage(Constants.START_PAGE_DEFAULT)
  }, [campus])

  const getEnds = () => {
    const { capacity, priceRange, sort, date, timeRange } = filterParams
    let rangeObj = {}
    if (priceRange) {
      rangeObj = JSON.parse(priceRange)
    }
    return {
      external_hourly_rate_min: rangeObj.min,
      external_hourly_rate_max: rangeObj.max,
      capacity_min: capacity,
      order_by: JSON.stringify([sort]),
      start_time: Constants.concatenateDateTime(
        date.format('l'),
        timeRange.start
      ),
      end_time: Constants.concatenateDateTime(date.format('l'), timeRange.end),
    }
  }

  const updateFilterParams = params => {
    const rest = { ...filterParams }
    const dup = Object.assign(rest, params)
    setFilterParams(dup)
    setPage(Constants.START_PAGE_DEFAULT)
    onUpdateBookingCriteria({
      date: dup.date,
      timeRange: dup.timeRange,
    })
  }

  const nextPage = () => {
    if (isFetchingRooms || nextTriggered) return

    if (
      (pagination &&
        rooms &&
        rooms.length < pagination.total_objects &&
        page < pagination.total_pages) ||
      !rooms
    ) {
      const newPage = page + 1
      setTrigger(true)
      setPage(newPage)
    }
  }

  const loadNextObjects = (page, per_page, nextPage) => {
    const params = getEnds()

    external_bookings_actions.getRooms(
      {
        page,
        per_page,
        community_id: publicCommunity.id,
        campus_id: campus.id,
        ...params,
      },
      nextPage
    )
  }

  const searchText = (results, params) => {
    return `${results.length} rooms`
  }

  return (
    <Container {...other}>
      <FilterStyled
        breakpoint={breakpoint}
        rooms={rooms}
        sortOptions={sortByOptions}
        defaultValue={defaultFilterParams}
        value={filterParams}
        onChange={updateFilterParams}
        brandColor={brandColor}
        radioOptions={radios(currencySymbol)}
        currencySymbol={currencySymbol}
        queryResultText={searchText}
      />
      <ScrollerContainer>
        <CardRenderStyled
          breakpoint={breakpoint}
          rooms={rooms}
          brandColor={brandColor}
          nextPage={nextPage}
          type="rooms"
          onClick={onSelect}
          currencySymbol={currencySymbol}
          isLoading={isFetchingRooms}
          totalPages={pagination && pagination.total_pages}
          placeholderTitle={'No rooms found'}
          placeholderSubtext1={'No rooms matched your filters'}
          icon={'door-open'}
          ancestor={null}
        />
      </ScrollerContainer>
    </Container>
  )
}

RoomList.propTypes = {
  history: PropTypes.object,
  publicCommunity: PropTypes.object,
  external_bookings_actions: PropTypes.object,
  timeParams: PropTypes.object,
  onUpdateBookingCriteria: PropTypes.func,
  location: PropTypes.object,
  ui_actions: PropTypes.object,
  onSelect: PropTypes.func,
  match: PropTypes.object,
  bookings: PropTypes.array,
  isFetchingRooms: PropTypes.bool,
  rooms: PropTypes.array,
  members: PropTypes.array,
  pagination: PropTypes.object,
  breakpoint: PropTypes.string,
  campus: PropTypes.object,
  theme: PropTypes.object,
}

RoomList.displayName = 'Room List'

function mapStateToProps(state) {
  return {
    rooms: state.externalBookings.rooms.data,
    pagination: state.externalBookings.rooms.meta
      ? state.externalBookings.rooms.meta.pagination
      : null,
    isFetchingRooms: resolveFetchingStatus(state, FETCHING_EXBOOKING_ROOMS),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    external_bookings_actions: bindActionCreators(
      externalBookingsActions,
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomList)
