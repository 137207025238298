import configureStore from './configureStore'
import { loadState, saveState } from './localStorage'
import throttle from 'lodash/throttle'
import initialState from '../reduxReducers/initialState'
// LOAD THE LOCAL STATE FROM THE LOCALSTORAGE
const persistedState = loadState()

// SELECT A APP STATE, IF A USER ALREADY HAS STATE VS A NEW USER
const store = configureStore(persistedState ? persistedState : initialState)

// INITIALLY DISPATCH LOAD ACCOUNTS INTO THE STORE
// store.dispatch(getAllRanks());
// store.dispatch(getAllActivities());
// store.dispatch(getAllObjectives());

// SAVE STATE TO LOCAL STORE EVERY TIME CHANGES

// ENABLE ALL THIS ON PRODUCTION
store.subscribe(
  throttle(() => {
    saveState({
      bookings: store.getState().bookings,
      campuses: store.getState().campuses,
      community: store.getState().community,
      manager: store.getState().manager,
      members: store.getState().members,
      plans: store.getState().plans,
      leads: store.getState().leads,
      resources: store.getState().resources,
      rooms: store.getState().rooms,
      teams: store.getState().teams,
      ui: store.getState().ui,
      user: store.getState().user,
    })
  }, 1000)
)

if (window.Cypress) {
  window.redux_store = store
}

export default store
