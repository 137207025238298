/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from 'reactstrap'
import * as Constants from './Constants'

import styled from 'styled-components'

const ImgStyled = styled.img`
  height: ${props => (props.size === 'rectangular' ? '180px' : '240px')};
  background: url(${props => props.imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  background-position: center;
`
const CardComponent = ({
  key,
  item,
  name,
  renderSubHeaderComponent,
  detail,
  value,
  editClickHandler,
  imageUrl,
  alt,
  cardSize,
  actionButtonText,
  isRectangular,
}) => {
  let size = 'square'
  let width = 3
  if (cardSize) width = cardSize
  if (isRectangular) {
    size = 'rectangular'
  }
  return (
    <div
      key={key ? key : item.id}
      className={`col-lg-${width} col-sm-6 text-center d-flex align-self-stretch`}
      id="CardComponent"
    >
      <a className="card p-0">
        <ImgStyled imageUrl={imageUrl} size={size} />
        <div className="card-block">
          <br />
          <div className="row">
            <div className="col-12">
              <h4>{name}</h4>
            </div>
            {renderSubHeaderComponent ? renderSubHeaderComponent() : null}
            <div className="col-12">
              <p className="text-muted">
                {Constants.getCardAbbreviation(detail)}
              </p>
            </div>
            <div className="col-12 text-center">
              <h6 className="text-center">{value ? value : ''}</h6>
            </div>
            <div className="col-12">
              <Button
                onClick={() => editClickHandler(item)}
                className="btn btn-outline-primary"
              >
                {actionButtonText ? actionButtonText : 'Edit'}
              </Button>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

CardComponent.displayName = 'CardComponent'

export default CardComponent
