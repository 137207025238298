/* eslint-disable react/prop-types,no-trailing-spaces,max-params */
import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const CancelSubscriptionModal = ({
  showSubscriptionDeletionModal,
  toggleCancelSubscriptionModal,
  team,
  subscriptionInQuestion,
  confirmCancelSubscriptionClicked,
}) => {
  return (
    <Modal
      isOpen={showSubscriptionDeletionModal}
      toggle={toggleCancelSubscriptionModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleCancelSubscriptionModal}>
        Cancel Plans Confirmation
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col text-center">
              <strong>Are you sure you want to cancel these plans?</strong>
            </div>
          </div>
          <div className="row">
            <div className="col">
              These plans will be removed from {team && team.name} and the{' '}
              {team && team.name} will no longer be billed for:
              <ul>
                {subscriptionInQuestion &&
                  subscriptionInQuestion.items.data.map((dateObj, index) => (
                    <li key={index}>{dateObj.plan.nickname}</li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleCancelSubscriptionModal}>
          Nevermind
        </Button>
        <Button color="danger" onClick={confirmCancelSubscriptionClicked}>
          Yes, cancel these plans
        </Button>
      </ModalFooter>
    </Modal>
  )
}
CancelSubscriptionModal.displayName = 'CancelSubscriptionModal'

export default CancelSubscriptionModal
