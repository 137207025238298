export function checkDoorProvider(integrations, activeCampus) {
  let list = null
  let hasKisiActive = false

  if (integrations && integrations.active) {
    list = integrations.active.filter(object => object.type === 'kisi')
    hasKisiActive = Boolean(list && list.length > 0)
  }

  let hasCampusPlaceId
  if (
    activeCampus.integration_ids &&
    activeCampus.integration_ids.door_access &&
    activeCampus.integration_ids.door_access.kisi_place_id
  ) {
    hasCampusPlaceId = true
  }
  return hasKisiActive && hasCampusPlaceId
}
