/* eslint-disable react/no-multi-comp,react/display-name */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Log from '@components/Directory/CheckIns/Log'

const Container = styled.div``
const Header = styled.h3`
  margin-bottom: 16px;
`
const LogStyled = styled(Log)`
  display: block;
`

function CheckIns({ member, ...other }) {
  return (
    <Container>
      <Header>{`${member.first_name} ${member.last_name}'s Check-ins`}</Header>
      <LogStyled unique className="card-block" member={member} {...other} />
    </Container>
  )
}

CheckIns.propTypes = {
  member: PropTypes.object,
}

CheckIns.defaultProps = {
  data: [],
}

CheckIns.displayName = 'CheckIns'

export default CheckIns
