/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'
import * as Reactstrap from 'reactstrap'
import without from '../../WithoutPropsStyledComponents'
import colors from '@global/Colors/colors'
import {
  resolveColor,
  resolveHoverColor,
  resolveButtonTextColor,
  resolveBorderColor,
} from '../baseComponentColorUtils'

const PillToggle = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0.875rem;
  line-height: 1.5;
  color: #263238;
  font-weight: 400;
  list-style: none;
  box-sizing: inherit;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
  border-radius: 15px !important;
  padding: 5px;
  cursor: pointer;
  display: inline-block;
  /* transition: background-color 0.5s; */

  background-color: transparent;
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      opacity: ${props => props.theme.disabledOpacity} !important;
    `};

  &.active {
    color: #fff !important;
    background-color: #269796 !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    list-style: none;
    text-decoration: none;
  }

  &:hover {
    color: ${props => props.theme.colorPrimaryHover} !important;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    list-style: none;
    outline-width: 0;
    text-decoration: none;
  }
`

export default PillToggle
