import io from 'socket.io-client'

import { socketURL } from '../../../config/axios'
import { updateTabletIsActive } from '../../../services/apiService'
import { ACTIVATE_MEMBER } from '../../../reduxActions/actionTypes'

let socket

/**
|--------------------------------------------------
| CONNECT AND DISCONNECT
|--------------------------------------------------
*/
export function connectToSocket() {
  socket = io.connect(socketURL, {
    'sync disconnect on unload': true,
  })
  /*
  , {
    'sync disconnect on unload': true,
  }
  */
  // socket.on('connection', socket => {
  //   socket.join(room);
  // });
  // cb(socket);
}

export function closeSocket(cb) {
  console.log('>>>>>>>>>>>>>>>>>>  closing socket')

  socket.close()
}

export function onDisconnect(cb) {
  socket.on('disconnect', () => cb())
}

/**
|--------------------------------------------------
| HANDSHAKE
|--------------------------------------------------
*/
export function sendTabletTypeOnRequest(cb) {
  socket.on('requestTabletType', () => cb())
}

export function publishTabletType(tabletType) {
  socket.emit('publishTabletType', tabletType)
}

export function sendTabletKeyOnRequest(cb) {
  socket.on('requestTabletKey', () => cb())
}

export function publishTabletKey(tabletKey) {
  socket.emit('publishTabletKey', tabletKey)
}

export function subscribeRoomId(cb) {
  socket.on('publishRoomId', campusTablet => cb(campusTablet))
}

export function subscribeInitialBookings(cb) {
  socket.on('publishInitialBookings', bookings => {
    console.log('Initial BOOKINGS: ', bookings)
    return cb(bookings)
  }) //eslint-disable-line
}

/**
|--------------------------------------------------
| INCOMING BOOKING CHANGES
|--------------------------------------------------
*/
export function subscribeBookingChannel(cb) {
  socket.on('modifyBooking', booking => cb(booking))
}

export function subscribeBookingDeleteChannel(cb) {
  socket.on('deleteBooking', booking => cb(booking))
}

/**
|--------------------------------------------------
| ROOM CHANGES
|--------------------------------------------------
*/
export function subscribeRoomModifiedChannel(cb) {
  socket.on('modifyRoom', room => cb(room))
}

export function subscribeRoomDeleteChannel(cb) {
  socket.on('deleteRoom', room => cb(room))
}

/**
|--------------------------------------------------
| OUTGOING BOOKING CHANGES
|--------------------------------------------------
*/
export function publishBooking(booking) {
  socket.emit('newBooking', booking)
}

export function subscribeToFailure(cb) {
  socket.on('failure', failure => cb(failure))
}

/**
|--------------------------------------------------
| VERSIONING
|--------------------------------------------------
*/
export function subscribeShouldTabletRefresh(cb) {
  // console.log('[subscribeShouldTabletRefresh] fires up clientside! ');
  socket.on('tabletShouldRefresh', modifiedVersion => cb(modifiedVersion))
}
export function subscribeShouldFrontDeskTabletRefresh(cb) {
  // console.log('[subscribeShouldFrontDeskTabletRefresh] fires up clientside! ');
  socket.on('frontdeskTabletShouldRefresh', modifiedVersion =>
    cb(modifiedVersion)
  )
}
