import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { Column } from '@global/Table'

const Container = styled.div``

function DateCol({ data }) {
  const date = moment(data).format('YYYY')

  return (
    <Container>
      <Column text={date} subtext={'hello world'} />
    </Container>
  )
}

DateCol.propTypes = {
  data: PropTypes.string,
}

DateCol.displayName = 'Date Column'

export default DateCol
