/* eslint-disable no-invalid-this */
import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import NothingFound from '@global/NothingFound'
import Placeholder from '@global/Placeholder'
export default class PaginatedTable extends React.Component {
  static propTypes = {
    objects: PropTypes.array,
    nextPage: PropTypes.func,
    canEdit: PropTypes.bool,
    totalPages: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    renderItem: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    containerClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    ancestor: PropTypes.any,
    placeholderSubtext1: PropTypes.string,
    placeholderSubtext2: PropTypes.string,
    icon: PropTypes.string,
    hidePlaceholder: PropTypes.bool,
    removeWrapper: PropTypes.bool,
    placeholderTitle: PropTypes.string,
    placeholderButtonText: PropTypes.string,
    placeholderHandleClick: PropTypes.string,
  }
  static defaultProps = {
    containerClassName: 'container-fluid',
    wrapperClassName: 'col-md-12',
    ancestor: 'window',
    icon: 'users',
    placeholderSubtext2: '',
    hidePlaceholder: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    const { objects, isLoading, nextPage } = this.props
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight &&
      objects &&
      objects.length &&
      !isLoading
    ) {
      nextPage()
    }
  }
  renderWaypoint = () => {
    const { objects, isLoading, nextPage, ancestor } = this.props
    return (
      <Waypoint
        scrollableAncestor={ancestor}
        onEnter={() => {
          if (objects && objects.length && !isLoading) {
            nextPage()
          }
        }}
      />
    )
  }
  renderContent = (objects, render) => (
    <React.Fragment>
      {objects &&
        objects.map((obj, index) => render(obj, index, objects.length))}
      {this.renderWaypoint()}
    </React.Fragment>
  )

  render() {
    const {
      objects,
      isLoading,
      type,
      containerClassName,
      wrapperClassName,
      className,
      renderItem,
      placeholderSubtext1,
      placeholderSubtext2,
      icon,
      removeWrapper,
      placeholderTitle,
      placeholderButtonText,
      placeholderHandleClick,
      hidePlaceholder,
    } = this.props
    if (objects && objects.length === 0 && !isLoading) {
      if (hidePlaceholder) return null
      return (
        <Placeholder
          title={placeholderTitle ? placeholderTitle : `No ${type}`}
          icon={icon}
          subtext1={
            placeholderSubtext1
              ? placeholderSubtext1
              : `You currently have no ${type} in this campus.`
          }
          subtext2={placeholderSubtext2}
          buttonText={placeholderButtonText}
          handleClick={placeholderHandleClick}
        />
      )
    }

    if (removeWrapper) return this.renderContent(objects, renderItem)

    return (
      <div
        className={
          containerClassName
            ? containerClassName.concat(' ', className)
            : className
        }
      >
        <div className={wrapperClassName}>
          {this.renderContent(objects, renderItem)}
        </div>
        {isLoading && (
          <div className="col-md-12 d-flex justify-content-center">
            <div className="h6 text-primary">Loading...</div>
          </div>
        )}
      </div>
    )
  }
}

PaginatedTable.displayName = 'PaginatedTable'
