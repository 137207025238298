/* eslint-disable react/prop-types,react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* eslint-disable no-invalid-this */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as Roles from '../../../../../config/roles' //todo implement this where roles are used
import { Button } from '@global/Base/Button/ButtonStyled'
import RoundedImage from '@global/RoundedImage'
import CheckInTimes from './CheckInTimes'
import CampusPicker from './CampusPicker'
import * as Yup from 'yup'
import { withFormik, Form, ErrorMessage } from 'formik'
import moment from 'moment'

const ModalFooterStyled = styled(ModalFooter)`
  display: flex;
`
const ModalBodyStyled = styled(ModalBody)`
  display: flex;
  flex-flow: wrap column;
  align-items: center;
`
const RoundedImageStyled = styled(RoundedImage)`
  margin-bottom: 18px;
`
const FormStyled = styled(Form)``
const RightButtons = styled.div`
  margin-left: auto !important;
`
const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: wrap column;
  align-items: center;
  margin-bottom: 24px;
`
const MethodWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 70%;
`
const Title = styled.h4`
  text-align: center;
`
const Creator = styled.span`
  font-size: 12px;
`
const Other = styled.h5`
  word-break: break-all;
`
const CampusPickerStyled = styled(CampusPicker)`
  width: 70%;
`
const CheckInTimesStyled = styled(CheckInTimes)`
  width: 70%;
  margin-bottom: 12px;
`
const ButtonStyled = styled(Button)`
  margin: 0 0.25em;
`

function InnerForm({
  history,
  role,
  data,
  hideButton,
  canEdit,
  onClose,
  defaultPic,
  onDelete,
  values,
  touched,
  errors,
  handleChange,
  submitForm,
  handleBlur,
  deleteMod,
  handleSubmit,
  setFieldValue,
  showCampusPicker,
  options,
  campuses,
  resetForm,
  isSubmitting,
  firstName,
  lastName,
  email,
  photo,
  ...other
}) {
  const { id, creator, created_at } = data
  const closeModal = () => {
    resetForm()
    onClose()
  }

  const renderEditButton = role => {
    return (
      <ModalFooterStyled>
        <ButtonStyled color="danger" onClick={() => onDelete(id)}>
          Delete
        </ButtonStyled>
        <RightButtons>
          <ButtonStyled color="white" onClick={closeModal}>
            Cancel
          </ButtonStyled>
          <ButtonStyled onClick={submitForm}>Save</ButtonStyled>
        </RightButtons>
      </ModalFooterStyled>
    )
  }

  return (
    <Modal toggle={closeModal} {...other}>
      <ModalHeader toggle={closeModal}>Edit Check-in</ModalHeader>
      <ModalBodyStyled>
        <RoundedImageStyled
          photo={photo}
          alt="Picture"
          size="large"
          className="img-responsive"
        />
        <InfoContainer>
          <Title>{`${firstName} ${lastName}`}</Title>
          <Other>{email}</Other>
        </InfoContainer>
        {showCampusPicker && (
          <CampusPickerStyled
            campus={values.selectedCampus}
            setFieldValue={setFieldValue}
            campuses={campuses}
          />
        )}
        <CheckInTimesStyled
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <MethodWrapper>
          <Creator className="text-muted">
            {creator &&
              `Checked in by ${creator.first_name} ${
                creator.last_name
              } @ ${moment(created_at).format('lll')}`}
          </Creator>
        </MethodWrapper>
      </ModalBodyStyled>
      {renderEditButton(role)}
    </Modal>
  )
}

const CheckInModal = withFormik({
  mapPropsToValues: ({ data }) =>
    Object.assign({
      timeIn: data.time_in ? moment(data.time_in).unix() : null,
      timeOut: data.time_out ? moment(data.time_out).unix() : null,
      selectedCampus:
        data.user && data.user.campus ? data.user.campus.id : null,
    }),

  validationSchema: Yup.object().shape({
    timeIn: Yup.number().required('Time-in must be present.'),
    timeOut: Yup.number()
      .nullable()
      .test(
        'is-true',
        "Time-out time can't come before time-in time.",
        // eslint-disable-next-line func-names
        function (value) {
          return value ? this.parent.timeIn < value : true
        }
      ),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onEdit(
      {
        time_in: moment.unix(values.timeIn).toISOString(),
        time_out: values.timeOut
          ? moment.unix(values.timeOut).toISOString()
          : null,
        from_campus: parseInt(values.selectedCampus, 10),
      },
      props.data.id
    )
    setSubmitting(false)
  },

  displayName: 'CheckInModal', // helps with React DevTools
  enableReinitialize: true,
})(InnerForm)

CheckInModal.propTypes = {
  history: PropTypes.object,
  role: PropTypes.string,
  data: PropTypes.object,
  defaultPic: PropTypes.object,
  photo: PropTypes.object,
  hideButton: PropTypes.bool,
  canEdit: PropTypes.bool,
  showCampusPicker: PropTypes.bool,
  onClose: PropTypes.func,
  campuses: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
}

CheckInModal.defaultProps = {
  role: null,
  hideButton: false,
  canEdit: true,
  data: {
    time_in: null,
    time_out: null,
    user: null,
    creator: null,
  },
}

CheckInModal.displayName = 'Check-in Modal'

export default CheckInModal
