/* eslint-disable no-undef */
/* eslint-disable brace-style */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import * as userActions from '../../../reduxActions/userActions'
import * as memberActions from '../../../reduxActions/memberActions'
import {
  Button,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { toast } from 'react-toastify'
import { FormErrors } from '../../Global/FormErrorsComponent'
import ImageUpload from '../../Global/UploadImage/ImageUploadComponent'
import Spinner from '@global/Spinner'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import defaultUserPic from '../../../img/placeholders/otto_black_white.jpeg'
import ChangePasswordForm from './ChangePasswordForm'
import { LabelStyled } from '@global/Form/FormComponents'
import RoundedImage from '@global/RoundedImage'
import styled from 'styled-components'
import {
  resolveFetchingStatus,
  FETCHING_UPDATING_USER,
} from '@global/Constants/FetchingConstants'

const VersionText = styled.div`
  cursor: ${props => (props.showPointer ? 'pointer' : '')};
  color: ${props =>
    props.showPointer ? props.theme.colorPrimary : '#b0bec5'} !important;
`

class MyProfile extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    user_actions: PropTypes.object,
    member_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCampusId: PropTypes.number,
    history: PropTypes.object,
    isFetching: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = {
      firstName: this.props.user.first_name,
      lastName: this.props.user.last_name,
      email: this.props.user.email,
      bio: this.props.user.bio ? this.props.user.bio : 'No bio',
      photo: this.props.user.user_photo
        ? this.props.user.user_photo.file_url
        : '',
      photoChanged: false,
      isViewOnly: true,
      isPasswordFormVisible: false,
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
      formErrors: { Passwords: '' },
      passwordValid: false,
      formValid: false,
      cropperOpen: false,
    }
  }

  updateUser = () => {
    const newUser = {
      id: this.props.user.id,
      campus_id: this.props.activeCampusId,
      bio: this.state.bio,
      email: this.state.email,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
    }
    if (this.state.isPasswordFormVisible) {
      newUser.password_confirmation = this.state.passwordConfirmation
      newUser.current_password = this.state.currentPassword
      newUser.password = this.state.password
    }
    if (this.state.photoChanged) newUser.user_photo = this.state.photo

    this.props.user_actions
      .updateUser({ user: newUser, fetchingName: FETCHING_UPDATING_USER })
      .then(() => {
        toast.success('Profile and Password Updated Successfully')
        this.handleEditClicked()
      })
      .catch(err => {
        if (err) {
          // console.log('fail: ', err.response.data.errors);
          this.setState({ errors: err })
          toast.error(this.state.errors.message)
        }
      })
  }
  onRefreshClick = event => {
    event.preventDefault()
    if (window.swUpdate) {
      return (window.location = 'https://dashboard.coworksapp.com/community')
    }
    return null
  }
  handleEditClicked = () => {
    this.setState({
      isViewOnly: !this.state.isViewOnly,
      isPasswordFormVisible: false,
    })
  }
  handleShowPasswordFormClicked = () => {
    this.setState({
      isPasswordFormVisible: !this.state.isPasswordFormVisible,
    })
  }
  handleImageUpload = image => {
    this.setState({
      photo: image,
      photoChanged: true,
    })
  }
  /*
    Start of form validation -- don't forget state variables and component in render()
   */
  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }
  validateField(fieldName /*, value*/) {
    const fieldValidationErrors = this.state.formErrors
    let passwordValid = this.state.passwordValid

    switch (fieldName) {
      case 'password':
      case 'passwordConfirmation':
        passwordValid = this.state.passwordConfirmation === this.state.password
        fieldValidationErrors.Passwords = passwordValid ? '' : " don't match"
        break
      default:
        break
    }
    this.setState(
      { formErrors: fieldValidationErrors, passwordValid: passwordValid },
      this.validateForm
    )
  }
  validateForm() {
    this.setState({ formValid: this.state.passwordValid })
  }
  /*
    End of form validation
  */
  renderProfilePicture = () => {
    return (
      <RoundedImage
        photo={
          this.props.user.user_photo
            ? this.props.user.user_photo.large_file_url
            : this.props.user.user_photo
        }
        alt="Picture"
        size="large"
      />
    )
  }
  render() {
    if (this.props.isFetching) {
      return <Spinner />
    }
    return (
      <TabContent>
        <TabPane>
          <div className="col-md-12">
            <div className="row border-bottom-1 my-3">
              <div className="col-10">
                <h2>My Profile</h2>
              </div>
              <div className="col-2 action-right">
                {this.state.isViewOnly ? (
                  <button
                    className="btn btn-outline-primary pull-right"
                    onClick={this.handleEditClicked}
                  >
                    Edit
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <form className="form-container col-md-12" id="global-image-form">
            <div className="row">
              <div className="col-md-3">
                {this.state.isViewOnly ? (
                  this.renderProfilePicture()
                ) : (
                  <ImageUpload
                    onUpload={this.handleImageUpload}
                    ref="imageUploadComponent"
                    imageUrl={this.state.photo}
                  />
                )}
              </div>
              <div className="col-md-9">
                <div className="row mb-3">
                  <div className="col">
                    <LabelStyled isEditing={!this.state.isViewOnly}>
                      First Name
                    </LabelStyled>
                    {this.state.isViewOnly ? (
                      <h3>{this.state.firstName}</h3>
                    ) : (
                      <Input
                        name={'firstName'}
                        type={'text'}
                        className={'mb-3'}
                        label={'First Name'}
                        value={this.state.firstName}
                        placeholder={'First Name'}
                        onChange={this.handleInputChange}
                      />
                    )}
                  </div>
                  <div className="col">
                    <LabelStyled isEditing={!this.state.isViewOnly}>
                      Last Name
                    </LabelStyled>
                    {this.state.isViewOnly ? (
                      <h3>{this.state.lastName}</h3>
                    ) : (
                      <Input
                        name={'lastName'}
                        type={'text'}
                        className={'mb-3'}
                        label={'Last Name'}
                        value={this.state.lastName}
                        placeholder={'Last Name'}
                        onChange={this.handleInputChange}
                      />
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <LabelStyled isEditing={!this.state.isViewOnly}>
                      Email
                    </LabelStyled>
                    {this.state.isViewOnly ? (
                      <h3>{this.state.email}</h3>
                    ) : (
                      <Input
                        name={'email'}
                        type={'text'}
                        className={'mb-3'}
                        label={'email'}
                        value={this.state.email}
                        placeholder={'email'}
                        onChange={this.handleInputChange}
                      />
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <LabelStyled isEditing={!this.state.isViewOnly}>
                      Bio
                    </LabelStyled>
                    {this.state.isViewOnly ? (
                      <h3>{this.state.bio}</h3>
                    ) : (
                      <Input
                        name={'bio'}
                        type={'text'}
                        className={'mb-3'}
                        label={'bio'}
                        value={this.state.bio}
                        placeholder={'bio'}
                        onChange={this.handleInputChange}
                      />
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    {this.state.isViewOnly ? (
                      <React.Fragment>
                        <LabelStyled isEditing={!this.state.isViewOnly}>
                          Password
                        </LabelStyled>
                        <div className="row mb-3">
                          <div className="col-md-6">••••••••••</div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <Button
                        className="btn btn-outline-primary"
                        // eslint-disable-next-line max-len
                        onClick={this.handleShowPasswordFormClicked}
                      >
                        {this.state.isPasswordFormVisible
                          ? 'Hide password form'
                          : 'Change Password'}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  {this.state.isPasswordFormVisible ? (
                    <div className="col-md-12">
                      <ChangePasswordForm
                        handleInputChange={this.handleInputChange}
                        password={this.state.password}
                        passwordConfirmation={this.state.passwordConfirmation}
                        currentPassword={this.state.currentPassword}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    {this.state.isViewOnly ? null : (
                      <Button
                        className="btn btn-secondary"
                        onClick={this.handleEditClicked}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                  <div className="col-md-3 offset-md-3">
                    {this.state.isViewOnly ? null : (
                      <Button
                        className="btn btn-primary"
                        onClick={this.updateUser}
                      >
                        Save Profile
                      </Button>
                    )}
                  </div>

                  <div className="col-md-12">
                    <FormErrors formErrors={this.state.formErrors} />
                  </div>

                  {this.state.isViewOnly && (
                    <VersionText
                      className="offset-md-9 col-3 text-muted "
                      onClick={this.onRefreshClick}
                      showPointer={Boolean(window.swUpdate)}
                    >
                      version {BUILD_VERSION}{' '}
                      {window.swUpdate && <FontAwesomeIcon icon="sync" />}
                    </VersionText>
                  )}
                </div>
              </div>
            </div>
          </form>
        </TabPane>
      </TabContent>
    )
  }
}
MyProfile.displayName = 'MyProfile'

function mapStateToProps(state) {
  return {
    user: state.user,
    activeCampusId: state.ui.activeCampus.id,
    isFetching: resolveFetchingStatus(state, FETCHING_UPDATING_USER),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    user_actions: bindActionCreators(userActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
