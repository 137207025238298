/* eslint-disable react/prop-types,camelcase */
import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { LabelStyled } from '@global/Form/FormComponents'

const InnerForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    // custom functions as props, and anything that will change in parent
    isEditing,
    goBack,
    isFetching,
    allowFormSubmit,
    stripe,
    context,
    verifyBank,
    showVerifyBankModal,
    toggleVerifyBankModal,
    stripeCustomerId,
    stripe_account_id,
    bankToken,
    isLoading,
  } = props

  return (
    <Modal
      isOpen={showVerifyBankModal}
      toggle={toggleVerifyBankModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleVerifyBankModal}>
        Verify Bank Account
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <p>
                  Please check the bank account of the member and look for 2
                  micro transactions labeled with AMNT: (value). Note: these
                  micro-transactions can take 1-2 days.
                </p>
                <p>
                  Please note that these amounts need to be entered as cents.
                  For $0.42, enter 42.
                </p>
              </div>
              <div className="col-6">
                <LabelStyled>Amount 1</LabelStyled>
                <input
                  name="amount1"
                  type="text"
                  placeholder="00"
                  className={`form-control w-100 ${errors.amount1 &&
                    touched.amount1 &&
                    'error'}`}
                  value={values.amount1}
                  onChange={handleChange}
                />
                {errors.amount1 && touched.amount1 && (
                  <div className="text-danger">{errors.amount1}</div>
                )}
              </div>
              <div className="col-6">
                <LabelStyled>Amount 2</LabelStyled>
                <input
                  name="amount2"
                  type="text"
                  placeholder="00"
                  className={`form-control w-100 ${errors.amount2 &&
                    touched.amount2 &&
                    'error'}`}
                  value={values.amount2}
                  onChange={handleChange}
                />
                {errors.amount2 && touched.amount2 && (
                  <div className="text-danger">{errors.amount2}</div>
                )}
              </div>
            </div>
            <ModalFooter>
              <Button color="secondary" onClick={toggleVerifyBankModal}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!dirty || isSubmitting || isFetching || isLoading}
                color="primary"
              >
                Verify Bank
              </Button>
            </ModalFooter>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}
InnerForm.displayName = 'InnerForm'

const VerifyBankModal = withFormik({
  validationSchema: Yup.object().shape({
    amount1: Yup.number()
      .positive("Amount 1 isn't a negative number!")
      .integer('Anount 1 should be a whole number (in cents)')
      .lessThan(100, 'Amount 1 is less than 100 cents.')
      .required('Amount 1  is required'),
    amount2: Yup.number()
      .positive("Amount 2 isn't a negative number!")
      .integer('Anount 2 should be a whole number (in cents)')
      .lessThan(100, 'Amount 2 is less than 100 cents.')
      .required('Amount 2  is required'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    // eslint-disable-next-line no-unused-expressions
    const bankData = {
      amount1: values.amount1,
      amount2: values.amount2,
      bankToken: props.bankToken,
    }
    props.verifyBank(bankData, props.stripeCustomerId)
    setSubmitting(false)
  },
  displayName: 'BasicForm', // helps with React DevTools
})(InnerForm)

VerifyBankModal.displayName = 'VerifyBankModal'
export default VerifyBankModal
