/* eslint-disable react/prop-types,react/display-name */
import React from 'react'

import { withFormik, Field } from 'formik'
import { ElementsConsumer } from '@stripe/react-stripe-js'

import * as Yup from 'yup'
import {
  CustomInputComponent,
  CustomSelectComponent,
} from '@global/Form/FormComponents'
import Spinner from '../Spinner'

import { LabelStyled } from '@global/Form/FormComponents'

import { Button } from '@global/Base/Button/ButtonStyled'
import { currencyCountryStripePairs } from '@global/Constants/PaymentConstants'
import { getCurrencyLabelFromName } from '@global/Constants'

const InnerForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    defaultSource,
    setFieldValue,
    // custom functions as props, and anything that will change in parent
    toggleAddBankModal,
    isFetching,
    allowFormSubmit,
    stripe,
    context,
    currency,
    country,
  } = props

  const buttonText = defaultSource ? 'Replace' : 'Submit'
  console.log(values.currency)
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-8">
              <Field
                label="Account Holder Name"
                name="accountHolderName"
                placeholder={'First and Last Name'}
                component={CustomInputComponent}
              />
            </div>
            <div className="col-4">
              <Field
                label="Account Type"
                name="accountHolderType"
                component={CustomSelectComponent}
              >
                {/* IMPORTANT: This needs to remain company for stripe */}
                <option name="Company" value="company">
                  Company
                </option>
                <option name="Individual" value="individual">
                  Individual
                </option>
              </Field>
            </div>
            <div className="col-12">
              <Field
                label="Account Number"
                name="accountNumber"
                placeholder="000123456789"
                component={CustomInputComponent}
              />
            </div>

            <div className="col-12">
              <Field
                label="Routing Number"
                name="routingNumber"
                placeholder="110000000"
                component={CustomInputComponent}
              />
            </div>

            {/* <div className="col-6">
          <Field
            label="Account Country"
            name="country"
            component={CustomSelectComponent}
            onChange={event => {
              // need to ensure newly selected country supports the currency selected
              const country = event.target.value
              const matches = currencyCountryStripePairs.filter(
                object => object.countrySymbol === country
              )
              if (matches.length > 0) {
                const object = matches[0]
                if (!object.currencyPairs.includes(values.currency)) {
                  setFieldValue('currency', object.currencyPairs[0])
                }
              }
              handleChange(event)
            }}
          >
            {currencyCountryStripePairs.map((object, index) => (
              <option value={object.countrySymbol}>{object.countryName}</option>
            ))}
          </Field>
        </div> */}
            {/* <div className="col-6">
          <Field
            label="Currency"
            name="currency"
            component={CustomSelectComponent}
          >
            {currencyCountryStripePairs
              .filter(object => object.countrySymbol === values.country)[0]
              .currencyPairs.map((currencyName, index) => (
                <option value={currencyName}>
                  {getCurrencyLabelFromName(currencyName)}
                </option>
              ))}
          </Field>
        </div> */}
            <div className="col-12 my-2">
              <p className="text-muted">
                Note: You can only accept ACH payments from US-based accounts.
              </p>
            </div>
            <div className="col-12 my-2 d-flex justify-content-end">
              <Button
                className="mr-3"
                color="white"
                onClick={toggleAddBankModal}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={
                  (!dirty || isSubmitting || isFetching) && !allowFormSubmit
                }
              >
                {isSubmitting ? <Spinner /> : buttonText}
              </Button>
            </div>
          </div>
        </form>
      )}
    </ElementsConsumer>
  )
}

const BankAccountForm = withFormik({
  validationSchema: Yup.object().shape({
    routingNumber: Yup.string()
      // .positive("Routing number isn't a negative number!")
      // .min(99999, 'Routing numbers are usually larger than that!')
      .required('Routing number is required'),
    accountNumber: Yup.string()
      // .positive("Account number isn't a negative number!")
      // .min(99999, 'Account numbers are usually larger than that!')
      .required('Account number is required'),
    accountHolderType: Yup.string(),
    accountHolderName: Yup.string().required(
      'Account holder name is required.'
    ),
  }),
  mapPropsToValues: props => ({
    currency: props.currency,
    country: props.country,
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    // eslint-disable-next-line no-unused-expressions
    const bankData = {
      country: values.country,
      currency: values.currency,
      routing_number: values.routingNumber,
      account_number: values.accountNumber,
      account_holder_name: values.accountHolderName,
      account_holder_type: values.accountHolderType,
    }
    props.createBankToken(props.stripe, bankData)
    setSubmitting(false)
  },
  displayName: 'BasicForm', // helps with React DevTools
})(InnerForm)

BankAccountForm.displayName = 'BankAccountForm'

export default BankAccountForm
