/* eslint-disable no-invalid-this */
import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import { frequencies } from '@global/Constants/PaymentConstants'
import {
  parseCustomTagState,
  resolveDomainPrefix,
  renderCustomDropdowns,
  assignSingleCustomDropdownValue,
  pluckObjectByID,
} from '@global/Constants'

import { extractCoworksErrorObject } from '@global/ErrorFactory'
import * as planActions from '../../../reduxActions/planActions'
import * as uiActions from '../../../reduxActions/uiActions'
import { toast } from 'react-toastify'
import Spinner from '@global/Spinner'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import { Button, TextButton } from '@global/Base/Button/ButtonStyled'

import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import {
  CustomInputComponent,
  CustomTextAreaComponent,
  CustomSelectComponent,
  CustomToggleComponent,
  LabelStyled,
} from '@global/Form/FormComponents'

import { renderDynamicGroups } from '@global/DynamicGroups/DynamicGroupFunctions'

function Plan({
  history,
  match,
  plan_actions,
  ui_actions,
  activeCampus,
  activeCommunity,
  plans,
  plan,
  isFetching,
  user,
  groups, // legacy groups
}) {
  // this is used for validation context for YUP
  const [includeConferenceHours, setIncludeConferenceHours] = useState(false)

  useEffect(() => {
    if (!plans || (plans.length === 0 && !plan)) {
      console.log(
        'Need to implement backup plan fetch when authroization sorted!'
      )
    }
  }, [plans, plan])

  useEffect(() => {
    // legacy groups
    if (!groups) {
      ui_actions.getGroups(activeCampus.id)
    }
  }, [ui_actions, activeCampus.id, groups])

  const handleCustomDropdownChange = (
    event,
    groupId,
    setFieldValue,
    customTagState
  ) => {
    // Legacy coworks groups handler
    const name = event.target.name
    const value = event.target.value
    if (name.includes('tag-')) {
      const newState = assignSingleCustomDropdownValue(
        customTagState,
        name.replace('tag-', ''),
        value,
        groupId
      )
      setFieldValue('customTagState', newState)
    }
  }

  const createPlan = (values, setSubmitting) => {
    const customTagState = values.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    const planObj = {
      campus_id: activeCampus.id,
      community_id: activeCommunity.id,
      cost: values.cost,
      description: values.description,
      name: values.name,
      frequency: values.frequency,
      conference_hours_default: values.conference_hours_default,
      conference_hour_rate: values.conference_hour_rate,
      currency_units: activeCommunity.community_preference.currency,
      type: 'service',
      is_public: values.isPublic,
      custom_tag_state: JSON.stringify(customTagState), // legacy custom fields
      dynamic_tags: JSON.stringify(values.dynamicTags), // dynamic groups
    }
    if (values.photo) {
      planObj.plan_photo = values.photo
    }
    if (planObj.cost === 'null' || !planObj.cost || planObj.cost === ' ') {
      planObj.cost = 0
    }

    plan_actions
      .createPlan(planObj)
      .then(() => history.push('/plans'))
      .catch(err => {
        setSubmitting(false)
        toast.error(extractCoworksErrorObject(err))
      })
  }
  const updatePlan = (values, setSubmitting) => {
    const customTagState = values.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    const obj = {
      id: plan.id,
      community_id: activeCommunity.id,
      campus_id: activeCampus.id,
      cost: values.cost,
      description: values.description,
      name: values.name,
      frequency: values.frequency,
      conference_hours_default: values.conference_hours_default,
      conference_hour_rate: values.conference_hour_rate,
      include_conference_hours: values.includeConferenceHours, // tells backend to reset values or not
      is_public: values.isPublic,
      custom_tag_state: JSON.stringify(customTagState), // legacy custom fields
      dynamic_tags: JSON.stringify(values.dynamicTags), // dynamic groups
    }
    if (values.imageChanged) {
      obj.plan_photo = values.photo
    }

    plan_actions
      .updatePlan(obj)
      .then(response => {
        toast.success('Updated the plan')
        history.goBack()
      })
      .catch(err => {
        setSubmitting(false)
        toast.error(extractCoworksErrorObject(err))
      })
  }
  const archivePlan = () => {
    plan_actions
      .archivePlan({
        id: plan ? plan.id : null,
        is_archived: true,
        community_id: activeCommunity.id,
      })
      .then(() => {
        toast.success('Archived the plan')
        history.replace('/plans')
      })
      .catch(err => {
        toast.error(extractCoworksErrorObject(err))
      })
  }

  const goBack = () => history.goBack()

  function showLinksAsEnabled(plan, values, touched) {
    if (plan) {
      // we have an existing plan
      if (plan.is_public) return true
      if (values.isPublic && !touched.isPublic) return false
    }
    // we have an new plan
    return false
  }

  // we haven't grabbed plan from redux yet!
  if (match.params.planId && !plan) return <Spinner />
  if (!groups) return <Spinner />
  const customTagState = parseCustomTagState(
    'plan',
    groups,
    plan ? plan.tags : []
  )
  return (
    <Formik
      initialValues={{
        cost: plan && plan.cost ? plan.cost : null,
        description: plan && plan.description ? plan.description : null,
        name: plan && plan.name ? plan.name : null,
        frequency: plan && plan.frequency ? plan.frequency : 'month',
        photo: plan && plan.plan_photo ? plan.plan_photo.large_file_url : null,
        isPublic: plan && plan.is_public ? plan.is_public : null,
        imageChanged: plan && plan.imageChanged ? plan.imageChanged : null,
        tags: plan && plan.tags ? plan.tags : null,
        includeConferenceHours: Boolean(
          plan &&
            plan.conference_hours_default !== null &&
            plan.conference_hours_default !== undefined
        ),
        conference_hours_default:
          plan && plan.conference_hours_default
            ? plan.conference_hours_default
            : null,
        conference_hour_rate:
          plan && plan.conference_hour_rate ? plan.conference_hour_rate : null,
        customTagState,
        dynamicTags: plan && plan.dynamic_tags ? plan.dynamic_tags : [],
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Name is required')
          .typeError('You must have a name'),
        cost: Yup.number()
          .typeError('You must specify a number')
          .required('Cost is required')
          .min(0, 'Must be 0 or more'),
        conference_hours_default: Yup.number().when('includeConferenceHours', {
          is: true,
          then: Yup.number()
            .typeError('You must specify a number')
            .required('Quantity is required!')
            .min(0, 'Must be 0 or more.'),
          otherwise: Yup.number().nullable(),
        }),
        conference_hour_rate: Yup.number().when('includeConferenceHours', {
          is: true,
          then: Yup.number()
            .typeError('You must specify a number')
            .required('Rate is required!')
            .min(0, 'Must be 0 or more.'),
          otherwise: Yup.number().nullable(),
        }),
      })}
      validationSchemaContext={{
        includeConferenceHours,
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (plan && plan.id) {
          updatePlan(values, setSubmitting)
        } else {
          createPlan(values, setSubmitting)
        }
      }}
    >
      {myProps => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = myProps

        const baseUrl = resolveDomainPrefix(
          activeCommunity.community_preference
        )
        const link = `${baseUrl}/membership-signup/${plan && plan.id}`
        const showArchiveButton = Boolean(plan && plan.id)
        return (
          <div className="animated fadeIn">
            <div id="new-plan-form" className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12">
                    <h2>{showArchiveButton ? 'Edit Plan' : 'New Plan'}</h2>
                  </div>
                </div>
              </div>

              <div className="card-block">
                <div className="row">
                  <div className="col-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <ImageUploadComponent
                                imageUrl={values.photo}
                                onUpload={image => {
                                  setFieldValue('imageChanged', true)
                                  setFieldValue('photo', image)
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-9">
                              <h3>Plan</h3>
                              <hr />
                              <div className="row">
                                <div className="col-12 col-md-8">
                                  <Field
                                    label={'Name'}
                                    name="name"
                                    placeholder={`What is it called?`}
                                    component={CustomInputComponent}
                                  />
                                </div>
                              </div>

                              <div className="row my-4">
                                <div className="col-md-8 col-12">
                                  <Field
                                    label={
                                      values.isPublic
                                        ? 'Public Description'
                                        : 'Description'
                                    }
                                    name="description"
                                    rows="3"
                                    placeholder={`Tell your space about ${values.name}`}
                                    component={CustomTextAreaComponent}
                                  />
                                  {values.isPublic && (
                                    <span className=" text-muted">
                                      Note: This description is shown publicly
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-md-3 col-6">
                                  <Field
                                    name="cost"
                                    placeholder="Plan Cost"
                                    label="Cost"
                                    component={CustomInputComponent}
                                  />
                                </div>
                                <div className="col-md-3 col-6">
                                  <Field
                                    label={'Billed'}
                                    name="frequency"
                                    component={CustomSelectComponent}
                                  >
                                    {frequencies.map((frequency, i) => (
                                      <option key={i} value={frequency.name}>
                                        {frequency.adverb}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </div>
                              {/*Conf Room Hours */}
                              <div className="row">
                                <div className="col-md-6">
                                  <Field
                                    name="includeConferenceHours"
                                    labelRight={
                                      values.includeConferenceHours
                                        ? 'Included'
                                        : 'Unlimited'
                                    }
                                    label="Conference Room Hours"
                                    onChange={value => {
                                      setFieldValue(
                                        'includeConferenceHours',
                                        !values.includeConferenceHours
                                      )
                                      setIncludeConferenceHours(
                                        !values.includeConferenceHours
                                      )
                                    }}
                                    component={CustomToggleComponent}
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                {values.includeConferenceHours && (
                                  <React.Fragment>
                                    <div className="col-md-3">
                                      <Field
                                        name="conference_hours_default"
                                        placeholder="Hours Included"
                                        label="Quantity"
                                        component={CustomInputComponent}
                                      />
                                    </div>

                                    <div className="col-md-3">
                                      <Field
                                        name="conference_hour_rate"
                                        placeholder="Hourly rate"
                                        label="Overage Rate"
                                        component={CustomInputComponent}
                                      />
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>

                              <div className="row">
                                <React.Fragment>
                                  <div className="col-md-3 col-12">
                                    <Field
                                      name="isPublic"
                                      label="Public Signup"
                                      labelRight={
                                        values.isPublic
                                          ? 'Public'
                                          : 'Not Public'
                                      }
                                      component={CustomToggleComponent}
                                    />
                                  </div>
                                  {values.isPublic && (
                                    <div className="col-md-9 col-12 mt-4 d-flex align-items-center justify-content-start">
                                      <TextButton
                                        disabled={
                                          !showLinksAsEnabled(
                                            plan,
                                            values,
                                            touched
                                          )
                                        }
                                        onClick={() => {
                                          const elem = document.createElement(
                                            'textarea'
                                          )
                                          document.body.appendChild(elem)
                                          elem.value = link
                                          elem.select()
                                          document.execCommand('copy')
                                          document.body.removeChild(elem)
                                          toast.success('Copied!')
                                        }}
                                      >
                                        <FontAwesomeIcon icon="copy" /> Copy
                                        link
                                      </TextButton>
                                      <TextButton
                                        disabled={
                                          !showLinksAsEnabled(
                                            plan,
                                            values,
                                            touched
                                          )
                                        }
                                        onClick={() => {
                                          window.open(link)
                                        }}
                                      >
                                        <FontAwesomeIcon icon="external-link-alt" />{' '}
                                        View link
                                      </TextButton>
                                    </div>
                                  )}
                                  {!showLinksAsEnabled() ? (
                                    <div className="col-md- col-12">
                                      <span className="text-muted">
                                        You must save before the plan will show
                                        publicly.
                                      </span>
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              {/* New dynamic groups acts-as-taggable */}

                              {renderDynamicGroups({
                                allGroups: activeCommunity.dynamic_groups,
                                handleChange: newValues => {
                                  setFieldValue('dynamicTags', newValues)
                                },
                                existingTagValues: values.dynamicTags,
                                groupType: 'Plan',
                              })}

                              {/* Legacy groups acts-as-taggable */}
                              <div className="row my-3">
                                <div className="col-8 col-md-6">
                                  {renderCustomDropdowns(
                                    groups,
                                    values.customTagState,
                                    (event, groupId, setFieldValue) =>
                                      handleCustomDropdownChange(
                                        event,
                                        groupId,
                                        setFieldValue,
                                        values.customTagState
                                      ),
                                    setFieldValue
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row my-1">
                        <div className="col-md-9">
                          {showArchiveButton ? (
                            <div>
                              <Button color="danger" onClick={archivePlan}>
                                Archive
                              </Button>
                              <span className="text-muted ml-4">
                                Note: Archiving plans will not cancel existing
                                memberships from teams.
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-3">
                          <Button
                            className="mr-3"
                            color={'white'}
                            onClick={goBack}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={isSubmitting || !dirty}
                            type="submit"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

Plan.displayName = 'Plan'

Plan.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  plan_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  isFetching: PropTypes.bool,
  user: PropTypes.object,
  plan: PropTypes.object,
  plans: PropTypes.array,
  groups: PropTypes.array,
}

function mapStateToProps(state, ownProps) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    plans: state.plans,
    plan: pluckObjectByID(state.plans, ownProps.match.params.planId),
    isFetching: state.ui.isFetching,
    user: state.user,
    groups: state.ui.groups,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    plan_actions: bindActionCreators(planActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plan)
