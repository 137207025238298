/* eslint-disable react/display-name,react/prop-types */
import React from 'react'
import { useParams } from 'react-router'
import { QueryStringToJSON } from '@global/Utilities/routeUtilities'

const urlParse = () => WrappedComp => {
  const Comp = ({ location, ...other }) => {
    const params = useParams()
    const queryParams = QueryStringToJSON(location)
    return (
      <WrappedComp
        params={params}
        queryParams={queryParams}
        location={location}
        {...other}
      />
    )
  }
  return Comp
}

urlParse.displayName = 'Url Params'
urlParse.propTypes = {}

export default urlParse
