/* eslint-disable no-invalid-this */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'
// import { DropdownToggle } from '@global/Base/Button/ButtonStyled'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import * as resourceActions from '../../reduxActions/resourceActions'
import * as uiActions from '../../reduxActions/uiActions'
import ResourceRowCard from './ResourceRowCard'

import Placeholder from '@global/Placeholder'
import Spinner from '@global/Spinner'

import {
  resolveFetchingStatus,
  FETCHING_GROUPS,
  FETCHING_RESOURCES,
} from '@global/Constants/FetchingConstants'

class Resources extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    resource_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    resources: PropTypes.array,
    isFetchingResources: PropTypes.bool,
    isFetchingGroups: PropTypes.bool,
    activeCampus: PropTypes.object,
  }

  componentDidMount() {
    if (!this.props.isFetchingResources) {
      this.props.resource_actions.getResources(this.props.activeCampus.id)
    }
    if (!this.props.isFetchingGroups) {
      this.props.ui_actions.getGroups(this.props.activeCampus.id)
    }
  }

  handleNewResource = type => {
    this.props.history.push('/resources/add-resource', { type })
  }

  renderResources = () => {
    const { resources, history, isFetchingResources } = this.props
    if (isFetchingResources) {
      return <Spinner />
    }
    if (!resources) {
      return null
    }
    if (resources.length === 0 && !isFetchingResources) {
      return (
        <Placeholder
          title={'No Resources'}
          icon={'coffee'}
          subtext1={`You currently have no resources in this campus`}
          subtext2={'Add one by clicking New Resource'}
        />
      )
    }
    const tags = []
    const uncategorizedResources = []
    resources.forEach(resource => {
      let found = false
      if (resource.tags && resource.tags.length > 0) {
        resource.tags.forEach(tagObj => {
          tags.forEach(category => {
            if (tagObj.name === category.name) {
              found = true
              category.resources.push(resource)
            }
          })
        })
        if (!found) {
          tags.push({ name: resource.tags[0].name, resources: [resource] })
        }
      } else {
        uncategorizedResources.push(resource)
      }
    })
    if (tags.length === 0) {
      return (
        <div className="row">
          <div className="col-md-12">
            <h4 className="text-muted">Uncategorized</h4>
            <h6 className="text-muted">
              {' '}
              - will not show in mobile until categorized
            </h6>
            <hr />
          </div>
          {resources.map((resource, i) => (
            <div className="col-md-12">
              <ResourceRowCard key={i} history={history} resource={resource} />
            </div>
          ))}
        </div>
      )
    }
    return (
      <React.Fragment>
        {tags.map(tag => (
          <div className="row">
            <div className="col-12">
              <h4>
                <b>{tag.name}</b>
              </h4>
              <hr className="mt-0 mb-1" />
            </div>
            {tag.resources.map((resource, i) => (
              <div className="col-12">
                <ResourceRowCard
                  key={i}
                  history={history}
                  resource={resource}
                />
              </div>
            ))}
            <div className="col-12 mb-5" />
          </div>
        ))}
        {uncategorizedResources.length > 0 && (
          <div className="row">
            <div className="col-12">
              <h4 className="text-muted">Uncategorized</h4>
              <span className="text-muted">
                {' '}
                Note: Uncategorized resources are not visible in the mobile
                apps.
              </span>
              <hr />
            </div>
            {uncategorizedResources.map((resource, i) => (
              <div className="col-12">
                <ResourceRowCard
                  key={resource.id * i}
                  history={history}
                  resource={resource}
                />
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h2>Resources</h2>
                  </div>
                  <div className="col-md-6 col-12 d-flex align-items-center justify-content-end">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        className="btn btn-primary"
                        caret
                        id="new-resource"
                      >
                        New Resource
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => this.handleNewResource('TextBased')}
                        >
                          <FontAwesomeIcon icon="file-alt" /> Text
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNewResource('Slides')}
                        >
                          <FontAwesomeIcon icon="image" /> Image Slides
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNewResource('Form')}
                        >
                          <FontAwesomeIcon icon="check-square" /> Form
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNewResource('Website')}
                        >
                          <FontAwesomeIcon icon="external-link-alt" /> Website
                          Link
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              </div>
              <div className="card-block">{this.renderResources()}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Resources.displayName = 'Resources'

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    resources: state.resources,
    isFetchingResources: resolveFetchingStatus(state, FETCHING_RESOURCES),
    isFetchingGroups: resolveFetchingStatus(state, FETCHING_GROUPS),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resource_actions: bindActionCreators(resourceActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resources)
