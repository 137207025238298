/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import Placeholder from '@global/Placeholder'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'

const TableBody = styled.tbody`
  .container-fluid {
    padding: 0;
  }
`

function Body({ nextPage = () => null, ...other }) {
  return (
    <TableBody>
      <PaginatedTable
        removeWrapper
        hidePlaceholder
        nextPage={nextPage}
        {...other}
      />
    </TableBody>
  )
}

Body.propTypes = {
  nextPage: PropTypes.func,
  data: PropTypes.array,
  renderItem: PropTypes.func,
}

Body.defaultProps = {
  data: [],
}

Body.displayName = 'Body'

export default Body
