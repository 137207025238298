const bookingColors = [
  '#4a69bd',
  '#3cb44b',
  '#e6194b',
  '#6a89cc',
  '#82ccdd',
  '#b8e994',
  '#f6b93b',
  '#78e08f',
  '#60a3bc',
  '#fa983a',
  '#eb2f06',
  '#1e3799',
  '#3c6382',
  '#38ada9',
  '#e58e26',
  '#b71540',
  '#079992',
  '#0082c8',
  '#f58231',
  '#f8c291',
  '#6a89cc',
  '#82ccdd',
  '#b8e994',
  '#911eb4',
  '#f032e6',
  '#d75f00',
  '#e6beff',
  '#aa6e28',
  '#5f00d7',
  '#808000',
  '#008080',
  '#afd7ff',
  '#d7d700',
  '#005fd7',
  '#87d700',
  '#5f0000',
  '#9ACD32',
  '#FA8072',
  '#483D8B',
  '#00FF7F',
  '#9370DB',
  '#000080',
  '#708090',
  '#8A2BE2',
  '#FF00FF',
  '#8B4513',
  '#0000FF',
  '#B0E0E6',
  '#EE82EE',
  '#D2B48C',
  '#2E8B57',
  '#696969',
  '#90EE90',
  '#B8860B',
  '#4B0082',
  '#FFFF00',
  '#7FFFD4',
  '#006400',
  '#FF4500',
  '#B22222',
  '#FFA500',
  '#5F9EA0',
  '#000080',
  '#800080',
  '#7FFF00',
  '#8A2BE2',
  '#FFA07A',
  '#ADFF2F',
  '#8FBC8F',
  '#9ACD32',
  '#87CEEB',
  '#8B0000',
  '#D2B48C',
  '#008B8B',
  '#DEB887',
  '#FF69B4',
  '#B0C4DE',
  '#483D8B',
  '#DC143C',
  '#7CFC00',
]
export default bookingColors
