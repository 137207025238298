import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Anchor from '@global/Base/Anchor/Anchor'
import * as _ from 'lodash'
import RoundedImage from '@global/RoundedImage'

const Container = styled.div`
  display: flex;
  flex: row nowrap;
  align-items: center;
`
const InfoWrapper = styled.div`
  width: 0;
  margin-left: 12px;
  display: flex;
  flex: 1 0 auto;
`
const InfoContent = styled.div`
  width: 100%;
`
const TopWrapper = styled.h6`
  line-height: 16px;
  max-height: 32px;
  float: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`
const BottomWrapper = styled.span`
  float: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

function MemberCol({
  first,
  last,
  email,
  photo,
  disabled,
  onClick,
  hideImage,
}) {
  return (
    <Container>
      {!hideImage && (
        <RoundedImage className="img-responsive" photo={photo} alt="Picture" />
      )}
      <InfoWrapper>
        <InfoContent>
          {disabled ? (
            <TopWrapper>{`${first} ${last}`}</TopWrapper>
          ) : (
            <Anchor onClick={onClick}>
              <TopWrapper>{`${first} ${last}`}</TopWrapper>
            </Anchor>
          )}
          <BottomWrapper className="text-muted">{email}&nbsp;</BottomWrapper>
        </InfoContent>
      </InfoWrapper>
    </Container>
  )
}

MemberCol.propTypes = {
  first: PropTypes.string,
  last: PropTypes.string,
  email: PropTypes.string,
  photo: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hideImage: PropTypes.bool,
}

MemberCol.defaultProps = {
  hideImage: false,
}

MemberCol.displayName = 'Member Column'

export default MemberCol
