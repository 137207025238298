export const FETCHING_PENDING_MEMBERS = 'pendingMembers'
export const FETCHING_PENDING_LEADS = 'pendingLeads'

export const FETCHING_PLANS = 'plans'
export const FETCHING_DAY_PASS = 'dayPass'
export const FETCHING_DAY_PASS_CHECKOUT = 'dayPassCheckout'
export const FETCHING_CREATE_DAY_PASS = 'createDayPass'

export const FETCHING_UPDATING_CAMPUS = 'upatingCampus'

export const FETCHING_INTEGRATIONS = 'integrations'
export const FETCHING_DOOR_ACCESS_GROUPS = 'doorAccessGroups'
export const FETCHING_DOOR_ACCESS_PLACES = 'doorAccessPlaces'
export const FETCHING_STRIPE_OAUTH_NAME = 'StripeOauthConnection'
export const FETCHING_MIGRATION = 'Migrations'
export const FETCHING_SETTINGS = 'CommunitySettings'

export const FETCHING_TEAMS = 'teams'
export const FETCHING_TEAMS_INACTIVE = 'inactiveTeams'
export const FETCHING_TEAM_MEMBERS = 'teamMembers'
export const FETCHING_MEMBER = 'member'
export const FETCHING_MEMBERS = 'members'
export const FETCHING_MEMBERS_INACTIVE = 'inactiveMembers'
export const FETCHING_LEADS = 'leads'
export const FETCHING_PROSPECTS = 'prospects'
export const FETCHING_CHECKINS = 'checkins'
export const FETCHING_CHECKINS_VISITOR = 'checkinsVisitor'
export const FETCHING_CHECKINS_PASSPORT = 'checkinsPassport'
export const FETCHING_UPDATING_USER = 'updatingUser'

export const FETCHING_GROUPS = 'groups'

export const FETCHING_COWORKS_CUSTOMER = 'isFetchingCoworksCustomer'
export const FETCHING_COWORKS_CUSTOMER_NAME = 'CoworksCustomer'

export const FETCHING_ROOMS = 'rooms'
export const FETCHING_EXBOOKING = 'externalBooking'
export const FETCHING_EXBOOKING_ROOMS = 'externalRooms'

export const FETCHING_DASHBOARD_STATS = 'dashboardStats'

export const FETCHING_REPORTS = 'isFetchingReport'

export const FETCHING_RESOURCES = 'resources'

export const FETCHING_INVOICES_PAID = 'invoicesPaid'
export const FETCHING_INVOICES_UNPAID = 'invoicesUnpaid'
export const FETCHING_INVOICES_CLOSED = 'invoicesClosed'
export const FETCHING_INVOICES_PAUSED = 'invoicesPaused'

export const FETCHING_BOOKING_THICC = 'bookingThicc'
export const FETCHING_ATTENDEES = 'attendees'

export const FETCHING_OCCUPANCY = 'occupancy'

export function resolveFetchingStatus(state, fetchingName) {
  return state &&
    state.ui &&
    state.ui.fetching &&
    state.ui.fetching[fetchingName]
    ? state.ui.fetching[fetchingName]
    : false
}
