/* eslint-disable no-invalid-this,quote-props */
import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import moment from 'moment'
import CoworksSearch from '@global/Search/CoworksSearch'

import * as Constants from '@global/Constants'
import * as _ from 'lodash'

import {
  resolveFetchingStatus,
  FETCHING_OCCUPANCY,
} from '@global/Constants/FetchingConstants'

import * as roomActions from '@reduxActions/roomActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'

import Filter from '@components/External/ExternalBooking/Filter'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import ListCard from './ListCard'
import conferencePlaceholder from '@app/img/placeholders/rooms_placeholder.jpeg'
import RoomSearchRow from '@global/Search/SearchRowComponents/RoomSearchRow'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
const PaginatedTableStyled = styled(PaginatedTable)`
  position: relative;
  flex: 1;
`
const FilterStyled = styled(Filter)`
  margin-bottom: 20px;
`

const ListCardStyled = styled(ListCard)`
  margin-bottom: 20px;
`

const radios = currencySymbol => [
  {
    label: `Less than ${currencySymbol}500`,
    value: JSON.stringify({ min: null, max: 499 }),
  },
  {
    label: `${currencySymbol}500 to ${currencySymbol}1500`,
    value: JSON.stringify({ min: 500, max: 1500 }),
  },
  {
    label: `${currencySymbol}1500 to ${currencySymbol}3000`,
    value: JSON.stringify({ min: 1500, max: 3000 }),
  },
  {
    label: `${currencySymbol}3000 to ${currencySymbol}5000`,
    value: JSON.stringify({ min: 3000, max: 5000 }),
  },
  {
    label: `Greater than ${currencySymbol}5000`,
    value: JSON.stringify({ min: 5001, max: null }),
  },
]

const sortByOptions = [
  {
    label: 'Vacant',
    value: 'is_occupied.ASC',
  },
  {
    label: 'Occupied',
    value: 'is_occupied.DESC',
  },
  {
    label: 'Name: A - Z',
    value: 'name.ASC',
  },
  {
    label: 'Name: Z - A',
    value: 'name.DESC',
  },
  {
    label: 'Price: high',
    value: 'cost.DESC',
  },
  {
    label: 'Price: low',
    value: 'cost.ASC',
  },
  {
    label: 'Capacity: high',
    value: 'capacity.DESC',
  },
  {
    label: 'Capacity: low',
    value: 'capacity.ASC',
  },
  {
    label: 'Size: high',
    value: 'size.DESC',
  },
  {
    label: 'Size: low',
    value: 'size.ASC',
  },
]

const start = moment()
const remainder = 30 - (start.minute() % 30)

const initialFilter = {
  attributes: {
    priceRange: null,
    capacity: 1,
    amenities: [],
    availability: null,
  },
  sort: sortByOptions[0].value,
}

const SuiteList = ({
  match,
  theme,
  rooms,
  activeCampus,
  history,
  stats,
  ui_actions,
  breakpoint,
  pagination,
  room_actions,
  isFetchingRooms,
  activeCommunity,
  onUpdateBookingCriteria,
  ...other
}) => {
  const defaultFilterParams = {
    sort: initialFilter.sort,
    ...initialFilter.attributes,
  }

  const [page, setPage] = useState(Constants.START_PAGE_DEFAULT)
  const [nextTriggered, setTrigger] = useState(false)
  const [filterParams, setFilterParams] = useState(defaultFilterParams)
  const perPage = Constants.PER_PAGE_DEFAULT
  const prevPageRef = useRef()
  const currencySymbol = Constants.getCurrencySymbolFromCommunity(
    activeCommunity
  )

  useEffect(() => {
    if (activeCampus && activeCommunity) {
      const prevPage = prevPageRef.current
        ? prevPageRef.current
        : Constants.START_PAGE_DEFAULT
      if (page > prevPage) {
        setTrigger(false)
        loadNextObjects(page, perPage)
      } else {
        loadNextObjects(Constants.START_PAGE_DEFAULT, perPage, true)
      }
      prevPageRef.current = page
    }
    // eslint-disable-next-line
  }, [page, activeCampus, filterParams])

  const getEnds = () => {
    const { capacity, sort, availability, priceRange } = filterParams
    const is_occupied = availability !== null ? availability : null
    let rangeObj = {}
    if (priceRange) {
      rangeObj = JSON.parse(priceRange)
    }
    const includeStats = page <= 0 || !stats
    return {
      suite_cost_min: rangeObj.min,
      suite_cost_max: rangeObj.max,
      capacity_min: capacity,
      order_by: JSON.stringify([sort]),
      types: JSON.stringify(['Suite']),
      serializer: 'RoomSerializer',
      include_occupancy_stats: includeStats,
      is_occupied,
    }
  }

  const updateFilterParams = params => {
    const rest = { ...filterParams }
    const dup = Object.assign(rest, params)
    setFilterParams(dup)
    setPage(Constants.START_PAGE_DEFAULT)
  }

  const roomDataResolver = response => {
    return response.rooms
  }

  const renderRoomSearchRow = room => <RoomSearchRow room={room} />

  const nextPage = () => {
    if (isFetchingRooms || nextTriggered) return

    if (
      (pagination &&
        rooms &&
        rooms.length < pagination.total_objects &&
        page < pagination.total_pages) ||
      !rooms
    ) {
      const newPage = page + 1
      setTrigger(true)
      setPage(newPage)
    }
  }

  const loadNextObjects = (page, per_page, clear) => {
    const params = getEnds()
    room_actions.getRooms(
      {
        campus_id: activeCampus.id,
        community_id: activeCommunity.id,
        page,
        per_page,
        ...params,
      },
      clear,
      roomActions.SUITE
    )
  }
  const handleSuiteSelect = suite => {
    history.push(`/occupancy/${suite.id}`, { room: suite })
  }

  const renderListCard = room => {
    const imageSrc = room.room_photo
      ? room.room_photo.file_url
      : conferencePlaceholder
    function handleClick() {
      handleSuiteSelect(room)
    }
    const metric = Constants.getMinifiedMetric(activeCampus.area_units, true)

    return (
      <ListCardStyled
        room={room}
        img={imageSrc}
        onClick={handleClick}
        history={history}
        metric={metric}
        match={match}
      />
    )
  }

  const searchText = (results, params) => {
    const queryDisplay = ['showing']
    queryDisplay.push(<b>{` ${results.length} `}</b>)
    if (params.availability !== null) {
      queryDisplay.push(
        <b>{` ${params.availability ? 'occupied' : 'vacant'} `}</b>
      )
    }
    queryDisplay.push('suites')
    if (params.priceRange) {
      queryDisplay.push(' where')
      const { min, max } = JSON.parse(params.priceRange)
      if (min && max) {
        queryDisplay.push(<b>{` cost is ${currencySymbol}${min}-${max} `}</b>)
      } else if (min) {
        queryDisplay.push(<b>{` cost is above ${currencySymbol}${min} `}</b>)
      } else if (max) {
        queryDisplay.push(<b>{` cost is below ${currencySymbol}${max} `}</b>)
      }

      if (params.capacity > 1) {
        queryDisplay.push('and')
        queryDisplay.push(<b>{` minimum capacity is ${params.capacity} `}</b>)
      }
    } else if (params.capacity > 1) {
      queryDisplay.push(' where')
      queryDisplay.push(<b>{` minimum capacity is ${params.capacity} `}</b>)
    }

    return <div>{queryDisplay}</div>
  }

  return (
    <Container {...other}>
      <FilterStyled
        rooms={rooms ? rooms : []}
        sortOptions={sortByOptions}
        defaultValue={defaultFilterParams}
        value={filterParams}
        onChange={updateFilterParams}
        currencySymbol={currencySymbol}
        radioOptions={radios(currencySymbol)}
        showTimeFilter={false}
        showCount={false}
        external={false}
        queryResultText={searchText}
        searchBar={
          <CoworksSearch
            isSearch={false}
            placeholder={'Search Suites'}
            renderSingleValue={Constants.renderRoomSelected}
            defaultData={rooms}
            actions={room_actions}
            otherParams={{
              types: JSON.stringify(['Suite']),
            }}
            activeCampus={activeCampus}
            handleItemSelected={handleSuiteSelect}
            dataResolver={roomDataResolver}
            // handleSearchCleared={
            //   this.handleRoomSearchItemCleared
            // }
            // value={null}
            isClearable
            renderSearchRow={renderRoomSearchRow}
          />
        }
      />
      <PaginatedTableStyled
        objects={rooms}
        type={'Suites'}
        nextPage={nextPage}
        wrapperClassName=""
        icon="chair-office"
        containerClassName=""
        isLoading={isFetchingRooms}
        totalPages={pagination && pagination.total_pages}
        renderItem={bookings => renderListCard(bookings)}
      />
    </Container>
  )
}

SuiteList.propTypes = {
  history: PropTypes.object,
  activeCommunity: PropTypes.object,
  room_actions: PropTypes.object,
  timeParams: PropTypes.object,
  onUpdateBookingCriteria: PropTypes.func,
  location: PropTypes.object,
  ui_actions: PropTypes.object,
  match: PropTypes.object,
  bookings: PropTypes.array,
  isFetchingRooms: PropTypes.bool,
  rooms: PropTypes.array,
  members: PropTypes.array,
  pagination: PropTypes.object,
  stats: PropTypes.object,
  breakpoint: PropTypes.string,
  activeCampus: PropTypes.object,
  theme: PropTypes.object,
}

SuiteList.displayName = 'Suite List'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetchingRooms: resolveFetchingStatus(state, FETCHING_OCCUPANCY),
    stats: roomSelectors.getSuiteStats(state),
    rooms: roomSelectors.getSuiteList(state),
    pagination: roomSelectors.getSuitePagination(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuiteList)
