/* eslint-disable react/display-name,react/prop-types */
import React from 'react'

import ErrorBoundary from '@global/ErrorBoundry'

const withErrorBoundry = WrappedComponent => {
  const errorBoundry = props => {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    )
  }
  return errorBoundry
}
export default withErrorBoundry
