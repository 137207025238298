import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import moment from 'moment'
import {
  CustomInputComponent,
  CustomToggleComponent,
  CustomSelectComponent,
  CustomPresetSelectComponent,
  LabelStyled,
} from '@global/Form/FormComponents'
import { Row, Collapse } from 'reactstrap'

import { Button } from '@global/Base/Button/ButtonStyled'

// import Tooltip from '@global/Base/Tooltip/Tooltip'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as Roles from '@app/config/roles'

function SuperAdminSettings({
  activeCommunity,
  stripeLink,
  testPublicUserPreferencesLink,
  sendCommunityOnboardingEmails,
  convertAllPendingMembers,
  disableOnboardingEmailButton,
  setFieldValue,
  user,
}) {
  const [collapse, setCollapse] = useState(false)
  const toggle = () => setCollapse(!collapse)
  return (
    <React.Fragment>
      <div className="bg-light">
        <h4>Super Admin Community Settings</h4>
        <br />
        <Field
          name="subdomain"
          label="Subdomain"
          component={CustomInputComponent}
        />
        <br />
        <Field
          label="What is the status of this account?"
          name="status"
          description="Only internal so we know which accounts are demos, active & signed."
          component={CustomSelectComponent}
        >
          <option value={'unclassified'}>unclassified</option>
          <option value={'trialing'}>Trialing - The account is trialing</option>
          <option value={'onboarding'}>
            Onboarding - This account is onboarding
          </option>
          <option value={'live'}>Live</option>
          <option value={'trail_ended'}>
            Trail Ended - The account was trialing, but now its ended (will not
            be automatically set)
          </option>
          <option value={'paused'}>Paused - The account is paused</option>
          <option value={'demo_account'}>
            Demo Account - This is a demo account
          </option>
          <option value={'delinquent_by_subscription'}>
            Delinquent by Subscription - The account hasn't paid its Coworks
            subscription in over 3 months
          </option>
          <option value={'terminated'}>
            Terminated - This will block managers and members out of the account
          </option>
        </Field>
        <div className="ml-4">
          <div>
            account_trial_end_at:{' '}
            {moment(
              activeCommunity.community_preference.account_trial_end_at
            ).format('MMMM DD, YYYY hh:mm a')}
          </div>
          <div>
            account_onboarding_ended_at:{' '}
            {moment(
              activeCommunity.community_preference.account_onboarding_ended_at
            ).format('MMMM DD, YYYY hh:mm a')}
          </div>
          <div>
            account_live_at:{' '}
            {moment(
              activeCommunity.community_preference.account_live_at
            ).format('MMMM DD, YYYY hh:mm a')}
          </div>
          <div>
            account_terminated_at:{' '}
            {moment(
              activeCommunity.community_preference.account_terminated_at
            ).format('MMMM DD, YYYY hh:mm a')}
          </div>
        </div>
        <Row>
          <div className="col-md-12">
            <br />
            <LabelStyled>Community Info</LabelStyled>
            <br />
            <p>
              Community:{' '}
              {`id: ${activeCommunity.id}, name: ${activeCommunity.name}`}
            </p>{' '}
            <p>
              Campuses: <br />
              {activeCommunity.campuses.map(
                campus => `[id: ${campus.id}, name: ${campus.name}, ]`
              )}
            </p>
            <b>
              Stripe Status:{' '}
              <span
                className={`badge badge-${
                  activeCommunity.stripe_account_id ? 'success' : 'danger'
                }`}
              >
                {activeCommunity.stripe_account_id ? 'Setup' : 'NOT SETUP'}
              </span>
            </b>{' '}
            <LabelStyled>
              <a target="blank_" href={stripeLink}>
                Stripe Dashboard Account
              </a>
            </LabelStyled>
            <br />
            <LabelStyled>
              <a target="blank_" href={testPublicUserPreferencesLink}>
                Test Public User Preferences Link
              </a>
            </LabelStyled>
            <br />
            <br />
          </div>
        </Row>
        <Button
          color="primary"
          onClick={toggle}
          style={{ marginBottom: '1rem' }}
        >
          Toggle Super Admin Actions
        </Button>
        <Collapse isOpen={collapse}>
          <div className="card p-4">
            <div className="row">
              <div className="col-6">
                <Button onClick={convertAllPendingMembers}>
                  Convert all PendingMembers to Members
                </Button>
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Button
                  color="danger"
                  onClick={sendCommunityOnboardingEmails}
                  disabled={disableOnboardingEmailButton}
                >
                  Send Community Onboarding emails
                </Button>
              </div>
            </div>
            <br />
          </div>
        </Collapse>

        <br />
        <h3>Billing</h3>
        <Field
          label="Alert to setup billing?"
          name="billing_enabled"
          description="Will alert the manager to setup billing if they don't have it setup"
          component={CustomToggleComponent}
        />
        <Field
          label="Currency"
          name="currency"
          type="text"
          description="This setting should only be changed at start of a community before plans/subscriptions are created."
          dropdownType="currency"
          component={CustomPresetSelectComponent}
        />
        <br />
        <h3>Branding</h3>
        <Field
          label="Whitelabeled?"
          name="is_whitelabeled"
          description="Tells the dashboard & mobile apps that its whitelabeled."
          component={CustomToggleComponent}
        />
        <Field
          name="branding_app_name"
          label="Mobile App Name"
          component={CustomInputComponent}
        />
        <Field
          name="branding_primary_text_color"
          label="Primary Text Color"
          component={CustomSelectComponent}
        >
          <option value="#FFFFFF">Light</option>
          <option value="#000001">Dark</option>
        </Field>
        <Field
          name="branding_secondary_text_color"
          label="Secondary Text Color"
          component={CustomSelectComponent}
        >
          <option value="#FFFFFF">Light</option>
          <option value="#000001">Dark</option>
        </Field>
        <Field
          name="branding_login_background_image_url"
          label="Login Background Image Url"
          component={CustomInputComponent}
        />
        <Field
          name="branding_resources_header_image_url"
          label="Resources Header Image Url"
          component={CustomInputComponent}
        />
        <Field
          name="website_signup_url"
          label="Member sign-up Link (for login page on apps)"
          component={CustomInputComponent}
        />
        <Field
          label="Android Store URL"
          name="android_store_url"
          description="The link to the android store sent in the welcome onboarding email. (if blank, default coworks app sent)"
          component={CustomInputComponent}
        />
        <Field
          label="iOS Store URL"
          name="ios_store_url"
          description="The link to the ios store sent in the welcome onboarding email. (if blank, default coworks app sent)"
          component={CustomInputComponent}
        />
        <br />
        <h3>General</h3>
        <Field
          label="Whats the max number of campuses allowed?"
          name="campus_max"
          type="number"
          description="This will allow the manager to create new campuses in the dash."
          component={CustomInputComponent}
        />
        <Field
          label="Whats the max number of custom fields allowed?"
          name="custom_field_max"
          type="number"
          description="This will allow the manager to create new campuses in the dash."
          component={CustomInputComponent}
        />

        <br />
        <br />
        <h3>Migrations</h3>
        <Field
          label="Show Booking Migration?"
          name="show_booking_migration"
          description="Will show/hide the booking migration section in the managers migration setting tab"
          component={CustomToggleComponent}
        />
        <Field
          label="Show Member Migration?"
          name="show_member_migration"
          description="Will show/hide the member migration section in the managers migration setting tab"
          component={CustomToggleComponent}
        />
      </div>
    </React.Fragment>
  )
}
SuperAdminSettings.displayName = 'SuperAdminSettings'

SuperAdminSettings.propTypes = {
  activeCommunity: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  stripeLink: PropTypes.string,
  testPublicUserPreferencesLink: PropTypes.string,
  disableOnboardingEmailButton: PropTypes.string,
  sendCommunityOnboardingEmails: PropTypes.func,
  convertAllPendingMembers: PropTypes.func,
  setFieldValue: PropTypes.func,
  jsonString: PropTypes.string,
}

export default SuperAdminSettings
