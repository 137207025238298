/* eslint-disable no-invalid-this,quote-props */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import * as googleCalendarActions from '@reduxActions/googleCalendarActions'
import * as memberActions from '@reduxActions/memberActions'
import * as teamActions from '@reduxActions/teamActions'
import * as leadActions from '@reduxActions/leadActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'
import * as memberSelectors from '@reduxSelectors/memberSelectors'

import moment from 'moment'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as _ from 'lodash'

import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Collapse,
  CardBody,
  Card,
  Row,
} from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import Spinner from '@global/Spinner'

import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import TogglesComponent from '@global/Base/Toggle/TogglesComponent'
import Badge from '@global/Base/Badge/Badge'
import * as Constants from '@global/Constants'
import QuickSearch from '@global/Search/QuickSearch'
import {
  resolveFetchingStatus,
  FETCHING_ROOMS,
  FETCHING_MEMBERS,
  FETCHING_TEAMS,
  FETCHING_LEADS,
} from '@global/Constants/FetchingConstants'

import BigCal from './BigCal'
import EventModalComponent from './EventModalComponent'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './big-calendar-overrides.scss'

const EVENTS_AND_BOOKINGS_KEY = 'Events & Bookings'
const EQUIPMENT_KEY = 'Equipment'

const BadgeStyled = styled(Badge)`
  margin-left: 0.5rem;
`
class Calendar extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    booking_actions: PropTypes.object,
    room_actions: PropTypes.object,
    member_actions: PropTypes.object,
    team_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    google_actions: PropTypes.object,
    bookings: PropTypes.array,
    isFetching: PropTypes.bool,
    isFetchingRooms: PropTypes.bool,
    isFetchingMembers: PropTypes.bool,
    isFetchingTeams: PropTypes.bool,
    rooms: PropTypes.array,
    members: PropTypes.array,
    teams: PropTypes.array,
    leads: PropTypes.array,
    googleCalendars: PropTypes.array,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    role: PropTypes.string,
    user: PropTypes.object,
    theme: PropTypes.object,
  }

  constructor(props) {
    super(props)
    let goToDate = moment().startOf('day').add(8, 'hours').toDate()
    let isPrimaryOpen = false
    let activeEvent = null

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state
    ) {
      const { returnToDate, event } = this.props.history.location.state
      if (returnToDate)
        goToDate = moment(
          this.props.history.location.state.returnToDate
        ).toDate()

      if (event) {
        isPrimaryOpen = true
        activeEvent = event
      }
    }

    const date = goToDate || moment().toDate()
    const earliestDate = moment(date).startOf('week').toISOString()
    const latestDate = moment(date).endOf('week').toISOString()

    this.state = {
      isPrimaryOpen,
      showEvents: true,
      showBookings: true,
      showMember: true,
      showGuest: true,
      showExternal: true,
      showMemberSource: true,
      showAdmin: true,
      showWalkup: true,
      bigCalEvents: [],
      bigCalEventsRAW: [],
      fetchedRooms: false,
      fetchedBookings: false,
      rooms: [],
      viewState: 'week',
      isAuthorizedWithGoogle: false,
      isGoogleApiReady: false,
      isError: false,
      goToDate,
      eventColor: this.props.theme.colorPrimary,
      bookingColor: this.props.theme.colorSecondary,
      showFilters: true,
      earliestDate: null,
      latestDate: null,
      calendarStartDate: earliestDate,
      calendarEndDate: latestDate,
      equipmentName:
        props.activeCommunity.community_preference.equipment_resource_name,
      equipmentEnabled:
        props.activeCommunity.community_preference.equipment_enabled,
      activeCalendarTab: EVENTS_AND_BOOKINGS_KEY,
      event: activeEvent,
    }
  }

  componentDidMount() {
    const {
      activeCampus,
      room_actions,
      isFetchingRooms,
      rooms,
      member_actions,
      isFetchingMembers,
      members,
      team_actions,
      isFetchingTeams,
      teams,
    } = this.props
    const { goToDate, memberToFilter, teamToFilter, guestToFilter } = this.state
    if (!isFetchingRooms) {
      if (rooms && rooms.length === 0) {
        room_actions
          .getRoomsAtCampus(activeCampus.id, {
            types: JSON.stringify(['Conference', 'Space', 'Equipment']),
            serializer: 'RoomSerializer',
          })
          .then(response => {
            this.parseRooms(this.props.rooms)
          })
          .catch(err => {
            toast.error('Error getting the rooms!')
            console.error(err)
          })
      } else {
        this.parseRooms(this.props.rooms)
      }
    }
    if (!isFetchingMembers && (!members || (members && members.length === 0))) {
      member_actions.getMembers(this.props.activeCampus.id, {})
    }
    if (!isFetchingTeams && (!teams || (teams && teams.length === 0))) {
      team_actions.getDirectoryTeams(this.props.activeCampus.id, {})
    }

    // parse google

    this.props.google_actions
      .injectGApiScript(10)
      .then(gApi => {
        this.setState({ googleApi: gApi })
        this.props.google_actions
          .initializeGApi(gApi)
          .then(() => {
            this.setState({ isGoogleApiReady: true })
            this.props.google_actions
              .checkGoogleLoginStatus(gApi)
              .then(() => {
                this.setState(
                  { isAuthorizedWithGoogle: true },
                  this.props.google_actions.getCalendars(
                    this.state.googleApi,
                    {}
                  )
                )
              })
              .catch(() => {
                // err
                // Not logged into google. That's fine!
              })
          })
          .catch(err => {
            // err
            console.log('Error initializing the Gapi scripts', err)
          })
      })
      .catch(err => {
        console.log('Error injecting the Gapi scripts', err)
        this.setState({ isGoogleApiReady: false })
      })

    this.updateDataApi({
      campusId: activeCampus.id,
      fromDate: this.state.calendarStartDate,
      toDate: this.state.calendarEndDate,
      memberToFilter,
      teamToFilter,
      guestToFilter,
      rooms: this.state.rooms,
      activeTab: this.state.activeCalendarTab,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // todo replace with static getDerivedStateFromProps(props, state) {
    if (
      !this.state.fetchedRooms &&
      !nextProps.isFetchingRooms &&
      nextProps.rooms &&
      nextProps.rooms.length > 0
    ) {
      this.parseRooms(nextProps.rooms)
    }
  }

  parseRooms = rooms => {
    const existingRooms = _.clone(rooms)
    const nextRooms = []

    existingRooms.map((room, i) => {
      nextRooms.push({ ...room, filterActive: true })
    })
    this.setState(
      {
        rooms: nextRooms,
        fetchedRooms: true,
      },
      () => {
        this.applyRoomColors()
      }
    )
  }
  parseBookings = bookings => {
    const existingBookings = []

    bookings.forEach((event, i) => {
      const name = event.name ? event.name : 'Booking'
      const obj = {
        ...event,
        start: moment(event.start_time).toDate(),
        end: moment(event.end_time).toDate(),
        index: i,
        name,
        description: event.description,
        allDay: false, // currently don't support full day events/bookings
        color: this.getColor({ event }),
      }
      if (event.type === 'Event' && this.state.showEvents) {
        existingBookings.push(obj)
      } else if (
        (event.type === 'Booking' ||
          !event.type ||
          event.type === null ||
          event.type === undefined) &&
        this.state.showBookings
      ) {
        existingBookings.push(obj)
      }
    })
    return existingBookings
  }
  mergeBookings = (oldBookings, newBookings) => {
    // merge these two arrays and replace oldBookings with newBookings
    // where the ids match
    return [
      ...oldBookings
        .concat(newBookings)
        .reduce(
          (left, right) =>
            left.set(right.id, Object.assign(left.get(right.id) || {}, right)),
          new Map()
        )
        .values(),
    ]
  }
  updateDataLocal = () => {
    this.setState({
      fetchedBookings: false,
    })
    const bigCalEvents = this.parseBookings(this.state.bigCalEventsRAW)
    this.setState(
      {
        bigCalEventsRAW: this.mergeBookings(
          this.state.bigCalEventsRAW,
          bigCalEvents
        ),
        fetchedBookings: true,
      },
      () => {
        this.applyRoomColors()
        this.handleToggleTab(this.state.activeCalendarTab)
        this.refreshFilters()
        //this.applyInitialFilters()
      }
    )
  }
  updateDataApi = (
    {
      campusId,
      fromDate,
      toDate,
      memberToFilter,
      teamToFilter,
      guestToFilter,
      rooms,
      activeTab,
    },
    clear = false
  ) => {
    this.setState({
      fetchedBookings: false,
    })

    const queryParams = {
      private: true,
      from_date: fromDate,
      to_date: toDate,
      serializer: 'Dashboard',
    }
    if (rooms && rooms.length > 0) {
      // rooms are fetching in a different api call so we may not have them yet
      const roomTypes =
        activeTab === EVENTS_AND_BOOKINGS_KEY
          ? ['Conference', 'Space', null]
          : ['Equipment']
      queryParams.room_ids = JSON.stringify(
        _.filter(
          rooms,
          room => room.filterActive && roomTypes.includes(room.type)
        ).map(({ id }) => id)
      )
    }
    const bookingTypes = []
    if (this.state.showBookings) {
      bookingTypes.push('Booking')
    }
    if (this.state.showEvents) {
      bookingTypes.push('Event')
    }

    // By Source
    const arr = []
    if (this.state.showExternal) arr.push('External')
    if (this.state.showMemberSource) arr.push('Member')
    if (this.state.showAdmin) arr.push('Admin')
    if (this.state.showWalkup) arr.push('WalkUp')

    queryParams.created_by_sources = JSON.stringify(arr)

    // By Who
    const gust = this.state.showGuest
    const mem = this.state.showMember
    queryParams.is_guest = mem === gust ? null : gust

    queryParams.types = JSON.stringify(bookingTypes)
    if (memberToFilter) {
      queryParams.user_id = memberToFilter.id
    }
    if (teamToFilter) {
      queryParams.team_id = teamToFilter.id
    }
    if (guestToFilter) {
      queryParams.contact_id = guestToFilter.id
    }
    this.props.booking_actions
      .getBookings(campusId, queryParams, clear)
      .then(response => {
        const newBigCalEventsRaw = this.parseBookings(
          response.bookings ? response.bookings : response.events
        )
        this.setState(
          {
            bigCalEventsRAW: this.mergeBookings(
              this.state.bigCalEventsRAW,
              newBigCalEventsRaw
            ),
            fetchedBookings: true,
          },
          () => {
            this.applyRoomColors()
            this.handleToggleTab(this.state.activeCalendarTab)
            this.refreshFilters()
          }
        )
      })
  }

  applyRoomColors = () => {
    if (this.state.fetchedRooms && this.state.fetchedBookings) {
      const filteredEvents = this.state.bigCalEvents.map(
        (bigCalEvent, index) => {
          if (!bigCalEvent) {
            return bigCalEvent
          }
          // eslint-disable-next-line no-param-reassign
          bigCalEvent.color = this.getColor({ event: bigCalEvent })
          return bigCalEvent
        }
      )
      this.setState({
        bigCalEvents: filteredEvents,
      })
    }
  }

  togglePrimary = () => {
    const newState = !this.state.isPrimaryOpen

    this.setState({
      isPrimaryOpen: newState,
    })
  }

  refreshFilters = () => {
    const filteredEvents = this.getFilteredEvents(
      this.state.rooms,
      this.state.activeCalendarTab
    )
    this.setState({
      bigCalEvents: filteredEvents,
    })
  }

  handleFilterChange = () => {
    const {
      calendarStartDate,
      calendarEndDate,
      memberToFilter,
      teamToFilter,
      guestToFilter,
    } = this.state
    this.setState(
      {
        bigCalEvents: [],
        bigCalEventsRAW: [],
        earliestDate: null,
        latestDate: null,
      },
      () => {
        this.updateDataApi(
          {
            campusId: this.props.activeCampus.id,
            fromDate: calendarStartDate,
            toDate: calendarEndDate,
            memberToFilter,
            teamToFilter,
            guestToFilter,
            rooms: this.state.rooms,
            activeTab: this.state.activeCalendarTab,
          },
          true
        )
      }
    )
  }

  handleMemberFilterSelected = member => {
    this.setState(
      {
        memberToFilter: member,
      },
      () => {
        this.handleFilterChange()
      }
    )
  }
  handleTeamFilterSelected = team => {
    this.setState(
      {
        teamToFilter: team,
      },
      () => {
        this.handleFilterChange()
      }
    )
  }
  handleGuestFilterSelected = guest => {
    this.setState(
      {
        guestToFilter: guest,
      },
      () => {
        this.handleFilterChange()
      }
    )
  }

  handleToggleTab = tab => {
    if (this.state.activeCalendarTab !== tab) {
      const filteredEvents = this.getFilteredEvents(this.state.rooms, tab)
      this.setState(
        {
          activeCalendarTab: tab,
          bigCalEvents: filteredEvents,
        },
        () => {
          this.handleFilterChange()
        }
      )
    }
  }
  handleEventClicked = event => {
    // MODAL
    this.setState({
      event: event,
    })

    this.togglePrimary()
  }

  handleCalendarDrag = slotInfo => {
    // NOT MODAL
    if (this.state.viewState === 'month' || slotInfo.action === 'click') {
      // do nothing
    } else {
      this.setState(
        {
          slotInfo: slotInfo,
        },
        () => {
          this.handleNewBooking(false)
        }
      )
    }
  }

  handleDrillDown = (date, viewState) => {
    if (viewState !== this.state.viewState) {
      this.setState({ viewState })
    }
  }
  handleRangeChange = (objOrArray, view) => {
    const {
      viewState,
      earliestDate,
      latestDate,
      memberToFilter,
      teamToFilter,
      rooms,
    } = this.state
    const { activeCampus } = this.props
    let viewType = null // month, week, day, agenda
    let incomingFirstDay = null // the first day of the incoming batch of dates
    let incomingLastDay = null // the last day of the incoming batch of dates.
    let newCalStart = null
    let newCalEnd = null
    let dateIsBeyondLocalState = false
    let newEarly = false
    let newLate = false

    viewType = view ? view : viewState

    // eslint-disable-next-line default-case
    switch (viewType) {
      case 'month':
        incomingFirstDay = moment(objOrArray.start)
          .startOf('week')
          .toISOString()
        incomingLastDay = moment(objOrArray.end).endOf('week').toISOString()
        break
      case 'week':
        incomingFirstDay = moment(objOrArray[0]).startOf('week').toISOString()
        incomingLastDay = moment(objOrArray[6]).endOf('week').toISOString()
        break
      case 'day':
        incomingFirstDay = moment(objOrArray[0]).startOf('day').toISOString()
        incomingLastDay = moment(objOrArray[0]).endOf('day').toISOString()
        break
      case 'agenda':
        incomingFirstDay = moment(objOrArray.start).toISOString()
        incomingLastDay = moment(objOrArray.end).toISOString()
        break
    }
    newCalStart = incomingFirstDay
    newCalEnd = incomingLastDay

    //checks if incoming date is earlier than recorded earliest date
    if (earliestDate) {
      if (incomingFirstDay < earliestDate) {
        dateIsBeyondLocalState = true
        newEarly = true
      } else {
        incomingFirstDay = earliestDate
      }
    } else {
      dateIsBeyondLocalState = true
      newEarly = true
    }

    //checks if incoming date is later than recorded latest date
    if (latestDate) {
      if (incomingLastDay > latestDate) {
        dateIsBeyondLocalState = true
        newLate = true
      } else {
        incomingLastDay = latestDate
      }
    } else {
      dateIsBeyondLocalState = true
      newLate = true
    }

    //only update state is any of the params have changed
    if (dateIsBeyondLocalState) {
      //both new dates
      if (newEarly && newLate) {
        this.updateDataApi({
          campusId: activeCampus.id,
          fromDate: incomingFirstDay,
          toDate: incomingLastDay,
          memberToFilter,
          teamToFilter,
          rooms: rooms,
          activeTab: this.state.activeCalendarTab,
        })
        //new early date only
      } else if (newEarly && !newLate) {
        this.updateDataApi({
          campusId: activeCampus.id,
          fromDate: incomingFirstDay,
          toDate: earliestDate,
          memberToFilter,
          teamToFilter,
          rooms: rooms,
          activeTab: this.state.activeCalendarTab,
        })
        //new later date only
      } else if (!newEarly && newLate) {
        this.updateDataApi({
          campusId: activeCampus.id,
          fromDate: latestDate,
          toDate: incomingLastDay,
          memberToFilter,
          teamToFilter,
          rooms: rooms,
          activeTab: this.state.activeCalendarTab,
        })
      }
    } else {
      this.updateDataLocal()
    }

    this.setState({
      viewState: viewType,
      earliestDate: incomingFirstDay,
      latestDate: incomingLastDay,
      calendarStartDate: newCalStart,
      calendarEndDate: newCalEnd,
    })
  }
  handleCheckboxChange = event => {
    this.setState(
      {
        [event.target.name]: !this.state[event.target.name],
      },
      () => {
        // this.refreshFilters()
        this.handleFilterChange()
      }
    )
  }
  handleSignout = () => {
    this.props.google_actions
      .logoutGoogleWithOAuth(this.state.googleApi)
      .then(() => {
        this.setState({ isAuthorizedWithGoogle: false })
        toast.success('Disconnected from Google Calendar.')
      })
      .catch(err => {
        console.log(err)
        //todo toast?
      })
  }

  handleAuthorize = () => {
    this.props.google_actions
      .loginGoogleWithOAuth(this.state.googleApi)
      .then(() => {
        this.setState(
          { isAuthorizedWithGoogle: true },
          this.props.google_actions.getCalendars(this.state.googleApi, {})
        )
        toast.success('Connected to Google Calendar.')
      })
      .catch(err => {
        console.log(err)
        //todo toast?
      })
  }
  handleNewBooking = isEvent => {
    let type = 'Booking'
    if (isEvent) {
      type = 'Event'
    }
    this.props.history.push('/calendar/add-event', {
      campusId: this.props.activeCampus.id,
      intendedType: type,
      slotInfo: this.state.slotInfo,
    })
  }
  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }
  toggle = () => {
    this.setState({ showFilters: !this.state.showFilters })
  }
  selectAllRoomFilters(shouldShow, rooms) {
    const newRooms = rooms.map((newRoom, index) => {
      // eslint-disable-next-line no-param-reassign
      newRoom.filterActive = shouldShow
      return newRoom
    })
    this.setState(
      {
        rooms: newRooms,
      },
      () => {
        // this.refreshFilters()
        this.handleFilterChange()
      }
    )
  }
  // TODO: handle roomfilter change and refetch new calendar events on change
  handleRoomFilterChange(rooms, room) {
    if (rooms && room) {
      const newRooms = rooms.map((stateRoomObj, index) => {
        if (room.id === stateRoomObj.id) {
          // eslint-disable-next-line no-param-reassign
          stateRoomObj.filterActive = !stateRoomObj.filterActive
        }
        return stateRoomObj
      })
      this.setState(
        {
          rooms: newRooms,
        },
        () => {
          // this.refreshFilters()
          this.handleFilterChange()
        }
      )
    }
  }
  isRoomFilterActive(rooms, room) {
    if (!rooms || !room) {
      return true
    }
    for (let counter = 0; counter < rooms.length; counter++) {
      const rom = rooms[counter]
      if (rom.id === room.id) {
        return rom.filterActive
      }
    }
    return true
  }
  // This is the function to determine whether or not to
  //    show a bookings based on active filters
  shouldRemoveBooking = (coworksBooking, rooms, activeCalendarTab) => {
    // console.log(`----- ${coworksBooking.name} ------- `)
    const { showEvents, showBookings } = this.state
    if (!coworksBooking) {
      return false
    }
    // Check top level tabs first (Events & BOokings | Equipment)
    if (
      activeCalendarTab === EVENTS_AND_BOOKINGS_KEY &&
      coworksBooking.room &&
      coworksBooking.room.type === EQUIPMENT_KEY
    ) {
      return false
    } else if (
      activeCalendarTab === EQUIPMENT_KEY &&
      ((coworksBooking.room && coworksBooking.room.type !== EQUIPMENT_KEY) ||
        // don't want to show outside of my campus events/bookings
        // because those aren't associated with Equipment
        !coworksBooking.room)
    ) {
      return false
    }

    // move on to check sub filters like Booking | Event buttons
    if (activeCalendarTab === EVENTS_AND_BOOKINGS_KEY) {
      if (coworksBooking.type === 'Event' && showEvents === false) {
        // don't want to show type "event"
        // console.log('not showing events check')
        return false // hide this booking
      }
      // don't want to show type "Booking"
      if (
        (!coworksBooking.type || coworksBooking.type === 'Booking') &&
        showBookings === false
      ) {
        // console.log('not showing bookings check')
        return false
      }
      if (coworksBooking.location) {
        // We do want to show bookings without a room, but have a location!!
      }
    }
    return true
  }
  getFilteredEvents(rooms, activeCalendarTab) {
    return this.state.bigCalEventsRAW.filter(myEvent =>
      this.shouldRemoveBooking(myEvent, rooms, activeCalendarTab)
    )
  }

  getColor = ({ event, room }) => {
    if (room) return room.color
    if (!event && !room) return this.state.bookingColor
    if (event && !event.room) {
      return event.type === 'Event'
        ? this.state.eventColor
        : this.state.bookingColor
    }
    return event.room.color
  }
  renderCalendar = disableCalendar => {
    const style = disableCalendar
      ? { opacity: '0.5', pointerEvents: 'none' }
      : {}
    return (
      <BigCal
        goToDate={this.state.goToDate}
        events={this.state.bigCalEvents}
        onView={this.onView}
        handleEventClicked={this.handleEventClicked}
        handleCalendarDrag={this.handleCalendarDrag}
        handleRangeChange={this.handleRangeChange}
        viewState={this.state.viewState}
        calStyling={style}
        eventColor={this.state.eventColor}
        bookingColor={this.state.bookingColor}
      />
    )
  }
  renderModal = () => {
    if (this.state.isPrimaryOpen) {
      return (
        <EventModalComponent
          user={this.props.user}
          role={this.props.role}
          history={this.props.history}
          isPrimaryOpen={this.state.isPrimaryOpen}
          isFetching={this.state.fetchingEvent}
          togglePrimary={this.togglePrimary}
          event={this.state.event}
        />
      )
    }
    return null
  }
  renderRoomFilters = () => {
    const {
      activeCalendarTab,
      equipmentName,
      showBookings,
      showEvents,
      showExternal,
      showMemberSource,
      showAdmin,
      showWalkup,
      showMember,
      showGuest,
      bookingColor,
      eventColor,
      showFilters,
      rooms,
    } = this.state
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-block">
              <div className="row d-flex align-items-center justity-content-center">
                <div className="col-md-1 col-8">
                  <h5>Filters</h5>
                </div>
                <div className="col-md-4 col-4">
                  <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={this.toggle}
                  >
                    {this.state.showFilters ? '- hide ' : '+ show '}
                  </button>
                </div>
              </div>
              <Collapse isOpen={this.state.showFilters}>
                {!rooms || rooms.length === 0 ? (
                  <Spinner />
                ) : (
                  <React.Fragment>
                    <div className="row d-flex ml-2">
                      {activeCalendarTab !== EQUIPMENT_KEY && (
                        <React.Fragment>
                          <div className="col-4 p-0">
                            <div className="col-12 mb-2">
                              <span className="text-muted mr-2">By Type</span>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <label className="custom-control custom-checkbox custom-control-spacing">
                                <input
                                  type="checkbox"
                                  name={`showEvents`}
                                  className="custom-control-input"
                                  checked={showEvents}
                                  onChange={this.handleCheckboxChange}
                                />
                                <span
                                  className="custom-control-indicator"
                                  style={{
                                    backgroundColor: eventColor,
                                  }}
                                />
                                Events
                              </label>
                              <label className="custom-control custom-checkbox custom-control-spacing">
                                <input
                                  type="checkbox"
                                  name={`showBookings`}
                                  className="custom-control-input"
                                  checked={showBookings}
                                  onChange={this.handleCheckboxChange}
                                />
                                <span
                                  className="custom-control-indicator"
                                  style={{
                                    backgroundColor: eventColor,
                                  }}
                                />
                                Bookings
                              </label>
                            </div>
                          </div>
                          <div className="col-4 p-0">
                            <div className="col-12 mb-2">
                              <span className="text-muted mr-2">By Source</span>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <label className="custom-control custom-checkbox custom-control-spacing">
                                <input
                                  type="checkbox"
                                  name={`showAdmin`}
                                  className="custom-control-input"
                                  checked={showAdmin}
                                  onChange={this.handleCheckboxChange}
                                />
                                <span
                                  className="custom-control-indicator"
                                  style={{
                                    backgroundColor: eventColor,
                                  }}
                                />
                                Admin Dashboard
                              </label>

                              <label className="custom-control custom-checkbox custom-control-spacing">
                                <input
                                  type="checkbox"
                                  name={`showMemberSource`}
                                  className="custom-control-input"
                                  checked={showMemberSource}
                                  onChange={this.handleCheckboxChange}
                                />
                                <span
                                  className="custom-control-indicator"
                                  style={{
                                    backgroundColor: eventColor,
                                  }}
                                />
                                Member App
                              </label>

                              <label className="custom-control custom-checkbox custom-control-spacing">
                                <input
                                  type="checkbox"
                                  name={`showWalkup`}
                                  className="custom-control-input"
                                  checked={showWalkup}
                                  onChange={this.handleCheckboxChange}
                                />
                                <span
                                  className="custom-control-indicator"
                                  style={{
                                    backgroundColor: eventColor,
                                  }}
                                />
                                Tablet
                              </label>
                              {this.props.activeCommunity.stripe_account_id && (
                                <label className="custom-control custom-checkbox custom-control-spacing">
                                  <input
                                    type="checkbox"
                                    name={`showExternal`}
                                    className="custom-control-input"
                                    checked={showExternal}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  <span
                                    className="custom-control-indicator"
                                    style={{
                                      backgroundColor: eventColor,
                                    }}
                                  />
                                  External Booking
                                  {moment(moment.unix(1609539970)).isAfter(
                                    moment()
                                  ) ? (
                                    <p>
                                      <BadgeStyled size="small">
                                        New
                                      </BadgeStyled>
                                    </p>
                                  ) : null}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="col-4 p-0">
                            <div className="col-12 mb-2">
                              <span className="text-muted mr-2">By Owner</span>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <label className="custom-control custom-checkbox custom-control-spacing">
                                <input
                                  type="checkbox"
                                  name={`showMember`}
                                  className="custom-control-input"
                                  checked={showMember}
                                  onChange={this.handleCheckboxChange}
                                />
                                <span
                                  className="custom-control-indicator"
                                  style={{
                                    backgroundColor: eventColor,
                                  }}
                                />
                                Member
                              </label>
                              <label className="custom-control custom-checkbox custom-control-spacing">
                                <input
                                  type="checkbox"
                                  name={`showGuest`}
                                  className="custom-control-input"
                                  checked={showGuest}
                                  onChange={this.handleCheckboxChange}
                                />
                                <span
                                  className="custom-control-indicator"
                                  style={{
                                    backgroundColor: eventColor,
                                  }}
                                />
                                Guest
                              </label>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      <div className="col-12 col-md-3 my-2 d-flex justify-content-start align-items-center">
                        <span className="text-muted">
                          {activeCalendarTab === EVENTS_AND_BOOKINGS_KEY
                            ? 'By Room Name'
                            : `By ${equipmentName} Name`}
                        </span>
                      </div>
                      <TextButton
                        className="p-0 m-0 mr-3"
                        onClick={() => this.selectAllRoomFilters(true, rooms)}
                      >
                        Select All
                      </TextButton>
                      <TextButton
                        className="m-0 p-0"
                        onClick={() => this.selectAllRoomFilters(false, rooms)}
                      >
                        None
                      </TextButton>
                    </div>
                    <div className="row ml-2 d-flex align-items-center">
                      {rooms.map((rom, index) => {
                        if (
                          (activeCalendarTab === EVENTS_AND_BOOKINGS_KEY &&
                            rom.type === EQUIPMENT_KEY) ||
                          (activeCalendarTab === EQUIPMENT_KEY &&
                            rom.type !== EQUIPMENT_KEY)
                        ) {
                          return null
                        }
                        const color = this.getColor({ room: rom })
                        const isChecked = this.isRoomFilterActive(rooms, rom)
                        return (
                          <div
                            className="col-12 col-md-6 col-xl-4"
                            key={rom.id}
                          >
                            <label className="custom-control custom-checkbox custom-control-spacing">
                              <input
                                type="checkbox"
                                name={`filter${rom.id}`}
                                className="custom-control-input"
                                checked={isChecked}
                                onChange={() =>
                                  this.handleRoomFilterChange(rooms, rom)
                                }
                              />
                              <span
                                className="custom-control-indicator"
                                style={{
                                  backgroundColor: isChecked
                                    ? `${color}`
                                    : '#ccc',
                                }}
                              />
                              <Badge
                                className="d-flex align-items-center"
                                badgeShape="pill"
                                size="small"
                                backgroundColor={isChecked ? color : '#ccc'}
                              >
                                {rom.name}
                              </Badge>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                    <Row>
                      <div className="col-6 my-2" style={{ zIndex: 16 }}>
                        <span className="text-muted mr-2 mb-1">By Member</span>
                        <QuickSearch
                          type="Member"
                          showTitle={false}
                          member={this.state.memberToFilter}
                          members={this.props.members}
                          handleSearchItemCleared={
                            this.handleMemberFilterSelected
                          }
                          handleSearchItemSelected={
                            this.handleMemberFilterSelected
                          }
                        />
                      </div>
                      <div className="col-6 my-2" style={{ zIndex: 16 }}>
                        <span className="text-muted mr-2 mb-1">By Team</span>
                        <QuickSearch
                          type="Team"
                          showTitle={false}
                          team={this.state.teamToFilter}
                          teams={this.props.teams}
                          handleSearchItemCleared={
                            this.handleTeamFilterSelected
                          }
                          handleSearchItemSelected={
                            this.handleTeamFilterSelected
                          }
                        />
                      </div>
                      <div className="col-6 my-2" style={{ zIndex: 15 }}>
                        <span className="text-muted mr-2 mb-1">By Guest</span>
                        <QuickSearch
                          type="Lead"
                          showTitle={false}
                          team={this.state.guestToFilter}
                          teams={this.props.leads}
                          placeholder={
                            "Start typing a guest's name or email..."
                          }
                          otherParams={{
                            community_id: this.props.activeCommunity.id,
                          }}
                          handleSearchItemCleared={
                            this.handleGuestFilterSelected
                          }
                          handleSearchItemSelected={
                            this.handleGuestFilterSelected
                          }
                        />
                      </div>
                    </Row>
                  </React.Fragment>
                )}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /* eslint-disable max-len */
  render() {
    let canEdit = false
    if (this.props.role !== 'Member') {
      canEdit = true
    }
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="row d-flex justify-content-between">
                  <div className="col-3">
                    <h2>Calendar</h2>
                  </div>
                  {canEdit ? (
                    <div className="col-4 d-flex align-items-center justify-content-end">
                      <ButtonDropdown
                        id="new-action"
                        isOpen={this.state.dropdownOpen}
                        toggle={() => this.toggleDropdown()}
                      >
                        {/*<Button className='btn btn-primary' >New</ButtonStyled>*/}
                        <DropdownToggle className="btn btn-primary" caret>
                          New
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            id="new-booking"
                            onClick={() => this.handleNewBooking(false)}
                          >
                            <FontAwesomeIcon icon="calendar-alt" /> Booking
                          </DropdownItem>
                          <DropdownItem
                            id="new-event"
                            onClick={() => this.handleNewBooking(true)}
                          >
                            <FontAwesomeIcon icon="calendar-alt" /> Event
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="my-1 btn btn-primary btn-sm"
                      onClick={() => this.handleNewBooking(false)}
                    >
                      New Booking
                    </button>
                  )}
                </div>
              </div>
              <div className="card-block">
                <div className="row">
                  <div className="col-12 col-lg-8 mb-2 d-flex align-items-center ">
                    {this.state.equipmentEnabled &&
                      TogglesComponent({
                        arrayOfTabs: [
                          {
                            label: EVENTS_AND_BOOKINGS_KEY,
                          },
                          {
                            label: this.state.equipmentName,
                            internalValue: EQUIPMENT_KEY,
                          },
                        ],
                        handleToggleTab: this.handleToggleTab,
                        activeTab: this.state.activeCalendarTab,
                      })}
                  </div>
                  <div className="col-12 col-lg-4 d-flex align-items-center justify-content-end">
                    {this.state.isAuthorizedWithGoogle ? (
                      <Button
                        className="btn btn-link"
                        onClick={this.handleSignout}
                      >
                        <FontAwesomeIcon icon={['fab', 'google']} /> Connected
                        to GCal
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-outline-primary"
                        onClick={this.handleAuthorize}
                      >
                        <FontAwesomeIcon icon={['fab', 'google']} /> Integrate
                        with GCal
                      </Button>
                    )}
                  </div>
                </div>
                {this.renderRoomFilters()}
                {this.state.activeCalendarTab !== EQUIPMENT_KEY && (
                  <div className="col-12 my-3 d-flex justify-content-end">
                    {(this.state.showEvents || this.state.showBookings) && (
                      <span>
                        {' '}
                        Showing{' '}
                        {this.state.showEvents && (
                          <span className={'text-primary'}>
                            <FontAwesomeIcon icon="beer" /> Events
                          </span>
                        )}
                        {this.state.showEvents &&
                          this.state.showBookings &&
                          ' and '}
                        {this.state.showBookings && (
                          <span className="text-primary">
                            <FontAwesomeIcon icon="calendar-alt" /> Bookings
                          </span>
                        )}
                      </span>
                    )}
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-12">
                    {this.props.isFetching && (
                      <div
                        className="col-lg-12"
                        style={{ position: 'absolute', marginTop: '35vh' }}
                      >
                        <Spinner />
                      </div>
                    )}
                    {this.renderCalendar(this.props.isFetching)}
                  </div>
                </div>
                {this.renderModal()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
Calendar.displayName = 'Calendar'

function mapStateToProps(state) {
  return {
    rooms: roomSelectors.getRoomsList(state),
    members: memberSelectors.getMemberList(state),
    bookings: state.bookings.bookings,
    teams: state.teams.teams,
    leads: state.leads.leads,
    isFetching: state.ui.isFetching,
    isFetchingRooms: resolveFetchingStatus(state, FETCHING_ROOMS),
    isFetchingMembers: resolveFetchingStatus(state, FETCHING_MEMBERS),
    isFetchingTeams: resolveFetchingStatus(state, FETCHING_TEAMS),
    isFetchingLeads: resolveFetchingStatus(state, FETCHING_LEADS),
    googleCalendars: state.gcal ? state.gcal.calendars : null,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
    booking_actions: bindActionCreators(bookingActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    lead_actions: bindActionCreators(leadActions, dispatch),
    google_actions: bindActionCreators(googleCalendarActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
