import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import conferencePlaceholder from '@app/img/placeholders/conference_room.jpg'
import Badge from '@global/Base/Badge/Badge'

const Image = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;

  @media (max-width: 1024px) {
    width: 40px;
    height: 40px;
  }
`

const Title = styled.h6`
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`

const Body = styled.span`
  color: #0000004d;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`

export default function RoomSearchRow({ room, equipmentName = 'Equipment' }) {
  return (
    <div className="row">
      <div className="col-2">
        <Image
          className="img-responsive center-block img-avatar"
          src={
            room.room_photo
              ? room.room_photo.small_file_url
              : conferencePlaceholder
          }
        />
      </div>
      <div className="col-8">
        <div className="row d-flex justify-space-between">
          <div className="col-8">
            <Title>{room.name}</Title>
          </div>
          <div className="col-4">
            <Badge>
              {room.type === 'Equipment' ? equipmentName : room.type}
            </Badge>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Body> Holds {room.capacity}</Body>
          </div>
        </div>
      </div>
    </div>
  )
}
RoomSearchRow.displayName = 'RoomSearchRow'
RoomSearchRow.propTypes = {
  room: PropTypes.object.isRequired,
  equipmentName: PropTypes.string,
}
