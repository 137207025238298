/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'

const ArchiveMessage = ({
  team,
  member,
  type,
  isMemberAdmin,
  isInactive,
  teamMemberComp,
  renderSubscriptions,
  hasReplacementAdmins,
  shouldRenderSubscriptions,
  companyButton,
  dropdownComp,
  ...other
}) => {
  const memberPrompt = () => {
    const isTeamActive = team.status === 'active'
    return (
      <div className="col-12">
        {adminPrompt()} {confirmationPrompt()} {accessPrompt()}{' '}
        {activateTeamWarningPrompt(
          (isTeamActive && !isInactive) || (!isTeamActive && isInactive)
        )}{' '}
        {!isInactive && replacementPrompt()} {promotionPrompt(isTeamActive)}{' '}
        {subscriptionsPrompt(isTeamActive)} {hintPrompt(isTeamActive)}{' '}
      </div>
    )
  }

  const adminPrompt = () =>
    isMemberAdmin ? (
      <span>
        <strong>{member.first_name}</strong> {` is the admin of `}{' '}
        <strong>{team.name}</strong>.
      </span>
    ) : null

  const confirmationPrompt = () => (
    <span>
      {`Are you sure you want to make `}
      <strong>{member.first_name}</strong>
      {` ${isInactive ? 'active' : 'inactive'}?`}
    </span>
  )

  const promotionPrompt = teamIsActive =>
    !isMemberAdmin && !teamIsActive ? (
      <span>
        {`${member.first_name} will also become the `} <b>admin</b>{' '}
        {` of this team`}
      </span>
    ) : null

  const replacementPrompt = () =>
    isMemberAdmin && hasReplacementAdmins ? (
      <div>
        <br />
        {`Please choose another member from this team as the `}
        <strong>new admin</strong>:{dropdownComp()}
      </div>
    ) : null

  const activateTeamWarningPrompt = matchingStatus =>
    isMemberAdmin && !hasReplacementAdmins && matchingStatus ? (
      <span>
        {`${
          isInactive
            ? ''
            : 'This is also the only active member for this team. '
        }Making this member ${isInactive ? 'active' : 'inactive'} will make `}
        <b>{team.name}</b>
        {` ${isInactive ? 'active' : 'inactive'} , too!`}
      </span>
    ) : null

  const accessPrompt = () => (
    <span>
      {`This member will `}{' '}
      <b> {`${isInactive ? 'gain' : 'lose'} access to the mobile apps.`} </b>
    </span>
  )

  const hintPrompt = () => (
    <p className="text-muted">
      <br />
      <br />
      {`Note: If you would like to change the status of the members on this team, go to the `}
      {companyButton()}
    </p>
  )

  const teamPrompt = () => (
    <div className="col-12">
      {`You are about to ${isInactive ? 'activate' : 'deactivate'} this team.
      ${
        isInactive
          ? 'Which members should also be activated'
          : 'All active members under this team will also be deactivated.'
      }`}
      {isInactive ? teamMemberComp() : null}
    </div>
  )

  const subscriptionsPrompt = () =>
    shouldRenderSubscriptions ? renderSubscriptions() : null

  return type === 'Member' ? memberPrompt() : teamPrompt()
}

ArchiveMessage.propTypes = {
  team: PropTypes.object,
  member: PropTypes.object,
  teamMemberComp: PropTypes.func,
  dropdownComp: PropTypes.func,
  renderSubscriptions: PropTypes.func,
  companyButton: PropTypes.func,
  type: PropTypes.string,
  isMemberAdmin: PropTypes.bool,
  shouldRenderSubscriptions: PropTypes.bool,
  hasReplacementAdmins: PropTypes.bool,
  isInactive: PropTypes.bool,
}

ArchiveMessage.displayname = 'Archive Message'

export default ArchiveMessage
