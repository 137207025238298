import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import RoundedImage from '@global/RoundedImage'

import './memberRow.scss'

class AnnouncementsMemberRow extends Component {
  static propTypes = {
    member: PropTypes.object,
    admins: PropTypes.object,
    addRecipients: PropTypes.func,
    added: PropTypes.bool,
    isModal: PropTypes.bool,
  }
  constructor(props) {
    super(props)
    const member = this.props.member
    const admins = this.props.admins
    this.state = {
      selected: false,
      isAdmin:
        (member.admin_teams && member.admin_teams.length > 0) ||
        (admins && admins.filter(admin => admin.id === member.id).length > 0) ||
        member.isAdmin,
    }
  }

  render() {
    const { member, addRecipients, added } = this.props
    return (
      <div className="message-member-wrapper border-bottom">
        <div className="member-info-container">
          <RoundedImage
            photo={member.user_photo}
            alt="Picture"
            size="medium"
            className="mr-3"
          />
          <div className="member-info">
            <p className="member-name">
              {member.first_name} {member.last_name}
            </p>
            <p className="member-email">{member.email}</p>
            {this.state.isAdmin ? (
              <p className="member-email text-muted">Team Admin</p>
            ) : null}
          </div>
        </div>
        {!this.props.isModal ? (
          <div>
            {added ? (
              <FontAwesomeIcon
                icon={'check'}
                className="member-plus-icon primary-color"
              />
            ) : (
              <FontAwesomeIcon
                icon={'plus'}
                className="member-plus-icon primary-color"
                onClick={() => addRecipients(member, 'member')}
              />
            )}
          </div>
        ) : null}
      </div>
    )
  }
}

AnnouncementsMemberRow.displayName = 'Member Row'

export default AnnouncementsMemberRow
