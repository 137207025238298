import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'

export function setNetworkMembers(members, meta = null) {
  return { type: actionTypes.SET_NETWORK_MEMBERS, members, meta }
}

export const getNetworkMembers = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getNetworkUsers(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const search = (campusId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getNetworkUsers(params)
      .then(response => {
        dispatch(setNetworkMembers(response.members, response.meta))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
