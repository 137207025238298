import React from 'react'
import PropTypes from 'prop-types'

import { SingleDatePicker as RD_SingleDatePicker } from 'react-dates'

//
// http://airbnb.io/react-dates/?path=/story/singledatepicker-sdp--default
export default function SingleDatePicker({
  id,
  name,
  className = 'w-100 form-control',
  date,
  numberOfMonths = 1, // the number of months visible
  onDateChange,
  focused,
  onFocusChange,
  showClearDate = false,
  reopenPickerOnClearDate = true,
  showDefaultInputIcon = true,
  small = true,
  placeholder = 'Date',
  readOnly = true,
  disabled = false,
  isOutsideRange,
}) {
  return (
    <RD_SingleDatePicker
      id={id}
      name={name}
      className={className}
      date={date}
      numberOfMonths={numberOfMonths}
      onDateChange={onDateChange}
      focused={focused}
      onFocusChange={onFocusChange} // ({ focused }) => setDatePickerFocused(focused)}
      showClearDate={showClearDate} // true / false
      reopenPickerOnClearDate={reopenPickerOnClearDate}
      showDefaultInputIcon={showDefaultInputIcon}
      small={small}
      placeholder={placeholder}
      readOnly={readOnly}
      isOutsideRange={isOutsideRange}
      disabled={disabled}
    />
  )
}
SingleDatePicker.displayName = 'Single Date Picker Default'

SingleDatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.object,
  placeholder: PropTypes.string,
  numberOfMonths: PropTypes.number,
  focused: PropTypes.bool,
  showClearDate: PropTypes.bool,
  reopenPickerOnClearDate: PropTypes.bool,
  showDefaultInputIcon: PropTypes.bool,
  small: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onDateChange: PropTypes.func,
  onFocusChange: PropTypes.func,
  isOutsideRange: PropTypes.func,
}
