import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { FETCHING_DASHBOARD_STATS } from '@global/Constants/FetchingConstants'

// ACTION CREATORS
export function setDashboardStats(stats) {
  return { type: actionTypes.SET_DASHBOARD_STATS, stats }
}

// ACTION DISPATCHERS
export function getDashboardStats(params) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_DASHBOARD_STATS ))
    return apiService
      .getDashboardStats(params)
      .then(response => {
        dispatch(setDashboardStats(response))
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_DASHBOARD_STATS ))
      })
      .catch(err => {
        console.log(err)
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_DASHBOARD_STATS ))
      })
  }
}
