/* eslint-disable no-invalid-this */
import React from 'react'
import ManagerSignup from './ManagerSignup'
import CreateCommunity from './CreateCommunity'
import EndOfSignup from './EndOfSignup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../../reduxActions/userActions'
import * as communityActions from '../../reduxActions/communityActions'
import * as campusActions from '../../reduxActions/campusActions'
import * as stripeActions from '../../reduxActions/stripeActions'
import * as uiActions from '../../reduxActions/uiActions'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { convertStripeErrorToMessage } from '@global/ErrorFactory'
import { Line } from 'rc-progress'
import Spinner from '@global/Spinner'
import styled from 'styled-components'

const OnboardingWrapper = styled.div`
  overflow-x: hidden;
  height: 100vh;
  width: 100vh;
  background: rgb(41, 64, 79);
  background: linear-gradient(
    343deg,
    rgba(41, 64, 79, 1) 0%,
    rgba(38, 151, 150, 1) 50%,
    rgba(205, 217, 229, 1) 100%
  );
`
export class OnboardingContainer extends React.Component {
  static propTypes = {
    user_actions: PropTypes.object,
    community_actions: PropTypes.object,
    campus_actions: PropTypes.object,
    stripe_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    history: PropTypes.object,
    community: PropTypes.object,
    isFetching: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      communityName: '',
      campusName: '',
      tosStatus: 'no',
      numberOfCampuses: 'single',
      billingEnabled: 'true',
      existingStripeAccount: null,
      country: 'US',
      currency: 'USD',
      errors: [],
      step: 0,
      formErrors: {
        Email: '',
        Password: '',
        FirstName: '',
        LastName: '',
        TOS: '',
      },
      emailValid: false,
      emailIsUnique: false,
      hasHitServer: false,
      firstNameValid: false,
      lastNameValid: false,
      passwordValid: false,
      tosValid: true, // false -- removed this from form
      formValid: false,
    }
  }

  goToNext = event => {
    event.preventDefault()

    if (this.state.step === 0) {
      // create community step
      this.setState(prevState => ({
        step: prevState.step + 1,
      }))
    } else if (this.state.step === 1) {
      // create community step
      this.handleSignUp()
    } else if (this.state.step === 2) {
      // end of sign up
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        communityName: '',
        campusName: '',
        numberOfCampuses: 'single',
        errors: [],
        step: 0,
      })
    }
  }

  goToDashboard = () => {
    this.props.user_actions
      .signIn({
        email: this.state.email,
        password: this.state.password,
      })
      .then(response => {
        this.props.user_actions.setUser(response.user)
        this.props.ui_actions.setActiveCampus(
          response.user.communities[0].campuses[0]
        )
        this.props.ui_actions.setActiveCommunity(response.user.communities[0])
        this.props.history.push('/dashboard')
      })
      .catch(err => {
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  handleSignUp = () => {
    const campusName =
      this.state.numberOfCampuses === 'single'
        ? this.state.communityName
        : this.state.campusName

    this.props.community_actions
      .register({
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        community_name: this.state.communityName,
        campus_name: campusName,
        team_name: `${campusName} Staff`,
        // This inidicates whether the space intends on having billing connected
        // If true, it will attempt to create a stripe account with the provided email address
        billing_enabled: this.state.billingEnabled,
        has_existing_stripe_account: this.state.existingStripeAccount,
        type: 'Admin',
        currency: this.state.currency,
        country: this.state.country,
      })
      .then(() =>
        this.setState(prevState => ({
          step: prevState.step + 1,
        }))
      )
      .catch(err => {
        if (err && err.data && err.data.stripe_error_object) {
          const stripeError = err.data.stripe_error_object
          if (stripeError.code === 'account_already_exists') {
            toast.error(
              'There is a Stripe Account that already has this email address. Please contact Coworks Support.'
            )
          } else {
            toast.error(err.data.stripe_error)
          }
        } else if (err && err.reason) {
          toast.error(err.reason)
        } else {
          console.log('Error in signing up', err)
        }
        this.props.ui_actions.updateFetchingStatus(false)
      })
  }

  goToLogin = () => this.props.history.push('/login')
  /*
      Start of form validation -- don't forget state variables and component in render()
  */
  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value
    if (name === 'email' && this.validateEmail(value)) {
      this.checkEmailExists(value)
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }
  checkEmailExists(email) {
    this.props.user_actions
      .checkEmailExists(email, null)
      .then(response => {
        if (response.emailExists.email_exists) {
          this.setState({
            emailIsUnique: false,
            hasHitServer: true,
          })
          toast.error(`${email} already exists`)
        } else {
          this.setState({
            emailIsUnique: true,
            hasHitServer: true,
          })
        }
        this.validateField('email', email)
      })
      .catch(err => {
        if (err) {
          toast.error('There was a problem with the server.')
        }
      })
  }
  validateEmail(email) {
    /* eslint-disable max-len */
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }
  validateField = (fieldName, value) => {
    const fieldValidationErrors = this.state.formErrors
    let emailValid = this.state.emailValid
    let passwordValid = this.state.passwordValid
    let lastNameValid = this.state.lastNameValid
    let firstNameValid = this.state.firstNameValid
    //let tosValid = this.state.tosValid;

    switch (fieldName) {
      case 'email':
        const validRegexEmail = this.validateEmail(value)
        if (validRegexEmail) {
          emailValid = validRegexEmail && this.state.emailIsUnique
        } else {
          emailValid = validRegexEmail
        }
        fieldValidationErrors.email =
          this.state.emailIsUnique && this.state.hasHitServer
            ? ''
            : 'Email already exists'
        if (!validRegexEmail) {
          fieldValidationErrors.email = ' is an invalid format.'
        }
        break
      case 'firstName':
        firstNameValid = value.length >= 1
        fieldValidationErrors.firstName = firstNameValid
          ? ''
          : 'First name is required.'
        break
      case 'lastName':
        lastNameValid = value.length >= 1
        fieldValidationErrors.lastName = lastNameValid
          ? ''
          : 'Last Nname is required.'
        break
      case 'password':
        passwordValid = value.length >= 6
        fieldValidationErrors.Password = passwordValid
          ? ''
          : 'Your password is too short.'
        break
      default:
        break
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        lastNameValid: lastNameValid,
        firstNameValid: firstNameValid,
        //tosValid,
      },
      this.validateForm
    )
  }
  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.firstNameValid &&
        this.state.lastNameValid /*&& this.state.tosValid*/,
    })
  }
  /*
    End of form validation
  */

  renderErrorMessages() {
    return this.state.errors.map(error => {
      return (
        <p key={error} className="my-2 text-center">
          <span className="badge badge-danger p-3 badge-pill">{error}</span>
        </p>
      )
    })
  }

  renderForm() {
    switch (this.state.step) {
      case 0:
        return (
          <ManagerSignup
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            password={this.state.password}
            handleInputChange={this.handleInputChange}
            handleTOSChecked={this.handleInputChange}
            tosStatus={this.state.tosStatus}
            goToLogin={this.goToLogin}
            goToNext={this.goToNext}
            formErrors={this.state.formErrors}
            formValid={this.state.formValid}
            emailValid={this.state.emailValid}
            firstNameValid={this.state.firstNameValid}
            lastNameValid={this.state.lastNameValid}
            passwordValid={this.state.passwordValid}
          />
        )
      case 1:
        return (
          <CreateCommunity
            firstName={this.state.firstName}
            communityName={this.state.communityName}
            campusName={this.state.campusName}
            currency={this.state.currency}
            country={this.state.country}
            billingEnabled={this.state.billingEnabled}
            existingStripeAccount={this.state.existingStripeAccount}
            numberOfCampuses={this.state.numberOfCampuses}
            handleInputChange={this.handleInputChange}
            goToNext={this.goToNext}
            formErrors={this.state.formErrors}
            formValid={this.state.formValid}
          />
        )
      case 2:
        return (
          <EndOfSignup
            firstName={this.state.firstName}
            goToDashboard={this.goToDashboard}
            formErrors={this.state.formErrors}
            formValid={this.state.formValid}
          />
        )
      default:
        this.props.history.push('/dashboard')
        return <h1>End of the line.</h1>
    }
  }

  render() {
    return (
      <OnboardingWrapper className="w-100" id="SignUp">
        <div className="row">
          <div className="col-md-10 offset-md-1 mt-5 card p-5">
            {/* <div className="m-2">
              <Line
                percent={(this.state.step / 3) * 100}
                strokeColor={'#3f3f'}
              />
            </div> */}
            {this.props.isFetching ? <Spinner /> : this.renderForm()}
          </div>
        </div>
      </OnboardingWrapper>
    )
  }
}

OnboardingContainer.displayName = 'OnboardingContainer'

const mapStateToProps = state => {
  return {
    user: state.user.current_user,
    community: state.community,
    isFetching: state.ui.isFetching,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    user_actions: bindActionCreators(userActions, dispatch),
    community_actions: bindActionCreators(communityActions, dispatch),
    campus_actions: bindActionCreators(campusActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
    stripe_Actions: bindActionCreators(stripeActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingContainer) // eslint-disable-line max-len
