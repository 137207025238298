import React from 'react'
import PropTypes from 'prop-types'

import { TextButton } from '@global/Base/Button/ButtonStyled'
import RoundedImage from '@global/RoundedImage'
import defaultAvatarPlaceholder from '@app/img/placeholders/otto_black_white.jpeg'
import styled from 'styled-components'
import * as Constants from '@global/Constants'

const RoundedImageStyled = styled(RoundedImage)``
const Label = styled.span`
  display: flex;
  font-size: ${props =>
    !props.secondary
      ? resolveTextSize(props.fontSize)
      : resolveSecondaryTextSize(props.fontSize)};
  margin-left: 8px;
  flex-flow: column nowrap;
  align-items: flex-start;
`
const Text = styled.span`
  font-size: ${props =>
    !props.secondary
      ? resolveTextSize(props.fontSize)
      : resolveSecondaryTextSize(props.fontSize)};
  margin-left: 8px;
`
const NameContainer = styled.div`
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 1rem;
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const TextButtonStyled = styled(TextButton)`
  margin: 0px;
  padding: 0px;
`
export default function AvatarNamePlate({
  object,
  objectType = 'team',
  onClick,
  size = 1,
  className,
  showHelperText = false, // right now this only turns it ON/OFF for teams, users have by default
  helperText,
}) {
  function handleClick() {
    onClick(object)
  }
  function resolveEmail() {
    if (!object) return ''
    if (helperText) return helperText
    switch (objectType) {
      case 'team':
        return object &&
          object.admins &&
          object.admins.length > 0 &&
          object.admins[0]
          ? object.admins[0].email
          : ''
      case 'user':
        return object.email
      default:
        return object.email
    }
  }
  function resolveName() {
    switch (objectType) {
      case 'team':
        return object ? (
          <React.Fragment>
            <Text fontSize={size}>{`${
              object && object.name ? object.name : 'No name'
            }`}</Text>
            {showHelperText && (
              <Text
                className="text-muted"
                secondary
                fontSize={size}
              >{`${resolveEmail()}`}</Text>
            )}
          </React.Fragment>
        ) : (
          ''
        )
      case 'user':
        return object ? (
          <React.Fragment>
            <Text
              fontSize={size}
            >{`${object.first_name} ${object.last_name}`}</Text>
            <Text
              className="text-muted"
              fontSize={size}
              secondary
            >{`${resolveEmail()}`}</Text>
          </React.Fragment>
        ) : (
          ''
        )
      case 'plan':
        return object ? (
          <React.Fragment>
            {object.name} -{' '}
            {object.currency_units
              ? Constants.getCurrencySymbolFromName(object.currency_units)
              : '$'}
            {Number(object.cost).toFixed(2)} per {object.frequency}
          </React.Fragment>
        ) : (
          ''
        )
      case 'room':
        return object.name ? object.name : ''
      default:
        return object.name ? object.name : ''
    }
  }
  function resolvePhoto() {
    switch (objectType) {
      case 'team':
        return object && object.team_photo
          ? object.team_photo.small_file_url
          : defaultAvatarPlaceholder
      case 'user':
        return object && object.user_photo
          ? object.user_photo.small_file_url
          : defaultAvatarPlaceholder
      case 'plan':
        return object && object.plan_photo
          ? object.plan_photo.small_file_url
          : defaultAvatarPlaceholder
      case 'room':
        return object && object.room_photo
          ? object.room_photo.small_file_url
          : defaultAvatarPlaceholder
      default:
        return null
    }
  }
  function wrapWithButton(component) {
    return onClick ? (
      <TextButtonStyled
        type="button"
        className="text-primary"
        onClick={handleClick}
      >
        {component}
      </TextButtonStyled>
    ) : (
      component
    )
  }
  return (
    <NameContainer className={className}>
      {wrapWithButton(
        <Wrapper>
          <RoundedImageStyled
            photo={resolvePhoto()}
            alt={`${objectType} photo`}
            size={convertToImageSize(size)}
          />
          <Label fontSize={size}> {resolveName()} </Label>
        </Wrapper>
      )}
    </NameContainer>
  )
}

function resolveTextSize(size) {
  switch (size) {
    case 1:
      return '0.875rem'
    case 2:
      return '1.25rem'
    case 3:
      return '2.00rem'
    case 4:
      return '3.00rem'
    default:
      return '1.25rem'
  }
}

function resolveSecondaryTextSize(size) {
  switch (size) {
    case 1:
      return '0.80rem'
    case 2:
      return '0.90rem'
    case 3:
      return '1.25rem'
    case 4:
      return '2.25rem'
    default:
      return '0.90rem'
  }
}

function convertToImageSize(size) {
  switch (size) {
    case 1:
      return 'small'
    case 2:
      return 'medium'
    case 3:
      return 'med-large'
    case 4:
      return 'large'
    default:
      return 'large'
  }
}

AvatarNamePlate.displayName = 'AvatarNamePlate'
AvatarNamePlate.propTypes = {
  object: PropTypes.object.isRequired,
  objectType: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  showHelperText: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
}
