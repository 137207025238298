/* eslint-disable no-invalid-this,quote-props */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import * as googleCalendarActions from '@reduxActions/googleCalendarActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'
import styled, { css } from 'styled-components'
import { readableColor, lighten } from 'polished'

import moment from 'moment'

import * as _ from 'lodash'
import { TextButton } from '@global/Base/Button/ButtonStyled'

import { SingleDatePicker } from '@global/Form/FormComponents'

import CoworksSearch from '@global/Search/CoworksSearch'
import RoomSearchRow from '@global/Search/SearchRowComponents/RoomSearchRow'

import Badge from '@global/Base/Badge/Badge'
import * as Constants from '@global/Constants'

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  width: 100%;
`
const FilterLabel = styled.label`
  margin-bottom: 0;
  ${props =>
    props.margin
      ? css`
          margin-right: 0;
        `
      : ''};
`
const FilterWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1200px) {
    order: 2;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
const SearchWrapper = styled.div`
  display: flex;
  flex: 3 0 auto;
  min-width: 260px;
  .text-primary {
    color: ${props =>
      props.color ? props.color : props.theme.colorPrimary} !important;
  }
  @media (max-width: 1200px) {
    width: 100%;
    order: 0;
    margin-bottom: 8px;
  }
`
const StretchContainer = styled.div`
  width: 100%;
`
const TodayButton = styled.div`
  display: flex;
`
const RangeWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;

  @media (max-width: 1200px) {
    order: 1;
  }

  @media (max-width: 768px) {
    width: 100%;

    .SingleDatePicker {
      width: 100%;
      > div {
        width: 100%;
        .SingleDatePickerInput {
          width: 100%;
        }
      }
    }
  }
  .DateInput_input__focused {
    border-bottom: 2px solid
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
  }

  .CalendarDay__selected {
    background: ${props =>
      props.color ? props.color : props.theme.colorPrimary};
    color: ${props => props.theme.colorWhite};
    border: 1px double
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
    &:hover {
      background: ${props =>
        lighten(0.1, props.color ? props.color : props.theme.colorPrimary)};
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
    color: ${props => props.theme.colorWhite};
  }
`

class BookingsFilter extends React.Component {
  static propTypes = {
    rooms: PropTypes.array,
    room_actions: PropTypes.func,
    activeCampus: PropTypes.object,
    room: PropTypes.object,
    dataResolver: PropTypes.object,
    handleItemSelected: PropTypes.func,
    handleSearchCleared: PropTypes.func,
    handleStartDateTimeChanged: PropTypes.func,
    handleCampusChanged: PropTypes.func,
    campuses: PropTypes.array,
    startDate: PropTypes.object,
    theme: PropTypes.object,
    showEvents: PropTypes.bool,
    showBookings: PropTypes.bool,
    handleCheckboxChange: PropTypes.function,
    selectedCampusId: PropTypes.number,
    otherRoomsParams: PropTypes.object,
    publicCommunity: PropTypes.object,
  }
  state = {}

  setDateToToday = () => {
    const time = moment()
    const momentDate = moment()
    momentDate.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    })
    this.props.handleStartDateTimeChanged(momentDate)
  }

  renderRoomSearchRow = room => (
    <RoomSearchRow room={room} equipmentName={this.state.equipmentName} />
  )

  createDateRagePickers = () => {
    const { publicCommunity } = this.props
    const brandColor =
      publicCommunity.community_preference.branding_primary_color

    return (
      <RangeWrapper color={brandColor}>
        <TodayButton>
          <TextButton
            className="m-0 mr-2"
            onClick={() => this.setDateToToday()}
            color={brandColor}
          >
            Today
          </TextButton>
        </TodayButton>
        <SingleDatePicker
          id="start_date_calendar"
          date={this.props.startDate}
          onDateChange={momentDate => {
            // ensure that we don't fuck with time that was set previously
            if (momentDate) {
              const time = this.props.startDate
              momentDate.set({
                hour: time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second'),
              })
            }
            this.props.handleStartDateTimeChanged(momentDate)
          }}
          focused={this.state.focused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          enableOutsideDays
          isOutsideRange={() => false}
          placeholder={'Start Date'}
        />
      </RangeWrapper>
    )
  }
  createRoomSearch = () => {
    const {
      rooms,
      room_actions,
      activeCampus,
      handleItemSelected,
      handleSearchCleared,
      dataResolver,
      room,
      otherRoomsParams,
      publicCommunity,
    } = this.props
    const brandColor =
      publicCommunity.community_preference.branding_primary_color

    return (
      <SearchWrapper color={brandColor}>
        <StretchContainer>
          <CoworksSearch
            isSearch={false}
            placeholder={'Search Rooms'}
            renderSingleValue={Constants.renderRoomSelected}
            defaultData={rooms}
            actions={room_actions}
            otherParams={otherRoomsParams}
            activeCampus={activeCampus}
            handleItemSelected={handleItemSelected}
            handleSearchCleared={handleSearchCleared}
            dataResolver={dataResolver}
            value={room}
            isClearable
            renderSearchRow={this.renderRoomSearchRow}
            colorRowHover={lighten(
              0.3,
              publicCommunity.community_preference.branding_primary_color
            )}
            colorRowSelected={
              publicCommunity.community_preference.branding_primary_color
            }
          />
        </StretchContainer>
      </SearchWrapper>
    )
  }
  showFilters = () => {
    const {
      showBookings,
      showEvents,
      handleCampusChanged,
      campuses,
      theme,
      selectedCampusId,
    } = this.props

    const eventColor = this.props.theme.colorPrimary
    const bookingColor = this.props.theme.colorSecondary
    return (
      <FilterWrapper>
        {campuses.length === 1 ? null : (
          <select
            className="form-control mx-4"
            onChange={handleCampusChanged}
            value={selectedCampusId}
          >
            {campuses.map((campus, index) => (
              <option value={campus.id}>{campus.name}</option>
            ))}
          </select>
        )}
        <FilterLabel className="custom-control custom-checkbox custom-control-spacing">
          <input
            type="checkbox"
            name={`showEvents`}
            className="custom-control-input"
            checked={showEvents}
            onChange={this.props.handleCheckboxChange}
          />
          <span
            className="custom-control-indicator"
            style={{
              backgroundColor: showEvents ? eventColor : '#ccc',
            }}
          />
          <Badge
            className="d-flex align-items-center"
            badgeShape="pill"
            backgroundColor={showEvents ? eventColor : '#ccc'}
          >
            Events
          </Badge>
        </FilterLabel>
        <FilterLabel
          margin
          className="custom-control custom-checkbox custom-control-spacing"
        >
          <input
            type="checkbox"
            name={`showBookings`}
            className="custom-control-input"
            checked={showBookings}
            onChange={this.props.handleCheckboxChange}
          />
          <span
            className="custom-control-indicator"
            style={{
              backgroundColor: showBookings ? bookingColor : '#ccc',
            }}
          />
          <Badge
            className="d-flex align-items-center"
            badgeShape="pill"
            backgroundColor={showBookings ? bookingColor : '#ccc'}
          >
            Bookings
          </Badge>
        </FilterLabel>
      </FilterWrapper>
    )
  }

  /* eslint-disable max-len */
  render() {
    return (
      <Header>
        {this.createDateRagePickers()}
        {this.createRoomSearch()}
        {this.showFilters()}
      </Header>
    )
  }
}

BookingsFilter.displayName = 'BookingsFilter'

function mapStateToProps(state) {
  return {
    rooms: roomSelectors.getRoomsList(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
    booking_actions: bindActionCreators(bookingActions, dispatch),
    google_actions: bindActionCreators(googleCalendarActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingsFilter)
