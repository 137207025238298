/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import RoomCard from './Card'
import RoomListCard from './ListCard'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import conferencePlaceholder from '@app/img/placeholders/rooms_placeholder.jpeg'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const ListCardWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`

const RoomListCardStyled = styled(RoomListCard)``

const CardWrapper = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 60%;
  margin-bottom: 10px;
`

const RoomCardStyled = styled(RoomCard)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const List = ({
  rooms,
  breakpoint,
  onClick,
  activeCampus,
  currencySymbol,
  brandColor,
  ...other
}) => {
  const renderCard = (room, index) => {
    const imageSrc = room.room_photo
      ? room.room_photo.file_url
      : conferencePlaceholder
    if (breakpoint === 'mobile') {
      return (
        <CardWrapper key={`card-wrapper-${index}-${room.id}`}>
          <RoomCardStyled
            room={room}
            img={imageSrc}
            currencySymbol={currencySymbol}
            onClick={() => onClick(room)}
          />
        </CardWrapper>
      )
    }
    return (
      <ListCardWrapper key={`list-card-wrapper-${index}-${room.id}`}>
        <RoomListCardStyled
          room={room}
          color={brandColor}
          currencySymbol={currencySymbol}
          img={imageSrc}
          onClick={() => onClick(room)}
        />
      </ListCardWrapper>
    )
  }

  return (
    <PaginatedTable
      wrapperClassName={null}
      containerClassName={null}
      objects={rooms}
      renderItem={renderCard}
      {...other}
    />
  )
}

List.propTypes = {
  rooms: PropTypes.array,
  breakpoint: PropTypes.string,
  brandColor: PropTypes.string,
  currencySymbol: PropTypes.string,
  onClick: PropTypes.func,
  room_actions: PropTypes.object,
  activeCampus: PropTypes.object,
}
List.displayName = 'List'

export default List
