import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'formik'

class ErrorFocus extends React.Component {
  static propTypes = {
    isValidating: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    formik: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  }
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik
    const keys = Object.keys(errors)
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(`[name="${keys[0]}"]`)
      if (errorElement) {
        errorElement.focus()
      }
    }
  }

  render() {
    return null
  }
}

ErrorFocus.displayName = 'ErrorFocus'
export default connect(ErrorFocus)
