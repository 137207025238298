import React from 'react'
import styled from 'styled-components'

const MessageTypeButton = styled.div`
  :hover {
    background-color: #e6e6e6;
    border-color: #adadad;
    cursor: pointer;
  }
`
const MessageTypeTitle = styled.h3`
  font-size: 2vw;
  color: ${props => props.theme.colorPrimary}!important;
`
const MessageTypeDescription = styled.h5`
  /* color: ${props => props.theme.colorPrimary}!important; */
  font-size: 17px;
`
export { MessageTypeButton, MessageTypeTitle, MessageTypeDescription }
