/* eslint-disable radix */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import * as planActions from '../../reduxActions/planActions'
import * as uiActions from '../../reduxActions/uiActions'

import { Button } from '@global/Base/Button/ButtonStyled'
import PillToggle from '@global/Base/Toggle/PillToggle'
import CardComponent from '@global/CardComponent'
import defaultUserPic from '../../img/placeholders/otto_black_white.jpeg'
import './Plans.scss'
import * as Constants from '@global/Constants'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Tooltip from '@bit/coworks.base-components.tooltip'
import * as planSelectors from '@reduxSelectors/planSelectors'

import Placeholder from '@global/Placeholder'
import Spinner from '@global/Spinner'
import TagRow from '@global/TagRow'
import {
  resolveFetchingStatus,
  FETCHING_PLANS,
} from '@global/Constants/FetchingConstants'

class Plans extends React.Component {
  static propTypes = {
    plan_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    plans: PropTypes.array,
    isFetchingPlans: PropTypes.bool,
    history: PropTypes.object,
  }
  constructor(props, context) {
    super(props, context)
    this.state = {
      filteredPlans: null,
      searchBar: '',
      hasReceivedPlans: false,
    }
  }
  UNSAFE_componentWillMount() {
    const { plans, isFetchingPlans } = this.props
    if (!isFetchingPlans && (!plans || (plans && plans.length === 0)))
      this.props.plan_actions.getPlans(this.props.activeCampus.id)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //todo replace with static getDerivedStateFromProps(props, state) {
    if (!this.state.hasReceivedPlans && nextProps.plans) {
      this.setState({
        hasReceivedPlans: true,
        filteredPlans: nextProps.plans,
      })
    }
  }
  editPlanClicked = plan => {
    this.props.history.push(`/plans/${plan.id}`, { plan })
  }
  filter = filter => {
    let filteredPlans = this.props.plans
    const num = parseInt(filter)
    filteredPlans = filteredPlans.filter(
      plan =>
        (plan.name
          ? plan.name.toLowerCase().includes(filter.toLowerCase())
          : false) ||
        plan.cost === (num !== 'NaN' ? num : filter) ||
        (plan.description
          ? plan.description.toLowerCase().includes(filter.toLowerCase())
          : false)
    )
    this.setState({
      filteredPlans,
    })
  }
  handleInputChange = event => {
    const value = event.target.value
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.filter(value)
      }
    )
  }
  handleNewPlanClicked = () => {
    this.props.history.push('/plans/add-plan')
  }
  renderSubHeaderComponent = plan => {
    const { activeCommunity, activeCampus } = this.props
    const baseUrl = Constants.resolveDomainPrefix(activeCommunity.preferences)
    const link = `${baseUrl}/membership-signup/${plan && plan.id}`
    return (
      <React.Fragment>
        {plan.is_public && (
          <div className="col-12">
            <Tooltip
              tooltipContent={
                'This plan is publicly visible through the signup form.'
              }
              header={`Publicly Visible`}
              buttonText={'View'}
              handleClick={() => {
                window.open(link, '_blank')
              }}
            >
              <FontAwesomeIcon icon="eye" /> Public
            </Tooltip>
          </div>
        )}
        <TagRow object={plan} shouldCenterTags />
      </React.Fragment>
    )
  }

  renderPlans() {
    let plans
    if (this.state.searchBar.length > 0) {
      plans = this.state.filteredPlans
    } else {
      plans = this.props.plans
    }
    if (!plans || (plans && plans.length === 0)) {
      return (
        <Placeholder
          title={'No Plans'}
          icon={'credit-card'}
          subtext1={`You currently have no plans in this campus`}
          subtext2={'Add one by clicking'}
          buttonText={'new plan'}
          handleClick={this.handleNewPlanClicked}
        />
      )
    }
    return (
      <div className="row">
        {plans.map((plan, i) => {
          const symbol = plan.currency_units
            ? Constants.getCurrencySymbolFromName(plan.currency_units)
            : '$'
          return CardComponent({
            key: plan.id,
            name: plan.name ? plan.name : 'No name',
            alt: plan.name ? plan.name : 'Image',
            editClickHandler: this.editPlanClicked,
            renderSubHeaderComponent: () => this.renderSubHeaderComponent(plan),
            item: plan,
            imageUrl: plan.plan_photo
              ? plan.plan_photo.file_url
              : defaultUserPic,
            detail: plan.description ? plan.description : 'No description',
            value: `${symbol}${
              plan.cost ? Number(plan.cost).toFixed(2) : 0
            } / ${plan.frequency ? plan.frequency : 'month'}`,
          })
        })}
      </div>
    )
  }

  render() {
    return (
      <div id="plans" className="animated fadeIn">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12 col-md-2">
                    <h2>Plans</h2>
                  </div>
                  <div className="col-md-7 col-12 d-flex align-items-center">
                    <div className="input-group">
                      <span className="input-group-addon">
                        <FontAwesomeIcon icon={'search'} />
                      </span>
                      <input
                        type="text"
                        name="searchBar"
                        value={this.state.searchBar}
                        disabled={Boolean(this.props.isFetchingPlans)}
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Search for a plan..."
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-12 d-flex align-items-center justify-content-end">
                    <Button color="primary" onClick={this.handleNewPlanClicked}>
                      <FontAwesomeIcon icon="credit-card" /> New Plan
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-block">
                {this.props.isFetchingPlans ? <Spinner /> : this.renderPlans()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Plans.displayName = 'Plans'

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    plans: planSelectors.getStandardPlansList(state),
    isFetchingPlans: resolveFetchingStatus(state, FETCHING_PLANS),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    plan_actions: bindActionCreators(planActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans)
