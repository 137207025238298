import * as _ from 'lodash'

// eslint-disable no-nested-ternary
export function arrayMerge(oldArray, updatedArray, compareKey = 'id') {
  const tempArray = [...oldArray]
  const newArray = [...updatedArray]

  const moddedArray = tempArray.map(oldObj => {
    for (const [index, newObj] of newArray.entries()) {
      if (oldObj[compareKey] === newObj[compareKey]) {
        newArray.splice(index, 1)
        return _.merge({}, oldObj, newObj)
      }
    }
    return oldObj
  })

  const uniqueArray = moddedArray.concat(newArray)
  return uniqueArray
}

export function sortByMemberName(arr) {
  return arr.sort((element1, element2) => {
    let num = 0
    if (element1.first_name.toLowerCase() > element2.first_name.toLowerCase()) {
      num = 1
    } else if (
      element2.first_name.toLowerCase() > element1.first_name.toLowerCase()
    ) {
      num = -1
    }
    return num
  })
}
export function sortByName(arr) {
  return arr.sort((element1, element2) => {
    if (element1.name.toLowerCase() > element2.name.toLowerCase()) {
      return 1
    } else if (element2.name.toLowerCase() > element1.name.toLowerCase()) {
      return -1
    }
    return 0
  })
}
export function sortByCreatedDate(arr) {
  return arr.sort((element1, element2) => {
    return new Date(element2.created_at) - new Date(element1.created_at)
  })
}
