import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { format, isAfter } from 'date-fns' //todo what?
import * as _ from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { theme, resolveColorValue } from '@global/Base/baseComponentColorUtils'
import * as Constants from '@global/Constants'
import HeaderIconButton from '@global/Header/HeaderIconButton'

const Container = styled.span`
  position: relative;
`

const UnreadIndicator = styled.span`
  position: absolute;
  pointer-events: none;
  bottom: 14px;
  right: 4px;
  display: ${props => (props.unread ? 'inline-flex' : 'none')};
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-family: inherit;
  font-size: ${theme('fonts.sizes.pSmall.fontSize')};
  background-color: ${theme('colors.primary')};
  color: ${theme('fonts.colors.light')};
  font-weight: ${theme('fonts.weights.bold')};
  height: 15px;
  width: 15px;
`

function NotificationBell({ unread, dropdownVisible }) {
  return (
    <Container>
      <UnreadIndicator unread={unread > 0}>{unread}</UnreadIndicator>
      <HeaderIconButton
        dropdownVisible={dropdownVisible}
        size="medium"
        color="neutral-4"
        icon={['fal', 'bell']}
      />
    </Container>
  )
}

NotificationBell.propTypes = {
  unread: PropTypes.number,
  dropdownVisible: PropTypes.bool,
}

NotificationBell.displayName = 'Notification Bell'

export default NotificationBell
