/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'formik'
import {
  CustomInputComponent,
  CustomToggleComponent,
  CustomSelectComponent,
} from '@global/Form/FormComponents'
import JsonFieldSection from '@global/Form/JsonFieldSection'
import GenericModal from '@global/GenericModal'
import * as Roles from '@app/config/roles'
import { getDaysInMonth } from '@global/Constants/TeamBillingConstants'
import Badge from '@global/Base/Badge/Badge'

const validateInvoiceFees = automaticFeesString => {
  const errors = {}
  if (
    automaticFeesString &&
    automaticFeesString !== '' &&
    automaticFeesString !== '[]'
  ) {
    const invoiceFees = JSON.parse(automaticFeesString)
    for (let index = 0; index < invoiceFees.length; index++) {
      const obj = invoiceFees[index]
      // const keys = Object.keys(obj)
      const nameKey = `name-${index}`
      const amountKey = `percentage_in_decimals-${index}`
      // validate values
      if (!obj.name || obj.name === '') {
        errors[nameKey] = 'Name is required'
      }
      if (obj.percentage_in_decimals < 0.01 || obj.percentage_in_decimals > 1) {
        errors[amountKey] =
          'Percentage must be a decimal number between 0.01 and 1'
      }
    }
  }
  return Object.keys(errors).length === 0 ? null : errors
}
function interceptSwitchChange(fieldName, fieldValue, setFieldValue) {
  setFieldValue(`show_modal_${fieldName}`, true)
}
// eslint-disable-next-line react/display-name
function renderModal({ setFieldValue, key, label, values }) {
  const showModal = `show_modal_${key}`
  return (
    <GenericModal
      className="modal-primary fade in"
      isOpen={values[showModal]}
      toggle={() => {
        setFieldValue(showModal, !values[showModal])
      }}
      header={'Are you sure?'}
      body={
        <p>
          This will automatically {`${values[key] ? 'stop passing' : 'pass'}`}{' '}
          all {label} fees to member in memberships and in one-off invoices.
          <br />
          <span className="text-muted">
            Note: This will only effect future invoices.
          </span>
        </p>
      }
      onConfirm={() => {
        setFieldValue(showModal, false)
        setFieldValue(key, !values[key])
      }}
      confirmLabel={values[key] ? 'Stop passing fees' : 'Yes, pass fees'}
    />
  )
}
// eslint-disable-next-line react/no-multi-comp
function BillingSettings({ values, setFieldValue, touched, jsonString, role }) {
  return (
    <React.Fragment>
      <h3>Member Billing</h3>
      <br />
      <Field
        label="Default Billing Cycle Date"
        name="default_billing_cycle_day"
        type="number"
        description="The default day of the month memberships are billed. Note: Changing this will not effect existing memberships"
        component={CustomSelectComponent}
      >
        {getDaysInMonth().map((monthlyBillDate, i) => (
          <option key={i} value={monthlyBillDate.value}>
            {monthlyBillDate.name}
          </option>
        ))}
      </Field>
      <Field
        label="Days Until Invoices Due"
        name="days_until_invoice_due"
        type="number"
        description='How long invoices can remain unpaid before they are considered late. This changes the "Due On" date for emailed invoices. Note: Changing this will not effect existing invoices'
        component={CustomInputComponent}
      />
      <Field
        label="Pass Credit Card fee to Member"
        name="pass_cc_fee_to_member"
        description="Pass the 2.9% + $0.30 credit card fee to members during invoice creation"
        interceptChange={interceptSwitchChange}
        component={CustomToggleComponent}
      />

      <Field
        label="Pass ACH fee to Member"
        name="pass_ach_fee_to_member"
        description="Pass the 0.8% (with a $5 max) ACH fee to members during invoice creation"
        interceptChange={interceptSwitchChange}
        component={CustomToggleComponent}
      />
      <br />
      <h3 id="#externalBookings">External Bookings</h3>
      <br />
      <Field
        label="Pass Credit Card fee to Booker"
        name="external_bookings.billing.pass_cc_fee"
        description="Pass the 2.9% + $0.30 credit card fee to members and guests for external bookings"
        component={CustomToggleComponent}
      />
      <br />
      <h3 id="#dayPasses">Day Pass</h3>
      <br />
      <Field
        label="Pass Credit Card fee to day pass purchaser"
        name="day_pass.billing.pass_cc_fee"
        description="Pass the 2.9% + $0.30 credit card fee to members and guests for external bookings"
        component={CustomToggleComponent}
      />
      {(role === Roles.superAdminRole ||
        process.env.NODE_ENV === 'development' ||
        !process.env.NODE_ENV) && (
        <div className="bg-light">
          <span>Super Admin & Localhost Only</span>
          <br />
          <br />
          <Badge badgeShape="pill" color={'warning'}>
            Incomplete
          </Badge>
          <Field
            label="Assume fee type when no default source present"
            name="billing.assume_fees_when_no_default_source"
            description="When no default source is present on a team at the time of invoice creation, should we assume a fee type?"
            component={CustomSelectComponent}
          >
            <option value="none">Don't assume fees</option>
            <option value="card">Assume credit card fees</option>
            <option value="bank_account">Assume ach fees</option>
          </Field>
          <Field
            name={'automatic_invoice_fees'}
            validate={validateInvoiceFees}
            component={JsonFieldSection}
            jsonArrayLengthMax={3}
            setFieldValue={setFieldValue}
            sectionName={'Automatic Invoice Fees'}
            createNewObject={() => ({ name: '', percentage_in_decimals: 0.0 })}
            resolveFieldName={obj => obj.name}
            resolveFieldValue={obj => obj.percentage_in_decimals}
            isEditing
          />
        </div>
      )}
      {renderModal({
        values,
        setFieldValue,
        label: 'credit card',
        key: 'pass_cc_fee_to_member',
      })}
      {renderModal({
        values,
        setFieldValue,
        label: 'bank account',
        key: 'pass_ach_fee_to_member',
      })}
      {renderModal({
        values,
        setFieldValue,
        label: 'credit card',
        key: 'external_bookings.billing.pass_cc_fees',
      })}
    </React.Fragment>
  )
}

BillingSettings.propTypes = {
  jsonString: PropTypes.string,
  role: PropTypes.string,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
}
BillingSettings.displayName = 'BillingSettings'
export default BillingSettings
