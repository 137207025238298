/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import Checkbox from '@bit/coworks.base-components.checkbox'

export default function DeleteInvoiceModal({
  showDeleteInvoiceModal,
  toggleDeleteInvoiceModal,
  isFetching,
  deleteInvoice,
  invoice,
}) {
  const [archiveEntity, setArchiveEntity] = useState(false)

  return (
    <Modal
      isOpen={showDeleteInvoiceModal}
      toggle={toggleDeleteInvoiceModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleDeleteInvoiceModal}>Close Invoice</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h6>
                Are you sure? This invoice has not yet been finalized and this
                will permanently delete the invoice. This action is{' '}
                <b>irreversible!</b> This will stop the invoice from being sent
                out.
              </h6>
              <br />
              {invoice && invoice.metadata.booking_id && (
                <div style={{ marginTop: '20px' }}>
                  <Checkbox
                    style={{ width: '100%' }}
                    label="Cancel Booking"
                    description="This invoice is tied to a booking. Checking this checkbox will cancel the booking and hide it on the calendar."
                    selected={archiveEntity}
                    handleChange={() => setArchiveEntity(prev => !prev)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleDeleteInvoiceModal}>
          Nevermind
        </Button>
        <Button
          color="danger"
          disabled={isFetching}
          onClick={() => deleteInvoice(archiveEntity)}
        >
          Cancel Invoice
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DeleteInvoiceModal.displayName = 'DeleteInvoiceModal'
