/* eslint-disable prefer-spread */
import React, { useEffect } from 'react'

// REDUX BITS
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as bookingActions from '../../../reduxActions/bookingActions'
import * as roomActions from '../../../reduxActions/roomActions'
import * as userActions from '../../../reduxActions/userActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'

// DEPENDENCIES
import PropTypes from 'prop-types'

// CHILDREN
import CardComponent from '../../Global/CardComponent'

// RESOURCES
import Office from '../../../img/placeholders/conference_edit.jpg'
import Spinner from '@global/Spinner'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { TextButton } from '@global/Base/Button/ButtonStyled'

import {
  resolveFetchingStatus,
  FETCHING_ROOMS,
} from '@global/Constants/FetchingConstants'

function BookingRoomsPage({
  rooms,
  isFetching,
  isFetchingRooms,
  room_actions,
  history,
  activeCampus,
  booking_actions,
  campusTablet,
  logout,
  user_actions,
  theme,
}) {
  useEffect(() => {
    room_actions.getRoomsAtCampus(activeCampus.id, {
      types: JSON.stringify(['Conference', 'Space', 'Equipment']),
    })
    room_actions.storeRoomToken(null)
  }, [room_actions, activeCampus])

  const handleSelectRoom = room => {
    // MAYBE add confirmation message here?
    const roomID = room.id
    const campusID = activeCampus.id
    booking_actions.getTabletKey(roomID, campusID).then(({ campus_tablet }) => {
      const tabletKey =
        campus_tablet && campus_tablet.tablet_key
          ? campus_tablet.tablet_key
          : null
      room_actions.storeRoomToken(tabletKey)
      history.replace(`/booking-tablet/${tabletKey}`)
    })
  }
  const backToTabletPicker = () => {
    history.push('/tablet-type')
  }
  const handleLogout = () => {
    user_actions.signOutUser()
    history.push('/tablet-login')
  }
  function renderRooms() {
    if (rooms && rooms.length > 0) {
      return rooms.map((room, i) => {
        return CardComponent({
          key: i,
          name: room.name ? room.name : 'No name',
          alt: room.name ? room.name : 'Image',
          editClickHandler: handleSelectRoom,
          item: room,
          path: '/rooms/room-detail',
          imageUrl: room.room_photo ? room.room_photo.file_url : Office,
          detail: room.description ? room.description : 'No description',
          value: `Capacity: ${room.capacity}`,
          actionButtonText: 'Select',
        })
      })
    }
    return null
  }

  if (isFetching || isFetchingRooms) {
    return <Spinner />
  }
  return (
    <div id="BookingRoomsPage">
      <nav className="navbar sticky-top navbar-light bg-faded">
        <TextButton
          className="m-0 p-0"
          backgroundColor={'#858585'}
          onClick={backToTabletPicker}
        >
          <FontAwesomeIcon color={theme.colorPrimary} icon="arrow-left" />{' '}
          change type
        </TextButton>
        <h2>
          Rooms{' '}
          <h6>
            <small> Select a room to link to this tablet </small>
          </h6>
        </h2>
        <Button className="btn btn-primary float-right" onClick={handleLogout}>
          Logout
        </Button>
      </nav>
      <div className="container-fluid">
        <div className="container h-100">
          <div className="row h-100 mt-3">{renderRooms()}</div>
        </div>
      </div>
    </div>
  )
}

BookingRoomsPage.displayName = 'BookingRoomsPage'
BookingRoomsPage.propTypes = {
  rooms: PropTypes.array,
  isFetching: PropTypes.bool,
  isFetchingRooms: PropTypes.bool,
  room_actions: PropTypes.object,
  history: PropTypes.object,
  activeCampus: PropTypes.object,
  booking_actions: PropTypes.object,
  campusTablet: PropTypes.object,
  logout: PropTypes.func,
  user_actions: PropTypes.object,
  theme: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    campusTablet: state.bookings.tabletKey
      ? state.bookings.tabletKey.campus_tablet
      : null,
    rooms: roomSelectors.getRoomsList(state),
    isFetching: state.ui.isFetching,
    isFetchingRooms: resolveFetchingStatus(state, FETCHING_ROOMS),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    booking_actions: bindActionCreators(bookingActions, dispatch),
    room_actions: bindActionCreators(roomActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingRoomsPage)
