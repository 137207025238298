/* eslint-disable react/display-name,react/prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'

const RESIZE_DELAY_INTERVAL = 300

const breaks = [
  {
    props: {
      breakpoint: 'web',
    },
  },
  {
    break: 768,
    props: {
      breakpoint: 'mobile',
    },
  },
]

const ResizeMonitor = () => WrappedComp => {
  const Comp = ({ ...other }) => {
    const [currentBreak, setBreakpoint] = useState(breaks[0])
    const handleResize = _.throttle(() => {
      calculateBreakpoint()
    }, RESIZE_DELAY_INTERVAL)

    const calculateBreakpoint = () => {
      let current = breaks[0]
      breaks.forEach(point => {
        const match = point.break ? point.break >= window.innerWidth : true
        if (match) current = point
      })
      setBreakpoint(current)
    }

    useEffect(() => {
      window.addEventListener('resize', handleResize, false)
      calculateBreakpoint()

      return () => window.removeEventListener('resize', handleResize, false)
    }, [currentBreak])

    return <WrappedComp {...currentBreak.props} {...other} />
  }

  Comp.propTypes = {
    breaks: PropTypes.array,
  }

  return Comp
}

export default ResizeMonitor
