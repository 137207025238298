/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import Spinner from '../Spinner'
import LabelStyled from '@global/Form/LabelStyled'
import {
  handleChange,
  handleAddRow,
  renderRow,
  handleRemoveCustomField,
} from './JsonFieldFunctions'

function handleKeyPress({
  event,
  setFieldValue,
  jsonString,
  objectKey,
  createNewObject,
}) {
  if (event.key === 'Enter') {
    const jsonArray = JSON.parse(jsonString)
    handleAddRow({ createNewObject, setFieldValue, objectKey, jsonArray })
  }
}

function JsonFieldSection({
  field: { name }, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const {
    hideLabel,
    isEditing,
    label,
    description,
    placeholder,
    type,
    sectionName,
    jsonArrayLengthMax,
    resolveFieldName,
    resolveFieldValue,
    createNewObject,
    setFieldValue,
  } = props
  const { values, errors, touched, setFieldTouched } = form
  const jsonString = values[name]
  let jsonArray
  if (!jsonString) {
    jsonArray = []
  } else {
    jsonArray = JSON.parse(jsonString)
  }

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <React.Fragment>
              <div className="row my-3">
                <div className="col-6">
                  <LabelStyled htmlFor="customFields" isEditing={isEditing}>
                    {sectionName}{' '}
                    {isEditing && (
                      <span className="text-muted">
                        {jsonArray.length}/{jsonArrayLengthMax}
                      </span>
                    )}
                  </LabelStyled>
                </div>
              </div>
              {renderRow({
                jsonString,
                resolveFieldName,
                resolveFieldValue,
                setFieldValue,
                handleAddRow,
                isEditing,
                handleKeyPress: event =>
                  handleKeyPress({
                    event,
                    setFieldValue,
                    jsonString,
                    objectKey: name,
                    createNewObject,
                  }),
                objectKey: name,
                setFieldTouched,
              })}
              {isEditing && (
                <TextButton
                  color="primary"
                  className="m-0 p-0"
                  disabled={jsonArray.length === jsonArrayLengthMax}
                  onClick={() => {
                    handleAddRow({
                      createNewObject,
                      setFieldValue,
                      objectKey: name,
                      jsonArray,
                    })
                  }}
                >
                  Add
                </TextButton>
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  )
}

JsonFieldSection.propTypes = {
  field: PropTypes.object.required,
  form: PropTypes.object.required,
  jsonString: PropTypes.string.required,
  sectionName: PropTypes.string.required,
  setFieldValue: PropTypes.func.required,
  handleChange: PropTypes.func.required,
  jsonArrayLengthMax: PropTypes.number.required,
  objectKey: PropTypes.string,
  isEditing: PropTypes.bool,
  resolveFieldName: PropTypes.func,
  resolveFieldValue: PropTypes.func,
  createNewObject: PropTypes.func,
}

JsonFieldSection.displayName = 'JsonFieldSection'

export default JsonFieldSection
