/* Anchor link */
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { resolveColor } from '../baseComponentColorUtils'

const Anchor = styled.a.attrs(props => ({
  color: props.color || 'primary',
}))`
  color: ${props => resolveColor(props)} !important;
  text-decoration: ${props =>
    props.underline ? 'underline' : 'none'} !important;
  &:hover {
    color: ${props => props.theme.colorPrimaryHover} !important;
    cursor: pointer !important;
  }
`
Anchor.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  underline: PropTypes.bool,
}
Anchor.defaultProps = {
  underline: false,
}
export default Anchor
