import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import * as apiService from '../services/apiService'
import { setSentryContext } from '../config/sentry'

export function successUpdatingUser(user) {
  setSentryContext(user)
  return { type: actionTypes.UPDATE_USER, user }
}

export function clearMembers() {
  return { type: actionTypes.CLEAR_MEMBERS }
}

export function successCheckingEmail(emailExists) {
  return { type: actionTypes.SET_CHECK_EMAIL_EXISTS, emailExists }
}

export function successSendingResetPassword(response) {
  return response
  //return { type: actionTypes.SET_CHECK_EMAIL_EXISTS, emailExists };
}

export function successRequestingResetPassword(response) {
  return { type: actionTypes.PASSWORD_RESET, response }
}
export function successGettingNotifications(
  notifications,
  notificationCount,
  lastClearNotificationsDate,
  meta
) {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    notifications,
    notificationCount,
    lastClearNotificationsDate,
    meta,
  }
}
export function successClearNotificationCount(
  notificationCount,
  lastClearNotificationsDate
) {
  return {
    type: actionTypes.CLEAR_NOTIFICATION_COUNT,
    notificationCount,
    lastClearNotificationsDate,
  }
}
export function successUpdateNotification(
  notification,
  notificationCount,
  lastClearNotificationsDate
) {
  return {
    type: actionTypes.UPDATE_NOTIFICATION,
    notification,
    notificationCount,
    lastClearNotificationsDate,
  }
}
export function successRefreshNotifications(
  notifications,
  notificationCount,
  lastClearNotificationsDate,
  meta
) {
  return {
    type: actionTypes.REFRESH_NOTIFICATIONS,
    notifications,
    notificationCount,
    lastClearNotificationsDate,
    meta,
  }
}

export function setUser(user) {
  return { type: actionTypes.SET_USER, user }
}

export function signOutUser() {
  return { type: actionTypes.SIGN_OUT }
}

export const signIn = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .signIn(params)
      .then(response => {
        resolve(response)
      })
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
      .catch(err => {
        reject(err)
      })
  })
}

export function updatePassword(passwordObject) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .updatePassword(passwordObject)
      .then(response => dispatch(successUpdatingUser(response.user)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}

export const validateToken = () => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .validateToken()
      .then(response => {
        if (response && response.user) {
          dispatch(setUser(response.user))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const updateUser = ({
  user,
  headers = null,
  fetchingName = null,
}) => dispatch => {
  // two different componments are calling this.
  // todo refactor the userprefs to pass in a fetching name so that we don't render the loading states conditionally
  if (fetchingName) dispatch(uiActions.updateFetchingStatus(true, fetchingName))
  return new Promise((resolve, reject) => {
    apiService
      .updateMember(user, headers)
      .then(response => {
        if (fetchingName)
          dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        dispatch(successUpdatingUser(response.user))
        resolve(response)
      })
      .catch(err => {
        if (fetchingName)
          dispatch(uiActions.updateFetchingStatus(false, fetchingName))
        reject(err)
      })
  })
}

export function checkEmailExists(email, campusId) {
  return dispatch => {
    //dispatch(uiActions.updateFetchingStatus(true));
    return apiService
      .checkEmailExists(email, campusId)
      .then(response => dispatch(successCheckingEmail(response)))
    //.then(() => dispatch(uiActions.updateFetchingStatus(false)));
  }
}

export function sendResetPassword(email) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService.sendResetPassword(email)
    //.then(response => dispatch(successSendingResetPassword(response)));
    //.then(() => dispatch(uiActions.updateFetchingStatus(false)));
  }
}
export const getUserPreferencesByToken = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getUserPreferencesByToken(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function resetPassword(
  password,
  passwordConfirmation,
  passwordResetToken
) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .resetPassword(password, passwordConfirmation, passwordResetToken)
      .then(response => dispatch(successRequestingResetPassword(response)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
  }
}
export const getNotifications = (
  userId,
  params,
  shouldRefresh = false
) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getNotifications(userId, params)
      .then(response => {
        dispatch(
          shouldRefresh
            ? successRefreshNotifications(
              response.notifications,
              response.notification_count,
              response.last_clear_notifications_date,
              response.meta
            )
            : successGettingNotifications(
              response.notifications,
              response.notification_count,
              response.last_clear_notifications_date,
              response.meta
            )
        )
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const markNotificationsAsSeen = (userId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .markNotificationsAsSeen(userId, params)
      .then(response => {
        dispatch(
          successGettingNotifications(
            response.notification_count,
            response.last_clear_notifications_date
          )
        )
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const clearNotificationCount = (userId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .clearNotificationCount(userId, params)
      .then(response => {
        dispatch(
          successClearNotificationCount(
            response.notification_count,
            response.last_clear_notifications_date
          )
        )
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const updateNotification = (notificationId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .updateNotification(notificationId, params)
      .then(response => {
        dispatch(
          successUpdateNotification(
            response.notification,
            response.notification_count,
            response.last_clear_notifications_date
          )
        )
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
