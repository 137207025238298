/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as memberActions from '@app/reduxActions/memberActions'
import * as teamActions from '@app/reduxActions/teamActions'
import * as uiActions from '@app/reduxActions/uiActions'
import * as userActions from '@app/reduxActions/userActions'
import * as checkinActions from '@app/reduxActions/checkinActions'
import * as Constants from '@global/Constants'
import * as memberSelectors from '@reduxSelectors/memberSelectors'
import { CHECKIN_VISITOR } from '@global/Constants/CheckinsConstants'
import styled from 'styled-components'
import TopBar from './TopBar'
import Table, { Column } from '@global/Table'
import {
  DateCol,
  StatusCol,
  ActionCol,
  MemberCol,
  CheckOutCol,
  CampusCol,
} from './LogColumns'
import CheckInModal from './CheckInModal'
import { presets } from '@global/Constants/DateConstants'
import {
  resolveFetchingStatus,
  FETCHING_CHECKINS,
} from '@global/Constants/FetchingConstants'
import { toast } from 'react-toastify'
import { resolveCheckins } from '@global/Utilities/ResolveConstants'

import moment from 'moment'

const ListWrapper = styled.div`
  padding-left: 0;
  padding-right: 0;
`
const Wrapper = styled.div`
  display: flex;
  height: 100%;
`

const rowStyle = {
  borderBottom: '1px #f2f2f2 solid',
  height: '75px',
}

const headerStyle = {
  borderBottom: '2px solid #cfd8dc',
  borderTop: '1px solid #cfd8dc',
  height: '48px',
}

const dateToday = {
  startDate: moment().startOf('day'),
  endDate: moment().endOf('day'),
}
const defaultDatePreset = 0
const defaultSort = { slug: 'time_in', asc: false }
const SLUG_ENUM = {
  MEMBER: 'first_name',
  CAMPUS: 'last_name',
  STATUS: 'read',
  TIME_IN: 'time_in',
  TIME_OUT: 'time_out',
}

function Log({
  checkins,
  checkin_actions,
  isFetching,
  members,
  member,
  type,
  activeCampus,
  activeCommunity,
  onReportClick,
  onCheckInMember,
  onCheckOutMember,
  showCampusPicker,
  unique,
  onMemberClick,
  toggleGrid,
  gridOpen,
  disableCheckin,
  user,
  ...other
}) {
  const [previewOpen, setModal] = useState(false)
  const [activeCheckIn, setActiveCheckIn] = useState(
    member ? Constants.getActiveCheckIn(member.checkins) : null
  )
  const [selectedCheckIn, setCheckIn] = useState(null)
  const [infiniteScroll, setInfiniteScroll] = useState(true)
  const [page, setPage] = useState(Constants.START_PAGE_DEFAULT)
  const [perPage, setPerPage] = useState(Constants.PER_PAGE_DEFAULT)
  const [nextTriggered, setTrigger] = useState(false)
  const [dateRange, setDateRange] = useState(dateToday)
  const [sort, setSort] = useState(defaultSort)
  const [refCount, setCount] = useState(0)
  const prevPageRef = useRef()
  const prevMemberId = useRef()
  const defaultPic =
    activeCommunity && activeCommunity.community_preference
      ? activeCommunity.community_preference.user_placeholder
      : null
  const { data, pagination } = checkins

  const refresh = () => {
    setCount(refCount + 1)
    setPage(Constants.START_PAGE_DEFAULT)
  }
  const sortHeader = (slug, asc) => {
    const sort = { slug, asc }
    setSort(sort)
    checkScroll(sort)
    refresh()
  }
  const updateDateRange = range => {
    setDateRange(range)
    refresh()
  }

  const selectCheckIn = data => {
    setModal(true)
    setCheckIn(data)
  }
  const deleteCheckIn = checkinId => {
    setModal(false)
    setCheckIn(null)
    checkin_actions.deleteCheckIn(checkinId, type).then(() => {
      toast.success(`Deleted Check-in`)
    })
  }
  const editCheckIn = (data, checkinId) => {
    setModal(false)
    const oldCheckIn = { ...selectedCheckIn }
    setCheckIn(null)
    checkin_actions.updateCheckIn(data, checkinId, type).then(response => {
      if (!oldCheckIn.time_out && response.checkin.time_out) {
        if (onCheckOutMember) onCheckOutMember(response)
        setActiveCheckIn(null)
      }
      toast.success(`Updated Check-in`)
    })
  }
  const checkInAction = () => {
    checkin_actions
      .createCheckIn({ user_id: member.id }, type)
      .then(response => {
        if (onCheckInMember) onCheckInMember(response)
        setActiveCheckIn(response.checkin)
        toast.success(`Successfully Checked In`)
      })
  }
  const checkOutAction = id => {
    checkin_actions.checkOutMember(id, type).then(response => {
      if (onCheckOutMember) onCheckOutMember(response)
      setActiveCheckIn(null)
      toast.success(`Successfully Checked Out`)
    })
  }
  const checkScroll = sort => {
    switch (sort.slug) {
      case SLUG_ENUM.TIME_IN:
        setInfiniteScroll(!sort.asc)
        break
      case SLUG_ENUM.TIME_OUT:
        setInfiniteScroll(!sort.asc)
        break
      default:
        setInfiniteScroll(true)
        break
    }
  }

  const customFormat = time => {
    return moment(time).calendar(null, {
      sameDay: '[Today at] hh:mm a',
      nextDay: 'MMM DD, YY hh:mm a',
      nextWeek: 'MMM DD, YY hh:mm a',
      lastDay: '[Yesterday at] hh:mm a',
      lastWeek: 'MMM DD, YY hh:mm a',
      sameElse: 'MMM DD, YY hh:mm a',
    })
  }

  const getInfo = (data, type) => {
    let first,
      last,
      photo,
      email = null
    if (type === CHECKIN_VISITOR) {
      first = data && data.contact ? data.contact.first_name : null
      last = data && data.contact ? data.contact.last_name : null
      email = data && data.contact ? data.contact.email : null
      photo = defaultPic
    } else {
      first = data && data.user ? data.user.first_name : null
      last = data && data.user ? data.user.last_name : null
      email = data && data.user ? data.user.email : null
      photo =
        data && data.user && data.user.user_photo
          ? data.user.user_photo
          : defaultPic
    }
    return { first, last, email, photo }
  }

  const columns = (unique = true) => {
    return [
      {
        name: '',
        style: {
          width: '40px',
        },
        options: {
          display: false,
          sortable: false,
        },
      },
      {
        name: type.match(/(Visitor)/) ? 'Visitor' : 'Member',
        slug: SLUG_ENUM.MEMBER,
        style: {
          paddingRight: '10px',
        },
        options: {
          display: !unique,
          customRender: data => {
            const { first, last, email, photo } = getInfo(data, type)
            return (
              <MemberCol
                first={first}
                last={last}
                email={email}
                // eslint-disable-next-line react/prop-types
                hideImage={type.match(/(Visitor)/)}
                // eslint-disable-next-line react/prop-types
                disabled={type.match(/(Network|Visitor)/)}
                photo={photo}
                // eslint-disable-next-line react/prop-types
                onClick={() => (data ? onMemberClick(data.user) : null)}
              />
            )
          },
        },
      },
      {
        name: 'Community',
        slug: SLUG_ENUM.CAMPUS,
        options: {
          display: type.match(/(Network)/),
          customRender: data => (
            <CampusCol
              campus={
                // eslint-disable-next-line react/prop-types
                data && data.from_community && data.from_community.name
                  ? // eslint-disable-next-line react/prop-types
                    data.from_community.name
                  : null
              }
            />
          ),
        },
      },
      {
        name: 'Status',
        slug: SLUG_ENUM.STATUS,
        style: {
          width: '120px',
          textAlign: 'center',
        },
        options: {
          display: false,
          // eslint-disable-next-line react/prop-types
          customRender: data => <StatusCol data={data.read} />,
        },
      },
      {
        name: 'Time In',
        slug: SLUG_ENUM.TIME_IN,
        options: {
          sort: 'desc',
          // eslint-disable-next-line react/prop-types
          customRender: data => <Column text={customFormat(data.time_in)} />,
        },
      },
      {
        name: 'Time Out',
        slug: SLUG_ENUM.TIME_OUT,
        options: {
          customRender: data => (
            <CheckOutCol
              // eslint-disable-next-line react/prop-types
              text={data.time_out ? customFormat(data.time_out) : null}
              // eslint-disable-next-line react/prop-types
              onClick={() => checkOutAction(data.id)}
            />
          ),
        },
      },
      {
        name: '',
        style: {
          width: '140px',
          textAlign: 'center',
        },
        options: {
          sortable: false,
          customRender: data => (
            <ActionCol onClick={() => selectCheckIn(data)} />
          ),
        },
      },
    ]
  }

  const getOrderString = () => {
    const str = `${sort.slug}.${sort.asc ? 'ASC' : 'DESC'}`
    return JSON.stringify([str])
  }

  useEffect(() => {
    if (!isFetching) {
      const prevPage = prevPageRef.current ? prevPageRef.current : 0
      const prevMemId = prevMemberId.current
      const propMemId = member ? member.id : null
      if (page > prevPage && prevMemId === propMemId) {
        setTrigger(false)
        loadNextObjects(page, perPage, true)
      } else {
        loadNextObjects(Constants.START_PAGE_DEFAULT, perPage)
      }
      prevPageRef.current = page
      prevMemberId.current = propMemId
    }
    // eslint-disable-next-line
  }, [dateRange, member, sort, page, type, refCount])

  const nextPage = () => {
    if (isFetching || nextTriggered) return

    if (
      (pagination &&
        data &&
        data.length < pagination.total_objects &&
        page < pagination.total_pages) ||
      !data
    ) {
      const newPage = page + 1
      setTrigger(true)
      setPage(newPage)
    }
  }

  const getEnds = () => {
    let from_date = dateRange.endDate
    let to_date = null
    if (!infiniteScroll) {
      from_date = dateRange.startDate
      to_date = moment().endOf('day')
    }
    return {
      from_date,
      to_date,
    }
  }
  const getIds = () => {
    let user_id,
      contact_id = null
    if (type === CHECKIN_VISITOR) {
      contact_id = member ? member.id : null
    } else {
      user_id = member ? member.id : null
    }
    return { user_id, contact_id }
  }

  const loadNextObjects = (page, per_page, nextPage) => {
    const paramID = getIds()
    const paramRange = getEnds()
    checkin_actions.getCheckIns(
      {
        campus_id: activeCampus.id,
        community_id: activeCommunity.id,
        order_by: sort ? getOrderString() : null,
        page,
        per_page,
        type: type,
        ...paramRange,
        ...paramID,
      },
      nextPage,
      type
    )
  }

  return (
    <React.Fragment>
      <Wrapper className="card card-block" {...other}>
        <TopBar
          handleDateRangeChanged={updateDateRange}
          presets={presets}
          defaultPreset={defaultDatePreset}
          isCheckedIn={activeCheckIn}
          onCheckInClicked={checkInAction}
          onCheckOutClicked={() => checkOutAction(activeCheckIn.id)}
          onRefreshClicked={refresh}
          canCheckIn={unique}
          isLoading={isFetching}
          disableCheckin={disableCheckin}
        />
        <ListWrapper className="card-block">
          <Table
            columns={columns(unique)}
            headerStyle={headerStyle}
            rowStyle={rowStyle}
            objects={data}
            type={'Check-ins'}
            icon={'map-marker-check'}
            nextPage={nextPage}
            onSort={sortHeader}
            isLoading={isFetching}
            renderSingleValue={Constants.renderMemberSelected}
            totalPages={pagination && pagination.total_pages}
            placeholderTitle={'No Check-ins'}
            placeholderSubtext1={
              'You currently have no check-ins that match this criteria'
            }
          />
        </ListWrapper>
      </Wrapper>
      <CheckInModal
        className="modal-primary fade in"
        isOpen={previewOpen}
        data={selectedCheckIn ? selectedCheckIn : undefined}
        photo={getInfo(selectedCheckIn, type).photo}
        firstName={getInfo(selectedCheckIn, type).first}
        lastName={getInfo(selectedCheckIn, type).last}
        email={getInfo(selectedCheckIn, type).email}
        showCampusPicker={showCampusPicker}
        campuses={activeCommunity.campuses}
        onClose={() => setModal(false)}
        onDelete={deleteCheckIn}
        onEdit={editCheckIn}
      />
    </React.Fragment>
  )
}

Log.propTypes = {
  data: PropTypes.array,
  nextPage: PropTypes.func,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  member: PropTypes.object,
  type: PropTypes.array,
  dateRange: PropTypes.object,
  isFetching: PropTypes.bool,
  gridOpen: PropTypes.bool,
  unique: PropTypes.bool,
  showCampusPicker: PropTypes.bool,
  members: PropTypes.array,
  onReportClick: PropTypes.func,
  toggleGrid: PropTypes.func,
  onMemberClick: PropTypes.func,
  onCheckInMember: PropTypes.func,
  onCheckOutMember: PropTypes.func,
  user: PropTypes.object,
  defaultPreset: PropTypes.number,
  pagination: PropTypes.object,
  history: PropTypes.object,
  checkin_actions: PropTypes.object,
  member_actions: PropTypes.object,
  match: PropTypes.object,
  checkins: PropTypes.object,
  disableCheckin: PropTypes.bool,
}

Log.defaultProps = {
  type: null,
  unique: false,
  member: null,
}

Log.displayName = 'Log'

function mapStateToProps(state, props) {
  return {
    user: state.user,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    members: memberSelectors.getMemberList(state),
    isFetching: resolveFetchingStatus(
      state,
      checkinActions.getFetchByType(props.type)
    ),
    checkins: resolveCheckins(state.checkins, props.type),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkin_actions: bindActionCreators(checkinActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Log)
