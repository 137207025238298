/* eslint-disable react/display-name, react/no-multi-comp */
import React from 'react'
import Button, {
  IconButton,
  OutlineButton,
} from '@bit/coworks.base-components.button'
import Checkbox from '@bit/coworks.base-components.checkbox'
import { breakpoints } from '@global/Constants/BreakpointConstants'
import {
  CustomInputComponent,
  CustomTextAreaComponent,
} from '@global/Form/FormComponentsV2'
import Spinner from '@global/Spinner'

import CreditCardForm from '@global/Stripe/CreditCardFormV2'
import { Formik, ErrorMessage, Form } from 'formik'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import TotalTextRows from '../TotalTextRows'
import {
  ElementsConsumer,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js'
import * as _ from 'lodash'
import { bindActionCreators, compose } from 'redux'
import CheckoutDetails from './CheckoutDetails'
import TermsOfServiceDescription from '../../../Signup/TermsOfServiceDescription'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-flow: ${props => (props.mobile ? 'column' : 'row')} nowrap;
  position: relative;

  .mobile-ft {
    display: none;
  }
  ${props => {
    if (props.mobile) {
      return css`
        .mobile-ft {
          display: block;
        }
      `
    }
    return null
  }}
`
const FormContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 12px 24px;
`
const IconButtonStyled = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
`
const OutlineButtonStyled = styled(OutlineButton)``
const ButtonStyled = styled(Button)`
  position: relative;

  .loading-spinner-contextual {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    opacity: 0.5;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  padding: 12px 24px;
  flex-flow: row nowrap;
  justify-content: flex-end;
`
const Title = styled.h5`
  display: flex;
  padding: 12px 24px 0;
  margin: 0;
  font-weight: bold;
`
const Subtitle = styled.h5`
  display: flex;
  font-size: 18px;
`
const InputContainer = styled.div`
  flex: 1;
`
const CostContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  background: #eee;
  min-width: 40%;
`
const BookingDetails = styled.div`
  flex: 2;
  padding: 12px 24px;
`
const Summary = styled.div`
  flex: 1;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 12px 24px;
`
const TotalTextRowsStyled = styled(TotalTextRows)``
const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
`
const OverlayHeader = styled.h5`
  font-weight: bold;
`
const TextAreaStyled = styled.textarea`
  height: 150px;
`

const CheckoutForm = ({
  className,
  mobile,
  brandColor,
  community,
  campus,
  onGoBackAction,
  // loading,
  showCheckbox = true,
  detailData,
  totalData,
  subtitle,
  onCancel,
  sendFormToApi,
  setLoading,
  // setValid,
  initialValues,
  validationSchema,
  billingEnabled,
  formRef,
  submitError,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const createCCToken = (values, formActions) => {
    if (billingEnabled) {
      const cardElement = elements.getElement(CardElement)
      stripe
        .createToken(cardElement)
        .then(({ token, error }) => {
          if (token) {
            // eslint-disable-next-line no-param-reassign
            values.card_token = token.id
            sendFormToApi(values, formActions)
          }
          if (error) {
            formActions.setFieldError(
              'creditCard',
              error.message
                ? error.message
                : 'There was a problem with your card.'
            )
            formActions.setSubmitting(false)
          }
        })
        .catch(err => {
          formActions.setFieldError(
            'creditCard',
            'There was a problem with your card.'
          )
          formActions.setSubmitting(false)
        })
    } else {
      sendFormToApi(values)
    }
  }

  const cancel = () => {
    if (onCancel) onCancel()
  }

  const detailRows = list =>
    list.map(item => (
      <div>
        <b>{`${item.title}:`}</b>
        {item.text}
      </div>
    ))

  const renderTermsOfServiceDescription = () => {
    return (
      <TermsOfServiceDescription
        campusName={campus && campus.name}
        communityTerms={
          community.preferences &&
          community.preferences.legal &&
          community.preferences.legal.terms_of_service_url
        }
      />
    )
  }

  const goBackButton = mobile => (
    <OutlineButtonStyled
      color={'neutral-4'}
      className={`btn btn-outline-primary ${
        mobile ? 'mobile-ft w-50 mr-2' : ''
      }`}
      onClick={onGoBackAction}
    >
      Go Back
    </OutlineButtonStyled>
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { props, setSubmitting, resetForm, setFieldError }
      ) => {
        setSubmitting(true)
        createCCToken(values, { setFieldError, setSubmitting, resetForm })
      }}
      enableReinitialize
    >
      {({
        isSubmitting,
        setFieldValue,
        setFieldError,
        resetForm,
        values,
        errors,
      }) => (
        <Form>
          <Container mobile={mobile} className={className}>
            <IconButtonStyled
              className="web-ft"
              color="#000"
              size="small"
              icon={['fal', 'times']}
              type="button"
              onClick={() => cancel(resetForm)}
            />
            <InputContainer>
              <Title className="web-ft">Guest Information</Title>
              <FormContainer>
                <CustomInputComponent
                  label="First Name"
                  name="first_name"
                  id="first_name"
                  placeholder="John"
                />
                <CustomInputComponent
                  label="Last Name"
                  name="last_name"
                  id="last_name"
                  placeholder="Doe"
                />
                <CustomInputComponent
                  label="Email"
                  name="email"
                  id="email"
                  type="email"
                  placeholder="jdoe@email.com"
                />
                <CustomInputComponent
                  label="Phone Number"
                  name="phone"
                  id="phone"
                  placeholder="(555)-555-5555"
                />
                <CustomInputComponent
                  label="Company"
                  name="company_name"
                  id="company_name"
                  placeholder="Doe's Company"
                />
                <CreditCardForm
                  stripe={stripe}
                  elements={elements}
                  onChange={() => setFieldError('creditCard', null)}
                  label="Credit Card"
                  name="creditCard"
                  id="creditCard"
                />
                <ErrorMessage name="creditCard">
                  {msg => <div className="text-danger">{msg}</div>}
                </ErrorMessage>
                <CustomTextAreaComponent
                  label="Extra Information (optional)"
                  name="description"
                  id="description"
                  placeholder="I need space for catering for this reservation."
                />
                {showCheckbox && (
                  <Checkbox
                    color={brandColor}
                    selected={values.policy}
                    handleChange={() => setFieldValue('policy', !values.policy)}
                    label="I Agree"
                    name="policy"
                    error={errors.policy}
                    description={renderTermsOfServiceDescription()}
                  />
                )}
                {submitError && (
                  <div className="text-danger">{submitError}</div>
                )}
              </FormContainer>
              <ButtonContainer className="web-ft">
                {goBackButton(false)}
                <OutlineButtonStyled
                  color={brandColor}
                  className="ml-3"
                  onClick={cancel}
                >
                  Cancel
                </OutlineButtonStyled>
              </ButtonContainer>
            </InputContainer>
            <CostContainer>
              <Title style={{ marginBottom: '12px' }}>Booking Details</Title>
              <CheckoutDetails
                subtitle="Cost Summary"
                detailData={detailData}
                totalData={totalData}
              />
              <ButtonContainer>
                {goBackButton(true)}
                <ButtonStyled
                  type="submit"
                  color={brandColor}
                  className={`w-${mobile ? '50' : '100'}`}
                  disabled={isSubmitting}
                >
                  {isSubmitting && <Spinner size="sm" color="#fff" />}
                  Complete Booking
                </ButtonStyled>
              </ButtonContainer>
            </CostContainer>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

CheckoutForm.displayName = 'CheckoutForm'

CheckoutForm.propTypes = {
  className: PropTypes.string,
  mobile: PropTypes.bool,
  brandColor: PropTypes.string,
  stripe: PropTypes.object,
  elements: PropTypes.array,
  community: PropTypes.object,
  campus: PropTypes.object,
  formRef: PropTypes.object,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  onGoBackAction: PropTypes.func,
  // setValid: PropTypes.func,
  loading: PropTypes.bool,
  checkValid: PropTypes.func,
  createToken: PropTypes.func,
  setLoading: PropTypes.func,
  // resetForm: PropTypes.func,
  billingEnabled: PropTypes.bool,
  isValid: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  detailData: PropTypes.array,
  totalData: PropTypes.array,
  subtitle: PropTypes.string,

  // onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  // values: PropTypes.object,
  // errors: PropTypes.object,
  // setFieldError: PropTypes.func,
  // setSubmitting: PropTypes.func,
  // validateForm: PropTypes.func,
  // setFieldValue: PropTypes.func,
  submitError: PropTypes.string,
  submitForm: PropTypes.func,
  sendFormToApi: PropTypes.func,
}

export default CheckoutForm
