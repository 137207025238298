import React, { useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import Column from './Column'

const TableRow = styled.tr`
  width: 100%;
`
const Col = styled.td`
`

function Row({
  data,
  index,
  columns,
  ...other
}) {

  const renderColumns = () => {
    return columns.map(col => {
      const { style, accessor, sub, options } = col
      if (options) {
        const { display, customRender } = options;
        if (display !== undefined && !display) return null
        if (customRender) return (
          <Col style={style}>
            {customRender(data,index)}
          </Col>
        )
      }

      return (
        <Col style={style}>
          <Column
            text={data[accessor]}
            subtext={data[sub]}
          />
        </Col>
      )
    })
  }

  return (
    <TableRow {...other}> 
      {renderColumns()}
    </TableRow>
  )
}

Row.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  index: PropTypes.number
}

Row.displayName = 'Row'

export default Row
