export const PENDING_MEMBER = 'PendingMember'
export const PROSPECT = 'Prospect'
export const LEAD = 'Lead'

export const LeadsTabInfo = [
  {
    slug: 'leads',
    title: 'Leads',
  },
  {
    slug: 'prospects',
    title: 'Prospects',
  },
]
