import React, { useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Field, Form, Formik } from 'formik'
import { Button } from 'reactstrap'
import {
  CustomInputComponent,
  CustomTextAreaComponent,
  CustomToggleComponent,
  CustomSelectComponent,
  CustomImageUploader,
} from '@global/Form/FormComponents'
import { frequencies } from '@global/Constants/PaymentConstants'
const FormStyled = styled(Form)`
  display: flex;
  flex-flow: column nowrap;
`
const Wrapper = styled.div`
  display: flex;
  .form-group {
    flex: 1;
  }
`
const Col = styled.div`
  flex: ${props => (props.size ? props.size : 1)};
  margin: 4px;
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  > :not(:last-child) {
    margin-right: 0.25rem;
  }
  > :not(:first-child) {
    margin-left: 0.25rem;
  }
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
  > :not(:last-child) {
    margin-right: 0.25rem;
  }
  > :not(:first-child) {
    margin-left: 0.25rem;
  }
`

// a temporary function while billing is not 100%
function isPlanFrequencyDisabled(freq) {
  switch (freq) {
    case 'day':
      return true
    case 'week':
      return true
    case 'month':
      return false
    case 'year':
      return true
    default:
      return true
  }
}

const _PlanFormModal = ({
  initialValues = {},
  schema = {},
  onSubmit,
  toggle,
  loading,
}) => {
  const { fields } = schema
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, handleSubmit }) => (
        <FormStyled onSubmit={handleSubmit}>
          <Wrapper>
            {fields.photo && (
              <Col size={2}>
                <Field
                  label="Photo"
                  name="photo"
                  component={CustomImageUploader}
                />
              </Col>
            )}
            <Col size={4}>
              {fields.name && (
                <Field
                  label="Name"
                  isEditing
                  name="name"
                  component={CustomInputComponent}
                />
              )}
              <Row>
                {fields.price && (
                  <Field
                    label="Price"
                    isEditing
                    type="number"
                    name="price"
                    component={CustomInputComponent}
                  />
                )}
                {fields.frequency && (
                  <Field
                    name="frequency"
                    label="Frequency"
                    component={CustomSelectComponent}
                  >
                    {frequencies.map((frequency, i) => (
                      <option
                        key={i}
                        disabled={isPlanFrequencyDisabled(frequency.name)}
                        value={frequency.name}
                      >
                        {frequency.adverb}
                      </option>
                    ))}
                  </Field>
                )}
              </Row>
              {fields.date_required && (
                <Field
                  name="date_required"
                  label="Date Required"
                  labelRight={
                    values.date_required
                      ? 'Require a date'
                      : 'Do not require a date'
                  }
                  component={CustomToggleComponent}
                />
              )}
              {fields.description && (
                <Field
                  label="Description"
                  isEditing
                  name="description"
                  style={{ minHeight: '150px' }}
                  component={CustomTextAreaComponent}
                />
              )}
            </Col>
          </Wrapper>
          <Footer>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={loading}>
              Save
            </Button>
          </Footer>
        </FormStyled>
      )}
    </Formik>
  )
}

_PlanFormModal.displayName = '_PlanFormModal'

_PlanFormModal.propTypes = {
  initialValues: PropTypes.object,
  schema: PropTypes.object,
  onSubmit: PropTypes.func,
  toggle: PropTypes.func,
  loading: PropTypes.bool,
}

export default _PlanFormModal
