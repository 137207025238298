import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Badge from '@global/Base/Badge/Badge'

const Container = styled.div`
  text-align: center;
`

function StatusCol({
  data
}) {

  return (
    <Container>
      <Badge color={data ? 'primary' : 'secondary'}>
        {data ? 'yup' : 'nope'}
      </Badge>
    </Container>
  )
}

StatusCol.propTypes = {
  data: PropTypes.bool
}

StatusCol.displayName = 'Status Column'

export default StatusCol