/* eslint-disable no-invalid-this */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import TagRow from '@global/TagRow';
import { UncontrolledTooltip } from 'reactstrap';

export default class ResourceCard extends React.Component {
  static propTypes = {
    resource: PropTypes.object,
    history: PropTypes.object,
  };

  handleEditClicked = () => {
    this.props.history.push(`/resources/resource-detail?${this.props.resource.id}`, { resource: this.props.resource });
  };
  resolveIcon = resource => {
    let icon = 'file-alt';
    if (resource.type === 'Slides') {
      icon = 'image';
    }
    if (resource.type === 'Website') {
      icon = 'external-link-alt';
    }
    if (resource.type === 'Form') {
      icon = 'check-square';
    }
    if (resource.type === 'Survey') {
      icon = 'check-square';
    }
    if (resource.type === 'EpifanySurvey') {
      icon = 'check-square';
    }
    return icon;
  }

  render() {
    const { resource } = this.props;
    return (
      <div className='row my-2 border-bottom-1 mt-2 mx-2'>
        <div className='col'>
          <h5><FontAwesomeIcon icon={this.resolveIcon(resource)}/> {resource.name}</h5>
          <span className='text-muted'>
            {resource.type === 'Slides' ? 'Slides' : null}
            {resource.type === 'TextBased' ? 'Text' : null}
            {resource.type === 'Website' ? 'Website Link' : null}
            {resource.type === 'Form' ? 'Form' : null}
            {resource.type === 'Survey' ? 'Survey' : null}
            {resource.type === 'EpifanySurvey' ? 'Epifany Survey' : null}
          </span>
        </div>
        <div className='col d-flex align-items-center justify-content-end'>
          <UncontrolledTooltip className='mb-2' placement='top' target={`pin-${resource.id}`}> Pinned </UncontrolledTooltip>
          <h5 id={`pin-${resource.id}`} className='text-muted mr-5 py-0 my-0'>{resource.is_pinned === true ? <FontAwesomeIcon icon={'thumbtack'} size={'1x'}/> : null}</h5>
          <Button
            onClick={this.handleEditClicked}
            className='btn btn-outline-primary'>
            Edit
          </Button>
        </div>
      </div>
    );
  }
}

ResourceCard.displayName = 'ResourceCard';
