import {
  CREATE_MEMBER,
  REGISTER_COMMUNITY,
  SET_MEMBERS,
  SET_INACTIVE_USERS,
  UPDATE_MEMBER,
  ACTIVATE_MEMBER,
  DEACTIVATE_MEMBER,
  DELETE_MEMBER_CHECKIN,
  UPDATE_MEMBER_CHECKIN,
  CREATE_MEMBER_CHECKIN,
  CLEAR_CAMPUS_DATA,
  ACTIVATE_TEAM,
  DEACTIVATE_TEAM,
  CLEAR_MEMBERS,
} from '../reduxActions/actionTypes'
import { arrayMerge, sortByMemberName } from './reducerConstants'
import initialState from './initialState'

export default function (members = initialState.members, action) {
  switch (action.type) {
    case CREATE_MEMBER:
      return {
        ...members,
        members: [...members.members, Object.assign({}, action.member)],
      }
    case REGISTER_COMMUNITY:
      return { ...members, members: [action.manager] }
    case SET_MEMBERS:
      if (members.members && members.members.length > 0) {
        const oldMembers = members.members
        const newMembers = action.members
        return {
          ...members,
          members: arrayMerge(oldMembers, newMembers),
          meta: action.meta,
        }
      }
      // list is empty so initialize so just set it.
      return {
        ...members,
        members: sortByMemberName(action.members),
        meta: action.meta,
      }
    case SET_INACTIVE_USERS:
      if (members.inactive && members.inactive.length > 0) {
        const oldMembers = members.inactive
        const newMembers = action.members
        return {
          ...members,
          inactive: arrayMerge(oldMembers, newMembers),
          inactiveMeta: action.meta,
        }
      }
      return {
        ...members,
        inactive: sortByMemberName(action.members),
        inactiveMeta: action.meta,
      }
    case UPDATE_MEMBER:
      let activeMembers = JSON.parse(JSON.stringify(members.members))
      let inactiveMembers = JSON.parse(JSON.stringify(members.inactive))
      if (action.member.status === 'active') {
        if (!activeMembers) return { ...members, members: [action.team] }
        activeMembers = arrayMerge(members.members, [action.member])
      } else if (action.member.status === 'inactive') {
        if (!inactiveMembers) return { ...members, inactive: [action.member] }
        inactiveMembers = arrayMerge(members.inactive, [action.member])
      }

      return {
        ...members,
        members: activeMembers,
        inactive: inactiveMembers,
      }
    case ACTIVATE_MEMBER:
      inactiveMembers = JSON.parse(JSON.stringify(members.inactive))
      inactiveMembers = inactiveMembers.filter(
        member => member.id !== action.member.id
      )
      return {
        ...members,
        members: arrayMerge(members.members, [action.member]),
        inactive: inactiveMembers,
      }
    case DEACTIVATE_MEMBER:
      activeMembers = JSON.parse(JSON.stringify(members.members))
      activeMembers = activeMembers.filter(
        member => member.id !== action.member.id
      )
      let theMembers = []
      if (members.inactive) {
        theMembers = members.inactive
      }
      return {
        ...members,
        members: activeMembers,
        inactive: arrayMerge(theMembers, [action.member]),
      }
    case ACTIVATE_TEAM:
      // when you archive a team or change the campus of the team.
      const inactiveMembersList = JSON.parse(JSON.stringify(members.inactive))
      let membersToDrop = action.team.inactive_users
      if (action.team.users) {
        membersToDrop = membersToDrop.concat(action.team.users)
      }
      membersList = inactiveMembersList.filter(member => {
        let keep = true
        membersToDrop.forEach(memberToDrop => {
          if (memberToDrop.id === member.id) {
            keep = false
          }
        })
        return keep
      })
      return { ...members, inactive: membersList }
    case DEACTIVATE_TEAM:
      // when you archive a team or change the campus of the team.
      let membersList = JSON.parse(JSON.stringify(members.members))
      membersToDrop = action.team.users
      if (action.team.inactive_users) {
        membersToDrop = membersToDrop.concat(action.team.inactive_users)
      }
      membersList = membersList.filter(member => {
        let keep = true
        membersToDrop.forEach(memberToDrop => {
          if (memberToDrop.id === member.id) {
            keep = false
          }
        })
        return keep
      })
      return { ...members, members: membersList }
    case DELETE_MEMBER_CHECKIN:
      return {
        ...members,
        members: members.members.map(oldMember => {
          if (oldMember.id !== action.memberId) {
            return oldMember
          }
          const newMember = {
            checkins: oldMember.checkins ? [...oldMember.checkins] : [],
          }
          for (let index = 0; index < newMember.checkins.length; index++) {
            const element = newMember.checkins[index]
            if (element.id === action.checkin.id) {
              newMember.checkins = newMember.checkins.slice(0, index)
              break
            }
          }
          return { ...oldMember, ...newMember }
        }),
      }
    case UPDATE_MEMBER_CHECKIN:
      return {
        ...members,
        members: members.members.map(oldMember => {
          if (oldMember.id !== action.memberId) {
            return oldMember
          }
          const newMember = {
            checkins: oldMember.checkins ? [...oldMember.checkins] : [],
          }
          for (let index = 0; index < newMember.checkins.length; index++) {
            const element = newMember.checkins[index]
            if (element.id === action.checkin.id) {
              newMember.checkins[index] = action.checkin
              break
            }
          }
          return { ...oldMember, ...newMember }
        }),
      }
    case CREATE_MEMBER_CHECKIN:
      return {
        ...members,
        members: members.members.map(oldMember => {
          if (oldMember.id !== action.memberId) {
            return oldMember
          }
          const newMember = {
            checkins: oldMember.checkins ? [...oldMember.checkins] : [],
          }
          newMember.checkins.push(action.checkin)
          return { ...oldMember, ...newMember }
        }),
      }
    case CLEAR_MEMBERS:
    case CLEAR_CAMPUS_DATA:
      return initialState.members
    default:
      return members
  }
}
