/* eslint-disable react/prop-types,no-trailing-spaces,max-params */
import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

const CompanyStatCard = ({ title, value, icon }) => {
  return (
    <div className='col-sm-6 col-lg-3'>
      <div className='card'>
        <div className='clearfix p-0 card-body'>
          <i className='fa fa-calendar bg-primary p-4 font-xl mr-3 float-left'>
            <FontAwesomeIcon icon={icon} color='white' />
          </i>
          <div className='h5 mb-0 text-primary pt-3'>{value}</div>
          <div className='text-muted text-uppercase font-weight-bold font-xs'>{title}</div>
        </div>
      </div>
    </div>
  );
};
CompanyStatCard.displayName = 'CompanyStatCard';

export default CompanyStatCard;
