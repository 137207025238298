import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { successUpdatingCommunityCoworksGroups } from './communityActions'
import {
  FETCHING_ROOMS,
  FETCHING_OCCUPANCY,
} from '@global/Constants/FetchingConstants'
export const ROOM = 'Conference'
export const SPACE = 'Space'
export const EQUIPMENT = 'Equipment'
export const SUITE = 'Suite'

const ENUM = {
  SET: 'set',
  CLEAR: 'clear',
  UPDATE: 'update',
  SUCCESS_CREATE: 'success-create',
  SUCCESS_DELETE: 'success-delete',
  SUCCESS_UPDATE: 'success-update',
}

export const getFetchByType = type => {
  switch (type) {
    case ROOM:
      return FETCHING_ROOMS
    default:
      return FETCHING_OCCUPANCY
  }
}

const getType = type => {
  switch (type) {
    case SUITE:
      return {
        [ENUM.SET]: actionTypes.SET_SUITES,
        [ENUM.CLEAR]: actionTypes.CLEAR_SUITES,
        [ENUM.SUCCESS_CREATE]: actionTypes.CREATE_SUITE,
        [ENUM.SUCCESS_DELETE]: actionTypes.ARCHIVE_SUITE,
        [ENUM.SUCCESS_UPDATE]: actionTypes.UPDATE_SUITE,
      }
    case ROOM:
    case EQUIPMENT:
    case SPACE:
    default:
      return {
        [ENUM.SET]: actionTypes.SET_ROOMS,
        [ENUM.CLEAR]: actionTypes.CLEAR_ROOMS,
        [ENUM.SUCCESS_CREATE]: actionTypes.CREATE_ROOM,
        [ENUM.SUCCESS_DELETE]: actionTypes.ARCHIVE_ROOM,
        [ENUM.SUCCESS_UPDATE]: actionTypes.UPDATE_ROOM,
      }
  }
}

export function createdRoom(type, room) {
  return { type: getType(type)[ENUM.SUCCESS_CREATE], room }
}

export function setRooms(type, rooms, meta = null, stats = null) {
  return { type: getType(type)[ENUM.SET], rooms, meta, stats }
}
export function clearRooms(type) {
  return { type: getType(type)[ENUM.CLEAR] }
}

export function successUpdatingRoom(type, room) {
  return { type: getType(type)[ENUM.SUCCESS_UPDATE], room }
}

export function successArchivingRoom(type, room) {
  return { type: getType(type)[ENUM.SUCCESS_DELETE], room }
}
export function storeRoomToken(roomToken) {
  return { type: actionTypes.STORE_ROOM_TOKEN, roomToken }
}

// ACTION DISPATCHERS
export const createRoom = (params, type = ROOM) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createRoom(params)
      .then(response => {
        dispatch(createdRoom(type, response.room))
        if (response.community_coworks_groups) {
          dispatch(
            successUpdatingCommunityCoworksGroups(
              response.community_coworks_groups
            )
          )
        }
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.room)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const updateRoom = (params, type = ROOM) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateRoom(params)
      .then(response => {
        dispatch(successUpdatingRoom(type, response.room))
        if (response.community_coworks_groups) {
          dispatch(
            successUpdatingCommunityCoworksGroups(
              response.community_coworks_groups
            )
          )
        }
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.room)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const archiveRoom = (params, type = ROOM) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateRoom(params)
      .then(response => {
        dispatch(successArchivingRoom(type, response.room))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.room)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export function getRoom(roomId, type = ROOM) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return new Promise((resolve, reject) => {
      apiService
        .getRoom(roomId)
        .then(response => {
          dispatch(successUpdatingRoom(type, response.room))
          resolve(response.room)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => dispatch(uiActions.updateFetchingStatus(false)))
    })
  }
}

export const getRoomsAtCampus = (campusId, params, type = ROOM) => dispatch => {
  dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
  return new Promise((resolve, reject) => {
    apiService
      .getRoomsAtCampus(campusId, params)
      .then(response => {
        dispatch(setRooms(type, response.rooms, response.meta))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
      )
  })
}
export const getRooms = (params, clear = false, type = ROOM) => dispatch => {
  dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
  if (clear) {
    dispatch(clearRooms(type))
  }
  return new Promise((resolve, reject) => {
    apiService
      .getRooms(params)
      .then(response => {
        dispatch(
          setRooms(
            type,
            response.rooms,
            response.meta,
            response.occupancy_stats
          )
        )
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
      )
  })
}

export const search = (campusId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getRoomsAtCampus(campusId, params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
