/* eslint-disable no-invalid-this */
import React, { useState } from 'react'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import * as Constants from '@global/Constants'
import * as _ from 'lodash'
import { Button, Input, Row } from 'reactstrap'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import DateTime from 'react-datetime'
import { toast } from 'react-toastify'
import Tooltip from '@bit/coworks.base-components.tooltip'
import Image from '@global/UploadImage/Image'
import Spinner from '@global/Spinner'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import * as roomActions from '@reduxActions/roomActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'
import { convertStripeErrorToMessage } from '@global/ErrorFactory'
import {
  LabelStyled,
  FontAwesomeIconLabel,
  CustomInputComponent,
  CustomQuickSearchComponent,
  CustomTextAreaComponent,
  CustomDatePickerComponent,
  HoursOfOperation,
} from '@global/Form/FormComponents'
import { CustomToggleComponent } from '@global/Form/FormComponentsV2'

import RadioGroup from '@global/LocalRadioGroup'

import CreatableSelect from '@global/Search/CoworksCreatableSelect'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import Badge from '@global/Base/Badge/Badge'
import { renderCreatableDropdownGroup } from '@global/DynamicGroups/DynamicGroupFunctions'

const OPTION_ENUM = {
  CONFERENCE: {
    label: 'Yes',
    value: 'Conference',
    description:
      'Conference rooms are bookable to members through the mobile apps.',
  },
  SPACE: {
    label: 'No, it is more like a space',
    value: 'Space',
    description:
      'Spaces are bookable by managers through the admin dashboard and are usually larger multi-purpose spaces for events.',
  },
}

const ContainerWithLeftMargin = styled.div`
  margin-left: 15px !important;
`
function Room({
  history,
  match,
  room,
  type,
  teams,
  room_actions,
  activeCampus,
  activeCommunity,
}) {
  // Editing
  const [photoChanged, setPhotoChanged] = useState(false)
  const [isEditing, setIsEditing] = useState(
    Boolean(
      !room || (match && match.params && match.params.formState === 'edit')
    )
  )
  const [isFetching, setIsFetching] = useState(false)
  const [userRoomPermissionOptions, setUserRoomPermissionOptions] = useState(
    activeCommunity.coworks_groups &&
      activeCommunity.coworks_groups.room_permissions &&
      activeCommunity.coworks_groups.room_permissions.tags
      ? activeCommunity.coworks_groups.room_permissions.tags.map(
          (permission, index) => ({
            label: permission,
            value: permission,
          })
        )
      : []
  )

  const goBack = () => history.goBack()

  const handleTeamClick = team => {
    if (team) {
      NavigationConstants.navigateToTeam({
        history,
        match,
        team,
      })
    } else {
      toast.error(`Whoops! Couldn't navigate to the team`)
    }
  }
  const handlePlanClick = plan => {
    if (plan) {
      NavigationConstants.navigateToPlan({
        history,
        match,
        plan,
      })
    } else {
      toast.error(`Whoops! Couldn't navigate to the plan`)
    }
  }

  const createRoom = formState => {
    const capacity = parseInt(formState.capacity, 10)
    const roomObj = {
      campus_id: activeCampus.id,
      capacity: capacity,
      description: formState.description,
      type: formState.type,
      size: formState.size,
      name: formState.roomName,
      is_public: formState.isPublic,
      external_hourly_rate: formState.externalHourlyRate,
      user_room_permissions: formState.userRoomPermissions
        ? formState.userRoomPermissions.map(obj => obj.value).join(', ')
        : '',
      requires_access: formState.requiresAccess,
      team_id: formState.team ? formState.team.id : null,
      plan_id: formState.plan ? formState.plan.id : null,
      notes: formState.notes ? formState.notes : null,
      contract_url: formState.contractUrl ? formState.contractUrl : null,
      room_photo: formState.photo ? formState.photo : null,
      preferences: JSON.stringify(formState.preferences),
    }
    setIsFetching(true)
    room_actions.createRoom(roomObj).then(room => {
      toast.success(`Created ${formState.roomName}`)
      history.replace(`/occupancy/${room.id}`, { room })
      setIsFetching(false)
      setIsEditing(false)
    })
  }

  const archiveRoom = () => {
    setIsFetching(true)

    room_actions
      .archiveRoom({
        id: room.id,
        is_archived: true,
      })
      .then(() => {
        toast.success(`Archived ${room.name} successfully.`)
        goBack()
      })
      .catch(err => {
        toast.error(convertStripeErrorToMessage(err))
        setIsFetching(false)
      })
  }

  const updateRoom = formState => {
    const roomPreferences = formState.room.preferences

    const obj = {
      id: formState.room.id,
      capacity: parseInt(formState.capacity, 10),
      description: formState.description,
      size: formState.size,
      name: formState.roomName,
      type: formState.type,
      is_public: formState.isPublic,
      external_hourly_rate: formState.externalHourlyRate,
      user_room_permissions: formState.userRoomPermissions
        ? formState.userRoomPermissions.map(obj => obj.value).join(', ')
        : '',
      requires_access: formState.requiresAccess,
      team_id: formState.team ? formState.team.id : '',
      plan_id: formState.plan ? formState.plan.id : '',
      notes: formState.notes ? formState.notes : '',
      contract_url: formState.contractUrl ? formState.contractUrl : '',
      preferences: JSON.stringify(formState.preferences),
    }
    if (photoChanged) {
      obj.room_photo = formState.photo
    }
    setIsFetching(true)

    room_actions
      .updateRoom(obj, obj.type)
      .then(room => {
        toast.success(`Updated the ${formState.roomName}.`)
        if (match && match.params && match.params.formState === 'edit') {
          history.replace(`/occupancy/${room.id}`, { room })
        }
        setIsEditing(false)
        setIsFetching(false)
      })
      .catch(err => {
        setIsFetching(false)
        toast.error(convertStripeErrorToMessage(err))
        console.error(err)
      })
  }

  function onlyIncludeSuites(plans) {
    // this filters out any plans that don't have the "Suite" tag
    const myplans = plans.filter(plan => {
      let isSuite = false
      plan.tags.forEach(tag => {
        if (tag.name === 'Suite') {
          isSuite = true
        }
      })
      return isSuite
    })
    return myplans
  }

  const handleImageUpload = (image, setFieldValue) => {
    setFieldValue('photo', image)
    setPhotoChanged(true)
  }
  const getAvailabilityDefault = () => {
    if (room && room.preferences && room.preferences.availability) {
      return room.preferences.availability
    } else if (activeCampus && activeCampus.preferences) {
      return activeCampus.preferences.hours_of_operation
    }
    return {
      monday: [{ open: 32400, close: 61200 }], // 9-5
      tuesday: [{ open: 32400, close: 61200 }], // 9-5
      wednesday: [{ open: 32400, close: 61200 }], // 9-5
      thursday: [{ open: 32400, close: 61200 }], // 9-5
      friday: [{ open: 32400, close: 61200 }], // 9-5
      saturday: [{ open: 0, close: 0 }], // not open
      sunday: [{ open: 0, close: 0 }], // not open,
    }
  }
  const toggleEditState = () => {
    setIsEditing(!isEditing)
  }
  const resolveFormTitle = (room, type, isEditing) => {
    let title = ''
    if (room) {
      if (isEditing) {
        title = 'Edit '
      }
      title += `${room.name}`
    } else {
      title += 'New '
      switch (type) {
        case 'Conference':
          title += 'Conference Room'
          break
        case 'Space':
          title += 'Space'
          break
        case 'Equipment':
          const equipmentName =
            activeCommunity.community_preference.equipment_resource_name
          title += equipmentName
          break
        case 'Suite':
          title += 'Suite'
          break
        default:
          break
      }
    }
    return title
  }
  if (isFetching) return <Spinner />
  const metric = Constants.getMinifiedMetric(activeCampus.area_units)
  return (
    <Formik
      initialValues={{
        type: room && room.type ? room.type : type,
        roomName: room && room.name ? room.name : '',
        description: room && room.description ? room.description : null,
        capacity: room && room.capacity ? room.capacity : null,
        size: room && room.size ? room.size : null,
        contractUrl: room && room.contract_url ? room.contract_url : null,
        isPublic: room && room.is_public ? room.is_public : false,
        externalHourlyRate:
          room && room.external_hourly_rate ? room.external_hourly_rate : 0,
        photo:
          room && room.room_photo && room.room_photo.file_url
            ? room.room_photo.file_url
            : '',
        requiresAccess:
          room && room.requires_access ? room.requires_access : false,
        notes: room && room.notes ? room.notes : null,
        plan: room && room.plan ? room.plan : null,
        team: room && room.team ? room.team : null,
        isOccupied: room && room.is_occupied ? room.is_occupied : false,
        moveInDate: room && room.move_in_date ? room.move_in_date : null,
        moveOutDate: room && room.move_out_date ? room.move_out_date : null,
        moveOutDatefocued: false,
        moveInDatefocused: false,
        preferences:
          room && room.preferences
            ? room.preferences
            : {
                availability_enforced: false,
                availability: getAvailabilityDefault(),
                booking_padding_in_mins: 0,
              },
        userRoomPermissions:
          room && room.user_room_permission_list
            ? room.user_room_permission_list.map((val, index) => ({
                label: val,
                value: val,
              }))
            : [],
      }}
      validationSchema={Yup.object().shape({
        roomName: Yup.string().required('Name is required!'),
        capacity: Yup.number().when('type', {
          is: 'Equipment',
          then: Yup.number().notRequired().nullable(),
          otherwise: Yup.number()
            .typeError('You must specify a number')
            .required('Capacity is required!')
            .min(1, 'Must be 1 or more.'),
        }),
        size: Yup.number().when('type', {
          is: 'Suite',
          then: Yup.number()
            .required()
            .typeError('You must specify a number')
            .required('Capacity is required!')
            .min(1, 'Must be 1 or more.'),
          otherwise: Yup.number().notRequired().nullable(),
        }),
        externalHourlyRate: Yup.number().when('isPublic', {
          is: false,
          then: Yup.number().notRequired(),
          otherwise: Yup.number()
            .required('External Hourly rate is required when public!')
            .min(0.01, 'Must be 0.01 or more.'),
        }),
        preferences: Yup.object().test(
          'start-after-end',
          'All start times must be before end times',
          (preferences, context) => {
            // loop though and check for errors
            // @ts-ignore
            const value = preferences.availability
            let isValid = true
            if (!preferences.availability_enforced) {
              return true
            }
            Object.keys(value).forEach(dayKey => {
              const obj = value[dayKey]
              // if open comes before closed
              if (obj.length === 1 && obj[0].open > obj[0].close) {
                isValid = false
              }
            })
            return isValid
          }
        ),
      })}
      validationSchemaContext={{
        type,
        isPublic: Boolean(room && room.is_public),
      }}
      onSubmit={(values, { setSubmitting }) => {
        const valuesCopy = JSON.parse(JSON.stringify(values))
        if (room) {
          valuesCopy.room = room
          updateRoom(valuesCopy)
        } else {
          createRoom(valuesCopy)
        }
        setSubmitting(false)
      }}
    >
      {formProps => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = formProps
        // Dev method for understanding errors
        if (errors && errors !== {}) {
          console.log(errors)
        }
        return (
          <div className="animated fadeIn">
            <div id="new-member-form" className="card">
              <div className="card-header">
                <Row>
                  <div className="col-6">
                    <h2>{resolveFormTitle(room, values.type, isEditing)}</h2>
                  </div>
                  {!isEditing && (
                    <div className="col-6 d-flex justify-content-end align-items-center">
                      <ButtonStyled onClick={toggleEditState}>
                        Edit
                      </ButtonStyled>
                    </div>
                  )}
                </Row>
              </div>
              <div className="card-block">
                <Row>
                  <div className="col-md-8 col-12 offset-md-2">
                    <form onSubmit={handleSubmit}>
                      <fieldset disabled={isFetching}>
                        <div className="row mb-2">
                          {isEditing ? (
                            <div className="col-12 upload-photo">
                              <ImageUploadComponent
                                imageUrl={values.photo}
                                onUpload={image =>
                                  handleImageUpload(image, setFieldValue)
                                }
                                aspectRatio={1.5}
                              />
                            </div>
                          ) : (
                            <div className="col-12 ">
                              <Image
                                width={'100%'}
                                height={'180px'}
                                imageUrl={values.photo}
                                imageType={'room'}
                              />
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div
                                className={`${
                                  values.type === 'Equipment'
                                    ? 'col-12'
                                    : 'col-6'
                                }`}
                              >
                                <Field
                                  label={`Name`}
                                  name="roomName"
                                  renderViewState={!isEditing}
                                  isEditing={isEditing}
                                  placeholder={`What is it called?`}
                                  component={CustomInputComponent}
                                />
                              </div>
                              {values.type !== 'Equipment' && (
                                <React.Fragment>
                                  <div className="col-3">
                                    <Field
                                      label={`Capacity`}
                                      name="capacity"
                                      type="number"
                                      renderViewState={!isEditing}
                                      isEditing={isEditing}
                                      component={CustomInputComponent}
                                      placeholder="Suite Capacity"
                                    />
                                  </div>

                                  <div className="col-3">
                                    <Field
                                      label={`Sq. ${metric}`}
                                      name="size"
                                      type="number"
                                      renderViewState={!isEditing}
                                      isEditing={isEditing}
                                      tooltipProps={{
                                        header: `Square ${activeCampus.area_units}`,
                                        tooltipContent:
                                          'The size of the suite. The units are configured in your campus.',
                                        buttonText: 'Change Metric',
                                        handleClick: () => {
                                          history.push(
                                            `/campuses/${activeCampus.id}/edit#areaUnits`
                                          )
                                        },
                                      }}
                                      component={CustomInputComponent}
                                      placeholder={'Suite Size'}
                                    />
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                            <Row className="my-3">
                              <div className="col-12">
                                <Field
                                  label={'Description'}
                                  name="description"
                                  rows={3}
                                  renderViewState={!isEditing}
                                  isEditing={isEditing}
                                  component={CustomTextAreaComponent}
                                  placeholder={`Tell your space about ${name}`}
                                />
                              </div>
                            </Row>

                            {values.type !== 'Equipment' &&
                            values.type !== 'Suite' &&
                            isEditing ? (
                              <Row className="my-3">
                                <div className="col-12">
                                  <div className="form-group">
                                    <LabelStyled>
                                      Would you consider this a conference room?
                                    </LabelStyled>
                                    <Tooltip
                                      tooltipContent={
                                        'Rooms are bookable through the mobile apps and spaces are only bookable by managers.'
                                      }
                                      header={`Rooms and Spaces`}
                                      buttonText={'More'}
                                      handleClick={() => {
                                        window.open(
                                          `https://help.coworksapp.com/en/articles/4473897-what-are-the-differences-between-rooms-spaces-and-equipment`,
                                          '_blank'
                                        )
                                      }}
                                    >
                                      {' '}
                                      <FontAwesomeIconLabel icon="question-circle" />
                                    </Tooltip>
                                    <br />
                                    <RadioGroup
                                      className="mb-2"
                                      name="type"
                                      activeRadioValue={values.type}
                                      handleChange={val =>
                                        setFieldValue('type', val)
                                      }
                                      arrayOfRadios={[
                                        {
                                          label: OPTION_ENUM.CONFERENCE.label,
                                          value: OPTION_ENUM.CONFERENCE.value,
                                          description:
                                            OPTION_ENUM.CONFERENCE.description,
                                        },
                                        {
                                          label: OPTION_ENUM.SPACE.label,
                                          value: OPTION_ENUM.SPACE.value,
                                          description:
                                            OPTION_ENUM.SPACE.description,
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </Row>
                            ) : null}
                            {values.type === 'Suite' && (
                              <React.Fragment>
                                <br />
                                <h3>Plans</h3>
                                <hr />
                                <Field
                                  id="#plan"
                                  label="Suite Plan"
                                  name="plan"
                                  type="plan"
                                  description="The plan that is associated to the suite. Note: This will only show plans of type Suite."
                                  onClick={handlePlanClick}
                                  dataFilter={plans => onlyIncludeSuites(plans)}
                                  renderViewState={!isEditing}
                                  isEditing={isEditing}
                                  noOptionsMessage={() =>
                                    'No suite plans found'
                                  }
                                  component={CustomQuickSearchComponent}
                                />
                              </React.Fragment>
                            )}
                            <Row className="my-3">
                              <div className="col-12">
                                <Field
                                  label={'Private Notes'}
                                  name="notes"
                                  rows={3}
                                  renderViewState={!isEditing}
                                  isEditing={isEditing}
                                  component={CustomTextAreaComponent}
                                  placeholder={`Some notes on ${name}`}
                                />
                              </div>
                            </Row>

                            {type !== 'Space' && type !== 'Suite' ? (
                              <React.Fragment>
                                <br />
                                <h3>Access Permissions</h3>
                                <hr />
                                <CustomToggleComponent
                                  name="requiresAccess"
                                  label="Requires Member Permissions"
                                  description="Controls if the member needs explicit permissions to book"
                                  disabled={!isEditing}
                                  onChange={() => {
                                    setFieldValue(
                                      'requiresAccess',
                                      !values.requiresAccess
                                    )
                                  }}
                                  children={renderCreatableDropdownGroup({
                                    name: 'Room Permissions Group',
                                    groupName: 'userRoomPermissions',
                                    placeholder:
                                      'Start typing to select or create a room permission group...',
                                    values: values.userRoomPermissions,
                                    options: userRoomPermissionOptions,
                                    handleChange: (name, newValues) =>
                                      setFieldValue(name, newValues),
                                    isEditing: isEditing,
                                  })}
                                />
                                <br />
                                <br />
                                {activeCommunity.stripe_account_id &&
                                values.type !== 'Equipment' ? (
                                  <React.Fragment>
                                    <div className="row">
                                      <div className="col-12">
                                        <CustomToggleComponent
                                          name="isPublic"
                                          disabled={!isEditing}
                                          label="Publicly Bookable"
                                          description="Shows or hides this in the external bookings feature"
                                          children={
                                            <ContainerWithLeftMargin>
                                              <Field
                                                name="externalHourlyRate"
                                                description="The amount the room will cost per hour for external bookings"
                                                label="External Hourly Rate"
                                                type="number"
                                                isEditing={isEditing}
                                                renderViewState={!isEditing}
                                                component={CustomInputComponent}
                                              />
                                            </ContainerWithLeftMargin>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ) : null}
                                <br />
                                <br />
                                {type !== 'Suite' && (
                                  <div className="row">
                                    <div className="col-12">
                                      <CustomToggleComponent
                                        name="preferences.availability_enforced"
                                        label="Schedule"
                                        disabled={!isEditing}
                                        description="Controls the room's booking availability schedule"
                                        children={
                                          <ContainerWithLeftMargin>
                                            <HoursOfOperation
                                              isEditing={isEditing}
                                              renderViewState={!isEditing}
                                              label="Hours of Operation"
                                              name="preferences.availability"
                                              id="businessHours"
                                              placeholder="Room Availability"
                                            />
                                            {errors.preferences ? (
                                              <div className="text-danger">
                                                {errors.preferences}{' '}
                                              </div>
                                            ) : null}
                                          </ContainerWithLeftMargin>
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            ) : null}
                            {type === 'Suite' && (
                              <React.Fragment>
                                <br />
                                <Row>
                                  <div className="col-6">
                                    <h3>Occupancy</h3>
                                  </div>
                                  <div className="col-6 d-flex justify-content-end">
                                    <Badge
                                      badgeShape={'pill'}
                                      color={values.team ? 'danger' : 'success'}
                                    >
                                      {values.team ? 'Occupied' : 'Vacant'}
                                    </Badge>
                                  </div>
                                </Row>

                                <hr />
                                <Field
                                  label="Team"
                                  name="team"
                                  type="team"
                                  id="#team"
                                  description="The team currently occupying the suite"
                                  renderViewState={!isEditing}
                                  isEditing={isEditing}
                                  teams={teams}
                                  onClick={handleTeamClick}
                                  component={CustomQuickSearchComponent}
                                />
                                {/* <br />
                                <Row>
                                  <div className="col-6">
                                    <Field
                                      label="Move In Date"
                                      name="moveInDate"
                                      renderViewState={!isEditing}
                                      isEditing={isEditing}
                                      id="moveInDate"
                                      component={CustomDatePickerComponent}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Field
                                      label="Move Out Date"
                                      name="moveOutDate"
                                      renderViewState={!isEditing}
                                      isEditing={isEditing}
                                      id="moveOutDate"
                                      component={CustomDatePickerComponent}
                                    />
                                  </div>
                                </Row> */}

                                <Field
                                  label="Contract Link"
                                  name="contractUrl"
                                  viewType="externalURL"
                                  renderViewState={!isEditing}
                                  isEditing={isEditing}
                                  description={
                                    'The link the contract the team signed for this suite'
                                  }
                                  id="contractUrl"
                                  placeholder={`https://www.example.com`}
                                  component={CustomInputComponent}
                                />
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </fieldset>
                      <Row className="my-3">
                        {room && (
                          <div className="col d-flex align-items-start">
                            <Button
                              color="danger"
                              className="mr-3"
                              onClick={archiveRoom}
                              disabled={isSubmitting || isFetching}
                            >
                              Archive
                            </Button>
                          </div>
                        )}
                        <div className={'col d-flex justify-content-end'}>
                          <Button
                            color="secondary"
                            className="mr-3"
                            onClick={
                              room && isEditing ? toggleEditState : goBack
                            }
                          >
                            {isEditing ? 'Cancel' : 'Go back'}
                          </Button>
                          {isEditing && (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={
                                (!dirty && !photoChanged) ||
                                isSubmitting ||
                                isFetching
                              }
                            >
                              Save
                            </Button>
                          )}
                        </div>
                      </Row>
                    </form>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = (state, props) => {
  const rooms = roomSelectors.getAll(state)
  let room = props.room
  const locState = props.history.location.state
  // Pull from history
  if (!room && locState && locState.room) {
    room = locState.room
  }

  // But prefer the redux version as it's likely more up to date
  if (room && rooms.length > 0) {
    const roomLookup = rooms.filter(reduxRoom => reduxRoom.id === room.id)
    if (roomLookup.length > 0) {
      room = roomLookup[0]
    }
  }

  // set type for NEW form
  let type = 'Conference'
  if (locState && locState.type) {
    type = locState.type
  }
  if (room && room.type) {
    type = room.type
  }

  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    room,
    type,
    isFetching: state.ui.isFetching,
    teams: state.teams.teams,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Room)

Room.displayName = 'Room'
Room.propTypes = {
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  room: PropTypes.object,
  room_actions: PropTypes.object,
  type: PropTypes.string,
  teams: PropTypes.array,
}
