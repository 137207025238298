import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as campusActions from '@reduxActions/campusActions'
import * as uiActions from '@reduxActions/uiActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import styled from 'styled-components'

import { Row, Col, Alert } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'

import * as Roles from '@app/config/roles'
import { logO, resolveDomainPrefix } from '@global/Constants'
import { LabelStyled } from '@global/Form/FormComponents'

import { TextButton } from '@global/Base/Button/ButtonStyled'
import {
  resolveFetchingStatus,
  FETCHING_UPDATING_CAMPUS,
} from '@global/Constants/FetchingConstants'
import Badge from '@global/Base/Badge/Badge'

import moment from 'moment'
import DayPassSettings from '../DayPassSettings'
import FormOption from './FormOption'

import CampusDropdown from '@global/Header/CampusDropdown'

const OPTIONS_KEY_ENUM = {
  BOOKING_LIST: 'bookings_list',
  DAY_PASS: 'day_pass',
  EXTERNAL_BOOKINGS: 'external_bookings',
  MEMBERSHIP_REQUEST: 'membership_request',
  TOUR_REQUEST: 'tour_request',
}

function WebsiteForms({
  history,
  activeCampus,
  activeCommunity,
  campus_actions,
  ui_actions,
  isFetching,
}) {
  const [modalOpen, setModalOpen] = useState(false)
  const preference = activeCommunity.community_preference
  const baseUrl = resolveDomainPrefix(preference)

  const membershipUrl = `${baseUrl}/membership-request`
  const tourRequestUrl = `${baseUrl}/tour-request`
  const dayPassUrl = `${baseUrl}/day-pass-signup/${activeCampus.id}`
  const eventUrl = `${baseUrl}/events/100`
  const bookingListUrl = `${baseUrl}/bookings/${activeCampus.id}`
  const externalBookingListUrl = `${baseUrl}/book/${activeCampus.id}`

  const consoleObject = {
    membershipUrl,
    tourRequestUrl,
    dayPassUrl,
    eventUrl,
    bookingListUrl,
    externalBookingListUrl,
  }

  const toggleModal = force => {
    if (typeof force === 'boolean') {
      setModalOpen(force)
    } else {
      setModalOpen(prev => !prev)
    }
  }

  const getAction = action => {
    switch (action.type) {
      case 'function':
        return () => action.call()
      case 'link':
        return () => history.push(action.url)
      default:
        return null
    }
  }

  const isActive = key =>
    key &&
    activeCampus &&
    activeCampus.preferences &&
    activeCampus.preferences.website_forms &&
    activeCampus.preferences.website_forms[key]
      ? activeCampus.preferences.website_forms[key].enabled
      : null

  logO('external URLs', consoleObject)

  const forms = [
    {
      sectionName: 'Leads',
      options: [
        {
          name: 'Membership Request Form',
          description:
            'Allow guests to requests a membership allowing you to handle the process of signing them up.',
          url: membershipUrl,
          editAction: {
            type: 'link',
            url: '/community/settings/external',
          },
        },
        {
          name: 'Tour Request Form',
          description:
            'Allow guests to requests a membership allowing you to handle the process of signing them up.',
          url: tourRequestUrl,
        },
      ],
    },
    {
      sectionName: 'Day Pass',
      sectionComponent:
        activeCommunity.campuses.length > 1 ? (
          <select
            className="form-control w-50"
            value={activeCampus.id}
            onChange={event => {
              ui_actions.setActiveCampus(
                activeCommunity.campuses.filter(
                  cc => cc.id === Number(event.target.value)
                )[0]
              )
            }}
          >
            {activeCommunity.campuses.map(camp => (
              <option value={camp.id}>{camp.name}</option>
            ))}
          </select>
        ) : null,
      options: [
        {
          name: activeCampus.day_pass ? (
            activeCampus.day_pass.name
          ) : (
            <Badge color="warning">Not setup!</Badge>
          ),
          key: OPTIONS_KEY_ENUM.DAY_PASS,
          description:
            'A campus-specific public form for guests to be able to purchase access to your space for a day.',
          url: dayPassUrl,
          editAction: {
            type: 'function',
            name: activeCampus.day_pass ? null : 'Create Day Pass',
            call: () => toggleModal(true),
          },
          toggleLabel: 'Enable',
          toggleDescription: `This feature is ${
            isActive(OPTIONS_KEY_ENUM.DAY_PASS) ? 'enabled' : 'disabled'
          }`,
        },
      ],
    },
    {
      sectionName: 'Calendar',
      options: [
        {
          name: 'Booking List',
          description:
            'A public list of the bookings for each room and equipment',
          url: bookingListUrl,
        },
      ],
    },
    {
      sectionName: 'Membership Signup',
      options: [
        {
          name: 'Automated Membership Signup',
          description:
            'A public accessible signup page for each plan. You will need to configure each plan individually and only publish those you want guests to be able to see.',
          url:
            'https://help.coworksapp.com/en/articles/3620360-how-can-i-allow-non-members-to-signup-to-plans-on-their-own',
          showCopyUrlButton: false,
        },
      ],
    },
  ]
  if (activeCommunity.stripe_account_id) {
    forms[2].options.push({
      name: 'External Bookings',
      description: 'A list of rooms bookable by the general public.',
      url: externalBookingListUrl,
      settingsUrl: `/campuses/${activeCampus.id}/edit#externalBookings`,
      showCopyUrlButton: true,
      newUntilBadgeDate: moment.unix(1609539970),
    })
  }

  const switchFeatureEnabled = option => {
    const key = option.key
    const newPrefs = JSON.parse(JSON.stringify(activeCampus.preferences))
    newPrefs.website_forms[key].enabled = !newPrefs.website_forms[key].enabled

    const campusData = {
      id: activeCampus.id,
      preferences: JSON.stringify({
        ...newPrefs,
      }),
    }

    return campus_actions
      .updateCampus(campusData)
      .then(response => {
        const name =
          typeof option.name === 'string' ? `${option.name} form` : 'feature'
        toast.success(
          `Turned ${
            !newPrefs.website_forms[key].enabled ? 'OFF' : 'ON'
          } the ${name}.`
        )
      })
      .catch(err => {
        if (err) {
          toast.error(err.message)
        } else {
          toast.error('There was an error updating the feature.')
        }
      })
  }

  const createOption = option => (
    <FormOption
      className="border-bottom-1 my-2"
      option={option}
      active={isActive(option.key)}
      onSwitch={switchFeatureEnabled}
      onEditClick={option.editAction && getAction(option.editAction)}
      toggle={toggleModal}
      history={history}
      disableSwitch={isFetching}
    />
  )

  const generateSection = forms =>
    forms.map(formObject => (
      <div className="col-12 m-3">
        <div className="row">
          <div className="col-6">
            <h4>
              <b>{formObject.sectionName}</b>
            </h4>
          </div>
          {formObject.sectionComponent && (
            <div className="col-6 d-flex align-items-center justify-content-end">
              {formObject.sectionComponent}
            </div>
          )}
        </div>
        <hr />
        {formObject.options.map(option => createOption(option))}
      </div>
    ))

  //todo add role for superadmin & add events url
  console.log(forms)
  return (
    <Row className="mb-2">
      <div className="col-12 m-3">
        <h4>
          <b>Your Community Subdomain</b>
          <hr />
        </h4>
        <Alert color="info" id="subdomain">
          {activeCommunity.community_preference.subdomain}
        </Alert>
        <p className="text-muted">
          The subdomain for your community. It gives you a unique url for your
          external (public) forms that Coworks provides. To change this, please
          contact support.{'  '}
          <TextButton
            className="m-0 p-0 ml-2"
            onClick={() =>
              window.open(
                'http://help.coworksapp.com/en/articles/3324065-what-is-my-subdomain-and-how-can-i-find-it',
                '_blank'
              )
            }
          >
            <FontAwesomeIcon icon={'external-link-alt'} /> More on subdomains
          </TextButton>
        </p>
      </div>
      {generateSection(forms)}
      <DayPassSettings open={modalOpen} toggle={toggleModal} />
    </Row>
  )
}

WebsiteForms.propTypes = {
  history: PropTypes.object.isRequired,
  activeCampus: PropTypes.object.isRequired,
  activeCommunity: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  campus_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  isFetching: PropTypes.bool,
}

WebsiteForms.displayName = 'External Features'

function mapStateToProps(state) {
  return {
    isFetching: state.ui.isFetching,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    campus_actions: bindActionCreators(campusActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteForms)
