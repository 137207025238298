/* eslint-disable react/prop-types,react/display-name,react/no-multi-comp */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import '../../../../styles/coworks/components/NewMemberForm.scss'

import DateTime from 'react-datetime'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
// import CoworksSearch from '@global/Search/CoworksSearch'
import QuickSearch from '@global/Search/QuickSearch'
import * as Constants from '@global/Constants'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import Switch from '@global/Base/Switch/Switch'
import Badge from '@global/Base/Badge/Badge'

import { withFormik, Field } from 'formik'
import * as Yup from 'yup'
import ContextualSpinner from '@global/Spinner'
import { listOfRolesForFrontEnd, canEditRoles } from '../../../../config/roles'
import MyStatefulEditor from '@global/MyStatefulEditor'
import faker from 'faker'
import Tooltip from '@bit/coworks.base-components.tooltip'
import Toggle from './Toggle'

import {
  LabelStyled,
  FontAwesomeIconLabel,
  CustomInputComponent,
  CustomMemberTypeSelectComponent,
} from '@global/Form/FormComponents'

const InnerNewMemberForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    validateForm,
    setFieldTouched,
    setFieldValue,
    // custom functions as props, and anything that will change in parent
    history,
    handleInputChange,
    isFetching,
    hasDoorProvider,
    communityPreference,
    activeCommunity,
    handleImageUpload,
    doorAccessOptions,
    showAccess,
    doorAccess,
    accessDate,
    handleUserAccessDateChanged,
    handleSendWelcomeEmailToggle,
    sendWelcomeEmail,
    handleUserJoinedAtChanged,
    userJoinedAt,
    handleAccessToggle,
    handleDoorsGrantedChanged,
    handleSearchItemSelected, //req teamdropdown
    handleSearchItemCleared, // req teamdropdown
    team,
    //createMember,
    disableMemberTypeDropdown,
    goBack,
    type,
    submittingRequest,
    runSearchAndAttemptMatchValue,
  } = props

  if (!dirty && !touched.email && values.email && team) {
    setFieldTouched('email', true, true)
    validateForm(values)
  }
  const renderDoorLabels = list => {
    if (!list || list.length === 0)
      return <span className="text-muted">None</span>
    return list.map((permission, index) => {
      return <Badge className="mr-2">{permission.label}</Badge>
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="animated fadeIn">
        <div id="new-member-form" className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-6">
                <h2>New Member</h2>
              </div>
              {(!process.env.NODE_ENV ||
                process.env.NODE_ENV === 'development') && (
                <div className="col-6">
                  <TextButton
                    onClick={() => {
                      setFieldValue('email', faker.internet.email())
                      setFieldValue('firstName', faker.name.firstName())
                      setFieldValue('lastName', faker.name.lastName())
                      setFieldValue('title', faker.name.jobTitle())
                      setFieldValue(
                        'bioHtml',
                        `<p>${faker.name.jobDescriptor()}</p>`
                      )
                      setFieldValue('phone', faker.phone.phoneNumber())
                    }}
                  >
                    Fake this shit
                  </TextButton>
                </div>
              )}
            </div>
          </div>
          <div className="card-block  ml-2">
            <div className="row">
              {isFetching ? (
                <ContextualSpinner />
              ) : (
                <div className="col-12  mt-2 p-4">
                  <div className="row">
                    <div className="col-md-3 col-12 upload-photo">
                      <ImageUploadComponent onUpload={handleImageUpload} />
                    </div>
                    <div className="col-md-8 col-12">
                      <h3>Profile</h3>
                      <hr />
                      <div className="row my-3">
                        <div className="col-6">
                          <Field
                            label="Email"
                            name="email"
                            type="email"
                            placeholder={'name@email.com'}
                            component={CustomInputComponent}
                          />
                        </div>
                        <div className="col-6" id="sendWelcomeEmail">
                          <LabelStyled>Welcome email </LabelStyled>
                          <Tooltip
                            header={`Welcome Email`}
                            tooltipContent={
                              'This will send a welcome email to the new member notifying them of their account!'
                            }
                            buttonText={'Change default'}
                            handleClick={() => {
                              history.push('/community/settings/onboarding')
                            }}
                          >
                            {' '}
                            <FontAwesomeIconLabel icon="question-circle" />
                          </Tooltip>
                          <div className="form-group mb-0">
                            <Switch
                              name="sendWelcomeEmail"
                              value={sendWelcomeEmail}
                              labelRight={
                                sendWelcomeEmail ? 'Send' : "Don't Send"
                              }
                              onChange={handleSendWelcomeEmailToggle}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <Field
                            label="First Name"
                            name="firstName"
                            placeholder={'First Name'}
                            component={CustomInputComponent}
                          />
                        </div>
                        <div className="col-6">
                          <Field
                            label="Last Name"
                            name="lastName"
                            placeholder={'Last Name'}
                            component={CustomInputComponent}
                          />
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-6">
                          <Field
                            label="Title"
                            name="title"
                            placeholder={
                              'Community Manager, Freelance Designer, Developer, etc.'
                            }
                            component={CustomInputComponent}
                          />
                        </div>
                        <div className="col-md-6">
                          <LabelStyled>Join Date</LabelStyled>
                          <DateTime
                            name="userJoinedAt"
                            value={userJoinedAt}
                            timeFormat={false}
                            inputProps={{
                              readOnly: true,
                              style: { backgroundColor: 'white' },
                            }}
                            timeConstraints={{
                              hours: { max: 23, step: 1 },
                              minutes: { max: 59, step: 5 },
                            }}
                            onChange={handleUserJoinedAtChanged}
                          />
                        </div>
                        <div className="col-6">
                          <Field
                            label="Phone"
                            name="phone"
                            placeholder={'555-555-5555'}
                            component={CustomInputComponent}
                          />
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-12">
                          <LabelStyled htmlFor="bio">Bio</LabelStyled>
                          <MyStatefulEditor
                            onChange={bio => {
                              setFieldValue('bio_html', bio)
                            }}
                            content={values.bio_html}
                            placeholder={`Use the bio to show skills, something interesting, or other aspects about this members!`}
                            className={'form-control w-100 '}
                          />
                        </div>
                      </div>
                      <div className="row my-3 d-flex align-items-center">
                        <div className="col-3">
                          <div className="form-group">
                            <LabelStyled>User Type</LabelStyled>
                            <select
                              name="type"
                              disabled={disableMemberTypeDropdown}
                              value={type}
                              onChange={handleInputChange}
                              className={'form-control w-100'}
                              type="text"
                            >
                              {listOfRolesForFrontEnd.map(item => (
                                <option value={item.role}>{item.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-9">
                          <label />
                          <span className="text-muted d-flex align-self-center">
                            Managers can only be added to the staff team.
                            <br />
                            Note: All managers have admin dashboard access!
                          </span>
                        </div>
                      </div>
                      {hasDoorProvider && (
                        <Toggle
                          className="row my-3"
                          isMulti
                          isEditing
                          show={showAccess}
                          name="doorAccess"
                          fieldLabel="Doors"
                          toggleLabel="Add to Kisi"
                          onChange={handleDoorsGrantedChanged}
                          onToggle={handleAccessToggle}
                          placeholder={
                            'Choose which doors this member can access'
                          }
                          value={doorAccess}
                          userKisiAccessDate={accessDate}
                          handleUserAccessDateChanged={
                            handleUserAccessDateChanged
                          }
                          options={
                            doorAccessOptions && [
                              { label: 'All Doors', value: null },
                              ...doorAccessOptions.groups.map(gr => ({
                                label: gr.name,
                                value: gr.id,
                                ...gr,
                              })),
                            ]
                          }
                        />
                      )}
                    </div>
                    <div className="offset-md-3 col-9">
                      <h3 className="pt-2">Team</h3>
                      <hr />
                    </div>
                    <div className="offset-md-3 col-6">
                      <div className="mb-3">
                        <QuickSearch
                          type="Team"
                          title={'Select Team'}
                          team={team}
                          runSearchAndAttemptMatchValue={
                            runSearchAndAttemptMatchValue
                          }
                          handleSearchItemCleared={handleSearchItemCleared}
                          handleSearchItemSelected={handleSearchItemSelected}
                        />
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                      <Button
                        color="secondary"
                        className="mr-3"
                        onClick={goBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={
                          (!dirty && !values.email) ||
                          isSubmitting ||
                          isFetching ||
                          !team ||
                          submittingRequest
                        }
                        className="btn btn-primary"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const NewMemberForm = withFormik({
  mapPropsToValues: props => ({
    email: props.email,
    lastName: props.lastName,
    firstName: props.firstName,
    phone: props.phone,
    doorAccess: [],
    title: '',
    bio: '',
    bio_html: '',
  }),
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required('First name is required.'),
    lastName: Yup.string().required('Last name is required.'),
    email: Yup.string().email().required('Email is required.'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.createMember(values)
    setSubmitting(false)
  },
  displayName: 'NewMemberForm', // helps with React DevTools
})(InnerNewMemberForm)

NewMemberForm.displayName = 'NewMemberForm'
export default NewMemberForm
