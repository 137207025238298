import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import defaultAvatar from '../../../img/placeholders/otto_black_white.jpeg';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';


class CompanyRecipientRow extends Component {
    static propTypes = {
        recipient: PropTypes.object,
        deleteRecipient: PropTypes.func,
        toggleMemberModal: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
      const { recipient } = this.props;
        return (
          <div className='border-bottom p-1 w-100 my-1' key={recipient.id}>
            <div className='row'>
              <div className='col-1 d-flex justify-content-center align-items-center'>
                <FontAwesomeIcon icon={'check'} className='member-plus-icon primary-color'/>
              </div>
              <div className='col-10 pl-0 pr-0'>
                <h5 className='my-0 mb-1'>{`${recipient.name}`}</h5>
                <Button className='btn btn-outline-primary pull-right btn-sm' onClick={() => this.props.toggleMemberModal(recipient)}>
                  {`${recipient.users.length} members`}
                </Button>
              </div>
              <div
                className='col-1 d-flex align-items-center'
                onClick={this.props.deleteRecipient}>
                <FontAwesomeIcon icon={'trash-alt'} className='recipient-delete-icon danger-color' onClick={this.props.deleteRecipient}/>
              </div>
            </div>
          </div>
        );
    }
    
}

CompanyRecipientRow.displayName = 'Company Recipient Row';

export default CompanyRecipientRow;

