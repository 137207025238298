/* eslint-disable react/no-multi-comp*/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { LabelStyled } from '@global/Form/FormComponents'
import renderHTML from 'react-render-html'

import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Badge from '@global/Base/Badge/Badge'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import AvatarNamePlate from '@global/Avatar/AvatarNamePlate'

import classnames from 'classnames'
import {
  Alert,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'
import AttendeeList from './AttendeeList'

const BadgeStyled = styled(Badge)`
  margin-left: 4px;
  padding: 2px 5px;
`
const TabContainer = styled.div`
  display: flex;
`
const TabWrappper = styled.div`
  margin-top: 24px;
`
const TabContentStyled = styled(TabContent)`
  border-left: none;
  border-right: none;
  border-bottom: none;
`

const ENUM = {
  INFO: 'info',
  ATTENDEES: 'attendees',
}

export default function EventPreview({
  history,
  match,
  event,
  calendarRedirect,
}) {
  const [activeTab, setActiveTab] = useState(ENUM.INFO)

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return `${str.slice(0, num)}...`
  }

  //eslint-disable-next-line react/display-name
  const getRedirectLink = () => {
    const { invoice_id, start } = event

    if (calendarRedirect) {
      return (
        <div className="col-4">
          <TextButton
            onClick={() =>
              history.push('/calendar', {
                returnToDate: moment(start).toISOString(),
                event,
              })
            }
          >
            <FontAwesomeIcon icon="external-link-alt" /> See in Calendar
          </TextButton>
        </div>
      )
    } else if (invoice_id) {
      return (
        <div className="col-4">
          <TextButton
            onClick={() => history.push(`/billing/invoices/${invoice_id}`)}
          >
            <FontAwesomeIcon icon="external-link-alt" /> View Invoice
          </TextButton>
        </div>
      )
    }
    return null
  }

  function getCreatedBySource(createdBy) {
    switch (createdBy) {
      case 'External':
        return 'External Booking'
      case 'Member':
        return 'Member App'
      case 'Admin':
        return 'Admin Dashboard'
      case 'WalkUp':
        return 'Booking Tablet'
      default:
        return ''
    }
  }

  const {
    name,
    start,
    end,
    description_html,
    room,
    location,
    notes,
    event_photo,
    user,
    type,
    invoice_id,
    creator,
    contact,
    created_at,
    created_by_source,
    is_archived,
    attendees_count,
  } = event

  const createdBySource = getCreatedBySource(created_by_source)
  const sameDay = moment(start).isSame(moment(end), 'date')

  let duration = moment.duration(moment(end).diff(moment(start))).asHours()
  let unitsString = 'hours'
  if (duration < 1) {
    unitsString = 'minutes'
    duration = moment.duration(moment(end).diff(moment(start))).asMinutes()
  }

  const getOwner = () => (contact ? contact : user)
  const getOwnerName = () => {
    const theOwner = getOwner()
    if (theOwner) {
      return `${theOwner.first_name} ${theOwner.last_name}`
    }
    return ''
  }

  const createdBy = creator && creator.id !== user.id ? creator : user
  const getCreatedBy = () => {
    const createdByOwner = createdBy ? createdBy : contact
    if (createdByOwner) {
      return `${createdByOwner.first_name} ${createdByOwner.last_name}`
    }

    return ''
  }

  const addEventToStack = event => {
    const locationState = {
      event,
      returnToDate: event.start_time,
    }
    history.replace({ state: locationState }) // makes sure the nav back to the calendar brings back the event modal
  }

  const onOwnerClicked = () => {
    if (contact) {
      addEventToStack(event)
      NavigationConstants.navigateToContact({
        history,
        match,
        lead: contact,
      })
    } else {
      addEventToStack(event)
      NavigationConstants.navigateToMember({
        history,
        match,
        member: user,
      })
    }
  }

  const handleMemberClick = member => {
    addEventToStack(event)
    NavigationConstants.navigateToMember({
      history,
      match,
      member,
    })
  }

  const owner = getOwner()
  const createdByName = getCreatedBy()

  const infoContent = () => {
    return (
      <form>
        <div className="row">
          <div className="col-12 my-1">
            {type !== 'Event' && (
              <React.Fragment>
                <LabelStyled>Name</LabelStyled>
                <div className="form-group">
                  <h5>{name}</h5>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="col-8 my-1">
            <div className="row">
              <div className="col-12">
                <LabelStyled>Date & Time</LabelStyled>
                {sameDay ? (
                  <h6>
                    {moment(start).format('ddd, MMM DD, YYYY')}
                    {' at '}
                    {moment(start).format('h:mm')} -{' '}
                    {moment(end).format('hh:mm a')}
                  </h6>
                ) : (
                  <h6>
                    {moment(start).format('ddd, MMM DD, YYYY h:mm a')} - <br />{' '}
                    {moment(end).format('ddd, MMM DD, YYYY h:mm a')}
                  </h6>
                )}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="col-12">
              <LabelStyled>Duration</LabelStyled>
              <h6>
                {Number(duration).toFixed(2)} {unitsString}
              </h6>
            </div>
          </div>
        </div>

        <div className="row my-1">
          <div className="col-12">
            <LabelStyled>{room ? 'Room:' : 'Location:'}</LabelStyled>
            <div className="form-group">
              <h5>{room ? room.name : location}</h5>
            </div>
          </div>
          <div className="col-12">
            <LabelStyled>Created By</LabelStyled>
            <div className="form-group">
              <h6>{createdBySource}</h6>
            </div>
          </div>
        </div>
        {type === 'Event' && (
          <div className="row my-1">
            <div className="col-8">
              <LabelStyled>Number of Attendees</LabelStyled>
              <div className="form-group">
                <h5>{attendees_count}</h5>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 my-1 mb-2">
            <LabelStyled>Description</LabelStyled>
            {description_html ? (
              <h5>{renderHTML(description_html)}</h5>
            ) : (
              <div className="text-muted">None</div>
            )}
          </div>

          <div className="col-8">
            <div className="row">
              <div className="col-12">
                <LabelStyled>Owner</LabelStyled>
                <AvatarNamePlate
                  object={owner}
                  onClick={onOwnerClicked}
                  objectType="user"
                />
              </div>
            </div>
          </div>
          {getRedirectLink()}
          <div className="col-12 my-1">
            {notes ? (
              <div>
                <LabelStyled>Notes</LabelStyled>
                <h6>{truncateString(notes, 500)}</h6>
              </div>
            ) : null}
          </div>

          {type === 'Event' && (
            <div className="col-12 my-1">
              <LabelStyled>Show to Members?</LabelStyled>
              <h5>{event.private ? 'Hide' : 'Show'}</h5>
            </div>
          )}
        </div>
      </form>
    )
  }

  const tabRender = () => {
    if (type !== 'Event') return infoContent()

    const { attendees_count } = event

    return (
      <TabWrappper>
        <div className="form-group">
          <h5>{name}</h5>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === ENUM.INFO })}
              onClick={() => {
                toggle(ENUM.INFO)
              }}
            >
              Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === ENUM.ATTENDEES })}
              onClick={() => {
                toggle(ENUM.ATTENDEES)
              }}
            >
              <TabContainer>
                <span>Attendees</span>
                <BadgeStyled size="small">{attendees_count}</BadgeStyled>
              </TabContainer>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContentStyled activeTab={activeTab}>
          <TabPane tabId={ENUM.INFO}>{infoContent()}</TabPane>
          <TabPane tabId={ENUM.ATTENDEES}>
            <AttendeeList event={event} handleMemberClick={handleMemberClick} />
          </TabPane>
        </TabContentStyled>
      </TabWrappper>
    )
  }

  return (
    <div className="animated fadeIn p-3">
      {is_archived && <Alert color="info">This booking is cancelled.</Alert>}
      <div className="row py-1 border-bottom">
        <div className="col-md-12">
          {event_photo && event_photo.file_url && (
            <img
              src={event_photo.file_url}
              alt={name}
              className="w-100 z-depth-1"
            />
          )}
          {tabRender()}
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-2 text-muted">
          <p>
            Created on {moment(created_at).format('MMM DD, YYYY')} at{' '}
            {moment(created_at).format('hh:mm a')} by {createdByName}
          </p>
        </div>
      </div>
    </div>
  )
}

EventPreview.displayName = 'EventPreview'

EventPreview.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  event: PropTypes.object,
  calendarRedirect: PropTypes.bool,
}
