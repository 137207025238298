import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import { getCreditCardLogo } from '@global/Constants/PaymentConstants'
import Spinner from '../Spinner'
import styled from 'styled-components'

const CreditCardStyled = styled.img`
  max-height: 30px;
  min-height: 10px;
`
export default class PaymentSourceComponent extends Component {
  static propTypes = {
    defaultSourceObject: PropTypes.object, // the source to render
    toggleVerifyBankModal: PropTypes.func, // if you want user to be able to verify bank where this is shown
    isLoadingSource: PropTypes.bool, // a isLoading boolean for when verifying the bank account
  }
  static defaultProps = {
    toggleVerifyBankModal: null,
  }
  render() {
    const {
      defaultSourceObject,
      toggleVerifyBankModal,
      isLoadingSource,
    } = this.props
    if (!defaultSourceObject) {
      return null
    }
    if (isLoadingSource) {
      return <Spinner />
    }
    const source =
      defaultSourceObject.object === 'card' ||
      (defaultSourceObject.last4 &&
        defaultSourceObject.exp_month &&
        defaultSourceObject.brand)
        ? defaultSourceObject
        : defaultSourceObject.card
    if (source) {
      return (
        <React.Fragment>
          <div className="col d-flex align-items-center">
            <CreditCardStyled
              className="w-50"
              src={getCreditCardLogo(source.brand)}
            />
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <h6 className="m-0 p-0">•••• {source.last4}</h6>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <h6 className="m-0 p-0">{`${
              source.exp_month < 10 ? `0${source.exp_month}` : source.exp_month
            }/${source.exp_year}`}</h6>
          </div>
        </React.Fragment>
      )
    } else if (defaultSourceObject.object === 'bank_account') {
      return (
        <React.Fragment>
          {(defaultSourceObject.status === 'new' ||
            defaultSourceObject.status === 'validated') &&
          toggleVerifyBankModal ? (
            <div className="col d-flex justify-content-end">
              <button
                className="btn btn-sm btn-link"
                onClick={toggleVerifyBankModal}
              >
                Verify
              </button>
            </div>
          ) : (
            <div className="col">
              <FontAwesomeIcon icon="university" size="3x" />
            </div>
          )}
          <div className="col mt-2 d-flex align-items-center">
            <h6>
              {defaultSourceObject.bank_name}
              {'    '}••••{defaultSourceObject.last4}
            </h6>
          </div>
        </React.Fragment>
      )
    }
    return null
  }
}

PaymentSourceComponent.displayName = 'PaymentSourceComponent'
