import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import mixpanel from 'mixpanel-browser'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import { MIXPANEL_APP_TOKEN, ENVIRONMENT_NAME } from './axios'

mixpanel.init(MIXPANEL_APP_TOKEN, {
  protocol: 'https',
})

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://ffc90796c2f848c09831188470d0bd60@sentry.io/1208789',
    // eslint-disable-next-line no-undef
    release: BUILD_VERSION,
    environment: ENVIRONMENT_NAME,
    normalizeDepth: 5,
  })
  // eslint-disable-next-line no-undef
  Sentry.setTag('release', BUILD_VERSION)
  Sentry.setTag('environment', ENVIRONMENT_NAME)
  ReactGA.initialize('UA-64111015-3') // Initialize GoogleAnalytics
}

let title
// eslint-disable-next-line no-undef
switch (ENVIRONMENT_NAME) {
  case 'local':
    title = 'Coworks Local'
    break
  case 'testing-stoplight':
    title = 'Coworks Local-Stoplight'
    break
  case 'testing':
    title = 'Coworks Testing'
    break
  case 'staging':
    title = 'Coworks Staging'
    break
  case 'production':
    title = 'Coworks App'
    break
  default:
    title = 'Coworks App'
    break
}
document.title = title

OfflinePluginRuntime.install({
  onUpdateReady: () => {
    console.log('SW Event:', 'onUpdateReady')
    // Tells to new SW to take control immediately
    OfflinePluginRuntime.applyUpdate()
  },
  onUpdated: () => {
    console.log('SW Event:', 'onUpdated')
    // Let the global state know that it needs to reload content on next interation.
    window.swUpdate = true
  },
})

export { mixpanel }
