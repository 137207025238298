import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as stripeActions from '@reduxActions/stripeActions'
import * as teamActions from '@reduxActions/teamActions'
import classnames from 'classnames'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import { Button, TextButton } from '@global/Base/Button/ButtonStyled'

import PillToggle from '@global/Base/Toggle/PillToggle'
import * as PaymentConstants from '@global/Constants/PaymentConstants'
import BillingTable from './BillingTable'

import { Nav, NavItem, NavLink, TabPane } from 'reactstrap'
import TabContent from './TabContent'
import { useRouteMatch } from 'react-router-dom'

import { QueryStringToJSON } from '@global/Utilities/routeUtilities'
function BillingTab({
  history,
  location,
  statusOptions,
  sectionName,
  user,
  teams,
  stripeTeams,
  inactiveTeams,
  team,
  charges,
  invoices,
  stripe_actions,
  team_actions,
  activeCommunity,
  activeCampus,
  isInactive,
  handleLastInvoiceReceived,
  handleTotalInvoiceReceived,
  shouldRefreshPayments,
  getTeamFromStripeObject,
  handleDetailClicked,
  disableActions,
}) {
  const { path, url } = useRouteMatch()

  const query = QueryStringToJSON(location)
  // if (isInactive) {
  //   disableActions = true
  // }
  const filterStatus = status => {
    const approvedStatuses = statusOptions.map(statusOption =>
      statusOption.type.toLowerCase()
    )
    if (!approvedStatuses.includes(status)) return approvedStatuses[0]
    return status
  }
  const [activeTab, setActiveTab] = useState(
    filterStatus(query && query.status ? query.status : null)
  )
  const [showBilling, setShowBilling] = useState(
    Boolean(activeCommunity.stripe_account_id)
  )
  // const [failedTransactionsCount, setFailedTransactionsCount] = useState(null)
  // const [shouldRefresh, setShouldRefresh] = useState(false)

  const toggleTab = status => {
    history.push(`${url}?status=${status}`, { team })
  }

  return (
    <div className="animated fadeIn">
      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="row">
            <div className="col-md-5 col-12 mb-3">
              {statusOptions.map(statusOption => {
                return (
                  <PillToggle
                    className={classnames({
                      active: activeTab === statusOption.type.toLowerCase(),
                    })}
                    onClick={() => {
                      toggleTab(statusOption.type.toLowerCase())
                    }}
                  >
                    {`${statusOption.type} `}
                    {statusOption.objectCount &&
                      `(${statusOption.objectCount})`}
                  </PillToggle>
                )
              })}
            </div>
            <div className="col-md-4 col-lg-4 col-xl-5 col-6 d-flex align-items-center justify-content-end">
              <TextButton
                color={'primary'}
                className="m-0 p-0"
                onClick={() => {
                  history.push('/reports/billing')
                }}
              >
                <FontAwesomeIcon icon={'book'} /> Billing Report
              </TextButton>
            </div>
            <div className="col-sm-3 col-lg-3 col-xl-2 col-6 d-flex align-items-center justify-content-end">
              <Button
                id="new-invoice"
                color="primary"
                onClick={() =>
                  PaymentConstants.handleCreateClicked(team, history, 'charge')
                }
                disabled={disableActions}
              >
                <FontAwesomeIcon icon="dollar-sign" /> New Invoice
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TabContent activeTab={activeTab}>
                {statusOptions.map(statusOption => {
                  if (activeTab !== statusOption.type.toLowerCase()) return null
                  return (
                    <TabPane tabId={statusOption.type.toLowerCase()}>
                      <BillingTable
                        type={statusOption.type}
                        apiParams={statusOption.apiParams}
                        isLoading={statusOption.isLoading}
                        objectName={`${sectionName}s`}
                        apiQuery={statusOption.apiQuery}
                        stripeData={statusOption.data}
                        dataResolver={statusOption.dataResolver}
                        setCount={statusOption.setCount}
                        shouldRefreshPayments={shouldRefreshPayments}
                        team={team}
                        history={history}
                        getTeamFromStripeObject={getTeamFromStripeObject}
                        handleLastInvoiceReceived={handleLastInvoiceReceived}
                        handleDetailClicked={handleDetailClicked}
                        disableActions={disableActions}
                        tooltipDescription={statusOptions.tooltipDescription}
                        isInvoices={sectionName === 'Invoices'}
                      />
                    </TabPane>
                  )
                })}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BillingTab.displayName = 'BillingTab'
BillingTab.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  statusOptions: PropTypes.object,
  sectionName: PropTypes.string,
  location: PropTypes.object,
  user: PropTypes.object,
  teams: PropTypes.array,
  stripeTeams: PropTypes.array,
  inactiveTeams: PropTypes.array,
  team: PropTypes.object, // this is for when this form is embeded in a team's manage page
  charges: PropTypes.array,
  invoices: PropTypes.array,

  stripe_actions: PropTypes.object,
  team_actions: PropTypes.object,
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  isInactive: PropTypes.bool, // determines whther or not the reduxActions should be disabled.
  handleLastInvoiceReceived: PropTypes.func, // this helps parent know the last charge price
  handleTotalInvoiceReceived: PropTypes.func, // this helps parent know the total charge price
  shouldRefreshPayments: PropTypes.bool,
  getTeamFromStripeObject: PropTypes.func,
  setUnpaidInvoicesCount: PropTypes.func,
  handleDetailClicked: PropTypes.func,
  disableActions: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    user: state.user,
    charges: state.stripe.charges,
    invoices: state.stripe.invoices,
    teams: state.teams.teams,
    stripeTeams: state.stripe.teams,
    inactiveTeams: state.teams.inactive,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    stripe_actions: bindActionCreators(stripeActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingTab)
