import React from 'react'
import PropTypes from 'prop-types'
import { LabelStyled } from '@global/Form/FormComponents'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Switch from '@global/Base/Switch/Switch'

export default function PinnedFields({ isPinned, handlePinnedStatusChange }) {
  return (
    <div className="row">
      <div className="col-12">
        <LabelStyled className="pt-2">
          <FontAwesomeIcon icon={'thumbtack'} size={'1x'} /> Pin it
        </LabelStyled>
        <Switch
          value={isPinned}
          labelRight={isPinned ? 'Pinned' : 'Not Pinned'}
          onChange={handlePinnedStatusChange}
        />
        {/* inactiveLabel={''} */}
        {/* activeLabel={<FontAwesomeIcon icon={'thumbtack'} />} */}
      </div>
    </div>
  )
}

PinnedFields.propTypes = {
  isPinned: PropTypes.string,
  handlePinnedStatusChange: PropTypes.func.isRequired,
}

PinnedFields.displayName = 'PinnedFields'
