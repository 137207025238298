/* eslint-disable react/no-multi-comp,react/display-name */
import React, { Component } from 'react'
import styled from 'styled-components'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import { Button } from 'reactstrap'

const ButtonFactory = ({ buttons }) => {
  const createButton = button => {
    switch (button.type) {
      case 'link':
        return <TextButton {...button}>{button.text}</TextButton>
      case 'styled':
        return <ButtonStyled {...button}>{button.text}</ButtonStyled>
      default:
        return <Button {...button}>{button.text}</Button>
    }
  }

  const retrieveButtons = () =>
    buttons.map((item, index) => {
      return createButton(item)
    })

  return retrieveButtons()
}

ButtonFactory.displayname = 'ButtonFactory'

export default ButtonFactory
