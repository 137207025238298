/* eslint-disable react/prop-types,no-trailing-spaces */
import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { getCustomerDefaultSource } from '@global/Constants/PaymentConstants'

import PaymentSourceComponent from '@global/Stripe/PaymentSourceComponent'

const ChangeBillingTypeModal = ({
  subscriptionToChangeBillingType,
  showChangeBillingTypeModal,
  customer,
  toggleChangeBillingTypeModal,
  handleChangeBillingType,
}) => {
  const proposed =
    subscriptionToChangeBillingType.billing === 'charge_automatically'
      ? 'send_invoice'
      : 'charge_automatically'
  const defaultSource = getCustomerDefaultSource(customer)
  return (
    <Modal
      isOpen={showChangeBillingTypeModal}
      toggle={toggleChangeBillingTypeModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleChangeBillingTypeModal}>
        Change Billing Type
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col text-center">
              <strong>
                Are you sure you want to
                <span className="text-primary">
                  {' '}
                  {proposed === 'charge_automatically'
                    ? 'enable'
                    : 'disable'}{' '}
                </span>
                automatic drafts?
              </strong>
              <p>
                <small>
                  {proposed === 'charge_automatically'
                    ? 'This will automatically draft from:'
                    : ' This will require the member to manually pay invoices.'}
                </small>
              </p>
            </div>
          </div>

          {proposed === 'charge_automatically' && (
            <React.Fragment>
              <div className="row">
                {defaultSource ? (
                  <PaymentSourceComponent defaultSourceObject={defaultSource} />
                ) : (
                  <div className="col text-center">
                    There is no default payment source. Please add one.
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-sm btn-secondary"
          onClick={toggleChangeBillingTypeModal}
        >
          Cancel
        </Button>
        <Button
          className="btn btn-sm btn-primary"
          disabled={Boolean(!defaultSource)}
          onClick={() =>
            handleChangeBillingType(subscriptionToChangeBillingType)
          }
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  )
}
ChangeBillingTypeModal.displayName = 'ChangeBillingTypeModal'
export default ChangeBillingTypeModal
