/* eslint-disable react/display-name,react/no-multi-comp,max-params */
import React from 'react'
import * as Constants from '.'
import moment from 'moment'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Row } from 'reactstrap'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import otto from '../../../img/placeholders/otto_black_white.jpeg'
import Callout from '@global/Base/Callout/Callout'
import AvatarNamePlate from '@global/Avatar/AvatarNamePlate'

export function planSuggestionModel(plan) {
  return {
    filter: plan.name,
    listItem: plan,
  }
}
export function renderPlanSuggestion(plan) {
  return <AvatarNamePlate objectType="plan" object={plan} />
}

export function renderInputComponent(inputProps) {
  return (
    <div>
      <div className="input-group">
        <input type="text" className="form-control" {...inputProps} />
        <span className="input-group-addon">
          <FontAwesomeIcon icon="search" />
        </span>
      </div>
    </div>
  )
}

export function renderDiscountBadge(
  discount,
  coupon,
  expirationDate,
  stripeSubscription,
  showAddDiscountModalForSub,
  deleteDiscount,
  symbol
) {
  if (!discount || !coupon) {
    return (
      <div className="col-md-6 d-flex justify-content-end align-items-center">
        <div className="text-muted">
          <TextButton
            className="m-0 p-0"
            onClick={() => showAddDiscountModalForSub(stripeSubscription)}
          >
            <FontAwesomeIcon icon="plus" /> Discount
          </TextButton>
        </div>
      </div>
    )
  }
  return (
    <div className="col-md-6">
      <Callout direction="right">
        <Row>
          <div className="col-12 d-flex align-items-center justify-content-end">
            <small className="text-primary">Discount </small>
          </div>

          <div
            className={`mb-1 col-12 d-flex align-items-center justify-content-end ${
              coupon.name ? '' : 'text-muted'
            }`}
          >
            {coupon.name ? coupon.name : 'No name'}
          </div>
          <div className="col-12 d-flex align-items-center justify-content-end">
            <h5 className="mb-1 mr-1">
              {symbol}
              {Number(coupon.amount_off).toFixed(2)} off
            </h5>
            {coupon.valid && expirationDate !== 'Expired' ? (
              <span>
                <span className="badge badge-primary badge-pill mb-2">
                  {expirationDate}
                </span>
              </span>
            ) : (
              <span className="badge badge-danger badge-pill mb-2">
                Expired
              </span>
            )}
          </div>
          <div className="col-12 d-flex align-items-center justify-content-end">
            <TextButton
              className="m-0 mb-1 p-0"
              color="danger"
              onClick={() => deleteDiscount(stripeSubscription)}
            >
              <small>Remove</small>
            </TextButton>
          </div>
        </Row>
      </Callout>
    </div>
  )
}
export function calculateDaysUntilBill(subscription) {
  if (subscription) {
    const startDate = moment() //Default start date to today
    const billDate = moment.unix(subscription.current_period_end)
    return billDate.diff(startDate, 'days')
  }
  return ''
}

export function calculuateProratedAmount(stripeSubscription) {
  //Default start date to today
  const dailyRate =
    Constants.calculateSubscriptionTotal(stripeSubscription) /
    moment().daysInMonth()
  return (dailyRate * this.calculateDaysUntilBill(stripeSubscription)).toFixed(
    2
  )
}

export function addAllSubscriptionTotals(subscriptions) {
  let total = 0
  subscriptions.map((subscription, index) => {
    total += Constants.calculateSubscriptionTotal(subscription)
  })
  return total
}

export function formatCoworksPlansForUI({ teamsPlans, isStripeConnected }) {
  // This function is shared logic for preparing Coworks plans as gathered from
  // props.team.plans...
  // Note: It will only display free plans if stripe is connected
  if (isStripeConnected === undefined || isStripeConnected === null) {
    // eslint-disable-next-line no-param-reassign
    isStripeConnected = false
  }
  if (!teamsPlans) {
    return null
  }
  const plans = []
  const teamPlans = JSON.parse(JSON.stringify(teamsPlans))
  // give coworks plans a quantity.
  for (let planCounter = 0; planCounter < teamPlans.length; planCounter++) {
    teamPlans[planCounter].quantity = 1
  }
  for (let counter = 0; counter < teamPlans.length; counter++) {
    if (isStripeConnected) {
      // This is for when billing is turned on!
      // eslint-disable-next-line radix
      if (parseInt(teamPlans[counter].cost) === 0) {
        // need to check to see if coworks plans can stack.
        let found = false
        for (let c2 = 0; c2 < plans.length; c2++) {
          // eslint-disable-next-line max-depth
          if (plans[c2].id === teamPlans[counter].id) {
            plans[c2].quantity += 1
            found = true
          }
        }
        // duplicates of the same plan wasn't found in team's plan relationships so no stacking needed.
        if (!found) {
          plans.push(teamPlans[counter])
        }
      } else {
        // showing stripe stuff and don't include the paid coworks plans
        // they are rendered by the renderSubscription method
      }
    } else {
      // billing is disabled and we want to show all coworks plans regardless of cost.
      // need to check to see if coworks plans can stack.
      let found = false
      for (let c2 = 0; c2 < plans.length; c2++) {
        if (plans[c2].id === teamPlans[counter].id) {
          plans[c2].quantity += 1
          found = true
        }
      }
      // duplicates of the same plan wasn't found in team's plan relationships so no stacking needed.
      if (!found) {
        plans.push(teamPlans[counter])
      }
    }
  }
  if (plans.length === 0) {
    return null
  }
  return plans
}

// This function takes a subscriptionCart array and parses out the plan quantities and the planIds for
//    the create subscription call.
export function formatPlansForApi(subscriptionCart) {
  const plans = []
  for (let counter = 0; counter < subscriptionCart.length; counter++) {
    const cartItem = subscriptionCart[counter]
    try {
      // eslint-disable-next-line radix
      const quantity = parseInt(cartItem.uiState.quantity)
      plans.push({
        id: cartItem.plan.id,
        quantity: quantity,
      })
    } catch (err) {
      //todo this should be converted int he validation phase
      console.error('error converting int', err)
    }
  }
  return plans
}

export function getDaysInMonth() {
  const days = []
  for (let i = 1; i <= 31; i++) {
    switch (i) {
      case 1:
      case 21:
      case 31:
        days.push({ name: `${i}st`, value: i })
        break
      case 2:
      case 22:
        days.push({ name: `${i}nd`, value: i })
        break
      case 3:
      case 23:
        days.push({ name: `${i}rd`, value: i })
        break
      default:
        days.push({ name: `${i}th`, value: i })
        break
    }
  }
  return days
}
