import React, { useState, useEffect, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as teamActions from '../../reduxActions/teamActions'
import * as userActions from '../../reduxActions/userActions'
import * as leadActions from '../../reduxActions/leadActions'
import * as integrationActions from '../../reduxActions/integrationActions'
import Staff from './Staff/Staff'
import PendingMembers from './Pending/PendingMembers'

// import Guests from './Guests/Guests'
import { logM, logO, resolveActiveTab } from '@global/Constants'
import { checkDoorProvider } from '@app/components/Community/Integrations/IntegrationConstants'
import { PENDING_MEMBER } from '@global/Constants/CRMConstants'
import {
  resolveFetchingStatus,
  FETCHING_PLANS,
  FETCHING_PENDING_MEMBERS,
  FETCHING_DOOR_ACCESS_GROUPS,
} from '@global/Constants/FetchingConstants'
import * as planActions from '../../reduxActions/planActions'

import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import Badge from '@global/Base/Badge/Badge'
import TeamsContainer from './Teams/TeamsContainer'
import MembersContainer from './Members/MembersContainer'
function Directory({
  match,
  history,
  location,
  plans,
  activeCommunity,
  activeCampus,
  plan_actions,
  lead_actions,
  integration_actions,
  isFetchingPlans,
  isFetchingPendingMembers,
  isFetchingDoorAccessGroups,
  doorAccessGroups,
  integrations,
  pendingMembers,
  role,
}) {
  const isOnDirectory = useCallback(() => {
    const path = location.pathname
    return (
      path === '/directory/teams' ||
      path === '/directory/members' ||
      path === '/directory/staff' ||
      path === '/directory/pending'
    )
  }, [location.pathname])

  useEffect(() => {
    if (isOnDirectory()) {
      if (!isFetchingPlans && ((plans && plans.length === 0) || !plans)) {
        plan_actions.getPlans(activeCampus.id, FETCHING_PLANS)
      }
      if (
        checkDoorProvider(integrations, activeCampus) &&
        activeCommunity &&
        !isFetchingDoorAccessGroups &&
        !doorAccessGroups
      ) {
        // only fetch if its null, otherwise, let validate token call fetch (refresh)
        integration_actions.getDoorAccessGroups(
          { campus_id: activeCampus.id, community_id: activeCommunity.id },
          FETCHING_DOOR_ACCESS_GROUPS
        )
      }
      // // Only make the call if the show_member_migration community preference setting is ons
      if (
        !isFetchingPlans &&
        activeCommunity.community_preference &&
        activeCommunity.community_preference.show_member_migration
      ) {
        lead_actions.getPendingMembers(activeCommunity.id, {
          type: 'PendingMember',
          status: 'active',
        })
      }
    }
  }, [
    isOnDirectory,
    activeCampus,
    activeCommunity,
    doorAccessGroups,
    integration_actions,
    integrations,
    isFetchingDoorAccessGroups,
    isFetchingPlans,
    lead_actions,
    plan_actions,
    plans,
  ])

  function toggleTab(tab) {
    if (!location.pathname.includes(tab)) {
      history.push(`/directory/${tab}`)
    }
  }

  const tabs = [
    { label: 'Members', name: 'members' },
    { label: 'Teams', name: 'teams' },
    { label: 'Staff', name: 'staff' },
    //{ name: 'Guests'},
  ]
  if (pendingMembers && pendingMembers.length > 0) {
    tabs.push({ name: 'pending', label: 'Pending' })
  }

  const { path, url } = useRouteMatch()
  return (
    <div className="animated fadeIn">
      <div className="row">
        <div className="col-md-12">
          {isOnDirectory() && (
            <Nav tabs>
              {/* Only show tabs when we're on the lists! */}
              {tabs.map((tab, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: location.pathname.includes(tab.name),
                    })}
                    onClick={() => {
                      toggleTab(tab.name)
                    }}
                  >
                    {tab.label}{' '}
                    {tab.name === 'pending' && (
                      <Badge size="small">
                        {
                          pendingMembers.filter(
                            contact =>
                              !contact.is_archived && !contact.is_converted
                          ).length
                        }
                      </Badge>
                    )}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          )}
          <Switch>
            <Route exact path={`${path}`}>
              <Redirect to={`${path}/members`} />
            </Route>
            <Route path={`${path}/members`}>
              <MembersContainer
                role={role}
                match={match}
                history={history}
                location={location}
              />
            </Route>
            <Route path={`${path}/teams`}>
              <TeamsContainer
                role={role}
                match={match}
                history={history}
                location={location}
              />
            </Route>

            <Route exact path={`${path}/staff`}>
              <TabContent>
                <TabPane>
                  <Staff
                    role={role}
                    match={match}
                    history={history}
                    location={location}
                  />
                </TabPane>
              </TabContent>
            </Route>
            <Route exact path={`${path}/pending`}>
              <TabContent>
                <TabPane>
                  <PendingMembers role={role} match={match} history={history} />
                </TabPane>
              </TabContent>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

Directory.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  team_actions: PropTypes.object,
  plan_actions: PropTypes.object,
  user_actions: PropTypes.object,
  lead_actions: PropTypes.object,
  integration_actions: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  plans: PropTypes.array,
  pendingMembers: PropTypes.array,
  doorAccessGroups: PropTypes.array,
  role: PropTypes.string,
  isFetchingPendingMembers: PropTypes.bool,
  isFetchingDoorAccessGroups: PropTypes.bool,
  isFetchingPlans: PropTypes.bool,
  integrations: PropTypes.object,
}
Directory.displayName = 'Directory'

const mapStateToProps = state => {
  return {
    plans: state.plans,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    teams: state.teams.teams,
    pendingMembers:
      state.leads && state.leads.pendingMembers
        ? state.leads.pendingMembers
        : [],
    isFetchingPlans: resolveFetchingStatus(state, FETCHING_PLANS),
    isFetchingPendingMembers: resolveFetchingStatus(
      state,
      FETCHING_PENDING_MEMBERS
    ),
    isFetchingDoorAccessGroups: resolveFetchingStatus(
      state,
      FETCHING_DOOR_ACCESS_GROUPS
    ),
    doorAccessGroups:
      state.integrations &&
      state.integrations.doorAccess &&
      state.integrations.doorAccess.groups,
    integrations: state.integrations,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    team_actions: bindActionCreators(teamActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    plan_actions: bindActionCreators(planActions, dispatch),
    lead_actions: bindActionCreators(leadActions, dispatch),
    integration_actions: bindActionCreators(integrationActions, dispatch),
  }
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(Directory))
