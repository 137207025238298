import { SET_STAFF } from '../reduxActions/actionTypes'

export default function(staff = [], action) {
  switch (action.type) {
    case SET_STAFF:
      return { ...staff, team: action.staff, meta: action.meta }
    default:
      return staff
  }
}
