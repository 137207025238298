/* eslint-disable camelcase */
/* eslint-disable no-invalid-this */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as resourceActions from '../../reduxActions/resourceActions'
import { Button, Row } from 'reactstrap'
import PinnedFields from './PinnedFields'

import { toast } from 'react-toastify'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { CustomInputComponent } from '@global/Form/FormComponents'

class ResourceFormTypeForm extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    resource: PropTypes.object,
    reset: PropTypes.func,
    resource_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    archiveResource: PropTypes.func,
    renderCustomDropdowns: PropTypes.func,
    customTagState: PropTypes.array,
    customTagsDirty: PropTypes.bool,
    goBack: PropTypes.function,
  }
  constructor(props, context) {
    super(props, context)
    let name = ''
    let isPinned = false
    let buttonText = 'Create Resource'
    let resource = {}
    let isEditing = false
    let description = ''
    let formButtonText = 'Submit'
    let formEmailRecipient = ''
    // let formPlaceholderText = '';
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.resource
    ) {
      resource = this.props.history.location.state.resource
      name = resource.name
      if (resource.is_pinned) {
        isPinned = true
      }
      buttonText = 'Update Resource'
      isEditing = true
      description = resource.description
      formButtonText = resource.form_button_text
      formEmailRecipient = resource.form_email_recipient
      // formPlaceholderText = resource.form_placeholder_text;
    }
    this.state = {
      resource,
      name,
      description,
      isPinned,
      buttonText,
      isEditing,
      formButtonText,
      formEmailRecipient,
      // formPlaceholderText,
      customTagsDirty: this.props.customTagsDirty,
    }
  }
  createResource = values => {
    let customTagState = this.props.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    customTagState = JSON.stringify(customTagState)
    this.props.resource_actions
      .createResource({
        name: values.name,
        type: 'Form',
        description: values.description,
        form_button_text: values.form_button_text,
        form_email_recipient: values.form_email_recipient,
        is_pinned: values.isPinned,
        campus_id: this.props.activeCampus.id,
        custom_tag_state: customTagState,
      })
      .then(() => {
        this.props.history.push('/resources')
        toast.success('Created the resource.')
      })
      .catch(err => {
        toast.error('Failed to created the resource.')
      })
  }

  updateResource = values => {
    let customTagState = this.props.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    customTagState = JSON.stringify(customTagState)
    this.props.resource_actions
      .updateResource({
        id: this.props.resource.id,
        name: values.name,
        description: values.description,
        form_button_text: values.form_button_text,
        form_email_recipient: values.form_email_recipient,
        type: 'Form',
        is_pinned: values.isPinned,
        campus_id: this.props.activeCampus.id,
        custom_tag_state: customTagState,
      })
      .then(() => {
        this.props.history.push('/resources')
      })
      .catch(err => {
        toast.error('Failed to update the resource.')
      })
  }

  render() {
    return (
      <div className="col-12  ">
        <div className="animated fadeIn">
          <Formik
            initialValues={{
              name: this.state.name,
              description: this.state.description,
              form_email_recipient: this.state.formEmailRecipient,
              // form_placeholder_text: this.state.formPlaceholderText,
              isPinned: this.state.isPinned,
              form_button_text: this.state.formButtonText,
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (this.props.resource) {
                this.updateResource(values)
              } else {
                this.createResource(values)
              }
              setSubmitting(false)
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required.'),
              description: Yup.string().nullable(),
              form_email_recipient: Yup.string()
                .email('This is not a valid email.')
                .required('Email is required.'),
              form_button_text: Yup.string().required(
                'The button text is required.'
              ),
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
                setFieldTouched,
              } = props
              if (errors && errors !== {}) {
                console.log(errors)
              }
              return (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-md-4 col-12">
                      <Field
                        label="Form Name"
                        name="name"
                        placeholder="Enter a name here"
                        component={CustomInputComponent}
                      />
                    </div>
                    <div className="col-md-4 col-12">
                      {PinnedFields({
                        isPinned: values.isPinned,
                        handlePinnedStatusChange: event => {
                          if (!touched.isPinned) {
                            setFieldTouched('isPinned', true, false)
                          }
                          setFieldValue('isPinned', !values.isPinned)
                        },
                      })}
                    </div>
                    <div className="col-md-6 col-12">
                      <Field
                        label="Form Subtitle"
                        name="description"
                        placeholder="Enter the subtitle on the form"
                        component={CustomInputComponent}
                      />
                    </div>
                  </Row>
                  {/* <Row>
                    <div className='col-6'>
                      <Field 
                        label='Form Placeholder Text'
                        name='form_placeholder_text'
                        placeholder='Your placeholder here'
                        component={CustomInputComponent} />
                    </div>
                  </Row> */}
                  <Row>
                    <div className="col-md-6 col-12">
                      <Field
                        label="Who receives the form submissions?"
                        name="form_email_recipient"
                        placeholder="example@email.com"
                        component={CustomInputComponent}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-6 col-12">
                      <Field
                        label="Form Submit Button label"
                        name="form_button_text"
                        placeholder="Submit"
                        component={CustomInputComponent}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-12 col-md-6">
                      {this.props.renderCustomDropdowns()}
                    </div>
                  </Row>
                  <Row>
                    {this.state.isEditing ? (
                      <div className="col-7  d-md-down-none">
                        <Button
                          className="btn btn-danger"
                          onClick={() =>
                            this.props.archiveResource(this.state.resource)
                          }
                        >
                          Archive
                        </Button>
                      </div>
                    ) : null}
                    <div className="col d-flex col justify-content-end">
                      <Button
                        className="btn btn-secondary mr-2"
                        onClick={this.props.goBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                          isSubmitting ||
                          (!dirty && !this.props.customTagsDirty)
                        }
                      >
                        {this.state.buttonText}
                      </Button>
                    </div>
                  </Row>
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

ResourceFormTypeForm.displayName = 'ResourceFormTypeForm'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resource_actions: bindActionCreators(resourceActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceFormTypeForm)
