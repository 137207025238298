/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
// inspired by:  https://github.com/guillaumemorin/react-styled-Switch
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '@global/Colors/colors'

const GlobalContainer = styled.label`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin-bottom: 0rem;

  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  /* justify-content: center; */
`

const InputContainer = styled.label`
  position: relative;
  display: inline-block;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-bottom: 0rem;
  > input {
    display: none;
  }
`

const Input = styled.input`
  &:checked + span {
    background-color: ${props => props.backgroundColorChecked};
  }
  &:disabled + span {
    background-color: ${props => props.backgroundColorUnchecked};
    opacity: ${props => props.theme.disabledOpacity};
    cursor: not-allowed;
  }
  &:disabled:checked + span {
    background-color: ${props => props.backgroundColorChecked};
    opacity: ${props => props.theme.disabledOpacity};
    cursor: not-allowed;
  }
  &:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }
  &:checked + span:before {
    -webkit-transform: translateX(${props => props.translate}px);
    -ms-transform: translateX(${props => props.translate}px);
    transform: translateX(${props => props.translate}px);
  }
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.backgroundColorUnchecked};
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 34px;
  &:before {
    position: relative;
    border-radius: 50%;
    content: '';
    height: ${props => props.sliderHeight}px;
    width: ${props => props.sliderWidth}px;
    left: 4px;
    background-color: ${props => props.backgroundColorButton};
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
`

const Label = styled.span`
  color: #777;
  font-size: 15px;
  font-family: ${props => props.theme.gray} 'Montserrat';
  padding: 0 10px;
  margin-bottom: 0rem !important;
`

const Switch = props => {
  const {
    onChange,
    checked,
    disabled,
    width,
    height,
    translate,
    backgroundColorChecked,
    backgroundColorUnchecked,
    backgroundColorButton,
    name,
    value,
    labelRight,
    labelLeft,
    sliderWidth,
    sliderHeight,
  } = props
  return (
    <GlobalContainer>
      {labelLeft && <Label>{labelLeft}</Label>}
      <InputContainer onChange={onChange} width={width} height={height}>
        <Input
          type="checkbox"
          name={name}
          value={value}
          checked={checked ? checked : value}
          disabled={disabled}
          translate={translate}
          backgroundColorChecked={backgroundColorChecked}
        />
        <Slider
          sliderWidth={sliderWidth}
          sliderHeight={sliderHeight}
          backgroundColorUnchecked={backgroundColorUnchecked}
          backgroundColorButton={backgroundColorButton}
        />
      </InputContainer>
      {labelRight && <Label>{labelRight}</Label>}
    </GlobalContainer>
  )
}

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  backgroundColorChecked: PropTypes.string,
  backgroundColorUnchecked: PropTypes.string,
  backgroundColorButton: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  labelRight: PropTypes.string,
  labelLeft: PropTypes.string,
  sliderWidth: PropTypes.number,
  sliderHeight: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  translate: PropTypes.number,
}

Switch.defaultProps = {
  backgroundColorChecked: colors ? colors.primary : '#28a745', //,
  backgroundColorUnchecked: '#ccc',
  backgroundColorButton: '#fff',
  width: 45,
  height: 25,
  sliderWidth: 15,
  sliderHeight: 15,
  translate: 22,
}

export default Switch
