import { toTitleCase } from './Constants'
import * as Sentry from '@sentry/browser'

// Note: This is the preferred method to use for ALL coworks api endpoint error handling.
// It has stripe built-in and will render stripe errors if it finds that format (before moving on to check for coworks format)
// It should be provided the raw caught error in the .catch(err => extractCoworksErrorObject(err))
// It is setup to respond to several cowork api error message formats.

// format 1: render json: {errors: contact.errors}, status: 422
// format 2: render json: {error: "Here's a sample error"}, status: 422
// format 3: render json: {errors: ["Here's a sample error"]}, status: 422
// format 4: render json: {error: session[:stripe_error], stripe_error: session[:stripe_error_object]}, status: 422
export function extractCoworksErrorObject(error, options = {}) {
  if (error.code && error.code === 'ECONNABORTED') {
    // this is like timeouts or something where we don't control the format
    return ['Your action failed because there was a timeout.', { ...error }]
  }
  if (error)
    if (error && error.response && error.response.data) {
      // This function will take a response from the coworks api and attempt to extract the error.
      // checks the status code (aka. 404 and returns a default message)
      const defaultErrorStatusMessage = convertErrorStatusToText(
        error.response.status
      )
      if (defaultErrorStatusMessage) {
        // this will look at error status codes to see if we have a catch-all message
        // This should eventually check against a base of coworks error codes, but will process 404's and such for now.
        console.error(defaultErrorStatusMessage)
        console.error(error.response.data.message)
        if (options.newFormat) {
          return [defaultErrorStatusMessage, {}]
        }
        return defaultErrorStatusMessage
      }
      // continue on to parse the stripe error
      if (error.response.data.stripe_error) {
        const stripeErrorObject = extractStripeErrorObject(error)
        if (stripeErrorObject) {
          return convertStripeCodeToText(
            stripeErrorObject.code
              ? stripeErrorObject.code
              : stripeErrorObject.type,
            stripeErrorObject.message
          )
        }
      }
      // continue on to check for coworks error
      if (error.response.data.error) {
        const err = error.response.data.error
        if (typeof err === 'string') {
          return err
        }
        // New format!
        //  error: {message:'', ...extra}
        if (
          typeof err === 'object' &&
          err.hasOwnProperty('message') &&
          typeof err.message === 'string'
        ) {
          // this returns array so the rest of the context isn't lost
          // example usage
          // const [message, context] = extractCoworksErrorObject(promiseErrorArg)
          return [err.message, { ...err }]
        }
      } else if (error.response.data.errors) {
        // potentially a list of errors... parse this into a string
        return convertCoworksErrorObject(error.response.data.errors)
      }
    }
  return null
}

export function captureException({ text, error }) {
  // Useful for logging to sentry directly.
  if (text) {
    Sentry.captureException(new Error(text))
  } else if (error) {
    if (!(error instanceof Error)) {
      Sentry.captureException(new Error(error))
    } else {
      Sentry.captureException(error)
    }
  } else {
    Sentry.captureException(
      new Error(
        "Tried to capture exception, but didn't receive any useful params"
      )
    )
  }
  console.error(text, error)
}

export function convertStripeErrorToMessage(error) {
  console.log(error)
  // This method will take the error reponse from coworks api and convert it into ui text for the user.
  let message =
    'There was an error. Please contact Coworks Support at support@coworksapp.com'
  const stripeErrorObject = extractStripeErrorObject(error)
  if (stripeErrorObject) {
    message = convertStripeCodeToText(
      stripeErrorObject.code ? stripeErrorObject.code : stripeErrorObject.type,
      stripeErrorObject.message
    )
  } else {
    const stripeError = resolveStripeErrorObject(error)
    if (stripeError) {
      message = stripeError
    }
  }
  return message
}

export function extractStripeErrorObject(error) {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.stripe_error
  ) {
    // Check for nested stripe_error (from Coworks Api)
    return error.response.data.stripe_error
  } else if (error.type && error.code && error.message) {
    // Check for error (from Stripe Api -- Create Token, etc)
    return error
  }
  return null
}

function convertCoworksErrorObject(errors) {
  // converts the api rails object.errors response into something readable.
  let errorString = ''
  if (Array.isArray(errors)) {
    errors.forEach(error => {
      if (errorString !== '') {
        errorString += ', '
      }
      errorString += error
    })
  } else if (typeof errors === 'object') {
    for (const property in errors) {
      if (errors.hasOwnProperty(property)) {
        // eslint-disable-next-line no-param-reassign
        if (errorString !== '') {
          errorString += ', '
        }
        errorString = `${errorString}${
          property.toLowerCase() !== 'base' ? `${toTitleCase(property)} ` : ''
        }${errors[property]}`
      }
    }
  }
  return errorString
}

function convertErrorStatusToText(errorStatus) {
  // ! Note: Codes in here should only be those that we wish to "globally catch."
  // ! If you add code here, it will override any messages nested in the error response
  // !    and return the message in the swtich instead.
  switch (errorStatus) {
    case 404:
      return 'There was a problem finding that resource.'
    case 500:
      return 'There was a problem with the server. The support team has been notified.'
    default:
      return null
  }
}
function convertStripeCodeToText(code, fallbackMessage) {
  const supportTag =
    'Error: Please contact Coworks Support at support@coworksapp.com'
  switch (code) {
    case 'account_country_invalid_address':
      return 'The country of the business address provided does not match the country of the account. Businesses must be located in the same country as the account.'
    case 'account_already_exists':
      return 'The email address provided for the creation of a Stripe account already has an account associated with it'
    case 'account_number_invalid':
      return 'The bank account number provided is invalid (e.g., missing digits). '
    // case 'account_invalid':  // we want default error
    //   return 'The account ID provided as a value for the Stripe-Account header is invalid. ';
    case 'amount_too_large':
      return 'The specified amount is greater than the maximum amount allowed. Use a lower amount and try again.'
    // case 'alipay_upgrade_required':
    //   return '';
    // case 'api_key_expired': // we want default error
    //   return '';
    case 'amount_too_small':
      return 'The specified amount is less than the minimum amount allowed. Use a higher amount and try again.'
    case 'bank_account_exists':
      return 'The bank account provided already exists.'
    case 'balance_insufficient':
      return ''
    case 'bank_account_unverified':
      return 'This bank account is unverified.'
    case 'bank_account_unusable':
      return 'The bank account provided cannot be used for payouts. A different bank account must be used.'
    case 'card_declined':
      return 'The card has been declined.'
    case 'bitcoin_upgrade_required':
      return ''
    case 'charge_already_captured':
      return 'The payment you’re attempting to capture has already been captured.'
    case 'charge_already_refunded':
      return 'This payment has already been refunded.'
    case 'charge_exceeds_source_limit':
      return 'This charge would cause you to exceed your rolling-window processing limit for this source type.'
    case 'charge_disputed':
      return `The charge you’re attempting to refund has been charged back.${supportTag}`
    case 'country_unsupported':
      return 'Your platform attempted to create a custom account in a country that is not yet supported.'
    case 'charge_expired_for_capture':
      return 'The charge cannot be captured as the authorization has expired. '
    case 'customer_max_subscriptions':
      return 'The maximum number of subscriptions for a customer has been reached.'
    case 'coupon_expired':
      return 'The coupon provided for a subscription has expired.'
    case 'expired_card':
      return 'The card has expired. Check the expiration date or use a different card.'
    case 'email_invalid':
      return 'The email address is invalid (e.g., not properly formatted). Check that the email address is properly formatted.'
    case 'incorrect_address':
      return 'The card’s address is incorrect. Check the card’s address or use a different card.'
    // case 'idempotency_key_in_use':
    //   return '';
    case 'incorrect_number':
      return 'The card number is incorrect. Check the card’s number or use a different card.'
    case 'incorrect_cvc':
      return 'The card’s security code is incorrect. Check the card’s security code or use a different card.'
    case 'instant_payouts_unsupported':
      return 'The debit card provided as an external account does not support instant payouts. '
    case 'incorrect_zip':
      return 'The card’s ZIP code is incorrect. Check the card’s ZIP code or use a different card.'
    case 'invalid_charge_amount':
      return 'he specified amount is invalid. The charge amount must be a positive integer in the smallest currency unit, and not exceed the minimum or maximum amount.'
    case 'invalid_card_type':
      return 'The card provided as an external account is not a debit card. Provide a debit card or use a bank account instead.'
    case 'invalid_expiry_month':
      return 'The card’s expiration month is incorrect. Check the expiration date or use a different card.'
    case 'invalid_cvc':
      return 'The card’s security code is invalid. Check the card’s security code or use a different card.'
    case 'invalid_number':
      return 'The card number is invalid. Check the card details or use a different card.'
    case 'invalid_expiry_year':
      return 'The card’s expiration year is incorrect. Check the expiration date or use a different card.'
    case 'invoice_no_customer_line_items':
      return 'An invoice cannot be generated for the specified customer as there are no pending invoice items. '
    case 'invalid_source_usage':
      return 'The source cannot be used because it is not in the correct state (e.g., a charge request is trying to use a source with a pending, failed, or consumed source).'
    case 'invalid_request_error':
      return fallbackMessage
    case 'invoice_not_editable':
      return 'The specified invoice can no longer be edited. '
    case 'invoice_no_subscription_line_items':
      return 'An invoice cannot be generated for the specified subscription as there are no pending invoice items.'
    case 'invoice_upcoming_none':
      return 'There is no upcoming invoice on the specified customer to preview. '
    case 'livemode_mismatch':
      return 'Test and live mode API keys, requests, and objects are only available within the mode they are in.'
    // case 'order_creation_failed':
    //   return '';
    // case 'order_required_settings':
    //   return '';
    // case 'order_status_invalid':
    //   return '';
    // case 'out_of_inventory':
    //   return '';
    // case 'parameter_invalid_empty': //we want defaults
    //   return '';
    // case 'parameter_invalid_integer': //we want defaults
    //   return '';
    // case 'parameter_invalid_string_blank': //we want defaults
    //   return '';
    // case 'parameter_invalid_string_empty': //we want defaults
    //   return '';
    // case 'parameter_missing': //we want defaults
    //   return '';
    // case 'parameter_unknown': //we want defaults
    //   return '';
    // case 'parameters_exclusive': //we want defaults
    //   return '';
    // case 'payment_intent_authentication_failure': //we want defaults
    //   return '';
    // case 'payment_intent_incompatible_payment_method': //we want defaults
    //   return '';
    // case 'payment_intent_invalid_parameter': //we want defaults
    //   return '';
    // case 'payment_intent_payment_attempt_failed': //we want defaults
    //   return '';
    // case 'payment_intent_unexpected_state': //we want defaults
    //   return '';
    case 'payment_method_unactivated':
      return 'The charge cannot be created as the payment method used has not been activated.'
    case 'payment_method_unexpected_state':
      return 'The provided payment method’s state was incompatible with the operation you were trying to perform. '
    case 'payouts_not_allowed':
      return 'Payouts have been disabled on the connected account.'
    // case 'platform_api_key_expired':
    //   return '';
    case 'postal_code_invalid':
      return 'The ZIP code provided was incorrect.'
    case 'processing_error':
      return 'An error occurred while processing the card.'
    // case 'product_inactive':
    //   return '';
    case 'resource_already_exists':
      return `This already exists! ${supportTag}`
    // case 'resource_missing':
    //   return '';
    case 'routing_number_invalid':
      return 'The bank routing number provided is invalid.'
    // case 'secret_key_required':
    //   return '';
    // case 'sepa_unsupported_account':
    //   return '';
    // case 'shipping_calculation_failed':
    //   return '';
    // case 'sku_inactive':
    //   return '';
    // case 'state_unsupported':
    //   return '';
    // case 'tls_version_unsupported':
    //   return '';
    // case 'token_already_used':
    //   return '';
    // case 'token_in_use':
    //   return '';
    // case 'transfers_not_allowed':
    //   return '';
    // case 'upstream_order_creation_failed':
    //   return '';
    // case 'url_invalid':
    //   return '';
    // ----- OAuth keys ------
    case 'access_denied':
      return 'Access was denied.'
    // case 'invalid_scope':
    //   return 'Invalid scope parameter provided.'
    case 'invalid_redirect_uri':
      return 'A redirect link was provided that was either not supported by Coworks or invalid.'
    case 'invalid_request':
      return 'Missing the response_type parameter.'
    // case 'unsupported_response_type':
    //   return 'Unsupported response_type parameter. Currently the only supported response_type is code.'
    default:
      return fallbackMessage
  }
}

// Resolve
function resolveStripeErrorObject(error) {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    return error.response.data.error
  }
  return null
}
