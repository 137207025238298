import * as Sentry from '@sentry/browser'

const setSentryContext = user => {
  if (user && process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: user.email,
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        // eslint-disable-next-line no-undef
        build_version: BUILD_VERSION,
      })
    })
  }
}

export { setSentryContext }
