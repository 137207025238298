/* eslint-disable no-invalid-this */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as campusActions from '../../reduxActions/campusActions'
import * as uiActions from '../../reduxActions/uiActions'
import * as userActions from '../../reduxActions/userActions'
import SignUp from './SignUp'
export class DashboardLoginWrapper extends React.Component {
  static propTypes = {
    campus_actions: PropTypes.object,
    user_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    campuses: PropTypes.array,
    user: PropTypes.object,
    isFetching: PropTypes.bool,
  }
  routeToLogin = () => {
    this.props.history.push('/login')
  }

  render() {
    return (
      <SignUp
        history={this.props.history}
        match={this.props.match}
        location={this.props.location}
        titleText={'Admin Dashboard'}
        // wrappedClassId={'Login'}
        isDashboardLogin
        showCampusPicker
      />
    )
  }
}

DashboardLoginWrapper.displayName = 'DashboardLoginWrapper'

const mapStateToProps = state => {
  return {
    campuses:
      state.ui.activeCommunity && state.ui.activeCommunity.campuses
        ? state.ui.activeCommunity.campuses
        : [],
    user: state.user,
    isFetching: state.ui.isFetching,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    campus_actions: bindActionCreators(campusActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardLoginWrapper)
