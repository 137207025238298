import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, TextButton } from '@global/Base/Button/ButtonStyled'
import { Column } from '@global/Table'
import moment from 'moment'

const Container = styled.div`
  display: flex;
  align-items: center;
`
const ButtonStyled = styled(TextButton)`
  margin: 0;
`

function DateCol({ text, onClick }) {
  return text ? (
    <Column text={text} />
  ) : (
    <Container>
      <ButtonStyled onClick={onClick}>Check Out</ButtonStyled>
    </Container>
  )
}

DateCol.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
}

DateCol.defaultProps = {
  text: null,
}

DateCol.displayName = 'Date Column'

export default DateCol
