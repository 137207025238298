/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from 'reactstrap'
import TaskRow from './TaskRow'

export default function CommentsComponent({
  tasks,
  handleInputChange,
  comment,
  handleSubmitComment,
  loading,
  surroundWithCard,
}) {
  const surroundWithACard =
    surroundWithCard !== undefined ? surroundWithCard : true
  return (
    <div className={surroundWithACard ? 'card card-block' : ''}>
      <div className="row">
        <div className="col-md-12">
          <h3>Notes</h3>
          <hr />
        </div>
      </div>
      {tasks && tasks.length === 0 && (
        <p className="text-muted">Looks like there's no notes.</p>
      )}
      {tasks &&
        tasks.length > 0 &&
        tasks.map((task, index) => TaskRow({ task, index }))}
      <div className="row">
        <div className="col-sm-6">
          <h6>New Note</h6>
          <textarea
            name="comment"
            className="w-100 form-control my-2"
            placeholder={'Write your note here.'}
            onChange={handleInputChange}
            value={comment}
          />
          <Button
            color="primary"
            disabled={loading}
            onClick={handleSubmitComment}
          >
            Add Note
          </Button>
        </div>
      </div>
    </div>
  )
}
CommentsComponent.displayName = 'CommentsComponent'
