/* eslint-disable no-invalid-this,quote-props */
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Modal } from 'reactstrap'
import { bindActionCreators, compose } from 'redux'
import styled from 'styled-components'
import { ThemeProvider, withTheme } from 'styled-components'
import { lightTemplate } from '../../../theme/Theme'
import colors from '@global/Colors/colors'
import { readableColor, lighten } from 'polished'

import {
  FETCHING_ROOMS,
  resolveFetchingStatus,
} from '@global/Constants/FetchingConstants'
import * as bookingActions from '@reduxActions/bookingActions'
import * as externalBookingActions from '@reduxActions/externalBookingsActions'
import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'
import * as Constants from '@global/Constants'

import Spinner from '@global/Spinner'
import ResizeMonitor from '@app/hocs/ResizeMonitor'
import ScheduleTourForm from '../../Tablet/FrontDesk/ScheduleTourForm'
import withPublicCommunityContext from '../withPublicCommunityContext'
import Detail from './Detail'
import { BookingPaymentModal, ConfirmBookingModal } from './Modal'
import Header from './Header'
import RoomList from './RoomList'
import { toast } from 'react-toastify'
import Success from '../../Tablet/FrontDesk/SuccessModal'

import moment from 'moment'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  background-color: rgb(246, 248, 250);

  .DateInput_input__focused {
    border-bottom: 2px solid
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
  }

  .CalendarDay__selected {
    background: ${props =>
      props.color ? props.color : props.theme.colorPrimary};
    color: ${props => props.theme.colorWhite};
    border: 1px double
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
    &:hover {
      background: ${props =>
        lighten(0.1, props.color ? props.color : props.theme.colorPrimary)};
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
    color: ${props => props.theme.colorWhite};
  }
`
const RoomListStyled = styled(RoomList)`
  margin: auto;
  width: 100%;
  height: 100%;
`
const SuccessModalStyled = styled(Modal)`
  margin: auto;
  height: 100%;
  margin-top: -100px;
  display: flex;
  align-items: center;

  @media (max-width: 550px) {
    width: 90vw;
    .success-title {
      font-size: 40px;
    }

    .text-center {
      font-size: 1.2rem;
    }
  }
`

function getDayOfWeek(publicCampus) {
  const hoursCopy = publicCampus.preferences.hours_of_operation
  let selectDay = moment()
  for (let index = 0; index < 7; index++) {
    if (index) selectDay = moment().add(index, 'days')

    const day = selectDay.format('dddd').toLowerCase()
    const dayHours = hoursCopy[day][0]

    if (dayHours.open === 0) continue
    if (!index) {
      const end = Constants.convertMinutesToTime(dayHours.close / 60)
      if (selectDay.isAfter(end)) continue
    }
    break
  }

  return selectDay
}

function createDefaultTimeParams(publicCampus) {
  const date = getDayOfWeek(publicCampus)

  const hoursCopy = publicCampus.preferences.hours_of_operation
  const dayOfWeekObject =
    hoursCopy[date.clone().format('dddd').toLowerCase()][0]

  let start = Constants.convertMinutesToTime(dayOfWeekObject.open / 60)
  let end = Constants.convertMinutesToTime(dayOfWeekObject.close / 60)

  const remainder =
    Constants.EXTERNAL_BOOKING_TIME_INTERVAL -
    (date.minute() % Constants.EXTERNAL_BOOKING_TIME_INTERVAL)
  const startTime = moment(date).add(remainder, 'minutes')

  start =
    start.isBefore(startTime) && end.isAfter(startTime) ? startTime : start

  const endTime = start.clone().add(1, 'hours')
  end = end.isAfter(endTime) ? endTime : end

  return {
    date,
    timeRange: {
      start: start.format('LT'),
      end: end.format('LT'),
    },
  }
}

function ExternalBookings({
  history,
  publicCommunity,
  publicCampus,
  coworks_community_actions,
  match,
  theme,
  breakpoint,
}) {
  const [currentRoom, setRoom] = useState(null)
  const [modalOpen, setModal] = useState(false)
  const [isScheduleTourModalOpen, setScheduleTourModal] = useState(false)
  const [tempDetails, setTempDetails] = useState(null)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [defaultTimeParams, setDefaultTimeParams] = useState({})
  const shortModalTimeout = 4000
  const isMobile = breakpoint === 'mobile'

  useEffect(() => {
    let campus = null
    if (publicCommunity) {
      if (!publicCommunity.stripe_account_id) {
        toast.error("This community doesn't allow bookings")
      }
      if (match.params.campusId) {
        const campusId = Number(match.params.campusId)
        campus = _.find(publicCommunity.campuses, ['id', campusId])
        coworks_community_actions.setPublicCampus(campus)
      } else {
        campus = publicCommunity.campuses[0]
        coworks_community_actions.setPublicCampus(campus)
        history.push(`/book/${campus.id}`)
      }
    }
  }, [
    publicCommunity,
    coworks_community_actions,
    match.params.campusId,
    history,
  ])

  useEffect(() => {
    if (publicCampus) {
      const params = createDefaultTimeParams(publicCampus)
      setBookingDetails({
        available: false,
        ...params,
      })
      setDefaultTimeParams(params)
    }
  }, [publicCampus])

  const toggle = () => setModal(prev => !prev)
  const toggleScheduleTourModal = () => setScheduleTourModal(prev => !prev)

  const selectRoom = room => {
    setRoom(room)
    toggle()
    // history.push(`/book/${currentCampus.id}/${room.id}`)
  }

  const updateBookingDetails = details => {
    const rest = { ...bookingDetails }
    const dup = Object.assign(rest, details)
    setBookingDetails(dup)
  }

  const reset = () => {
    setModal(false)
    updateBookingDetails({ available: false })
    setTempDetails(null)
  }

  const confirmBookingNow = details => {
    updateBookingDetails({ available: true })
    setTempDetails(details)
  }

  const cancelBookingAction = () => {
    updateBookingDetails({ available: false })
    setTempDetails(null)
    toggle()
  }

  const onSuccessfulBooking = () => {
    toggleSuccessModal()
    reset()
  }

  const modifyColors = () => {
    const color = publicCommunity.community_preference.branding_primary_color
    const colorsCopy = Object.assign(colors, { primary: color })

    return colorsCopy
  }

  const toggleSuccessModal = () => {
    if (!isSuccessModalOpen) {
      setIsSuccessModalOpen(true)
      setTimeout(() => {
        setIsSuccessModalOpen(false)
      }, shortModalTimeout)
    } else {
      setIsSuccessModalOpen(false)
    }
  }

  const constructStamp = () => {
    if (_.isEmpty(defaultTimeParams)) {
      return ''
    }
    return `roomlist-${
      publicCampus ? publicCampus.name : ''
    }-${defaultTimeParams.date.format('LL')}-${
      defaultTimeParams.timeRange.start
    }-${defaultTimeParams.timeRange.end}`
  }

  if (!publicCampus || _.isEmpty(defaultTimeParams)) return <Spinner />
  return (
    <ThemeProvider theme={lightTemplate(modifyColors())}>
      <Container>
        <Header
          history={history}
          campus={publicCampus}
          community={publicCommunity}
          toggleScheduleTourModal={toggleScheduleTourModal}
          title="Reserve A Space"
          url="book"
        />
        <Route exact path={`/book/:campusId`}>
          {publicCampus && publicCommunity && (
            <RoomListStyled
              key={constructStamp()}
              breakpoint={breakpoint}
              campus={publicCampus}
              publicCommunity={publicCommunity}
              onSelect={selectRoom}
              timeParams={defaultTimeParams}
              onUpdateBookingCriteria={updateBookingDetails}
              theme={theme}
            />
          )}
        </Route>
        <Route exact path={`/book/:campusId/:roomId`}>
          <Breadcrumb>
            <BreadcrumbItem>
              {publicCampus && (
                <Link to={`/book/${publicCampus.id}`}>Spaces</Link>
              )}
            </BreadcrumbItem>
            <BreadcrumbItem>
              {currentRoom ? currentRoom.name : null}
            </BreadcrumbItem>
          </Breadcrumb>
          <Detail
            room={currentRoom}
            onBook={toggle}
            toggleScheduleTourModal={toggleScheduleTourModal}
          />
        </Route>
        <ConfirmBookingModal
          isOpen={modalOpen && !bookingDetails.available}
          toggle={toggle}
          room={currentRoom}
          activeCommunity={publicCommunity}
          campus={publicCampus}
          fullscreen={isMobile}
          bookingDetails={tempDetails ? tempDetails : bookingDetails}
          onCancel={cancelBookingAction}
          onSubmit={confirmBookingNow}
        />
        <BookingPaymentModal
          isOpen={modalOpen && tempDetails && bookingDetails.available}
          toggle={toggle}
          room={currentRoom}
          fullscreen={isMobile}
          billingEnabled={publicCommunity.stripe_account_id}
          activeCommunity={publicCommunity}
          bookingDetails={tempDetails}
          currentRoom={currentRoom}
          currentCampus={publicCampus}
          onGoBackAction={() => updateBookingDetails({ available: false })}
          onCancel={cancelBookingAction}
          onSuccess={onSuccessfulBooking}
          theme={theme}
        />
        <SuccessModalStyled
          isOpen={isSuccessModalOpen}
          toggle={toggleSuccessModal}
          className="fade in bd-example-modal-lg member-modal"
        >
          {Success({
            whitelabeled: true,
            toggleSuccessModal: () => toggleSuccessModal(),
            successMessage: `Successfully Booked ${
              currentRoom ? currentRoom.name : ''
            }! Check your email for details on your reservation.`,
            successTitle: 'Booked!',
            containerStyles: {
              height: '200px',
            },
          })}
        </SuccessModalStyled>
        <Modal
          isOpen={isScheduleTourModalOpen}
          toggle={toggleScheduleTourModal}
          className="fade in bd-example-modal-lg modal-lg member-modal"
        >
          <div id="BookingAuth" className="request-tour">
            <div
              id="close-modal"
              className="fixed-top"
              aria-hidden="true"
              onClick={toggleScheduleTourModal}
            >
              <span className="mr-2 mt-2 cancel-btn">
                <FontAwesomeIcon icon="times" />
              </span>
            </div>
            <div className="p-4" id="ScheduleTourForm">
              <div className="row">
                <ScheduleTourForm
                  toggleScheduleTourModal={toggleScheduleTourModal}
                  activeCampus={publicCampus}
                  activeCommunity={publicCommunity}
                  publicCommunity={publicCommunity}
                  scheduleTour={() => null}
                  type={'TourRequest'}
                />
              </div>
            </div>
          </div>
        </Modal>
      </Container>
    </ThemeProvider>
  )
}

ExternalBookings.propTypes = {
  history: PropTypes.object,
  booking_actions: PropTypes.object,
  publicCommunity: PropTypes.object.isRequired,
  publicCampus: PropTypes.object.isRequired,
  coworks_community_actions: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  isFetching: PropTypes.bool,
  breakpoint: PropTypes.string,
  theme: PropTypes.object,
}
ExternalBookings.displayName = 'External Bookings'

function mapStateToProps(state, props) {
  return {
    publicCampus: state.ui.publicCampus,
    isFetching: state.ui && state.ui.isFetching ? state.ui.isFetching : false,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    coworks_community_actions: bindActionCreators(
      coworksCommunityActions,
      dispatch
    ),
    booking_actions: bindActionCreators(bookingActions, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPublicCommunityContext(),
  ResizeMonitor()
)(ExternalBookings)
