import React from 'react'
import PropTypes from 'prop-types'
import SidePanel from './OnboardingSidePanel'
import styled from 'styled-components'

const H1 = styled.h1`
margin-bottom: 1rem;
`;
export default function EndOfSignup({ firstName, goToDashboard }) {
  // eslint-disable-line max-len
  return (
    <div>
      <div className="row">
        <SidePanel
          title="Yup, that was it."
          caption="Time to take the chaos out of managing your space."
          isLastStep
        />

        <div className="col-md-5 offset-md-1">
          <H1>You did it {firstName}!</H1>
          <div className="row">
            <p className="col-md-12">What would you like to do next?</p>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between">
              <button
                className="btn btn-primary pull-right"
                onClick={goToDashboard}
              >
                Go to my dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EndOfSignup.propTypes = {
  firstName: PropTypes.string,
  goToDashboard: PropTypes.func,
}

EndOfSignup.displayName = 'EndOfSignup'
