import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { readableColor } from 'polished'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment'

import EventPreview from './EventPreview/EventPreview'
import * as Roles from '@app/config/roles'
import { Button } from '@global/Base/Button/ButtonStyled'

import * as bookingActions from '@reduxActions/bookingActions'
import * as resourceActions from '@reduxActions/resourceActions'

import Spinner from '@global/Spinner'

const ModalHeaderStyled = styled(ModalHeader)`
  ${({ roomColor, theme, isExternal }) => {
    if (roomColor) {
      return css`
        background-color: ${roomColor} !important;
        color: ${theme.colorWhite} !important;
        border-style: ${isExternal ? 'solid' : undefined};
        border-width: ${isExternal ? '0px 0px 0px 3px' : undefined};
        border-color: ${isExternal ? 'black' : undefined};
      `
    }
    return null
  }}
`

function EventModalComponent({
  event,
  canEdit = true,
  booking_actions,
  headerColor,
  fetchFull = true,
  role,
  user,
  isPrimaryOpen,
  togglePrimary,
  history,
  calendarRedirect,
  hideButton,
}) {
  const shouldFetchThick = () => {
    // see https://gitlab.com/Coworks/coworks-client/-/merge_requests/126#note_520420889
    // event.hasOwnProperty('attendees')
    return Boolean(event && !event.attendees)
  }

  const [thicckEvent, setThicckEvent] = useState(event)
  const [fetching, setFetching] = useState(!event || shouldFetchThick())

  let brandingColor =
    thicckEvent && thicckEvent.room && thicckEvent.room.color
      ? thicckEvent.room.color
      : null
  if (headerColor) {
    brandingColor = headerColor
  }

  const parseBooking = event => {
    const name = event.name ? event.name : 'Booking'
    return {
      ...event,
      start: moment(event.start_time).toDate(),
      end: moment(event.end_time).toDate(),
      name,
      allDay: false,
    }
  }

  useEffect(() => {
    if (!fetchFull) {
      setFetching(false)
    } else if (event && shouldFetchThick()) {
      booking_actions
        .getBookingThick(event.id, {
          include_attendees: false,
        })
        .then(res => {
          setThicckEvent(parseBooking(res.booking))
        })
        .catch(err => {
          toast.error('There was a problem loading this booking')
        })
        .finally(() => {
          setFetching(false)
        })
    } else if (!shouldFetchThick()) {
      setThicckEvent(event)
      setFetching(false)
    } else {
      setFetching(false)
    }
  }, [])

  const addEventToStack = event => {
    const locationState = {
      event,
      returnToDate: event.start_time,
    }
    history.replace({ state: locationState }) // makes sure the nav back to the calendar brings back the event modal
  }

  const editEventClicked = () => {
    addEventToStack(thicckEvent)
    history.push(`/calendar/event-detail?${thicckEvent.id}`, {
      event: thicckEvent,
    })
  }
  // eslint-disable-next-line react/display-name, react/no-multi-comp
  const renderEditButton = (role, currentUser, event) => {
    // if you're an admin you can edit no matter what
    // if you're a staff or a member and the booking is yours, you can edit as long as the information
    // on the booking has come through from the api.

    if (hideButton) {
      return null
    }

    if (
      role === Roles.superAdminRole ||
      ((Roles.justSpaceStaffRoles.includes(role) ||
        (role === Roles.memberRole && currentUser.id === event.user.id)) &&
        role &&
        currentUser &&
        event &&
        event.user &&
        canEdit)
    ) {
      return (
        <ModalFooter>
          <Button color="white" onClick={togglePrimary}>
            Cancel
          </Button>
          <Button
            backgroundColor={brandingColor}
            disabled={event.is_archived}
            onClick={editEventClicked}
          >
            Edit
          </Button>
        </ModalFooter>
      )
    }
    return (
      <ModalFooter>
        <Button color="secondary" onClick={togglePrimary}>
          Cancel
        </Button>
      </ModalFooter>
    )
  }
  function getModalTitle() {
    if (thicckEvent) {
      if (thicckEvent.type === 'Event') return Event
      if (thicckEvent.created_by_source === 'External')
        return 'External Booking'
    }
    return 'Booking'
  }

  return (
    <Modal
      isOpen={isPrimaryOpen}
      toggle={togglePrimary}
      className="modal-primary fade in"
    >
      {fetching ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <ModalHeaderStyled
            toggle={togglePrimary}
            roomColor={brandingColor}
            isExternal={
              thicckEvent.created_by_source &&
              thicckEvent.created_by_source === 'External'
            }
          >
            {getModalTitle()}
          </ModalHeaderStyled>
          <ModalBody>
            <EventPreview
              calendarRedirect={calendarRedirect}
              event={thicckEvent}
              history={history}
            />
          </ModalBody>

          {renderEditButton(role, user, thicckEvent)}
        </React.Fragment>
      )}
    </Modal>
  )
}

EventModalComponent.displayName = 'EventModalComponent'

EventModalComponent.propTypes = {
  event: PropTypes.object,
  history: PropTypes.object,
  isPrimaryOpen: PropTypes.bool,
  togglePrimary: PropTypes.func,
  role: PropTypes.string,
  canEdit: PropTypes.bool,
  hideButton: PropTypes.bool,
  calendarRedirect: PropTypes.bool,
  user: PropTypes.object,
  booking_actions: PropTypes.object,
  isFetchingBookingThicck: PropTypes.bool,
  fetchFull: PropTypes.bool,
  headerColor: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
  return {
    booking_actions: bindActionCreators(bookingActions, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(EventModalComponent)
