import React from 'react'
import PropTypes from 'prop-types'
import _RadioGroup from '@bit/coworks.base-components.radio-group'
import styled from 'styled-components'

const Wrapper = styled.div`
  label > div {
    box-sizing: content-box;
  }
`

const LocalRadioGroup = ({ className, ...other }) => {
  return (
    <Wrapper className={className}>
      <_RadioGroup {...other} />
    </Wrapper>
  )
}

LocalRadioGroup.displayName = 'RadioGroup'

LocalRadioGroup.propTypes = {
  className: PropTypes.string,
}

export default LocalRadioGroup
