import React from 'react'
import PropTypes from 'prop-types'
import SidePanel from './OnboardingSidePanel'
import { LabelStyled } from '@global/Form/FormComponents'
import { Input } from 'reactstrap'
import { currencies, countries } from '@global/Constants'
import { H1, Button } from './SharedOnboardingComponents'

const ENTER_KEY = 13

export default class CreateCommunity extends React.Component {
  static propTypes = {
    firstName: PropTypes.string,
    communityName: PropTypes.string,
    campusName: PropTypes.string,
    currency: PropTypes.string,
    country: PropTypes.string,
    numberOfCampuses: PropTypes.string,
    billingEnabled: PropTypes.string,
    existingStripeAccount: PropTypes.string,
    goToNext: PropTypes.func,
    handleInputChange: PropTypes.func,
    formErrors: PropTypes.object,
    formValid: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount() {
    document.getElementById('community-name').focus()
    document.getElementById('community-name').select()
  }

  handleKeyDown(event) {
    switch (event.keyCode) {
      case ENTER_KEY:
        if (this.props.formValid) {
          this.props.goToNext(event)
        }
        break
      default:
        break
    }
  }

  render() {
    return (
      <div onKeyDown={this.handleKeyDown}>
        <div className="row">
          <SidePanel
            title={`Nice to meet you ${this.props.firstName}.`}
            caption="Tell us about your community."
          />

          <div className="col-md-5 offset-md-1">
            <form>
              <H1>Your Community</H1>
              <div className="form-group">
                <p>How many campuses does your community have?</p>
                <LabelStyled>
                  <input
                    className="mr-1"
                    name="numberOfCampuses"
                    type="radio"
                    value="single"
                    checked={this.props.numberOfCampuses === 'single'}
                    onChange={this.props.handleInputChange}
                  />
                  Just one
                </LabelStyled>
                <br />
                <LabelStyled>
                  <input
                    className="mr-1"
                    name="numberOfCampuses"
                    type="radio"
                    value="multiple"
                    checked={this.props.numberOfCampuses === 'multiple'}
                    onChange={this.props.handleInputChange}
                  />
                  More than one
                </LabelStyled>
              </div>

              <div className="form-group">
                <LabelStyled htmlFor="communityName">
                  Name of your coworking community
                </LabelStyled>
                <Input
                  id="community-name"
                  placeholder="Acme Coworking"
                  className="form-control"
                  name="communityName"
                  value={this.props.communityName}
                  onChange={this.props.handleInputChange}
                />
              </div>
              {this.props.numberOfCampuses === 'multiple' ? (
                <div className="form-group">
                  <LabelStyled htmlFor="campusName">
                    Name of your first campus
                  </LabelStyled>
                  <Input
                    placeholder="Acme Coworking Los Angeles"
                    className="form-control"
                    name="campusName"
                    value={this.props.campusName}
                    onChange={this.props.handleInputChange}
                  />
                </div>
              ) : null}

              <div className="form-group">
                <p>Would you like to enable billing?</p>
                <LabelStyled>
                  <input
                    className="mr-1"
                    name="billingEnabled"
                    type="radio"
                    value="true"
                    checked={this.props.billingEnabled === 'true'}
                    onChange={this.props.handleInputChange}
                  />
                  Yes, enable automated billing
                </LabelStyled>
                <br />
                <LabelStyled>
                  <input
                    className="mr-1"
                    name="billingEnabled"
                    type="radio"
                    value="false"
                    checked={this.props.billingEnabled === 'false'}
                    onChange={this.props.handleInputChange}
                  />
                  No, I'll handle it myself
                </LabelStyled>
              </div>
              {this.props.billingEnabled === 'true' && (
                <div className="form-group">
                  <p>Do you have an existing Stripe account?</p>
                  <LabelStyled>
                    <input
                      className="mr-1"
                      name="existingStripeAccount"
                      type="radio"
                      value="true"
                      checked={this.props.existingStripeAccount === 'true'}
                      onChange={this.props.handleInputChange}
                    />
                    Yes
                  </LabelStyled>
                  <br />
                  <LabelStyled>
                    <input
                      className="mr-1"
                      name="existingStripeAccount"
                      type="radio"
                      value="false"
                      checked={this.props.existingStripeAccount === 'false'}
                      onChange={this.props.handleInputChange}
                    />
                    No, create a new one for me
                  </LabelStyled>
                </div>
              )}

              {this.props.billingEnabled === 'true' &&
              this.props.existingStripeAccount === 'false' ? (
                <React.Fragment>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <LabelStyled>Country of Bank Account:</LabelStyled>
                        <select
                          name="country"
                          value={this.props.country}
                          type="text"
                          className={'form-control w-100'}
                          onChange={this.props.handleInputChange}
                        >
                          {countries.map((countryPair, index) => {
                            return (
                              <option value={countryPair.code}>
                                {countryPair.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <LabelStyled>Currency:</LabelStyled>
                        <select
                          name="currency"
                          value={this.props.currency}
                          type="text"
                          className={'form-control w-100'}
                          onChange={this.props.handleInputChange}
                        >
                          {currencies.map((currencyPair, index) => {
                            return (
                              <option value={currencyPair.name}>
                                {currencyPair.label}
                              </option>
                            )
                          })}
                        </select>
                        <p className="text-muted">
                          Note: Currency must be supported in bank account
                          country{' '}
                          <a
                            href="https://stripe.com/docs/payouts#supported-accounts-and-settlement-currencies"
                            target="blank_"
                          >
                            Check here
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <Button
                className={`btn btn-primary pull-right `}
                notComplete={!(this.props.communityName.length >= 1)}
                onClick={this.props.goToNext}
              >
                Next
              </Button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

CreateCommunity.displayName = 'CreateCommunity'
