import React from 'react'
import object from 'lodash'
import PropTypes from 'prop-types'

import PaginatedTable from '@global/Base/Layout/PaginatedTable'

import './announcements.scss'

export default class AnnouncementsTable extends React.Component {
  static propTypes = {
    messages: PropTypes.array,
    onNext: PropTypes.func,
    handlePlaceholderButtonClick: PropTypes.func,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
    renderRow: PropTypes.func,
  }

  displayMessages = () => {
    let messages = []
    if (this.props.messages) {
      messages = this.props.messages
    }

    return (
      <PaginatedTable
        objects={messages}
        canEdit={false}
        type={'Messages'}
        nextPage={this.props.onNext}
        isLoading={this.props.isFetching}
        containerClassName={null}
        wrapperClassName={null}
        totalPages={this.props.pagination && this.props.pagination.total_pages}
        placeholderSubtext2={'Add one by clicking'}
        placeholderButtonText={'new announcement'}
        placeholderHandleClick={this.props.handlePlaceholderButtonClick}
        renderItem={this.props.renderRow}
      />
    )
  }

  render() {
    return (
      <div className="animated fadeIn">
        <table className="table announcement-table">
          <thead className="primary-background d-none d-sm-block ">
            <tr className="font-200 row">
              <th className="font-200 col-md-1" />
              <th className="font-200 col-md-3">Subject / Title</th>
              <th className="font-200 col-md-4">Message</th>
              <th className="font-200 col-md-4">Date</th>
            </tr>
          </thead>
          <tbody>{this.displayMessages()}</tbody>
        </table>
      </div>
    )
  }
}

AnnouncementsTable.displayName = 'AnnouncementsTable'
