import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Spinner from '@global/Spinner'

export default function SpinnerTable({ loading }) {
  return loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <Spinner />
      </div>
    </div>
  ) : null
}
SpinnerTable.propTypes = { loading: PropTypes.bool }
SpinnerTable.displayName = 'SpinnerTable'
