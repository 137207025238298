const currencies = [
  { name: 'USD', label: 'USD - United States Dollar', symbol: '$' },
  { name: 'EUR', label: 'EUR - Euro', symbol: '€' },
  { name: 'GBP', label: 'GBP - Pound Sterling', symbol: '£' },
  { name: 'JPY', label: 'JPY - Japanese Yen', symbol: '¥' },
  { name: 'AUD', label: 'AUD - Australian Dollar', symbol: 'A$' },
  { name: 'CAD', label: 'CAD - Canadian Dollar', symbol: 'C$' },
  { name: 'CHF', label: 'CHF - Swiss Franc', symbol: 'CHF' },
  {
    name: 'CNY',
    label: 'CNY - Renminbi - Peoples Republic of China',
    symbol: '元',
  },
  { name: 'NOK', label: 'NOK - Norwegian Krone', symbol: 'kr' },
  { name: 'MXN', label: 'MXN - Mexican Peso', symbol: '$' },
  { name: 'INR', label: 'INR - Indian Rupee Peso', symbol: '₹' },
  { name: 'KGS', label: 'KGS - Kyrgyzstani Som', symbol: 'С̲' },
  { name: 'MYR', label: 'MYR - Malaysian Ringgit', symbol: 'RM' },
  { name: 'DKK', label: 'DKK - Danish Krone', symbol: 'Kr.' },
  { name: 'SEK', label: 'SEK - Swedish Krona', symbol: 'kr' },
  { name: 'BRL', label: 'BRL - Brazilian Real', symbol: 'R$' },
  { name: 'HKD', label: 'HKD - Hong Kong Dollar', symbol: '$' },
  { name: 'NZD', label: 'NZD - New Zealand Dollar', symbol: '$' },
  { name: 'PLN', label: 'PLN - Polish Złoty', symbol: 'zł' },
  { name: 'SGD', label: 'SGD - Singapore Dollar', symbol: '$' },
  { name: 'ZAR', label: 'ZAR - South African Rand', symbol: 'R' },
]
export default currencies
