/* eslint-disable radix */
/* eslint-disable react/prop-types,no-trailing-spaces,max-params */
import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from 'reactstrap'
import * as Constants from '@global/Constants'
import {
  renderInputComponent,
  renderPlanSuggestion,
  planSuggestionModel,
} from '@global/Constants/TeamBillingConstants'
import { doPlanFrequenciesMatch } from '@global/Constants/PaymentConstants'
import QuickSearch from '@global/Search/QuickSearch'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import PlanRow from '../../../../Plans/PlanRow'
import {
  LabelStyled,
  FontAwesomeIconLabel,
  SingleDatePicker,
} from '@global/Form/FormComponents'

function doSubscriptionFrequenciesMatch(
  subscriptionCart,
  existingSubscription
) {
  if (subscriptionCart && subscriptionCart.length > 0 && existingSubscription) {
    return doPlanFrequenciesMatch(
      subscriptionCart[0].plan,
      existingSubscription.items.data[0].plan
    )
  }
  return true
}
const AddPlanModal = ({
  subscriptionCart,
  stripeSubscriptions,
  showAddPlanDropdown,
  team,
  showPlanAddModal,
  toggleAddPlanModal,
  plans,
  renderSuggestionsContainer,
  handleAutoSuggestChange,
  toggleChangePlanModal,
  handleChangePlanClicked,
  handleExistingPlanQuantityChange,
  shouldProrate,
  existingSubscription,
  newPlanProrateStatus,
  handleInputChange,
  confirmSaveClickedModal,
  previewSubscriptionChangesClicked,
  defaultCurrency,
  conferenceHoursAllowance,
  startDate,
  handleStartDateChanged,
  startDateFocused,
  handleStartDateFocused,
}) => {
  if (!team) {
    return null
  }

  const frequenciesMatch = doSubscriptionFrequenciesMatch(
    subscriptionCart,
    existingSubscription
  )

  return (
    <Modal
      isOpen={showPlanAddModal}
      toggle={toggleAddPlanModal}
      className="modal-primary modal-lg fade in"
    >
      <ModalHeader toggle={toggleAddPlanModal}>
        Add Plan Confirmation
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <strong>Which plan do you want to add to {team.name}?</strong>
              <div className="col">
                <div className="card-null p-3 ">
                  <div className="" style={{ position: 'relative' }}>
                    {showAddPlanDropdown ? (
                      <QuickSearch
                        type="Plan"
                        showTitle={false}
                        handleSearchItemCleared={() =>
                          handleAutoSuggestChange(null)
                        }
                        handleSearchItemSelected={plan =>
                          handleAutoSuggestChange(plan)
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {subscriptionCart && subscriptionCart.length > 0 && (
                <PlanRow
                  defaultCurrency={defaultCurrency}
                  index={0}
                  plan={subscriptionCart[0].plan}
                  quantity={subscriptionCart[0].uiState.quantity}
                  toggleChangePlanModal={toggleChangePlanModal}
                  cost={subscriptionCart[0].plan.cost}
                  totalCost={subscriptionCart[0].totalCost}
                  handleChangePlanClicked={handleChangePlanClicked}
                  handleQuantityChange={handleExistingPlanQuantityChange}
                  size={'small'}
                />
              )}
            </div>
          </div>

          {existingSubscription &&
          !frequenciesMatch &&
          subscriptionCart &&
          subscriptionCart.length > 0 ? (
            <div className="mb-5">
              <div className="mb-2">
                <LabelStyled>
                  When do you want the membership to start?
                </LabelStyled>
              </div>
              <SingleDatePicker
                id="add_plan_start_date"
                onDateChange={handleStartDateChanged}
                // renderCalendarInfo={this.renderDatePresets}
                date={startDate} // momentPropTypes.momentObj or null
                focused={startDateFocused} // PropTypes.bool
                onFocusChange={({ focused }) => handleStartDateFocused(focused)} // PropTypes.func.isRequired
                placeholder={'Start Date'}
              />
            </div>
          ) : null}

          {existingSubscription &&
          shouldProrate &&
          subscriptionCart &&
          subscriptionCart.length > 0 &&
          frequenciesMatch &&
          // eslint-disable-next-line radix
          parseInt(subscriptionCart[0].plan.cost) !== 0 ? (
            <div>
              <div className="row">
                <div className="col">
                  <LabelStyled>
                    Would you like to prorate this for the current billing
                    cycle?
                  </LabelStyled>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4">
                  <div className="border p-3 d-inline">
                    <Button
                      name="newPlanProrateStatus"
                      className={`btn btn-sm d-inline ${
                        newPlanProrateStatus === 'yes'
                          ? 'btn-primary'
                          : 'btn-link text-muted'
                      }`}
                      onClick={handleInputChange}
                    >
                      Prorate
                    </Button>
                    <Button
                      name="newPlanProrateStatus"
                      className={`btn btn-sm d-inline ${
                        newPlanProrateStatus === 'no'
                          ? 'btn-primary'
                          : 'btn-link text-muted'
                      }`}
                      onClick={handleInputChange}
                    >
                      Don't prorate
                    </Button>
                  </div>
                </div>

                <div className="col-8 d-flex align-items-center justify-content-start">
                  <TextButton
                    disabled={!team.stripe_customer_id}
                    className="m-0 p-0"
                    onClick={() =>
                      previewSubscriptionChangesClicked(existingSubscription)
                    }
                  >
                    Preview invoice changes
                  </TextButton>
                </div>
              </div>
              <hr />
              <div className="row mt-2">
                <div className="col-md-8">
                  <LabelStyled>
                    How many conference hours would you like to add?
                  </LabelStyled>
                  <Input
                    className="form-control"
                    name="conferenceHoursAllowance"
                    onChange={handleInputChange}
                    value={conferenceHoursAllowance}
                  />
                </div>
                <div className="col-12">
                  <p className="text-muted">
                    {team.name}'s new conference hours allowance:{' '}
                    {team &&
                      parseInt(
                        team.conference_hours_allowance
                          ? team.conference_hours_allowance
                          : 0
                      ) + parseInt(conferenceHoursAllowance)}{' '}
                    hours
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {!frequenciesMatch && (
            <b>
              <br />
              The frequency of the selected plan doesn't match the membership
              you are adding this plan to.
            </b>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleAddPlanModal}>
          Cancel
        </Button>
        <Button color="primary" onClick={confirmSaveClickedModal}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}
AddPlanModal.displayName = 'AddPlanModal'

export default AddPlanModal
