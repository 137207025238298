import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import { captureException } from '@global/ErrorFactory'
import Spinner from '@global/Spinner'
import * as memberActions from '@reduxActions/memberActions'
import * as teamActions from '@reduxActions/teamActions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { bindActionCreators } from 'redux'
import ChangeTeam from './ChangeTeam'
import Message from './Message'

const ChangeTeamStyled = styled(ChangeTeam)`
  margin-top: 24px;
`

function ChangeTeamModal({
  history,
  match,
  team_actions,
  member_actions,
  member,
  activeCampus,
  team,
  showModal,
  toggleShowModal,
  newAdminId,
  // handleInputChange,
}) {
  let shouldSelectAll = false
  let theCheckedMembers = []
  if (theCheckedMembers) {
    if (team.status === 'inactive' && team.inactive_users) {
      // pre-check the whole inactive list
      theCheckedMembers = team.inactive_users.map(member => member.id)
      shouldSelectAll = true
    } else if (team.admins) {
      // pre-check admins
      theCheckedMembers = team.admins.map(member => member.id)
    }
  }

  const isInactive = Boolean(member.status === 'inactive')
  const [loading, setLoading] = useState(false)
  const [selectedAdminId, setSelectedAdminId] = useState(
    team && team.admins && team.admins.length > 0 ? team.admins[0].id : null
  )
  const [checkedMembers, setCheckedMembers] = useState(theCheckedMembers)
  const [targetTeam, setTargetTeam] = useState(null)

  // we have this state so we can pull a team from the member and useEffect will
  // refetch the customer when this is resolved.
  const [teamCopy, setTeamCopy] = useState(team ? team : null)

  const memberTeams = member && member.teams ? member.teams : null

  useEffect(() => {
    setLoading(true)
    team_actions
      .getTeam(member.teams[0].id, {
        campus_id: activeCampus.id,
        status: 'active',
      })
      .then(team => {
        setTeamCopy(team)
      })
      .catch(error => {
        toast.error(
          'Whoops, looks like there was an error getting the latest member details!'
        )
        captureException({
          text: `Error getting team in archive modal`,
          error,
        })
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCampus.id, memberTeams])

  const viewTeamClicked = team => {
    NavigationConstants.navigateToTeam({
      history: history,
      match: match,
      team,
      tab: 'members',
    })
  }

  const changeTeam = params => {
    setLoading(true)
    member_actions
      .changeTeam(params)
      .then(response => {
        toast.success("Changed member's team.")
        if (response.team) {
          NavigationConstants.navigateToTeam({
            history: history,
            match: match,
            team: response.team,
            tab: 'profile',
          })
        } else {
          toggleShowModal()
        }
      })
      .catch(response => {
        if (response && response.errors) {
          toast.error(response.errors.message)
        } else {
          toast.error('Error saving member')
        }
        captureException({
          text: `Problem changing a members team`,
          response,
        })
      })
      .finally(() => setLoading(false))
  }

  // eslint-disable-next-line react/no-multi-comp,  react/display-name
  function checkReactivateAdmin(id, members) {
    if (members && members.length > 0) {
      for (let index = 0; index < members.length; index++) {
        const element = members[index]
        if (element === id) return true
      }
      return false
    }
    return true
  }
  const onSubmit = () => {
    const hasReplacementAdmins = replacementAdmins.length > 0
    const params = {
      id: member.id,
      campus_id: activeCampus.id,
      current_team_id: team.id,
      archive_team: Boolean(isMemberAdmin && !hasReplacementAdmins),
    }
    if (targetTeam.id) {
      params.new_team_id = targetTeam.id
    } else {
      params.new_team_name = targetTeam.name
    }
    changeTeam(params)
  }

  let replacementAdmins = []
  const isMemberAdmin = Constants.isMemberAdmin(member, team)
  const hasTarget = targetTeam
    ? Boolean(targetTeam.name || targetTeam.id)
    : false
  const hasReactivateAdmin = checkReactivateAdmin(
    selectedAdminId,
    checkedMembers
  )
  if (isMemberAdmin) {
    // to save on some computing
    replacementAdmins = getReplacementAdmins(teamCopy, member)
  }

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleShowModal}
      className="modal-primary fade in bd-example-modal-lg"
    >
      <ModalHeader toggle={toggleShowModal}>{`Change Team`}</ModalHeader>
      <ModalBody className="py-0">
        <div className="row border-bottom py-2">
          <Message
            team={teamCopy}
            member={member}
            isInactive={isInactive}
            isMemberAdmin={isMemberAdmin}
            hasReplacementAdmins={replacementAdmins.length > 0}
            teamMemberComp={() => (
              <ChangeTeamStyled
                team={teamCopy}
                handleTeamChange={setTargetTeam}
              />
            )}
            companyButton={() => (
              <TextButton
                onClick={() => viewTeamClicked(team)}
                className="m-0 p-0"
              >
                team profile.{' '}
              </TextButton>
            )}
          />
          {isInactive && checkedMembers && checkedMembers.length === 0 && (
            <div className="text-danger px-3">
              Warning: You are about to reactivate this team with no members.
            </div>
          )}
          {isInactive && !hasReactivateAdmin && (
            <div className="text-danger px-3">
              Warning: You must select an admin before moving this member.
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end align-items-center">
        <Button color="secondary" onClick={toggleShowModal}>
          Cancel
        </Button>
        <Button
          disabled={Boolean(
            (replacementAdmins &&
              isMemberAdmin &&
              replacementAdmins.length > 0 &&
              newAdminId < 0) ||
              loading ||
              !hasTarget ||
              !hasReactivateAdmin
          )}
          color="success"
          onClick={() => {
            const params = {}
            if (selectedAdminId) {
              params.admin_id = selectedAdminId
            }

            if (checkedMembers && checkedMembers.length >= 0) {
              params.member_ids = JSON.stringify(checkedMembers)
            }
            onSubmit()
          }}
        >
          Change Team
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function getReplacementAdmins(team, member) {
  if (!team.users) {
    return []
  }
  const replacementAdmins = []
  for (let counter = 0; counter < team.users.length; counter++) {
    if (team.users[counter].id !== member.id) {
      // don't add the current admin to the list of replacements
      replacementAdmins.push(team.users[counter])
    }
  }
  return replacementAdmins
}

function renderReplacementAdmins(replacements) {
  return replacements.map((member, index) => (
    <option
      key={index}
      value={member.id}
    >{`${member.first_name} ${member.last_name} - ${member.email}`}</option>
  ))
}

ChangeTeamModal.displayName = 'Change Team Modal'
ChangeTeamModal.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  team_actions: PropTypes.object.isRequired,
  member_actions: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  activeCampus: PropTypes.object.isRequired,
  activeCommunity: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleShowModal: PropTypes.func.isRequired,
  newAdminId: PropTypes.number.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    member_actions: bindActionCreators(memberActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(ChangeTeamModal)
