import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { IconButton } from '@bit/coworks.base-components.button'

import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import fallbackUserAvatar from '../../../img/global/otto_final-16_1024.png'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Lottie from '@global/Lottie/Lottie'

const LottieContainer = styled.div`
  height: 50vh;
`
const IconButtonStyled = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`

export default function SuccessModal({
  whitelabeled,
  successTitle,
  successMessage,
  toggleSuccessModal,
  containerStyles,
}) {
  return (
    <div>
      <ModalHeader>
        <IconButtonStyled
          color="#000"
          size="small"
          icon={['fal', 'times']}
          onClick={toggleSuccessModal}
        />
        {/* <div
          id="close-modal"
          className="fixed-top"
          aria-hidden="true"
          onClick={toggleSuccessModal}
        >
          <span className="mr-2 mt-2 cancel-btn">
            <FontAwesomeIcon icon="times" />
          </span>
        </div> */}
      </ModalHeader>
      <ModalBody className="py-0 mb-md-4 mb-lg-5">
        <div className="row py-2">
          <div className="col-12 mb-2">
            {whitelabeled ? (
              <LottieContainer style={containerStyles}>
                <Lottie type={'checkin'} />
              </LottieContainer>
            ) : (
              <img
                src={fallbackUserAvatar}
                className="mx-auto d-block campus-avatar"
                style={{ width: '22vh' }}
              />
            )}
            <h1 className="mb-2 success-title mt-3">{successTitle}</h1>
            <h4 className="text-center">{successMessage}</h4>
          </div>
        </div>
      </ModalBody>
    </div>
  )
}
// Set default props
SuccessModal.defaultProps = {
  successMessage: '',
  successTitle: 'Success!',
}
// Prop types
SuccessModal.propTypes = {
  toggleSuccessModal: PropTypes.func,
  successMessage: PropTypes.string,
  successTitle: PropTypes.string,
  whitelabeled: PropTypes.string,
  containerStyles: PropTypes.object,
}
SuccessModal.displayName = 'Success'
