/* eslint-disable react/prop-types */
import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

export default function ResendInvoiceModal({
  showResendInvoiceModal,
  toggleResendInvoiceModal,
  renderNotesFields,
  isFetching,
  resendInvoice,
}) {
  return (
    <Modal
      isOpen={showResendInvoiceModal}
      toggle={toggleResendInvoiceModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleResendInvoiceModal}>
        Resend Invoice
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h6>
                This will resend the invoice via email to the customer. If the
                invoice is already paid, it will send a receipt instead.
              </h6>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleResendInvoiceModal}>
          Cancel
        </Button>
        <Button color="primary" disabled={isFetching} onClick={resendInvoice}>
          Resend Invoice
        </Button>
      </ModalFooter>
    </Modal>
  )
}
ResendInvoiceModal.displayName = 'ResendInvoiceModal'
