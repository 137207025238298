/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import styled from 'styled-components'
import { ThemeProvider, withTheme } from 'styled-components'
import colors from '@global/Colors/colors'
import { Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap'
import { Button } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { LabelStyled } from '@global/Form/FormComponents'
import { IconButton } from '@bit/coworks.base-components.button'
import conferencePlaceholder from '../../../../img/placeholders/rooms_placeholder.jpeg'

const Container = styled.div`
  display: flex;
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
`
const Foreground = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 12px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.19) 38.82%,
    rgba(0, 0, 0, 0.61) 100%
  );
`
const InfoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 3;
`
const TopInfo = styled.div``
const BottomInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.19) 38.82%,
    rgba(0, 0, 0, 0.61) 100%
  );
`
const Title = styled.h5`
  display: flex;
  flexx-flow: row nowrap;
  font-weight: bold;
`
const Subtext = styled.h6``
const Available = styled.h6`
  display: ${props => (props.available ? 'flex' : 'none')};
  margin-left: auto;
  font-weight: bold;
  margin-bottom: 0;
  color: ${props => props.theme.colorWarning};
  align-items: flex-end;
`
const Cost = styled.h6`
  font-weight: bold;
  margin-top: -3px;
  flex: 1;
  text-align: center;
`

const RoomCard = ({ room, currencySymbol, img, ...other }) => {
  const { name, type, capacity, external_hourly_rate, available } = room
  let roomType = type
  if (roomType === 'Conference') roomType = 'Conference Room'
  return (
    <Container {...other}>
      <Wrapper src={img}>
        <Foreground>
          <InfoContainer>
            <TopInfo>
              <Title>
                {name}
                <Available available={!available}>Unavailable</Available>
              </Title>
            </TopInfo>
            <BottomInfo>
              <Subtext className="mr-3">{roomType}</Subtext>
              <Subtext>{`Seats ${capacity}`}</Subtext>
              <Cost>{`${currencySymbol}${parseInt(external_hourly_rate, 10)}/hr`}</Cost>
            </BottomInfo>
          </InfoContainer>
        </Foreground>
      </Wrapper>
    </Container>
  )
}

RoomCard.propTypes = {
  room: PropTypes.object,
  img: PropTypes.string,
  currencySymbol: PropTypes.string,
  available: PropTypes.bool,
}

export default RoomCard
