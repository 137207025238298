/* eslint-disable react/no-multi-comp, no-invalid-this */
import React from 'react'
import PropTypes from 'prop-types'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'

function EventLayout({ event }) {
  return (
    <span>
      <strong>{event.name}</strong>
      <br />
      <i className="small">
        {' '}
        {(event.room && event.room.name) || event.location}
      </i>
      <br />
      <span className="small">
        {' '}
        {`${event.user && event.user.first_name} ${
          event.user && event.user.last_name
        }`}
      </span>
    </span>
  )
}
EventLayout.propTypes = { event: PropTypes.object }
EventLayout.displayName = 'EventLayout'

// BigCalendar.setLocalizer(
//   BigCalendar.momentLocalizer(moment)
// );

const localizer = BigCalendar.momentLocalizer(moment)

const formats = {
  dayFormat: (date, culture, localizer) =>
    localizer.format(date, 'ddd, MMM DD', culture),
}

class BigCal extends React.Component {
  static propTypes = {
    events: PropTypes.array,
    handleEventClicked: PropTypes.func,
    handleCalendarDrag: PropTypes.func,
    handleDrillDown: PropTypes.func,
    handleRangeChange: PropTypes.func,
    onView: PropTypes.func,
    viewState: PropTypes.string,
    goToDate: PropTypes.object,
    eventColor: PropTypes.string,
    bookingColor: PropTypes.string,
    calStyling: PropTypes.object,
  }

  eventStyleGetter(event, start, end, isSelected) {
    const style = {
      backgroundColor: event.color,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    }
    if (event.created_by_source === 'External') {
      style.borderLeft = '3px'
      style.borderColor = 'black'
      style.borderStyle = 'solid'
    }
    return {
      style,
    }
  }

  render() {
    const {
      goToDate,
      viewState,
      onView,
      events,
      calStyling,
      handleRangeChange,
      handleEventClicked,
      handleCalendarDrag,
      handleDrillDown,
    } = this.props
    const now = goToDate ? goToDate : moment().toDate()
    return (
      <BigCalendar
        localizer={localizer}
        selectable="ignoreEvents"
        popup
        defaultView={viewState}
        onView={onView}
        events={events}
        style={Object.assign({ height: '70vh' }, calStyling)}
        defaultDate={now}
        scrollToTime={now}
        onRangeChange={handleRangeChange}
        onSelectEvent={handleEventClicked}
        onSelectSlot={handleCalendarDrag}
        getDrilldownView={handleDrillDown}
        eventPropGetter={this.eventStyleGetter}
        formats={formats}
        components={{
          event: EventLayout,
        }}
      />
    )
  }
}

BigCal.displayName = 'BigCal'

export default BigCal
