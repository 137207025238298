/* eslint-disable react/prop-types,react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
} from 'reactstrap'

const Container = styled.div`
`
const ButtonStyled = styled(Button)`
  width: 75px;
`

function ActionCol({
  onClick,
  onDelete,
  onEdit,
}) {
  const [dropdownOpen, setDropdown] = useState(false)

  return (
    <ButtonStyled
      className="btn btn-sm btn-outline-primary d-lg-block d-none"
      onClick={onClick}
    >
      Edit
    </ButtonStyled>
  )
  // return (
  //   <Container>
  //     <ButtonDropdown
  //       isOpen={dropdownOpen}
  //       toggle={() => setDropdown(!dropdownOpen)}
  //     >
  //       <ButtonStyled
  //         className="btn btn-sm btn-outline-primary d-lg-block d-none"
  //         onClick={onClick}
  //       >
  //         View
  //       </ButtonStyled>
  //       <DropdownToggle caret color="outline-primary" />
  //       <DropdownMenu>
  //         <DropdownItem header>Actions</DropdownItem>
  //         <DropdownItem
  //           onClick={onEdit}
  //         >
  //           Edit
  //         </DropdownItem>
  //         <DropdownItem
  //           className="d-sm-block d-none-lg"
  //           onClick={onDelete}
  //         >
  //           Delete
  //         </DropdownItem>
  //       </DropdownMenu>
  //     </ButtonDropdown>
  //   </Container>
  // )
}

ActionCol.propTypes = {
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

ActionCol.displayName = 'Date Column'

export default ActionCol