/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { Button } from 'reactstrap'
import * as _ from 'lodash'

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const ListItem = styled(Button)`
  border: 1px solid ${props => props.theme.colorPrimary} !important;
  margin-right: 12px;

  ${props => {
    if (props.active) {
      return css`
        background-color: ${props => props.theme.colorPrimary} !important;
        color: white !important;
      `
    }
    return null
  }}
`

const OptionSelect = ({ options, value, onChange, ...other }) => {
  const [active, setActive] = useState(value)

  const toggleActive = val => {
    const newArray = [...active]
    const index = active.indexOf(val)
    if (index > -1) {
      newArray.splice(index, 1)
    } else {
      newArray.push(val)
    }
    setActive(newArray)
    onChange(newArray)
  }

  return (
    <Container {...other}>
      {options.map(item => (
        <ListItem
          active={active.includes(item.value)}
          onClick={() => toggleActive(item.value)}
        >
          {item.label}
        </ListItem>
      ))}
    </Container>
  )
}

OptionSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
}
OptionSelect.defaultProps = {
  value: [],
}

OptionSelect.displayName = 'Option Select'

export default OptionSelect
