import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { TextButton } from '@global/Base/Button/ButtonStyled'

import SelectDropdown from '../../../Leads/LeadsTable/FiltersCollection/SelectDropdown'

import TimeFilter from './TimeFilter'
import AttributeFilter from './AttributeFilter'

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
`
const Wrapper = styled.div``
const FilterWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: auto;
  width: 100%;
`
const TextWrapper = styled.div`
  margin-top: 4px;
`
const Subtext = styled.h5``
const ResultsTotal = styled.span``
const TopText = styled.div`
  display: flex;
  flex-flow: row nowrap;

  @media (max-width: 768px) {
    h5 {
      font-size: 14px;
    }
  }
`
const LogoContainer = styled.div``
const TimeContainer = styled.div``
const SearchBarWrapper = styled.div`
  margin-right: auto;
  width: 500px;
`
const ButtonContainer = styled.div``
const LeftFilters = styled.div`
  display: flex;
  flex-flow: row nowrap;

  .input-range__track--active,
  .input-range__slider {
    background: ${props => props.theme.colorPrimary};
  }

  .input-range__slider {
    border: 1px solid ${props => props.theme.colorPrimary};
  }

  .input-range__label--value {
    font-size: 1rem;
    color: #000;
    top: -2.1rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const TextButtonStyled = styled(TextButton)`
  margin: 0;
  padding: 0;
  margin-left: 15px;
  text-decoration: underline;
`

// const DateFilterStyled = styled(DateFilter)`
//   margin: 0 10px;
// `
const TimeFilterStyled = styled(TimeFilter)`
  margin-right: 10px;
  margin-bottom: 10px;
`
const AttributeFilterStyled = styled(AttributeFilter)`
  margin-right: ${props => (props.external ? 'auto' : '20px')};
  @media (max-width: 768px) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`
const SelectStyled = styled(SelectDropdown)`
  z-index: 1;
  @media (max-width: 768px) {
    max-width: 200px;
    margin-bottom: 10px;
  }
`

const Filter = ({
  breakpoint,
  brandColor,
  rooms,
  onChange,
  sortOptions,
  defaultValue,
  currencySymbol,
  radioOptions,
  value,
  theme,
  showAttributeFilter = true,
  showTimeFilter = true,
  external = true,
  queryResultText,
  searchBar,
  ...other
}) => {
  const time = {
    date: value.date,
    timeRange: value.timeRange,
    duration: value.duration,
    isTime: value.isTime,
  }
  const attributes = {
    priceRange: value.priceRange,
    capacity: value.capacity,
    amenities: value.amenities,
    availability: value.availability,
  }

  const sort = value.sort
  // const [timeModal, setTimeModal] = useState(false)
  const isMobile = breakpoint === 'mobile'

  const onSortChange = stuff => {
    onChange({ sort: stuff })
  }
  const onClearTime = () => {
    onChange({
      date: defaultValue.date,
      timeRange: defaultValue.timeRange,
      duration: defaultValue.duration,
      isTime: defaultValue.isTime,
    })
  }
  const onClearAttributes = () => {
    onChange({
      priceRange: defaultValue.priceRange,
      capacity: defaultValue.capacity,
      amenities: defaultValue.amenities,
      availability: defaultValue.availability,
    })
  }

  const getLabel = () => {
    const { date, timeRange, duration, isTime } = time
    if (isTime) {
      return `${date.format('MMMM Do')}, from ${timeRange.start} - ${
        timeRange.end
      }`
    }
    return `${date.format('MMMM Do')}, for ${duration} hours`
  }

  return (
    <Container {...other}>
      <Wrapper>
        <FilterWrapper>
          {searchBar && <SearchBarWrapper>{searchBar}</SearchBarWrapper>}
          {showTimeFilter && (
            <TimeFilterStyled
              fullscreen={isMobile}
              defaultValue={time}
              handleTimeChange={onChange}
              // open={timeModal}
              // onToggle={setTimeModal}
              onClear={onClearTime}
              brandColor={brandColor}
            />
          )}
          {showAttributeFilter && (
            <AttributeFilterStyled
              fullscreen={isMobile}
              sortByOptions={sortOptions}
              defaultValue={attributes}
              handleAttChange={onChange}
              onClear={onClearAttributes}
              brandColor={brandColor}
              currencySymbol={currencySymbol}
              external={external}
              radioOptions={radioOptions}
            />
          )}
          <SelectStyled
            onChange={onSortChange}
            value={sort}
            label={'Sort By'}
            options={sortOptions}
          />
        </FilterWrapper>
        <TextWrapper>
          {/* <TopText>
            <Subtext>
              {getLabel()}
              <TextButtonStyled onClick={() => setTimeModal(true)}>
                change
              </TextButtonStyled>
            </Subtext>
          </TopText> */}
          {queryResultText && (
            <ResultsTotal>{queryResultText(rooms, value)}</ResultsTotal>
          )}
        </TextWrapper>
      </Wrapper>
    </Container>
  )
}

Filter.propTypes = {
  breakpoint: PropTypes.string,
  brandColor: PropTypes.string,
  currencySymbol: PropTypes.string,
  rooms: PropTypes.array,
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  theme: PropTypes.object,
  value: PropTypes.object,
  searchBar: PropTypes.object,
  showTimeFilter: PropTypes.bool,
  external: PropTypes.bool,
  showAttributeFilter: PropTypes.bool,
  sortOptions: PropTypes.array,
  radioOptions: PropTypes.array,
  queryResultText: PropTypes.func,
}

Filter.displayName = 'Filter'

export default Filter
