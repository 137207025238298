/* eslint-disable no-invalid-this,brace-style */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import * as planActions from '@reduxActions/planActions'
import * as uiActions from '@reduxActions/uiActions'
import * as teamActions from '@reduxActions/teamActions'
import * as memberActions from '@reduxActions/memberActions'

import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import { QueryStringToJSON } from '@global/Utilities/routeUtilities'

import Spinner from '@global/Spinner'
import CompanyRow from './TeamRow'
import CompanyRowSearch from './TeamRowSearch'
import QuickSearch from '@global/Search/QuickSearch'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import { Button } from '@global/Base/Button/ButtonStyled'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  resolveFetchingStatus,
  FETCHING_TEAMS,
  FETCHING_TEAMS_INACTIVE,
} from '@global/Constants/FetchingConstants'

const HeaderText = styled.h2`
  font-size: ${props => (props.small ? '1.5rem' : '2rem')};
`

export class Teams extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    plan_actions: PropTypes.object,
    team_actions: PropTypes.object,
    member_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    teams: PropTypes.array,
    inactiveTeams: PropTypes.array,
    plans: PropTypes.array,
    activeCampus: PropTypes.object,
    role: PropTypes.string,
    pagination: PropTypes.object,
    inactivePagination: PropTypes.object,
    isFetching: PropTypes.bool,
    isFetchingInactive: PropTypes.bool,
  }

  constructor(props, context) {
    super(props, context)
    let canEdit = false
    if (this.props.role !== 'Member') {
      canEdit = true
    }
    const query = QueryStringToJSON(this.props.location)
    this.state = {
      teamToEdit: null,
      showInactive: Boolean(
        query && query.status && query.status === 'inactive'
      ),
      searchBar: '',
      canEdit,
      page: Constants.START_PAGE_DEFAULT,
      pageInactive: Constants.START_PAGE_DEFAULT,
      perPage: Constants.PER_PAGE_DEFAULT,
    }
  }

  componentDidMount() {
    if (this.state.page === Constants.START_PAGE_DEFAULT) {
      this.loadNextObjects(
        this.state.showInactive ? this.state.pageInactive : this.state.page
      )
    }
  }

  toggleShowInactiveTeams = () => {
    const self = this
    this.props.history.push(
      `/directory/teams?status=${
        this.state.showInactive ? 'active' : 'inactive'
      }`
    )
    this.setState(
      {
        showInactive: !this.state.showInactive,
      },
      () => {
        self.loadNextObjects(
          this.state.showInactive ? this.state.pageInactive : this.state.page
        )
      }
    )
  }
  newCompanyClicked = () => {
    this.props.history.push('/directory/teams/add-team', {
      backRoute: '/directory/teams',
    })
  }
  editTeamClicked = (teem, canEdit) => {
    const team = JSON.parse(JSON.stringify(teem))
    team.isInactive = this.state.showInactive
    NavigationConstants.navigateToTeam({
      history: this.props.history,
      match: this.props.match,
      team,
      state: {
        team,
        canEdit,
      },
    })
  }
  handleSearchItemSelected = team => {
    this.editTeamClicked(team)
  }
  dataResolver = response => {
    return response.teams
  }
  loadNextObjects = page => {
    if (
      (this.state.showInactive && !this.props.isFetchingInactive) ||
      (!this.state.showInactive && !this.props.isFetching)
    ) {
      this.props.team_actions.getTeams(
        this.props.activeCampus.id,
        {
          page: page,
          per_page: this.state.perPage,
          status: this.state.showInactive ? 'inactive' : 'active',
          order_by: this.state.sortBy,
          serializer: 'TeamDirectorySerializer',
        },
        this.state.showInactive ? FETCHING_TEAMS : FETCHING_TEAMS_INACTIVE
      )
    }
  }

  nextPage = () => {
    const stateCopy = Object.assign({}, this.state)
    const showInactive = this.state.showInactive
    const pagination = showInactive
      ? this.props.inactivePagination
      : this.props.pagination
    const teams = showInactive ? this.props.inactiveTeams : this.props.teams
    const page = stateCopy[showInactive ? 'pageInactive' : 'page']
    if (
      pagination &&
      teams &&
      page < pagination.total_pages &&
      teams.length < pagination.total_objects
    ) {
      this.setState(
        {
          [showInactive ? 'pageInactive' : 'page']: page + 1,
        },
        () => this.loadNextObjects(page)
      )
    }
  }
  renderSearchRow = object => {
    return CompanyRowSearch({
      key: object.id,
      company: object,
      index: object.id,
      showEdit: false,
      showActions: false,
      editCompanyClicked: () =>
        this.editTeamClicked(object, this.state.canEdit),
      includeMarginsAndPadding: true,
      includePhoto: false,
      canEdit: false,
    })
  }
  renderTeams = (object, index) => {
    return CompanyRow({
      key: object.id,
      company: object,
      index: object.id,
      showEdit: true,
      showActions: true,
      editCompanyClicked: () =>
        this.editTeamClicked(object, this.state.canEdit),
      includeMarginsAndPadding: true,
      includePhoto: true,
      canEdit: this.state.canEdit,
      isInactive: object.status === 'inactive',
    })
  }

  renderPaginatedCompanies = canEdit => {
    const { isFetchingInactive, isFetching } = this.props
    const showInactive = this.state.showInactive
    const teams = showInactive ? this.props.inactiveTeams : this.props.teams
    const pagination = showInactive
      ? this.props.inactivePagination
      : this.props.pagination
    const fetchingStatus = showInactive ? isFetchingInactive : isFetching

    return (
      <PaginatedTable
        objects={teams}
        canEdit={canEdit}
        type={showInactive ? 'inactive teams' : 'teams'}
        nextPage={this.nextPage}
        isLoading={fetchingStatus}
        totalPages={pagination && pagination.total_pages}
        renderItem={(team, index) => this.renderTeams(team, index)}
        placeholderTitle={`No ${showInactive ? 'inactive teams' : 'teams'} at ${
          this.props.activeCampus.name
        }`}
        placeholderSubtext1={`You currently have no ${
          showInactive ? 'inactive teams' : 'teams'
        } in this campus`}
        placeholderSubtext2={showInactive ? '' : `Add one by clicking`}
        placeholderButtonText={'new team'}
        placeholderHandleClick={this.newCompanyClicked}
        icon={'users'}
      />
    )
  }

  render() {
    const { canEdit, showInactive } = this.state
    const teams = showInactive ? this.props.inactiveTeams : this.props.teams
    return (
      <div className="animated fadeIn">
        <div className="col-md-12">
          <div className="row border-bottom-1 my-3 d-flex justify-content-between align-items-center">
            <div className="col-12 col-md-3">
              <HeaderText small={showInactive}>
                {`${showInactive ? 'Inactive' : ''} Teams`}
              </HeaderText>
            </div>
            <div className="col-12 col-md-5">
              <QuickSearch
                showTitle={false}
                placeholder={`Search ${showInactive ? 'Inactive' : ''} Teams`}
                handleSearchItemSelected={this.handleSearchItemSelected}
                otherParams={{
                  status: showInactive ? 'inactive' : 'active',
                  serializer: 'TeamDirectorySerializer',
                }}
              />
            </div>
            {canEdit && (
              <div className="col-6 col-md-2 d-flex align-items-center d-md-down-none">
                <select
                  onChange={this.toggleShowInactiveTeams}
                  value={this.state.showInactive ? 'inactive' : 'active'}
                  className={'w-100 form-control'}
                >
                  <option value={'active'}>Active</option>
                  <option value={'inactive'}>Inactive</option>
                </select>
              </div>
            )}
            {canEdit && (
              <div className="col-md-2 action-right">
                <Button onClick={this.newCompanyClicked} color="primary">
                  <FontAwesomeIcon icon="users" /> New Team
                </Button>
              </div>
            )}
          </div>
          {this.renderPaginatedCompanies(canEdit)}
        </div>
      </div>
    )
  }
}

Teams.displayName = 'Teams'

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    teams: state.teams.teams,
    inactiveTeams: state.teams.inactive,
    pagination: state.teams.meta ? state.teams.meta.pagination : null,
    inactivePagination: state.teams.inactiveMeta
      ? state.teams.inactiveMeta.pagination
      : null,
    plans: state.plans,
    isFetching: resolveFetchingStatus(state, FETCHING_TEAMS),
    isFetchingInactive: resolveFetchingStatus(state, FETCHING_TEAMS_INACTIVE),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    plan_actions: bindActionCreators(planActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
    company_actions: bindActionCreators(teamActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Teams)
