/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TogglesComponent from '@global/Base/Toggle/TogglesComponent'
import { LabelStyled } from '@global/Form/FormComponents'
import QuickSearch from '@global/Search/QuickSearch'
import { Button, Input, Row } from 'reactstrap'
import RadioGroup from '@global/LocalRadioGroup'

const Container = styled.div``

const OPTION_ENUM = {
  EXISTING: 'Move to Existing Team',
  NEW: 'Create New Team',
}

const ChangeTeam = ({ team, handleTeamChange, className }) => {
  const [selectedTeamToChange, setToChange] = useState(null)

  const [optionValue, setOptionValue] = useState(OPTION_ENUM.EXISTING)
  const [newTeamName, setTeamName] = useState('')
  const handleInputChange = event => {
    const val = event.target.value
    setTeamName(val)
    handleTeamChange({ name: val })
  }
  const selectTeam = team => {
    setToChange(team)
    handleTeamChange(team)
  }

  const onOptionChange = oV => {
    setTeamName('')
    selectTeam(null)
    setOptionValue(oV)
  }
  const ignoreArr = []
  if (team && team.id) ignoreArr.push(team.id)
  return (
    <Container className={className}>
      <LabelStyled>Where should we move the member to?</LabelStyled>
      <RadioGroup
        className="mb-2"
        activeRadioValue={optionValue}
        handleChange={onOptionChange}
        arrayOfRadios={[
          {
            label: OPTION_ENUM.EXISTING,
            value: OPTION_ENUM.EXISTING,
            children: (
              <QuickSearch
                type="Team"
                showTitle={false}
                team={selectedTeamToChange}
                ignoreIds={ignoreArr}
                handleSearchItemCleared={() => selectTeam(null)}
                handleSearchItemSelected={val => selectTeam(val)}
                otherParams={{ include_empty_teams: true }}
              />
            ),
          },
          {
            label: OPTION_ENUM.NEW,
            value: OPTION_ENUM.NEW,
            children: (
              <Input
                name="newTeamName"
                value={newTeamName}
                placeholder="New team name"
                className="form-control"
                onChange={handleInputChange}
              />
            ),
          },
        ]}
      />
      {/* <LabelStyled>
            What should we do with {team ? team.name : 'the old team'}?
          </LabelStyled> */}
      {/* <div className="col-6">
            {TogglesComponent({
              arrayOfTabs: [
                {
                  label: 'Deactivate',
                },
                {
                  label: 'Keep active',
                },
              ],
              handleToggleTab: toggleTeamStatusAfterMemberChange,
              activeTab: archiveTeamAfterChange,
            })}
          </div> */}
      {/* <div className="m-2 col-6 d-flex align-items-center justify-content-end">
            <Button
              color="primary"
              disabled={
                (option === 'Existing Team' && !selectedTeamToChange) ||
                (option === 'New Team' && !newTeamName)
              }
              onClick={changeTeam}
            >
              Change Team
            </Button>
          </div> */}
    </Container>
  )
}

ChangeTeam.propTypes = {
  members: PropTypes.array,
  selectedAdminId: PropTypes.number,
  handleChangeInactiveAdmin: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  checkedMembers: PropTypes.array,
  team: PropTypes.object,
  handleTeamChange: PropTypes.func,
  selectValue: PropTypes.bool,
}

ChangeTeam.defaultProps = {
  selectValue: true,
}

ChangeTeam.displayName = 'Change Team'

export default ChangeTeam
