/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import PaymentSourceComponent from '@global/Stripe/PaymentSourceComponent'
import Checkbox from '@bit/coworks.base-components.checkbox'
import RadioGroup from '@global/Base/Radio/RadioGroup'
import styled from 'styled-components'
import { LabelStyled } from '@global/Form/FormComponents'
const InputWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  flex-flow row nowrap;
  align-items: center;
`

const REFUND_ENUM = {
  FULL: 'full',
  PARTIAL: 'partial',
}

function getPaymentSourceFromCharge(charge) {
  let paymentSource = null
  if (charge && charge.payment_method_details) {
    if (charge.payment_method_details.card) {
      paymentSource = {
        object: 'source',
        type: 'card',
        card: charge.payment_method_details.card,
      }
    } else if (charge.payment_method_details.ach_debit) {
      paymentSource = {
        object: 'source',
        type: 'bank_account',
        bank_account: {
          ...charge.payment_method_details.ach_debit,
          status: 'verified',
        },
      }
    }
  }
  return paymentSource
}

export default function RefundModal({
  showRefundModal,
  toggleRefundModal,
  renderNotesFields,
  isFetching,
  issueRefund,
  currencySymbol,
  charge,
  invoice,
}) {
  const [archiveEntity, setArchiveEntity] = useState(false)
  const [activeRadio, setActiveRadio] = useState(REFUND_ENUM.FULL)
  const [refundAmount, setRefundAmount] = useState(undefined)

  const onRefundInputChange = event => {
    let val = event.target.value
    val = val === '' ? undefined : parseFloat(val)
    setRefundAmount(val)
  }

  const calculateRemainingRefundAmount = charge =>
    parseFloat(charge.amount) - parseFloat(charge.amount_refunded / 100)

  const maxRefund = calculateRemainingRefundAmount(charge)

  const submit = () => {
    const value = refundAmount
      ? refundAmount.toFixed(2)
      : maxRefund.toFixed(2).toString()
    setActiveRadio(REFUND_ENUM.FULL)
    issueRefund(archiveEntity, value)
  }

  const handleRadioChange = event => {
    const val = event.target.name
    setRefundAmount(undefined)
    switch (val) {
      case REFUND_ENUM.FULL:
        setActiveRadio(REFUND_ENUM.FULL)
        break
      case REFUND_ENUM.PARTIAL:
        setActiveRadio(REFUND_ENUM.PARTIAL)
        break
      default:
        setActiveRadio(REFUND_ENUM.FULL)
        break
    }
  }

  const refundIsValid = () => {
    if (activeRadio === REFUND_ENUM.PARTIAL) {
      const floatAmount = refundAmount
        ? parseFloat(refundAmount).toFixed(2)
        : refundAmount
      if (
        refundAmount === undefined ||
        floatAmount <= 0 ||
        floatAmount > parseFloat(calculateRemainingRefundAmount(charge))
      )
        return false
    }
    return true
  }

  const hasBeenRefundedPreviously = charge => charge.amount_refunded

  return (
    <Modal
      isOpen={showRefundModal}
      toggle={toggleRefundModal}
      size={'bd-example-modal-lg'}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleRefundModal}>Issue a Refund</ModalHeader>
      <ModalBody>
        <div>
          <div className="row">
            <div className="col-12">
              Are you sure you would like to refund this invoice?
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <LabelStyled>Refund Amount</LabelStyled>
            </div>
            <div className="col-12">
              <FormGroup tag="fieldset">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name={REFUND_ENUM.FULL}
                      checked={activeRadio === REFUND_ENUM.FULL}
                      onClick={handleRadioChange}
                    />{' '}
                    {`Refund the ${
                      hasBeenRefundedPreviously ? 'remaining' : 'full'
                    } ${currencySymbol}${parseFloat(
                      calculateRemainingRefundAmount(charge)
                    ).toFixed(2)}`}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name={REFUND_ENUM.PARTIAL}
                      checked={activeRadio === REFUND_ENUM.PARTIAL}
                      onClick={handleRadioChange}
                    />{' '}
                    Refund a different amount
                  </Label>
                  {activeRadio === REFUND_ENUM.PARTIAL && (
                    <InputWrapper>
                      <span>{currencySymbol}</span>
                      <Input
                        name="refund_amount"
                        type="number"
                        value={refundAmount}
                        placeholder="Enter an amount"
                        className="form-control w-50 m-2"
                        onChange={onRefundInputChange}
                      />
                    </InputWrapper>
                  )}
                </FormGroup>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <LabelStyled>Payment Source</LabelStyled>
            </div>
          </div>
          <div className="row">
            <PaymentSourceComponent
              isLoadingSource={false}
              toggleVerifyBankModal={null}
              defaultSourceObject={getPaymentSourceFromCharge(charge)}
            />
            <br />
          </div>
          <br />
          <Row>
            <div className="col-12">{renderNotesFields()}</div>
          </Row>

          <br />
          {invoice && invoice.metadata.booking_id && (
            <div style={{ marginTop: '20px' }}>
              <Checkbox
                style={{ width: '100%' }}
                label="Cancel Booking"
                description="This invoice is tied to a booking. Checking this checkbox will archive the booking and hide it on the calendar."
                selected={archiveEntity}
                handleChange={() => setArchiveEntity(prev => !prev)}
              />
            </div>
          )}
        </div>
        <Row>
          <div className="col-12">
            {' '}
            <span className="text-muted">
              Please note that refunds take 5-10 business days.
            </span>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleRefundModal}>
          Cancel
        </Button>
        <Button
          color="danger"
          disabled={isFetching || !refundIsValid()}
          onClick={submit}
        >
          Submit Refund
        </Button>
      </ModalFooter>
    </Modal>
  )
}
RefundModal.displayName = 'RefundModal'
