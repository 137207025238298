/* eslint-disable */
import colors from './colors'
import color from 'color'

/**
 * Converts a 3 character hex string to 6 characters, which makes it easier to convert to RGB
 * @param {string} hex - a 3 character hex string
 * @returns a 6 character hex string
 */
export const convert3CharHexTo6CharHex = hex => {
  // convert any 3 character hex string to a full 6 character string
  const shortHandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  return hex.replace(shortHandRegex, (inputString, r, g, b) => {
    return `${r}${r}${g}${g}${b}${b}`
  })
}

/**
 * Takes a hex color string and returns an object containing rgb values as { r, g, b }
 * Solution pulled from https://stackoverflow.com/a/5624139/6204722
 * @param {string} hex - Color hex value
 */
export const hexToRGB = hex => {
  // convert 3 character hex string to a full 6 character string
  const newHex = convert3CharHexTo6CharHex(hex)

  // convert the 6 character hex string to an array, ie. ('2CCCE1' -> ['2C', 'CC', 'E1'])
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex)
  // return an object of rgb values
  return {
    r: result && result[1] ? parseInt(result[1], 16) : 0,
    g: result && result[2] ? parseInt(result[2], 16) : 0,
    b: result && result[3] ? parseInt(result[3], 16) : 0,
  }
}

/**
 * Converts a hex value and opacity into an rgba string
 * @param {string} hex - Color hex value
 * @param {float} opacity - Opacity value between 0 and 1
 */
export const hexToRGBAString = (hex, opacity = 1.0) => {
  const rgbColor = hexToRGB(hex)
  return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${parseFloat(
    opacity
  )})`
}

/**
 * Convert an rgb color to a hex value
 * @param {number} r - Red
 * @param {number} g - Green
 * @param {number} b - Blue
 */
export const rgbToHex = (r, g, b) => {
  // force to integers
  const red = parseInt(r, 10) || 0
  const green = parseInt(g, 10) || 0
  const blue = parseInt(b, 10) || 0

  // left shifting without bitwise operators
  return `#${(
    1 * Math.pow(2, 24) +
    red * Math.pow(2, 16) +
    green * Math.pow(2, 8) +
    blue
  )
    .toString(16)
    .slice(1)}`
}

export const getContrastingColor = (inputColor, black = colors.md_grey_800, white = colors.md_grey_100) => {

  let contrastingColor = black

  // eslint-disable-next-line no-implicit-coercion
  if (
    inputColor &&
    isValidHex(inputColor) &&
    color(inputColor) &&
    color(inputColor).isDark()
  ) {
    contrastingColor = white
  }
  return contrastingColor
}

export const isValidHex = hexInput => {
  // eslint-disable-next-line curly
  if (!hexInput || typeof hexInput !== 'string') return false
  return /(^#[0-9A-F]{6}$)/i.test(hexInput)
}

// This will lighten the color by theLum (0 - 1)
export function ColorLuminance(theHex, theLum) {
  // validate hex string
  let hex = String(theHex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  const lum = theLum || 0

  // convert to decimal and change luminosity
  let rgb = '#'
  let color = ''
  let i
  for (i = 0; i < 3; i++) {
    color = parseInt(hex.substr(i * 2, 2), 16)
    color = Math.round(
      Math.min(Math.max(0, color + color * lum), 255)
    ).toString(16)
    rgb += `00${color}`.substr(color.length)
  }
  return rgb
}

// Darkens a hex code color value by a certain decimal proportion
export function darken(hex, decimalPercent) {
  let rgb = hexToRGB(hex)
  let hsl = rgbToHsl(rgb.r, rgb.g, rgb.b)
  hsl.l -= decimalPercent
  hsl.l = Math.max(0, hsl.l)
  rgb = hslToRgb(hsl.h, hsl.s, hsl.l)
  let newHex = rgbToHex(rgb.r, rgb.g, rgb.b)
  return newHex
}

// Lightens a hex code color value by a certain decimal proportion
export function lighten(hex, decimalPercent) {
  let rgb = hexToRGB(hex)
  let hsl = rgbToHsl(rgb.r, rgb.g, rgb.b)
  hsl.l += decimalPercent
  hsl.l = Math.min(1, Math.max(0, hsl.l))
  rgb = hslToRgb(hsl.h, hsl.s, hsl.l)
  let newHex = rgbToHex(rgb.r, rgb.g, rgb.b)
  return newHex
}

/**
 * Conversion utility from HSL to RGB
 * borrowed from tinycolor
 */
function hslToRgb(h, s, l) {
  var r, g, b

  function hue2rgb(p, q, t) {
    if (t < 0) t += 1
    if (t > 1) t -= 1
    if (t < 1 / 6) return p + (q - p) * 6 * t
    if (t < 1 / 2) return q
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
    return p
  }

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s
    var p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return { r: r * 255, g: g * 255, b: b * 255 }
}

/**
 * conversion utility from RGB to HSL
 * borrowed from tinycolor
 */
function rgbToHsl(r, g, b) {
  r = r / 255
  g = g / 255
  b = b / 255

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h,
    s,
    l = (max + min) / 2

  if (max == min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  return { h: h, s: s, l: l }
}
