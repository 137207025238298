import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import * as memberActions from './memberActions'
import {
  FETCHING_CHECKINS,
  FETCHING_CHECKINS_VISITOR,
  FETCHING_CHECKINS_PASSPORT,
} from '@global/Constants/FetchingConstants'

import {
  CHECKIN_MEMBER,
  CHECKIN_VISITOR,
  CHECKIN_PASSPORT,
} from '@global/Constants/CheckinsConstants'

import moment from 'moment'

const ENUM = {
  SET: 'set',
  CLEAR: 'clear',
  UPDATE: 'update',
  SUCCESS_CREATE: 'success-create',
  SUCCESS_DELETE: 'success-delete',
  SUCCESS_UPDATE: 'success-update',
}

export const getFetchByType = type => {
  switch (type) {
    case CHECKIN_PASSPORT:
      return FETCHING_CHECKINS_PASSPORT
    case CHECKIN_VISITOR:
      return FETCHING_CHECKINS_VISITOR
    default:
      return FETCHING_CHECKINS
  }
}

const getType = type => {
  switch (type) {
    case CHECKIN_PASSPORT:
      return {
        [ENUM.SET]: actionTypes.SET_PASSPORT_CHECKINS,
        [ENUM.CLEAR]: actionTypes.CLEAR_PASSPORT_CHECKINS,
        [ENUM.UPDATE]: actionTypes.UPDATE_PASSPORT_CHECKINS,
        [ENUM.SUCCESS_CREATE]: actionTypes.CREATE_PASSPORT_CHECKIN,
        [ENUM.SUCCESS_DELETE]: actionTypes.DELETE_PASSPORT_CHECKIN,
        [ENUM.SUCCESS_UPDATE]: actionTypes.UPDATE_PASSPORT_CHECKIN,
      }
    case CHECKIN_VISITOR:
      return {
        [ENUM.SET]: actionTypes.SET_VISITOR_CHECKINS,
        [ENUM.CLEAR]: actionTypes.CLEAR_VISITOR_CHECKINS,
        [ENUM.UPDATE]: actionTypes.UPDATE_VISITOR_CHECKINS,
        [ENUM.SUCCESS_CREATE]: actionTypes.CREATE_VISITOR_CHECKIN,
        [ENUM.SUCCESS_DELETE]: actionTypes.DELETE_VISITOR_CHECKIN,
        [ENUM.SUCCESS_UPDATE]: actionTypes.UPDATE_VISITOR_CHECKIN,
      }
    default:
      return {
        [ENUM.SET]: actionTypes.SET_CHECKINS,
        [ENUM.CLEAR]: actionTypes.CLEAR_CHECKINS,
        [ENUM.UPDATE]: actionTypes.UPDATE_CHECKINS,
        [ENUM.SUCCESS_CREATE]: actionTypes.CREATE_CHECKIN,
        [ENUM.SUCCESS_DELETE]: actionTypes.DELETE_CHECKIN,
        [ENUM.SUCCESS_UPDATE]: actionTypes.UPDATE_CHECKIN,
      }
  }
}

export function setCheckins(checkinType, checkins, meta = null) {
  return { type: getType(checkinType)[ENUM.SET], checkins, meta }
}
export function clearCheckins(checkinType) {
  return { type: getType(checkinType)[ENUM.CLEAR] }
}
export function updateCheckins(checkinType, checkins, meta = null) {
  return { type: getType(checkinType)[ENUM.UPDATE], checkins, meta }
}
export function setActive(checkins) {
  return { type: actionTypes.SET_ACTIVE, checkins }
}
export function successCreatingCheckIn(checkinType, checkin) {
  return { type: getType(checkinType)[ENUM.SUCCESS_CREATE], checkin }
}
export function successDeletingCheckIn(checkinType, checkinId) {
  return { type: getType(checkinType)[ENUM.SUCCESS_DELETE], checkinId }
}
export function successUpdatingCheckIn(checkinType, checkin) {
  return { type: getType(checkinType)[ENUM.SUCCESS_UPDATE], checkin }
}

export const checkOutMember = (checkInId, type = CHECKIN_MEMBER) => (
  dispatch,
  getState
) => {
  const obj = {
    time_out: moment().toISOString(),
  }
  return dispatch(updateCheckIn(obj, checkInId, type))
}

export const getCheckIns = (
  params,
  nextPage,
  type = CHECKIN_MEMBER
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    if (!nextPage) dispatch(clearCheckins(type))
    apiService
      .getCheckIns({
        type,
        ...params,
      })
      .then(response => {
        if (nextPage) {
          dispatch(updateCheckins(type, response.checkins, response.meta))
        } else {
          dispatch(setCheckins(type, response.checkins, response.meta))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
      )
  })
}

export const createCheckIn = (params, type = CHECKIN_MEMBER) => (
  dispatch,
  getState
) => {
  const state = getState()
  const newParams = {
    ...params,
    campus_id: params.campus_id || state.ui.activeCampus.id,
    community_id: params.community_id || state.ui.activeCommunity.id,
    creator_id: params.creator_id || state.user.id,
    time_in: params.time_in || moment().toISOString(),
  }
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    apiService
      .createCheckIn(newParams)
      .then(response => {
        dispatch(successCreatingCheckIn(type, response.checkin))
        if (type === CHECKIN_MEMBER) {
          dispatch(memberActions.createMemberCheckin(response.checkin))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
      )
  })
}

export const deleteCheckIn = (checkinId, type = CHECKIN_MEMBER) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    apiService
      .deleteCheckIn(checkinId)
      .then(response => {
        dispatch(successDeletingCheckIn(type, checkinId))
        if (type === CHECKIN_MEMBER) {
          dispatch(memberActions.deleteMemberCheckin(response.checkin))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
      )
  })
}

export const updateCheckIn = (
  params,
  checkinId,
  type = CHECKIN_MEMBER
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    apiService
      .updateCheckIn(params, checkinId)
      .then(response => {
        dispatch(successUpdatingCheckIn(type, response.checkin))
        if (type === CHECKIN_MEMBER) {
          dispatch(memberActions.updateMemberCheckin(response.checkin))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
      )
  })
}
