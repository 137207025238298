export const CHECKIN_PASSPORT = 'CheckinNetwork'
export const CHECKIN_MEMBER = 'CheckinMember'
export const CHECKIN_VISITOR = 'CheckinVisitor'

// Redux keys
export const CHECKIN_ENUM = {
  [CHECKIN_MEMBER]: 'member',
  [CHECKIN_VISITOR]: 'visitor',
  [CHECKIN_PASSPORT]: 'passport',
}
