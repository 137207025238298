/* eslint-disable no-invalid-this,radix */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IntercomAPI } from 'react-intercom'

import Spinner from '@global/Spinner'
import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import {
  shouldDisableChargeNow,
  CHARGE_AUTOMATICALLY,
  SEND_INVOICE,
} from '@global/Constants/PaymentConstants'
import * as TeamBillingConstants from '@global/Constants/TeamBillingConstants'
import NewTeamForm from './NewTeamForm'

import * as planActions from '@reduxActions/planActions'
import * as memberActions from '@reduxActions/memberActions'
import * as uiActions from '@reduxActions/uiActions'
import * as userActions from '@reduxActions/userActions'
import { toast } from 'react-toastify'
import { convertStripeErrorToMessage } from '@global/ErrorFactory'

import moment from 'moment'

class NewTeam extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    member_actions: PropTypes.object,
    plan_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const activeTab = this.props.history.location.state
      ? this.props.history.location.state.activeTab
      : '1'
    const backRoute =
      this.props.history.location.state &&
      this.props.history.location.state.backRoute !== null &&
      this.props.history.location.state.backRoute !== undefined
        ? this.props.history.location.state.backRoute
        : '/directory'

    let isBillingEnabled = false
    const community = this.props.activeCommunity
    if (community.stripe_account_id) {
      isBillingEnabled = true
    }
    const lead =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state
        ? this.props.history.location.state.lead
        : null
    const passedInPropsNavFromMember =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.navFromMember !== null
        ? this.props.history.location.state.navFromMember
        : false
    const navFromMember = Boolean(
      activeTab === 'members' || passedInPropsNavFromMember
    )
    let teamName = ''
    if (lead && lead.company_name) {
      teamName = lead.company_name
    }
    const communityPrefs = props.activeCommunity.community_preference
    const { chargeNowDisabled, billingType } = shouldDisableChargeNow(
      communityPrefs
    )
    this.state = {
      lead,
      teamName,
      description: '',
      cost: 0,
      conferenceHoursAllowance: 0,
      planCost: 0,
      planName: '',
      planDescription: '',
      planFrequency: 'month',
      planCurrency:
        communityPrefs && communityPrefs.currency
          ? communityPrefs.currency
          : 'USD',
      billDates: Constants.billDates,
      teamPhoto: null,
      teamPhotoPreview: null,
      errors: [],
      isFlexPriceCollapsed: false,
      showNewPlanModal: false,
      showAddDiscountModal: false,
      isProrated: true,
      monthlyBillDate: communityPrefs.default_billing_cycle_day
        ? communityPrefs.default_billing_cycle_day
        : 1,
      startDate: moment(),

      joinedAt: moment(),
      userJoinedAt: moment(),
      isBillingEnabled,
      isCreatingNewPlan: false,
      navFromMember,
      activeTab,
      backRoute,
      captureCard: chargeNowDisabled ? 'true' : 'false', // this is used for when create charge disabled, but want to capture cc anyway
    }
  }
  // componentDidMount() {
  //   if (!this.props.plans || this.props.plans.length === 0) {
  //     this.props.plan_actions.getPlans(this.props.activeCampus.id)
  //   }
  // }
  handleImageUpload = image => {
    this.setState({
      imageChanged: true,
      teamPhoto: image,
      teamPhotoPreview: image,
    })
  }
  handleFrequencyDropdownChange = frequency => {
    this.setState({
      planFrequency: frequency,
    })
  }
  handleConferenceHoursChange = hours => {
    this.setState({
      conferenceHoursAllowance: hours,
    })
  }

  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value })
  }
  handleProrateToggle = isProrated => {
    this.setState({
      isProrated: isProrated,
    })
  }
  createTeam = childState => {
    // isProrated ? use billing_cycle_anchor for next this.state.monthlyBillDate
    // !isProrated ? create a free trial until start date and use a regular subscription
    let frequency = 'month'
    if (childState.subscriptionCart.length > 0) {
      frequency = childState.subscriptionCart[0].plan.frequency
    }

    const plans = TeamBillingConstants.formatPlansForApi(
      childState.subscriptionCart
    )
    const {
      conferenceHoursAllowance,
      joinedAt,
      description,
      isBillingEnabled,
      teamName,
      startDate,
      monthlyBillDate,
      teamPhotoPreview,
      discountObject,
      isProrated,
      userJoinedAt,
    } = this.state

    const { activeCampus, activeCommunity } = this.props

    let teamObj = {
      campus_id: activeCampus.id,
      team_name: teamName,
      type: 'Member',
      first_name: childState.firstName,
      last_name: childState.lastName,
      team_joined_at: joinedAt,
      user_joined_at: userJoinedAt,
      email: childState.email,
      title: childState.title,
      phone: childState.phone,
      team_website: childState.website,
      plans: JSON.stringify(plans),
      description,
      send_onboarding_email: childState.sendWelcomeEmail,
      conference_hours_allowance:
        conferenceHoursAllowance > 0 ? conferenceHoursAllowance : null,
      //TODO : add planDescription: PropTypes.string,planFrequency: PropTypes.string, planCurrency: PropTypes.string,
      // todo: add bill date, start date, prorate,
      add_to_door_access_system: childState.showAccess,
      all_doors: childState.allDoors,
      door_access_group_ids: JSON.stringify(childState.doorGroups),
    }
    if (isBillingEnabled) {
      let calculatedBillDate = startDate
      if (frequency === 'month') {
        // set stripe subscription related settings
        calculatedBillDate = Constants.getMonthlyBillDate(
          startDate,
          monthlyBillDate
        )
      } else if (frequency === 'year') {
        // todo support billing cycle setting on a yearly plan???
        calculatedBillDate = startDate
      }
      teamObj = {
        ...teamObj,
        ...Constants.getSubscriptionDateParams(
          startDate,
          calculatedBillDate,
          isProrated
        ),
      }
      teamObj.billing_type = childState.billingType
      if (childState.billingType === 'send_invoice') {
        teamObj.days_until_due =
          activeCommunity.community_preference.days_until_invoice_due
      }
      if (childState.cardToken) {
        teamObj.card_token = childState.cardToken
      }
      if (discountObject) {
        teamObj.coupon_duration = discountObject.duration
        teamObj.coupon_amount_off = discountObject.amount_off
        teamObj.coupon_name = discountObject.coupon_name
        if (discountObject.duration_in_months) {
          teamObj.coupon_duration_in_months = discountObject.duration_in_months
        }
        teamObj.coupon_metadata = discountObject.metadata
      }
    }

    if (teamPhotoPreview) {
      teamObj.team_photo = teamPhotoPreview
    }
    if (childState.lead) {
      teamObj.lead_id = childState.lead.id
    }

    if (childState.showAccess) {
      teamObj.add_to_door_access_system = true

      const doorIDs = []
      childState.doorAccess.forEach(element => {
        if (element.value === null) {
          teamObj.all_doors = true
        } else {
          doorIDs.push(element.id)
        }
      })
      teamObj.door_access_valid_from = childState.accessDate.toISOString()

      if (doorIDs.length > 0) {
        teamObj.door_access_group_ids = JSON.stringify(doorIDs)
      }
    }

    const self = this
    this.props.member_actions
      .createMember(teamObj)
      .then(response => {
        let team = null
        if (response && response.team) {
          team = response.team
        }
        if (response && response.user) {
          if (!team) {
            team = response.user.teams[0]
          }
          toast.success(`${team.name} was created.`)
          NavigationConstants.navigateToTeam({
            history: self.props.history,
            match: self.props.match,
            team: team,
            tab: 'profile',
          })
        } else {
          toast.success('The team was created successfully.')
          self.props.history.push('/directory', {
            activeTab: this.state.navFromMember ? 'members' : 'teams',
            backRoute: this.state.backRoute,
          })
        }
        IntercomAPI('trackEvent', 'add-team')
      })
      .catch(err => {
        toast.error(convertStripeErrorToMessage(err))
      })
  }

  toggleExtraTeamDetails = () => {
    this.setState({ isFlexPriceCollapsed: !this.state.isFlexPriceCollapsed })
  }

  toggleNewPlanModal = show => {
    // show is sometimes a boolean, and sometimes an event object
    if (show !== null && (show === true || show === false)) {
      this.setState({
        showNewPlanModal: show,
      })
    } else {
      this.setState({
        showNewPlanModal: !this.state.showNewPlanModal,
      })
    }
  }
  toggleAddDiscountModal = () => {
    this.setState({
      showAddDiscountModal: !this.state.showAddDiscountModal,
    })
  }
  handleMonthlyBillDateChanged = monthlyBillDate => {
    this.setState({
      monthlyBillDate: monthlyBillDate,
    })
  }
  handleProrateLength = value => {
    this.setState({
      prorateLength: value,
    })
  }
  handleJoinedAtChanged = mom => {
    this.setState({
      joinedAt: mom,
    })
  }
  handleUserJoinedAtChanged = mom => {
    this.setState({
      userJoinedAt: mom,
    })
  }
  handleStartDateChanged = mom => {
    this.setState({
      startDate: mom,
    })
  }

  handleSaveDiscountClicked = discount => {
    this.setState({
      discountObject: discount,
    })
  }
  handleIsCreatingNewPlan = isCreating => {
    this.setState({
      isCreatingNewPlan: isCreating,
    })
  }
  goBack = () => {
    this.props.history.goBack()
  }
  getProrateLength = () => {
    const { startDate, monthlyBillDate } = this.state
    if (!startDate) return null
    return Constants.getMonthlyBillDate(startDate, monthlyBillDate).diff(
      startDate.clone().startOf('day'),
      'days'
    )
  }

  /* eslint-disable max-len */
  render() {
    if (this.state.isCreatingNewPlan) {
      return <Spinner />
    }
    return (
      <NewTeamForm
        history={this.props.history}
        match={this.props.match}
        lead={this.state.lead}
        createTeam={this.createTeam}
        isBillingEnabled={this.state.isBillingEnabled}
        handleIsCreatingNewPlan={this.handleIsCreatingNewPlan}
        description={this.state.description}
        errors={this.state.errors}
        cost={this.state.cost}
        conferenceHoursAllowance={this.state.conferenceHoursAllowance}
        goBack={this.goBack}
        handleImageUpload={this.handleImageUpload}
        handleInputChange={this.handleInputChange}
        handleFrequencyDropdownChange={this.handleFrequencyDropdownChange}
        planCost={this.state.planCost}
        planName={this.state.planName}
        planDescription={this.state.planDescription}
        planFrequency={this.state.planFrequency}
        planCurrency={this.state.planCurrency}
        teamName={this.state.teamName}
        toggleExtraTeamDetails={this.toggleExtraTeamDetails}
        isExtraTeamDetailsCollapsed={this.state.isFlexPriceCollapsed}
        toggleNewPlanModal={this.toggleNewPlanModal}
        showNewPlanModal={this.state.showNewPlanModal}
        showAddDiscountModal={this.state.showAddDiscountModal}
        toggleAddDiscountModal={this.toggleAddDiscountModal}
        discountObject={this.state.discountObject}
        isProrated={this.state.isProrated}
        monthlyBillDate={this.state.monthlyBillDate}
        handleMonthlyBillDateChanged={this.handleMonthlyBillDateChanged}
        handleProrateToggle={this.handleProrateToggle}
        handleProrateLength={this.handleProrateLength}
        startDate={this.state.startDate}
        handleStartDateChanged={this.handleStartDateChanged}
        getProrateLength={this.getProrateLength}
        getMonthlyBillDate={Constants.getMonthlyBillDate}
        saveDiscount={this.handleSaveDiscountClicked}
        navFromMember={this.state.navFromMember}
        joinedAt={this.state.joinedAt}
        userJoinedAt={this.state.userJoinedAt}
        handleConferenceHoursChange={this.handleConferenceHoursChange}
        handleJoinedAtChanged={this.handleJoinedAtChanged}
        handleUserJoinedAtChanged={this.handleUserJoinedAtChanged}
        captureCard={this.state.captureCard} // this is used for when create charge disabled, but want to capture cc anyway
      />
    )
  }
  /* eslint-enable max-len */
}

NewTeam.displayName = 'NewTeam'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    member_actions: bindActionCreators(memberActions, dispatch),
    plan_actions: bindActionCreators(planActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTeam)
