/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import Checkbox from '@bit/coworks.base-components.checkbox'

export default function VoidInvoiceModal({
  showVoidInvoiceModal,
  toggleVoidInvoiceModal,
  renderNotesFields,
  isFetching,
  voidInvoice,
  invoice,
}) {
  const [archiveEntity, setArchiveEntity] = useState(false)

  return (
    <Modal
      isOpen={showVoidInvoiceModal}
      toggle={toggleVoidInvoiceModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleVoidInvoiceModal}>Close Invoice</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h6>
                Are you sure? This will close the invoice and this action is{' '}
                <b>irreversible!</b> The recipient will no longer be able to pay
                this invoice.
              </h6>
              <br />
              {invoice && invoice.metadata.booking_id && (
                <div style={{ marginTop: '20px' }}>
                  <Checkbox
                    style={{ width: '100%' }}
                    label="Cancel Booking"
                    description="This invoice is tied to a booking. Checking this checkbox will cancel the booking and hide it on the calendar."
                    selected={archiveEntity}
                    handleChange={() => setArchiveEntity(prev => !prev)}
                  />
                </div>
              )}
            </div>
          </div>
          <Row>
            <div className="col-12">{renderNotesFields()}</div>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleVoidInvoiceModal}>
          Cancel
        </Button>
        <Button
          color="danger"
          disabled={isFetching}
          onClick={() => voidInvoice(archiveEntity)}
        >
          Close Invoice
        </Button>
      </ModalFooter>
    </Modal>
  )
}
VoidInvoiceModal.displayName = 'VoidInvoiceModal'
