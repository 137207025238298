import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

import DateTime from 'react-datetime'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  LabelStyled,
  FontAwesomeIconLabel,
  SingleDatePicker,
} from '@global/Form/FormComponents'
import Tooltip from '@bit/coworks.base-components.tooltip'
import Switch from '@global/Base/Switch/Switch'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import Toggle from '../NewMember/Toggle'

export default function InviteNewMember({
  history,
  handleInputChange,
  handleBlur,
  shouldMarkError,
  email,
  title,
  firstName,
  lastName,
  emailValid,
  phone,
  sendWelcomeEmail,
  hasDoorProvider,
  handleWelcomeEmailToggle,
  handleAccessToggle,
  showAccess,
  doorAccessOptions,
  doorAccess,
  handleDoorsGrantedChanged,
  joinedAt,
  accessDate,
  handleUserAccessDateChanged,
  handleJoinedAtChanged,
  handleDateFocused,
  formErrors,
  dateFocused,
}) {
  return (
    <React.Fragment>
      <div className="row d-flex align-items-end">
        <div className="col-12 col-md-4 my-2">
          <LabelStyled>Email</LabelStyled>
          <Input
            type="text"
            name="email"
            onBlur={handleBlur('email')}
            className={shouldMarkError('email') ? ' w-100 error' : 'w-100'}
            placeholder="john@me.com"
            value={email}
            onChange={handleInputChange}
          />
          {formErrors.email && !emailValid ? (
            <p className="help-block text-danger">{formErrors.email}</p>
          ) : null}
        </div>
        {handleWelcomeEmailToggle && (
          <div className="col-12 col-md-4 my-2" id="sendWelcomeEmail">
            <LabelStyled>Welcome Email</LabelStyled>
            <Tooltip
              header={`Welcome Email`}
              tooltipContent={
                'This will send a welcome email to the new member notifying them of their account after creation.'
              }
              buttonText={'Change default'}
              handleClick={() => {
                history.push('/community/settings/onboarding')
              }}
            >
              {' '}
              <FontAwesomeIconLabel icon="question-circle" />
            </Tooltip>
            <div className="form-group mb-0">
              {/* activeLabel={<FontAwesomeIcon icon='paper-plane' />}
                      inactiveLabel={<FontAwesomeIcon icon='times' />} */}
              <Switch
                name="sendWelcomeEmail"
                labelRight={sendWelcomeEmail ? 'Send' : "Don't Send"}
                value={sendWelcomeEmail}
                onChange={handleWelcomeEmailToggle}
              />
            </div>
          </div>
        )}
      </div>
      <div className="row d-flex align-items-end">
        <div className="col-12 col-md-4  my-2">
          <LabelStyled>First Name</LabelStyled>
          <Input
            type="text"
            name="firstName"
            onBlur={handleBlur('firstName')}
            className={shouldMarkError('firstName') ? ' w-100 error' : 'w-100'}
            placeholder="Johnny"
            value={firstName}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-12 col-md-4  my-2">
          <LabelStyled>Last Name</LabelStyled>
          <Input
            type="text"
            name="lastName"
            onBlur={handleBlur('lastName')}
            className={shouldMarkError('lastName') ? ' w-100 error' : 'w-100'}
            placeholder="Appleseed"
            value={lastName}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4  my-2">
          <LabelStyled>Phone</LabelStyled>
          <Input
            type="text"
            name="phone"
            onBlur={handleBlur('phone')}
            className="form-group"
            placeholder="555-555-5555"
            value={phone}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-12 col-md-4  my-2">
          <LabelStyled>Title</LabelStyled>
          <Input
            type="text"
            name="title"
            onBlur={handleBlur('title')}
            className="form-group"
            placeholder="Founder, CTO, Sales, etc"
            value={title}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-12 col-md-4  my-2">
          <LabelStyled>Member Join Date</LabelStyled> <br />
          <SingleDatePicker
            id="join_date_invite_new_member" // PropTypes.string.isRequired,
            date={joinedAt} // momentPropTypes.momentObj or null
            onDateChange={handleJoinedAtChanged} // PropTypes.func.isRequired
            focused={dateFocused} // PropTypes.bool
            onFocusChange={handleDateFocused} // PropTypes.func.isRequired
            isOutsideRange={() => false}
            showClearDate
            placeholder={'Join Date'}
          />
        </div>
      </div>
      {hasDoorProvider && (
        <Toggle
          className="row my-2"
          isMulti
          isEditing
          show={showAccess}
          onToggle={handleAccessToggle}
          toggleLabel="Add to Kisi"
          fieldLabel="Door Groups"
          name="doorAccess"
          placeholder={'Choose which doors this member can access'}
          onChange={handleDoorsGrantedChanged}
          value={doorAccess}
          userKisiAccessDate={accessDate}
          handleUserAccessDateChanged={handleUserAccessDateChanged}
          options={
            doorAccessOptions && doorAccessOptions.groups
              ? [
                  { label: 'All Doors', value: null },
                  ...doorAccessOptions.groups.map(gr => ({
                    label: gr.name,
                    value: gr.id,
                    ...gr,
                  })),
                ]
              : []
          }
        />
      )}
      <hr />
    </React.Fragment>
  )
}

InviteNewMember.displayName = 'InviteNewMember'
InviteNewMember.propTypes = {
  history: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  shouldMarkError: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  emailValid: PropTypes.bool.isRequired,
  title: PropTypes.string,
  phone: PropTypes.string,
  sendWelcomeEmail: PropTypes.bool,
  hasDoorProvider: PropTypes.bool,
  handleWelcomeEmailToggle: PropTypes.func,
  handleAccessToggle: PropTypes.func,
  showAccess: PropTypes.bool,
  doorAccessOptions: PropTypes.array,
  doorAccess: PropTypes.array,
  handleDoorsGrantedChanged: PropTypes.func,
  joinedAt: PropTypes.object,
  accessDate: PropTypes.object,
  handleUserAccessDateChanged: PropTypes.func,
  handleJoinedAtChanged: PropTypes.func,
  handleDateFocused: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  dateFocused: PropTypes.bool.isRequired,
}
