import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import defaultAvatar from '../../../../img/placeholders/otto_black_white.jpeg'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import '../../../Messages/TableRow/memberRow.scss'

class CampusRow extends Component {
  static propTypes = {
    campus: PropTypes.object,
    isInactive: PropTypes.bool,
    campusClick: PropTypes.func,
  }

  render() {
    const { campus, campusClick, isInactive } = this.props
    return (
      <div className="message-member-wrapper border-bottom">
        <div className="member-info-container">
          <div className="member-avatar-wrapper round-image-wrapper">
            <img
              src={
                campus.campus_photo
                  ? campus.campus_photo.file_url
                  : defaultAvatar
              }
              alt={campus.name}
              className="member-avatar round-image"
            />
          </div>
          <div className="member-info">
            <p className="member-name">{campus.name}</p>
          </div>
        </div>
        <div>
          <Button
            className="btn btn-primary"
            disabled={Boolean(isInactive)}
            onClick={campusClick}
          >
            Change
          </Button>
        </div>
      </div>
    )
  }
}

CampusRow.displayName = 'Campus Row'

export default CampusRow
