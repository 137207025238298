/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'

import CoworksSearch from '@global/Search/CoworksSearch'
import CreatableSelect from '@global/Search/CoworksCreatableSelect'

import { LabelStyled } from '@global/Form/FormComponents'
import Badge from '@global/Base/Badge/Badge'
import { Row } from 'reactstrap'

// eslint-disable-next-line react/display-name
export function renderDynamicGroups({
  allGroups = [],
  handleChange,
  existingTagValues = [],
  groupType = '',
  isEditing = true,
}) {
  if (!groupType) {
    console.warn('Type is needed!')
    return null
  }
  // while loading
  if (!allGroups) return null

  // let adjustedGroupTypes = [groupType]

  // // Profile and Contacts belong together
  // if (['Profile', 'Contact'].includes(groupType)) {
  //   adjustedGroupTypes = ['Profile', 'Contact']
  // }

  // we need to pluck out "relevant" dynamic dropdowns based on the model we're looking at
  const relevantDynamicGroups = allGroups.filter(group => {
    const type = group.type.includes(',') ? group.type.split(',') : group.type
    return type.includes(groupType)
  })
  if (relevantDynamicGroups.length === 0) return null
  // now let's render those
  return (
    <React.Fragment>
      <hr />
      <div className="row my-3">
        <div className="col-12">
          <h4>Custom Dropdowns</h4>
        </div>
        <div className="col-12 col-md-6">
          {relevantDynamicGroups.map(group => {
            let options = []
            if (group && group.tags) {
              options = group.tags.map(tag => ({
                value: tag,
                label: tag,
              }))
            }
            const matchingGroup = existingTagValues
              ? existingTagValues.find(tag => tag.group_id === group.id)
              : null
            const existingValues = matchingGroup
              ? matchingGroup.values.map(value => ({
                  value,
                  label: value,
                }))
              : []
            return (
              <div key={group.id} className="my-2">
                <LabelStyled isEditing={isEditing}>{group.label}</LabelStyled>
                {isEditing ? (
                  <CoworksSearch
                    defaultData={options}
                    isClearable={false}
                    isSearch={false}
                    value={existingValues}
                    handleItemSelected={option =>
                      handleChange(
                        handleCustomItemSelected({
                          selectedOption: option,
                          groupName: group.id,
                          existingDynamicTags: existingTagValues,
                        })
                      )
                    }
                  />
                ) : (
                  renderValues(existingValues)
                )}
              </div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

renderDynamicGroups.displayName = 'renderDynamicGroups'

function handleCustomItemSelected({
  selectedOption,
  groupName,
  existingDynamicTags,
}) {
  const existingValueIndex = existingDynamicTags.findIndex(
    tag => tag.group_id === groupName
  )
  const valuesCopy = Object.assign([], existingDynamicTags)
  if (existingValueIndex > -1) {
    valuesCopy[existingValueIndex] = {
      group_id: groupName,
      values: [selectedOption.value],
    }
  } else {
    valuesCopy.push({
      group_id: groupName,
      values: [selectedOption.value],
    })
  }
  return valuesCopy
}

export function renderValues(values = [], renderWithBadge = false) {
  if (!values || values.length === 0) {
    return <div className="text-muted">None</div>
  }
  return values.map((object, index) =>
    renderWithBadge ? (
      <Badge className="mr-2">{object.label}</Badge>
    ) : (
      <div className="mr-2">{object.label}</div>
    )
  )
}

// helper for built-in groups
// room permissions, user skills, team industries....
// eslint-disable-next-line react/no-multi-comp
export function renderCreatableDropdownGroup({
  name,
  groupName,
  placeholder = 'Select an option or type to create...',
  values,
  options,
  handleChange,
  useBadgesForViewState = true,
  isEditing = true,
  isMulti = true,
}) {
  return (
    <div className="col-12">
      <LabelStyled isEditing={isEditing}>{name}</LabelStyled>
      {isEditing ? (
        <Row>
          <div className="col-12">
            <CreatableSelect
              name={groupName}
              isMulti={isMulti}
              placeholder={placeholder}
              onChange={(newValues, actionMeta) => {
                handleChange(groupName, newValues)
              }}
              value={values}
              options={options}
            />
          </div>
        </Row>
      ) : (
        <Row>
          <div className="col-12">
            {renderValues(values, useBadgesForViewState)}
          </div>
        </Row>
      )}
    </div>
  )
}
renderCreatableDropdownGroup.displayName = 'renderCreatableDropdownGroup'
