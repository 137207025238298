/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CenterText = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column nowrap;
`
const Header = styled.h6`
  font-weight: bold;
`
const Message = styled.span``
const TextWrapper = styled.span`
  text-align: center;
`

const ArchiveMessage = ({
  team,
  member,
  isMemberAdmin,
  isInactive,
  teamMemberComp,
  companyButton,
  dropdownComp,
  hasReplacementAdmins,
  // type,
  // renderSubscriptions,
  // shouldRenderSubscriptions,
  ...other
}) => {
  const memberPrompt = () => {
    const isTeamActive = team.status === 'active'
    return (
      <div className="col-12">
        <CenterText>
          <Header>
            {replacementPromptHeader()}
            {activateTeamWarningPromptHeader(
              (isTeamActive && !isInactive) || (!isTeamActive && isInactive)
            )}
          </Header>
          <Message>
            {confirmationPrompt()}
            {activateTeamWarningPrompt(
              (isTeamActive && !isInactive) || (!isTeamActive && isInactive)
            )}
            {!isInactive && replacementPrompt()}{' '}
          </Message>
          {!hasReplacementAdmins && teamMemberComp()}
          <br />
          {hintPrompt(isTeamActive)}{' '}
        </CenterText>
      </div>
    )
  }

  const confirmationPrompt = () =>
    !isMemberAdmin && (
      <span>
        {`Are you sure you want to move ${member.first_name} to another team?`}
      </span>
    )

  const replacementPromptHeader = () =>
    isMemberAdmin && hasReplacementAdmins
      ? `Cannot Remove The Team Admin!`
      : null

  const replacementPrompt = () =>
    isMemberAdmin && hasReplacementAdmins ? (
      <div>
        <br />
        {`${member.first_name} is the Admin of ${team.name}. Please choose another member from this team as the new admin by going to the `}
        {companyButton()}
      </div>
    ) : null

  const activateTeamWarningPrompt = matchingStatus =>
    isMemberAdmin && !hasReplacementAdmins && matchingStatus ? (
      <div>
        <br />
        <span>
          {!isInactive &&
            `${member.first_name} is the only active member of this team. Moving this member will automatically deactivate ${team.name}!`}
        </span>
      </div>
    ) : null

  const activateTeamWarningPromptHeader = matchingStatus =>
    isMemberAdmin && !hasReplacementAdmins && matchingStatus
      ? 'Are you sure you want to change teams?'
      : null

  const hintPrompt = () =>
    !isMemberAdmin &&
    !hasReplacementAdmins && (
      <p className="text-muted">
        <br />
        {`If you would like to change the admins of the members on this team, go to the `}
        {companyButton()}
      </p>
    )

  return memberPrompt()
}

ArchiveMessage.propTypes = {
  team: PropTypes.object,
  member: PropTypes.object,
  teamMemberComp: PropTypes.func,
  dropdownComp: PropTypes.func,
  renderSubscriptions: PropTypes.func,
  companyButton: PropTypes.func,
  type: PropTypes.string,
  isMemberAdmin: PropTypes.bool,
  shouldRenderSubscriptions: PropTypes.bool,
  hasReplacementAdmins: PropTypes.bool,
  isInactive: PropTypes.bool,
}

ArchiveMessage.displayname = 'Archive Message'

export default ArchiveMessage
