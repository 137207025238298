/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import * as _ from 'lodash'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import * as Constants from '@global/Constants'
import Spinner from '@global/Spinner'
import Checkbox from '@bit/coworks.base-components.checkbox'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import Badge from '@global/Base/Badge/Badge'
import PlanRow from '../../Plans/PlanRow'
import ArchiveMessage from './ArchiveMessage'
import RoundedImage from '@global/RoundedImage'

const Container = styled.div``

const ActionContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 6px;
  label {
    cursor: pointer;
  }
`

const ArchiveMemberSelect = ({
  members,
  selectedAdminId,
  handleChangeInactiveAdmin,
  handleCheckboxChange,
  checkedMembers,
  team,
  selectValue,
  onSelectAll,
  ...other
}) => {
  const renderMember = (member, index) => {
    const { id, last_name, user_photo, first_name, title, email } = member
    const checked = checkedMembers.filter(val => val === id).length > 0

    return (
      <div className="row my-2 mb-3">
        <div className="col-10 d-flex align-items-center ">
          <Checkbox
            selected={checked}
            handleChange={() => handleCheckboxChange(id, checked)}
            label={
              <div className="col">
                <div className="row">
                  {/* <div className="d-flex align-items-center justify-content-center">
                    <RoundedImage
                      photo={user_photo}
                      alt="Picture"
                      size="medium"
                    />
                  </div> */}
                  <div className="d-flex flex-column justify-content-center">
                    <h5>
                      {first_name} {last_name}
                      <span className="text-muted h6">
                        {' '}
                        {title ? title : ''}
                      </span>
                    </h5>
                    <p className="m-0 text-muted">{email}</p>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          {selectedAdminId === id ? (
            <Badge>Admin</Badge>
          ) : (
            <React.Fragment>
              {team && team.status === 'inactive' ? (
                <TextButton
                  className="p-0 m-0"
                  onClick={() => handleChangeInactiveAdmin(id)}
                >
                  Make Admin
                </TextButton>
              ) : null}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  const renderMemberItems = () =>
    members.map((member, index) => renderMember(member, index))

  const renderSelectAction = () => {
    return (
      <ActionContainer>
        <Checkbox
          selected={selectValue}
          handleChange={() => onSelectAll(!selectValue)}
          label={<h6>Select All</h6>}
        />
      </ActionContainer>
    )
  }

  return (
    <Container {...other}>
      {renderSelectAction()}
      {renderMemberItems()}
    </Container>
  )
}

ArchiveMemberSelect.propTypes = {
  members: PropTypes.array,
  selectedAdminId: PropTypes.number,
  handleChangeInactiveAdmin: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  checkedMembers: PropTypes.array,
  team: PropTypes.object,
  onSelectAll: PropTypes.func,
  selectValue: PropTypes.bool,
}

ArchiveMemberSelect.defaultProps = {
  selectValue: true,
}

ArchiveMemberSelect.displayName = 'Archive Member Select'

export default ArchiveMemberSelect
