import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import AnnouncementsMemberRow from './TableRow/AnnouncementsMemberRow'
import Spinner from '@global/Spinner'
import './announcementForm.scss'

class MemberModal extends Component {
  static propTypes = {
    showMemberModal: PropTypes.bool,
    toggleMemberModal: PropTypes.func,
    addRecipients: PropTypes.func,
    markedRecipientIds: PropTypes.array,
    allRecipients: PropTypes.bool,
    team: PropTypes.object,
    loading: PropTypes.bool,
    selectAll: PropTypes.func,
  }

  renderMembers = () => {
    const {
      team,
      markedRecipientIds,
      allRecipients,
      addRecipients,
    } = this.props

    const teams = []
    const strippedTeam = Object.assign({}, team)
    delete strippedTeam.users
    teams[0] = strippedTeam

    return (
      <Row>
        {team.users.map(object => {
          const member = Object.assign({}, object)
          member.teams = teams

          return (
            <React.Fragment>
              <div key={member.id} className="col-12 text-truncate">
                <AnnouncementsMemberRow
                  key={member.id}
                  member={member}
                  admins={team.admins}
                  addRecipients={addRecipients}
                  added={
                    markedRecipientIds.includes(object.id) || allRecipients
                  }
                />
              </div>
            </React.Fragment>
          )
        })}
      </Row>
    )
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showMemberModal}
        toggle={() => this.props.toggleMemberModal(this.props.team)}
      >
        <ModalHeader
          toggle={() => this.props.toggleMemberModal(this.props.team)}
        >
          {this.props.team.name} Members
        </ModalHeader>
        {this.props.loading ? (
          <Spinner />
        ) : (
          <ModalBody>
            <div className="card p-4">
              <div className="member-list-wrapper">{this.renderMembers()}</div>
            </div>

            <div className="d-flex">
              <Button
                className="btn btn-secondary ml-auto btn-primary"
                onClick={() => this.props.selectAll(this.props.team, 'company')}
              >
                Select All
              </Button>
            </div>
          </ModalBody>
        )}
      </Modal>
    )
  }
}

MemberModal.displayName = 'Member Modal'

export default MemberModal
