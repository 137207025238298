/* eslint-disable id-length */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import Colors from './colors'
import ColorSquare from '@global/Colors/ColorSquare'

import { LabelStyled, formatDescription } from '../Form/FormComponents'
// import utils
import { rgbToHex, hexToRGB, isValidHex } from './ColorUtils'

class ColorPicker extends Component {
  handleChange = event => {
    const { color, onChange, name } = this.props
    const rgb = hexToRGB(color)
    const targetName = event.target.name
    const value = event.target.value
    if (targetName === 'Hex' && isValidHex(value)) {
      onChange(value)
    } else if (targetName === 'R' || targetName === 'G' || targetName === 'B') {
      const newRGB = {
        r: (targetName === 'R' && value) || rgb.r,
        g: (targetName === 'G' && value) || rgb.g,
        b: (targetName === 'B' && value) || rgb.b,
      }
      const newColor = rgbToHex(newRGB.r, newRGB.g, newRGB.b)
      onChange(newColor)
    } else {
      onChange(value)
    }
  }

  render() {
    const { color, label, description, disabled } = this.props

    let rgb = { r: 0, g: 0, b: 0 }
    if (color) {
      rgb = hexToRGB(color)
    }

    const styles = {
      hue: {
        width: '15rem',
      },
      hexInput: {
        input: {
          width: '100%',
          marginTop: '2rem',
          outline: 'none',
          border: '0px',
          borderBottom: `2px solid ${color}`,
        },
        label: {
          position: 'absolute',
          top: 0,
          left: 0,
          fontSize: '1.2rem',
          color: Colors.md_grey_500,
        },
      },
      rgbInput: {
        wrap: {
          marginTop: '1rem',
        },
        input: {
          width: '100%',
          marginTop: '2rem',
          outline: 'none',
          border: '0px',
          borderBottom: `1px solid ${Colors.md_grey_500}`,
        },
        label: {
          position: 'absolute',
          top: 0,
          left: 0,
          fontSize: '1.2rem',
          color: Colors.md_grey_500,
        },
      },
    }

    let colorLabel = ''
    // eslint-disable-next-line curly
    if (color) colorLabel = color.toUpperCase()
    return (
      <div className="row" name={this.props.name}>
        <div className="col-12">
          <LabelStyled>{label}</LabelStyled>
          {formatDescription(description)}
        </div>
        <div className="col-md-4">
          <ColorSquare color={color} colorLabel={colorLabel} />
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <LabelStyled>Hex</LabelStyled>
                <Input
                  name="Hex"
                  value={color}
                  disabled={disabled}
                  style={styles.hexInput}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <LabelStyled>R</LabelStyled>
                <Input
                  type="number"
                  name="R"
                  value={rgb.r}
                  disabled={disabled}
                  style={styles.rgbInput}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <LabelStyled>G</LabelStyled>
                <Input
                  type="number"
                  name="G"
                  value={rgb.g}
                  disabled={disabled}
                  style={styles.rgbInput}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <LabelStyled>B</LabelStyled>
                <Input
                  type="number"
                  name="B"
                  value={rgb.b}
                  disabled={disabled}
                  style={styles.rgbInput}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string, // hex string
  name: PropTypes.string, // hex string
  label: PropTypes.string, // label of component
  disabled: PropTypes.boolean, // label of component
  description: PropTypes.string, // description of component
  onChange: PropTypes.func,
}
ColorPicker.displayName = 'ColorPicker'

export default ColorPicker
