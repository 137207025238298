/* eslint-disable react/no-multi-comp,react/display-name */
import React, { useEffect, useReducer, useRef, useState } from 'react'
import AvatarNamePlate from '@global/Avatar/AvatarNamePlate'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import * as Constants from '@global/Constants'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as apiService from '../../../services/apiService'

const PaginatedTableStyled = styled(PaginatedTable)`
  max-height: 350px;
  overflow-y: scroll;
  padding: 2px;
`
const AvatarNamePlateStyled = styled(AvatarNamePlate)`
  flex: 1;
  margin-bottom: 12px;
`

const FETCHING = 'FETCHING'
const RESOLVE_FETCH = 'RESOLVE_FETCH'
const RESOLVE_FETCH_FAILED = 'RESOLVE_FETCH_FAILED'

const initialState = {
  data: {
    attendees: [],
    meta: { pagination: null },
  },
  loading: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case FETCHING:
      return {
        ...state,
        loading: true,
      }
    case RESOLVE_FETCH:
      const { attendees, meta } = action.payload
      return {
        loading: false,
        data: {
          attendees: [...state.data.attendees, ...attendees],
          meta,
        },
      }
    case RESOLVE_FETCH_FAILED:
      return {
        loading: false,
        data: initialState.data,
      }
    default:
      return state
  }
}

const AttendeeList = ({
  event,
  activeCommunity,
  activeCampus,
  handleMemberClick,
}) => {
  const [page, setPage] = useState(Constants.START_PAGE_DEFAULT)
  const [{ data, loading }, dispatch] = useReducer(reducer, initialState)
  const perPage = Constants.PER_PAGE_DEFAULT
  const prevPageRef = useRef()
  const { attendees, meta } = data

  const nextPage = () => {
    if (loading) return

    if (
      (meta.pagination &&
        attendees &&
        attendees.length < meta.pagination.total_objects &&
        page < meta.pagination.total_pages) ||
      !attendees
    ) {
      const newPage = page + 1
      setPage(newPage)
    }
  }

  useEffect(() => {
    if (activeCampus && activeCommunity) {
      const prevPage = prevPageRef.current
        ? prevPageRef.current
        : Constants.START_PAGE_DEFAULT
      if (page > prevPage) {
        loadNextObjects(page, perPage, true)
      } else {
        loadNextObjects(Constants.START_PAGE_DEFAULT, perPage)
      }
      prevPageRef.current = page
    }
  }, [page])

  const loadNextObjects = async (page, per_page, nextPage) => {
    const body = {
      page,
      per_page,
      community_id: activeCommunity.id,
      campus_id: activeCampus.id,
      order_by: 'name.ASC',
    }
    dispatch({ type: FETCHING })
    apiService
      .getAttendees(event.id, body)
      .then(response => {
        dispatch({ type: RESOLVE_FETCH, payload: response })
      })
      .catch(err => {
        dispatch({ type: RESOLVE_FETCH_FAILED })
        console.error(err)
      })
  }

  const renderComp = ({ user }, index) => {
    return (
      <AvatarNamePlateStyled
        object={user}
        onClick={handleMemberClick}
        size={1}
        objectType="user"
      />
    )
  }
  return (
    <PaginatedTableStyled
      wrapperClassName="col-md-12 p-0"
      objects={attendees}
      type={'attendees'}
      nextPage={nextPage}
      isLoading={loading}
      totalPages={meta.pagination && meta.pagination.total_pages}
      renderItem={(member, index) => renderComp(member, index)}
      placeholderTitle={'No Attendees'}
      placeholderSubtext1={'No attendees found for this event.'} // TODO FIX THIS PLZ
      icon={'user'}
      ancestor={null}
    />
  )
}

AttendeeList.displayName = 'AttendeeList'

AttendeeList.propTypes = {
  event: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  handleMemberClick: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
  }
}

export default connect(mapStateToProps)(AttendeeList)
