/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div``
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const LText = styled.p`
  margin-bottom: 0.5rem;
`
const RText = styled.p`
  margin-bottom: 0.5rem;
`

const TotalTextRows = ({ rows, ...other }) => {
  const constructRows = () =>
    rows.map(thing => (
      <Wrapper style={thing.style}>
        <LText>{thing.label}</LText>
        <RText>{thing.value}</RText>
      </Wrapper>
    ))

  return <Container {...other}>{constructRows()}</Container>
}

TotalTextRows.propTypes = {
  rows: PropTypes.array,
}

TotalTextRows.displayName = 'TotalTextRows'

export default TotalTextRows
