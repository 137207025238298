import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import CampusDetail from './Detail/CampusDetail'
import Campuses from './List/Campuses'

function CampusContainer({ match, history, user }) {
  const { path, url } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/add`}>
        <CampusDetail history={history} />
      </Route>
      <Route path={`${path}/:campusId`}>
        <CampusDetail history={history} />
      </Route>
      <Route exact path={`${path}`}>
        <TabPane>
          <Campuses history={history} user={user} />{' '}
        </TabPane>
      </Route>
    </Switch>
  )
}

CampusContainer.displayName = 'Campus Container'
CampusContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object.isRequired,
}

export default CampusContainer
