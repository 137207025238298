/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, TextButton } from '@global/Base/Button/ButtonStyled'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { isInclusivelyAfterDay } from 'react-dates'
import PresetDatePicker from '@global/PresetDatePicker/PresetDatePicker'

import moment from 'moment'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  width: 100%;
`
const RangeWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
`
const TextButtonStyled = styled(TextButton)`
  margin: 0;
`
const ButtonStyled = styled(Button)`
  margin: 0;
  margin-left: 12px;
`
const TodayButton = styled.div`
  display: flex;
`

const dateToday = {
  startDate: moment().startOf('day'),
  endDate: moment().endOf('day'),
}
function TopBar({
  handleDateRangeChanged,
  presets,
  defaultPreset,
  canCheckIn,
  isCheckedIn,
  isLoading,
  disableCheckin,
  onRefreshClicked,
  onCheckInClicked,
  onCheckOutClicked,
}) {
  const [dropVal, setDropdownVal] = useState(null)

  const getCheckInButtons = () => {
    if (canCheckIn && onCheckInClicked && onCheckOutClicked) {
      if (!isCheckedIn) {
        return (
          <ButtonStyled
            disabled={isLoading || disableCheckin}
            onClick={onCheckInClicked}
          >
            Check In
          </ButtonStyled>
        )
      }
      return (
        <ButtonStyled disabled={isLoading} onClick={onCheckOutClicked}>
          Check Out
        </ButtonStyled>
      )
    }
    return null
  }

  const updateDrop = range => {
    handleDateRangeChanged(range)
    setDropdownVal(range)
  }
  const onTodayClick = () => {
    handleDateRangeChanged(dateToday)
    setDropdownVal(null)
  }

  return (
    <Container>
      <RangeWrapper>
        <TodayButton>
          <TextButton className="m-0 mr-2" onClick={onTodayClick}>
            Today
          </TextButton>
        </TodayButton>
        <PresetDatePicker
          handleDateRangeChanged={updateDrop}
          presets={presets}
          minimumNights={0}
          enableOutsideDays
          defaultPreset={defaultPreset}
          value={dropVal}
          placeholderText="Jump to date..."
          isOutsideRange={day =>
            isInclusivelyAfterDay(day, moment().add(1, 'day'))
          }
        />
      </RangeWrapper>
      <TextButtonStyled onClick={onRefreshClicked}>
        <FontAwesomeIcon icon="sync" />
      </TextButtonStyled>
      {getCheckInButtons()}
    </Container>
  )
}

TopBar.propTypes = {
  handleDateRangeChanged: PropTypes.func,
  presets: PropTypes.array,
  defaultPreset: PropTypes.number,
  isCheckedIn: PropTypes.bool,
  canCheckIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  onRefreshClicked: PropTypes.func,
  onCheckInClicked: PropTypes.func,
  onCheckOutClicked: PropTypes.func,
  disableCheckin: PropTypes.bool,
}

TopBar.displayName = 'TopBar'

export default TopBar
