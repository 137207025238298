/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import renderHTML from 'react-render-html'
import { IntercomAPI } from 'react-intercom'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { Field } from 'formik'
import {
  CustomInputComponent,
  CustomToggleComponent,
  CustomRichTextComponent,
  LabelStyled,
} from '@global/Form/FormComponents'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import { Row, Alert } from 'reactstrap'

import * as uiActions from '@reduxActions/uiActions'
import * as memberActions from '@reduxActions/memberActions'

const SubstitutionSection = styled.div`
  background-color: #eeeeee;
`
const Label = styled(LabelStyled)`
  margin-bottom: 0px;
`

function OnboardingSettings(props) {
  const activeCommunity = props.activeCommunity
  const communityPreferences = activeCommunity.community_preference

  return (
    <React.Fragment>
      <h3>Legal</h3>
      <br />
      <Field
        label="Terms of Service"
        name="legal.terms_of_service_url"
        description="The link to your terms of service. Used on the automated member signup and the external bookings."
        component={CustomInputComponent}
      />
      <br />
    </React.Fragment>
  )
}

OnboardingSettings.propTypes = {
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  user: PropTypes.object,
  member_actions: PropTypes.object,
  values: PropTypes.object,
}
OnboardingSettings.displayName = 'OnboardingSettings'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetching: state.ui.isFetching,
    campuses: state.ui.activeCommunity.campuses,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ui_actions: bindActionCreators(uiActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSettings)
