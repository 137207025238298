import React from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import Spinner from '@global/Spinner'
import Tooltip from '@bit/coworks.base-components.tooltip'

const Container = styled.div`
  height: 80px;
`

const Card = styled.div`
  height: 100%;
  padding: 10px;
`
const Wrapper = styled.div`
  display: flex; 
  flex-flow: row nowrap;
  height: 100%;
  align-items: center;
`
const Content = styled.div`
  display: flex;
  flex-flow: column;
`

const Label = styled.div`
  font-weight: bold;
  margin-right: 3px;
`
const TextWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

const CardIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: ${props => props.theme.colorPrimary}!important;
  margin-right: 10px;
  border-radius: 100%;
`
const TextStyled = styled.h4`
  font-weight: bold;
  margin: 0;
  color: ${props =>
    props.color === 'primary'
      ? props.theme.colorPrimary
      : props.theme.colorSecondary}!important;
`

function DashboardMiniCard({ loading, count, icon, text, iconColor, className, tooltipProps, }) {
  return (
    <Container className={className}>
      <Card className="card">
        <Wrapper>
          {icon && (
            <CardIconStyled >
              <FontAwesomeIcon className="fa-fw" icon={icon} color={iconColor} size="lg" />
            </CardIconStyled>
          )}
          {loading ? <Spinner /> : (
            <Content>
              <TextStyled color="primary">
                {count}
              </TextStyled>
              <TextWrapper>
                <Label
                  style={{ fontWeight: 'bold' }}
                >
                  {text}
                </Label>

                {
                  tooltipProps &&
                  <Tooltip
                    {...tooltipProps}
                  >
                    <FontAwesomeIcon icon="question-circle" />
                  </Tooltip>
                }
              </TextWrapper>
            </Content>
          )}
        </Wrapper>
      </Card>
    </Container >
  )
}

DashboardMiniCard.propTypes = {
  count: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  tooltipProps: PropTypes.object
}

DashboardMiniCard.defaultProps = {
  iconColor: 'white'
}



DashboardMiniCard.displayName = 'DashboardMiniCard'
export default DashboardMiniCard
