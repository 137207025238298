/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import renderHTML from 'react-render-html'
import { IntercomAPI } from 'react-intercom'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { Field } from 'formik'
import {
  CustomInputComponent,
  CustomToggleComponent,
  CustomRichTextComponent,
  LabelStyled,
} from '@global/Form/FormComponents'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import { Row, Alert } from 'reactstrap'

import * as uiActions from '@reduxActions/uiActions'
import * as memberActions from '@reduxActions/memberActions'
import Badge from '@global/Base/Badge/Badge'

const SubstitutionSection = styled.div`
  background-color: #eeeeee;
`
const Label = styled(LabelStyled)`
  margin-bottom: 0px;
`

function OnboardingSettings(props) {
  const member_name = useRef(null)
  const member_first_name = useRef(null)
  const member_last_name = useRef(null)
  const member_email = useRef(null)
  const campus_name = useRef(null)
  const community_name = useRef(null)
  const activeCommunity = props.activeCommunity
  const communityPreferences = activeCommunity.community_preference

  // eslint-disable-next-line react/display-name
  function renderTag(tag, myRef) {
    return (
      document.queryCommandSupported('copy') && (
        <div className="col-6 d-flex align-items-center">
          <Badge ref={myRef} badgeShape="pill">
            {tag}
          </Badge>
          <TextButton
            className="m-0"
            onClick={() => {
              const elem = document.createElement('textarea')
              document.body.appendChild(elem)
              elem.value = tag
              elem.select()
              document.execCommand('copy')
              document.body.removeChild(elem)
              toast.success('Copied!')
            }}
          >
            Copy
          </TextButton>
        </div>
      )
    )
  }
  function renderBadge(text) {
    return <Badge badgeShape="pill">{text}</Badge>
  }

  function resendOnboardingEmail({ activeCampus, member, member_actions }) {
    const params = {
      id: member.id,
      campus_id: activeCampus.id,
    }
    member_actions
      .resendOnboardingEmail(params)
      .then(user => {
        toast.success(`Sent a test onboarding email to ${member.email}.`)
      })
      .catch(err => {
        toast.error('Failed to resend the test onboarding email.')
        console.error(err)
      })
  }
  // eslint-disable-next-line react/display-name,
  function renderPreview({ value, plainText }) {
    if (!value || value === '') {
      return null
    }
    if (plainText) {
      const newValue = value.split(' ')
      return newValue.map((val, index) => {
        switch (val) {
          case '{{campus_name}}':
            return this.renderBadge('Raleigh Campus ')
          case '{{community_name}}':
            return this.renderBadge('Coworks Community ')
          case '{{member_name}}':
            return this.renderBadge('Johnny Appleseed ')
          case '{{member_first_name}}':
            return this.renderBadge('Johnny ')
          case '{{member_last_name}}':
            return this.renderBadge('Appleseed ')
          case '{{member_email}}':
            return this.renderBadge('johnny@appleseed.com ')
          default:
            return <span>{val} </span>
        }
      })
    }
    let newValue = value
    newValue = newValue.replace(
      /\{{campus_name}}/g,
      '<span className="badge badge-pill badge-primary">Raleigh Campus </span>'
    )
    newValue = newValue.replace(
      /\{{community_name}}/g,
      '<span className="badge badge-pill badge-primary">Coworks Community </span>'
    )
    newValue = newValue.replace(
      /\{{member_name}}/g,
      '<span className="badge badge-pill badge-primary">Johnny Appleseed </span>'
    )
    newValue = newValue.replace(
      /\{{member_first_name}}/g,
      '<span className="badge badge-pill badge-primary">Johnny </span>'
    )
    newValue = newValue.replace(
      /\{{member_last_name}}/g,
      '<span className="badge badge-pill badge-primary">Appleseed </span>'
    )
    newValue = newValue.replace(
      /\{{member_email}}/g,
      '<span className="badge badge-pill badge-primary">johnny@appleseed.com </span>'
    )
    return renderHTML(newValue)
  }

  return (
    <React.Fragment>
      <h3>Onboarding</h3>
      <br />
      <Field
        label="Send Member Onboarding Email by Default"
        name="send_onboarding_email"
        description='The default setting for the "Send Onboarding Email" toggle in new member and team admin dashboard forms'
        component={CustomToggleComponent}
      />
      <br />
      <Field
        label="Membership Agreement Url"
        name="membership_agreement_url"
        description={`The url for the membership agreement shown in the mobile apps on first login. Last updated: ${
          props.values.membership_agreement_updated_at
            ? moment(props.values.membership_agreement_updated_at).format(
                'MMMM DD, YYYY hh:mm a'
              )
            : 'never'
        }`}
        component={CustomInputComponent}
      />
      <br />
      <Row>
        <div className="col-md-8 col-12">
          <h3>Onboarding Email Customization</h3>
        </div>
        <div className="col-md-4 col-12">
          <TextButton
            onClick={() =>
              resendOnboardingEmail({
                activeCampus: props.activeCampus,
                member: props.user,
                member_actions: props.member_actions,
              })
            }
          >
            Send Test Email
          </TextButton>
        </div>
        {!communityPreferences.is_whitelabeled && (
          <div className="col-12">
            <br />
            <Field
              name="welcome_email_from_email"
              label={'"From" Email Address'}
              description="The email address that shows as the 'from' for the member onboarding emails"
              component={CustomInputComponent}
            />
            <br />
          </div>
        )}
      </Row>
      {communityPreferences.is_whitelabeled ? (
        <React.Fragment>
          <SubstitutionSection className="p-3">
            <div className="row">
              <div className="col-12">
                <h5>Email Customization</h5>
              </div>
              <div className="col-12">
                <div>
                  Use these tags in the section below to dynamically personalize
                  the recipient's content in the onboarding email.
                  <br />
                  <br />
                  Note: The tags must be copied exactly. Properly formatted
                  variables will appear in the preview as tags. Send a test
                  email above to preview a live version of your email content.
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-3  d-flex align-items-center">
                <Label>Member's Full Name Tag: </Label>{' '}
              </div>
              {renderTag('{{member_name}}', member_name)}
            </div>
            <div className="row">
              <div className="col-3  d-flex align-items-center">
                <Label>Member's First Name Tag: </Label>{' '}
              </div>
              {renderTag('{{member_first_name}}', member_first_name)}
            </div>
            <div className="row">
              <div className="col-3  d-flex align-items-center">
                <Label>Member's Last Name Tag: </Label>{' '}
              </div>
              {renderTag('{{member_last_name}}', member_last_name)}
            </div>
            <div className="row">
              <div className="col-3  d-flex align-items-center">
                <Label>Member's Email Tag: </Label>
              </div>
              {renderTag('{{member_email}}', member_email)}
            </div>
            <div className="row">
              <div className="col-3  d-flex align-items-center">
                <Label>Campus Name Tag: </Label>
              </div>
              {renderTag('{{campus_name}}', campus_name)}
            </div>
            <div className="row">
              <div className="col-3  d-flex align-items-center">
                <Label>Community Name Tag: </Label>{' '}
              </div>
              {renderTag('{{community_name}}', community_name)}
            </div>
          </SubstitutionSection>
          <br />
          <Field
            name="welcome_email_from_email"
            label={'"From" Email Address'}
            description="The email address that shows as the 'from' for the member onboarding emails"
            component={CustomInputComponent}
          />
          <Row>
            <div className="col-12 col-lg-6">
              {' '}
              <LabelStyled>Header Text</LabelStyled>
              <br />
              <label>{'The text in the header of the onboarding email'}</label>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-6">
              <Field
                hideLabel
                name="welcome_email_header_text"
                component={CustomInputComponent}
              />
            </div>
            <div className="col-12 col-lg-6">
              {renderPreview({
                value: props.values.welcome_email_header_text,
                plaintext: true,
              })}
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-6">
              {' '}
              <LabelStyled>Greeting Text</LabelStyled>
              <br />
              <label>{'The first text in the body of the email'}</label>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-6">
              <Field
                hideLabel
                name="welcome_email_greeting_text"
                component={CustomRichTextComponent}
              />
            </div>
            <div className="col-12 col-lg-6  mt-lg-5">
              {' '}
              {renderPreview({
                value: props.values.welcome_email_greeting_text,
              })}
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-6">
              {' '}
              <LabelStyled>Body Text</LabelStyled>
              <br />
              <label>
                {'The text in the body under the app credentials box'}
              </label>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-6">
              <Field
                hideLabel
                name="welcome_email_body_text"
                component={CustomRichTextComponent}
              />
            </div>
            <div className="col-12 col-lg-6  mt-lg-5">
              {renderPreview({ value: props.values.welcome_email_body_text })}
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-6">
              {' '}
              <LabelStyled>Signature Text</LabelStyled>
              <br />
              <label>{'This is the text at the bottom of the email'}</label>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-6">
              <Field
                hideLabel
                name="welcome_email_signature_text"
                component={CustomRichTextComponent}
              />
            </div>
            <div className="col-12 col-lg-6 mt-lg-5">
              {renderPreview({
                value: props.values.welcome_email_signature_text,
              })}
            </div>
          </Row>
        </React.Fragment>
      ) : (
        <Alert color="info">
          <FontAwesomeIcon icon="paint-brush" /> Looking to customize your
          onboarding email? This is a premium feature that's available with the
          white-label package. It's an $99/month add-on.{' '}
          <TextButton
            className="p-0 m-0"
            onClick={() => {
              IntercomAPI(
                'showNewMessage',
                'I would like to add the white-labeled branding upgrade to my account.'
              )
            }}
          >
            I want white-labeled
          </TextButton>
        </Alert>
      )}
    </React.Fragment>
  )
}

OnboardingSettings.propTypes = {
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  user: PropTypes.object,
  member_actions: PropTypes.object,
  values: PropTypes.object,
}
OnboardingSettings.displayName = 'OnboardingSettings'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetching: state.ui.isFetching,
    campuses: state.ui.activeCommunity.campuses,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ui_actions: bindActionCreators(uiActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSettings)
