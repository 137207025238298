/* eslint-disable prefer-spread, no-constant-condition, react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import styled from 'styled-components'

import { Chart, Line, Pie, Doughnut, Bar } from 'react-chartjs-2'
import { NavLink, Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import '../../styles/coworks/components/dashboard/dashboard.scss'

import * as uiActions from '@reduxActions/uiActions'
import * as memberActions from '@reduxActions/memberActions'
import * as teamActions from '@reduxActions/teamActions'
import * as planActions from '@reduxActions/planActions'
import * as resourceActions from '@reduxActions/resourceActions'
import * as statsActions from '@reduxActions/statsActions'
import * as bookingActions from '@reduxActions/bookingActions'
import * as memberSelectors from '@reduxSelectors/memberSelectors'

import Spinner from '@global/Spinner'

import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'

import OttoPlaceholder from '../../img/placeholders/otto_black_white.jpeg'
import DashboardMiniCard from './DashboardMiniCard'
import DashboardMiniScrollview from './DashboardMiniScrollview'
import RoundedImage from '@global/RoundedImage'
import {
  resolveFetchingStatus,
  FETCHING_DASHBOARD_STATS,
} from '@global/Constants/FetchingConstants'

const CardStyled = styled.div`
  background-color: ${props =>
    props.color === 'primary'
      ? props.theme.colorPrimary
      : props.theme.colorSecondary};
`

const DashboardMiniCardStyled = styled(DashboardMiniCard)`
  width: 100%;
`
// eslint-disable-next-line react/display-name, react/prop-types
const renderChart = Component => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} />) ||
  (error && error.toString()) || <Spinner />

const numberRender = ({ resultSet }) =>
  resultSet.seriesNames().map(set =>
    DashboardMiniCard({
      loading: false,
      count: resultSet.totalRow()[set.key],
      text: 'Total Bookings',
      icon: 'calendar-alt',
    })
  )
const RecentItemTitleStyled = styled.div`
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: ${props => props.theme.colorPrimary} !important;
  :hover {
    color: ${props => props.theme.colorPrimaryHover}!important;
  }
`

export class Dashboard extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    members_actions: PropTypes.object,
    team_actions: PropTypes.object,
    booking_actions: PropTypes.object,
    plan_actions: PropTypes.object,
    resource_actions: PropTypes.object,
    stats_actions: PropTypes.object,
    members: PropTypes.array,
    teams: PropTypes.array,
    bookings: PropTypes.array,
    plans: PropTypes.array,
    resources: PropTypes.array,
    isFetching: PropTypes.bool,
    isFetchingStats: PropTypes.bool,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    dashboardStats: PropTypes.object,
    theme: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      loadedCampusId: this.props.activeCampus.id,
      isEvents: false,
      isPlans: false,
      isMembers: false,
      isResources: false,
      onboardingComplete: true,
      activeGrowthChartOneTab: 'member',
      activeGrowthChartTwoTab: 'booking',
    }
    Dashboard.drawChart = Dashboard.drawChart.bind(this)
    Constants.logO('Community:', this.props.activeCommunity)
    Constants.logO('Campus:', this.props.activeCampus)
    Constants.logO('User:', this.props.user)
  }
  componentDidMount() {
    this.updateData(this.props.activeCampus.id)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //todo replace with static getDerivedStateFromProps(props, state) {
    const activeCommunity = nextProps.activeCommunity
    if (
      nextProps.activeCampus &&
      nextProps.activeCampus.id !== this.state.loadedCampusId
    ) {
      this.setState(
        { loadedCampusId: nextProps.activeCampus.id },
        this.updateData(nextProps.activeCampus.id)
      )
    }
    if (
      activeCommunity &&
      activeCommunity.prefs &&
      activeCommunity.prefs.trial_end &&
      moment(activeCommunity.prefs.trial_end).isBefore(moment()) &&
      nextProps.dashboardStats
    ) {
      const alteredState = {
        isEvents: false,
        isPlans: false,
        isMembers: false,
        isResources: false,
      }
      const dashboardStatCounts = nextProps.dashboardStats.counts
      Constants.logM(dashboardStatCounts)
      if (dashboardStatCounts.members > 2) {
        alteredState.isMembers = true
      }
      if (dashboardStatCounts.events > 2) {
        alteredState.isEvents = true
      }
      if (dashboardStatCounts.plans > 2) {
        alteredState.isPlans = true
      }
      if (dashboardStatCounts.resources > 2) {
        alteredState.isResources = true
      }
      let status = true
      for (const key of Object.keys(alteredState)) {
        if (!alteredState[key]) {
          status = false
        }
      }
      alteredState.onboardingComplete = status
      this.setState(alteredState)
    }
    if (window.IS_UNAUTHORIZED) {
      this.props.history.push('/login')
    }
  }
  createEventClicked = () => {
    const type = 'Event'
    this.props.history.push('/calendar/add-event', {
      campusId: this.props.activeCampus.id,
      intendedType: type,
    })
  }
  updateData = campusId => {
    const date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth()
    const firstDayOfMonth = new Date(year, month, 1)
    const params = {
      campus_id: campusId,
      from_date: firstDayOfMonth.toISOString(),
      recent_count: 15,
    }
    if (!this.props.isFetchingStats) {
      this.props.stats_actions.getDashboardStats(params)
    }
  }
  // Graph Tab Logic
  handleGrowthOneClick = tab => {
    this.setState({
      activeGrowthChartOneTab: tab,
    })
  }
  handleGrowthTwoClick = tab => {
    this.setState({
      activeGrowthChartTwoTab: tab,
    })
  }
  static drawChart() {
    const canvz = document.getElementById('chartRevenue')
    if (canvz && canvz.dataset.drawn === 'false') {
      new Chart(canvz, {
        type: 'bar',
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
          datasets: [
            {
              label: 'Monthly Revenue',
              data: [2000, 3000, 2200, 3100, 3400, 4000],
              backgroundColor: [
                'rgba(38, 151, 150, .3)',
                'rgba(38, 151, 150, .3)',
                'rgba(38, 151, 150, .3)',
                'rgba(38, 151, 150, .3)',
                'rgba(38, 151, 150, .3)',
                'rgba(38, 151, 150, .3)',
                'rgba(38, 151, 150, .3)',
              ],
              borderColor: [
                'rgba(38, 151, 150, 1)',
                'rgba(38, 151, 150, 1)',
                'rgba(38, 151, 150, 1)',
                'rgba(38, 151, 150, 1)',
                'rgba(38, 151, 150, 1)',
                'rgba(38, 151, 150, 1)',
                'rgba(38, 151, 150, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          // responsive: true
        },
      })
    }
    const ctx = document.getElementById('chartMembers')
    if (ctx && ctx.dataset.drawn === 'false') {
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Communal', 'Dedicated Desks', 'Suites'],
          datasets: [
            {
              // label: 'Monthly Revenue',
              data: [200, 300, 220],
              backgroundColor: [
                'rgba(38, 151, 150, 1)',
                'rgba(37, 56, 71, 1)',
                'rgba(79, 95, 107, 1)',
              ],
            },
          ],
        },
        options: {
          responsive: true,
        },
      })
    }
  }

  viewEventClicked = booking => {
    this.props.history.push(`/calendar/event-detail?${booking.id}`, {
      event: booking,
    })
  }
  // Event & Bookings List View
  renderRecentEvents() {
    const stats = this.props.dashboardStats
    if (!stats) {
      return null
    }
    return stats.recents.events.map(booking => {
      return (
        <div key={`booking-${booking.id}`} className="listview-wrapper">
          <div className="listview-booking-wrapper ml-1">
            <div>
              <p className="listitem-title m-0 mt-1">{booking.name}</p>

              {/*TODO - the navigation doesn't pass correctly the event  <RecentItemTitleStyled className='m-0 mt-1' onClick={() => this.viewEventClicked(booking)}>{booking.name}</RecentItemTitleStyled> */}
              <p className="listitem-description mb-1">{booking.description}</p>
            </div>
            <div>
              <p className="listitem-event m-0 mt-1">Event Date</p>
              <p className="listitem-date mb-1">
                {moment(booking.start_time).format('MM-DD')} @{' '}
                {moment(booking.start_time).format('hh:mm A')}
              </p>
            </div>
          </div>
        </div>
      )
    })
  }

  viewTeamClicked = team => {
    NavigationConstants.navigateToTeam({
      history: this.props.history,
      match: this.props.match,
      team,
      tab: 'profile',
    })
  }
  // Team List View
  renderRecentTeams() {
    const stats = this.props.dashboardStats
    if (!stats) {
      return null
    }
    const teams = JSON.parse(JSON.stringify(stats.recents.teams))
    const sortedTeams = teams.sort((first, second) => {
      const date1 = first.joined_at ? first.joined_at : first.created_at
      const date2 = second.joined_at ? second.joined_at : second.created_at
      return Date.parse(date2) - Date.parse(date1)
    })
    return sortedTeams.map(team => {
      return (
        <div key={`team-${team.id}`} className="listview-wrapper">
          <div className="listview-container">
            <div className="team-profile-image-wrapper">
              <RoundedImage
                photo={team.team_photo}
                alt="Picture"
                className="img-responsive mr-3"
              />
            </div>
            <div>
              <RecentItemTitleStyled
                className="m-0 mt-1"
                onClick={() => this.viewTeamClicked(team)}
              >
                {team.name}
              </RecentItemTitleStyled>
              <p className="listitem-description mb-1">
                {moment(
                  team.joined_at ? team.joined_at : team.created_at
                ).format('MMM DD - hh:mm A')}
              </p>
            </div>
          </div>
        </div>
      )
    })
  }

  viewMemberClicked = member => {
    NavigationConstants.navigateToMember({
      history: this.props.history,
      match: this.props.match,
      member,
    })
  }
  // Member List View
  renderRecentMembers() {
    const stats = this.props.dashboardStats
    if (!stats) {
      return null
    }
    const members = JSON.parse(JSON.stringify(stats.recents.members))
    const sortedMembers = members.sort((first, second) => {
      const date1 = first.joined_at ? first.joined_at : first.created_at
      const date2 = second.joined_at ? second.joined_at : second.created_at
      return Date.parse(date2) - Date.parse(date1)
    })
    return sortedMembers.map(member => {
      return (
        <div key={`member-${member.id}`} className="listview-wrapper">
          <div className="listview-container">
            <div className="member-profile-image-wrapper">
              <RoundedImage
                photo={member.user_photo}
                alt="Picture"
                className="img-responsive member-profile-image"
              />
            </div>
            <div>
              <RecentItemTitleStyled
                className="m-0 mt-1"
                onClick={() => this.viewMemberClicked(member)}
              >
                {member.first_name} {member.last_name}
              </RecentItemTitleStyled>
              <p className="listitem-description mb-1">
                {member.team && member.team.name}
              </p>
            </div>
          </div>
        </div>
      )
    })
  }
  // Bar chart
  renderBookingsChart() {
    const dates = [
      moment().subtract(5, 'months'),
      moment().subtract(4, 'months'),
      moment().subtract(3, 'months'),
      moment().subtract(2, 'months'),
      moment().subtract(1, 'months'),
      moment(),
    ]
    const data = {
      labels: [
        dates[0].format('MMMM'),
        dates[1].format('MMMM'),
        dates[2].format('MMMM'),
        dates[3].format('MMMM'),
        dates[4].format('MMMM'),
        dates[5].format('MMMM'),
      ],
      datasets: [
        {
          label: 'Booking Count',
          backgroundColor: '#14a2a0',
          borderColor: 'rgba(255,255,255,.55)',
          borderWidth: 1,
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    }

    const bookingChartOpts = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              color: 'transparent',
              zeroLineColor: 'transparent',
            },
            ticks: {
              /*fontSize: 2,*/
              fontColor: '#FFF',
            },
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              display: false,
              min: 0,
              max: 100,
            },
          },
        ],
      },
      elements: {
        line: {
          borderWidth: 1,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    }

    return <Bar data={data} options={bookingChartOpts} />
  }

  // Doughnut chart
  renderRoomsChart() {
    const data = {
      labels: ['Dedicated Desk', 'Coworking', 'Suite'],
      datasets: [
        {
          data: [22, 15, 18],
          backgroundColor: ['#14a2a0', 'white', '#466377'],
          borderColor: 'transparent',
        },
      ],
    }

    const doughnutChartOpts = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: true,
        labels: {
          boxWidth: 15,
          fontColor: 'white',
        },
      },
      title: {
        display: true,
        text: 'most popular rooms to date',
        fontColor: 'white',
      },
    }

    return (
      <Doughnut
        data={data}
        width={30}
        height={30}
        options={doughnutChartOpts}
      />
    )
  }

  renderMemberGrowthChart = (theme, dashboardStats) => {
    if (!dashboardStats) {
      return null
    }
    const dates = [
      moment().subtract(5, 'months'),
      moment().subtract(4, 'months'),
      moment().subtract(3, 'months'),
      moment().subtract(2, 'months'),
      moment().subtract(1, 'months'),
      moment(),
    ]
    const growths = JSON.parse(dashboardStats.growth.members)
    // Card Chart 1
    const cardChartData1 = {
      labels: [
        dates[0].format('MMMM'),
        dates[1].format('MMMM'),
        dates[2].format('MMMM'),
        dates[3].format('MMMM'),
        dates[4].format('MMMM'),
        dates[5].format('MMMM'),
      ],
      datasets: [
        {
          label: 'Member Count',
          backgroundColor: theme.colorSecondaryHover,
          borderColor: 'rgba(255,255,255,.55)',
          data: [
            growths.month_minus_5,
            growths.month_minus_4,
            growths.month_minus_3,
            growths.month_minus_2,
            growths.month_minus_1,
            growths.this_month,
          ],
        },
      ],
    }

    const cardChartOpts1 = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              color: 'transparent',
              zeroLineColor: 'transparent',
            },
            ticks: {
              /*fontSize: 2,*/
              fontColor: '#FFF',
            },
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              display: false,
              min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
              max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
            },
          },
        ],
      },
      elements: {
        line: {
          borderWidth: 1,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    }
    return <Line data={cardChartData1} options={cardChartOpts1} height={70} />
  }
  renderTeamGrowthChart = (theme, dashboardStats) => {
    if (!dashboardStats) {
      return null
    }
    const growths = JSON.parse(dashboardStats.growth.teams)
    const dates = [
      moment().subtract(5, 'months'),
      moment().subtract(4, 'months'),
      moment().subtract(3, 'months'),
      moment().subtract(2, 'months'),
      moment().subtract(1, 'months'),
      moment(),
    ]
    // Card Chart 1
    const cardChartData1 = {
      labels: [
        dates[0].format('MMMM'),
        dates[1].format('MMMM'),
        dates[2].format('MMMM'),
        dates[3].format('MMMM'),
        dates[4].format('MMMM'),
        dates[5].format('MMMM'),
      ],
      datasets: [
        {
          label: 'Team Count',
          backgroundColor: theme.colorPrimaryHover,
          borderColor: 'rgba(255,255,255,.55)',
          data: [
            growths.month_minus_5,
            growths.month_minus_4,
            growths.month_minus_3,
            growths.month_minus_2,
            growths.month_minus_1,
            growths.this_month,
          ],
        },
      ],
    }

    const cardChartOpts1 = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              color: 'transparent',
              zeroLineColor: 'transparent',
            },
            ticks: {
              /*fontSize: 2,*/
              fontColor: '#FFF',
            },
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              display: false,
              min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
              max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
            },
          },
        ],
      },
      elements: {
        line: {
          borderWidth: 1,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    }
    return <Line data={cardChartData1} options={cardChartOpts1} height={70} />
  }
  renderBookingGrowthChart = (theme, dashboardStats) => {
    if (!dashboardStats) {
      return null
    }
    const dates = [
      moment().subtract(5, 'months'),
      moment().subtract(4, 'months'),
      moment().subtract(3, 'months'),
      moment().subtract(2, 'months'),
      moment().subtract(1, 'months'),
      moment(),
    ]
    const growths = JSON.parse(dashboardStats.growth.bookings)
    // Card Chart 1
    const cardChartData1 = {
      labels: [
        dates[0].format('MMMM'),
        dates[1].format('MMMM'),
        dates[2].format('MMMM'),
        dates[3].format('MMMM'),
        dates[4].format('MMMM'),
        dates[5].format('MMMM'),
      ],
      datasets: [
        {
          label: 'Booking Count',
          backgroundColor: theme.colorPrimaryHover,
          borderColor: 'rgba(255,255,255,.55)',
          data: [
            growths.month_minus_5,
            growths.month_minus_4,
            growths.month_minus_3,
            growths.month_minus_2,
            growths.month_minus_1,
            growths.this_month,
          ],
        },
      ],
    }

    const cardChartOpts1 = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              color: 'transparent',
              zeroLineColor: 'transparent',
            },
            ticks: {
              /*fontSize: 2,*/
              fontColor: '#FFF',
            },
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              display: false,
              min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
              max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
            },
          },
        ],
      },
      elements: {
        line: {
          borderWidth: 1,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    }
    return <Line data={cardChartData1} options={cardChartOpts1} height={70} />
  }
  renderEventGrowthChart = (theme, dashboardStats) => {
    if (!dashboardStats) {
      return null
    }
    const growths = JSON.parse(dashboardStats.growth.events)
    const dates = [
      moment().subtract(5, 'months'),
      moment().subtract(4, 'months'),
      moment().subtract(3, 'months'),
      moment().subtract(2, 'months'),
      moment().subtract(1, 'months'),
      moment(),
    ]
    // Card Chart 1
    const cardChartData1 = {
      labels: [
        dates[0].format('MMMM'),
        dates[1].format('MMMM'),
        dates[2].format('MMMM'),
        dates[3].format('MMMM'),
        dates[4].format('MMMM'),
        dates[5].format('MMMM'),
      ],
      datasets: [
        {
          label: 'Events Count',
          backgroundColor: theme.colorSecondaryHover,
          borderColor: 'rgba(255,255,255,.55)',
          data: [
            growths.month_minus_5,
            growths.month_minus_4,
            growths.month_minus_3,
            growths.month_minus_2,
            growths.month_minus_1,
            growths.this_month,
          ],
        },
      ],
    }

    const cardChartOpts1 = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              color: 'transparent',
              zeroLineColor: 'transparent',
            },
            ticks: {
              /*fontSize: 2,*/
              fontColor: '#FFF',
            },
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              display: false,
              min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
              max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
            },
          },
        ],
      },
      elements: {
        line: {
          borderWidth: 1,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    }
    return <Line data={cardChartData1} options={cardChartOpts1} height={70} />
  }
  renderBookingUsageGraph = dashboardStats => {
    if (!dashboardStats) {
      return null
    }
    const roomLabels = []
    const dataSet = []
    const backgroundColors = []
    dashboardStats.room_usage.map((obj, index) => {
      roomLabels.push(obj.room_name)
      dataSet.push(Number(obj.minutes_count / 60).toFixed(2))
      if (index <= Constants.bookingColors.length) {
        backgroundColors.push(Constants.bookingColors[index])
      } else {
        backgroundColors.push(Constants.bookingColors[0])
      }
    })

    const pieData = {
      labels: roomLabels,
      datasets: [
        {
          borderColor: 'rgba(255,255,255,.55)',
          data: dataSet,
          backgroundColor: backgroundColors,
        },
      ],
    }
    const pieChartOptions = {
      responsive: true,
      legend: {
        position: 'left',
      },
    }
    return <Pie data={pieData} options={pieChartOptions} height={70} />
  }
  /* eslint-disable max-len */
  // Top Chart tabs
  renderChartTabs(theme, activeTab, handleClick, isDirectory) {
    const option1 = isDirectory ? 'member' : 'booking'
    const option2 = isDirectory ? 'company' : 'event'
    return (
      <div
        className="btn-group btn-group-toggle btn-group-container"
        data-toggle="buttons"
      >
        <label
          className="btn btn-graph"
          onClick={() => handleClick(option1)}
          style={
            activeTab === option1
              ? {
                  backgroundColor: 'white',
                  color: !isDirectory
                    ? theme.colorPrimary
                    : theme.colorSecondary,
                }
              : { backgroundColor: 'transparent', color: 'white' }
          }
        >
          <input type="radio" name={option1} id="option1" autoComplete="off" />{' '}
          {isDirectory ? 'Member' : 'Bookings'}
        </label>
        <label
          className="btn btn-graph"
          onClick={() => handleClick(option2)}
          style={
            activeTab === option2
              ? {
                  backgroundColor: 'white',
                  color: !isDirectory
                    ? theme.colorSecondary
                    : theme.colorPrimary,
                }
              : { backgroundColor: 'transparent', color: 'white' }
          }
        >
          <input type="radio" name={option2} id="option2" autoComplete="off" />{' '}
          {isDirectory ? 'Team' : 'Events'}
        </label>
      </div>
    )
  }
  renderTabsOne(activeTab, handleClick, invertColors) {
    return (
      <div
        className="btn-group btn-group-toggle btn-group-container"
        data-toggle="buttons"
      >
        <label
          className="btn btn-graph"
          onClick={() => this.handleGrowthOneClick('member')}
          style={
            this.state.activeGrowthChartOneTab === 'member'
              ? {
                  backgroundColor: 'white',
                  color: invertColors
                    ? this.props.theme.colorPrimary
                    : this.props.theme.colorSecondary,
                }
              : { backgroundColor: 'transparent', color: 'white' }
          }
        >
          <input type="radio" name="member" id="option1" autoComplete="off" />{' '}
          Member
        </label>
        <label
          className="btn btn-graph"
          onClick={() => this.handleGrowthOneClick('company')}
          style={
            this.state.activeGrowthChartOneTab === 'company'
              ? {
                  backgroundColor: 'white',
                  color: invertColors
                    ? this.props.theme.colorPrimary
                    : this.props.theme.colorSecondary,
                }
              : { backgroundColor: 'transparent', color: 'white' }
          }
        >
          <input type="radio" name="company" id="option2" autoComplete="off" />{' '}
          Team
        </label>
      </div>
    )
  }
  renderTabsTwo() {
    return (
      <div
        className="btn-group btn-group-toggle btn-group-container"
        data-toggle="buttons"
      >
        <label
          className="btn btn-graph"
          onClick={() => this.handleGrowthTwoClick('booking')}
          style={
            this.state.activeGrowthChartTwoTab === 'booking'
              ? {
                  backgroundColor: 'white',
                  color: this.props.theme.colorPrimary,
                }
              : { backgroundColor: 'transparent', color: 'white' }
          }
        >
          <input type="radio" name="booking" id="option1" autoComplete="off" />{' '}
          Bookings
        </label>
        <label
          className="btn btn-graph"
          onClick={() => this.handleGrowthTwoClick('event')}
          style={
            this.state.activeGrowthChartTwoTab === 'event'
              ? {
                  backgroundColor: 'white',
                  color: this.props.theme.colorSecondary,
                }
              : { backgroundColor: 'transparent', color: 'white' }
          }
        >
          <input type="radio" name="event" id="option2" autoComplete="off" />{' '}
          Events
        </label>
      </div>
    )
  }
  // Onboarding
  renderProgressBar() {
    const { isEvents, isPlans, isMembers, isResources } = this.state
    const booleanArray = [isEvents, isPlans, isMembers, isResources]

    let value = 0

    booleanArray.map(boolean => {
      if (boolean === true) {
        value += 25
      }
    })

    return (
      <div
        className="progress-bar"
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${value}%` }}
      />
    )
  }

  renderOnboarding() {
    if (this.state.onboardingComplete) return null

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="card">
              <div className="card-header">
                <h5>Get Started</h5>
              </div>
              <div className="card-block">
                <div className="row my-2 mx-2">
                  <h2 className="">
                    Welcome {this.props.user.first_name}! Get started with
                    Coworks!{' '}
                  </h2>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    {this.state.isEvents ? (
                      <div className="card card-inverse text-center active-onboarding-card">
                        <div className="card-block">
                          <blockquote className="card-blockquote">
                            <FontAwesomeIcon icon={'check'} size="3x" />
                          </blockquote>
                          <footer>
                            <p className="active-onboarding-text">
                              Event Added!
                            </p>
                          </footer>
                        </div>
                      </div>
                    ) : (
                      <div className="card card-inverse text-center">
                        <div className="card-block">
                          <blockquote className="card-blockquote text-primary mb-3">
                            <FontAwesomeIcon icon="calendar-alt" size="3x" />
                          </blockquote>
                          <footer>
                            <Button
                              onClick={this.createEventClicked}
                              className="btn btn-primary btn-block"
                            >
                              Add an Event!
                            </Button>
                          </footer>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-md-3">
                    {this.state.isPlans ? (
                      <div className="card card-inverse text-center active-onboarding-card">
                        <div className="card-block">
                          <blockquote className="card-blockquote">
                            <FontAwesomeIcon icon={'check'} size="3x" />
                          </blockquote>
                          <footer>
                            <p className="active-onboarding-text">
                              Plan Added!
                            </p>
                          </footer>
                        </div>
                      </div>
                    ) : (
                      <div className="card card-inverse text-center">
                        <div className="card-block">
                          <blockquote className="card-blockquote text-primary  mb-3">
                            <FontAwesomeIcon
                              color="color-primary"
                              icon="credit-card"
                              size="3x"
                            />
                          </blockquote>
                          <footer>
                            <NavLink
                              to="/plans/add-plan"
                              className="btn btn-primary btn-block"
                            >
                              Add a Plan!
                            </NavLink>
                          </footer>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-md-3">
                    {this.state.isMembers ? (
                      <div className="card card-inverse text-center active-onboarding-card">
                        <div className="card-block">
                          <blockquote className="card-blockquote">
                            <FontAwesomeIcon icon={'check'} size="3x" />
                          </blockquote>
                          <footer>
                            <p className="active-onboarding-text">
                              Member Added!
                            </p>
                          </footer>
                        </div>
                      </div>
                    ) : (
                      <div className="card card-inverse text-center">
                        <div className="card-block">
                          <blockquote className="card-blockquote text-primary  mb-3">
                            <FontAwesomeIcon
                              color="color-primary"
                              icon={'users'}
                              size="3x"
                            />
                          </blockquote>

                          <footer>
                            <NavLink
                              to="/directory/teams/add-team"
                              className="btn btn-primary btn-block"
                            >
                              Add a Member!
                            </NavLink>
                          </footer>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-md-3">
                    {this.state.isResources ? (
                      <div className="card card-inverse text-center active-onboarding-card">
                        <div className="card-block">
                          <blockquote className="card-blockquote">
                            <FontAwesomeIcon icon={'check'} size="3x" />
                          </blockquote>
                          <footer>
                            <p className="active-onboarding-text">
                              Resource Added!
                            </p>
                          </footer>
                        </div>
                      </div>
                    ) : (
                      <div className="card card-inverse text-center">
                        <div className="card-block">
                          <blockquote className="card-blockquote text-primary  mb-3">
                            <FontAwesomeIcon icon={'coffee'} size="3x" />
                          </blockquote>
                          <footer>
                            <NavLink
                              to="/resources"
                              className="btn btn-primary btn-block"
                            >
                              Add a Resource!
                            </NavLink>
                          </footer>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="progress" style={{ borderRadius: '5px' }}>
                  {this.renderProgressBar()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { dashboardStats, theme } = this.props
    return (
      <div>
        {this.renderOnboarding()}
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="row">
              <div className="col-sm-6">
                <CardStyled
                  color={
                    this.state.activeGrowthChartOneTab === 'member'
                      ? 'secondary'
                      : 'primary'
                  }
                  className="card card-inverse"
                >
                  <div className="card-block pb-0">
                    <h4 className="mb-0">
                      {this.state.activeGrowthChartOneTab === 'member'
                        ? 'Member'
                        : 'Team'}{' '}
                      Growth
                    </h4>
                    <p className="text-muted">by Month</p>
                    <div className="tabs-container">
                      {this.renderChartTabs(
                        theme,
                        this.state.activeGrowthChartOneTab,
                        this.handleGrowthOneClick,
                        true
                      )}
                    </div>
                  </div>
                  {this.props.isFetching ? (
                    <Spinner />
                  ) : (
                    <div className="chart-wrapper px-3">
                      {this.state.activeGrowthChartOneTab === 'member'
                        ? this.renderMemberGrowthChart(theme, dashboardStats)
                        : this.renderTeamGrowthChart(theme, dashboardStats)}
                    </div>
                  )}
                </CardStyled>
              </div>
              <div className="col-sm-6">
                <CardStyled
                  color={
                    this.state.activeGrowthChartTwoTab === 'event'
                      ? 'secondary'
                      : 'primary'
                  }
                  className="card card-inverse"
                >
                  <div className="card-block pb-0">
                    <h4 className="mb-0">
                      {' '}
                      {this.state.activeGrowthChartTwoTab === 'event'
                        ? 'Event'
                        : 'Booking'}{' '}
                      Growth
                    </h4>
                    <p className="text-muted">by Month</p>
                    <div className="tabs-container">
                      {this.renderChartTabs(
                        theme,
                        this.state.activeGrowthChartTwoTab,
                        this.handleGrowthTwoClick,
                        false
                      )}
                    </div>
                  </div>
                  {this.props.isFetching ? (
                    <Spinner />
                  ) : (
                    <div className="chart-wrapper px-3">
                      {this.state.activeGrowthChartTwoTab === 'event'
                        ? this.renderEventGrowthChart(theme, dashboardStats)
                        : this.renderBookingGrowthChart(theme, dashboardStats)}
                    </div>
                  )}
                </CardStyled>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12 col-md-12">
                <div className="row">
                  <div className="col-sm-6 col-lg-3">
                    <DashboardMiniCardStyled
                      loading={this.props.isFetching}
                      count={dashboardStats && dashboardStats.counts.members}
                      text={'Members'}
                      icon={'users'}
                    />
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <DashboardMiniCardStyled
                      loading={this.props.isFetching}
                      count={dashboardStats && dashboardStats.counts.teams}
                      text={'Teams'}
                      icon={'briefcase'}
                    />
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <DashboardMiniCardStyled
                      loading={this.props.isFetching}
                      count={dashboardStats && dashboardStats.counts.bookings}
                      text={'Bookings'}
                      icon={'calendar-alt'}
                    />
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <DashboardMiniCardStyled
                      loading={this.props.isFetching}
                      count={dashboardStats && dashboardStats.counts.events}
                      text={'Events'}
                      icon={'beer'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card pb-4">
                  <div className="card-block pb-0">
                    <h4 className="mb-0">Conference Room Usage</h4>
                    <p className="text-muted">Hours per Month</p>
                  </div>
                  {this.props.isFetching ? (
                    <Spinner />
                  ) : (
                    <div className="chart-wrapper px-3 pb-2">
                      {this.renderBookingUsageGraph(dashboardStats)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*<div className='row rooms-row-container'>
              <div className='col-sm-12 col-md-12 col-lg-8'>
                <div className='card card-inverse card-primary room-columns'>
                  <div className='card-block pb-0'>
                    <h4 className='mb-0'>Bookings</h4>
                    <p className='text-muted'>by Month</p>
                  </div>
                  {this.props.isFetching ? <Spinner /> :
                  <div className='chart-wrapper px-3'>
                    {this.renderBookingsChart()}
                  </div>}
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-4'>
                <div className='card card-inverse card-brand-secondary room-columns'>
                  <div className='card-block pb-0'>
                    <h4 className='mb-0'>Rooms</h4>
                    <p className='text-muted'>by Most Booked</p>
                  </div>
                  {this.props.isFetching ? <Spinner /> :
                  <div className='chart-wrapper rooms-chart-wrapper px-2 pb-4'>
                    {this.renderRoomsChart()}
                  </div>}
                </div>
              </div>
            </div>*/}
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="row">
                  <div className="col-sm-12 col-lg-4">
                    {DashboardMiniScrollview({
                      loading: this.props.isFetching,
                      renderItems: () => this.renderRecentEvents(),
                      title: 'Upcoming Events',
                      linkDestination: '/calendar',
                    })}
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    {DashboardMiniScrollview({
                      loading: this.props.isFetching,
                      renderItems: () => this.renderRecentTeams(),
                      title: 'Recent Teams',
                      linkDestination: '/directory/teams',
                    })}
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    {DashboardMiniScrollview({
                      loading: this.props.isFetching,
                      renderItems: () => this.renderRecentMembers(),
                      title: 'Recent Members',
                      linkDestination: '/directory/members',
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  /* eslint-enable max-len */
}
Dashboard.displayName = 'Dashboard'

const mapStateToProps = state => {
  return {
    user: state.user,
    teams: state.teams.teams ? state.teams.teams : [],
    members: memberSelectors.getMemberList(state),
    bookings: state.bookings.bookings,
    isFetching: state.ui.isFetching,
    isFetchingStats: resolveFetchingStatus(state, FETCHING_DASHBOARD_STATS),
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    plans: state.plans,
    resources: state.resources,
    dashboardStats: state.stats.dashboard,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ui_actions: bindActionCreators(uiActions, dispatch),
    members_actions: bindActionCreators(memberActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    booking_actions: bindActionCreators(bookingActions, dispatch),
    plan_actions: bindActionCreators(planActions, dispatch),
    resource_actions: bindActionCreators(resourceActions, dispatch),
    stats_actions: bindActionCreators(statsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
