/* eslint-disable no-unused-vars,max-depth,max-statements */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
} from 'react-router-dom'

import * as stripeActions from '@reduxActions/stripeActions'
import * as teamActions from '@reduxActions/teamActions'
import BillingTab from './BillingTab'

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import NothingFound from '@global/NothingFound'
import Badge from '@global/Base/Badge/Badge'
import {
  resolveFetchingStatus,
  FETCHING_TEAMS,
  FETCHING_INVOICES_PAID,
  FETCHING_INVOICES_UNPAID,
  FETCHING_INVOICES_CLOSED,
  FETCHING_INVOICES_PAUSED,
} from '@global/Constants/FetchingConstants'
function Billing({
  history,
  location,
  user,
  teams,
  stripeTeams,
  inactiveTeams,
  team,
  charges,
  invoices,
  manualInvoices,
  stripe_actions,
  team_actions,
  activeCommunity,
  activeCampus,
  isFetchingTeams,
  isFetchingInvoices,
  isFetchingTransactions,
  isInactive,
  handleLastInvoiceReceived,
  handleTotalInvoiceReceived,
  shouldRefreshPayments,
  unpaidInvoices,
  markedAsPaid,
  closedInvoices,
  isFetchingInvoicesUnpaid,
  isFetchingInvoicesPaid,
  isFetchingInvoicesClosed,
  isFetchingTransactionsSuccessful,
  transactions,
  isFetchingTransactionsPending,
  pendingTransactions,
  isFetchingTransactionsFailed,
  failedTransactions,
}) {
  const { path, url } = useRouteMatch()
  // let { subTabId, tabId } = useParams()

  const [disableActions, setDisableActions] = useState(Boolean(isInactive))
  const [unpaidInvoicesCount, setUnpaidInvoicesCount] = useState(null)
  const [pendingTransactionsCount, setPendingTransactionsCount] = useState(null)
  const [shouldRefresh, setShouldRefresh] = useState(false)

  useEffect(() => {
    if (!teams && !isFetchingTeams) {
      team_actions.getTeams(activeCampus.id, {})
    }
    return () => {
      stripe_actions.clearStripePaymentData()
    }
  }, [])

  const handleDetailClicked = (obj, team) => {
    const stateObj = {
      team,
    }
    if (obj.object === 'charge') {
      stateObj.charge = obj
    } else if (obj.object === 'invoice') {
      stateObj.invoice = obj
    }
    history.push(`/billing/invoices/${obj.id}`, stateObj)
  }

  const toggleTab = tab => {
    history.push(`${url}/${tab}`, { team })
  }
  function compareTeamWithStripeObject(team, stripeObject) {
    if (
      (stripeObject.customer &&
        typeof stripeObject.customer === 'object' &&
        team.stripe_customer_id === stripeObject.customer.id) ||
      (stripeObject.customer &&
        typeof stripeObject.customer === 'string' &&
        team.stripe_customer_id === stripeObject.customer)
    ) {
      return true
    }
    return false
  }
  const updateUnpaidInvoicesCount = count => {
    if (count > 0) {
      setUnpaidInvoicesCount(count)
    }
  }
  const updatePendingTransactionsCount = count => {
    if (count > 0) {
      setPendingTransactionsCount(count)
    }
  }

  const getTeamFromStripeObject = stripeObject => {
    let teams = stripeTeams
    //attempting to map to active teams
    if (teams) {
      for (let counter = 0; counter < teams.length; counter++) {
        if (compareTeamWithStripeObject(teams[counter], stripeObject)) {
          return teams[counter]
        }
      }
    }
    // attempting to map to inactive teams
    teams = inactiveTeams
    if (teams) {
      for (let counter = 0; counter < teams.length; counter++) {
        if (compareTeamWithStripeObject(teams[counter], stripeObject)) {
          return teams[counter]
        }
      }
    }
    return null
  }

  if (!activeCommunity.stripe_account_id) {
    return (
      <NothingFound
        renderInCard
        displayText="Whoops, this is a billing-only feature!"
      />
    )
  }

  return (
    <div className="animated fadeIn">
      {team && (
        <div className="row">
          <div className="mt-3 ml-3 col-10">
            <h2 className="text-primary">{team.name}</h2>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 mb-4">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: location.pathname.includes('invoices'),
                })}
                onClick={() => {
                  toggleTab('invoices')
                }}
              >
                Invoices{' '}
                {unpaidInvoicesCount ? (
                  <Badge color="warning" size="small">
                    {unpaidInvoicesCount}
                  </Badge>
                ) : null}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: location.pathname.includes('transactions'),
                })}
                onClick={() => {
                  toggleTab('transactions')
                }}
              >
                Transactions{' '}
                {pendingTransactionsCount ? (
                  <Badge color="info" size="small">
                    {pendingTransactionsCount}
                  </Badge>
                ) : null}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent>
            <Switch>
              <Route exact path={`${path}`}>
                <Redirect
                  to={{
                    pathname: `${url}/invoices`,
                    state: { team },
                  }}
                />
              </Route>
              <Route path={`${path}/invoices`}>
                {location.pathname.includes('invoices') && (
                  <TabPane>
                    <BillingTab
                      history={history}
                      location={location}
                      shouldRefreshPayments={shouldRefreshPayments}
                      sectionName={'Invoices'}
                      statusOptions={[
                        {
                          type: 'Unpaid',
                          isLoading: isFetchingInvoicesUnpaid,
                          data: unpaidInvoices,
                          dataResolver: data => data.invoices,
                          apiQuery: stripe_actions.getInvoices,
                          apiParams: {
                            stripe_account_id:
                              activeCommunity.stripe_account_id,
                            limit: 20,
                            expand: JSON.stringify([
                              'data.customer',
                              'data.charge',
                            ]),
                            closed: false,
                            draft: false,
                            paid: false,
                            include: JSON.stringify(['total_count']),
                          },
                          tooltipDescription:
                            'Unpaid will show you all invoices that have not been collected.',
                          objectCount: unpaidInvoicesCount,
                          setCount: updateUnpaidInvoicesCount,
                        },
                        {
                          type: 'Paid',
                          isLoading: isFetchingInvoicesPaid,
                          data: markedAsPaid,
                          dataResolver: data => data.invoices,
                          apiQuery: stripe_actions.getInvoices,
                          apiParams: {
                            stripe_account_id:
                              activeCommunity.stripe_account_id,
                            limit: 20,
                            expand: JSON.stringify([
                              'data.customer',
                              'data.charge',
                            ]),
                            // closed: true,
                            // paid: true,
                            // charge == nil
                            draft: false,
                            status: 'paid',
                            include: JSON.stringify(['total_count']),
                          },
                          tooltipDescription:
                            'Paid will show you invoices that are paid by the customer and also marked as paid.',
                        },
                        {
                          type: 'Closed',
                          isLoading: isFetchingInvoicesClosed,
                          data: closedInvoices,
                          dataResolver: data => data.invoices,
                          apiQuery: stripe_actions.getInvoices,
                          apiParams: {
                            stripe_account_id:
                              activeCommunity.stripe_account_id,
                            limit: 20,
                            expand: JSON.stringify([
                              'data.customer',
                              'data.charge',
                            ]),
                            //closed: true,
                            // draft: false,
                            // paid: false,
                            //status: 'open',
                            status: 'void',
                            include: JSON.stringify(['total_count']),
                          },
                          tooltipDescription:
                            'Closed will show you all closed invoices, which have not collected funds. These are voided invoices, but leave a paper trail.',
                        },
                        {
                          type: 'Paused',
                          isLoading: isFetchingInvoicesClosed,
                          data: closedInvoices,
                          dataResolver: data => data.invoices,
                          apiQuery: stripe_actions.getInvoices,
                          apiParams: {
                            stripe_account_id:
                              activeCommunity.stripe_account_id,
                            limit: 20,
                            expand: JSON.stringify([
                              'data.customer',
                              'data.charge',
                            ]),
                            closed: true,
                            status: 'open',
                            include: JSON.stringify(['total_count']),
                          },
                          tooltipDescription:
                            'Closed will show you all closed invoices, which have not collected funds. These are voided invoices, but leave a paper trail.',
                        },
                      ]}
                      team={team}
                      getTeamFromStripeObject={getTeamFromStripeObject}
                      handleDetailClicked={handleDetailClicked}
                      disableActions={disableActions}
                    />
                  </TabPane>
                )}
              </Route>
              <Route path={`${path}/transactions`}>
                {location.pathname.includes('transactions') && (
                  <TabPane>
                    <BillingTab
                      history={history}
                      location={location}
                      shouldRefreshPayments={shouldRefreshPayments}
                      sectionName={'Transactions'}
                      statusOptions={[
                        {
                          type: 'Successful',
                          isLoading: isFetchingTransactionsSuccessful,
                          data: transactions,
                          dataResolver: data => data.charges,
                          apiQuery: stripe_actions.getTransactions,
                          apiParams: {
                            stripe_account_id:
                              activeCommunity.stripe_account_id,
                            limit: 20,
                            expand: JSON.stringify([
                              'data.customer',
                              'data.invoice',
                            ]),
                            paid: true,
                            include: JSON.stringify(['total_count']),
                          },
                          tooltipDescription:
                            'Transactions will show you all money that has been collected on behalf of invoices and one-off payments.',
                        },
                        {
                          type: 'Pending',
                          isLoading: isFetchingTransactionsPending,
                          data: pendingTransactions,
                          dataResolver: data => data.charges,
                          apiQuery: stripe_actions.getTransactions,
                          apiParams: {
                            stripe_account_id:
                              activeCommunity.stripe_account_id,
                            limit: 20,
                            expand: JSON.stringify([
                              'data.customer',
                              'data.invoice',
                            ]),
                            status: 'pending',
                            paid: false,
                            include: JSON.stringify(['total_count']),
                          },
                          tooltipDescription:
                            'Pending will show you all pending attempts of an invoice or one-off charge to be collected from the recipient.',
                          objectCount: pendingTransactionsCount,
                          setCount: setPendingTransactionsCount,
                        },
                        {
                          type: 'Failed',
                          isLoading: isFetchingTransactionsFailed,
                          data: failedTransactions,
                          dataResolver: data => data.charges,
                          apiQuery: stripe_actions.getTransactions,
                          apiParams: {
                            stripe_account_id:
                              activeCommunity.stripe_account_id,
                            limit: 20,
                            expand: JSON.stringify([
                              'data.customer',
                              'data.invoice',
                            ]),
                            status: 'failed',
                            paid: false,
                            include: JSON.stringify(['total_count']),
                          },
                          tooltipDescription:
                            'Closed will show you all closed invoices, which have not collected funds. These are voided invoices, but leave a paper trail.',
                        },
                      ]}
                      team={team}
                      getTeamFromStripeObject={getTeamFromStripeObject}
                      handleDetailClicked={handleDetailClicked}
                      disableActions={disableActions}
                    />
                  </TabPane>
                )}
              </Route>
            </Switch>
          </TabContent>
        </div>
      </div>
    </div>
  )
}

Billing.displayName = 'Billing'
Billing.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  teams: PropTypes.array,
  stripeTeams: PropTypes.array,
  inactiveTeams: PropTypes.array,
  team: PropTypes.object, // the determines whether or not this page is nested in a team detail or not
  charges: PropTypes.object,
  invoices: PropTypes.object,
  stripe_actions: PropTypes.object,
  team_actions: PropTypes.object,
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  isFetchingTeams: PropTypes.bool,
  isFetchingInvoices: PropTypes.bool,
  isFetchingTransactions: PropTypes.bool,
  isInactive: PropTypes.bool, // determines whther or not the reduxActions should be disabled.
  handleLastInvoiceReceived: PropTypes.func, // this helps parent know the last charge price
  handleTotalInvoiceReceived: PropTypes.func, // this helps parent know the total charge price
  manualInvoices: PropTypes.object,
  unpaidInvoices: PropTypes.object,
  closedInvoices: PropTypes.object,
  markedAsPaid: PropTypes.object,
  transactions: PropTypes.object,
  failedTransactions: PropTypes.object,
  pendingTransactions: PropTypes.object,
  shouldRefreshPayments: PropTypes.bool,
  isFetchingInvoicesUnpaid: PropTypes.bool,
  isFetchingInvoicesPaid: PropTypes.bool,
  isFetchingInvoicesClosed: PropTypes.bool,
  isFetchingTransactionsSuccessful: PropTypes.bool,
  isFetchingTransactionsPending: PropTypes.bool,
  isFetchingTransactionsFailed: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    user: state.user,
    charges: state.stripe.charges,
    invoices: state.stripe.invoices,
    teams: state.teams.teams,
    stripeTeams: state.stripe.teams,
    inactiveTeams: state.teams.inactive,
    manualInvoices: state.stripe.manualInvoices,
    unpaidInvoices: state.stripe.unpaidInvoices,
    markedAsPaid: state.stripe.markedAsPaid,
    closedInvoices: state.stripe.closedInvoices,
    isFetchingTeams: resolveFetchingStatus(state, FETCHING_TEAMS),
    isFetchingInvoicesPaid: resolveFetchingStatus(
      state,
      FETCHING_INVOICES_PAID
    ),
    isFetchingInvoicesUnpaid: resolveFetchingStatus(
      state,
      FETCHING_INVOICES_UNPAID
    ),
    isFetchingInvoicesClosed: resolveFetchingStatus(
      state,
      FETCHING_INVOICES_CLOSED
    ),
    transactions: state.stripe.transactions,
    failedTransactions: state.stripe.failedTransactions,
    pendingTransactions: state.stripe.pendingTransactions,
    isFetchingTransactionsFailed: state.ui.fetching.transactionsFailed,
    isFetchingTransactionsPending: state.ui.fetching.transactionsPending,
    isFetchingTransactionsSuccessful: state.ui.fetching.transactionsSuccessful,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    stripe_actions: bindActionCreators(stripeActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)
