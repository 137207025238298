import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import PropTypes from 'prop-types'
import 'react-phone-number-input/style.css'
import { ErrorMessage, getIn } from 'formik'

import { LabelStyled, InputStyled } from './FormComponents'
const PhoneInputField = ({
  className = '',
  field: { name, value },
  form: { errors, handleBlur, setFieldValue, touched },
  form,
  label = '',
  defaultCountry = 'US',

  disabled = false,
  isEditing = false,
  primaryColor,
}) => {
  const [isFocused, setFocused] = useState(false)
  const filledStyle = isFocused || value ? 'filled' : ''
  const disabledStyle = disabled ? 'disabled' : ''

  const handleInputBlur = event => {
    setFocused(false)
    handleBlur(event)
  }

  const handleInputFocus = () => setFocused(true)

  const onValueChange = phoneNumber => {
    if (phoneNumber) {
      setFieldValue(name, phoneNumber)
    }
  }
  return (
    <div className={`${className} ${filledStyle} ${disabledStyle} form-group`}>
      <LabelStyled isEditing={isEditing} htmlFor={name}>
        {label}
      </LabelStyled>

      <PhoneInput
        name={name}
        inputComponent={InputStyled}
        international
        placeholder="Enter phone number"
        countryCallingCodeEditable={false}
        value={value}
        onChange={onValueChange}
        defaultCountry={defaultCountry}
      />
      <ErrorMessage name={name}>
        {msg => <div className="text-danger">{msg}</div>}
      </ErrorMessage>
    </div>
  )
}

PhoneInputField.propTypes = {
  className: PropTypes.string,
  form: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultCountry: PropTypes.string,
  primaryColor: PropTypes.string,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
}

PhoneInputField.displayName = 'Phone Number Field'

export default PhoneInputField
