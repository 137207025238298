import React from 'react'
import PropTypes from 'prop-types'
import NothingFound from './NothingFound'
import * as Sentry from '@sentry/browser'
import { Button } from '@global/Base/Button/ButtonStyled'
import Placeholder from '@global/Placeholder'
import { withTheme } from 'styled-components'
class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
    renderFallbackComponent: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    Sentry.withScope(scope => {
      scope.setExtras(info)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
    console.error('Encountered an error!!', error)
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }
  provideAddlFeeback = () => {
    Sentry.showReportDialog({ eventId: this.state.eventId })
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (!this.props.renderFallbackComponent) {
        return null
      }
      return (
        <div>
          <Placeholder
            title={
              'Whoops, look like something went wrong. I have already notified the Coworks team!'
            }
            icon={'heart-broken'}
            color={this.props.theme.colorPrimary}
            buttonText={'Want to share some additional information?'}
            subtext2={"We're just as sad as you are."}
            handleClick={this.provideAddlFeeback}
          />
        </div>
      )
    }
    return this.props.children
  }
}
ErrorBoundary.defaultProps = {
  renderFallbackComponent: true,
}

ErrorBoundary.displayName = 'ErrorBoundary'
export default withTheme(ErrorBoundary)
