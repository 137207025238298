import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { withRouter, Route, Switch } from 'react-router'
import classnames from 'classnames'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import * as _ from 'lodash'
import { Row, Collapse, Col } from 'reactstrap'
import moment from 'moment'
import {
  resolveFetchingStatus,
  FETCHING_GROUPS,
} from '@global/Constants/FetchingConstants'

import {
  stripNullString,
  stripPhotoObjects,
  logO,
  resolveDomainPrefix,
} from '@global/Constants'
import * as Roles from '@app/config/roles'
import Placeholder from '@global/Placeholder'

import Spinner from '@global/Spinner'
import { extractCoworksErrorObject } from '@global/ErrorFactory'
import Button, { MenuButton } from '@global/Base/Button/ButtonStyled'
import ErrorFocus from '@global/Form/ErrorFocus'

import DynamicGroupFormModal from './DynamicGroupFormModal'
// todo update group in redux

function DynamicGroups({
  history,
  location,
  match,
  activeCommunity,
  activeCampus,
  isFetching,
}) {
  // refs are being weird so this is what works! const
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [editingGroup, setEditingGroup] = useState(null)
  const toggle = () => setCollapse(!collapse)
  const toggleShowCreateGroupModal = () => {
    if (showCreateGroupModal) setEditingGroup(null)
    setShowCreateGroupModal(!showCreateGroupModal)
  }

  if (isFetching) return <Spinner />

  return (
    <div className="animated fadeIn">
      <div className="col-md-12">
        <div className="row border-bottom-1 my-3 d-flex justify-space-between align-items-center">
          <div className="col">
            <h2>Custom Dropdowns</h2>
          </div>
          <div className="col d-flex justify-content-end">
            <Button onClick={toggleShowCreateGroupModal}>New Dropdown</Button>
          </div>
        </div>
      </div>

      <Row>
        {/* {activeCommunity.dynamic_groups &&
          activeCommunity.dynamic_groups.length === 0 && (
            <Placeholder
              title={`No Custom Dropdowns`}
              subtext1={'You have not created any custom fields.'}
            />
          )} */}
        <Col>
          {activeCommunity.dynamic_groups &&
            activeCommunity.dynamic_groups.map(group => {
              return (
                <Row>
                  <Col>
                    <div className="card p-3 m-2">
                      <Row>
                        <Col xs="9">
                          <h4>{group.label}</h4>
                        </Col>
                        <Col
                          xs="3"
                          className="d-flex align-items-center justify-content-end"
                        >
                          <Button
                            type="button"
                            onClick={() => {
                              setEditingGroup(group)
                              toggleShowCreateGroupModal()
                            }}
                            color="white"
                          >
                            Edit
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>{formatGroupType(group.type)}</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )
            })}
        </Col>
      </Row>
      <DynamicGroupFormModal
        activeCampus={activeCampus}
        activeCommunity={activeCommunity}
        dynamicGroup={editingGroup}
        showCreateGroupModal={showCreateGroupModal}
        toggleCreateGroupModal={toggleShowCreateGroupModal}
      />
    </div>
  )
}

function formatGroupType(type) {
  if (type === 'Profile') return 'Lead/Member'
  if (type === 'Contact,Profile') return 'Lead/Member'
  if (type === 'Contact') return 'Lead/Member'
  if (type === 'Lead') return 'Member/Lead'
  return type
}

DynamicGroups.displayName = 'DynamicGroups'

DynamicGroups.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  preference: PropTypes.object,
  ui_actions: PropTypes.object,
  role: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
}
export default DynamicGroups
