import React, { useEffect, useState } from 'react'
import { captureException } from '@global/ErrorFactory'

function useCountryCodeLookup(defaultCountryCode = 'US') {
  const [countryCode, setCountryCode] = useState(defaultCountryCode)

  useEffect(() => {
    // lookup user's IP to get the country for the phone number input
    fetch('https://extreme-ip-lookup.com/json/')
      .then(res => res.json())
      .then(response => {
        if (response && response.countryCode) {
          setCountryCode(response.countryCode)
        }
      })
      .catch(err => {
        captureException({
          text: `Ip lookup failed`,
          error: err,
        })
      })
  }, [])
  // Return enabled state and setter
  return [countryCode, setCountryCode]
}
export default useCountryCodeLookup
