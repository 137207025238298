import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import {
  TextButton,
  IconButton as OldButton,
} from '@bit/coworks.base-components.button'
import { Header } from '@bit/coworks.base-components.dropdown-item'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { theme } from '@global/Base/baseComponentColorUtils'

const NotificationsMenuContainer = styled.div`
  padding: 0px;
  border-radius: 4px;
  background: ${theme('colors.white')};
  border: ${theme('values.outlines.outline')} ${theme('colors.neutral4')};
  z-index: 9999;
`

const IconButton = styled(OldButton)`
  outline: none !important;
`

const NotificationHeader = styled.div`
  display: flex;
  height: 35px;
  align-items: center;
  padding-right: 10px;
  overflow-x: hidden !important;
  width: 400px;
  border-bottom: ${theme('values.outlines.outline')} ${theme('colors.neutral4')};
  top: 0;
`

const NotificationsHeaderLabel = styled(Header)`
  display: flex;
  padding-top: 0px;
  height: auto;
  align-items: center;
  flex-grow: 1;
`

const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 0px 0px 4px 4px;
`

const Gap = styled.div`
  width: ${props => props.width}px;
`

function NotificationMenu({
  children,
  handleClickRefresh,
  handleClickMarkAllRead,
  handleClickSettings,
}) {
  return (
    <NotificationsMenuContainer>
      <NotificationHeader>
        <NotificationsHeaderLabel>
          Notifications
          <Gap width={5} />
          <IconButton
            color="primary"
            size="small"
            icon={['fal', 'sync']}
            onClick={handleClickRefresh}
          />
        </NotificationsHeaderLabel>
        <IconButton
          onClick={handleClickMarkAllRead}
          color="primary"
          size="small"
          icon={['fal', 'envelope-open-text']}
        />
        <Gap width={5} />
        <IconButton
          onClick={handleClickSettings}
          color="primary"
          size="small"
          icon={['fal', 'cog']}
        />
      </NotificationHeader>
      <NotificationsContainer>{children}</NotificationsContainer>
    </NotificationsMenuContainer>
  )
}

NotificationMenu.displayName = 'NotificationMenu'
NotificationMenu.propTypes = {
  children: PropTypes.array,
  handleClickRefresh: PropTypes.func.isRequired,
  handleClickMarkAllRead: PropTypes.func.isRequired,
  handleClickSettings: PropTypes.func.isRequired,
}

export default NotificationMenu
