import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  resolveColor,
  resolveHoverColor,
  resolveButtonTextColor,
  resolveBorderColor,
} from '@global/Base/baseComponentColorUtils'

import Button, { IconButton } from '@bit/coworks.base-components.button'

const Container = styled.div`
  display: flex;
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  padding: 10px;
  flex-flow: row nowrap;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
`
const ImageContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`
const Image = styled.div`
  width: 180px;
  height: 120px;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-position: center;
`
const Body = styled.div`
  flex: 1;
  margin: 0 10px;
`
const Detail = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`

const Title = styled.h5`
  display: flex;
  flex-flow: row nowrap;
  font-weight: bold;
`
const Clickable = styled.div`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
const Subtitle = styled.h6``
const Accomodates = styled.div``
const PillsContainer = styled.div``

const PriceContainer = styled.div`
  display: flex;
`
const PriceWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
`
const Amount = styled.h5`
  font-weight: bold;
  margin: 0;
`
const Available = styled.p`
  display: ${props => (props.available ? 'flex' : 'none')};
  align-items: flex-end;
  font-size: 14px;
  margin: 0;
  margin-bottom: 3px;
  margin-left: 10px;
  color: ${props => props.theme.colorWarning};
`
const ButtonStyled = styled(Button)``
const Rate = styled.p`
  margin: 0;
`

const ListCard = ({ room, img, onClick, currencySymbol, color, ...other }) => {
  const {
    name,
    type,
    capacity,
    external_hourly_rate,
    price_day,
    available,
  } = room
  let roomType = type
  if (roomType === 'Conference') roomType = 'Conference Room'
  return (
    <Container {...other}>
      <Wrapper>
        <ImageContainer onClick={onClick}>
          <Image src={img} />
        </ImageContainer>
        <Body>
          <Title>
            <Clickable onClick={onClick}>{name}</Clickable>
            <Available available={!available}>
              {/* <FontAwesomeIcon icon={'check'} /> */}
              {` `}Unavailable
            </Available>
          </Title>
          <Subtitle>{roomType}</Subtitle>
          <Accomodates>
            <FontAwesomeIcon icon={'users'} />
            {` `}
            {capacity}
          </Accomodates>
        </Body>
        <Detail>
          <PriceContainer>
            <PriceWrapper>
              <Amount>{`${currencySymbol}${parseInt(external_hourly_rate, 10)}`}</Amount>
              <Rate>per hour</Rate>
            </PriceWrapper>
            {/* <PriceWrapper>
              <Amount>${price_day}</Amount>
              <Rate>per day</Rate>
            </PriceWrapper> */}
          </PriceContainer>
          <ButtonStyled
            color={color}
            style={{ borderRadius: '5px' }}
            onClick={onClick}
          >
            Book
          </ButtonStyled>
        </Detail>
      </Wrapper>
    </Container>
  )
}

ListCard.propTypes = {
  room: PropTypes.object,
  onClick: PropTypes.func,
  currencySymbol: PropTypes.string,
  img: PropTypes.string,
  available: PropTypes.bool,
  color: PropTypes.string,
}

ListCard.displayName = 'ListCard'

export default ListCard
