import React from 'react'
import PropTypes from 'prop-types'

import * as axios from '../../../config/axios'
import ButtonStyled from '@global/Base/Button/ButtonStyled'
import { Row, Col } from 'reactstrap'
import SuccessCard from '@app/components/External/Signup/SuccessCard'

const StripeStandardSetup = ({ activeCommunity }) => {
  // const localRedirectUrl = 'http://localhost:3001/community'
  const localRedirectUrl = 'http://localhost:3001/connect-to-stripe'
  const devRedirectUrl = 'https://dev.coworksapp.com/connect-to-stripe'
  const prodRedirectUrl = 'https://dashboard.coworksapp.com/connect-to-stripe'
  let redirectUrl = prodRedirectUrl

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    redirectUrl = devRedirectUrl
  }
  if (window.location.host.includes('localhost')) {
    redirectUrl = localRedirectUrl
  }
  // eslint-disable-next-line max-len
  const stripeConnectURL = `${axios.STRIPE_OAUTH_BASE_URL}?response_type=${axios.STRIPE_RESPONSE_TYPE}&client_id=${axios.STRIPE_CLIENT_ID}&scope=${axios.STRIPE_OAUTH_SCOPE}&redirect_uri=${redirectUrl}`

  const stripeConnectButton = (
    <div className="col-md-12">
      <br />
      <br />
      <ButtonStyled onClick={() => window.open(stripeConnectURL, '_blank')}>
        Connect with Stripe
      </ButtonStyled>
    </div>
  )

  if (activeCommunity.stripe_account_id) {
    return (
      <Row className="m-5">
        <Col>
          <SuccessCard
            inCard={false}
            successMessage={'Connected!'}
            confirmationMessage={
              'Your stripe account is successfully connected.'
            }
          />
        </Col>
      </Row>
    )
  }

  return (
    <div id="billing" className="animated fadeIn">
      <Row>
        <div className="col-md-12">
          <div className="row border-bottom-1 my-3">
            <div className="col-10">
              <h2>Connect your Stripe Account</h2>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div>
            We use Stripe to process all of your member's payments. Before you
            can create plans and start inviting your members to join, you need
            to connect your Stripe account to Coworks.
          </div>
          <br />
          <div>
            Stripe is the gold standard for collecting payment information on
            the web. Their services help ensure that all funds collected through
            Coworks are{' '}
            <a
              href="https://stripe.com/docs/security"
              target="_blank"
              rel="noopener noreferrer"
            >
              Secure
            </a>
            {'. '}
            They take a 2.9% + $0.30 USD fee for all credit card transactions
            and 0.8% for ACH payments with a cap at $5 USD.
          </div>
        </div>
        {stripeConnectButton}
      </Row>
    </div>
  )
}

StripeStandardSetup.propTypes = { activeCommunity: PropTypes.object.isRequired }
StripeStandardSetup.displayName = 'StripeStandardSetup'

export default StripeStandardSetup
