import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { DayPickerSingleDateController } from 'react-dates'
import withBlockDays from '@app/hocs/withBlockDays'
import { DropdownMenu, DropdownToggle, Dropdown, Button } from 'reactstrap'

const DayPickerBusinessHours = withBlockDays(DayPickerSingleDateController)

const Menu = styled(DropdownMenu)`
  padding: 0px;
`
const Container = styled.div`
  .DayPicker__withBorder {
    box-shadow: none;
  }
`
const TimeContainer = styled.div``
const ButtonContainer = styled.div``
const StateButton = styled(Button)`
  background-color: ${props => (props.active ? 'blue' : 'none')} !important;
  color: ${props => (props.active ? 'white' : 'none')} !important;
`

const DateDropdown = ({
  onChange,
  value,
  withBusinessHours,
  disabled,
  calendarProps,
  ...other
}) => {
  const [dropdownOpen, setToggle] = useState(false)

  const onCustomChanged = date => {
    onChange(date)
  }

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => setToggle(prev => !prev)}
      {...other}
    >
      <DropdownToggle
        disabled={disabled}
        className="w-100"
        style={{ textAlign: 'left' }}
      >
        {value.format('MMM Do')}
      </DropdownToggle>
      <Menu>
        <Container>
          {withBusinessHours ? (
            <DayPickerBusinessHours
              onDateChange={onCustomChanged}
              date={value}
              {...calendarProps}
            />
          ) : (
            <DayPickerSingleDateController
              onDateChange={onCustomChanged}
              date={value}
              {...calendarProps}
            />
          )}
        </Container>
      </Menu>
    </Dropdown>
  )
}

DateDropdown.propTypes = {
  withBusinessHours: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.object,
  calendarProps: PropTypes.object,
}
DateDropdown.displayName = 'Date Dropdown'

export default DateDropdown
