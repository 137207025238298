/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import Otto from '@app/img/placeholders/otto_color.png'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import { withTheme } from 'styled-components'

const PermissionsPlaceholder = ({
  header,
  subheader,
  text,
  onClick,
  buttonText = 'go back',
  colorPrimary = '#b0bec5',
}) => {
  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-5  d-flex align-items-center justify-content-end">
              <img
                className="img-responsive center-block mb-3"
                style={{ width: '15rem' }}
                src={Otto}
              />
            </div>
            <div className="col-4 d-flex align-items-center justify-content-start">
              <h2>{header}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-8 offset-md-3">
              <h4 className="text-primary">{subheader}</h4>
            </div>
            <div className="col-6 offset-md-3">
              <p>{text}</p>
            </div>
            <div className="col-6 offset-md-3">
              <TextButton
                className="m-0 p-0"
                backgroundColor={'#858585'}
                onClick={onClick}
              >
                <FontAwesomeIcon color={colorPrimary} icon="arrow-left" />
                {buttonText}
              </TextButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PermissionsPlaceholder.displayName = 'PermissionsPlaceholder'

PermissionsPlaceholder.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  theme: PropTypes.object,
  colorPrimary: PropTypes.object,
}

export default withTheme(PermissionsPlaceholder)
