import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import { TextButton } from '@global/Base/Button/ButtonStyled'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Alert } from 'reactstrap'

import {
  CustomInputComponent,
  CustomToggleComponent,
  LabelStyled,
} from '@global/Form/FormComponents'
function ExternalSettings({
  formProps,
  role,
  membershipUrl,
  tourRequestUrl,
  bookingListUrl,
  eventUrl,
  history,
}) {
  function navigateToWebsiteForms() {
    history.push('/community/website')
  }
  return (
    <React.Fragment>
      <Alert color={'info'}>
        <div className="row d-flex align-items-center justify-space-between">
          <div className="col-8">
            Looking for your website form links?{' '}
            <TextButton className="p-0 m-0" onClick={navigateToWebsiteForms}>
              View Links
            </TextButton>
          </div>
        </div>
      </Alert>
      <br />
      <h3>Membership Request Form</h3>
      <br />
      <Field
        name="membership_request_title"
        label="Form Header Title"
        description="The title at the top of the membership request form"
        component={CustomInputComponent}
      />
      <br />
      <h3>Day Pass Signup</h3>
      <br />
      <Field
        name="day_passes.default_success_redirect_url"
        label="Success Redirect Url"
        description="The url that will be redirected to on successful submission of a day pass signup form"
        component={CustomInputComponent}
      />
      <br />
      <h3>Automated Member Signup</h3>
      <br />
      <Field
        name="public_membership_signup_options.default_success_redirect_url"
        label="Success Redirect Url"
        description="The url that will be redirected to on successful submission of a public plan signup form"
        component={CustomInputComponent}
      />
      <Field
        name="public_member_signup.send_onboarding_email"
        label="Send Onboarding Email after signup"
        description="Will control if the onboarding email is sent automatically after a guest fills out & pays for a membership using a public plan signup form"
        component={CustomToggleComponent}
      />
      {/* <Field
        name="public_membership_signup_options.default_failure_redirect_url"
        label="Member Signup Form Failure Redirect Url"
        component={CustomInputComponent}
      /> */}
      <br />
      <br />
      <h3>Website Form Notifications</h3>
      <br />
      <Field
        label="Sales Email"
        name="sales_email"
        // eslint-disable-next-line max-len
        description="External membership, tour request, and external bookings (front-desk or website form) notification emails will be sent to this address. Managers will be able to control notification preferences individually. Note: This does not need to be set to receive these email notifications"
        component={CustomInputComponent}
      />
    </React.Fragment>
  )
}

ExternalSettings.propTypes = {
  history: PropTypes.object,
  formProps: PropTypes.object,
  membershipUrl: PropTypes.string,
  tourRequestUrl: PropTypes.string,
  bookingListUrl: PropTypes.string,
  eventUrl: PropTypes.string,
  role: PropTypes.string,
}
ExternalSettings.displayName = 'ExternalSettings'

export default ExternalSettings
