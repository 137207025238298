import React from 'react'
import PropTypes from 'prop-types'

import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Spinner from '@global/Spinner'
import { logO } from '@global/Constants'
import { toast } from 'react-toastify'
import CardComponent from '@global/CardComponent'
import * as Constants from '@global/Constants'
import defaultUserPic from '@app/img/placeholders/otto_black_white.jpeg'

class PublicPlansList extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    isFetching: PropTypes.bool,
    coworks_community_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    publicCommunity: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      hasSubmitted: true,
      modalTimeout: 80000,
      shortModalTimeout: 20000,
      isSuccessModalOpen: false,
      plans: null,
    }
  }
  UNSAFE_componentWillMount() {
    let subdomain = window.location.hostname
    subdomain = subdomain.substring(0, subdomain.indexOf('.'))
    const params = {
      subdomain,
    }
    this.props.coworks_community_actions
      .getPublicCommunityProfile(params)
      .then(response => {
        this.setState({
          isLoading: false,
        })
        logO('successful', response)
        console.log('successful')
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
        logO('successful', err)
        console.log('fail', err)
      })
    this.props.coworks_community_actions
      .getPublicPlans(params)
      .then(response => {
        this.setState({
          plans: response.plans,
          isLoading: false,
        })
        logO('successful', response)
        console.log('successful')
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
        logO('successful', err)
        console.log('fail', err)
      })
  }

  toggleSuccessModal = message => {
    if (!this.state.isSuccessModalOpen) {
      this.setState({
        isSuccessModalOpen: true,
        successMessage: message,
      })
      setTimeout(() => {
        this.setState({
          isSuccessModalOpen: false,
          successMessage: null,
        })
      }, this.state.shortModalTimeout)
    } else {
      this.setState({
        isSuccessModalOpen: false,
        successMessage: null,
      })
    }
  }

  sendRequest = formState => {
    const params = {
      created_by: 'WebsiteForm',
      first_name: formState.firstName,
      last_name: formState.lastName,
      email: formState.email,
      interested_in: formState.interestedIn,
      phone: formState.phone,
      community_id: formState.publicCommunity.id,
      campus_id: formState.campusId,
    }
    this.props.coworks_community_actions
      .sendRequest(params)
      .then(response => {
        if (response && response.lead && response.lead.first_name) {
          this.toggleSuccessModal(
            `Thanks ${response.lead.first_name}. You have successfully sent your request! We will reach out soon!`
          )
        }
        console.log('Success', response)
      })
      .catch(err => {
        toast.error('Whoops, looks like there was an error!')
        console.log('Error', err)
      })
  }

  render() {
    const { publicCommunity } = this.props
    const { isLoading, plans } = this.state
    if (isLoading) {
      return <Spinner />
    }
    if (!isLoading && !publicCommunity) {
      return (
        <div>
          Unable to load the form. Please contact Coworks support at
          support@coworksapp.com! We'll get you fixed up.
        </div>
      )
    }
    return (
      <div id="PublicSignup">
        {plans && plans.length > 0
          ? plans.map(plan => {
              const symbol = plan.currency_units
                ? Constants.getCurrencySymbolFromName(plan.currency_units)
                : '$'
              return CardComponent({
                key: plan.id,
                name: plan.name ? plan.name : 'No name',
                alt: plan.name ? plan.name : 'Image',
                editClickHandler: this.editPlanClicked,
                shouldCenterTags: true,
                item: plan,
                imageUrl: plan.plan_photo
                  ? plan.plan_photo.file_url
                  : defaultUserPic,
                detail: plan.description ? plan.description : 'No description',
                value: `${symbol}${
                  plan.cost ? Number(plan.cost).toFixed(2) : 0
                } / ${plan.frequency ? plan.frequency : 'month'}`,
              })
            })
          : 'no plan'}
      </div>
    )
  }
}

PublicPlansList.displayName = 'PublicPlansList'

function mapStateToProps(state) {
  return {
    publicCommunity: state.ui.publicCommunity,
    isFetching: state.ui.isFetching,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    coworks_community_actions: bindActionCreators(
      coworksCommunityActions,
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicPlansList)
