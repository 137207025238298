import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import RoundedImage from '@global/RoundedImage'

import './memberRow.scss'

class CompanyRow extends Component {
  static propTypes = {
    company: PropTypes.object,
    addRecipients: PropTypes.func,
    added: PropTypes.bool,
    toggleMemberModal: PropTypes.func,
  }
  constructor(props) {
    super(props)

    this.state = {
      selected: false,
    }
  }

  render() {
    const { company, addRecipients, added } = this.props
    return (
      <div className="message-member-wrapper border-bottom">
        <div className="member-info-container">
          <RoundedImage
            photo={company.team_photo}
            alt="Picture"
            size="medium"
            className="mr-3"
          />
          <div className="member-info">
            <p className="member-name">{company.name}</p>
            <Button
              className="btn btn-outline-primary pull-right btn-sm"
              onClick={() => this.props.toggleMemberModal(company)}
            >
              {`${company.size} members`}
            </Button>
          </div>
        </div>
        <div>
          {added ? (
            <FontAwesomeIcon
              icon={'check'}
              className="member-plus-icon primary-color"
            />
          ) : (
            <FontAwesomeIcon
              icon={'plus'}
              className="member-plus-icon primary-color"
              onClick={() => this.props.toggleMemberModal(company)}
            />
          )}
        </div>
      </div>
    )
  }
}

CompanyRow.displayName = 'Company Row'

export default CompanyRow
