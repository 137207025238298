import * as _ from 'lodash'
const addMinutes = require('date-fns/add_minutes')
import {
  IS_FETCHING,
  IS_FETCHING_REPORT,
  SET_ACTIVE_TAB,
  SET_ACTIVE_CAMPUS,
  SET_ACTIVE_COMMUNITY,
  SET_ACTIVE_CONTEXT,
  SEND_ONBOARDING_EMAIL,
  CLEAR_SEND_ONBOARDING_EMAIL_STATUS,
  SET_GROUPS,
  ADD_GROUP,
  STORE_ROOM_TOKEN,
  SET_FRONT_DESK_LOADED,
  SET_IS_TABLET_USER,
  SET_PUBLIC_COMMUNITY,
  SET_PUBLIC_CAMPUS,
  UPDATE_COMMUNITY_PREFERENCE,
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATION_COUNT,
  UPDATE_NOTIFICATION,
  REFRESH_NOTIFICATIONS,
  SET_NOTIFICATIONS_READ,
  UPDATE_CAMPUS,
  CREATE_CAMPUS,
  UPDATE_COMMUNITY_COWORKS_GROUPS,
  SET_TIMEZONE_IDENTIFIER,
  UPDATE_FILTERS,
  CLEAR_CAMPUS_DATA,
  ADD_DYNAMIC_GROUP,
  UPDATE_DYNAMIC_GROUP,
  SET_FILTERS,
  ADD_DAY_PASS,
  UPDATE_DAY_PASS,
  CONNECT_TO_STRIPE,
} from '../reduxActions/actionTypes'
import { arrayMerge } from './reducerConstants'

export default function (ui = {}, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return Object.assign({}, ui, { activeTab: action.activeTab })
    case IS_FETCHING:
      if (!action.objectType) {
        // standard isFetching
        return {
          ...ui,
          isFetching: action.isFetching,
        }
      }
      // new fetching statuses
      return {
        ...ui,
        fetching: {
          ...ui.fetching,
          [action.objectType]: action.isFetching,
        },
      }
    case CLEAR_CAMPUS_DATA:
      return {
        ...ui,
        fetching: {},
        isFetching: false,
      }
    case IS_FETCHING_REPORT:
      return Object.assign({}, ui, {
        isFetchingReport: action.isFetchingReport,
      })
    case UPDATE_CAMPUS:
      return {
        ...ui,
        activeCampus:
          action.campus &&
          ui.activeCampus &&
          action.campus.id === ui.activeCampus.id
            ? action.campus
            : ui.activeCampus,
        activeCommunity: {
          ...ui.activeCommunity,
          campuses: [
            ...ui.activeCommunity.campuses.map(oldCampus => {
              if (oldCampus.id !== action.campus.id) {
                return oldCampus
              }
              return { ...oldCampus, ...action.campus }
            }),
          ],
        },
      }
    case CREATE_CAMPUS:
      if (action.campus && ui.activeCampus) {
        return {
          ...ui,
          activeCommunity: {
            ...ui.activeCommunity,
            campuses: [
              ...ui.activeCommunity.campuses,
              Object.assign({}, action.campus),
            ],
          },
        }
      }
      return ui
    case UPDATE_COMMUNITY_COWORKS_GROUPS:
      return {
        ...ui,
        activeCommunity: {
          ...ui.activeCommunity,
          coworks_groups: Object.assign({}, action.coworksGroups),
        },
      }
    case SET_ACTIVE_CAMPUS:
      return Object.assign({}, ui, { activeCampus: action.activeCampus })
    case SET_ACTIVE_COMMUNITY:
      return Object.assign({}, ui, {
        activeCommunity: action.activeCommunity,
        activeCampus: action.activeCommunity.campuses[0],
      })
    case SET_ACTIVE_CONTEXT:
      return Object.assign({}, ui, {
        activeCommunity: action.activeCommunity,
        activeCampus: action.activeCampus,
      })
    case CONNECT_TO_STRIPE:
    case SET_PUBLIC_COMMUNITY:
      if (action.community)
        return Object.assign({}, ui, { publicCommunity: action.community })
      return ui
    case SET_PUBLIC_CAMPUS:
      return Object.assign({}, ui, { publicCampus: action.campus })
    case STORE_ROOM_TOKEN:
      return Object.assign({}, ui, { roomToken: action.roomToken })
    case SET_FRONT_DESK_LOADED:
      return Object.assign({}, ui, { frontDeskLoaded: action.shouldSet })
    case SET_GROUPS:
      return Object.assign({}, ui, { groups: action.groups })
    case ADD_GROUP:
      // legacy groups
      if (ui.groups) {
        return { ...ui, groups: [...ui.groups, action.group] }
      }
      return Object.assign({}, ui, { groups: [action.group] })
    case ADD_DYNAMIC_GROUP:
      // modern acts-as-taggable groups
      return {
        ...ui,
        activeCommunity: {
          ...ui.activeCommunity,
          dynamic_groups: ui.activeCommunity.dynamic_groups
            ? [...ui.activeCommunity.dynamic_groups, action.group]
            : [action.group],
        },
      }
    case UPDATE_DYNAMIC_GROUP:
      // modern acts-as-taggable groups
      const oldGroups = ui.activeCommunity.dynamic_groups
      const newGroups = oldGroups
        ? arrayMerge(oldGroups, [action.group], 'id')
        : [action.group]
      return {
        ...ui,
        activeCommunity: {
          ...ui.activeCommunity,
          dynamic_groups: newGroups,
        },
      }
    case SET_IS_TABLET_USER:
      return Object.assign({}, ui, { isTabletUser: action.tabletUser })
    case SET_NOTIFICATIONS:
      if (ui.notifications && ui.notifications.notifications.length > 0) {
        const oldNotifications = ui.notifications.notifications
        const newNotifications = action.notifications
        return {
          ...ui,
          notifications: {
            notifications: arrayMerge(oldNotifications, newNotifications),
            notificationCount: action.notificationCount,
            lastClearNotificationsDate: action.lastClearNotificationsDate
              ? action.lastClearNotificationsDate
              : ui.notifications.notifications.lastClearNotificationsDate,
            meta: action.meta ? action.meta : ui.notifications.meta,
          },
        }
      }
      return Object.assign({}, ui, {
        notifications: {
          notifications: action.notifications,
          notificationCount: action.notificationCount,
          meta: action.meta,
        },
      })
    case CLEAR_NOTIFICATION_COUNT:
      return Object.assign({}, ui, {
        notifications: {
          notifications: ui.notifications.notifications,
          notificationCount: action.notificationCount,
          lastClearNotificationsDate: action.lastClearNotificationsDate,
          meta: ui.notifications.meta,
        },
      })
    case UPDATE_NOTIFICATION:
      return {
        ...ui,
        notifications: {
          notifications:
            ui.notifications.notifications &&
            ui.notifications.notifications.length > 0
              ? ui.notifications.notifications.map(oldNotification => {
                  if (oldNotification.id !== action.notification.id) {
                    return oldNotification
                  }
                  return { ...oldNotification, ...action.notification }
                })
              : [action.notification],
          notificationCount: action.notificationCount,
          lastClearNotificationsDate: action.lastClearNotificationsDate,
          meta: action.meta,
        },
      }
    case REFRESH_NOTIFICATIONS:
      return Object.assign({}, ui, {
        notifications: {
          notifications: action.notifications,
          notificationCount: action.notificationCount,
          lastClearNotificationsDate: action.lastClearNotificationsDate,
          meta: action.meta,
        },
      })
    case UPDATE_COMMUNITY_PREFERENCE:
      const oldPrefs = ui.activeCommunity.community_preference
      const newPrefs = action.communityPreference
      if (JSON.stringify(oldPrefs) !== JSON.stringify(newPrefs)) {
        return {
          ...ui,
          activeCommunity: {
            ...ui.activeCommunity,
            community_preference: newPrefs,
          },
        }
      }
      return ui
    case SET_TIMEZONE_IDENTIFIER:
      return {
        ...ui,
        timezoneIdentifier: action.timezoneIdentifier,
      }
    case SET_FILTERS:
      const oldFilters = Object.assign({}, ui.filters)
      oldFilters[action.filters.type] = action.filters.values
      return {
        ...ui,
        filters: oldFilters,
      }
    case UPDATE_FILTERS:
      const type = action.filters.type
      const oldFilt = Object.assign({}, ui.filters)
      const oldValues = !_.isEmpty(oldFilt[type]) ? oldFilt[type] : {}
      const newValues = {
        ...oldValues,
        ...action.filters.values,
      }
      oldFilt[type] = newValues
      return {
        ...ui,
        filters: oldFilt,
      }
    case UPDATE_DAY_PASS:
      const newDayPass = action.dayPass
      return {
        ...ui,
        activeCampus: {
          ...ui.activeCampus,
          day_pass: newDayPass,
        },
      }
    default:
      return ui
  }
}
