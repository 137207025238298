/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import * as _ from 'lodash'
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

import DayPickerControllerWrapper from './DayPickerControllerWrapper'

const DropdownToggleStyled = styled(DropdownToggle)``
const ButtonDropdownStyled = styled(ButtonDropdown)`
  .show {
    ${props =>
    props.custom
      ? css`
            display: flex;
          `
      : ''}
  }
`
const ItemsWrapper = styled.div`
  min-width: 170px;
`
const CalendarWrapper = styled.div``

function PresetDatePicker({
  handleDateRangeChanged,
  dateRange,
  presets,
  value,
  defaultPreset,
  placeholderText,
  className,
  ...other
}) {
  const [dropdownOpen, setDropDown] = useState(false)
  const [openCustom, setCustom] = useState(false)
  const [buttonText, setText] = useState(
    defaultPreset ? presets[defaultPreset].text : null
  )

  useEffect(() => {
    if (!value) {
      setText(null)
    }
  }, [value, openCustom])

  const handlePresetClick = ({ text, startDate, endDate }) => {
    handleDateRangeChanged({ startDate, endDate })
    setText(text)
    setCustom(false)
  }
  const handleApplyDates = range => {
    handleDateRangeChanged(range)
    setCustom(false)
    setDropDown(false)
  }
  const onCustomChanged = ({ startDate, endDate }) => {
    const startText = startDate.format('l')
    const endText = endDate.format('l')
    setText(`${startText} - ${endText}`)
  }
  const renderDatePresets = () => {
    return presets.map(preset => (
      <DropdownItem onClick={() => handlePresetClick(preset)}>
        {preset.text}
      </DropdownItem>
    ))
  }
  const isToggling = value => {
    setDropDown(value)
    if (!value) setCustom(value)
  }

  return (
    <ButtonDropdownStyled
      isOpen={dropdownOpen}
      custom={openCustom}
      toggle={() => isToggling(!dropdownOpen)}
      className={className}
    >
      <DropdownToggleStyled className="btn btn-secondary" caret>
        {buttonText || placeholderText}
      </DropdownToggleStyled>
      <DropdownMenu>
        <ItemsWrapper>
          {renderDatePresets()}
          <DropdownItem onClick={() => setCustom(true)} toggle={false}>
            Custom
          </DropdownItem>
        </ItemsWrapper>
        {openCustom && (
          <CalendarWrapper>
            <DayPickerControllerWrapper
              onApplyDates={range => {
                onCustomChanged(range)
                handleApplyDates(range)
              }}
              {...other}
            />
          </CalendarWrapper>
        )}
      </DropdownMenu>
    </ButtonDropdownStyled>
  )
}

PresetDatePicker.propTypes = {
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  dateRange: PropTypes.object,
  placeholderText: PropTypes.string,
  handleDateRangeChanged: PropTypes.func,
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      start: PropTypes.object,
      end: PropTypes.object,
    })
  ),
  defaultPreset: PropTypes.number,
  value: PropTypes.object,
}

PresetDatePicker.defaultProps = {
  placeholderText: 'Select from dropdown...',
  dateRange: {
    startDate: null,
    endDate: null,
  },
}

PresetDatePicker.displayName = 'PresetDatePicker'

export default PresetDatePicker
