import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reduxReducers'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { createReduxEnhancer } from '@sentry/react'

const ENABLE_REDUX_LOGGER = false
const middleware = getMiddleware(process.env.NODE_ENV)

function getMiddleware(env) {
  let mw = []
  switch (env) {
    case 'production':
      mw = [thunk]
      break
    default:
      mw = [reduxImmutableStateInvariant(), thunk]
  }
  if (ENABLE_REDUX_LOGGER) mw.push(createLogger()) // eslint-disable-line curly
  return mw
}

const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: state => {
    // Remove sensitive data and large state that is not needed for debugging
    // return {
    //   ...state,
    //   auth: {
    //     ...state.auth,
    //     password: null,
    //   },
    //   giganticState: null,
    // }
    return state
  },
  actionTransformer: action => {
    // // Ignore actions with sesitive data
    // if (action.type === 'AUTH.SET_INPUT.PASSWORD') {
    //   return null
    // }
    return action
  },
})

// for https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
  )

  // this was causing ane
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../reduxReducers', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return store
}
