import React from 'react'

import PropTypes from 'prop-types'
import { Field } from 'formik'

import {
  CustomInputComponent,
  HoursOfOperation,
} from '@global/Form/FormComponents'

export const CampusSettingsFields = ({
  isEditing,
  showDoorAccessFields,
  preferences,
  errors,
}) => {
  return (
    <div id="campus-setting-fields">
      {showDoorAccessFields && (
        <React.Fragment>
          <br />
          <h3>Integrations</h3>
          <hr />
          <div className="row">
            <div className="col-6">
              <Field
                isEditing={isEditing}
                renderViewState={!isEditing}
                label="Door Access Place Id"
                name="placeID"
                id="placeID"
                placeholder="1234"
                component={CustomInputComponent}
              />
            </div>
          </div>
        </React.Fragment>
      )}

      <React.Fragment>
        <br />
        <h3 id="#externalBookings">External Bookings</h3>
        <hr />
        <div className="row">
          {preferences.hours_of_operation && (
            <div className="col-12">
              <HoursOfOperation
                isEditing={isEditing}
                description={
                  'Controls what times are available to book to guests and members through the external bookings feature.'
                }
                renderViewState={!isEditing}
                label="Hours of Operation"
                name="preferences.hours_of_operation"
                id="businessHours"
                placeholder="Business Hours"
              />
            </div>
          )}
        </div>
      </React.Fragment>

      {preferences.hasOwnProperty('bookings') && (
        <React.Fragment>
          <br />
          <h3 id="#externalBookings">Bookings</h3>
          <hr />
          <div className="row">
            <div className="col-12">
              <Field
                id="bookings_after_padding_time_in_mins"
                name="preferences.bookings.after_padding_time_in_mins"
                label="Booking Padding time in minutes"
                description="Adds a block of padding time after a booking so that you have time to clean, flip, or perform any other duties necessary between bookings."
                isEditing={isEditing}
                renderViewState={!isEditing}
                component={CustomInputComponent}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="booking_min_advance_in_mins"
                name="preferences.bookings.min_advance_in_mins"
                label="Minimum time in advance in minutes that a booking needs to be scheduled"
                description="Prevents bookings from being scheduled too soon so that you don't have time to react or control the booking process."
                isEditing={isEditing}
                renderViewState={!isEditing}
                component={CustomInputComponent}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {errors.preferences ? (
        <div className="text-danger">{errors.preferences} </div>
      ) : null}
    </div>
  )
}

CampusSettingsFields.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  preferences: PropTypes.object.isRequired,
  showDoorAccessFields: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
}
CampusSettingsFields.displayName = 'CampusSettingsFields'

export default CampusSettingsFields
