/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { convertMinutesToTime } from '@global/Constants'

const SelectElement = styled.select.attrs(props => ({
  className: 'form-control',
}))``

const TimeDropdown = ({
  onChange,
  minInterval = 60,
  timeFormat = 'hh:mm a',
  value, // "01:01 am" or moment()
  disabled,
  earliestTime = null,
  latestTime = null,
  returnAsDate = false, // will return a date object or a formatted string
  ...other
}) => {
  const getTimes = () => {
    const times = []
    for (let index = 0; index < 24 * 60; index = minInterval + index) {
      if (earliestTime === null && latestTime === null) {
        times.push(index)
      } else if (
        earliestTime !== null &&
        latestTime !== null &&
        index >= earliestTime &&
        index <= latestTime
      ) {
        times.push(index)
      }
    }
    return times
  }

  function formatTime(date) {
    if (!date) {
      return '-- : --'
    } else if (date instanceof Object) {
      return date.format('h:mm a')
    }
    return moment(date, 'hh:mm a').format('h:mm a')
  }

  const setTime = time =>
    returnAsDate ? onChange(moment(time, timeFormat)) : onChange(time)

  return (
    <SelectElement
      value={formatTime(value)}
      onChange={event => setTime(event.target.value)}
      disabled={disabled}
      {...other}
    >
      {getTimes().map(time => (
        <option value={formatTime(convertMinutesToTime(time))}>
          {formatTime(convertMinutesToTime(time))}
        </option>
      ))}
    </SelectElement>
  )
}

TimeDropdown.propTypes = {
  onChange: PropTypes.func,
  timeFormat: PropTypes.string,
  minInterval: PropTypes.number,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  returnAsDate: PropTypes.bool,
  earliestTime: PropTypes.bool,
  latestTime: PropTypes.bool,
}

TimeDropdown.displayName = 'Time Dropdown'

export default TimeDropdown
