import React from 'react';

/* eslint-disable react/prop-types */
/* eslint-disable no-else-return */
/* eslint-disable no-return-assign */
export const FormErrors = ({ formErrors }) => {
  let returnval = '';
  let classs = '';
  let errorCount = 0;
  const html = (
    <div id='formErrors'>
      <ul>
      {Object.keys(formErrors).map((fieldName, i) => {
        if (formErrors[fieldName].length > 0) {
          errorCount += 1;
          returnval = (
            <li key={i}><p>{fieldName} {formErrors[fieldName]}</p></li>
          );
        }
        return returnval;
      })}
      </ul>
    </div>
  );
  if (errorCount > 0) {
    classs = 'formErrors';
  } else {
    classs = '';
  }
  if (document && document.getElementById('formErrors') && document.getElementById('formErrors')) {
    document.getElementById('formErrors').className = classs;
  }
  return html;
};
FormErrors.displayName = 'FormErrors';
/* eslint-disable react/prop-types */

