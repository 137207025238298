import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Row, Col, Collapse } from 'reactstrap'

import * as uiActions from '@reduxActions/uiActions'
import * as communityActions from '@reduxActions/communityActions'

import Spinner from '@global/Spinner'
import RoundedImage from '@global/RoundedImage'
import Badge from '@global/Base/Badge/Badge'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import ColorSquare from '@global/Colors/ColorSquare'
import Filters from '@global/Filters/Filters'
import * as Roles from '@config/roles'
import {
  extractCoworksErrorObject,
  captureException,
} from '@global/ErrorFactory'

const Container = styled.div`
  background-size: cover;
  background-attachment: fixed;
  width: 100% !important;
  height: 100vh;
  background-image: linear-gradient(
    to right,
    rgba(08, 90, 110),
    rgba(38, 110, 110),
    rgb(90, 179, 177)
  );
`
const Wrapper = styled.div``
const TextRed = styled.div`
  color: red;
`
function ChangeCommunity({
  community_actions,
  ui_actions,
  history,
  campuses,
  user,
  isFetching,
  activeCommunity,
  activeCampus,
  isTabletUser,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [communities, setCommunities] = useState([])
  const [showCommunityPicker, setShowCommunityPicker] = useState(
    user.type === Roles.superAdminRole
  )
  const [showCampusPicker, setShowCampusPicker] = useState(
    user.type !== Roles.superAdminRole
  )
  const [sortBy, setSortBy] = useState('live')
  const [campusDropdownOpen, setCampusDropdownOpen] = useState({})

  useEffect(() => {
    setIsLoading(true)
    // this is recalled every time the sortBy variable changes
    if (user.type === Roles.superAdminRole) {
      const params = {
        user_id: user.id,
      }
      if (sortBy) params.status = sortBy
      community_actions
        .getCommunities(params)
        .then(response => {
          const groupedCommunities = groupCommunitiesByStatus(
            response.communities
          )
          setCommunities(groupedCommunities)
          setIsLoading(false)
        })
        .catch(err => {
          setIsLoading(false)
          toast.error(extractCoworksErrorObject(err))
          console.error(err)
        })
    }
  }, [sortBy, user, community_actions])

  const groupCommunitiesByStatus = communities => {
    const communitiesByStatus = _.groupBy(
      communities,
      'community_preference.status'
    )
    // const sortedAlphabetically = []
    // communitiesByStatus.forEach(array => {
    //   sortedAlphabetically.push(array.sortBy('name'))
    // })
    return communitiesByStatus
  }

  // eslint-disable-next-line react/no-multi-comp, react/display-name
  const renderAccountStatusBadge = status => {
    let color
    switch (status) {
      case 'unclassified':
      case 'demo_performed':
      case 'demo_account':
        color = 'default'
        break
      case 'onboarding':
      case 'paused':
        color = 'warning'
        break
      case 'live':
        color = 'success'
        break
      case 'trialing':
        color = 'info'
        break
      case 'trail_ended':
      case 'delinquent_by_subscription':
      case 'terminated':
        color = 'danger'
        break
      default:
        color = 'danger'
        break
    }

    return (
      <Badge badgeShape="pill" color={color}>
        {status}
      </Badge>
    )
  }
  const handleSelection = (community, campus) => {
    if (
      user.type === Roles.superAdminRole &&
      activeCommunity.id !== community.id
    ) {
      // we're super admin changing the communities
      ui_actions.setActiveCommunity(community)
    }
    ui_actions.setActiveCampus(campus)
    if (user && user.type === 'Member') {
      history.replace('/calendar')
    } else if (user && Roles.justSpaceStaffRoles.includes(user.type)) {
      history.replace(isTabletUser ? '/tablet-type' : '/dashboard')
    } else {
      console.warn('No user so sending back to login')
      history.replace(isTabletUser ? '/tablet-login' : '/login')
    }
  }
  const throwAlert = text => {
    let alertText =
      'Whoops it looks like there is a problem with your account. Please contact support!'
    if (text) {
      alertText = text
    }
    toast.error(alertText)
    captureException({ text })
  }
  const renderCampuses = community => {
    if (community.campuses.length === 0) {
      return <h5>This community has no campuses!</h5>
    }
    return community.campuses.map((campus, index) => (
      <div
        role={'button'}
        key={index}
        className={`${
          community.campuses.length - 1 !== index ? 'border-bottom-1' : ''
        } mt-2`}
        onClick={() => handleSelection(community, campus)}
      >
        <a onClick={() => handleSelection(community, campus)}>
          <RoundedImage
            photo={campus.campus_photo}
            alt="Picture"
            size={'medium'}
          />
          <TextButton className="ml-3">{campus.name}</TextButton>
        </a>
      </div>
    ))
  }
  const renderCommunities = communities => {
    if (!communities || isLoading) {
      return <Spinner />
    }
    if (Object.keys(communities).length === 0) {
      return <h5>There are no communities with status: {`${sortBy}`}!</h5>
    }
    return _.sortBy(Object.keys(communities)).map(key => {
      if (sortBy && key !== sortBy) {
        return null
      }
      return communities[key].map((community, index) => {
        const prefs = community.community_preference
        return (
          <div
            role={'button'}
            key={community.id}
            className="border-bottom-1 m-2 p-2"
          >
            <Row>
              <div className="col-md-7 col-12">
                <Row>
                  <div className="col">
                    <h6 className="text-primary">{community.name}</h6>
                  </div>
                </Row>
                <Row>
                  <div className="col text-muted">
                    Created on{' '}
                    {moment(community.created_at).format(
                      'MMM DD, YYYY hh:mm a'
                    )}
                  </div>
                </Row>
                <Row className="ml-2">
                  <div className="col-12">
                    <TextButton
                      className="m-0 p-0 mb-2"
                      onClick={() =>
                        setCampusDropdownOpen({
                          [community.id]: !campusDropdownOpen[community.id],
                          ...campusDropdownOpen,
                        })
                      }
                    >
                      {!campusDropdownOpen[community.id]
                        ? `+ Campuses (${community.campuses.length})`
                        : `- Campuses (${community.campuses.length})`}
                    </TextButton>
                  </div>
                  <Collapse isOpen={campusDropdownOpen[community.id]}>
                    <div className="col-12">
                      {community.campuses.map((campus, index) => (
                        <div
                          key={campus.id}
                          role={'button'}
                          onClick={() => handleSelection(community, campus)}
                        >
                          <a onClick={() => handleSelection(community, campus)}>
                            <RoundedImage
                              photo={campus.campus_photo}
                              alt="Picture"
                              size={'x-small'}
                            />
                            <TextButton className="ml-3 m-0 p-0">
                              {campus.name}
                            </TextButton>
                          </a>
                        </div>
                      ))}
                    </div>
                  </Collapse>
                </Row>
              </div>
              <div className="col-12 col-md-5">
                <Row>
                  <div className="col-12">
                    <h6>
                      {renderAccountStatusBadge(prefs.status)}
                      {prefs.status === 'trialing' && prefs.trial_end_date ? (
                        <span>
                          {' '}
                          {moment(prefs.trial_end_date).isAfter(moment()) ? (
                            <TextRed>
                              until{' '}
                              {moment(prefs.trial_end_date).format(
                                'MMM DD, YYYY'
                              )}
                            </TextRed>
                          ) : (
                            <React.Fragment>
                              until{' '}
                              {moment(prefs.trial_end_date).format(
                                'MMM DD YYYY'
                              )}
                            </React.Fragment>
                          )}
                        </span>
                      ) : null}
                    </h6>
                  </div>
                </Row>
                <Row>
                  <div className="col-12">
                    <small className="mr-2">
                      <FontAwesomeIcon icon={'dollar-sign'} /> billing_enabled:{' '}
                      {prefs.billing_enabled ? 'Yes' : 'No'}{' '}
                    </small>
                    <small>
                      {prefs.billing_enabled &&
                      (prefs.status === 'live' ||
                        prefs.status === 'onboarding' ||
                        prefs.status === 'trialing') ? (
                        <React.Fragment>
                          {/* This is where we expect billing to be ON and stripe connected! */}
                          {community.stripe_account_id !== undefined &&
                          community.stripe_account_id !== null &&
                          community.stripe_account_id !== '' ? (
                            'stripe: connected'
                          ) : (
                            <TextRed>stripe: disconnected</TextRed>
                          )}
                        </React.Fragment>
                      ) : null}
                    </small>
                  </div>
                </Row>
                <Row>
                  <div className="col-12">
                    <small className="mr-2">
                      <Row>
                        <div className="col-7">
                          <FontAwesomeIcon icon={'paint-brush'} />{' '}
                          is_whitelabeled:{' '}
                          {prefs.is_whitelabeled !== true ? 'No' : 'Yes'}
                        </div>
                        <div className="col-5">
                          {prefs.is_whitelabeled === true ? (
                            <Row>
                              <div className="col-1">
                                <ColorSquare
                                  size="x-small"
                                  color={prefs.branding_primary_color}
                                />
                              </div>
                              <div className="col-10 m-0 p-0">
                                {prefs.branding_primary_color === '#269796' && (
                                  <TextRed>default</TextRed>
                                )}
                              </div>
                            </Row>
                          ) : null}
                        </div>
                      </Row>
                    </small>
                  </div>
                </Row>
              </div>
            </Row>
          </div>
        )
      })
    })
  }
  if (!activeCommunity) {
    toast.error('No community found. Redirecting to login.')
    return <Spinner />
    // history.replace(isTabletUser ? '/tablet-login' : '/login')
  }

  return (
    <Container className="container-fluid">
      <div className="row">
        {showCommunityPicker && (
          <div className="col-md-8 offset-md-2 mt-2 card p-4">
            <h4>Communities</h4>
            <Filters
              sortBy={sortBy}
              onChangeSortBy={setSortBy}
              sortByOptions={[
                { value: null, label: 'none' },
                { value: 'live', label: 'live' },
                { value: 'onboarding', label: 'onboarding' },
                { value: 'trialing', label: 'trialing' },
                { value: 'demo_performed', label: 'demo_performed' },
                { value: 'demo_account', label: 'demo_account' },
                { value: 'paused', label: 'paused' },
                { value: 'trail_ended', label: 'trial_ended' },
                { value: 'unclassified', label: 'unclassified' },
                {
                  value: 'delinquent_by_subscription',
                  label: 'delinquent_by_subscription',
                },
                { value: 'terminated', label: 'terminated' },
              ]}
            />
            {renderCommunities(communities)}
          </div>
        )}

        {showCampusPicker && (
          <div className="col-md-4 offset-md-4 mt-2 card p-4">
            <h4>Your Campuses</h4>
            <h6 className="text-muted">Please choose one</h6>
            {activeCommunity &&
            activeCommunity.campuses &&
            activeCommunity.campuses.length > 0 ? (
              renderCampuses(activeCommunity)
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </div>
    </Container>
  )
}

ChangeCommunity.displayName = 'ChangeCommunity'

ChangeCommunity.propTypes = {
  campus_actions: PropTypes.object,
  community_actions: PropTypes.object,
  user_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  history: PropTypes.object,
  campuses: PropTypes.array,
  user: PropTypes.object,
  isFetching: PropTypes.bool,
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  showCampusPicker: PropTypes.bool,
  showCommunityPicker: PropTypes.bool,
  isTabletUser: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    user: state.user,
    isTabletUser: state.ui.isTabletUser,
    isFetching: state.ui.isFetching,
    activeCommunity: state.ui.activeCommunity,
    activeCampus: state.ui.activeCampus,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    community_actions: bindActionCreators(communityActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCommunity)
