import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom'

import * as leadActions from '../../../reduxActions/leadActions'
import * as uiActions from '../../../reduxActions/uiActions'
import { PENDING_MEMBER } from '@global/Constants/CRMConstants'

import defaultUserPic from '../../../img/placeholders/otto_black_white.jpeg'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

import NothingFound from '../../Global/NothingFound'
import Spinner from '@global/Spinner'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'

class PendingMembers extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    lead_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    isFetching: PropTypes.bool,
    pendingMembers: PropTypes.array,
    ui_actions: PropTypes.object,
    setPendingMembersCount: PropTypes.func,
  }

  constructor() {
    super()
    this.state = {
      filterStatus: null,
      searchBar: '',
      dropdownsOpen: [],
    }
  }

  archiveContact = contact => {
    const leadObj = {
      id: contact.id,
      campus: this.props.activeCampus.id,
      is_archived: true,
    }

    this.props.lead_actions.updatePendingMember(leadObj).then(() => {
      toast.success(`Deleted ${contact.first_name} ${contact.last_name}`)
    })
  }
  toggleDropdown = index => {
    const dropdownsOpen = this.state.dropdownsOpen
    if (this.state.dropdownsOpen[index]) {
      dropdownsOpen[index] = !this.state.dropdownsOpen[index]
    } else {
      dropdownsOpen[index] = true
    }
    // update state
    this.setState({
      dropdownsOpen,
    })
  }
  editLeadClicked = lead => {
    this.props.history.push(`/pendingMembers/add-lead?${lead.id}`, {
      lead,
      activeTab: 'pending',
      backRoute: '/directory#pending',
    })
  }
  promoteToMemberExistingTeamClicked = lead => {
    this.props.history.push(`/directory/members/add-member?${lead.id}`, {
      lead,
      activeTab: 'pending',
      backRoute: '/directory#pending',
    })
  }
  promoteToMemberNewTeamClicked = lead => {
    this.props.history.push(`/directory/teams/add-team?${lead.id}`, {
      lead,
      activeTab: 'pending',
      backRoute: '/directory#pending',
    })
  }
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  getCampusName(campusId, campuses) {
    for (let counter = 0; counter < campuses.length; counter++) {
      const campus = campuses[counter]
      if (campus.id === campusId) {
        return (
          <p className="text-muted m-0 p-0">
            <FontAwesomeIcon icon="building" /> {campus.name}
          </p>
        )
      }
    }
    return null
  }

  // TODO: Break out into it's own component
  // eslint-disable-next-line max-params,no-unused-vars
  renderLeadRow(index, lead) {
    let defaultPicture, defaultEmail
    if (!lead.lead_photo) {
      defaultPicture = defaultUserPic
    }
    if (!lead.email) {
      defaultEmail = ''
    }
    let name = null
    if (lead.first_name && lead.last_name) {
      name = `${lead.first_name} ${lead.last_name}`
    } else if (lead.first_name) {
      name = `${lead.first_name}`
    } else if (lead.last_name) {
      name = `${lead.last_name}`
    } else {
      name = 'No Name'
    }

    return (
      <div key={index} className=" border-bottom-1 mt-2">
        <div className="row p-2">
          <div className="col-md-8">
            <h4 className={name === 'No Name' ? 'text-muted' : ''}>{name}</h4>
            {lead.company_name && (
              <h6 className="text-muted">
                <FontAwesomeIcon icon="briefcase" />{' '}
                {lead.company_name ? lead.company_name : ''}
              </h6>
            )}
            <h6>
              {lead.email ? (
                <a href={`mailto:${lead.email}`} title={`Email ${lead.email}`}>
                  <FontAwesomeIcon icon="envelope" /> {lead.email}
                </a>
              ) : (
                ''
              )}
            </h6>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-end">
            <ButtonDropdown
              isOpen={this.state.dropdownsOpen[index]}
              toggle={() => this.toggleDropdown(index)}
            >
              <DropdownToggle caret color="outline-primary">
                Promote
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.promoteToMemberNewTeamClicked(lead)}
                >
                  <FontAwesomeIcon icon="user-plus" /> New Team
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.promoteToMemberExistingTeamClicked(lead)}
                >
                  <FontAwesomeIcon icon="users" /> Existing Team
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <Button
              color="outline-danger"
              className="ml-2"
              onClick={() => this.archiveContact(lead)}
            >
              <FontAwesomeIcon icon="times" /> Delete{' '}
            </Button>
          </div>
        </div>
      </div>
    )
  }
  renderPendingMembers = () => {
    let pendingMembers = this.props.pendingMembers
    if (pendingMembers.length === 0) {
      return (
        <NothingFound
          renderInCard={false}
          displayText={"Looks like there's no pendingMembers!"}
        />
      )
    }
    pendingMembers = pendingMembers.filter(
      contact => !contact.is_archived && !contact.is_converted
    )
    return pendingMembers.map((lead, i) => this.renderLeadRow(i, lead))
  }
  render() {
    return (
      <div id="pendingMembers" className="animated fadeIn">
        <div className="row">
          <div className="col-md-12">
            <div className="row border-bottom-1 my-3">
              <div className="col-md-5">
                <h2>Pending Members</h2>
              </div>
            </div>
            {this.props.isFetching ? <Spinner /> : this.renderPendingMembers()}
          </div>
        </div>
      </div>
    )
  }
  /* eslint-enable max-len */
}

PendingMembers.displayName = 'PendingMembers'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetching: state.ui.isFetching,
    pendingMembers:
      state.leads && state.leads.pendingMembers
        ? state.leads.pendingMembers
        : [],
  }
}

function mapDispatchToProps(dispatch) {
  return {
    lead_actions: bindActionCreators(leadActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingMembers)
