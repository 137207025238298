import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const ListItem = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 8px;
  padding-left: 20px;
  margin-left: ${props => (props.indentSize ? props.indentSize : undefined)};

  .icon {
    position: absolute;
    left: 0;
    top: 3px;
  }
`
export default function InvoiceLink({
  isCurrent,
  label,
  invoiceId,
  indentSize,
}) {
  return (
    <ListItem indentSize={indentSize}>
      {isCurrent ? (
        <React.Fragment>
          <FontAwesomeIcon className="icon" icon={'external-link-alt'} />{' '}
          {`${label} (current)`}
        </React.Fragment>
      ) : (
        <Link to={`/billing/invoices/${invoiceId}`}>
          <FontAwesomeIcon className="icon" icon={'external-link-alt'} />
          {label}
        </Link>
      )}
    </ListItem>
  )
}

InvoiceLink.displayName = 'Invoice Link'
InvoiceLink.propTypes = {
  isCurrent: PropTypes.bool,
  indentSize: PropTypes.string,
  label: PropTypes.string,
  invoiceId: PropTypes.string,
}
