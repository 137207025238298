/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from 'reactstrap'
import RoundedImage from '@global/RoundedImage'

const CampusCard = ({
  theKey,
  campus,
  name,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  handleEditCampusClicked,
  picture,
}) => (
  <div key={campus && campus.id ? campus.id : theKey}>
    <div className="card-block">
      <div className="row">
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-2">
              <RoundedImage photo={picture} alt="Picture" size="medium" />
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <h5>{name}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {address1 ? (
                    <small>
                      <p className="mb-0">
                        {address1}
                        <br />
                        {address2} {address2 ? <br /> : null}
                        {city}, {state} {zip}
                        <br />
                        {country}
                      </p>
                    </small>
                  ) : (
                    <small>None</small>
                  )}
                </div>
                {/*<div className='col'>
                  <small><h4 className='h6'>Members</h4></small>
                  <small><p className='mb-0'>XX Members<br/>
                  </p>
                  </small>
                </div>*/}
                {/* <div className='col'>
                  <small><h4 className='h6'>Revenue</h4></small>
                  <p>$XX,XXX/Month</p>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-end">
          <Button
            className="btn-outline-primary btn-sm btn"
            onClick={() => handleEditCampusClicked(campus)}
          >
            View
          </Button>
        </div>
      </div>
    </div>
  </div>
)

CampusCard.displayName = 'CampusCard'
export default CampusCard
