/* eslint-disable no-invalid-this,max-depth,radix */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as Constants from '@global/Constants'
import { LEAD, PROSPECT } from '@global/Constants/CRMConstants'
import LeadForm from './LeadForm'
import * as leadActions from '@reduxActions/leadActions'
import * as userActions from '@reduxActions/userActions'
import * as taskActions from '@reduxActions/taskActions'
import urlParse from '@app/hocs/urlParse'

import { toast } from 'react-toastify'
import * as uiActions from '@reduxActions/uiActions'
import moment from 'moment/moment'
import Spinner from '@global/Spinner'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import CommentsComponent from '@global/CommentsComponent'
import {
  resolveFetchingStatus,
  FETCHING_LEADS,
} from '@global/Constants/FetchingConstants'
import * as _ from 'lodash'
import { FETCHING_PROSPECTS } from '../Global/Constants/FetchingConstants'
import { extractCoworksErrorObject } from '@global/ErrorFactory'

const populateLeadInfo = lead => {
  const val = lead ? lead : {}
  const hasLead = Boolean(lead)
  const {
    id = null,
    notes = '',
    description = '',
    email = '',
    first_name = '',
    company_name = '',
    team_website = '',
    last_name = '',
    title = '',
    contact_photo = null,
    custom_field = { fields: [] },
    phone = '',
    toured = false,
    is_archived = false,
    is_lead = undefined,
    tour_date = null,
    follow_up_date = null,
    tags = null,
    tasks = [],
    dynamic_tags = [],
  } = _.omitBy(val, _.isNil)
  return {
    isEditing: hasLead,
    lead: hasLead ? lead : null,
    id,
    notes,
    description,
    email,
    title,
    phone,
    tags,
    tasks,
    teamWebsite: team_website,
    firstName: first_name,
    teamName: company_name,
    lastName: last_name,
    userPhoto: contact_photo,
    hasToured: toured,
    isInactive: is_archived,
    isLead: is_lead,
    dynamicTags: dynamic_tags,
    customFieldsObject: custom_field,
    followUpDate: follow_up_date ? moment(follow_up_date) : null,
    userPhotoPreview: contact_photo ? contact_photo.file_url : '',
    tourDate: tour_date ? moment(tour_date) : moment().hour(13).minute(0),
  }
}

export class Lead extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    lead_actions: PropTypes.object,
    user_actions: PropTypes.object,
    task_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    contactId: PropTypes.string,
    params: PropTypes.object,
    isPosting: PropTypes.bool,
    isFetching: PropTypes.bool,
    plans: PropTypes.array,
    teams: PropTypes.array,
    groups: PropTypes.array,
  }

  constructor(props) {
    super(props)
    const { history, params } = props
    const type = params.tabId
    const lead = history.location.state ? history.location.state.lead : null
    const isLead = type === 'leads'
    const leadState = populateLeadInfo(lead)
    leadState.isLead =
      leadState.isLead !== undefined ? leadState.isLead : isLead
    this.state = {
      isLead: leadState.isLead ? leadState.isLead : isLead,
      type,
      fetching:
        (lead && !leadState.lead.created_at) || Boolean(params.contactId),
      creatingNote: false,
      customTagState: [],
      allowFormSubmit: false,
      comment: '',
      dropdownOpen: false,
      ...leadState,
    }
  }

  UNSAFE_componentWillMount() {
    const {
      groups,
      ui_actions,
      lead_actions,
      activeCampus,
      params,
      contactId,
      history,
    } = this.props
    if (!groups) {
      ui_actions.getGroups(activeCampus.id)
    } else {
      this.setState({
        customTagState: Constants.parseCustomTagState(
          'contact',
          this.getGroups(),
          this.state.tags
        ),
      })
    }

    if (contactId) {
      lead_actions
        .getContact(contactId, params.tabId)
        .then(lead => {
          const leadState = populateLeadInfo(lead)
          this.setState({
            ...this.state,
            ...leadState,
            fetching: false,
          })
        })
        .catch(err => {
          toast.error('There was a problem loading the tasks.')
          this.setState({ fetching: false })
        })
        .finally(() => {
          this.setState({ fetching: false })
        })
    }
  }

  handleImageUpload = obj => {
    this.setState({
      userPhoto: obj.file,
      userPhotoPreview: obj.imagePreviewUrl,
      allowFormSubmit: true,
    })
  }

  handleFormSubmit = (values, setSubmitting) => {
    if (this.state.isEditing) {
      this.updateContact(values, setSubmitting)
    } else {
      this.createContact(values, setSubmitting)
    }
  }
  handleCustomDropdownChange = (event, groupId) => {
    const newState = Constants.parseCustomFieldsToCustomTagState(
      event,
      this.state.customTagState,
      groupId
    )
    newState.allowFormSubmit = true
    this.setState(newState)
  }
  createContact = (formState, setSubmitting) => {
    const { customTagState, isLead } = this.state
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    const contactObj = {
      notes: formState.notes,
      campus_id: this.props.activeCampus.id,
      community_id: this.props.activeCommunity.id,
      email: formState.email,
      first_name: formState.firstName,
      last_name: formState.lastName,
      title: formState.title,
      description: formState.description,
      phone: formState.phone,
      company_name: formState.teamName,
      team_website: formState.teamWebsite,
      is_lead: this.state.isLead,
      toured: isLead ? this.state.hasToured : null,
      tour_date: isLead ? this.state.tourDate : null,
      follow_up_date: isLead ? this.state.followUpDate : null,
      custom_tag_state: isLead ? JSON.stringify(customTagState) : null,
      custom_fields: JSON.stringify(this.state.customFieldsObject),
      type: this.state.isLead ? LEAD : null,
      dynamic_tags: JSON.stringify(this.state.dynamicTags),
    }
    if (this.state.userPhotoPreview) {
      contactObj.contact_photo = this.state.userPhotoPreview
    }
    this.props.lead_actions
      .createContact(contactObj, this.state.isLead ? LEAD : PROSPECT)
      .then(() => {
        setSubmitting(false)
        this.props.history.push(`/crm/${isLead ? 'leads' : 'prospects'}`)
      })
      .catch(err => {
        toast.error(extractCoworksErrorObject(err))
        setSubmitting(false)
      })
  }

  updateContact = (formState, setSubmitting) => {
    const { customTagState, isLead } = this.state
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    const leadObj = {
      id: this.state.id,
      campus_id: this.props.activeCampus.id,
      community_id: this.props.activeCommunity.id,
      notes: formState.notes,
      email: formState.email,
      company_name: formState.teamName,
      team_website: formState.teamWebsite,
      description: formState.description,
      first_name: formState.firstName,
      last_name: formState.lastName,
      title: formState.title,
      phone: formState.phone,
      custom_fields: JSON.stringify(this.state.customFieldsObject),
      toured: isLead ? this.state.hasToured : null,
      tour_date: formState.tourDate,
      follow_up_date: isLead ? this.state.followUpDate : null,
      custom_tag_state: isLead ? JSON.stringify(customTagState) : null,
      is_lead: this.state.isLead,
      type: this.state.isLead ? LEAD : null,
      dynamic_tags: JSON.stringify(this.state.dynamicTags),
    }
    if (this.state.userPhotoPreview) {
      leadObj.contact_photo = this.state.userPhotoPreview
    }

    this.props.lead_actions
      .updateContact(leadObj, this.state.isLead ? LEAD : PROSPECT)
      .then(() => {
        setSubmitting(false)
        this.props.history.push(`/crm/${isLead ? 'leads' : 'prospects'}`)
      })
      .catch(err => {
        toast.error(extractCoworksErrorObject(err))
        setSubmitting(false)
      })
  }
  handleSubmitComment = () => {
    this.setState({ creatingNote: true })
    this.props.task_actions
      .createTask({
        description: this.state.comment,
        contact_id: this.state.lead.id,
        created_by_user_id: this.props.user.id,
      })
      .then(response => {
        if (response && response.task) {
          const newTask = response.task
          newTask.created_by_user = this.props.user
          this.setState({
            tasks: [...this.state.tasks, newTask],
            comment: '',
          })
        }
      })
      .catch(err => {
        toast.error(extractCoworksErrorObject(err))
      })
      .finally(() => {
        this.setState({
          creatingNote: false,
        })
      })
  }
  archiveLead = status => {
    const { type, id } = this.state
    const leadObj = {
      id,
      campus: this.props.activeCampus.id,
      is_archived: status,
    }

    this.props.lead_actions
      .archiveContact(leadObj, this.state.isLead ? LEAD : PROSPECT)
      .then(() => {
        this.props.history.push(`/crm/${type}`)
      })
      .catch(err => {
        toast.error(extractCoworksErrorObject(err))
      })
  }
  convertLead = event => {
    const { type, id } = this.state
    event.preventDefault()
    const leadObj = {
      id,
      campus: this.props.activeCampus.id,
      is_converted: true,
    }
    this.props.lead_actions
      .updateContact(leadObj, this.state.isLead ? LEAD : PROSPECT)
      .then(() => {
        this.props.history.push(`/crm/${type}`)
      })
      .catch(err => {
        toast.error()
      })
  }
  goBack = event => {
    event.preventDefault()
    this.props.history.goBack()
  }
  toggleIsLead = () => {
    this.setState(
      {
        isLead: !this.state.isLead,
        allowFormSubmit: true,
        customTagState: Constants.parseCustomTagState(
          'contact',
          this.getGroups(!this.state.isLead),
          this.state.tags
        ),
      },
      () => {}
    )
  }
  toggleHasToured = value => {
    this.setState({
      hasToured: !this.state.hasToured,
      allowFormSubmit: true,
    })
  }
  handleTourDateChanged = mom => {
    this.setState({
      tourDate: mom,
      allowFormSubmit: true,
    })
  }
  handleFollowUpDateChanged = mom => {
    this.setState({
      followUpDate: mom,
      allowFormSubmit: true,
    })
  }
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleInputValuesChange = (name, value) => {
    this.setState({
      [name]: value,
      allowFormSubmit: true,
    })
  }
  handleTourDateFocused = focused => {
    this.setState({
      tourDateFocused: focused,
    })
  }
  handleFollowUpDateFocused = focused => {
    this.setState({
      followUpDateFocused: focused,
    })
  }
  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }
  promoteToMemberNewTeamClicked = event => {
    const lead = this.state.lead
    this.props.history.push(`/directory/teams/add-team?${lead.id}`, { lead })
  }
  promoteToMemberExistingTeamClicked = event => {
    const lead = this.state.lead
    this.props.history.push(`/directory/members/add-member?${lead.id}`, {
      lead,
    })
  }
  followUpQuickButtonSelected = selection => {
    const date = moment()
    switch (selection) {
      case 0:
        date.add(7, 'days')
        break
      case 1:
        date.add(14, 'days')
        break
      case 2:
        date.add(1, 'months')
        break
      default:
        break
    }
    this.setState({
      followUpDate: date,
    })
  }
  getGroups = bool => {
    if (!this.props.groups) return null
    const result = this.props.groups.filter(group => {
      const isLeadCheck = bool !== undefined ? bool : this.state.isLead
      if (!isLeadCheck) {
        return group.name !== 'Lead Status'
      }
      return true
    })
    return result
  }

  render() {
    const { activeCommunity, isFetching, isPosting } = this.props
    const {
      lead,
      notes,
      hasToured,
      email,
      teamName,
      teamWebsite,
      title,
      firstName,
      userPhoto,
      userPhotoPreview,
      lastName,
      description,
      phone,
      customFieldsObject,
      customTagState,
      temperature,
      dynamicTags,
      fetching,
      allowFormSubmit,
      tourDate,
      followUpDate,
      isLead,
      isInactive,
      tourDateFocused,
      followUpDateFocused,
      dropdownOpen,
      tasks,
      comment,
      creatingNote,
      isEditing,
    } = this.state

    return isFetching || fetching ? (
      <Spinner />
    ) : (
      <div className="animated fadeIn">
        <LeadForm
          activeCommunity={activeCommunity}
          leadId={lead ? lead.id : null}
          notes={notes}
          isEditing={isEditing}
          hasToured={hasToured}
          archiveLead={this.archiveLead}
          email={email}
          checkEmailExists={this.props.lead_actions.checkEmailExists}
          handleFormSubmit={(values, setSubmitting) =>
            this.handleFormSubmit(values, setSubmitting)
          }
          teamName={teamName}
          teamWebsite={teamWebsite}
          firstName={firstName}
          title={title}
          userPhoto={userPhoto}
          userPhotoPreview={userPhotoPreview}
          goBack={this.goBack}
          handleImageUpload={this.handleImageUpload}
          handleInputChange={this.handleInputChange}
          handleInputValuesChange={this.handleInputValuesChange}
          dynamicTags={dynamicTags}
          lastName={lastName}
          description={description}
          phone={phone}
          customFieldsObject={customFieldsObject}
          temperature={temperature}
          convertLead={this.convertLead}
          isConverted={lead ? lead.is_converted : false}
          customFieldMax={activeCommunity.community_preference.custom_field_max}
          renderCustomDropdowns={() =>
            Constants.renderCustomDropdowns(
              this.getGroups(),
              customTagState,
              (event, groupId) =>
                this.handleCustomDropdownChange(event, groupId)
            )
          }
          toggleIsLead={this.toggleIsLead}
          toggleHasToured={this.toggleHasToured}
          allowFormSubmit={allowFormSubmit}
          tourDate={tourDate}
          followUpDate={followUpDate}
          lead={lead}
          isLead={isLead}
          isInactive={isInactive}
          getGeneratedFromTag={() =>
            lead ? Constants.getGeneratedFromBadge(lead.created_by) : null
          }
          handleTourDateChanged={this.handleTourDateChanged}
          handleFollowUpDateChanged={this.handleFollowUpDateChanged}
          tourDateFocused={tourDateFocused}
          followUpDateFocused={followUpDateFocused}
          handleTourDateFocused={this.handleTourDateFocused}
          handleFollowUpDateFocused={this.handleFollowUpDateFocused}
          followUpQuickButtonSelected={selection =>
            this.followUpQuickButtonSelected(selection)
          }
          dropdownOpen={dropdownOpen}
          toggleDropdown={this.toggleDropdown}
          promoteToMemberNewTeamClicked={this.promoteToMemberNewTeamClicked}
          promoteToMemberExistingTeamClicked={
            this.promoteToMemberExistingTeamClicked
          }
        />
        {isEditing && (
          <React.Fragment>
            {isPosting ? (
              <Spinner />
            ) : (
              CommentsComponent({
                tasks: tasks,
                handleInputChange: this.handleInputChange,
                comment: comment,
                handleSubmitComment: this.handleSubmitComment,
                loading: creatingNote,
              })
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

Lead.displayName = 'Lead'

function mapStateToProps(state, ownProps) {
  const { params, history } = ownProps
  let id = null
  if (
    history &&
    history.location &&
    history.location.state &&
    history.location.state.lead &&
    history.location.state.lead.id
  ) {
    id = history.location.state.lead.id
  } else if (
    history &&
    history.location &&
    history.location.state &&
    history.location.state.contactId
  ) {
    id = history.location.state.contactId
  } else if (params.contactId) {
    // for now we can't rely on this!
  }
  return {
    contactId: id,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    checkEmailExists: state.checkEmail.check_email,
    checkedEmail: state.checkEmail.email,
    isPosting: state.ui.isPosting,
    isFetching:
      resolveFetchingStatus(state, FETCHING_LEADS) ||
      resolveFetchingStatus(state, FETCHING_PROSPECTS),
    groups: state.ui.groups,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    lead_actions: bindActionCreators(leadActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
    task_actions: bindActionCreators(taskActions, dispatch),
  }
}

export default compose(
  urlParse(),
  connect(mapStateToProps, mapDispatchToProps)
)(Lead)
