/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import * as Constants from '@global/Constants'

import TimeDropdown from './TimeDropdown'

const TimeDropdownWithBusinessHours = ({
  date,
  publicCampus,
  earliestTime,
  latestTime,
  timeFormat = 'hh:mm a',
  ...other
}) => {
  const passedEarliest = earliestTime
    ? Constants.convertTimeToMinutes(earliestTime, timeFormat)
    : null
  const passedLatest = latestTime
    ? Constants.convertTimeToMinutes(latestTime, timeFormat)
    : null
  const [earliest, setEarliest] = useState(passedEarliest)
  const [latest, setLatest] = useState(passedLatest)
  const firstRun = useRef(true)

  useEffect(() => {
    if (
      publicCampus &&
      publicCampus.preferences &&
      publicCampus.preferences.hours_of_operation
    ) {
      const hoursCopy = publicCampus.preferences.hours_of_operation
      const dayOfWeek = moment(date).clone().format('dddd').toLowerCase()
      const dayOfWeekObject = hoursCopy[dayOfWeek][0]

      setEarliest(dayOfWeekObject.open / 60)
      setLatest(dayOfWeekObject.close / 60)
    }
  }, [date, publicCampus]) // earliest, latest,

  useEffect(() => {
    // this is to make sure the newly selected date doesn't cause the previous times to be
    // outside the allowed bounds of the new date.
    if (!firstRun.current) {
      const passedEarliest = earliestTime
        ? Constants.convertTimeToMinutes(earliestTime, timeFormat)
        : earliest
      const passedLatest = latestTime
        ? Constants.convertTimeToMinutes(latestTime, timeFormat)
        : latest
      setEarliest(passedEarliest)
      setLatest(passedLatest)
    } else {
      firstRun.current = false
    }
  }, [earliestTime, latestTime])
  
  return <TimeDropdown earliestTime={earliest} latestTime={latest} {...other} />
}

TimeDropdownWithBusinessHours.propTypes = {
  date: PropTypes.object,
  publicCampus: PropTypes.object.isRequired,
  earliestTime: PropTypes.string,
  latestTime: PropTypes.string,
  timeFormat: PropTypes.string,
}

TimeDropdownWithBusinessHours.displayName = 'TimeDropdownWithBusinessHours'

function mapStateToProps(state) {
  return {
    publicCampus: state.ui.publicCampus,
  }
}

export default connect(mapStateToProps, null)(TimeDropdownWithBusinessHours)
