import React from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const SpinnerStyled = styled.div`
  color: ${props =>
    props.color ? props.color : props.theme.colorPrimary}!important;
`

export default function Spinner({ size, color }) {
  return (
    <SpinnerStyled color={color} className="loading-spinner-contextual">
      <FontAwesomeIcon icon="circle-notch" size={size} spin />
    </SpinnerStyled>
  )
}

Spinner.propTypes = { size: PropTypes.string, color: PropTypes.string }
Spinner.displayName = 'Spinner'
