/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import Badge from '@global/Base/Badge/Badge'

export default function TagRow({ object, shouldCenterTags }) {
  function renderTags() {
    return object.tags.map(tag => (
      <Badge
        key={tag.id}
        className="mr-1"
        size="small"
        backgroundColor={`#${tag.color}`}
      >
        {tag.name}
      </Badge>
    ))
  }

  if (object && object.tags && object.tags.length > 0) {
    return (
      <div className={shouldCenterTags ? 'col-12 mb-2' : ''}>
        {renderTags()}
      </div>
    )
  }
  return null
}
TagRow.displayName = 'TagRow'
TagRow.propTypes = {
  object: PropTypes.object,
  shouldCenterTags: PropTypes.bool,
}
