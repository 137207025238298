/* eslint-disable react/no-multi-comp,react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as memberActions from '@app/reduxActions/memberActions'
import * as teamActions from '@app/reduxActions/teamActions'
import * as uiActions from '@app/reduxActions/uiActions'
import * as networkActions from '@app/reduxActions/networkActions'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import MemberSelector from '../Members/MemberSelector'
import Spinner from '@global/Spinner'
import moment from 'moment'

// This component was copied from Members.jsx
class PassportList extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    network_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    ui_actions: PropTypes.object,
    user: PropTypes.object,
    role: PropTypes.string,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      isFetching: true,
      showCheckinModal: false,
    }
  }

  toggleCheckinModal = () => {
    this.setState({
      showCheckinModal: !this.state.showCheckinModal,
    })
  }
  viewMemberClicked = (member, team) => {
    this.setState(
      {
        member,
      },
      () => {
        this.toggleCheckinModal()
      }
    )
  }

  checkInVisitor = member => {
    const { activeCampus, activeCommunity, user } = this.props
    this.props.network_actions
      .checkinMember({
        campus_id: activeCampus.id,
        community_id: activeCommunity.id,
        user_id: member.id,
        creator_id: user.id,
        time_in: moment().toISOString(),
      })
      .then(response => {
        toast.success(`Checked in ${member.first_name}`)
        this.props.history.goBack()
      })
      .catch(err => {
        toast.error(`Failed to check-in ${member.first_name}`)
      })
  }

  render() {
    const { canEdit, isFetching, showCheckinModal } = this.state
    const { activeCommunity, activeCampus } = this.props
    return (
      <div className="animated fadeIn card">
        <div className="col-md-12">
          <MemberSelector
            toggleModal={() => {}}
            headerText={'Who are you checking in?'}
            action={this.checkInVisitor}
            campusId={activeCampus.id}
            actions={this.props.network_actions}
            showCloseButton={false}
            buttonText={'Checkin'}
            otherParams={{ community_id: activeCommunity.id }}
            type="Ping [MemberName]"
          />
          {/* {this.renderPaginatedMembers(canEdit)} */}
        </div>
      </div>
    )
  }
}

PassportList.displayName = 'PassportList'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    teams: state.teams.teams,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    member_actions: bindActionCreators(memberActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
    network_actions: bindActionCreators(networkActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PassportList)
