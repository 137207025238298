import Authorization from '../hocs/withAuth'

// ------- Roles --------
// L1
const superAdminRole = 'SuperAdmin' // only Coworks Team Members
const adminRole = 'Admin'
const managerRole = 'Manager'
// L2
const accountantRole = 'Accountant'
const eventsCoordinatorRole = 'EventsCoordinator'
const staffRole = 'Staff'
const dataAdminRole = 'DataAdmin'
const salesAdminRole = 'SalesAdmin'
// L3
const memberRole = 'Member'
//const guestRole = 'Guest'; // todo eventually guest?

// ------- Roles wrapped in Authorization HOC --------

// Space staff L1 roles wrapped in Authorization HOC
const SuperAdmin = Authorization([superAdminRole])
const Admin = Authorization([superAdminRole, adminRole])
const Manager = wrapWithAuth() // has manager and other top 3 permissions

// Space Staff L2 (including top L1 roles) wrapped in Authorization HOC
const Accountant = wrapWithAuth([accountantRole])
const EventsCoordinator = wrapWithAuth([eventsCoordinatorRole])
const Staff = wrapWithAuth([staffRole])
const DataAdmin = wrapWithAuth([dataAdminRole])
const SalesAdmin = wrapWithAuth([salesAdminRole])

// L3 Roles
const MemberAccessRoles = withL1Roles([
  accountantRole,
  eventsCoordinatorRole,
  staffRole,
  dataAdminRole,
  salesAdminRole,
  memberRole,
])

const Member = wrapWithAuth(MemberAccessRoles)

// ---- Role Groups wrapped in Authorization HOC -----
// handy role groups wrapped with Authorization HOC
const justSpaceStaff = wrapWithAuth([
  accountantRole,
  eventsCoordinatorRole,
  staffRole,
  dataAdminRole,
  salesAdminRole,
])
const justMembers = Authorization([memberRole])

// ---- (vanilla) Role Groups ----
// everyone but members
const justSpaceStaffRoles = withL1Roles([
  accountantRole,
  eventsCoordinatorRole,
  staffRole,
  dataAdminRole,
  salesAdminRole,
])

// ------ Helper Methods and Lists ------
// doesn't include superAdmin and Admin because you can't change those in front end
const listOfRolesForFrontEnd = [
  { role: managerRole, name: 'Manager' },
  { role: accountantRole, name: 'Accountant' },
  //{ role: eventsCoordinatorRole, name: 'Events Coordinator' },
  //{ role: dataAdminRole, name: 'Data Admin' },
  { role: salesAdminRole, name: 'Sales Admin' },
  { role: staffRole, name: 'Staff' },
  { role: memberRole, name: 'Member' },
]
// Generic list of all roles
const listOfRoles = [
  adminRole,
  managerRole,
  accountantRole,
  eventsCoordinatorRole,
  staffRole,
  dataAdminRole,
  salesAdminRole,
  memberRole,
]
// List of roles that can edit everything
const canEditRoles = withL1Roles()
// List of roles that can view payment/billing information
const canViewEditBilling = withL1Roles([accountantRole, salesAdminRole])
// Function to wrap array with Authorization HOC including L1 roles
function wrapWithAuth(array) {
  // eslint-disable-next-line curly
  if (array && Array.isArray(array))
    return Authorization(array.concat([superAdminRole, managerRole, adminRole]))
  return Authorization([superAdminRole, managerRole, adminRole])
}
// Function to merge array with L1 roles
function withL1Roles(array) {
  // eslint-disable-next-line curly
  if (array && Array.isArray(array))
    return array.concat([superAdminRole, managerRole, adminRole])
  return [superAdminRole, adminRole, managerRole]
}

//import * as Roles from '../../config/roles'; //todo implement this where roles are used
export {
  SuperAdmin,
  Admin,
  Manager,
  Accountant,
  EventsCoordinator,
  Staff,
  DataAdmin,
  SalesAdmin,
  Member,
  MemberAccessRoles,
  justSpaceStaff,
  justSpaceStaffRoles,
  justMembers,
  listOfRolesForFrontEnd,
  listOfRoles,
  accountantRole,
  superAdminRole,
  eventsCoordinatorRole,
  staffRole,
  dataAdminRole,
  salesAdminRole,
  memberRole,
  wrapWithAuth,
  canEditRoles,
  canViewEditBilling,
}
