/* eslint-disable no-invalid-this,quote-props */
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { readableColor } from 'polished'

import moment from 'moment'

const OverflowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const HeaderWrapper = styled.div`
  flex-grow: 1;
  max-width: 100%;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 10%;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`
const BottomTime = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  flex: 1 0 auto;
  @media (max-width: 768px) {
    h6 {
      margin-bottom: 5px;
    }
  }
`
const TopTime = styled.div`
  position: relative;
  flex: 1 0 auto;
`
const DetailsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  min-width: 140px;
  @media (max-width: 768px) {
    justify-content: space-between;
    min-height: 25px;
  }
`
const BookingType = styled.div`
  text-transform: uppercase;
  position: absolute;
  top: 0;
  color: #b0bec5 !important;
  @media (max-width: 768px) {
    position: relative;
    top: auto;
  }
`
const TimeTillWrapper = styled.div`
  position: absolute;
  bottom: 0;
  @media (max-width: 768px) {
    position: relative;
    bottom: auto;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100px;
  width: 100%;
`
const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  border-left: 1px #ccc solid;
  border-bottom: 1px #ccc solid;
  min-width: 130px;
  @media (max-width: 768px) {
    min-width: 100px;
  }
`
const Content = styled.div`
  display: flex;
  flex: 1 0 auto;
  border-right: 1px #ccc solid;
  border-bottom: 1px #ccc solid;
  width: 80%;
  @media (max-width: 768px) {
    width: 10%;
    flex-direction: column;
  }
`
const Divider = styled.div`
  width: 1px;
`
const ButtonStyled = styled.div`
  pointer-events: all;
  border-color: ${props =>
    props.color ? props.color : props.theme.colorPrimary};
  color: ${props => (props.color ? props.color : props.theme.colorPrimary)};
  &:hover {
    background: ${props =>
      props.color ? props.color : props.theme.colorPrimary};
    color: ${props => props.theme.colorWhite};
    border-color: transparent;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
/* eslint-disable no-unused-expressions */
const Type = styled.h6`
  opacity: ${props => (props.light ? 0.7 : 1)};
  ${props =>
    props.min
      ? css`
          margin-bottom: 3px;
        `
      : ''};
`
const Time = styled.h6``
const Header = styled.h4`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`
const Subheader = styled.h6``
const Text = styled.h6``
const Banner = styled.div`
  width: 9px;
  background: ${props => props.color};
`
const DetailContainer = styled.div``

const Container = styled.div`
  display: flex;
  flex-direction: row;
  pointer-events: none;

  @media (max-width: 768px) {
    pointer-events: all;
    cursor: pointer;

    font-size: 14px;
    .btn {
      display: none;
    }
    h4 {
      font-size: 20px;
    }
    h6 {
      font-size: 14px;
    }
    .col {
      padding: 0px 8px;
      basis: auto;
    }
  }
  @media (max-width: 580px) {
    h4 {
      font-size: 18px;
    }
  }
`

class BookingsCard extends React.Component {
  static propTypes = {
    booking: PropTypes.object,
    unique: PropTypes.bool,
    onClick: PropTypes.function,
    brandColor: PropTypes.string,
  }

  getTime = booking => {
    let topTime = null
    let bottomTime = null
    const timeFormat = 'h:mm A'
    const dayFormat = 'MMM Do'

    if (booking) {
      const startTime = moment(booking.start_time)
      const endTime = moment(booking.end_time)

      const hrsDiff = endTime.diff(startTime, 'hours')
      if (hrsDiff >= 24) {
        topTime = startTime.format(dayFormat)
        bottomTime = endTime.format(dayFormat)
      } else {
        topTime = startTime.format(timeFormat)
        bottomTime = endTime.format(timeFormat)

        const topZone = topTime.substring(topTime.length - 2)
        const bottomZone = bottomTime.substring(bottomTime.length - 2)
        if (topZone.match(bottomZone)) {
          topTime = topTime.substring(0, topTime.length - 2)
        }
      }
    }

    let duration = Number(
      moment
        .duration(moment(booking.end_time).diff(moment(booking.start_time)))
        .asHours()
    ).toFixed(2)
    let unitsString = 'hours'
    if (duration < 1) {
      unitsString = 'minutes'
      duration = moment
        .duration(moment(booking.end_time).diff(moment(booking.start_time)))
        .asMinutes()
    }

    return (
      <TimeWrapper className="p-2">
        <TopTime>
          <Type min>{`${topTime} -`}</Type>
          <Type light>{bottomTime}</Type>
        </TopTime>
        {bottomTime && (
          <BottomTime>
            <Type light>{`${duration} ${unitsString}`}</Type>
          </BottomTime>
        )}
      </TimeWrapper>
    )
  }

  getStartTime = startTime => {
    if (moment(startTime).isAfter() && moment().isSame(startTime, 'day')) {
      const timeTill = moment(startTime).fromNow(true)
      return `starts in ${timeTill}`
    }
    return null
  }

  getContent = booking => {
    const { unique, brandColor } = this.props
    return (
      <Content className="p-2">
        <ContentWrapper>
          <HeaderWrapper>
            <Header>{booking.name}</Header>
          </HeaderWrapper>
          <DetailContainer>
            {unique && booking.room && (
              <Subheader>{booking.room.name}</Subheader>
            )}
            <Subheader>
              {booking.user &&
                `${booking.user.first_name} ${booking.user.last_name}`}
            </Subheader>
          </DetailContainer>
        </ContentWrapper>
        <DetailsWrapper>
          <BookingType>{booking.type}</BookingType>
          <ButtonStyled
            className="btn btn-outline-primary"
            onClick={() => this.props.onClick(booking)}
            color={brandColor}
          >
            Details
          </ButtonStyled>
          <TimeTillWrapper className="text-muted">
            {this.getStartTime(booking.start_time)}
          </TimeTillWrapper>
        </DetailsWrapper>
      </Content>
    )
  }

  getTimeTill = time => {
    const timeObj = moment(time)
    if (timeObj.diff(moment(), 'days') === 0) {
      return timeObj.fromNow()
    }

    return null
  }

  /* eslint-disable max-len */
  render() {
    const { booking, unique } = this.props
    return (
      <Container onClick={() => this.props.onClick(booking)}>
        <Wrapper>
          {unique && <Banner color={booking.room && booking.room.color} />}
          {this.getTime(booking)}
          {this.getContent(booking)}
        </Wrapper>
      </Container>
    )
  }
}

BookingsCard.displayName = 'BookingsCard'

export default BookingsCard
