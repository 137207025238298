import React from 'react'
import PropTypes from 'prop-types'
import Anchor from '@global/Base/Anchor/Anchor'
import TagRow from '@global/TagRow'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
  opacity: ${props => (props.inactive ? 0.6 : 1)};
`

const LeadCol = ({ lead, onClick, ...other }) => {
  let name = null
  if (lead.first_name && lead.last_name) {
    name = `${lead.first_name} ${lead.last_name}`
  } else if (lead.first_name) {
    name = `${lead.first_name}`
  } else if (lead.last_name) {
    name = `${lead.last_name}`
  } else {
    name = 'No Name'
  }

  return (
    <Container inactive={lead.is_archived} {...other}>
      <h4 className={name === 'No Name' ? 'text-muted' : undefined}>
        <Anchor
          color="secondary"
          className="my-0 p-0"
          onClick={() => onClick(lead)}
        >
          {name}
        </Anchor>
      </h4>
      {lead.company_name && (
        <h6 className="text-muted">
          <FontAwesomeIcon icon="briefcase" />{' '}
          {lead.company_name ? lead.company_name : ''}
        </h6>
      )}
      <h6>
        {lead.email ? (
          <Anchor href={`mailto:${lead.email}`} title={`Email ${lead.email}`}>
            <FontAwesomeIcon icon="envelope" /> {lead.email}
          </Anchor>
        ) : (
          ''
        )}
      </h6>
      <div>
        <TagRow object={lead} />
      </div>
    </Container>
  )
}

LeadCol.propTypes = {
  lead: PropTypes.object,
  onClick: PropTypes.func,
}

LeadCol.displayName = 'Lead Column'

export default LeadCol
