/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import Otto from '@app/img/placeholders/otto_color.png'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import styled, { css, withTheme } from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  padding-bottom: 12vh;
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 40%;
  max-width: 900px;
  margin: auto;
`
const ContentWrapper = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-start;
`
const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: end;

  h1 {
    font-weight: bold;
  }
  h4 {
    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 0.5rem;
  }
`
const Icon = styled(FontAwesomeIcon)`
  width: 37% !important;
  height: 100%;
`
const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const PageMessage = ({
  header,
  subheader,
  text,
  onClick,
  icon,
  buttonText = 'go back',
  colorPrimary = '#b0bec5',
  className,
}) => (
  <Container className={className}>
    <Wrapper>
      <Image>
        {icon ? (
          <Icon icon={icon} color={colorPrimary} />
        ) : (
          <img style={{ width: '15rem' }} src={Otto} />
        )}
      </Image>
      <ContentWrapper>
        <Content>
          <h1>{header}</h1>
          <h4>{subheader}</h4>
          <p>{text}</p>
          <TextButton
            className="m-0 p-0"
            backgroundColor={'#858585'}
            onClick={onClick}
          >
            <FontAwesomeIcon color={colorPrimary} icon="arrow-left" />
            {buttonText}
          </TextButton>
        </Content>
      </ContentWrapper>
    </Wrapper>
  </Container>
)

PageMessage.displayName = 'PageMessage'

PageMessage.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  theme: PropTypes.object,
  colorPrimary: PropTypes.object,
}

export default withTheme(PageMessage)
