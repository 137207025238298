import * as apiService from '../services/apiService'
import * as uiActions from './uiActions'
import * as actionTypes from './actionTypes'

export function updateFetchingReportStatus(isFetchingReport) {
  return { type: actionTypes.IS_FETCHING_REPORT, isFetchingReport }
}

// ACTION DISPATCHERS

export const getCommunityMembersReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityMembersReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}
export const getCheckinsReport = (params, type) => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCheckinsReport(params, type)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}

export const getCommunityTeamsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityTeamsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}
export const getCommunityPlansReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getStripePlansReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}

export const getCommunityBookingsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityBookingsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}
export const getCampusBookingsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCampusBookingsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}

export const getCommunityRoomsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityRoomsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}

export const getCommunityLeadsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityLeadsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}

export const getCommunityProspectsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityProspectsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}

export const getCommunityBookingTotalsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityBookingTotalsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}

export const getCommunityInvoicesReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityInvoicesReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}
export const getCommunityPaymentsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityPaymentsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}
export const getCommunityActiveMembershipsReport = params => dispatch => {
  dispatch(uiActions.updateFetchingReportStatus(true))
  return new Promise((resolve, reject) => {
    apiService
      .getCommunityActiveMembershipsReport(params)
      .then(response => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingReportStatus(false))
        reject(err)
      })
  })
}
