import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BookingTablet from './BookingTablet'
import { Row } from 'reactstrap'

const PER_ROW = 3
export default class CommandCenter extends Component {
  static propTypes = {
    prop: PropTypes,
    location: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = { tabletKeys: this.getTabletKeys() }
  }
  getTabletKeys() {
    if (!this.props.location.search) {
      return []
    }
    let search = this.props.location.search
    search = search.substring(1)
    const keys = search.split(',')
    const myKeys = []
    let row = []
    for (let count = 0; count < keys.length; count++) {
      const key = keys[count]
      if (count % PER_ROW === 0 && count > 0) {
        myKeys.push(row)
        row = []
      } else {
        row.push(key)
      }
    }
    return myKeys
  }

  render() {
    return (
      <div className="container-fluid">
        {this.state.tabletKeys.map((row, index) => (
          <Row>
            {row.map((tabletKey, index) => (
              <div className="col">
                <BookingTablet tabletKey={tabletKey} />
              </div>
            ))}
          </Row>
        ))}
      </div>
    )
  }
}
CommandCenter.displayName = 'CommandCenter'
