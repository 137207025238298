/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import styled, { css } from 'styled-components'
import Button, {
  IconButton,
  OutlineButton,
  TextButton,
} from '@bit/coworks.base-components.button'
import { lighten } from 'polished'
import * as Constants from '@global/Constants'

import moment from 'moment'
import { DayPickerSingleDateController } from 'react-dates'
import { DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap'
import { LabelStyled } from '@global/Form/FormComponents'

import InputRange from 'react-input-range'
import TimeDropdownWithBusinessHours from './TimeDropdownWithBusinessHours'
import GenericModal from '@global/GenericModal'
import { isInclusivelyBeforeDay } from 'react-dates'
import withBlockDays from '@app/hocs/withBlockDays'

const DayPickerBusinessHours = withBlockDays(DayPickerSingleDateController)

const GenericModalStyled = styled(GenericModal)`
  height: 100vh;
  max-width: none;

  .modal-content {
    border: none !important;
    width: 100%;
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-flow: ${props => (props.mobile ? 'column' : 'row')} nowrap;
  width: 100%;

  .DateInput_input__focused {
    border-bottom: 2px solid
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
  }

  .CalendarDay__selected {
    background: ${props =>
      props.color ? props.color : props.theme.colorPrimary};
    color: ${props => props.theme.colorWhite};
    border: 1px double
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
    &:hover {
      background: ${props =>
        lighten(0.1, props.color ? props.color : props.theme.colorPrimary)};
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid
      ${props => (props.color ? props.color : props.theme.colorPrimary)};
    color: ${props => props.theme.colorWhite};
  }
`
const Container = styled.div`
  display: flex;
  // flex-flow: ${props => (props.mobile ? 'column' : 'row')} nowrap;
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: ${props => (props.mobile ? 'column' : 'row')} nowrap;
  width: 100%;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`
const FooterWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const Menu = styled(DropdownMenu)`
  padding: 18px !important;
  @media (max-width: 768px) {
    display: none;
  }
`
const Overlay = styled.div`
  padding: 18px !important;
`
const LeftWrapper = styled.div`
  .DayPicker__withBorder {
    box-shadow: none;
  }
`
const OverlayHeader = styled.h5`
  margin: 0;
  font-weight: bold;
`
const WrapperDivider = styled.div`
  margin: 20px;
`
const RightWrapper = styled.div`
  min-width: 300px;
`
const TimeContainer = styled.div``
const InputText = styled.h6`
  display: flex;
  align-items: center;
  margin: ${props => (props.mobile ? '10px 0' : '0 20px')};
  font-size: ${props => (props.mobile ? '14' : '16')}px;
`
const SwitchContainer = styled.div`
  display: flex;
  flex-flow: ${props => (props.mobile ? 'column' : 'row')} nowrap;
  width: 100%;
  padding-left: ${props => (props.mobile ? '0' : '22')}px;
`
const ButtonLinkStyled = styled(TextButton)`
  margin: 0;
`
const FormWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 0 18px;

  .input-range {
    margin: 12px 0;
  }
`
const TimeDropdownWithBusinessHoursStyled = styled(
  TimeDropdownWithBusinessHours
)`
  width: 100%;
`
const ButtonContainer = styled.div`
  display: ${props => (props.mobile ? 'none' : 'flex')};
  justify-content: flex-end;
  padding-left: 22px;
  margin-top: 10px;
  button {
    margin-left: 10px;
  }
`
const ButtonStyled = styled(Button)``
const OutlineButtonStyled = styled(OutlineButton)``
const LeftButtonText = styled(TextButton)`
  margin-left: 0 !important;
  margin-right: auto;
`
const FormContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding-top: 18px;
`

const TimeFilter = ({
  handleTimeChange,
  fullscreen,
  defaultValue,
  brandColor,
  // values,
  // handleSubmit,
  // setFieldValue,
  // resetForm,
  publicCampus,
  onClear,
  open = false,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(open)
  const [values, setValues] = useState(defaultValue)
  const [error, setError] = useState(null)

  const setFieldValue = (field, val) => {
    const dup = { ...values }
    dup[field] = val
    setValues(dup)
  }

  const resetVals = () => {
    setValues(defaultValue)
  }

  const changeView = view => updateIsTime(view === 'time')
  const toggle = () => setIsOpen(prevState => !prevState)

  useEffect(() => {
    setIsOpen(open)
    resetVals()
  }, [open, defaultValue])

  useEffect(() => {
    checkValid()
  }, [values])

  const checkValid = () => {
    let error = null
    const { timeRange } = values
    const startTime = moment(timeRange.start, 'h:mm a')
    const endTime = moment(timeRange.end, 'h:mm a')
    if (endTime.isBefore(startTime))
      error = 'The end time must be after the start time.'
    setError(error)
  }

  const getLabelText = () => {
    const { date, timeRange, duration, isTime } = defaultValue
    if (isTime) {
      return `Date: ${date.format('MMM D')} @ ${timeRange.start} - ${
        timeRange.end
      }`
    }
    return `Date: ${date.format('MMM D')} @ ${duration}hrs`
  }

  const getInputText = () => {
    const { date, timeRange, duration, isTime } = values
    if (isTime) {
      return `${date.format('MMMM D')}, from ${timeRange.start} - ${
        timeRange.end
      }`
    }
    return `${date.format('MMMM D')}, for ${duration} hour(s)`
  }

  const handleTimePickerSelection = (pos, time) => {
    const dup = { ...values.timeRange }
    dup[pos] = time

    if (pos === 'start') {
      const date = values.date
      const hoursCopy = publicCampus.preferences.hours_of_operation
      const dayOfWeekObject =
        hoursCopy[date.clone().format('dddd').toLowerCase()][0]

      const dateString = date.format('LL')
      const end = Constants.convertMinutesToTime(dayOfWeekObject.close / 60)
      const newEnd = moment(`${dateString} ${end.format('LT')}`)
      const oldEnd = moment(`${dateString} ${time}`, 'LLL').add(1, 'hours')

      console.log(oldEnd, newEnd)
      if (newEnd.isBefore(oldEnd)) {
        dup.end = newEnd.format('h:mm a')
      } else {
        dup.end = oldEnd.format('h:mm a')
      }
    }

    setFieldValue('timeRange', dup)
  }
  const updateDuration = dur => {
    setFieldValue('duration', dur)
  }
  const updateIsTime = bol => {
    setFieldValue('isTime', bol)
  }
  const updateDate = date => {
    const hoursCopy = publicCampus.preferences.hours_of_operation
    const dayOfWeekObject =
      hoursCopy[date.clone().format('dddd').toLowerCase()][0]

    const start = Constants.convertMinutesToTime(dayOfWeekObject.open / 60)
    const end = Constants.convertMinutesToTime(dayOfWeekObject.close / 60)

    const endTime = start.clone().add(1, 'hours')
    // end = end.isAfter(endTime) ? endTime : end

    const dateString = date.format('LL')
    const oldStart = moment(`${dateString} ${values.timeRange.start}`, 'LLL')
    const newStart = moment(`${dateString} ${start.format('LT')}`)
    const oldEnd = moment(`${dateString} ${values.timeRange.end}`, 'LLL')
    const newEnd = moment(`${dateString} ${end.format('LT')}`)
    const hourFromNewStart = moment(`${dateString} ${endTime.format('LT')}`)

    const times = {
      start: values.timeRange.start,
      end: values.timeRange.end,
    }

    if (newStart.isAfter(oldStart) || oldStart.isAfter(newEnd)) {
      times.start = newStart.format('LT')
      if (oldEnd.isBefore(newStart))
        times.end = newEnd.isBefore(hourFromNewStart)
          ? newEnd.format('LT')
          : hourFromNewStart.format('LT')
    }
    if (newEnd.isBefore(oldEnd)) times.end = newEnd.format('LT')

    const dup = { ...values }
    dup.date = date
    dup.timeRange = times
    setValues(dup)
  }

  const submit = event => {
    event.preventDefault()
    setIsOpen(false)
    handleTimeChange(values)
  }

  const cancel = () => {
    resetVals()
    setIsOpen(false)
  }

  const clear = () => {
    resetVals()
    setIsOpen(false)
    onClear()
  }

  const form1 = () => (
    <FormContainer>
      <FormWrapper>
        <LabelStyled htmlFor="firstName">Start Time</LabelStyled>
        <TimeDropdownWithBusinessHoursStyled
          value={values.timeRange.start}
          timeFormat="h:mm A"
          minInterval={Constants.EXTERNAL_BOOKING_TIME_INTERVAL}
          date={values.date}
          onChange={time => handleTimePickerSelection('start', time)}
        />
      </FormWrapper>
      <FormWrapper>
        <LabelStyled htmlFor="firstName">End Time</LabelStyled>
        <TimeDropdownWithBusinessHoursStyled
          value={values.timeRange.end}
          timeFormat="h:mm A"
          date={values.date}
          minInterval={Constants.EXTERNAL_BOOKING_TIME_INTERVAL}
          onChange={time => handleTimePickerSelection('end', time)}
        />
      </FormWrapper>
      {error && <div className="text-danger">{error}</div>}
    </FormContainer>
  )

  const form2 = () => (
    <FormContainer>
      <FormWrapper>
        <LabelStyled htmlFor="firstName">Duration (hours)</LabelStyled>
        <InputRange
          maxValue={10}
          minValue={0.5}
          step={0.5}
          value={values.duration}
          onChange={updateDuration}
        />
      </FormWrapper>
      <FormWrapper>
        <LabelStyled htmlFor="startTime">After Time</LabelStyled>
        <TimeDropdownWithBusinessHoursStyled
          value={'7:00 am'}
          timeFormat="h:mm A"
          onChange={() => null}
        />
      </FormWrapper>
      <FormWrapper>
        <LabelStyled htmlFor="endTime">Before Time</LabelStyled>
        <TimeDropdownWithBusinessHoursStyled
          value={'7:00 am'}
          timeFormat="h:mm A"
          onChange={() => null}
        />
      </FormWrapper>
    </FormContainer>
  )

  const onCustomChanged = date => {
    updateDate(date)
  }

  const applyButton = mobile => (
    <ButtonStyled
      color={brandColor}
      onClick={submit}
      style={{ width: mobile ? '100%' : 'auto' }}
      disabled={Boolean(error)}
    >
      Apply
    </ButtonStyled>
  )

  const content = () => (
    <React.Fragment>
      {/* <SwitchContainer mobile={fullscreen}>
        <ButtonGroup>
          <StateButton
            onClick={() => changeView('time')}
            active={values.isTime}
          >
            Time
          </StateButton>
          <StateButton
            onClick={() => changeView('duration')}
            active={!values.isTime}
          >
            Duration
          </StateButton>
        </ButtonGroup>
        <InputText mobile={fullscreen}>{getInputText()}</InputText>
      </SwitchContainer> */}
      <ContentContainer mobile={fullscreen}>
        <LeftWrapper>
          <DayPickerBusinessHours
            focused
            date={values.date}
            onDateChange={onCustomChanged}
            initialVisibleMonth={() => defaultValue.date}
            isOutsideRange={day =>
              isInclusivelyBeforeDay(day, moment().subtract(1, 'day'))
            }
          />
        </LeftWrapper>
        {!fullscreen && <WrapperDivider />}
        <RightWrapper>
          <TimeContainer>{values.isTime ? form1() : form2()}</TimeContainer>
        </RightWrapper>
      </ContentContainer>
      <ButtonContainer mobile={fullscreen}>
        <LeftButtonText color={brandColor} onClick={clear}>
          Reset
        </LeftButtonText>
        <OutlineButtonStyled color="neutral-4" onClick={cancel}>
          Cancel
        </OutlineButtonStyled>
        {applyButton(fullscreen)}
      </ButtonContainer>
    </React.Fragment>
  )

  const windowView = () => (
    <Dropdown isOpen={isOpen} toggle={cancel}>
      <ButtonStyled
        onClick={toggle}
        color={brandColor}
        style={{
          position: 'relative',
        }}
      >
        {getLabelText()}
        <DropdownToggle
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            visibility: 'hidden',
          }}
        />
      </ButtonStyled>
      <Menu>{content()}</Menu>
    </Dropdown>
  )

  const fullscreenView = () => (
    <React.Fragment>
      <ButtonStyled color={brandColor} onClick={toggle}>
        {getLabelText()}
      </ButtonStyled>
      <GenericModalStyled
        mobile
        style={{ margin: '0px' }}
        header={
          <HeaderWrapper>
            <OverlayHeader>Date Options</OverlayHeader>
            <ButtonLinkStyled color={brandColor} onClick={clear}>
              clear
            </ButtonLinkStyled>
          </HeaderWrapper>
        }
        body={<div>{content()}</div>}
        footer={applyButton(fullscreen)}
        isOpen={isOpen}
        hideHeaderToggle
        onClose={cancel}
      />
    </React.Fragment>
  )

  return (
    <Container {...other}>
      {fullscreen ? fullscreenView() : windowView()}
    </Container>
  )
}

TimeFilter.propTypes = {
  handleTimeChange: PropTypes.func,
  fullscreen: PropTypes.bool,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onClear: PropTypes.func,
  values: PropTypes.object,
  defaultValue: PropTypes.object,
  theme: PropTypes.object,
  publicCampus: PropTypes.object,
  setFieldValue: PropTypes.func,
  resetForm: PropTypes.func,
  brandColor: PropTypes.string,
}

TimeFilter.displayName = 'Time Filter'

function mapStateToProps(state) {
  return {
    publicCampus: state.ui.publicCampus,
  }
}

export default connect(mapStateToProps, null)(TimeFilter)
