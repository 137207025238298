import React from 'react'
import PropTypes from 'prop-types'
import renderHTML from 'react-render-html'
import RoundedImage from '@global/RoundedImage'

const EmailMessagePreview = ({
  header,
  body,
  greeting,
  preferences,
  campusPhoto,
}) => {
  const prependGreeting = (body = '') => {
    const greetingText = `
      <div><p>${greeting},</p></div>
    `
    const text = greetingText + body
    return text
  }
  const bodyText = greeting !== null ? prependGreeting(body) : body
  const primaryColor =
    preferences.is_whitelabeled && preferences.branding_primary_color
      ? preferences.branding_primary_color
      : '#269796'
  const photo = campusPhoto
    ? campusPhoto
    : 'https://s3.amazonaws.com/coworksprod/assets/admin-dashboard-assets/frontdesk-tablet-background.jpg'
  return (
    <React.Fragment>
      <div
        className="email-preview-header"
        style={{ backgroundColor: primaryColor }}
      >
        <div className="row">
          <div className="col-md-12 d-flex flex-column justify-content-center align-items-center p-2 m-2">
            <RoundedImage photo={photo} alt="Picture" size="med-large" />
          </div>
          {header === '' || !header ? null : (
            <div className="col-md-12">
              <h2 className="email-preview-subject">{header}</h2>
            </div>
          )}
        </div>
      </div>
      <div className="card-block">{renderHTML(bodyText)}</div>
    </React.Fragment>
  )
}

EmailMessagePreview.propTypes = {
  body: PropTypes.string,
  greeting: PropTypes.srting,
  header: PropTypes.string,
  preferences: PropTypes.obj,
  campusPhoto: PropTypes.string,
}

EmailMessagePreview.displayName = 'EmailMessagePreview'

export default EmailMessagePreview
