/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
// eslint-disable-next-line no-unused-vars
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DayPickerRangeController } from 'react-dates'
import styled from 'styled-components'

const PresetButtonStyled = styled.button`
  position: relative;
  height: 100%;
  text-align: center;
  background: none;
  border: 2px solid
    ${props =>
      props.color === 'primary'
        ? props.theme.colorPrimary
        : props.theme.colorSecondary};
  color: ${props =>
    props.color === 'primary'
      ? props.theme.colorPrimary
      : props.theme.colorSecondary};
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer;

  &.active {
    outline: 0;
  }
`
const PresetsPanelStyled = styled.div`
  padding: 0 22px 11px 22px;
`

function DayPickerRangeControllerWrapper({
  onApplyDates,
  onDatesUpdated,
  ...other
}) {
  const [focusedInput, setfocusedInput] = useState('startDate')
  const [startDate, setStart] = useState(null)
  const [endDate, setEnd] = useState(null)

  const onDatesChange = ({ startDate, endDate }) => {
    setStart(startDate)
    setEnd(endDate)
    if (onDatesUpdated) onDatesUpdated(startDate, endDate)
  }
  const onFocusChange = focusedInput => {
    const focu = !focusedInput ? 'startDate' : focusedInput
    setfocusedInput(focu)
  }
  const renderAppleButton = () => {
    return endDate ? (
      <PresetsPanelStyled>
        <PresetButtonStyled
          color="primary"
          type="button"
          onClick={() => onApplyDates({ startDate, endDate })}
        >
          Apply
        </PresetButtonStyled>
      </PresetsPanelStyled>
    ) : null
  }
  return (
    <DayPickerRangeController
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      focusedInput={focusedInput}
      startDate={startDate}
      endDate={endDate}
      renderCalendarInfo={renderAppleButton}
      {...other}
    />
  )
}

DayPickerRangeControllerWrapper.propTypes = {
  onApplyDates: PropTypes.func,
  onDatesUpdated: PropTypes.func,
}

DayPickerRangeControllerWrapper.displayName = 'DayPickerRangeControllerWrapper'

export default DayPickerRangeControllerWrapper
