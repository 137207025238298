/* eslint-disable no-invalid-this */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import { Button, Row } from 'reactstrap'
import styled from 'styled-components'

import * as resourceActions from '../../reduxActions/resourceActions'
import * as attachmentActions from '../../reduxActions/attachmentActions'
import * as defaultResource1 from '../../img/resources/resources_blue.jpg'

import PinnedFields from './PinnedFields'
import { FormErrors } from '@global/FormErrorsComponent'
import { toast } from 'react-toastify'
import Spinner from '@global/Spinner'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { LabelStyled } from '@global/Form/FormComponents'
import SortableResourceImageForms from './SortableResourceImageForm'
import arrayMove from 'array-move'
import * as _ from 'lodash'

const SortableStyled = styled(SortableResourceImageForms)`
  width: 100%;
`

class ResourceDetailImageForm extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    reset: PropTypes.func,
    resource: PropTypes.object,
    resource_actions: PropTypes.object,
    archiveResource: PropTypes.func,
    renderCustomDropdowns: PropTypes.func,
    attachments: PropTypes.array,
    customTagState: PropTypes.array,
    attachment_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    isFetching: PropTypes.bool,
    goBack: PropTypes.function,
  }
  constructor(props, context) {
    super(props, context)
    let slides = []
    const slidesToDelete = []
    const slideMax = 5
    let showAddButton = true
    let resource = null
    let name = ''
    let description = null
    let isPinned = false
    let nameValid = false
    let descriptionsValid = false
    let slidePhotosValid = false
    let formValid = false
    let buttonText = 'Create Resource'
    let isEditing = false
    if (this.props.resource) {
      resource = this.props.resource
      name = resource.name
      nameValid = true
      descriptionsValid = true
      slidePhotosValid = true
      formValid = true
      buttonText = 'Update Resource'
      description = resource.description
      if (resource.is_pinned) {
        isPinned = true
      }
      isEditing = true
      if (resource.slides) {
        for (let counter = 0; counter < resource.slides.length; counter++) {
          slides.push({
            id: resource.slides[counter].id,
            order: counter,
            slide_photo: resource.slides[counter].slide_photo,
            description: resource.slides[counter].description,
            attachment_id: resource.slides[counter].slide_photo
              ? resource.slides[counter].slide_photo.id
              : null,
          })
        }
        //TODO are we gonna use the slide we just made to get the id?
        // slides = resource.slides;
        if (slides.length > slideMax) {
          showAddButton = false
        }
      } else {
        slides = [this.createEmptySlideObject()]
      }
    } else {
      slides = [this.createEmptySlideObject()]
    }
    this.state = {
      name,
      content: null,
      resource,
      slides,
      slidesToDelete,
      slideMax,
      showAddButton,
      description,
      isPinned,
      formErrors: { Name: '' },
      nameValid,
      descriptionsValid,
      slidePhotosValid,
      formValid,
      buttonText,
      isEditing,
    }
  }
  createEmptySlideObject() {
    return {
      slide_photo: { file_url: null, name: null },
      photo: '',
      description: null,
      attachment_id: null,
      new_id: new Date().getTime(),
    }
  }
  handleSlideDescriptionChange = (i, event) => {
    const value = event.target.value
    const slide = Object.assign({}, this.state.slides[i], {
      description: value,
    })
    const clonedSlides = JSON.parse(JSON.stringify(this.state.slides))
    clonedSlides[i] = slide
    this.setState({ slides: clonedSlides }, () => {
      this.validateField('description', value)
    })
  }
  handleImageUpload = (i, image) => {
    // This logic is to display the preview image to the user and will update the Ui
    const slide = Object.assign({}, this.state.slides[i], {
      slide_photo: { file_url: image },
    })
    const clonedSlides = JSON.parse(JSON.stringify(this.state.slides))
    clonedSlides[i] = slide
    this.setState({ slides: clonedSlides }, () => {
      this.validateField('slidePhoto', slide.slide_photo)
    })

    // This logic actually creates the attachments and then calls the correct action to create the attachments in the db
    if (this.state.resource && this.state.slides[i].id) {
      // check if slide was already existing
      if (this.props.resource.slides[i].slide_photo) {
        const attachmentId = this.props.resource.slides[i].slide_photo.id // get original photo id
        this.updateAnAttachment(i, attachmentId, slide)
      } else {
        this.createAnAttachment(i, slide)
      }
    } else {
      this.createAnAttachment(i, slide)
    }
    // this.setState({
    //   image: childState.image,
    // });
  }
  createAnAttachment = (intendedSlideIndex, slide) => {
    this.props.attachment_actions
      .createAttachment({
        photo: slide.slide_photo.file_url,
        description: 'resource slide',
      })
      .then(attachment => {
        const slidesCopy = Object.assign([], this.state.slides)
        slidesCopy[intendedSlideIndex].attachment_id = attachment.id
        this.setState({
          slides: slidesCopy,
        })
      })
      .catch(err => {
        toast.error(err.message)
        const slidesCopy = Object.assign([], this.state.slides)
        slidesCopy[intendedSlideIndex].attachment_id = -1 // this tells the api which slide actually failed.
        this.setState({
          slides: slidesCopy,
        })
      })
  }
  updateAnAttachment = (intendedSlideIndex, attachmentId, slide) => {
    this.props.attachment_actions
      .updateAttachment({
        id: attachmentId,
        photo: slide.slide_photo.file_url,
        description: 'resource slide',
      })
      .then(attachment => {
        const slidesCopy = Object.assign([], this.state.slides)
        slidesCopy[intendedSlideIndex].attachment_id = attachment.id
        this.setState({
          slides: slidesCopy,
        })
      })
      .catch(err => {
        toast.error(err.message)
        const slidesCopy = Object.assign([], this.state.slides)
        slidesCopy[intendedSlideIndex].attachment_id = -1 // this tells the api which slide actually failed.
        this.setState({
          slides: slidesCopy,
        })
      })
  }

  handlePinnedStatusChange = () => {
    this.setState({
      isPinned: !this.state.isPinned,
    })
  }
  removeSlideCard = index => {
    let slidesToDelete = []
    slidesToDelete = Object.assign([], this.state.slidesToDelete)
    const deletedSlide = this.state.slides[index]
    slidesToDelete.push(deletedSlide)

    let slidesCopy = []
    if (this.state.slides.length >= 0) {
      slidesCopy = Object.assign([], this.state.slides)
      slidesCopy.splice(index, 1)
    }
    this.setState(
      {
        slides: slidesCopy,
        slidesToDelete: slidesToDelete,
        showAddButton: true,
      },
      () => {
        this.validateField('description', {})
        this.validateField('slidePhoto', {})
      }
    )
  }
  /*
    Start of form validation -- don't forget state variables and component in render()
   */
  handleInputChange = changeEvent => {
    const name = changeEvent.target.name
    const value = changeEvent.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }
  validateField = (fieldName, value) => {
    const fieldValidationErrors = this.state.formErrors
    let nameValid = this.state.nameValid
    let descriptionsValid = this.state.descriptionsValid
    let slidePhotosValid = this.state.slidePhotosValid

    // alwaus make sure that the slide length is greater than 0.
    fieldValidationErrors.Slides =
      this.state.slides.length > 0 ? '' : ' are required'

    switch (fieldName) {
      case 'name':
        nameValid = value.length >= 1
        fieldValidationErrors.Name = nameValid ? '' : ' is a required field.'
        break
      case 'description':
        descriptionsValid = this.doAllSlidesHaveDescriptions()
        fieldValidationErrors.Descriptions = descriptionsValid
          ? ''
          : ' are required on all slides.'
        break
      case 'slidePhoto':
        slidePhotosValid = this.doAllSlidesHaveAttachments()
        fieldValidationErrors.Photos = slidePhotosValid
          ? ''
          : ' are required on all slides'
        break
      default:
        break
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        nameValid: nameValid,
        descriptionsValid: descriptionsValid,
        slidePhotosValid: slidePhotosValid,
      },
      this.validateForm
    )
  }
  validateForm = () => {
    this.setState({
      formValid:
        this.state.slides.length > 0 &&
        this.state.nameValid &&
        this.state.descriptionsValid &&
        this.state.slidePhotosValid,
    })
  }
  doAllSlidesHaveAttachments = () => {
    if (this.state.slides.length > 0) {
      const slidesCopy = Object.assign([], this.state.slides)
      for (let counter = 0; counter < slidesCopy.length; counter++) {
        if (!slidesCopy[counter].slide_photo.file_url) {
          return false
        }
      }
      return true // expects to return a true if we're good to go. anyNullSlides is true if there are any null slides which is the opposite.
    }
    return true
  }
  finalizeOrder = slides =>
    slides.map((slide, index) => {
      const clone = _.cloneDeep(slide)
      delete clone.new_id
      clone.order = index
      return clone
    })

  doAllSlidesHaveDescriptions = () => {
    // Checks to see if the state of the slides and whether there are any null descriptions (required by api).
    if (this.state.slides.length > 0) {
      for (let counter = 0; counter < this.state.slides.length; counter++) {
        if (!this.state.slides[counter].description) {
          return false
        }
      }
      return true
    }
    return true
  }
  /*
    End of form validation
  */
  createResource = () => {
    if (!this.doAllSlidesHaveAttachments()) {
      toast.error(
        'Please make sure all slides have images before creating resources!'
      )
      return
    }
    let customTagState = this.props.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    customTagState = JSON.stringify(customTagState)

    this.props.resource_actions
      .createResourceSlides({
        name: this.state.name,
        is_slide: true,
        description: this.state.description,
        is_pinned: this.state.isPinned,
        campus_id: this.props.activeCampus.id,
        type: 'Slides',
        slides: JSON.stringify(this.finalizeOrder(this.state.slides)),
        custom_tag_state: customTagState,
      })
      .then(() => {
        this.props.history.push('/resources', { isImage: null })
      })
      .catch(err => {
        if (err) {
          toast.error(err.message)
        }
      })
  }

  updateResource = () => {
    let customTagState = this.props.customTagState
    for (let counter = 0; counter < customTagState.length; counter++) {
      const obj = customTagState[counter]
      delete obj.tags
    }
    customTagState = JSON.stringify(customTagState)
    const obj = {
      id: this.props.resource.id,
      name: this.state.name,
      is_slide: true,
      description: this.state.description,
      is_pinned: this.state.isPinned,
      campus_id: this.props.activeCampus.id,
      slides: JSON.stringify(this.finalizeOrder(this.state.slides)),
      slides_to_delete: JSON.stringify(this.state.slidesToDelete),
      custom_tag_state: customTagState,
    }
    this.props.resource_actions
      .updateResource(obj)
      .then(() => {
        this.props.history.push('/resources', { isImage: null })
      })
      .catch(err => {
        if (err) {
          toast.error(err.message)
        }
      })
  }

  renderNewSlideCard = () => {
    if (!this.doAllSlidesHaveAttachments()) {
      toast.error('Please add a photo before adding another slide!')
      return
    }
    if (this.state.slides && this.state.slides.length < 5) {
      this.state.slides.push(this.createEmptySlideObject())
      this.setState(
        {
          showAddButton: true,
          slides: this.state.slides,
        },
        () => {
          this.validateField('description', {})
          this.validateField('slidePhoto', {})
        }
      )
    } else {
      this.setState(
        {
          showAddButton: false,
        },
        () => {
          this.validateField('description', {})
          this.validateField('slidePhoto', {})
        }
      )
    }
  }

  swap(input, index_A, index_B) {
    const thing = input
    const temp = thing[index_A]

    thing[index_A] = thing[index_B]
    thing[index_B] = temp

    return thing
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      slides: arrayMove(this.state.slides, oldIndex, newIndex),
    })
  }

  renderSlideCards = () => {
    return (
      <SortableStyled
        className="col-12 row"
        slides={this.state.slides}
        removeSlideCard={this.removeSlideCard}
        handleSlideDescriptionChange={this.handleSlideDescriptionChange}
        handleImageUpload={this.handleImageUpload}
        loading={this.props.isFetching}
      />
    )
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12 p-3">
          <div className="row mb-3">
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-12">
                  <LabelStyled>Resource Name</LabelStyled>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    className="w-100 form-control"
                    placeholder="Name"
                    onChange={this.handleInputChange}
                    value={this.state.name}
                  />
                </div>
                <div className="col-6">
                  {PinnedFields({
                    isPinned: this.state.isPinned,
                    handlePinnedStatusChange: () =>
                      this.handlePinnedStatusChange(),
                  })}
                </div>

                <div className="col-12 col-md-4">
                  {this.props.renderCustomDropdowns()}
                </div>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <h5 className="mr-3">
                Slides: {this.state.slides ? this.state.slides.length : 0}/
                {this.state.slideMax}{' '}
              </h5>
              <h6 className="text-muted mb-4">Drag slides to change order</h6>
            </div>
            <SortableStyled
              className="col-12"
              slides={this.state.slides}
              removeSlideCard={this.removeSlideCard}
              handleSlideDescriptionChange={this.handleSlideDescriptionChange}
              handleImageUpload={this.handleImageUpload}
              loading={this.props.isFetching}
              onSortEnd={this.onSortEnd}
            >
              {this.state.showAddButton && (
                <li
                  className="d-flex align-items-stretch"
                  onClick={this.renderNewSlideCard}
                >
                  <Button
                    className="btn btn-default w-100 mx-auto text-faded text-primary py-4"
                    disabled={
                      this.state.slides.length === 5 || this.props.isFetching
                    }
                  >
                    <FontAwesomeIcon icon="plus-circle" size="3x" />
                    <br />
                    <span className="my-3">
                      {this.state.slides.length === 5
                        ? 'Max of 5 slides allowed'
                        : 'Add More Photos'}
                    </span>
                  </Button>
                </li>
              )}
            </SortableStyled>
          </div>
          <div className="row my-1">
            {this.state.isEditing && (
              <div className="col-4  d-md-down-none">
                <Button
                  className="btn btn-danger"
                  onClick={() =>
                    this.props.archiveResource(this.state.resource)
                  }
                >
                  Archive
                </Button>
              </div>
            )}
            <div className={'d-flex justify-content-end col'}>
              <Button className="btn btn-secondary" onClick={this.props.goBack}>
                Cancel
              </Button>
              <Button
                className="btn btn-primary ml-3"
                onClick={
                  this.props.resource
                    ? this.updateResource
                    : this.createResource
                }
                disabled={!this.state.formValid || this.props.isFetching}
              >
                {this.state.buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ResourceDetailImageForm.displayName = 'ResourceDetailImageForm'
const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    attachments: state.attachments,
    isFetching: state.ui.isFetching,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resource_actions: bindActionCreators(resourceActions, dispatch),
    attachment_actions: bindActionCreators(attachmentActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceDetailImageForm)
