import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import withPublicCommunityContext from './withPublicCommunityContext'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import Header from './ExternalBooking/Header'
import { Switch, Route } from 'react-router-dom'
import PublicDayPass from './Signup/DayPasses/PublicDayPass'
import PlansSignup from './Signup/Plans/PlansSignup'
import FeatureToggle from '@app/hocs/FeatureToggle'
import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'
import * as _ from 'lodash'

const headerPaths = ['/day-pass-signup', '/day-pass-signup/:campusId']

const DayPassFeature = FeatureToggle('day_pass')(PublicDayPass)

const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
`
const HeaderStyled = styled(Header)`
  position: absolute;
  z-index: 1;
  width: 100%;
`
const campusSpecificPath = path => {
  const url = path
  for (let i = 0; i < headerPaths.length; i++) {
    const thing = headerPaths[i]
    if (thing.includes(url) || thing === url) {
      return true
    }
  }
  return false
}
const PublicTemplate = props => {
  const {
    theme,
    match,
    history,
    publicCommunity,
    publicCampus,
    coworks_community_actions,
  } = props
  const isCampusSpecific = campusSpecificPath(match.path)
  const compMod = isCampusSpecific && publicCampus ? publicCampus.id : 0

  // TODO: update App.js paths to have campusId come first
  useEffect(() => {
    let campus = null
    if (publicCommunity) {
      if (match.params.campusId) {
        const campusId = Number(match.params.campusId)
        campus = _.find(publicCommunity.campuses, ['id', campusId])
        coworks_community_actions.setPublicCampus(campus)
      } else if (isCampusSpecific) {
        campus = publicCommunity.campuses[0]
        history.push(`${match.url}/${campus.id}`)
      }
    }
  }, [
    publicCommunity,
    coworks_community_actions,
    match.params.campusId,
    history,
  ])

  const getHeader = () => {
    const url = match.path.split('/:campusId')[0].substring(1)
    if (isCampusSpecific && publicCampus) {
      return (
        <HeaderStyled
          history={history}
          campus={publicCampus}
          community={publicCommunity}
          url={url}
        />
      )
    }
    return null
  }

  return (
    <Container key={`strict_campus-${compMod}`}>
      {getHeader()}
      <Switch>
        <Route
          exact
          path="/membership-signup/:planId"
          render={props => (
            <PlansSignup
              theme={theme}
              publicCampus={publicCampus}
              publicCommunity={publicCommunity}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/day-pass-signup/:campusId"
          render={props => (
            <DayPassFeature
              theme={theme}
              publicCampus={publicCampus}
              publicCommunity={publicCommunity}
              {...props}
            />
          )}
        />
      </Switch>
    </Container>
  )
}

PublicTemplate.displayName = 'PublicTemplate'

PublicTemplate.propTypes = {
  theme: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  publicCampus: PropTypes.object,
  publicCommunity: PropTypes.object,
  coworks_community_actions: PropTypes.object,
}

const mapStateToProps = state => ({
  publicCampus: state.ui.publicCampus,
})

const mapDispatchToProps = dispatch => ({
  coworks_community_actions: bindActionCreators(
    coworksCommunityActions,
    dispatch
  ),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPublicCommunityContext()
)(PublicTemplate)
