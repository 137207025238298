/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField, Form, Field, Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Row } from 'reactstrap'
import * as Constants from '@global/Constants'
import moment from 'moment'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import {
  CustomInputComponentWithHooks,
  CustomDatePickerComponent,
  LabelStyled,
  PhoneInputField,
} from '@global/Form/FormComponents'
import ButtonStyled from '@global/Base/Button/ButtonStyled'
import CreditCardForm from '@global/Stripe/CreditCardForm'
import useCountryCodeLookup from '@app/customHooks/useCountryCodeLookup'
import Checkbox from '@bit/coworks.base-components.checkbox'
import { isInclusivelyAfterDay } from 'react-dates'
import { captureException } from '@global/ErrorFactory'
import CheckoutDetails from '../ExternalBooking/Modal/BookingPaymentModal/CheckoutDetails'
import { breakpoints } from '@global/Constants/BreakpointConstants'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  position: relative;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
  }
`
const TextRed = styled.div`
  color: red;
`
const InputContainer = styled.div`
  flex: 1;
  padding: 12px 24px;
`
const CostContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  background: #eee;
  min-width: 275px;
  width: 35%;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`
const Title = styled.h5`
  display: flex;
  padding: 12px 24px 0;
  margin: 0;
  font-weight: bold;
`

function renderError(message) {
  return <TextRed className="my-2 p-2">{message}</TextRed>
}
const PublicSignupForm = ({
  primaryColor,
  onSubmit,
  billingEnabled,
  extraInitialValues = {},
  extraValidation = {},
  purchaseCondition,
  termsDescription,
  error: submissionError,
  hasSubmitted,
  totalData = [],
  detailData = [],
}) => {
  // Get a reference to Stripe or Elements using hooks.
  const stripe = useStripe()
  const elements = useElements()
  const [defaultCountry] = useCountryCodeLookup('US')

  const newInitial = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    policy: false,
  }

  const newSchema = {
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phone: Yup.string().required('Phone Number is required'),
    email: Yup.string()
      .email('This is not a valid email address')
      .required('Email is required'),
    policy: Yup.bool().oneOf(
      [true],
      'You must accept the terms before continuing.'
    ),
  }

  return (
    <Formik
      initialValues={Object.assign(newInitial, extraInitialValues)}
      validationSchema={Yup.object().shape(
        Object.assign(newSchema, extraValidation)
      )}
      onSubmit={(values, actions) => {
        if (billingEnabled) {
          const cardElement = elements.getElement(CardElement)
          stripe
            .createToken(cardElement)
            .then(({ token, error }) => {
              if (token) {
                // eslint-disable-next-line no-param-reassign
                values.card_token = token.id
                onSubmit(values, actions.setSubmitting)
              }
              if (error) {
                actions.setFieldError(
                  'creditCard',
                  error.message
                    ? error.message
                    : 'There was a problem with your card.'
                )
                actions.setSubmitting(false)
              }
            })
            .catch(err => {
              actions.setFieldError(
                'creditCard',
                'There was a problem with your card.'
              )
              actions.setSubmitting(false)
              captureException({
                text: `Failed to charge a card in public signup form`,
                error: err,
              })
            })
        } else {
          onSubmit(values, actions.setSubmitting)
        }
      }}
    >
      {({
        isSubmitting,
        setFieldValue,
        setFieldError,
        values,
        errors,
        touched,
      }) => {
        return !hasSubmitted ||
          Object.keys(errors).length > 0 ||
          submissionError ? (
          <Form>
            <Container>
              <InputContainer>
                <Form className="w-100">
                  <CustomInputComponentWithHooks
                    name="firstName"
                    label="First Name"
                    primaryColor={primaryColor}
                  />
                  <CustomInputComponentWithHooks
                    name="lastName"
                    label="Last Name"
                    primaryColor={primaryColor}
                  />
                  <CustomInputComponentWithHooks
                    name="email"
                    type="email"
                    label="Email"
                    primaryColor={primaryColor}
                  />
                  <Field
                    label="Phone"
                    isEditing
                    name="phone"
                    defaultCountry={defaultCountry}
                    primaryColor={primaryColor}
                    className={'w-100'}
                    component={PhoneInputField}
                  />
                  {extraInitialValues.teamName !== undefined && (
                    <CustomInputComponentWithHooks
                      name="teamName"
                      label="Team Name"
                    />
                  )}
                  {extraInitialValues.date !== undefined && (
                    <Field
                      label="Date of Usage"
                      name="date"
                      id="date"
                      placeholder={'Select'}
                      isOutsideRange={day =>
                        !isInclusivelyAfterDay(day, moment().add(0, 'day'))
                      }
                      component={CustomDatePickerComponent}
                    />
                  )}
                  {billingEnabled && (
                    <div className="mb-3">
                      <LabelStyled>Credit Card</LabelStyled>
                      <CreditCardForm
                        name={'creditCard'}
                        onChange={() => setFieldError('creditCard', null)}
                        stripe={stripe}
                        elements={elements}
                        primaryColor={primaryColor}
                      />
                      <ErrorMessage name="creditCard">
                        {msg => <TextRed className="my-2 p-2">{msg}</TextRed>}
                      </ErrorMessage>
                    </div>
                  )}
                  <Checkbox
                    color={primaryColor}
                    selected={values.policy}
                    handleChange={() => setFieldValue('policy', !values.policy)}
                    label="I Agree"
                    name={'policy'}
                    error={errors.policy && touched.policy}
                    description={termsDescription}
                  />

                  {submissionError && (
                    <TextRed className="my-2 p-2">{submissionError}</TextRed>
                  )}
                  {billingEnabled && (
                    <Row>
                      <div className="col-12 my-2 ">{purchaseCondition}</div>
                    </Row>
                  )}
                </Form>
              </InputContainer>
              <CostContainer>
                <Title style={{ marginBottom: '12px' }}>Order Summary</Title>
                <CheckoutDetails
                  totalData={totalData}
                  detailData={detailData}
                />
                <div className="d-flex align-i. ems-center justify-content-end mb-2">
                  <ButtonStyled
                    type="submit"
                    className="btn-block"
                    style={{
                      margin: '12px 24px',
                    }}
                    backgroundColor={primaryColor}
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <React.Fragment>
                        <FontAwesomeIcon icon={'circle-notch'} spin />{' '}
                      </React.Fragment>
                    )}
                    {`Purchase for ${totalData[totalData.length - 1].value}`}
                  </ButtonStyled>
                </div>
              </CostContainer>
            </Container>
          </Form>
        ) : null
      }}
    </Formik>
  )
}

PublicSignupForm.propTypes = {
  communityName: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired,
  primaryColor: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  billingEnabled: PropTypes.bool,
  defaultBillingCycleDay: PropTypes.string,
  error: PropTypes.string,
  purchaseCondition: PropTypes.string,
  termsDescription: PropTypes.string,
  extraInitialValues: PropTypes.object,
  extraValidation: PropTypes.object,
  hasSubmitted: PropTypes.bool,
  totalData: PropTypes.array,
  detailData: PropTypes.array,
}

PublicSignupForm.defaultProps = {
  billingEnabled: false,
}

export default PublicSignupForm
