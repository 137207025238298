import {
  REGISTER_COMMUNITY,
  SET_USER,
  UPDATE_PASSWORD,
  UPDATE_USER,
} from '../reduxActions/actionTypes'

export default function (user = {}, action) {
  switch (action.type) {
    case REGISTER_COMMUNITY:
      return [action.manager]
    case SET_USER:
      return action.user
    case UPDATE_PASSWORD:
      return action.user
    case UPDATE_USER:
      const oldUser = user
      const newUser = {}
      for (const key in oldUser) {
        if (typeof action.user === 'object') {
          newUser[key] = oldUser[key]
          for (const key2 in action.user) {
            // eslint-disable-next-line max-depth
            if (typeof action.user === 'object') {
              // eslint-disable-next-line max-depth
              if (action.user[key2] && action.user[key] !== '') {
                newUser[key2] = action.user[key2]
              }
            }
          }
        }
      }
      //console.log('New user', newUser);
      return newUser
    default:
      return user
  }
}
