import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImageUploadComponent from '@global/UploadImage/ImageUploadComponent'
import { Input, Button, Row } from 'reactstrap'
import DateTime from 'react-datetime'
import defaultAvatar from '@app/img/placeholders/otto_black_white.jpeg'
import { LabelStyled } from '@global/Form/FormComponents'
import RoundedImage from '@global/RoundedImage'
import Spinner from '@global/Spinner'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import {
  CustomInputComponent,
  CustomTextAreaComponent,
  CustomDatePickerComponent,
} from '@global/Form/FormComponents'

import {
  renderDynamicGroups,
  renderValues,
  renderCreatableDropdownGroup,
} from '@global/DynamicGroups/DynamicGroupFunctions'

/* eslint-disable max-len */
export default function CompanyProfileForm({
  description,
  errors,
  goBack,
  handleImageUpload,
  handleInputChange,
  name,
  email,
  website,
  photo,
  isInactive,
  isStaff,
  updateTeam,
  deactivateTeam,
  activateTeam,
  editCompany,
  toggleChangeCampusModal,
  joinedAt,
  isEditing,
  isLoading,
  canEdit,
  handleJoinedAtChanged,
  conferenceHoursAllowance,
  activeCommunity,
  teamIndustries,
  teamIndustryOptions,
  dynamicTags,
}) {
  const [joinedAtFocused, setJoinedAtFocused] = useState(false)

  // eslint-disable-next-line react/no-multi-comp,react/display-name
  const renderProfilePicture = () => (
    <RoundedImage
      photo={photo ? photo : defaultAvatar}
      alt="Profile Picture"
      size="large"
      className="img-responsive"
    />
  )

  const showAsEditing = isEditing && canEdit
  return (
    <div className="animated fadeIn">
      <div id="new-member-form">
        <Row>
          <div className="col-12">
            <Formik
              initialValues={{
                name,
                conferenceHoursAllowance,
                description,
                joinedAt,
                joinedAtFocused: false,
                email,
                website,
                teamIndustries,
                teamIndustryOptions,
                dynamicTags,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Name is required.'),
                joinedAt: Yup.object()
                  .nullable()
                  .required('Joined date is required.'),
                website: Yup.string()
                  .nullable()
                  .url('This must be in the format https://domain.com'),
                description: Yup.string().nullable(),
                email: Yup.string()
                  .nullable()
                  .email('This must be a valid email address'),
                conferenceHoursAllowance: Yup.number()
                  .typeError('This must be a number')
                  .nullable(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                updateTeam(values)
                setSubmitting(true)
              }}
            >
              {myProps => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  setFieldTouched,
                } = myProps
                return (
                  <form onSubmit={handleSubmit}>
                    <fieldset disabled={Boolean(isInactive)}>
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-3">
                                <Row>
                                  <div
                                    className={`col-12 ${
                                      !showAsEditing
                                        ? 'd-flex justify-content-center align-items-center'
                                        : ''
                                    }`}
                                  >
                                    {showAsEditing ? (
                                      <ImageUploadComponent
                                        imageUrl={photo}
                                        onUpload={newPhoto =>
                                          setFieldValue('photo', newPhoto)
                                        }
                                      />
                                    ) : (
                                      renderProfilePicture()
                                    )}
                                  </div>
                                  <div className="col-12 d-flex justify-content-center align-items-center my-4">
                                    {activeCommunity.campuses.length > 1 && (
                                      <TextButton
                                        disabled={Boolean(
                                          isInactive || isEditing
                                        )}
                                        onClick={toggleChangeCampusModal}
                                      >
                                        Change Campus
                                      </TextButton>
                                    )}
                                  </div>
                                </Row>
                              </div>
                              <div className="col-9">
                                <div className="row">
                                  <div className="col-md-6">
                                    <h3>Profile</h3>
                                  </div>
                                  {canEdit && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                      {isEditing ? (
                                        <div className="text-muted">
                                          Editing
                                        </div>
                                      ) : (
                                        <Button
                                          className="d-flex align-items-center"
                                          color="outline-primary"
                                          disabled={Boolean(isInactive)}
                                          onClick={editCompany}
                                        >
                                          Edit
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <Field
                                      isEditing={showAsEditing}
                                      renderViewState={!showAsEditing}
                                      label="Team Name"
                                      name="name"
                                      id="name"
                                      placeholder="Team name"
                                      component={CustomInputComponent}
                                    />
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <Field
                                      isEditing={showAsEditing}
                                      renderViewState={!showAsEditing}
                                      label="Join Date"
                                      name="joinedAt"
                                      id="joinedAt"
                                      format={'MMM DD, YYYY'}
                                      placeholder={`Join Date`}
                                      component={CustomDatePickerComponent}
                                    />
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-12">
                                    <Field
                                      isEditing={showAsEditing}
                                      renderViewState={!showAsEditing}
                                      label="Description"
                                      name="description"
                                      id="description"
                                      rows="4"
                                      placeholder={`Tell your space about ${name}`}
                                      component={CustomTextAreaComponent}
                                    />
                                  </div>
                                </div>

                                <Row className="my-3">
                                  {renderCreatableDropdownGroup({
                                    name: 'Team Industries',
                                    groupName: 'teamIndustries',
                                    placeholder:
                                      'Select or type to create new team industries...',
                                    values: values.teamIndustries,
                                    options: teamIndustryOptions,
                                    handleChange: setFieldValue,
                                    useBadgesForViewState: true,
                                    isEditing,
                                  })}
                                </Row>

                                <Row className="my-3">
                                  <div className="col-6">
                                    <Field
                                      isEditing={showAsEditing}
                                      renderViewState={!showAsEditing}
                                      label="Team website"
                                      name="website"
                                      viewType="externalURL"
                                      id="website"
                                      placeholder={`https://www.${
                                        name
                                          ? name
                                              .toLowerCase()
                                              .replace(/\s/g, '')
                                              .replace(/,/g, '')
                                          : 'example'
                                      }.com`}
                                      component={CustomInputComponent}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Field
                                      isEditing={showAsEditing}
                                      renderViewState={!showAsEditing}
                                      label="Team email"
                                      name="email"
                                      id="email"
                                      placeholder={`hello@${
                                        name
                                          ? name
                                              .toLowerCase()
                                              .replace(/\s/g, '')
                                              .replace(/,/g, '')
                                          : 'example'
                                      }.com`}
                                      component={CustomInputComponent}
                                    />
                                  </div>
                                </Row>
                                <div className="row my-3">
                                  <div className="col-6">
                                    <Field
                                      isEditing={showAsEditing}
                                      renderViewState={!showAsEditing}
                                      label="Conference Hours Allowance"
                                      name="conferenceHoursAllowance"
                                      component={CustomInputComponent}
                                    />
                                  </div>
                                </div>

                                {renderDynamicGroups({
                                  allGroups: activeCommunity.dynamic_groups,
                                  handleChange: newValues => {
                                    setFieldValue('dynamicTags', newValues)
                                  },
                                  existingTagValues: values.dynamicTags,
                                  groupType: 'Team',
                                  isEditing: isEditing,
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </fieldset>
                    <div className="row my-3 d-flex">
                      {!isStaff && (
                        <div className="col-2 d-flex justify-content-center mr-3">
                          <Button
                            disabled={Boolean(showAsEditing || isLoading)}
                            className={`btn btn-${
                              isInactive ? 'success' : 'danger'
                            }`}
                            onClick={isInactive ? activateTeam : deactivateTeam}
                          >
                            {isInactive ? 'Activate' : 'Deactivate'}
                          </Button>
                        </div>
                      )}
                      {showAsEditing && (
                        <React.Fragment>
                          <div className="col d-flex justify-content-end">
                            <ButtonStyled
                              className="mr-4"
                              color="white"
                              disabled={Boolean(isInactive || isLoading)}
                              onClick={() => {
                                handleReset()
                                editCompany()
                              }}
                            >
                              Cancel
                            </ButtonStyled>
                            <ButtonStyled
                              type="submit"
                              disabled={Boolean(isInactive || isLoading)}
                            >
                              Update
                            </ButtonStyled>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </form>
                )
              }}
            </Formik>
          </div>
        </Row>
      </div>
    </div>
  )
}
/* eslint-enable max-len */

CompanyProfileForm.displayName = 'CompanyProfileForm'
CompanyProfileForm.propTypes = {
  description: PropTypes.string,
  errors: PropTypes.array,
  goBack: PropTypes.func.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string,
  photo: PropTypes.string,
  isInactive: PropTypes.bool,
  isStaff: PropTypes.bool,
  updateTeam: PropTypes.func.isRequired,
  deactivateTeam: PropTypes.func.isRequired,
  activateTeam: PropTypes.func.isRequired,
  editCompany: PropTypes.func.isRequired,
  toggleChangeCampusModal: PropTypes.func,
  joinedAt: PropTypes.object,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  canEdit: PropTypes.bool,
  handleJoinedAtChanged: PropTypes.func,
  conferenceHoursAllowance: PropTypes.number,
  activeCommunity: PropTypes.object,
  teamIndustries: PropTypes.object,
  teamIndustryOptions: PropTypes.object,
  dynamicTags: PropTypes.object,
}
