import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/react-toastify-overrides.css'
import 'react-input-range/lib/css/index.css'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { STRIPE_API_KEY } from './config/axios'
const stripePromise = loadStripe(STRIPE_API_KEY)

import ScrollHandler from './hocs/ScrollHandler'

import { mixpanel } from './config/initializers'

import { Admin, Manager, Member } from './config/roles'

import SmartLogger from './hocs/SmartLogger'
import ErrorBoundary from '@global/ErrorBoundry'

// Containers
import MainContainer from '@components/MainContainer'
import OnboardingContainer from '@components/Onboarding/OnboardingContainer' // eslint-disable-line
//import Banner from '@components/Global/Banner';

// Views
import Login from '@components/Login/DashboardLoginWrapper'
import SignUp from '@components/SignUp/DashboardSignUpWrapper'
import ForgotPass from '@components/ForgotPass/ForgotPassword'
import ResetPass from '@components/ForgotPass/ResetPassword'
import ChangeCommunity from '@components/Community/Campuses/ChangeCommunity'

import ChangeTabletType from '@components/Tablet/ChangeTabletType'

// Conferece Tablet
import BookingTablet from '@components/Tablet/Conference/BookingTablet'
import TabletLogin from '@components/Login/TabletLoginWrapper'
import BookingRoomsPage from '@components/Tablet/Conference/BookingRoomsPage'

// Front Desk
import FrontDesk from '@components/Tablet/FrontDesk/FrontDesk'
// Events feed
import EventFeed from '@components/Tablet/EventFeed/EventFeed'

// External Booking
import ExternalBookings from '@components/External/ExternalBooking/ExternalBookings'

// External Forms
import MembershipRequest from '@components/External/MembershipRequest/MembershipRequest'
import TourRequest from '@components/External/Tours/TourRequest'
import ExternalEventPage from '@components/External/Events/ExternalEventPage'
import PlansList from '@components/External/Signup/Plans/PlansList'
import PlansSignup from '@components/External/Signup/Plans/PlansSignup'
import PublicDayPass from '@components/External/Signup/DayPasses/PublicDayPass'
import PublicPageTemplate from '@components/External/PublicPageTemplate'

import CommandCenter from '@components/Tablet/Conference/CommandCenter'

import UserPreferences from '@components/External/Preferences/UserPreferences'

import Bookings from '@components/External/Bookings/Bookings'

try {
  // eslint-disable-next-line no-undef
  console.log(`App version: ${BUILD_VERSION} []`)
} catch (error) {
  console.error("Couldn't print the app version", error)
}

const AppRoutes = () => (
  <Router>
    <ErrorBoundary>
      <ScrollHandler />
      <Elements stripe={stripePromise}>
        <Switch>
          <Route exact path="/login" component={SmartLogger(mixpanel)(Login)} />
          <Route path="/signup" component={SmartLogger(mixpanel)(SignUp)} />

          <Route
            path="/change-campus"
            component={withTheme(SmartLogger(mixpanel)(ChangeCommunity))}
          />

          <Route
            path="/tablet-type"
            component={Manager(
              withTheme(SmartLogger(mixpanel)(ChangeTabletType))
            )}
          />

          <Route
            exact
            path="/forgot_password/"
            component={SmartLogger(mixpanel)(ForgotPass)}
          />
          <Route
            path="/reset_password/:token"
            exact
            component={SmartLogger(mixpanel)(ResetPass)}
          />
          <Route
            exact
            path="/tablet-login"
            component={SmartLogger(mixpanel)(TabletLogin)}
          />
          <Route
            exact
            path="/conference-rooms"
            component={Manager(SmartLogger(mixpanel)(BookingRoomsPage))}
          />
          <Route
            path="/booking-tablet/:tabletKey"
            component={Manager(SmartLogger(mixpanel)(BookingTablet))}
          />
          <Route
            exact
            path="/command-center"
            component={Manager(SmartLogger(mixpanel)(CommandCenter))}
          />
          <Route
            exact
            path="/events"
            component={Manager(SmartLogger(mixpanel)(EventFeed))}
          />
          <Route
            path="/bookings/:campusId/:roomId"
            component={withTheme(SmartLogger(mixpanel)(Bookings))}
          />
          <Route
            path="/bookings/:campusId"
            component={withTheme(SmartLogger(mixpanel)(Bookings))}
          />
          <Route
            path="/bookings"
            component={withTheme(SmartLogger(mixpanel)(Bookings))}
          />
          <Route
            path="/front-desk"
            component={Manager(SmartLogger(mixpanel)(FrontDesk))}
          />
          <Route
            path="/book/:campusId/:roomId"
            component={withTheme(SmartLogger(mixpanel)(ExternalBookings))}
          />
          <Route
            path="/book/:campusId"
            component={withTheme(SmartLogger(mixpanel)(ExternalBookings))}
          />
          <Route
            path="/book"
            component={withTheme(SmartLogger(mixpanel)(ExternalBookings))}
          />
          <Route
            name="Onboarding Container"
            exact
            path="/build-your-community"
            component={SmartLogger(mixpanel)(OnboardingContainer)}
          />
          <Route
            exact
            path="/membership-request"
            component={SmartLogger(mixpanel)(MembershipRequest)}
          />
          <Route
            exact
            path="/membership-plans"
            component={SmartLogger(mixpanel)(PlansList)}
          />
          <Route
            path="/membership-signup/:planId"
            component={withTheme(SmartLogger(mixpanel)(PublicPageTemplate))}
          />
          <Route
            exact
            path="/day-pass-signup/:campusId"
            component={withTheme(SmartLogger(mixpanel)(PublicPageTemplate))}
          />
          <Route
            exact
            path="/day-pass-signup"
            component={withTheme(SmartLogger(mixpanel)(PublicPageTemplate))}
          />
          <Route
            exact
            path="/tour-request"
            component={SmartLogger(mixpanel)(TourRequest)}
          />
          <Route
            exact
            path="/events/:eventId"
            component={SmartLogger(mixpanel)(ExternalEventPage)}
          />
          <Route
            exact
            path="/preferences/:tabId/:email/:communityId/:client/:token"
            component={SmartLogger(mixpanel)(UserPreferences)}
          />
          <Route
            exact
            path="/preferences/:tabId/:email/:communityId/:client/:token"
            component={SmartLogger(mixpanel)(UserPreferences)}
          />
          <Route
            path="/"
            component={withTheme(SmartLogger(mixpanel)(MainContainer))}
          />
        </Switch>
      </Elements>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </ErrorBoundary>
  </Router>
)
AppRoutes.displayName = 'AppRoutes'
export default AppRoutes
