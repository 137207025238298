import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import CampusRow from './CampusRow'
import '@components/Messages/announcementForm.scss'

class CampusSelectorModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
    switchCampus: PropTypes.func,
    campuses: PropTypes.array,
    currentCampus: PropTypes.object,
    disableRows: PropTypes.bool,
  }

  renderCampuses = () => {
    const { currentCampus, campuses, disableRows, switchCampus } = this.props
    return campuses.map(object => {
      if (object.id !== currentCampus.id) {
        return (
          <div className="container" key={object.id}>
            <CampusRow
              key={object.id}
              campus={object}
              isInactive={disableRows}
              campusClick={() => switchCampus(object)}
            />
          </div>
        )
      }

      return null
    })
  }

  render() {
    const { currentCampus } = this.props
    return (
      <Modal isOpen={this.props.showModal} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal}>
          Select New Campus for Team
        </ModalHeader>
        <ModalBody>
          <div className="card p-4">
            <div className="member-list-wrapper">{this.renderCampuses()}</div>
          </div>
          <div className="d-flex">
            <Button
              className="btn btn-secondary ml-auto"
              onClick={this.props.toggleModal}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

CampusSelectorModal.displayName = 'Campus Selector Modal'

export default CampusSelectorModal
