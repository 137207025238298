/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as _ from 'lodash'
import { withFormik, Form } from 'formik'
import {
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Button as OldOutlineButton,
} from 'reactstrap'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  default as OldButton,
  TextButton as OldTextButton,
} from '@global/Base/Button/ButtonStyled'

import RadioGroup from '@global/LocalRadioGroup'

import { LabelStyled } from '@global/Form/FormComponents'
import SelectDropdown from '@components/Leads/LeadsTable/FiltersCollection/SelectDropdown'
import GenericModal from '@global/GenericModal'
import IncrementButton from './IncrementButton'
import OptionSelect from './OptionSelect'

import {
  default as NewButton,
  IconButton,
  OutlineButton as NewOutlineButton,
  TextButton as NewTextButton,
} from '@bit/coworks.base-components.button'
import { defaultTheme } from 'react-select'

const radios = currencySymbol => [
  {
    label: `Less than ${currencySymbol}25`,
    value: JSON.stringify({ min: null, max: 24 }),
  },
  {
    label: `${currencySymbol}25 to ${currencySymbol}50`,
    value: '{ min: 25, max: 50 }',
  },
  {
    label: `${currencySymbol}50 to ${currencySymbol}100`,
    value: '{ min: 50, max: 100 }',
  },
  {
    label: `${currencySymbol}100 to ${currencySymbol}300`,
    value: '{ min: 100, max: 300 }',
  },
  {
    label: `Greater than ${currencySymbol}300`,
    value: '{ min: 301, max: null }',
  },
]

const Menu = styled(DropdownMenu)`
  padding: 18px !important;
`
const Container = styled.div``
const GenericModalStyled = styled(GenericModal)`
  height: 100vh;
  max-width: none;

  .modal-content {
    border: none !important;
    width: 100%;
  }
`
const ContentContainer = styled.div`
  width: 100%;
`
const SwitchWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;

  .text {
    margin-left: 8px;
  }
`
const Wrapper = styled.div`
  width: 500px;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`
const TimeContainer = styled.div``
const ButtonContainer = styled.div`
  display: ${props => (props.mobile ? 'none' : 'flex')};
  justify-content: flex-end;
  margin-top: 10px;
  button {
    margin-left: 10px;
  }
`
const OverlayHeader = styled.h5`
  margin: 0;
  font-weight: bold;
`
const IncrementButtonStyled = styled(IncrementButton)`
  width: 160px;
`
const OptionSelectStyled = styled(OptionSelect)`
  width: 160px;
`
const FormWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 0 18px;

  .input-range {
    margin: 12px 0;
  }

  ${props => {
    if (props.mobile) {
      return css`
        @media (min-width: 769px) {
          display: none !important;
        }
      `
    }
    return null
  }}
`
const FormContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
`
const SelectDropdownStyled = styled(SelectDropdown)``

const sortOptions = [
  {
    label: 'Vacant',
    value: false,
  },
  {
    label: 'Occupied',
    value: true,
  },
]

const AttributeFilter = ({
  fullscreen,
  filters = [],
  defaultValue = {},
  handleAttChange,
  brandColor,
  open = false,
  onClear,
  currencySymbol,
  radioOptions,
  external,
  ...other
}) => {
  const [isOpen, setToggle] = useState(open)
  const [values, setValues] = useState(defaultValue)

  /* eslint-disable react/prop-types */
  const ButtonComp = ({ children, ...other }) => {
    return external ? (
      <NewButton {...other}>{children}</NewButton>
    ) : (
      <OldButton {...other}>{children}</OldButton>
    )
  }

  /* eslint-disable react/prop-types */
  const TextButtonComp = ({ children, ...other }) => {
    return external ? (
      <NewTextButton {...other}>{children}</NewTextButton>
    ) : (
      <OldTextButton {...other}>{children}</OldTextButton>
    )
  }

  /* eslint-disable react/prop-types */
  const OutlineButtonComp = ({ children, ...other }) => {
    return external ? (
      <NewOutlineButton {...other}>{children}</NewOutlineButton>
    ) : (
      <OldOutlineButton {...other}>{children}</OldOutlineButton>
    )
  }

  const setFieldValue = (field, val) => {
    const dup = { ...values }
    dup[field] = val
    setValues(dup)
  }

  const resetVals = () => {
    setValues(defaultValue)
  }

  useEffect(() => {
    setToggle(open)
    resetVals()
  }, [open, defaultValue])

  const toggle = () => setToggle(prevState => !prevState)

  // eslint-disable-next-line
  const getLabelText = () => {
    return (
      <React.Fragment>
        <FontAwesomeIcon icon="sliders-h" className="mr-1" />
        {`Filters${filters.length > 0 ? ` (${filters.length})` : ''}`}
      </React.Fragment>
    )
  }

  const updatePriceRange = price => {
    setFieldValue('priceRange', price)
  }
  const updateCapacity = cap => {
    setFieldValue('capacity', cap)
  }
  const updateAvailability = val => {
    setFieldValue('availability', val)
  }
  const updateAmenities = amen => {
    setFieldValue('amenities')
  }

  const submit = event => {
    event.preventDefault()
    setToggle(false)
    handleAttChange(values)
  }

  const cancel = () => {
    resetVals()
    setToggle(false)
  }

  const clear = () => {
    resetVals()
    setToggle(false)
    onClear()
  }

  const applyBtn = mobile => (
    <ButtonComp
      color={brandColor}
      style={{ width: mobile ? '100%' : 'auto' }}
      onClick={submit}
    >
      Apply
    </ButtonComp>
  )

  const form = () => (
    <FormContainer>
      <FormWrapper style={{ margin: 0 }}>
        <LabelStyled>Price</LabelStyled>
        {/* <InputRange
          maxValue={20}
          minValue={0}
          value={values.priceRange}
          allowSameValues
          formatLabel={value => `$${value}`}
          onChange={updatePriceRange}
        /> */}
        <RadioGroup
          activeRadioValue={values.priceRange}
          arrayOfRadios={radioOptions}
          disabled={false}
          cards={false}
          color={brandColor}
          handleChange={updatePriceRange}
        />
      </FormWrapper>
      <FormWrapper style={{ marginTop: '-24px' }}>
        <LabelStyled>Minimum Capacity</LabelStyled>
        <IncrementButtonStyled
          value={values.capacity}
          min={1}
          color={brandColor}
          onChange={updateCapacity}
        />
      </FormWrapper>
      {values.availability !== undefined && (
        <FormWrapper style={{ marginTop: 0 }}>
          <LabelStyled>Availability</LabelStyled>
          <SelectDropdownStyled
            onChange={updateAvailability}
            value={values.availability}
            options={sortOptions}
          />
        </FormWrapper>
      )}
      {/* <FormWrapper> V2 FEATURE!!!!!
        <LabelStyled>Amenities</LabelStyled>
        <OptionSelectStyled
          value={values.amenities}
          options={[
            {
              label: 'Wifi1',
              value: 'wifi1',
            },
            {
              label: 'Wifi2',
              value: 'wifi2',
            },
            {
              label: 'Wifi3',
              value: 'wifi3',
            },
          ]}
          onChange={updateAmenities}
        />
      </FormWrapper> */}
      {/* <FormWrapper mobile>
        <LabelStyled>Sort By</LabelStyled>
        <SelectDropdownStyled
          onChange={updateSort}
          value={values.sort}
          label=""
          options={sortByOptions}
        />
      </FormWrapper> */}
    </FormContainer>
  )

  const content = () => (
    <React.Fragment>
      <ContentContainer>
        <Wrapper>{form()}</Wrapper>
      </ContentContainer>
      <ButtonContainer mobile={fullscreen}>
        <TextButtonComp
          style={{
            marginLeft: '0 !important',
            marginRight: 'auto',
          }}
          color={brandColor}
          onClick={clear}
        >
          Reset
        </TextButtonComp>
        <OutlineButtonComp color="neutral-4" onClick={cancel}>
          Cancel
        </OutlineButtonComp>
        {applyBtn(fullscreen)}
      </ButtonContainer>
    </React.Fragment>
  )

  const windowView = () => (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      {external ? (
        <ButtonComp
          color={brandColor}
          onClick={toggle}
          style={{
            position: 'relative',
          }}
        >
          {getLabelText()}
          <DropdownToggle
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              visibility: 'hidden',
            }}
          />
        </ButtonComp>
      ) : (
        <DropdownToggle>{getLabelText()}</DropdownToggle>
      )}
      <Menu>{content()}</Menu>
    </Dropdown>
  )

  const fullscreenView = () => (
    <React.Fragment>
      <ButtonComp color={brandColor} onClick={toggle}>
        {getLabelText()}
      </ButtonComp>
      <GenericModalStyled
        mobile
        style={{ margin: '0px' }}
        header={
          <HeaderWrapper>
            <OverlayHeader>Filter Options</OverlayHeader>
            <TextButtonComp color={brandColor} onClick={clear}>
              clear
            </TextButtonComp>
          </HeaderWrapper>
        }
        body={content()}
        footer={applyBtn(fullscreen)}
        isOpen={isOpen}
        hideHeaderToggle
        onClose={cancel}
      />
    </React.Fragment>
  )
  return (
    <Container {...other}>
      {fullscreen ? fullscreenView() : windowView()}
    </Container>
  )
}

AttributeFilter.propTypes = {
  handleAttChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  fullscreen: PropTypes.bool,
  open: PropTypes.bool,
  external: PropTypes.bool,
  filters: PropTypes.array,
  radioOptions: PropTypes.array,
  values: PropTypes.object,
  defaultValue: PropTypes.object,
  setFieldValue: PropTypes.func,
  resetForm: PropTypes.func,
  onClear: PropTypes.func,
  brandColor: PropTypes.string,
  currencySymbol: PropTypes.string,
}

AttributeFilter.displayName = 'Attribute Filter'

export default AttributeFilter
