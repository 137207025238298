import { Axios } from '../config/axios'
import { mockRequest } from '../components/Global/Constants'
// import { request } from 'http';

// helper function for post calls.
export function formatFormData(params) {
  const data = new FormData()
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      if (
        // we still want to let API know if there are "clears"
        //      so when things are set to ""
        params[key] !== null &&
        params[key] !== undefined &&
        params[key] !== 'null'
      ) {
        //console.log('apprending...', params[key])
        data.append(key, params[key])
      }
    }
  }
  return data
}
// -------------------- STATS -----------------------------------
export function getDashboardStats(params) {
  return Axios.get('/api/v1/stats/dashboard', {
    params,
  }).then(response => {
    return response.data
  })
}

// -------------------- USERS -----------------------------------
export function createMember(params) {
  const requestUrl = '/api/v1/auth/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function signIn(params) {
  const requestUrl = '/api/v1/auth/sign_in'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function registerCommunity(params) {
  const requestUrl = '/api/v1/auth'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function validateToken() {
  return Axios.get('/api/v1/auth/validate_token').then(response => {
    return response.data
  })
}
export function getUsers(campusId, params) {
  return Axios.get(`/api/v1/campuses/${campusId}/members`, { params }).then(
    response => {
      return response.data
    }
  )
}
export function getNetworkUsers(params) {
  // we don't know the network id on the front end yet
  return Axios.get(`/api/v1/networks/members`, { params }).then(response => {
    return response.data
  })
}
export function getInactiveUsers(campusId) {
  return Axios.get(`/api/v1/campuses/${campusId}/inactive_members`).then(
    response => {
      return response.data
    }
  )
}

export function getLeads(campusId) {
  return Axios.get(`/api/v1/campuses/${campusId}/leads`).then(response => {
    return response.data
  })
}
export function getContact(contactId) {
  return Axios.get(`/api/v1/contacts/${contactId}`).then(response => {
    return response.data
  })
}
export function getContacts(params) {
  return Axios.get(`/api/v1/contacts`, { params }).then(response => {
    return response.data
  })
}
export function getCommunityLeads(communityId, params) {
  return Axios.get(`/api/v1/communities/${communityId}/leads`, { params }).then(
    response => {
      return response.data
    }
  )
}
export function getNotifications(userId, params) {
  return Axios.get(`/api/v1/users/${userId}/notifications`, { params }).then(
    response => {
      return response.data
    }
  )
}
export function updateNotification(notificationID, params) {
  return Axios.put(
    `/api/v1/notifications/${notificationID}`,
    formatFormData(params)
  ).then(response => {
    return response.data
  })
}

export function markNotificationsAsSeen(userId, params) {
  return Axios.post(
    `/api/v1/users/${userId}/mark_notifications_seen`,
    formatFormData(params)
  ).then(response => {
    return response.data
  })
}
export function clearNotificationCount(userId, params) {
  return Axios.get(`/api/v1/users/${userId}/clear_unread_notification_count`, {
    params,
  }).then(response => {
    return response.data
  })
}
// --------------- COMMUNITY ---------
export function getCommunities(params) {
  return Axios.get('/api/v1/communities', { params }).then(response => {
    return response.data
  })
}
export function getStaff(communityId, params) {
  return Axios.get(
    `/api/v1/communities/${communityId}/get_community_staff_team`,
    { params }
  ).then(response => {
    return response.data
  })
}
export function getCommunityPreference(communityId, params) {
  return Axios.get(`/api/v1/communities/${communityId}/community_preference`, {
    params,
  }).then(response => {
    return response.data
  })
}

export function updateCommunityPreference(communityId, params) {
  const requestUrl = `/api/v1/communities/${communityId}/update_community_preference`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

export function checkEmailExistsContact(email, communityId) {
  return Axios.get(
    `/api/v1/contacts/check_email_exists?email=${email}&community_id=${communityId}`
  ).then(response => {
    return response.data
  })
}
export function getUserPreferencesByToken(params) {
  const userToken = params.authentication_token
  const client = params.client
  const uid = params.uid
  return Axios.get(
    `/api/v1/users/preferences?community_id=${params.community_id}`,
    {
      headers: {
        'Access-Token': userToken,
        Client: client,
        Uid: uid,
      },
    }
  ).then(response => {
    return response.data
  })
}
export function getMember(memberId, params) {
  return Axios.get(`/api/v1/users/${memberId}`, {
    params,
  }).then(response => {
    return response.data
  })
}
export function updateMember(params, headers) {
  const requestUrl = `/api/v1/users/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data, headers).then(response => {
    return response.data
  })
}

export function changeMemberTeam(params) {
  const requestUrl = `/api/v1/users/${params.id}/change_team`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function deactivateMember(params) {
  const requestUrl = `/api/v1/users/${params.id}/deactivate`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function activateMember(params) {
  const requestUrl = `/api/v1/users/${params.id}/activate`
  const data = new FormData()

  for (const key in params) {
    if (params.hasOwnProperty(key) && params[key] !== '') {
      data.append(key, params[key])
    }
  }

  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function updatePassword(params) {
  const requestUrl = '/api/v1/auth/password'
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

export function checkEmailExists(email, campusId) {
  let apiUrl
  if (campusId) {
    apiUrl = `/api/v1/users/check_email_exists?email=${email}&campus_id=${campusId}`
  } else {
    apiUrl = `/api/v1/users/check_email_exists?email=${email}`
  }
  return Axios.get(apiUrl).then(response => {
    return response.data
  })
}

export function sendResetPassword(email) {
  return Axios.get('/api/v1/users/forgot_password', {
    params: {
      email: email,
    },
  }).then(response => {
    return response.data
  })
}

export function resetPassword(
  password,
  passwordConfirmation,
  passwordResetToken
) {
  return Axios.post('/api/v1/users/reset_forgotten_password', {
    password: password,
    password_confirmation: passwordConfirmation,
    reset_password_token: passwordResetToken,
  }).then(response => {
    return response.data
  })
}

export function resendOnboardingEmail(params) {
  const requestUrl = `/api/v1/users/${params.id}/send_onboarding_email`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function sendNotification(params) {
  let requestUrl

  if (params.type === 'visitor') {
    requestUrl = `/api/v1/users/${params.memberId}/notify_of_visitor?message=${params.message}&campus_id=${params.campusId}&first_name=${params.firstName}&last_name=${params.lastName}&email=${params.email}`
  }

  return Axios.post(requestUrl).then(response => {
    return response.data
  })
}

// -------------------- PLANS -----------------------------------
export function createPlan(params) {
  const requestUrl = '/api/v1/plans/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function getPlans(campusId, params) {
  return Axios.get(`/api/v1/campuses/${campusId}/plans`, { params }).then(
    response => {
      return response.data
    }
  )
}

export function updatePlan(params) {
  const requestUrl = `/api/v1/plans/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

export function checkoutDayPass(params) {
  const requestUrl = `/api/v1/plans/${params.plan_id}/day_pass_checkout`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

// -------------------- TEAMS -----------------------------------
export function createTeam(params) {
  const requestUrl = '/api/v1/teams/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function getTeams(campusId, params) {
  return Axios.get(`/api/v1/campuses/${campusId}/teams`, { params }).then(
    response => {
      return response.data
    }
  )
}
export function getDirectoryTeams(campusId, params) {
  return Axios.get(`/api/v1/campuses/${campusId}/directory_teams`, {
    params,
  }).then(response => {
    return response.data
  })
}
export function getTeamMembers(teamId, params) {
  return Axios.get(`/api/v1/teams/${teamId}/members`, {
    params,
  }).then(response => {
    return response.data
  })
}

export function getInactiveTeams(campusId) {
  return Axios.get(`/api/v1/campuses/${campusId}/inactive_teams`).then(
    response => {
      return response.data
    }
  )
}

export function getTeam(teamId, params) {
  return Axios.get(`/api/v1/teams/${teamId}`, { params }).then(response => {
    return response.data
  })
}

export function updateTeam(params) {
  const requestUrl = `/api/v1/teams/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}
export function deactivateTeam(teamId, params) {
  return Axios.post(
    `/api/v1/teams/${teamId}/deactivate`,
    formatFormData(params)
  ).then(response => response.data)
}
export function activateTeam(teamId, params) {
  return Axios.post(
    `/api/v1/teams/${teamId}/activate`,
    formatFormData(params)
  ).then(response => response.data)
}
export function changeCampus(teamId, params) {
  return Axios.post(
    `/api/v1/teams/${teamId}/change_campus`,
    formatFormData(params)
  ).then(response => response.data)
}
export function changeAdmin(params) {
  const requestUrl = `/api/v1/teams/${params.id}/change_admin`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function removeCoworksPlan(params) {
  const requestUrl = `/api/v1/teams/${params.id}/remove_coworks_plan`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function addCoworksPlan(params) {
  const requestUrl = `/api/v1/teams/${params.id}/add_coworks_plan`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function createTeamSubscription(params) {
  const requestUrl = `/api/v1/teams/${params.id}/create_team_subscription`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

// -------------------- RESOURCES -----------------------------------
export function getResources(campusId) {
  return Axios.get(`/api/v1/campuses/${campusId}/resources`).then(response => {
    return response.data
  })
}

export function createResourceSlides(params) {
  const requestUrl = '/api/v1/resources/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function createResource(params) {
  const requestUrl = '/api/v1/resources/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function updateResource(params) {
  const requestUrl = `/api/v1/resources/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

// -------------------- ROOMS -----------------------------------
export function getRoom(roomId) {
  return Axios.get(`/api/v1/rooms/${roomId}`).then(response => {
    return response.data
  })
}

export function getRoomsAtCampus(campusId, params) {
  return Axios.get(`/api/v1/campuses/${campusId}/rooms`, { params }).then(
    response => {
      return response.data
    }
  )
}

export function getRooms(params) {
  return Axios.get(`/api/v1/rooms`, { params }).then(response => {
    return response.data
  })
}

export function createRoom(params) {
  const requestUrl = '/api/v1/rooms/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function updateRoom(params) {
  const requestUrl = `/api/v1/rooms/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

// -------------------- BOOKINGS -----------------------------------
export function getBookings(campusId, otherParams) {
  return Axios.get(`/api/v1/campuses/${campusId}/bookings`, {
    params: otherParams,
  }).then(response => {
    return response.data
  })
}

export function getAttendees(eventId, otherParams) {
  return Axios.get(`/api/v1/bookings/${eventId}/attendees`, {
    params: otherParams,
  }).then(response => {
    return response.data
  })
}
// -------------------- BOOKINGS -----------------------------------
export function getBooking(bookingId) {
  return Axios.get(`/api/v1/bookings/${bookingId}`).then(response => {
    return response.data
  })
}
export function getBookingsThin(campusId, fromDate, isPrivate) {
  return Axios.get(
    `/api/v1/campuses/${campusId}/bookings_thin?from_date=${fromDate}&private=${isPrivate}`
  ).then(response => {
    return response.data
  })
}

export function getBookingThick(eventId, otherParams) {
  return Axios.get(`/api/v1/bookings/${eventId}`, {
    params: otherParams,
  }).then(response => {
    return response.data
  })
}

export function updateBooking(booking) {
  const requestUrl = `/api/v1/bookings/${booking.id}`
  const data = formatFormData(booking)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

export function createBooking(params) {
  const requestUrl = '/api/v1/bookings/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function getTabletKey(roomId, campusId) {
  const requestUrl = `/api/v1/campus_tablets?room_id=${roomId}&campus_id=${campusId}`

  return Axios.post(requestUrl).then(response => {
    return response.data
  })
}

export function updateCampusTablet(params) {
  const requestUrl = `/api/v1/campus_tablets/0?tablet_key=${params.tablet_key}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

// -------------------- CAMPUS -----------------------------------
export function updateCampus(params) {
  const requestUrl = `/api/v1/campuses/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}
export function createCampus(params) {
  const requestUrl = '/api/v1/campuses/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

// -------------------- ATTACHMENT -----------------------------------
export function createAttachment(params) {
  const requestUrl = '/api/v1/attachments/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function updateAttachment(params) {
  const requestUrl = `/api/v1/attachments/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

// -------------------- LEADS -----------------------------------

export function createLead(params) {
  const requestUrl = '/api/v1/contacts/'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function updateLead(params) {
  const requestUrl = `/api/v1/contacts/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}
export function scheduleTour(params) {
  const requestUrl = '/api/v1/contacts/schedule_tour'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

// -------------------- STRIPE -----------------------------------
export function acceptTOS(stripeAccountId) {
  const requestUrl = '/api/v1/billing/accept_tos'
  const data = new FormData()
  data.append('account_id', stripeAccountId)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function getStripeCustomer(params) {
  const requestUrl = '/api/v1/billing/get_customer_by_id'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function updateStripeCustomer(params) {
  const requestUrl = '/api/v1/billing/update_customer'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function getStripeCoworksCustomer(params) {
  return Axios.get(
    `/api/v1/billing/get_coworks_customer?coworks_customer_id=${params.coworks_customer_id}`
  ).then(response => {
    return response.data
  })
}
export function addSourceTokenToCustomer(params) {
  const requestUrl = '/api/v1/billing/add_token'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function removeStripeCustomerSource(params) {
  const requestUrl = '/api/v1/billing/delete_payment_source'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function getStripeInvoices(params) {
  const requestUrl = '/api/v1/billing/get_invoices'
  const data = formatFormData(params)
  return Axios.get(requestUrl, { params }).then(response => {
    return response.data
  })
}
export function getStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/get_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function getStripeTransaction(params) {
  const requestUrl = '/api/v1/billing/get_transaction'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function getStripeUpcomingInvoice(params) {
  const requestUrl = '/api/v1/billing/get_upcoming_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function cancelStripeSubscription(params) {
  const requestUrl = '/api/v1/billing/cancel_subscription'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function updateStripeSubscription(params) {
  const requestUrl = '/api/v1/billing/update_subscription'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function updateStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/update_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function payStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/pay_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function splitStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/split_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function voidStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/void_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function finalizeStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/finalize_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function deleteStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/delete_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function resendStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/resend_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function createStripeSubscription(params) {
  const requestUrl = '/api/v1/billing/create_subscription'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function removeStripePlanOnSubscription(params) {
  const requestUrl = '/api/v1/billing/remove_plans_from_subscription'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function createStripeCharge(params) {
  const requestUrl = '/api/v1/billing/create_charge'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function createStripeInvoice(params) {
  const requestUrl = '/api/v1/billing/create_invoice'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function createStripeInvoiceItems(params) {
  const requestUrl = '/api/v1/billing/create_invoice_items'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function updateStripeCharge(params) {
  const requestUrl = '/api/v1/billing/update_charge'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function getStripeCharges(params) {
  const requestUrl = '/api/v1/billing/get_charges'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function getStripeTransactions(params) {
  const requestUrl = '/api/v1/billing/get_transactions'
  return Axios.get(requestUrl, {
    params,
  }).then(response => {
    return response.data
  })
}
export function updateStripeAccount(params) {
  const requestUrl = '/api/v1/billing/update_account'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function getStripeAccount(params) {
  const requestUrl = '/api/v1/billing/get_account'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function createStripeRefund(params) {
  const requestUrl = '/api/v1/billing/create_refund'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function deleteStripeDiscount(params) {
  const requestUrl = '/api/v1/billing/delete_discount'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function createCouponForSubscription(params) {
  const requestUrl = '/api/v1/billing/update_subscription'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function verifyStripeBank(params) {
  const requestUrl = '/api/v1/billing/verify_stripe_bank'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

//-------------- OTHER ---------------------

export function getGroups(campusId) {
  return Axios.get(`/api/v1/campuses/${campusId}/groups`).then(response => {
    return response.data
  })
}
export function createGroup(params) {
  const requestUrl = '/api/v1/groups'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function updateGroup(params) {
  const requestUrl = `/api/v1/groups/${params.id}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}
export function getTagsForGroup(params) {
  return Axios.get(`/api/v1/groups/tags`, {
    params,
  }).then(response => {
    return response.data
  })
}
// ------------------ REPORTS ------------------
export function getCommunityMembersReport(params) {
  return Axios.get('/api/v1/reports/community_members', {
    params,
  }).then(response => {
    return response.data
  })
}
export function getCheckinsReport(params, type) {
  return Axios.get(`/api/v1/reports/${type.toLowerCase()}_checkins`, {
    params,
  }).then(response => {
    return response.data
  })
}
export function getCommunityTeamsReport(params) {
  return Axios.get('/api/v1/reports/community_teams', {
    params,
  }).then(response => {
    return response.data
  })
}

export function getCommunityBookingsReport(params) {
  return Axios.get('/api/v1/reports/community_bookings', {
    params,
  }).then(response => {
    return response.data
  })
}
export function getCampusBookingsReport(params) {
  return Axios.get('/api/v1/reports/campus_bookings', {
    params,
  }).then(response => {
    return response.data
  })
}
export function getCommunityLeadsReport(params) {
  return Axios.get('/api/v1/reports/community_leads', {
    params,
  }).then(response => {
    return response.data
  })
}
export function getCommunityProspectsReport(params) {
  return Axios.get('/api/v1/reports/community_prospects', {
    params,
  }).then(response => {
    return response.data
  })
}
export function getCommunityRoomsReport(params) {
  return Axios.get(`/api/v1/reports/community_rooms`, {
    params,
  }).then(response => {
    return response.data
  })
}

export function getStripePlansReport(params) {
  return Axios.get('/api/v1/reports/stripe_plans', {
    params,
  }).then(response => {
    return response.data
  })
}
export function getCommunityInvoicesReport(params) {
  return Axios.get('/api/v1/reports/stripe_invoices', {
    params,
  }).then(response => {
    return response.data
  })
}
export function getCommunityPaymentsReport(params) {
  return Axios.get('/api/v1/reports/stripe_payments', { params }).then(
    response => {
      return response.data
    }
  )
}

export function getCommunityActiveMembershipsReport(params) {
  return Axios.get('/api/v1/reports/stripe_subscriptions', { params }).then(
    response => {
      return response.data
    }
  )
}
export function getCommunityBookingTotalsReport(params) {
  return Axios.get('/api/v1/reports/booking_usage_report', {
    params,
  }).then(response => {
    return response.data
  })
}

export function connectToStripeStandard(params) {
  const requestUrl = '/api/v1/billing/connect_to_stripe'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function importMembers(params) {
  const requestUrl = '/api/v1/users/import_members'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function importBookings(params) {
  const requestUrl = '/api/v1/bookings/import_bookings'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function sendCommunityOnboardingEmails(params, communityId) {
  const requestUrl = `/api/v1/communities/${communityId}/send_community_onboarding_emails`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function convertAllPendingMembers(params, communityId) {
  const requestUrl = `/api/v1/communities/${communityId}/convert_pendingmembers_to_members`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

// --- Coworks community
export function getPublicCommunityProfile(params) {
  return Axios.get('/api/v1/coworks_communities/public_community_profile', {
    params,
  }).then(response => {
    return response.data
  })
}
export function getPublicEvent(params) {
  return Axios.get('/api/v1/coworks_communities/public_event', { params }).then(
    response => {
      return response.data
    }
  )
}
export function getPublicPlans(params) {
  return Axios.get('/api/v1/coworks_communities/public_plans', { params }).then(
    response => {
      return response.data
    }
  )
}
export function getPublicPlan(params) {
  return Axios.get('/api/v1/coworks_communities/public_plan', { params }).then(
    response => {
      return response.data
    }
  )
}
// -------------------- tasks -----------------------
export function createTask(params) {
  const requestUrl = '/api/v1/tasks'
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

// ------------------ ANNOUNCEMENTS ------------------

export function getAnnouncements(campusId, params) {
  return Axios.get(`/api/v1/campuses/${campusId}/announcements`, {
    params,
  }).then(response => {
    return response.data
  })
}

export function createAnnouncement(announcement) {
  const requestUrl = '/api/v1/announcements/'
  const data = formatFormData(announcement)
  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function updateAnnouncement(announcementId, params) {
  const requestUrl = `/api/v1/announcements/${announcementId}`
  const data = formatFormData(params)
  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

export function getAnnouncementRecipients(annoucementId, params) {
  return Axios.get(`/api/v1/announcements/${annoucementId}/recipients`, {
    params,
  }).then(response => {
    return response.data
  })
}

export function getAnnouncement(annoucementId, params) {
  return Axios.get(`/api/v1/announcements/${annoucementId}`, { params }).then(
    response => {
      return response.data
    }
  )
}

// export function checkinMember(params) {
//   const requestUrl = '/api/v1/networks/checkin'
//   const data = formatFormData(params)

//   return Axios.post(requestUrl, data).then(response => {
//     return response.data
//   })
// }

// door access
export function getDoorAccessPlaces(params) {
  return Axios.get(`/api/v1/doors/places`, {
    params,
  }).then(response => {
    return response.data
  })
}
export function getDoorAccessGroups(params) {
  return Axios.get(`/api/v1/doors/lock_groups`, {
    params,
  }).then(response => {
    return response.data
  })
}
// intgrations

export function activateIntegration(params) {
  const requestUrl = `/api/v1/communities/${params.community_id}/activate_integration`
  const data = formatFormData(params)

  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}
export function deactivateIntegration(params) {
  const requestUrl = `/api/v1/communities/${params.community_id}/deactivate_integration`
  const data = formatFormData(params)

  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

// ------------------ CHECKINS ------------------

export function getCheckIns(params) {
  const requestUrl = `/api/v1/checkins/`
  // const data = formatFormData(params)

  return Axios.get(requestUrl, {
    params,
  }).then(response => {
    return response.data
  })
}

export function createCheckIn(params) {
  const requestUrl = `/api/v1/checkins/`
  const data = formatFormData(params)

  return Axios.post(requestUrl, data).then(response => {
    return response.data
  })
}

export function deleteCheckIn(checkinId) {
  const requestUrl = `/api/v1/checkins/${checkinId}`

  return Axios.delete(requestUrl).then(response => {
    return response.data
  })
}

export function updateCheckIn(params, checkinId) {
  const requestUrl = `/api/v1/checkins/${checkinId}`
  const data = formatFormData(params)

  return Axios.put(requestUrl, data).then(response => {
    return response.data
  })
}

// ------------------ EXTERNAL BOOKINGS ------------------

export function getExternalRooms(params) {
  const requestUrl = `/api/v1/rooms/external_bookings`
  return Axios.get(requestUrl, { params }).then(response => response.data)
}
export function getRoomAvailability(roomId, params) {
  const requestUrl = `/api/v1/rooms/${roomId}/availability`
  return Axios.get(requestUrl, { params }).then(response => response.data)
}
export function createExternalBooking(params) {
  const requestUrl = `/api/v1/bookings/external`
  const data = formatFormData(params)
  return Axios.post(requestUrl, data).then(response => response.data)
}
