import { resolveColor } from '../../../Global/Base/baseComponentColorUtils'
import styled from 'styled-components'

const Callout = styled.div.attrs(props => ({
  color: props.color || 'primary',
  direction: props.direction || 'left',
}))`
  position: relative;
  padding: 0 1rem;
  margin: 1rem 0;
  border: 0 solid #cfd8dc;
  border-left-width: ${props =>
    props.direction === 'left' ? '0.25rem' : null};
  border-right-width: ${props =>
    props.direction === 'right' ? '0.25rem' : null};
  border-left-color: ${props =>
    props.direction === 'left' ? resolveColor(props) : 'transparent'};
  border-right-color: ${props =>
    props.direction === 'right' ? resolveColor(props) : 'transparent'};
`

export default Callout
