import React from 'react'
import PropTypes from 'prop-types'
import Anchor from '@global/Base/Anchor/Anchor'
import TagRow from '@global/TagRow'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const TouredCol = ({ toured, ...other }) => {
  const text = toured ? 'Toured' : 'Not Toured'
  const icon = toured ? 'check' : 'times'
  const color = toured ? '#14a2a0' : '#ccc'
  return (
    <span className="badge m-1" style={{ backgroundColor: color }} {...other}>
      <FontAwesomeIcon icon={icon} /> {text}
    </span>
  )
}

TouredCol.propTypes = {
  toured: PropTypes.bool,
}

TouredCol.displayName = 'Toured Column'

export default TouredCol
