import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { successCheckingEmail } from './userActions'
import {
  FETCHING_LEADS,
  FETCHING_PENDING_MEMBERS,
  FETCHING_PROSPECTS,
} from '@global/Constants/FetchingConstants'

const PENDING_MEMBER = 'PendingMember'
const PROSPECT = 'Prospect'
const LEAD = 'Lead'

const ENUM = {
  SET: 'set',
  CLEAR: 'clear',
  UPDATE: 'update',
  SUCCESS_CREATE: 'success-create',
  SUCCESS_DELETE: 'success-delete',
  SUCCESS_UPDATE: 'success-update',
}

export const getFetchByType = type => {
  switch (type) {
    case PENDING_MEMBER:
      return FETCHING_PENDING_MEMBERS
    case LEAD:
      return FETCHING_LEADS
    default:
      return FETCHING_PROSPECTS
  }
}

const getType = type => {
  switch (type) {
    case LEAD:
      return {
        [ENUM.SET]: actionTypes.SET_LEADS,
        [ENUM.CLEAR]: actionTypes.CLEAR_LEADS,
        [ENUM.UPDATE]: actionTypes.UPDATE_LEADS,
        [ENUM.SUCCESS_CREATE]: actionTypes.CREATE_LEAD,
        [ENUM.SUCCESS_DELETE]: actionTypes.ARCHIVE_LEAD,
        [ENUM.SUCCESS_UPDATE]: actionTypes.UPDATE_LEAD,
      }
    default:
      return {
        [ENUM.SET]: actionTypes.SET_PROSPECTS,
        [ENUM.CLEAR]: actionTypes.CLEAR_PROSPECTS,
        [ENUM.UPDATE]: actionTypes.UPDATE_PROSPECTS,
        [ENUM.SUCCESS_CREATE]: actionTypes.CREATE_PROSPECT,
        [ENUM.SUCCESS_DELETE]: actionTypes.ARCHIVE_PROSPECT,
        [ENUM.SUCCESS_UPDATE]: actionTypes.UPDATE_PROSPECT,
      }
  }
}

// ACTION CREATORS
export function setLeads(type, leads, meta = null) {
  return { type: getType(type)[ENUM.SET], leads, meta }
}
export function updateLeads(type, leads, meta = null) {
  return { type: getType(type)[ENUM.UPDATE], leads, meta }
}
export function clearLeads(type) {
  return { type: getType(type)[ENUM.CLEAR] }
}
export function successCreatingLead(type, response) {
  return { type: getType(type)[ENUM.SUCCESS_CREATE], response }
}
export function successUpdatingLead(type, lead) {
  return { type: getType(type)[ENUM.SUCCESS_UPDATE], lead }
}
export function successArchivingLead(type, lead) {
  return { type: getType(type)[ENUM.SUCCESS_DELETE], lead }
}
export function setPendingMembers(pendingMembers) {
  return { type: actionTypes.SET_PENDING_MEMBERS, pendingMembers }
}
export function successUpdatingPendingMember(pendingMember) {
  return { type: actionTypes.UPDATE_PENDING_MEMBER, pendingMember }
}
export function successSchedulingTour(lead) {
  return { type: actionTypes.SUCCESS_SCHEDULING_TOUR, lead }
}

// ACTION DISPATCHERS
export const createContact = (contact, type = PROSPECT) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    return apiService
      .createLead(contact)
      .then(response => {
        dispatch(successCreatingLead(type, response.lead))
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        reject(err)
      })
  })
}
export const getCommunityContacts = (
  params,
  nextPage,
  type = PROSPECT
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    if (!nextPage) dispatch(clearLeads(type))
    apiService
      .getContacts(params)
      .then(response => {
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        switch (type) {
          case PENDING_MEMBER:
            dispatch(setPendingMembers(response.pending_members))
            resolve(response.pending_members)
            break
          case LEAD:
            if (nextPage) {
              dispatch(updateLeads(LEAD, response.leads, response.meta))
            } else {
              dispatch(setLeads(LEAD, response.leads, response.meta))
            }
            resolve(response.leads)
            break
          default:
            if (nextPage) {
              dispatch(updateLeads(PROSPECT, response.contacts, response.meta))
            } else {
              dispatch(setLeads(PROSPECT, response.contacts, response.meta))
            }
            resolve(response.contacts)
            break
        }
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        reject(err)
      })
  })
}
export const updateContact = (contact, type = PROSPECT) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    return apiService
      .updateLead(contact)
      .then(response => {
        dispatch(successUpdatingLead(type, response.lead))
        dispatch(
          successArchivingLead(type === LEAD ? PROSPECT : LEAD, response.lead)
        )
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        reject(err)
      })
  })
}
export const archiveContact = (contact, type = PROSPECT) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    return apiService
      .updateLead(contact)
      .then(response => {
        dispatch(successArchivingLead(type, response.lead))
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        reject(err)
      })
  })
}
export const getContact = (contactId, type = PROSPECT) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, type))
    return apiService
      .getContact(contactId)
      .then(response => {
        dispatch(successUpdatingLead(type, response.lead))
        dispatch(uiActions.updateFetchingStatus(false, type))
        resolve(response.lead)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, type))
        reject(err)
      })
  })
}
export const search = (campusId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    const type = params.type
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    apiService
      .getContacts(params)
      .then(response => {
        switch (type) {
          case LEAD:
            dispatch(updateLeads(LEAD, response.leads, response.meta))
            resolve(response.leads)
            break
          default:
            dispatch(updateLeads(PROSPECT, response.contacts, response.meta))
            resolve(response.contacts)
            break
        }
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        reject(err)
      })
  })
}

export const getPendingMembers = (
  communityId,
  params,
  type = PENDING_MEMBER
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, getFetchByType(type)))
    apiService
      .getCommunityLeads(communityId, params)
      .then(response => {
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        dispatch(setPendingMembers(response.pending_members))
        resolve(response.pending_members)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false, getFetchByType(type)))
        reject(err)
      })
  })
}
export const updatePendingMember = contact => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(
      uiActions.updateFetchingStatus(true, getFetchByType(PENDING_MEMBER))
    )
    return apiService
      .updateLead(contact)
      .then(response => {
        dispatch(successUpdatingPendingMember(response.pendingMember))
        dispatch(
          uiActions.updateFetchingStatus(false, getFetchByType(PENDING_MEMBER))
        )
        resolve(response)
      })
      .catch(err => {
        dispatch(
          uiActions.updateFetchingStatus(false, getFetchByType(PENDING_MEMBER))
        )
        reject(err)
      })
  })
}
export function checkEmailExists(email, communityId) {
  return dispatch => {
    //dispatch(uiActions.updateFetchingStatus(true));
    return apiService
      .checkEmailExistsContact(email, communityId)
      .then(response => dispatch(successCheckingEmail(response)))
    //.then(() => dispatch(uiActions.updateFetchingStatus(false)));
  }
}
export const scheduleTour = object => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .scheduleTour(object)
      .then(response => {
        dispatch(successSchedulingTour(response.lead))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
