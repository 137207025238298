/* eslint-disable no-invalid-this */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as campusActions from '../../reduxActions/campusActions'
import * as uiActions from '../../reduxActions/uiActions'
import * as userActions from '../../reduxActions/userActions'
import styled from 'styled-components'
import Login from './Login'
import { Link } from 'react-router-dom'

const LoginContainer = styled.div`
  overflow-x: hidden;
  width: 100% !important;
  height: 100vh;

  background: rgb(41, 64, 79);
  background: linear-gradient(
    343deg,
    rgba(41, 64, 79, 1) 0%,
    rgba(38, 151, 150, 1) 50%,
    rgba(205, 217, 229, 1) 100%
  );
`
function DashboardLoginWrapper({ user_actions, ui_actions, history }) {
  function routeToSignUp() {
    history.push('/signup')
  }
  useEffect(() => {
    ui_actions.setIsTabletUser(false)
    user_actions.signOutUser()
  }, [ui_actions, user_actions])

  return (
    <LoginContainer>
      <Login
        history={history}
        routeToSignup={routeToSignUp}
        titleText={'Manager Dashboard'}
      />
    </LoginContainer>
  )
}

DashboardLoginWrapper.displayName = 'DashboardLoginWrapper'
DashboardLoginWrapper.propTypes = {
  history: PropTypes.object,
  user_actions: PropTypes.object,
  ui_actions: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    campuses:
      state.ui.activeCommunity && state.ui.activeCommunity.campuses
        ? state.ui.activeCommunity.campuses
        : [],
    user: state.user,
    isFetching: state.ui.isFetching,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    campus_actions: bindActionCreators(campusActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardLoginWrapper)
