/* eslint-disable no-invalid-this,brace-style,prefer-spread,radix */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { Switch, Route, Redirect } from 'react-router-dom'

import * as teamActions from '@reduxActions/teamActions'
import * as checkinActions from '@app/reduxActions/checkinActions'
import * as stripeActions from '@reduxActions/stripeActions'
import * as taskActions from '@reduxActions/taskActions'
import * as memberSelectors from '@reduxSelectors/memberSelectors'
import { Alert } from 'reactstrap'
import * as _ from 'lodash'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Row,
} from 'reactstrap'
import { toast } from 'react-toastify'
import * as Roles from '@app/config/roles'
import { extractCoworksErrorObject } from '@global/ErrorFactory'

import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'

import Spinner from '@global/Spinner'
import CampusSelectorModal from './CampusSelectorModal'
import TeamProfileForm from './Profile/TeamProfileForm'
import TeamMembership from './Membership/TeamMembership'
import TeamMembers from './Members/TeamMembers'

import ArchiveModal from '../../ArchiveModal'
import {
  resolveFetchingStatus,
  FETCHING_TEAM_MEMBERS,
} from '@global/Constants/FetchingConstants'

import TeamStatCard from './TeamStatCard'
import Billing from '@components/Payments/Billing'
import MemberRow from '../../Members/MemberRow'
import NothingFound from '@global/NothingFound'
import CommentsComponent from '@global/CommentsComponent'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'

import moment from 'moment'

class TeamDetail extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    team_actions: PropTypes.object,
    stripe_actions: PropTypes.object,
    task_actions: PropTypes.object,
    checkin_actions: PropTypes.object,
    campus: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    team: PropTypes.object,
    teamId: PropTypes.string,
    teams: PropTypes.array,
    inactiveTeams: PropTypes.array,
    plans: PropTypes.array,
    members: PropTypes.array,
    campuses: PropTypes.array,
    role: PropTypes.string,
    user: PropTypes.object,
    isPosting: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFetchingTeamMembers: PropTypes.bool,
    location: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const { location, team } = props
    // resolve the joined_at date.
    const timeStamp = this.getJoinedAtTime(team)

    let isStaff = false
    //todo this looks wrong to me  vvvvv -- tanner
    if (
      location.state &&
      location.state.isStaff !== null &&
      location.state.isStaff !== undefined
    ) {
      isStaff = location.state.isStaff
    }
    // added support for is_staff when you click on the company in the list
    if (team && team.campus_teams && team.campus_teams.length > 0) {
      for (let count = 0; count < team.campus_teams.length; count++) {
        const campusTeam = team.campus_teams[count]
        if (campusTeam.is_staff) {
          isStaff = true
        }
      }
    }

    this.state = {
      photo: '',
      conferenceHours: 0,
      joinedAt: timeStamp,
      dropdownsOpen: [],
      dropdownOpen: false,
      errors: [],
      isEditing: false,
      showCampusSelectorModal: false,
      isStaff,
      creatingNote: false,
      lastInvoiceAmount: '--',
      totalInvoiceAmount: '--',
      isInactive: false,
      shouldRefreshPayments: false,
      comment: '',
      tasks: [],
      loading: true,
      loadingNext: false,
      showConferenceUsage: true,
      pagination: { total_pages: 1, total_objects: Constants.PER_PAGE_DEFAULT },
      page: Constants.START_PAGE_DEFAULT,
      perPage: Constants.PER_PAGE_DEFAULT,
      members: [],
      photoChanged: false,
    }
  }

  componentDidMount() {
    // Gets the team thicck
    const { teamId } = this.props
    if (teamId) {
      this.props.team_actions
        .getTeam(teamId, {
          campus_id: this.props.activeCampus.id,
          show_conference_usage: this.state.showConferenceUsage,
          active_users_limit: 0,
          inactive_users_limit: 0,
          status: this.state.isInactive ? 'inactive' : 'active',
        })
        .then(team => {
          const newState = {}
          if (team.tasks) {
            newState.tasks = team.tasks
          }
          newState.gotTeamThicck = true
          newState.photo = team.team_photo ? team.team_photo.file_url : ''
          newState.conferenceHours = team.conference_hours
            ? team.conference_hours
            : 0
          newState.isInactive = team.status === 'inactive'

          this.setState(newState)
        })
        .catch(err => {
          toast.error(
            'There was a problem getting the team. Please contact support.'
          )
        })
        .finally(() => {
          this.setState({ loading: false })
        })
      // get the first set of members.
    }
  }
  handleSubmitComment = () => {
    this.setState({ creatingNote: true })
    this.props.task_actions
      .createTask({
        description: this.state.comment,
        created_by_user_id: this.props.user.id,
        team_id: this.props.team.id,
      })
      .then(response => {
        if (response && response.task) {
          const newTask = response.task
          newTask.created_by_user = this.props.user
          this.setState({
            tasks: [...this.state.tasks, newTask],
            comment: '',
          })
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.setState({
          creatingNote: false,
        })
      })
  }
  toggleShouldRefreshPayments = () => {
    this.props.stripe_actions.clearStripePaymentData()
    this.setState({
      shouldRefreshPayments: !this.state.shouldRefreshPayments,
    })
  }

  toggleShowArchiveModal = () => {
    const { team } = this.props
    if (team) {
      this.setState({
        showArchiveModal: !this.state.showArchiveModal,
      })
    }
  }

  toggleTab = tab => {
    const { team, history, match } = this.props
    const { path, url } = match
    if (team) {
      NavigationConstants.navigateToTeam({
        history: history,
        match: match,
        team,
        tab: tab,
        url,
      })
    }
  }
  getJoinedAtTime = team => {
    let timeStamp = team && team.joined_at ? moment(team.joined_at) : null
    if (!timeStamp && team && team.created_at) {
      timeStamp = moment(team.created_at)
    }
    return timeStamp
  }
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleImageUpload = photo => {
    this.setState({
      photo,
      photoChanged: true,
    })
  }
  handleLastInvoiceReceived = total => {
    this.setState({
      lastInvoiceAmount: total ? total : 0,
    })
  }
  handleTotalInvoiceReceived = total => {
    this.setState({
      totalInvoiceAmount: total,
    })
  }
  handleJoinedAtChanged = mom => {
    this.setState({
      joinedAt: mom,
    })
  }

  goBack = () => this.props.history.goBack()
  toggleEdit = () => this.setState({ isEditing: !this.state.isEditing })
  toggleChangeCampusModal = () =>
    this.setState({
      showCampusSelectorModal: !this.state.showCampusSelectorModal,
    })

  switchCampus = newCampus => {
    this.props.team_actions
      .changeCampus(this.props.team.id, {
        old_campus_id: this.props.activeCampus.id,
        new_campus_id: newCampus.id,
      })
      .then(() => {
        toast.success('Switched campuses.')
        this.props.history.push('/directory')
      })
      .catch(err => {
        Constants.logO('failed to switch campuses', err)
        toast.error('There was a problem switching campuses.')
      })
  }
  handleChangeStatus = ({ isInactive, params }) => {
    this.setState({
      showArchiveModal: false,
      loading: true,
    })
    const apiParams = _.cloneDeep(params)
    apiParams.campus_id = this.props.activeCampus.id

    if (isInactive) {
      // need to activate
      this.props.team_actions
        .activateTeam(this.props.team.id, apiParams)
        .then(response => {
          toast.success(`Activated ${this.props.team.name}.`)
          this.setState({
            showArchiveModal: false,
            loading: false,
            isInactive: false,
          })
        })
        .catch(err => {
          this.setState({
            showArchiveModal: false,
            loading: false,
          })
          if (err) {
            toast.error(extractCoworksErrorObject(err))
          }
        })
    } else {
      // need to deactivate
      this.props.team_actions
        .deactivateTeam(this.props.team.id, {
          campus_id: this.props.activeCampus.id,
        })
        .then(response => {
          toast.success(`Deactivated ${this.props.team.name}.`)
          this.setState({
            showArchiveModal: false,
            loading: false,
            isInactive: true,
          })
        })
        .catch(err => {
          this.setState({
            showArchiveModal: false,
            loading: false,
          })
          if (err) {
            toast.error(extractCoworksErrorObject(err))
          }
        })
    }
  }
  updateTeam = values => {
    this.setState({
      loading: true,
    })

    const teamUpdates = {
      id: this.props.team.id,
      description: values.description,
      name: values.name,
      joined_at: values.joinedAt,
      conference_hours_allowance: values.conferenceHoursAllowance,
      website: values.website,
      email: values.email,
      team_industries: values.teamIndustries.map(obj => obj.value).join(', '),
      dynamic_tags: JSON.stringify(values.dynamicTags),
    }
    if (values.photo) {
      teamUpdates.team_photo = values.photo
    }
    this.props.team_actions
      .updateTeam(teamUpdates)
      .then(response => {
        const team = response.team
        this.setState({
          photo: team && team.team_photo ? team.team_photo.file_url : '',
          joinedAt: this.getJoinedAtTime(team),
          conferenceHours:
            team && team.conference_hours ? team.conference_hours : 0,
          conferenceHoursAllowance:
            team && team.conference_hours_allowance !== null
              ? Number(team.conference_hours_allowance).toFixed(2)
              : 0,
          loading: false,
          isInactive: team && team.status === 'inactive',
        })
        this.toggleEdit()
        toast.success(`Updated the ${team.name} team.`)
      })
      .catch(err => {
        toast.error(extractCoworksErrorObject(err))
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  renderInactiveAlert = () => {
    if (this.state.isInactive) {
      return (
        <Alert
          color="warning"
          className={'m-0'}
          isOpen={this.props.team && this.props.team.status === 'inactive'}
        >
          This team is inactive!
        </Alert>
      )
    }
    return null
  }
  renderTeamWidgets = (team, lastInvoiceAmount, totalInvoiceAmount) => {
    const { conferenceHours, pagination, members, loading } = this.state
    if (!team || loading) {
      return null
    }

    let confText = '--'
    if (team && team.conference_hours_allowance) {
      confText = `${Number(conferenceHours).toFixed(2)}/${Number(
        team.conference_hours_allowance
      ).toFixed(2)}`
    } else if (team && team.conference_hours) {
      confText = Number(team.conference_hours).toFixed(2)
    }
    const symbol = Constants.getCurrencySymbolFromCommunity(
      this.props.activeCommunity
    )
    const memberCount = team.size
    return (
      <div className="row border-bottom-1 my-3">
        <TeamStatCard
          title={`Member${memberCount > 1 ? 's' : ''}`}
          value={memberCount ? memberCount : '--'}
          icon="users"
        />
        {/* <TeamStatCard
          value={`${symbol}${
            lastInvoiceAmount === '--'
              ? '--'
              : Number(lastInvoiceAmount).toFixed(2)
          }`}
          title="Last Payment"
          icon="dollar-sign"
        /> */}
        <TeamStatCard
          value={`${moment().diff(
            this.state.joinedAt ? this.state.joinedAt : moment(),
            'days'
          )}`}
          title="Days in Space"
          icon="users"
        />
        <TeamStatCard
          value={confText}
          title={`${moment().format('MMM')} Booking Hours`}
          icon="calendar-alt"
        />
      </div>
    )
  }

  render() {
    const {
      team,
      teamId,
      role,
      user,
      activeCommunity,
      history,
      match,
      activeCampus,
      campus,
      isPosting,
      campuses,
      isFetching,
    } = this.props

    const {
      totalInvoiceAmount,
      lastInvoiceAmount,
      isEditing,
      loading,
      errors,
      photo,
      isInactive,
      joinedAt,
      conferenceHoursAllowance,
      isStaff,
      members,
      shouldRefreshPayments,
      tasks,
      comment,
      showCampusSelectorModal,
      showArchiveModal,
    } = this.state
    const isMyTeam =
      user && user.teams && user.teams.length > 0 && user.teams[0].id === teamId
    const canEdit = Roles.justSpaceStaffRoles.includes(role) || isMyTeam
    const admin =
      team && team.admins && team.admins.length > 0 && team.admins[0]
        ? team.admins[0]
        : null
    const communityHasBilling = Boolean(
      activeCommunity.stripe_account_id && team && team.stripe_customer_id
    )
    if (communityHasBilling) {
      Constants.logO(
        `https://dashboard.stripe.com/${activeCommunity.stripe_account_id}/customers/${team.stripe_customer_id}`
      )
    }
    const canViewEditBilling = Roles.canViewEditBilling.includes(user.type)

    const showBilling =
      communityHasBilling &&
      !isStaff &&
      canEdit &&
      (isMyTeam || canViewEditBilling)

    const { path, url } = match

    return (
      <div className="animated fadeIn">
        <div className="row">
          {/* For some reason, the quicksearch component in teammembership doesn't like index without it here*/}
          <div className="col-md-12 mb-4" style={{ zIndex: 30 }}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: location.pathname.includes('profile'),
                  })}
                  onClick={() => {
                    this.toggleTab('profile')
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      location.pathname.includes('members') &&
                      !location.pathname.includes('membership'),
                  })}
                  onClick={() => {
                    this.toggleTab('members')
                  }}
                >
                  {isStaff ? 'Staff Members' : 'Members'}
                </NavLink>
              </NavItem>
              {showBilling ? (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: location.pathname.includes('membership'),
                    })}
                    onClick={() => {
                      this.toggleTab('membership')
                    }}
                  >
                    {isStaff && 'Staff '}Membership
                  </NavLink>
                </NavItem>
              ) : null}
              {showBilling ? (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: location.pathname.includes('billing'),
                    })}
                    onClick={() => {
                      this.toggleTab('billing')
                    }}
                  >
                    {isStaff && 'Staff '}Billing
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
            <TabContent>
              <Switch>
                <Route exact path={`${path}`}>
                  <Redirect to={`${url}/profile`} />
                </Route>
                <Route path={`${path}/profile`}>
                  {location.pathname.includes('profile') && (
                    <TabPane>
                      {this.renderInactiveAlert()}
                      <div className="row border-bottom-1 my-3">
                        <div className="col-6">
                          {team && team.name ? <h2>{team.name}</h2> : null}
                        </div>
                        <div className="col-6 ">
                          {admin ? (
                            <Row>
                              <div className="col-12 d-flex align-items-center justify-content-end">
                                <h6>
                                  <span className="text-muted">Admin:</span>{' '}
                                  {admin.first_name} {admin.last_name}
                                </h6>
                              </div>
                              <div className="col-12 d-flex align-items-center justify-content-end">
                                <a href={`mailto:${admin.email}`}>
                                  {admin.email}
                                </a>
                              </div>
                            </Row>
                          ) : null}
                        </div>
                      </div>

                      {canEdit &&
                        this.renderTeamWidgets(
                          team,
                          lastInvoiceAmount,
                          totalInvoiceAmount
                        )}
                      {loading ? (
                        <Spinner />
                      ) : (
                        <TeamProfileForm
                          history={history}
                          isEditing={isEditing}
                          isLoading={loading}
                          canEdit={canEdit}
                          isStaff={isStaff}
                          description={team && team.description}
                          website={team && team.website}
                          email={team && team.email}
                          name={team && team.name}
                          conferenceHoursAllowance={
                            team && team.conference_hours_allowance
                          }
                          photo={photo}
                          isInactive={team && team.status !== 'active'}
                          joinedAt={joinedAt}
                          errors={errors}
                          teamIndustries={
                            team && team.team_industry_list
                              ? team.team_industry_list.map((val, index) => ({
                                  label: val,
                                  value: val,
                                }))
                              : []
                          }
                          teamIndustryOptions={
                            activeCommunity.coworks_groups &&
                            activeCommunity.coworks_groups.team_industries &&
                            activeCommunity.coworks_groups.team_industries.tags
                              ? activeCommunity.coworks_groups.team_industries.tags.map(
                                  (permission, index) => ({
                                    label: permission,
                                    value: permission,
                                  })
                                )
                              : []
                          }
                          dynamicTags={
                            team && team.dynamic_tags ? team.dynamic_tags : []
                          }
                          goBack={this.goBack}
                          handleImageUpload={this.handleImageUpload}
                          handleInputChange={this.handleInputChange}
                          editCompany={this.toggleEdit}
                          toggleChangeCampusModal={this.toggleChangeCampusModal}
                          handleJoinedAtChanged={this.handleJoinedAtChanged}
                          activeCommunity={activeCommunity}
                          updateTeam={this.updateTeam}
                          activateTeam={this.toggleShowArchiveModal}
                          deactivateTeam={this.toggleShowArchiveModal}
                        />
                      )}
                    </TabPane>
                  )}
                </Route>
                <Route path={`${path}/members`}>
                  <TabPane>
                    {this.renderInactiveAlert()}
                    <TeamMembers
                      canEdit={canEdit}
                      isInactive={this.state.isInactive}
                      {...this.props}
                    />
                  </TabPane>
                </Route>
                {team && canEdit && Roles.canViewEditBilling && !isStaff ? (
                  <Route path={`${path}/membership`}>
                    {location.pathname.includes('membership') && (
                      <TabPane>
                        {this.renderInactiveAlert()}
                        <TeamMembership
                          team={team}
                          history={history}
                          campusId={activeCampus.id}
                          isInactive={isInactive}
                          toggleShouldRefreshPayments={
                            this.toggleShouldRefreshPayments
                          }
                          campus={campus}
                        />
                      </TabPane>
                    )}
                  </Route>
                ) : null}
                {team &&
                canEdit &&
                showBilling &&
                Roles.canViewEditBilling &&
                !isStaff ? (
                  <Route path={`${path}/billing`}>
                    {location.pathname.includes('billing') && (
                      <TabPane>
                        {this.renderInactiveAlert()}
                        <Billing
                          history={history}
                          match={match}
                          location={location}
                          team={team}
                          shouldRefreshPayments={shouldRefreshPayments}
                          isTeamCharges
                          isInactive={isInactive}
                          handleTotalInvoiceReceived={
                            this.handleTotalInvoiceReceived
                          }
                          handleLastInvoiceReceived={
                            this.handleLastInvoiceReceived
                          }
                        />
                      </TabPane>
                    )}
                  </Route>
                ) : null}
              </Switch>
            </TabContent>
          </div>
          <div className="col-md-12">
            {canEdit && (
              <React.Fragment>
                {isPosting ? (
                  <Spinner />
                ) : (
                  CommentsComponent({
                    tasks: tasks,
                    handleInputChange: this.handleInputChange,
                    comment: comment,
                    surroundWithACard: false,
                    handleSubmitComment: this.handleSubmitComment,
                    loading: this.state.creatingNote,
                  })
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        <CampusSelectorModal
          showModal={showCampusSelectorModal}
          toggleModal={this.toggleChangeCampusModal}
          switchCampus={this.switchCampus}
          campuses={campuses}
          currentCampus={activeCampus}
          disableRows={isFetching}
        />
        {showArchiveModal && (
          <ArchiveModal
            history={this.props.history}
            match={this.props.match}
            type="Team"
            team={team}
            isInactive={team && team.status !== 'active'}
            plans={this.props.plans}
            handleInputChange={this.handleInputChange}
            showArchiveModal={this.state.showArchiveModal}
            toggleShowArchiveModal={this.toggleShowArchiveModal}
            activeCampus={this.props.activeCampus}
            activeCommunity={this.props.activeCommunity}
            handleChangeStatus={this.handleChangeStatus}
          />
        )}
      </div>
    )
  }
}

TeamDetail.displayName = 'TeamDetail'

function mapStateToProps(state, { match, history, team }) {
  let resolvedTeam,
    teamId = null

  if (team) {
    resolvedTeam = team
    teamId = team.id
  } // passed in as a prop (my-team)
  if (
    !teamId &&
    history &&
    history.location &&
    history.location.state &&
    history.location.state.team &&
    history.location.state.team.id
  ) {
    // todo eventually we should allow params.teamId pluck but authorization
    teamId = history.location.state.team.id
  }
  // fallback on finding the team in redux based on the URL params
  if (teamId && !team) {
    let teamLookup = Constants.pluckObjectByID(state.teams.teams, teamId)
    if (!teamLookup) {
      teamLookup = Constants.pluckObjectByID(state.teams.inactive, teamId)
    }
    if (teamLookup) resolvedTeam = teamLookup
  }

  return {
    team: resolvedTeam,
    teamId,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    teams: state.teams.teams,
    inactiveTeams: state.teams.inactive,
    members: memberSelectors.getMemberList(state),
    plans: state.plans,
    user: state.user,
    campuses:
      state.ui.activeCommunity && state.ui.activeCommunity.campuses
        ? state.ui.activeCommunity.campuses
        : [],
    isFetching: state.ui.isFetching,
    isFetchingTeamMembers: resolveFetchingStatus(state, FETCHING_TEAM_MEMBERS),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkin_actions: bindActionCreators(checkinActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
    stripe_actions: bindActionCreators(stripeActions, dispatch),
    task_actions: bindActionCreators(taskActions, dispatch),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamDetail)
)
