/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Row,
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

function Filters({ onChangeSortBy, sortBy, sortByOptions }) {
  // const [showFilters, setShowFilters] = useState(false)
  return (
    <div>
      {/* <div className="col-2">
        <Button
          color="primary"
          className="btn btn-link"
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? 'Hide' : 'Show'}
        </Button>
      </div> */}
      {/* {showFilters && ( */}
      <div>
        {/* <select
          name="sortBy"
          className="form-control"
          value={sortBy}
          onChange={onChangeSortBy}
        ></select> */}
        <UncontrolledDropdown>
          <DropdownToggle caret>
            Sort By
            {sortBy !== null &&
              `: ${
                sortByOptions.filter(option => option.value === sortBy)[0].label
              }`}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu">
            {sortByOptions.map(option => (
              <DropdownItem
                key={option.label}
                onClick={() => onChangeSortBy(option.value)}
              >
                {sortBy === option.value && <FontAwesomeIcon icon={'check'} />}{' '}
                {option.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      {/* )} */}
    </div>
  )
}
Filters.displayName = 'Filters'
export default Filters
