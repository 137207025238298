/* eslint-disable react/prop-types,react/display-name,react/no-multi-comp, brace-style */

import React, { useRef } from 'react'
import { CustomImageUploader } from '@global/Form/FormComponents'
import { withFormik, Field } from 'formik'

function BrandingPhotos({ disabled }) {
  // refs are being weird so this is what works!
  const usersRef = useRef('user_placeholder')
  const teamRef = useRef('team_placeholder')

  const resourceHeaderRef = useRef('whitelabel_background_resource_header')
  const loginBackgroundRef = useRef('whitelabel_background_login')

  return (
    <React.Fragment>
      <h3>Directory Placeholders</h3>
      <br />
      <Field
        name="user_placeholder"
        ref={usersRef}
        disabled={disabled}
        label="Member Placeholder"
        description="The placeholder in the directory for members when no photo uploaded"
        component={CustomImageUploader}
      />

      <Field
        name="team_placeholder"
        ref={teamRef}
        disabled={disabled}
        label="Team Placeholder"
        description="The placeholder in the directory for teams when no team photo uploaded"
        component={CustomImageUploader}
      />
      {/* <br />
      <h3>Mobile App</h3>
      <Field
        name="whitelabel_background_resource_header"
        ref={resourceHeaderRef}
        label="Resources Tab Header"
        disabled={disabled}
        aspectRatio={18 / 9} // iphone 10 // todo aspectRatio is not working!
        description="Will be the header image in the resources tab of the mobile app"
        component={CustomImageUploader}
      />

      <Field
        name="whitelabel_background_login"
        ref={loginBackgroundRef}
        aspectRatio={18 / 9} // iphone 10 // todo aspectRatio is not working!
        disabled={disabled}
        label="Login Background Image"
        description="Will be the login background image of the mobile app"
        component={CustomImageUploader}
      /> */}
    </React.Fragment>
  )
}
export default BrandingPhotos
