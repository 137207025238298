import React, { useState } from 'react'
import { toast } from 'react-toastify'

import * as campusActions from '@reduxActions/campusActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as uiActions from '@reduxActions/uiActions'
import PropTypes from 'prop-types'
import Spinner from '@global/Spinner'
import { checkDoorProvider } from '@components/Community/Integrations/IntegrationConstants'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import classnames from 'classnames'
import { Switch, Route, withRouter } from 'react-router'

import * as Yup from 'yup'
import {
  Button,
  Row,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'

import CampusProfileFields from './CampusProfileFields'
import CampusSettingsFields from './CampusSettingsFields'

export const CampusDetail = ({
  campus,
  isFetching,
  history,
  match,
  campus_actions,
  ui_actions,
  activeCampus,
  activeCommunity,
  integrations,
}) => {
  // this allows us to make a call to /campuses/id/edit and this form will respond with the state.
  const shouldEdit =
    match &&
    match.params &&
    match.params.formState &&
    match.params.formState === 'edit'

  const isCreate = history.location.pathname.includes('/add')
  let activeTab = 'profile'
  if (isCreate) {
    activeTab = null
  } else if (history.location.pathname.includes('settings')) {
    activeTab = 'settings'
  }

  const [isEditing, setIsEditing] = useState((campus && shouldEdit) || !campus)

  const saveCampus = (values, setSubmitting) => {
    // while campus prefs are small, we're going to just send up the whole thing!
    const newCampusPreferenceValues = values.preferences // {}

    if (
      values.preferences.bookings.after_padding_time_in_mins &&
      !isNaN(values.preferences.bookings.after_padding_time_in_mins)
    ) {
      newCampusPreferenceValues.bookings.after_padding_time_in_mins = parseInt(
        newCampusPreferenceValues.bookings.after_padding_time_in_mins,
        10
      )
    }
    if (
      values.preferences.bookings.min_advance_in_mins &&
      !isNaN(values.preferences.bookings.min_advance_in_mins)
    ) {
      newCampusPreferenceValues.bookings.min_advance_in_mins = parseInt(
        newCampusPreferenceValues.bookings.min_advance_in_mins,
        10
      )
    }
    const campusData = {
      name: values.name,
      description: values.description ? values.description : '',
      address1: values.address1 ? values.address1 : '',
      address2: values.address2 ? values.address2 : '',
      city: values.city ? values.city : '',
      state: values.state ? values.state : '',
      zip: values.zip ? values.zip : '',
      country: values.country ? values.country : '',
      phone: values.phone ? values.phone : '',
      rent_cost: values.rentCost ? values.rentCost : '',
      //currency_units: values.currencyUnits ? values.currencyUnits : 'USD',
      campus_size: values.campusSize ? values.campusSize : 0,
      area_units: values.areaUnits ? values.areaUnits : '',
      dedicated_desks: values.dedicatedDesks ? values.dedicatedDesks : '',
      business_hours: values.businessHours ? values.businessHours : '',
      suites: values.suites ? values.suites : '',
      place_id: values.placeID ? values.placeID : '',
      preferences: JSON.stringify({
        ...newCampusPreferenceValues,
      }),
    }

    if (values.photo && !values.photo.includes('http')) {
      campusData.campus_photo = values.photo
    }
    if (campus) {
      campusData.id = campus.id
      // editing a campus
      campus_actions
        .updateCampus(campusData)
        .then(response => {
          toast.success('Updated the campus')
          setSubmitting(false)
        })
        .catch(err => {
          setSubmitting(false)
          if (err) {
            // this.setState({ errors: err })
            toast.error(err.message)
          } else {
            toast.error('There was an error updating the campus.')
          }
        })
    } else {
      // creating a new campus
      campusData.community_id = activeCommunity.id
      campus_actions
        .createCampus(campusData)
        .then(() => {
          toast.success('Created the campus')
          history.goBack()
        })
        .catch(err => {
          setSubmitting(false)
          if (err) {
            // this.setState({ errors: err })
            toast.error(err.message)
          } else {
            toast.error('There was an error creating the campus.')
          }
        })
    }
  }
  const handleCancelButtonClicked = resetForm => {
    resetForm()
    if (!campus) {
      history.goBack()
    } else {
      setIsEditing(false)
    }
  }

  const toggleTab = tab => {
    history.push(`${match.url}/${tab}`, { campus: campus })
  }

  function wrapInSwitch(component) {
    if (!isCreate) {
      return <Switch>{component}</Switch>
    }
    return component
  }
  function wrapInRoute(route, component) {
    if (!isCreate) {
      return (
        <Route path={`${match.path}/${route}`}>
          <TabPane tabId={route}>{component}</TabPane>
        </Route>
      )
    }
    return component
  }
  const resolvePlaceId = () => {
    let placeId = ''
    if (
      campus &&
      campus.integration_ids &&
      campus.integration_ids.door_access
    ) {
      placeId = campus.integration_ids.door_access.kisi_place_id
      if (!placeId) {
        placeId = campus.integration_ids.door_access.salto_place_id
      }
    }
    return placeId
  }

  if (isFetching) {
    return <Spinner />
  }
  // const hasDoorAccess = checkDoorProvider(integrations, activeCampus)
  let hasDoorAccessActivated = false

  if (integrations && integrations.active) {
    hasDoorAccessActivated =
      integrations.active.filter(
        object => object.type === 'kisi' || object.type === 'salto'
      ).length > 0
  }
  const image =
    campus && campus.campus_photo ? campus.campus_photo.small_file_url : null
  const placeID = resolvePlaceId()

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          name: campus && campus.name ? campus.name : '',
          description: campus && campus.description ? campus.description : '',
          address1: campus && campus.address1 ? campus.address1 : '',
          address2: campus && campus.address2 ? campus.address2 : '',
          city: campus && campus.city ? campus.city : '',
          state: campus && campus.state ? campus.state : '',
          zip: campus && campus.zip ? campus.zip : '',
          country: campus && campus.country ? campus.country : '',
          phone: campus && campus.phone ? campus.phone : '',
          photo: campus && campus.campus_photo ? campus.campus_photo : null,
          areaUnits: campus && campus.area_units ? campus.area_units : '',
          campusSize: campus && campus.campus_size ? campus.campus_size : 0,
          rentCost: campus && campus.rent_cost ? campus.rent_cost : 0,
          //currencyUnits: campus && campus.currency_units ? campus.currency_units : 'USD',
          dedicatedDesks:
            campus && campus.dedicated_desks ? campus.dedicated_desks : 0,
          suites: campus && campus.suites ? campus.suites : 0,
          businessHours:
            campus && campus.business_hours ? campus.business_hours : '',
          placeID,
          preferences:
            campus && campus.preferences
              ? campus.preferences
              : {
                  hours_of_operation: {
                    monday: [{ open: 32400, close: 61200 }], // 9-5
                    tuesday: [{ open: 32400, close: 61200 }], // 9-5
                    wednesday: [{ open: 32400, close: 61200 }], // 9-5
                    thursday: [{ open: 32400, close: 61200 }], // 9-5
                    friday: [{ open: 32400, close: 61200 }], // 9-5
                    saturday: [{ open: 0, close: 0 }], // not open
                    sunday: [{ open: 0, close: 0 }], // not open,
                  },
                  bookings: {
                    min_advance_in_mins: undefined,
                    after_padding_time_in_mins: undefined,
                  },
                },
        }}
        onSubmit={(values, { setSubmitting }) => {
          saveCampus(values, setSubmitting)
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required'),
          placeID: Yup.number('The place ID is a 4 digit number')
            .typeError('The place ID is a 4 digit number')
            .min(1000, 'The place ID is a 4 digit number')
            .max(9999, 'The place ID is a 4 digit number')
            .integer('The place ID is a 4 digit positive number'),
          preferences: Yup.object().test(
            'preferences-test',
            'All preferences must be valid.',
            // eslint-disable-next-line func-names
            function (preferences, context) {
              // Hours of operation testing
              let isValid = true
              let day = ''
              Object.keys(preferences.hours_of_operation).forEach(dayKey => {
                const obj = preferences.hours_of_operation[dayKey]
                // if open comes before closed
                if (obj.length === 1 && obj[0].open > obj[0].close) {
                  isValid = false
                  day = dayKey
                }
              })
              if (!isValid) {
                return this.createError({
                  message: `Hours of operation start time on ${day} must be before the end time!`,
                }) // isntead of returning false, you can return custom error messages
              }
              if (preferences.bookings) {
                if (preferences.bookings.min_advance_in_mins) {
                  if (isNaN(preferences.bookings.min_advance_in_mins)) {
                    return this.createError({
                      message: 'Minimum lead time in minutes must be a number.',
                    }) // isntead of returning false, you can return custom error messages
                  } else if (
                    parseInt(preferences.bookings.min_advance_in_mins, 10) <= 0
                  ) {
                    return this.createError({
                      message:
                        'Minimum lead time in minutes must be a number larger than 0.',
                    }) // instead of returning false, you can return custom error messages
                  }
                }
                if (preferences.bookings.after_padding_time_in_mins) {
                  if (isNaN(preferences.bookings.after_padding_time_in_mins)) {
                    return this.createError({
                      message: 'Booking padding time must be a number.',
                    }) // isntead of returning false, you can return custom error messages
                  } else if (
                    parseInt(
                      preferences.bookings.after_padding_time_in_mins,
                      10
                    ) <= 0
                  ) {
                    return this.createError({
                      message:
                        'Booking padding time must be a number larger than 0.',
                    }) // isntead of returning false, you can return custom error messages
                  }
                }
              }
              //
              return true // valid
            }
          ),
        })}
      >
        {({ values, resetForm, setFieldValue, errors }) => (
          <Form className="form-container" id="global-image-form">
            {!isCreate && (
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 'profile',
                    })}
                    onClick={() => {
                      toggleTab('profile')
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 'settings',
                    })}
                    onClick={() => {
                      toggleTab('settings')
                    }}
                  >
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>
            )}
            <TabContent activeTab={activeTab}>
              {wrapInSwitch(
                <div className="animated fadeIn">
                  <div id="new-campus-form">
                    <div className="card-block">
                      <div className="row">
                        <div className="col-6">
                          {!isCreate && campus ? (
                            <h2>
                              {campus.name} {activeTab}
                            </h2>
                          ) : null}
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                          {isEditing ? (
                            <span className="text-muted">
                              {campus && 'Editing'}
                            </span>
                          ) : (
                            <Button
                              color="outline-primary"
                              type="button"
                              onClick={() => setIsEditing(true)}
                            >
                              Edit
                            </Button>
                          )}
                        </div>

                        <div className="col-12">
                          {wrapInRoute(
                            'profile',
                            <CampusProfileFields
                              isEditing={isEditing}
                              errors={errors}
                              photo={values.photo}
                            />
                          )}
                        </div>
                        <div className="col-12 col-md-8 offset-md-1">
                          {wrapInRoute(
                            'settings',
                            <CampusSettingsFields
                              isEditing={isEditing}
                              errors={errors}
                              showDoorAccessFields={hasDoorAccessActivated}
                              preferences={values.preferences}
                            />
                          )}
                        </div>
                        <div className="col-12 col-md-8 offset-md-3">
                          <div className="row my-1">
                            <div className="col-6" />
                            <div className="col-6 d-flex justify-content-end">
                              {isEditing && (
                                <Button
                                  type="button"
                                  className="btn btn-secondary mr-2"
                                  onClick={() =>
                                    handleCancelButtonClicked(resetForm)
                                  }
                                >
                                  Cancel
                                </Button>
                              )}
                              {isEditing && (
                                <Button color="primary" type="submit">
                                  Save
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </TabContent>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

CampusDetail.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  campus: PropTypes.object,
  isFetching: PropTypes.bool,
  campus_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  integrations: PropTypes.object,
}
CampusDetail.displayName = 'CampusDetail'

function mapStateToProps(state, ownProps) {
  let campusId =
    ownProps.history &&
    ownProps.history.location &&
    ownProps.history.location.state &&
    ownProps.history.location.state.campus
      ? ownProps.history.location.state.campus.id
      : null

  if (
    !campusId &&
    ownProps.match &&
    ownProps.match.params &&
    ownProps.match.params.campusId &&
    !NaN(ownProps.match.params.campusId) // might be "campuses/new"
  ) {
    campusId = Number(ownProps.match.params.campusId)
  }
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetching: state.ui.isFetching,
    campus: state.ui.activeCommunity.campuses.find(
      campus => campus.id === campusId
    ),
    integrations: state.integrations,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    campus_actions: bindActionCreators(campusActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CampusDetail))
