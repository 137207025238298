import React from 'react';
import PropTypes from 'prop-types';
import Hand from '../../img/mobile/hand-coworks.png';
import MobileLandingBackground from '../../img/mobile/mobile-landing-bkg-small-trans.png';
import GooglePlayButton from './../../img/mobile/play-store-small.png';
import AppleStoreButton from './../../img/mobile/app-store-small.png';


export default class MobileApp extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  }


  render() {
    return (
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-sm-12 col-md-12'>
            <div className='card'>
              <div className='card-header'>
                <div className='row'>
                  <div className='col-10'>
                    <h2>Mobile Apps</h2>
                  </div>
                </div>
              </div>
              <div className='card-block p-0' style={{ backgroundImage: `url(${MobileLandingBackground})`, backgroundSize: 'cover',
                color: 'white' }}>
                  <div className='row p-0 m-0'>
                    <div className='col-6'>
                      <img className='img-responsive' src={Hand} />
                    </div>
                    <div className='col-6 d-flex-column'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2 className='text-center d-block mx-auto'>Ready to try Coworks Mobile?</h2>
                        </div>
                        <div className='col-12'>
                          <h5 className=' text-center d-block mx-auto'>Download now!</h5>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <img src={AppleStoreButton} className='img-fluid'/>
                        </div>
                        <div className='col'>
                          <img src={GooglePlayButton} className='img-fluid' />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MobileApp.displayName = 'MobileApp';
