import React from 'react'
import { TabContent, TabPane } from 'reactstrap'

import UserPreferences from '@components/External/Preferences/UserPreferences'

export default function MyPreferences(props) {
  return (
    <TabContent>
      <TabPane>
        <UserPreferences {...props} />
      </TabPane>
    </TabContent>
  )
}

MyPreferences.displayName = 'MyPreferences'
