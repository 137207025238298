/* eslint-disable react/display-name */
import React from 'react'
import './config/why-did-you-render'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
//import { IntlProvider } from 'react-intl';
import { ThemeProvider, withTheme } from 'styled-components'
import './styles/styles.scss'
import theme from './theme/Theme'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './styles/react-calendar-overrides.css'
import App from './App'

import { createBrowserHistory } from 'history'

// import * as serviceWorker from './config/registerServiceWorker'

import store from './store/store'
import './config/enzyme'

if (process.env.NODE_ENV === 'development' || !process.env.NODE_ENV) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  console.log('init why did you render')
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  // })
}

createBrowserHistory()

//addLocalizationData();
//const locale = 'en'; // this will need to come from redux eventually.
//const messages = getLocaleMessages(locale);
const render = App => {
  return ReactDOM.render(
    // <IntlProvider locale={locale} messages={messages}> todo this breaks the tablets for now.
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>,
    //</IntlProvider>`
    document.getElementById('root')
  )
}

render(App)

// Hot module reloading!
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store
}
