import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import designTokens from '@app/theme/designTokens'

const SuccessCardStyled = styled.div.attrs(props => ({
  className: props.inCard ? 'card p-5' : '',
}))`
  margin: auto;
  width: 95%;
  max-width: 550px;
`

export default function SuccessCard({
  shouldRedirectSuccess,
  renderGoBack,
  confirmationMessage,
  primaryColor,
  successColor = designTokens.fonts.textSuccess.color,
  headerMessage = 'Success',
  inCard = true,
}) {
  return (
    <SuccessCardStyled inCard={inCard}>
      <Row>
        <div className="col-12 d-flex align-items-center justify-content-center">
          <h1
            style={{
              fontWeight: 'bold',
            }}
          >
            <FontAwesomeIcon
              icon={['far', 'check-circle']}
              color={successColor}
            />{' '}
            {headerMessage}
          </h1>
        </div>
      </Row>
      <Row>
        <div className="col-12 d-flex align-items-center justify-content-center">
          <br />
          <br />
          {confirmationMessage}
        </div>
      </Row>

      {renderGoBack && (
        <Row>
          <div className="col-12">
            {shouldRedirectSuccess ? (
              <div>Redirecting...</div>
            ) : (
              renderGoBack(primaryColor)
            )}
          </div>
        </Row>
      )}
    </SuccessCardStyled>
  )
}

SuccessCard.propTypes = {
  shouldRedirectSuccess: PropTypes.bool,
  renderGoBack: PropTypes.func,
  confirmationMessage: PropTypes.string,
  primaryColor: PropTypes.string,
  successColor: PropTypes.string,
  headerMessage: PropTypes.string,
  inCard: PropTypes.bool,
}
SuccessCard.displayName = 'SuccessCard'
