import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled from 'styled-components'
import renderHTML from 'react-render-html'
import EmailMessagePreview from './Preview/EmailMessagePreview'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import './announcementForm.scss'
import moment from 'moment'
import Spinner from '@global/Spinner'
import * as apiService from '../../services/apiService'
import IOSPushNotificationPreview from './Preview/IOSPushNotificationPreview'
import IOSSMSPreview from './Preview/IOSSMSPreview'

const CardStyled = styled.div`
  overflow: hidden;
`

class ReviewModal extends Component {
  static defaultProps = {
    type: 'Email',
    sendAsSMS: false,
    sendAsEmail: false,
    editMode: false,
  }
  static propTypes = {
    sendToAll: PropTypes.bool,
    editMode: PropTypes.bool,
    showReviewModal: PropTypes.bool,
    sendAsSMS: PropTypes.bool,
    toggleReviewModal: PropTypes.func,
    sendMessage: PropTypes.func,
    recipients: PropTypes.array,
    from: PropTypes.string,
    subject: PropTypes.string,
    header: PropTypes.string,
    message: PropTypes.string,
    greeting: PropTypes.string,
    sentDate: PropTypes.object,
    sentByUser: PropTypes.object,
    deleteRecipient: PropTypes.func,
    fetchRecipients: PropTypes.func,
    recipientCount: PropTypes.number,
    announcementId: PropTypes.number,
    submitButton: PropTypes.object,
    activeCommunity: PropTypes.object,
    activeCampus: PropTypes.object,
    type: PropTypes.string.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      recipient: true,
      showRecipientModal: false,
      //   recipients: props.editMode ? props.recipients : props.message.recipients,
      recipients: props.recipients,
      recipientCount: props.recipients.length,
      //   recipientCount: props.editMode
      //     ? props.recipients.length
      //     : props.message.recipient_count,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //todo replace with static getDerivedStateFromProps(props, state) {
    this.setState({
      recipients: nextProps.recipients,
    })
  }

  toggleRecipientModal = () => {
    this.setState(
      {
        showRecipientModal: !this.state.showRecipientModal,
      },
      () => {
        if (!this.state.editMode && this.state.showRecipientModal) {
          this.fetchRecipients()
        }
      }
    )
  }

  fetchRecipients = () => {
    this.setState({
      recipient: true,
    })
    apiService
      .getAnnouncementRecipients(this.props.announcementId)
      .then(response => {
        this.setState({
          recipients: response.recipients,
          recipient: false,
        })
      })
      .catch(err => {
        this.setState({
          recipient: false,
        })
      })
  }

  renderEmailButton = (recipient, inList = false) => {
    return this.props.editMode ? (
      <Button
        key={recipient.id}
        color="primary"
        className={`email-review-${inList ? 'list-' : ''}recipient`}
        onClick={() => this.props.deleteRecipient(recipient)}
      >
        <FontAwesomeIcon icon={'times'} className="mr-1" />{' '}
        {`${recipient.first_name} ${recipient.last_name} (${recipient.email})`}
      </Button>
    ) : (
      <Button
        key={recipient.id}
        color="primary"
        className={`email-review-${inList ? 'list-' : ''}recipient`}
      >
        <FontAwesomeIcon className="mr-1" />{' '}
        {`${recipient.first_name} ${recipient.last_name} (${recipient.email})`}
      </Button>
    )
  }

  renderAllRecipients = () => {
    if (this.state.recipient) {
      return <Spinner />
    }
    if (this.state.recipients) {
      return this.state.recipients.map((recipient, index) => {
        return this.renderEmailButton(recipient, true)
      })
    }
    return []
  }

  renderSomeRecipients = () => {
    if (this.props.sendToAll) {
      return <p className="email-review-subject">All</p>
    }
    const recipients = []
    if (this.props.recipients && this.props.recipients.length > 0) {
      for (const [index, recipient] of this.props.recipients.entries()) {
        if (index > 2) {
          break
        }
        recipients.push(this.renderEmailButton(recipient))
      }
    }
    return recipients
  }

  renderMemberCount = () => {
    if (
      (this.props.recipients && this.props.recipients.length > 3) ||
      this.props.recipientCount > 3
    ) {
      const recipientCount = this.props.editMode
        ? this.props.recipients.length
        : this.props.recipientCount
      return (
        <Button
          className="email-recipient-count primary-color"
          onClick={this.toggleRecipientModal}
        >
          + {recipientCount - 3} more
        </Button>
      )
    }
    return null
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showReviewModal}
        toggle={this.props.toggleReviewModal}
        size="lg"
      >
        <ModalHeader toggle={this.props.toggleReviewModal}>{`${
          this.props.editMode ? 'Review Announcement' : 'View Announcement'
        }`}</ModalHeader>
        <ModalBody>
          {this.props.type === 'Email' ? (
            <React.Fragment>
              <span className="email-review-label">From:</span>
              <p className="email-review-subject">{this.props.from}</p>
              <span className="email-review-label">To:</span>
              <div className="email-review-recipient-wrapper">
                {this.renderSomeRecipients()}
              </div>
              <div className="member-count-row">{this.renderMemberCount()}</div>
              <span className="email-review-label">Subject:</span>
              <p className="email-review-subject">{this.props.subject}</p>
              <span className="email-review-label">Message:</span>
              <CardStyled className="card">
                <EmailMessagePreview
                  header={this.props.header}
                  body={this.props.message}
                  greeting={this.props.greeting}
                  campusPhoto={
                    this.props.activeCampus &&
                    this.props.activeCampus.campus_photo
                      ? this.props.activeCampus.campus_photo.medium_file_url
                      : null
                  }
                  preferences={this.props.activeCommunity.community_preference}
                />
                {!this.props.editMode ? (
                  <span className="email-review-label">
                    Sent By:{' '}
                    {`${this.props.sentByUser.first_name} ${this.props.sentByUser.last_name} -`}{' '}
                    {moment(this.props.sentDate).format('MMM DD, YY hh:mm a')}
                  </span>
                ) : null}
              </CardStyled>
            </React.Fragment>
          ) : (
            <div className="col-12">
              <span className="email-review-label">To:</span>
              <div className="email-review-recipient-wrapper">
                {this.renderSomeRecipients()}
              </div>
              <div className="member-count-row">{this.renderMemberCount()}</div>
              <h3 className="mt-3">Push Preview</h3>
              {IOSPushNotificationPreview({
                title: 'Coworks App',
                timing: 'NOW',
                messageTitle: this.props.header,
                message: this.props.message,
              })}
              {this.props.sendAsSMS && (
                <React.Fragment>
                  <h3 className="mt-3">Text Preview</h3>
                  {IOSSMSPreview({
                    title: this.props.header,
                    fromMe: true,
                    message: this.props.message,
                  })}
                </React.Fragment>
              )}
            </div>
          )}
          <Modal
            isOpen={this.state.showRecipientModal}
            toggle={this.toggleRecipientModal}
          >
            <ModalHeader>All Recipients</ModalHeader>
            <ModalBody>
              <div className="card p-4">
                <div className="email-review-all-recipient-wrapper">
                  {this.renderAllRecipients()}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </ModalBody>
        <ModalFooter>
          {this.props.submitButton}
          <Button color="secondary" onClick={this.props.toggleReviewModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ReviewModal.defaultProps = {
  editMode: true,
}

ReviewModal.displayName = 'Review Modal'

export default ReviewModal
