import * as Constants from '@global/Constants'
import { FORM_TYPE_TEAM, FORM_TYPE_GUEST } from './CreateInvoice'

const validate = values => {
  const errors = {}

  // Primary validation
  if (values.formType === FORM_TYPE_GUEST) {
    if (!values.email) {
      errors.email = 'Email is required'
    } else if (!Constants.validateEmail(values.email)) {
      errors.email = 'Invalid email address'
    }
  } else if (values.formType === FORM_TYPE_TEAM) {
    if (!values.team) {
      errors.team = 'Team is required'
    }
  }

  //Validation for the invoice items array=
  for (let index = 0; index < values.invoiceItemFieldArray.length; index++) {
    const invoiceRow = values.invoiceItemFieldArray[index]
    const amountKey = `amount${index}`
    const descriptionKey = `description${index}`
    const quantityKey = `quantity${index}`
    // validate amount
    if (!invoiceRow.amount) {
      errors[amountKey] = 'Unit Price is required'
    } else if (invoiceRow.amount < 0.01) {
      errors[amountKey] = 'Unit Price must be 0.01 or larger'
    } else if (invoiceRow.amount > 100000) {
      errors[amountKey] =
        'Unit Prices that are above 100,000 must contact support'
    } else if (typeof invoiceRow.amount !== 'number') {
      errors[amountKey] = 'Unit Prices must be a number'
    }
    // validate quantity
    if (!invoiceRow.quantity) {
      errors[quantityKey] = 'Required'
    } else if (invoiceRow.quantity < 0) {
      errors[quantityKey] = 'Must be 1 or larger.'
    } else if (invoiceRow.quantity > 100000) {
      errors[quantityKey] = 'Quantities that are above 100,000 contact support'
    } else if (typeof invoiceRow.quantity !== 'number') {
      errors[quantityKey] = 'Quantity must be a number'
    } else if (!Number.isInteger(invoiceRow.quantity)) {
      errors[quantityKey] = 'Quantity must be a whole number'
    }

    // validate description
    if (!invoiceRow.description) {
      errors[descriptionKey] = 'Description is required'
    }
  }
  return errors
}

export { validate }
