import React from 'react'
import PropTypes from 'prop-types'

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { CustomInputComponent } from '@global/Form/FormComponents'
import Checkbox from '@bit/coworks.base-components.checkbox'
import { LabelStyled } from '@global/Form/FormComponents'
import styled from 'styled-components'
import { renderDateTimeStamp } from '@global/Constants/DateConstants'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const RowStyled = styled.div.attrs(() => ({
  className: 'row',
}))``
const ColumnStyled = styled.div.attrs(() => ({
  className: 'col d-flex',
}))`
  flex-flow: column wrap;
`
const Container = styled.div.attrs(() => ({
  className: 'container-fluid',
}))``
const TopLabel = styled(LabelStyled)`
  display: block;
`

export default function SplitInvoiceModal({
  showSplitInvoiceModal,
  toggleSplitInvoiceModal,
  splitInvoice,
  isFetching,
  invoice,
  currencySymbol,
}) {
  const invoiceAmount = invoice ? invoice.total : 0
  const calcRemaining = amount => {
    const split1 = amount ? parseFloat(amount) * 100 : 0
    const difference = invoiceAmount - split1
    return (difference >= 0 ? difference : 0) / 100.0
  }
  return (
    <Modal
      isOpen={showSplitInvoiceModal}
      toggle={toggleSplitInvoiceModal}
      className="modal-primary fade in"
    >
      <Formik
        initialValues={{
          amount: undefined,
          finalize: false,
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.number()
            .typeError('Amount must be a number')
            .min(0.01, 'Amount must be greater than or equal to 0.01')
            .max(
              invoiceAmount / 100.0,
              'Amount cannot exceed the original invoice total'
            )

            .required('Amount is required'),
        })}
        onSubmit={(values, actions) => {
          splitInvoice(values)
        }}
      >
        {({ values, setFieldValue, errors, isValid, dirty }) => (
          <Form>
            <ModalHeader toggle={toggleSplitInvoiceModal}>
              Split Invoice
            </ModalHeader>
            <ModalBody>
              <Container>
                <RowStyled>
                  <ColumnStyled>
                    This will split the selected invoice into two separate
                    invoices.
                  </ColumnStyled>
                </RowStyled>
                <br />
                <RowStyled>
                  <ColumnStyled>
                    <TopLabel>Original Invoice</TopLabel>
                    <p>{`${currencySymbol}${invoiceAmount / 100} due on ${
                      invoice
                        ? renderDateTimeStamp(invoice.due_date, false)
                        : 'N/A'
                    }`}</p>
                  </ColumnStyled>
                </RowStyled>
                <RowStyled>
                  <ColumnStyled>
                    <Field
                      label="Invoice 1"
                      name="amount"
                      placeholder="0.00"
                      component={CustomInputComponent}
                    />
                  </ColumnStyled>
                  <ColumnStyled>
                    <TopLabel>Invoice 2 (Remainder)</TopLabel>
                    <span>{`${currencySymbol}${calcRemaining(
                      values.amount
                    ).toFixed(2)}`}</span>
                  </ColumnStyled>
                </RowStyled>
                <RowStyled>
                  <ColumnStyled>
                    <Checkbox
                      name="finalize"
                      label="Finalize Immediately"
                      description="This will email the invoices immediately"
                      selected={values.finalize}
                      handleChange={() =>
                        setFieldValue('finalize', !values.finalize)
                      }
                      error={errors.finalize}
                    />
                  </ColumnStyled>
                </RowStyled>
                <br />
                <span className="text-muted">
                  Note: The original invoice will be closed as a result of this
                  action.
                </span>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleSplitInvoiceModal}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={!(isValid && dirty) || isFetching}
              >
                {isFetching && <FontAwesomeIcon icon={'circle-notch'} spin />}{' '}
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
SplitInvoiceModal.displayName = 'SplitInvoiceModal'
SplitInvoiceModal.propTypes = {
  showSplitInvoiceModal: PropTypes.bool,
  toggleSplitInvoiceModal: PropTypes.func,
  splitInvoice: PropTypes.func,
  isFetching: PropTypes.bool,
  invoice: PropTypes.object,
  currencySymbol: PropTypes.string,
}
