/* eslint-disable react/prop-types,react/display-name,react/no-multi-comp,max-params */
import React from 'react'
import PropTypes from 'prop-types'
import Async from 'react-select/async'
import Creatable from 'react-select/async-creatable'
import { components } from 'react-select'
import { withTheme } from 'styled-components'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const search = (inputValue, campusId, actions, dataResolver, otherParams) => {
  // if (inputValue === '') {
  //   return []
  // }
  return actions
    .search(campusId, {
      search_string: inputValue,
      ...otherParams,
    })
    .then(response => {
      return dataResolver(response)
    })
    .catch(err => {
      console.error(err)
    })
}

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {props.selectProps.renderSingleValue(props.data)}
  </components.SingleValue>
)

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon="search" />
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  )
}
const LoadingIndicator = () => {
  return <FontAwesomeIcon icon={'circle-notch'} spin />
}

const searchStyles = {
  // option: (styles, { data, isDisabled, isFocused, isSelected }) => { },
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
}

const GroupHeading = props => {
  const groupStyles = {
    border: `2px dotted red`,
    color: 'white',
    background: 'red',
    padding: '5px 0px',
    display: 'flex',
  }
  return (
    <div style={groupStyles}>
      <components.GroupHeading {...props} />
      Something here!
      <button />
    </div>
  )
}
class CoworksSearch extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    activeCampus: PropTypes.object,
    defaultData: PropTypes.array,
    actions: PropTypes.object.isRequired,
    handleItemSelected: PropTypes.func,
    handleSearchCleared: PropTypes.func,
    controlShouldRenderValue: PropTypes.bool,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    dataResolver: PropTypes.func,
    renderSearchRow: PropTypes.func.isRequired,
    isClearable: PropTypes.bool,
    value: PropTypes.object,
    renderSingleValue: PropTypes.func.isRequired,
    otherParams: PropTypes.object,
    disabled: PropTypes.bool,
    runSearchAndAttemptMatchValue: PropTypes.string,
    isCreatable: PropTypes.bool, // uses the createable component
    onCreateOption: PropTypes.func, // only createable
    clearOnSelect: PropTypes.bool, // uses the createable component
    colorRowHover: PropTypes.string,
    colorRowSelected: PropTypes.string,
  }
  static defaultProps = {
    disabled: false,
    isSearch: true,
    isCreatable: false,
    clearOnSelect: false,
  }

  componentDidMount() {
    const {
      runSearchAndAttemptMatchValue,
      activeCampus,
      actions,
      dataResolver,
      handleItemSelected,
      otherParameters,
    } = this.props
    if (runSearchAndAttemptMatchValue && activeCampus) {
      const result = this.interceptAndDelaySearch(
        runSearchAndAttemptMatchValue,
        activeCampus.id,
        actions,
        response => {
          const array = dataResolver(response)
          if (array.length === 1) {
            handleItemSelected(array[0])
          }
        },
        otherParameters
      )
    }
  }
  _onSelectChanged = (object, { action }) => {
    const { handleItemSelected, handleSearchCleared } = this.props
    switch (action) {
      case 'input-change':
        if (handleItemSelected && object) {
          handleItemSelected(object)
        }
        return
      // case 'menu-close':
      //   let menuIsOpen
      //   if (this.props.value) {
      //     menuIsOpen = true
      //   }
      //   this.setState({
      //     menuIsOpen,
      //   })
      //   return
      case 'clear':
        if (handleSearchCleared) {
          handleSearchCleared()
        }
        return
      case 'select-option':
        if (handleItemSelected && object) {
          handleItemSelected(object)
        }
        return

      default:
        return
    }
  }
  checkNull(variable, defaultValue) {
    if (variable === null || variable === undefined) {
      // eslint-disable-next-line no-param-reassign
      variable = defaultValue
    }
    return variable
  }
  applyThemeColors = existingTheme => {
    const { theme, colorRowHover, colorRowSelected } = this.props
    return {
      ...existingTheme,
      borderRadius: 0,
      colors: {
        ...existingTheme.colors,
        primary25: colorRowHover
          ? colorRowHover
          : theme.colorPrimaryHoverSearchRowHighlight,
        primary: colorRowSelected ? colorRowSelected : theme.colorPrimaryHover,
      },
    }
  }
  interceptAndDelaySearch = (
    searchTerm,
    campusId,
    actions,
    dataResolver,
    otherParams
  ) => {
    clearTimeout(this.search)
    return new Promise((resolve, reject) => {
      this.search = setTimeout(() => {
        resolve(
          search(searchTerm, campusId, actions, dataResolver, otherParams)
        )
      }, 600)
    })
  }
  isOptionSelected = option => {
    const value = this.props.value
    if (value) {
      // check if we're dealing with a coworks model
      if (
        option.hasOwnProperty('id') &&
        value.hasOwnProperty('id') &&
        option.id === value.id
      ) {
        return true
      } else if (
        // check if we're dealing with a {label, value} object
        option.hasOwnProperty('value') &&
        value.hasOwnProperty('value') &&
        option.value === value.value
      ) {
        return true
      }
    }
    return false
  }
  render() {
    const {
      id,
      actions,
      activeCampus,
      controlShouldRenderValue,
      autoFocus,
      placeholder,
      defaultData,
      dataResolver,
      renderSearchRow,
      value,
      renderSingleValue,
      otherParams,
      disabled,
      isSearch,
      noOptionsMessage,
      theme,
      isCreatable,
      onCreateOption,
    } = this.props
    let { isClearable } = this.props
    isClearable = this.checkNull(isClearable, false)
    const otherParameters = otherParams ? otherParams : {}
    const selectComponents = isSearch
      ? { DropdownIndicator, LoadingIndicator }
      : { LoadingIndicator }

    if (renderSingleValue) {
      selectComponents.SingleValue = SingleValue
    }

    return !isCreatable ? (
      <Async
        id={id}
        renderSingleValue={renderSingleValue}
        onChange={this._onSelectChanged}
        components={selectComponents}
        cacheOptions
        value={value}
        isDisabled={disabled}
        autoFocus={autoFocus}
        placeholder={placeholder}
        isOptionSelected={this.isOptionSelected}
        controlShouldRenderValue={controlShouldRenderValue}
        formatOptionLabel={renderSearchRow}
        defaultOptions={defaultData}
        isClearable={isClearable}
        noOptionsMessage={noOptionsMessage}
        singleValue
        styles={isSearch ? searchStyles : {}}
        theme={this.applyThemeColors}
        loadOptions={searchTerm => {
          if (activeCampus) {
            return this.interceptAndDelaySearch(
              searchTerm,
              activeCampus.id,
              actions,
              dataResolver,
              otherParameters
            )
          }
          return []
        }}
      />
    ) : (
      <Creatable
        id={id}
        renderSingleValue={renderSingleValue}
        onChange={this._onSelectChanged}
        components={selectComponents}
        cacheOptions
        value={value}
        isDisabled={disabled}
        autoFocus={autoFocus}
        placeholder={placeholder}
        isOptionSelected={this.isOptionSelected}
        controlShouldRenderValue={controlShouldRenderValue}
        onCreateOption={onCreateOption}
        formatOptionLabel={renderSearchRow}
        defaultOptions={defaultData}
        isClearable={isClearable}
        noOptionsMessage={noOptionsMessage}
        singleValue
        styles={isSearch ? searchStyles : {}}
        theme={this.applyThemeColors}
        loadOptions={searchTerm =>
          this.interceptAndDelaySearch(
            searchTerm,
            activeCampus.id,
            actions,
            dataResolver,
            otherParameters
          )
        }
      />
    )
  }
}
export default withTheme(CoworksSearch)

CoworksSearch.displayName = 'CoworksSearch'
