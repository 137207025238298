import { arrayMerge, sortByMemberName } from './reducerConstants'
import { REGISTER_COMMUNITY } from '../reduxActions/actionTypes'

export default function (community = {}, action) {
  switch (action.type) {
    case REGISTER_COMMUNITY:
      return action.community
    default:
      return community
  }
}
