/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import './EventFeed.scss'

export default class EventFeed extends Component {
  render() {
    return (
      <div id="EventFeed">
        <div
          className="container-fluid m-0 p-0 minH-100"
          style={{
            backgroundImage:
              'url(http://hq.community/wp-content/uploads/2017/01/flexible.png)',
            backgroundSize: 'cover',
          }}
        >
          <div className=" h-100 overlay">
            <div className="row fixed">
              <div className="col-md-10 py-2  mx-auto">
                <h1 className="text-white">This Week at CoWorks</h1>
              </div>
            </div>
            <div className="row h-100">
              <div className="col-md-10 py-5 mx-auto ">
                <div
                  style={{
                    backgroundImage:
                      'url("https://source.unsplash.com/1600x900/?beer")',
                  }}
                  className="card"
                >
                  <div className="overlay-gradient text-white h-100 p-4">
                    <h2>Happy Hour at CoWorks</h2>
                    <h5 className="text-muted"> CoWorks Main Campus</h5>
                    <hr />
                    <h4>Monday at 5:00 PM</h4>
                    <p>Join us for Happy hour</p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage:
                      'url("https://source.unsplash.com/1600x900/?code")',
                  }}
                  className="card"
                >
                  <div className="overlay-gradient text-white h-100 p-4">
                    <h2>Coding With Tech Talent South</h2>
                    <h5 className="text-muted"> CoWorks Main Campus</h5>
                    <hr />
                    <h4>Tuesday at 5:00 PM</h4>
                    <p>Join us for Happy hour</p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage:
                      'url("https://source.unsplash.com/1600x900/?iPhone")',
                  }}
                  className="card"
                >
                  <div className="overlay-gradient text-white h-100 p-4">
                    <h2>Office Hour with Lithios</h2>
                    <h5 className="text-muted"> CoWorks Main Campus</h5>
                    <hr />
                    <h4>Wednesday at 5:00 PM</h4>
                    <p>Join us for Happy hour</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EventFeed.displayName = 'EventFeed'
