import React from 'react'
import PropTypes from 'prop-types'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as teamActions from '@reduxActions/teamActions'
import * as planActions from '@reduxActions/planActions'
import * as memberActions from '@reduxActions/memberActions'
import * as announcementActions from '@reduxActions/announcementActions'
import * as memberSelectors from '@reduxSelectors/memberSelectors'
import * as apiService from '../../services/apiService'

// External Libs
import classnames from 'classnames'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { Wizard, Steps, Step } from 'react-albus'
import styled from 'styled-components'
import Switch from '@global/Base/Switch/Switch'

import { toast } from 'react-toastify'
import { Line } from 'rc-progress'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
  Row,
  Input,
  Label,
  Button,
} from 'reactstrap'
import moment from 'moment'
import renderHTML from 'react-render-html'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { text } from '@fortawesome/fontawesome'

// Global Components
import * as Constants from '@global/Constants'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import Placeholder from '@global/Placeholder'
import Spinner from '@global/Spinner'
import CoworksSearch from '@global/Search/CoworksSearch'
import { CustomInputComponent, LabelStyled } from '@global/Form/FormComponents'
import MyStatefulEditor from '@global/MyStatefulEditor'

/// Components
import ReviewModal from './ReviewModal'
import MemberModal from './MemberModal'
import EmailMessagePreview from './Preview/EmailMessagePreview'
import AnnouncementsMemberRow from './TableRow/AnnouncementsMemberRow'
import CompanyRow from './TableRow/TeamRow'
import TeamRecipientRow from './TableRow/TeamRecipientRow'
import MemberRecipientRow from './TableRow/MemberRecipientRow'
import './announcementForm.scss'
import IOSPushNotificationPreview from './Preview/IOSPushNotificationPreview'
import IOSSMSPreview from './Preview/IOSSMSPreview'

import {
  MessageTypeButton,
  MessageTypeTitle,
  MessageTypeDescription,
} from './MessageTypeButton'

// Styled Components
const InputGroupAddonStyled = styled(InputGroupAddon)`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: none;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
`

class AnnouncementForm extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    user: PropTypes.object,
    match: PropTypes.object,
    families: PropTypes.array,
    community_students: PropTypes.array,
    team_actions: PropTypes.object,
    member_actions: PropTypes.object,
    announcement_actions: PropTypes.object,
    plan_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    teams: PropTypes.array,
    members: PropTypes.array,
    announcements: PropTypes.array,
    history: PropTypes.object,
    errors: PropTypes.object,
    handleSubmit: PropTypes.func,
    submitForm: PropTypes.func,
    role: PropTypes.string,
    teams_pagination: PropTypes.object,
    members_pagination: PropTypes.object,
    theme: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    // isFetchingMembers: PropTypes.bool.isRequired,
    // isFetchingTeams: PropTypes.bool.isRequired,
  }
  constructor(props, context) {
    super(props, context)
    const isSafari =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1
    this.wizRef = React.createRef()
    this.state = {
      checked: this.props.checked,
      showModal: false,
      fromText: props.user.email,
      template: 0,
      email: '',
      greeting: 'Dear',
      greetingHistory: 'Dear', // this is so that we can keep what it was between removals.
      headerText: '',
      message: this.setSignature('', props.user, props.user.email),
      subject: '',
      allowGreeting: true,
      announcement_type: 'Email',
      campus_id: this.props.activeCampus.id,
      programLoader: false,
      familyLoader: false,
      studentLoader: false,
      emailLoader: false,
      members: [],
      companies: [],
      recipients: [],
      companyRecipients: [],
      markedRecipientIds: [],
      ignoreRecipientIds: [],
      isSending: false,
      isLoadingTeam: false,
      formSubmitted: false,
      markedCompanyRecipientIds: [],
      allRecipients: false,
      isSafari: isSafari,
      isTemplate: false,
      showValidationModal: false,
      validationSuccess: false,
      validationMessage: '',
      messageTypeSelected: '', // the type of message => push, or email
      activeTab: 'members',
      announcementLoader: false,
      memberLoader: false,
      companyLoader: false,
      showReviewModal: false,
      showMemberModal: false,
      teamId: null,
      memberPage: Constants.START_PAGE_DEFAULT,
      companyPage: Constants.START_PAGE_DEFAULT,
      perPage: Constants.PER_PAGE_DEFAULT,
      totalRows: 0,
      sinceTS: moment().unix(),
      pushMessageTitle: '', // for announcement flow
      pushMessage: '', // for announcement flow
      sendAsSMS: false, // for announcement flow
      sendAsEmail: false,
    }
  }

  componentDidMount() {
    this.fetchInitialProps()
  }

  setSignature = (text = '', user, userEmail) => {
    let message = text
    message += '<p><br/></p>'
    message += '<p>Thanks,</p>'
    message += `<h6>${user.first_name} ${user.last_name}</h6>`
    // message += `<p><a href="mailto:${userEmail}" alt="${userEmail}">${userEmail}</a></p>`
    return message
  }

  fetchInitialProps = () => {
    if (!this.props.members || this.props.members.length === 0) {
      this.setState({ memberLoader: true })
      this.loadNextObjects('member', this.state.memberPage, this.state.perPage)
    } else {
      const id = this.props.match.params.id

      if (id !== null && id !== undefined) {
        const announcementId = Number(id)
        this.fetchAnnouncementById(announcementId, announcement =>
          this.populateMessage(announcement)
        )
      }
    }
  }

  populateMessage = announcement => {
    if (announcement === null) {
      return
    }
    const recipientIds = []
    const markedRecipientIdList = []
    const recipientList = []
    let greeting = ''
    let allowGreeting = false

    if (announcement.type === 'Email') {
      if (announcement.greeting) {
        greeting = announcement.greeting.match('<p>(.*){{')[1].trim()
        allowGreeting = true
      }
    }

    announcement.recipients.map(obj => {
      recipientIds.push(obj.id)
    })

    this.props.members.map(object => {
      if (recipientIds.includes(object.id)) {
        const member = Object.assign({}, object)

        recipientList.push(member)
        markedRecipientIdList.push(member.id)
      }
    })

    // populate the screens so back button works
    this.wizRef.current.push('Template')
    this.wizRef.current.push('Recipients')
    this.wizRef.current.push('MessageTemplate')

    this.setState({
      fromText: announcement.from_email,
      greeting,
      allowGreeting,
      headerText: announcement.header,
      message: announcement.message,
      pushMessage: announcement.message,
      pushMessageTitle: announcement.header,
      subject: announcement.subject,
      recipients: recipientList,
      markedRecipientIds: markedRecipientIdList,
      announcement_type: announcement.type,
    })
  }

  loadNextObjects = async (type, page, perPage, callback) => {
    const self = this
    switch (type) {
      case 'member':
        this.props.member_actions
          .getMembers(this.props.activeCampus.id, {
            page: page,
            per_page: perPage,
          })
          .then(() => {
            const stateCopy = Object.assign({}, this.state)
            self.setState(
              {
                memberLoader: false,
                memberPage: stateCopy.memberPage + 1,
              },
              () => callback && callback()
            )
          })
          .catch(err => {
            self.setState({
              memberLoader: false,
            })
          })
        break
      case 'company':
        this.props.team_actions
          .getDirectoryTeams(this.props.activeCampus.id, {
            page: page,
            per_page: perPage,
          })
          .then(() => {
            const stateCopy = Object.assign({}, this.state)
            const page = stateCopy.companyPage + 1
            self.setState(
              {
                companyLoader: false,
                companyPage: page,
              },
              () => callback && callback()
            )
          })
          .catch(err => {
            self.setState({
              companyLoader: false,
            })
          })
        break
      default:
        break
    }
  }

  nextMemberPage = () => {
    const stateCopy = Object.assign({}, this.state)
    if (
      this.props.members_pagination &&
      this.props.members &&
      stateCopy.memberPage < this.props.members_pagination.total_pages &&
      this.props.members.length < this.props.members_pagination.total_objects
    ) {
      const page = stateCopy.memberPage
      const perPage = stateCopy.perPage
      this.loadNextObjects('member', page, perPage)
    }
  }

  nextTeamPage = () => {
    const stateCopy = Object.assign({}, this.state)
    if (
      this.props.teams_pagination &&
      this.props.teams &&
      stateCopy.companyPage < this.props.teams_pagination.total_pages &&
      this.props.teams.length < this.props.teams_pagination.total_objects
    ) {
      const page = stateCopy.companyPage
      const perPage = stateCopy.perPage
      this.loadNextObjects('company', page, perPage)
    }
  }

  fetchAnnouncementById = (announcementId, callback) => {
    this.setState({
      announcementLoader: true,
    })
    apiService
      .getAnnouncement(announcementId)
      .then(response => {
        callback(response.announcement)
        this.setState({
          announcementLoader: false,
        })
      })
      .catch(err => {
        callback(null)
        this.setState({
          announcementLoader: false,
        })
      })
  }

  dataMemberResolver = response => {
    return response.members
  }

  dataCompanyResolver = response => {
    return response.teams
  }

  handleSearchMemberSelected = member => {
    this.addRecipients(member, 'member')
  }

  handleSearchCompanySelected = company => {
    this.addRecipients(company, 'company')
  }

  handleClick = type => {
    if (type === 'member') {
      this.props.history.push('/directory')
    }
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
        },
        () => {
          if (
            tab === 'teams' &&
            (!this.props.teams || this.props.teams.length === 0)
          ) {
            this.setState({ companyLoader: true })
            this.loadNextObjects(
              'company',
              this.state.companyPage,
              this.state.perPage
            )
          }
        }
      )
    }
  }

  toggleReviewModal = () => {
    this.setState({
      showReviewModal: !this.state.showReviewModal,
    })
  }

  toggleMemberModal = team => {
    if (team.users.length === 0) {
      this.setState({
        isLoadingTeam: true,
        teamId: team.id,
        showMemberModal: !this.state.showMemberModal,
      })

      this.props.team_actions
        .getTeam(team.id, { campus_id: this.props.activeCampus.id })
        .then(theTeam => {
          this.setState({
            isLoadingTeam: false,
          })
        })
        .catch(err => {
          Constants.logO('problem updated admin', err)
        })
    } else {
      this.setState({
        teamId: team.id,
        showMemberModal: !this.state.showMemberModal,
      })
    }
  }

  createGreeting = (allowGreeting, greeting) => {
    if (allowGreeting) {
      return `<p>${greeting} {{member_first_name}},</p>`
    }
    return ''
  }

  sendEmailMessage = values => {
    const {
      subject,
      campus_id,
      fromText,
      headerText,
      message,
      template,
      allRecipients,
      announcement_type,
      recipients,
      sendAsSMS,
      messageTypeSelected,
      allowGreeting,
      greeting,
    } = values
    this.setState({ isSending: true })
    this.props.announcement_actions
      .createAnnouncement({
        user_id: this.props.user.id,
        user_ids: JSON.stringify(this.getRecipientIds(recipients)),
        subject: subject,
        campus_id: campus_id,
        from_email: fromText,
        header: headerText,
        greeting: this.createGreeting(allowGreeting, greeting),
        message: message,
        template: template,
        send_to_campus: allRecipients,
        community_id: this.props.activeCampus.id,
        message_type: messageTypeSelected,
      })
      .then(() => {
        this.setState({ isSending: false })
        this.pushToAnnouncement()
        toast.success('Sent announcement.')
      })
      .catch(err => {
        if (err) {
          this.setState({
            showReviewModal: false,
            isSending: false,
            errors: err,
          })
          toast.error(this.state.errors.message)
        }
      })
  }
  sendPushSMSMessage = values => {
    const {
      subject,
      campus_id,
      allRecipients,
      announcement_type,
      recipients,
      sendAsSMS,
      sendAsEmail,
      messageTypeSelected,
      pushMessageTitle,
      pushMessage,
    } = values
    this.setState({ isSending: true })
    this.props.announcement_actions
      .createAnnouncement({
        user_id: this.props.user.id,
        user_ids: JSON.stringify(this.getRecipientIds(recipients)),
        campus_id: campus_id,
        send_to_campus: allRecipients,
        community_id: this.props.activeCampus.id,
        send_as_sms: sendAsSMS,
        send_as_email: sendAsEmail,
        message_type: messageTypeSelected,
        push_message_title: pushMessageTitle,
        push_message: pushMessage,
      })
      .then(() => {
        this.setState({ isSending: false })
        this.pushToAnnouncement()
        toast.success('Sent announcement.')
      })
      .catch(err => {
        if (err) {
          this.setState({
            showReviewModal: false,
            isSending: false,
            errors: err,
          })
          toast.error(this.state.errors.message)
        }
      })
  }
  pushToAnnouncement = () => {
    this.props.history.push('/announcements')
  }

  clearRecipients = () => {
    this.setState({
      allRecipients: false,
      recipients: [],
      companyRecipients: [],
      markedRecipientIds: [],
      markedCompanyRecipientIds: [],
      ignoreRecipientIds: [],
    })
  }

  resetRecipients = previous => {
    this.setState(
      {
        recipients: [],
        companies: [],
      },
      () => {
        previous()
      }
    )
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSendAsSMS = () => {
    this.setState({
      sendAsSMS: !this.state.sendAsSMS,
    })
  }
  handleSendAsEmail = () => {
    this.setState({
      sendAsEmail: !this.state.sendAsEmail,
    })
  }

  handleTemplateChange = (templateId, setFieldValue, fromText) => {
    let message = ''
    let subject = ''
    let headerText = ''
    let greeting = ''

    if (templateId !== null) {
      const template = Constants.templates[templateId]
      message = this.setSignature(template.message, this.props.user, fromText)
      subject = template.subject
      headerText = template.header
      greeting = template.greeting
      setFieldValue('subject', subject)
      setFieldValue('greeting', greeting)
    } else {
      message = this.setSignature(null, this.props.user, fromText)
    }
    setFieldValue('template', templateId)
    setFieldValue('headerText', headerText)
    setFieldValue('message', message)
  }
  handleChangeGreeting = (showGreeting, values, setFieldValue) => {
    let greeting = values.greeting
    if (showGreeting) {
      greeting = values.greetingHistory
    } else if (values.greetingHistory && values.greetingHistory !== greeting) {
      setFieldValue('greetingHistory', greeting)
    } else {
      setFieldValue('greetingHistory', greeting)
      greeting = ''
    }

    setFieldValue('greeting', greeting)
  }

  selectAll = () => {
    this.setState({
      allRecipients: true,
      recipients: [],
      companyRecipients: [],
      markedRecipientIds: [],
      markedCompanyRecipientIds: [],
      ignoreRecipientIds: [],
    })
  }

  deleteRecipient = recipient => {
    const recipientList = [...this.state.recipients]
    const markedRecipientIdList = [...this.state.markedRecipientIds]
    const foundIndex = recipientList.findIndex(user => user.id === recipient.id)
    const foundIdIndex = markedRecipientIdList.indexOf(recipient.id)

    if (foundIndex !== -1) {
      recipientList.splice(foundIndex, 1)
    }
    if (foundIdIndex !== -1) {
      markedRecipientIdList.splice(foundIdIndex, 1)
    }

    this.setState({
      recipients: recipientList,
      markedRecipientIds: markedRecipientIdList,
    })
  }

  deleteCompanyRecipient = recipient => {
    const companyRecipientList = [...this.state.companyRecipients]
    const recipientList = [...this.state.recipients]
    const markedRecipientIdList = [...this.state.markedRecipientIds]
    const ignoreRecipientIdList = [...this.state.ignoreRecipientIds]
    const markedCompanyRecipientIdList = [
      ...this.state.markedCompanyRecipientIds,
    ]
    // const newMarkedList = markedRecipientIdList;
    // const newIngoreList = ignoreRecipientIdList;

    const foundIndex = companyRecipientList.findIndex(
      user => user.id === recipient.id
    )
    const foundCompanyMarkedIndex = markedCompanyRecipientIdList.indexOf(
      recipient.id
    )

    if (foundIndex !== -1) {
      companyRecipientList.splice(foundIndex, 1)
    }

    if (foundIndex !== -1) {
      markedCompanyRecipientIdList.splice(foundCompanyMarkedIndex, 1)
    }

    recipient.users.map(user => {
      const foundRecipientIndex = recipientList.findIndex(
        recip => recip.id === user.id
      )
      const foundMarkedIndex = markedRecipientIdList.indexOf(user.id)
      const foundIgnoredIndex = ignoreRecipientIdList.indexOf(user.id)

      if (foundRecipientIndex !== -1) {
        recipientList.splice(foundRecipientIndex, 1)
      }

      if (foundMarkedIndex !== -1) {
        markedRecipientIdList.splice(foundMarkedIndex, 1)
      }

      if (foundIgnoredIndex !== -1) {
        ignoreRecipientIdList.splice(foundIgnoredIndex, 1)
      }
    })

    this.setState({
      companyRecipients: companyRecipientList,
      recipients: recipientList,
      markedRecipientIds: markedRecipientIdList,
      ignoreRecipientIds: ignoreRecipientIdList,
      markedCompanyRecipientIds: markedCompanyRecipientIdList,
    })
  }

  addRecipients = (recipients, type) => {
    const recipientList = [...this.state.recipients]
    const companyRecipientList = [...this.state.companyRecipients]
    const markedRecipientIdList = [...this.state.markedRecipientIds]
    const ignoreRecipientIdList = [...this.state.ignoreRecipientIds]
    const markedCompanyRecipientIdList = [
      ...this.state.markedCompanyRecipientIds,
    ]
    const company = this.getCompanyById(this.state.teamId)
    if (type === 'company') {
      const teams = []
      const strippedTeam = Object.assign({}, recipients)
      delete strippedTeam.users
      teams[0] = strippedTeam

      recipients.users.map(object => {
        if (!markedRecipientIdList.includes(object.id)) {
          const member = Object.assign({}, object)
          member.teams = teams
          const admins = recipients.admins
          member.isAdmin =
            (member.admin_teams && member.admin_teams.length > 0) ||
            (admins &&
              admins.filter(admin => admin.id === member.id).length > 0) ||
            member.isAdmin

          recipientList.push(member)
          markedRecipientIdList.push(member.id)
        }
      })

      this.setState({
        recipients: recipientList,
        showMemberModal: false,
        markedRecipientIds: markedRecipientIdList,
        allRecipients: false,
      })
    } else if (type === 'member') {
      const member = Object.assign({}, recipients)
      member.isAdmin =
        (company.admins &&
          company.admins.filter(admin => admin.id === member.id).length > 0) ||
        (member.admin_teams && member.admin_teams.length > 0)

      if (recipientList.length > 0) {
        const foundIndex = recipientList.findIndex(
          recipient => recipient.id === recipients.id
        )
        if (foundIndex > -1) {
          recipientList[foundIndex] = member
        } else {
          recipientList.push(member)
          if (!markedRecipientIdList.includes(recipients.id)) {
            markedRecipientIdList.push(recipients.id)
          }
        }
      } else {
        recipientList.push(member)
        if (!markedRecipientIdList.includes(recipients.id)) {
          markedRecipientIdList.push(recipients.id)
        }
      }

      this.setState({
        recipients: recipientList,
        markedRecipientIds: markedRecipientIdList,
        allRecipients: false,
      })
    }
  }

  displayPlaceholderText = () => {
    if (this.state.allRecipients) {
      return (
        <Placeholder
          title={'Sending to All Members'}
          icon={'paper-plane'}
          subtext1={'This announcement will be sent to all members'}
          color="#269796"
        />
      )
    }
    return (
      <Placeholder
        title={'No Recipients Listed'}
        icon={'users'}
        subtext1={'You currently have chosen no recipients'}
        subtext2={'Start adding recipients to send your announcement to'}
      />
    )
  }

  displayRecipients = () => {
    const members = []
    this.state.recipients.map((member, index) => {
      if (!this.state.ignoreRecipientIds.includes(member.id)) {
        members.push(
          <MemberRecipientRow
            recipient={member}
            deleteRecipient={() => this.deleteRecipient(member, index)}
          />
        )
      }
    })
    return (
      <React.Fragment>
        <div>{members}</div>
      </React.Fragment>
    )
  }

  displayMembers = () => {
    if (this.state.memberLoader) {
      return <Spinner />
    }
    let members = []
    if (this.props.members) {
      members = this.props.members
    }

    if (members.length > 0) {
      return (
        <PaginatedTable
          objects={members}
          canEdit={false}
          type={'Members'}
          nextPage={() => this.nextMemberPage()}
          isLoading={this.props.isFetching}
          containerClassName={null}
          wrapperClassName={null}
          ancestor={null}
          totalPages={
            this.props.members_pagination &&
            this.props.members_pagination.total_pages
          }
          renderItem={(obj, index) => this.renderMemberRow(obj, index)}
        />
      )
    } else if (members.length === 0) {
      return (
        <Placeholder
          title={'No Members on Campus'}
          icon={'users'}
          subtext1={'You currently have no members in this campus'}
          subtext2={
            'Start adding members so you can send an announcement to them'
          }
          buttonText={'Add Member'}
          buttonIcon={'plus'}
          handleClick={() => this.handleClick('member')}
        />
      )
    }
    return null
  }

  displayCompanies = () => {
    if (this.state.companyLoader) {
      return <Spinner />
    }
    let teams = []
    if (this.props.teams) {
      teams = this.props.teams
    }

    if (teams.length > 0) {
      return (
        <PaginatedTable
          objects={teams}
          canEdit={false}
          type={'Companies'}
          nextPage={() => this.nextTeamPage()}
          isLoading={this.props.isFetching}
          containerClassName={null}
          wrapperClassName={null}
          ancestor={null}
          totalPages={
            this.props.teams_pagination &&
            this.props.teams_pagination.total_pages
          }
          renderItem={(obj, index) => this.renderCompanySearchRow(obj, index)}
        />
      )
    } else if (teams.length === 0) {
      return (
        <Placeholder
          title={'No teams at Campus'}
          icon={'users'}
          subtext1={'You currently have no teams in this campus'}
          subtext2={
            'Start adding teams so you can send an announcement to them'
          }
          buttonText={'Add Team'}
          buttonIcon={'plus'}
          handleClick={() => this.handleClick('company')}
        />
      )
    }
    return null
  }
  createOptions = () => {
    const options = Constants.templates.map((template, index) => (
      <option value={index}>{template.name}</option>
    ))
    return options
  }
  generateGreeting = (greeting, allowGreeting, greetingHistory) => {
    if (!allowGreeting) {
      return null
    }
    let text = greeting
    text += ' (member_name)'
    return text
  }
  onInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  onChangeSubject = event => {
    this.setState({ subject: event.target.value })
  }

  onChangeMessage = event => {
    this.setState({ message: event.target.value })
  }

  getRecipientIds = recipients => {
    return recipients.map(obj => {
      return obj.id
    })
  }

  getCompanyById = id => {
    if (id === null) {
      return {}
    }

    let team = {}
    for (const obj of this.props.teams) {
      if (obj.id === id) {
        team = obj
        break
      }
    }
    return team
  }
  messageTypeSelector = (type, next) => {
    if (type === 'Email') {
      this.setState({
        messageTypeSelected: 'Email',
      })
    } else if (type === 'Push') {
      this.setState({
        messageTypeSelected: 'Push',
      })
    }
    next()
  }

  renderSignature = () => (
    <div className="mt-5 email-preview-signature">
      <p className="mb-3 mt-3 email-preview-thanks">Sincerely,</p>
      <p className="mb-1 email-preview-name">
        {this.props.user.first_name} {this.props.user.last_name}
      </p>
      <a href={`mailto:${this.props.user.email}`} alt={this.props.user.email}>
        {this.props.user.email}
      </a>
    </div>
  )

  renderMemberSearchRow = object => (
    <AnnouncementsMemberRow
      key={object.id}
      member={object}
      added={
        this.state.markedRecipientIds.includes(object.id) ||
        this.state.allRecipients
      }
      addRecipients={this.addRecipients}
    />
  )
  renderMemberRow = object => (
    <AnnouncementsMemberRow
      key={object.id}
      member={object}
      added={
        this.state.markedRecipientIds.includes(object.id) ||
        this.state.allRecipients
      }
      addRecipients={this.addRecipients}
    />
  )

  renderCompanySearchRow = object => (
    <CompanyRow
      key={object.id}
      company={object}
      addRecipients={this.addRecipients}
      added={
        this.state.markedCompanyRecipientIds.includes(object.id) ||
        this.state.allRecipients
      }
      toggleMemberModal={this.toggleMemberModal}
    />
  )

  getValidationSchema = () => {
    if (this.state.messageTypeSelected === 'Email') {
      return Yup.object().shape({
        fromText: Yup.string()
          .email('This email is not formatted correctly!')
          .required('Email is required'),
        subject: Yup.string().required('Subject is required!'),
      })
    }
    return Yup.object().shape({
      pushMessage: Yup.string().required('Message is required'),
      pushMessageTitle: Yup.string(),
    })
  }

  render() {
    const {
      messageTypeSelected,
      announcementLoader,
      activeTab,
      sendAsSMS,
      sendAsEmail,
      email,
      subject,
      campus_id,
      fromText,
      headerText,
      message,
      template,
      allRecipients,
      announcement_type,
      recipients,
      pushMessageTitle,
      pushMessage,
      allowGreeting,
      greeting,
      greetingHistory,
    } = this.state

    // references for the functions.
    const self = this
    return (
      <div id="CampaignPage">
        {announcementLoader ? (
          <Spinner />
        ) : (
          <Wizard
            ref={this.wizRef}
            render={({ step, steps }) => (
              <React.Fragment>
                <div className="m-2">
                  <Line
                    percent={(steps.indexOf(step) / steps.length) * 100}
                    strokeColor={this.props.theme.colorPrimary}
                  />
                </div>
                <Steps key={step.id} step={step}>
                  <Step
                    id="Template"
                    render={({ next, previous }) => (
                      <div className="row d-flex full-height flex-column justify-content-between">
                        <div className="bg-white w-100 py-4 message-page-header">
                          <div className="row flex-column animated fadeInDown">
                            <strong className="d-block">
                              <h2 className="text-center text-primary">
                                Please select the type
                              </h2>
                            </strong>
                            <h4 className="text-center">
                              Send as a custom email or send a quick
                              announcement?
                            </h4>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row px-md-5">
                            <div className="col-md-6 ">
                              <MessageTypeButton
                                className={`card d-flex align-items-center justify-content-center p-3 m-5`}
                                onClick={() =>
                                  this.messageTypeSelector('Email', next)
                                }
                              >
                                <FontAwesomeIcon icon={'envelope'} size="3x" />
                                <MessageTypeTitle>Email</MessageTypeTitle>
                                <MessageTypeDescription>
                                  Send a custom email message to inform your
                                  members.
                                </MessageTypeDescription>
                              </MessageTypeButton>
                            </div>
                            <div className="col-md-6">
                              <div className="container">
                                <MessageTypeButton
                                  className={`card d-flex align-items-center justify-content-center p-3 m-5`}
                                  onClick={() =>
                                    this.messageTypeSelector('Push', next)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={'bullhorn'}
                                    size="3x"
                                  />
                                  <MessageTypeTitle>
                                    Announcement
                                  </MessageTypeTitle>
                                  <MessageTypeDescription>
                                    Send quick an announcement with push or sms
                                    text.
                                  </MessageTypeDescription>
                                </MessageTypeButton>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Row>
                          <div className="col-6">
                            <NavLink
                              className="mx-auto btn-block btn btn-secondary"
                              onClick={() => {
                                this.props.history.push('/announcements')
                              }}
                            >
                              Back
                            </NavLink>
                          </div>
                        </Row>
                      </div>
                    )}
                  />
                  <Step
                    id="Recipients"
                    render={({ next, previous }) => (
                      <div className="row d-flex full-height flex-column justify-content-between animated fadeIn">
                        <div className="row">
                          <div className="col-md-6">
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 'members',
                                  })}
                                  onClick={() => {
                                    this.toggleTab('members')
                                  }}
                                >
                                  Members
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 'teams',
                                  })}
                                  onClick={() => {
                                    this.toggleTab('teams')
                                  }}
                                >
                                  Teams
                                </NavLink>
                              </NavItem>
                              <div className="ml-auto">
                                <Button onClick={this.selectAll} className="">
                                  Select All
                                </Button>
                              </div>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                              <TabPane
                                tabId="members"
                                className="fixed-container"
                              >
                                <CoworksSearch
                                  placeholder={'Search Members'}
                                  defaultData={this.props.members}
                                  actions={this.props.member_actions}
                                  activeCampus={this.props.activeCampus}
                                  handleItemSelected={
                                    this.handleSearchMemberSelected
                                  }
                                  dataResolver={this.dataMemberResolver}
                                  renderSearchRow={this.renderMemberSearchRow}
                                  renderSingleValue={() => {
                                    return null
                                  }}
                                />
                                {this.displayMembers()}
                              </TabPane>
                              <TabPane
                                tabId="teams"
                                className="fixed-container"
                              >
                                <CoworksSearch
                                  placeholder={'Search Teams'}
                                  defaultData={this.props.teams}
                                  actions={this.props.team_actions}
                                  activeCampus={this.props.activeCampus}
                                  handleItemSelected={
                                    this.handleSearchCompanySelected
                                  }
                                  dataResolver={this.dataCompanyResolver}
                                  renderSearchRow={this.renderCompanySearchRow}
                                  renderSingleValue={() => {
                                    return null
                                  }}
                                />
                                {this.displayCompanies()}
                              </TabPane>
                            </TabContent>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div className="card">
                              <div className="card-header">
                                <div className="row d-flex align-items-center">
                                  <div className="col-6">
                                    <h2 className="m-0">Recipients</h2>
                                  </div>
                                  <div className="col-6 d-flex justify-content-end align-items-center">
                                    <Button
                                      onClick={this.clearRecipients}
                                      className=""
                                    >
                                      Clear
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <div className="card-block fixed-container">
                                {this.state.recipients.length === 0
                                  ? this.displayPlaceholderText()
                                  : this.displayRecipients()}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 pb-3">
                          <div className="row">
                            <div className="col-6">
                              <button
                                className="mx-auto btn-block btn btn-secondary"
                                onClick={previous}
                              >
                                Back
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className="mx-auto btn-block btn btn-primary"
                                onClick={next}
                                disabled={
                                  this.state.recipients.length === 0 &&
                                  !this.state.allRecipients
                                }
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  />

                  <Step
                    id="MessageTemplate"
                    render={({ next, previous }) => {
                      const saveStateBeforePrevious = values => {
                        this.setState({
                          ...values,
                        })
                        previous()
                      }
                      return (
                        <Formik
                          initialValues={{
                            email,
                            subject,
                            campus_id,
                            fromText,
                            headerText,
                            message,
                            template,
                            allRecipients,
                            announcement_type,
                            recipients,
                            sendAsSMS,
                            sendAsEmail,
                            messageTypeSelected,
                            pushMessageTitle,
                            pushMessage,
                            greeting,
                            greetingHistory,
                            allowGreeting,
                            showReviewModal: false,
                          }}
                          validationSchema={this.getValidationSchema}
                          onSubmit={(
                            values,
                            { setSubmitting, setFieldValue, validateForm }
                          ) => {
                            setSubmitting(false)
                            validateForm(values)
                              .then(whatever => {
                                setFieldValue(
                                  'showReviewModal',
                                  !values.showReviewModal
                                )
                              })
                              .catch(err => {
                                setSubmitting(false)
                              })
                          }}
                        >
                          {props => {
                            const {
                              values,
                              touched,
                              errors,
                              dirty,
                              isSubmitting,
                              handleChange,
                              handleBlur,
                              handleReset,
                              handleSubmit,
                              setFieldValue,
                              setFieldTouched,
                              setSubmitting,
                              submitForm,
                              validateForm,
                            } = props
                            return (
                              <form onSubmit={handleSubmit}>
                                <div className="row d-flex full-height flex-column justify-content-between animated fadeIn">
                                  {messageTypeSelected === 'Email' ? (
                                    <div className="col-12">
                                      <div className="row email-template-row">
                                        <div className="col-6 bg-dark">
                                          <div className="container">
                                            <div className="row">
                                              <div className="col-2 p-0 mt-3">
                                                <Label for="subject">
                                                  From:
                                                </Label>
                                              </div>
                                              <div className="col-10 p-0 mt-2">
                                                <Input
                                                  id="fromText"
                                                  type="email"
                                                  name="fromText"
                                                  className={`form-control w-100 ${
                                                    errors.fromText && 'error'
                                                  }`}
                                                  onChange={ev => {
                                                    const value =
                                                      ev.target.value
                                                    // this isn't quite what we want
                                                    // this.handleTemplateChange(
                                                    //   null,
                                                    //   setFieldValue,
                                                    //   value
                                                    // )
                                                    setFieldValue(
                                                      'fromText',
                                                      value
                                                    )
                                                  }}
                                                  value={values.fromText}
                                                />
                                                {errors.fromText && (
                                                  <div className="text-danger">
                                                    {errors.fromText}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="container">
                                            <div className="row">
                                              <div className="col-2 p-0 mt-3">
                                                <Label for="subject">
                                                  Subject:
                                                </Label>
                                              </div>
                                              <div className="col-10 p-0 mt-2">
                                                <Input
                                                  key={`subject-template-${values.template}`}
                                                  id="subject"
                                                  name="subject"
                                                  placeholder="Type your email subject here"
                                                  className={`form-control w-100 ${
                                                    errors.subject && 'error'
                                                  }`}
                                                  onChange={handleChange}
                                                  value={values.subject}
                                                />
                                                {errors.subject && (
                                                  <div className="text-danger">
                                                    {errors.subject}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="container">
                                            <div className="row">
                                              <div className="col-2 p-0 mt-3">
                                                <Label for="subject">
                                                  Header:
                                                </Label>
                                              </div>
                                              <div className="col-10 p-0 mt-2">
                                                <Input
                                                  key={`headerText-template-${values.template}`}
                                                  placeholder="Type your header text here"
                                                  id="headerText"
                                                  name="headerText"
                                                  onChange={handleChange}
                                                  value={values.headerText}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="container">
                                            <div className="row d-flex align-items-center mt-3 mb-2">
                                              <Label
                                                for="subject"
                                                className="col-2 p-0 m-0"
                                              >
                                                Greeting
                                              </Label>
                                              <div>
                                                {/* inactiveLabel={
                                                    <FontAwesomeIcon icon="times" />
                                                  }
                                                  activeLabel={
                                                    <FontAwesomeIcon icon="check" />
                                                  } */}
                                                <Switch
                                                  value={values.allowGreeting}
                                                  onChange={() => {
                                                    this.handleChangeGreeting(
                                                      !values.allowGreeting,
                                                      values,
                                                      setFieldValue
                                                    )
                                                    setFieldValue(
                                                      'allowGreeting',
                                                      !values.allowGreeting
                                                    )
                                                  }}
                                                />
                                              </div>
                                              {values.allowGreeting && (
                                                <div className="col pl-5">
                                                  <InputGroup>
                                                    <Input
                                                      key={`greeting-template-${values.template}`}
                                                      id="greeting"
                                                      name="greeting"
                                                      onChange={handleChange}
                                                      value={values.greeting}
                                                    />
                                                    <InputGroupAddonStyled addonType="append">
                                                      Member Name
                                                    </InputGroupAddonStyled>
                                                  </InputGroup>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <Label for="message" className="mt-3">
                                            Message
                                          </Label>
                                          <div
                                            key={`message-template-${values.template}`}
                                          >
                                            <MyStatefulEditor
                                              name="message"
                                              content={values.message}
                                              onChange={
                                                htmlString => {
                                                  setFieldValue(
                                                    'message',
                                                    htmlString
                                                  )
                                                }
                                                // this.handleStatefulEditorChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-6 bg-dark">
                                          <div className="container mb-2">
                                            <div className="row">
                                              <div className="col-2 p-0 mt-3">
                                                <Label for="templateSelect">
                                                  Template
                                                </Label>
                                              </div>
                                              <div className="col-10 p-0 mt-2">
                                                <Input
                                                  type="select"
                                                  name="template"
                                                  id="templateSelect"
                                                  onChange={event => {
                                                    this.handleTemplateChange(
                                                      event.target.value,
                                                      setFieldValue,
                                                      values.fromText
                                                    )
                                                  }}
                                                  value={values.template}
                                                >
                                                  {this.createOptions()}
                                                </Input>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card">
                                            <div className="card-header">
                                              <div className="row">
                                                <div className="col-12">
                                                  <h2 className="m-0">
                                                    Preview
                                                  </h2>
                                                </div>
                                              </div>
                                            </div>
                                            <EmailMessagePreview
                                              header={values.headerText}
                                              body={values.message}
                                              greeting={this.generateGreeting(
                                                values.greeting,
                                                values.allowGreeting,
                                                values.greetingHistory
                                              )}
                                              preferences={
                                                this.props.activeCommunity
                                                  .community_preference
                                              }
                                              campusPhoto={
                                                this.props.activeCampus
                                                  .campus_photo
                                                  ? this.props.activeCampus
                                                      .campus_photo
                                                      .medium_file_url
                                                  : null
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      <div className="col-6">
                                        <h3 className="mt-3">Text Editor</h3>
                                        <Row>
                                          <div className="col-12 m-2">
                                            <LabelStyled>Title</LabelStyled>
                                            <input
                                              className={`form-control w-100 ${
                                                errors.pushMessageTitle &&
                                                'error'
                                              }`}
                                              name="pushMessageTitle"
                                              onChange={handleChange}
                                              placeholder="Type your message title"
                                              value={values.pushMessageTitle}
                                            />
                                            {errors.pushMessageTitle && (
                                              <div className="text-danger">
                                                {errors.pushMessageTitle}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-12 m-2">
                                            <LabelStyled>Message</LabelStyled>
                                            <textarea
                                              className={`form-control w-100 ${
                                                errors.pushMessage && 'error'
                                              }`}
                                              name="pushMessage"
                                              type="text"
                                              rows="3"
                                              cols="70"
                                              maxLength="120"
                                              onChange={handleChange}
                                              placeholder="Type your message here"
                                              value={values.pushMessage}
                                            />
                                            {errors.pushMessage && (
                                              <div className="text-danger">
                                                {errors.pushMessage}
                                              </div>
                                            )}
                                          </div>
                                        </Row>
                                      </div>
                                      <div className="col-6 card">
                                        <h3 className="mt-3">Push Preview</h3>
                                        {IOSPushNotificationPreview({
                                          title: 'Coworks App',
                                          timing: 'NOW',
                                          messageTitle: values.pushMessageTitle,
                                          message: values.pushMessage,
                                        })}
                                      </div>
                                      <div className="col-6">
                                        <Row>
                                          <div className="col-6 m-2">
                                            <LabelStyled>
                                              Also send as sms message?
                                            </LabelStyled>
                                            <div className="form-group">
                                              {/* activeLabel={
                                                  <FontAwesomeIcon icon="paper-plane" />
                                                }
                                                inactiveLabel={
                                                  <FontAwesomeIcon icon="times" />
                                                } */}
                                              <Switch
                                                name="sendAsSMS"
                                                value={values.sendAsSMS}
                                                onChange={() => {
                                                  setFieldValue(
                                                    'sendAsSMS',
                                                    !values.sendAsSMS
                                                  )
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Row>
                                        {/* <Row>
                                        <div className="offset-col-6 col-6 mt-2">
                                          <LabelStyled>
                                            Send as email?
                                          </LabelStyled>
                                          <div className="form-group">
                                            <ToggleButton
                                              name="sendAsEmail"
                                              activeLabel={
                                                <FontAwesomeIcon icon="paper-plane" />
                                              }
                                              inactiveLabel={
                                                <FontAwesomeIcon icon="times" />
                                              }
                                              value={sendAsEmail}
                                              onToggle={this.handleSendAsEmail}
                                            />
                                          </div>
                                        </div>
                                      </Row> */}
                                      </div>
                                      {values.sendAsSMS && (
                                        <div className="card col-6">
                                          <Row>
                                            <div className="col-12">
                                              <h3 className="mt-3">
                                                SMS Preview
                                              </h3>
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-12">
                                              {IOSSMSPreview({
                                                fromMe: true,
                                                title: values.pushMessageTitle,
                                                message: values.pushMessage,
                                              })}
                                            </div>
                                          </Row>
                                        </div>
                                      )}
                                      {values.sendAsEmail && (
                                        <div className="card col-6 offset-md-6">
                                          <Row>
                                            <div className="col-12">
                                              <h3 className="mt-3">
                                                Email Preview
                                              </h3>
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-12">
                                              <LabelStyled>From: </LabelStyled>
                                              <p>{this.props.user.email}</p>
                                              <LabelStyled>
                                                Subject:{' '}
                                              </LabelStyled>
                                              <p>{values.pushMessageTitle}</p>
                                              <LabelStyled>
                                                Message:{' '}
                                              </LabelStyled>
                                              <p>{values.pushMessage}</p>
                                            </div>
                                          </Row>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  <div className="mt-3 pb-3">
                                    <div className="row">
                                      <div className="col">
                                        <button
                                          className="mx-auto btn-block btn btn-secondary"
                                          onClick={() =>
                                            saveStateBeforePrevious(values)
                                          }
                                        >
                                          Change Recipients
                                        </button>
                                      </div>
                                      <div className="col">
                                        <button
                                          type="submit"
                                          className="mx-auto btn-block btn btn-primary"
                                          disabled={!dirty || isSubmitting}
                                        >
                                          <i className="fas fa fa-paper-plane" />{' '}
                                          Review
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <ReviewModal
                                    activeCommunity={self.props.activeCommunity}
                                    activeCampus={self.props.activeCampus}
                                    showReviewModal={values.showReviewModal}
                                    editMode
                                    toggleReviewModal={() =>
                                      setFieldValue(
                                        'showReviewModal',
                                        !values.showReviewModal
                                      )
                                    }
                                    type={values.messageTypeSelected}
                                    recipients={values.recipients}
                                    sendToAll={values.allRecipients}
                                    from={values.fromText}
                                    subject={values.subject}
                                    header={
                                      values.messageTypeSelected === 'Email'
                                        ? values.headerText
                                        : values.pushMessageTitle
                                    }
                                    message={
                                      values.messageTypeSelected === 'Email'
                                        ? values.message
                                        : values.pushMessage
                                    }
                                    sendAsSMS={values.sendAsSMS}
                                    sendAsEmail={values.sendAsEmail}
                                    greeting={this.generateGreeting(
                                      values.greeting,
                                      values.allowGreeting,
                                      values.greetingHistory
                                    )}
                                    submitButton={
                                      <Button
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                          setSubmitting(true)
                                          if (messageTypeSelected === 'Email') {
                                            self.sendEmailMessage(values)
                                          } else if (
                                            messageTypeSelected === 'Push'
                                          ) {
                                            self.sendPushSMSMessage(values)
                                          } else {
                                            console.error('NO TYPE')
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon icon={'paper-plane'} />
                                        {values.messageTypeSelected === 'Email'
                                          ? ' Send Email'
                                          : ' Send Announcement'}
                                      </Button>
                                    } // end of submitButton
                                    deleteRecipient={this.deleteRecipient}
                                  />
                                </div>
                              </form>
                            )
                          }}
                        </Formik>
                      )
                    }}
                  />
                </Steps>
              </React.Fragment>
            )}
          />
        )}

        {this.state.showMemberModal && (
          <MemberModal
            toggleMemberModal={this.toggleMemberModal}
            showMemberModal={this.state.showMemberModal}
            markedRecipientIds={this.state.markedRecipientIds}
            allRecipients={this.state.allRecipients}
            addRecipients={this.addRecipients}
            loading={this.state.isLoadingTeam}
            team={this.getCompanyById(this.state.teamId)}
            selectAll={this.addRecipients}
          />
        )}
      </div>
    )
  }
}
AnnouncementForm.displayName = 'AnnouncementForm'

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    announcements: state.announcements.announcements,
    teams: state.teams.teams,
    teams_pagination: state.teams.meta ? state.teams.meta.pagination : null,
    members: memberSelectors.getMemberList(state),
    members_pagination: memberSelectors.getMemberPagination(state),
    user: state.user,
    isFetching: state.ui.isFetching,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    team_actions: bindActionCreators(teamActions, dispatch),
    member_actions: bindActionCreators(memberActions, dispatch),
    announcement_actions: bindActionCreators(announcementActions, dispatch),
    plan_actions: bindActionCreators(planActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementForm)
