import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SidebarNavLink from '../SidebarNavLink'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import {
  canViewEditBilling,
  accountantRole,
  eventsCoordinatorRole,
  salesAdminRole,
} from '../../../config/roles'
import moment from 'moment'
import Badge from '@global/Base/Badge/Badge'

const SidebarStyled = styled.div`
  background: ${props => props.theme.colorSecondary}!important;
`
class Sidebar extends Component {
  static propTypes = {
    location: PropTypes.object,
    activeCommunity: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const type = 'Member'
    this.state = {
      type: props.user && props.user.type ? this.props.user.type : type,
    }
  }

  handleClick(event) {
    event.preventDefault()
    event.target.parentElement.classList.toggle('open')
  }

  renderLinkText = tab => {
    return (
      <span className="sidebar-nav-coworks">
        <FontAwesomeIcon
          className="fa-fw sidebar-nav-coworks-icon"
          icon={`${tab.icon}`}
        />{' '}
        <span className="sidebar-nav-coworks-title">
          {tab.title}{' '}
          {tab.newUntilBadgeDate &&
          moment(tab.newUntilBadgeDate).isAfter(moment()) ? (
            <React.Fragment>
              {'   '}
              <Badge color="warning" size="small">
                New
              </Badge>
            </React.Fragment>
          ) : null}
        </span>
      </span>
    )
  }

  renderTabs = () => {
    const allTabs = [
      { title: 'Dashboard', icon: 'tachometer-alt', path: '/dashboard' },
      { title: 'Calendar', icon: 'calendar-alt', path: '/calendar' },
      { title: 'Directory', icon: 'users', path: '/directory' },
      { title: 'CRM', icon: 'user-plus', path: '/crm' },
      { title: 'Check-ins', icon: 'map-marker-check', path: '/checkins' },
      {
        title: 'Occupancy',
        icon: 'chair-office',
        path: '/occupancy',
        newUntilBadgeDate: moment.unix(1609539970),
      },
      { title: 'Announcements', icon: 'envelope', path: '/announcements' },
      { title: 'Plans', icon: 'credit-card', path: '/plans' },
      { title: 'Rooms', icon: 'door-open', path: '/rooms' },
      { title: 'Resources', icon: 'coffee', path: '/resources' },
      { title: 'Reports', icon: 'book', path: '/reports' },
      { title: 'My Team', icon: 'users', path: '/my-team' },
      { title: 'Community', icon: 'cog', path: '/community' },
    ]
    if (
      this.props.activeCommunity &&
      this.props.activeCommunity.stripe_account_id &&
      canViewEditBilling.includes(this.props.user.type)
    ) {
      allTabs.splice(3, 0, {
        title: 'Billing',
        icon: 'dollar-sign',
        path: '/billing',
      })
    }

    const adminOnlyPaths = [
      '/dashboard',
      '/billing',
      '/crm',
      '/checkins',
      '/occupancy',
      '/announcements',
      '/plans',
      '/rooms',
      '/resources',
      '/reports',
      '/community',
      //'/account',
    ]

    const memberOnlyPaths = ['/my-team']

    const renderedTabs = []
    if (this.state.type === 'Member') {
      for (const tab of allTabs) {
        if (!adminOnlyPaths.includes(tab.path)) {
          renderedTabs.push(tab)
        }
      }
    } else {
      for (const tab of allTabs) {
        if (!memberOnlyPaths.includes(tab.path)) {
          renderedTabs.push(tab)
        }
      }
    }
    return renderedTabs.map(tab => {
      return (
        <div key={tab.title} className="nav-item">
          <SidebarNavLink
            id={tab.title}
            to={tab.path}
            history={this.props.history}
            activeClassName="active"
            text={this.renderLinkText(tab)}
          />
        </div>
      )
    })
  }

  render() {
    return (
      <SidebarStyled className="sidebar">
        <nav className="sidebar-nav">
          <div className="nav">{this.renderTabs()}</div>
        </nav>
      </SidebarStyled>
    )
  }
}

Sidebar.displayName = 'Sidebar'

const mapStateToProps = state => {
  return {
    activeCommunity: state.ui.activeCommunity,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(Sidebar)
