import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Lottie from '@global/Lottie/Lottie'
import { Row, Col } from 'reactstrap'
import Spinner from '@global/Spinner'
import { Button } from '@global/Base/Button/ButtonStyled'

const ColLeft = styled(Col)`
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
`
const ColRight = styled(Col)`
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
`

const H2 = styled.h2`
  font-size: ${props => props.theme.fonts.sizes.h2.fontSize} !important;
  line-height: ${props => props.theme.fonts.sizes.h2.lineHeight} !important;
  letter-spacing: ${props =>
    props.theme.fonts.sizes.h2.letterSpacing} !important;
  font-weight: 700;
`
export default function ConnectionStatus({
  handleAnimationComplete,
  handleButtonClick,
  message = 'Successfully Connected',
  type = 'checkin',
  width = 300,
  height = 300,
  loading = false,
  showSupportButton = false,
}) {
  return (
    <Row>
      <ColLeft>
        {loading ? (
          <Spinner />
        ) : (
          <Lottie
            type={type}
            width={width}
            height={height}
            eventListeners={
              handleAnimationComplete && [
                {
                  eventName: 'complete',
                  callback: handleAnimationComplete,
                },
              ]
            }
          />
        )}
      </ColLeft>
      <Col className="d-flex align-items-center justify-content-center">
        <Row>
          <ColRight xs="12">
            <H2>{loading ? 'Connecting to Stripe...' : message}</H2>
          </ColRight>
          {showSupportButton ? (
            <Col xs="12">
              <Button color="primary" onClick={handleButtonClick}>
                Contact Support
              </Button>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  )
}
ConnectionStatus.displayName = 'ConnectionStatus'
ConnectionStatus.propTypes = {
  handleAnimationComplete: PropTypes.func,
  handleButtonClick: PropTypes.func,
  message: PropTypes.string,
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  loading: PropTypes.bool,
  showSupportButton: PropTypes.bool,
}
