import React from 'react'
import PropTypes from 'prop-types'
import defaultUserPic from '../../img/placeholders/otto_black_white.jpeg'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import TagRow from '@global/TagRow'

export default function LeadSearchRow({ lead, editLeadClicked }) {
  if (!lead) {
    return null
  }
  let defaultPicture, defaultEmail
  if (lead && !lead.lead_photo) {
    defaultPicture = defaultUserPic
  }
  if (!lead.email) {
    defaultEmail = ''
  }
  let name = null
  if (lead && lead.first_name && lead.last_name) {
    name = `${lead.first_name} ${lead.last_name}`
  } else if (lead.first_name) {
    name = `${lead.first_name}`
  } else if (lead.last_name) {
    name = `${lead.last_name}`
  } else {
    name = 'No Name'
  }
  return (
    <div key={`leads-search-${lead.id}`} className="border-bottom-1 mt-0">
      <div className="col pb-2" onClick={() => editLeadClicked(lead)}>
        <h5 className={'text-muted'}>{name}</h5>
        {lead.company_name && (
          <h6 className="text-muted">
            <FontAwesomeIcon size="sm" icon="briefcase" />{' '}
            {lead.company_name ? lead.company_name : ''}
          </h6>
        )}
        <h6>
          {lead.email ? (
            <a href={`mailto:${lead.email}`} title={`Email ${lead.email}`}>
              <FontAwesomeIcon size="sm" icon="envelope" /> {lead.email}
            </a>
          ) : (
            ''
          )}
        </h6>
        <TagRow object={lead} />
      </div>
    </div>
  )
}
LeadSearchRow.propTypes = {
  lead: PropTypes.object.isRequired,
  editLeadClicked: PropTypes.func.isRequired,
}
LeadSearchRow.displayName = 'LeadSearchRow'
