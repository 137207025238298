/* eslint-disable react/prop-types */
/* eslint-disable react/display-name, max-len */
import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import * as Axios from '../config/axios'

import {
  SuperAdmin,
  Member,
  MemberAccessRoles,
  justMembers,
  justSpaceStaff,
  Admin,
  Manager,
  Accountant,
  EventsCoordinator,
  Staff,
  DataAdmin,
  SalesAdmin,
  accountantRole,
  eventsCoordinatorRole,
  staffRole,
  dataAdminRole,
  salesAdminRole,
  wrapWithAuth,
} from '../config/roles'
import { mixpanel } from '../config/initializers'

import { withTheme } from 'styled-components'
import SmartLogger from '../hocs/SmartLogger'
import withErrorBoundry from '../hocs/withErrorBoundry'

// ROUTES
import Dashboard from './Dashboard/Dashboard'

import Calendar from './Calendar/Calendar'
import EventForm from './Calendar/EventForm'

import BillingContainer from './Payments/BillingContainer'

import Plans from './Plans/Plans'
import NewEditPlan from './Plans/Plan/Plan'

import Leads from './Leads/Leads'
import NewLead from './Leads/Lead'

import Announcements from './Messages/Announcements'
import AnnouncementForm from './Messages/AnnouncementForm'

import Resources from './Resources/Resources'
import ResourceDetail from './Resources/NewResource'

import Community from './Community/Community'
import ChangeCommunity from './Community/Campuses/ChangeCommunity'

import Directory from './Directory/Directory'
import PassportList from './Directory/Passport/PassportList'
import CheckIns from './Directory/CheckIns/CheckIns'

import MyTeam from './Directory/MyTeam'

import MemberDetail from './Directory/Members/MemberDetail/MemberDetail'
import NewMember from './Directory/Members/NewMember/NewMember'

import MyProfile from './Community/Personal/MyProfile'
import MyPreferences from './Community/Personal/MyPreferences'

import Rooms from './Rooms/Rooms'
import Room from './Rooms/Room'

import Occupancy from './Occupancy/Occupancy'

import MobileApp from './MobileApp/MobileApp'

import Reports from './Reports/Reports'

import ConnectToStripe from './Stripe/ConnectToStripe'

function wrapInHOCs(Component, user, theme) {
  return withTheme(SmartLogger(mixpanel, user)(withErrorBoundry(Component)))
}

// eslint-disable-next-line react/no-multi-comp
export default ({ user, theme, ...rest }) => {
  return (
    <Switch>
      <Route
        path="/dashboard"
        component={justSpaceStaff(wrapInHOCs(Dashboard, user, theme))}
      />
      <Route
        exact
        path="/calendar"
        component={Member(wrapInHOCs(Calendar, user, theme))}
      />
      <Route
        path="/calendar/event-detail"
        component={Member(wrapInHOCs(EventForm, user, theme))}
      />
      <Route path="/calendar/add-event" component={Member(EventForm)} />

      <Route exact path="/payments">
        <Redirect to="/billing" />
      </Route>

      <Route
        path="/billing"
        component={wrapWithAuth([
          accountantRole,
          eventsCoordinatorRole,
          salesAdminRole,
        ])(wrapInHOCs(BillingContainer, user, theme))}
      />

      <Route
        exact
        path="/plans"
        component={wrapWithAuth([
          accountantRole,
          eventsCoordinatorRole,
          salesAdminRole,
          staffRole,
        ])(wrapInHOCs(Plans, user, theme))}
      />
      <Route path="/plans/plan-detail">
        <Redirect to="/plans" />
      </Route>
      <Route
        path="/plans/add-plan"
        component={wrapWithAuth([
          accountantRole,
          eventsCoordinatorRole,
          salesAdminRole,
          staffRole,
        ])(wrapInHOCs(NewEditPlan, user, theme))}
      />
      <Route
        path="/plans/:planId"
        component={wrapWithAuth([
          accountantRole,
          eventsCoordinatorRole,
          salesAdminRole,
          staffRole,
        ])(wrapInHOCs(NewEditPlan, user, theme))}
      />

      <Route
        path="/directory"
        component={Member(wrapInHOCs(Directory, user, theme))}
      />
      <Route
        path="/my-team"
        component={justMembers(wrapInHOCs(MyTeam, user, theme))}
      />
      <Route path="/checkin">
        <Redirect to={'/checkins'} />
      </Route>
      <Route exact path={'/checkins'}>
        <Redirect to={'/checkins/member'} />
      </Route>
      <Route
        path="/checkins/:tabId"
        component={Member(wrapInHOCs(CheckIns, user, theme))}
      />
      <Route exact path="/passport">
        <Redirect to={'/checkins/network'} />
      </Route>
      <Route path="/leads">
        <Redirect to={'/crm'} />
      </Route>
      <Route exact path="/crm">
        <Redirect to={'/crm/leads'} />
      </Route>
      <Route
        exact
        path="/crm/:tabId"
        component={justSpaceStaff(wrapInHOCs(Leads, user, theme))}
      />
      <Route
        path="/crm/:tabId/add"
        component={justSpaceStaff(wrapInHOCs(NewLead, user, theme))}
      />
      <Route
        path="/crm/:tabId/:contactId"
        component={justSpaceStaff(wrapInHOCs(NewLead, user, theme))}
      />
      <Route
        exact
        path="/resources"
        component={justSpaceStaff(wrapInHOCs(Resources, user, theme))}
      />
      <Route
        path="/resources/add-resource"
        component={justSpaceStaff(wrapInHOCs(ResourceDetail, user, theme))}
      />
      <Route
        path="/resources/resource-detail"
        component={justSpaceStaff(wrapInHOCs(ResourceDetail, user, theme))}
      />
      <Route path="/account">
        <Redirect to="/community" />
      </Route>
      <Route path="/campuses">
        <Redirect to="/community/campuses" />
      </Route>
      <Route
        path="/community"
        component={justSpaceStaff(wrapInHOCs(Community, user, theme))}
      />
      <Route
        path="/my-profile"
        component={Member(wrapInHOCs(MyProfile, user, theme))}
      />
      <Route
        path="/preferences"
        component={Member(wrapInHOCs(MyPreferences, user, theme))}
      />
      <Route
        exact
        path="/occupancy"
        component={justSpaceStaff(wrapInHOCs(Occupancy, user, theme))}
      />
      <Route
        exact
        path="/occupancy/:suiteId"
        component={justSpaceStaff(wrapInHOCs(Room, user, theme))}
      />
      <Route
        exact
        path="/occupancy/:suiteId/:formState"
        component={justSpaceStaff(wrapInHOCs(Room, user, theme))}
      />
      <Route
        exact
        path="/occupancy/add"
        component={justSpaceStaff(wrapInHOCs(Room, user, theme))}
      />
      <Route
        exact
        path="/rooms/add-room"
        component={justSpaceStaff(wrapInHOCs(Room, user, theme))}
      />
      <Route
        exact
        path="/rooms/room-detail"
        component={justSpaceStaff(wrapInHOCs(Room, user, theme))}
      />
      <Route
        exact
        path="/rooms/:tabId"
        component={justSpaceStaff(wrapInHOCs(Rooms, user, theme))}
      />
      <Route
        path="/rooms"
        component={justSpaceStaff(wrapInHOCs(Rooms, user, theme))}
      />
      <Route
        exact
        path="/reports/:reportName"
        component={justSpaceStaff(wrapInHOCs(Reports, user, theme))}
      />
      <Route
        path="/reports"
        component={justSpaceStaff(wrapInHOCs(Reports, user, theme))}
      />
      <Route
        exact
        path="/announcements"
        component={justSpaceStaff(wrapInHOCs(Announcements, user, theme))}
      />
      <Route
        exact
        path="/announcements/send-announcement"
        component={justSpaceStaff(wrapInHOCs(AnnouncementForm, user, theme))}
      />
      <Route
        exact
        path="/announcements/send-announcement/:id"
        component={justSpaceStaff(wrapInHOCs(AnnouncementForm, user, theme))}
      />
      <Route
        exact
        path="/mobile"
        component={Member(wrapInHOCs(MobileApp, user, theme))}
      />
      <Route
        path="/connect-to-stripe"
        component={Staff(wrapInHOCs(ConnectToStripe, user, theme))}
      />

      {/* //Must remain at the bottom <Redirect from='/' to='/dashboard'/>*/}
      <Redirect from="" to="/dashboard" />
    </Switch>
  )
}
