/* eslint-disable react/prop-types */
/* eslint-disable react/display-name,max-params,react/no-multi-comp */
import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import moment from 'moment'
import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import SpinnerTable from '@global/SpinnerTable'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import RoundedImage from '@global/RoundedImage'
import NothingFound from '@global/NothingFound'
import Placeholder from '@global/Placeholder'
import defaultCompanyPic from '../../../img/placeholders/otto_black_white.jpeg'
export const defaultPageSize = 20

// https://github.com/muffinresearch/payment-icons
import visaLogo from 'payment-icons/min/flat/visa.svg'
import mastercardLogo from 'payment-icons/min/flat/mastercard.svg'
import amexLogo from 'payment-icons/min/flat/amex.svg'
import discoverLogo from 'payment-icons/min/flat/discover.svg'
import creditCardLogo from 'payment-icons/min/flat/default.svg'

export const CHARGE_AUTOMATICALLY = 'charge_automatically'
export const SEND_INVOICE = 'send_invoice'

/* FREQUENCIES FOR BILLING PERIODS */
export const frequencies = [
  { name: 'month', adverb: 'monthly', value: 2 },
  { name: 'quarter', adverb: 'quarterly', value: 5 },
  { name: 'semi-annual', adverb: 'semi-annual', value: 4 }, // Semi-annually Plans doesnt make sense
  { name: 'year', adverb: 'yearly', value: 3 },
]

export const convertIntervalToFrequency = (
  stripeInterval,
  stripeIntervalCount
) => {
  if (stripeInterval === 'month' && stripeIntervalCount === 1)
    return frequencies.filter(freq => freq.name === 'month')[0]
  if (stripeInterval === 'year' && stripeIntervalCount === 1)
    return frequencies.filter(freq => freq.name === 'year')[0]
  if (stripeInterval === 'month' && stripeIntervalCount === 3)
    return frequencies.filter(freq => freq.name === 'quarter')[0]
  if (stripeInterval === 'month' && stripeIntervalCount === 6)
    return frequencies.filter(freq => freq.name === 'semi-annual')[0]

  return frequencies.filter(freq => freq.name === 'month')[0]
}

export const doPlanFrequenciesMatch = (coworksPlan, coworksOrStripePlan) => {
  // This compares the frequencies of [coworksPlan, coworksPlan] or [coworksPlan, stripePlan]
  // to see if they match.

  // checks the param to see if the second one is a stripe or coworks plan.
  const isOtherPlanStripePlan = Boolean(
    coworksOrStripePlan &&
      coworksOrStripePlan.hasOwnProperty('interval_count') &&
      coworksOrStripePlan.hasOwnProperty('object') &&
      coworksOrStripePlan.object === 'plan'
  )

  if (isOtherPlanStripePlan) {
    // comparing coworksPlan to StripePlan
    const stripePlan = coworksOrStripePlan
    return (
      coworksPlan.frequency ===
      convertIntervalToFrequency(stripePlan.interval, stripePlan.interval_count)
        .name
    )
  }
  // comparing two coworks plans
  const otherCoworksPlan = coworksOrStripePlan
  return coworksPlan.frequency === otherCoworksPlan.frequency
}

export function renderInvoiceDetailButton({
  handleDetailClicked,
  stripeObject,
  company,
}) {
  return (
    <TextButton
      className="m-0 p-0"
      onClick={() => handleDetailClicked(stripeObject, company)}
    >
      Details
    </TextButton>
  )
}

export function getTableColumns(
  getTeamFromStripeObject,
  history,
  handleDetailClicked,
  renderCompanyName,
  teamLinkClicked,
  isInvoices
) {
  const tableColumns = [
    {
      columns: [
        {
          Header: 'Id',
          id: 'id',
          maxWidth: 60,
          accessor: object => object.id,
        },
        {
          Header: 'Status',
          id: 'status',
          maxWidth: 90,
          accessor: (object, size, currencySymbol) =>
            Constants.renderInvoiceStatusBadge(object, size, currencySymbol),
        },
        {
          Header: 'Created',
          id: 'created',
          minWidth: 105,
          accessor: object => getStripeObjectCreated(object),
        },
        {
          Header: 'Team / Email',
          id: 'companyId',
          minWidth: 150,
          accessor: object => (
            <td>
              {renderCompanyName(
                object,
                getTeamFromStripeObject(object),
                history,
                teamLinkClicked
              )}
            </td>
          ),
        },
        {
          Header: 'Description',
          id: 'descriptionId',
          minWidth: 200,
          accessor: Constants.getTransactionDetailText,
        },
        {
          Header: 'Type',
          id: 'typeId',
          maxWidth: 90,
          accessor: Constants.getTransactionType,
        },
        {
          Header: 'Amount',
          id: 'amountId',
          accessor: object =>
            `${Constants.getCurrencySymbolFromName(object.currency)} ${Number(
              (object.object === 'invoice'
                ? object.amount_due
                : object.amount) / 100
            ).toFixed(2)}`,
        },
        {
          Header: '',
          id: 'actionId',
          maxWidth: 80,
          accessor: object =>
            renderInvoiceDetailButton({
              handleDetailClicked,
              stripeObject: object,
              company: getTeamFromStripeObject(object),
            }),
        },
      ],
    },
  ]

  if (!isInvoices) {
    tableColumns[0].columns.splice(2, 0, {
      Header: 'Invoice',
      id: 'invoiceId',
      maxWidth: 90,
      accessor: object =>
        object.invoice ? (
          <TextButton
            onClick={() =>
              history.push(
                `/billing/invoices/${
                  typeof object.invoice === 'string'
                    ? object.invoice
                    : object.invoice.id
                }`
              )
            }
          >
            View
          </TextButton>
        ) : null,
    })
  }

  return tableColumns
}

export function getStripeObjectCreated(stripeObject) {
  let date
  const timeFormat = 'MM/DD/YY hh:mm a'
  if (typeof stripeObject === 'object') {
    if (stripeObject.object === 'charge') {
      date = moment.unix(stripeObject.created).format(timeFormat)
    } else if (stripeObject.object === 'invoice') {
      date =
        stripeObject.status_transitions &&
        stripeObject.status_transitions.finalized_at
          ? stripeObject.status_transitions.finalized_at
          : stripeObject.created
      date = moment.unix(date).format(timeFormat)
    }
  }
  return date
}

export function renderCompanyName(
  object,
  team,
  history,
  teamLinkClicked,
  className
) {
  if (team) {
    return (
      <TextButton
        type="button"
        className="m-0 p-0 text-primary"
        style={{ marginBottom: '1rem' }}
        onClick={() => teamLinkClicked(team, history)}
      >
        <div className={className}>
          <RoundedImage
            photo={
              team && team.team_photo
                ? team.team_photo.small_file_url
                : defaultCompanyPic
            }
            alt="Picture"
            size="small"
            className="img-responsive"
          />
          {'  '}
          {team ? team.name : ''}
        </div>
      </TextButton>
    )
  }
  let email
  if (object.receipt_email) {
    email = object.receipt_email
  } else if (object.customer) {
    email = object.customer.email
  }
  return <p className="m-0 p-0">{email ? email : 'Guest'}</p>
}

export function teamLinkClicked(team, history) {
  if (team) {
    NavigationConstants.navigateToTeam({
      history,
      team,
    })
  }
}

export function handleCreateClicked(team, history, type) {
  history.push('/billing/create-charge', { team, type })
}

export function renderDopeTable(
  data,
  pages,
  isFetching,
  getTeamFromStripeObject,
  history,
  onFetchData,
  handleDetailClicked,
  renderCompanyName,
  teamLinkClicked,
  isInvoices,
  objectName,
  objectType
) {
  // console.log('data length', data && data.length ? data.length : 'nothin')
  // console.log('loading', isFetching)
  if (data && data.length === 0) {
    return (
      <Placeholder
        title={`No ${objectType.toLowerCase()} ${objectName}`}
        icon={'dollar-sign'}
        subtext1={`You currently have no ${objectType.toLowerCase()} ${objectName} in this community.`}
      />
    )
  }
  return (
    <ReactTable
      manual // pagination is handled server side
      showPaginationTop={false}
      sortable={false}
      showPageSizeOptions={false}
      noDataText=""
      defaultPageSize={defaultPageSize}
      pages={pages}
      columns={getTableColumns(
        getTeamFromStripeObject,
        history,
        handleDetailClicked,
        renderCompanyName,
        teamLinkClicked,
        isInvoices
      )} // tells the table how to read the data
      //data={stripeObjects && stripeObjects.data ? stripeObjects.data : []} // the data its interested in displaying
      data={data} // the data its interested in displaying
      loading={isFetching}
      LoadingComponent={SpinnerTable}
      onFetchData={onFetchData}
      className="-highlight"
      showPageJump={false}
    />
  )
}

export function addFeesToInvoice(inv, prefs, customer) {
  // this function will add stripe fees and automatic invoice amounts to invoice so that
  // the front end can show them before they are actually added.
  if (inv.billing_reason === 'upcoming' && inv.status === 'draft') {
    const invoice = JSON.parse(JSON.stringify(inv))
    const itemsToAdd = createInvoiceFeesArray({
      amountInDollars: convertToDollars(invoice.amount_due),
      prefs,
      returnEmpty: false,
      customer,
      intendedPaymentSource: null,
    })
    if (itemsToAdd) {
      const items = invoice.lines.data.concat(itemsToAdd)
      invoice.lines.data = items
      itemsToAdd.forEach(feeItem => {
        invoice.amount_due += Number(feeItem.amount)
      })
    }
    // if (
    //   invoice.discount &&
    //   invoice.discount.coupon &&
    //   invoice.discount.coupon.valid
    // ) {
    //   // calc new invoice total
    //   invoice.amount_due -= invoice.discount.coupon.amount_off
    // }
    return invoice
  }
  return inv
}

function convertToCents(amountInDollars) {
  if (!amountInDollars) {
    return 0
  }
  return Number(amountInDollars) * 100
}

function convertToDollars(amountInCents) {
  if (!amountInCents) {
    return 0
  }
  return Number(amountInCents) / 100
}

export function createInvoiceFeesArray({
  amountInDollars,
  prefs,
  returnEmpty = false,
  customer = null,
  intendedPaymentSource = null,
  feature = 'memberBilling',
}) {
  // this function will create an array of invoice items
  // this is used by the preview invoice and the
  // subscription total box in the subscription create and edit.

  // intendedPaymentSource 'card' 'bank_account'

  // "feature" is [memberBilling, externalBookings, dayPasses]

  // Important: This function accepts and receives items in cents.
  if ((!amountInDollars && amountInDollars !== 0) || !prefs) {
    return returnEmpty ? [] : null
  }
  let invoiceAmountInCents = convertToCents(amountInDollars)
  let amountToAddAfterLoopInCents = 0

  const itemsToAdd = []

  // -------- Automated Invoice Fees --------
  if (prefs.automatic_invoice_fees) {
    const fees = JSON.parse(prefs.automatic_invoice_fees)
    if (fees.length > 0) {
      fees.forEach(feeItem => {
        const name = feeItem.name
        const amountInDollars = feeItem.amount_in_dollars
        const percentageInDecimals = feeItem.percentage_in_decimals
        let amountToAddInCents = 0
        if (amountInDollars) {
          amountToAddInCents = convertToCents(
            Number(amountInDollars).toFixed(2)
          ) // we need to send cents
        } else if (percentageInDecimals) {
          amountToAddInCents = Number(
            invoiceAmountInCents * percentageInDecimals
          )
        }
        amountToAddAfterLoopInCents += Number(amountToAddInCents)
        itemsToAdd.push({
          amount: Number(amountToAddInCents),
          amountInDollars: convertToDollars(amountToAddInCents),
          quantity: 1,
          description: name,
        })
      })
      invoiceAmountInCents += amountToAddAfterLoopInCents
    }
  }
  let myChargeableSource = null

  // check for chargeable source before showing appended fees!
  if (customer) {
    const { defaultChargeableSource } = getCustomerDefaultSource(customer, true)
    if (defaultChargeableSource) {
      myChargeableSource = defaultChargeableSource
    }
  }
  // -------- MemberBilling Credit Card Fees --------
  // determine if we need to render credit card fees
  if (
    (myChargeableSource && myChargeableSource.object === 'card') ||
    intendedPaymentSource === 'card'
  ) {
    // Only if payment source is card do we continue
    if (
      (feature === 'memberBilling' && prefs.pass_cc_fee_to_member) ||
      (feature === 'externalBookings' &&
        prefs.external_bookings &&
        prefs.external_bookings.billing &&
        prefs.external_bookings.billing.pass_cc_fee) ||
      (feature === 'dayPasses' &&
        prefs.day_pass &&
        prefs.day_pass.billing &&
        prefs.day_pass.billing.pass_cc_fee)
    ) {
      // pass fees to member
      const amountToAddInCents = invoiceAmountInCents * 0.029 + 30
      itemsToAdd.push({
        amount: Number(amountToAddInCents),
        amountInDollars: Number(convertToDollars(amountToAddInCents)).toFixed(
          2
        ),
        description: 'Transaction Fee',
        quantity: 1,
      })
    }
  }
  // END -------- MemberBilling Credit Card Fees --------

  // -------- MemberBilling ACH Bank Account Fees --------
  if (
    prefs.pass_ach_fee_to_member &&
    ((myChargeableSource && myChargeableSource.object === 'bank_account') ||
      intendedPaymentSource === 'bank_account')
  ) {
    const amountToAddInCents = invoiceAmountInCents * 0.008
    itemsToAdd.push({
      amount: Number(amountToAddInCents),
      amountInDollars: Number(convertToDollars(amountToAddInCents)).toFixed(2),
      description: 'Transaction Fee',
      quantity: 1,
    })
  }
  // END -------- MemberBilling ACH Bank Account Fees --------
  return itemsToAdd
}

// This function will extract the default source from the customer object
export function getCustomerDefaultSource(customer, returnInObject = false) {
  if (!customer) return {}
  let defaultCard,
    defaultBank,
    defaultSource,
    defaultChargeableSource,
    secondaryChargeableSource

  // look to get th default card
  for (let counter = 0; counter < customer.sources.data.length; counter++) {
    const source = customer.sources.data[counter]
    if (source.id === customer.default_source) {
      if (
        (source.object === 'source' && source.type === 'ach_credit_transfer') ||
        source.object === 'ach_credit_transfer'
      ) {
        // fuck parsing the default we're fucked
      } else if (source.object === 'card') {
        defaultSource = customer.sources.data[counter]
        defaultCard = defaultSource
        defaultChargeableSource = defaultSource
      } else if (source.object === 'bank_account') {
        defaultSource = customer.sources.data[counter]
        defaultBank = defaultSource
        defaultChargeableSource = defaultSource
      } else if (source.object === 'source' && source.card) {
        defaultSource = customer.sources.data[counter]
        defaultSource = source
        defaultCard = source.card
        defaultChargeableSource = source.card
      } else if (
        source.object === 'source' &&
        source.bank_account &&
        source.bank_account.status === 'verified'
      ) {
        defaultSource = customer.sources.data[counter]
        defaultBank = source
        defaultChargeableSource = source
      }
    }
  }
  // loop through again to get secondary card...
  // needs to happen in two loops because we don't always get the default card first...
  for (let counter = 0; counter < customer.sources.data.length; counter++) {
    const source = customer.sources.data[counter]
    if (source.id !== customer.default_source) {
      if (
        // parsing the secondary source!!
        source.object === 'source' &&
        source.bank_account &&
        source.bank_account.status === 'verified'
      ) {
        // if this source is a bank account and its verified, go ahead and take it.
        secondaryChargeableSource = source
      } else if (source.object === 'card' || source.card) {
        // if this source is a card, let's attempt to parse it

        if (!defaultSource) {
          secondaryChargeableSource = source
        } else {
          // is the default source a source wrapped card?
          let tempDefaultCard = null
          if (
            defaultSource &&
            defaultSource.object === 'source' &&
            defaultSource.card
          ) {
            tempDefaultCard = defaultSource.card
          }
          // is the default source a card?
          if (!tempDefaultCard && defaultSource.object === 'card') {
            tempDefaultCard = defaultSource
          }

          // if the default source is a valid bank account, then take this card as secondary!
          if (
            !tempDefaultCard &&
            defaultSource &&
            defaultSource.object === 'bank_account' &&
            defaultSource.status === 'verified'
          ) {
            secondaryChargeableSource = source
          }

          // checking the source against the default if its a card and making
          // sure they're not the same card number!
          const tempCard = source.object === 'card' ? source : source.card
          if (
            tempCard &&
            tempDefaultCard &&
            tempCard.last4 &&
            tempDefaultCard.last4 &&
            tempCard.last4 !== tempDefaultCard.last4
          ) {
            secondaryChargeableSource = source
          }
        }
      }
    }
  }

  if (returnInObject) {
    // this allows the results to be parsed
    // console.log({
    //   defaultSource,
    //   defaultCard,
    //   defaultBank,
    //   defaultChargeableSource,
    //   secondaryChargeableSource,
    // })
    return {
      defaultSource,
      defaultCard,
      defaultBank,
      defaultChargeableSource,
      secondaryChargeableSource,
    }
  }
  return defaultSource
}

export function shouldDisableChargeNow(communityPreferences) {
  // Create Invoice form
  // It will disable the Charge now button if there are invoice fees in the preferences
  let billingType = 'charge_automatically'
  let chargeNowDisabled = false

  const shouldPassCCFees = communityPreferences.pass_cc_fee_to_member
  const shouldPassACHFees = communityPreferences.pass_ach_fee_to_member
  const invoiceFees = communityPreferences.automatic_invoice_fees
  if (
    (shouldPassCCFees !== null &&
      shouldPassCCFees !== undefined &&
      shouldPassCCFees === true) ||
    (shouldPassACHFees !== null &&
      shouldPassACHFees !== undefined &&
      shouldPassACHFees === true) ||
    invoiceFees
  ) {
    chargeNowDisabled = true
    billingType = 'send_invoice'
  }
  return { chargeNowDisabled, billingType }
}

export const getIntendedPaymentSource = (billingType, captureCard) => {
  return billingType === CHARGE_AUTOMATICALLY ||
    (billingType === SEND_INVOICE && captureCard === 'true')
    ? 'card'
    : null
}

export function getCreditCardLogo(brand) {
  switch (brand.toLowerCase()) {
    case 'visa':
      return visaLogo
    case 'mastercard':
      return mastercardLogo
    case 'amex':
      return amexLogo
    case 'discover':
      return discoverLogo
    default:
      return creditCardLogo
  }
}

const standardCurrencies = ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF']
export const currencyCountryStripePairs = [
  {
    countryName: 'Australia (AU)',
    countrySymbol: 'AU',
    currencyPairs: ['AUD'],
  },
  {
    countryName: 'Austria (AT)',
    countrySymbol: 'AT',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Belgium (BE)',
    countrySymbol: 'BE',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Brazil (BR)',
    countrySymbol: 'BR',
    currencyPairs: ['BRL'],
  },
  {
    countryName: 'Canada (CA)',
    countrySymbol: 'CA',
    currencyPairs: ['CAD', 'USD'],
  },
  {
    countryName: 'Denmark (DK)',
    countrySymbol: 'DK',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Estonia (EE)',
    countrySymbol: 'EE',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Finland (FI)',
    countrySymbol: 'FI',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'France (FR)',
    countrySymbol: 'FR',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Germany (DE)',
    countrySymbol: 'DE',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Greece (GR)',
    countrySymbol: 'GR',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Hong Kong (HK)',
    countrySymbol: 'HK',
    currencyPairs: ['HKD'],
  },
  {
    countryName: 'India (IN)',
    countrySymbol: 'IN',
    currencyPairs: ['INR'],
  },
  {
    countryName: 'Ireland (IE)',
    countrySymbol: 'IE',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Italy (IT)',
    countrySymbol: 'IT',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Japan (JP)',
    countrySymbol: 'JP',
    currencyPairs: ['JPY'],
  },
  {
    countryName: 'Latvia (LV)',
    countrySymbol: 'LV',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Lithuania (LT)',
    countrySymbol: 'LT',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Luxembourg (LU)',
    countrySymbol: 'LU',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Malaysia (MY)',
    countrySymbol: 'MY',
    currencyPairs: ['MYR'],
  },
  {
    countryName: 'Mexico (MX)',
    countrySymbol: 'MX',
    currencyPairs: ['MXN'],
  },
  {
    countryName: 'Netherlands (NL)',
    countrySymbol: 'NL',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'New Zealand (NZ)',
    countrySymbol: 'NZ',
    currencyPairs: ['NZD'],
  },
  {
    countryName: 'Norway (NO)',
    countrySymbol: 'NO',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Poland (PL)',
    countrySymbol: 'PL',
    currencyPairs: standardCurrencies.concat('PLN'),
  },
  {
    countryName: 'Portugal (PT)',
    countrySymbol: 'PT',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Singapore (SG)',
    countrySymbol: 'SG',
    currencyPairs: ['SGD'],
  },
  {
    countryName: 'Slovakia (SK)',
    countrySymbol: 'SK',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Slovenia (SI)',
    countrySymbol: 'SI',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Spain (ES)',
    countrySymbol: 'ES',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Sweden (SE)',
    countrySymbol: 'SE',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'Switzerland (CH)',
    countrySymbol: 'CH',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'United Kingdom (GB)',
    countrySymbol: 'GB',
    currencyPairs: standardCurrencies,
  },
  {
    countryName: 'United States (US)',
    countrySymbol: 'US',
    currencyPairs: ['USD'],
  },
]
