/* eslint-disable react/prop-types */
import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

export default function EditNotesModal({
  showEditNotesModal,
  toggleEditNotesModal,
  renderNotesFields,
  isFetching,
  saveNotes,
}) {
  return (
    <Modal
      isOpen={showEditNotesModal}
      toggle={toggleEditNotesModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleEditNotesModal}>
        Edit Internal Notes
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Row>
            <div className="col-12">{renderNotesFields()}</div>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleEditNotesModal}>
          Cancel
        </Button>
        <Button color="primary" disabled={isFetching} onClick={saveNotes}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}
EditNotesModal.displayName = 'EditNotesModal'
