import React, { useRef } from 'react'
import { LabelStyled } from '@global/Form/FormComponents'
import { Button } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import InputWithoutBorders from '@global/Form/InputWithoutBorders'

// const useFocus = () => {
//   const htmlElRef = useRef(null)
//   const setFocus = () => {
//     // eslint-disable-next-line no-unused-expressions
//     htmlElRef.current && htmlElRef.current.focus()
//   }

//   return [htmlElRef, setFocus]
// }

export function renderCustomField({
  customFieldsObject,
  handleCustomFieldsChange,
  setFieldValue,
  isEditing,
  handleKeyPress,
}) {
  if (
    !customFieldsObject ||
    !customFieldsObject.fields ||
    customFieldsObject.fields.length === 0 ||
    !handleCustomFieldsChange
  ) {
    return isEditing ? null : <span className="text-muted">None</span>
  }
  const inputs = []
  const theValues = customFieldsObject.fields
  for (let index = 0; index < theValues.length; index++) {
    const obj = theValues[index]
    const shouldFocus = index === theValues.length - 1
    inputs.push(
      <React.Fragment>
        <div className="row my-3">
          <div className="col-3">
            {!isEditing ? (
              <LabelStyled htmlFor={obj.field_name}>
                {obj.field_name}
              </LabelStyled>
            ) : (
              <InputWithoutBorders
                id={`field_name-${index}`}
                key={`field_name-${index}`}
                name={`field_name-${index}`}
                value={obj.field_name}
                type="text"
                className={'form-control w-100'}
                placeholder={'Field Name'}
                onKeyPress={handleKeyPress}
                onChange={event =>
                  handleCustomFieldsChange({
                    isChangingKey: true,
                    event,
                    index,
                    setFieldValue,
                    customFieldsObject,
                  })
                }
              />
            )}
          </div>
          <div className="col-8">
            {!isEditing ? (
              <div>
                {obj.field_value ? (
                  obj.field_value
                ) : (
                  <div className="text-muted">nothing</div>
                )}
              </div>
            ) : (
              <input
                id={`field_value-${index}`}
                key={`field_value-${index}`}
                name={`field_value-${index}`}
                value={obj.field_value}
                type="text"
                className={'form-control w-100'}
                placeholder={'Value'}
                onKeyPress={handleKeyPress}
                onChange={event =>
                  handleCustomFieldsChange({
                    isChangingKey: false,
                    index,
                    event,
                    setFieldValue,
                    customFieldsObject,
                  })
                }
              />
            )}
          </div>
          <div className="col-1">
            {isEditing && (
              <Button
                id={`trash-${index}`}
                key={`trash-${index}`}
                name={`trash-${index}`}
                className="btn-link"
                onClick={event =>
                  handleRemoveCustomField({
                    indexToRemove: index,
                    setFieldValue,
                    customFieldsObject,
                  })
                }
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return inputs
  // return customFields.``
}

export function handleCustomFieldsChange({
  isChangingKey,
  event,
  index,
  setFieldValue,
  customFieldsObject,
}) {
  if (!event || !setFieldValue || !customFieldsObject) {
    return null
  }
  const newCustomFields = updateCustomFieldAttribute({
    isChangingKey,
    index,
    inputValue: event.target.value,
    customFieldsObject,
  })
  setFieldValue('customFieldsObject', newCustomFields)
  return newCustomFields
}

export function handleAddCustomField(customFieldsObject) {
  if (!customFieldsObject) {
    return null
  }
  const newCustomFields = updateCustomFieldAttribute({
    isChangingKey: true,
    inputValue: '',
    customFieldsObject,
    index: null,
  })
  return newCustomFields
}

export function handleRemoveCustomField({
  customFieldsObject,
  indexToRemove,
  setFieldValue,
}) {
  const customFieldsCopy = JSON.parse(JSON.stringify(customFieldsObject))
  customFieldsCopy.fields.splice(indexToRemove, 1)
  if (setFieldValue) {
    setFieldValue('customFieldsObject', customFieldsCopy)
  }
  return customFieldsCopy
}

export function updateCustomFieldAttribute({
  isChangingKey,
  inputValue,
  customFieldsObject,
  index,
}) {
  // as a pair of inputs, both of the inputs have reserved attributes name, value
  // input name='key1' value='key1' ||| input name='value1' value='value1'
  // represented -> {name: 'key1', value: 'value1'}
  // you need to make sure you know which you're editing because names
  // are quite confusing here.
  // isChangingKey helps with letting you know if you're changing the key
  // value of the representation or the value of the representation, not the input // /// reserved names
  const customFieldsCopy = JSON.parse(JSON.stringify(customFieldsObject))
  if (
    index !== null && // if (index && ) wasn't working??? js wtf?
    index !== undefined &&
    customFieldsCopy.fields.length > index
  ) {
    if (isChangingKey) {
      customFieldsCopy.fields[index].field_name = inputValue
    } else {
      customFieldsCopy.fields[index].field_value = inputValue
    }
  } else {
    customFieldsCopy.fields.push({ field_name: inputValue, field_value: '' })
  }
  return customFieldsCopy
}
