import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { FETCHING_TEAMS } from '@global/Constants/FetchingConstants'

// ACTION CREATORS
export function clearTeams() {
  return { type: actionTypes.CLEAR_TEAMS }
}

export function setTeams(teams, meta = null) {
  return { type: actionTypes.SET_TEAMS, teams, meta }
}
export function setTeam(team) {
  return { type: actionTypes.SET_TEAM, team }
}
export function setInactiveTeams(teams, meta = null) {
  return { type: actionTypes.SET_INACTIVE_TEAMS, teams, meta }
}
export function successCreatingTeam(team) {
  return { type: actionTypes.CREATE_TEAM, team }
}
export function successUpdatingTeam(team) {
  return { type: actionTypes.UPDATE_TEAM, team }
}
export function successRemovingTeam(team) {
  return { type: actionTypes.DELETE_TEAM, team }
}
export function successChangingAdmin(team) {
  return { type: actionTypes.UPDATE_TEAM, team }
}
export function successDeactivatingTeam(team) {
  return { type: actionTypes.DEACTIVATE_TEAM, team }
}
export function successActivatingTeam(team) {
  return { type: actionTypes.ACTIVATE_TEAM, team }
}
export function successRemovingCoworksPlan(team) {
  return { type: actionTypes.UPDATE_TEAM, team }
}
export function successAddingCoworksPlan(team) {
  return { type: actionTypes.UPDATE_TEAM, team }
}
export function successCreatingTeamSubscription(customer) {
  return { type: actionTypes.SET_STRIPE_CUSTOMER, customer }
}

// ACTION DISPATCHERS
export const createTeam = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createTeam(params)
      .then(response => {
        dispatch(successCreatingTeam(response.data))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export function getTeams(campusId, params, fetchingKey = FETCHING_TEAMS) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingKey))
    return apiService
      .getTeams(campusId, params)
      .then(response => {
        if (params.status === 'inactive') {
          dispatch(setInactiveTeams(response.teams, response.meta))
        } else {
          dispatch(setTeams(response.teams, response.meta))
        }

        dispatch(uiActions.updateFetchingStatus(false, fetchingKey))
      })
      .catch(err =>
        dispatch(uiActions.updateFetchingStatus(false, fetchingKey))
      )
  }
}
export function getDirectoryTeams(
  campusId,
  params,
  fetchingKey = FETCHING_TEAMS
) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true, fetchingKey))
    return (
      apiService
        .getDirectoryTeams(campusId, params)
        .then(response => {
          if ((params.status && params.status === 'active') || !params.status) {
            dispatch(setTeams(response.teams, response.meta))
          } else if (params.status && params.status === 'inactive') {
            dispatch(setInactiveTeams(response.teams, response.meta))
          }
        })
        // .catch(err => )
        .finally(() =>
          dispatch(uiActions.updateFetchingStatus(false, fetchingKey))
        )
    )
  }
}
export const getTeamMembers = (teamId, params, type) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, type))
    apiService
      .getTeamMembers(teamId, params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() => dispatch(uiActions.updateFetchingStatus(false, type)))
  })
}
export const search = (campusId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    //dispatch(uiActions.updateFetchingStatus(true));
    apiService
      .getTeams(campusId, params)
      .then(response => {
        if ((params.status && params.status === 'active') || !params.status) {
          dispatch(setTeams(response.teams, response.meta))
        } else if (params.status && params.status === 'inactive') {
          dispatch(setInactiveTeams(response.teams, response.meta))
        }
        //ispatch(uiActions.updateFetchingStatus(false));
        resolve(response)
      })
      .catch(err => {
        reject(err)
        //dispatch(uiActions.updateFetchingStatus(false));
      })
  })
}

// Get team thicck

export const getTeam = (teamId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .getTeam(teamId, params)
      .then(response => {
        dispatch(successUpdatingTeam(response.team))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.team)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const updateTeam = team => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateTeam(team)
      .then(response => {
        dispatch(successUpdatingTeam(response.team))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export function changeCampus(team, params) {
  return dispatch => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .changeCampus(team, params)
      .then(response => dispatch(successRemovingTeam(response.team)))
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
      .catch(err => dispatch(uiActions.updateFetchingStatus(false)))
  }
}
// Use this function to archive and unarchive a team
export const deactivateTeam = (teamId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .deactivateTeam(teamId, params)
      .then(response => {
        dispatch(successDeactivatingTeam(response.team))
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}
export const activateTeam = (teamId, params) => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .activateTeam(teamId, params)
      .then(response => {
        dispatch(successActivatingTeam(response.team))
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}
export const changeAdmin = team => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .changeAdmin(team)
      .then(response => {
        dispatch(successChangingAdmin(response.team))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}
export const removeCoworksPlan = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .removeCoworksPlan(params)
      .then(response => {
        dispatch(successRemovingCoworksPlan(response.team))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response.team)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const addCoworksPlan = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .addCoworksPlan(params)
      .then(response => {
        dispatch(successAddingCoworksPlan(response.team))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const createTeamSubscription = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createTeamSubscription(params)
      .then(response => {
        dispatch(successCreatingTeamSubscription(response.customer))
        dispatch(successUpdatingTeam(response.team))
        resolve({ team: response.team, customer: response.customer })
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
