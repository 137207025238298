/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import styled, { withTheme } from 'styled-components'

import { Alert } from 'reactstrap'
import { IntercomAPI } from 'react-intercom'
import Button, { TextButton } from '@global/Base/Button/ButtonStyled'

import moment from 'moment'

const AlertStyled = styled(Alert)`
  margin-bottom: 0px;
`

function AlertsContainer({ activeCommunity, history, stripeAccount }) {
  const [showBillingAlert, setShowBillingAlert] = useState(false)
  const [
    showAccountTerminationAlert,
    setShowAccountTerminationAlert,
  ] = useState(false)
  const [showTrialAccountAlert, setShowTrialAccountAlert] = useState(false)
  const [
    showPayoutAccountMissingAlert,
    setShowPayoutAccountMissingAlert,
  ] = useState(false)
  const [
    showClaimStripeAccountAlert,
    setShowClaimStripeAccountAlert,
  ] = useState(false)

  useEffect(() => {
    // re-process the alerts when we have changes in the props
    handleAlertProcessing(activeCommunity, stripeAccount)
  }, [stripeAccount, activeCommunity])

  function handleAlertProcessing(activeCommunity, stripeAccount) {
    // Note: if the account is a demo account, all of these alerts are disabled.
    // This function will also check to make sure the status of the modals are set before  setting them to false
    //    to avoid uneccessary rendering.
    const communityPreference = activeCommunity.community_preference

    if (communityPreference) {
      // parse the stripe account if provided.
      if (stripeAccount) {
        if (!stripeAccount.charges_enabled) {
          if (!showClaimStripeAccountAlert) setShowClaimStripeAccountAlert(true)
        } else {
          // should we show a missing payout account for stripe alert?
          // charges are enabled - only show the claim alert when charges are NOT enabled.
          if (showClaimStripeAccountAlert) setShowClaimStripeAccountAlert(false)

          // should we show an incorrectly setup stripe alert?
          if (!stripeAccount.payouts_enabled) {
            // eslint-disable-next-line max-depth
            if (!showPayoutAccountMissingAlert) {
              setShowPayoutAccountMissingAlert(true)
            }
          } else if (showPayoutAccountMissingAlert) {
            setShowPayoutAccountMissingAlert(false)
          }
        }
      }

      // state in which the alert should be shown to connect stripe account
      if (
        communityPreference.billing_enabled &&
        !activeCommunity.stripe_account_id
      ) {
        if (!showBillingAlert) {
          setShowBillingAlert(true)
        }
      } else if (showBillingAlert) {
        setShowBillingAlert(false)
      }

      // Trial Account ending Warning
      if (communityPreference.status === 'trialing') {
        // if this is visible, we want to disable all other alerts.
        setShowBillingAlert(false)
        setShowAccountTerminationAlert(false)
        setShowPayoutAccountMissingAlert(false)
        setShowClaimStripeAccountAlert(false)

        if (showTrialAccountAlert !== true) {
          setShowTrialAccountAlert(true)
        }
      } else if (showTrialAccountAlert === true) {
        // if we're not longer trialing, check to see if we were trialing
        setShowTrialAccountAlert(false)
      }

      // Account Termination (delinquent) Warning
      if (communityPreference.status === 'delinquent_by_subscription') {
        // if this is visible, we want to disable all other alerts.
        setShowBillingAlert(false)
        setShowTrialAccountAlert(false)
        setShowPayoutAccountMissingAlert(false)
        setShowClaimStripeAccountAlert(false)
        if (showAccountTerminationAlert !== true) {
          setShowAccountTerminationAlert(true)
        }
      } else if (showAccountTerminationAlert === true) {
        setShowAccountTerminationAlert(false)
      }

      // we don't want to show anything when a demo account!
      if (communityPreference.status === 'demo_account') {
        setShowAccountTerminationAlert(false)
        setShowBillingAlert(false)
        setShowClaimStripeAccountAlert(false)
        setShowPayoutAccountMissingAlert(false)
        setShowTrialAccountAlert(false)
      }
    }
  }

  return (
    <React.Fragment>
      {renderTrialAccountAlert(showTrialAccountAlert, activeCommunity)}
      {renderBillingAlert(showBillingAlert, history)}
      {renderAccountTerminationAlert(
        showAccountTerminationAlert,
        activeCommunity
      )}
      {renderSetupStripePayoutAccountAlert(showPayoutAccountMissingAlert)}
      {renderClaimStripeAccountAlert(
        showClaimStripeAccountAlert,
        stripeAccount
      )}
    </React.Fragment>
  )
}

function renderBillingAlert(shouldShow, history) {
  if (shouldShow) {
    return renderAlert({
      alertText:
        "Looks like you're ready to have your Stripe account connected! Please connect now to proceed with billing setup.",
      handleAlertButtonClick: () => {
        history.push('/community/stripe')
      },
      alertButtonText: 'Connect Stripe',
      alertType: 'info',
    })
  }
  return null
}
function renderTrialAccountAlert(shouldShow, activeCommunity) {
  const prefs = activeCommunity.community_preference
  if (shouldShow && prefs.trial_end_date) {
    const days = moment(prefs.trial_end_date).diff(moment(), 'days')
    const trailRemainingMessage = `Your free trial ends in ${days} days.`
    const trialOverMessage =
      'Your free trial has ended. Please contact Support.'
    let message = trailRemainingMessage
    let color = 'info'
    const trialEnded = days < 1
    if (trialEnded) {
      message = trialOverMessage
      color = 'warning'
    }
    return renderAlert({
      alertText: message,
      handleAlertButtonClick: () => {
        if (trialEnded) {
          IntercomAPI(
            'showNewMessage',
            `My trial expired ${
              days * -1
            } days ago, but I am still interested in buying!`
          )
        } else {
          IntercomAPI('showNewMessage', `I am ready to signup for Coworks!`)
        }
      },
      alertButtonText: days > 0 ? 'I am ready to signup' : 'Contact Support',
      alertType: color,
    })
  }
  return null
}
function renderSetupStripePayoutAccountAlert(shouldShow) {
  if (shouldShow) {
    return renderAlert({
      alertText:
        "Looks like you've claimed your Stripe account, but don't have a payout account setup yet!",
      alertButtonText: 'Setup Payout Account',
      alertType: 'info',
      handleAlertButtonClick: () => {
        window.open('https://dashboard.stripe.com/account/payouts')
      },
    })
  }
  return null
}
function renderClaimStripeAccountAlert(shouldShow, stripeAccount) {
  if (shouldShow) {
    return renderAlert({
      alertText: `Looks like a Stripe account has been generated for you, but you have not claimed it! Please check your ${
        stripeAccount && stripeAccount.email ? stripeAccount.email : ''
      } email inbox for the activation email.`,
      alertButtonText: 'I need help.',
      handleAlertButtonClick: () => {
        IntercomAPI(
          'showNewMessage',
          "I still need to claim my Stripe account, but I don't know how!"
        )
      },
      alertType: 'info',
    })
  }
  return null
}

function renderAccountTerminationAlert(shouldShow, activeCommunity) {
  const prefs = activeCommunity.community_preference
  if (shouldShow) {
    let date = 'soon'
    if (prefs.account_terminated_at) {
      date = moment(prefs.account_terminated_at).format('MMMM D, YYYY')
    }
    return renderAlert({
      alertText: `Hey there! Looks like we haven't received payment for your Coworks Subscription. Your account will automatically be deactivated ${date} unless you become current with your payments!`,
      alertType: 'warning',
      alertButtonText: 'I need help.',
      handleAlertButtonClick: () => {
        IntercomAPI(
          'showNewMessage',
          'My account is saying it will be deactivated unless I become current with my payments, but I need help with that!'
        )
      },
    })
  }
  return null
}

// eslint-disable-next-line react/display-name
function renderAlert({
  alertText,
  alertButtonText,
  alertType,
  handleAlertButtonClick,
}) {
  return (
    <AlertStyled color={alertType ? alertType : 'info'}>
      {alertText}
      {alertButtonText && (
        <TextButton
          className="m-0"
          color="primary"
          onClick={handleAlertButtonClick}
        >
          {alertButtonText}
        </TextButton>
      )}
    </AlertStyled>
  )
}

renderAlert.propTypes = {
  alertText: PropTypes.string,
  alertButtonText: PropTypes.string,
  alertType: PropTypes.string,
  handleAlertButtonClick: PropTypes.func,
}

AlertsContainer.propTypes = {
  activeCommunity: PropTypes.object,
  history: PropTypes.object,
  stripeAccount: PropTypes.object,
  setAlertShown: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    stripeAccount: state.stripe.account,
    activeCommunity: state.ui.activeCommunity,
    activeCampus: state.ui.activeCampus,
  }
}

AlertsContainer.displayName = 'AlertsContainer'

export default connect(mapStateToProps, null)(AlertsContainer)
