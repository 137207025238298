import {
  CHECKIN_ENUM,
  CHECKIN_MEMBER
} from '@global/Constants/CheckinsConstants'


export function resolvePreferenceFromCommunity(community) {
  if (!community) {
    return null
  }
  return community.community_preference
}

export function resolveCheckins(checkins, type = CHECKIN_MEMBER) {
  const checkinType = CHECKIN_ENUM[type];
  let checkinsData = null;
  let checkinsPagination = null;

  const stateData = checkins[checkinType];
  if (stateData) {
    checkinsData = stateData.data
    if (stateData.meta) {
      checkinsPagination = stateData.meta.pagination
    }
  }

  return {
    data: checkinsData,
    pagination: checkinsPagination
  }
}
