/* eslint-disable react/display-name,react/prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import PageMessage from '@global/PageMessage'

const FeatureToggle = feat => WrappedComp => {
  const Comp = ({
    history,
    match,
    publicCampus,
    publicCommunity,
    coworks_community_actions,
    ...other
  }) => {
    const checkFeature = () => {
      if (
        feat !== undefined &&
        publicCampus &&
        publicCampus.preferences &&
        publicCampus.preferences.website_forms &&
        publicCampus.preferences.website_forms[feat]
      ) {
        const featureAllowed =
          publicCampus.preferences.website_forms[feat].enabled
        if (!featureAllowed) {
          return (
            <PageMessage
              icon="lock"
              header="Better luck next time..."
              subheader="Looks like this feature has been disabled"
              text={`Try contacting the campus.`}
              onClick={history.goBack}
            />
          )
        }
      }
      return (
        <WrappedComp
          publicCommunity={publicCommunity}
          publicCampus={publicCampus}
          history={history}
          match={match}
          {...other}
        />
      )
    }
    return checkFeature()
  }

  Comp.propTypes = {
    publicCampus: PropTypes.object,
  }

  function mapStateToProps(state) {
    return {
      publicCampus: state.ui.publicCampus,
      isFetching: state.ui && state.ui.isFetching ? state.ui.isFetching : false,
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      coworks_community_actions: bindActionCreators(
        coworksCommunityActions,
        dispatch
      ),
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Comp)
}

export default FeatureToggle
