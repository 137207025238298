import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import Billing from './Billing'
import InvoiceDetail from './InvoiceDetail/InvoiceDetail'
import CreateInvoice from './CreateInvoice'

function BillingContainer({ role, user, theme }) {
  const { path, url } = useRouteMatch()
  return (
    <Switch>
      {/* <Route exact path={`${path}`}>
        <Redirect to={`${url}/invoices`} />
      </Route> */}

      <Route
        exact
        path={`${path}/create-charge`}
        render={props => (
          <CreateInvoice
            role={role}
            user={user}
            theme={theme}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${path}/:tabId/:objectId`}
        render={props => (
          <InvoiceDetail
            role={role}
            user={user}
            theme={theme}
            {...props}
          />
        )}
      />
      <Route
        path={`${path}`}
        render={props => (
          <Billing
            role={role}
            user={user}
            theme={theme}
            {...props}
          />
        )}
      />
    </Switch>
  )
}

BillingContainer.displayName = 'Billing Container'
BillingContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  role: PropTypes.string,
  user: PropTypes.object,
  theme: PropTypes.object,
}

export default BillingContainer
