/* eslint-disable max-depth,max-statements */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, UncontrolledTooltip } from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { bindActionCreators } from 'redux'
import * as teamActions from '../../reduxActions/teamActions'
import * as stripeActions from '../../reduxActions/stripeActions'
import * as PaymentConstants from '@global/Constants/PaymentConstants'

import { logO } from '@global/Constants'
import { toast } from 'react-toastify'
import { convertStripeErrorToMessage } from '@global/ErrorFactory'
import { ButtonStyled } from '@global/Base/Button/ButtonStyled'
import * as _ from 'lodash'
class BillingTable extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    type: PropTypes.string.isRequired,
    objectName: PropTypes.string.isRequired,
    apiQuery: PropTypes.func.isRequired,
    apiParams: PropTypes.object.isRequired,
    team: PropTypes.object, // this is for when this form is embeded in a team's manage page
    user: PropTypes.object,
    activeCommunity: PropTypes.object,
    disableActions: PropTypes.bool, // this comes from when the team this is nested in is inactive
    // transactions: PropTypes.object,
    isFetching: PropTypes.bool,
    handleDetailClicked: PropTypes.func.isRequired,
    stripe_actions: PropTypes.object,
    handleLastInvoiceReceived: PropTypes.func,
    getTeamFromStripeObject: PropTypes.func.isRequired, // helper function to find team associated with invoice/charge
    shouldRefreshPayments: PropTypes.bool,
    stripeData: PropTypes.object,
    dataResolver: PropTypes.func.isRequired,
    setCount: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    isInvoices: PropTypes.bool.isRequired,
  }
  static defaultProps = {
    dataResolver: response => {
      return response.charges
    },
    isInvoices: true,
    objectName: 'Transactions',
  }
  constructor(props) {
    super(props)
    this.state = {
      pageData: [],
      currentPage: 0,
      initialFetch: true,
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //todo replace with static getDerivedStateFromProps(props, state) {
    if (nextProps.shouldRefreshPayments !== this.props.shouldRefreshPayments) {
      this.setState(
        {
          pageData: [],
          currentPage: 0,
        },
        () => {
          this.onFetchData(this.state)
        }
      )
    }
  }
  onFetchData = (state, instance) => {
    const {
      type,
      stripeData,
      team,
      dataResolver,
      setCount,
      apiParams: params,
    } = this.props
    const { initialFetch, currentPage, pageData } = this.state

    // append api params if needed for pagination
    if (
      !initialFetch &&
      stripeData &&
      stripeData.data &&
      stripeData.data.length > 0
    ) {
      params.starting_after = stripeData.data[stripeData.data.length - 1].id
    }
    // append team customer id if needed for pagination
    if (team && team.stripe_customer_id) {
      // Only care about this if you're seeing invoices specific to one team.
      params.stripe_customer_id = team.stripe_customer_id
    }
    const newPage = state.page

    this.setState(
      { currentPage: newPage }, //keep track of table state
      () => {
        const loadedPage = pageData.length
        if (newPage >= loadedPage) {
          //new data to load
          this.props
            .apiQuery(params, type)
            .then(response => {
              const resolvedResponse = dataResolver(response)
              if (resolvedResponse && resolvedResponse.data) {
                if (setCount) {
                  this.props.setCount(resolvedResponse.total_count)
                }
                const newData = resolvedResponse.data //data coming in
                const oldData =
                  stripeData && stripeData.data ? stripeData.data : []
                const currentData = _.clone(oldData) //list of all data so far
                const pageDataCopy = _.clone(pageData) //array of data stored by page
                if (currentData && currentData.length > 0) {
                  //if we already have data,
                  if (this.props.handleLastInvoiceReceived) {
                    // sent data to parent if provided the function
                    this.props.handleLastInvoiceReceived(
                      currentData[0].amount / 100
                    )
                  }
                  pageDataCopy[newPage] = newData
                  //this.setState({ data: { ...currentData, ...newData }, pageData: pageData });
                  this.setState({
                    data: pageDataCopy[newPage],
                    pageData: pageDataCopy,
                    initialFetch: false,
                  })
                } else {
                  //first time load
                  pageData[0] = newData
                  this.setState({
                    data: newData,
                    pageData: pageData,
                    initialFetch: false,
                  })
                }
              }
            })
            .catch(err => {
              toast.error(convertStripeErrorToMessage(err))
            })
        } else {
          //no new data to load so pull from page state
          // const pageData = _.clone(pageData)
          const data = pageData[newPage]
          this.setState({
            // pageData,
            data,
            initialFetch: false,
          })
        }
      }
    )
  }

  render() {
    const data = this.state.data
      ? Object.assign([], this.state.data)
      : this.state.data
    const pages = this.props.stripeData
      ? Math.ceil(
          this.props.stripeData.total_count / PaymentConstants.defaultPageSize
        )
      : 1
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-md-12">
            {PaymentConstants.renderDopeTable(
              data,
              pages,
              this.props.isLoading,
              this.props.getTeamFromStripeObject,
              this.props.history,
              (state, instance) => this.onFetchData(state, instance),
              this.props.handleDetailClicked,
              PaymentConstants.renderCompanyName,
              PaymentConstants.teamLinkClicked,
              this.props.isInvoices,
              this.props.objectName,
              this.props.type
            )}
          </div>
        </div>
      </div>
    )
  }
}

BillingTable.displayName = 'BillingTable'

function mapStateToProps(state) {
  return {
    user: state.user,
    stripeTeams: state.stripe.teams,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    isFetching: state.ui.isFetching,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    stripe_actions: bindActionCreators(stripeActions, dispatch),
    team_actions: bindActionCreators(teamActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingTable)
