import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { getContrastingColor } from './ColorUtils'

export const ColorSquareStyled = styled.span`
  background: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 2px;
  margin: 0 !important;
  height: ${props => {
    switch (props.size) {
      case 'x-small':
        return '10px'
      case 'small':
        return '25px'
      case 'medium':
        return '125px'
      case 'large':
        return '125px'
      default:
        return '125px'
    }
  }};
  width: ${props => {
    switch (props.size) {
      case 'x-small':
        return '10px'
      case 'small':
        return '25px'
      case 'medium':
        return '125px'
      case 'large':
        return '125px'
      default:
        return '125px'
    }
  }};
`
export const ColorLabelStyled = styled.div`
  color: ${props => getContrastingColor(props.color)};
  position: relative;
  font-size: '1.5rem';
`
export default function ColorSquare({ color, colorLabel, size, ...props }) {
  return (
    <ColorSquareStyled color={color} size={size} {...props}>
      {colorLabel && (
        <ColorLabelStyled color={color}> {colorLabel}</ColorLabelStyled>
      )}
    </ColorSquareStyled>
  )
}
ColorSquare.propTypes = {
  color: PropTypes.string.isRequired,
  colorLabel: PropTypes.string.isRequired,
  size: PropTypes.string,
}
ColorSquare.defaultProps = {
  size: 'medium',
}
ColorSquare.displayName = 'ColorSquare'
