import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import styled from 'styled-components'
import colors from '@global/Colors/colors'
import defaultCompanyPic from '../../../../img/placeholders/otto_black_white.jpeg'
import Main from './Main'
import Aside from './Aside'
import DetailMap from './DetailMap'

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 100px;
`
const Wrapper = styled.div`
  display: flex;
  margin: auto;
`
const DetailMapStyled = styled(DetailMap)`
  display: flex;
  margin: auto;
  background: #eee;
  width: 100%;
  height: 50vh;
  max-height: 400px;
`
const MainStyled = styled(Main)`
  margin: auto;
`
const AsideStyled = styled(Aside)`
  margin-left: 50px;
  @media (max-width: 1024px) {
    display: none;
  }
`

const Detail = ({ onBook, toggleScheduleTourModal, room, ...other }) => {
  return (
    <Container {...other}>
      <Wrapper>
        <MainStyled onBook={onBook} room={room} />
        <AsideStyled onBook={onBook} toggle={toggleScheduleTourModal} />
      </Wrapper>
      <DetailMapStyled />
    </Container>
  )
}

Detail.propTypes = {
  onBook: PropTypes.func,
  room: PropTypes.object,
  toggleScheduleTourModal: PropTypes.func,
}
Detail.displayName = 'Detail'

export default Detail
