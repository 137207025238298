import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Row, Col, TabContent, TabPane } from 'reactstrap'
import styled from 'styled-components'
import { IntercomAPI } from 'react-intercom'

import * as stripeActions from '@reduxActions/stripeActions'

import {
  resolveFetchingStatus,
  FETCHING_MIGRATION,
  FETCHING_SETTINGS,
  FETCHING_STRIPE_OAUTH_NAME,
} from '@global/Constants/FetchingConstants'

import { QueryStringToJSON } from '@global/Utilities/routeUtilities'
import { logO } from '@global/Constants'
import {
  extractCoworksErrorObject,
  captureException,
} from '@global/ErrorFactory'

import ConnectionStatus from './ConnectionStatus'
const ColRight = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: start;
`
export default function ConnectToStripe({ history }) {
  const activeCommunity = useSelector(state => state.ui.activeCommunity)

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [connected, setConnected] = useState(
    Boolean(activeCommunity.stripe_account_id)
  )

  const [scope, setScope] = useState(null)
  const [code, setCode] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    // Run once on mount
    // catch the stripe authorization information when connecting to stripe
    const location = history.location
    const query = QueryStringToJSON(location)
    if (query.scope && query.code) {
      setScope(query.scope)
      setCode(query.code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading && scope && code && !connected) {
      setLoading(true)
      dispatch(
        stripeActions.connectToStripeStandard({
          code: code,
          grant_type: 'authorization_code',
          community_id: activeCommunity.id,
        })
      )
        .then(() => {
          toast.success('Connected to Stripe account.')
          setLoading(false)
          setConnected(true)
        })
        .catch(err => {
          const errorMessage = extractCoworksErrorObject(err)
          captureException({
            text: `Failed to connect to stripe account. CommunityId: ${activeCommunity.id}. Error: ${errorMessage}`,
          })
          const message = errorMessage
            ? errorMessage
            : 'Failed to connect to Stripe.'
          setErrorMessage(message)
          toast.error(message)
          setLoading(false)
          setConnected(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, code])

  function handleAnimationComplete() {
    history.replace('/community/stripe')
  }

  return (
    <TabContent>
      <TabPane>
        <div className="animated fadeIn">
          <Row>
            <Col>
              <ConnectionStatus
                loading={loading}
                type={connected ? 'checkin' : 'error'}
                message={
                  connected ? 'Successfully Connected!' : 'Failed to Connect'
                }
                width={connected ? 300 : 160}
                height={connected ? 300 : 160}
                handleAnimationComplete={
                  connected ? handleAnimationComplete : undefined
                }
                showSupportButton={!connected && !loading}
                handleButtonClick={() =>
                  IntercomAPI(
                    'showNewMessage',
                    `I am having trouble connecting my stripe account. The error message was: ${
                      errorMessage ? errorMessage : 'generic.'
                    }`
                  )
                }
              />
            </Col>
          </Row>
        </div>
      </TabPane>
    </TabContent>
  )
}
ConnectToStripe.displayName = 'ConnectToStripe'
ConnectToStripe.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

// stripe_actions: PropTypes.object,

function mapStateToProps(state) {
  return {
    user: state.user,
    coworksCustomer: state.stripe.coworksCustomer,
    activeCommunity: state.ui.activeCommunity,
    activeCampus: state.ui.activeCampus,
    isFetchingStripeOauthConnection: resolveFetchingStatus(
      state,
      FETCHING_STRIPE_OAUTH_NAME
    ),
    isFetchingMigration: resolveFetchingStatus(state, FETCHING_MIGRATION),
    isFetchingSettings: resolveFetchingStatus(state, FETCHING_SETTINGS),
  }
}
