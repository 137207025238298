import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import * as Roles from '@app/config/roles'

import TeamDetail from './Teams/TeamDetail/TeamDetail'

export default function MyTeam() {
  const user = useSelector(state => state.user)
  const team = user.teams && user.teams.length > 0 ? user.teams[0] : null
  //   directory / teams / 118 / profile
  const { path, url } = useRouteMatch()

  /* <Redirect
    to={{
      pathname: `/directory/teams/${team.id}/profile`,
      state: { team },
    }}
  /> */

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect
          to={{
            pathname: `${path}/${team.id}`,
            state: { team },
          }}
        />
      </Route>
      <Route path={`${path}/:teamId`}>
        <TeamDetail team={team} role={Roles.memberRole} />
      </Route>
    </Switch>
  )
}

MyTeam.displayName = 'My Team'
