import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'reactstrap'
import { Field } from 'formik'
import {
  CustomInputComponent,
  CustomToggleComponent,
  LabelStyled,
  CustomPresetSelectComponent,
} from '@global/Form/FormComponents'
function CalendarSettings({ formProps }) {
  return (
    <React.Fragment>
      <h3>Calendar</h3>
      <br />
      <Field
        label="Events public by default"
        name="event_visibility_default"
        description="Set the events public/private toggle in the new event form"
        component={CustomToggleComponent}
      />
      <Field
        label="Default timezone"
        name="timezone_identifier"
        description="Change the default timezone for you community"
        dropdownType="timezone"
        component={CustomPresetSelectComponent}
      />
      <br />
      <h3>Notifications</h3>
      <br />
      {/* Used to be notification_member_booking_creation_push */}
      <Field
        label="Team & Member Booking Overage Notifications"
        name="notifications.bookings.overages"
        description="Default setting of notifying members for bookings created by the member, or bookings created for that member."
        component={CustomToggleComponent}
      />
      {/* Used to be notification_member_booking_creation_email */}
      <Field
        label="Member Booking Creation Notifications"
        name="notifications.bookings.creation"
        description="Default setting of notifying members of booking overages. Note: Members will have the option to individually turn this off"
        component={CustomToggleComponent}
      />
      <br />
      <h3>Booking Tablets</h3>
      <br />
      <Field
        label='Show "Book Now" button on Tablet'
        name="should_show_tablet_join_button"
        description="Visibility of the book now button on the conference room tablets"
        component={CustomToggleComponent}
      />
    </React.Fragment>
  )
}

CalendarSettings.propTypes = {
  formProps: PropTypes.object,
  role: PropTypes.string,
}

CalendarSettings.displayName = 'CalendarSettings'

export default CalendarSettings
