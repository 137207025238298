import { SET_CHECK_EMAIL_EXISTS } from '../reduxActions/actionTypes'

export default function(checkEmailExists = {}, action) {
  switch (action.type) {
    case SET_CHECK_EMAIL_EXISTS:
      return action.emailExists
    default:
      return checkEmailExists
  }
}
