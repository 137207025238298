import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { LabelStyled } from '@global/Form/FormComponents'

export default class ChangePasswordForm extends React.Component {
  static propTypes = {
    handleInputChange: PropTypes.func,
    currentPassword: PropTypes.string,
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
  }

  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div>
        <div className="row mb-3">
          <div className="col-md-6">
            <h4>Update Password</h4>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <LabelStyled>Current Password</LabelStyled>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <Input
              name={'currentPassword'}
              type={'password'}
              className={'mb-3'}
              label={''}
              value={this.props.currentPassword}
              placeholder={'current password'}
              onChange={this.props.handleInputChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <LabelStyled>New Password</LabelStyled>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <Input
              name={'password'}
              type={'password'}
              className={'mb-3'}
              label={''}
              value={this.props.password}
              placeholder={'new password'}
              onChange={this.props.handleInputChange}
            />
          </div>
          <div className="col-md-6">
            <Input
              name={'passwordConfirmation'}
              type={'password'}
              className={'mb-3'}
              label={''}
              value={this.props.passwordConfirmation}
              placeholder={'password confirmation'}
              onChange={this.props.handleInputChange}
            />
          </div>
        </div>
      </div>
    )
  }
}
ChangePasswordForm.displayName = 'ChangePasswordForm'
