const initialState = {
  attachments: [],
  announcements: {},
  bookings: {
    bookings: [],
    meta: null,
    tabletKey: null,
  },
  checkEmail: {},
  checkins: {
    member: {
      data: [],
      meta: null,
    },
    passport: {
      data: [],
      meta: null,
    },
    visitor: {
      data: [],
      meta: null,
    },
  },
  community: {},
  gcal: {},
  guests: {},
  integrations: {
    active: [],
    pending: [],
    inactive: [
      {
        name: 'Door Access',
        internal_name: 'door_access',
        integrations: [
          {
            name: 'Kisi',
            type: 'kisi',
            description: 'Automatically add all users from Coworks.',
            actionButtonText: 'Activate',
            photo:
              'https://coworksprod.s3.amazonaws.com/placeholders/integration+images/kisi.jpg',
            active: false,
            section_internal_name: 'door_access',
            activation_help_article:
              'http://help.coworksapp.com/en/articles/3945644-how-to-setup-the-kisi-integration',
          },
        ],
      },
      {
        name: 'Automation',
        internal_name: 'automation',
        integrations: [
          {
            name: 'Zapier',
            type: 'zapier',
            actionButtonText: 'View',
            description:
              'Automatically perform actions when bookings, members, events, and leads are created.',
            photo:
              'https://coworksprod.s3.amazonaws.com/placeholders/integration+images/zapier.png',
            active: false,
            externalUrl:
              'https://help.coworksapp.com/en/articles/3431165-how-to-setup-coworks-with-zapier',
            section_internal_name: 'automation',
          },
        ],
      },
    ],
    doorAccess: {
      groups: null,
      places: null,
    },
  },
  leads: {
    leads: [],
    leadsMeta: null,
    prospects: [],
    prospectsMeta: null,
    pendingMembers: [],
  },
  manager: {},
  members: { members: [], inactive: [], meta: null, inactiveMeta: null },
  network: {
    members: [],
    meta: null,
  },
  plans: {},
  resources: {},
  rooms: {
    rooms: { data: [], meta: null },
    suites: { data: [], meta: null, stats: null },
  },
  staff: {},
  stats: {},
  stripe: {},
  teams: { teams: null },
  ui: { isFetching: false, fetching: {}, filters: {} },
  user: {},
}
export default initialState
