import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as coworksCommunityActions from '@reduxActions/coworksCommunityActions'
import Spinner from '@global/Spinner'

const withPublicCommunityContext = props => WrappedComponent => {
  class withPublicCommunityContext extends React.Component {
    static propTypes = {
      history: PropTypes.object,
      coworks_community_actions: PropTypes.object,
    }
    state = {
      isLoading: true,
      publicCommunity: null,
      error: null,
    }

    componentDidMount() {
      let subdomain = window.location.hostname
      subdomain = subdomain.substring(0, subdomain.indexOf('.'))
      const params = {
        subdomain,
      }
      this.props.coworks_community_actions
        .getPublicCommunityProfile(params)
        .then(response => {
          this.setState({
            isLoading: false,
            publicCommunity: response.community,
          })
        })
        .catch(err => {
          this.setState({
            isLoading: false,
            error: new Error(err),
          })
        })
    }

    render() {
      const { isLoading, error, publicCommunity } = this.state
      if (isLoading) return <Spinner />
      if (publicCommunity)
        return (
          <WrappedComponent publicCommunity={publicCommunity} {...this.props} />
        )
      if (error || (!isLoading && !publicCommunity))
        return <div>Looks like there was a problem.</div>
      return null
    }
  }
  function mapDispatchToProps(dispatch) {
    return {
      coworks_community_actions: bindActionCreators(
        coworksCommunityActions,
        dispatch
      ),
    }
  }
  withPublicCommunityContext.displayName = `withPublicCommunityContext(${WrappedComponent.name})`
  return connect(null, mapDispatchToProps)(withPublicCommunityContext)
}

export default withPublicCommunityContext
