import React from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { TextButton } from '@global/Base/Button/ButtonStyled'
import * as Constants from '@global/Constants'
import RoundedImage from '@global/RoundedImage'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Row } from 'reactstrap'
import styled from 'styled-components'
import * as Yup from 'yup'
import withPublicCommunityContext from '../withPublicCommunityContext'
import PublicSignupForm from './PublicSignupForm'
import { createInvoiceFeesArray } from '@global/Constants/PaymentConstants'
import { resolvePreferenceFromCommunity } from '@global/Utilities/ResolveConstants'
import { breakpoints } from '@global/Constants/BreakpointConstants'
import SuccessCard from './SuccessCard'

const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  @media (min-width: ${breakpoints.desktop + 1}px) {
    margin: 0 20px;
    margin-top: 6vh;
  }
  @media (max-width: ${breakpoints.desktop}px) {
    flex-direction: column;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  @media (min-width: ${breakpoints.desktop + 1}px) {
    margin-right: 2vw;
  }
`
const Info = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 500px;
  margin-left: auto;
  @media (max-width: ${breakpoints.desktop}px) {
    width: 90%;
    max-width: 900px;
    margin: auto;
  }
  @media (max-width: ${breakpoints.mobile}px) {
    width: 95%;
  }
`
const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px 40px;
`
const FormCard = styled.div.attrs(props => ({
  className: 'card',
}))`
  flex: 1;
  margin: auto;
  width: 90%;
  max-width: 900px;
  @media (max-width: ${breakpoints.mobile}px) {
    width: 95%;
  }
`

const PublicSignupContainer = ({
  content,
  onSubmit,
  publicCommunity,
  isSuccessful,
  hasSubmitted,
  primaryColor,
  shouldRedirectSuccess,
  confirmationMessage,
  error,
  goBack,
  formProps,
  theme,
  purchaseCondition,
  termsDescription,
  history,
  detailData,
  featureType = 'dayPasses',
}) => {
  const currencySymbol = Constants.getCurrencySymbolFromCommunity(
    publicCommunity
  )
  const renderGoBack = primaryColor => {
    return (
      <TextButton className="my-2" backgroundColor={'#858585'} onClick={goBack}>
        <FontAwesomeIcon color={primaryColor} icon="arrow-left" /> go back
      </TextButton>
    )
  }
  const getPlanText = () => {
    let freq = ''
    if (content.frequency !== 'once') {
      freq = ` / ${content.frequency}`
    }
    return Number(content.cost) > 0
      ? `It's only ${currencySymbol}${Number(content.cost).toFixed(2)}${freq}`
      : `It's free!`
  }

  function renderCoworksBrand() {
    return publicCommunity &&
      publicCommunity.community_preference &&
      !publicCommunity.community_preference.is_whitelabeled ? (
      <Row>
        <div className="col-md-2" />
        <div className="col-12 col-md-4 text-muted p-5">
          Powered by{' '}
          <a href="https://www.coworksapp.com" target="_blank">
            Coworks
          </a>
        </div>
        <div className="col-md-2" />
      </Row>
    ) : null
  }

  const calculateTotal = () => {
    let subtotal = Number(content.cost)

    const feesArray = createInvoiceFeesArray({
      amountInDollars: subtotal,
      prefs: resolvePreferenceFromCommunity(publicCommunity),
      returnEmpty: false,
      customer: null,
      intendedPaymentSource: 'card',
      feature: featureType,
    })

    const costSum = []
    costSum.push({
      label: 'Subtotal',
      value: `${currencySymbol}${subtotal.toFixed(2)}`,
      style: { fontSize: 14, fontWeight: 'bold' },
    })
    if (feesArray && feesArray.length > 0) {
      feesArray.forEach(fee => {
        subtotal += fee.amount / 100
        costSum.push({
          label: fee.description,
          value: `${currencySymbol}${(fee.amount / 100).toFixed(2)}`,
          style: { fontSize: 12 },
        })
      })
    }
    costSum.push({
      label: 'Total',
      value: `${currencySymbol}${subtotal.toFixed(2)}`,
      style: { fontSize: 18, fontWeight: 'bold' },
    })
    return costSum
  }

  const passTermsCondition = () => {
    const data = calculateTotal()
    const finalTotal = data[data.length - 1].value
    return termsDescription(finalTotal)
  }

  const renderSuccessCard = () => {
    if (hasSubmitted && isSuccessful) {
      return (
        <SuccessCard
          shouldRedirectSuccess={shouldRedirectSuccess}
          renderGoBack={renderGoBack}
          confirmationMessage={confirmationMessage}
          primaryColor={primaryColor}
          successColor={theme.colorSuccess}
        />
      )
    }
    return null
  }

  return (
    <Container>
      {content ? (
        <React.Fragment>
          <ContentWrapper className="pt-5">
            <InfoWrapper className="mt-3">
              <Info>
                {!hasSubmitted && (
                  <div className="col-12 p-0">{renderGoBack(primaryColor)}</div>
                )}
                {''}
                {content.photo && (
                  <div className="col-12">
                    <RoundedImage
                      photo={content.photo}
                      alt="Plan photo"
                      size={'medium'}
                    />
                  </div>
                )}
                <div className="col-12 my-2">
                  <h6>Sign up for {content.name}</h6>
                </div>
                <div className="col-12">
                  <h3>
                    <b>{getPlanText()}</b>
                  </h3>
                </div>
                {content.description && (
                  <div className="col-12 text-muted">
                    <div>{content.description}</div>
                  </div>
                )}
              </Info>
            </InfoWrapper>
            <ActionWrapper>
              {!(hasSubmitted && isSuccessful) && (
                <FormCard className="mt-5">
                  {/* Form controls the render of itself since the form state is wiped when parent state is changed due to re-render */}
                  <PublicSignupForm
                    history={history}
                    communityName={publicCommunity.name}
                    primaryColor={primaryColor}
                    billingEnabled={publicCommunity.stripe_account_id}
                    error={error}
                    totalData={calculateTotal()}
                    // detailData={detailData}
                    hasSubmitted={hasSubmitted}
                    plan={content}
                    currencySymbol={currencySymbol}
                    purchaseCondition={purchaseCondition}
                    termsDescription={passTermsCondition()}
                    onSubmit={onSubmit}
                    publicCommunity={publicCommunity}
                    defaultBillingCycleDay={
                      publicCommunity.community_preference
                        .default_billing_cycle_day
                    }
                    extraValidation={{
                      date: Yup.object().required('date is required'),
                    }}
                    extraInitialValues={{
                      date: moment(),
                    }}
                    {...formProps}
                  />
                </FormCard>
              )}
              {renderSuccessCard()}
            </ActionWrapper>
          </ContentWrapper>
          {renderCoworksBrand()}
        </React.Fragment>
      ) : (
        renderFatalError()
      )}
    </Container>
  )
}

function renderFatalError() {
  return (
    <Row className="mt-5">
      <div className="col-2" />
      <div className="col-6">
        <h6>
          Wow! Looks like you've found a secret page, or something isn't working
          properly. If you are a manager, Coworks support has been notified and
          will reach out and help you get this setup.
        </h6>
      </div>
      <div className="col-2" />
    </Row>
  )
}

PublicSignupContainer.propTypes = {
  publicCommunity: PropTypes.object.isRequired,
  campus: PropTypes.object,
  content: PropTypes.object,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
  isSuccessful: PropTypes.bool,
  shouldRedirectSuccess: PropTypes.bool,
  showHeader: PropTypes.bool,
  hasSubmitted: PropTypes.bool,
  theme: PropTypes.object,
  error: PropTypes.object,
  formProps: PropTypes.object,
  history: PropTypes.object,
  primaryColor: PropTypes.string,
  purchaseCondition: PropTypes.string,
  termsDescription: PropTypes.string,
  confirmationMessage: PropTypes.string,
  featureType: PropTypes.string,
  detailData: PropTypes.array,
}

PublicSignupContainer.displayName = 'PublicSignupContainer'

export default PublicSignupContainer
