import React from 'react';
import PropTypes from 'prop-types';
import SidePanel from './OnboardingSidePanel';
import { FormErrors } from '@global/FormErrorsComponent';
import { H1, Button } from './SharedOnboardingComponents'

const ENTER_KEY = 13;


export default class ManagerSignup extends React.Component {
  static propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    goToNext: PropTypes.func,
    goToLogin: PropTypes.func,
    handleInputChange: PropTypes.func,
    formValid: PropTypes.bool,
    emailValid: PropTypes.bool,
    firstNameValid: PropTypes.bool,
    passwordValid: PropTypes.bool,
    lastNameValid: PropTypes.bool,
    formErrors: PropTypes.object,
    handleTOSChecked: PropTypes.func,
    tosStatus: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.getElementById('first-name').focus();
    document.getElementById('first-name').select();
  }

  handleKeyDown(event) {
    switch (event.keyCode) {
      case ENTER_KEY:
        if (this.props.formValid) {
          this.props.goToNext(event);
        }
        break;
      default: break;
    }
  }
  handleTypeChange = event => this.setState({ type: event.target.value });

  render() {
    const {
      formErrors,
      emailValid,
      firstNameValid,
      lastNameValid,
      passwordValid,
    } = this.props;
    return (
      <div onKeyDown={this.handleKeyDown}>
        <div className='row'>
          <SidePanel
            title='Hey there!'
            caption='Tell us a little about yourself.' />

          <div className='col-md-5 offset-md-1'>
            <H1>Let's get started</H1>
            <div className='row'>
              <div className="col-6">
                <div className='form-group'>
                  <label htmlFor='firstName'>First Name</label>
                  <input
                    id='first-name'
                    className='form-control'
                    name='firstName'
                    placeholder='Johnny'
                    value={this.props.firstName}
                    onChange={this.props.handleInputChange} />
                  {formErrors.firstName && !firstNameValid ? <p className='help-block text-danger'>{formErrors.firstName}</p> : null}
                </div>
              </div>
              <div className="col-6">
                <div className='form-group'>
                  <label htmlFor='lastName'>Last Name</label>
                  <input
                    className='form-control'
                    name='lastName'
                    placeholder='Appleseed'
                    value={this.props.lastName}
                    onChange={this.props.handleInputChange} />
                  {formErrors.lastName && !lastNameValid ? <p className='help-block text-danger'>{formErrors.lastName}</p> : null}
                </div>
              </div>
            </div>



            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input
                className='form-control'
                name='email'
                placeholder='johnny@me.com'
                value={this.props.email}
                onChange={this.props.handleInputChange} />
              {formErrors.email && !emailValid ? <p className='help-block text-danger'>{formErrors.email}</p> : null}
            </div>

            <div className='form-group'>
              <label htmlFor='password'>Password</label>
              <input
                type='password'
                className='form-control'
                name='password'
                placeholder='password'
                value={this.props.password}
                onChange={this.props.handleInputChange} />
              {formErrors.password && !passwordValid ? <p className='help-block text-danger'>{formErrors.password}</p> : null}
            </div>

            <div className='row d-flex justify-space-between align-items-end'>
              <button
                className='btn'
                onClick={this.props.goToLogin}>Back to Login
            </button>

              <Button
                className={`btn btn-primary`}
                notComplete={!(this.props.password.length >= 8)}
                disabled={!this.props.formValid}
                onClick={this.props.goToNext}>Next
            </Button>
            </div>



          </div>
        </div>
      </div>

    );
  }
}

ManagerSignup.displayName = 'ManagerSignup';
