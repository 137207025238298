import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import ButtonFactory from '../../Global/ButtonFactory'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const Container = styled.div``
const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const ImgStyled = styled.img`
  background: url(${props => props.imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  background-position: center;
`

const Content = styled.div``
const TopSection = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const BottomSection = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const ButtonActions = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
`

const CardRowComponent = ({ header, sub, actions, bottomRow, ...other }) => {
  return (
    <Container id="CardRowComponent" {...other}>
      <Wrapper className="card p-0">
        {/* <ImgStyled imageUrl={imageUrl}/> */}
        <Content className="card-block">
          <TopSection>
            <div className="col-12">
              <h4>{header}</h4>
            </div>
          </TopSection>
          <BottomSection>
            <div className="col-12">
              <p className="text-muted">{sub}</p>
              {bottomRow ? bottomRow : null}
            </div>
          </BottomSection>
          <ButtonActions>
            <ButtonFactory buttons={actions} />
          </ButtonActions>
        </Content>
      </Wrapper>
    </Container>
  )
}

CardRowComponent.propTypes = {
  header: PropTypes.string,
  sub: PropTypes.string,
  actions: PropTypes.array,
  bottomRow: PropTypes.object,
}

CardRowComponent.displayName = 'Card Row Component'

export default CardRowComponent
