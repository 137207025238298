/* eslint-disable react/no-multi-comp */
import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// https://github.com/stripe/react-stripe-js

// TO use this component, wrap your form (that contains the credit card form with this ->)
//   import { ElementsConsumer } from '@stripe/react-stripe-js'
//   <ElementsConsumer>
//     {({ stripe, elements }) => (

//     )}
//   </ElementsConsumer>

// OR to use with hooks, use:
// (useStipe, useElements) from '@stripe/react-stripe-js'

const CardContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0.5rem;
  background-color: #ffffff;

  .StripeElement--focus {
    /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;

    color: #607d8b;
    background-color: #ffffff;
    border-color: #64d8d7 !important;
    outline: none;
  }
`
const CreditCardForm = ({ stripe, elements, primaryColor, onChange }) => (
  <CardContainer>
    <CardElement
      stripe={stripe}
      elements={elements}
      onChange={onChange}
      options={{
        style: {
          base: {
            fontSize: '1rem',
            iconColor: primaryColor,
            fontFamily: 'Montserrat, Open Sans, Segoe UI, sans-serif',
          },
        },
      }}
    />
  </CardContainer>
)

CreditCardForm.displayName = 'CreditCardForm'

CreditCardForm.propTypes = {
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  primaryColor: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default CreditCardForm
