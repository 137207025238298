/* eslint-disable react/prop-types */
import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { withTheme } from 'styled-components'

const animatedComponents = makeAnimated()

function CoworksSelect(props) {
  return (
    <Select
      {...props}
      components={animatedComponents}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: props.theme.colorPrimaryHoverSearchRowHighlight,
          primary: props.theme.colorPrimaryHover,
        },
      })}
    />
  )
}

CoworksSelect.displayName = 'CoworksSelect'

export default withTheme(CoworksSelect)
