/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as bookingActions from '@reduxActions/bookingActions'
import styled from 'styled-components'
import { ThemeProvider, withTheme } from 'styled-components'
import colors from '@global/Colors/colors'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import { LabelStyled } from '@global/Form/FormComponents'
import { Button, Input } from 'reactstrap'
import Checkbox from '@bit/coworks.base-components.checkbox'

const Container = styled.div`
  width: 250px;
  margin-bottom: auto;
  background: #fff;
  padding: 24px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  text-align: center;
`
const InputContainer = styled.div`
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
  margin-bottom: 12px;
`
const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const FormContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
`
const TitleContainer = styled.div`
  padding: 12px 24px;
`
const Title = styled.h2`
  display: flex;
`
const CheckboxStyled = styled(Checkbox)`
  margin-right: 12px;
  line-height: 0;
`
const InfoContainer = styled.div`
  padding: 12px 24px;
`
const CostContainer = styled.div`
  background: #eee;
  padding: 12px 24px;
`
const CloseIcon = styled.div`
  font-size: 24px;
`
const HelperText = styled.span`
  margin: 12px 0;
`
const DayToggle = styled.div``
const TimeInputs = styled.div`
  display: flex;
`
const FContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const LText = styled.div`
  display: flex;
`
const Cost = styled.h5`
  display: flex;
`

const Detail = ({ onBook, toggle, ...other }) => {
  const form = () => (
    <FormContainer>
      <div>
        <LabelStyled htmlFor="firstName">Date</LabelStyled>
        <Input
          id="firstName"
          type="text"
          name="firstName"
          className="w-100"
          placeholder="First Name"
          onChange={() => null}
          value={'while'}
        />
      </div>
      <div>
        <LabelStyled htmlFor="firstName">Time</LabelStyled>
        <Input
          id="firstName"
          type="text"
          name="firstName"
          className="w-100"
          placeholder="First Name"
          onChange={() => null}
          value={'while'}
        />
      </div>
    </FormContainer>
  )

  return (
    <Container {...other}>
      <PriceContainer>
        <Cost>$25/hour</Cost>
        <Cost>$40/day</Cost>
      </PriceContainer>
      <InputContainer>{form()}</InputContainer>
      <ButtonContainer>
        <ButtonStyled onClick={onBook}>Book Now</ButtonStyled>
        <HelperText>You will not be charged yet.</HelperText>
        <Button className="btn btn-outline-primary" onClick={toggle}>
          Schedule a Tour
        </Button>
      </ButtonContainer>
    </Container>
  )
}

Detail.propTypes = {
  onBook: PropTypes.func,
  toggle: PropTypes.func,
}
Detail.displayName = 'Detail'

export default Detail
