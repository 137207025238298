import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import '../announcements.scss'
import { DropdownToggleOutlineStyled, AStyled } from '@global/BaseComponents'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

class MessageRow extends React.Component {
  static propTypes = {
    message: PropTypes.object,
    onView: PropTypes.func,
    onDelete: PropTypes.func,
    onDuplicate: PropTypes.func,
  }
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  viewMessage = () => {
    this.props.onView()
    this.toggleDropdown()
  }
  deleteMessage = () => {
    this.props.onDelete()
    this.toggleDropdown()
  }

  duplicateMessage = () => {
    this.props.onDuplicate()
    this.toggleDropdown()
  }

  trunc = (word, lim) => {
    if (word && word.length > 0) {
      return word.substr(0, lim - 1) + (word.length > lim ? '...' : '')
    }
    return ''
  }

  strip = html => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }

  render() {
    const { message } = this.props

    return (
      <tr className="border-bottom announcement-row row">
        <td className="announcement-row-type col-md-1 border-top-0">
          {message.type === 'Email' && (
            <React.Fragment>
              <UncontrolledTooltip
                placement="top"
                target={`email-${message.id}`}
              >
                <div className="col-md-12">
                  <h6 className="mt-2">Sent as Email</h6>
                </div>
              </UncontrolledTooltip>
              <FontAwesomeIcon id={`email-${message.id}`} icon={'envelope'} />
            </React.Fragment>
          )}
          {message.type === 'Push' && (
            <React.Fragment>
              <UncontrolledTooltip
                placement="top"
                target={`push-${message.id}`}
              >
                <div className="col-md-12">
                  <h6 className="mt-2">Sent as Push Notification</h6>
                </div>
              </UncontrolledTooltip>
              <FontAwesomeIcon id={`push-${message.id}`} icon={'bell'} />
            </React.Fragment>
          )}
          {message.send_as_sms && (
            <React.Fragment>
              <UncontrolledTooltip placement="top" target={`sms-${message.id}`}>
                <div className="col-md-12">
                  <h6 className="mt-2">Sent as SMS Text message</h6>
                </div>
              </UncontrolledTooltip>
              <FontAwesomeIcon id={`sms-${message.id}`} icon={'sms'} />
            </React.Fragment>
          )}
          {message.send_as_email && (
            <React.Fragment>
              <UncontrolledTooltip
                placement="top"
                target={`email2-${message.id}`}
              >
                <div className="col-md-12">
                  <h6 className="mt-2">Sent as quick email</h6>
                </div>
              </UncontrolledTooltip>
              <FontAwesomeIcon id={`email2-${message.id}`} icon={'envelope'} />
            </React.Fragment>
          )}
        </td>
        <td className="col-md-3 border-top-0">
          <p className="announcement-row-text">
            {this.trunc(
              message.type === 'Email' ? message.subject : message.header,
              55
            )}
          </p>
        </td>
        <td className="col-md-4 border-top-0">
          <p className="announcement-row-text">
            {this.trunc(
              message.type === 'Email'
                ? this.strip(message.message)
                : message.message,
              80
            )}
          </p>
        </td>
        <td className="announcement-row-date col-md-2 border-top-0">
          {moment(message.created_at).format('M/D/Y')}
        </td>
        <td className="d-flex justify-content-center col-md-2 border-top-0">
          <ButtonContainer>
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleDropdown}
            >
              <DropdownToggleOutlineStyled caret>
                Actions
              </DropdownToggleOutlineStyled>
              <DropdownMenu>
                <DropdownItem onClick={this.viewMessage}>View</DropdownItem>
                <DropdownItem onClick={this.deleteMessage}>Delete</DropdownItem>
                {/* <DropdownItem onClick={this.duplicateMessage}>
                  Duplicate
                </DropdownItem> */}
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonContainer>
        </td>
      </tr>
    )
  }
}

MessageRow.displayName = 'Message Row'

export default MessageRow
