/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'

import * as Yup from 'yup'
import styled from 'styled-components'
import { Formik, FormikProps, Form, Field, ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import ButtonStyled from '@global/Base/Button/ButtonStyled'

import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomTextAreaComponent,
} from '@global/Form/FormComponents'

import PhoneInputField from '@global/Form/PhoneInputField'

const BButton = styled(ButtonStyled)`
  border: none !important;
`
const LogoStyled = styled.div`
  background-image: url('${props => props.imgURL}') !important;
  background-size: cover !important;
  background-position: center !important;
`

function ScheduleTourForm({
  isFetching,
  publicCommunity,
  activeCampus,
  scheduleTour,
  type = 'TourRequest',
  phone = '',
  firstName = '',
  lastName = '',
  teamName = '',
  title = '',
  website = '',
  interestedIn = '',
  email = '',
}) {
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (activeCampus) {
      // eslint-disable-next-line no-param-reassign
      values.campusId = activeCampus.id
    } else {
      // eslint-disable-next-line no-param-reassign
      values.campusId = publicCommunity.campuses[0].id
    }
    scheduleTour(values)
    setSubmitting(false)
    resetForm()
  }

  function initialValues(publicCommunity) {
    return {
      publicCommunity,
      phone,
      firstName,
      lastName,
      teamName,
      title,
      website,
      interestedIn,
      email,
    }
  }

  function resolveBranding(publicCommunity, activeCampus) {
    const { community_preference } = publicCommunity
    let imageUrl =
      community_preference && community_preference.whitelabel_background_login
        ? community_preference.whitelabel_background_login
        : 'https://s3.amazonaws.com/coworksprod/assets/admin-dashboard-assets/frontdesk-tablet-background.jpg'
    if (activeCampus) {
      if (activeCampus && activeCampus.campus_photo) {
        imageUrl = activeCampus.campus_photo.file_url
      }
    } else if (publicCommunity) {
      if (publicCommunity && publicCommunity.community_photo) {
        imageUrl = publicCommunity.community_photo.file_url
      } else if (
        publicCommunity.campuses &&
        publicCommunity.campuses.length > 0
      ) {
        for (let index = 0; index < publicCommunity.campuses.length; index++) {
          const campus = publicCommunity.campuses[index]
          if (campus.campus_photo) {
            imageUrl = campus.campus_photo.file_url
          }
        }
      }
    }

    const spaceName = activeCampus ? activeCampus.name : publicCommunity.name
    let headerText = `Request a tour of ${spaceName}`
    let buttonText = 'Request a Tour'
    let bodyPlaceholder = 'I want to take a tour of the suites'

    switch (type) {
      case 'MembershipRequest':
        if (
          publicCommunity &&
          publicCommunity.community_preference &&
          publicCommunity.community_preference.membership_request_title
        ) {
          headerText =
            publicCommunity.community_preference.membership_request_title
        } else {
          headerText = `Request Membership at ${spaceName}`
        }
        buttonText = 'Send Request'
        bodyPlaceholder = 'I want a membership!'
        break
      default:
        break
    }
    return { imageUrl, spaceName, headerText, buttonText, bodyPlaceholder }
  }

  if (!publicCommunity) {
    return null
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    interestedIn: Yup.string().required('The message is required.'),
    title: Yup.string(),
    website: Yup.string().url(
      'Invalid website format! It must be http://www.link.com'
    ),
  })
  const branding = resolveBranding(publicCommunity, activeCampus)
  return (
    <Formik
      initialValues={initialValues(publicCommunity)}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(stuff, otherStuff) => handleSubmit(stuff, otherStuff)}
      render={formProps => (
        <Form className="minh-50 col-12">
          <div className="row d-flex justify-content-center">
            <LogoStyled className="logo-wrapper" imgURL={branding.imageUrl} />
          </div>
          <h2 className="text-center my-5">{branding.headerText}</h2>
          <div className="row mt-1">
            <div className="col-12 col-md-6">
              <Field
                name="firstName"
                label="First Name*"
                className="form-control-lg form-control w-100"
                placeholder="John"
                component={CustomInputComponent}
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="lastName"
                label="Last Name*"
                className="form-control-lg form-control w-100"
                placeholder="Doe"
                component={CustomInputComponent}
              />
            </div>
          </div>
          <div className="row">
            <div className={`col-12 col-md-6`}>
              <Field
                name="email"
                type="email"
                label="Email*"
                className="form-control-lg form-control w-100"
                placeholder="name@email.com"
                component={CustomInputComponent}
              />
            </div>

            <div className={`col-12 col-md-6`}>
              <Field
                name="phone"
                type="tel"
                label="Phone Number"
                isEditing
                className="w-100"
                placeholder="555-555-5555"
                component={PhoneInputField}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <Field
                name="teamName"
                label="Team Name*"
                placeholder="Apple, Inc"
                className="form-control-lg form-control w-100"
                component={CustomInputComponent}
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="title"
                label="Title"
                className="form-control-lg form-control w-100"
                placeholder="Manager"
                component={CustomInputComponent}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                name="website"
                label="Team Website"
                placeholder="https://www.apple.com"
                className="form-control-lg form-control w-100"
                component={CustomInputComponent}
              />
            </div>
            <div className="col-12">
              <Field
                name="interestedIn"
                label="What are you interested in?*"
                className="form-control-lg form-control w-100"
                rows="2"
                cols="50"
                placeholder={branding.bodyPlaceholder}
                component={CustomTextAreaComponent}
              />
            </div>
          </div>
          <BButton
            type="submit"
            color={
              publicCommunity &&
              publicCommunity.community_preference &&
              publicCommunity.community_preference.branding_primary_color
            }
            disabled={
              (!formProps.isValid && !formProps.dirty) ||
              formProps.isSubmitting ||
              isFetching
            }
            className="btn btn-lg btn-block"
          >
            {branding.buttonText}
          </BButton>
          <p className="text-muted mt-3">
            Please allow 1-2 days for our team to reach out via phone or email.
            Thank you
          </p>
        </Form>
      )}
    />
  )
}

ScheduleTourForm.displayName = 'ScheduleTourForm'
ScheduleTourForm.propTypes = {
  history: PropTypes.object,
  isFetching: PropTypes.bool,
  coworks_community_actions: PropTypes.object,
  ui_actions: PropTypes.object,
  publicCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  customHeaderText: PropTypes.string,
  tourImage: PropTypes.string,
  customButtonText: PropTypes.string,
  customBodyPlaceholder: PropTypes.string,
  type: PropTypes.string,
  scheduleTour: PropTypes.func,
  phone: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  teamName: PropTypes.string,
  title: PropTypes.string,
  website: PropTypes.string,
  interestedIn: PropTypes.string,
  email: PropTypes.string,
}

export default ScheduleTourForm
