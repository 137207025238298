import React from 'react'
import PropTypes from 'prop-types'
import Anchor from '@global/Base/Anchor/Anchor'
import TagRow from '@global/TagRow'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'

const PromoteCol = ({
  isOpen,
  toggleDropdown,
  onPromoteToLead,
  onPromoteToNewTeamClicked,
  onPromoteToExistingTeamClicked,
  onEditClicked,
  isLead,
  ...other
}) => {
  return (
    <div {...other}>
      <ButtonDropdown isOpen={isOpen} toggle={toggleDropdown}>
        <Button
          className="btn btn-sm btn-outline-primary d-lg-block d-none"
          onClick={isLead ? onPromoteToNewTeamClicked : onEditClicked}
        >
          {isLead ? 'Promote' : 'Edit'}
        </Button>
        <DropdownToggle caret color="outline-primary" />
        <DropdownMenu>
          <DropdownItem header>Actions</DropdownItem>
          {!isLead && (
            <DropdownItem
              className="d-sm-block d-none-lg"
              onClick={onPromoteToLead}
            >
              Mark as Lead
            </DropdownItem>
          )}
          <DropdownItem
            className="d-sm-block d-none-lg"
            onClick={onPromoteToNewTeamClicked}
          >
            Promote to New Member
          </DropdownItem>
          <DropdownItem onClick={onPromoteToExistingTeamClicked}>
            Promote to Existing Team
          </DropdownItem>
          <DropdownItem onClick={onEditClicked}>Edit</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  )
}

PromoteCol.propTypes = {
  isOpen: PropTypes.bool,
  toggleDropdown: PropTypes.func,
  onPromoteToLead: PropTypes.func,
  onPromoteToNewTeamClicked: PropTypes.func,
  onPromoteToExistingTeamClicked: PropTypes.func,
  onEditClicked: PropTypes.func,
  isLead: PropTypes.bool,
}

PromoteCol.displayName = 'Campus Column'

export default PromoteCol
