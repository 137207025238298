import React, { useState } from 'react'
import Spinner from '@global/Spinner'

import PropTypes from 'prop-types'
import * as Constants from '@global/Constants'
import Colors from '@global/Colors/colors'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
const momentTZ = require('moment-timezone')

import { FETCHING_MIGRATION } from '@global/Constants/FetchingConstants'

const styles = {
  dropzoneContainer: {
    border: 'none',
    borderColor: Colors.primary,
    cursor: 'pointer',
    background: Colors.md_blue_grey_50,
  },
  dropzoneAccept: {
    border: `1px solid ${Colors.success}`,
  },
  dropzoneReject: {
    border: `1px solid ${Colors.error}`,
  },
}

export default function Migration({
  isFetching,
  activeCommunity,
  activeCampus,
  community_actions,
  history,
  preference,
  role,
}) {
  const superAdmin = Boolean(role === 'SuperAdmin')
  const showMembersMigrations = preference.show_member_migration || superAdmin
  const showLeadsMigrations = preference.show_member_migration || superAdmin
  const showBookingsMigrations = preference.show_booking_migration || superAdmin
  const showPlansMigrations = preference.show_plans_migration || superAdmin
  const showRoomsMigrations = preference.show_rooms_migration || superAdmin

  const [acceptedFile, setAcceptedFile] = useState('')
  const [showDropText, setShowDropText] = useState(null)

  const handleFileDrop = (acceptedFiles, rejectedFiles, name) => {
    // handle those files
    if (acceptedFiles.length > 0 && acceptedFiles.length === 1) {
      setAcceptedFile(acceptedFiles[0])
      setShowDropText(name)
    }
    if (rejectedFiles.length > 0) {
      toast.error('Could not accept that file! Make sure it is a .csv file')
    }
  }
  const uploadNexudusMembers = event => {
    event.preventDefault()
    const params = {
      community_id: activeCommunity.id,
      members_csv: acceptedFile,
      report_type: 'Nexudus',
      timezone_identifier: momentTZ.tz.guess(),
      model_type: 'PendingMember',
    }
    community_actions
      .importMembers(params, FETCHING_MIGRATION)
      .then(response => {
        toast.success(
          `Uploaded ${
            response && response.total_imported_members
          } pending members.`
        )
        history.push('/directory/pending')
      })
      .catch(err => {
        toast.error('Failed to upload pending members')
        console.error(err)
      })
  }
  const importProspects = event => {
    event.preventDefault()
    const params = {
      community_id: activeCommunity.id,
      campus_id: activeCampus.id,
      members_csv: acceptedFile,
      timezone_identifier: momentTZ.tz.guess(),
      model_type: 'Prospect',
    }
    community_actions
      .importMembers(params, FETCHING_MIGRATION)
      .then(response => {
        toast.success(
          `Uploaded ${response && response.total_imported_members} contacts.`
        )
        history.push('/crm/prospects')
      })
      .catch(err => {
        toast.error('Failed to upload prospects')
        console.error(err)
      })
  }

  const uploadBookingsCSVorICS = event => {
    event.preventDefault()
    const params = {
      community_id: activeCommunity.id,
      campus_id: activeCampus.id,
      timezone_identifier: momentTZ.tz.guess(),
    }
    if (acceptedFile.type === 'text/calendar') {
      params.bookings_ics_file = acceptedFile
    } else {
      params.bookings_csv = acceptedFile
    }

    community_actions
      .importBookings(params, FETCHING_MIGRATION)
      .then(response => {
        toast.success(
          `Uploaded ${response && response.total_successes} bookings.`
        )
        history.push('/calendar')
      })
      .catch(err => {
        toast.error('Failed to upload bookings & events')
        console.log(err)
      })
  }

  const renderMigrationsSection = ({
    name,
    helperText,
    supportedFiles,
    showText,
    handleUpload,
    isVisible,
  }) => {
    if (!isVisible) return null
    return (
      <div className="row p-3 m-3">
        <div className="col-12">
          <div className="h4">{name}</div>
        </div>
        <div className="col-12">
          <Dropzone
            accept={supportedFiles}
            onDrop={(acceptedFiles, rejectedFiles) => {
              handleFileDrop(acceptedFiles, rejectedFiles, name)
            }}
            multiple={false}
            style={styles.dropzoneContainer}
            acceptStyle={styles.dropzoneAccept}
            rejectStyle={styles.dropzoneReject}
            onDragEnter={() => setShowDropText(name)}
            onDragLeave={() => setShowDropText(null)}
          >
            {showText}
            <div className="row">
              <div className="col-md-12 p-4 d-flex justify-content-center">
                <FontAwesomeIcon
                  icon={'cloud-upload-alt'}
                  size="3x"
                  className="text-primary"
                />
              </div>
              <div className="col-md-12 d-flex text-primary justify-content-center">
                <p>
                  {showText && acceptedFile
                    ? `${acceptedFile.name}`
                    : helperText}
                </p>
              </div>
            </div>
          </Dropzone>
          {showText && (
            <div className="row">
              <div className="col-md-12  m-3 d-flex justify-content-center">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={handleUpload}
                >
                  Import!
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const types = [
    {
      name: 'Members',
      handleUpload: uploadNexudusMembers,
      helperText: 'Drop your "Active Members.csv" report from Nexudus here',
      supportedFiles: ['text/plain', 'text/csv', 'application/vnd.ms-excel'],
      visible: showMembersMigrations,
    },
    {
      name: 'Prospects',
      handleUpload: importProspects,
      helperText: 'Drop your prospects report here',
      supportedFiles: ['text/plain', 'text/csv', 'application/vnd.ms-excel'],
      visible: showLeadsMigrations,
    },
    {
      name: 'Bookings',
      handleUpload: uploadBookingsCSVorICS,
      helperText: 'Drop your "Bookings.csv" or a "calendar.ics" file here',
      supportedFiles: [
        'text/plain',
        'text/csv',
        'application/vnd.ms-excel',
        'text/calendar',
      ],
      visible: showBookingsMigrations,
    },
  ]

  return (
    <div className="animated fadeIn">
      <div className="col-md-12">
        <div className="row border-bottom-1 my-3">
          <div className="col-10">
            <h2>Migrations</h2>
          </div>
        </div>
      </div>
      {isFetching ? (
        <Spinner />
      ) : (
        <div className="col-md-12">
          {types.map(option =>
            renderMigrationsSection({
              name: option.name,
              helperText: option.helperText,
              supportedFiles: option.supportedFiles,
              showText: showDropText === option.name,
              handleUpload: option.handleUpload,
              isVisible: option.visible,
            })
          )}
        </div>
      )}
    </div>
  )
}

Migration.displayName = 'Migration'
Migration.propTypes = {
  isFetching: PropTypes.bool,
  activeCommunity: PropTypes.object,
  activeCampus: PropTypes.object,
  community_actions: PropTypes.object,
  history: PropTypes.object,
  preference: PropTypes.object,
  role: PropTypes.string,
}
