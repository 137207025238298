/* eslint-disable react/prop-types */
import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

export default function FinalizeInvoiceModal({
  showFinalizeInvoiceModal,
  toggleFinalizeInvoiceModal,
  renderNotesFields,
  isFetching,
  finalizeInvoice,
}) {
  return (
    <Modal
      isOpen={showFinalizeInvoiceModal}
      toggle={toggleFinalizeInvoiceModal}
      className="modal-primary fade in"
    >
      <ModalHeader toggle={toggleFinalizeInvoiceModal}>
        Finalize Invoice
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h6>
                Are you sure? This will finalize the invoice and send it out
                immediately.
              </h6>
            </div>
          </div>
          <Row>
            <div className="col-12">{renderNotesFields()}</div>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleFinalizeInvoiceModal}>
          Cancel
        </Button>
        <Button color="primary" disabled={isFetching} onClick={finalizeInvoice}>
          Finalize Invoice
        </Button>
      </ModalFooter>
    </Modal>
  )
}
FinalizeInvoiceModal.displayName = 'FinalizeInvoiceModal'
