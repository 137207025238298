import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import { successUpdatingTeam } from './teamActions'
import { FETCHING_COWORKS_CUSTOMER } from '@global/Constants/FetchingConstants'

export function setCustomer(response) {
  return { type: actionTypes.SET_STRIPE_CUSTOMER, customer: response.customer }
}
export function successUpdatingCustomer(response) {
  return { type: actionTypes.SET_STRIPE_CUSTOMER, customer: response.customer }
}
export function setCoworksCustomer(response) {
  return { type: actionTypes.SET_COWORKS_CUSTOMER, customer: response.customer }
}
export function setInvoice(response) {
  return { type: actionTypes.SET_STRIPE_INVOICE, invoice: response.invoice }
}
export function setTransaction(response) {
  return {
    type: actionTypes.SET_STRIPE_TRANSACTION,
    transaction: response.transaction,
  }
}
export function setTransactions(response) {
  return {
    type: actionTypes.SET_STRIPE_TRANSACTIONS,
    transactions: response.charges,
    teams: response.teams,
  }
}
export function setFailedTransactions(response) {
  return {
    type: actionTypes.SET_STRIPE_FAILED_TRANSACTIONS,
    failedTransactions: response.charges,
    teams: response.teams,
  }
}
export function setPendingTransactions(response) {
  return {
    type: actionTypes.SET_STRIPE_PENDING_TRANSACTIONS,
    pendingTransactions: response.charges,
    teams: response.teams,
  }
}
export function setUnpaidInvoices(response) {
  return {
    type: actionTypes.SET_STRIPE_UNPAID_INVOICES,
    unpaidInvoices: response.invoices,
    teams: response.teams,
  }
}
export function setMarkedAsPaidInvoices(response) {
  return {
    type: actionTypes.SET_STRIPE_MARKED_AS_PAID_INVOICES,
    markedAsPaid: response.invoices,
    teams: response.teams,
  }
}
export function successDeletingSubscription(response) {
  return { type: actionTypes.SET_STRIPE_CUSTOMER, customer: response.customer }
}
export function successUpdatingSubscription(subscription, customer) {
  return {
    type: actionTypes.UPDATE_STRIPE_SUBSCRIPTION,
    subscription,
    customer,
  }
}
export function successUpdatingInvoice(invoice) {
  return { type: actionTypes.UPDATE_STRIPE_INVOICE, invoice }
}
export function successCreatingSubscription(subscription) {
  return { type: actionTypes.CREATE_STRIPE_SUBSCRIPTION, subscription }
}
export function successCreatingRefund(refund) {
  return { type: actionTypes.CREATE_STRIPE_REFUND, refund }
}
export function successRemovingPlans(subscriptionItems) {
  return { type: actionTypes.REMOVE_STRIPE_PLANS_FROM_SUB, subscriptionItems }
}
export function successAcceptingTOS(response) {
  return { type: actionTypes.ACCEPT_STRIPE_TOS, response }
}
export function successCreatingCharge(response) {
  return { type: actionTypes.CREATE_STRIPE_CHARGE, response }
}
export function successUpdatingCharge(charge) {
  return { type: actionTypes.UPDATE_STRIPE_CHARGE, charge }
}
export function successCreatingInvoice(response) {
  return { type: actionTypes.CREATE_STRIPE_INVOICE, response }
}
export function successUpdatingAccount(response) {
  return { type: actionTypes.SET_STRIPE_ACCOUNT, response }
}
export function setStripeAccount(response) {
  return { type: actionTypes.SET_STRIPE_ACCOUNT, response }
}
export function successAddingCardToken(response) {
  return { type: actionTypes.SET_STRIPE_CUSTOMER, customer: response.customer }
}
export function successAddingTokenCoworksCustomer(response) {
  return { type: actionTypes.SET_COWORKS_CUSTOMER, customer: response.customer }
}
export function successDeletingDiscount(subscription, customer) {
  return {
    type: actionTypes.UPDATE_STRIPE_SUBSCRIPTION,
    subscription,
    customer,
  }
}
export function successConnectingToStripe(response) {
  return { type: actionTypes.CONNECT_TO_STRIPE, response }
}
export function successVerifyingBank(response) {
  return { type: actionTypes.SET_STRIPE_CUSTOMER, customer: response.customer }
}
export function clearStripePaymentData() {
  return { type: actionTypes.CLEAR_STRIPE_PAYMENT_DATA }
}

// ACTION DISPATCHERS
export const getCustomer = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .getStripeCustomer(params)
      .then(response => {
        dispatch(setCustomer(response))
        dispatch(uiActions.updateFetchingStatus(false))

        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}
export const getCoworksCustomer = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_COWORKS_CUSTOMER))
    apiService
      .getStripeCoworksCustomer(params)
      .then(response => {
        dispatch(setCoworksCustomer(response))
        dispatch(
          uiActions.updateFetchingStatus(false, FETCHING_COWORKS_CUSTOMER)
        )

        resolve(response)
      })
      .catch(err => {
        reject(err)
        dispatch(
          uiActions.updateFetchingStatus(false, FETCHING_COWORKS_CUSTOMER)
        )
      })
  })
}
export const addTokenToCustomer = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .addSourceTokenToCustomer(params)
      .then(response => {
        if (params.coworks_customer_id) {
          dispatch(successAddingTokenCoworksCustomer(response))
        } else {
          dispatch(successAddingCardToken(response))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const removeCustomerBillingSource = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .removeStripeCustomerSource(params)
      .then(response => {
        if (params.coworks_customer_id) {
          dispatch(successAddingTokenCoworksCustomer(response))
        } else {
          dispatch(successAddingCardToken(response))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const cancelSubscription = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .cancelStripeSubscription(params)
      .then(response => {
        dispatch(successUpdatingTeam(response.team))
        dispatch(successDeletingSubscription(response))
        resolve(response)
      })
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const updateSubscription = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    return apiService
      .updateStripeSubscription(params)
      .then(response => {
        dispatch(setCustomer(response))
        resolve(response)
      })
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const getInvoices = (params, type) => dispatch => {
  return new Promise((resolve, reject) => {
    if (type) {
      dispatch(uiActions.updateFetchingStatus(true, `invoices${type}`))
    } else {
      dispatch(uiActions.updateFetchingStatus(true))
    }
    apiService
      .getStripeInvoices(params)
      .then(response => {
        switch (type) {
          case 'Paid':
            dispatch(setMarkedAsPaidInvoices(response))
            dispatch(uiActions.updateFetchingStatus(false, `invoices${type}`))
            break
          case 'Unpaid':
            dispatch(setUnpaidInvoices(response))
            dispatch(uiActions.updateFetchingStatus(false, `invoices${type}`))
            break
          case 'Closed':
            dispatch(setUnpaidInvoices(response))
            dispatch(uiActions.updateFetchingStatus(false, `invoices${type}`))
            break
          default:
            // default is unpaid invoices
            dispatch(setUnpaidInvoices(response))
            dispatch(uiActions.updateFetchingStatus(false, `invoices${type}`))
            break
        }
        resolve(response)
      })
      .catch(err => {
        if (type) {
          dispatch(uiActions.updateFetchingStatus(false, `invoices${type}`))
        } else {
          dispatch(uiActions.updateFetchingStatus(false))
        }
        reject(err)
      })
  })
}
export const getTransactions = (params, type) => dispatch => {
  return new Promise((resolve, reject) => {
    if (type) {
      dispatch(uiActions.updateFetchingStatus(true, `transactions${type}`))
    } else {
      dispatch(uiActions.updateFetchingStatus(true))
    }
    apiService
      .getStripeTransactions(params)
      .then(response => {
        switch (type.toLowerCase()) {
          case 'successful':
            dispatch(setTransactions(response))
            dispatch(
              dispatch(
                uiActions.updateFetchingStatus(false, `transactions${type}`)
              )
            )
            break
          case 'failed':
            dispatch(setFailedTransactions(response))
            dispatch(
              dispatch(
                uiActions.updateFetchingStatus(false, `transactions${type}`)
              )
            )
            break
          case 'pending':
            dispatch(setPendingTransactions(response))
            dispatch(
              dispatch(
                uiActions.updateFetchingStatus(false, `transactions${type}`)
              )
            )
            break
          default:
            dispatch(uiActions.updateFetchingStatus(false))
            break
        }
        resolve(response)
      })
      .catch(err => {
        if (type) {
          dispatch(uiActions.updateFetchingStatus(false, `transactions${type}`))
        } else {
          dispatch(uiActions.updateFetchingStatus(false))
        }
        reject(err)
      })
  })
}
export const getInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .getStripeInvoice(params)
      .then(response => {
        dispatch(setInvoice(response))
        resolve(response)
      })
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}
export const getTransaction = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .getStripeTransaction(params)
      .then(response => {
        dispatch(setTransaction(response))
        resolve(response)
      })
      .then(() => dispatch(uiActions.updateFetchingStatus(false)))
      .catch(err => {
        reject(err)
        dispatch(uiActions.updateFetchingStatus(false))
      })
  })
}

export const getUpcomingInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    //dispatch(uiActions.updateFetchingStatus(true));
    apiService
      .getStripeUpcomingInvoice(params)
      .then(response => {
        dispatch(setInvoice(response))
        resolve(response)
      })
      .then() //dispatch(uiActions.updateFetchingStatus(false)))
      .catch(err => {
        reject(err)
        //dispatch(uiActions.updateFetchingStatus(false));
      })
  })
}

export const updateInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateStripeInvoice(params)
      .then(response => {
        dispatch(successUpdatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const voidInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .voidStripeInvoice(params)
      .then(response => {
        dispatch(successUpdatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const payInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .payStripeInvoice(params)
      .then(response => {
        dispatch(successUpdatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const splitInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .splitStripeInvoice(params)
      .then(response => {
        dispatch(successUpdatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const resendInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .resendStripeInvoice(params)
      .then(response => {
        dispatch(successUpdatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const deleteInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .deleteStripeInvoice(params)
      .then(response => {
        dispatch(successUpdatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const finalizeInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .finalizeStripeInvoice(params)
      .then(response => {
        dispatch(successUpdatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const createCharge = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createStripeCharge(params)
      .then(response => {
        dispatch(successCreatingCharge(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const updateCharge = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateStripeCharge(params)
      .then(response => {
        dispatch(successUpdatingCharge(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const createInvoice = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createStripeInvoice(params)
      .then(response => {
        dispatch(successCreatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const createInvoiceItems = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createStripeInvoiceItems(params)
      .then(response => {
        dispatch(successCreatingInvoice(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const updateCustomer = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateStripeCustomer(params)
      .then(response => {
        dispatch(successUpdatingCustomer(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const updateAccount = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .updateStripeAccount(params)
      .then(response => {
        dispatch(successUpdatingAccount(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}
export const getAccount = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .getStripeAccount(params)
      .then(response => {
        dispatch(setStripeAccount(response))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const createRefund = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true))
    apiService
      .createStripeRefund(params)
      .then(response => {
        dispatch(successCreatingRefund(response))
        dispatch(uiActions.updateFetchingStatus(false))
        resolve(response)
      })
      .catch(err => {
        dispatch(uiActions.updateFetchingStatus(false))
        reject(err)
      })
  })
}

export const deleteDiscount = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .deleteStripeDiscount(params)
      .then(response => {
        dispatch(
          successDeletingDiscount(response.subscription, response.customer)
        )
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const createAndAddCouponToSubscription = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .createCouponForSubscription(params)
      .then(response => {
        dispatch(
          successUpdatingSubscription(response.subscription, response.customer)
        )
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const connectToStripeStandard = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .connectToStripeStandard(params)
      .then(response => {
        dispatch(successConnectingToStripe(response))
        if (response.community) {
          dispatch(uiActions.setActiveCommunity(response.community))
        }
        dispatch(uiActions.clearCampusData())
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const verifyBank = params => dispatch => {
  return new Promise((resolve, reject) => {
    apiService
      .verifyStripeBank(params)
      .then(response => {
        dispatch(successVerifyingBank(response))
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
