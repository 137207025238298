import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import Members from './Members'
import NewMember from './NewMember/NewMember'
import MemberDetail from './MemberDetail/MemberDetail'

function MembersContainer({ match, history, location, role }) {
  const { path, url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <TabContent>
          <TabPane>
            <Members location={location} role={role} history={history} />
          </TabPane>
        </TabContent>
      </Route>
      <Route path={`${path}/add-member`}>
        <NewMember
          location={location}
          match={match}
          role={role}
          history={history}
        />
      </Route>
      <Route path={`${path}/:memberId/:tabId`}>
        <MemberDetail
          location={location}
          match={match}
          role={role}
          history={history}
        />
      </Route>
    </Switch>
  )
}

MembersContainer.displayName = 'Members Container'
MembersContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  role: PropTypes.string,
}

export default MembersContainer
