import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import Teams from './Teams'
import NewTeam from './NewTeam/NewTeam'
import TeamDetail from './TeamDetail/TeamDetail'

function TeamsContainer({ match, history, location, role }) {
  const { path, url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <TabContent>
          <TabPane>
            <Teams
              role={role}
              match={match}
              history={history}
              location={location}
            />
          </TabPane>
        </TabContent>
      </Route>
      <Route exact path={`${path}/add-team`}>
        <NewTeam
          role={role}
          match={match}
          history={history}
          location={location}
        />
      </Route>
      <Route path={`${path}/:teamId`}>
        <TeamDetail location={location} role={role} history={history} />
      </Route>
    </Switch>
  )
}

TeamsContainer.displayName = 'TeamsContainer'
TeamsContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  role: PropTypes.string,
}

export default TeamsContainer
