/* eslint-disable no-invalid-this */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as userActions from '../../reduxActions/userActions'
import * as uiActions from '../../reduxActions/uiActions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { FormErrors } from '@global/FormErrorsComponent'
import Spinner from '@global/Spinner'
import { LabelStyled } from '@global/Form/FormComponents'
import { Formik, Field } from 'formik'
import { CustomInputComponent } from '@global/Form/FormComponents'
import { Button, Row } from 'reactstrap'
import * as Yup from 'yup'

const Container = styled.div`
  overflow-x: hidden;
  background-size: cover;
  background-attachment: fixed;
  width: 100% !important;
  height: 100vh;
  background-image: linear-gradient(
    to right,
    rgba(08, 90, 110),
    rgba(38, 110, 110),
    rgb(90, 179, 177)
  );

  form {
    display: flex;
  }
`

const Card = styled.div`
  width: 100%;
  min-width: 310px;
  max-width: 500px;
`

export class ResetPassword extends React.Component {
  static propTypes = {
    user_actions: PropTypes.object,
    ui_actions: PropTypes.object,
    isFetching: PropTypes.bool,
    history: PropTypes.object,
    user: PropTypes.object,
    match: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
    }
  }

  UNSAFE_componentWillMount() {
    this.props.user_actions.signOutUser()
  }

  handleResetPass = values => {
    this.props.user_actions
      .resetPassword(
        values.password,
        values.confirmPassword,
        this.props.match.params.token
      )
      .then(response => {
        this.setState({
          submitted: true,
        })
        toast.success(
          'Password has been successfully reset. Please return to the app and login.'
        )
      })
      .catch(err => {
        this.setState({
          disableForm: true,
        })
        toast.error(err.response.data.errors)
        this.props.history.push('/forgot_password')
      })
  }

  render() {
    return (
      <Container id="Login">
        {!this.state.submitted ? (
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .required('Password is required.'),
              confirmPassword: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .test(
                  'passwords-match',
                  'Password Confirmation must match',
                  // eslint-disable-next-line
                  function (value) {
                    // don't change this!!!
                    if (!value) {
                      return false
                    }
                    return this.parent.password === value
                  }
                )
                .required('Password Confirmation is required.'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              this.handleResetPass(values)
            }}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
                setFieldTouched,
              } = props
              // Dev method for understanding errors
              //if (errors && errors !== {}) { console.log(errors); }
              return (
                <form
                  onSubmit={handleSubmit}
                  disabled={this.state.submitted}
                  className="p-5"
                >
                  <Row className="w-100 d-flex justify-content-center">
                    <Card className="mt-2 card p-4">
                      <h1 className="text-center my-3 h2">Reset Password</h1>
                      <div className="col-12">
                        <Field
                          label="Password"
                          name="password"
                          type="password"
                          placeholder="Enter your new password"
                          component={CustomInputComponent}
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          label="Password Confirmation"
                          name="confirmPassword"
                          type="password"
                          placeholder="Enter your password confirmation"
                          component={CustomInputComponent}
                        />
                      </div>
                      <div className="col-12 d-flex justify-content-end">
                        <Button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={!dirty || isSubmitting}
                        >
                          Update Password
                        </Button>
                      </div>
                    </Card>
                  </Row>
                </form>
              )
            }}
          </Formik>
        ) : (
          <Row>
            <div className="col-md-4 offset-md-4 mt-2 card p-4">
              <h1 className="text-center my-3 h2">Reset Password</h1>
              <h6 className="text-muted">Password reset successfully.</h6>
            </div>
          </Row>
        )}
      </Container>
    )
  }
}

ResetPassword.displayName = 'Reset Password'

const mapStateToProps = state => {
  return {
    user: state.user,
    isFetching: state.ui.isFetching,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    user_actions: bindActionCreators(userActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
