/* eslint-disable no-invalid-this,quote-props */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '@reduxActions/roomActions'
import * as roomSelectors from '@reduxSelectors/roomSelectors'
import * as bookingActions from '@reduxActions/bookingActions'
import styled from 'styled-components'
import { ThemeProvider, withTheme } from 'styled-components'
import { lightTemplate } from '../../../theme/Theme'
import colors from '@global/Colors/colors'

import moment from 'moment'
import { toast } from 'react-toastify'
import * as _ from 'lodash'

import * as Constants from '@global/Constants'
import { QueryStringToJSON } from '@global/Utilities/routeUtilities'
import Spinner from '@global/Spinner'

import EventModalComponent from '../../Calendar/EventModalComponent'
import BookingsResults from './BookingsResults'
import BookingsFilter from './BookingsFilter'

import withPublicCommunityContext from '../withPublicCommunityContext'
import { isThisSecond } from 'date-fns'
import {
  resolveFetchingStatus,
  FETCHING_ROOMS,
} from '@global/Constants/FetchingConstants'

const Container = styled.div`
  @media (max-width: 768px) {
    padding: 0;
  }
`
const Wrapper = styled.div`
  height: 100%;
`
const Header = styled.div`
  height: 100%;
`
const ResultContainer = styled.div`
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
`
const BundleWrapper = styled.div`
  border-left: 1px #ccc solid;
  border-right: 1px #ccc solid;
  border-bottom: 1px #ccc solid;
`
const FilterWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  border-left: 1px #ccc solid;
  border-right: 1px #ccc solid;
  border-bottom: 1px #ccc solid;
`

class Bookings extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    booking_actions: PropTypes.object,
    publicCommunity: PropTypes.object,
    room_actions: PropTypes.object,
    location: PropTypes.object,
    ui_actions: PropTypes.object,
    match: PropTypes.object,
    bookings: PropTypes.array,
    isFetching: PropTypes.bool,
    isFetchingRooms: PropTypes.bool,
    rooms: PropTypes.array,
    pagination: PropTypes.object,
    theme: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const match = this.props.match
    const query = QueryStringToJSON(this.props.location)

    let roomId = null
    let campusId = null
    let earliestDate = moment()
    let latestDate = moment().add('years', 1)

    // process the roomId from the URL
    if (match && match.params && match.params.roomId) {
      roomId = parseInt(match.params.roomId, 10)
    }
    // process the campusId from the URL
    if (match && match.params && match.params.campusId) {
      campusId = parseInt(match.params.campusId, 10)
    }
    let activeCampus = this.props.publicCommunity.campuses.find(
      campus => `${campus.id}` === `${campusId}`
    )

    const params = {}
    if (!activeCampus) {
      // if there's an invalid campus id in the header, let's push to the right location
      activeCampus = this.props.publicCommunity.campuses[0]
      params.pathname = `/bookings/${activeCampus.id}`
      if (query && query.start_date) {
        params.search = `?start_date=${query.start_date}`
      }
    }
    if (!_.isEmpty(params)) {
      this.props.history.push(params)
    }

    // process the roomId from the URL
    if (query && query.start_date) {
      earliestDate = moment.unix(query.start_date)
      latestDate = moment.unix(query.start_date).add('years', 1)
    }

    this.state = {
      fetchedBookings: false,
      isFetchingRooms: false,
      rooms: [],
      earliestDate,
      latestDate,
      roomId,
      room: null,
      page: Constants.START_PAGE_DEFAULT,
      perPage: Constants.PER_PAGE_DEFAULT,
      showEvents: true,
      showBookings: true,
      isPrimaryOpen: false,
      event: null,
      activeCampus,
      otherRoomsParams: {
        types: JSON.stringify(['Conference', 'Equipment']),
        serializer: 'RoomSerializer',
      },
    }
  }

  componentDidMount() {
    this.props.booking_actions.clearBookings()
    this.getRooms()
  }

  getRooms = force => {
    const { room_actions, rooms, publicCommunity } = this.props
    const {
      earliestDate,
      page,
      activeCampus,
      isFetchingRooms,
      otherRoomsParams,
    } = this.state

    if (!isFetchingRooms || force) {
      if (!rooms || (rooms && rooms.length === 0) || force) {
        this.setState({ isFetchingRooms: true })
        room_actions.clearRooms()
        room_actions
          .getRoomsAtCampus(activeCampus.id, otherRoomsParams)
          .then(response => {
            this.parseRooms(response.rooms)
            this.setInitialRoom(response.rooms)
            this.setState({ isFetchingRooms: false })
          })
          .catch(err => {
            toast.error('Error getting the rooms!')
            console.error(err)
            this.setState({ isFetchingRooms: false })
          })
      } else {
        this.parseRooms(this.props.rooms)
        this.setInitialRoom(this.props.rooms)
      }
    }
  }

  setInitialRoom = rooms => {
    if (!this.state.roomId) return

    for (let i = 0; i < rooms.length; i++) {
      const room = rooms[i]
      if (room.id === this.state.roomId) {
        this.handleRoomSearchItemSelected(room)
        break
      }
    }
  }

  parseRooms = rooms => {
    const existingRooms = _.clone(rooms)
    const nextRooms = []
    existingRooms.map((room, i) => {
      nextRooms.push({ ...room, filterActive: true })
    })
    this.setState({
      rooms: nextRooms,
      fetchedRooms: true,
    })
  }

  updateDataApi = (campusId, page, earliestDate, continuous) => {
    const {
      fetchedBookings,
      loading,
      room,
      showEvents,
      showBookings,
    } = this.state
    if (fetchedBookings || loading || !room) return

    if (continuous) {
      this.setState({
        fetchedBookings: true,
      })
    } else {
      this.setState({
        loading: true,
      })
    }

    let typeParams = {}
    if (showEvents && !showBookings) {
      typeParams = { type: 'Event' }
    } else if (showEvents && showBookings) {
      typeParams = { include_events: true }
    }

    const queryParams = {
      private: true,
      page: page,
      from_date: earliestDate,
      room_ids: JSON.stringify([room.id]),
      to_date: moment(earliestDate).add('years', 1),
      per_page: this.state.perPage,
      serializer: 'Dashboard',
      ...typeParams,
    }

    this.props.booking_actions
      .getBookings(campusId, queryParams)
      .then(response => {
        this.setState({
          fetchedBookings: false,
          loading: false,
        })
      })
      .catch(err => {
        this.setState({
          fetchedBookings: false,
          loading: false,
        })
      })
  }

  nextPage = () => {
    const { activeCampus } = this.state
    const { pagination, bookings } = this.props
    const stateCopy = Object.assign({}, this.state)
    const page = stateCopy.page
    const earliestDate = stateCopy.earliestDate
    if (
      pagination &&
      bookings &&
      page < pagination.total_pages &&
      bookings.length < pagination.total_objects
    ) {
      this.setState(
        {
          page: page + 1,
        },
        () => this.updateDataApi(activeCampus.id, page, earliestDate, true)
      )
    }
  }

  handleCheckboxChange = event => {
    const { earliestDate, activeCampus } = this.state
    const page = Constants.START_PAGE_DEFAULT

    this.setState(
      {
        [event.target.name]: !this.state[event.target.name],
      },
      () => this.updateDataApi(activeCampus.id, page, earliestDate, false)
    )
  }

  handleStartDateTimeChanged = newDate => {
    const { earliestDate, activeCampus } = this.state
    const page = Constants.START_PAGE_DEFAULT

    if (earliestDate.isSame(newDate, 'day')) return
    this.props.history.push({ search: `?start_date=${newDate.unix()}` })

    this.setState(
      {
        earliestDate: newDate,
        page: page + 1,
      },
      () => this.updateDataApi(activeCampus.id, page, earliestDate, false)
    )
  }
  handleCampusChanged = event => {
    const newCampusId = event.target.value
    const self = this
    const newCampus = this.props.publicCommunity.campuses.find(
      campus => `${campus.id}` === newCampusId
    )
    this.props.history.push({
      pathname: `/bookings/${newCampus.id}`,
      search: this.props.location.search,
    })
    this.setState(
      {
        activeCampus: newCampus,
        room: null,
      },
      () => {
        self.getRooms(true)
      }
    )
  }
  handleRoomSearchItemSelected = newRoom => {
    const { room, earliestDate, activeCampus } = this.state
    const page = Constants.START_PAGE_DEFAULT

    if (room && room.id === newRoom.id) return
    this.props.history.push({
      pathname: `/bookings/${activeCampus.id}/${newRoom.id}`,
      search: this.props.location.search,
    })

    this.setState(
      {
        room: newRoom,
        page: page + 1,
      },
      () => this.updateDataApi(activeCampus.id, page, earliestDate, false)
    )
  }
  handleRoomSearchItemCleared = () => {
    this.setState({ room: null })
  }
  roomDataResolver = response => {
    return response.rooms
  }
  togglePrimary = () => {
    this.setState({
      isPrimaryOpen: !this.state.isPrimaryOpen,
    })
  }
  renderModal = () => {
    const { publicCommunity } = this.props
    const brandColor =
      publicCommunity.community_preference.branding_primary_color

    if (this.state.event) {
      return (
        <EventModalComponent
          user={null}
          role={null}
          history={this.props.history}
          isPrimaryOpen={this.state.isPrimaryOpen}
          togglePrimary={this.togglePrimary}
          canEdit={false}
          headerColor={brandColor}
          hideButton
          fetchFull={false}
          event={this.state.event}
          publicCommunity={this.props.publicCommunity}
        />
      )
    }
    return null
  }
  handleEventClicked = event => {
    // MODAL
    this.setState({
      event: this.parseBooking(event),
      isPrimaryOpen: !this.state.isPrimaryOpen,
    })
  }
  parseBooking = event => {
    const name = event.name ? event.name : 'Booking'
    const obj = {
      ...event,
      start: moment(event.start_time).toDate(),
      end: moment(event.end_time).toDate(),
      name,
      description: event.description,
      allDay: false, // currently don't support full day events/bookings
    }
    if (event.type === 'Event' && this.state.showEvents) {
      return obj
    } else if (
      (event.type === 'Booking' ||
        !event.type ||
        event.type === null ||
        event.type === undefined) &&
      this.state.showBookings
    ) {
      return obj
    }
    return null
  }

  modifyColors = () => {
    const { publicCommunity } = this.props
    const isWhiteLabeled = publicCommunity.community_preference.is_whitelabeled
    const color = isWhiteLabeled
      ? '#7c949c'
      : publicCommunity.community_preference.branding_primary_color
    const colorsCopy = Object.assign(colors, { primary: color })

    return colorsCopy
  }

  /* eslint-disable max-len */
  render() {
    const {
      earliestDate,
      showBookings,
      showEvents,
      room,
      loading,
      fetchedBookings,
      activeCampus,
      otherRoomsParams,
    } = this.state
    const { publicCommunity } = this.props
    const newColors = this.modifyColors()

    return (
      <ThemeProvider theme={lightTemplate(newColors)}>
        <Container className="p-md-5">
          <Wrapper className="card">
            <FilterWrapper className="card-header">
              <BookingsFilter
                room={room}
                handleItemSelected={this.handleRoomSearchItemSelected}
                handleSearchCleared={this.handleRoomSearchItemCleared}
                handleCampusChanged={this.handleCampusChanged}
                campuses={publicCommunity.campuses}
                selectedCampusId={activeCampus.id}
                activeCampus={activeCampus}
                otherRoomsParams={otherRoomsParams}
                dataResolver={this.roomDataResolver}
                handleStartDateTimeChanged={this.handleStartDateTimeChanged}
                handleCheckboxChange={this.handleCheckboxChange}
                theme={this.props.theme}
                showBookings={showBookings}
                showEvents={showEvents}
                startDate={earliestDate}
                publicCommunity={publicCommunity}
              />
            </FilterWrapper>
            <ResultContainer className="card-block">
              <BookingsResults
                bookings={this.props.bookings}
                startDate={earliestDate}
                loading={loading}
                fetching={fetchedBookings}
                showBookings={showBookings}
                showEvents={showEvents}
                onClick={this.handleEventClicked}
                nextPage={this.nextPage}
                room={room}
                publicCommunity={publicCommunity}
              />
            </ResultContainer>
          </Wrapper>
          {this.renderModal()}
        </Container>
      </ThemeProvider>
    )
  }
}

Bookings.displayName = 'Bookings'

function mapStateToProps(state) {
  return {
    bookings: state.bookings.bookings,
    isFetching: state.ui && state.ui.isFetching ? state.ui.isFetching : false,
    isFetchingRooms: resolveFetchingStatus(state, FETCHING_ROOMS),
    rooms: roomSelectors.getRoomsList(state),
    pagination: roomSelectors.getRoomsPagination(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    room_actions: bindActionCreators(roomActions, dispatch),
    booking_actions: bindActionCreators(bookingActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPublicCommunityContext()(Bookings))
