/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import styled from 'styled-components'
import defaultPic from '../../img/placeholders/otto_black_white.jpeg'

const RoundedImageStyled = styled.img`
  /* border-color: ${props => props.theme.colorPrimary}!important; */
  /* border: 2px solid; */
  border-radius: 50em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  /* margin-right: 0.5rem !important; */
  width: ${props => props.dimens};
  height: ${props => props.dimens};
`

export default function RoundedImage({
  photo = defaultPic,
  alt = 'Image',
  size = 'small',
  className,
}) {
  let dimens = '35px'
  switch (size) {
    case 'small':
      dimens = '35px'
      break
    case 'medium':
      dimens = '60px'
      break
    case 'med-large':
      dimens = '100px'
      break
    // case 'large-':
    //   dimens = '150px'
    //   break
    case 'large':
      dimens = '200px'
      break
    default:
      break
  }
  let source = photo instanceof Object ? photo.medium_file_url : photo

  if (!source) {
    source = defaultPic
  }
  return (
    <RoundedImageStyled
      src={source}
      alt={alt}
      width={dimens}
      height={dimens}
      className={className}
    />
  )
}
RoundedImage.displayName = 'RoundedImage'
RoundedImage.defaultProps = {
  photo: defaultPic,
  alt: 'Image',
  size: 'small',
}
