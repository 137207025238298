import * as apiService from '../services/apiService'
import * as actionTypes from './actionTypes'
import * as uiActions from './uiActions'
import * as bookingActions from './bookingActions'
import {
  FETCHING_EXBOOKING_ROOMS,
  FETCHING_EXBOOKING
} from '@global/Constants/FetchingConstants'

// ACTION CREATORS
export function setRooms(rooms, meta = null) {
  return { type: actionTypes.SET_EXBOOKING_ROOMS, rooms, meta }
}
export function updateRooms(rooms, meta = null) {
  return { type: actionTypes.UPDATE_EXBOOKING_ROOMS, rooms, meta }
}
export function clearRooms() {
  return { type: actionTypes.CLEAR_EXBOOKING_ROOMS }
}

// ACTION DISPATCHERS
export const getRooms = (params, nextPage) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_EXBOOKING_ROOMS))
    if (!nextPage) dispatch(clearRooms())
    return apiService
      .getExternalRooms(params)
      .then(response => {
        if (nextPage) {
          dispatch(updateRooms(response.rooms, response.meta))
        } else {
          dispatch(setRooms(response.rooms, response.meta))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_EXBOOKING_ROOMS))
      )
  })
}

export const getRoomAvailability = (params, nextPage) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_EXBOOKING_ROOMS))
    if (!nextPage) dispatch(clearRooms())
    return apiService
      .getExternalRooms(params)
      .then(response => {
        if (nextPage) {
          dispatch(updateRooms(response.rooms, response.meta))
        } else {
          dispatch(setRooms(response.rooms, response.meta))
        }
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_EXBOOKING_ROOMS))
      )
  })
}

export const createBooking = params => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(uiActions.updateFetchingStatus(true, FETCHING_EXBOOKING))
    return apiService
      .createExternalBooking(params)
      .then(response => {
        bookingActions.successCreatingBooking(response.booking)
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() =>
        dispatch(uiActions.updateFetchingStatus(false, FETCHING_EXBOOKING))
      )
  })
}