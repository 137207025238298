import React, { useState } from 'react'
import { Formik, FormikProps, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import '../../../styles/coworks/components/frontDesk/FrontDesk.scss'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { CustomInputComponent, LabelStyled } from '@global/Form/FormComponents'

// DEPENDENCIES
import PropTypes from 'prop-types'
import {
  Button,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap'

export default function ConfirmMessage({
  isOpen,
  member,
  toggleModal,
  toggleSuccessModal,
  sendMessage,
}) {
  const initialValues = {
    firstName: '',
    lastName: '',
    visitorMessage: '',
    email: '',
  }
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    email: Yup.string().email().required('Email is required.'),
  })
  function onFormSubmit(values, { setSubmitting }) {
    sendMessage({
      visitorMessage: values.visitorMessage,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    })
    toggleModal()
    toggleSuccessModal(`You have successfully notified ${member.first_name}`)
    setSubmitting(false)
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="fade in bd-example-modal-lg modal-lg member-modal"
    >
      <div>
        <ModalHeader>
          <div
            id="close-modal"
            className="fixed-top text-black mr-2 mt-2 "
            aria-hidden="true"
            onClick={toggleModal}
          >
            <FontAwesomeIcon icon="times" color="black" />
          </div>
          <h1 className="mb-2 member-title mt-3">
            Message {`${member.first_name}`}?
          </h1>
          <div className="mb-2">
            {`Let ${member.first_name} ${member.last_name} know you're here!`}
          </div>
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFormSubmit}
          render={formProps => {
            return (
              <Form>
                <ModalBody className="py-0">
                  <Row>
                    <Col xs="6" className="form-group align-items-center">
                      <Field
                        type="text"
                        name="firstName"
                        label={'First Name'}
                        placeholder="John"
                        className="form-control visitor-message form-control-lg"
                        component={CustomInputComponent}
                      />
                    </Col>
                    <Col xs="6" className="form-group align-items-center">
                      <Field
                        type="text"
                        name="lastName"
                        label={'Last Name'}
                        placeholder="Smith"
                        className="form-control visitor-message form-control-lg"
                        component={CustomInputComponent}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6" className="form-group align-items-center">
                      <Field
                        type="text"
                        name="email"
                        label={'Email'}
                        placeholder="visitor@email.com"
                        className="form-control visitor-message form-control-lg"
                        component={CustomInputComponent}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="form-group align-items-center">
                      <LabelStyled htmlFor="message">
                        Message (optional)
                      </LabelStyled>
                      <textarea
                        className="form-control visitor-message form-control-lg"
                        id="message"
                        name="visitorMessage"
                        maxLength="75"
                        rows="3"
                        placeholder=""
                        value={formProps.values.visitorMessage}
                        onChange={formProps.handleChange}
                      />
                      <ErrorMessage name="visitorMessage" />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end align-items-center">
                  <Button className="cancel-btn btn-lg" onClick={toggleModal}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="send-btn btn-lg"
                    disabled={formProps.isSubmitting}
                  >
                    Send
                  </Button>
                </ModalFooter>
              </Form>
            )
          }}
        />
      </div>
    </Modal>
  )
}

ConfirmMessage.propTypes = {
  member: PropTypes.object,
  toggleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleSuccessModal: PropTypes.func,
  sendMessage: PropTypes.func,
}
ConfirmMessage.displayName = 'ConfirmMessage'
