/* eslint-disable react/no-multi-comp,react/display-name */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Anchor from '@global/Base/Anchor/Anchor'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import * as leadActions from '../../reduxActions/leadActions'
import * as uiActions from '../../reduxActions/uiActions'
import { QueryStringToJSON } from '@global/Utilities/routeUtilities'
import { toast } from 'react-toastify'

import TagRow from '@global/TagRow'
import defaultUserPic from '../../img/placeholders/otto_black_white.jpeg'

import styled from 'styled-components'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import * as Constants from '@global/Constants'
import { LeadsTabInfo, LEAD, PROSPECT } from '@global/Constants/CRMConstants'
import NothingFound from '@global/NothingFound'
import PaginatedTable from '@global/Base/Layout/PaginatedTable'
import Spinner from '@global/Spinner'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'
import {
  resolveFetchingStatus,
  FETCHING_LEADS,
} from '@global/Constants/FetchingConstants'
import CoworksSearch from '@global/Search/CoworksSearch'
import QuickSearch from '@global/Search/QuickSearch'
import LeadSearchRow from './LeadSearchRow'
import moment from 'moment'
import LeadsTable from './LeadsTable'
import classnames from 'classnames'
import * as _ from 'lodash'

const TextButtonStyled = styled(TextButton)`
  margin: 0;
`
const Container = styled.div``
const Header = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const HeaderText = styled.h4`
  flex: 1;
`
const HeaderSearch = styled.div`
  flex: 2;
`
const HeaderActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`
const TabWrapper = styled.div``

const getDefault = () => {
  const query = QueryStringToJSON(location)
  return query && !_.isEmpty(query, true)
    ? query
    : {
        archived: 'active',
      }
}

const Leads = ({
  activeCommunity,
  lead_actions,
  prospects,
  leads,
  prospectsPagination,
  leadsPagination,
  history,
  location,
  theme,
  match,
}) => {
  const [activeTab, setActiveTab] = useState(
    match.params.tabId ? match.params.tabId : 'leads'
  )
  const defaults = getDefault()

  const toggleTab = tab => {
    if (activeTab !== tab) {
      history.push(`/crm/${tab}`)
    }
  }

  const updateQuery = params => {
    const text = []
    Object.keys(params).map(key => {
      text.push(`${key}=${params[key]}`)
    })
    // history.push({ search: `?${text.join(',')}` })
  }

  const renderPaginatedLeads = type => {
    const isLead = activeTab === LeadsTabInfo[0].slug
    return (
      <LeadsTable
        data={isLead ? leads : prospects}
        dataPagination={isLead ? leadsPagination : prospectsPagination}
        onLeadClick={val => editLeadClicked(val, type)}
        onEditClicked={val => editLeadClicked(val, type)}
        onPromoteToLead={promoteToLead}
        onPromoteToExistingTeamClicked={promoteToMemberExistingTeamClicked}
        onPromoteToNewTeamClicked={promoteToMemberNewTeamClicked}
        type={type}
        icon={'user-plus'}
        theme={theme}
        onQueryChange={updateQuery}
        archivedDefault={defaults.archived}
        placeholderHandleClick={() => handleNewLeadClicked(type)}
      />
    )
  }

  const editLeadClicked = (lead, type) => {
    history.push(`/crm/${type}/${lead.id}`, { lead })
  }
  const promoteToLead = lead => {
    const isLead = activeTab === LeadsTabInfo[0].slug
    lead_actions
      .updateContact(lead, isLead ? LEAD : PROSPECT)
      .then(() => {
        toast.success('Successfully marked as a lead')
      })
      .catch(err => {
        if (err) {
          toast.error(err.message)
        }
      })
  }
  const promoteToMemberExistingTeamClicked = lead => {
    history.push(`/directory/members/add-member?${lead.id}`, {
      lead,
    })
  }
  const promoteToMemberNewTeamClicked = lead => {
    history.push(`/directory/teams/add-team?${lead.id}`, {
      lead,
      activeTab: 'members',
    })
  }
  const handleNewLeadClicked = type => {
    history.push(`/crm/${type}/add`)
  }
  const handleSearchItemSelected = (lead, type) => {
    history.push(`/crm/${type}/${lead.id}`, { lead })
  }
  const redirectReport = () => {
    history.push(`/reports/${activeTab}`)
  }

  const renderSearchField = type => {
    const isLead = activeTab === LeadsTabInfo[0].slug
    return (
      <QuickSearch
        type="Lead"
        showTitle={false}
        handleSearchItemSelected={val => handleSearchItemSelected(val, type)}
        otherParams={{
          community_id: activeCommunity.id,
          is_lead: isLead,
          type: isLead ? 'Lead' : null,
        }}
        placeholder={
          isLead
            ? "Start typing a lead's name or email..."
            : "Start typing a prospect's name or email..."
        }
        renderSingleValue={() => {}}
      />
    )
  }

  const renderActions = type => {
    return (
      <HeaderActions>
        <TextButtonStyled color="primary" onClick={redirectReport}>
          <FontAwesomeIcon icon={'book'} />{' '}
          {activeTab === LeadsTabInfo[0].slug
            ? 'Leads Report'
            : 'Prospects Report'}
        </TextButtonStyled>
        <ButtonStyled
          style={{ marginLeft: '8px' }}
          onClick={() => handleNewLeadClicked(type)}
        >
          <FontAwesomeIcon icon="user-plus" />{' '}
          {activeTab === LeadsTabInfo[0].slug ? 'New Lead' : 'New Prospect'}
        </ButtonStyled>
      </HeaderActions>
    )
  }

  const getTabContent = (header, type) => (
    <TabWrapper>
      <Header>
        <HeaderText>{header}</HeaderText>
        <HeaderSearch>{renderSearchField(type)}</HeaderSearch>
        <HeaderActions>{renderActions(type)}</HeaderActions>
      </Header>
      {renderPaginatedLeads(type)}
    </TabWrapper>
  )

  return (
    <Container>
      <Nav tabs>
        {LeadsTabInfo.map(tab => (
          <NavItem>
            <NavLink
              to={`${tab.slug}/`}
              className={classnames({
                active: activeTab === tab.slug,
              })}
              onClick={() => toggleTab(tab.slug)}
            >
              {tab.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {activeTab === LeadsTabInfo[0].slug && (
          <TabPane tabId={LeadsTabInfo[0].slug}>
            {getTabContent('Leads', LeadsTabInfo[0].slug)}
          </TabPane>
        )}
      </TabContent>
      <TabContent activeTab={activeTab}>
        {activeTab === LeadsTabInfo[1].slug && (
          <TabPane tabId={LeadsTabInfo[1].slug}>
            {getTabContent('Prospects', LeadsTabInfo[1].slug)}
          </TabPane>
        )}
      </TabContent>
    </Container>
  )
}

Leads.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  lead_actions: PropTypes.object,
  activeCampus: PropTypes.object,
  activeCommunity: PropTypes.object,
  theme: PropTypes.object,
  leads: PropTypes.array,
  prospects: PropTypes.array,
  leadsPagination: PropTypes.object,
  prospectsPagination: PropTypes.object,
  ui_actions: PropTypes.object,
}
Leads.displayName = 'Leads'

function mapStateToProps(state) {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    leads: state.leads && state.leads.leads ? state.leads.leads : null,
    leadsPagination:
      state.leads && state.leads.leadsMeta
        ? state.leads.leadsMeta.pagination
        : null,
    prospects:
      state.leads && state.leads.prospects ? state.leads.prospects : null,
    prospectsPagination:
      state.leads && state.leads.prospectsMeta
        ? state.leads.prospectsMeta.pagination
        : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    lead_actions: bindActionCreators(leadActions, dispatch),
    ui_actions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leads)
