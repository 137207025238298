import React from 'react'
import styled from 'styled-components'

import roomPlaceholder from '@app/img/placeholders/rooms_placeholder.jpeg'

const height = props => {
  return props.height ? props.height : '180px'
}
const width = props => {
  return props.width ? props.width : '100%'
}
const resolvePlaceholder = props => {
  const imageType = props.imageType ? props.imageType.toLowerCase() : ''
  switch (imageType) {
    case 'room':
      return roomPlaceholder
    default:
      return roomPlaceholder
  }
}

const Img = styled.img`
  height: ${props => height(props)};
  width: ${props => width(props)};
  background: url(${props =>
    props.imageUrl ? props.imageUrl : resolvePlaceholder(props)});
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  background-position: center;
`

export default Img
