import { useReducer } from 'react'
import * as apiService from '../services/apiService'

const FETCHING = 'FETCHING';
const RESOLVE_FETCH = 'RESOLVE_FETCH';
const RESOLVE_FETCH_FAILED = 'RESOLVE_FETCH_FAILED';

const initialState = {
  response: null,
  loading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case FETCHING:
      return {
        loading: true,
        response: null
      };
    case RESOLVE_FETCH:
      return {
        loading: false,
        response: action.payload,
      };
    case RESOLVE_FETCH_FAILED:
      return {
        loading: false,
        response: null,
      };
    default:
      return state;
  }
}

const useAvailabilityCheck = roomId => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const queryApi = async options => {
    dispatch({ type: FETCHING })

    await apiService.getRoomAvailability(roomId, options).then(response =>
      dispatch({ type: RESOLVE_FETCH, payload: response })
    ).catch(err =>
      dispatch({ type: RESOLVE_FETCH_FAILED })
    )

  }

  return [state, queryApi]
}

export default useAvailabilityCheck