/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Row,
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from 'reactstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

const CheckmarkWrapper = styled.div`
  width: 22px;
  margin-left: -12px;
`

const DropdownItemStyled = styled(DropdownItem)`
  display: flex;
  flex-flow: row nowrap;
`

const SelectDropdown = ({
  onChange,
  value,
  options,
  dropdownStyle,
  placeholder,
  label,
  ...other
}) => {
  const getValue = () => {
    let arrItem
    if (value !== null) {
      if (typeof value === 'object') {
        arrItem = options.filter(option => option.value.slug === value.slug)[0]
      } else {
        arrItem = options.filter(option => option.value === value)[0]
      }
    }
    return arrItem ? arrItem.label : placeholder
  }
  return (
    <UncontrolledDropdown {...other}>
      <DropdownToggle style={dropdownStyle} caret>{`${
        label ? `${label} ` : ''
      }${getValue()}`}</DropdownToggle>
      <DropdownMenu style={{ width: '100%' }} className="dropdown-menu">
        {options.map(option => (
          <DropdownItemStyled onClick={() => onChange(option.value)}>
            <CheckmarkWrapper>
              {value === option.value && <FontAwesomeIcon icon={'check'} />}{' '}
            </CheckmarkWrapper>
            {option.label}
          </DropdownItemStyled>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

SelectDropdown.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  dropdownStyle: PropTypes.object,
}

SelectDropdown.defaultProps = {
  placeholder: 'Select',
}

SelectDropdown.displayName = 'Filters'
export default SelectDropdown
