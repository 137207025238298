import * as Yup from 'yup'
import { isValidHex } from '@global/Colors/ColorUtils'

const validationSchema = Yup.object().shape({
  label: Yup.string('This must exist.').max(
    100,
    'Must be shorter than 100 characters'
  ),
})

export default validationSchema
