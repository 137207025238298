/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { breakpoints } from '@global/Constants/BreakpointConstants'

import { IconButton } from '@bit/coworks.base-components.button'

const ModalStyled = styled(Modal)`
  display: flex;
  ${props => {
    if (props.mobile) {
      return css`
        @media (max-width: ${breakpoints.tablet}px) {
          margin: 0;
        }
      `
    }
    return null
  }}
`

const ModalHeaderStyled = styled(ModalHeader)`
  padding: ${props => {
    if (props.noHeaderPadding) {
      return '0 !important'
    }
    return undefined
  }};
  background-color: ${props => {
    if (props.color) {
      return props.color
    }
    return undefined
  }};
  color: ${props => {
    if (props.textColor) {
      return props.textColor
    }
    return undefined
  }};
  .modal-title {
    width: 100%;
  }
`
/* 
  
   */

const HeaderWrapper = styled.div`
  ${props => {
    if (props.mobile) {
      return css`
        @media (min-width: ${breakpoints.tablet + 1}px) {
          display: none;
        }
        border-bottom: 1px solid #ccc;
      `
    }
    return null
  }}
`
const ModalBodyStyled = styled(ModalBody)`
  display: flex;
  overflow-y: auto;
  ${props => {
    if (props.noPadding) {
      return css`
        padding: 0 !important;
      `
    }
    return null
  }}
`
const ModalFooterStyled = styled(ModalFooter)`
  ${props => {
    if (props.mobile) {
      return css`
        @media (min-width: ${breakpoints.tablet + 1}px) {
          display: none;
        }
        border-top: 1px solid #ccc;
      `
    }
    return null
  }}
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const IconButtonStyled = styled(IconButton)`
  width: 40px;
  height: 40px;
`

const GenericModal = ({
  onClose,
  header,
  noPadding,
  noHeaderPadding,
  footer,
  body,
  toggle,
  isLoading,
  onConfirm,
  isOpen,
  cancelLabel = 'Cancel',
  confirmLabel = 'Submit',
  confirmColor = 'primary',
  className,
  mobile = false,
  hideHeaderToggle = false,
  hideFooter = false,
  color = undefined,
  textColor = undefined,
}) => {
  return (
    <ModalStyled
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      mobile={mobile}
    >
      <HeaderWrapper mobile={mobile}>
        {hideHeaderToggle && onClose && (
          <ButtonContainer>
            <IconButtonStyled
              size="small"
              icon={['fal', 'times']}
              onClick={onClose}
            />
          </ButtonContainer>
        )}
        {header && (
          <ModalHeaderStyled
            toggle={!hideHeaderToggle && toggle}
            noHeaderPadding={noHeaderPadding}
            color={color}
            textColor={textColor}
          >
            {header}
          </ModalHeaderStyled>
        )}
      </HeaderWrapper>
      <ModalBodyStyled noPadding={noPadding}>{body}</ModalBodyStyled>
      {!hideFooter && (
        <ModalFooterStyled mobile={mobile}>
          {footer ? (
            footer
          ) : (
            <React.Fragment>
              {onClose && (
                <Button color="secondary" onClick={onClose}>
                  {cancelLabel}
                </Button>
              )}
              {onConfirm && (
                <Button
                  color={confirmColor}
                  disabled={isLoading}
                  onClick={onConfirm}
                >
                  {confirmLabel}
                </Button>
              )}
            </React.Fragment>
          )}
        </ModalFooterStyled>
      )}
    </ModalStyled>
  )
}

GenericModal.displayName = 'GenericModal'

GenericModal.propTypes = {
  header: PropTypes.object,
  footer: PropTypes.object,
  body: PropTypes.object,
  onClose: PropTypes.func,
  noPadding: PropTypes.bool,
  noHeaderPadding: PropTypes.bool,
  isLoading: PropTypes.bool,
  mobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  hideHeaderToggle: PropTypes.bool,
  hideFooter: PropTypes.bool,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelLabel: PropTypes.string,
  confirmColor: PropTypes.string,
  confirmLabel: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
}

export default GenericModal
