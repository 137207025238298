/* eslint-disable react/sort-comp */
/* eslint-disable max-params */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as _ from 'lodash'
import { Formik, Form, Field } from 'formik'
import RoundedImage from '@global/RoundedImage'

import classnames from 'classnames'

import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import CreditCardForm from '@global/Stripe/CreditCardForm'
import * as teamActions from '@reduxActions/teamActions'
import * as stripeActions from '@reduxActions/stripeActions'
import * as Constants from '@global/Constants'

import {
  LabelStyled,
  CustomInputComponent,
  CustomTextAreaComponent,
  SingleDatePicker,
} from '@global/Form/FormComponents'

import ErrorFocus from '@global/Form/ErrorFocus'
import CoworksSearch from '@global/Search/CoworksSearch'
import QuickSearch from '@global/Search/QuickSearch'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from 'reactstrap'
import ButtonStyled, { TextButton } from '@global/Base/Button/ButtonStyled'
import PaymentSourceComponent from '@global/Stripe/PaymentSourceComponent'
import { resolvePreferenceFromCommunity } from '@global/Utilities/ResolveConstants'
import {
  createInvoiceFeesArray,
  getCustomerDefaultSource,
  shouldDisableChargeNow,
  getIntendedPaymentSource,
  SEND_INVOICE,
} from '@global/Constants/PaymentConstants'

import Spinner from '@global/Spinner'

import { convertStripeErrorToMessage } from '@global/ErrorFactory'

import PillToggle from '@global/Base/Toggle/PillToggle'
import Tooltip from '@bit/coworks.base-components.tooltip'

import TeamRowSearch from '@components/Directory/Teams/TeamRowSearch'
import { captureException } from '@global/ErrorFactory'
import defaultTeamPic from '../../img/placeholders/otto_black_white.jpeg'
import { validate } from './InvoiceFormConstants'
import {
  MessageTypeButton,
  MessageTypeTitle,
  MessageTypeDescription,
} from '../Messages/MessageTypeButton'
import {
  resolveFetchingStatus,
  FETCHING_TEAMS,
} from '@global/Constants/FetchingConstants'

const CHARGE = 'charge'
const INVOICE = 'invoice'
const ADD_TO_NEXT_INVOICE = 'addToNextInvoice'

export const FORM_TYPE_TEAM = 'team'
export const FORM_TYPE_GUEST = 'guest'
const EMPTY_INVOICE_ITEM = {
  amount: 0,
  description: '',
  quantity: 1,
  internalId: `${Math.random() * 100}`,
}

class CreateInvoiceForm extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    user: PropTypes.object,
    team_actions: PropTypes.object,
    stripe_actions: PropTypes.object,
    lead_actions: PropTypes.object,
    teams: PropTypes.array,
    leads: PropTypes.array,
    stripe: PropTypes.object,
    customers: PropTypes.array,
    isFetching: PropTypes.bool,
    isFetchingTeams: PropTypes.bool,
  }
  constructor(props) {
    super(props)
    let team = null
    let lead = null
    let email = null
    // todo JUST FOR TESTING
    // team =
    //   this.props.teams && this.props.teams.length > 0
    //     ? this.props.teams[0]
    //     : null
    let billingType = CHARGE
    const allowedBillingTypes = [INVOICE, CHARGE]
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state
    ) {
      if (this.props.history.location.state.team) {
        team = this.props.history.location.state.team
      }
      if (this.props.history.location.state.lead_actions) {
        lead = this.props.history.location.state.lead
        if (lead.email) {
          email = this.props.history.location.state.lead.email
        }
      }
      if (this.props.history.location.state.type) {
        billingType = this.props.history.location.state.type
      }
    }

    this.state = {
      formType: team ? FORM_TYPE_TEAM : null,
      billingType,
      allowedBillingTypes, // invoice, charge, addToUpcomingInvoice
      team,
      lead,
      email,
      focused: false,
      showChargeModal: false,
      creditCardRequiredError: null,
      notes: null,
      isLoading: false,
      useDefaultCard: true,
    }
  }

  UNSAFE_componentWillMount() {
    // there was a team passed in... go ahead and get that stripe customer broski
    const team = this.state.team
    if (team) {
      if (team.stripe_customer_id) {
        this.setState({
          isLoading: true,
        })
        this.props.stripe_actions.getCustomer({
          team_id: team.id,
          community_id: this.props.activeCommunity.id,
        })
      } else {
        toast.error(
          'Whoops. This team is not billable. Support has been notified.'
        )
        captureException({
          text: `Failure to charge team because they have no stripe id team_id: ${
            team ? team.id : null
          }`,
        })
      }
      if (team.objectType && team.objectType !== 'TeamThicck') {
        this.props.team_actions.getTeam(team.id, {})
      }
    }
    if (
      !this.props.teams ||
      (this.props.teams && this.props.teams.length === 0) ||
      !this.props.isFetchingTeams
    ) {
      this.props.team_actions.getDirectoryTeams(this.props.activeCampus.id, {
        page: 1,
        per_page: 20,
      })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //todo replace with static getDerivedStateFromProps(props, state) {
    if (nextProps.teams && this.state.team) {
      for (let counter = 0; counter < nextProps.teams.length; counter++) {
        if (nextProps.teams[counter].id === this.state.team.id) {
          if (
            nextProps.teams[counter].objectType &&
            nextProps.teams[counter].objectType === 'TeamThicck'
          ) {
            this.setState({
              team: nextProps.teams[counter],
            })
          }
          break
        }
      }
    }
    if (nextProps.customers && nextProps.customers.length > 0) {
      for (let counter = 0; counter < nextProps.customers.length; counter++) {
        const customer = nextProps.customers[counter]
        if (
          (this.state.team &&
            this.state.team.stripe_customer_id === customer.id) ||
          (this.state.lead &&
            this.state.lead.stripe_customer_id === customer.id)
        ) {
          const newAllowedTypes = _.clone(this.state.allowedBillingTypes)
          if (
            customer &&
            customer.subscriptions &&
            customer.subscriptions.total_count > 0
          ) {
            newAllowedTypes.push(ADD_TO_NEXT_INVOICE)
          }
          this.setState({
            customer,
            isLoading: false,
            allowedBillingTypes: newAllowedTypes,
          })
        }
      }
    }
  }

  handleSearchItemClicked = team => {
    // when a team is selected
    const hasTeamThick = Boolean(team && team.objectType === 'TeamThicck')
    const newState = {
      team: team,
    }
    this.setState(newState, () => {
      if (!hasTeamThick) {
        // only care if the global state doesn't have the team thicck already!
        this.props.team_actions.getTeam(team.id, {})
      }
      if (team && team.stripe_customer_id) {
        this.setState({
          isLoading: true,
        })
        this.props.stripe_actions
          .getCustomer({
            team_id: team.id,
            community_id: this.props.activeCommunity.id,
          })
          .then(() => {
            this.setState({
              isLoading: false,
            })
          })
          .catch(err => {
            this.setState({
              isLoading: false,
            })
          })
      } else {
        toast.error(
          'Whoops. This team is not billable. Please contact support.'
        )
        captureException({
          text: `Failure to charge team because they have no stripe id (2) team_id: ${
            team ? team.id : null
          }`,
        })
      }
      // this.shouldDisableChargeButton()
    })
  }
  handleLeadSearchItemSelected = lead => {
    // when a lead or email is selected

    if (lead && typeof lead === 'object' && lead.stripe_customer_id) {
      this.setState({
        isLoading: true,
      })
      this.props.stripe_actions
        .getCustomer({
          contact_id: lead.id,
          community_id: this.props.activeCommunity.id,
        })
        .then(() => {
          this.setState({
            isLoading: false,
          })
        })
        .catch(err => {
          this.setState({
            isLoading: false,
          })
        })
    }
  }
  teamDataResolver = response => response.teams

  renderSearchRow = object => {
    return TeamRowSearch({
      key: object.id,
      company: object,
      index: object.id,
      showEdit: false,
      showActions: false,
      editCompanyClicked: null,
      includeMarginsAndPadding: true,
      includePhoto: false,
      canEdit: false,
    })
  }
  /* autosuggestion functions */
  handleChangeTeam = () => {
    this.setState({
      team: null,
      customer: null,
      showCreditCardForm: false,
      allowedBillingTypes: [INVOICE, CHARGE],
      billingType: CHARGE,
    })
  }
  bankDescriptorValid = bankDescriptor => {
    if (/[<>\\'"]/.test(bankDescriptor)) {
      return false
    }
    return true
  }
  handleFormTypeChange = type => {
    this.setState({
      formType: type,
      team: null,
    })
  }

  prepareMultiLineInvoiceObject = invoiceItemsArray => {
    const invoiceItems = []

    for (let index = 0; index < invoiceItemsArray.length; index++) {
      const invoiceRow = invoiceItemsArray[index]

      invoiceItems.push({
        description: invoiceRow.description,
        unit_amount: invoiceRow.amount,
        quantity: invoiceRow.quantity,
      })
    }
    return invoiceItems
  }
  prepareInvoiceObject = formState => {
    // This method forms the api object for the create_invoice call
    const community = this.props.activeCommunity
    const preference = community.community_preference
    const params = {
      statement_descriptor: formState.statement_descriptor,
      stripe_account_id: community.stripe_account_id,
      billing: 'send_invoice', // This is so that we can append fees to it even if "charge now"
      community_id: community.id,
      campus_id: this.props.activeCampus.id,
      notes: formState.notes,
      days_until_due: formState.dueDate.diff(moment().startOf('day'), 'days'),
      invoice_items: JSON.stringify(
        this.prepareMultiLineInvoiceObject(formState.invoiceItemFieldArray)
      ),
    }
    if (formState.finalize) {
      params.finalize = true
    }
    if (formState.pay_now) {
      params.pay_now = true
    }
    if (this.state.team) {
      if (this.state.team.stripe_customer_id) {
        params.stripe_customer_id = this.state.team.stripe_customer_id
      }
      params.team_id = this.state.team.id
    } else if (formState.email) {
      params.email = formState.email
    }
    return params
  }
  createInvoice = (fstate, stripe, elements, setSubmitting) => {
    const formState = fstate
    const statementDescriptor = `${this.props.activeCommunity.name.substring(
      0,
      14
    )} Invoice`.replace("'", '')
    const { customer, team, useDefaultCard } = this.state
    formState.statement_descriptor = statementDescriptor
    // eslint-disable-next-line no-unused-expressions
    if (this.state.billingType === CHARGE) {
      formState.finalize = true
      formState.pay_now = true
    }
    const invoiceObj = this.prepareInvoiceObject(formState)
    const { defaultChargeableSource } = getCustomerDefaultSource(customer, true)
    if (
      this.shouldShowCCForm(fstate) ||
      (defaultChargeableSource && !useDefaultCard)
    ) {
      const cardElement = elements.getElement(CardElement)
      stripe
        .createToken(cardElement)
        .then(({ token }) => {
          if (token === null || token === undefined) {
            this.setState({
              creditCardRequiredError: 'Credit Card is required',
            })
            setSubmitting(false)
          } else {
            this.setState(
              {
                creditCardRequiredError: null,
                cardToken: token.id,
              },
              () => {
                invoiceObj.card_token = token.id
                this.props.stripe_actions
                  .createInvoice(invoiceObj)
                  .then(response => {
                    const historyState = { invoice: response.invoice }
                    if (this.state.team) {
                      historyState.team = this.state.team
                    }
                    this.props.history.replace(
                      `/billing/invoices/${response.invoice.id}`,
                      historyState
                    )
                  })
                  .catch(err => {
                    this.setState({
                      showChargeModal: false,
                    })
                    setSubmitting(false)
                    toast.error(convertStripeErrorToMessage(err))
                  })
              }
            )
          }
        })
        .catch(err => {
          this.setState({ isLoading: false })
          toast.error(convertStripeErrorToMessage(err))
        })
    } else {
      this.props.stripe_actions
        .createInvoice(invoiceObj)
        .then(response => {
          const historyState = { invoice: response.invoice }
          if (this.state.team) {
            historyState.team = this.state.team
          }
          this.props.history.replace(
            `/billing/invoices/${response.invoice.id}`,
            historyState
          )
        })
        .catch(err => {
          this.setState({
            showChargeModal: false,
            isLoading: false,
          })
          toast.error(convertStripeErrorToMessage(err))
        })
    }
  }

  addToUpcomingInvoice = (formState, setSubmitting) => {
    this.props.stripe_actions
      .createInvoiceItems(this.prepareInvoiceItemsObject(formState))
      .then(() => {
        // todo add a "See it" button here that takes you to company detail and opens an invoice preview
        toast.success('Added items to the next invoice.')
        this.props.history.goBack()
      })
      .catch(err => {
        this.setState({
          showChargeModal: false,
        })
        setSubmitting(false)
        toast.error(convertStripeErrorToMessage(err))
      })
  }
  prepareInvoiceItemsObject = formState => {
    // this call is used for append to upcoming invoice
    // This method forms the api object for the createInvoiceItems call
    const community = this.props.activeCommunity
    const params = {
      invoice_items: JSON.stringify(
        this.prepareMultiLineInvoiceObject(formState.invoiceItemFieldArray)
      ),
      // stripe_account_id: community.stripe_account_id,
      community_id: community.id,
      campus_id: this.props.activeCampus.id,
    }
    // todo maybe the backend should be responsible for this?
    if (this.state.customer.subscriptions.total_count > 0) {
      // if there's more than one subscription, we need to find the earliest one append to
      const subscriptions = this.state.customer.subscriptions.data
      let earliestSubscription = subscriptions[0]
      if (this.state.customer.subscriptions.total_count > 1) {
        subscriptions.map((sub, index) => {
          const nextInvoiceDate = moment.unix(sub.current_period_end)
          const earliestSubscriptionDate = moment.unix(
            earliestSubscription.current_period_end
          )
          if (moment(nextInvoiceDate).isBefore(earliestSubscriptionDate)) {
            earliestSubscription = sub
          }
        })
      }
      params.subscription_id = earliestSubscription.id
    }

    if (this.state.team) {
      if (this.state.team.stripe_customer_id) {
        params.stripe_customer_id = this.state.team.stripe_customer_id
      }
      params.team_id = this.state.team.id
    } else if (formState.email) {
      params.email = formState.email
    }
    return params
  }

  getSubmitButtonText = isSubmitting => {
    const { billingType, isLoading } = this.state
    return (
      <React.Fragment>
        {isSubmitting || isLoading ? (
          <FontAwesomeIcon icon={'circle-notch'} spin />
        ) : (
          <React.Fragment>
            {billingType === CHARGE && <FontAwesomeIcon icon={'dollar-sign'} />}
            {billingType === INVOICE && (
              <FontAwesomeIcon icon={'paper-plane'} />
            )}
            {billingType === ADD_TO_NEXT_INVOICE && (
              <FontAwesomeIcon icon={'clock'} />
            )}
          </React.Fragment>
        )}
        {billingType === CHARGE && ' Charge Now'}
        {billingType === INVOICE && ' Create Invoice'}
        {billingType === ADD_TO_NEXT_INVOICE && ' Add to Next Invoice'}
      </React.Fragment>
    )
  }
  assignChildRef = ref => (this.child = ref)

  shouldShowCCForm = values => {
    const { team, billingType, customer } = this.state
    const { defaultSource, defaultChargeableSource } = getCustomerDefaultSource(
      customer,
      true
    )
    let showCCForm = false
    if (
      (team || values.lead) &&
      customer &&
      !defaultChargeableSource &&
      billingType === CHARGE
    ) {
      showCCForm = true
    }
    if (values.email && billingType === CHARGE) {
      // we're charging an email.
      showCCForm = true
    }

    return showCCForm
  }
  toggleUseDefaultCard = () =>
    this.setState({ useDefaultCard: !this.state.useDefaultCard })

  goBack = event => {
    event.preventDefault()
    this.props.history.goBack()
  }
  handleInvoiceItemChange = ({
    type = 'amount',
    invoiceItemFieldArray,
    setFieldValue,
    newAmount,
    newQuantity,
    index,
    intendedPaymentSource,
  }) => {
    let subTotal = 0
    let total = 0
    // if not index, we're simply doing a total.
    const newArray = []
    for (let counter = 0; counter < invoiceItemFieldArray.length; counter++) {
      // here were trying to sum the amounts in the invoiceItemFieldArray ()
      const invoiceItem = invoiceItemFieldArray[counter]
      if (counter === index) {
        if (type === 'amount') {
          // use new amount
          subTotal += invoiceItem.quantity * (newAmount ? newAmount : 0) // use 0 fallback if data isn't valid
          newArray.push({
            ...invoiceItem,
            amount: newAmount,
          })
        } else if (type === 'quantity') {
          // use new quantity
          subTotal += invoiceItem.amount * (newQuantity ? newQuantity : 0)
          newArray.push({
            ...invoiceItem,
            quantity: newQuantity,
          })
        }
      } else {
        // we're just adding up the total.
        subTotal += invoiceItem.amount * invoiceItem.quantity
        newArray.push(invoiceItem)
      }
    }

    setFieldValue('invoiceItemFieldArray', newArray)

    // generate the fees
    const feesArray = createInvoiceFeesArray({
      amountInDollars: subTotal,
      prefs: resolvePreferenceFromCommunity(this.props.activeCommunity),
      returnEmpty: false,
      customer: this.state.customer,
      intendedPaymentSource,
    })
    setFieldValue('subTotal', Number(subTotal).toFixed(2))
    total += subTotal
    if (feesArray) {
      // add the fees
      feesArray.forEach(fee => {
        total += Number(fee.amountInDollars)
      })
    }
    setFieldValue('total', Number(total).toFixed(2))
  }

  toggleChargeModal = () => {
    this.setState({
      showChargeModal: !this.state.showChargeModal,
    })
  }

  toggleBillingType = value => {
    this.setState({
      billingType: value,
    })
  }

  onFormSubmit = (values, { setSubmitting }, something, stripe, elements) => {
    // setSubmitting(true)
    if (this.state.billingType === ADD_TO_NEXT_INVOICE) {
      this.addToUpcomingInvoice(values, setSubmitting)
    } else {
      this.createInvoice(values, stripe, elements, setSubmitting)
    }
  }

  renderChargeModal(chargeNow, customer, showChargeModal, toggleChargeModal) {
    // TODO This modal is not used anymore....FIX THIS!!!!!!!!!!
    // todo chargeNow is now a TYPE
    return (
      <Modal
        isOpen={showChargeModal}
        toggle={toggleChargeModal}
        className="modal-primary fade in"
      >
        <ModalHeader toggle={toggleChargeModal}>
          {chargeNow ? 'Charge Team' : 'Send Invoice'}
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <div className="row">
              {chargeNow ? (
                <div className="col text-center">
                  <strong>
                    Are you sure you want to charge{' '}
                    <span className="text-primary">
                      {this.state.team
                        ? this.state.team.name
                        : this.state.email}
                    </span>
                    ?
                  </strong>
                </div>
              ) : (
                <div className="col text-center">
                  <strong>
                    Are you sure you want to send an invoice to{' '}
                    <span className="text-primary">
                      {this.state.team
                        ? this.state.team.name
                        : this.state.email}
                    </span>
                    ?
                  </strong>
                  <p>
                    <small>This will be due in 30 days.</small>
                  </p>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleChargeModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={this.createInvoice}>
            {this.getSubmitButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderInvoiceItems({
    invoiceItemFieldArray,
    setFieldValue,
    currencySymbol,
    setFieldError,
    setFieldTouched,
    intendedPaymentSource,
  }) {
    return invoiceItemFieldArray.map((invoiceItem, index) => {
      return (
        <div className="row mt-2 mb-2" key={invoiceItem.internalId}>
          <div className="col-md-5 col-12">
            <Field
              name={`description${index}`}
              label={'Description'}
              hideLabel={index !== 0}
              placeholder="Write a description here"
              component={CustomInputComponent}
              value={invoiceItem[`description${index}`]}
              onChange={event => {
                const newArray = []
                const newDescription = event.target.value
                // here were trying to sum the amounts in the invoiceItemFieldArray ()
                for (
                  let counter = 0;
                  counter < invoiceItemFieldArray.length;
                  counter++
                ) {
                  const invoiceItem = invoiceItemFieldArray[counter]
                  if (counter === index) {
                    // use new value
                    newArray.push({
                      ...invoiceItem,
                      description: newDescription,
                    })
                  } else {
                    newArray.push(invoiceItem)
                  }
                }
                setFieldValue('invoiceItemFieldArray', newArray)
              }}
            />
          </div>

          <div className="col-md-2 col-3">
            <Field
              name={`quantity${index}`}
              type="text"
              hideLabel={index !== 0}
              label={'Quantity'}
              placeholder={'qty'}
              value={invoiceItem[`quantity${index}`]}
              onChange={event => {
                const newQuantity = Number(event.target.value)
                if (
                  (newQuantity &&
                    !isNaN(newQuantity) &&
                    typeof newQuantity === 'number') ||
                  !newQuantity
                ) {
                  this.handleInvoiceItemChange({
                    type: 'quantity',
                    invoiceItemFieldArray,
                    newQuantity,
                    setFieldValue,
                    index,
                    intendedPaymentSource,
                  })
                }
              }}
              component={CustomInputComponent}
            />
          </div>
          <div className="col-md-2 col-3">
            <Field
              name={`amount${index}`}
              type="text"
              hideLabel={index !== 0}
              label={'Unit Price'}
              placeholder={'Price'}
              inputPrefix={currencySymbol}
              component={CustomInputComponent}
              value={
                !isNaN(invoiceItem[`amount${index}`])
                  ? Number(invoiceItem[`amount${index}`]).toFixed(2)
                  : invoiceItem[`amount${index}`]
              }
              onChange={event => {
                const newAmount = Number(event.target.value)
                if (
                  (newAmount &&
                    !isNaN(newAmount) &&
                    typeof newAmount === 'number') ||
                  !newAmount
                ) {
                  this.handleInvoiceItemChange({
                    type: 'amount',
                    invoiceItemFieldArray: invoiceItemFieldArray,
                    newAmount,
                    setFieldValue,
                    index,
                  })
                }
              }}
            />
          </div>
          <div className="col-3">
            {index === 0 && (
              <React.Fragment>
                <LabelStyled>Total</LabelStyled>
                <br />
              </React.Fragment>
            )}
            <Row>
              <div className="col-12 mt-1 d-flex align-items-center justify-content-between">
                <h5 className="mr-3">
                  {!isNaN(Number(invoiceItem.quantity)) &&
                  !isNaN(Number(invoiceItem.amount))
                    ? `${currencySymbol} ${Number(
                        Number(invoiceItem.quantity) *
                          Number(invoiceItem.amount)
                      ).toFixed(2)}`
                    : ''}
                </h5>
                <TextButton
                  color="danger"
                  className="p-0 m-0 mb-1"
                  disabled={Boolean(
                    !invoiceItemFieldArray ||
                      (invoiceItemFieldArray &&
                        invoiceItemFieldArray.length === 1)
                  )}
                  onClick={() => {
                    if (
                      !invoiceItemFieldArray ||
                      (invoiceItemFieldArray &&
                        invoiceItemFieldArray.length === 1)
                      // eslint-disable-next-line no-empty
                    ) {
                    } else {
                      const arrayCopy = _.clone(invoiceItemFieldArray)
                      arrayCopy.splice(index, 1)
                      setFieldError(`amount${index}`, undefined)
                      setFieldError(`description${index}`, undefined)

                      setFieldTouched(`amount${index}`, false)
                      setFieldTouched(`description${index}`, false)
                      this.handleInvoiceItemChange({
                        invoiceItemFieldArray: arrayCopy,
                        setFieldValue,
                      })
                    }
                  }}
                >
                  <FontAwesomeIcon icon={'trash-alt'} />
                </TextButton>
              </div>{' '}
            </Row>
          </div>
        </div>
      )
    })
  }

  render() {
    const {
      billingType,
      allowedBillingTypes,
      team,
      lead: leadFromState,
      email: emailFromState,
      creditCardRequiredError,
      customer,
      formType,
      isLoading,
      useDefaultCard,
    } = this.state

    const { teams, team_actions, activeCampus, activeCommunity } = this.props

    const { defaultChargeableSource } = getCustomerDefaultSource(customer, true)
    const currencySymbol = Constants.getCurrencySymbolFromCommunity(
      this.props.activeCommunity
    )

    const { chargeNowDisabled } = shouldDisableChargeNow(
      this.props.activeCommunity.community_preference
    )
    const intendedPaymentSource = getIntendedPaymentSource(
      billingType === CHARGE ? SEND_INVOICE : null,
      chargeNowDisabled ? 'true' : 'false'
    )
    const daysUntilDue =
      activeCommunity &&
      activeCommunity.preferences &&
      activeCommunity.preferences.days_until_invoice_due
        ? activeCommunity.preferences.days_until_invoice_due
        : 3
    return (
      <React.Fragment>
        {!formType ? (
          <React.Fragment>
            <div className="col-12">
              <div className="bg-white w-100 py-4 message-page-header">
                <div className="row flex-column animated fadeInDown">
                  <strong className="d-block">
                    <h2 className="text-center text-primary">
                      Please select the type
                    </h2>
                  </strong>
                  <h4 className="text-center">
                    Who are you trying to invoice?
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row px-md-5">
                <div className="col-md-6 ">
                  <MessageTypeButton
                    id="company-charge"
                    className={`card d-flex align-items-center justify-content-center p-3 m-5`}
                    onClick={() => this.handleFormTypeChange(FORM_TYPE_TEAM)}
                  >
                    <FontAwesomeIcon icon={'users'} size="3x" />
                    <MessageTypeTitle>Team</MessageTypeTitle>
                    <MessageTypeDescription>
                      Create an invoice for a team in Coworks.
                    </MessageTypeDescription>
                  </MessageTypeButton>
                </div>
                <div className="col-md-6">
                  <div className="container">
                    <MessageTypeButton
                      id="guest-charge"
                      className={`card d-flex align-items-center justify-content-center p-3 m-5`}
                      onClick={() => this.handleFormTypeChange(FORM_TYPE_GUEST)}
                    >
                      <FontAwesomeIcon icon={'user-plus'} size="3x" />
                      <MessageTypeTitle>Guest</MessageTypeTitle>
                      <MessageTypeDescription>
                        Create an invoice for a guest who is not a member in
                        Coworks.
                      </MessageTypeDescription>
                    </MessageTypeButton>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <Formik
                initialValues={{
                  invoiceItemFieldArray: [EMPTY_INVOICE_ITEM],
                  lead: null,
                  email: null,
                  dueDate: moment().startOf('day').add(daysUntilDue, 'days'),
                }}
                validate={validate}
                onSubmit={(values, something, someMore) =>
                  this.onFormSubmit(
                    values,
                    something,
                    someMore,
                    stripe,
                    elements
                  )
                }
                render={formProps => {
                  const {
                    values,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched,
                    resetForm,
                    errors,
                    touched,
                    handleChange,
                    dirty,
                    isSubmitting,
                    submitCount,
                  } = formProps
                  const prefs = resolvePreferenceFromCommunity(activeCommunity)
                  const total =
                    values.total && values.total >= 0
                      ? Number(values.total).toFixed(2)
                      : Number(0).toFixed(2)
                  // sync form state with component state because they could have been passed via props
                  //        like from a company billing tab or in the future, a lead detail
                  if (
                    (team && !values.team) ||
                    (team && values.team !== team)
                  ) {
                    setFieldValue('team', team)
                    setFieldTouched('team', true)
                  }
                  if (
                    (leadFromState && !values.lead) ||
                    (leadFromState && leadFromState !== values.lead)
                  ) {
                    setFieldValue('lead', leadFromState)
                    setFieldTouched('lead', true)
                    if (leadFromState.email) {
                      setFieldValue('email', leadFromState.email)
                      setFieldTouched('email', true)
                    }
                  }
                  if (
                    (emailFromState && !values.email) ||
                    (emailFromState && emailFromState !== values.email)
                  ) {
                    setFieldValue('email', email)
                    setFieldTouched('email', true)
                  }
                  if (
                    (formType && !values.formType) ||
                    (formType && values.formType !== formType)
                  ) {
                    setFieldValue('formType', formType)
                  }

                  const { lead, email } = values
                  const shouldDisableSubmit =
                    !dirty ||
                    isSubmitting ||
                    isLoading ||
                    (formType === FORM_TYPE_GUEST && !values.email) ||
                    (formType === FORM_TYPE_TEAM && !team)
                  return (
                    <ElementsConsumer>
                      {({ stripe, elements }) => (
                        <div className="animated fadeIn">
                          <div className="row">
                            <div className="col">
                              <div className="card">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col">
                                      <h3>
                                        Create{' '}
                                        {billingType === CHARGE
                                          ? 'Charge'
                                          : 'Invoice'}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-block">
                                  <Form>
                                    <div className="row mt-1 mb-2">
                                      <div className="col-4 col-md-6 d-flex">
                                        <TextButton
                                          onClick={event => {
                                            if (
                                              this.props.history &&
                                              this.props.history.location &&
                                              this.props.history.location
                                                .state &&
                                              this.props.history.location.state
                                                .team
                                            ) {
                                              this.goBack(event)
                                            } else {
                                              this.handleChangeTeam()
                                              this.handleFormTypeChange()
                                              resetForm()
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={'arrow-left'}
                                          />{' '}
                                          back
                                        </TextButton>
                                      </div>
                                    </div>
                                    <div className="row mx-3">
                                      {!team && formType === FORM_TYPE_TEAM ? (
                                        <React.Fragment>
                                          <div className="col-12 col-md-6">
                                            <LabelStyled>Team</LabelStyled>
                                            <CoworksSearch
                                              isSearch={false}
                                              disabled={Boolean(values.email)}
                                              placeholder={
                                                'Search and choose a team'
                                              }
                                              defaultData={teams}
                                              actions={team_actions}
                                              activeCampus={activeCampus}
                                              handleItemSelected={
                                                this.handleSearchItemClicked
                                              }
                                              dataResolver={
                                                this.teamDataResolver
                                              }
                                              renderSearchRow={
                                                this.renderSearchRow
                                              }
                                            />
                                          </div>
                                          {(errors.team && touched.team) ||
                                          (errors.team && submitCount > 0) ? (
                                            <div className="col-12 mt-2">
                                              <div className="text-danger">
                                                {errors.team}
                                              </div>
                                            </div>
                                          ) : null}
                                        </React.Fragment>
                                      ) : null}
                                      {formType === FORM_TYPE_TEAM && team ? (
                                        <div className="col-12">
                                          <div className="row">
                                            <div className="col-12 col-md-1">
                                              <RoundedImage
                                                size="medium"
                                                photo={
                                                  team.team_photo
                                                    ? team.team_photo
                                                        .small_file_url
                                                    : defaultTeamPic
                                                }
                                                alt="Picture"
                                                className="img-responsive"
                                              />
                                              {/* {/* img-responsive center-block img-avatar mx-2 /*} */}
                                            </div>
                                            <div className="col-md-9 col-12">
                                              <h4>{team.name}</h4>
                                              {team &&
                                              team.admins &&
                                              team.admins.length > 0 ? (
                                                <p className="mb-0">
                                                  <span className="text-muted">
                                                    Admin:{' '}
                                                  </span>{' '}
                                                  {team.admins[0].first_name}{' '}
                                                  {team.admins[0].last_name}
                                                </p>
                                              ) : null}
                                              {team &&
                                              team.admins &&
                                              team.admins.length > 0 ? (
                                                <p className="mb-0">
                                                  <span className="text-muted">
                                                    Email:{' '}
                                                  </span>{' '}
                                                  {team.admins[0].email}
                                                </p>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}

                                      {!lead &&
                                        !values.email &&
                                        formType === FORM_TYPE_GUEST && (
                                          <React.Fragment>
                                            <div className="col-12 col-md-6">
                                              <QuickSearch
                                                type="Lead"
                                                isCreatable
                                                placeholder={
                                                  'Search or enter a new email to invoice'
                                                }
                                                title={'Guest email'}
                                                handleSearchItemSelected={lead => {
                                                  setFieldError(
                                                    'lead',
                                                    undefined
                                                  )
                                                  setFieldTouched('lead', true)
                                                  setFieldValue('lead', lead)

                                                  setFieldError(
                                                    'email',
                                                    undefined
                                                  )
                                                  setFieldTouched('email', true)
                                                  setFieldValue(
                                                    'email',
                                                    lead.email
                                                  )

                                                  this.handleLeadSearchItemSelected(
                                                    lead
                                                  )
                                                }}
                                                dataFilter={leads =>
                                                  leads.filter(
                                                    lead =>
                                                      lead.email &&
                                                      Constants.validateEmail(
                                                        lead.email
                                                      )
                                                  )
                                                }
                                                onCreateOption={email => {
                                                  setFieldError(
                                                    'email',
                                                    undefined
                                                  )
                                                  setFieldTouched('email', true)
                                                  setFieldValue('email', email)
                                                }}
                                                otherParams={{
                                                  community_id:
                                                    activeCommunity.id,
                                                }}
                                                renderSingleValue={() => {}}
                                              />
                                            </div>
                                          </React.Fragment>
                                        )}
                                      {formType === FORM_TYPE_GUEST &&
                                      (lead || email) ? (
                                        <div className="col-12">
                                          <div className="row">
                                            <div className="col-9">
                                              {lead && (
                                                <h4>
                                                  {lead.first_name}{' '}
                                                  {lead.last_name}
                                                </h4>
                                              )}
                                              <h4 className="mb-0">
                                                <span className="text-muted">
                                                  Email:{' '}
                                                </span>{' '}
                                                {lead ? lead.email : email}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      {formType === FORM_TYPE_GUEST &&
                                      errors.email &&
                                      touched.email ? (
                                        <div className="col-12 mt-2">
                                          <div className="text-danger">
                                            {errors.email}
                                          </div>
                                        </div>
                                      ) : null}
                                      {email || lead || team ? (
                                        <div className="col-12">
                                          <TextButton
                                            className="m-0 p-0 mt-4"
                                            onClick={() => {
                                              setFieldValue('email', null)
                                              setFieldError('email', undefined)
                                              setFieldTouched('email', false)

                                              setFieldValue('lead', null)
                                              setFieldError('lead', undefined)
                                              setFieldTouched('lead', false)

                                              setFieldValue('team', null)
                                              setFieldError('team', undefined)
                                              setFieldTouched('team', false)
                                              this.setState({
                                                team: null,
                                                allowedBillingTypes: [
                                                  CHARGE,
                                                  INVOICE,
                                                ],
                                                billingType:
                                                  billingType ===
                                                  ADD_TO_NEXT_INVOICE
                                                    ? CHARGE
                                                    : billingType,
                                                customer: null,
                                                stripeSubscriptions: null,
                                              })
                                            }}
                                          >
                                            Change{' '}
                                            {email || lead ? 'email' : 'team'}
                                          </TextButton>
                                        </div>
                                      ) : null}
                                    </div>

                                    <React.Fragment>
                                      <div className="col-12">
                                        <div className="row">
                                          <div className="col">
                                            <hr />
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-12">
                                            <Tooltip
                                              header={'Charge Now'}
                                              tooltipContent={
                                                'This will charge the payment source immediately'
                                              }
                                              buttonText={'More on Invoices'}
                                              handleClick={() => {
                                                window.open(
                                                  'https://help.coworksapp.com/en/articles/3764710-invoices',
                                                  '_blank'
                                                )
                                              }}
                                            >
                                              <PillToggle
                                                id={'ChargeNow'}
                                                className={classnames({
                                                  active:
                                                    billingType === CHARGE,
                                                })}
                                                onClick={() => {
                                                  // intercept click and check for multi invoice items because they're not supported.
                                                  this.toggleBillingType(CHARGE)
                                                }}
                                              >
                                                <FontAwesomeIcon icon="dollar-sign" />{' '}
                                                Charge Now
                                              </PillToggle>
                                            </Tooltip>

                                            <Tooltip
                                              header={'Emailed Invoice'}
                                              tooltipContent={
                                                'This will create an invoice that will be emailed to the team or guest to be paid manually.'
                                              }
                                              buttonText={'More on Invoices'}
                                              handleClick={() => {
                                                window.open(
                                                  'https://help.coworksapp.com/en/articles/3764710-invoices',
                                                  '_blank'
                                                )
                                              }}
                                            >
                                              <PillToggle
                                                id={'EmailInvoice'}
                                                className={classnames({
                                                  active:
                                                    billingType === INVOICE,
                                                })}
                                                onClick={() => {
                                                  this.toggleBillingType(
                                                    INVOICE
                                                  )
                                                }}
                                              >
                                                <FontAwesomeIcon icon="paper-plane" />{' '}
                                                Email
                                              </PillToggle>
                                            </Tooltip>

                                            <Tooltip
                                              header={'Add to next Invoice'}
                                              tooltipContent={
                                                team
                                                  ? "This will add this charge to the team's next invoice that is generated from its membership."
                                                  : 'Guests do not have memberships so you cannot add to items to their next invoice.'
                                              }
                                              buttonText={'More on Invoices'}
                                              handleClick={() => {
                                                window.open(
                                                  'https://help.coworksapp.com/en/articles/3764710-invoices',
                                                  '_blank'
                                                )
                                              }}
                                            >
                                              <PillToggle
                                                id={'AddToNextInvoice'}
                                                className={classnames({
                                                  active:
                                                    billingType ===
                                                    ADD_TO_NEXT_INVOICE,
                                                })}
                                                onClick={() => {
                                                  if (
                                                    allowedBillingTypes.includes(
                                                      ADD_TO_NEXT_INVOICE
                                                    )
                                                  ) {
                                                    this.toggleBillingType(
                                                      ADD_TO_NEXT_INVOICE
                                                    )
                                                  }
                                                }}
                                                disabled={
                                                  !allowedBillingTypes.includes(
                                                    ADD_TO_NEXT_INVOICE
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={
                                                    isLoading
                                                      ? 'circle-notch'
                                                      : 'clock'
                                                  }
                                                  spin={isLoading}
                                                />{' '}
                                                Add To Next Invoice
                                              </PillToggle>
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col">
                                            <hr />
                                          </div>
                                        </div>
                                      </div>
                                      <Row className="px-3">
                                        {billingType === INVOICE && (
                                          <div className="col-4 mb-2">
                                            <LabelStyled>Due Date</LabelStyled>
                                            <br />
                                            <SingleDatePicker
                                              id="due_date"
                                              date={values.dueDate}
                                              onDateChange={momentDate => {
                                                // ensure that we don't fuck with time that was set previously
                                                const time = values.dueDate
                                                if (momentDate && time) {
                                                  momentDate.set({
                                                    hour: time.get('hour'),
                                                    minute: time.get('minute'),
                                                    second: time.get('second'),
                                                  })
                                                }
                                                setFieldValue(
                                                  'dueDate',
                                                  momentDate
                                                )
                                              }}
                                              focused={this.state.focused}
                                              onFocusChange={({ focused }) =>
                                                this.setState({ focused })
                                              }
                                              showClearDate={false}
                                              placeholder={'Due Date'}
                                            />
                                          </div>
                                        )}
                                        <div className="col-md-12">
                                          <Field
                                            name="notes"
                                            rows={3}
                                            label="Internal Notes"
                                            placeholder="Write any notes on this invoice"
                                            component={CustomTextAreaComponent}
                                          />
                                        </div>
                                        <div className="col-12">
                                          <hr />
                                        </div>
                                      </Row>

                                      <Row className="px-3 my-3 d-flex align-items-center">
                                        <div className="col-md-12">
                                          <h3>Invoice Items</h3>
                                        </div>
                                        <div className="col-md-12">
                                          {this.renderInvoiceItems({
                                            invoiceItemFieldArray:
                                              values.invoiceItemFieldArray,
                                            setFieldValue,
                                            currencySymbol,
                                            setFieldError,
                                            setFieldTouched,
                                            intendedPaymentSource,
                                          })}
                                          <Tooltip
                                            header={'Item Limit Reached'}
                                            tooltipContent={
                                              'You can only add up to 20 line items.'
                                            }
                                            enabled={
                                              values.invoiceItemFieldArray &&
                                              values.invoiceItemFieldArray
                                                .length >= 20
                                            }
                                          >
                                            <span
                                              id={'addInvoiceButton'}
                                              style={
                                                values.invoiceItemFieldArray &&
                                                values.invoiceItemFieldArray
                                                  .length >= 20
                                                  ? {
                                                      cursor: 'pointer',
                                                    }
                                                  : {}
                                              }
                                            >
                                              <TextButton
                                                color="primary"
                                                style={
                                                  values.invoiceItemFieldArray &&
                                                  values.invoiceItemFieldArray
                                                    .length >= 20
                                                    ? {
                                                        pointerEvents: 'none',
                                                      }
                                                    : {}
                                                }
                                                className="p-0 m-0"
                                                disabled={
                                                  values.invoiceItemFieldArray &&
                                                  values.invoiceItemFieldArray
                                                    .length >= 20
                                                }
                                                onClick={() => {
                                                  const newInvoiceItem = _.cloneDeep(
                                                    EMPTY_INVOICE_ITEM
                                                  )
                                                  newInvoiceItem.internalId = `${
                                                    Math.random() * 100
                                                  }`
                                                  setFieldValue(
                                                    'invoiceItemFieldArray',
                                                    [
                                                      ...values.invoiceItemFieldArray,
                                                      newInvoiceItem,
                                                    ]
                                                  )
                                                }}
                                              >
                                                Add Invoice Item
                                              </TextButton>
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </Row>
                                    </React.Fragment>

                                    <div className="row">
                                      <div className="col">
                                        <div className="row my-2 mx-1">
                                          <Col
                                            md={{ size: 4, offset: 8 }}
                                            xs={{ size: 12 }}
                                            className="d-flex align-items-center justify-content-between"
                                          >
                                            <p className="h6 d-inline">
                                              <strong>Subtotal</strong>
                                            </p>
                                            <p className="h6 d-inline">
                                              <strong>
                                                {' '}
                                                {currencySymbol}
                                                {!isNaN(values.subTotal)
                                                  ? Number(
                                                      values.subTotal
                                                    ).toFixed(2)
                                                  : ''}
                                              </strong>
                                            </p>
                                          </Col>
                                        </div>
                                        {createInvoiceFeesArray({
                                          amountInDollars: values.subTotal,
                                          prefs,
                                          returnEmpty: true,
                                          customer,
                                          intendedPaymentSource,
                                        }).map((fee, i) => {
                                          return (
                                            <Row className="mx-1">
                                              <Col
                                                md={{ size: 4, offset: 8 }}
                                                xs={{ size: 12 }}
                                                className="d-flex align-items-center justify-content-between"
                                              >
                                                <b>{fee.description}</b>
                                                <b>
                                                  {currencySymbol}
                                                  {Number(
                                                    fee.amountInDollars
                                                  ).toFixed(2)}
                                                </b>
                                              </Col>
                                            </Row>
                                          )
                                        })}
                                        <div className="row my-2 mx-1">
                                          <Col
                                            md={{ size: 4, offset: 8 }}
                                            xs={{ size: 12 }}
                                            className="d-flex align-items-center justify-content-between"
                                          >
                                            <p className="h2 d-inline">
                                              <strong>Total</strong>
                                            </p>
                                            <p className="h2 d-inline">
                                              <strong>
                                                {currencySymbol}
                                                {total}
                                              </strong>
                                            </p>
                                          </Col>
                                        </div>
                                      </div>
                                    </div>

                                    {this.shouldShowCCForm(values) ? (
                                      <Row className=" px-3">
                                        <div className="col-md-12">
                                          <h3>Payment Method</h3>
                                          <span className="text-muted">
                                            Don't have a payment method on hand?{' '}
                                            <TextButton
                                              className="p-0 m-0"
                                              onClick={() =>
                                                this.toggleBillingType(INVOICE)
                                              }
                                            >
                                              Send an Invoice
                                            </TextButton>{' '}
                                            instead.
                                          </span>
                                        </div>
                                        <div className="col-6 my-3">
                                          <CreditCardForm
                                            stripe={stripe}
                                            elements={elements}
                                          />

                                          {creditCardRequiredError && (
                                            <div className="text-danger">
                                              {creditCardRequiredError}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-12">
                                          <hr />
                                        </div>
                                      </Row>
                                    ) : null}
                                    {billingType === CHARGE &&
                                    defaultChargeableSource ? (
                                      <Row className="px-3 py-2">
                                        <div className="col-md-12">
                                          <h3>Payment Source</h3>
                                        </div>
                                        <div className="col-md-12">
                                          <span className="text-muted">
                                            This is the default payment source.{' '}
                                            <TextButton
                                              className="m-0 p-0"
                                              onClick={
                                                this.toggleUseDefaultCard
                                              }
                                            >
                                              {useDefaultCard
                                                ? 'Need to use a different card?'
                                                : 'Never mind, use default.'}
                                            </TextButton>
                                          </span>
                                        </div>
                                        {useDefaultCard && (
                                          <div className="col-12">
                                            <div className="row w-50 mt-2 mb-2">
                                              <PaymentSourceComponent
                                                defaultSourceObject={
                                                  defaultChargeableSource
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                        {!useDefaultCard && (
                                          <div className="col-6 my-3">
                                            <CreditCardForm
                                              stripe={stripe}
                                              elements={elements}
                                            />
                                            {creditCardRequiredError && (
                                              <div className="text-danger">
                                                {creditCardRequiredError}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        <div className="row">
                                          <div className="col">
                                            <hr />
                                          </div>
                                        </div>
                                      </Row>
                                    ) : null}

                                    <div className="row mt-5 mx-2 d-flex justify-content-end">
                                      <ButtonStyled
                                        color="white"
                                        className="mr-2"
                                        onClick={this.goBack}
                                      >
                                        Cancel
                                      </ButtonStyled>
                                      <ButtonStyled
                                        type="submit"
                                        disabled={shouldDisableSubmit}
                                      >
                                        {this.getSubmitButtonText(isSubmitting)}
                                      </ButtonStyled>
                                    </div>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ErrorFocus formik={formProps} />
                        </div>
                      )}
                    </ElementsConsumer>
                  )
                }}
              />
            )}
          </ElementsConsumer>
        )}
      </React.Fragment>
    )
  }
}

CreateInvoiceForm.displayName = 'CreateInvoiceForm'

const mapStateToProps = state => {
  return {
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    teams: state.teams.teams,
    leads: state.leads,
    user: state.user,
    customers: state.stripe.customers,
    isFetching: state.ui.isFetching,
    isFetchingTeams: resolveFetchingStatus(state, FETCHING_TEAMS),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    team_actions: bindActionCreators(teamActions, dispatch),
    stripe_actions: bindActionCreators(stripeActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoiceForm)
