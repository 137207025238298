/* eslint-disable no-invalid-this,max-depth,camelcase */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Route, withRouter } from 'react-router-dom'
import moment from 'moment'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  Row,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import { captureException } from '@global/ErrorFactory'
import {
  resolveFetchingStatus,
  FETCHING_MEMBER,
} from '@global/Constants/FetchingConstants'

import classnames from 'classnames'
import { Alert, Input } from 'reactstrap'

import * as memberActions from '@reduxActions/memberActions'
import * as teamActions from '@reduxActions/teamActions'
import * as taskActions from '@reduxActions/taskActions'
import * as checkinActions from '@app/reduxActions/checkinActions'
import * as memberSelectors from '@reduxSelectors/memberSelectors'

import * as Roles from '@app/config/roles'

import * as Constants from '@global/Constants'
import * as NavigationConstants from '@global/Constants/NavigationConstants'
import { checkDoorProvider } from '@app/components/Community/Integrations/IntegrationConstants'
import { CHECKIN_MEMBER } from '@global/Constants/CheckinsConstants'

import CommentsComponent from '@global/CommentsComponent'
import QuickSearch from '@global/Search/QuickSearch'
import { Button } from '@global/Base/Button/ButtonStyled'
import TogglesComponent from '@global/Base/Toggle/TogglesComponent'
import { LabelStyled } from '@global/Form/FormComponents'
import { presets } from '@global/Constants/DateConstants'

import RadioGroup from '@global/Base/Radio/RadioGroup'
import ArchiveModal from '../../ArchiveModal'
import MemberForm from './Profile/MemberForm'
import * as _ from 'lodash'
import Spinner from '@global/Spinner'
import CheckinsView from './Checkins/CheckinsView'
import { FETCHING_CHECKINS } from '@global/Constants/FetchingConstants'
import MemberProfile from './Profile/MemberProfile'
import urlParse from '../../../../hocs/urlParse'

const TabContentStyled = styled(TabContent)`
  margin-bottom: 15px;
`
const CheckinsViewStyled = styled(CheckinsView)`
  display: block;
  padding: 0;
`

class MemberDetail extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    member_actions: PropTypes.object,
    checkin_actions: PropTypes.object,
    team_actions: PropTypes.object,
    task_actions: PropTypes.object,
    activeCampus: PropTypes.object,
    activeCommunity: PropTypes.object,
    pagination: PropTypes.object,
    plans: PropTypes.array,
    integrations: PropTypes.object,
    customers: PropTypes.array,
    member: PropTypes.object,
    memberId: PropTypes.string.isRequired,
    params: PropTypes.object,
    team: PropTypes.object,
    role: PropTypes.string,
    isPosting: PropTypes.bool,
    isFetchingMember: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    const locState = props.history.location.state
    const isEditing = locState && locState.isEditing
    const viewOnly = props.user && props.user.type === 'Member'

    this.state = {
      archiveTeamAfterChange: 'Deactivate',
      newOrExistingTeam: 'Existing Team',
      loading: false,
      viewOnly,
      creatingNote: false,
      showAccess: false,
      doorAccess: [],
      accessDate: moment(),
      photoChanged: false,
      errors: [],
      showArchiveModal: false,
      newAdminId: -1,
      isEditing,
      comment: '',
      activeTab: props.params.tabId ? props.params.tabId : 'profile',
      dateRange: {
        startDate: moment(),
        endDate: moment().add('weeks', 1),
      },
      tasks: [],
      disableWelcomeEmailButton: false,
    }
  }
  componentDidMount() {
    const {
      member_actions,
      team_actions,
      activeCampus,
      activeCommunity,
      team,
      member,
      match,
      memberId,
    } = this.props
    if (memberId) {
      this.setState({ loading: true })
      // get the member thicck

      member_actions
        .getMember(memberId, { campus_id: activeCampus.id }, FETCHING_MEMBER)
        .then(({ user }) => {
          const newState = {}
          if (user.tasks) newState.tasks = user.tasks
          this.setState(newState)
        })
        .catch(error => {
          toast.error(
            'Whoops, looks like there was an error getting latest member details! Support has been notified!'
          )
          captureException({
            text: `Error getting member details (1) ${
              member ? member.id : null
            } `,
            error,
          })
        })
        .finally(() => this.setState({ loading: false }))
    }
  }

  toggleTeamStatusAfterMemberChange = newTab => {
    this.setState({
      archiveTeamAfterChange: newTab,
    })
  }
  handleSubmitComment = () => {
    this.setState({ creatingNote: true })
    this.props.task_actions
      .createTask({
        description: this.state.comment,
        created_by_user_id: this.props.user.id,
        user_id: this.props.member.id,
      })
      .then(response => {
        if (response && response.task) {
          const newTask = response.task
          newTask.created_by_user = this.props.user
          this.setState({
            tasks: [...this.state.tasks, response.task],
            comment: '',
          })
        }
      })
      .catch(error =>
        captureException({
          text: `Error getting submitting comment`,
          error,
        })
      )
      .finally(() => {
        this.setState({
          creatingNote: false,
        })
      })
  }
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  renderInactiveAlert = () => {
    const { member } = this.props
    if (member && member.is_archived) {
      return (
        <Alert color="warning" className={'m-0'} isOpen>
          This member is inactive!
        </Alert>
      )
    }
    return null
  }

  changeTeam = () => {
    const obj = {
      id: this.props.member.id,
      campus_id: this.props.activeCampus.id,
    }
    if (this.props.team) obj.current_team_id = this.props.team.id

    if (this.state.selectedTeamToChange) {
      obj.new_team_id = this.state.selectedTeamToChange.id
      // Should the company be deactivated after the move.
    }
    obj.archive_team = Boolean(
      this.state.archiveTeamAfterChange === 'Deactivate'
    )
    obj.new_team_name = this.state.newTeamName
    this.props.member_actions
      .changeTeam(obj)
      .then(response => {
        toast.success("Changed member's team.")
        if (response.team) {
          NavigationConstants.navigateToTeam({
            history: this.props.history,
            match: this.props.match,
            team: response.team,
            tab: 'profile',
          })
        }
      })
      .catch(response => {
        if (response && response.errors) {
          this.setState({ errors: response.errors, isEditing: false })
          toast.error(response.errors.message)
        } else {
          toast.error('Error saving member')
        }
        captureException({
          text: `Problem changing a members team`,
          response,
        })
      })
  }
  toggleShowArchiveModal = () => {
    if (this.props.member && this.props.team) {
      this.setState({
        showArchiveModal: !this.state.showArchiveModal,
      })
    }
  }
  handleSearchItemSelected = team => {
    if (team) {
      this.setState({
        selectedTeamToChange: team,
      })
    }
  }
  handleSearchItemCleared = () => {
    this.setState({
      selectedTeamToChange: null,
    })
  }
  handleSelectRadio = value => {
    this.setState({ newOrExistingTeam: value })
  }
  toggleTab = tab => {
    const { member, history, match } = this.props
    if (member) {
      NavigationConstants.navigateToMember({
        history: history,
        match: match,
        member,
        tab,
      })
    }
  }
  handleMemberCheckedIn = () => {
    this.setState({ isCheckedIn: true })
  }
  handleMemberCheckedOut = () => {
    this.setState({ isCheckedIn: false })
  }

  render() {
    const { activeCampus, member, team } = this.props
    const {
      activeTab,
      loading,
      tasks,
      comment,
      selectedTeamToChange,
      creatingNote,
    } = this.state

    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === 'profile',
              })}
              onClick={() => {
                this.toggleTab('profile')
              }}
            >
              Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === 'checkins',
              })}
              onClick={() => {
                this.toggleTab('checkins')
              }}
            >
              Check-Ins
            </NavLink>
          </NavItem>
        </Nav>
        <TabContentStyled activeTab={activeTab}>
          {loading || !member || !team ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <TabPane tabId="profile">
                {this.renderInactiveAlert()}
                <Route path={'/directory/members/:memberId/profile'}>
                  {activeTab === 'profile' && <MemberProfile {...this.props} />}
                </Route>
              </TabPane>
              <TabPane tabId="checkins">
                {this.renderInactiveAlert()}
                <Route path={'/directory/members/:memberId/checkins'}>
                  {activeTab === 'checkins' && (
                    <CheckinsViewStyled
                      onCheckInMember={this.handleMemberCheckedIn}
                      onCheckOutMember={this.handleMemberCheckedOut}
                      disableCheckin={member && member.is_archived}
                      type={CHECKIN_MEMBER}
                      {...this.props}
                    />
                  )}
                </Route>
              </TabPane>
            </React.Fragment>
          )}
        </TabContentStyled>
        {this.props.isPosting ? (
          <Spinner />
        ) : (
          CommentsComponent({
            tasks: tasks,
            handleInputChange: this.handleInputChange,
            comment: comment,
            handleSubmitComment: this.handleSubmitComment,
            loading: creatingNote,
          })
        )}
      </React.Fragment>
    )
  }
}

MemberDetail.displayName = 'MemberDetail'

function mapStateToProps(state, ownProps) {
  let member,
    memberId,
    team = null
  const { history, params } = ownProps
  if (
    history &&
    history.location &&
    history.location.state &&
    history.location.state.member &&
    history.location.state.member.id
  ) {
    memberId = history.location.state.member.id
  } else if (params.memberId) {
    // can't rely on this for now
  }
  // fallback on finding the team in redux based on the URL params
  if (memberId) {
    let memberLookup = Constants.pluckObjectByID(
      memberSelectors.getMemberList(state),
      memberId
    )
    if (!memberLookup) {
      memberLookup = Constants.pluckObjectByID(state.members.inactive, memberId)
    }
    if (memberLookup) member = memberLookup

    // see https://gitlab.com/Coworks/coworks-client/-/merge_requests/126#note_520420889
    // member.hasOwnProperty('teams')
    if (member && member.teams) {
      const activeTeams = member.teams
        ? member.teams.filter(team => team.status === 'active')
        : []
      const inactiveTeams = member.inactiveTeams
        ? member.inactiveTeams.filter(team => team.status === 'inactive')
        : []

      if (activeTeams.length > 0) {
        team = activeTeams[0]
      } else if (inactiveTeams.length > 0) {
        team = inactiveTeams[0]
      } else {
        captureException({
          text: `Member (UserThicck) has no Team ${member ? member.id : null} `,
        })
      }
    }
  }

  return {
    member,
    memberId,
    team,
    activeCampus: state.ui.activeCampus,
    activeCommunity: state.ui.activeCommunity,
    plans: state.plans,
    integrations: state.integrations,
    customers: state.stripe.customers,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    member_actions: bindActionCreators(memberActions, dispatch),
    task_actions: bindActionCreators(taskActions, dispatch),
  }
}

export default compose(
  urlParse(),
  connect(mapStateToProps, mapDispatchToProps)
)(MemberDetail)
