import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const NotificationWrapper = styled.div`
  .header {
    background-color: rgba(220, 220, 220, 0.9);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px;
    align-items: center;
    .title {
      text-transform: uppercase;
      font-family: 'Helvetica', Arial, sans-serif;
      color: #3c424b;
      font-weight: bold;
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 18px;
    }
    .time {
      font-family: 'Helvetica', Arial, sans-serif;
      color: #3c424b;
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 12px;
    }
  }
  border-radius: 15px;
  background-color: rgba(200, 200, 200, 0.8);
`
function IOSPushNotificationPreview({ title, message, messageTitle, timing }) {
  return (
    <NotificationWrapper className="mb-3">
      <div className="header">
        <h3 className="title">{title}</h3>
        <h3 className="time">{timing}</h3>
      </div>
      <div className="p-2">
        {messageTitle && (
          <b>
            {messageTitle}
            <br />
          </b>
        )}
        <p>{message}</p>
      </div>
    </NotificationWrapper>
  )
}
IOSPushNotificationPreview.defaultProps = {
  title: 'Coworks App',
  timing: 'NOW',
  message: '',
  messageTitle: null,
}
IOSPushNotificationPreview.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  messageTitle: PropTypes.string,
  timing: PropTypes.string.isRequired,
}
IOSPushNotificationPreview.displayName = 'IOSPushNotificationPreview'
export default IOSPushNotificationPreview
